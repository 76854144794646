import { format } from 'date-fns';
import {
  BAD_REQUEST,
  FORBIDDEN,
  NOT_FOUND,
  PAYMENT_REQUIRED,
  UNAUTHORIZED,
} from 'http-status-codes';
import { LOCATION_CHANGE, push, replace } from 'redux-first-history';
import { eventChannel } from 'redux-saga';
import { call, getContext, put, race, select, take } from 'redux-saga/effects';
import {
  CONTACT_FORM_DEFAULTS,
  CONTACT_FORM_ERROR_DETAILS,
  CONTACT_FORM_GENERAL_ERROR_MESSAGE_TEMPLATE,
} from '../../../constants';
import { API_SERVICES } from '../../../constants/api';
import {
  AUTHENTICATION_ERROR_DESCRIPTIONS,
  AUTHENTICATION_ERROR_TITLE,
  BAD_REQUEST_ERROR_DESCRIPTIONS,
  BAD_REQUEST_ERROR_TITLE,
  CANCEL_BUTTON_KEY,
  DOMAIN_NOT_WHITELISTED_DESCRIPTION,
  DOMAIN_NOT_WHITELISTED_TITLE,
  EMAIL_RESET_TOKEN_REQUEST_DESCRIPTION,
  EMAIL_RESET_TOKEN_REQUEST_TITLE,
  EXCEED_USER_LIMIT_DESCRIPTION,
  EXCEED_USER_LIMIT_TITLE,
  FILE_BAD_STATUS_REQUEST_DESCRIPTION,
  FORBIDDEN_REGISTER_ERROR_DESCRIPTION,
  FORBIDDEN_REGISTER_ERROR_TITLE,
  INCORRECT_CURRENT_PASSWORD_ERROR_DESCRIPTION,
  INCORRECT_CURRENT_PASSWORD_ERROR_TITLE,
  MAX_USERS_REGISTER_ERROR_DESCRIPTION,
  MAX_USERS_REGISTER_ERROR_TITLE,
  NAME_TOO_LONG_ERROR_DESCRIPTION,
  NAME_TOO_LONG_ERROR_TITLE,
  NAME_TOO_SHORT_ERROR_DESCRIPTION,
  NAME_TOO_SHORT_ERROR_TITLE,
  NO_BUTTON_KEY,
  NO_USER_REGISTRATION_DESCRIPTION,
  NO_USER_REGISTRATION_TITLE,
  OK_BUTTON_KEY,
  OTP_EXPIRED_ERROR_DESCRIPTION,
  OTP_EXPIRED_ERROR_TITLE,
  OVERWRITE_KEY,
  PASSWORD_MISMATCH_DESCRIPTION,
  PASSWORD_MISMATCH_TITLE,
  PASSWORD_TOO_WEAK_DESCRIPTION,
  PASSWORD_TOO_WEAK_TITLE,
  REPORT_BUTTON_KEY,
  RESEND_EMAIL_BUTTON_KEY,
  RETURN_TO_LOGIN_BUTTON_KEY,
  UNAUTHORIZED_RESET_ERROR_DESCRIPTION,
  UNAUTHORIZED_RESET_ERROR_TITLE,
  YES_BUTTON_KEY,
  closeSettingsWhileUploadingAlert,
  createActiveDashboardSessionForbiddenAlert,
  createAppFailedToStartAlert,
  createAskConversationLimitAlert,
  createBrowserTypeAlert,
  createDatasetAlreadyExistAlert,
  createDatasetsAlreadyExistAlert,
  createDuplicateNameErrorAlert,
  createFileAlreadyExistsAlert,
  createFileBadStatusRequestAlert,
  createFileInvalidAlert,
  createFileInvalidNameAlert,
  createFileStorageLimitReachedAlert,
  createFilesAlreadyExistAlert,
  createForbiddenErrorAlert,
  createForbiddenRoleErrorAlert,
  createForgetDatasetAlert,
  createGeneralAvaErrorAlert,
  createGeneralErrorAlert,
  createGeneralInsightsBoardErrorAlert,
  createLoginErrorAlert,
  createRecipeAlreadyExistsAlert,
  createRegisterErrorAlert,
  createReloadSessionForbiddenAlert,
  createReloadSessionNotFoundAlert,
  createSendVerifyEmailFailureAlert,
  createSessionNameAlreadyExistsAlert,
  createSwitchAppsAlert,
  createSwitchDashboardsAlert,
  createSwitchFromChatToDashboardAlert,
  createSwitchFromDashboardToChatAlert,
  createTaskFailedAlert,
  createUnauthenticatedErrorAlert,
  createUnverifiedAccountAlert,
  createVerifyAccountFailureAlert,
  editExistingDatabaseAlert,
  renameExistingDatabaseAlert,
  renameObjectAlreadyExistsAlert,
  uploadConnectionFailedAlert,
  uploadFileFailedAlert,
} from '../../../constants/dialog.constants';
import { HOME_OBJECTS } from '../../../constants/home_screen';
import { isNetworkError } from '../../../constants/network_error';
import { isChatPage, isDataChatSessionPage, paths } from '../../../constants/paths';
import { PasswordMismatchError } from '../../../utils/errors';
import { authenticationAlert, logoutRequest } from '../../actions/auth.actions';
import { hasSeenBrowserCompatibilityWarning } from '../../actions/browserType.action';
import { openContactForm } from '../../actions/contact_form.actions';
import { START_DASHBOARD } from '../../actions/dashboard.actions';
import { closeDialog, openAlertDialog } from '../../actions/dialog.actions';
import {
  GET_HOME_SCREEN_OBJECTS_FAILURE,
  GET_HOME_SCREEN_OBJECTS_SUCCESS,
  deleteObjectFailure,
  getHomeScreenObjectsRequest,
} from '../../actions/home_screen.actions';
import { describeAndSendUtteranceRequest } from '../../actions/messages.actions';
import { showNetworkStatus } from '../../actions/settings.actions';
import { addUploadedDatasets } from '../../reducers/datasetCreator.reducer';
import { selectAppId, selectSession, selectSessionType } from '../../selectors/session.selector';
import {
  SESSION_TYPES,
  exitSessionSuccess,
  onAppClick,
  tryExitSessionRequest,
} from '../../slices/session.slice';
import { sendEmailVerifyRequestWorker } from '../auth.saga';
import {
  selectAccessToken,
  selectAppsById,
  selectDashboardSessionName,
  selectFilesRequesting,
} from '../selectors';
import { putFileResponseHandler } from './upload';

export const chatStateSelector = (state) => state.chat;

/**
 * Opens a generic alert and prompts the user with a list of options.
 * The channel emits when the button is pressed.
 *
 * const alertChannel = yield createAlertChannel(..., [{ key: 'key1', ... }, { key: 'key2', ... }]);
 * const key = yield take(alertChannel); // will return either key1 or key2
 *
 * Remember to close the dialog!!!
 * yield put(closeDialog());
 *
 * (Event Channel is necessary to handle the button click callback.)
 *
 */
// TODO: this should be used in a TS file, comment out for future use
// {
//   title: string,
//   descriptions: string[],
//   collapsedDescriptions: string[],
//   inputs: [
//     {
//       key?: string,
//       props?: any,
//       type: string,
//     },
//   ],
//   buttons: [
//     {
//       key?: string,
//       label: string,
//     },
//   ],
//   id: string,
//   dialogType: string,
// }

export function* createAlertChannel({
  title,
  descriptions,
  collapsedDescriptions = [],
  inputs = [],
  buttons = [],
  id,
  dialogType,
  showCloseButton = false,
}) {
  let alert;
  const channel = eventChannel((emit) => {
    alert = {
      title,
      descriptions,
      collapsedDescriptions,
      inputs: inputs.map((input) => ({
        ...input,
        onChange: (e) => emit(e.target),
        onCheck: (value, checked) => emit({ value, checked }),
      })),
      buttons: buttons.map((button, index) => ({
        ...button,
        onClick: () => emit(button.key || index),
      })),
      id,
      dialogType,
      closeButton: {
        show: showCloseButton,
        onClick: () => emit(CANCEL_BUTTON_KEY),
      },
    };

    return () => {};
  });
  yield put(openAlertDialog(alert));

  return channel;
}

function* getCurrentAppName() {
  const currentAppId = yield select(selectAppId);
  const appsList = yield select(selectAppsById);
  const { name } = appsList[currentAppId];
  return name;
}

export function* getAppNameFromId(appId) {
  const appsList = yield select(selectAppsById);
  const { name } = appsList[appId];
  return name;
}

/**
 * Tells the user that the app failed to start and redirects the user to the home page
 * when the OK button is clicked on.
 */
export function* appFailedToStartAlert() {
  const alertChannel = yield createAlertChannel(createAppFailedToStartAlert('DataChat'));
  const keyChoice = yield take(alertChannel);
  yield put(closeDialog());
  if (keyChoice === OK_BUTTON_KEY) {
    yield put(replace(paths.index));
    yield put(tryExitSessionRequest());
    yield take(exitSessionSuccess.type);
  }
}

export function* createTaskFailedAlertChannel(error) {
  const alertChannel = yield* createAlertChannel(createTaskFailedAlert());
  const keyChoice = yield take(alertChannel);
  const sessionId = yield select(selectSession);
  if (keyChoice === OK_BUTTON_KEY) yield put(closeDialog());
  if (keyChoice === REPORT_BUTTON_KEY) {
    yield put(closeDialog());
    yield put(
      openContactForm({
        subject: CONTACT_FORM_DEFAULTS.SUBJECT_BUG_REPORT,
        content: '',
        messageType: CONTACT_FORM_DEFAULTS.SUBJECT_BUG_REPORT,
        hiddenDetails: `${CONTACT_FORM_ERROR_DETAILS(
          error,
          sessionId,
          window.location.pathname,
        )} \n\n Details: Task creation failed`,
      }),
    );
  }
}

export function* uploadConnectiontAlert() {
  const alertChannel = yield createAlertChannel(uploadConnectionFailedAlert());
  const keyChoice = yield take(alertChannel);
  if (keyChoice === OK_BUTTON_KEY) yield put(closeDialog());
}

export function* sessionConcurrencyLimitAlert() {
  // Refresh session list before opening the dialogue
  // so we can accurately tell users why they are having session conflicts
  yield put(getHomeScreenObjectsRequest({ objectType: HOME_OBJECTS.SESSION }));
  yield race([take(GET_HOME_SCREEN_OBJECTS_FAILURE), take(GET_HOME_SCREEN_OBJECTS_SUCCESS)]);
  const alertChannel = yield createAlertChannel(createAskConversationLimitAlert());
  yield take(alertChannel);
  yield put(closeDialog());
  // if deleting a snapshot, set the cancel the delete object request for all object types
  // this will be removed when delete endpoint for workspace objects is implemented
  yield put(
    deleteObjectFailure({
      object: { objectType: HOME_OBJECTS.ALL },
      error: 'Concurrent Session Limit Reached',
    }),
  );
}

export function* askConversationLimitAlert() {
  const alertChannel = yield createAlertChannel(createAskConversationLimitAlert());
  yield take(alertChannel);
  yield put(closeDialog());
}

export function* activeDashboardForbiddenAlert() {
  const alertChannel = yield createAlertChannel(createActiveDashboardSessionForbiddenAlert());
  yield take(alertChannel);
  yield put(closeDialog());
}

export function* reloadForbiddenAlert() {
  const alertChannel = yield createAlertChannel(createReloadSessionForbiddenAlert());
  yield take(alertChannel);
  yield put(closeDialog());
}

export function* sessionNameAlreadyExistsAlert() {
  const alertChannel = yield createAlertChannel(createSessionNameAlreadyExistsAlert());
  yield take(alertChannel);
  yield put(closeDialog());
}

// Create a confirmation alert for Ben's close button
export function* confirmForgetDatasetAlert(message, callback) {
  const alertChannel = yield createAlertChannel(createForgetDatasetAlert());
  const keyChoice = yield take(alertChannel);
  if (keyChoice === YES_BUTTON_KEY) {
    yield put(closeDialog());
    yield put(describeAndSendUtteranceRequest({ message, callback }));
  }
  if (keyChoice === CANCEL_BUTTON_KEY) {
    yield put(closeDialog());
  }
}

export function* reloadNotFoundAlert() {
  const alertChannel = yield createAlertChannel(createReloadSessionNotFoundAlert());
  yield take(alertChannel);
  yield put(closeDialog());
  yield put(push(paths.index));
}

export function* unauthenticatedAlert() {
  const alertChannel = yield createAlertChannel(createUnauthenticatedErrorAlert());
  const keyChoice = yield take(alertChannel);
  if (keyChoice === RETURN_TO_LOGIN_BUTTON_KEY) {
    yield put(closeDialog());
    yield put(logoutRequest());
  }
}

export function* switchAlert(nextSession, cancelAction, confirmAction) {
  // Extract all info they may be needed in the alert
  const currentAppName = yield getCurrentAppName();
  const currentSessionType = yield select(selectSessionType);
  // TODO: in case user starts a new tab, need to persist dash/session name.
  let currentSessionName = yield select(selectDashboardSessionName);
  if (currentSessionName === undefined) currentSessionName = 'Unknown';

  const targetAppName = yield getAppNameFromId(nextSession.appId);
  const targetSessionName = nextSession.dashboardName;

  // Build template based on transition type
  let template;
  if (nextSession.type === START_DASHBOARD && currentSessionType === SESSION_TYPES.DASHBOARD) {
    // dashboard -> dashboard
    template = createSwitchDashboardsAlert(
      currentSessionName,
      currentAppName,
      targetSessionName,
      targetAppName,
    );
  } else if (nextSession.type === START_DASHBOARD && currentSessionType === SESSION_TYPES.CHAT) {
    // chat -> dashboard
    template = createSwitchFromChatToDashboardAlert();
  } else if (
    nextSession.type === onAppClick.type &&
    currentSessionType === SESSION_TYPES.DASHBOARD
  ) {
    // dashboard -> chat
    template = createSwitchFromDashboardToChatAlert(
      currentSessionName,
      currentAppName,
      targetAppName,
    );
  } else if (nextSession.type === onAppClick.type && currentSessionType === SESSION_TYPES.CHAT) {
    // chat -> chat
    template = createSwitchAppsAlert(currentAppName, targetAppName);
  } else {
    // Unknown combination
    throw new Error(`Unknown session transition from ${currentSessionType} to ${nextSession.type}`);
  }

  // Build fallback url if user cancels the next session
  let cancelUrl;
  if (currentSessionType === SESSION_TYPES.CHAT) {
    const currentAppId = yield select(selectAppId);
    cancelUrl = `${paths.chat}/${currentAppId}`;
  } else {
    cancelUrl = paths.index;
  }

  // Fire alert
  const alertChannel = yield createAlertChannel(template);
  const keyChoice = yield take(alertChannel);
  yield put(closeDialog());
  if (keyChoice === YES_BUTTON_KEY) {
    // Continue to next session if not instructed differently
    if (confirmAction) yield* confirmAction();
    else yield put(nextSession);
  } else if (cancelAction) yield* cancelAction();
  else yield put(push(cancelUrl));
}

export function* browserTypeAlert() {
  const alertChannel = yield createAlertChannel(createBrowserTypeAlert());
  yield take(alertChannel);
  yield put(hasSeenBrowserCompatibilityWarning());
  yield put(closeDialog());
}

/**
 * When there is no connection to the internet,
 * allow the user to either reconnect or exit the session.
 *
 * @param {Redux Action} retryRequestAction A redux action to be dispatched again
 */
export function* networkErrorWorker() {
  yield put(showNetworkStatus());
}

/**
 * Handles authentication errors
 * Refreshes the token and dispatches the action again.
 * If the refresh request fails, log the user out.
 *
 * @param {Object} retryRequestAction An saga action
 */
export function* authenticationErrorWorker(retryRequestAction) {
  yield put(authenticationAlert({ retryRequestAction }));
}

// Handles non-existent pages
export function* pageNotFoundErrorWorker() {
  yield put(push(paths.unauthorized)); // Redirect to a safety page.
}

// Handles forbidden errors
export function* forbiddenErrorWorker() {
  const alertChannel = yield createAlertChannel(createForbiddenErrorAlert());
  // Changing the location makes this alert irrelevant
  const [, locationChange] = yield race([take(alertChannel), take(LOCATION_CHANGE)]);
  yield put(closeDialog());
  // If this dialog closed because the location changed, don't do clean up
  if (locationChange) return;
  yield put(replace(paths.index)); // Redirect the user to the home page.
}

// More specific version of the forbidden error worker
// Handles cases where the user has reached their limit for their subscription/role
// or does not have any access
// TODO: Make this point to the subscription page once the subscriptions are ready
export function* forbiddenRoleErrorWorker() {
  const alertChannel = yield createAlertChannel(createForbiddenRoleErrorAlert());
  yield take(alertChannel);
  yield put(closeDialog());
}

export function* fileUploadFailureWorker() {
  const alertChannel = yield createAlertChannel(uploadFileFailedAlert());
  yield take(alertChannel);
  yield put(closeDialog());
}

export function* fileStorageLimitReachedWorker(limit) {
  const alertChannel = yield createAlertChannel(createFileStorageLimitReachedAlert(limit));
  yield take(alertChannel);
  yield put(closeDialog());
}

// Handles most errors.
export function* generalErrorWorker(error) {
  // Show a pop up.
  const alertChannel = yield createAlertChannel(createGeneralErrorAlert(error));
  const keyChoice = yield take(alertChannel);
  // User picked OK. Close the pop up.
  if (keyChoice === OK_BUTTON_KEY) {
    yield put(closeDialog());
  }
  if (keyChoice === REPORT_BUTTON_KEY) {
    // Creating the hidden content
    const session = yield select(selectSession);
    const pathName = window.location.pathname;

    // Creating visible content
    const timeStamp = format(Date.now(), 'MMM d y K:mm a');

    const content = {
      subject: CONTACT_FORM_DEFAULTS.GENERAL_ERROR_SUBJECT,
      content: CONTACT_FORM_GENERAL_ERROR_MESSAGE_TEMPLATE(timeStamp, error.message),
      messageType: CONTACT_FORM_DEFAULTS.SUBJECT_BUG_REPORT,
      hiddenDetails: CONTACT_FORM_ERROR_DETAILS(error, session, pathName),
    };
    yield put(openContactForm(content));
    yield put(closeDialog());
  }
}

/**
 * Executes when there is a duplicate name being used for an insights board.
 * @param {409 resource conflict error} error
 */
export function* duplicateNameErrorWorker() {
  const alertChannel = yield createAlertChannel(createDuplicateNameErrorAlert());
  const keyChoice = yield take(alertChannel);
  // User picked OK. Close the pop up.
  if (keyChoice === OK_BUTTON_KEY) yield put(closeDialog());
}

/**
 * Executes when a user tries to upload a file name which contains invalid characters
 * or an invalid name (e.g. empty string). Invalid characters are non-ISO-8859-1 characters
 * @param {String} filename The name of the file that contains invalid characters
 */
export function* fileInvalidNameWorker(filename) {
  const alertChannel = yield createAlertChannel(createFileInvalidNameAlert(filename));
  const keyChoice = yield take(alertChannel);
  // User picked OK. Close the pop up.
  if (keyChoice === OK_BUTTON_KEY) yield put(closeDialog());
}

export function* fileInvalidWorker() {
  const alertChannel = yield createAlertChannel(createFileInvalidAlert());
  const keyChoice = yield take(alertChannel);
  // User picked OK. Close the pop up.
  if (keyChoice === OK_BUTTON_KEY) yield put(closeDialog());
}

export function* fileBadRequestWorker(error) {
  const errorMessage = error?.error?.data ?? FILE_BAD_STATUS_REQUEST_DESCRIPTION;
  const alertChannel = yield createAlertChannel(createFileBadStatusRequestAlert(errorMessage));
  const keyChoice = yield take(alertChannel);
  // User picked OK. Close the pop up.
  if (keyChoice === OK_BUTTON_KEY) yield put(closeDialog());
}

/**
 * Prompt user to overwrite an existing recipe.
 * @param {String} name - name of recipe to create
 */
export function* recipeAlreadyExistsWorker(name) {
  const alertChannel = yield createAlertChannel(createRecipeAlreadyExistsAlert(name));
  const key = yield take(alertChannel);
  yield put(closeDialog());

  if (key === YES_BUTTON_KEY) {
    // return overwrite flag
    return { overwrite: true };
  }
  // cancel recipe creation
  return { overwrite: false };
}

/**
 * @param {*} dataset
 * @returns { overwrites: [], removals: [], cancel: boolean }
 */
export function* datasetAlreadyExistWorker(dataset) {
  const alertChannel = yield createAlertChannel(createDatasetAlreadyExistAlert(dataset.Name));
  const key = yield take(alertChannel);
  yield put(closeDialog());

  if (key === YES_BUTTON_KEY) {
    // return overwrite list
    return { overwrites: [dataset], removals: [], cancel: false };
  }
  // cancel dataset creation operation
  return { overwrites: [], removals: [dataset], cancel: true };
}

/**
 * @param {*} dataset
 * @returns { overwrites: [], removals: [], cancel: boolean }
 */
export function* datasetsAlreadyExistWorker(datasets) {
  const alertChannel = yield createAlertChannel(createDatasetsAlreadyExistAlert(datasets));
  const datasetNames = new Map(datasets.map((ds) => [ds.Name, ds]));
  const checkStatuses = new Map(datasets.map((ds) => [ds.Name, false]));

  let key;

  while (true) {
    key = yield take(alertChannel);
    if (key === OVERWRITE_KEY || key === NO_BUTTON_KEY) {
      break;
    } else {
      checkStatuses.set(key.value, key.checked);
    }
  }
  yield put(closeDialog());

  // Tracks datasets to overwrite
  let overwrites = [];
  // Tracks datasets NOT to overwrite
  let removals = [];

  // Split the datasets up into overwrite datasets and datasets to note overwrite
  checkStatuses.forEach((v, k) => {
    if (v === true) {
      overwrites = [...overwrites, datasetNames.get(k)];
    } else {
      removals = [...removals, datasetNames.get(k)];
    }
  });

  // If cancel is selected, we specify to not overwrite any datasets
  if (key === NO_BUTTON_KEY) {
    return { overwrites: [], removals: [...removals, overwrites], cancel: true };
  }
  //
  return { overwrites, removals, cancel: false };
}

export function* fileAlreadyExistsWorker(
  filePond,
  file,
  fileUploadSuccess,
  fileUploadFailure,
  loadImmediatelyAlert,
) {
  const alertChannel = yield createAlertChannel(createFileAlreadyExistsAlert(file.filename));
  const key = yield take(alertChannel);
  yield put(closeDialog());

  if (key === YES_BUTTON_KEY) {
    // Instruct the BE to overwrite the session file by setting the
    // 'overwrite' condition to true in the file's metadata.
    file.setMetadata('overwrite', true);

    try {
      const { serverId } = yield filePond.processFile(file);
      const uploadService = yield getContext(API_SERVICES.UPLOAD);
      const accessToken = yield select(selectAccessToken);
      const response = yield call(uploadService.putFiles, accessToken, serverId);
      yield call(putFileResponseHandler, response.data);
      const uploadedDatasets = response.data
        .filter((datasetCreationStatus) => datasetCreationStatus?.error === '')
        .map((datasetCreationStatus) => {
          return {
            name: datasetCreationStatus.dataset_object_name,
            uuid: datasetCreationStatus.dataset_object_uuid,
          };
        });
      yield put(addUploadedDatasets(uploadedDatasets));
      yield put(fileUploadSuccess({ file: file.filename }));
      if (isChatPage() || isDataChatSessionPage()) {
        yield put(loadImmediatelyAlert(uploadedDatasets, [file.filename]));
      }
      yield put(
        getHomeScreenObjectsRequest({ objectType: HOME_OBJECTS.DATAFILE, refreshing: true }),
      );
      yield put(
        getHomeScreenObjectsRequest({ objectType: HOME_OBJECTS.DATASET, refreshing: true }),
      );
      yield put(getHomeScreenObjectsRequest({ objectType: HOME_OBJECTS.FOLDER, refreshing: true }));
    } catch (error) {
      yield put(fileUploadFailure({ file, error }));
      yield* fileUploadFailureWorker();
    }
  } else if (key === NO_BUTTON_KEY) {
    yield filePond.removeFile(file);
    yield put(
      fileUploadFailure({
        file,
        error: new Error(
          'Canceled file upload because user selected not to overwrite existing file.',
        ),
      }),
    );
  }
}

export function* filesAlreadyExistWorker(
  filePond,
  files,
  fileUploadSuccess,
  fileUploadFailure,
  loadImmediatelyAlert,
) {
  const alertChannel = yield createAlertChannel(createFilesAlreadyExistAlert(files));
  const fileNames = new Map(files.map((file) => [file.filename, file]));
  const checkStatuses = new Map(files.map((file) => [file.filename, false]));
  const cancelError = new Error(
    'Canceled file upload because user selected not to overwrite existing file.',
  );
  let key;

  while (true) {
    key = yield take(alertChannel);
    if (key === OVERWRITE_KEY || key === CANCEL_BUTTON_KEY) {
      break;
    } else {
      checkStatuses.set(key.value, key.checked);
    }
  }
  yield put(closeDialog());

  if (key === OVERWRITE_KEY) {
    // Overwrite Selected clicked, add only selected files
    let overwrites = [];
    let removals = [];

    // Split the files up into overwrite files and files to be removed
    checkStatuses.forEach((v, k) => {
      if (v === true) {
        // Set 'overwrite' in metadata to true to instruct the BE to overwrite the session file
        fileNames.get(k).setMetadata('overwrite', true);
        overwrites = [...overwrites, fileNames.get(k)];
      } else {
        removals = [...removals, fileNames.get(k)];
      }
    });

    for (const file of removals) {
      // Instruct FilePond to remove unselected files
      yield filePond.removeFile(file);
      // Dispatch actions notifying of successes and failures
      yield put(fileUploadFailure({ file, error: cancelError }));
    }

    if (overwrites.length <= 0) return;

    // Instruct FilePond to overwrites selected files
    const overwrittenFiles = yield filePond.processFiles(overwrites);
    // Dispatch actions notifying of successes and failures
    for (const file of overwrittenFiles) {
      const accessToken = yield select(selectAccessToken);
      const uploadService = yield getContext(API_SERVICES.UPLOAD);
      const response = yield call(uploadService.putFiles, accessToken, file.serverId);
      yield call(putFileResponseHandler, response.data);
      const uploadedDatasets = response.data
        .filter((datasetCreationStatus) => datasetCreationStatus?.error === '')
        .map((datasetCreationStatus) => {
          return {
            name: datasetCreationStatus.dataset_object_name,
            uuid: datasetCreationStatus.dataset_object_uuid,
          };
        });
      yield put(addUploadedDatasets(uploadedDatasets));
      yield put(fileUploadSuccess({ file: file.filename }));
      if (isChatPage() || isDataChatSessionPage()) {
        yield put(loadImmediatelyAlert(uploadedDatasets, [file.filename]));
      }
      yield put(
        getHomeScreenObjectsRequest({ objectType: HOME_OBJECTS.DATAFILE, refreshing: true }),
      );
      yield put(
        getHomeScreenObjectsRequest({ objectType: HOME_OBJECTS.DATASET, refreshing: true }),
      );
      yield put(getHomeScreenObjectsRequest({ objectType: HOME_OBJECTS.FOLDER, refreshing: true }));
    }
  } else if (key === CANCEL_BUTTON_KEY) {
    // Cancel button clicked, remove all conflicting files
    for (const file of files) {
      yield filePond.removeFile(file);
      yield put(fileUploadFailure({ file, error: cancelError }));
    }
  }
}

// If a condition is true, sends an alert and executes an action if user says yes to alert
// Useful for leaving a sensitive menu, such as the settings upload menu, or the dataset builder
export function* tryLeaveSensitiveMenuAlert(conditionFn, alertFn, yesActions) {
  if (conditionFn()) {
    const alertChannel = yield createAlertChannel(alertFn());
    const choice = yield take(alertChannel);

    if (choice === YES_BUTTON_KEY) {
      for (const yesAction of yesActions) {
        yield put(yesAction());
      }
    } else {
      // Do nothing.
    }
    yield put(closeDialog());
  } else {
    for (const yesAction of yesActions) {
      yield put(yesAction());
    }
  }
}

// Checks whether navigating away from the settings upload menu would cancel one or more uploads
export function* tryLeaveSettingsUploadMenuAlert(yesAction, yesActionArg) {
  const filesUploading = yield select(selectFilesRequesting);

  const conditionFn = () => filesUploading > 0;
  const alertFn = () => closeSettingsWhileUploadingAlert(filesUploading);
  const yesActionWithArg = [() => yesAction(yesActionArg)];

  yield* tryLeaveSensitiveMenuAlert(conditionFn, alertFn, yesActionWithArg);
}

export function* tryExitSessionAlert(isClosing, yesActions) {
  const conditionFn = () => false;
  const alertFn = () => {};
  yield* tryLeaveSensitiveMenuAlert(conditionFn, alertFn, yesActions);
}

// Filters through the status code responses.
export function* statusErrorFilter(error, status, retryRequestAction) {
  if (status === UNAUTHORIZED) {
    // User is not authenticated
    yield* authenticationErrorWorker(retryRequestAction);
  } else if (status === FORBIDDEN) {
    // User does not have access.
    yield* forbiddenErrorWorker();
  } else if (status === NOT_FOUND) {
    yield* pageNotFoundErrorWorker();
  } else {
    yield* generalErrorWorker(error);
  }
}

// Handles register errors
export function* registerErrorWorker(error, retryRequestAction) {
  if (isNetworkError(error)) {
    yield* networkErrorWorker(retryRequestAction);
  } else if (error instanceof PasswordMismatchError) {
    const alertChannel = yield createAlertChannel(
      createRegisterErrorAlert(PASSWORD_MISMATCH_TITLE, PASSWORD_MISMATCH_DESCRIPTION),
    );
    yield take(alertChannel);
    yield put(closeDialog());
  } else if (error.response) {
    const { status } = error.response;
    let title;
    let description;
    if (status === BAD_REQUEST) {
      if (error.response.data === 'name is too long') {
        title = NAME_TOO_LONG_ERROR_TITLE;
        description = NAME_TOO_LONG_ERROR_DESCRIPTION;
      } else if (error.response.data === 'name is too short') {
        title = NAME_TOO_SHORT_ERROR_TITLE;
        description = NAME_TOO_SHORT_ERROR_DESCRIPTION;
      } else {
        title = BAD_REQUEST_ERROR_TITLE;
        description = BAD_REQUEST_ERROR_DESCRIPTIONS;
      }
    } else if (status === FORBIDDEN) {
      if (error.response.data === 'email domain is not whitelisted') {
        title = DOMAIN_NOT_WHITELISTED_TITLE;
        description = DOMAIN_NOT_WHITELISTED_DESCRIPTION;
      } else if (error.response.data === 'password strength too weak') {
        title = PASSWORD_TOO_WEAK_TITLE;
        description = PASSWORD_TOO_WEAK_DESCRIPTION;
      } else if (error.response.data === 'this organization does not have any room for new users') {
        title = EXCEED_USER_LIMIT_TITLE;
        description = EXCEED_USER_LIMIT_DESCRIPTION;
      } else if (error.response.data === 'direct registration not permitted') {
        title = NO_USER_REGISTRATION_TITLE;
        description = NO_USER_REGISTRATION_DESCRIPTION;
      } else {
        title = FORBIDDEN_REGISTER_ERROR_TITLE;
        description = FORBIDDEN_REGISTER_ERROR_DESCRIPTION;
      }
    } else if (status === PAYMENT_REQUIRED) {
      title = MAX_USERS_REGISTER_ERROR_TITLE;
      description = MAX_USERS_REGISTER_ERROR_DESCRIPTION;
    } else {
      // Other status codes go here.
      yield* generalErrorWorker(error);
      return;
    }
    const alertChannel = yield createAlertChannel(createRegisterErrorAlert(title, description));
    yield take(alertChannel);
    yield put(closeDialog());
  } else yield* generalErrorWorker(error);
}

// Handles Reset Password errors
export function* resetPasswordErrorWorker(error, retryRequestAction) {
  if (isNetworkError(error)) {
    yield* networkErrorWorker(retryRequestAction);
  } else if (error instanceof PasswordMismatchError) {
    const alertChannel = yield createAlertChannel(
      createRegisterErrorAlert(PASSWORD_MISMATCH_TITLE, PASSWORD_MISMATCH_DESCRIPTION),
    );
    yield take(alertChannel);
    yield put(closeDialog());
  } else if (error.response) {
    const { status } = error.response;
    let title;
    let description;
    if (status === FORBIDDEN) {
      title = EMAIL_RESET_TOKEN_REQUEST_TITLE;
      description = EMAIL_RESET_TOKEN_REQUEST_DESCRIPTION;
    } else if (status === UNAUTHORIZED) {
      title = UNAUTHORIZED_RESET_ERROR_TITLE;
      description = UNAUTHORIZED_RESET_ERROR_DESCRIPTION;
    } else {
      // Other status codes go here.
      yield* generalErrorWorker(undefined);
      return;
    }
    const alertChannel = yield createAlertChannel(createRegisterErrorAlert(title, description));
    yield take(alertChannel);
    yield put(closeDialog());
  } else yield* generalErrorWorker(error);
}

// Handles Reset Password from the menu errors
export function* resetPasswordFromMenuErrorWorker(error, retryRequestAction) {
  if (isNetworkError(error)) {
    yield* networkErrorWorker(retryRequestAction);
  } else if (error instanceof PasswordMismatchError) {
    const alertChannel = yield createAlertChannel(
      createRegisterErrorAlert(PASSWORD_MISMATCH_TITLE, PASSWORD_MISMATCH_DESCRIPTION),
    );
    yield take(alertChannel);
    yield put(closeDialog());
  } else if (error.response) {
    const { status } = error.response;
    const errorMessage = error.response.data;
    let title;
    let description;
    if (status === FORBIDDEN) {
      title = EMAIL_RESET_TOKEN_REQUEST_TITLE;
      description = EMAIL_RESET_TOKEN_REQUEST_DESCRIPTION;
    } else if (status === UNAUTHORIZED) {
      if (errorMessage === 'incorrect current password') {
        title = INCORRECT_CURRENT_PASSWORD_ERROR_TITLE;
        description = INCORRECT_CURRENT_PASSWORD_ERROR_DESCRIPTION;
      } else {
        title = UNAUTHORIZED_RESET_ERROR_TITLE;
        description = UNAUTHORIZED_RESET_ERROR_DESCRIPTION;
      }
    } else {
      // Other status codes go here.
      yield* generalErrorWorker(undefined);
      return;
    }
    const alertChannel = yield createAlertChannel(createRegisterErrorAlert(title, description));
    yield take(alertChannel);
    yield put(closeDialog());
  } else yield* generalErrorWorker(error);
}

// Handles login errors
export function* loginErrorWorker(error, retryRequestAction) {
  if (isNetworkError(error)) {
    // User is not connected to the internet.
    yield* networkErrorWorker(retryRequestAction);
  } else if (error.response) {
    // User encountered an error with a status code from the server.
    const { status } = error.response;
    if (error.response.data === 'email is not verified') {
      const alertChannel = yield createAlertChannel(createUnverifiedAccountAlert());
      const keyChoice = yield take(alertChannel);
      yield put(closeDialog());

      if (keyChoice === RESEND_EMAIL_BUTTON_KEY) {
        const { email } = error.response.headers;
        yield* sendEmailVerifyRequestWorker({ email });
      }
    } else if (status === FORBIDDEN) {
      const alertChannel = yield createAlertChannel(
        createLoginErrorAlert(OTP_EXPIRED_ERROR_TITLE, OTP_EXPIRED_ERROR_DESCRIPTION),
      );
      yield take(alertChannel);
      yield put(closeDialog());
    } else if (status === UNAUTHORIZED) {
      // User received an unauthorized status code. Show a pop up.
      const alertChannel = yield createAlertChannel(
        createLoginErrorAlert(AUTHENTICATION_ERROR_TITLE, AUTHENTICATION_ERROR_DESCRIPTIONS),
      );
      yield take(alertChannel);
      yield put(closeDialog());
    } else {
      yield* generalErrorWorker(error);
    } // User encountered a general error.
  } else {
    yield* generalErrorWorker(error);
  } // User encountered a general error.
}

// Handles Sending Verification Email errors
export function* sendVerifyEmailErrorWorker(error, retryRequestAction) {
  const response = error.response.data;
  const params = new URLSearchParams(window.location.search);
  const redirectTo = params.get('redirect') || paths.login;
  const clientId = params.get('client_id');
  const scope = params.get('scope');
  const state = params.get('state');
  const redirectUri = params.get('redirect_uri');

  if (isNetworkError(error)) {
    yield* networkErrorWorker(retryRequestAction);
  } else if (response) {
    if (response === 'no user associated with email') {
      // extract email from response headers
      const { email } = error.response.headers;
      const description =
        `There doesn't seem to be an account associated with ${email}. ` +
        'Please verify that the email above is spelt correctly. ' +
        'If this problem persists, please contact info@datachat.ai ' +
        'Clicking OK will redirect you to the login page.';
      const alertChannel = yield createAlertChannel(createSendVerifyEmailFailureAlert(description));
      const keyChoice = yield take(alertChannel);
      // User clicked OK. Close the pop up.
      if (keyChoice === OK_BUTTON_KEY) yield put(closeDialog());

      // Redirect to login page
      yield put(
        push(
          `${redirectTo}?client_id=${clientId}&scope=${scope}&state=${state}&redirect_uri=${redirectUri}`,
        ),
      );
    } else if (response === 'user is already verified') {
      // extract email from response headers
      const { email } = error.response.headers;
      const description = `${email} is already verified. Clicking OK will redirect you to the login page.`;
      const alertChannel = yield createAlertChannel(createSendVerifyEmailFailureAlert(description));
      const keyChoice = yield take(alertChannel);
      // User clicked OK. Close the pop up.
      if (keyChoice === OK_BUTTON_KEY) yield put(closeDialog());
      yield put(
        push(
          `${redirectTo}?client_id=${clientId}&scope=${scope}&state=${state}&redirect_uri=${redirectUri}`,
        ),
      );
    } else {
      // some other error occurred
      yield* generalErrorWorker(error);
      yield put(
        push(
          `${redirectTo}?client_id=${clientId}&scope=${scope}&state=${state}&redirect_uri=${redirectUri}`,
        ),
      );
    }
  } else {
    // no response data (error not produced from management service)
    yield* generalErrorWorker(error);
    yield put(
      push(
        `${redirectTo}?client_id=${clientId}&scope=${scope}&state=${state}&redirect_uri=${redirectUri}`,
      ),
    );
  }
}

// Handles Account Verification errors
export function* verifyAccountErrorWorker(email, error, retryRequestAction) {
  const response = error.response.data;
  const params = new URLSearchParams(window.location.search);
  const redirectTo = params.get('redirect') || paths.login;
  const clientId = params.get('client_id');
  const scope = params.get('scope');
  const state = params.get('state');
  const redirectUri = params.get('redirect_uri');

  if (isNetworkError(error)) {
    yield* networkErrorWorker(retryRequestAction);
  } else if (response) {
    if (response === 'bad token') {
      const description =
        "Your supplied token doesn't exist in our database. " +
        'We can try to generate a new one and send it to you if you click Resend. ' +
        'Otherwise, clicking OK will take you back to the login screen.';
      const alertChannel = yield createAlertChannel(createVerifyAccountFailureAlert(description));
      const keyChoice = yield take(alertChannel);
      yield put(closeDialog());

      // User clicked OK. Close the pop up.
      if (keyChoice === OK_BUTTON_KEY) {
        yield put(
          push(
            `${redirectTo}?client_id=${clientId}&scope=${scope}&state=${state}&redirect_uri=${redirectUri}`,
          ),
        );
      } else {
        // User elected to resend email verification
        yield* sendEmailVerifyRequestWorker({ email });
      }
    } else if (response === 'token has expired') {
      const description =
        'Your supplied token has expired. ' +
        'We can try to generate a new one and send it to you if you click Resend. ' +
        'Otherwise, clicking OK will take you back to the login screen.';
      const alertChannel = yield createAlertChannel(createVerifyAccountFailureAlert(description));
      const keyChoice = yield take(alertChannel);
      yield put(closeDialog());

      // User clicked OK. Close the pop up.
      if (keyChoice === OK_BUTTON_KEY) {
        yield put(
          push(
            `${paths.login}?client_id=${clientId}&scope=${scope}&state=${state}&redirect_uri=${redirectUri}`,
          ),
        );
      } else {
        // User elected to resend email verification
        yield* sendEmailVerifyRequestWorker({ email });
      }
    } else if (response === 'good token bad email') {
      // NOTE: email exists in the database but the token belongs to another email
      const description =
        "Your supplied email isn't associated with your supplied token. " +
        'We can try to generate a new token and it to you if you click Resend. ' +
        'Otherwise, clicking OK will take you back to the login screen.';
      const alertChannel = yield createAlertChannel(createVerifyAccountFailureAlert(description));
      const keyChoice = yield take(alertChannel);
      yield put(closeDialog());

      // User clicked OK. Close the pop up.
      if (keyChoice === OK_BUTTON_KEY) {
        yield put(push(redirectTo));
      } else {
        // User elected to resend email verification
        yield* sendEmailVerifyRequestWorker({ email });
      }
    } else if (response === 'bad email') {
      // NOTE: email doesn't exist in the database
      const description =
        `There doesn't seem to be an account associated with ${email}. ` +
        'Please verify that the email above is spelt correctly. ' +
        'If this problem persists, please contact info@datachat.ai ' +
        'Clicking OK will redirect you to the login page.';
      const alertChannel = yield createAlertChannel(createSendVerifyEmailFailureAlert(description));
      const keyChoice = yield take(alertChannel);

      // User clicked Ok. Close the pop up
      yield put(closeDialog());
      if (keyChoice === OK_BUTTON_KEY) yield put(push(paths.login));
    } else if (response === "user's email is already verified") {
      const description =
        `It appears ${email} is already verified. ` +
        'Clicking OK will redirect you to the login page.';
      const alertChannel = yield createAlertChannel(createSendVerifyEmailFailureAlert(description));
      const keyChoice = yield take(alertChannel);

      // User clicked Ok. Close the pop up
      yield put(closeDialog());
      if (keyChoice === OK_BUTTON_KEY)
        yield put(
          push(
            `${redirectTo}?client_id=${clientId}&scope=${scope}&state=${state}&redirect_uri=${redirectUri}`,
          ),
        );
    } else {
      yield* generalErrorWorker(error);
      yield put(push(redirectTo));
    }
  } else {
    yield* generalErrorWorker(error);
    yield put(push(redirectTo));
  }
}

// worker for rename alert for db connection - if uuser renames a db conn,
// access will be removed for all users
export function* renameExistingDatabaseAlertWorker(callbackObj) {
  const alertChannel = yield createAlertChannel(renameExistingDatabaseAlert());
  const keyChoice = yield take(alertChannel);
  const { values, actions, newConName, handleOnSubmit } = callbackObj;
  if (keyChoice === YES_BUTTON_KEY && callbackObj) {
    yield handleOnSubmit(values, actions, newConName);
  }

  actions.setSubmitting(false);
  yield put(closeDialog());
}

export function* editExistingDatabaseAlertWorker(callbackObj) {
  const alertChannel = yield createAlertChannel(editExistingDatabaseAlert());
  const keyChoice = yield take(alertChannel);

  if (keyChoice === YES_BUTTON_KEY && callbackObj) {
    // calling from edit screen. Actions needed to finish form submission
    if (callbackObj.fields !== null) {
      const params = {};
      yield callbackObj.fields.forEach((field) => {
        // TODO: Temporary fix; databaseType is always the default value instead of being dynamic.
        if (field.id === 'databaseType') params.databaseType = field.value;
        else params[field.id] = callbackObj.values[field.id];
      });
      // put in connectionName since fields does not contain it
      yield (params.connectionName = callbackObj.values.connectionName);
      yield (params.readOnly = callbackObj.values.readOnly);
      yield callbackObj.postRequest(params, callbackObj.actions);
    } else {
      // calling clone from menu screen - no actions
      yield callbackObj.postRequest(callbackObj.values);
    }
  }

  // fire closing action
  yield put(closeDialog());
}

export function* objectAlreadyExistsAlert(type, name) {
  const alertChannel = yield createAlertChannel(renameObjectAlreadyExistsAlert(type, name));
  yield take(alertChannel);
  yield put(closeDialog());
}

/**
 * General error handling dialog for Ava errors.
 * Intended for critical errors that could prevent users from using Ava.
 * @param {String} title dialog title
 * @param {String} description dialog description
 * @param {Error} error (optional) error object
 */
export function* generalAvaErrorWorker(error, title) {
  const alertChannel = yield createAlertChannel(createGeneralAvaErrorAlert(title));
  const keyChoice = yield take(alertChannel);

  if (keyChoice === OK_BUTTON_KEY) {
    // Simply close the dialog
    yield put(closeDialog());
  } else if (keyChoice === REPORT_BUTTON_KEY) {
    // Close dialog and open contact form
    const session = yield select(selectSession);
    const pathName = window.location.pathname;
    const timeStamp = format(Date.now(), 'MMM d y K:mm a');
    const content = {
      subject: CONTACT_FORM_DEFAULTS.AVA_ERROR_SUBJECT,
      content: CONTACT_FORM_GENERAL_ERROR_MESSAGE_TEMPLATE(timeStamp, error.message),
      messageType: CONTACT_FORM_DEFAULTS.SUBJECT_BUG_REPORT,
      hiddenDetails: CONTACT_FORM_ERROR_DETAILS(error, session, pathName),
    };
    yield put(openContactForm(content));
    yield put(closeDialog());
  }
}

/**
 * General error handling dialog for cross filters.
 * Intended for critical errors that could prevent users from using cross filters.
 * @param {String} title dialog title
 * @param {String} description dialog description
 * @param {Error} error (optional) error object
 */
export function* generalInsightsBoardErrorWorker(error, title) {
  const alertChannel = yield createAlertChannel(createGeneralInsightsBoardErrorAlert(title));
  const keyChoice = yield take(alertChannel);

  if (keyChoice === OK_BUTTON_KEY) {
    // Just close the dialog
    yield put(closeDialog());
  } else if (keyChoice === REPORT_BUTTON_KEY) {
    const pathName = window.location.pathname;
    const timeStamp = format(Date.now(), 'MMM d y K:mm a');

    // Close the dialog and open the contact form
    yield put(
      openContactForm({
        subject: CONTACT_FORM_DEFAULTS.INSIGHTS_BOARD_ERROR_SUBJECT,
        content: CONTACT_FORM_GENERAL_ERROR_MESSAGE_TEMPLATE(timeStamp, error.message),
        messageType: CONTACT_FORM_DEFAULTS.SUBJECT_BUG_REPORT,
        hiddenDetails: CONTACT_FORM_ERROR_DETAILS(error, null, pathName),
      }),
    );
    yield put(closeDialog());
  }
}
