/**
 * This is the main navigation unit for the web application.
 * The routing logic between pages is done here.
 */
import debounce from 'lodash/debounce';
import React, { lazy, Suspense } from 'react';
import { isBrowser } from 'react-device-detect';
import { connect } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';
import { HistoryRouter as Router } from 'redux-first-history/rr6';

import PopOutModal from './components/PopOutModal/PopOutModal';
import { history } from './configureStore';
import { CONTEXTS, UPDATE_SCREEN_DIMENSIONS_DEBOUNCE_TIMING } from './constants';
import { DEV } from './constants/env';
import { isInsightsBoardPage, paths, UNAUTHENTICATED_PAGES } from './constants/paths';
import {
  TOAST_BOTTOM_LEFT,
  TOAST_BOTTOM_RIGHT,
  TOAST_TOP_LEFT,
  TOAST_TOP_MIDDLE,
} from './constants/toast';
import LoadingScreen from './pages/LoadingScreen';
import { updateScreenDimensions } from './store/actions/window.actions';
import './styles/App.scss';
import { datachatPageRegex, showHeader } from './utils/router_utils';

import { requireProductKey } from './constants/require_product_key';

import { hasDashboards, hasUploadFileManager } from './utils/userconfig_selector';

import ShareLinkDialog from './components/AskAva/Share/ShareLinkDialog';
import CalendlyPopup from './components/CalendlyPopup';
import DatachatCatalog from './components/common/DatachatCatalog/DatachatCatalog';
import DatasetCreator from './components/DatasetCreator/DatasetCreator';
import IntegrationsMenu from './components/IntegrationsMenu/IntegrationsMenu';
import ReAuthenticationDialog from './components/ReAuthenticationDialog/ReAuthenticationDialog';
import SimpleFileUpload from './components/SimpleFileUpload';
import UserSelectMenu from './components/UserSelectMenu';
import { DEPLOYMENT_MODE } from './constants/config';
import { configRequest } from './store/reducers/config.reducer';
import {
  selectCalendlyLink,
  selectCurrentLocation,
  selectFileModalOpen,
  selectShareLinkDialogOpen,
  selectShowContactForm,
  selectShowOfficeHoursMenu,
  selectShowUserSelectMenu,
} from './store/sagas/selectors';
import { selectIsCatalogOpen } from './store/selectors/catalog.selector';
import { selectDatabaseBrowserIsOpen } from './store/selectors/dbBrowser.selector';
import {
  selectReAuthenticationDialogOpen,
  selectShowIntegrationMenu,
} from './store/selectors/integrations.selector';
import { CSSVarsFromMUI } from './utils/themes';

const [
  Register,
  Login,
  OAuthConsent,
  EmailResetToken,
  ResetPassword,
  NewHome,
  ChatApp,
  AlertDialog,
  VerifyEmail,
  Embed,
  PrivateEmbed,
  DiscourseSSO,
  InsightsBoardPage,
  WorkflowEditorPage,
  CloudAuth,
  LinearProgress,
  Toast,
  InsightsBoardCreator,
  UploadFileManager,
  Snackbar,
  Snackbars,
  Header,
  Settings,
  NotificationViewer,
  NotFound,
  SelectSaveCard,
  ModelMenuDialog,
  ContactForm,
  DatabaseBrowser,
  DataChatPage,
  Organizations,
  Organization,
  Users,
  User,
  ApiKeys,
  Publications,
  AcceptInvitation,
  OAuthRedirect,
  SnowflakeLogin,
  ExternalOAuthClients,
] = [
  import('./pages/unauthenticated/Register'),
  import('./pages/unauthenticated/Login'),
  import('./pages/unauthenticated/OAuthConsent/OAuthConsent'),
  import('./pages/unauthenticated/EmailResetToken'),
  import('./pages/unauthenticated/ResetPassword'),
  import('./pages/authenticated/NewHome'),
  import('./pages/authenticated/ChatApp'),
  import('./components/AlertDialog/AlertDialog'),
  import('./pages/unauthenticated/VerifyEmail'),
  import('./pages/unauthenticated/Embed'),
  import('./pages/authenticated/PrivateEmbed'),
  import('./pages/unauthenticated/DiscourseSSO'),
  import('./pages/authenticated/InsightsBoardPage'),
  import('./pages/authenticated/WorkflowEditorPage'),
  import('./pages/authenticated/CloudAuth'),
  import('@mui/material/LinearProgress'),
  import('./components/common/Toast'),
  import('./components/InsightsBoardCreator'),
  import('./components/UploadFileManager'),
  import('./components/Snackbar'),
  import('./components/common/Snackbars'),
  import('./components/Header/Header'),
  import('./components/Settings'),
  import('./components/NotificationViewer'),
  import('./pages/NotFound'),
  import('./components/SelectSaveCard'),
  import('./components/ModelViewer/ModelMenuDialog'),
  import('./components/ContactForm/ContactForm'),
  import('./components/DatabaseBrowser/DatabaseBrowser'),
  import('./pages/authenticated/DataChatPage'),
  import('./pages/authenticated/AdminOrganizationsPage/Organizations'),
  import('./pages/authenticated/AdminOrganizationsPage/Organization'),
  import('./pages/authenticated/AdminUsersPage/Users'),
  import('./pages/authenticated/AdminUsersPage/User'),
  import('./pages/authenticated/AdminApiKeysPage'),
  import('./pages/authenticated/AdminPublicationsPage'),
  import('./pages/unauthenticated/AcceptInvitation'),
  import('./pages/authenticated/OAuthRedirect/OAuthRedirect'),
  import('./pages/unauthenticated/SnowflakeLogin'),
  import('./pages/authenticated/AdminEOAuthClientsPage/ExternalOAuthClients'),
].map((component) => lazy(() => component));

type RequireAuthProps = {
  children: React.Component,
  isAuthenticated: boolean,
};

const RequireAuth = ({ children, isAuthenticated }: RequireAuthProps) => {
  const location = useLocation();
  const isOAuthConsent = location.pathname === '/web/oauth/consent';

  return isAuthenticated && !isOAuthConsent ? children : <Login />;
};

type Props = {
  hasOpenedUploadFileManagerOnce: boolean,
  isAuthenticated: boolean,
  isDownloading: boolean,
  showNetworkStatus: boolean,
  showSettingsMenu: boolean,
  showPopOutModal: Boolean,
  showSlicedWorkflowPopout: Boolean,
  showContactForm: boolean,
  showSelectSaveCard: boolean,
  showUserSelectMenu: boolean,
  dialogs: [],
  configRequest: () => undefined,
  updateScreenDimensions: () => undefined,
  userConfig: () => mixed,
  toasts: [],
  showModelProfiler: boolean,
  context: String,
  deploymentMode: String,
  showLoader: Boolean,
  fileModalOpen: Boolean,
  showDBBrowser: Boolean,
  showIntegrationMenu: Boolean,
  showCatalog: Boolean,
  location: string,
  showOfficeHoursMenu: Boolean,
  calendlyLink: string,
  linkDialogOpen: Boolean,
  showReAuthenticationDialog: boolean,
};

/**
 * The App Component should simply choose between components
 * with as little implementation as possible
 */
class App extends React.Component<Props> {
  constructor(props) {
    super(props);
    window.DEV = DEV;

    // Store the debounced function in a class property
    this.debouncedUpdateScreenDimensions = debounce(() => {
      this.props.updateScreenDimensions({
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth,
      });
    }, UPDATE_SCREEN_DIMENSIONS_DEBOUNCE_TIMING);
  }

  componentDidMount = () => {
    window.addEventListener('unload', this.onUnload);
    window.addEventListener('resize', this.debouncedUpdateScreenDimensions);
    this.props.configRequest();
  };

  componentWillUnmount = () => {
    window.removeEventListener('unload', this.onUnload);
    window.removeEventListener('resize', this.debouncedUpdateScreenDimensions);
  };

  getRoutes = () => {
    const defaultUnauthenticatedRoutes = [
      <Route
        key={paths.snowflakeLogin}
        path={paths.snowflakeLogin}
        element={<SnowflakeLogin />}
        exact
      />,
      <Route key={paths.login} path={paths.login} exact element={<Login />} />,
      <Route key={paths.oauthConsent} path={paths.oauthConsent} exact element={<OAuthConsent />} />,
      ...[paths.register, paths.registerAsk].map((path) => (
        <Route
          key={path}
          path={path}
          exact
          element={<Register requireProductKey={requireProductKey} />}
        />
      )),
      <Route
        key={paths.emailResetToken}
        path={paths.emailResetToken}
        exact
        element={<EmailResetToken />}
      />,
      <Route key={paths.verifyEmail} path={paths.verifyEmail} exact element={<VerifyEmail />} />,
      <Route
        key={paths.resetPassword}
        path={paths.resetPassword}
        exact
        element={<ResetPassword />}
      />,
      <Route key={paths.embed} path={`${paths.embed}/:type/:id`} exact element={<Embed />} />,
      <Route key={paths.discourseSSO} path={paths.discourseSSO} exact element={<DiscourseSSO />} />,
      <Route
        key={paths.insightsBoardView}
        path={`${paths.insightsBoardView}/:id`}
        exact
        element={<InsightsBoardPage />}
      />,
      <Route
        key={paths.acceptInvitation(':token')}
        path={paths.acceptInvitation(':token')}
        exact
        element={<AcceptInvitation />}
      />,
      <Route key={paths.oauth} path={paths.oauth} exact element={<OAuthRedirect />} />,
    ];

    const defaultAuthenticatedRoutes = [
      <Route
        key={paths.index}
        path={paths.index}
        exact
        element={
          <RequireAuth isAuthenticated={this.props.isAuthenticated}>
            <LoadingScreen />
          </RequireAuth>
        }
      />,
      [paths.home, paths.homeScreenTabs].map((path) => (
        <Route
          key={path}
          path={path}
          exact
          element={
            <RequireAuth isAuthenticated={this.props.isAuthenticated}>
              <NewHome />
            </RequireAuth>
          }
        />
      )),
      <Route
        key={paths.chat}
        path={`${paths.chat}/:id`}
        exact
        element={
          <RequireAuth isAuthenticated={this.props.isAuthenticated}>
            <ChatApp />
          </RequireAuth>
        }
      />,
      <Route
        key={paths.dataChatSession}
        path={`${paths.dataChatSession}/:id`}
        exact
        element={
          <RequireAuth isAuthenticated={this.props.isAuthenticated}>
            <DataChatPage />
          </RequireAuth>
        }
      />,
      <Route
        key={paths.editor}
        path={paths.editorView(':objectType', ':objectId')}
        exact
        element={
          <RequireAuth isAuthenticated={this.props.isAuthenticated}>
            <WorkflowEditorPage />
          </RequireAuth>
        }
      />,
      <Route
        key={paths.resetPassword}
        path={paths.resetPassword}
        exact
        element={<ResetPassword />}
      />,
      <Route
        key={paths.cloudauth}
        path={paths.cloudauth}
        exact
        element={
          <RequireAuth isAuthenticated={this.props.isAuthenticated}>
            <CloudAuth />
          </RequireAuth>
        }
      />,
      <Route
        key={paths.privateEmbed}
        path={`${paths.privateEmbed}/:type/:id`}
        exact
        element={
          <RequireAuth isAuthenticated={this.props.isAuthenticated}>
            <PrivateEmbed />
          </RequireAuth>
        }
      />,
      <Route
        key={paths.organizations}
        path={paths.organizations}
        exact
        element={
          <RequireAuth isAuthenticated={this.props.isAuthenticated}>
            <Organizations />
          </RequireAuth>
        }
      />,
      <Route
        key={paths.organization(':organizationId')}
        path={paths.organization(':organizationId')}
        exact
        element={
          <RequireAuth isAuthenticated={this.props.isAuthenticated}>
            <Organization />
          </RequireAuth>
        }
      />,
      <Route
        key={paths.organizationUser(':organizationId', ':userId')}
        path={paths.organizationUser(':organizationId', ':userId')}
        exact
        element={
          <RequireAuth isAuthenticated={this.props.isAuthenticated}>
            <User />
          </RequireAuth>
        }
      />,
      <Route
        key={paths.users}
        path={paths.users}
        exact
        element={
          <RequireAuth isAuthenticated={this.props.isAuthenticated}>
            <Users />
          </RequireAuth>
        }
      />,
      <Route
        key={paths.user(':userId')}
        path={paths.user(':userId')}
        exact
        element={
          <RequireAuth isAuthenticated={this.props.isAuthenticated}>
            <User />
          </RequireAuth>
        }
      />,
      <Route
        key={paths.publications}
        path={paths.publications}
        exact
        element={
          <RequireAuth isAuthenticated={this.props.isAuthenticated}>
            <Publications />
          </RequireAuth>
        }
      />,
      <Route
        key={paths.apiKeys}
        path={paths.apiKeys}
        exact
        element={
          <RequireAuth isAuthenticated={this.props.isAuthenticated}>
            <ApiKeys />
          </RequireAuth>
        }
      />,
      <Route
        key={paths.oauthClients}
        path={paths.oauthClients}
        exact
        element={
          <RequireAuth isAuthenticated={this.props.isAuthenticated}>
            <ExternalOAuthClients />
          </RequireAuth>
        }
      />,
    ];

    const defaultFallbackRoutes = [
      <Route key={paths.unauthorized} path="*" element={<NotFound />} />,
    ];

    const defaultRoutes = [
      ...defaultUnauthenticatedRoutes,
      ...defaultAuthenticatedRoutes,
      ...defaultFallbackRoutes,
    ];

    switch (this.props.deploymentMode) {
      case DEPLOYMENT_MODE.AMI: {
        const excludedPaths = new Set([
          paths.register,
          paths.emailResetToken,
          paths.verifyEmail,
          paths.resetPassword,
          paths.embed,
          paths.discourseSSO,
          paths.editor,
          paths.cloudauth,
          paths.privateEmbed,
        ]);

        // Create routes with all defaultRoutes paths not in excludedPaths
        const routes = defaultRoutes.filter((route) => !excludedPaths.has(route.key));
        return routes;
      }
      default: {
        return defaultRoutes;
      }
    }
  };

  updateChatAppMode = () => {
    this.forceUpdate();
  };

  render() {
    const {
      isAuthenticated,
      isDownloading,
      showNetworkStatus,
      showSettingsMenu,
      showContactForm,
      showSelectSaveCard,
      showUserSelectMenu,
      toasts,
      context,
      showLoader,
      fileModalOpen,
      showDBBrowser,
      showIntegrationMenu,
      showCatalog,
      location,
      showOfficeHoursMenu,
      calendlyLink,
      linkDialogOpen,
      showReAuthenticationDialog,
    } = this.props;
    let appClassNames = 'App';
    if (isBrowser) {
      appClassNames += ' browser';
    } else {
      appClassNames += ' non-browser';
    }
    if (isDownloading) appClassNames += ' progress';

    // TODO: Turn anonymous prop functions to defined functions for optimization
    // See https://reactjs.org/docs/faq-functions.html#arrow-function-in-render
    return (
      <Router history={history}>
        <div className={appClassNames}>
          <Suspense fallback={<LoadingScreen />}>
            <CSSVarsFromMUI />
            {showHeader() &&
              location !== paths.ask &&
              !UNAUTHENTICATED_PAGES.includes(location) &&
              !datachatPageRegex.test(location) && (
                <Header className="header" updateChatAppMode={this.updateChatAppMode} />
              )}
            {((context !== CONTEXTS.REST && !isInsightsBoardPage()) || showLoader) && (
              <LinearProgress className="loading-linear" />
            )}
            {((this.props.showPopOutModal && !isInsightsBoardPage()) ||
              this.props.showSlicedWorkflowPopout) && <PopOutModal />}

            <div className="body">
              <Toast
                position={TOAST_BOTTOM_RIGHT}
                toasts={Object.keys(toasts)
                  .filter((id) => toasts[id]?.position === TOAST_BOTTOM_RIGHT)
                  .reduce((obj, id) => ({ ...obj, [id]: toasts[id] }), {})}
              />
              <Toast
                position={TOAST_TOP_LEFT}
                toasts={Object.keys(toasts)
                  .filter((id) => toasts[id]?.position === TOAST_TOP_LEFT)
                  .reduce((obj, id) => ({ ...obj, [id]: toasts[id] }), {})}
              />
              <Toast
                position={TOAST_TOP_MIDDLE}
                toasts={Object.keys(toasts)
                  .filter((id) => toasts[id]?.position === TOAST_TOP_MIDDLE)
                  .reduce((obj, id) => ({ ...obj, [id]: toasts[id] }), {})}
              />
              <Toast
                position={TOAST_BOTTOM_LEFT}
                toasts={Object.keys(toasts)
                  .filter((id) => toasts[id]?.position === TOAST_BOTTOM_LEFT)
                  .reduce((obj, id) => ({ ...obj, [id]: toasts[id] }), {})}
              />
              <Snackbars className="snackbars" />
              <div className="body-content">
                <Routes>{this.getRoutes()}</Routes>
              </div>
            </div>
            {this.props.dialogs.map((dialog) => (
              <AlertDialog dialog={dialog} key={dialog.id} />
            ))}
            {this.props.showModelProfiler && <ModelMenuDialog />}
            {isAuthenticated && <NotificationViewer />}
            {hasDashboards(this.props.userConfig) &&
              isAuthenticated &&
              this.props.userConfig.newInsightsBoardFlag && <InsightsBoardCreator />}
            {hasUploadFileManager(this.props.userConfig) &&
              this.props.hasOpenedUploadFileManagerOnce && <UploadFileManager />}
            {/* <EditPad /> */}
            {showContactForm && <ContactForm />}
            {fileModalOpen && <SimpleFileUpload />}
            {showSettingsMenu && <Settings />}
            {showUserSelectMenu && <UserSelectMenu />}
            {showNetworkStatus && <Snackbar content="Disconnected..." refreshButton />}
            {showSelectSaveCard && <SelectSaveCard />}
            {showDBBrowser && <DatabaseBrowser />}
            {showIntegrationMenu && <IntegrationsMenu />}
            {showCatalog && <DatachatCatalog />}
            {showOfficeHoursMenu && <CalendlyPopup supportCalendarLink={calendlyLink} />}
            {showReAuthenticationDialog && <ReAuthenticationDialog />}
            <ShareLinkDialog open={linkDialogOpen} />
            <DatasetCreator />
          </Suspense>
        </div>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isDownloading: state.download.isRequesting,
  dialogs: state.dialog,
  userConfig: state.settings.userConfig,
  hasOpenedUploadFileManagerOnce: state.settings.hasOpenedUploadFileManagerOnce,
  showNetworkStatus: state.settings.showNetworkStatus,
  showSettingsMenu: state.settings.showSettingsMenu,
  showUserSelectMenu: selectShowUserSelectMenu(state),
  showContactForm: selectShowContactForm(state),
  toasts: state.toast.toasts,
  showSelectSaveCard: state.settings.showSelectSaveCard,
  showModelProfiler: state.model.showModelProfiler,
  context: state.context.context,
  deploymentMode: state.config.deploymentMode,
  showPopOutModal: state.popout.isPopOutModalOpen,
  showSlicedWorkflowPopout: state.viewSlicedWorkflow.isSlicedWorkflowPopoutOpen,
  showLoader: state.context.showLoader,
  showCatalog: selectIsCatalogOpen(state),
  fileModalOpen: selectFileModalOpen(state),
  showDBBrowser: selectDatabaseBrowserIsOpen(state),
  showIntegrationMenu: selectShowIntegrationMenu(state),
  location: selectCurrentLocation(state),
  showOfficeHoursMenu: selectShowOfficeHoursMenu(state),
  calendlyLink: selectCalendlyLink(state),
  linkDialogOpen: selectShareLinkDialogOpen(state),
  showReAuthenticationDialog: selectReAuthenticationDialogOpen(state),
});

export default connect(mapStateToProps, {
  updateScreenDimensions,
  configRequest,
})(App);
