import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { isStatusRequesting, isStatusSuccess, isStatusUnrequested } from '../../utils/home_screen';
import TextWithTooltip from '../common/TextWithTooltip';
import './RefreshText.scss';

const getRelativeTime = (lastUpdated, status, failureText) => {
  let relativeTime = lastUpdated ? `Last Updated: ${moment(lastUpdated).fromNow()}` : '';
  if (isStatusRequesting(status)) {
    relativeTime = 'Loading...';
  } else if (isStatusUnrequested(status)) {
    relativeTime = '';
  } else if (!isStatusSuccess(status)) {
    relativeTime = failureText;
  }
  return relativeTime;
};

const RefreshText = ({ lastUpdated, status, failureText }) => {
  const [displayText, setDisplayText] = useState(getRelativeTime(lastUpdated, status, failureText));

  useEffect(() => {
    // Ensures that the text is updated when the status changes
    setDisplayText(getRelativeTime(lastUpdated, status, failureText));
  }, [lastUpdated, status, failureText]);

  useEffect(() => {
    // Update every minute
    const interval = setInterval(() => {
      setDisplayText(getRelativeTime(lastUpdated, status, failureText));
    }, 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastUpdated, status, failureText]);

  return <TextWithTooltip className="RefreshText" text={displayText} />;
};

RefreshText.propTypes = {
  lastUpdated: PropTypes.string,
  status: PropTypes.string,
  failureText: PropTypes.string,
};

RefreshText.defaultProps = {
  lastUpdated: undefined,
  status: undefined,
  failureText: 'Failed to fetch data',
};

export default RefreshText;
