import axios from 'axios';
import { DatasetAnnotation } from 'types/catalog.types';
import { endpoints } from '../constants/endpoints';
import {
  DEFAULT_COLUMN_ANNOTATION,
  DEFAULT_DATASET_ANNOTATION,
} from '../store/slices/catalog.slice';
import { stringToBase64 } from '../utils/encoding';

/**
 * Fetches the annotation for a dataset.
 * One of sessionId or workspaceUuid must be provided.
 *
 * @param id dc_dataset.id to get the annotation for
 * @param accessToken user's bearer token
 * @param sessionId optional session id (claims)
 * @param workspaceUuid optional workspace uuid (claims)
 * @returns Dataset's Annotation
 */
export const getAnnotation = (
  id: string,
  accessToken: string,
  { sessionId, workspaceUuid }: { sessionId?: string; workspaceUuid?: string },
) => {
  return axios.get<DatasetAnnotation>(endpoints.catalogDataset(id), {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      session: sessionId,
      object_id: workspaceUuid,
    },
  });
};

/**
 * Saves the annotation for a dataset.
 * One of sessionId or workspaceUuid must be provided.
 *
 * @param id dc_dataset.id to update the annotation for
 * @param annotation Annotation update to save.
 * @param accessToken User's bearer token.
 * @param sessionId optional session id (claims)
 * @param workspaceUuid optional workspace uuid (claims)
 * @returns Updated Dataset's Annotation
 */
export const putAnnotation = (
  id: string,
  annotation: Partial<DatasetAnnotation>,
  accessToken: string,
  { sessionId, workspaceUuid }: { sessionId?: string; workspaceUuid?: string },
) => {
  // clone the annotation to avoid modifying the original object
  annotation = structuredClone(annotation);

  // base64 encode the dataset's annotations since ModSec may block certain characters.

  // base64 encode the dataset annotation
  annotation.annotation = stringToBase64(
    annotation?.annotation ?? DEFAULT_DATASET_ANNOTATION.annotation,
  );

  // base64 encode the column annotations
  for (const [name, anno] of Object.entries(
    annotation?.columns ?? DEFAULT_DATASET_ANNOTATION.columns,
  ))
    if (annotation?.columns?.[name]) {
      annotation.columns[name].annotation = stringToBase64(
        anno.annotation ?? DEFAULT_COLUMN_ANNOTATION.annotation,
      );
    }

  // update the annotation
  return axios.put<DatasetAnnotation>(endpoints.catalogDataset(id), annotation, {
    headers: { Authorization: `Bearer ${accessToken}` },
    params: { session: sessionId, object_id: workspaceUuid },
  });
};
