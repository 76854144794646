import { DataGridPro, LicenseInfo, useGridApiRef } from '@mui/x-data-grid-pro';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import DataChatDataGridTheme from '../../../common/DataChatDataGridTheme/DataChatDataGridTheme';
import LoadingFailure from '../../../common/LoadingFailure';
// Redux
import {
  saveTableColumnOrder,
  saveTableFormatting,
} from '../../../../store/actions/tableObject.actions';
import {
  selectAllTableObjects,
  selectAutoSizeColumns,
  selectGroupedTableObjects,
  selectInsightsBoardName,
} from '../../../../store/sagas/selectors';
import { selectSession } from '../../../../store/selectors/session.selector';

// Constants
import { EMPTY_COLUMN, INDEX_COLUMN } from '../../../../pages/authenticated/constants';
import { getValueFormatter } from '../../../../utils/formatting/column_formatting';
import { VALUE_FORMATTERS } from '../../../../utils/formatting/constants';
import { isNumeric } from '../../../../utils/formatting/type';
import {
  COLUMN_HEADER_HEIGHT,
  DEFAULT_FIXED_WIDTH,
  DEFAULT_TABLE_EXPANSION_LIMIT,
  ESTIMATED_ROW_HEIGHT,
  INSIGHTS_TABLE_EXPANSION_LIMIT,
  PAGINATION_WIDTH,
  TABBED_TABLE_EXPANSION_LIMIT,
  calculateTableDimensions,
} from './constants';

// Components
import CellContents from './CellContents';
import ColumnHeader from './ColumnHeader';
import TableColumnOptionMenu from './TableColumnOptionMenu';
import TablePaginationActions from './TablePaginationActions';

// Utility
import { SessionDatasetClaim } from '../../../../types/claim.type';
import LoadingComponent from '../../../LoadingComponent/LoadingComponent';
import usePipelinerDatasetDescription from '../../../usePipelinerDatasetDescription';
import { useExpandedWidths } from './tableHooks';
import { calculatePageSizeOptions, getHiddenColsFromModel } from './utils';

// Remove the watermark and console warnings
LicenseInfo.setLicenseKey(
  'ab2b581e657eecaaf30f46ad01dba034T1JERVI6NDA3MTksRVhQSVJZPTE2ODAxOTk0NzAwMDAsS0VZVkVSU0lPTj0x',
);

const useTableDimensions = (
  objectId,
  totalRowCount,
  messageData,
  expansionLimit,
  isInsightsBoard,
) => {
  const tableObject = useSelector(selectGroupedTableObjects);
  const tableState = tableObject ? tableObject[objectId] : {};
  // TODO: change with totalColumnCount when we update the message context data
  const COLUMN_ESTIMATE_PLACEHOLDER = 10;
  const columnLength = messageData?.columns?.length || COLUMN_ESTIMATE_PLACEHOLDER;
  const [height, width] = calculateTableDimensions(
    totalRowCount,
    tableState,
    columnLength,
    expansionLimit,
  );
  return [height, isInsightsBoard ? '100%' : width];
};

/**
 * This is customized Mui DataGridPro component to render tables in Notebook mode,
 * Right hand side of Grid mode, and insights boards.
 *
 * @param {*} props
 * @returns Table Component
 */
const Table = (props) => {
  const {
    id,
    data,
    sessionId,
    tableObject,
    insightsBoardName,
    autoSizeColumns,
    isLoading,
    isFailed,
    isInsightsBoard,
    isTabVisual,
    rowCount,
    tableExpansionLimit,
    pageSizeOptions,
    noRowsText,
    updateRowCount,
    showColumnAnnotations,
    disableCellKeyActions,
    disableColumnMenu,
    getColumnHeaderEndAdornment,
    pipelinerDatasetId,
  } = props;

  const apiRef = useGridApiRef();

  const description = usePipelinerDatasetDescription(
    pipelinerDatasetId,
    new SessionDatasetClaim(sessionId),
    { totalRowCount: true },
  );
  const totalRowCount =
    data?.totalRowCount ?? (description.kind === 'data' ? description.data.totalRowCount : 0);

  const expansionLimit =
    tableExpansionLimit ?? isInsightsBoard
      ? INSIGHTS_TABLE_EXPANSION_LIMIT
      : isTabVisual
      ? TABBED_TABLE_EXPANSION_LIMIT
      : DEFAULT_TABLE_EXPANSION_LIMIT;

  const [height, defaultLoadingWidth] = useTableDimensions(
    id,
    totalRowCount,
    data,
    expansionLimit,
    isInsightsBoard,
  );

  const colNames = useMemo(() => {
    const sourceColumns = data?.columns || data?.schema?.fields || [];
    return sourceColumns.map((field) => field.name);
  }, [data?.columns, data?.schema?.fields]);

  // create a stable reference of rows
  const rows = useMemo(() => {
    const source = data?.rows || data?.data || [];
    // Check that our data is in the correct format before adding the index column
    if (Array.isArray(source) && !Array.isArray(source[0])) {
      const tableRows = source.map((row, idx) => ({ [INDEX_COLUMN]: idx + 1, ...row }));
      // if showStat is true, add a row with id = 0 to reserve the space for the column stat
      if (showColumnAnnotations && tableRows[0]?.id !== 0) {
        tableRows.unshift({ [INDEX_COLUMN]: 0, name: '_column_annotation' });
      }
      return tableRows;
    }
    return [];
  }, [data.rows, data.data, showColumnAnnotations]);

  // Calculated Vars
  const initNumRowsShown =
    rowCount === null || rowCount >= expansionLimit ? expansionLimit : rowCount;
  const initColumnVisibilityModel = { [EMPTY_COLUMN]: false };
  const tableId = id || `${data.name}_${data.version}`;
  const parentId = insightsBoardName || sessionId;
  const hasPagination = totalRowCount > expansionLimit;

  // Stateful Vars
  const [valueFormatterMap, setValueFormatterMap] = useState({});
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(initColumnVisibilityModel);
  const [totalWidth, setTotalWidth] = useState(DEFAULT_FIXED_WIDTH * (colNames.length || 1));

  useEffect(() => {
    // Workaround to fix the issue where pageIndex is controlled
    // https://github.com/mui/mui-x/issues/4674
    if (!apiRef?.current) return;
    // The disable the scrollToIndexes `.
    // eslint-disable-next-line
    apiRef.current.scrollToIndexes = (params, firstCall = true) => {
      if (apiRef?.current?.windowRef && firstCall) {
        apiRef.current.scrollToIndexes(params, false);
      }
    };
  }, [apiRef]);
  const initPageSize =
    tableObject?.parentMap?.[parentId]?.[id]?.columnsState?.pagination.pageSize || initNumRowsShown;
  const [pageSize, setPageSize] = useState(initPageSize);
  const [pageNumber, setPageNumber] = useState(0);

  // Pagination State Vars and functions to save the pagination state to redux
  const savePagination = (pagination) => {
    if (!parentId) return;
    const stateToSave = apiRef.current?.exportState();
    const state = { ...stateToSave, pagination };
    stateToSave.pagination = pagination;
    props.saveTableColumnOrder(parentId, tableId, state);
  };

  useEffect(() => {
    savePagination({ page: pageNumber, pageSize });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, pageNumber]);

  /**
   * Call to fetch data when we update the page size or page number
   * @param {Number} newPageNumber The new page number
   * @param {Number} newPageSize The new number of rows displayed per page
   * @returns {void} Calls updateRowCount to display more data
   */
  const fetchDataWrapper = ({ newPageNumber = pageNumber, newPageSize = pageSize }) => {
    // The number of rows required for the newPageNumber & newPageSize
    const numRowsRequired = Math.min((newPageNumber + 1) * newPageSize, totalRowCount);
    const needMoreRows = rowCount < numRowsRequired;

    if (needMoreRows && !isFailed && !isLoading) {
      const numRowsToFetch = Math.max(rowCount + 100, (newPageNumber + 1) * newPageSize);
      updateRowCount(Math.min(totalRowCount, numRowsToFetch));
    }
  };

  const handlePageSizeChange = (newPageSize) => {
    fetchDataWrapper({ newPageSize });
    setPageSize(newPageSize);
  };

  const handlePageChange = (newPageNumber) => {
    fetchDataWrapper({ newPageNumber });
    setPageNumber(newPageNumber);
  };

  // This updates the valueformatter map in the redux store
  useEffect(() => {
    if (parentId && Object.keys(valueFormatterMap).length !== 0) {
      props.saveTableFormatting(parentId, tableId, valueFormatterMap);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableId, valueFormatterMap, parentId]);

  // Restore the previous value formatter map
  useEffect(() => {
    // Get the previous value formatter
    const tableObjectValueFormatter =
      tableObject?.parentMap?.[parentId]?.[tableId]?.valueFormatterMap;
    if (tableObjectValueFormatter && Object.keys(tableObjectValueFormatter).length !== 0) {
      setValueFormatterMap(tableObjectValueFormatter);
    }
  }, [tableObject, tableId, parentId]);

  useEffect(() => {
    // Get the previous column visibility model
    const savedColumnVisibilityModel =
      tableObject?.parentMap?.[parentId]?.[tableId]?.columnsState?.columns?.columnVisibilityModel;
    if (savedColumnVisibilityModel) {
      setColumnVisibilityModel(savedColumnVisibilityModel);
      setHiddenColumns(getHiddenColsFromModel(savedColumnVisibilityModel));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const expandedWidths = useExpandedWidths(
    data,
    valueFormatterMap,
    pageNumber * pageSize,
    (pageNumber + 1) * pageSize,
    true,
    39,
  );

  // create a stable reference of columns
  const columns = useMemo(() => {
    const sourceColumns = data?.columns || data?.schema?.fields || [];
    if (sourceColumns.length > 0) {
      const columnDimensions =
        tableObject?.parentMap?.[parentId]?.[tableId]?.columnsState?.columns?.dimensions;
      const columnList = sourceColumns.map((field, index) => {
        const savedColumnWidth = columnDimensions?.[field.name]?.width;
        const doubleClickHandler = () => {
          apiRef.current.setColumnWidth(field.name, expandedWidths?.[field.name]);
        };
        return {
          field: field.name,
          headerName: field.name,
          type: isNumeric(field.type) ? 'number' : field.type.toLowerCase(),
          renderHeader: () => {
            return (
              <ColumnHeader
                getEndAdornment={getColumnHeaderEndAdornment}
                tableId={tableId}
                columnText={colNames[index]}
                onDoubleClick={doubleClickHandler}
              />
            );
          },
          headerAlign: 'left',
          // Render's Table and JSON objects
          renderCell: (params) => (
            <CellContents params={params} showColumnAnnotation={showColumnAnnotations} />
          ),
          cellClassName: `data-spreadsheet--cell${isNumeric(field.type) ? ' numeric' : ''}`,
          headerClassName: 'data-spreadsheet--header',
          // Disable sorting except in the Column column of Describe output
          sortable: field.name === 'Column',
          width:
            savedColumnWidth ||
            (autoSizeColumns
              ? expandedWidths[field.name] || DEFAULT_FIXED_WIDTH
              : DEFAULT_FIXED_WIDTH),
          valueFormatter: getValueFormatter(
            field,
            'none',
            valueFormatterMap[field.name] === VALUE_FORMATTERS.SCIENTIFIC,
          ),
        };
      });
      if (columnList?.length > 0) {
        columnList.push({
          field: EMPTY_COLUMN,
          headerName: EMPTY_COLUMN,
          type: 'string',
          renderHeader: () => <div />,
          headerClassName: 'data-spreadsheet--header',
          cellClassName: 'data-spreadsheet--cell',
        });
      }
      return columnList;
    }
    return [];
  }, [
    tableObject?.parentMap,
    parentId,
    tableId,
    data?.columns,
    data?.schema?.fields,
    colNames,
    valueFormatterMap,
    autoSizeColumns,
    expandedWidths,
    apiRef,
    showColumnAnnotations,
    getColumnHeaderEndAdornment,
  ]);

  // // Restores the state of the datagrid component, preserving column order and column visibility between renders
  useEffect(() => {
    const tableObjectGridState = tableObject?.parentMap?.[parentId]?.[tableId]?.columnsState;

    if (tableObjectGridState && Object.keys(tableObjectGridState).length !== 0) {
      const { columns: columnsState } = tableObjectGridState;

      // const persistedColumnVisibilityModel = columnsState?.columnVisibilityModel;
      const persistedColumnOrder = columnsState?.orderedFields;
      const persistedWidths = columnsState?.dimensions;

      apiRef.current.restoreState({
        columns: {
          columnVisibilityModel,
          orderedFields: persistedColumnOrder,
          dimensions: persistedWidths,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  const saveColumnChanges = () => {
    if (!parentId) return;
    const stateToSave = apiRef.current.exportState();
    stateToSave.columns = { ...stateToSave.columns, columnVisibilityModel };
    props.saveTableColumnOrder(parentId, tableId, stateToSave);
  };

  useEffect(() => {
    const columnDimensions =
      tableObject?.parentMap?.[parentId]?.[tableId]?.columnsState?.columns?.dimensions;
    if (!columnDimensions && expandedWidths.length > 0) saveColumnChanges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandedWidths, tableObject, parentId, tableId]);

  useEffect(() => {
    saveColumnChanges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnVisibilityModel]);

  const updateTableWidth = useCallback(() => {
    const tableWidth = apiRef?.current
      ?.getVisibleColumns()
      .reduce((acc, col) => acc + col.width, 0);
    // Set the table width to the max of the table width or the pagination width
    // Note: the additional 2 is to account for the border of the internal table
    setTotalWidth(Math.max(tableWidth + 2, hasPagination ? PAGINATION_WIDTH : 0));
  }, [apiRef, hasPagination]);

  useEffect(() => {
    updateTableWidth();
  }, [updateTableWidth, hiddenColumns, columns, apiRef]);

  const handleWidthChange = () => {
    saveColumnChanges();
    updateTableWidth();
  };

  const onColumnVisibilityModelChange = (model) => {
    if (model) {
      // Creates list of hidden columns from MUIs column visibility model
      const hiddenCols = getHiddenColsFromModel(model);

      // Display an empty column when all columns are hidden
      if (!hiddenCols[EMPTY_COLUMN] && hiddenCols.length === columns.length) {
        model[EMPTY_COLUMN] = true;
      } else {
        model[EMPTY_COLUMN] = false;
      }
      setHiddenColumns(hiddenCols);
      setColumnVisibilityModel(model);
    }
  };

  // Handle fetching more data
  useEffect(() => {
    // Prevents page size from decreasing when
    // Only set the new page size if the pagination state stored in the redux is not the default value
    if (pageSize < expansionLimit || rowCount < expansionLimit) {
      const newPageSize = rowCount > expansionLimit ? expansionLimit : rowCount;
      const newPageNumber = 0;
      setPageSize(newPageSize);
      setPageNumber(newPageNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowCount, expansionLimit]);

  useEffect(() => {
    // Retrieve the pagination info on-mount of the table
    const paginations = tableObject?.parentMap?.[parentId]?.[tableId]?.columnsState?.pagination;
    let newPageSize = pageSize;
    let newPageNumber = pageNumber;
    if (paginations?.pageSize) {
      newPageSize = paginations.pageSize;
      newPageNumber = paginations.page;
    }
    // Handle the case the redux store has outdated pagination values
    if (Number(totalRowCount) >= expansionLimit && newPageSize < expansionLimit) {
      if (newPageSize < expansionLimit || rowCount < expansionLimit) {
        newPageSize = rowCount > expansionLimit ? expansionLimit : rowCount;
      }
    }
    setPageSize(newPageSize);
    // todo persist page number when the package bug is fixed
    setPageNumber(newPageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPageSize(initPageSize);
  }, [initPageSize]);

  // Determine list of page size options
  const rowsPerPageOptions = pageSizeOptions || calculatePageSizeOptions(pageSize, totalRowCount);
  const numDisplayedRows = !rowCount ? 0 : Math.min(rowCount - pageSize * pageNumber, pageSize);
  const enableAutoHeight = numDisplayedRows === pageSize;

  const labelDisplayedRows = ({ from, to, count }) => {
    const countStr = count != null ? count.toLocaleString() : 0;
    return `${from}–${to} of ${countStr}`;
  };

  return (
    <div
      className="Table-Container"
      style={{
        width: columns?.length > 0 || !defaultLoadingWidth ? totalWidth : defaultLoadingWidth,
      }}
    >
      <div
        className="Table-Resize-Container"
        style={{
          overflowY: 'hidden',
          minHeight: height,
          width: '100%',
          minWidth: hasPagination ? PAGINATION_WIDTH : 0,
        }}
      >
        <DataChatDataGridTheme height={enableAutoHeight ? undefined : height}>
          <DataGridPro
            apiRef={apiRef}
            initialState={{ columns: { columnVisibilityModel: initColumnVisibilityModel } }}
            columns={columns}
            rows={rows}
            rowCount={totalRowCount || rowCount}
            rowHeight={ESTIMATED_ROW_HEIGHT}
            getRowId={(row) => row[INDEX_COLUMN]}
            headerHeight={COLUMN_HEADER_HEIGHT}
            disableSelectionOnClick
            pageSize={pageSize}
            page={pageNumber}
            pinnedRows={showColumnAnnotations ? { top: [{ [INDEX_COLUMN]: 0 }] } : { top: [] }}
            experimentalFeatures={{ rowPinning: true }}
            onPageSizeChange={handlePageSizeChange}
            onPageChange={handlePageChange}
            onColumnOrderChange={saveColumnChanges}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={onColumnVisibilityModelChange}
            rowsPerPageOptions={rowsPerPageOptions}
            pagination
            loading={isLoading}
            hideFooter={!hasPagination}
            autoHeight={enableAutoHeight}
            getRowHeight={() => 'auto'}
            getEstimatedRowHeight={() => ESTIMATED_ROW_HEIGHT}
            onColumnWidthChange={handleWidthChange}
            components={{
              ColumnMenu: TableColumnOptionMenu,
              NoRowsOverlay: () => <LoadingFailure overRideWithText={noRowsText} />,
              LoadingOverlay: LoadingComponent,
            }}
            disableColumnMenu={disableColumnMenu}
            componentsProps={{
              pagination: {
                labelDisplayedRows,
                ActionsComponent: TablePaginationActions,
              },
              columnMenu: {
                valueFormatterMap,
                setValueFormatterMap,
                data,
                totalRowCount,
              },
            }}
            onCellKeyDown={(_, e) => {
              if (disableCellKeyActions) {
                e.stopPropagation();
              }
            }}
          />
        </DataChatDataGridTheme>
      </div>
    </div>
  );
};

Table.propTypes = {
  // From Parent Component
  id: PropTypes.string, // ObjectID or <dataset name>_<dataset version>
  data: PropTypes.object.isRequired,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  isLoading: PropTypes.bool,
  isInsightsBoard: PropTypes.bool,
  isTabVisual: PropTypes.bool,
  tableExpansionLimit: PropTypes.number,
  isFailed: PropTypes.bool,
  noRowsText: PropTypes.string,
  rowCount: PropTypes.number,
  pipelinerDatasetId: PropTypes.string,
  updateRowCount: PropTypes.func,
  showColumnAnnotations: PropTypes.bool,
  disableCellKeyActions: PropTypes.bool,
  disableColumnMenu: PropTypes.bool,
  getColumnHeaderEndAdornment: PropTypes.func,
  // From Redux Store
  autoSizeColumns: PropTypes.bool.isRequired,
  tableObject: PropTypes.object,
  sessionId: PropTypes.string,
  insightsBoardName: PropTypes.string,
  // Reducer from tableObject.reducer.js
  saveTableFormatting: PropTypes.func.isRequired,
  saveTableColumnOrder: PropTypes.func.isRequired,
};

Table.defaultProps = {
  tableObject: {},
  sessionId: '',
  insightsBoardName: '',
  id: '',
  isLoading: false,
  isFailed: false,
  isTabVisual: false,
  isInsightsBoard: false,
  tableExpansionLimit: undefined,
  pageSizeOptions: undefined,
  noRowsText: 'No rows',
  showColumnAnnotations: false,
  disableColumnMenu: false,
  disableCellKeyActions: false,
  rowCount: null,
  pipelinerDatasetId: '',
  getColumnHeaderEndAdornment: () => null,
  updateRowCount: () => {},
};

const mapStateToProps = (state) => ({
  tableObject: selectAllTableObjects(state),
  sessionId: selectSession(state),
  insightsBoardName: selectInsightsBoardName(state),
  autoSizeColumns: selectAutoSizeColumns(state),
});

export default connect(mapStateToProps, {
  saveTableFormatting,
  saveTableColumnOrder,
})(Table);
