/**
 * Converts a string to a Uint8Array.
 * @param str string to encode
 * @returns Uint8Array of the encoded string
 */
export const stringToBytes = (str: string) => new TextEncoder().encode(str);

/**
 * Converts a Uint8Array to a base64 string.
 * @param bytes Uint8Array to convert to base64
 * @returns Base64 encoded string
 */
export const bytesToBase64 = (bytes: Uint8Array<ArrayBufferLike>) =>
  btoa(Array.from(bytes, (byte) => String.fromCodePoint(byte)).join(''));

/**
 * Converts a string to a base64 string.
 * @param str string to encode
 * @returns Base64 encoded string
 */
export const stringToBase64 = (str: string) => {
  const bytes = stringToBytes(str);
  return bytesToBase64(bytes);
};
