import moment from 'moment';
// Increasing Dimensions
// e.g. milliseconds to seconds, seconds to minutes, and so on.
// Unit Functions
export const millisecondsToSeconds = (ms) => ms / 1000;
export const secondsToMinutes = (s) => s / 60;

// Compound Functions
export const millisecondsToMinutes = (ms) => {
  const seconds = millisecondsToSeconds(ms);
  const minutes = secondsToMinutes(seconds);
  return minutes;
};

// Decreasing Dimensions
// e.g. days to hours, hours to minutes, and so on.
// Unit Functions
export const minutesToSeconds = (m) => m * 60;
export const secondsToMilliseconds = (s) => s * 1000;

// Compound Functions
export const minutesToMilliseconds = (m) => {
  const seconds = minutesToSeconds(m);
  const milliseconds = secondsToMilliseconds(seconds);
  return milliseconds;
};

// The current time in milliseconds.
export const getCurrentTime = () => new Date().getTime();

// Convert UTC time to a User Readable Format
export const convertToReadableFormat = (time) => moment(time).toLocaleString();

// A cleaner format for FileManger
const calendarFormat = Object.freeze({
  sameElse: 'Do MMM YYYY [at] hh:mm A',
});

/**
 * Convert utc time to local calendar date
 */
export const convertToCalenderDate = (time) =>
  time ? moment.utc(time).local().calendar(null, calendarFormat) : null;

export const convertToTimeString = (utcTime) => moment(utcTime).toString();

/**
 * Gets a formatted version of the date
 */
export const getFormattedTime = (dateString) =>
  dateString ? convertToCalenderDate(dateString) : 'Not available';
