import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { ReactComponent as EditIcon } from '../../../../../assets/icons/edit_mode.svg';
import { isWorkflowEditorPage } from '../../../../../constants/paths';
import { showReplayDisableToast } from '../../../../../constants/toast';
import { addToast } from '../../../../../store/actions/toast.actions';
import { selectCurrentLocation } from '../../../../../store/sagas/selectors';
import { selectIsStepwise } from '../../../../../store/selectors/context.selector';
import { EDIT_MODES } from '../../../constants';
import { isVisualEditable } from '../../helpers/utils';

// TODO: this needs furthur refactor
/**
 * A functional component to render buttons for starting or stopping the edit mode of a plot
 */
const ChartEditButton = (props) => {
  // redux dispatch hook
  const dispatch = useDispatch();

  const {
    chartEditingMode,
    isLoading,
    data,
    editAnnotation,
    isInsightsBoard,
    isInsightsBoardCreator,
    isReplaying,
    isStepwise,
    isWorkflowEditor,
    messageType,
    messageTypeVersion,
    toggleChartEditingMode,
    toggleIBFontMenu,
    isPopoutEditable,
  } = props;

  // handle click to edit the chart
  const onEditClickHandler = () => {
    // show toast during not stepwise replaying
    if (isReplaying && !isStepwise) {
      showReplayDisableToast(addToast, 'edit', dispatch);
      return;
    }
    // toggle editing mode
    toggleChartEditingMode(true, EDIT_MODES.Chart);
  };

  if (
    isVisualEditable({
      data,
      isInsightsBoard,
      isInsightsBoardCreator,
      isWorkflowEditor,
      messageType,
      isPopoutEditable,
    })
  ) {
    if (messageType === 'annotation') {
      // Editing for annotations
      return (
        <MenuItem key="edit" className="Menu-Item-Container" onClick={editAnnotation}>
          <div className="Menu-Item" data-cy="Menu-Item-Edit">
            <EditIcon className="Menu-Item-Icon" />
            <span className="Menu-Item-Text">Edit mode</span>
          </div>
        </MenuItem>
      );
    }
    // Editing plotly charts on an IB
    if (messageType === 'viz' && messageTypeVersion !== 2 && isInsightsBoard) {
      return (
        <MenuItem key="edit" className="Menu-Item-Container" onClick={toggleIBFontMenu}>
          <div className="Menu-Item" data-cy="Menu-Item-Edit">
            <EditIcon className="Menu-Item-Icon" />
            <span className="Menu-Item-Text">Edit fonts</span>
          </div>
        </MenuItem>
      );
    }
    if (!chartEditingMode && messageTypeVersion === 2 && messageType === 'viz') {
      return (
        <MenuItem
          key="editMode"
          className="Menu-Item-Container"
          onClick={() => {
            if (!isLoading) {
              onEditClickHandler();
            }
          }}
        >
          <div
            className={`${
              isReplaying && !isStepwise
                ? 'Menu-Item Replaying'
                : isLoading
                ? 'Menu-Item-Disabled'
                : 'Menu-Item'
            }`}
            data-cy="Menu-Item-Edit"
          >
            <EditIcon className="Menu-Item-Icon" />
            <span className="Menu-Item-Text">Edit mode</span>
          </div>
        </MenuItem>
      );
    }
  }

  return null;
};

ChartEditButton.propTypes = {
  chartEditingMode: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  editAnnotation: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  isLoading: PropTypes.bool,
  isInsightsBoard: PropTypes.bool,
  isInsightsBoardCreator: PropTypes.bool,
  isReplaying: PropTypes.bool.isRequired,
  isStepwise: PropTypes.bool.isRequired,
  isWorkflowEditor: PropTypes.bool.isRequired,
  messageType: PropTypes.string.isRequired,
  messageTypeVersion: PropTypes.number,
  toggleChartEditingMode: PropTypes.func.isRequired,
  toggleIBFontMenu: PropTypes.func,
  isPopoutEditable: PropTypes.bool,
};

ChartEditButton.defaultProps = {
  editAnnotation: undefined,
  isLoading: false,
  isInsightsBoard: false,
  isInsightsBoardCreator: false,
  isPopoutEditable: true,
  messageTypeVersion: 0,
  toggleIBFontMenu: () => {},
};

const mapStateToProps = (state) => ({
  isReplaying: state.context.isReplaying,
  isStepwise: selectIsStepwise(state),
  isWorkflowEditor: isWorkflowEditorPage(selectCurrentLocation(state)),
});

export default connect(mapStateToProps, {})(ChartEditButton);
