import * as Yup from 'yup';
import {
  DATABASE_CONNECTION_FIELDS,
  FORM_INPUT_TYPES,
  YUP_VALIDATION_SCHEMAS,
} from '../../utils/connection/connection';

import { createDatabaseType } from './__helpers__';

const NAME = 'MySQL';

export default {
  meta: {
    name: NAME,
  },
  body: {
    validationSchema: Yup.object().shape({
      databaseName: Yup.string().required('Required'),
      hostname: Yup.string().required('Required'),
      portNumber: Yup.string().required('Required'),
      databaseType: Yup.string().required('Required'),
      username: Yup.string().required('Required'),
      password: Yup.string().required('Required'),
      datasets: YUP_VALIDATION_SCHEMAS.DATASETS,
      readOnly: Yup.boolean().required('Required'),
    }),
    fields: [
      { ...createDatabaseType, value: NAME },
      {
        id: 'databaseName',
        label: 'Database',
        value: '',
        type: FORM_INPUT_TYPES.TEXT,
      },
      {
        id: 'hostname',
        label: 'Host name',
        value: '',
        type: FORM_INPUT_TYPES.TEXT,
      },
      {
        id: 'portNumber',
        label: 'Port Number',
        value: '3306',
        type: FORM_INPUT_TYPES.NUMBER,
      },
      {
        id: 'username',
        label: 'Username',
        value: '',
        type: FORM_INPUT_TYPES.TEXT,
      },
      {
        id: 'password',
        label: 'Password',
        value: '',
        autocomplete: 'new-password',
        type: FORM_INPUT_TYPES.PASSWORD,
      },
      DATABASE_CONNECTION_FIELDS.DATASETS,
      {
        id: 'readOnly',
        label: 'readOnly',
        value: false,
        isRequired: true,
        type: FORM_INPUT_TYPES.CHECKBOX,
      },
    ],
  },
};
