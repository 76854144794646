export const OPEN_GENERIC_MENU = 'menus/OPEN_GENERIC_MENU';
export const CLOSE_GENERIC_MENU = 'menus/CLOSE_GENERIC_MENU';

// Open a generic menu with the passed in parameters
export const openGenericMenu = ({ xPos, yPos, openMenuID, options }) => ({
  type: OPEN_GENERIC_MENU,
  xPos,
  yPos,
  openMenuID,
  options,
});

// Close all generic menus
export const closeGenericMenu = () => ({
  type: CLOSE_GENERIC_MENU,
});
