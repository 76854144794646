import Close from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeLinkDialog } from '../../../store/actions/share.actions';
import {
  selectDataToShare,
  selectCreatingPublicLink,
  selectPublicLink,
} from '../../../store/sagas/selectors';
import ShareLink from './ShareLink';
import SharePreview from './SharePreview';

// renders a dialog to create a public link
// the dialog has 3 states:
// 1. creating - the backend is going through the logic to make an item public: render spinner
// 2. publicLink - the backend has created a public link: render the link
// 3. preview - show a preview of the chart / table that will be shared
const ShareLinkDialog = ({ open }) => {
  const dispatch = useDispatch();
  const creating = useSelector(selectCreatingPublicLink);
  const publicLink = useSelector(selectPublicLink);
  const shareData = useSelector(selectDataToShare);

  // if there is a type on the share data (if share data is a message from message store),
  // then set the visual type to be the type of the message
  const visualType = shareData?.type || null;

  return (
    <Modal open={open}>
      <Container maxWidth="md" className="ShareLinkDialog-Container">
        {creating ? (
          <CircularProgress sx={{ color: 'white' }} />
        ) : (
          <Paper className="ShareLinkDialog">
            <DialogTitle className="ShareLinkDialog-Title">
              <div>Create a public link</div>
              <IconButton onClick={() => dispatch(closeLinkDialog())}>
                <Close />
              </IconButton>
            </DialogTitle>
            {publicLink ? (
              <ShareLink publicLink={publicLink} />
            ) : (
              <SharePreview data={shareData} visualType={visualType} />
            )}
          </Paper>
        )}
      </Container>
    </Modal>
  );
};

ShareLinkDialog.propTypes = {
  open: PropTypes.bool,
};

ShareLinkDialog.defaultProps = {
  open: false,
};

export default ShareLinkDialog;
