export const OPEN_EXPLORER = 'explorer/OPEN_EXPLORER';
export const CLOSE_EXPLORER = 'explorer/CLOSE_EXPLORER';
export const SET_EXPLORER_POS_X = 'explorer/SET_EXPLORER_POS_X';
export const SET_EXPLORER_POS_Y = 'explorer/SET_EXPLORER_POS_Y';
export const REPOSITION_EXPLORER = 'explorer/REPOSITION_EXPLORER';

/**
 * Dispatches an action that opens the Explorer.
 */
export const openExplorer = () => ({
  type: OPEN_EXPLORER,
});

/**
 * Dispatches an action that closes the Explorer.
 */
export const closeExplorer = () => ({
  type: CLOSE_EXPLORER,
});

/**
 * Dispatches an action that updates the X position of the Explorer.
 */
export const setExplorerPosX = (posX) => ({
  type: SET_EXPLORER_POS_X,
  posX,
});

/**
 * Dispatches an action that updates the Y position of the Explorer.
 */
export const setExplorerPosY = (posY) => ({
  type: SET_EXPLORER_POS_Y,
  posY,
});

/**
 * Repositions the explorer after window resize to keep the explorer in frame
 */
export const repositionExplorer = (xFactor, yFactor) => ({
  type: REPOSITION_EXPLORER,
  xFactor,
  yFactor,
});
