import { SeriesGroupKeys, SeriesMarkKeys } from 'translate_dc_to_echart';
import ChartModules from '../modules/ChartModules';
import { InputType } from '../types/chartBuilderIO';
import { isNumeric } from '../utils/columnSelection';
import ChartType from './ChartType';

export default class Violin extends ChartType {
  static fieldGenMapping = {
    mark: {
      [SeriesMarkKeys.x]: InputType.x,
      [SeriesMarkKeys.y]: InputType.y,
    },
    group: {
      [SeriesGroupKeys.subplot]: InputType.subplot,
      [SeriesGroupKeys.slider]: InputType.slider,
      [SeriesGroupKeys.split]: InputType.split,
    },
  };

  static columnFilters = {
    [InputType.y]: [isNumeric],
  };

  constructor() {
    super(new ChartModules(Violin.columnFilters, Violin.fieldGenMapping));
  }
}
