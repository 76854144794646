import root from '../index';

export const createDatabaseType = {
  id: 'databaseType',
  label: 'database type',
  value: '',
  hidden: true,
};

export const getDatabaseTypesList = () => Object.entries(root).map(([key]) => root[key].meta.name);

export const hideIfTrue = (values, key) => {
  // Hide if the given key is true
  const useOAuth = values?.[key] ?? false;
  return useOAuth;
};
