import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios';
import { dcAppServiceEndpoints, endpoints } from '../constants/endpoints';
import { DatasetClaim, Description, DescriptionParameters } from '../types/dataset.types';
import { handleDCAppServiceCall } from '../utils/errorHandling/errorHandlers.api';
import { getClientIdSecretPair } from './apiUtils';

/**
 * Adds Authorization header to Axios request config. If a client secrete pair exists,
 * the access token is not used.
 */
export const addAuthToAxiosConfig = <T>(
  accessToken: string,
  config: AxiosRequestConfig<T>,
): AxiosRequestConfig<T> => {
  const { clientId, secret } = getClientIdSecretPair();
  const useClientSecretPair = clientId && secret;
  const token = useClientSecretPair ? undefined : accessToken;
  return {
    ...config,
    headers: { ...config?.headers, Authorization: `Bearer ${token}` },
    params: { ...config?.params, client_id: clientId, secret },
  };
};

export const getDatasetNamesTable = (accessToken: string, sessionId: string) =>
  handleDCAppServiceCall(() =>
    axios.get(
      dcAppServiceEndpoints.datasetNamesTable,
      addAuthToAxiosConfig(accessToken, { params: { sessionId } }),
    ),
  );

export const getDatasetNames = (accessToken: string, sessionId: string) =>
  handleDCAppServiceCall(() =>
    axios.get(dcAppServiceEndpoints.datasetNames, {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: { sessionId },
    }),
  );

export const listDatasets = (accessToken: string, sessionId: string) =>
  handleDCAppServiceCall(() =>
    axios.get(dcAppServiceEndpoints.listDatasets, {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: { sessionId },
    }),
  );

export const getBaseDatasets = (accessToken: string, sessionId: string, userId: number) =>
  handleDCAppServiceCall(() =>
    axios.get(
      dcAppServiceEndpoints.baseDatasets,
      // if accessToken is not provided, it means that the user is not logged in
      // Instead, we will use the query params to get the client_id and secret
      // to make the request
      addAuthToAxiosConfig(accessToken, { params: { sessionId, userId } }),
    ),
  );

export const deleteDataset = (accessToken: string, uuid: string) =>
  axios.delete(endpoints.dataset, {
    headers: { Authorization: `Bearer ${accessToken}` },
    params: { uuid },
  });

/**
 * @param {string} accessToken Bearer token for "Authorization" header
 * @param {string} sessionId Session ID to search for dataset name and version in
 * @param {string} datasetObjectId uuid of dataset object who's dataset's pipeline will be updated
 * @param {string} name name of dataset in session
 * @param {number} version version of dataset in session
 */
export const putDatasetFromSession = (
  accessToken: string,
  sessionId: string,
  datasetObjectId: string,
  name: string,
  version: number,
) =>
  handleDCAppServiceCall(() =>
    axios.put(dcAppServiceEndpoints.datasetFromSession, null, {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: { sessionId, datasetObjectId, name, version },
    }),
  );

export const createDatasetFromDatabase = (
  accessToken: string,
  {
    connectionId,
    schema,
    tableName,
    parentUUID,
  }: { connectionId: string; schema: string; tableName: string; parentUUID: string },
) =>
  handleDCAppServiceCall(() =>
    axios.post(dcAppServiceEndpoints.createDatasetFromDatabase, null, {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: { connectionId, schema, tableName, parentUUID },
    }),
  );

/**
 * Sends a request to get the pipline backed dataset data
 *
 * @returns {Promise<Object>} The dataset sample
 */
export const retrievePipelinerDataset = (
  accessToken: string,
  signal: AbortSignal,
  {
    numRows,
    offset,
    pipelinerDatasetId,
    sessionId,
    insightsBoardId,
    publicationId,
    workspaceUuid,
  }: {
    /**
     * The number of rows to sample
     */
    numRows?: number;
    /**
     * The number of rows to offset the sample
     */
    offset?: number;
    /**
     * The pipeline ID to use
     */
    pipelinerDatasetId: string;
    /**
     * The session ID to use (claims)
     */
    sessionId?: string;
    /**
     * The insights board ID to use (claims)
     */
    insightsBoardId?: string;

    /**
     * The publication ID to use (claims)
     */
    publicationId?: string;
    /**
     * The workspace UUID to use (claims)
     */
    workspaceUuid?: string;
  },
): Promise<object> => {
  const { clientId, secret } = getClientIdSecretPair();
  const useClientSecretPair = clientId && secret;

  return handleDCAppServiceCall(() =>
    axios.post(
      dcAppServiceEndpoints.retrievePipelinerDataset(pipelinerDatasetId),
      { numRows, offset },
      {
        signal,
        headers: {
          Authorization: `Bearer ${useClientSecretPair ? undefined : accessToken}`,
          'Content-Type': 'application/json',
        },
        params: {
          client_id: clientId,
          secret,
          sessionId,
          insightsBoardId,
          publicationId,
          workspaceUuid,
        },
      },
    ),
  );
};

/**  Requests GET /app/dataset/.../description */
export const getDatasetDescription = (
  accessToken: string,
  signal: AbortSignal,
  {
    id,
    claim,
    params,
  }: {
    id: string;
    claim: DatasetClaim;
    params: DescriptionParameters;
  },
): Promise<AxiosResponse<Description>> => {
  // initialize parameters with the provided params
  const parameters: DescriptionParameters & {
    sessionId?: string;
    workspaceUuid?: string;
    insightsBoardId?: string;
    publicationId?: string;
  } = { ...params };

  // add appropriate claim parameter to parameters
  switch (claim.kind) {
    case 'session':
      parameters.sessionId = claim.sessionId;
      break;
    case 'workspace':
      parameters.workspaceUuid = claim.workspaceUuid;
      break;
    case 'insightsBoard':
      parameters.insightsBoardId = claim.insightsBoardId;
      break;
    case 'publication':
      parameters.publicationId = claim.publicationId;
      break;
    default:
      // @ts-expect-error: default to future-proof against new claim kinds
      throw new Error(`unknown claim kind: ${claim.kind}`);
  }

  // request dataset description
  return handleDCAppServiceCall(() =>
    axios.get(dcAppServiceEndpoints.datasetDescription(id), {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: parameters,
      signal,
      // allow statuses in the 200s and 501
      validateStatus: (status) => status === 501 || (status >= 200 && status < 300),
    }),
  );
};

/**
 * Sends a request to the pipeliner to perform aggregation, binning, and filtering
 */
export const computedDataset = (
  accessToken: string,
  signal: AbortSignal,
  {
    aggregate,
    bins,
    filters,
    pipelinerDatasetId,
    sessionId,
    insightsBoardId,
    publicationId,
    workspaceUuid,
    useCache = true,
  }: {
    /**
     * The aggregation to perform
     */
    aggregate?: string;
    /**
     * The binning to perform
     */
    bins?: string;
    /**
     * The filter to apply
     */
    filters?: string;
    /**
     * The pipeline ID to use
     */
    pipelinerDatasetId?: string;
    /**
     * The session ID to use (claims)
     */
    sessionId?: string;
    /**
     * The insights board ID to use (claims)
     */
    insightsBoardId?: string;
    /**
     * The publication ID to use (claims)
     */
    publicationId?: string;
    /**
     * The workspace UUID to use (claims)
     */
    workspaceUuid?: string;
    /**
     * Whether to use the cache
     */
    useCache?: boolean;
  },
) =>
  handleDCAppServiceCall(() =>
    axios.post(
      dcAppServiceEndpoints.computedDataset,
      { aggregate, bins, filters },
      addAuthToAxiosConfig(accessToken, {
        headers: { 'Content-Type': 'application/json' },
        params: {
          pipelinerDatasetId,
          sessionId,
          insightsBoardId,
          publicationId,
          workspaceUuid,
          useCache,
        },
        signal,
      }),
    ),
  );

/**
 * Send a request to the pipeliner to materialize a dataset
 * @param {String} accessToken The user's access token
 * @param {AbortSignal} signal The signal to use for request cancellation
 * @param {Object} args
 * @param {String} args.pipelinerDatasetId The pipeline ID to use
 * @param {String} args.sessionId The session ID (for claims)
 */
export const materializeDataset = (
  accessToken: string,
  signal: AbortSignal,
  args: { pipelinerDatasetId: string; sessionId: string },
) => {
  const { pipelinerDatasetId, sessionId } = args;
  return handleDCAppServiceCall(() =>
    axios.post(dcAppServiceEndpoints.materializeDataset(pipelinerDatasetId), null, {
      signal,
      headers: { Authorization: `Bearer ${accessToken}` },
      params: { sessionId },
    }),
  );
};

/**
 * Send a request to get the dataset source, file, bigquery, etc.
 */
export const getDatasetSource = (
  accessToken: string,
  sessionId: string,
  datasetName: string,
  version: number,
) =>
  handleDCAppServiceCall(() =>
    axios.get(dcAppServiceEndpoints.datasetSource, {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: { sessionId, datasetName, version },
    }),
  );
