import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import mapKeys from 'lodash/mapKeys';
import { getDatasetReferenceString } from '../../components/ChartData/dataUtils';
import { COMPUTE_SAMPLE_TRIAL_COUNT, SAMPLE_TRIAL_COUNT } from '../../constants';
import {
  COMPUTE_DATASET_REQUEST,
  GET_BASE_DATASETS_SUCCESS,
  GET_DATASET_LIST_SUCCESS,
  REMOVE_DATASET,
  RESET_COLUMN_STAT,
  RESET_DATASETS,
  RETRIEVE_COLUMN_STATS_FAILURE,
  RETRIEVE_COLUMN_STATS_REQUEST,
  RETRIEVE_COLUMN_STATS_SUCCESS,
  RETRIEVE_FORGOTTEN_DATASET,
  SAMPLE_SESSION_DATASET_FAILURE,
  SAMPLE_SESSION_DATASET_REQUEST,
  SAMPLE_SESSION_DATASET_SUCCESS,
  SET_PIVOT_ERROR,
  SET_SHOW_ANNOTATIONS,
  UPDATE_SELECTED_DATASET,
  UPDATE_TEMP_RENAME_VALUES,
} from '../actions/dataset.actions';
import { setCurrentDatasetSuccess } from '../slices/dataspace.slice';

export const initialState = {
  currentDataset: [], // current dataset set by the backend
  datasetList: {}, // list of all available datasets
  hiddenDatasetList: {}, // list of all hidden datasets
  sessionDatasetStorage: {}, // cached samples of datasets
  newDatasetName: '', // new name of column being renamed
  oldDatasetName: '', // old name of column being renamed
  selectedDatasetName: '', // dataset currently displayed in grid table
  selectedDatasetVersion: 0, // dataset version currently displayed in grid table
  columnStatLoading: false, // loading the column stat from the BE
  showStat: false, // indicate expand/collapse state of the column stat
  baseDatasets: {}, // all datasets created using the load skill
  pivotError: false, // error encountered when pivoting a dataset
  pivotErrorMsg: '', // error message encountered when pivoting a dataset
  showAnnotations: false, // whether to show dataset annotations
};

/**
 * Helper function to get the latest valid version of a dataset
 * @param {Object} datasetList list of datasets available to the user
 * @param {String} datasetName name of the dataset to find the latest version for
 * @returns latest valid version number
 */
const getLatestValidVersion = (datasetList, datasetName) => {
  const validVersionList = Object.values(datasetList[datasetName]).filter(
    (ds) => ds.renamed_to === null && !ds.forgotten,
  );
  return validVersionList[validVersionList.length - 1].version;
};

/**
 * Helper function that updates newDatasetName, oldDatasetName, selectedDatasetName, and
 * selectedDatasetVersion as a result of changes to the current dataset or the list of
 * available datasets.
 * @param {Object} state - dataset reducer state
 * @param {Object} action - action of type GET_DATASET_LIST_SUCCESS
 * @returns Update newDatasetName, oldDatasetName, selectedDatasetName, and selectedDatasetVersion
 */
const updateSelectedDataset = (state, action) => {
  const { newDatasetName, oldDatasetName, selectedDatasetName, selectedDatasetVersion } = state;
  const { datasetList, currentDataset } = action;
  // variables to update in this function, default to current values
  let updatedSelectedName = selectedDatasetName;
  let updatedSelectedVersion = selectedDatasetVersion;
  let updatedOldName = state.oldDatasetName;
  const updatedNewName = state.newDatasetName;

  // server returned an empty dataset list
  const listEmpty = isEmpty(datasetList);
  // whether a dataset is selected for viewing
  const datasetSelected = selectedDatasetName !== '' && selectedDatasetVersion !== 0;
  // current dataset has been updated
  const currentUpdated = !isEqual(state.currentDataset, currentDataset);
  // whether the selected dataset was forgotten
  const selectedForgotten = !(selectedDatasetName in datasetList);
  // whether the selected version of the dataset is found in the dataset list
  const selectedVersionForgotten =
    !selectedForgotten && datasetList[selectedDatasetName][selectedDatasetVersion].forgotten;

  if (listEmpty) {
    // reset selected datset when dataset list is emptied (e.g. forgetting all datasets, loading connection)
    if (datasetSelected) {
      updatedSelectedName = '';
      updatedSelectedVersion = 0;
    }
  } else if (!datasetSelected || currentUpdated || selectedForgotten) {
    // context !== CONTEXTS.STARTUP && // TODO check if needed in above this if statement
    if (
      currentDataset?.[1] !== 0 &&
      currentDataset[0] in datasetList &&
      currentDataset[1] in datasetList[currentDataset[0]]
    ) {
      // set selected dataset to the current dataset if it exists
      [updatedSelectedName, updatedSelectedVersion] = currentDataset;
    } else {
      // if there are datasets but no current (e.g. loading a snapshot), use the first listed dataset
      // this dataset will automatically be updated to current (see datasetSideEffectHandler in dataset.saga.js)
      const firstDataset = Object.keys(datasetList)[0];
      updatedSelectedName = firstDataset;
      updatedSelectedVersion = 1;
    }
  } else if (selectedVersionForgotten) {
    // the selected version of the dataset was forgotten
    // set selected dataset to the latest version of the currently selected dataset
    updatedSelectedName = selectedDatasetName;
    updatedSelectedVersion = getLatestValidVersion(datasetList, selectedDatasetName);
  }

  // rename dataset logic
  if (oldDatasetName) {
    if (Object.keys(state.datasetList).length !== Object.keys(datasetList).length) {
      // new name existed, old dataset name will be removed so that length - 1
      updatedOldName = '';
      // create a new name
    } else if (Object.keys(datasetList).includes(newDatasetName)) {
      updatedOldName = '';
    }
    if (newDatasetName && datasetList?.[newDatasetName]) {
      const versions = Object.keys(datasetList?.[newDatasetName]);
      updatedSelectedName = newDatasetName;
      updatedSelectedVersion = parseInt(versions[versions.length - 1], 10);
    }
  }

  return {
    newDatasetName: updatedNewName,
    oldDatasetName: updatedOldName,
    selectedDatasetName: updatedSelectedName,
    selectedDatasetVersion: updatedSelectedVersion,
    showStat: currentUpdated || selectedForgotten ? false : state.showStat,
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RETRIEVE_FORGOTTEN_DATASET: {
      const referenceString = getDatasetReferenceString({
        pipelinerDatasetId: action.pipelinerDatasetId,
      });
      return {
        ...state,
        sessionDatasetStorage: {
          ...state.sessionDatasetStorage,
          [referenceString]: {
            ...state.sessionDatasetStorage[referenceString],
            isTableSampleLoading: true,
            isTableSampleFailed: false,
            forgotten: false,
          },
        },
      };
    }
    case GET_DATASET_LIST_SUCCESS:
      return {
        ...state,
        ...updateSelectedDataset(state, action),
        datasetList: action.datasetList,
        hiddenDatasetList: action.hiddenDatasetList,
        currentDataset: action.currentDataset,
      };
    case SAMPLE_SESSION_DATASET_REQUEST:
    case COMPUTE_DATASET_REQUEST: {
      const {
        dcChartId,
        computeSpec,
        insightsBoardId,
        isTable,
        numRows,
        pipelinerDatasetId,
        publicationId,
        usedCompute,
      } = action;

      // If we're requesting a compute from an IB, we don't want to use this reducer
      if (action.type === COMPUTE_DATASET_REQUEST && (insightsBoardId || publicationId)) {
        return state;
      }

      const referenceString = getDatasetReferenceString({
        dcChartId,
        computeSpec,
        pipelinerDatasetId,
        usedCompute,
      });

      const currData = state.sessionDatasetStorage[referenceString] ?? null;
      const samplingTrialCount = isTable
        ? currData?.tableSamplingTrialCount ?? 0
        : currData?.samplingTrialCount ?? 0;

      // Protect against setting { isSampleLoading: true } when a request isn't forked via takeEachDataset()
      const currRowNum = currData?.rows?.length ?? 0;
      const totalRowNum = currData?.totalRowCount ?? null;
      const hasEnoughRows = currRowNum >= numRows || currRowNum === totalRowNum;

      return {
        ...state,
        sessionDatasetStorage: {
          ...state.sessionDatasetStorage,
          [referenceString]: {
            ...state.sessionDatasetStorage[referenceString],
            ...(isTable
              ? {
                  tableError: null,
                  isTableSampleFailed: false,
                  isTableSampleLoading: !hasEnoughRows,
                  tableSamplingTrialCount: samplingTrialCount + 1,
                  // Set the chart loading statuses to false if we have nothing in the reducer yet
                  ...(!currData && { isSampleFailed: false, isSampleLoading: false }),
                }
              : {
                  chartError: null,
                  isSampleFailed: false,
                  isSampleLoading: !hasEnoughRows,
                  samplingTrialCount: samplingTrialCount + 1,
                  // Set the table loading statuses to false if we have nothing in the reducer yet
                  ...(!currData && { isTableSampleFailed: false, isTableSampleLoading: false }),
                }),
          },
        },
      };
    }
    case SAMPLE_SESSION_DATASET_FAILURE: {
      const { dcChartId, computeSpec, error, isTable, pipelinerDatasetId, usedCompute } = action;
      const referenceString = getDatasetReferenceString({
        dcChartId,
        computeSpec,
        pipelinerDatasetId,
        usedCompute,
      });

      const currData = state.sessionDatasetStorage[referenceString];
      const samplingTrialCount = isTable
        ? currData?.tableSamplingTrialCount ?? 0
        : currData?.samplingTrialCount ?? 0;
      const keepLoading = usedCompute
        ? samplingTrialCount < COMPUTE_SAMPLE_TRIAL_COUNT
        : samplingTrialCount < SAMPLE_TRIAL_COUNT; // if it is the first trial keep trying

      return {
        ...state,
        sessionDatasetStorage: {
          ...state.sessionDatasetStorage,
          [referenceString]: {
            ...state.sessionDatasetStorage[referenceString],
            ...(isTable
              ? {
                  tableError: !keepLoading ? error : null,
                  isTableSampleFailed: !keepLoading,
                  isTableSampleLoading: keepLoading,
                  tableSamplingTrialCount: keepLoading ? samplingTrialCount : 0,
                }
              : {
                  chartError: !keepLoading ? error : null,
                  isSampleFailed: !keepLoading,
                  isSampleLoading: keepLoading,
                  samplingTrialCount: keepLoading ? samplingTrialCount : 0,
                }),
          },
        },
      };
    }
    case SAMPLE_SESSION_DATASET_SUCCESS: {
      const {
        dcChartId,
        computeSpec,
        forgotten,
        isTable,
        pipelinerDatasetId,
        result,
        tableSampleRowCount,
        usedCompute,
      } = action;
      const referenceString = getDatasetReferenceString({
        dcChartId,
        computeSpec,
        pipelinerDatasetId,
        usedCompute,
      });

      // Total number of rows that we have on the FE
      const existingNumRows = state.sessionDatasetStorage[referenceString]?.rows?.length ?? null;
      const incomingNumRows = result?.rows?.length ?? 0;

      return {
        ...state,
        sessionDatasetStorage: {
          ...state.sessionDatasetStorage,
          [referenceString]: {
            ...state.sessionDatasetStorage[referenceString],
            // Only insert data if we received more than we currently have
            ...((!existingNumRows || incomingNumRows > existingNumRows) && { ...result }),
            // Update our tableSampleRowCount if it changed, ex. Scrolling on a table
            ...(isTable
              ? {
                  tableError: null,
                  isTableSampleFailed: false,
                  isTableSampleLoading: false,
                  tableSamplingTrialCount: 0,
                  tableSampleRowCount:
                    tableSampleRowCount > incomingNumRows ? incomingNumRows : tableSampleRowCount,
                }
              : {
                  chartError: null,
                  isSampleFailed: false,
                  isSampleLoading: false,
                  samplingTrialCount: 0,
                }),
            forgotten,
          },
        },
      };
    }
    case RETRIEVE_COLUMN_STATS_REQUEST: {
      return { ...state, columnStatLoading: true };
    }
    case RESET_COLUMN_STAT: {
      return { ...state, columnStatLoading: false, showStat: false };
    }
    case RETRIEVE_COLUMN_STATS_SUCCESS: {
      const { data, pipelinerDatasetId } = action;
      const referenceString = getDatasetReferenceString({ pipelinerDatasetId });
      return {
        ...state,
        sessionDatasetStorage: {
          ...state.sessionDatasetStorage,
          [referenceString]: {
            ...state.sessionDatasetStorage[referenceString],
            columnStat: data,
          },
        },
        columnStatLoading: false,
        showStat: true,
      };
    }
    case RETRIEVE_COLUMN_STATS_FAILURE: {
      return { ...state, columnStatLoading: false, showStat: false };
    }
    case REMOVE_DATASET: {
      // removes table data cache
      const referenceString = getDatasetReferenceString({
        pipelinerDatasetId: action.pipelinerDatasetId,
      });
      return {
        ...state,
        sessionDatasetStorage: {
          ...state.sessionDatasetStorage,
          [referenceString]: {
            forgotten: true,
          },
        },
      };
    }
    case UPDATE_SELECTED_DATASET: {
      if (action.datasetName !== undefined) {
        // Scenario 1: action.datasetName is defined
        // Use action.datasetVersion if defined, otherwise use the last not renamed version
        let { datasetVersion } = action;
        if (datasetVersion === undefined) {
          datasetVersion = getLatestValidVersion(state.datasetList, action.datasetName);
        }
        return {
          ...state,
          selectedDatasetName: action.datasetName,
          selectedDatasetVersion: datasetVersion,
          showStat: false,
          columnStatLoading: false,
        };
      } else if (action.datasetVersion !== undefined) {
        // Scenario 2: ONLY action.datasetVersion is defined
        // Update just the selectedDatasetVersion
        return {
          ...state,
          selectedDatasetVersion: action.datasetVersion,
          showStat: false,
          columnStatLoading: false,
        };
      }
      // Scenario 3: both action.datasetName and action.datasetVersion are undefined
      // Nothing to update, return state
      return state;
    }
    case setCurrentDatasetSuccess.type: {
      // DataSpace still uses this state, need to close
      // stats if the current dataset changes.
      return {
        ...state,
        showStat: false,
      };
    }
    case UPDATE_TEMP_RENAME_VALUES: {
      const newDatasetName =
        action.newDatasetName !== undefined ? action.newDatasetName : state.newDatasetName;
      const oldDatasetName =
        action.oldDatasetName !== undefined ? action.oldDatasetName : state.oldDatasetName;
      return { ...state, newDatasetName, oldDatasetName };
    }
    case GET_BASE_DATASETS_SUCCESS:
      return {
        ...state,
        baseDatasets: mapKeys(action.baseDatasets, 'name'),
      };
    case RESET_DATASETS:
      return initialState;
    case SET_PIVOT_ERROR: {
      return {
        ...state,
        pivotError: action.error,
        pivotErrorMsg: action.errorMsg,
      };
    }
    case SET_SHOW_ANNOTATIONS: {
      return {
        ...state,
        showAnnotations: action.showAnnotations,
      };
    }
    default:
      return state;
  }
};
