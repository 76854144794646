import bigquery from './bigquery';
import cockroach from './cockroach';
import connectionstring from './connection_string';
import databricks from './databricks';
import microsoftsqlserver from './microsoft_sql_server';
import mysql from './mysql';
import postgresql from './postgres';
import presto from './presto';
import snowflake from './snowflake';
import redshift from './redshift';

export default {
  snowflake,
  cockroach,
  microsoftsqlserver,
  postgresql,
  mysql,
  bigquery,
  presto,
  databricks,
  connectionstring,
  redshift,
};
