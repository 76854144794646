export const OPEN_REPLAY_CONTROLLER = 'replayController/OPEN_REPLAY_CONTROLLER';
export const CLOSE_REPLAY_CONTROLLER = 'replayController/CLOSE_REPLAY_CONTROLLER';
export const SET_REPLAY_CONTROLLER_POS_X = 'replayController/SET_REPLAY_CONTROLLER_POS_X';
export const SET_REPLAY_CONTROLLER_POS_Y = 'replayController/SET_REPLAY_CONTROLLER_POS_Y';
export const REPOSITION_REPLAY_CONTROLLER = 'replayController/REPOSITION_REPLAY_CONTROLLER';

/**
 * Dispatches an action that opens the Replay Controller.
 */
export const openReplayController = () => ({
  type: OPEN_REPLAY_CONTROLLER,
});

/**
 * Dispatches an action that closes the Replay Controller.
 */
export const closeReplayController = () => ({
  type: CLOSE_REPLAY_CONTROLLER,
});

/**
 * Dispatches an action that updates the X position of the Replay Controller.
 */
export const setReplayControllerPosX = (posX) => ({
  type: SET_REPLAY_CONTROLLER_POS_X,
  posX,
});

/**
 * Dispatches an action that updates the Y position of the Replay Controller.
 */
export const setReplayControllerPosY = (posY) => ({
  type: SET_REPLAY_CONTROLLER_POS_Y,
  posY,
});

/**
 * Repositions replay controller after window resize to keep the toolbar in frame
 */
export const repositionReplayController = (xFactor, yFactor) => ({
  type: REPOSITION_REPLAY_CONTROLLER,
  xFactor,
  yFactor,
});
