/* Header */
export const hasLogoDisplay = (userConfig) => userConfig && userConfig.hasLogoDisplay;
export const hasClickableLogo = (userConfig) => userConfig && userConfig.hasClickableLogo;
export const hasHeaderInfoDisplay = (userConfig) => userConfig && userConfig.hasHeaderInfoDisplay;
export const hasAppName = (userConfig) => userConfig && userConfig.hasAppName;
export const hasUserIdentity = (userConfig) => userConfig && userConfig.hasUserIdentity;
export const hasMenuDisplay = (userConfig) => userConfig && userConfig.hasMenuDisplay;

/* Headers */
export const hasDashboards = (userConfig) => userConfig && userConfig.hasDashboards;

/* Menu Buttons */
export const hasUploadFileManager = (userConfig) => userConfig && userConfig.hasUploadFileManager;
export const hasLogout = (userConfig) => userConfig && userConfig.hasLogout;

/* Client Skills */
export const hasClearScreenSkill = (userConfig) => userConfig && userConfig.hasClearScreenSkill;
export const hasSuggestionsAnalysisSkill = (userConfig) =>
  userConfig && userConfig.hasSuggestionsAnalysisSkill;
export const hasGoodbyeSkill = (userConfig) => userConfig && userConfig.hasGoodbyeSkill;

/* Features
These should match the values in app/app.go
*/
export const hasFeatureEnabled = (userConfig, feature) =>
  userConfig?.featureFlags?.[feature] ?? false;
