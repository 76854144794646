import { SeriesGroupKeys, SeriesMarkKeys } from 'translate_dc_to_echart';
import ChartModules from '../modules/ChartModules';
import { InputType } from '../types/chartBuilderIO';
import {
  disallowWithMultiYAxis,
  isNumeric,
  maximumOfOneColumnWithGroup,
} from '../utils/columnSelection';
import ChartType from './ChartType';

export default class Line extends ChartType {
  static fieldGenMapping = {
    mark: {
      [SeriesMarkKeys.x]: InputType.x,
      [SeriesMarkKeys.y]: InputType.y,
      [SeriesMarkKeys.color]: InputType.group,
      [SeriesMarkKeys.label]: InputType.label,
    },
    group: {
      [SeriesGroupKeys.subplot]: InputType.subplot,
      [SeriesGroupKeys.slider]: InputType.slider,
    },
  };

  static columnFilters = {
    [InputType.y]: [maximumOfOneColumnWithGroup, isNumeric],
    [InputType.group]: [disallowWithMultiYAxis],
  };

  constructor() {
    super(new ChartModules(Line.columnFilters, Line.fieldGenMapping));
  }
}
