import extendTheme from '@mui/material/styles/experimental_extendTheme';
import merge from 'lodash/merge';

// IDE May be confused about SASS and require manually inserting new imports
import {
  almostWhite,
  blackTranslucentDark,
  bluePurpleTransparent,
  blueVeryDark,
  grey,
  greyBright,
  greyLight,
  greyMild,
  greyMildLight,
  purple500,
  purple600,
  purpleDark,
  purpleLightTransparent,
  purpleTransparent,
  purpleVeryLightTransparent,
  redErrorPrimary,
  white,
  bluePurple,
} from '../../styles/_colors.scss';

// Theme Names
export const THEME_STANDARD = 'STANDARD';
// Unfinished theme!  Use only for testing.
export const THEME_HIGH_CONTRAST = 'HIGH_CONTRAST';

/**
 * Options we want to use in every theme
 */
const SHARED_OPTIONS = {
  typography: {
    fontFamily: '"Source Sans Pro", sans-serif',
  },
};

/**
 * Merge specified options with shared options for extendTheme.
 */
const extendDCTheme = (options) => extendTheme(merge({}, SHARED_OPTIONS, options));

/**
 * Defines the Material UI Themes we use in DataChat.  Themes
 * here should specify any standard MUI colors/properties wanted, along with
 * our custom palette entries: background, text.
 *
 * Colors should be imported from the SASS colors file to maintain a single
 * source of truth for colors.
 *
 * Note that some valid CSS like color names (e.g 'white') won't work for MUI.
 *
 * Do not add custom variables unless absolutely necessary.
 * See default variables here: https://mui.com/material-ui/customization/default-theme/
 */
export const THEMES = {
  [THEME_STANDARD]: extendDCTheme({
    colorSchemes: {
      light: {
        palette: {
          primary: {
            main: purple600,
            dark: purpleDark,
            light: '#F2E3FC',
            // Deprecated: Use rgba(var(--mui-palette-primary-mainChannel) / 0.12)
            transparent: purpleTransparent,
            lightTransparent: purpleLightTransparent,
            veryLighttransparent: purpleVeryLightTransparent,
          },
          secondary: {
            main: bluePurple,
            light: purple500,
            // Deprecated: Use rgba(var(--mui-palette-secondary-mainChannel) / 0.12)
            transparent: bluePurpleTransparent,
          },
          background: {
            default: almostWhite,
            paper: white,
            // Deprecated: Use above colors or manipulate them
            main: almostWhite,
            light: white,
            dark: greyLight,
          },
          text: {
            // Deprecated: Use above colors or manipulate them
            main: blueVeryDark,
            medium: grey,
            light: greyMild,
            mildLight: greyMildLight,
            dark: blackTranslucentDark,
            white,
          },
          error: {
            main: redErrorPrimary,
          },
          light: {
            main: white,
            contrastText: purple600,
          },
          dark: {
            main: blackTranslucentDark,
            contrastText: greyLight,
          },
        },
        typography: {
          button: {
            fontSize: '1rem',
            textTransform: 'none',
          },
        },
      },
      dark: {
        palette: {
          primary: {
            main: '#ffffff',
            contrastText: '#212121',
          },
          background: {
            default: '#212121',
          },
        },
        typography: {
          button: {
            fontSize: '1rem',
            textTransform: 'none',
          },
        },
      },
    },
    spacing: () => '1rem',
    components: {
      MuiAvatar: {
        styleOverrides: {
          root: ({ theme }) => ({ backgroundColor: theme.palette.text.secondary }),
        },
      },
    },
  }),
  [THEME_HIGH_CONTRAST]: extendDCTheme({
    palette: {
      primary: {
        main: purple500,
      },
      background: {
        main: greyBright,
      },
    },
  }),
};
