import { WINDOW_RESIZE } from '../actions/window.actions';

const initialState = {
  windowWidth: undefined,
  windowHeight: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case WINDOW_RESIZE:
      return {
        ...state,
        windowWidth: action.windowWidth,
        windowHeight: action.windowHeight,
      };
    default:
      return state;
  }
};
