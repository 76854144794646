import { buffers } from 'redux-saga';
import { actionChannel, call, fork, put, take } from 'redux-saga/effects';
import { isNetworkError } from '../../constants/network_error';
import { NotAuthenticatedError } from '../../utils/errors';
import { closeDialog, CREATE_ALERT_REQUEST, HANDLE_CLIENT_ERROR } from '../actions/dialog.actions';
import {
  authenticationErrorWorker,
  createAlertChannel,
  generalErrorWorker,
  networkErrorWorker,
  statusErrorFilter,
} from './utils/alert-channels';

// Entry point for most errors.
export function* errorWorker(error, retryRequestAction) {
  if (isNetworkError(error)) {
    // User is not connected to the internet.
    yield* networkErrorWorker(retryRequestAction);
  } else if (error instanceof NotAuthenticatedError) {
    // User encountered an authentication error.
    yield* authenticationErrorWorker(retryRequestAction);
  } else if (error.response) {
    // User encountered an error with a status code from the server.
    yield* statusErrorFilter(error, error.response.status, retryRequestAction);
  } else {
    // User encountered an error that we do not handle specifically.
    yield* generalErrorWorker(error);
  }
}

function* watchRequests() {
  // Show only 10 alerts at a time at max.
  const buffer = buffers.fixed(10);
  const requestChan = yield actionChannel([CREATE_ALERT_REQUEST, HANDLE_CLIENT_ERROR], buffer);

  // keeps track of error message in the current buffer
  let alertTypes = new Set();

  while (true) {
    // Take alert request
    const action = yield take(requestChan);

    if (action?.type === HANDLE_CLIENT_ERROR) {
      const { alertConfig } = action || {};
      const alertChannel = yield* createAlertChannel(alertConfig);
      yield take(alertChannel);
      yield put(closeDialog());
    } else {
      // Handle regular error alerts
      const { error } = action;
      if (!alertTypes.has(error.message)) {
        yield call(errorWorker, error);
        alertTypes.add(error.message);
      }
    }

    // clear the alertTypes when the buffer is empty
    if (buffer.isEmpty()) {
      alertTypes = new Set();
    }
  }
}

export default function* () {
  yield fork(watchRequests);
}
