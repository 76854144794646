import {
  BIGQUERY_MAP,
  COCKROACH_MAP,
  DATABRICKS_MAP,
  MICROSOFTSQLSERVER_MAP,
  MYSQL_MAP,
  POSTGRESQL_MAP,
  PRESTO_MAP,
  REDSHIFT_MAP,
  SNOWFLAKE_MAP,
} from './db_connection_info_cards';
import databaseConnectionForms from './form/index';

export const CONNECTION_DATABASES_TYPES: {
  [key: string]: string;
} = {
  BIGQUERY: databaseConnectionForms.bigquery.meta.name,
  COCKROACH: databaseConnectionForms.cockroach.meta.name,
  DATABRICKS: databaseConnectionForms.databricks.meta.name,
  MICROSOFTSQLSERVER: databaseConnectionForms.microsoftsqlserver.meta.name,
  MYSQL: databaseConnectionForms.mysql.meta.name,
  POSTGRESQL: databaseConnectionForms.postgresql.meta.name,
  PRESTO: databaseConnectionForms.presto.meta.name,
  SNOWFLAKE: databaseConnectionForms.snowflake.meta.name,
  REDSHIFT: databaseConnectionForms.redshift.meta.name,
  CONNECTIONSTRING: databaseConnectionForms.connectionstring.meta.name,
};

export const DATABASE_TYPES_DISPLAY_NAMES: {
  [key: string]: string;
} = {
  BIGQUERY: 'BigQuery',
  COCKROACH: 'Cockroach',
  DATABRICKS: 'Databricks',
  MICROSOFTSQLSERVER: 'MicrosoftSQLServer',
  MYSQL: 'MySQL',
  POSTGRESQL: 'PostgreSQL',
  PRESTO: 'Presto',
  SNOWFLAKE: 'Snowflake',
  REDSHIFT: 'Redshift',
  CONNECTIONSTRING: 'ConnectionString',
};

export const DEPRECATED_CONNECTION_DATABASES_TYPES: string[] = ['COCKROACH'];

export const CONNECTION_DATABASES = {
  [CONNECTION_DATABASES_TYPES.SNOWFLAKE]: databaseConnectionForms.snowflake,
  [CONNECTION_DATABASES_TYPES.COCKROACH]: databaseConnectionForms.cockroach,
  [CONNECTION_DATABASES_TYPES.MICROSOFTSQLSERVER]: databaseConnectionForms.microsoftsqlserver,
  [CONNECTION_DATABASES_TYPES.POSTGRESQL]: databaseConnectionForms.postgresql,
  [CONNECTION_DATABASES_TYPES.MYSQL]: databaseConnectionForms.mysql,
  [CONNECTION_DATABASES_TYPES.BIGQUERY]: databaseConnectionForms.bigquery,
  [CONNECTION_DATABASES_TYPES.PRESTO]: databaseConnectionForms.presto,
  [CONNECTION_DATABASES_TYPES.DATABRICKS]: databaseConnectionForms.databricks,
  [CONNECTION_DATABASES_TYPES.CONNECTIONSTRING]: databaseConnectionForms.connectionstring,
  [CONNECTION_DATABASES_TYPES.REDSHIFT]: databaseConnectionForms.redshift,
};

export const INPUT_FIELD_INFO_MAP = {
  [CONNECTION_DATABASES_TYPES.SNOWFLAKE]: SNOWFLAKE_MAP,
  [CONNECTION_DATABASES_TYPES.COCKROACH]: COCKROACH_MAP,
  [CONNECTION_DATABASES_TYPES.MICROSOFTSQLSERVER]: MICROSOFTSQLSERVER_MAP,
  [CONNECTION_DATABASES_TYPES.POSTGRESQL]: POSTGRESQL_MAP,
  [CONNECTION_DATABASES_TYPES.MYSQL]: MYSQL_MAP,
  [CONNECTION_DATABASES_TYPES.BIGQUERY]: BIGQUERY_MAP,
  [CONNECTION_DATABASES_TYPES.PRESTO]: PRESTO_MAP,
  [CONNECTION_DATABASES_TYPES.DATABRICKS]: DATABRICKS_MAP,
  [CONNECTION_DATABASES_TYPES.REDSHIFT]: REDSHIFT_MAP,
};

export const DISCARD_CONNECTION_FIELD_IDS = new Set(['readOnly', 'databaseType', 'default']);

// ID for Connection Name input field in the connection editor form
export const CONNECTION_NAME = 'connectionName';

// ID for readOnly input field in the connection editor form
export const READ_ONLY = 'readOnly';

// ID for useStorageAPI input field in the connection editor form
export const USE_STORAGE_API = 'useStorageApi';

// ID for default input field in the connection editor form
export const DEFAULT = 'default';

// Info for datasets field
export const DATASETS_INFO =
  'Add SQL queries here to load custom datasets or specific tables based on the contents of the database above';

// Tooltip for the Display Name field
export const DISPLAY_NAME_TOOLTIP = 'The name of the database as it appears across DataChat.';

// Variables declared for class names for components
export const CE = 'ConnectionEditor';
export const TITLE = `${CE}-Title`; // ConnectionEditor-Title-Container
export const CONTENT = `${CE}-Content`; // ConnectionEditor-Content
export const CONTENT_CONTAINER = `${CONTENT}-Container`; // ConnectionEditor-Content-Container
export const FORM = `${CONTENT_CONTAINER}-Form`; // ConnectionEditor-Content-Container-Form
export const FORM_SCROLL_CONTAINER = `${FORM}-Scroll-Container`; // ConnectionEditor-Content-Container-Form-Scroll-Container
export const FORM_GRID = `${CONTENT_CONTAINER}-Grid`; // ConnectionEditor-Content-Container-Grid
export const BUTTONS_CONTAINER = `${FORM_GRID}-Buttons-Container`; // ConnectionEditor-Content-Container-Grid-Buttons-Container
export const OUTLINED_BUTTON = `${BUTTONS_CONTAINER}-Outlined-Button`; // ConnectionEditor-Content-Container-Grid-Outlined-Button
export const BUTTON = `${BUTTONS_CONTAINER}-Button`; // ConnectionEditor-Content-Container-Grid-Button
