import { put, takeLatest } from 'redux-saga/effects';

import { authenticate } from '../../utils/authenticate';
import { createAlertChannelRequest } from '../actions/dialog.actions';
import {
  LOAD_UTTERANCE_REQUEST,
  loadUtteranceFailure,
  loadUtteranceSuccess,
} from '../actions/utterance.actions';

export function* loadUtteranceWorker({ rawUtterance }) {
  try {
    // TODO: Implement Backend Support
    // const accessToken = yield select(selectAccessToken);
    // const session = yield select(selectSession);
    // const response = yield call(getComplete, rawUtterance, accessToken, session);
    yield put(loadUtteranceSuccess({ utterance: rawUtterance }));
  } catch (error) {
    yield put(loadUtteranceFailure({ error }));

    yield put(createAlertChannelRequest({ error }));
  }
}

export default function* () {
  yield takeLatest(LOAD_UTTERANCE_REQUEST, authenticate(loadUtteranceWorker, loadUtteranceFailure));
}
