import { Divider as antDivider, DividerProps } from 'antd';
import styled, { css } from 'styled-components';

interface IDividerProps extends DividerProps {
  $noMargin?: boolean;
  // add new props here
}

const Divider = styled(antDivider)<IDividerProps>`
  ${(props) =>
    props.$noMargin &&
    css`
      margin: 0;
    `}
`;

export default Divider;
