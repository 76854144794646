// This file contains error messages for cases where the translation layer is expected to throw an error.
// These are expected to primarily be used to provide quick feedback to the user when manipulating fields
// in the chart builder

// To add error handling to the translation layer...
// 1. Decide the error name & message
// 2. Export ERROR_NAME from this file with the desired message
// 3. Locate position in code where we want an error to be thrown
// 4. Import, then add `throw new Error( ERROR_NAME );` at that location
// 5. Add case to getUserFacingMessage() to display the new message instead of the default
import {
  NO_DATA_ERROR_GENERAL,
  NO_DATA_ERROR_SAMPLED_FILTER,
  NO_DATA_ERROR_WHOLE_FILTER,
  NO_DATA_ERROR_FILTER_NULL_COLUMN,
  SINGLE_IND_MULTIPLE_DEP_MSG,
  SINGLE_IND_MULTIPLE_DEP_MSG_DONUT,
  SINGLE_METRIC_AGGREGATE_ERROR,
  TOO_FEW_SPLIT_VALUES_ERROR,
  TOO_MANY_ROWS_ERROR,
  TOO_MANY_SPLIT_VALUES_ERROR,
  TOO_MANY_SUBPLOTS_ERROR,
} from 'translate_dc_to_echart';

/**
 * Generic error message for cases when an unexpected error is thrown/caught.
 */
export const DEFAULT_MSG = 'An unexpected error occurred and your chart could not be plotted.';

/**
 * Computes the error message to display to the user.
 * Displays a generic message, unless we catch a specific error message.
 * @param {String} message The message thrown with the error
 * @returns {String} The error message to display to the user
 */
export const getUserFacingMessage = (message) => {
  switch (message) {
    case SINGLE_IND_MULTIPLE_DEP_MSG:
    case SINGLE_IND_MULTIPLE_DEP_MSG_DONUT:
    case TOO_MANY_ROWS_ERROR:
    case SINGLE_METRIC_AGGREGATE_ERROR:
    case NO_DATA_ERROR_GENERAL:
    case NO_DATA_ERROR_WHOLE_FILTER:
    case NO_DATA_ERROR_SAMPLED_FILTER:
    case NO_DATA_ERROR_FILTER_NULL_COLUMN:
    case TOO_MANY_SUBPLOTS_ERROR:
    case TOO_MANY_SPLIT_VALUES_ERROR:
    case TOO_FEW_SPLIT_VALUES_ERROR:
      return message;
    default:
      return DEFAULT_MSG;
  }
};
