/** Regex to match valid names for objects and sessions. */
const VALID_NAME = /^[a-zA-Z0-9_]+$/;

// Workflow and session names can include alphanumeric values and underscores
export const INVALID_CHARACTERS = /[^a-zA-Z0-9_]+/g;

// Workflow and session names can include alphanumeric values and underscores
// use for: workflow, session, folder
export const VALID_WORKFLOW_NAME = VALID_NAME;

export const VALID_CONNECTION_NAME = VALID_NAME;

// Insights board names can include alphanumeric values, and underscores
export const VALID_INSIGHTS_BOARD_NAME = VALID_NAME;

/** Matches valid names for datasets. */
export const VALID_DATASET_NAME = VALID_NAME;

export const HTML_TAG = /&[^;]*;/g;
