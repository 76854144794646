import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import React, { useCallback, useRef, useState } from 'react';
import './DropDownButton.scss';

const DropDownButton = ({ disabled, label, loading, onClick, subOptions, variant }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const hasSubOptions = subOptions?.filter((option) => !option.hidden).length > 0;

  const handleToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, [setOpen]);

  const handleClose = useCallback(
    (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }
      setOpen(false);
    },
    [anchorRef, setOpen],
  );

  const handleListKeyDown = useCallback(
    (event) => {
      if (event.key === 'Tab') {
        event.preventDefault();
        setOpen(false);
      } else if (event.key === 'Escape') {
        setOpen(false);
      }
    },
    [setOpen],
  );

  const getDropDown = useCallback(
    () => (
      <>
        <div className={`VerticalLine${disabled ? ' disabled' : ''}`}></div>
        <ExpandMoreIcon
          className="DropDownIcon"
          data-cy="DropDownButton-ExpandMoreIcon"
          ref={anchorRef}
          onMouseDown={() => !disabled && !loading && handleToggle()}
          onClick={(e) => e.stopPropagation()}
        />
      </>
    ),
    [disabled, loading, handleToggle],
  );

  return (
    <Stack direction="row" spacing={2}>
      <Button
        className={`DropDownButton${loading ? ' isLoading' : ''}${
          hasSubOptions ? ' hasSubOptions' : ''
        }}`}
        onClick={() => !loading && onClick()}
        variant={variant}
        disabled={disabled}
        data-cy="DropDownButton-MainAction"
      >
        {loading ? (
          <CircularProgress size={20} className="CircularProgress" />
        ) : (
          <>
            {label}
            {hasSubOptions && getDropDown()}
          </>
        )}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-end"
        transition
        disablePortal
        style={{ zIndex: 11 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  className="DropDownButtonMenu"
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                >
                  {subOptions.map((option) => (
                    <MenuItem
                      id="composition-button"
                      key={option.label}
                      onClick={option.onClick}
                      data-cy={`${option.label.split(' ').join('-')}`}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Stack>
  );
};

DropDownButton.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  subOptions: PropTypes.arrayOf(PropTypes.object),
  variant: PropTypes.string,
};

DropDownButton.defaultProps = {
  disabled: false,
  label: '',
  loading: false,
  onClick: () => {},
  subOptions: [],
  variant: 'contained',
};

export default DropDownButton;
