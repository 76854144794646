import React, { RefObject } from 'react';
import { TableColumnType, Table as antTable } from 'antd';
import { FileSearchOutlined, SearchOutlined } from '@ant-design/icons';
import { FilterDropdownProps } from 'antd/es/table/interface';
import { Row, Col, Input, Button, Flex, Gutter, InputRef } from '../../index';
import colors from '../../theme/colors';

export type { ColumnsType as TableColumnType } from 'antd/es/table';
export type { FilterDropdownProps as TableFilterDropdownProps } from 'antd/es/table/interface';

export function getTableColumnSearch<T>(
  dataIndex: keyof T,
  title: string,
  inputRef: RefObject<InputRef>,
  handleSearch: (
    selectedKeys: string[],
    confirm: FilterDropdownProps['confirm'],
    dataIndex: keyof T,
  ) => void,
  handleReset: (clearFilters: () => void) => void,
): TableColumnType<T> {
  return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <>
          <Gutter size={2} />
          <Row justify="center" onKeyDown={(e) => e.stopPropagation()} gutter={[8, 8]}>
            <Col span={22}>
              <Input
                placeholder={`Search ${title || String(dataIndex)}`}
                ref={inputRef}
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
              />
            </Col>
            <Col span={22}>
              <Flex gap={8} justify="end">
                <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small">
                  Reset
                </Button>
                <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                  icon={<FileSearchOutlined />}
                  size="small"
                >
                  Apply
                </Button>
              </Flex>
            </Col>
          </Row>
          <Gutter size={2} />
        </>
      );
    },
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? colors.primary : colors.darkText }} />
    ),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => inputRef?.current?.select(), 100);
      }
    },
  };
}

export default antTable;
