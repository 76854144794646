import PropTypes from 'prop-types';
import React from 'react';
import '../ChartBuilder.scss';
import { dropdownTitles } from '../utils/constants';
import DropdownArrow from './DropdownArrow';

/**
 * Renders the chart builder dropdowns with a displayName and DropdownArrow in different formats
 * @returns {JSX.Element}
 */
const Dropdown = (props) => {
  const { componentName, disabled, format, onClick } = props;

  // Get the display name for the dropdown from the component name
  const displayName = dropdownTitles[componentName];

  // Get the className for the row. This only applies to the menu format
  const className = disabled ? 'row-shaded-disabled' : 'row-shaded';

  // Select the dropdown format
  switch (format) {
    case 'menu':
      return (
        <div className={className} data-testid={componentName} onClick={onClick}>
          <p>{displayName}</p>
          <DropdownArrow {...props} />
        </div>
      );
    case 'footer':
      return (
        <>
          <p>{displayName}</p>
          <DropdownArrow {...props} />
        </>
      );
    default:
      return null;
  }
};

Dropdown.propTypes = {
  // The dropdown component that we're rendering, ex. 'Describe', 'RequiredFields', 'Filter', etc.
  componentName: PropTypes.string.isRequired,
  // Should the dropdown be disabled?
  disabled: PropTypes.bool,
  // The format of the dropdown. This tells us which styles to apply and where to add the onClick
  format: PropTypes.string.isRequired,
  // The onClick function to attach to the dropdown row or dropdown arrow
  onClick: PropTypes.func.isRequired,
};

Dropdown.defaultProps = {
  disabled: false,
};

export default Dropdown;
