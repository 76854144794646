import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectEditedDatasetsIds } from '../../../../store/selectors/catalog.selector';
import { discard, save } from '../../../../store/slices/catalog.slice';
import { HomeObjectKeys, HomeObjectKeysTypes } from '../../../../utils/homeScreen/types';
import NameTypeCell from '../../../HomeScreenObjectTable/NameTypeCell';

const CatalogNameTypeCell: React.FC<{ rowData: HomeObjectKeysTypes }> = ({ rowData }) => {
  const dispatch = useDispatch();
  const editedDatasetIds = useSelector(selectEditedDatasetsIds);
  const datasetId = rowData[HomeObjectKeys.OBJECT_FK] ?? '';
  const hasEdits = editedDatasetIds.includes(datasetId);
  return (
    <NameTypeCell
      rowData={rowData}
      showIndicator={hasEdits}
      hoverActions={[
        {
          icon: CloseIcon,
          onClick: () => dispatch(discard(datasetId)),
          tooltip: 'Discard changes',
        },
        {
          icon: SaveIcon,
          onClick: () => dispatch(save(datasetId)),
          primary: true,
          tooltip: 'Save changes',
        },
      ]}
    />
  );
};

export default CatalogNameTypeCell;
