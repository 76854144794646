import { ApolloClient, ApolloLink, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { store } from '../configureStore';
import { selectAccessToken } from '../store/sagas/selectors';

const httpLink = createHttpLink({
  uri: '/api/graphql',
  credentials: 'include',
});

const authLink = setContext((_, { headers }) => {
  const accessToken: string = selectAccessToken(store.getState());
  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${accessToken}`,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  };
});

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message }: { message: string }) => {
      return `GraphQL Error: ${message}`;
    });
  }
});

const links = ApolloLink.from([errorLink, authLink, httpLink]);

export const client = new ApolloClient({
  link: links,
  cache: new InMemoryCache({
    // TODO: update this accordingly
  }),
});
