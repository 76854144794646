import cloneDeep from 'lodash/cloneDeep';
import { generateAxisTitle, generateTitle } from './generateTitles';

/**
 * Generates the presentation object state
 * @param {Array} series The array of objects that instructs the translation
 * layer how to plot the chart
 * @param {Object} presentation The existing presentation params (fontSize, annotations, etc)
 * @param {Array} aggregates The array of aggregates from the chart spec
 * @param {Array} bins The array of bins from the chart spec
 * @param {Object} fields The key/value pairs for the menu input fields
 * @param {String} type The chart type (ex. 'stacked_bar')
 * @returns {Object} The presentation object that instructs the translation
 * layer to add titles, font sizes, and misc other options like smoothing
 */
export const generatePresentation = (series, presentation, aggregates, bins, fields, type) => {
  // Deep copies so we don't mutate
  presentation = cloneDeep(presentation);
  series = cloneDeep(series);

  // The aggregate expression
  const expression = aggregates[0]?.expression;

  // Clear special case keys in presentation object
  // Will re-load later if still necessary
  delete presentation.horizontal;
  delete presentation.smooth;
  // Set colorOverride back to empty so we don't accidentally recolor
  presentation.colorOverride = [];

  // Parse fields for presentation keys/values
  Object.keys(fields).forEach((field) => {
    switch (field) {
      case 'smooth':
        presentation[field] = fields[field];
        break;
      default:
        break;
    }
  });

  // Handle special cases depending on chart type
  if (type === 'horizontal_bar') {
    presentation.horizontal = true;
  }

  const xAxisText = generateAxisTitle('x-axis', series, type, expression, bins);
  const yAxisText = generateAxisTitle('y-axis', series, type, expression, bins);
  const overlayText = generateAxisTitle('overlay', series, type, expression, bins);

  // Set the generated text in the presentation object
  presentation.xaxis.text = xAxisText;
  presentation.yaxis.text = yAxisText;
  presentation.overlayAxis.text = overlayText;

  // The only difference between series objects should be in the x/y-axis
  // So, here we can grab the mark/group from any one of them
  const { mark = {}, group = {} } = series[0] || {};

  // Generate the chart title
  const title = generateTitle(xAxisText, yAxisText, group, mark, fields, type, expression, bins);
  if (title !== undefined && title !== null) presentation.title.text = [title];

  return presentation;
};
