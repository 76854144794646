import CancelIcon from '@mui/icons-material/Cancel';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import PopupState from 'material-ui-popup-state';
import { bindMenu, bindTrigger } from 'material-ui-popup-state/hooks';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { IoSettingsOutline } from 'react-icons/io5';
import '../ChartBuilder.scss';
import {
  AXIS_MAX_EXPRESSION,
  AXIS_MIN_EXPRESSION,
  AXIS_RANGE_EXPRESSION,
} from '../utils/constants';

// Columns must be numeric to configure their axis range
export const AXIS_CONFIG_TYPES = ['Integer', 'Float'];
// We can only configure axis range for these inputs
export const AXIS_CONFIG_INPUTS = ['x-axis', 'y-axis', 'overlay'];

// Colors used for the gear icon
const purple500 = '#791abf';
const greyMedDark = '4e5a5f';

// The default state for this component
const defaultValues = {
  axisMin: '',
  axisMax: '',
  minError: '',
  maxError: '',
};

const findTransform = (transforms, columnNames, expression) =>
  transforms.find(
    (t) => columnNames.includes(t.column) && t.expression === expression && t.axisConfig,
  );

// Component to configure an 'axis range' transform for the AXIS_CONFIG_INPUTS
const AxisConfig = (props) => {
  const { applyAxisConfig, columnNames, columnsTypes, inputName, transforms } = props;

  // State for the axis min/max values and any errors
  const [axisMin, setAxisMin] = useState(defaultValues.axisMin);
  const [axisMax, setAxisMax] = useState(defaultValues.axisMax);
  const [minError, setMinError] = useState(defaultValues.minError);
  const [maxError, setMaxError] = useState(defaultValues.maxError);

  // Look for any existing axis range transforms for the current input
  const appliedTransform = findTransform(transforms, columnNames, AXIS_RANGE_EXPRESSION);
  const minTransform = findTransform(transforms, columnNames, AXIS_MIN_EXPRESSION);
  const maxTransform = findTransform(transforms, columnNames, AXIS_MAX_EXPRESSION);

  // When the component mounts, set the axis min/max values to the applied transform or default
  useEffect(() => {
    setAxisMin(minTransform?.value[0] || appliedTransform?.value[0] || defaultValues.axisMin);
    setAxisMax(maxTransform?.value[0] || appliedTransform?.value[1] || defaultValues.axisMax);
  }, [appliedTransform, minTransform, maxTransform]);

  // Resets all of the state in this component to default
  const resetComponentState = () => {
    setAxisMin(defaultValues.axisMin);
    setAxisMax(defaultValues.axisMax);
    setMinError(defaultValues.minError);
    setMaxError(defaultValues.maxError);
  };

  // Validate that the user entered a valid number for the min/max
  const validateInput = (userInput) => (Number.isNaN(Number(userInput)) ? 'Must be a number' : '');

  // Validate the min/max user inputs when they change
  useEffect(() => {
    setMinError(validateInput(axisMin));
    setMaxError(validateInput(axisMax));
  }, [axisMin, axisMax]);

  // Can we perform axis configuration via this form on the current input?
  const isAxisConfigurable = () => {
    return (
      columnNames &&
      columnNames.length !== 0 &&
      AXIS_CONFIG_INPUTS.includes(inputName) &&
      columnNames.every((columnName) => {
        const colType = columnsTypes.find((col) => col.name === columnName)?.type;
        return AXIS_CONFIG_TYPES.includes(colType);
      })
    );
  };

  // Should we enable the clear button?
  const isClearValid = () => axisMin !== '' || axisMax !== '' || appliedTransform;

  // Clear the current axis range
  const handleClear = (popupState) => {
    popupState.close();
    applyAxisConfig({ clearTransforms: true, columnNames });
    resetComponentState();
  };

  const hasAppliedTransform = appliedTransform || minTransform || maxTransform;
  const transformDisabled =
    hasAppliedTransform &&
    (minTransform?.disabled || maxTransform?.disabled || appliedTransform?.disabled);

  // Verify that we specified the min or max without any errors
  const isSubmissionValid = () =>
    (axisMin !== '' && minError === '') || (axisMax !== '' && maxError === '');

  // Apply the new axis range and close the menu
  const handleSubmit = (popupState) => {
    applyAxisConfig({ axisMin, axisMax, columnNames });
    popupState.close();
  };

  return (
    isAxisConfigurable() && (
      <PopupState variant="popover">
        {(popupState) => (
          <>
            <Tooltip title="Axis configuration">
              <IconButton
                {...bindTrigger(popupState)}
                data-cy={`${inputName}-config`}
                data-testid={`${inputName}-config`}
              >
                <IoSettingsOutline
                  size={18}
                  color={hasAppliedTransform ? purple500 : greyMedDark}
                />
              </IconButton>
            </Tooltip>
            <Menu
              className="ChipDropdown"
              {...bindMenu(popupState)}
              sx={{ overflow: 'visible' }}
              data-testid="axis-config-menu"
            >
              <IconButton className="close-button" onClick={popupState.close}>
                <CancelIcon />
              </IconButton>
              <div className="axis-config-row">
                <div className="label">Axis Min</div>
                <TextField
                  key="axisMin"
                  data-cy="axisMin-input"
                  data-testid="axisMin-input"
                  placeholder={`${columnNames.join(', ')}`}
                  error={minError !== ''}
                  label={minError}
                  sx={{ width: '100%' }}
                  variant="outlined"
                  value={axisMin}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  onChange={(e) => setAxisMin(e.target.value)}
                  onKeyDown={(e) => e.stopPropagation()}
                />
              </div>
              <div className="axis-config-row">
                <div className="label">Axis Max</div>
                <TextField
                  key="axisMax"
                  data-cy="axisMax-input"
                  data-testid="axisMax-input"
                  placeholder={`${columnNames.join(', ')}`}
                  error={maxError !== ''}
                  label={maxError}
                  sx={{ width: '100%' }}
                  variant="outlined"
                  value={axisMax}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  onChange={(e) => setAxisMax(e.target.value)}
                  onKeyDown={(e) => e.stopPropagation()}
                />
              </div>
              <div className="axis-config-row-buttons">
                <Button
                  onClick={() => handleClear(popupState)}
                  data-testid="clear-axis-config"
                  color="primary"
                  variant="outlined"
                  size="small"
                  disabled={!isClearValid()}
                >
                  Clear
                </Button>
                <Button
                  onClick={() => handleSubmit(popupState)}
                  data-testid="apply-axis-config"
                  color="primary"
                  variant="outlined"
                  size="small"
                  disabled={!isSubmissionValid()}
                  style={{ marginLeft: '5px' }}
                >
                  {transformDisabled ? 'Enable' : 'Apply'}
                </Button>
              </div>
            </Menu>
          </>
        )}
      </PopupState>
    )
  );
};

AxisConfig.propTypes = {
  // The function to apply the current axis config to the chartSpec
  applyAxisConfig: PropTypes.func,
  // The names of the column selected in the current input, ex. ['Fare', 'PassengerId']
  columnNames: PropTypes.array,
  // The column/type information in array of object format, ex. [{ name: 'Age', type: 'Float' }]
  columnsTypes: PropTypes.array.isRequired,
  // The name of the current input, ex. 'x-axis', 'group'
  inputName: PropTypes.string,
  // Specific transforms to apply to the dataset
  transforms: PropTypes.array,
};

AxisConfig.defaultProps = {
  applyAxisConfig: () => {},
  columnNames: [],
  inputName: null,
  transforms: [],
};

export default AxisConfig;
