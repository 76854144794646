import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface LoadCardState {
  error: string | null;
  loading: boolean;
  showLoadCard: boolean;
}

const initialState: LoadCardState = {
  error: null,
  loading: false,
  showLoadCard: false,
};

const loadCardSlice = createSlice({
  name: 'loadCard',
  initialState,
  reducers: {
    openLoadCard(state, { payload }: PayloadAction<{ showWhileLoading?: boolean }>) {
      state.error = null;
      state.loading = true;
      // Default to show the load card while loading the object info
      state.showLoadCard = payload.showWhileLoading ?? true;
    },
    openLoadCardSuccess(state) {
      state.loading = false;
      state.showLoadCard = true;
    },
    openLoadCardFailure: (state, { payload }: PayloadAction<{ error: Error }>) => {
      state.loading = false;
      state.error = payload.error.message;
    },
    closeLoadCard(state) {
      state.error = null;
      state.loading = false;
      state.showLoadCard = false;
    },
  },
});

export const { openLoadCard, closeLoadCard, openLoadCardFailure, openLoadCardSuccess } =
  loadCardSlice.actions;

export default loadCardSlice.reducer;
