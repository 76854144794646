import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

/**
 * A Functional Componemnt to render the alert infomation under the header of Echart
 */
const AlertBanner = (props) => {
  const { alertOpen, alertText } = props;

  if (!alertText) {
    return null;
  }

  return (
    // TODO: ideally we should show these alerts in Graph mode, but there is a sizing bug to resolve:
    <div className="AlertDiv" data-cy="Alert-Banner" data-testid="Alert-Banner">
      <Collapse in={alertOpen} className="AlertCollapse">
        <Alert severity="info" className="AlertText">
          {alertText}
        </Alert>
      </Collapse>
    </div>
  );
};

AlertBanner.propTypes = {
  alertOpen: PropTypes.bool.isRequired,
  alertText: PropTypes.string,
};

AlertBanner.defaultProps = {
  alertText: undefined,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {})(AlertBanner);
