import React from 'react';
import Draggable from 'react-draggable';

import Close from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import useTheme from '@mui/material/styles/useTheme';
// eslint-disable-next-line no-restricted-syntax
import { SxProps } from '@mui/system';

export interface PrimaryProps {
  onClick: () => void;
  label: string;
  disabled?: boolean;
}

export interface ModalProps {
  children: React.ReactNode;
  closeProps: { onClick: () => void; label?: string; button?: boolean };
  primaryProps?: PrimaryProps;
  title: string;
  maxWidth?: DialogProps['maxWidth'];
  whiteBackground: boolean;
  dialogSx?: SxProps;
}

const PaperComponent = (props: any) => {
  return (
    <Draggable
      handle=".drag-handle"
      cancel='[class*="MuiDialogContent-root"], [class*="MuiDialogActions-root"]'
    >
      <div {...props} />
    </Draggable>
  );
};

const Modal = (props: ModalProps) => {
  const { children, closeProps, primaryProps, title, maxWidth, whiteBackground, dialogSx } = props;
  const [isDragging, setIsDragging] = React.useState(false);

  const theme = useTheme();

  return (
    <Dialog
      maxWidth={maxWidth}
      onMouseUp={() => setIsDragging(false)}
      fullWidth
      open
      PaperComponent={PaperComponent}
      PaperProps={{
        sx: {
          backgroundColor: whiteBackground
            ? theme.palette.background.paper
            : theme.palette.background.default,
          borderRadius: '0.8rem',
          ...dialogSx,
        },
      }}
    >
      <DialogTitle
        className="drag-handle"
        onMouseDown={() => setIsDragging(true)}
        sx={{
          color: theme.palette.secondary.light,
          fontSize: 24,
          fontWeight: 'bold',
          letterSpacing: 1,
          userSelect: 'none',
          cursor: isDragging ? 'grabbing' : 'grab',
        }}
      >
        {title}
        <IconButton
          data-testid="close-modal"
          onClick={closeProps.onClick}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        {closeProps.button && <Button onClick={closeProps.onClick}>{closeProps.label}</Button>}
        {primaryProps && (
          <Button
            disabled={primaryProps.disabled}
            variant="contained"
            sx={{ textTransform: 'none' }}
            onClick={primaryProps.onClick}
          >
            {primaryProps.label}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

Modal.defaultProps = {
  maxWidth: 'md',
  primaryProps: null,
  dialogSx: undefined,
};

export default Modal;
