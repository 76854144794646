import {
  FILE_DOWNLOAD_FAILURE,
  FILE_DOWNLOAD_REQUEST,
  FILE_DOWNLOAD_SUCCESS,
} from '../actions/file_download.actions';

export const initialState = {
  isRequesting: false, // Indicates if the response is still pending.
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FILE_DOWNLOAD_REQUEST:
      return { ...state, isRequesting: true };
    case FILE_DOWNLOAD_SUCCESS:
      return { ...state, isRequesting: false };
    case FILE_DOWNLOAD_FAILURE:
      return { ...state, isRequesting: false };
    default:
      return state;
  }
};
