/* Page Size */
export const DEFAULT_TABLE_EXPANSION_LIMIT = 5;
export const INSIGHTS_TABLE_EXPANSION_LIMIT = 10;
export const AVA_TABLE_EXPANSION_LIMIT = 10;
export const TABBED_TABLE_EXPANSION_LIMIT = 20;
export const PAGE_SIZES = [DEFAULT_TABLE_EXPANSION_LIMIT, 10, 20, 30, 40, 50];
export const TABLE_PADDING = 3;

/* Row Height */
export const ESTIMATED_ROW_HEIGHT = 37;
export const COLUMN_HEADER_HEIGHT = 40;
export const PAGINATION_HEIGHT = 53;

/* Column Width */
export const MAX_COLUMN_WIDTH = 500;
export const MARGIN_WIDTH = 16;
export const PAGINATION_WIDTH = 450;
export const COLUMN_HEADER_WIDTH_ADJUSTMENT = 80;
export const DEFAULT_FIXED_WIDTH = 130;

/* Cell Limit */
export const MAX_CELL_LIMIT = 10000;

export const ESTIMATED_FOOTER_HEIGHT = 53;

/* Table text */
export const NO_ROWS_TEXT = 'No Rows.';

/* Cell Types */
export const CELL_TYPES = {
  TABLE: 'nested table',
  NULL: 'null',
  LINK: 'link text',
  PLAIN_TEXT: 'regular text',
  JSON: 'json object',
};

export const calculateTableHeight = (totalRowCount, expansionLimit, tableState) => {
  const rowCount = totalRowCount === 0 ? 3 : totalRowCount;
  const rowHeight = Math.min(rowCount, expansionLimit) * ESTIMATED_ROW_HEIGHT;
  let height = rowHeight + COLUMN_HEADER_HEIGHT;
  if (rowCount > expansionLimit) {
    if (tableState?.columnsState?.pagination) {
      const { pageSize } = tableState?.columnsState?.pagination;
      height = pageSize * ESTIMATED_ROW_HEIGHT + COLUMN_HEADER_HEIGHT;
    }
    height += ESTIMATED_FOOTER_HEIGHT;
  }
  return height || DEFAULT_FIXED_WIDTH;
};

const calculateTableWidth = (tableState, columnLength) => {
  let width = 0;
  if (tableState?.columnsState?.columns?.dimensions) {
    const { dimensions } = tableState?.columnsState?.columns;
    Object.values(dimensions).forEach((column) => {
      width += column.width;
    });
  } else {
    width = columnLength * DEFAULT_FIXED_WIDTH;
  }
  width = Math.max(width, PAGINATION_WIDTH);
  return width || PAGINATION_WIDTH;
};

export const calculateTableDimensions = (
  rowCount,
  tableState,
  columnLength,
  expansionLimit = 5,
) => {
  const height = calculateTableHeight(rowCount, expansionLimit, tableState);
  const width = columnLength ? calculateTableWidth(tableState, columnLength) : '100%';
  return [height, width];
};
