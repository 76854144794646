import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OAuthPassthroughState } from '../../components/OAuthButtons/oauth.types';
import { RequestStatus } from '../../types/databaseBrowser.types';
import {
  Integration,
  IntegrationProvider,
  IntegrationsState,
} from '../../types/integrations.types';

export const initialState: IntegrationsState = {
  open: false,
  reAuthenticationDialog: {
    open: false,
    provider: undefined,
    detail: null,
  },
  integrations: {},
  requestStatus: RequestStatus.Unrequested,
};

const integrationsSlice = createSlice({
  name: 'integrations',
  initialState,
  reducers: {
    openIntegrationsMenu: (state) => {
      state.open = true;
    },

    putOAuthRequest: {
      reducer: () => {},
      prepare: ({
        passthroughState,
        code,
      }: {
        passthroughState: OAuthPassthroughState;
        code: string;
      }) => {
        return {
          payload: { passthroughState, code },
        };
      },
    },

    disconnectIntegrationRequest: (state, { payload }: PayloadAction<IntegrationProvider>) => {
      const integration = payload;
      if (state.integrations[integration]) state.integrations[integration].deleting = true;
    },
    disconnectIntegrationSuccess: (state, { payload }: PayloadAction<IntegrationProvider>) => {
      const integration = payload;
      delete state.integrations[integration];
    },
    disconnectIntegrationFailure: (state, { payload }: PayloadAction<IntegrationProvider>) => {
      const integration = payload;
      if (state.integrations[integration]) state.integrations[integration].deleting = false;
    },

    getIntegrationsRequest: (state: IntegrationsState) => {
      state.requestStatus = RequestStatus.Requesting;
    },
    getIntegrationsSuccess: (
      state: IntegrationsState,
      { payload }: PayloadAction<Partial<Record<IntegrationProvider, Integration>>>,
    ) => {
      state.requestStatus = RequestStatus.Success;
      state.integrations = payload;
    },
    getIntegrationsFailure: (state: IntegrationsState) => {
      state.requestStatus = RequestStatus.Failure;
    },

    closeIntegrationsMenu: (state) => {
      state.open = false;
    },

    closeReAuthenticationDialog: (state) => {
      state.reAuthenticationDialog.open = false;
      state.reAuthenticationDialog.provider = undefined;
    },
    openReAuthenticationDialog: (
      state,
      { payload }: PayloadAction<{ provider: IntegrationProvider; detail: string | null }>,
    ) => {
      state.reAuthenticationDialog.open = true;
      state.reAuthenticationDialog.provider = payload.provider;
      state.reAuthenticationDialog.detail = payload.detail;
    },
  },
});

export const {
  openIntegrationsMenu,
  closeIntegrationsMenu,
  putOAuthRequest,
  disconnectIntegrationRequest,
  disconnectIntegrationSuccess,
  disconnectIntegrationFailure,
  getIntegrationsRequest,
  getIntegrationsSuccess,
  getIntegrationsFailure,
  closeReAuthenticationDialog,
  openReAuthenticationDialog,
} = integrationsSlice.actions;

export default integrationsSlice.reducer;
