/**
 * @fileoverview This file contains the definitions for different buttons used throughout the app
 * Reuse the button structure here, and the existing definitions when possible.
 */
import Cable from '@mui/icons-material/Cable';
import FileUpload from '@mui/icons-material/FileUpload';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import React from 'react';
// no default exports so need to disable eslint rule
// eslint-disable-next-line
import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/types';
import { AppDispatch } from '../configureStore';
import { openFileModal } from '../store/actions/upload.actions';
import { openDatabaseBrowser } from '../store/slices/dbBrowser.slice';

// Defines a dispatched action on a button click
export type ButtonAction = (dispatch: AppDispatch, args?: unknown) => void;

/**
 * Definition for each dropdown option of complex buttons' dropdowns.
 */
export type DropdownDefinition = {
  // Action when clicking this option in the dropdown list
  action: ButtonAction;
  // Icon for the button
  icon: OverridableComponent<SvgIconTypeMap>;
  // Class of the icon
  iconClassName: string;
  // Text of the button - Should match the parent key
  text: string;
};

/**
 * Definition for complex buttons.
 * These buttons may contain a primary action and a dropdown of additional actions
 */
export type ButtonOptions = {
  primary?: Record<string, ButtonAction>;
  dropdown?: Record<string, DropdownDefinition>;
};

// 'Connect' button opens the database browser
export const connectionButtonDefinition: DropdownDefinition = {
  action: (dispatch: AppDispatch) => dispatch(openDatabaseBrowser({ connection: null })),
  icon: Cable,
  iconClassName: 'ConnectIcon',
  text: 'Connect',
};

// 'Upload' button opens the file upload modal
export const uploadButtonDefinition: DropdownDefinition = {
  action: (dispatch: AppDispatch) => dispatch(openFileModal()),
  icon: FileUpload,
  iconClassName: 'UploadIcon',
  text: 'Upload',
};

// Gets the DropdownDefinition button as an icon & label text combination
export const getLabelWithIcon = ({
  icon: Icon,
  iconClassName,
  text,
}: DropdownDefinition): JSX.Element => (
  <>
    <ListItemIcon className={iconClassName}>
      <Icon />
    </ListItemIcon>
    <ListItemText primary={text} />
  </>
);
