import { ECHARTS_DATACHAT_THEME } from 'translate_dc_to_echart';
import { DEFAULT_USER_CONFIG, HOME_MODES } from '../../constants';
import { SETTINGS_VIEW } from '../../constants/settings';
import { THEME_STANDARD } from '../../constants/themes/themes';
import {
  CHANGE_SETTINGS_VIEW,
  CLOSE_EDITPAD,
  CLOSE_HELP_DROP_DOWN_MENU,
  CLOSE_INSIGHTSBOARD_SHARE_MENU,
  CLOSE_INSIGHTS_BOARD_CREATOR,
  CLOSE_NETWORK_STATUS,
  CLOSE_PROFILE_DROP_DOWN_MENU,
  CLOSE_SELECT_SAVE_CARD,
  CLOSE_SETTINGS_MENU,
  CLOSE_UPLOAD_FILE_MANAGER,
  CLOSE_USER_SELECTOR,
  CLOSE_UTTERANCE_EXT_TIMEOUT_FORM,
  GET_USER_CONFIG_FAILURE,
  GET_USER_CONFIG_REQUEST,
  GET_USER_CONFIG_SUCCESS,
  GET_USER_RECORD_FAILURE,
  GET_USER_RECORD_REQUEST,
  GET_USER_RECORD_SUCCESS,
  HAS_OPENED_UPLOAD_FILE_MANAGER_ONCE,
  MODIFY_ORGANIZATION_USERS_SELECT_STATE,
  OPEN_EDITPAD,
  OPEN_HELP_DROP_DOWN_MENU,
  OPEN_INSIGHTSBOARD_SHARE_MENU,
  OPEN_INSIGHTS_BOARD_CREATOR,
  OPEN_PROFILE_DROP_DOWN_MENU,
  OPEN_SELECT_SAVE_CARD,
  OPEN_SETTINGS_MENU,
  OPEN_UPLOAD_FILE_MANAGER,
  OPEN_USER_SELECTOR,
  OPEN_UTTERANCE_EXT_TIMEOUT_FORM,
  SET_HOME_MODE,
  SET_OFFICE_HOURS_MENU_VISIBILITY,
  SET_ORGANIZATION_USERS_SELECT_STATE,
  SET_THEME,
  SHOW_NETWORK_STATUS,
  TOGGLE_SUGGESTIONS_ANALYSIS,
  UPDATE_USER_CONFIG_FAILURE,
  UPDATE_USER_CONFIG_REQUEST,
  UPDATE_USER_CONFIG_SUCCESS,
} from '../actions/settings.actions';
import { exitSessionRequest } from '../slices/session.slice';

export const settingsInitialState = {
  homeMode: HOME_MODES.app, // Indicates the current mode in the home screen.
  isHelpDropDownMenuOpen: false, // Indicates if the drop down menu is open
  isProfileDropDownMenuOpen: false, // Indicates if the profile drop down menu is open
  userConfig: DEFAULT_USER_CONFIG,
  organizationID: 0, // Indicates the ID of the user's organization
  organizationName: '', // Indicates the name of the user's organization
  error: undefined, // Error message
  isRequesting: false, // Indicates if the request is pending
  hasRequestedUserConfig: false, // Indicates if the client has already requested for user configurations. This should only be called once.
  hasRequestedUserRecord: false, // Indicates if the client has already requested for the user record from database.
  showUploadFileManager: false, // Indicates if the upload file manager should be open.
  showEditPad: false,
  hasOpenedUploadFileManagerOnce: false, // Indicates if the upload file manager has already been opened once. Used to render the upload file manager only when the user first opens the upload file manager.
  showInsightsBoardShareMenu: false, // Indicates if the insights board share menu should be open.
  showSettingsMenu: false, // Indicates if settings menu should be open.
  showUserSelectMenu: false, // Indicates if user select menu should be open.
  organizationUsersSelectState: [], // Array of users in organization. This is used to notify users when a new notification is created.
  settingsMenuView: SETTINGS_VIEW.sessions, // The current selected settings view.
  connectionId: undefined, // Requested connection to edit
  onSuggestionsAnalysis: false, // Indicates if the response time for the suggestions API call should be displayed in the suggestions pop up.
  showNetworkStatus: false, // Indicates that there is a network error
  showInsightsBoardCreator: false, // Indicates if the dashboard creator should be open.
  showSelectSaveCard: false, // Indicates if the modal for saving a trimmed workflow should be open.
  theme: THEME_STANDARD,
  chartTheme: ECHARTS_DATACHAT_THEME,
  showUtteranceTimeoutExtForm: false,
  utteranceTimeoutMessage: undefined,
  utteranceAutoInterruptTime: undefined,
  showOfficeHoursMenu: false,
};

/**
 * @param {import('../../types/settings.types').SettingsState} state
 * @param {any} action
 */
export default (state = settingsInitialState, action) => {
  switch (action.type) {
    case OPEN_HELP_DROP_DOWN_MENU:
      return { ...state, isHelpDropDownMenuOpen: true };
    case CLOSE_HELP_DROP_DOWN_MENU:
      return { ...state, isHelpDropDownMenuOpen: false };
    case CLOSE_PROFILE_DROP_DOWN_MENU:
      return { ...state, isProfileDropDownMenuOpen: false };
    case OPEN_PROFILE_DROP_DOWN_MENU:
      return { ...state, isProfileDropDownMenuOpen: true };
    case GET_USER_CONFIG_REQUEST:
      return { ...state, isRequesting: true, hasRequestedUserConfig: true };
    case GET_USER_CONFIG_SUCCESS:
      return {
        ...state,
        userConfig: action.config,
        isRequesting: false,
      };
    case GET_USER_CONFIG_FAILURE:
      return {
        ...state,
        error: action.error,
        isRequesting: false,
        userConfig: DEFAULT_USER_CONFIG,
        hasRequestedUserConfig: false,
      };
    case UPDATE_USER_CONFIG_REQUEST:
      return { ...state, isRequesting: true, hasRequestedUserConfig: true };
    case UPDATE_USER_CONFIG_SUCCESS:
      return {
        ...state,
        userConfig: action.config,
        isRequesting: false,
      };
    case UPDATE_USER_CONFIG_FAILURE:
      return {
        ...state,
        error: action.error,
        isRequesting: false,
      };
    case GET_USER_RECORD_REQUEST:
      return { ...state, hasRequestedUserRecord: true };
    case GET_USER_RECORD_SUCCESS:
      return {
        ...state,
        phoneNumber: action.phoneNumber,
        organizationID: action.organizationID,
        organizationName: action.organizationName,
      };
    case GET_USER_RECORD_FAILURE:
      return {
        ...state,
        error: action.error,
        hasRequestedUserRecord: false,
      };
    case TOGGLE_SUGGESTIONS_ANALYSIS:
      return { ...state, onSuggestionsAnalysis: !state.onSuggestionsAnalysis };
    case OPEN_UPLOAD_FILE_MANAGER:
      return {
        ...state,
        showUploadFileManager: true,
      };
    case CLOSE_UPLOAD_FILE_MANAGER:
      return {
        ...state,
        showUploadFileManager: false,
      };
    case HAS_OPENED_UPLOAD_FILE_MANAGER_ONCE:
      return {
        ...state,
        hasOpenedUploadFileManagerOnce: true,
      };
    case OPEN_EDITPAD:
      return {
        ...state,
        showEditPad: true,
      };
    case CLOSE_EDITPAD:
      return {
        ...state,
        showEditPad: false,
      };
    case OPEN_INSIGHTSBOARD_SHARE_MENU:
      return { ...state, showInsightsBoardShareMenu: true };
    case CLOSE_INSIGHTSBOARD_SHARE_MENU:
      return { ...state, showInsightsBoardShareMenu: false };
    case OPEN_INSIGHTS_BOARD_CREATOR:
      return {
        ...state,
        showInsightsBoardCreator: true,
      };
    case CLOSE_INSIGHTS_BOARD_CREATOR:
      return {
        ...state,
        showInsightsBoardCreator: false,
      };
    case OPEN_SELECT_SAVE_CARD:
      return { ...state, showSelectSaveCard: true };
    case CLOSE_SELECT_SAVE_CARD:
      return { ...state, showSelectSaveCard: false };
    case SET_HOME_MODE:
      return { ...state, homeMode: action.homeMode };
    case SHOW_NETWORK_STATUS:
      return { ...state, showNetworkStatus: true };
    case CLOSE_NETWORK_STATUS:
      return { ...state, showNetworkStatus: false };
    case OPEN_SETTINGS_MENU:
      return { ...state, showSettingsMenu: true, settingsMenuView: action.view };
    case CHANGE_SETTINGS_VIEW:
      return { ...state, settingsMenuView: action.view, connectionId: action.connectionId };
    case CLOSE_SETTINGS_MENU:
      return { ...state, showSettingsMenu: false };
    case OPEN_USER_SELECTOR:
      return { ...state, showUserSelectMenu: true };
    case CLOSE_USER_SELECTOR:
      return { ...state, showUserSelectMenu: false };
    case SET_ORGANIZATION_USERS_SELECT_STATE:
      return { ...state, organizationUsersSelectState: action.users };
    case MODIFY_ORGANIZATION_USERS_SELECT_STATE:
      return {
        ...state,
        organizationUsersSelectState: state.organizationUsersSelectState.map((user) => {
          if (user.email === action.email) {
            return { ...user, selected: action.selected };
          }
          return user;
        }),
      };

    case SET_THEME:
      return { ...state, theme: action.theme };
    case OPEN_UTTERANCE_EXT_TIMEOUT_FORM:
      return {
        ...state,
        showUtteranceTimeoutExtForm: true,
        utteranceTimeoutMessage: action.message,
        utteranceAutoInterruptTime: action.autoInterruptTime,
      };
    case CLOSE_UTTERANCE_EXT_TIMEOUT_FORM:
      return {
        ...state,
        showUtteranceTimeoutExtForm: false,
        utteranceTimeoutMessage: undefined,
        utteranceAutoInterruptTime: undefined,
      };
    case exitSessionRequest.type:
      return {
        ...state,
        showUtteranceTimeoutExtForm: false,
        utteranceTimeoutMessage: undefined,
        utteranceAutoInterruptTime: undefined,
      };
    case SET_OFFICE_HOURS_MENU_VISIBILITY:
      return {
        ...state,
        showOfficeHoursMenu: action.open,
      };

    default:
      return state;
  }
};
