import PropTypes from 'prop-types';
import React from 'react';
import { TABLE_PADDING } from '../../DisplayPanel/Charts/Table/constants';
import './DataChatDataGridTheme.scss';

/**
 * Theming component for DataGridPro
 */
const DataChatDataGridTheme = (props) => {
  const { children, className, height } = props;
  const combinedClassName = `DataChatDataGridTheme${className ? ` ${className}` : ''}`;
  return (
    <div
      style={{ height: height ? height + TABLE_PADDING : undefined }}
      className={combinedClassName}
    >
      {' '}
      {children}
    </div>
  );
};

DataChatDataGridTheme.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
  height: PropTypes.number,
};

DataChatDataGridTheme.defaultProps = {
  className: '',
  height: undefined,
};

export default DataChatDataGridTheme;
