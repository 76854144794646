import { BAD_REQUEST, CONFLICT, FORBIDDEN, NOT_FOUND } from 'http-status-codes';
import {
  call,
  fork,
  getContext,
  put,
  select,
  spawn,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { createFilePondServerConfig } from '../../api/files.api';
import { API_SERVICES } from '../../constants/api';
import { HOME_OBJECTS } from '../../constants/home_screen';
import { EXPERIMENTAL_REMOVED_OBJECTS } from '../../constants/home_screen_temp';
import { isChatPage, isDataChatSessionPage } from '../../constants/paths';
import { loadDatasets, loadDatasetsKwargs } from '../../constants/utterance_templates';
import { authenticate } from '../../utils/authenticate';
import { HomeObjects } from '../../utils/homeScreen/types';
import { createAlertChannelRequest } from '../actions/dialog.actions';
import { getHomeScreenObjectsRequest } from '../actions/home_screen.actions';
import { sendMessageRequest } from '../actions/messages.actions';
import {
  FILE_UPLOAD_CONFLICT,
  FILE_UPLOAD_FAILURE,
  FILE_UPLOAD_REQUEST,
  FILE_UPLOAD_SUCCESS,
  LOAD_IMMEDIATELY,
  fileUploadConflict,
  fileUploadFailure,
  fileUploadSuccess,
  loadImmediatelyAlert,
} from '../actions/upload.actions';
import { addUploadedDatasets } from '../reducers/datasetCreator.reducer';
import { selectSession } from '../selectors/session.selector';
import { listFilesRequestWorker } from './file_manager.saga';
import {
  selectAccessToken,
  selectDatasetsLoadImmediately,
  selectExperimentalFlag,
  selectFilesLoadImmediately,
} from './selectors';
import {
  fileAlreadyExistsWorker,
  fileBadRequestWorker,
  fileInvalidNameWorker,
  filesAlreadyExistWorker,
  uploadConnectiontAlert as uploadConnectionAlert,
} from './utils/alert-channels';
import { putFileConflictHandler, putFileResponseHandler } from './utils/upload';

// Error message received from the FilePond when a file name contains invalid characters
export const INVALID_CHARACTER_ERROR =
  "Failed to execute 'setRequestHeader' on 'XMLHttpRequest': String contains non ISO-8859-1 code point.";

// Error message thrown when a file name is generically invalid
export const INVALID_FILE_NAME_ERROR = 'Invalid file name.';

/**
 * Duplicated from isValidFileName() in management_server/web-server/api/file.go
  1. Check if the start and end character is not one of [/, :, *, ", /, \, |, .]
  2. Check if the filename has at least three characters
  3. Check if the filename has at least one character that is not [/, :, *, ", /, \, |, .]
  and that the character is followed by at least one character that is not [/, :, *, ", /, \, |, .]
 * @param {String} fileName The name of the file without the extension
 * @returns {Boolean} True if the filename is valid, false otherwise
*/
const isValidFileName = (fileName) => {
  return /^[^\\\\/\\:\\*\\?"\\<\\>\\|\\.]+(.[^\\\\/\\:\\*\\?"\\<\\>\\|\\.]+)+$/.test(fileName);
};

export function* loadImmediatelyWorker() {
  const sessionID = yield select(selectSession);
  const datasetsToLoad = yield select(selectDatasetsLoadImmediately);
  const filesToLoad = yield select(selectFilesLoadImmediately);
  const devMode = yield select(selectExperimentalFlag);

  const shouldLoadFiles = EXPERIMENTAL_REMOVED_OBJECTS.has(HomeObjects.DATAFILE) && !devMode;
  const shouldLoadDatasets = !shouldLoadFiles;

  if (shouldLoadFiles && filesToLoad.length > 0) {
    for (const file of filesToLoad) {
      // submit an utterance to "Load data from the file " + file
      yield put(
        sendMessageRequest({
          message: `Load data from the file <strong>${file}</strong>`,
          sessionID,
        }),
      );
    }
  }

  if (shouldLoadDatasets && datasetsToLoad.length > 0) {
    const datasetNames = datasetsToLoad.map((dataset) => dataset.name);
    yield put(
      sendMessageRequest({
        message: loadDatasets(datasetNames),
        sessionID,
        utteranceMetadata: loadDatasetsKwargs(datasetsToLoad),
      }),
    );
  }
}

export function* uploadRequestWorker({ filePond, file, isCache }) {
  try {
    // New overwrite implementation note:
    // The overwrite condition of a file is no longer set using FilePond configuration.
    // FilePond's configuration is limited in that the overwrite condition was shared by
    // file uploads. This allowed file uploads to piggy-back off of the overwrite condition
    // being set to true by a previous file upload.
    // The new solution is to set a 'overwrite' condition in each file's metadata.
    // This can be done with a FilePond file object, such as below,
    // or by appending it to the FormData.

    // Initialize the overwrite condition to false in the file's metadata.
    yield call(file.setMetadata, 'overwrite', false);

    const accessToken = yield select(selectAccessToken);
    // Use accessToken in FilePond's Request Header
    filePond._pond.setOptions({
      server: createFilePondServerConfig(accessToken),
    });

    const splitFileName = file.filename.split('.');
    // Post-extension name (e.g. 'csv' in 'test.csv')
    const ext = splitFileName.pop();
    // Pre-extension name (e.g. 'test' in 'test.csv')
    const name = splitFileName.join('.');

    // Check whether file already exists in backend
    // If it does, a CONFLICT response status error will be thrown here.
    // If the file is a cache, no need to check as overriding it won't
    // be a big deal.
    // This check is only done for data files
    if (ext === 'conn') {
      yield call(uploadConnectionAlert);
      yield call(filePond.removeFile, file);
      return;
    }

    // If the file name is invalid throw the error
    if (!isValidFileName(name)) throw new Error(INVALID_FILE_NAME_ERROR);

    // Workflow files (.dcw) cannot be checked for conflicts using headFiles.
    // Because workflows are not stored in the file system, fileAlreadyExistsHandler (file.go)
    // returns OK response status by default for all workflow files.
    // The handler would need the appID in order to check for conflicts in the PG database.
    const uploadService = yield getContext(API_SERVICES.UPLOAD);
    if (!isCache && ext !== 'dcs' && ext !== 'dcw') {
      try {
        yield call(uploadService.headFiles, accessToken, file.filename);
        // eslint-disable-next-line no-throw-literal
        throw { response: { status: CONFLICT } };
      } catch (error) {
        if (error.response.status !== NOT_FOUND) {
          throw error;
        }
        // file doesn't exist, so we can proceed with the upload
      }
    }

    // Upload the File via FilePond.
    // Returns CONFLICT response status for workflow files if the file already exists.

    // Create dc_objects from the uploaded file via PUT files request
    let datafileId;
    try {
      const res = yield call(filePond.processFile, file);
      datafileId = res.serverId;
    } catch (error) {
      // If the error is a 403, the user has reached the file storage limit
      if (error.error && error.error.status === FORBIDDEN) {
        yield call(putFileConflictHandler, accessToken, file, error);
        return;
      }
      // If the error is not a 403, throw the error
      throw error;
    }

    const response = yield call(uploadService.putFiles, accessToken, datafileId);
    yield call(putFileResponseHandler, response.data);

    // Get the uploaded datasets from the put response and process them
    const uploadedDatasets = response.data
      .filter((datasetCreationStatus) => datasetCreationStatus?.error === '')
      .map((datasetCreationStatus) => {
        return {
          name: datasetCreationStatus.dataset_object_name,
          uuid: datasetCreationStatus.dataset_object_uuid,
        };
      });
    yield put(addUploadedDatasets(uploadedDatasets));
    yield put(fileUploadSuccess({ file, isCache }));
    if (isChatPage() || isDataChatSessionPage()) {
      yield put(loadImmediatelyAlert(uploadedDatasets, [file.filename]));
    }
    // Refresh the home screen objects
    yield put(getHomeScreenObjectsRequest({ objectType: HOME_OBJECTS.DATAFILE, refreshing: true }));
    yield put(getHomeScreenObjectsRequest({ objectType: HOME_OBJECTS.DATASET, refreshing: true }));
    yield put(getHomeScreenObjectsRequest({ objectType: HOME_OBJECTS.FOLDER, refreshing: true }));
    yield call(listFilesRequestWorker);
  } catch (error) {
    const { response } = error; // some errors contain response instead of nested error
    if (
      (error.error && error.error.code === BAD_REQUEST) ||
      (error.error && error.error.status === BAD_REQUEST) ||
      (response && response.status === BAD_REQUEST)
    ) {
      yield call(fileBadRequestWorker, error);
      yield put(fileUploadFailure({ file, error }));
    } else if (
      (error.error && error.error.code === CONFLICT) ||
      (error.error && error.error.status === CONFLICT) ||
      (response && response.status === CONFLICT)
    ) {
      // File already exists in the server.
      yield put(fileUploadConflict({ filePond, file }));
    } else if (
      error.message === INVALID_CHARACTER_ERROR ||
      error.message === INVALID_FILE_NAME_ERROR
    ) {
      const { filename } = file;
      yield call(filePond.removeFile, file);
      yield put(fileUploadFailure({ file, error }));
      yield call(fileInvalidNameWorker, filename);
    } else {
      // Generic error handling.
      yield call(filePond.removeFile, file);
      yield put(fileUploadFailure({ file, error }));
      yield put(createAlertChannelRequest({ error }));
    }
  }
}

/**
 * Tracks all files being processed by FilePond, and triggers an alert dialogue
 * to handle all file already exists conflicts.
 *
 * Files are added to the processingFiles list with every upload request (FILE_UPLOAD_REQUEST).
 * Files are removed when the upload process ends (FILE_UPLOAD_SUCCESS or FILE_UPLOAD_FAILURE).
 * Files are added to the conflictingFiles list when a conflict is detected (FILE_UPLOAD_CONFLICT).
 * An alert is only triggered once all processingFiles have been checked for conflicts.
 */
function* fileAlreadyExistsWatcher() {
  let processingFiles = []; // List of all files being processed
  let conflictingFiles = []; // List of all processing files with conflicts
  let sharedPond; // FilePond reference for handling conflicting files

  while (true) {
    // Yield on update to file processing status
    const action = yield take([
      FILE_UPLOAD_REQUEST,
      FILE_UPLOAD_CONFLICT,
      FILE_UPLOAD_SUCCESS,
      FILE_UPLOAD_FAILURE,
    ]);

    switch (action.type) {
      case FILE_UPLOAD_REQUEST:
        processingFiles = [...processingFiles, action.file];
        break;
      case FILE_UPLOAD_CONFLICT:
        conflictingFiles = [...conflictingFiles, action.file];
        sharedPond = action.filePond;
        break;
      case FILE_UPLOAD_SUCCESS:
        processingFiles = processingFiles.filter((file) => file !== action.file);
        break;
      case FILE_UPLOAD_FAILURE:
        processingFiles = processingFiles.filter((file) => file !== action.file);
        break;
      default:
        break;
    }

    // If processingFiles and conflictingFiles are the same size and non-empty,
    // then all remaining files to be processed have "file already exists" conflicts.
    if (processingFiles.length === conflictingFiles.length && conflictingFiles.length > 0) {
      if (conflictingFiles.length === 1) {
        yield spawn(
          fileAlreadyExistsWorker,
          sharedPond,
          conflictingFiles[0],
          fileUploadSuccess,
          fileUploadFailure,
          loadImmediatelyAlert,
        );
      } else {
        yield spawn(
          filesAlreadyExistWorker,
          sharedPond,
          conflictingFiles,
          fileUploadSuccess,
          fileUploadFailure,
          loadImmediatelyAlert,
        );
      }
      // Clear conflictingFiles and reset sharedPond
      conflictingFiles = [];
      sharedPond = undefined;
    }
  }
}

export default function* () {
  yield takeEvery(FILE_UPLOAD_REQUEST, authenticate(uploadRequestWorker, fileUploadFailure));
  yield takeLatest(LOAD_IMMEDIATELY, loadImmediatelyWorker);
  yield fork(fileAlreadyExistsWatcher);
}
