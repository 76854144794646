export const GET_DATASET_LIST_REQUEST = 'dataset/GET_DATASET_LIST_REQUEST';
export const GET_DATASET_LIST_SUCCESS = 'dataset/GET_DATASET_LIST_SUCCESS';
export const GET_DATASET_LIST_FAILURE = 'dataset/GET_DATASET_LIST_FAILURE';

export const RESET_DATASETS = 'dataset/RESET_DATASETS';
export const RETRIEVE_COLUMN_STATS_REQUEST = 'dataset/RETRIEVE_COLUMN_STATS_REQUEST';
export const RETRIEVE_COLUMN_STATS_SUCCESS = 'dataset/RETRIEVE_COLUMN_STATS_SUCCESS';
export const RETRIEVE_COLUMN_STATS_FAILURE = 'dataset/RETRIEVE_COLUMN_STATS_FAILURE';
export const RESET_COLUMN_STAT = 'dataset/RESET_COLUMN_STAT';
export const REMOVE_DATASET = 'dataset/SAMPLE_DATASET_REMOVE';
export const UPDATE_SELECTED_DATASET = 'dataset/UPDATE_SELECTED_DATASET';
export const UPDATE_TEMP_RENAME_VALUES = 'dataset/UPDATE_TEMP_RENAME_VALUES';

export const GET_BASE_DATASETS_REQUEST = 'dataset/GET_BASE_DATASETS_REQUEST';
export const GET_BASE_DATASETS_SUCCESS = 'dataset/GET_BASE_DATASETS_SUCCESS';
export const GET_BASE_DATASETS_FAILURE = 'dataset/GET_BASE_DATASETS_FAILURE';
export const RETRIEVE_FORGOTTEN_DATASET = 'dataset/RETRIEVE_FORGOTTEN_DATASET';

export const UPDATE_DATASET_REQUEST = 'dataset/UPDATE_DATASET_REQUEST';
export const UPDATE_DATASET_SUCCESS = 'dataset/UPDATE_DATASET_SUCCESS';
export const UPDATE_DATASET_FAILURE = 'dataset/UPDATE_DATASET_FAILURE';

export const SET_PIVOT_ERROR = 'chart/SET_PIVOT_ERROR';

export const SAMPLE_SESSION_DATASET_REQUEST = 'dataset/SAMPLE_SESSION_DATASET_REQUEST';
export const SAMPLE_SESSION_DATASET_SUCCESS = 'dataset/SAMPLE_SESSION_DATASET_SUCCESS';
export const SAMPLE_SESSION_DATASET_FAILURE = 'dataset/SAMPLE_SESSION_DATASET_FAILURE';

export const SAMPLE_DATASET_REQUEST = 'dataset/SAMPLE_DATASET_REQUEST';

export const COMPUTE_DATASET_REQUEST = 'dataset/COMPUTE_DATASET_REQUEST';
export const COMPUTE_DATASET_SUCCESS = 'dataset/COMPUTE_DATASET_SUCCESS';
export const COMPUTE_DATASET_FAILURE = 'dataset/COMPUTE_DATASET_FAILURE';

export const SET_SHOW_ANNOTATIONS = 'dataset/SET_SHOW_ANNOTATIONS';

export const retrieveForgottenDataset = ({
  datasetName,
  pipelinerDatasetId,
  sendUseUtternace = false,
  version,
}) => ({
  type: RETRIEVE_FORGOTTEN_DATASET,
  datasetName,
  pipelinerDatasetId,
  sendUseUtternace,
  version,
});

/**
 * Dispatches an action that updates the current list of datasets
 */
export const getDatasetList = () => ({
  type: GET_DATASET_LIST_REQUEST,
});

/**
 * Success of table update
 */
export const getDatasetListSuccess = ({ datasetList, hiddenDatasetList, currentDataset }) => ({
  type: GET_DATASET_LIST_SUCCESS,
  datasetList,
  hiddenDatasetList,
  currentDataset,
});

/**
 * Failure of table update
 */
export const getDatasetListFailure = ({ error }) => ({
  type: GET_DATASET_LIST_FAILURE,
  error,
});

export const resetDatasets = () => ({
  type: RESET_DATASETS,
});

/**
 * Runs a saga worker which determines which request to fire:
 *   sampleSessionDatasetRequest()
 *   sampleSessionlessDatasetRequest()
 *   computeDatasetRequest()
 *
 * This is necessary to wrap these requests in order to prevent setting
 * any loading statuses before actually deciding which request to fire
 *
 * @param {Object} params The params of the intended request: sampleSessionDatasetRequest,
 * sampleSessionlessDatasetRequest, or computeDatasetRequest. Along with the required params to
 * construct the reference string and sessionId, insightsBoardId, dcObjectId, publicationId (claims)
 */
export const sampleDatasetRequest = (params) => ({
  type: SAMPLE_DATASET_REQUEST,
  ...params,
});

/**
 * Request to retrieve data within a session. May be cancelled in favor of a larger request
 * for the same data via takeEachDataset.  Tables will be batched into their own pool of requests
 * to separate their shorter requests from the longer chart requests.
 *
 * @param {Function} callback Function to call after the request is complete
 * @param {String} dcChartId The dcChartId of the chart that is requesting the data
 * @param {Array} selectedColumns The columns to retrieve data for. Used for isFatTable optimization
 * @param {Boolean} isTable Is this a table request?
 * @param {Number} numRows The number of rows to retrieve. If undefined, retrieve all rows
 * @param {String} pipelinerDatasetId The pipeline ID of the dataset
 * @param {String} referenceString The reference string of the dataset
 * @param {String} workspaceUuid The uuid of the workspace object
 */
export const sampleSessionDatasetRequest = ({
  callback,
  dcChartId,
  selectedColumns,
  isTable = false,
  numRows,
  pipelinerDatasetId,
  referenceString,
  workspaceUuid,
}) => ({
  type: SAMPLE_SESSION_DATASET_REQUEST,
  callback,
  dcChartId,
  selectedColumns,
  isTable,
  numRows,
  pipelinerDatasetId,
  referenceString,
  workspaceUuid,
});

/**
 * Request to retrieve data via a BE compute.
 * Result data is indexed by: computeSpec, pipelinerDatasetId
 *
 * @param {Function} callback Callback function to trigger on success
 * @param {Object} computeSpec The compute spec for the dataset:
 * { aggregate, bins, transforms }
 * @param {String} insightsBoardId (Sessionless) The insights board ID
 * @param {String} pipelinerDatasetId Pipeline ID of the dataset
 * @param {String} publicationId (Sessionless) The publication ID
 * @param {Boolean} usedCompute Whether the request used the BE compute
 * @param {String} referenceString The reference string of the dataset
 * @param {String} workspaceUuid The uuid of the workspace object
 */
export const computeDatasetRequest = ({
  callback,
  computeSpec,
  insightsBoardId,
  pipelinerDatasetId,
  publicationId,
  referenceString,
  workspaceUuid,
  useCache,
}) => ({
  type: COMPUTE_DATASET_REQUEST,
  callback,
  computeSpec,
  insightsBoardId,
  pipelinerDatasetId,
  publicationId,
  usedCompute: true,
  referenceString,
  workspaceUuid,
  useCache,
});

export const sampleSessionDatasetSuccess = ({
  dcChartId = null,
  computeSpec,
  forgotten = false,
  isTable = false,
  pipelinerDatasetId,
  result,
  tableSampleRowCount,
  usedCompute,
}) => ({
  type: SAMPLE_SESSION_DATASET_SUCCESS,
  dcChartId,
  computeSpec,
  forgotten,
  isTable,
  pipelinerDatasetId,
  result,
  tableSampleRowCount,
  usedCompute,
});

export const sampleSessionDatasetFailure = ({
  dcChartId,
  computeSpec,
  error,
  isTable = false,
  pipelinerDatasetId,
  usedCompute,
}) => ({
  type: SAMPLE_SESSION_DATASET_FAILURE,
  dcChartId,
  computeSpec,
  error,
  isTable,
  pipelinerDatasetId,
  usedCompute,
});

export const retrieveColumnStatsRequest = ({ pipelinerDatasetId }) => ({
  type: RETRIEVE_COLUMN_STATS_REQUEST,
  pipelinerDatasetId,
});

export const retrieveColumnStatsSuccess = ({ data, pipelinerDatasetId }) => ({
  type: RETRIEVE_COLUMN_STATS_SUCCESS,
  data,
  pipelinerDatasetId,
});

export const retrieveColumnStatsFailure = () => ({
  type: RETRIEVE_COLUMN_STATS_FAILURE,
});

export const resetColumnStat = () => ({
  type: RESET_COLUMN_STAT,
});
export const removeDataset = ({ pipelinerDatasetId }) => ({
  type: REMOVE_DATASET,
  pipelinerDatasetId,
});

/**
 * Action for updating the selected dataset and/or version
 * @param {Object} param0 object with keywords datasetName, datasetVersion
 * @returns redux action of type UPDATE_SELECTED_DATASET
 */
export const updateSelectedDataset = ({
  datasetName = undefined,
  datasetVersion = undefined,
} = {}) => ({
  type: UPDATE_SELECTED_DATASET,
  datasetName,
  datasetVersion,
});

/**
 * Action for setting the temporary variables used for storing the old and new
 * name of a dataset being rename.
 * @param {*} param0 object with keywords newDatasetName, oldDatasetName
 * @returns redux action of type UPDATE_TEMP_RENAME_VALUES
 */
export const updateTempRenameValues = ({
  newDatasetName = undefined,
  oldDatasetName = undefined,
} = {}) => ({
  type: UPDATE_TEMP_RENAME_VALUES,
  newDatasetName,
  oldDatasetName,
});

export const getBaseDatasetsRequest = () => ({
  type: GET_BASE_DATASETS_REQUEST,
});

export const getBaseDatasetsSuccess = ({ baseDatasets }) => ({
  type: GET_BASE_DATASETS_SUCCESS,
  baseDatasets,
});

export const getBaseDatasetsFailure = ({ error }) => ({
  type: GET_BASE_DATASETS_FAILURE,
  error,
});

/**
 * Requests to replace a dataset object's dataset's pipeline with the one backing a certain dataset
 * name and version from a session.
 *
 * @param {Object} o Argument to be destructured
 * @param {Object} o.datasetObject Dataset object
 * @param {string} o.sessionId ID of session containing dataset to update with
 * @param {string} o.datasetName Name of dataset in session
 * @param {string} o.datasetVersion Version of dataset in session
 */
export function updateDatasetRequest({ datasetObject, sessionId, datasetName, datasetVersion }) {
  return {
    type: UPDATE_DATASET_REQUEST,
    datasetObject,
    sessionId,
    datasetName,
    datasetVersion,
  };
}

export const updateDatasetSuccess = ({ datasetObject }) => ({
  type: UPDATE_DATASET_SUCCESS,
  datasetObject,
});

export const updateDatasetFailure = ({ datasetObject, error }) => ({
  type: UPDATE_DATASET_FAILURE,
  datasetObject,
  error,
});

export const setPivotError = ({ error, errorMsg }) => ({
  type: SET_PIVOT_ERROR,
  error,
  errorMsg,
});

export const setShowAnnotations = ({ showAnnotations }) => ({
  type: SET_SHOW_ANNOTATIONS,
  showAnnotations,
});
