import { WORKSPACE_ACCESS_TYPES } from '../../constants/workspace';
import { HomeObjects } from '../../utils/homeScreen/types';

/**
 * Access options for most object types.
 */
export const GENERAL_ACCESS_OPTIONS = [
  WORKSPACE_ACCESS_TYPES.EDITOR,
  WORKSPACE_ACCESS_TYPES.VIEWER,
];

/**
 * Access options for different object types.
 * Viewer and editor options are available for all object types except IBs and sessions.
 */
export const ACCESS_OPTIONS = {
  [HomeObjects.RECIPE]: GENERAL_ACCESS_OPTIONS,
  [HomeObjects.SNAPSHOT]: GENERAL_ACCESS_OPTIONS,
  [HomeObjects.FOLDER]: GENERAL_ACCESS_OPTIONS,
  [HomeObjects.CONNECTION]: GENERAL_ACCESS_OPTIONS,
  [HomeObjects.QUERY]: GENERAL_ACCESS_OPTIONS,
  [HomeObjects.DATAFILE]: GENERAL_ACCESS_OPTIONS,
  [HomeObjects.DATASET]: GENERAL_ACCESS_OPTIONS,
  // Insights Boards do not allow editor access
  [HomeObjects.INSIGHTS_BOARD]: [WORKSPACE_ACCESS_TYPES.VIEWER],
  // Sessions do not have viewer/editor granularity
  [HomeObjects.SESSION]: [],
};

/**
 * The default accessOption when sharing with a new user (not organization).
 */
export const DEFAULT_ACCESS_OPTION = {
  [HomeObjects.RECIPE]: WORKSPACE_ACCESS_TYPES.EDITOR,
  [HomeObjects.SNAPSHOT]: WORKSPACE_ACCESS_TYPES.EDITOR,
  [HomeObjects.INSIGHTS_BOARD]: WORKSPACE_ACCESS_TYPES.VIEWER,
  [HomeObjects.FOLDER]: WORKSPACE_ACCESS_TYPES.EDITOR,
  [HomeObjects.CONNECTION]: WORKSPACE_ACCESS_TYPES.EDITOR,
  [HomeObjects.QUERY]: WORKSPACE_ACCESS_TYPES.EDITOR,
  [HomeObjects.DATAFILE]: WORKSPACE_ACCESS_TYPES.EDITOR,
  [HomeObjects.DATASET]: WORKSPACE_ACCESS_TYPES.EDITOR,
  [HomeObjects.SESSION]: WORKSPACE_ACCESS_TYPES.EDITOR,
};

/**
 * Add the option to enable organization access for different object types.
 */
export const ALLOW_ORG_ACCESS_OPTION = {
  [HomeObjects.RECIPE]: true,
  [HomeObjects.SNAPSHOT]: true,
  [HomeObjects.INSIGHTS_BOARD]: true,
  [HomeObjects.FOLDER]: true,
  [HomeObjects.CONNECTION]: true,
  [HomeObjects.QUERY]: true,
  [HomeObjects.DATAFILE]: true,
  [HomeObjects.DATASET]: true,
  [HomeObjects.SESSION]: false,
};

export const ORG_SHARING_TEXT = 'Share with organization';
export const GENERAL_SHARING_DIALOG_ERROR =
  'There was a problem opening your sharing options. Please try again.';

/**
 * Message to display when access is updated.
 * @param names Names of the users who now have access.
 * @returns Message to display.
 */
export const accessUpdatedMessage = (users: { name: string; id: number }[]) =>
  `Shared with ${users.map((u) => u.name).join(', ')}.`;
