import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IntegrationProvider } from '../../types/integrations.types';
import { ExternalOAuthClient, OAuthClient } from '../../types/oauth.types';

export interface OAuthState {
  externalOAuthClients: Record<
    IntegrationProvider,
    {
      loading: boolean;
      error: string | null;
      clients: ExternalOAuthClient[];
    }
  >;
  client: OAuthClient;
  loading: boolean;
  error: string | null;
  redirectURL: string | null;
  approvalError: string | null;
}

export const initialState: OAuthState = {
  externalOAuthClients: {
    [IntegrationProvider.BIGQUERY]: { loading: false, error: null, clients: [] },
    [IntegrationProvider.SNOWFLAKE]: { loading: false, error: null, clients: [] },
  },
  client: { clientId: null, name: null, redirectUri: null },
  loading: false,
  error: null,
  redirectURL: null,
  approvalError: null,
};

const oauthSlice = createSlice({
  name: 'oauth',
  initialState,
  reducers: {
    getOAuthClientRequest: (
      state,
      action: PayloadAction<{ clientId: string; redirectUri: string }>,
    ) => {
      state.loading = true;
      state.error = null;
      state.client = {
        clientId: action.payload.clientId,
        name: null,
        redirectUri: [action.payload.redirectUri],
      };
    },
    getOAuthClientSuccess: (state, action: PayloadAction<{ client: OAuthClient }>) => {
      state.loading = false;
      state.client = action.payload.client;
    },
    getOAuthClientFailure: (state, action: PayloadAction<{ error: string }>) => {
      state.loading = false;
      state.error = action.payload.error;
    },
    approveOAuthConsentRequest: (
      state,
      action: PayloadAction<{
        clientId: string;
        redirectUri: string;
        state?: string;
      }>,
    ) => {
      state.client = {
        clientId: action.payload.clientId,
        name: state.client.name,
        redirectUri: [action.payload.redirectUri, ...(state.client.redirectUri || [])],
      };
      state.approvalError = null;
      state.redirectURL = null;
    },
    approveOAuthConsentSuccess: (state, action: PayloadAction<{ redirectURL: string }>) => {
      state.redirectURL = action.payload.redirectURL;
      state.approvalError = null;
    },
    approveOAuthConsentFailure: (state, action: PayloadAction<{ error: string }>) => {
      state.redirectURL = null;
      state.approvalError = action.payload.error;
    },

    getExternalOAuthClientsRequest: (
      state,
      action: PayloadAction<{ provider: IntegrationProvider }>,
    ) => {
      state.externalOAuthClients = {
        ...state.externalOAuthClients,
        [action.payload.provider]: {
          ...state.externalOAuthClients[action.payload.provider],
          loading: true,
          error: null,
        },
      };
    },
    getExternalOAuthClientsSuccess: (
      state,
      action: PayloadAction<{ provider: IntegrationProvider; clients: ExternalOAuthClient[] }>,
    ) => {
      state.externalOAuthClients = {
        ...state.externalOAuthClients,
        [action.payload.provider]: {
          loading: false,
          error: null,
          clients: action.payload.clients,
        },
      };
    },
    getExternalOAuthClientsFailure: (
      state,
      action: PayloadAction<{ provider: IntegrationProvider; error: string }>,
    ) => {
      state.externalOAuthClients = {
        ...state.externalOAuthClients,
        [action.payload.provider]: {
          ...state.externalOAuthClients[action.payload.provider],
          loading: false,
          error: action.payload.error,
        },
      };
    },
  },
});

export const {
  getOAuthClientRequest,
  getOAuthClientSuccess,
  getOAuthClientFailure,
  approveOAuthConsentRequest,
  approveOAuthConsentSuccess,
  approveOAuthConsentFailure,
  getExternalOAuthClientsRequest,
  getExternalOAuthClientsSuccess,
  getExternalOAuthClientsFailure,
} = oauthSlice.actions;

export default oauthSlice.reducer;
