import { AggregateExpressions, ChartTypes, DCColTypes } from 'translate_dc_to_echart';
import {
  aggregateGroupBy,
  getAggregatedInputs,
  histogramAggregates,
  nonNumericColumnAggregates,
  numericColumnAggregates,
} from './constants';

/**
 * Gets the available aggregate options for the given chart type & column type
 * @param {String} chartType The type of chart
 * @param {String} colType The type of the column
 * @returns dropdown menu list
 */
export const filterAggregate = (chartType, colType) => {
  if (chartType === ChartTypes.histogram) return histogramAggregates;
  if (
    colType === DCColTypes.INTEGER ||
    colType === DCColTypes.FLOAT ||
    colType === DCColTypes.BOOLEAN ||
    colType === DCColTypes.NUMBER
  ) {
    return numericColumnAggregates;
  }
  return nonNumericColumnAggregates;
};

/**
 * Manually generates additional aggregate params for heatmaps and line charts
 * @param {Object} fields key/value pairs for the menu input fields
 * @param {string} type the type of chart
 * @returns {Object} generated aggregate params
 */
const performManualAggregation = (fields, type) => {
  const aggregateCols = getAggregatedInputs(type);
  const groupByCols = aggregateGroupBy(type);
  const columns = new Set();
  const groupBy = new Set();

  for (const key of Object.keys(fields)) {
    if (aggregateCols.includes(key)) {
      if (Array.isArray(fields[key])) {
        fields[key].forEach((col) => columns.add(col));
      } else {
        columns.add(fields[key]);
      }
    }

    if (groupByCols.includes(key)) {
      if (Array.isArray(fields[key])) {
        fields[key].forEach((col) => groupBy.add(col));
      } else {
        groupBy.add(fields[key]);
      }
    }
  }

  return {
    columns: [...columns],
    groupBy: [...groupBy],
  };
};

/**
 * Constructs the full aggregate objects to send to the translation layer
 * @param {String} chartType the type of chart
 * @param {String} expression the aggregate expression
 * @param {Object} fields key/value pairs for the menu input fields
 */
export const constructAggregate = (chartType, expression, fields) => {
  if (!expression) return [];

  const { columns, groupBy } = performManualAggregation(fields, chartType);
  return [{ expression, columns, groupBy }];
};

/**
 * Gets the columns that are used in the aggregate spec
 * @param {Array} aggregateSpec The aggregate spec from the chart spec
 * @returns {Array} All of the columns that we're using when computing the aggregates
 */
export const getColumnsAfterAggregation = (aggregateSpec = []) => {
  const columnsUsed = aggregateSpec
    .filter((agg) => agg.expression !== AggregateExpressions.none)
    .reduce((acc, curr) => acc.concat(curr.columns, curr.groupBy), []);

  return columnsUsed && columnsUsed.length > 0 ? columnsUsed : null;
};
