// Object Actions
export const GET_HOME_SCREEN_OBJECTS_REQUEST = 'home_screen/GET_HOME_SCREEN_OBJECTS_REQUEST';
export const GET_HOME_SCREEN_OBJECTS_SUCCESS = 'home_screen/GET_HOME_SCREEN_OBJECTS_SUCCESS';
export const GET_HOME_SCREEN_OBJECTS_FAILURE = 'home_screen/GET_HOME_SCREEN_OBJECTS_FAILURE';

export const CREATE_NEW_OBJECT_REQUEST = 'home_screen/CREATE_NEW_OBJECT_REQUEST';
export const CREATE_NEW_OBJECT_SUCCESS = 'home_screen/CREATE_NEW_OBJECT_SUCCESS';
export const CREATE_NEW_OBJECT_FAILURE = 'home_screen/CREATE_NEW_OBJECT_FAILURE';

export const OBJECT_OPEN_REQUEST = 'home_screen/OBJECT_OPEN_REQUEST';
export const OBJECT_OPEN_SUCCESS = 'home_screen/OBJECT_OPEN_SUCCESS';
export const OBJECT_OPEN_FAILURE = 'home_screen/OBJECT_OPEN_FAILURE';

export const OBJECT_RENAME_REQUEST = 'home_screen/OBJECT_RENAME_REQUEST';
export const OBJECT_RENAME_SUCCESS = 'home_screen/OBJECT_RENAME_SUCCESS';
export const OBJECT_RENAME_FAILURE = 'home_screen/OBJECT_RENAME_FAILURE';

export const CLEAR_HOME_SCREEN_OBJECTS = 'home_screen/CLEAR_HOME_SCREEN_OBJECTS';

export const DELETE_OBJECT_REQUEST = 'home_screen/DELETE_OBJECT_REQUEST';
export const DELETE_OBJECT_SUCCESS = 'home_screen/DELETE_OBJECT_SUCCESS';
export const DELETE_OBJECT_FAILURE = 'home_screen/DELETE_OBJECT_FAILURE';

export const DELETE_OBJECTS_REQUEST = 'home_screen/DELETE_OBJECTS_REQUEST';
export const DELETE_OBJECTS_SUCCESS = 'home_screen/DELETE_OBJECTS_SUCCESS';
export const DELETE_OBJECTS_FAILURE = 'home_screen/DELETE_OBJECTS_FAILURE';

export const SET_TAB = 'home_screen/SET_TAB';

export const DIALOG_INPUT_CHANGE = 'home_screen/DIALOG_INPUT_CHANGE';

// Search Actions
export const SET_SEARCH_LOADING = 'home_screen/SET_SEARCH_LOADING';
export const SEARCH_REQUEST = 'home_screen/SEARCH_REQUEST';
export const SEARCH_SUCCESS = 'home_screen/SEARCH_SUCCESS';
export const SUBMIT_SEARCH = 'home_screen/SUBMIT_SEARCH';
export const SEARCH_FAILURE = 'home_screen/SEARCH_FAILURE';
export const CLEAR_SEARCH = 'home_screen/CLEAR_SEARCH';

// Selection
export const DESELECT_OBJECT = 'home_screen/DESELECT_OBJECT';

// Home screen object refreshing
export const WAIT_AND_REFRESH_HOME_OBJECTS = 'home_screen/WAIT_AND_REFRESH_HOME_OBJECTS';
export const CANCEL_HOME_OBJECTS_REFRESH = 'home_screen/CANCEL_HOME_OBJECTS_REFRESH';

// Hidden objects
export const SET_SHOW_HIDDEN_OBJECTS = 'home_screen/SET_SHOW_HIDDEN_OBJECTS';
export const SET_HIDE_HIDDEN_OBJECTS = 'home_screen/SET_HIDE_HIDDEN_OBJECTS';

// Selected Object Context Menu
export const SET_SELECTED_OBJECT_OPTIONS = 'home_screen/SET_SELECTED_OBJECT_OPTIONS';
export const CLOSE_CONTEXT_MENU = 'home_screen/CLOSE_CONTEXT_MENU';

export const SET_SELECTED_ROWS = 'home_screen/SET_SELECTED_ROWS';
export const SET_CONTEXT_MENU_ANCHOR = 'home_screen/SET_CONTEXT_MENU_ANCHOR';

export const SET_SELECTED_FEATURE_CARDS = 'home_screen/SET_SELECTED_FEATURE_CARDS';

export const BULK_LOAD_DATASET_REQUEST = 'home_screen/BULK_LOAD_DATASET_REQUEST';

// Home screen object table filters
export const SET_OBJECT_FILTERS = 'home_screen/SET_OBJECT_FILTERS';
export const CLEAR_OBJECT_FILTERS = 'home_screen/CLEAR_OBJECT_FILTERS';

export const GET_CHART_RELATED_SNAPSHOT_UUIDS_REQUEST =
  'home_screen/GET_CHART_RELATED_SNAPSHOT_UUIDS_REQUEST';
export const GET_CHART_RELATED_SNAPSHOT_UUIDS_SUCCESS =
  'home_screen/GET_CHART_RELATED_SNAPSHOT_UUIDS_SUCCESS';
export const GET_CHART_RELATED_SNAPSHOT_UUIDS_FAILURE =
  'home_screen/GET_CHART_RELATED_SNAPSHOT_UUIDS_FAILURE';

/**
 * Get the list of all Home Screen Objects or get a list of objects specific to object type.
 * @param {object} params The parameters for the action.
 * @param {string} params.objectType String representing the type of the object.
 * @param {boolean} params.refreshing Represents whether the request is for refreshing the list.
 * @returns The action object.
 */
export const getHomeScreenObjectsRequest = ({ objectType, refreshing }) => ({
  type: GET_HOME_SCREEN_OBJECTS_REQUEST,
  objectType,
  refreshing,
});

export const getHomeScreenObjectsSuccess = ({ objectType, data }) => ({
  type: GET_HOME_SCREEN_OBJECTS_SUCCESS,
  objectType,
  data,
});

export const getHomeScreenObjectsFailure = ({ objectType, error }) => ({
  type: GET_HOME_SCREEN_OBJECTS_FAILURE,
  objectType,
  error,
});

export const createNewObjectRequest = ({ objectType, args }) => ({
  type: CREATE_NEW_OBJECT_REQUEST,
  objectType,
  args,
});

export const createNewObjectSuccess = ({ objectType }) => ({
  type: CREATE_NEW_OBJECT_SUCCESS,
  objectType,
});

export const createNewObjectFailure = ({ objectType, error }) => ({
  type: CREATE_NEW_OBJECT_FAILURE,
  objectType,
  error,
});

/**
  Dispatch action when any of the home screen object is clicked
  @param {Object} object // Object representing the home screen object to be opened
*/
export const objectOpenRequest = ({ object, args }) => ({
  type: OBJECT_OPEN_REQUEST,
  object,
  args,
});

export const objectOpenSuccess = ({ object }) => ({
  type: OBJECT_OPEN_SUCCESS,
  object,
});

export const objectOpenFailure = ({ object, error }) => ({
  type: OBJECT_OPEN_FAILURE,
  object,
  error,
});

/**
  Dispatch action for renaming the object
  @param {Object} object // Object representing the homescreen object to be renamed
  @param {String} newName // The name for the object to be renamed to
*/

export const objectRenameRequest = ({ object, newName }) => ({
  type: OBJECT_RENAME_REQUEST,
  object,
  newName,
});

export const objectRenameSuccess = ({ object, newName }) => ({
  type: OBJECT_RENAME_SUCCESS,
  object,
  newName,
});

export const objectRenameFailure = ({ object, newName, error }) => ({
  type: OBJECT_RENAME_FAILURE,
  object,
  newName,
  error,
});

/**
  Clears the list of all home screen objects
*/
export const clearHomeScreenObjects = () => ({
  type: CLEAR_HOME_SCREEN_OBJECTS,
});

/**
  Dispatch action for deleting the objects
  @param {Object} object // Object representing the home screen object to be deleted
*/

export const deleteObjectsRequest = ({ objects }) => ({
  type: DELETE_OBJECTS_REQUEST,
  objects,
});

export const deleteObjectsSuccess = () => ({
  type: DELETE_OBJECTS_SUCCESS,
});

export const deleteObjectsFailure = ({ failedObjects = {}, cancelledObjects = [] }) => ({
  type: DELETE_OBJECTS_FAILURE,
  failedObjects,
  cancelledObjects,
});

export const deleteObjectRequest = ({ object }) => ({
  type: DELETE_OBJECT_REQUEST,
  object,
});

export const deleteObjectSuccess = ({ object }) => ({
  type: DELETE_OBJECT_SUCCESS,
  object,
});

export const deleteObjectFailure = ({ object, error }) => ({
  type: DELETE_OBJECT_FAILURE,
  object,
  error,
});

export const setTab = ({ tab, pushToUrl = true }) => ({
  type: SET_TAB,
  tab,
  pushToUrl,
});

/**
  Dispatch an action for the input value change on the alert dialog
  @param {Object} value // Input value for the dialog
*/
export const dialogInputChange = ({ value }) => ({
  type: DIALOG_INPUT_CHANGE,
  value,
});

/**
  Dispatch an action to set the loading status for searching results
  @param {String} isLoading // Boolean respresenting true or false for searching
*/
export const setSearchLoading = ({ isLoading }) => ({
  type: SET_SEARCH_LOADING,
  isLoading,
});

/**
  Dispatch action for searching the objects
  @param {String} value // String represting the value entered by the user in the search bar
*/
export const searchRequest = ({ value }) => ({
  type: SEARCH_REQUEST,
  value,
});

/**
  Dispatch an action for assigning the filtered results after search
  @param {String} value // String represting the value entered by the user in the search bar
*/
// SearchSuccess
export const searchSuccess = ({ data, value }) => ({
  type: SEARCH_SUCCESS,
  data,
  value,
});

/**
  Dispatch an action if there is any failure in searching
  @param {Error} error
*/

export const searchFailure = ({ error, value }) => ({
  type: SEARCH_FAILURE,
  error,
  value,
});

/**
 * Dispatch an action for submit search
 * @param {Boolean} refreshResults // if true, DO NOT set the tab to Search Results.
 */
export const submitSearch = ({ refreshResults = false }) => ({
  type: SUBMIT_SEARCH,
  refreshResults,
});

/**
  Dispatch an action to clear SEARCH and its states in store
*/
export const clearSearch = () => ({
  type: CLEAR_SEARCH,
});

export const deselectObject = () => ({
  type: DESELECT_OBJECT,
});

export const waitAndRefreshHomeObjects = ({ objectType }) => ({
  type: WAIT_AND_REFRESH_HOME_OBJECTS,
  objectType,
});

export const cancelHomeObjectsRefresh = () => ({
  type: CANCEL_HOME_OBJECTS_REFRESH,
});

export const setShowHiddenObjects = () => ({
  type: SET_SHOW_HIDDEN_OBJECTS,
});

export const setHideHiddenObjects = () => ({
  type: SET_HIDE_HIDDEN_OBJECTS,
});

// Action to set selected row options. To have the options in the more context menu,
// all of these variables in the reducer should be set.
export const setSelectedObjectOptions = ({ contextMenuAnchor = null, actionType = null }) => ({
  type: SET_SELECTED_OBJECT_OPTIONS,
  contextMenuAnchor,
  actionType,
});

/**
 *
 * @param {HomeObjectKeysTypes[]} selectedRows
 */
export const setSelectedRows = (selectedRows) => ({
  type: SET_SELECTED_ROWS,
  selectedRows,
});

/**
 *
 * @param {HomeObjectKeysTypes} selectedFeatureCards
 */
export const setSelectedFeatureCard = (selectedFeatureCard) => ({
  type: SET_SELECTED_FEATURE_CARDS,
  selectedFeatureCard,
});

export const setContextMenuAnchor = ({ contextMenuAnchor }) => ({
  type: SET_CONTEXT_MENU_ANCHOR,
  contextMenuAnchor,
});

// This will only clear contextMenuAnchor and actionType from the home screen reducer.
// To clear the selectedObject from the reducer user deselectObject action.
export const closeContextMenu = () => ({
  type: CLOSE_CONTEXT_MENU,
});

/**
  Action to set object filters
  @param o
  @param {Boolean} [o.onSearchBar] // Boolean used to set object type filter irrespective of selected tab. Important when
   user starts searching with filter chip and not on search bar tab.
  @param {Boolean} [o.pushToUrl] // Boolean used to determine whether to push or replace to browser URL path.
  @param {Object} o.filtersObject // Filters object that needs to be assigned in the reducer
*/
export const setObjectFilters = ({ onSearchBar = false, pushToUrl = true, filtersObject }) => ({
  type: SET_OBJECT_FILTERS,
  onSearchBar,
  pushToUrl,
  filtersObject,
});

// Clears the object filters based on the selected tab in the reducer.
export const clearObjectFilters = () => ({
  type: CLEAR_OBJECT_FILTERS,
});

/**
  Get the list of all Home Screen Objects (or) Get list of objects specific to object type
  @param {String} objectType // String represting the type of object
*/
export const getChartBackingSnapshotUUIDsRequest = () => ({
  type: GET_CHART_RELATED_SNAPSHOT_UUIDS_REQUEST,
});

export const getChartBackingSnapshotUUIDsSuccess = ({ uuidList }) => ({
  type: GET_CHART_RELATED_SNAPSHOT_UUIDS_SUCCESS,
  uuidList,
});

export const getChartBackingSnapshotUUIDsFailure = ({ error }) => ({
  type: GET_CHART_RELATED_SNAPSHOT_UUIDS_FAILURE,
  error,
});

export const bulkLoadDatasetRequest = ({ folders, datasets }) => ({
  type: BULK_LOAD_DATASET_REQUEST,
  folders,
  datasets,
});
