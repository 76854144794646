import createTheme from '@mui/material/styles/createTheme';
import { theme } from '../styles/themes';

const tableTheme = createTheme({
  palette: {
    primary: {
      main: theme.palette.primary,
      contrastText: theme.palette.white,
    },
    secondary: {
      main: theme.palette.primary,
    },
    light: {
      main: theme.palette.white,
    },
  },
  typography: theme.typography,
});

export default tableTheme;
