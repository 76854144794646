import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { ReactComponent as PanModeIcon } from '../../../../../assets/icons/arrows_move.svg';
import { selectIsStepwise } from '../../../../../store/selectors/context.selector';
import { PLOTS_WITHOUT_MODE_BAR } from '../../../constants';

/**
 * A functional component to render a button UI to switch from zoom to pan mode
 */
const PanModeButton = (props) => {
  const { togglePanMode, messageType, messageTypeVersion, data, isReplaying, isStepwise } = props;
  return (
    <>
      {!data.isDependencyGraph &&
      messageType === 'viz' &&
      !PLOTS_WITHOUT_MODE_BAR.includes(data.chartType) &&
      messageTypeVersion === 2 &&
      data.plot?.series?.[0]?.type !== 'donut' &&
      data.plot?.series?.[0]?.type !== 'single_metric' ? (
        <MenuItem key="pan-mode" className="Menu-Item-Container" onClick={togglePanMode}>
          <div
            className={`${isReplaying && !isStepwise ? 'Menu-Item Replaying' : 'Menu-Item'}`}
            data-cy="Menu-Item-Pan-Mode"
          >
            <PanModeIcon className="Menu-Item-Icon" width="16px" height="16px" />
            <span className="Menu-Item-Text">Pan Mode</span>
          </div>
        </MenuItem>
      ) : null}
    </>
  );
};

PanModeButton.propTypes = {
  // Are we currently replaying a WF?
  isReplaying: PropTypes.bool.isRequired,
  // Are we replaying stepwise?
  isStepwise: PropTypes.bool.isRequired,
  messageType: PropTypes.string.isRequired,
  messageTypeVersion: PropTypes.number,
  data: PropTypes.object.isRequired,
  togglePanMode: PropTypes.func.isRequired,
};

PanModeButton.defaultProps = {
  messageTypeVersion: null,
};

const mapStateToProps = (state) => ({
  isReplaying: state.context.isReplaying,
  isStepwise: selectIsStepwise(state),
});

export default connect(mapStateToProps, {})(PanModeButton);
