import PropTypes from 'prop-types';
import React from 'react';
import './LoadingFailure.scss';

const LoadingFailure = ({ overRideWithText, retryFn, style }) => {
  return (
    <div className="Datachat-Loading-Failure-Container" style={style}>
      {overRideWithText ? (
        <span className="Datachat-Loading-Failure-Text" data-testid="Datachat-Loading-Failure-Text">
          {overRideWithText}
        </span>
      ) : (
        <span className="Datachat-Loading-Failure-Text" data-testid="Datachat-Loading-Failure-Text">
          Failed to Load. Click
          <span className="Datachat-Loading-Failure-Text-Button" onClick={retryFn}>
            here
          </span>
          to retry.
        </span>
      )}
    </div>
  );
};

LoadingFailure.propTypes = {
  retryFn: PropTypes.func,
  style: PropTypes.object,
  overRideWithText: PropTypes.string,
};

LoadingFailure.defaultProps = {
  retryFn: () => {},
  style: {},
  overRideWithText: '',
};

export default LoadingFailure;
