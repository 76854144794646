// Constants for the collaborator avatars
export const COLLAB_AVATAR_HEIGHT = 28;
export const COLLAB_AVATAR_WIDTH = 28;
export const COLLAB_AVATAR_FONT_SIZE = 1.15;

export const ORIENTATIONS = {
  VERT: 'vertical',
  HORIZ: 'horizontal',
  ROTATED: 'rotated',
};

/**
 * Converts a font size to a rem value
 */
export const fontSizeToRem = (fontSize: number) => `${fontSize}rem`;
