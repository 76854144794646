import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../configureStore';
import { selectPipelinerDatasetDescription } from '../store/slices/pipelinerDatasets/selectors';
import { getDescription } from '../store/slices/pipelinerDatasets/slice';
import { AsyncResult } from '../types/AsyncResult.types';
import { DatasetClaim } from '../types/claim.type';
import { Description, DescriptionParameters } from '../types/dataset.types';

export default function usePipelinerDatasetDescription(
  id: string,
  claim: DatasetClaim,
  params: DescriptionParameters = {},
): AsyncResult<Description> {
  const dispatch = useDispatch();

  // get the total row count from the store
  const description = useSelector((state: RootState) =>
    selectPipelinerDatasetDescription(state, id),
  );

  if (!id) return { kind: 'error', error: new Error('missing pipeliner dataset id') };

  // No parameters were specified, return an empty description
  if (!params.totalRowCount && (!params.stats || isEmpty(params.stats))) {
    return { kind: 'data', data: { columns: {} } };
  }

  // if there is no total row count in the store, request it
  if (!description) {
    dispatch(getDescription({ id, claim, params }));
    return { kind: 'loading' };
  }

  // if the total row count is in the store, return it
  return description;
}
