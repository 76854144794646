/* eslint-disable no-unused-vars */

import isString from 'lodash/isString';

/**
 * Causes the div element to blink
 * @param {Object} el A HTML element
 */
export const flicker = (el) => {
  if (el !== undefined) {
    if (el.classList.contains('flicker')) return; // Ensures atomicity.
    el.classList.add('flicker');
    setTimeout(() => {
      el.classList.remove('flicker');
    }, 1000);
  }
};

const scrollToElement = (parentCssClass, cssClass, which) => {
  const parents = document.getElementsByClassName(parentCssClass);
  for (let i = 0; i < parents.length; i++) {
    const correspondingElement = parents[i].getElementsByClassName(cssClass)[which];
    if (correspondingElement !== undefined) {
      correspondingElement.scrollIntoView();
    }
    flicker(correspondingElement);
  }
};

export const scrollToUserMessage = (userMessageID, panel = 'ChatPanel') => {
  scrollToElement(panel, 'chat-bubble user', userMessageID);
};

export const scrollToExplorerMessage = (userMessageID) => {
  scrollToElement('Explorer-UtterancesList', 'Explorer-UtterancesList-Item', userMessageID);
};

/**
 * Scrolls the chat panel in the workflow editor to a specific line and applies
 * flicker styling to all edited lines.
 * @param {Number} scrollIndex - index to scroll the editor panel to
 * @param {Array} changedLines - array of indices to apply flicker styling to
 */
export const scrollToEditorUtterance = (scrollIndex, changedLines = []) => {
  const editorPanel = document.getElementsByClassName('WorkflowEditor')[0];
  const scrollLine = editorPanel.getElementsByClassName('UtteranceEditor')[scrollIndex];
  if (scrollLine !== undefined) {
    const parentBox = editorPanel.getBoundingClientRect();
    const childBox = scrollLine.getBoundingClientRect();
    // Only scroll and flicker if the changed line is not visible within the parent
    if (parentBox.top > childBox.top || parentBox.bottom < childBox.bottom) {
      scrollLine.scrollIntoView({ block: 'center' });
      flicker(scrollLine);
    }
  }
  // flicker all changed lines (used for bulk operations)
  changedLines.forEach((index) => {
    const line = editorPanel.getElementsByClassName('UtteranceEditor')[index];
    if (line !== undefined) flicker(line);
  });
};

/**
 * Helper function to detect if an utterance contains tagged json spec
 * @param {String} utterance
 * @returns true if a tagged json spec is found, false otherwise
 */
export const containsTaggedJson = (utterance) => {
  return utterance.match(/<strong>{(.*)}<\/strong>/);
};

/**
 * Helper function replace tagged json specs in an utterance with
 * @param {String} utterance
 * @returns true if a tagged json spec is found, false otherwise
 */
export const collapseTaggedJson = (utterance) => {
  return utterance.replace(/<strong>{(.*)}<\/strong>/, '<strong>{...}</strong>');
};

export const trimLeadingWhitespace = (input) => {
  if (!input || !isString(input)) return input;
  return input.replace(/^\s+/g, '');
};
