import { call, put, select, takeLatest } from 'redux-saga/effects';
import { authenticate } from '../../utils/authenticate';
import {
  ACKNOWLEDGE_NOTIFICATION_REQUEST,
  CREATE_NOTIFICATION_REQUEST,
  GET_ALL_NOTIFICATIONS_REQUEST,
  GET_SEEN_NOTIFICATIONS_REQUEST,
  GET_UNSEEN_NOTIFICATIONS_REQUEST,
  TERMINATE_NOTIFICATION_REQUEST,
  createNotificationFailure,
  createNotificationSuccess,
  getAllNotificationsFailure,
  getAllNotificationsRequest,
  getAllNotificationsSuccess,
  getSeenNotificationsFailure,
  getSeenNotificationsRequest,
  getSeenNotificationsSuccess,
  getUnseenNotificationsFailure,
  getUnseenNotificationsRequest,
  getUnseenNotificationsSuccess,
} from '../actions/notification.action';

import {
  acknowledgeNotification,
  createNotification,
  getAllNotifications,
  getSeenNotifications,
  getUnseenNotifications,
  terminateNotification,
} from '../../api/notification.api';
import { TOAST_ERROR, TOAST_SHORT, TOAST_SUCCESS } from '../../constants/toast';
import { createAlertChannelRequest } from '../actions/dialog.actions';
import { setOrganizationUsersSelectState } from '../actions/settings.actions';
import { addToast } from '../actions/toast.actions';
import { selectAccessToken } from './selectors';

/**
 * Gets unseen notifications from the server.
 */
export function* getUnseenNotificationsWorker() {
  try {
    const accessToken = yield select(selectAccessToken);
    const notificationResponse = yield call(getUnseenNotifications, accessToken);
    const unseenNotifications = notificationResponse.data;

    yield put(getUnseenNotificationsSuccess({ unseenNotifications }));
  } catch (error) {
    yield put(getUnseenNotificationsFailure({ error }));
    yield put(createAlertChannelRequest({ error }));
  }
}

/**
 * Gets seen notifications from the server.
 */
export function* getSeenNotificationsWorker() {
  try {
    const accessToken = yield select(selectAccessToken);
    const notificationResponse = yield call(getSeenNotifications, accessToken);
    const seenNotifications = notificationResponse.data;

    yield put(getSeenNotificationsSuccess({ seenNotifications }));
  } catch (error) {
    yield put(getSeenNotificationsFailure({ error }));
    yield put(createAlertChannelRequest({ error }));
  }
}

/**
 * Gets all notifications from the server.
 */
export function* getAllNotificationsWorker() {
  try {
    const accessToken = yield select(selectAccessToken);
    const notificationResponse = yield call(getAllNotifications, accessToken);
    const allNotifications = notificationResponse.data;

    yield put(getAllNotificationsSuccess({ allNotifications }));
  } catch (error) {
    yield put(getAllNotificationsFailure({ error }));
    yield put(createAlertChannelRequest({ error }));
  }
}

/**
 * Posts to the server that the user viewed or acknowledged an announcement
 */
export function* acknowledgeNotificationWorker({ notificationIds }) {
  try {
    const accessToken = yield select(selectAccessToken);
    yield call(acknowledgeNotification, accessToken, notificationIds);
    // Reload notifications after updating.
    yield put(getUnseenNotificationsRequest());
    yield put(getSeenNotificationsRequest());
  } catch (error) {
    yield put(createAlertChannelRequest({ error }));
  }
}

/**
 * Posts to the server that the user no longer wishes to see an announcement
 */
export function* terminateNotificationWorker({ notificationId }) {
  try {
    const accessToken = yield select(selectAccessToken);
    yield call(terminateNotification, accessToken, notificationId);
    // Reload notifications after updating.
    yield put(getAllNotificationsRequest());
  } catch (error) {
    yield put(createAlertChannelRequest({ error }));
  }
}

/**
 * Posts a new notification to the server
 */
export function* createNotificationWorker({ newNotification }) {
  try {
    const accessToken = yield select(selectAccessToken);
    yield call(createNotification, accessToken, newNotification);
    yield put(createNotificationSuccess());
    yield put(setOrganizationUsersSelectState([]));
    yield put(getAllNotificationsRequest());
    yield put(
      addToast({
        toastType: TOAST_SUCCESS,
        length: TOAST_SHORT,
        message: 'Notification creation successful!',
      }),
    );
  } catch (error) {
    yield put(createNotificationFailure({ error }));
    yield put(createAlertChannelRequest({ error }));
    yield put(
      addToast({
        toastType: TOAST_ERROR,
        length: TOAST_SHORT,
        message: 'Failed to create notification',
      }),
    );
  }
}

/**
 * Listens for the most recent notification related
 * actions from the client.
 */
export default function* apiWatcher() {
  yield takeLatest(
    GET_UNSEEN_NOTIFICATIONS_REQUEST,
    authenticate(getUnseenNotificationsWorker, getUnseenNotificationsFailure),
  );
  yield takeLatest(
    GET_SEEN_NOTIFICATIONS_REQUEST,
    authenticate(getSeenNotificationsWorker, getUnseenNotificationsFailure),
  );
  yield takeLatest(
    GET_ALL_NOTIFICATIONS_REQUEST,
    authenticate(getAllNotificationsWorker, getAllNotificationsFailure),
  );
  yield takeLatest(
    ACKNOWLEDGE_NOTIFICATION_REQUEST,
    authenticate(acknowledgeNotificationWorker, getUnseenNotificationsFailure),
  );
  yield takeLatest(
    CREATE_NOTIFICATION_REQUEST,
    authenticate(createNotificationWorker, createNotificationFailure),
  );
  yield takeLatest(
    TERMINATE_NOTIFICATION_REQUEST,
    authenticate(terminateNotificationWorker, getAllNotificationsFailure),
  );
}
