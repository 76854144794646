import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { ReactComponent as CopyLinkIcon } from '../../../../../assets/icons/copy-link.svg';
import { isWorkflowEditorPage } from '../../../../../constants/paths';
import { showReplayDisableToast } from '../../../../../constants/toast';
import { shareChartRequest } from '../../../../../store/actions/chart.actions';
import { addToast } from '../../../../../store/actions/toast.actions';
import { selectCurrentLocation, selectUserConfig } from '../../../../../store/sagas/selectors';
import { selectIsStepwise } from '../../../../../store/selectors/context.selector';
import { FeatureTypes } from '../../../../../types/feature.types';
import { hasFeatureEnabled } from '../../../../../utils/userconfig_selector';
import { CHART_DESTINATIONS } from '../../../constants';

// A functional component to render a button UI to copy the link of visual element
const CopyLinkButton = (props) => {
  // redux dispatch hook
  const dispatch = useDispatch();

  const {
    copyMsg,
    isDependencyGraph,
    isReplaying,
    isStepwise,
    isWorkflowEditor,
    messageType,
    objectId,
    setCopyMsg,
  } = props;

  const userConfig = useSelector(selectUserConfig);
  const isPublicLinkAvailable = hasFeatureEnabled(userConfig, FeatureTypes.PUBLIC_LINKS);
  if (
    !isPublicLinkAvailable ||
    !(objectId && messageType === 'viz' && !isWorkflowEditor && !isDependencyGraph)
  ) {
    return null;
  }

  // copy link of visual to clipboard
  const copyLinkToClipboard = () => {
    if (isReplaying && !isStepwise) {
      showReplayDisableToast(addToast, 'copy link', dispatch);
      return;
    }
    dispatch(shareChartRequest('chart', objectId, CHART_DESTINATIONS.Clipboard));
    // Show the 'copied to clipboard' message
    setCopyMsg('Link copied to clipboard');
  };

  return (
    <>
      <MenuItem key="copyLink" className="Menu-Item-Container" onClick={copyLinkToClipboard}>
        <div
          className={`${isReplaying && !isStepwise ? 'Menu-Item Replaying' : 'Menu-Item'}`}
          data-cy="Menu-Item-Copy-Link"
        >
          <CopyLinkIcon className="Menu-Item-Icon" />
          <span className="Menu-Item-Text">{copyMsg}</span>
        </div>
      </MenuItem>
    </>
  );
};

CopyLinkButton.propTypes = {
  copyMsg: PropTypes.string.isRequired,
  isDependencyGraph: PropTypes.bool,
  isReplaying: PropTypes.bool.isRequired,
  isStepwise: PropTypes.bool.isRequired,
  isWorkflowEditor: PropTypes.bool.isRequired,
  messageType: PropTypes.string.isRequired,
  objectId: PropTypes.string,
  setCopyMsg: PropTypes.func.isRequired,
};

CopyLinkButton.defaultProps = {
  isDependencyGraph: false,
  objectId: undefined,
};

const mapStateToProps = (state) => ({
  isStepwise: selectIsStepwise(state),
  isReplaying: state.context.isReplaying,
  isWorkflowEditor: isWorkflowEditorPage(selectCurrentLocation(state)),
});

export default connect(mapStateToProps, {})(CopyLinkButton);
