import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import TextField from '@mui/material/TextField';
import { PropTypes } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { ChartTypes, DCColTypes, getBinChipTitle } from 'translate_dc_to_echart';
import DataChatAutocompleteInput from '../../common/DataChatAutocompleteInput';
import '../ChartBuilder.scss';
import {
  BIN_FORMATTING_THRESHOLD,
  BIN_METHODS_TESTABLE_BY_INTERVAL,
  binMethodsArr,
  intervalPlaceholderByMethod,
} from '../utils/constants';
import { getBinColumnOptions, validateInterval } from '../utils/manageBins';

// The default state for this component
export const defaultBinState = {
  method: '',
  interval: '',
  intervalError: '',
};

/**
 *
 * @returns {JSX.Element} The bin section within the menu
 */
const Bin = (props) => {
  const { applyAndClose, bins, column, columnsTypes, currChartType, fields } = props;

  const oldBin = bins.find((bin) => bin.column === column);
  const oldBinTitle = getBinChipTitle(oldBin);

  // Parse method & interval from existing bins
  const [method, setMethod] = useState(
    bins.find((bin) => bin.column === column)?.method || defaultBinState.method,
  );
  const [binInterval, setBinInterval] = useState(
    bins.find((bin) => bin.column === column)?.interval || defaultBinState.interval,
  );

  // Error state for the bin interval input
  const [intervalError, setIntervalError] = useState(defaultBinState.intervalError);

  // Anchor element for the bin method dropdown
  const [methodAnchorEl, setMethodAnchorEl] = useState(null);

  // Ref for the bin interval input
  const binIntervalRef = useRef();

  // Test the bin interval for parsing errors
  useEffect(() => {
    setIntervalError(validateInterval(binInterval, method));
  }, [binInterval, method]);

  // Is there a bin or any bin inputs to clear?
  const isClearValid = () => oldBin || method || binInterval;

  // Clear the oldBin (if any) and all of the input fields, then close the menu
  const handleClear = (event) => applyAndClose(event, { oldBinTitle, newBin: null });

  // Has the user created a viable bin?
  const isSubmissionValid = () => {
    // Check that all inputs are filled, and there is no error
    if ([column, method, binInterval].some((val) => val === '' || val === null)) return false;
    if (intervalError !== '') return false;
    return true;
  };

  // Calculates and applies the new bin information to the chart spec
  const handleSubmit = (event) => {
    // Set the bin type for histograms or default to string
    const binType =
      currChartType === ChartTypes.histogram &&
      BIN_METHODS_TESTABLE_BY_INTERVAL.includes(method) &&
      Number(binInterval) > BIN_FORMATTING_THRESHOLD
        ? DCColTypes.FLOAT
        : DCColTypes.STRING;

    // Generate the bin title, ex. 'FareInt10'
    const binTitle = getBinChipTitle({ column, method, interval: binInterval });

    // Apply the bin and close the complex column menu
    const newBin = {
      column,
      method,
      interval: binInterval,
      title: binTitle,
      type: binType,
    };
    applyAndClose(event, { newBin, oldBinTitle });
  };

  /**
   * Should we enable the input field?
   * @param {String} input The name of the input field
   * @returns true/false to enable/disable the input field
   */
  const shouldEnableInput = (input) => {
    const hasColumnOptions = getBinColumnOptions(columnsTypes, currChartType, fields).length > 0;
    const hasColumn = column !== null && column !== '';
    const hasMethod = method !== null && method !== '';

    switch (input) {
      case 'column':
      case 'method':
        return hasColumnOptions;
      case 'interval':
        return hasColumnOptions && hasColumn && hasMethod;
      default:
        return true;
    }
  };

  return (
    <>
      <div className="dropdown-row">
        <ClickAwayListener mouseEvent="onMouseDown" onClickAway={() => setMethodAnchorEl(null)}>
          <div style={{ width: '100%' }}>
            <DataChatAutocompleteInput
              key="binMethod"
              anchorEl={methodAnchorEl}
              data-cy="binMethod-input"
              data-testid="binMethod-input"
              placeholder="Select a binning method"
              open={Boolean(methodAnchorEl)}
              options={binMethodsArr}
              value={method}
              listboxProps={{ style: { maxHeight: '10rem' } }}
              getOptionLabel={(option) => option}
              onChange={(e, newSelection) => {
                e.stopPropagation();
                setMethod(newSelection);
                setMethodAnchorEl(null);
              }}
              onKeyDown={(e) => {
                e.stopPropagation();
                const typedVal = e.target.value;
                if (e.code === 'Enter' && typedVal) {
                  // Attempt to match the user's input to a bin method
                  const matchedVal = binMethodsArr.find((m) =>
                    m.toLowerCase().startsWith(typedVal.toLowerCase()),
                  );
                  if (matchedVal) {
                    setMethod(matchedVal);
                    setMethodAnchorEl(null);
                  }
                }
              }}
              disabled={!shouldEnableInput('method')}
              onClick={(e) => {
                setMethodAnchorEl(e.currentTarget);
                e.stopPropagation();
              }}
            />
          </div>
        </ClickAwayListener>
      </div>
      <div className="dropdown-row">
        <TextField
          key="binInterval"
          data-cy="binInterval-input"
          data-testid="binInterval-input"
          placeholder={intervalPlaceholderByMethod[method]}
          error={intervalError !== ''}
          label={intervalError}
          sx={{ width: '100%' }}
          variant="outlined"
          value={binInterval}
          InputLabelProps={{ style: { fontSize: 14 } }}
          onChange={(e) => setBinInterval(e.target.value)}
          onKeyDown={(e) => e.stopPropagation()}
          disabled={!shouldEnableInput('interval')}
          inputRef={binIntervalRef}
          onClick={() => binIntervalRef.current.focus()}
        />
      </div>
      <div className="dropdown-row-buttons">
        <Button
          onClick={handleClear}
          color="primary"
          variant="outlined"
          size="small"
          disabled={!isClearValid()}
          data-testid="bin-clear-button"
        >
          Clear
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="outlined"
          size="small"
          disabled={!isSubmissionValid()}
          style={{ marginLeft: '5px' }}
          data-testid="bin-apply-button"
        >
          Apply
        </Button>
      </div>
    </>
  );
};

Bin.propTypes = {
  // Closes the complex column options menu
  applyAndClose: PropTypes.func,
  // The bins that the user has already created
  bins: PropTypes.array,
  // The column that we're binning, ex. 'Fare'
  column: PropTypes.string.isRequired,
  // The column/type information in array of object format, ex. [{ name: 'Age', type: 'Float' }]
  columnsTypes: PropTypes.array.isRequired,
  // The selected chart type, ex. scatter, bubble, bar
  currChartType: PropTypes.string.isRequired,
  // The chart builder's required & optional field selections, ex. { group: 'SibSp' }
  fields: PropTypes.object.isRequired,
};

Bin.defaultProps = {
  applyAndClose: () => {},
  bins: [],
};

// Set the displayName to be accessed by the container
Bin.displayName = 'Bin';

export default Bin;
