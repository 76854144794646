export const LIST_FILES_REQUEST = 'file_manager/LIST_FILES_REQUEST';
export const LIST_FILES_SUCCESS = 'file_manager/LIST_FILES_SUCCESS';
export const LIST_FILES_FAILURE = 'file_manager/LIST_FILES_FAILURE';

export const SET_FILE_MANAGER_MODE = 'file_manager/CHANGE_FILE_MANAGER_MODE';
export const TRY_SET_FILE_MANAGER_MODE = 'file_manager/TRY_CHANGE_FILE_MANAGER_MODE';

export const AUTO_OPEN_BROWSE_FILES = 'file_manager/AUTO_OPEN_BROWSE_FILES';

export const DELETE_FILE_BY_ID_REQUEST = 'file_manager/DELETE_FILE_BY_ID_REQUEST';
export const DELETE_FILE_BY_ID_SUCCESS = 'file_manager/DELETE_FILE_BY_ID_SUCCESS';
export const DELETE_FILE_BY_ID_FAILURE = 'file_manager/DELETE_FILE_BY_ID_FAILURE';

/**
 * Dispatches an action that initiates an API call to retrieve a list of
 * files in the user's server directory.
 */
export const listFilesRequest = () => ({
  type: LIST_FILES_REQUEST,
});

/**
 * Dispatches an action that indicates that the API call to list files succeeded.
 *
 * @param {Array} files Multiple objects containing a list of files per file type.
 * 						Please refer to the server API README.md for more details.
 */
export const listFilesSuccess = ({ files }) => ({
  type: LIST_FILES_SUCCESS,
  files,
});

/**
 * Dispatches an action that indicates that the API call to list files failed.
 *
 * @param {Object} error An error returned by the server
 */
export const listFilesFailure = ({ error }) => ({
  type: LIST_FILES_FAILURE,
  listFilesError: error,
});

/**
 * Dispatches an action that changes the file manager mode between upload and browse.
 *
 * @param {Object} mode The selected file manager mode
 */
export const changeFileManagerMode = (mode) => ({
  type: SET_FILE_MANAGER_MODE,
  mode,
});
/**
 * Dispatches an action that indicates that we should automatically open the "Browse files" menu
 * when we get to the "Upload" menu.
 */
export const autoOpenBrowseFiles = (openBrowseFiles) => ({
  type: AUTO_OPEN_BROWSE_FILES,
  openBrowseFiles,
});

/**
 * Attempts to dispatch an action that changes the file manager mode between upload and browse.
 * Triggers a warning prompt if an upload is in progress
 *
 * @param {Object} mode The selected file manager mode
 */
export const tryChangeFileManagerMode = (mode) => ({
  type: TRY_SET_FILE_MANAGER_MODE,
  mode,
});

export const deleteFileByIdRequest = ({ fileId }) => ({
  type: DELETE_FILE_BY_ID_REQUEST,
  fileId,
});

export const deleteFileByIdSuccess = () => ({
  type: DELETE_FILE_BY_ID_SUCCESS,
});

export const deleteFileByIdFailure = () => ({
  type: DELETE_FILE_BY_ID_FAILURE,
});
