export const SWITCH_CHAT_WINDOW = 'chat/SWITCH_CHAT_WINDOW';

export const ENABLE_CHAT = 'chat/ENABLE_CHAT';

export const APPEND_UTTERANCE_HISTORY = 'chat/APPEND_UTTERANCE_HISTORY';
export const APPEND_INFORMATIONAL_MESSAGE = 'chat/APPEND_INFORMATIONAL_MESSAGE';
export const CLEAR_UTTERANCE_HISTORY = 'chat/CLEAR_UTTERANCE_HISTORY';

export const OPEN_CHAT_PANEL_FULLY = 'chat/OPEN_CHAT_PANEL_FULLY';
export const OPEN_CHAT_PANEL_CONTEXTUALLY = 'chat/OPEN_CHAT_PANEL_CONTEXTUALLY';

export const UPDATE_CHAT_PANEL_STYLE = 'chat/UPDATE_CHAT_PANEL_STYLE';
export const UPDATE_CHAT_PANEL_TEXT = 'chat/UPDATE_CHAT_PANEL_TEXT';

export const OPEN_CHAT_PANEL = 'chat/OPEN_CHAT_PANEL';
export const CLOSE_CHAT_PANEL = 'chat/CLOSE_CHAT_PANEL';

export const SET_HAS_UNREAD_MESSAGES = 'chat/SET_HAS_UNREAD_MESSAGES';

export const SELECT_CHART = 'chat/SELECT_CHART';

export const SET_CURRENT_UTTERANCE = 'chat/SET_CURRENT_UTTERANCE';

export const SET_SHOULD_FLUSH_TEXTBOX = 'chat/SET_SHOULD_FLUSH_TEXTBOX';

export const enableChat = ({ enable = true }) => ({
  type: ENABLE_CHAT,
  enable,
});

export const switchChatWindow = ({ bottom } = {}) => ({
  type: SWITCH_CHAT_WINDOW,
  toggle: bottom === undefined,
  bottom,
});

export const appendUtteranceHistory = ({ utterance } = {}) => ({
  type: APPEND_UTTERANCE_HISTORY,
  utterance,
});

export const appendInformationalMessage = ({ message, sessionId } = {}) => ({
  type: APPEND_INFORMATIONAL_MESSAGE,
  message,
  sessionId,
});

export const clearUtteranceHistory = () => ({
  type: CLEAR_UTTERANCE_HISTORY,
});

export const openChatPanelFully = () => ({
  type: OPEN_CHAT_PANEL_FULLY,
});

export const openChatPanelContextually = () => ({
  type: OPEN_CHAT_PANEL_CONTEXTUALLY,
});

export const updateChatPanelStyle = ({ height, bottom }) => ({
  type: UPDATE_CHAT_PANEL_STYLE,
  height,
  bottom,
});

export const updateChatPanelText = ({ text }) => ({
  type: UPDATE_CHAT_PANEL_TEXT,
  text,
});

export const openChatPanel = () => ({
  type: OPEN_CHAT_PANEL,
});

export const closeChatPanel = () => ({
  type: CLOSE_CHAT_PANEL,
});

export const setHasUnreadMessages = ({ hasUnreadMessages }) => ({
  type: SET_HAS_UNREAD_MESSAGES,
  hasUnreadMessages,
});

/**
 * Updated by the Textbox whenever the utterance is changed.
 * For use only by Textbox component to give other components access to the Textbox's text.
 * @param {string} text current utterance in the Textbox component
 */
export const setCurrentUtterance = ({ text }) => ({
  type: SET_CURRENT_UTTERANCE,
  text,
});

/**
 * Called when the user selects a specific chart to focus on.
 *
 * @param {String} contextId index into the state's messages array representing the desired chart.
 */
export const selectChart = ({ contextId }) => ({
  type: SELECT_CHART,
  contextId,
});

/**
 * Called to notify the textbox to flush its input value.
 * Used by the ReplayController to show placeholder messages after submitting messages
 *
 * @param {boolean} shouldFlushTextbox
 */
export const setShouldFlushTextbox = ({ shouldFlushTextbox }) => ({
  type: SET_SHOULD_FLUSH_TEXTBOX,
  shouldFlushTextbox,
});
