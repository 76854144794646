import axios from 'axios';
import qs from 'qs';
import { dcAppServiceEndpoints, endpoints } from '../constants/endpoints';
import { handleDCAppServiceCall } from '../utils/errorHandling/errorHandlers.api';

export const getConfig = (accessToken) =>
  axios.get(endpoints.config, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

export const putConfig = (accessToken, config) =>
  axios.put(endpoints.config, qs.stringify({ config: JSON.stringify(config) }), {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

// Retrieves the user record.  This contains user fields like Phone Number and Organization ID.
export const getUserRecord = (accessToken) =>
  axios.get(endpoints.userRecord, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

// Updates the user record.  Should send a json object containing the appropriate user fields.
export const postUserRecord = (accessToken, userRecord) =>
  axios.post(endpoints.userRecord, qs.stringify(userRecord), {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

export const getSystemVersion = (accessToken) =>
  axios.get(endpoints.systemVersion, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

export const getConnectionList = (accessToken) =>
  axios({
    url: endpoints.dbConnection,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    method: 'GET',
  });

export const getConnection = (accessToken, id) =>
  axios({
    url: `${endpoints.dbConnection}/${id}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    method: 'GET',
  });

export const deleteDatabaseConnection = (accessToken, id) =>
  axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    method: 'DELETE',
    url: `${endpoints.dbConnection}/${id}`,
  });

export const postDatabaseConnection = (accessTok, values) =>
  axios({
    headers: {
      Authorization: `Bearer ${accessTok}`,
    },
    method: 'POST',
    url: endpoints.dbConnection,
    data: values,
  });

export const putDatabaseConnection = (accessToken, id, values) =>
  axios.put(`${endpoints.dbConnection}/${id}`, values, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

/**
 * Creates a copy of the database connection with the new name.
 *
 * @param {string} accessToken
 * @param {string} id
 * @param {string} name
 */
export const postCopyDBConnection = (accessToken, id, name) =>
  axios.post(endpoints.dbConnectionCopy, qs.stringify({ id, name }), {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

export const getDatabaseConnectionHeaders = (accessTok, connectionName) =>
  axios({
    headers: { Authorization: `Bearer ${accessTok}` },
    params: { connectionName },
    method: 'HEAD',
    url: endpoints.dbConnection,
  });

// DC App Service test connection endpoint which supports testing all types of database connections.
export const testAllDbConnections = (accessToken, values) =>
  handleDCAppServiceCall(() =>
    axios.post(dcAppServiceEndpoints.testAllDbConnection, values, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }),
  );

export const getDatabaseFieldOptions = (accessTok, values) =>
  axios({
    headers: {
      Authorization: `Bearer ${accessTok}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    url: endpoints.dbFieldOptions,
    data: values,
  });
