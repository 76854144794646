import React from 'react';
import { connect } from 'react-redux';

import MaterialTable from '@material-table/core';
import AddCircleOutlineOutlined from '@mui/icons-material/AddCircleOutlineOutlined';
import ArrowBack from '@mui/icons-material/ArrowBack';
import Close from '@mui/icons-material/Close';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import Tooltip from '@mui/material/Tooltip';
import {
  closeUserSelector,
  modifyOrganizationUsersSelectState,
  setOrganizationUsersSelectState,
} from '../../store/actions/settings.actions';
import { tableIcons } from '../DeleteFileManager/tableIcons';

import {
  selectOrganizationUsersSelectState,
  selectUsersInOrganizationById,
} from '../../store/sagas/selectors';
import './index.scss';

type Props = {
  closeUserSelector: () => mixed,
  modifyOrganizationUsersSelectState: () => mixed,
  setOrganizationUsersSelectState: () => mixed,
  organizationUsers: Array<mixed>,
  organizationUsersSelectState: Array<mixed>,
};

/**
 * Controls logic for the User Selection Menu.
 */
class UserSelectMenu extends React.Component<Props> {
  componentDidMount() {
    // set organizationUsersSelectState to organizationUsers
    // check if organizationUsersSelectState is empty
    const { organizationUsersSelectState, organizationUsers } = this.props;
    if (organizationUsersSelectState.length === 0) {
      this.props.setOrganizationUsersSelectState(organizationUsers);
    }
  }

  /**
   * Contains the component that renders the list of users from all
   * across the organization.
   */
  userListRenderComponent = () => {
    return (
      <div className="UserSelectMenu">
        <div className="UserSelectMenu-Header ListHeader" id="UserSelectMenu-Header">
          <div
            className="UserSelectMenuPeopleAccessList-Header-Title"
            onClick={() => this.props.closeUserSelector()}
          >
            <ArrowBack />
            Back
          </div>

          <div
            className="UserSelectMenu-Header-CloseButton"
            onClick={() => this.props.closeUserSelector()}
          >
            <Close />
          </div>
        </div>
        <div className="Body" id="UserSelectMenu-Body">
          <MaterialTable
            columns={[
              { title: 'Name', field: 'name', cellStyle: { padding: '1rem', width: '2.5rem' } },
              {
                title: 'Email',
                field: 'email',
                cellStyle: { padding: '1rem', width: '2.5rem' },
              },
            ]}
            data={this.props.organizationUsersSelectState}
            options={{
              actionsColumnIndex: -1,
              paging: false,
              // dont show header title
              showTitle: false,
              search: true,
              headerStyle: {
                fontFamily: "'Source Sans Pro', sans-serif",
                fontSize: '14px',
                color: '#4e5a5f',
                fontWeight: '700',
                textTransform: 'uppercase',
                backgroundColor: '#f8f8f8',
              },
              rowStyle: {
                fontFamily: "'Source Sans Pro', sans-serif",
                fontSize: '0.875rem',
              },
            }}
            localization={{
              body: {
                emptyDataSourceMessage: 'No users found',
              },
              toolbar: {
                searchPlaceholder: 'Search',
              },
              header: {
                actions: '',
              },
            }}
            icons={tableIcons}
            actions={[
              (rowData) => ({
                icon: () => {
                  if (rowData.selected) {
                    return (
                      <Tooltip title="Remove User">
                        <CloseOutlined color="error" />
                      </Tooltip>
                    );
                  }
                  return (
                    <Tooltip title="Add User">
                      <AddCircleOutlineOutlined />
                    </Tooltip>
                  );
                },
                onClick: () => {
                  // if it is undefined then set it to true
                  this.props.modifyOrganizationUsersSelectState(rowData.email, !rowData?.selected);
                },
              }),
            ]}
          />
        </div>
      </div>
    );
  };

  render() {
    return <div className="UserSelectMenu-Backdrop">{this.userListRenderComponent()}</div>;
  }
}

const mapStateToProps = (state) => ({
  userEmail: state.auth.email,
  accessToken: state.auth.accessToken,
  organizationUsers: selectUsersInOrganizationById(state),
  organizationUsersSelectState: selectOrganizationUsersSelectState(state),
});

export default connect(mapStateToProps, {
  closeUserSelector,
  modifyOrganizationUsersSelectState,
  setOrganizationUsersSelectState,
})(UserSelectMenu);
