// eslint-disable-next-line
import { ApolloProvider } from '@apollo/client';
/**
 * This is the JavaScript entry point of the entire web application.
 */
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
// eslint-disable-next-line
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import ErrorBoundary from './components/common/ErrorBoundary/ErrorBoundary';

import App from './App';
import { persistor, store } from './configureStore';
import { DEV } from './constants/env';
import { selectTheme } from './store/sagas/selectors';

import { ThemeProvider } from './antd-ui-kit';
import { client } from './apollo/apolloClient';
import { AuthProvider } from './components/PermissionWrapper';

// store injection functions

import Auth0ProviderWithRedux from './auth0/Auth0ProviderWithRedux';
import { injectStoreToHomeScreenConstants } from './constants/home_screen';
import { injectStoreToAskAvaSaga } from './store/sagas/askAva.saga';
import { injectPersistorToAuthSaga } from './store/sagas/auth.saga';
import { injectStoreToEditorSaga } from './store/sagas/editor.saga';
import { injectStoreToHomeScreenSaga } from './store/sagas/home_screen.saga';
import { injectStoreToClient4xxErrorHandlers } from './utils/errorHandling/handlers/Client4xxErrorHandler';
import { injectStoreToDatabaseAuthErrorHandlers } from './utils/errorHandling/handlers/DatabaseAuthErrorHandler';
import { injectStoreToOAuthErrorHandlers } from './utils/errorHandling/handlers/OAuthErrorHandler';
import { injectStoreToHomeScreenConstantsTypeScript } from './utils/homeScreen/homeScreenActions';
import { injectStoreToHomeScreenUtils } from './utils/home_screen';

// Inject the store to where it used outside of components
// https://redux.js.org/faq/code-structure#how-can-i-use-the-redux-store-in-non-component-files
// TODO: We are currently injecting the store in many files: if possible, refactor the files,
// to avoid calling the store from outside a component.
// -------------------------------------------------------
injectStoreToHomeScreenUtils(store);
injectStoreToHomeScreenConstants(store);
injectStoreToOAuthErrorHandlers(store);
injectStoreToClient4xxErrorHandlers(store);
injectStoreToDatabaseAuthErrorHandlers(store);
injectStoreToHomeScreenConstantsTypeScript(store);
injectStoreToEditorSaga(store);
injectStoreToHomeScreenSaga(store);
injectStoreToAskAvaSaga(store);
// persistor injection
injectPersistorToAuthSaga(persistor);
// -------------------------------------------------------
const theme = selectTheme(store.getState());

if (DEV) console.log(process.env); // eslint-disable-line no-console

if (module.hot) module.hot.accept();

const loading = <div>Loading user...</div>;

// This is where the App is bootstrapped.
// We tell the ReactJs library that we want to display our main App component
// inside a specific element in the DOM. In this case, there is a div with id="root"
// inside the index.html file. Our React App component will be injected into here
const root = document.getElementById('root');
if (root) {
  render(
    <ThemeProvider>
      <ApolloProvider client={client}>
        <Provider store={store}>
          <Auth0ProviderWithRedux>
            <AuthProvider>
              <PersistGate loading={loading} persistor={persistor}>
                <StyledEngineProvider injectFirst>
                  <CssVarsProvider theme={theme}>
                    <ErrorBoundary>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <App />
                      </LocalizationProvider>
                    </ErrorBoundary>
                  </CssVarsProvider>
                </StyledEngineProvider>
              </PersistGate>
            </AuthProvider>
          </Auth0ProviderWithRedux>
        </Provider>
      </ApolloProvider>
    </ThemeProvider>,
    root,
  );
}
