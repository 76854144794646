import { PayloadAction } from '@reduxjs/toolkit';
import { EventChannel } from 'redux-saga';
import { call, getContext, put, take, takeLatest } from 'typed-redux-saga';
import { ChartRenderSSEMessage } from '../../api/chart.api';
import { ReduxSagaContext } from '../../configureStore';
import { API_SERVICES } from '../../constants/api';
import { ChartRenderParams, Renderers } from '../../types/render.types';
import {
  getRender,
  getRenderFailure,
  getRenderSuccess,
  updateRenderProgress,
} from '../slices/chartRenders.slice';

/**
 * A helper saga that listens to an event channel to handle SSE chart render events.
 *
 * @param eventChannel - The channel to listen to.
 * @param dcChartId - The chart id.
 * @param renderer - The renderer name.
 * @param dimensions - An object containing width and height (always passed).
 *
 * @returns boolean - Returns true if a render was received and we exited early.
 */
export function* handleRenderChannel(
  eventChannel: EventChannel<ChartRenderSSEMessage>,
  dcChartId: string,
  renderer: Renderers,
  dimensions: { width?: number; height?: number } = {},
): Generator<any, boolean, any> {
  while (true) {
    const data = yield* take(eventChannel);

    if (data.progress) {
      yield* put(
        updateRenderProgress({
          dcChartId,
          renderer,
          progress: data.progress,
          ...dimensions,
        }),
      );
    }

    if (data.data) {
      yield* put(
        getRenderSuccess({
          dcChartId,
          renderer,
          render: data.data,
          ...dimensions,
        }),
      );
      eventChannel.close();
      return true;
    }
  }
}

export function* getChartRenderWorker({ payload }: PayloadAction<ChartRenderParams>) {
  const { dcChartId, renderer, width, height } = payload;
  const chartingService = (yield* getContext(
    API_SERVICES.CHART,
  )) as ReduxSagaContext[API_SERVICES.CHART];

  let eventChannel: EventChannel<ChartRenderSSEMessage> | null = null;
  let getSuccess: boolean = false;

  try {
    // Always listen for render events with dimensions and exit as soon as data is received.
    eventChannel = yield* call([chartingService, 'getChartRender'], payload);
    getSuccess = yield* call(handleRenderChannel, eventChannel, dcChartId, renderer, {
      width,
      height,
    });
    if (getSuccess) return;

    // Trigger a render with a PUT request
    yield* call([chartingService, 'putChartRender'], payload);

    // Re-subscribe to events
    eventChannel = yield* call([chartingService, 'getChartRender'], payload);
    getSuccess = yield* call(handleRenderChannel, eventChannel, dcChartId, renderer, {
      width,
      height,
    });
    if (getSuccess) return;
  } catch (error) {
    yield* put(
      getRenderFailure({
        dcChartId,
        renderer,
        error,
        width,
        height,
      }),
    );
  } finally {
    if (eventChannel) {
      eventChannel.close();
    }
  }
}

export default function* chartRendersSaga() {
  yield* takeLatest(getRender, getChartRenderWorker);
}
