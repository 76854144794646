import * as Yup from 'yup';
import {
  DATABASE_CONNECTION_FIELDS,
  FORM_INPUT_TYPES,
  YUP_VALIDATION_SCHEMAS,
} from '../../utils/connection/connection';

import { IntegrationProvider } from '../../types/integrations.types';
import { createDatabaseType, hideIfTrue } from './__helpers__';

const NAME = 'BigQuery';
export const BigQueryConnectionFields = {
  projectName: 'projectName',
  datasetName: 'datasetName',
  useOAuth: 'useOAuth',
  credentialFile: 'credentialFile',
  datasets: 'datasets',
  readOnly: 'readOnly',
};

export default {
  meta: {
    name: NAME,
  },
  body: {
    validationSchema: Yup.object().shape({
      [BigQueryConnectionFields.projectName]: Yup.string()
        .test(
          'no-leading-trailing-spaces',
          'Project name should not contain leading or trailing spaces.',
          (value) => (value ? value.trim() === value : true),
        )
        .required('Required'),
      [BigQueryConnectionFields.datasetName]: Yup.string()
        .test(
          'no-leading-trailing-spaces',
          'Dataset name should not contain leading or trailing spaces.',
          (value) => (value ? value.trim() === value : true),
        )
        .required('Required'),
      // Credential file is required only when useOauth is false
      [BigQueryConnectionFields.credentialFile]: Yup.mixed().when('useOAuth', {
        is: false, // When useOauth is false
        then: Yup.mixed()
          .required('Required')
          .test('fileExists', 'Required', (value) => !!value),
        otherwise: Yup.mixed().nullable(), // If not required, make it nullable or optional
      }),

      // New useOauth toggle
      [BigQueryConnectionFields.useOAuth]: Yup.boolean().required('Required'),
      [BigQueryConnectionFields.datasets]: YUP_VALIDATION_SCHEMAS.DATASETS,
      [BigQueryConnectionFields.readOnly]: Yup.boolean().required('Required'),
    }),
    fields: [
      { ...createDatabaseType, value: NAME },
      {
        id: BigQueryConnectionFields.projectName,
        label: 'Project',
        value: '',
        type: FORM_INPUT_TYPES.TEXT,
      },
      {
        id: BigQueryConnectionFields.datasetName,
        label: 'Dataset Name',
        value: '',
        type: FORM_INPUT_TYPES.TEXT,
      },
      {
        id: BigQueryConnectionFields.useOAuth,
        label: 'Use OAuth',
        value: false,
        isRequired: true,
        provider: IntegrationProvider.BIGQUERY,
        type: FORM_INPUT_TYPES.OAuthToggle,
      },
      {
        id: 'credentialFile',
        label: 'Credential File',
        value: '',
        type: FORM_INPUT_TYPES.FILE,
        hideIfFn: (values) => hideIfTrue(values, BigQueryConnectionFields.useOAuth),
      },
      DATABASE_CONNECTION_FIELDS.DATASETS,
      {
        id: BigQueryConnectionFields.readOnly,
        label: 'readOnly',
        value: false,
        isRequired: true,
        type: FORM_INPUT_TYPES.CHECKBOX,
      },
    ],
  },
};
