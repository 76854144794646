import PivotGrid, {
  FieldChooser,
  FieldPanel,
  LoadPanel,
  Scrolling,
} from 'devextreme-react/pivot-grid';
import 'devextreme/dist/css/dx.material.purple.light.compact.css';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import React from 'react';

import './CustomPivotGrid.scss';

interface CustomPivotGridProps {
  width: number;
  height: number;
  dataSource: PivotGridDataSource;
  onCellPrepared?: (e: any) => void;
  onContextReady?: (e: any) => void;
  scrollingMode?: string;
  visible?: boolean;
}

export const CustomPivotGrid = (props: CustomPivotGridProps) => {
  const { width, height, dataSource, onCellPrepared, onContextReady, scrollingMode, visible } =
    props;

  return (
    <PivotGrid
      visible={visible ?? true}
      id="pivot-displayPanel"
      dataSource={dataSource}
      onCellPrepared={onCellPrepared}
      onContentReady={onContextReady}
      width={width}
      height={height}
      allowSorting
      allowFiltering={false}
      allowExpandAll
    >
      <FieldPanel
        showColumnFields
        showDataFields
        showFilterFields={false}
        showRowFields
        allowFieldDragging
        visible
      />
      <FieldChooser enabled={false} />
      <LoadPanel enabled={false} />
      <Scrolling mode={scrollingMode} />
    </PivotGrid>
  );
};
