export const OPEN_CONTACT_FORM = 'contact_form/OPEN_CONTACT_FORM';
export const CLOSE_CONTACT_FORM = 'contact_forms/CLOSE_CONTACT_FORM';

/* Send contact form */
export const SEND_CONTACT_FORM_REQUEST = 'contact_form/SEND_CONTACT_FORM_REQUEST';
export const SEND_CONTACT_FORM_FAILURE = 'contact_form/SEND_CONTACT_FORM_FAILURE';
export const SEND_CONTACT_FORM_SUCCESS = 'contact_form/SEND_CONTACT_FORM_SUCCESS';

/**
 * Opens the contact form.
 */
export const openContactForm = ({ subject, content, messageType, hiddenDetails='' }) => ({
  type: OPEN_CONTACT_FORM,
  subject,
  content,
  messageType,
  hiddenDetails
});

/**
 * Closes the contact form.
 */
export const closeContactForm = () => ({
  type: CLOSE_CONTACT_FORM,
});

/**
 * Send the contact form
 */
export const sendContactFormRequest = ({ contactForm }) => ({
  type: SEND_CONTACT_FORM_REQUEST,
  contactForm,
});

/**
 * Send contact form was a success
 */
export const sendContactFormSuccess = () => ({
  type: SEND_CONTACT_FORM_SUCCESS,
});

/**
 * Send contact form was a Failure
 */
export const sendContactFormFailure = ({ error }) => ({
  type: SEND_CONTACT_FORM_FAILURE,
  error,
});
