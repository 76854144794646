import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ReactJson from 'react-json-view';
import { COLUMN_TYPES } from '../../../../utils/formatting/constants';
import { isNumeric } from '../../../../utils/formatting/type';
import { smartParseObject } from '../../../../utils/string';
import Text from '../../../ChatPanel/ChatContext/BubbleComponent/Text';
import ColumnAnnotation from '../../../DatasetDisplayPanel/ColumnAnnotation/ColumnAnnotation';
import ColumnStat from '../../../DatasetDisplayPanel/ColumnStatistics/ColumnStat';
import { CELL_TYPES } from './constants';
import { getCellType } from './utils';

/**
 * Renders cells as either a string, json, or datachat link
 */
const CellContents = (props) => {
  const {
    params,
    enableCellClick,
    showStat,
    showColumnAnnotation,
    type,
    expandedCells,
    onCellClick,
  } = props;
  const { value, colDef, id: rowId } = params;
  const { type: columnType, field: columnName } = colDef;

  const cellId = `column_${columnName}-row_${rowId}`;

  const [expanded, setExpanded] = useState(expandedCells[cellId]);
  const numeric = isNumeric(columnType);

  const handleCellClick = () => {
    if (enableCellClick) {
      const isExpanded = !expanded;
      setExpanded(isExpanded);
      if (onCellClick) onCellClick(isExpanded, cellId);
    }
  };

  // Parse the value and convert it to a string
  const result = smartParseObject(value);

  const cellType = getCellType(result);

  if (params.id === 0) {
    if (showStat) {
      return <ColumnStat colType={type} colName={params.field} />;
    }
    if (showColumnAnnotation) {
      return <ColumnAnnotation colName={params.field} />;
    }
  }

  switch (cellType) {
    case CELL_TYPES.PLAIN_TEXT:
      return (
        <div
          className={`cell-text-container${numeric ? ' numeric' : ''}`}
          onClick={handleCellClick}
        >
          <div className={`cell-text${expanded ? ' expanded' : ''}`}>
            {colDef?.valueFormatter ? colDef.valueFormatter(params)?.toString() : result}
          </div>
        </div>
      );
    case CELL_TYPES.LINK:
      return (
        <div className="cell-text-container" onClick={handleCellClick}>
          <div className={`cell-text${expanded ? ' expanded' : ''}`}>
            <Text data={result} expanded={expanded} />
          </div>
        </div>
      );
    case CELL_TYPES.TABLE:
      return (
        <div className="nested-table-cell">
          <Text data={result} />
        </div>
      );
    case CELL_TYPES.JSON:
      return (
        <ReactJson
          src={result}
          collapsed
          enableClipboard={false}
          displayDataTypes={false}
          displayObjectSize={false}
          name={false} // to avoid displaying "root"
          iconStyle="circle"
          indentWidth={2}
        />
      );
    default:
      return 'null';
  }
};

CellContents.propTypes = {
  enableCellClick: PropTypes.bool,
  expandedCells: PropTypes.object,
  onCellClick: PropTypes.func,
  params: PropTypes.object.isRequired, // GridRenderCellParams defined from MuiDataGridPro
  showStat: PropTypes.bool,
  showColumnAnnotation: PropTypes.bool,
  type: PropTypes.string,
};

CellContents.defaultProps = {
  enableCellClick: true,
  expandedCells: {},
  onCellClick: null,
  showStat: false,
  showColumnAnnotation: false,
  type: COLUMN_TYPES.UNDEFINED,
};

export default CellContents;
