import { buffers } from 'redux-saga';
import {
  actionChannel,
  call,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { sendMessageRequest } from '../actions/messages.actions';

import { getComplete } from '../../api/complete.api';
import { authenticate } from '../../utils/authenticate';
import { parseJSONIfPossible } from '../../utils/string';
import { isPrompt } from '../../utils/suggestions_utils';
import { loadSuggestionsFailure } from '../actions/suggestions.actions';
import {
  EXECUTE_UTTERANCE_GROUP,
  LOAD_PARTIAL_SUGGESTIONS_REQUEST_FORM,
  LOAD_SUGGESTIONS_REQUEST_FORM,
  clearUtteranceComposer,
  loadSuggestionsFailureForm,
  loadSuggestionsSuccessForm,
} from '../actions/utterance_composer.actions';
import { selectSession } from '../selectors/session.selector';
import { selectAccessToken } from './selectors';

/**
 * Executes the utterance composed through the UtteranceComposer UI
 */
export function* executeComposedUtteranceGroupWorker({ utterance }) {
  const sessionID = yield select(selectSession);
  yield put(sendMessageRequest({ message: utterance, sessionID }));
  yield put(clearUtteranceComposer());
}

export function* loadSuggestionsWorkerForm({ message, id }) {
  try {
    const accessToken = yield select(selectAccessToken);
    const session = yield select(selectSession);
    const response = yield call(
      getComplete,
      message,
      accessToken,
      session,
      false, // AC requests in form should not be self interrupting
    );
    // TODO: Use axios interceptors to retrieve the exact time of request and response for a more accurate result.
    // Separate suggestions and prompts
    // Ignore null in tuple for prompts (take only second value)
    // and ignore entity name in tuple for suggestions (take only first value).
    const suggestions = [];
    const prompts = [];
    for (let i = 0; i < response.data.length; i++) {
      const curr = response.data[i];
      if (isPrompt(curr)) {
        prompts.push(curr[1]);
      } else {
        // If we have both prompt and suggestion, send both of them to textbox
        // so that when none of the suggestions match with user input
        // we can still show the prompts.
        prompts.push(curr[1]);
        suggestions.push(parseJSONIfPossible(curr[0]));
      }
    }
    yield put(loadSuggestionsSuccessForm({ suggestions, message, id, prompts }));
  } catch (error) {
    yield put(loadSuggestionsFailureForm({ error, id, message }));
  }
}

export function* loadPartialSuggestionsWorkerForm({ message, id }) {
  try {
    const accessToken = yield select(selectAccessToken);
    const session = yield select(selectSession);
    const response = yield call(
      getComplete,
      message,
      accessToken,
      session,
      false, // AC requests in form should not be self interrupting
    );
    // TODO: Use axios interceptors to retrieve the exact time of request and response for a more accurate result.
    // Separate suggestions and prompts
    // Ignore null in tuple for prompts (take only second value)
    // and ignore entity name in tuple for suggestions (take only first value).
    const suggestions = [];
    const prompts = [];
    for (let i = 0; i < response.data.length; i++) {
      const curr = response.data[i];
      if (isPrompt(curr)) {
        prompts.push(curr[1]);
      } else {
        // If we have both prompt and suggestion, send both of them to textbox
        // so that when none of the suggestions match with user input
        // we can still show the prompts.
        prompts.push(curr[1]);
        suggestions.push(parseJSONIfPossible(curr[0]));
      }
    }
    yield put(loadSuggestionsSuccessForm({ suggestions, message, id, prompts }));
  } catch (error) {
    yield put(loadSuggestionsFailureForm({ error, id, message }));
  }
}

/**
 * Spins up a worker that manages the latest API request for suggestions.
 * Queue at most 1 request at a time.
 * Discard any overflowing requests which come before the current request is complete.
 */
export function* loadPartialSuggestionsWatcher() {
  const requestChan = yield actionChannel(
    LOAD_PARTIAL_SUGGESTIONS_REQUEST_FORM,
    buffers.sliding(1),
  );
  while (true) {
    const payload = yield take(requestChan);
    yield call(authenticate(loadPartialSuggestionsWorkerForm, loadSuggestionsFailure), payload);
  }
}

export default function* () {
  yield takeEvery(LOAD_SUGGESTIONS_REQUEST_FORM, loadSuggestionsWorkerForm);
  yield takeLatest(EXECUTE_UTTERANCE_GROUP, executeComposedUtteranceGroupWorker);
  yield fork(loadPartialSuggestionsWatcher);
}
