import {
  COLUMN_HEADER_HEIGHT,
  ESTIMATED_ROW_HEIGHT,
  INSIGHTS_TABLE_EXPANSION_LIMIT,
} from '../../../components/DisplayPanel/Charts/Table/constants';

import {
  MAX_PIVOT_HEIGHT,
  MAX_PIVOT_WIDTH,
  PIVOT_COLUMN_MAX_WIDTH,
  PIVOT_PADDING,
  PIVOT_ROW_HEIGHT,
} from '../../../components/DisplayPanel/Charts/PivotTable/constants';

// Constants used for determining the layout using the react-grid-layout library
// These values are used to translate the layout for old IBs from grid units to px
export const REACT_GRID_LAYOUT = {
  /**
   *  Number of grid columns in an insights board
   */
  COLS: 12,
  PADDING: [10, 10],
  MARGIN: [5, 5],
  ROW_HEIGHT: 100,
  /**
   * Minimum width of an item in number of grid columns
   */
  MIN_W: 1,
  /**
   * Maximum width of an item in number of grid columns
   */
  MAX_W: 12,
  /**
   * Minimum height of an item in number of grid columns
   */
  MIN_H: 1,
  /**
   * Maximum height of an item in number of grid columns
   */
  MAX_H: 15,
};

// Calculates the width of each column in pixels for react-grid-layout wrt viewport width
// Reference: https://github.com/react-grid-layout/react-grid-layout/blob/5126e7be9a1e38053e078f2b17e1a0f3a7abcda0/lib/calculateUtils.js#L14
export const calculateGridColWidth = (containerWidth) => {
  const { COLS, MARGIN, PADDING } = REACT_GRID_LAYOUT;
  return (containerWidth - MARGIN[0] * (COLS - 1) - PADDING[0] * 2) / COLS;
};

// Translates the width in react-grid-layout grid units to pixels
// Reference: https://github.com/react-grid-layout/react-grid-layout/blob/5126e7be9a1e38053e078f2b17e1a0f3a7abcda0/lib/calculateUtils.js#L120
export const translateGridWidthToPixels = (w, gridColWidth) => {
  const { MARGIN } = REACT_GRID_LAYOUT;
  return w * (gridColWidth + MARGIN[0]) - MARGIN[0];
};

// Translates the height in react-grid-layout grid units to pixels
// Reference: https://github.com/react-grid-layout/react-grid-layout/blob/5126e7be9a1e38053e078f2b17e1a0f3a7abcda0/lib/calculateUtils.js#L120
export const translateGridHeightToPixels = (h) => {
  const { MARGIN, ROW_HEIGHT } = REACT_GRID_LAYOUT;
  return h * (ROW_HEIGHT + MARGIN[1]) - MARGIN[1];
};

// Translates the X coordinates in react-grid-layout grid units to pixels
// Reference: https://github.com/react-grid-layout/react-grid-layout/blob/5126e7be9a1e38053e078f2b17e1a0f3a7abcda0/lib/calculateUtils.js#L85
export const translateXCoordsToPixels = (x, gridColWidth) => {
  const { MARGIN } = REACT_GRID_LAYOUT;
  return x * (gridColWidth + MARGIN[0]) + MARGIN[0];
};

// Translates the Y coordinates in react-grid-layout grid units to pixels
// Reference: https://github.com/react-grid-layout/react-grid-layout/blob/5126e7be9a1e38053e078f2b17e1a0f3a7abcda0/lib/calculateUtils.js#L85
export const translateYCoordsToPixels = (y) => {
  const { MARGIN, ROW_HEIGHT } = REACT_GRID_LAYOUT;
  return y * (ROW_HEIGHT + MARGIN[1]) + MARGIN[1];
};

/**
 * Determines the default size (height and width) for a note containing
 * the given number of character
 * @param {Number} charCount number of characters in the note
 */
const noteSize = (charCount) => {
  // Arbitrary parameters determined through experimentation:
  const PARAM = 175;
  const MIN_VAL = 2;
  const MAX_VAL = 8;
  // The height/width grows with the square root of the area of a square
  // which in turn should be approximately proportional to the number
  // of characters in the note
  const adjustedCharNum = Math.max(charCount / PARAM - 1, 0);
  const noteSizeInGridUnits = Math.min(Math.ceil(Math.sqrt(adjustedCharNum)) + MIN_VAL, MAX_VAL);
  return noteSizeInGridUnits;
};

/**
 * Determines the default width for a table containing the given total
 * column length and number of columns
 * @param {Number} totalColumnsLength the sum of (the maximum number of characters
 * in any cell of a column) over all columns
 * @param {Number} numColumns number of columns in table
 */
const tableWidth = (totalColumnsLength, numColumns, windowWidth) => {
  // Arbitrary parameters determined through experimentation:
  const COLUMN_LENGTH_PARAM = 15;
  const NUM_COLUMNS_PARAM = 3;
  const MIN_WIDTH = 3;
  const MAX_WIDTH = REACT_GRID_LAYOUT.COLS;
  // The width of the table grows linearly with both the number of columns
  // and the total length of each column
  const widthInGridUnits = Math.min(
    Math.max(
      Math.ceil(totalColumnsLength / COLUMN_LENGTH_PARAM + numColumns / NUM_COLUMNS_PARAM),
      MIN_WIDTH,
    ),
    MAX_WIDTH,
  );
  const gridColWidth = calculateGridColWidth(windowWidth);
  const widthInPixels = translateGridWidthToPixels(widthInGridUnits, gridColWidth);
  return widthInPixels;
};

/**
 * Determines the default height for a table containing the given number of rows
 * and whether it has a caption or paging
 * @param {Number} rowCount number of rows in table
 * @param {Boolean} hasPaging table has paging
 * @param {Boolean} hasCaption table has a caption
 */
const tableHeight = (rowCount, hasPaging, hasCaption) => {
  // TODO: update logic for captions when bug in IB is fixed:
  // https://github.com/DataChatAI/datachat/issues/27713
  const CAPTION_HEIGHT = 20;
  const TABLE_MARGINS = 2; // margins in pixels
  // Get the default font size set in the user's browser. If unavailable, fall back to a default of 16px
  const defaultFontSize =
    window
      ?.getComputedStyle(document.body, null)
      ?.getPropertyValue('font-size')
      ?.replace(/[^0-9]/g, '') || '16px';
  // Header height for all charts is defined in em units in Chart.scss
  const tableHeaderHeightInEm = 1.3;
  // Convert the header height from em to px
  const tableHeaderHeightInPx = tableHeaderHeightInEm * Number(defaultFontSize);
  // Total padding applied to the header
  const tableHeaderPaddingInPx = 17;
  // Adds height for caption
  const tableCaptionHeightInPx = hasCaption ? CAPTION_HEIGHT : 0;
  // Determines the number of rows shown by default in the table
  const numRows = Math.min(INSIGHTS_TABLE_EXPANSION_LIMIT, rowCount);
  // Calculates the height of the inner table
  const DataGridHeightInPixels =
    numRows * ESTIMATED_ROW_HEIGHT + COLUMN_HEADER_HEIGHT + (hasPaging ? 53 : 0) + TABLE_MARGINS;
  const calculatedHeightInPx = Math.ceil(
    DataGridHeightInPixels +
      tableHeaderHeightInPx +
      tableHeaderPaddingInPx +
      tableCaptionHeightInPx,
  );

  return calculatedHeightInPx;
};

export const INSIGHTS_BOARD_VARIABLE_LAYOUT = (width, height, windowWidth) => {
  const { MIN_W, MAX_W, MIN_H, MAX_H } = REACT_GRID_LAYOUT;
  const gridColWidth = calculateGridColWidth(windowWidth);
  // All variables/widgets by default have a height of 2 grid units and width of 2 grid units.
  // The Toggle type widget has dynamic width assigned based on the number of options.
  return {
    minW: translateGridWidthToPixels(MIN_W, gridColWidth),
    w: translateGridWidthToPixels(width, gridColWidth),
    maxW: translateGridWidthToPixels(MAX_W, gridColWidth),
    minH: translateGridHeightToPixels(MIN_H),
    h: translateGridHeightToPixels(height),
    maxH: translateGridHeightToPixels(MAX_H),
    z: 0,
  };
};

/**
 * Determines default values for note containing the given number of characters
 * @param {Number} charCount number of characters in given note
 */
export const INSIGHTS_BOARD_NOTES_LAYOUT = (charCount, windowWidth) => {
  const currNoteSize = noteSize(charCount, windowWidth);
  const gridColWidth = calculateGridColWidth(windowWidth);
  const { MIN_W, MAX_W, MIN_H, MAX_H } = REACT_GRID_LAYOUT;
  return {
    minW: translateGridWidthToPixels(MIN_W, gridColWidth),
    w: translateGridWidthToPixels(currNoteSize, gridColWidth),
    maxW: translateGridWidthToPixels(MAX_W, gridColWidth),
    minH: translateGridHeightToPixels(MIN_H),
    h: translateGridHeightToPixels(currNoteSize),
    maxH: translateGridHeightToPixels(MAX_H),
    z: 0,
  };
};

// Constants describing the Insights Board Layout
export const INSIGHTS_BOARD_PAGE = {
  PADDING_X: 5,
  PADDING_Y: 5,
  IB_PADDING: 15,
  VERTICAL_MENU_WIDTH: 52,
};

/**
 * Determines the default values for a table with the given number of rows,
 * total columns length, and number of columns
 * @param {Number} rowCount number of rows in table
 * @param {Number} totalColumnsLength the sum of (the maximum number of characters
 * in any cell of a column) over all columns
 * @param {Number} numColumns number of columns in table
 */
export const INSIGHTS_BOARD_TABLES_LAYOUT = (
  rowCount,
  hasPaging,
  totalColumnsLength,
  numColumns,
  hasCaption,
  windowWidth,
) => {
  const height = tableHeight(rowCount, hasPaging, hasCaption, windowWidth);
  const width = tableWidth(totalColumnsLength, numColumns, windowWidth);
  const { MIN_W, MAX_W, MIN_H, MAX_H } = REACT_GRID_LAYOUT;
  const gridColWidth = calculateGridColWidth(windowWidth);
  return {
    minW: translateGridWidthToPixels(MIN_W, gridColWidth),
    w: width,
    maxW: translateGridWidthToPixels(MAX_W, gridColWidth),
    minH: translateGridHeightToPixels(MIN_H),
    h: height,
    maxH: translateGridHeightToPixels(MAX_H),
    z: 0,
  };
};

export const INSIGHTS_BOARD_PIVOT_LAYOUT = (data) => {
  const { dimensions } = data.values;
  let height = (dimensions[0] + 2) * PIVOT_ROW_HEIGHT;
  height = height > MAX_PIVOT_HEIGHT ? MAX_PIVOT_HEIGHT : height;
  const outerHeight = height + PIVOT_PADDING + 30;

  let width = (PIVOT_COLUMN_MAX_WIDTH + 22) * (dimensions[1] + 1);

  width = width > MAX_PIVOT_WIDTH ? MAX_PIVOT_WIDTH : width;

  return {
    minW: 300,
    w: width,
    maxW: width,
    minH: 200,
    h: outerHeight,
    maxH: outerHeight,
    z: 0,
  };
};

// Constants describing the default size of an unknown publication
export const INSIGHTS_BOARD_DEFAULT_LAYOUT = (windowWidth) => {
  const { MIN_W, MAX_W, MIN_H, MAX_H } = REACT_GRID_LAYOUT;
  const gridColWidth = calculateGridColWidth(windowWidth);
  // All unknown publications by default have a height and width of 6 grid units:
  return {
    minW: translateGridWidthToPixels(MIN_W, gridColWidth),
    w: translateGridWidthToPixels(6, gridColWidth),
    maxW: translateGridWidthToPixels(MAX_W, gridColWidth),
    minH: translateGridHeightToPixels(MIN_H),
    h: translateGridHeightToPixels(6),
    maxH: translateGridHeightToPixels(MAX_H),
    z: 0,
    tempLayout: true,
  };
};

export const INSIGHTS_BOARD_PLOT_LAYOUT = (windowWidth) => {
  const { MIN_W, MAX_W, MIN_H, MAX_H } = REACT_GRID_LAYOUT;
  const gridColWidth = calculateGridColWidth(windowWidth);
  // All plots by default have a height and width of 6 grid units:
  return {
    minW: translateGridWidthToPixels(MIN_W, gridColWidth),
    w: translateGridWidthToPixels(6, gridColWidth),
    maxW: translateGridWidthToPixels(MAX_W, gridColWidth),
    minH: translateGridHeightToPixels(MIN_H),
    h: translateGridHeightToPixels(6),
    maxH: translateGridHeightToPixels(MAX_H),
    z: 0,
  };
};

// Polls start fast and back off gradually such that we're never more than 10% of the total wait time behind.
// Ex. 1 sec -> 0.1 s poll time; 10 sec -> 1 sec poll time
export const INSIGHTS_BOARD_REFRESH_MIN_POLL_RATE = 200;
export const INSIGHTS_BOARD_REFRESH_BACKOFF_MIN_PERCENT = 0.2;

// export const INSIGHTS_BOARD_UNDO_STACK_LENGTH = 20;

// Number of publication to fetch at a time for each fetch
export const NUM_PUBS_TO_FETCH = [2, 4, 8, 16];

export const LAYOUTS = ['default', 'wide', 'thin'];

export const ZOOM_OPERATIONS = ['zoom in', 'zoom out', 'reset'];

export const EMPTY_IB_TEXT =
  'This Insights Board is empty. Use the Publish skill to add charts to this board.';

// className for the Insights Board Page Container
export const IB_PAGE_CONTAINER = 'Insights-Board-Page-Container';

/**
 * Titles for IB errors that are displayed using dialogs
 */
export const INSIGHTS_BOARD_ERRORS = {
  GET_IB_PUB_LIBRARY: 'Failed to get Publication Library',
  DELETE_IB_PUBS: 'Failed to delete Publications',
  GET_CROSS_FILTERS: 'Failed to get Cross Filters',
  SAVE_CROSS_FILTERS: 'Failed to save Cross Filters',
};
