import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getSubscriptionTiers } from '../../api/subscriptionTiers.api';
import {
  GET_SUBSCRIPTION_TIERS_REQUEST,
  getSubscriptionTiersFailure,
  getSubscriptionTiersSuccess,
} from '../actions/subscriptionTiers.actions';
import { selectAccessToken } from './selectors';

function* getSubscriptionTiersRequestWorker() {
  try {
    const accessToken = yield select(selectAccessToken);
    const subscriptionTiers = yield call(getSubscriptionTiers, accessToken);
    yield put(
      getSubscriptionTiersSuccess({
        subscriptionTiers: subscriptionTiers.data,
      }),
    );
  } catch (err) {
    yield put(getSubscriptionTiersFailure(err));
  }
}

export default function* () {
  yield takeLatest(GET_SUBSCRIPTION_TIERS_REQUEST, getSubscriptionTiersRequestWorker);
}
