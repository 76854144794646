import Checkbox from '@mui/material/Checkbox';
import PropTypes from 'prop-types';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { isWorkflowEditorPage } from '../../../constants/paths';
import { handleSelectChart } from '../../../store/actions/chart_selection.actions';
import { selectCurrentLocation } from '../../../store/sagas/selectors';
import { selectIsReplaying } from '../../../store/selectors/context.selector';

/**
 * A functional component to render a check box to select visual elements
 */
const SelectBox = (props) => {
  const {
    chartStatuses,
    objectId,
    data,
    isPopOutChart,
    isSelecting,
    isReplaying,
    isWorkflowEditor,
  } = props;

  // redux dispatch hook
  const dispatch = useDispatch();

  // from the chartStatuses array, find the index of the item whose objectId
  // matches the objectId we receive from props
  const index = chartStatuses.findIndex((item) => item.objectId === objectId);
  const selected = chartStatuses[index] && chartStatuses[index].selected;
  const selectable = chartStatuses[index] && chartStatuses[index].selectable;

  const onChangeHandler = (event) => {
    dispatch(handleSelectChart(index, event.target.checked));
  };

  return (
    <>
      {selected !== undefined &&
        selectable &&
        !isPopOutChart &&
        !isWorkflowEditor &&
        !data.isDependencyGraph && (
          <Checkbox
            className={`${!isSelecting && 'grey-out'}`}
            checked={selected}
            disabled={isReplaying}
            onChange={onChangeHandler}
            onClick={(event) => event.stopPropagation()}
          />
        )}
    </>
  );
};

SelectBox.propTypes = {
  chartStatuses: PropTypes.array.isRequired,
  objectId: PropTypes.string,
  data: PropTypes.object.isRequired,
  isPopOutChart: PropTypes.bool.isRequired,
  isSelecting: PropTypes.bool.isRequired,
  isReplaying: PropTypes.bool.isRequired,
  isWorkflowEditor: PropTypes.bool.isRequired,
};

SelectBox.defaultProps = {
  objectId: undefined,
};

const mapStateToProps = (state) => ({
  chartStatuses: state.chartSelection.chartStatuses,
  isReplaying: selectIsReplaying(state),
  isWorkflowEditor: isWorkflowEditorPage(selectCurrentLocation(state)),
});

export default connect(mapStateToProps, {})(SelectBox);
