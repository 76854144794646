// Open connection editor actions
export const OPEN_CONNECTION_EDITOR = 'connection/OPEN_CONNECTION_EDITOR';
export const OPEN_CONNECTION_EDITOR_REQUEST = 'connection/OPEN_CONNECTION_EDITOR_REQUEST';
export const OPEN_CONNECTION_EDITOR_SUCCESS = 'connection/OPEN_CONNECTION_EDITOR_SUCCESS';
export const OPEN_CONNECTION_EDITOR_FAILURE = 'connection/OPEN_CONNECTION_EDITOR_FAILURE';

// Update Connection
export const UPDATE_CONNECTION_REQUEST = 'connection/UPDATE_CONNECTION_REQUEST';
export const UPDATE_CONNECTION_SUCCESS = 'connection/UPDATE_CONNECTION_SUCCESS';
export const UPDATE_CONNECTION_FAILURE = 'connection/UPDATE_CONNECTION_FAILURE';

// Delete Connection
export const DELETE_CONNECTION_REQUEST = 'connection/DELETE_CONNECTION_REQUEST';
export const DELETE_CONNECTION_SUCCESS = 'connection/DELETE_CONNECTION_SUCCESS';
export const DELETE_CONNECTION_FAILURE = 'connection/DELETE_CONNECTION_FAILURE';

// Get Connection and Object data
export const GET_CONNECTION_OBJECT_DATA_REQUEST = 'connection/GET_CONNECTION_OBJECT_DATA_REQUEST';
export const GET_CONNECTION_OBJECT_DATA_SUCCESS = 'connection/GET_CONNECTION_OBJECT_DATA_SUCCESS';
export const GET_CONNECTION_OBJECT_DATA_FAILURE = 'connection/GET_CONNECTION_OBJECT_DATA_FAILURE';

// Get Connection and Object data
export const TEST_CONNECTION_REQUEST = 'connection/TEST_CONNECTION_REQUEST';
export const TEST_CONNECTION_SUCCESS = 'connection/TEST_CONNECTION_SUCCESS';
export const TEST_CONNECTION_FAILURE = 'connection/TEST_CONNECTION_FAILURE';

// Clear Connection
export const CLEAR_CONNECTION = 'connection/CLEAR_CONNECTION';

// Close Connection Editor
export const CLOSE_CONNECTION_EDITOR = 'connection/CLOSE_CONNECTION_EDITOR';

// Set Edit Mode
export const SET_EDIT_MODE = 'connection/SET_EDIT_MODE';

// Set selected DB
export const SET_SELECTED_DB = 'connection/SET_SELECTED_DB';

/**
  Dispatch an action open a new or existing connection editor
  @param {String} id // Id of the connection from dc_connection_file
  @param {Object} object // Connection object
  @param {Boolean} newTab  // Boolean to open connection editor in a new tab or existing tab
*/

export const openConnectionEditor = ({ uuid = '', object = {} }) => ({
  type: OPEN_CONNECTION_EDITOR,
  uuid,
  object,
});

/**
 *
 * @param {{
 *  uuid?: string,
 *  object?: import("../../utils/homeScreen/types").HomeObjectKeysTypes,
 *  newTab?: boolean
 * }} object
 */
export const openConnectionEditorRequest = ({ uuid = '', object = {}, newTab = false }) => ({
  type: OPEN_CONNECTION_EDITOR_REQUEST,
  uuid,
  object,
  newTab,
});

export const openConnectionEditorSuccess = ({ uuid, object }) => ({
  type: OPEN_CONNECTION_EDITOR_SUCCESS,
  uuid,
  object,
});

export const openConnectionEditorFailure = ({ uuid, object, error }) => ({
  type: OPEN_CONNECTION_EDITOR_FAILURE,
  uuid,
  object,
  error,
});

/**
  Dispatch an action to create or update the connection
  @param {object} o
  @param {object} o.object // Connection Object
  @param {boolean} o.isEditing // Connection data
  @param {object} [o.payload] // Connection data
  @param {string} [o.saveAsName] // Name of the connection to be saved as
*/
export const updateConnectionRequest = ({ object, payload, isEditing, saveAsName = null }) => ({
  type: UPDATE_CONNECTION_REQUEST,
  object,
  isEditing,
  payload,
  saveAsName,
});

export const updateConnectionSuccess = ({ object, payload }) => ({
  type: UPDATE_CONNECTION_SUCCESS,
  object,
  payload,
});

export const updateConnectionFailure = ({ object, payload, error }) => ({
  type: UPDATE_CONNECTION_FAILURE,
  object,
  payload,
  error,
});

/**
  Dispatch an action to delete the connection
  @param {Object} object  // Connection Object
*/
export const deleteConnectionRequest = ({ object }) => ({
  type: DELETE_CONNECTION_REQUEST,
  object,
});

export const deleteConnectionSuccess = ({ object }) => ({
  type: DELETE_CONNECTION_SUCCESS,
  object,
});

export const deleteConnectionFailure = ({ object, error }) => ({
  type: DELETE_CONNECTION_FAILURE,
  object,
  error,
});

/**
  Dispatch an action to clear the connection state in store
*/
export const clearConnection = () => ({
  type: CLEAR_CONNECTION,
});

/**
 * Dispatch an action to close the connection editor
 */
export const closeConnectionEditor = () => ({
  type: CLOSE_CONNECTION_EDITOR,
});

/**
  Dispatch an action to get the connection object and connection data for edit connections
  @param {{uuid: string}} object // Uuid of the connection object
*/
export const getConnectionObjectDataRequest = ({ uuid }) => ({
  type: GET_CONNECTION_OBJECT_DATA_REQUEST,
  uuid,
});

export const getConnectionObjectDataSuccess = ({ uuid, object, data }) => ({
  type: GET_CONNECTION_OBJECT_DATA_SUCCESS,
  uuid,
  object,
  data,
});

export const getConnectionObjectDataFailure = ({ uuid, error }) => ({
  type: GET_CONNECTION_OBJECT_DATA_FAILURE,
  uuid,
  error,
});

export const setEditMode = ({ editMode }) => ({
  type: SET_EDIT_MODE,
  editMode,
});

export const setSelectedDb = ({ database }) => ({
  type: SET_SELECTED_DB,
  database,
});

/**
  Dispatch an action to test the connection
  @param {String} databaseType // Type of the database connection
  @param {String} payload // Payload values for the connection
*/
export const testConnectionRequest = ({ databaseType, payload }) => ({
  type: TEST_CONNECTION_REQUEST,
  databaseType,
  payload,
});

export const testConnectionSuccess = ({ databaseType, payload }) => ({
  type: TEST_CONNECTION_SUCCESS,
  databaseType,
  payload,
});

export const testConnectionFailure = ({ databaseType, payload, error }) => ({
  type: TEST_CONNECTION_FAILURE,
  databaseType,
  payload,
  error,
});
