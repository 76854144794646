import { createSelector } from 'reselect';
import { RootState } from '../../configureStore';

export const selectCurrentTaskId = (state: RootState) => state.task.currentTaskId;

export const selectActiveTasks = (state: RootState) => state.task.activeTasks;

export const selectCurrentTask = createSelector([selectActiveTasks], (activeTasks) => {
  if (!activeTasks?.length) return null;
  // return the task with the latest creation time
  return activeTasks.reduce(
    (curr, t) => (t.creationTime > curr.creationTime ? t : curr),
    activeTasks[0],
  );
});

export const selectTask = createSelector(
  [selectActiveTasks, (_state: RootState, taskId: string) => taskId],
  (activeTasks, taskId) => {
    return activeTasks?.find((t) => t.id === taskId) ?? null;
  },
);
