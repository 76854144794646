import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import isEqual from 'lodash/isEqual';
import { CatalogState, ColumnAnnotations, DatasetAnnotation } from '../../types/catalog.types';
import { DeepPartial } from '../../types/DeepPartial.types';
import merge from '../../utils/merge';

export const CATALOG_TITLE = 'Data Assistant Dictionary';
export const CATALOG_BETA_CHIP_TEXT =
  "Use the Data Assistant Dictionary to define your datasets and columns. During beta, these definitions apply only to base datasets and won't carry over to future versions.";

export const DEFAULT_COLUMN_ANNOTATION = {
  annotation: '',
  safeToShare: false,
};

export const DEFAULT_DATASET_ANNOTATION: DatasetAnnotation = {
  annotation: '',
  columns: {},
};

export const initialState: CatalogState = {
  open: false,
  selectedDatasetId: null,
  datasetAnnotations: {},
  edits: {},
  delete: {},
};

/**
 * Given a list of column annotations, we filters out any columns that have been deleted
 *
 * @param columns - The column annotations
 * @param deletedColumns - The list of columns that have been deleted
 * @returns The column annotations with the deleted columns removed
 */
export const filterDeletedColumns = (columns: ColumnAnnotations, deletedColumns: string[]) => {
  return Object.keys(columns)
    .filter((column) => !deletedColumns.includes(column))
    .reduce((cols, name) => {
      cols[name] = columns[name];
      return cols;
    }, {} as ColumnAnnotations);
};

/**
 * Deletes a column edit if it is no different than the original saved column annotation
 *
 * @param state The current catalog state
 * @param datasetId ID of the dataset
 * @param column Column within the dataset
 */
const removeColumnIfSameAsOriginal = (state: CatalogState, datasetId: string, column: string) => {
  // extract the annotation
  const savedAnnotation =
    (state.datasetAnnotations[datasetId]?.kind === 'data'
      ? state.datasetAnnotations[datasetId].data?.columns?.[column]
      : undefined) ?? DEFAULT_COLUMN_ANNOTATION;
  const editedAnnotation = state.edits[datasetId]?.columns?.[column] ?? DEFAULT_COLUMN_ANNOTATION;
  const defaultSafeToShare = DEFAULT_COLUMN_ANNOTATION.safeToShare;

  // Compute if the edited annotation is the same as the saved annotation
  const annotationMatch =
    (savedAnnotation.annotation ?? '') === (editedAnnotation.annotation ?? '');
  // Compute if the safeToShare value is the same as the saved annotation
  const safeToShareMatch =
    (editedAnnotation.safeToShare ?? defaultSafeToShare) ===
    (savedAnnotation.safeToShare ?? defaultSafeToShare);

  // Delete the column annotation if all conditions are met

  if (annotationMatch && safeToShareMatch) {
    delete state.edits[datasetId]?.columns?.[column];
  }
};

/** Gets a dataset's annotation from the store
 *
 * @param state - The current catalog state
 * @param datasetId - The ID of the dataset
 * @returns The dataset's annotation
 */
const getAnnotation = (state: CatalogState, datasetId: string): DatasetAnnotation | undefined => {
  const annotationAsyncResult = state.datasetAnnotations[datasetId] ?? {};
  const annotationData =
    annotationAsyncResult && annotationAsyncResult.kind === 'data' && annotationAsyncResult.data
      ? annotationAsyncResult.data
      : undefined;
  return annotationData;
};

/**
 * Merges the original annotations with the edited annotations and filters out any deleted columns
 *
 * @param annotation - Saved annotation
 * @param edited - Edited annotation
 * @param deleted - Deleted columns
 * @returns The merged annotations
 */
export const getMergedColumnAnnotations = (
  annotation: DatasetAnnotation,
  edited: DeepPartial<DatasetAnnotation>,
  deleted: string[],
) => {
  const mergedAnnotations = merge(annotation, edited);
  const columns: ColumnAnnotations = mergedAnnotations.columns ?? {};
  return filterDeletedColumns(columns, deleted);
};

/**
 * Checks if all columns are safe to share
 *
 * @param columnAnnotations - The column annotations
 * @param allColumns - The list of all columns
 * @returns True if all columns are safe to share, false otherwise
 */
export const getAllSafeToShare = (columnAnnotations: ColumnAnnotations, allColumns: string[]) =>
  allColumns.every((column) => {
    return columnAnnotations[column]?.safeToShare ?? DEFAULT_COLUMN_ANNOTATION.safeToShare;
  });

/** Deletes a dataset edit if it is no different than the original */
export const deleteEditIfSameAsOriginal = (state: CatalogState, datasetId: string) => {
  let original =
    (state.datasetAnnotations[datasetId]?.kind === 'data'
      ? state.datasetAnnotations[datasetId].data
      : undefined) ?? DEFAULT_DATASET_ANNOTATION;
  // temporarily remove deleted columns from the original annotation
  original = {
    ...original,
    columns: filterDeletedColumns(original.columns ?? {}, state.delete[datasetId] ?? []),
  };

  const edited = state.edits[datasetId] ?? DEFAULT_DATASET_ANNOTATION;
  const mergedEdits = merge(original, edited);

  if (isEqual(original, mergedEdits)) {
    delete state.edits[datasetId];
  }
};

const selectDatasetHelper = (state: CatalogState, datasetId: string) => {
  state.selectedDatasetId = datasetId;
  state.datasetAnnotations[datasetId] = { kind: 'loading' };
};

const catalogSlice = createSlice({
  name: 'catalog',
  initialState,
  reducers: {
    /** Opens the catalog. */
    open: {
      prepare: (datasetId?: string) => ({ payload: datasetId }),
      reducer: (state: CatalogState, { payload }: PayloadAction<string | undefined>) => {
        state.open = true;
        if (payload) selectDatasetHelper(state, payload);
      },
    },
    /** Closes the catalog and discards any unsaved changes. */
    close: (state: CatalogState) => {
      state.open = false;
      state.edits = {};
    },
    /** Changes the catalog's selected datset.  */
    selectDataset: {
      prepare: (datasetId: string) => ({ payload: datasetId }),
      reducer: (state: CatalogState, { payload }: PayloadAction<string>) => {
        selectDatasetHelper(state, payload);
      },
    },
    closeCatalogRequest: () => {},
    /** Stores a dataset annotation. */
    gotDatasetAnnotation: {
      prepare: (datasetId: string, annotation: DatasetAnnotation) => ({
        payload: { datasetId, annotation },
      }),
      reducer: (
        state: CatalogState,
        { payload }: PayloadAction<{ datasetId: string; annotation: DatasetAnnotation }>,
      ) => {
        state.datasetAnnotations[payload.datasetId] = { kind: 'data', data: payload.annotation };
      },
    },
    gotDatasetAnnotationError: (
      state: CatalogState,
      { payload }: PayloadAction<{ datasetId: string; error: Error }>,
    ) => {
      state.datasetAnnotations[payload.datasetId] = { kind: 'error', error: payload.error };
    },
    /** Stores the edit made to a dataset annotation. */
    edit: {
      prepare: (datasetId: string, annotation: string) => ({
        payload: { datasetId, annotation },
      }),
      reducer: (
        state: CatalogState,
        { payload }: PayloadAction<{ datasetId: string; annotation: string }>,
      ) => {
        // get the datasetId and annotation from the payload
        const { datasetId, annotation } = payload;

        const currentEditData = state.edits[datasetId] ?? DEFAULT_DATASET_ANNOTATION;

        // apply save the edit
        state.edits[datasetId] = { ...currentEditData, annotation };

        // check if the newly edited annotation is the same as the original annotation
        deleteEditIfSameAsOriginal(state, datasetId);
      },
    },
    /** Stores edits made to column annotations */
    editColumn: {
      prepare: (datasetId: string, column: string, annotation: string, safeToShare: boolean) => ({
        payload: { datasetId, column, annotation, safeToShare },
      }),
      reducer: (
        state: CatalogState,
        {
          payload,
        }: PayloadAction<{
          datasetId: string;
          column: string;
          annotation: string;
          safeToShare: boolean;
        }>,
      ) => {
        const { datasetId, column, annotation, safeToShare } = payload;

        const currentEditData = state.edits[datasetId] ?? DEFAULT_DATASET_ANNOTATION;
        // apply the edit
        state.edits[datasetId] = {
          annotation: currentEditData?.annotation,
          columns: {
            ...currentEditData?.columns,
            [column]: { annotation, safeToShare },
          },
        };

        // delete the column edit if it's the same as the original
        removeColumnIfSameAsOriginal(state, datasetId, column);

        // if a user edits this column, remove it from the delete list
        if (state.delete[datasetId]) {
          state.delete[datasetId] = state.delete[datasetId].filter((c) => c !== column);
        }

        // check if the newly edited annotation is the same as the original annotation
        deleteEditIfSameAsOriginal(state, datasetId);
      },
    },
    toggleSafeToShare: (
      state: CatalogState,
      { payload }: PayloadAction<{ datasetId: string; allColumns: string[] }>,
    ) => {
      const { datasetId, allColumns } = payload;

      // Get the dataset's annotation, edited annotations, and deleted columns
      const annotation: DatasetAnnotation =
        getAnnotation(state, datasetId) ?? DEFAULT_DATASET_ANNOTATION;
      const currentEditData = state.edits[datasetId] ?? {};
      const deleted = [...(state.delete[datasetId] ?? [])];

      // Merge the annotations and filter out the deleted columns
      const columns = getMergedColumnAnnotations(annotation, currentEditData, deleted);

      const allSafeToShare = getAllSafeToShare(columns, allColumns);
      allColumns.forEach((column) => {
        state.edits[datasetId] = {
          ...state.edits[datasetId],
          columns: {
            ...state.edits[datasetId]?.columns,
            [column]: { ...columns[column], safeToShare: !allSafeToShare },
          },
        };
        removeColumnIfSameAsOriginal(state, datasetId, column);
      });

      deleteEditIfSameAsOriginal(state, datasetId);
    },
    deleteColumn: (
      state: CatalogState,
      { payload }: PayloadAction<{ datasetId: string; column: string }>,
    ) => {
      const { datasetId, column } = payload;
      // Add the column to the delete list
      state.delete[datasetId] = [...(state.delete[datasetId] ?? []), column];

      // Remove the column from the edit list
      delete state.edits[datasetId]?.columns?.[column];

      // Check if the dataset edit is the same as the original
      deleteEditIfSameAsOriginal(state, datasetId);
    },
    /** Signals a dataset's annotation should be saved. */
    save: {
      prepare: (datasetId: string) => ({ payload: datasetId }),
      reducer: () => {},
    },
    /** Signals all dataset annotations should be saved. */
    saveAll: () => {},
    /** Discards edits made to a dataset's annotation. */
    discard: {
      prepare: (datasetId: string) => ({ payload: datasetId }),
      reducer: (state: CatalogState, { payload: datasetId }: PayloadAction<string>) => {
        delete state.edits[datasetId];
        delete state.delete[datasetId];
      },
    },
    /** Discards all edits made to any dataset annotations. */
    discardAll: (state: CatalogState) => {
      state.edits = {};
      state.delete = {};
    },
  },
});

export const {
  open,
  close,
  selectDataset,
  edit,
  editColumn,
  toggleSafeToShare,
  deleteColumn,
  gotDatasetAnnotation,
  gotDatasetAnnotationError,
  save,
  saveAll,
  discard,
  discardAll,
  closeCatalogRequest,
} = catalogSlice.actions;

export default catalogSlice.reducer;
