import { Descriptions as antDescriptions, DescriptionsProps } from 'antd';
import styled from 'styled-components';

type IDescriptionsProps = DescriptionsProps;

const Descriptions = styled(antDescriptions)<IDescriptionsProps>``;

export type { DescriptionsProps } from 'antd';

export default Descriptions;
