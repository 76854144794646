/*
  This 'enum' mirrors the one found in dcworkspace.go.
 */
export const workspaceObjectTypes = {
  WORKSPACE: 'workspace',
  WORKFLOW: 'workflow',
  SNAPSHOT: 'snapshot',
  INSIGHTS_BOARD: 'insights_board',
  FOLDER: 'folder',
  CONNECTION: 'connection',
  ANY: 'any',
  QUERY: 'query',
  DATAFILE: 'datafile',
  DATASET: 'dataset',
};

export const myWorkWorkspaceTypes = new Set([
  workspaceObjectTypes.WORKFLOW,
  workspaceObjectTypes.INSIGHTS_BOARD,
  workspaceObjectTypes.SNAPSHOT,
  workspaceObjectTypes.CONNECTION,
  workspaceObjectTypes.FOLDER,
  workspaceObjectTypes.QUERY,
  workspaceObjectTypes.DATAFILE,
]);

export const workspaceAccessTypes = {
  MODIFY: 'MODIFY',
  VIEW: 'VIEW',
};

export const workspaceTableStyle = {
  maxWidth: '100%',
  overflow: 'auto',
  zIndex: 0,
};

export const workspaceTableOptions = {
  paging: false,
  actionsColumnIndex: -1,
  rowStyle: {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: '0.875rem',
  },
};

export const workspaceTableLocalization = {
  header: {
    actions: '',
  },
};

export const sharedQueryParam = 'shared';

export const WORKSPACE_ACCESS_TYPES = {
  OWNER: 'owner',
  VIEWER: 'viewer',
  EDITOR: 'editor',
  COMMENTER: 'commenter', // Not supported for now
};

/**
 * Enum for the different types of workspace access
 */
export const WORKSPACE_ACCESS = {
  [WORKSPACE_ACCESS_TYPES.VIEWER]: 'view',
  [WORKSPACE_ACCESS_TYPES.EDITOR]: 'edit',
  [WORKSPACE_ACCESS_TYPES.COMMENTER]: 'comment', // Not supported for now
};

export const WORKSPACE_VISIBILITY = {
  VISIBLE: 'visible',
  HIDDEN: 'hidden',
};

export const ROOT_FOLDER = {
  uuid: 'root',
  objectName: 'My Work',
  accessType: WORKSPACE_ACCESS_TYPES.OWNER,
};
