import { REPRESENTATION_TYPES } from '../../../../utils/formatting/type';

export const getMissingColumns = (
  annotatedColumnsNames: string[],
  tableColumns: { name: string; type: string }[],
): { name: string; type: string }[] => {
  return annotatedColumnsNames
    .filter(
      (col) =>
        !tableColumns.some((tableCol: { name: string; type: string }) => tableCol.name === col),
    )
    .map((col) => ({ name: col, type: REPRESENTATION_TYPES.STRING }));
};

export const formatCatalogSample = (
  rows: any[][],
  columns: { name: string; type: string }[],
  missingColumns: { name: string; type: string }[],
): { [column: string]: any }[] =>
  rows.map((row) => {
    const newRow: { [col: string]: any } = {};
    // Insert the missing columns here
    missingColumns.forEach((col) => {
      newRow[col.name] = null;
    });

    // reformat the existing row data
    return columns.reduce(
      (acc: { [key: string]: any }, col: { name: string; type: string }, idx: number) => {
        acc[col.name] = row[idx];
        return acc;
      },
      newRow,
    );
  });
