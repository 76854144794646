import { EXPERIMENTAL_REMOVED_OBJECTS } from '../../constants/home_screen_temp';
import { HomeObjects } from '../../utils/homeScreen/types';

// Types that we can load from the LoadCard
const SUPPORTED_LOAD_TYPES = [HomeObjects.DATASET, HomeObjects.DATAFILE];

// Types that we will display in the LoadCard. Must include all of SUPPORTED_LOAD_TYPES
const SUPPORTED_DISPLAY_TYPES = [...SUPPORTED_LOAD_TYPES, HomeObjects.FOLDER];

/**
 * Get the supported load types dependent on devMode flag
 */
export const getSupportedLoadTypes = (devMode: boolean) =>
  devMode
    ? SUPPORTED_LOAD_TYPES.filter((t) => !Array.from(EXPERIMENTAL_REMOVED_OBJECTS).includes(t))
    : SUPPORTED_LOAD_TYPES;

/**
 * Get the supported display types dependent on devMode flag
 */
export const getSupportedDisplayTypes = (devMode: boolean) =>
  devMode
    ? SUPPORTED_DISPLAY_TYPES.filter((t) => !Array.from(EXPERIMENTAL_REMOVED_OBJECTS).includes(t))
    : SUPPORTED_DISPLAY_TYPES;
