import {
  PublicationActionStatuses,
  PublicationActions,
  PublicationTypes,
} from '../../constants/embed';
import { parseModelSpec } from '../../utils/model';
import {
  GET_ACTION_RESULT_ERROR,
  GET_ACTION_RESULT_FAILURE,
  GET_ACTION_RESULT_REQUEST,
  GET_ACTION_RESULT_SUCCESS,
  GET_PUBLICATION_FAILURE,
  GET_PUBLICATION_REQUEST,
  GET_PUBLICATION_SUCCESS,
  GET_REFRESHED_CHART_FAILURE,
  GET_REFRESHED_CHART_REQUEST,
  RESET_PRIVATE_EMBED_PAGE,
  SUBMIT_ACTION_FAILURE,
  SUBMIT_ACTION_REQUEST,
  SUBMIT_ACTION_SUCCESS,
} from '../actions/embed.actions';

/**
 * Returns a row object for display in the prediction table.
 * @param {Number} actionId id of the publication action
 * @param {Object} data API response data
 * @param {Object} modelOutput name of the target column
 * @returns
 */
const createResultTableRow = ({ actionId, data, modelOutput }) => {
  switch (data.status) {
    case PublicationActionStatuses.COMPLETE: {
      const parsedResult = JSON.parse(data.result.String)[0];
      return {
        ...parsedResult,
        actionId,
        [modelOutput.PredictName]: parsedResult[modelOutput.PredictName],
        PredictResultStatus: data.status,
      };
    }
    case PublicationActionStatuses.FAILURE: {
      return {
        actionId,
        [modelOutput.PredictName]: data.result.String,
        PredictResultStatus: PublicationActionStatuses.ERROR,
      };
    }
    case PublicationActionStatuses.INCOMPLETE:
    case PublicationActionStatuses.ERROR:
    default: {
      const parsedParameters = JSON.parse(data.parameters.String)[0];
      return {
        ...parsedParameters,
        actionId,
        [modelOutput.PredictName]: data.result.String,
        PredictResultStatus: data.status,
      };
    }
  }
};

const initialState = {
  apiKey: undefined,
  apiSecret: undefined,
  publicationId: undefined,
  publication: undefined,
  isLoading: true,
  publicationError: undefined,
  profilingError: undefined,
  submittingPrediction: false,
  submittingProfile: false,
  loadingProfileResult: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_PRIVATE_EMBED_PAGE:
      return initialState;
    /**
     * For retrieving a published object from the server
     */
    case GET_PUBLICATION_REQUEST:
      // pubId, pubType, refreshId, apiKey, apiSecret, accessToken
      return {
        ...state,
        publicationId: action.pubId,
        isLoading: true,
        apiKey: action.apiKey,
        apiSecret: action.apiSecret,
      };
    case GET_PUBLICATION_SUCCESS:
      // pubType, rawData
      if (
        action.pubType === PublicationTypes.CHART ||
        action.pubType === PublicationTypes.AVA_CONVERSATION
      ) {
        return { ...state, publication: action.rawData, isLoading: false };
      } else if (action.pubType === PublicationTypes.MODEL) {
        const parsedModelSpec = parseModelSpec(action.rawData.content);
        return {
          ...state,
          publication: {
            ...parsedModelSpec,
            predictionResults: [],
            profileResults: [],
            pickedFeatures: [],
            profileId: undefined,
          },
          isLoading: false,
        };
      }
      return state;
    case GET_PUBLICATION_FAILURE: // pubType, error
      return { ...state, publicationError: action.error, isLoading: false };

    /**
     * For submitting an action on a publication
     */
    case SUBMIT_ACTION_REQUEST: {
      // actionType, actionInput
      if (action.actionType === PublicationActions.PREDICT) {
        return { ...state, submittingPrediction: true };
      } else if (action.actionType === PublicationActions.PROFILE) {
        return { ...state, submittingProfile: true };
      }
      return state;
    }
    case SUBMIT_ACTION_SUCCESS: {
      // actionId, actionType, data
      if (action.actionType === PublicationActions.PREDICT) {
        const newPredictionRow = createResultTableRow({
          actionId: action.actionId,
          data: action.data,
          modelOutput: state.publication.modelOutput,
        });
        return {
          ...state,
          submittingPrediction: false,
          publication: {
            ...state.publication,
            predictionResults: [newPredictionRow, ...state.publication.predictionResults],
          },
        };
      } else if (action.actionType === PublicationActions.PROFILE) {
        return { ...state, submittingProfile: false };
      }
      return state;
    }
    case SUBMIT_ACTION_FAILURE: {
      // pubId, actionType, error
      if (action.actionType === PublicationActions.PREDICT) {
        return { ...state, submittingPrediction: false };
      } else if (action.actionType === PublicationActions.PROFILE) {
        return { ...state, submittingProfile: false };
      }
      return state;
    }

    /**
     * For retrieving the result of a publication action
     */
    case GET_ACTION_RESULT_REQUEST: {
      if (action.actionType === PublicationActions.PROFILE) {
        return { ...state, loadingProfileResult: true };
      }
      return state;
    }
    case GET_ACTION_RESULT_SUCCESS: {
      // pubId, actionId, actionType, data
      if (action.actionType === PublicationActions.PREDICT) {
        const updatedTableRow = createResultTableRow({
          actionId: action.actionId,
          data: action.data,
          modelOutput: state.publication.modelOutput,
        });
        return {
          ...state,
          submittingPrediction: false,
          publication: {
            ...state.publication,
            predictionResults: state.publication.predictionResults.map((predictionResult) => {
              if (predictionResult.actionId === action.actionId) return updatedTableRow;
              return predictionResult;
            }),
          },
        };
      } else if (action.actionType === PublicationActions.PROFILE) {
        const parameters = JSON.parse(action.data.parameters.String);
        const profileResults = JSON.parse(action.data.result.String);
        const initialPosition = profileResults[0];
        return {
          ...state,
          loadingProfileResult: false,
          profilingError: undefined,
          publication: {
            ...state.publication,
            profileResults,
            profileId: action.actionId,
            pickedFeatures: Object.entries(parameters)
              // eslint-disable-next-line no-unused-vars
              .filter(([_, value]) => Array.isArray(value))
              // eslint-disable-next-line no-unused-vars
              .map(([key, _]) => ({ name: key, selected: initialPosition[key] })),
          },
        };
      }
      return state;
    }
    case GET_ACTION_RESULT_ERROR: {
      // pubId, actionId, actionType, data
      if (action.actionType === PublicationActions.PREDICT) {
        const updatedTableRow = createResultTableRow({
          actionId: action.actionId,
          data: action.data,
          modelOutput: state.publication.modelOutput,
        });
        return {
          ...state,
          submittingPrediction: false,
          publication: {
            ...state.publication,
            predictionResults: state.publication.predictionResults.map((predictionResult) => {
              if (predictionResult.actionId === action.actionId) return updatedTableRow;
              return predictionResult;
            }),
          },
        };
      } else if (action.actionType === PublicationActions.PROFILE) {
        return {
          ...state,
          loadingProfileResult: false,
          profilingError: action.data.result.String,
        };
      }
      return state;
    }
    case GET_ACTION_RESULT_FAILURE: {
      // pubId, actionId, actionType, error
      if (action.actionType === PublicationActions.PREDICT) {
        const updatedTableRow = createResultTableRow({
          actionId: action.actionId,
          data: {
            result: { String: action.error.message, value: true },
            status: PublicationActionStatuses.FAILURE,
          },
          modelOutput: state.publication.modelOutput,
        });
        return {
          ...state,
          publication: {
            ...state.publication,
            predictionResults: state.publication.predictionResults.map((predictionResult) => {
              if (predictionResult.actionId === action.actionId) {
                return { ...predictionResult, ...updatedTableRow };
              }
              return predictionResult;
            }),
          },
        };
      } else if (action.actionType === PublicationActions.PROFILE) {
        return { ...state, loadingProfileResult: false, profilingError: action.error };
      }
      return state;
    }

    /**
     * For refreshing a published chart and retrieving that chart from the server
     * NOTE: Specific to embedded chart use
     */
    case GET_REFRESHED_CHART_REQUEST: // pubId, apiKey, apiSecret, accessToken
      return { ...state, isLoading: true, apiKey: action.apiKey, apiSecret: action.apiSecret };
    case GET_REFRESHED_CHART_FAILURE: // error
      return { ...state, publicationError: action.error, isLoading: false };

    default:
      return state;
  }
};
