/* eslint-disable class-methods-use-this */
import { ErrorDetails } from '../../../types/errorCodes/errorCodes.types';
import { BaseErrorHandler } from './BaseErrorHandler';

export class ConnectionErrorHandler extends BaseErrorHandler {
  statuses = [502, 504];

  errorCodes = ['ERR_NETWORK'];

  canHandle(errorDetails?: ErrorDetails): boolean {
    if (!errorDetails) return false;
    const status = errorDetails.status ?? 0;
    const errorCode = errorDetails.error_code ?? '';
    return this.statuses.includes(status) || this.errorCodes.includes(errorCode);
  }

  // Assumes we will either retry or ignore this connection error
  handle(): void {}
}
