import PropTypes from 'prop-types';
import React from 'react';
import { HOME_OBJECT_KEYS } from '../constants/home_screen';
import './DemoIndicator.scss';

/**
 * Should we add a demo tag to the object?
 * @param {Object} rowData The object to test
 * Returns true if it's a demo object
 */
const shouldAddDemoTag = (rowData) => {
  return rowData[HOME_OBJECT_KEYS.EXAMPLE_FK];
};

const DemoIndicator = ({ rowData }) => {
  return shouldAddDemoTag(rowData) ? <span className="Demo-Label">Demo</span> : <></>;
};

DemoIndicator.propTypes = {
  rowData: PropTypes.object,
};

DemoIndicator.defaultProps = {
  rowData: {},
};

export default DemoIndicator;
