import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CONNECTION_DATABASES_TYPES } from '../../../../constants/connection';
import { DefaultErrorMessage, EmptyPlaceholderText } from '../../../../constants/databaseBrowser';
import {
  clearConnection,
  openConnectionEditorRequest,
  setEditMode,
  setSelectedDb,
} from '../../../../store/actions/connection.actions';
import { selectSelectedDb } from '../../../../store/sagas/selectors';
import {
  selectDatabaseBrowserConnections,
  selectDatabaseBrowserConnectionsRequestStatus,
  selectDatabaseBrowserOpenConnection,
} from '../../../../store/selectors/dbBrowser.selector';
import { setConnection } from '../../../../store/slices/dbBrowser.slice';
import { HomeObjectKeys } from '../../../../utils/homeScreen/types';
import { isStatusSuccess } from '../../../../utils/home_screen';
import StatusPlaceholder from '../../../StatusPlaceholder';
import TextWithTooltip from '../../../common/TextWithTooltip';
import StickyWrapper, { StickyWrapperContentProps } from '../../StickyWrapper/StickyWrapper';
import DatabaseBrowserSearch from '../DatabaseBrowserSearch';
import NamespaceList from '../Namespaces/NamespaceList';
import './ConnectionList.scss';
import DatabaseItem from './DatabaseItem';

const ConnectionList = () => {
  const dispatch = useDispatch();

  const currentConnection = useSelector(selectDatabaseBrowserOpenConnection);
  const connectionList = useSelector(selectDatabaseBrowserConnections);
  // convert connectionList to a list of strings
  const formattedConnections = Object.values(connectionList).map((connection) => ({
    name: connection[HomeObjectKeys.NAME],
    id: connection[HomeObjectKeys.UUID],
  }));
  const connectionListRequestStatus = useSelector(selectDatabaseBrowserConnectionsRequestStatus);
  const selectedDb = useSelector(selectSelectedDb);

  const handleExpand = (connUUID: string) => {
    const isOpen = currentConnection?.[HomeObjectKeys.UUID] === connUUID;
    dispatch(setConnection({ connection: isOpen ? null : connectionList[connUUID] }));
  };

  const handleAddConnectionClick = () => {
    dispatch(clearConnection());
    dispatch(openConnectionEditorRequest({}));
    dispatch(setSelectedDb({ database: selectedDb || CONNECTION_DATABASES_TYPES.BIGQUERY }));
    dispatch(setEditMode({ editMode: false }));
  };

  if (!isStatusSuccess(connectionListRequestStatus)) {
    return (
      <Box className="DatabaseBrowser-ConnectionList">
        <StatusPlaceholder
          status={connectionListRequestStatus}
          errorText={DefaultErrorMessage.Database}
          isEmpty={Object.keys(connectionList).length === 0}
          emptyText={EmptyPlaceholderText.Connections}
        />
      </Box>
    );
  }

  return (
    <Box className="DatabaseBrowser-ConnectionList">
      <Box className="DatabaseBrowser-ConnectionList-Search">
        <DatabaseBrowserSearch />
        <Button
          className="DatabaseBrowser-ConnectionList-Add-Connection-Button"
          size="large"
          data-cy="add-connection-button"
          onClick={handleAddConnectionClick}
          variant="outlined"
        >
          <AddCircleOutlineIcon />
          <TextWithTooltip text="Add Connection" />
        </Button>
      </Box>
      <Box className="DatabaseBrowser-ConnectionList-Navigation">
        <StickyWrapper
          options={formattedConnections}
          onClick={handleExpand}
          Content={DatabaseItem}
          parents={[]}
          expandedOverride={[currentConnection?.[HomeObjectKeys.NAME] ?? '']}
          renderChildren={(args: StickyWrapperContentProps) => {
            return (
              <NamespaceList parents={[...args.parents, args.current]} current={args.current} />
            );
          }}
        />
      </Box>
    </Box>
  );
};

export default ConnectionList;
