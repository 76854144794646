import CloseIcon from '@mui/icons-material/Close';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import { AxiosError } from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import React from 'react';
import { connect } from 'react-redux';
import ReactResizeDetector from 'react-resize-detector';
import { getBinChipTitle } from 'translate_dc_to_echart';
import { ReactComponent as ExportMultipleFigureIcon } from '../../assets/icons/download_multi.svg';
import { isDataChatSessionPage } from '../../constants/paths';
import { TOAST_ERROR, TOAST_LONG } from '../../constants/toast';
import Chart from '../../echart-library/ChartMapping';
import { requestBulkExport } from '../../store/actions/bulk_charts_export.action';
import { modifyChartRequest } from '../../store/actions/chart.actions';
import { closeChartBuilder } from '../../store/actions/chart_builder.actions';
import { closeDialog, openAlertDialog } from '../../store/actions/dialog.actions';
import { sendMessageBypass } from '../../store/actions/messages.actions';
import { addToast, dismissAllToasts } from '../../store/actions/toast.actions';
import { selectGroupedTableObjects } from '../../store/sagas/selectors';
import { selectSession } from '../../store/selectors/session.selector';
import { updateChartRequest } from '../../store/slices/chartspace.slice';
import DCPlotV2 from '../DisplayPanel/Charts/dcplotV2/DCPlotV2';
import DCPlotV2ErrorBoundary from '../DisplayPanel/Charts/dcplotV2/DCPlotV2ErrorBoundary';
import ErrorBoundary from '../common/ErrorBoundary/ErrorBoundary';
import './ChartBuilder.scss';
import DataSample from './Footer/DataSample';
import Describe from './Footer/Describe';
import FooterDropdownContainer from './Footer/FooterDropdownContainer';
import ChartTypeSelector from './Header/ChartTypeSelector';
import Customize from './Menu/Customize';
import Filter from './Menu/Filter';
import MenuDropdownContainer from './Menu/MenuDropdownContainer';
import OptionalFields from './Menu/OptionalFields';
import RequiredFields from './Menu/RequiredFields';
import SampleLimit from './Menu/SampleLimit';
import {
  AXIS_MAX_EXPRESSION,
  AXIS_MIN_EXPRESSION,
  AXIS_RANGE_EXPRESSION,
  SAMPLING_ALL_VALUES_PHRASE,
} from './utils/constants';
import { generatePresentation } from './utils/generatePresentation';
import { generateSeries } from './utils/generateSeries';
import { getColumnsAfterAggregation } from './utils/manageAggregates';
import { handleCloseTextField, handleOpenTextField } from './utils/manageCBAnnotations';
import { constructChartSpec, hasRequiredFields, initializePresentation } from './utils/manageChart';
import { getHeight, getWidth } from './utils/manageSpec';
import { validateAggregate, validateBins, validateTransforms } from './utils/validation';

/* eslint-disable */
type Props = {
  data: {
    caption: String, // Caption added to the plot
    values: {
      aggregate: [], // Information about any aggregation performed
      bins: [],
      transforms: [], // Specific transforms to apply to the dataset
    }, // Data used to plot the chart
  }, // Info required to plot the chart
  series: {}, // Info about the chart type, columns to use for axes, group, slider or subplot
  datasetList: {}, // List of datasets saved in the Redux state
  rows: [], // Rows in the underlying dataset
  columnsTypes: [], // Types for each column in the underlying dataset
  externalName: String, // User-facing name used to refer to the chart
  options: {
    datasetName: String, // Name of the underlying dataset
    datasetVersion: Number, // Version of the underlying dataset
    chartType: { type: String, name: String }, // Type of the chart being plotted
  }, // Data required for plotting in the chart builder
  chartEditingMode: Boolean, // Flag that indicates if the chart is being edited
  objectId: String, // Unique identifier for each object
  sessionId: String, // Unique identifier for each session
  placeholderText: String, // Informational text displayed in the chart area when there is no chart plotted
  renderChart: Boolean, // Flag that indicates if the chart should be rendered
  toggleChartEditingMode: () => mixed, // Toggles the edit mode for the chart
  handleDatasetClose: () => mixed, // Handles closing the dataset selection menu
  setChartType: () => mixed, // Sets the new chart type when the selection changes
  sendMessageBypass: () => mixed, // Sends a message/utterance to the backend
  addToast: () => mixed, // Adds a toast message with a given type and text
  dismissAllToasts: () => mixed, // Dismisses all toasts displayed
  openAlertDialog: () => mixed, // Opens a new alert dialog with the given title, description and buttons
  closeDialog: () => mixed, // Closes the opened dialog if any
  closeChartBuilder: () => mixed, // Closes the chart builder modal
  requestBulkExport: () => mixed, // request chart exporting for all slides
  updateChartRequest: () => mixed, // Updates the chart in ChartSpace
  isLoadingDataset: Boolean, // Flag that indicates if the chart builder is loading a dataset
  isLoadingFailed: Boolean, // Flag that indicates if the chart builder failed to load a dataset
  dataLoadingError: AxiosError | Error | null, // Error object that contains the error message and status code
  publicationId: Number, // Unique identifier for each item published to an IB
  isSessionless: Boolean, // Flag that indicates if the chart builder is backed by a session
  modifyChartRequest: () => mixed, // Modifies the chart spec
  modifyChartCallback: () => mixed, // Fetches the content of the publication with the given id
  userCanModify: Boolean, // Flag that indicates if the current user can modify the chart
  insightsBoardId: Number, // Unique identifier for each IB
  chooseDataSampleLimit: () => mixed,
  dataSampleLimit: Number,
  isFatTable: Boolean,
  tableObjects: Object, // List of datasets within a session or insights board
  /**
   * Callback to request data for the chart. This can be used to call for retrieval via BE compute
   */
  requestData: () => mixed,
  computedColumns: [], // Computed columns after BE compute
  computedRows: [], // Computed rows after BE compute
  usedCompute: Boolean, // Flag that indicates if the chart used BE compute
};

class ChartBuilder extends React.Component<Props> {
  constructor(props) {
    super(props);
    this.echartsRef = React.createRef(null);

    const chartType = props.options.chartType.type;

    let caption = '';
    let fields = {};
    const aggregate = cloneDeep(props.data?.values?.aggregate) || [];
    const bins = cloneDeep(props.data?.values?.bins) || [];
    const presentation = initializePresentation(props.data?.plot?.presentation);

    if (props.chartEditingMode) {
      caption = props.data.caption;
      fields = Chart[chartType].generateFields(props.series, presentation);
    }

    const chartSpec = constructChartSpec({
      chartSpec: {},
      options: props.options,
      dataSampleLimit: props.dataSampleLimit,
      totalRowCount: props.totalRowCount,
      state: {
        aggregate,
        series: cloneDeep(props.series) || [],
        presentation,
        transforms: cloneDeep(props.data?.values?.transforms) || [],
        caption,
        bins,
      },
      props: {
        columnsTypes: props.usedCompute ? props.computedColumns : props.columnsTypes,
        rows: props.usedCompute ? props.computedRows : props.rows,
        sessionId: props.sessionId,
      },
    });

    this.state = {
      customizeKey: 0,
      anchorEl: null,
      hasUnsavedChanges: false,
      exportChart: false,
      renderChart: false,
      customUpdate: false,
      showTextField: false, // for deciding if render textbox for annotation/text in <Customize />
      textFieldIndex: null, // annotation/text index when render textbox for annotation/text in <Customize />
      fields,
      chartSpec,
    };
  }

  componentDidMount = () => {
    // Watch for width and height changes
    window.addEventListener('resize', this.handleResize);
  };

  componentDidUpdate = (prevProps, prevState) => {
    // Watch for dataset changing & reset the spec
    if (
      prevProps.options?.datasetName !== this.props.options?.datasetName ||
      prevProps.options?.datasetVersion !== this.props.options?.datasetVersion
    ) {
      this.updateChart({
        aggregate: [],
        bins: [],
        transforms: [],
        updatedFields: {},
      });
    }

    /**
     * Watch for renderChart updates from container. This is used to control
     * rendering due to the actions of the container, such as data fetching or dataset changes.
     * Make sure to verify that the required fields are present before accepting rendering.
     */
    if (prevProps.renderChart !== this.props.renderChart) {
      this.setState({
        renderChart:
          this.props.renderChart && hasRequiredFields(this.state.fields, this.props.options),
      });
    }

    //If a chart is loading or done loading, see if we should render the chart.
    if (prevProps.isLoadingDataset !== this.props.isLoadingDataset) {
      const newRenderChart =
        !this.props.isLoadingDataset && // loading so don't render
        hasRequiredFields(this.state.fields, this.props.options);
      this.setState({ renderChart: newRenderChart });
    }

    // Watch for parent's row retrieval & update the chart spec with the new rows & columns
    // Prefer the computed rows over the original dataset information (if we used BE compute)
    if (
      prevProps.rows?.length !== this.props.rows?.length ||
      prevProps.computedRows?.length !== this.props.computedRows?.length ||
      prevProps.computedColumns?.length !== this.props.computedColumns?.length ||
      !isEqual(prevProps.computedRows, this.props.computedRows) ||
      !isEqual(prevProps.computedColumns, this.props.computedColumns) ||
      prevProps.dataSampleLimit !== this.props.dataSampleLimit ||
      prevProps.usedCompute !== this.props.usedCompute
    ) {
      this.setChartSpec({
        props: this.props,
        callback: () => this.updateChart({}),
      });
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.handleResize);
    this.echartsRef.current = null;
  };

  /**
   * Sets an error if the translation has failed
   * @param {boolean} bool flag indicating translation error
   */
  setHasTranslationError = (bool) => {
    this.setState({ hasTranslationError: bool });
  };

  setCustomUpdate = (newCustomUpdate) => {
    this.setState({ customUpdate: newCustomUpdate });
  };

  /**
   * Updates the chart spec. All params are optional
   * @param {Object} state option to be updated within the chart spec
   * @param {Object} props option to be updated within the chart spec
   * @param {Function} callback function to be called after state is updated
   */
  setChartSpec = ({ state = {}, props = {}, callback = () => {} }) => {
    this.setState(
      (prev) => ({
        chartSpec: constructChartSpec({
          chartSpec: prev.chartSpec,
          options: this.props.options,
          totalRowCount: this.props.totalRowCount,
          dataSampleLimit: this.props.dataSampleLimit
            ? this.props.dataSampleLimit
            : SAMPLING_ALL_VALUES_PHRASE,
          props,
          state,
        }),
        ...(!isEmpty(state) && { hasUnsavedChanges: true }),
      }),
      callback,
    );
  };

  updateModalPng = () => {
    this.setState({ exportChart: false });
  };

  /**
   * Updates field & chartSpec state with functions to verify the creation of a valid chart.
   *
   * TODO: Look for instances of constructChartSpec() or setChartSpec(), and
   * fold other chartSpec mutations into this function. This will allow us to perform
   * a single state update and avoid unnecessary renders.
   *
   *
   * @param {Array} aggregates aggregates to be added to the chart spec
   * @param {Array} bins bins to be added to the chart spec
   * @param {Array} transforms transforms to be added to the chart spec
   * @param {Object} updatedFields The key/value pairs for the menu input fields
   */
  updateChart = ({ aggregates, bins, transforms, updatedFields = this.state.fields }) => {
    const { columnsTypes, dataSampleLimit, options, totalRowCount } = this.props;
    const { chartSpec } = this.state;

    const chartType = options.chartType.type;
    const rowCount = dataSampleLimit ?? totalRowCount;

    let updatedAggregates = aggregates ?? (chartSpec?.values?.aggregate || []);
    let updatedBins = bins ?? (chartSpec?.values?.bins || []);
    let updatedTransforms = transforms ?? (chartSpec?.values?.transforms || []);

    // Validate the existing transforms for the new inputs
    updatedTransforms = validateTransforms(updatedTransforms, updatedFields);

    // Validate the existing aggregate for the new inputs
    updatedAggregates = validateAggregate(
      updatedAggregates,
      updatedFields,
      columnsTypes,
      rowCount,
      chartType,
    );

    // Validate the bins for the new inputs
    updatedBins = validateBins(updatedBins, updatedFields, columnsTypes, rowCount, chartType);

    // Should only allow label for available columns (columns that are selected in other CB inputs)
    // If the previous label column is not available anymore, clear it from the fields
    const columnsAfterAggregate = getColumnsAfterAggregation(updatedAggregates);
    if (
      updatedFields.label !== undefined &&
      columnsAfterAggregate &&
      !columnsAfterAggregate.includes(updatedFields.label[0])
    ) {
      delete updatedFields.label;
    }

    const newSeries = generateSeries(updatedFields, chartType);

    // Overwrite the axis labels & title when the fields change
    const newPresentation = generatePresentation(
      newSeries,
      chartSpec.plot.presentation,
      updatedAggregates,
      updatedBins,
      updatedFields,
      chartType,
    );

    const areRequiredFieldsFilled = hasRequiredFields(updatedFields, options);
    this.setState(
      (prev) => ({
        hasUnsavedChanges: areRequiredFieldsFilled,
        renderChart: areRequiredFieldsFilled,
        chartSpec: constructChartSpec({
          chartSpec: prev.chartSpec,
          options: this.props.options,
          state: {
            aggregate: updatedAggregates,
            bins: updatedBins,
            transforms: updatedTransforms,
            series: newSeries,
            presentation: newPresentation,
          },
          props: this.props,
        }),
        ...(!!updatedFields && { fields: updatedFields }),
      }),
      () => {
        this.remountCustomize();
        // Attempt to use compute after fields change if we have the requiredFields
        if (areRequiredFieldsFilled) {
          const { aggregate, bins, transforms } = this.state.chartSpec.values;
          this.props.requestData({
            newComputeSpec: { aggregate, bins, transforms },
            skipCompute: false,
          });
        }
      },
    );
  };

  /**
   * This function will update the presentation's each annotation/text's visibility
   * and interactability if users open/close a textfield for a text or annotation
   * @param {Boolean} showTextField if show textfield, indicator for open/close
   * @param {Integer} textFieldIndex the index of annotation when open a textfield, null if closing
   * @param {Boolean} showAnnotations if show all annotations(in customize)
   * @param {Boolean} showTexts if show all texts(in customize)
   */
  updateAnnotationTextField = (showTextField, textFieldIndex, showAnnotations, showTexts) => {
    const { presentation } = this.state.chartSpec.plot || {};
    let updatedPresentation = {};
    if (textFieldIndex === null) {
      updatedPresentation = handleCloseTextField(presentation, showAnnotations, showTexts);
    } else {
      updatedPresentation = handleOpenTextField(presentation, textFieldIndex);
    }

    // update everything after
    this.setState((prev) => ({
      customUpdate: true,
      showTextField,
      textFieldIndex,
      chartSpec: constructChartSpec({
        chartSpec: prev.chartSpec,
        options: this.props.options,
        state: { presentation: updatedPresentation },
      }),
    }));
  };

  /**
   * Handles changes to the chart's width and height
   */
  handleResize = () => {
    const width = getWidth(this.echartsRef);
    const height = getHeight(this.echartsRef);
    if (width && height) {
      this.setState({ width, height });
    }
  };

  /**
   * Displays an alert on a discard attempt when there are unsaved changes in the edit mode
   */
  confirmDiscard = () => {
    if (this.state.hasUnsavedChanges && this.props.chartEditingMode) {
      this.props.openAlertDialog({
        title: 'Are you sure?',
        descriptions: ['Discarding changes cannot be undone.'],
        buttons: [
          {
            label: 'Yes',
            key: 'yes',
            onClick: () => {
              this.handleExit();
            },
          },
          {
            label: 'Cancel',
            key: 'cancel',
            onClick: () => this.props.closeDialog(),
          },
        ],
      });
    } else {
      this.handleExit();
    }
  };

  /**
   * Handles closing/exiting the chart builder modal
   */
  handleExit = () => {
    if (this.props.chartEditingMode) this.props.toggleChartEditingMode(false);
    this.props.closeChartBuilder();
    this.props.closeDialog();
  };

  /**
   * Handles the field-validation side effect for swapping chart types
   * @param {String} type
   * @param {Object} updatedFields
   * @param {Array} updatedAggregates
   */
  handleChartType = (
    type,
    updatedFields = null,
    updatedAggregates = [],
    updatedBins = [],
    updatedTransforms = [],
  ) => {
    this.setState(
      (prev) => ({
        chartSpec: constructChartSpec({
          chartSpec: prev.chartSpec,
          options: this.props.options,
          state: {
            aggregate: updatedAggregates,
            bins: updatedBins,
            transforms: updatedTransforms,
          },
        }),
        ...(!!updatedFields && { fields: updatedFields }),
      }),
      () => this.updateChart({}),
    );
    this.props.setChartType(type);
  };

  /**
   * Apply the new axis configuration specified from the <AxisConfig /> form
   * @param {Object} config The axis min/max and selected column names
   */
  applyAxisConfig = (config) => {
    const { axisMin, axisMax, clearTransforms, columnNames } = config;
    const { transforms } = this.state.chartSpec.values || [];
    let updatedTransforms = cloneDeep(transforms);

    for (const columnName of columnNames) {
      // Clear the existing axis transforms for these columns
      updatedTransforms = updatedTransforms.filter(
        (t) => !(t.column === columnName && t.axisConfig),
      );

      // If we're not using the clear button, push the new one
      if (!clearTransforms) {
        // Select the expression and value(s)
        let newExpression;
        let newValue;
        if (axisMin && axisMax) {
          newExpression = AXIS_RANGE_EXPRESSION;
          newValue = [axisMin, axisMax];
        } else if (axisMin) {
          newExpression = AXIS_MIN_EXPRESSION;
          newValue = [axisMin];
        } else if (axisMax) {
          newExpression = AXIS_MAX_EXPRESSION;
          newValue = [axisMax];
        }

        const newTransform = {
          type: 'filter',
          column: columnName,
          expression: newExpression,
          value: newValue,
          columnType: 'float',
          axisConfig: true,
        };

        updatedTransforms.push(newTransform);
      }
    }

    this.updateChart({ transforms: updatedTransforms });
  };

  /**
   * Applies the new bin details to the chart spec, and callbacks to updateChart
   * so that we can update the axis titles with the new bin information
   * @param {Object} bin The new bin
   * @param {String} oldBinTitle The name of the previous bin, if we're editing an existing bin
   */
  applyBin = (newBin, oldBinTitle = null) => {
    const currBins = cloneDeep(this.state.chartSpec.values.bins);

    // If we're editing an existing bin, remove the old bin
    if (oldBinTitle) {
      currBins.splice(
        currBins.indexOf(currBins.find((b) => oldBinTitle === getBinChipTitle(b))),
        1,
      );
    }

    const bins = newBin === null ? [...currBins] : [...currBins, newBin];
    this.updateChart({ bins });
  };

  /**
   * Add the new transform to the array of transforms if it's not a duplicate
   * @param {String} column The column name to transform
   * @param {String} columnType The type for the selected column ex. 'Boolean'
   * @param {Boolean} disabled Flag that indicates if the transform is disabled
   * @param {String} expression The transform expression ex. 'is equal to'
   * @param {String} type The type of transform. Only 'filter' right now
   * @param {Array} value The values selected for the transform
   */
  applyTransform = ({ column, columnType, disabled, expression, type, value }) => {
    const { transforms } = this.state.chartSpec.values || [];
    const newTransform = {
      type,
      column,
      disabled,
      expression,
      value,
      columnType,
      // children: [] // If we ever want to add nested functionality
    };

    // Check if transform already exists, omitting disabled property from comparison
    for (const transform of transforms) {
      if (isEqual(omit(transform, ['disabled']), newTransform)) {
        return;
      }
    }

    transforms.push(newTransform);
    this.updateChart({ transforms });
  };

  /**
   * Deletes the transform  at the given index from the array
   * of transforms in the chart spec
   * @param {number} index index in the transforms array
   */
  deleteTransformAtIndex = (index) => {
    const { transforms } = this.state.chartSpec.values || [];
    transforms.splice(index, 1);
    this.updateChart({ transforms });
  };

  /**
   * Toggles the disabled property of a transform at the given index in
   * the array of transforms in the chart spec
   * @param {number} index index in the transforms array
   */
  toggleTransformDisabledAtIndex = (index) => {
    const { transforms } = this.state.chartSpec.values || [];
    transforms[index].disabled = !transforms[index].disabled;
    this.updateChart({ transforms });
  };

  /**
   * Saves the completed chart with either the modify skill or createECharts skill
   * @returns {void}
   */
  saveChanges = () => {
    const editing = this.props.chartEditingMode;
    const { publicationId, insightsBoardId } = this.props;
    const { chartSpec } = this.state;

    const isDataChatSession = isDataChatSessionPage();

    // Filter out the disabled transforms
    chartSpec.values.transforms.filter((t) => !t.disabled);
    chartSpec.values.bins.filter((b) => !b.disabled);

    delete chartSpec.specUpdate;
    delete chartSpec.values.complete;
    delete chartSpec.values.rows;

    if (!editing) {
      // Use the createEChart skill when creating a brand new chart
      const utterance = `Plot a chart with the specification <strong>${JSON.stringify(
        chartSpec,
      )}</strong>`;
      try {
        this.props.dismissAllToasts();
        this.props.sendMessageBypass({
          message: {
            data: utterance,
            type: 'text',
          },
          sessionID: this.props.sessionId,
        });
        this.handleExit();
      } catch (e) {
        this.props.addToast({
          toastType: TOAST_ERROR,
          length: TOAST_LONG,
          message: 'Your changes could not be saved. Please try again.',
        });
      }
      return;
    }

    if (isDataChatSession) {
      try {
        // Get the title of the chart to use in the request
        let chartTitle = chartSpec.plot.presentation.title.text;
        if (Array.isArray(chartTitle)) chartTitle = chartTitle[0];

        this.props.updateChartRequest({
          chartSpec: { data: chartSpec, type: 'viz', typeVersion: 2 },
          dcChartID: this.props.objectId,
          name: chartTitle,
        });
        this.props.dismissAllToasts();
        this.handleExit();
      } catch (e) {
        this.props.addToast({
          toastType: TOAST_ERROR,
          length: TOAST_LONG,
          message: 'Your changes could not be saved. Please try again.',
        });
      }
      return;
    }

    try {
      this.props.modifyChartRequest({
        aggregate: chartSpec.values.aggregate,
        bins: chartSpec.values.bins,
        caption: chartSpec.caption,
        dataSampleLimit: chartSpec.values.dataSampleLimit,
        insightsBoardId,
        presentation: chartSpec.plot.presentation,
        publicationId,
        series: chartSpec.plot.series,
        transforms: chartSpec.values.transforms,
        callback: () => this.props.modifyChartCallback(publicationId, chartSpec.caption),
      });
      this.props.dismissAllToasts();
      this.handleExit();
    } catch (e) {
      this.props.addToast({
        toastType: TOAST_ERROR,
        length: TOAST_LONG,
        message: 'Your changes could not be saved. Please try again.',
      });
    }
  };

  /**
   * Updates our presentation state with changes from <Customize />
   * @param {Object} options The presentation key/value pairs that are being updated
   * for example, options: { 'title': {}, 'xaxis': {} }
   */
  updateFromCustomize = (options, callback) => {
    const { presentation } = this.state.chartSpec.plot || {};
    const newPresentation = cloneDeep(presentation);

    for (const [key, value] of Object.entries(options)) {
      newPresentation[key] = value;
    }
    // Set customUpdate to 'true' to trigger translation. Refer to applyCustomize()
    this.setState(
      (prev) => ({
        customUpdate: true,
        chartSpec: constructChartSpec({
          chartSpec: prev.chartSpec,
          options: this.props.options,
          state: { presentation: newPresentation },
        }),
      }),
      () => {
        if (callback) callback();
      },
    );
  };

  /**
   * Updates the presentation's annotations from the translation layer
   * @param {number} index index of the annotation to update
   * @param {Object} updatedAnnotation updated echarts annotation config
   */
  updateGraphicAnnotation = (index, updatedAnnotation) => {
    const { presentation } = this.state.chartSpec.plot || {};
    const newPresentation = cloneDeep(presentation);
    newPresentation.annotations[index] = updatedAnnotation;
    this.setState((prev) => ({
      hasUnsavedChanges: true,
      customUpdate: true,
      chartSpec: constructChartSpec({
        chartSpec: prev.chartSpec,
        options: this.props.options,
        state: { presentation: newPresentation },
      }),
    }));
  };

  /**
   * Re-mounts the Customize component to sync the current spec when changing input fields
   */
  remountCustomize = () => {
    this.setState((prev) => ({ customizeKey: prev.customizeKey + 1 }));
  };

  render() {
    const {
      chartEditingMode,
      columnsTypes,
      dataLoadingError,
      dataSampleLimit,
      datasetList,
      handleDatasetClose,
      insightsBoardId,
      isLoadingDataset,
      isLoadingFailed,
      isSessionless,
      options,
      placeholderText,
      rows,
      chooseDataSampleLimit,
      userCanModify,
      tableObjects,
      totalRowCount,
    } = this.props;

    const {
      anchorEl,
      chartSpec,
      customizeKey,
      customUpdate,
      exportChart,
      fields,
      hasTranslationError,
      height,
      renderChart,
      showTextField,
      textFieldIndex,
      width,
    } = this.state;

    /**
     * Renders the header for the chart builder. Allows changing dataset when not editing
     * @returns {ReactFragment} Fragment wrapping the chart builder title
     */
    const renderTitle = () => {
      // If we're editing, don't add handling for changing the dataset
      if (chartEditingMode) {
        return `Edit a ${options.chartType?.name} using the dataset: ${options.datasetName}
        ${dataSampleLimit ? ' (sample of ' + dataSampleLimit.toLocaleString() + ' rows)' : ''}`;
      }
      // If we're in standalone mode and have more than 1 dataset, allow user to change datasets
      return (
        <>
          {`Plot a chart using the dataset: `}
          {Object.keys(datasetList).length <= 1 ? (
            `${options.datasetName} v${options.datasetVersion}`
          ) : (
            <>
              <Tooltip title="Change Datasets" key="changeDatasets" placement="right-end">
                <span
                  className="CB-underlined"
                  onClick={(e) => this.setState({ anchorEl: e.currentTarget })}
                >
                  {`${options.datasetName} v${options.datasetVersion}`}
                </span>
              </Tooltip>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => {
                  this.setState({ anchorEl: null });
                  handleDatasetClose(null);
                }}
              >
                {Object.keys(datasetList).map((datasetName) => {
                  return (
                    <div key={datasetName}>
                      {Object.keys(datasetList[datasetName]).map((datasetVersion) => (
                        <MenuItem
                          key={`${datasetName} v${datasetVersion}`}
                          onClick={() => {
                            this.setState({ anchorEl: null });
                            const changing = handleDatasetClose([
                              datasetName,
                              parseInt(datasetVersion, 10),
                            ]);
                            // Clear fields when dataset changes
                            if (changing) this.updateChart({ updatedFields: {} });
                          }}
                        >
                          {`${datasetName} v${datasetVersion}`}
                        </MenuItem>
                      ))}
                    </div>
                  );
                })}
              </Menu>
            </>
          )}
          {dataSampleLimit && !chartEditingMode
            ? ' (sample of ' + dataSampleLimit.toLocaleString() + ' rows)'
            : null}
        </>
      );
    };

    return (
      <div className="CB-Backdrop">
        <div className="CB">
          <div
            className="CB-Header"
            ref={(headerElement) => {
              this.headerElement = headerElement;
            }}
          >
            <div className="CB-Header-Title">{renderTitle()}</div>
            <div>
              {/* render "Export all slides" icon only when slider dropdown has a value */}
              {fields['slider'] ? (
                <Tooltip title="Export all slides" key="exportAllSlides">
                  <span>
                    <IconButton
                      onClick={() => {
                        this.props.requestBulkExport({
                          isExportedFromChartBuilder: true,
                        });
                      }}
                      size="large"
                      disabled={!renderChart}
                      data-cy="CB-Export-All-Slides"
                    >
                      <SvgIcon viewBox="0 0 17 17">
                        <ExportMultipleFigureIcon />
                      </SvgIcon>
                    </IconButton>
                  </span>
                </Tooltip>
              ) : null}

              <Tooltip title="Export Chart" key="exportChart">
                <span>
                  <IconButton
                    onClick={() => this.setState({ exportChart: true })}
                    size="large"
                    disabled={!renderChart}
                    data-cy="CB-Export-Chart"
                  >
                    <SaveAltIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <IconButton onClick={this.confirmDiscard} size="large" data-cy="CB-Close">
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <div className="CB-Body">
            <div className="CB-Menu-Container">
              <div
                className="CB-Menu"
                ref={(menuElement) => {
                  this.menuElement = menuElement;
                }}
              >
                <div className="menu-row-shaded">
                  <div className="CB-menu-button-container">
                    <ChartTypeSelector
                      aggregates={chartSpec.values.aggregate}
                      bins={chartSpec.values.bins}
                      columnsTypes={columnsTypes}
                      fields={fields}
                      chooseDataSampleLimit={chooseDataSampleLimit}
                      closeNonDefaultDropdowns={this.closeNonDefaultDropdowns}
                      currChartType={options.chartType.type}
                      handleChartType={this.handleChartType}
                      rowCount={this.props.dataSampleLimit ?? this.props.totalRowCount}
                      transforms={chartSpec.values.transforms}
                    />
                  </div>
                </div>
                <MenuDropdownContainer
                  bins={cloneDeep(chartSpec.values.bins)}
                  echartsRef={this.echartsRef}
                  fields={fields}
                  isLoading={isLoadingDataset}
                  key="MenuDropdownContainer"
                  renderChart={renderChart}
                  currChartType={options.chartType.type}
                  insightsBoardId={insightsBoardId}
                >
                  <RequiredFields
                    aggregates={chartSpec.values.aggregate}
                    applyAxisConfig={this.applyAxisConfig}
                    applyBin={this.applyBin}
                    bins={chartSpec.values.bins}
                    chooseDataSampleLimit={chooseDataSampleLimit}
                    columnsTypes={columnsTypes}
                    fields={fields}
                    isLoadingDataset={isLoadingDataset}
                    key="RequiredFields"
                    options={options}
                    transforms={chartSpec.values.transforms}
                    updateChart={this.updateChart}
                  />
                  <OptionalFields
                    aggregates={chartSpec.values.aggregate}
                    applyAxisConfig={this.applyAxisConfig}
                    applyBin={this.applyBin}
                    bins={chartSpec.values.bins}
                    chooseDataSampleLimit={chooseDataSampleLimit}
                    columnsTypes={columnsTypes}
                    fields={fields}
                    isLoadingDataset={isLoadingDataset}
                    key="OptionalFields"
                    options={options}
                    transforms={chartSpec.values.transforms}
                    updateChart={this.updateChart}
                    updateSmoothingField={(_, val) =>
                      this.updateChart({
                        updatedFields: { ...this.state.fields, smooth: val === true ? true : null },
                      })
                    }
                  />
                  <SampleLimit
                    dataSampleLimit={dataSampleLimit}
                    key="SampleLimit"
                    options={options}
                    totalRowCount={totalRowCount}
                    chooseDataSampleLimit={chooseDataSampleLimit}
                  />
                  <Filter
                    applyTransform={this.applyTransform}
                    columnsTypes={columnsTypes}
                    deleteTransform={this.deleteTransformAtIndex}
                    displayFilterChips
                    key="Filter"
                    rows={rows}
                    toggleTransformDisabledAtIndex={this.toggleTransformDisabledAtIndex}
                    // Copy transforms so that they get updated and re-mapped
                    transforms={cloneDeep(chartSpec.values.transforms)}
                  />
                  <Customize
                    chartSpec={chartSpec}
                    fields={fields}
                    customUpdate={customUpdate}
                    height={height}
                    caption={chartSpec.caption}
                    echartsRef={this.echartsRef}
                    headerElement={this.headerElement}
                    key={`Customize-${customizeKey}`}
                    menuElement={this.menuElement}
                    options={options}
                    presentation={chartSpec.plot.presentation}
                    series={chartSpec.plot.series}
                    showTextField={showTextField}
                    textFieldIndex={textFieldIndex}
                    updateAnnotationTextField={this.updateAnnotationTextField}
                    updateCaption={(caption) => this.setChartSpec({ state: { caption } })}
                    updateFromCustomize={this.updateFromCustomize}
                    width={width}
                    isLoadingDataset={isLoadingDataset}
                    updateChart={(updatedFields) => this.updateChart({ updatedFields })}
                  />
                </MenuDropdownContainer>
              </div>
            </div>
            <div className="CB-Container">
              <div className="CB-Chart">
                {isLoadingFailed ? (
                  <ErrorBoundary
                    type="echart"
                    error={dataLoadingError}
                    hasError={isLoadingFailed}
                  />
                ) : renderChart ? (
                  <ReactResizeDetector handleWidth handleHeight>
                    {({ width, height, targetRef }) => (
                      <div ref={targetRef} className="resizeWrapper">
                        <DCPlotV2ErrorBoundary
                          dcSpec={chartSpec}
                          isLoading={isLoadingDataset}
                          objectId={this.props.objectId}
                          setError={this.setHasTranslationError}
                        >
                          <DCPlotV2
                            width={width}
                            height={height}
                            chartEditingMode={chartEditingMode}
                            showModalPng={exportChart}
                            updateModalPng={this.updateModalPng}
                            dcSpec={chartSpec}
                            ref={this.echartsRef}
                            inChartBuilder
                            customUpdate={customUpdate}
                            setCustomUpdate={this.setCustomUpdate}
                            updateGraphicAnnotation={this.updateGraphicAnnotation}
                            updateAnnotationTextField={this.updateAnnotationTextField}
                            isFatTable={this.props.isFatTable}
                            insightsBoardId={insightsBoardId}
                          />
                        </DCPlotV2ErrorBoundary>
                      </div>
                    )}
                  </ReactResizeDetector>
                ) : (
                  <div className="CB-No-Chart">{placeholderText}</div>
                )}
              </div>
              <FooterDropdownContainer
                fields={fields}
                hasTranslationError={hasTranslationError}
                isLoading={isLoadingDataset}
                isSessionless={isSessionless}
                key="FooterDropdownContainer"
                options={options}
                renderChart={renderChart}
                saveChanges={this.saveChanges}
                userCanModify={userCanModify}
                tableObjects={tableObjects}
                datasetPair={`${options.datasetName}_${options.datasetVersion}`}
              >
                <DataSample
                  datasetName={options.datasetName}
                  datasetVersion={options.datasetVersion}
                  isInsightsBoard={!!insightsBoardId}
                  objectId={this.props.objectId}
                />
                <Describe columnsTypes={columnsTypes} key="Describe" />
              </FooterDropdownContainer>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  sessionId: selectSession(state),
  tableObjects: selectGroupedTableObjects(state),
});

export default connect(mapStateToProps, {
  sendMessageBypass,
  closeDialog,
  openAlertDialog,
  closeChartBuilder,
  addToast,
  dismissAllToasts,
  modifyChartRequest,
  requestBulkExport,
  updateChartRequest,
})(ChartBuilder);
