// Disable payment popup - user has a subscription
export const DISABLE_PAYMENT_POPUP_REQUEST = 'subscriptions/DISABLE_PAYMENT_POPUP_REQUEST';
export const DISABLE_PAYMENT_POPUP_SUCCESS = 'subscriptions/DISABLE_PAYMENT_POPUP_SUCCESS';
export const DISABLE_PAYMENT_POPUP_FAILURE = 'subscriptions/DISABLE_PAYMENT_POPUP_FAILURE';

// Enter pages associated with stripe payments and account
export const ENTER_STRIPE = 'subscriptions/ENTER_STRIPE';
export const EXIT_STRIPE = 'subscriptions/EXIT_STRIPE';

// Open the popup to choose a subscription tier
export const OPEN_CHOOSE_SUBSCRIPTION = 'subscriptions/OPEN_CHOOSE_SUBSCRIPTION';
export const EXIT_CHOOSE_SUBSCRIPTION = 'subscriptions/EXIT_CHOOSE_SUBSCRIPTION';

// Set the variables for creating a subscription
export const SET_SUBSCRIPTION_VARS = 'subscriptions/SET_SUBSCRIPTION_VARS';

// Fetch the list of subscriptions from stripe
export const GET_SUBSCRIPTIONS_REQUEST = 'subscriptions/GET_SUBSCRIPTIONS_REQUEST';
export const GET_SUBSCRIPTIONS_SUCCESS = 'subscriptions/GET_SUBSCRIPTIONS_SUCCESS';
export const GET_SUBSCRIPTIONS_FAILURE = 'subscriptions/GET_SUBSCRIPTIONS_FAILURE';

// Update a customer subscription in stripe
export const UPDATE_SUBSCRIPTION_REQUEST = 'subscriptions/UPDATE_SUBSCRIPTION_REQUEST';
export const UPDATE_SUBSCRIPTION_SUCCESS = 'subscriptions/UPDATE_SUBSCRIPTION_SUCCESS';
export const UPDATE_SUBSCRIPTION_FAILURE = 'subscriptions/UPDATE_SUBSCRIPTION_FAILURE';

// Cancel a customer subscription in stripe
export const CANCEL_SUBSCRIPTION_REQUEST = 'subscriptions/CANCEL_SUBSCRIPTION_REQUEST';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'subscriptions/CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_FAILURE = 'subscriptions/CANCEL_SUBSCRIPTION_FAILURE';

// Get the current subscription price in stripe
export const GET_SUBSCRIPTION_PRICE_REQUEST = 'subscriptions/GET_SUBSCRIPTION_PRICE_REQUEST';
export const GET_SUBSCRIPTION_PRICE_SUCCESS = 'subscriptions/GET_SUBSCRIPTION_PRICE_SUCCESS';
export const GET_SUBSCRIPTION_PRICE_FAILURE = 'subscriptions/GET_SUBSCRIPTION_PRICE_FAILURE';

// Submit payment form request
export const SUBMIT_PAYMENT_REQUEST = 'subscriptions/SUBMIT_PAYMENT_REQUEST';
export const SUBMIT_PAYMENT_SUCCESS = 'subscriptions/SUBMIT_PAYMENT_SUCCESS';
export const SUBMIT_PAYMENT_FAILURE = 'subscriptions/SUBMIT_PAYMENT_FAILURE';

// Start a free trial request
export const START_FREE_TIER_REQUEST = 'subscriptions/START_FREE_TIER_REQUEST';
export const START_FREE_TIER_SUCCESS = 'subscriptions/START_FREE_TIER_SUCCESS';
export const START_FREE_TIER_FAILURE = 'subscriptions/START_FREE_TIER_FAILURE';

// open / close add payment dialog
export const OPEN_ADD_PAYMENT_DIALOG = 'subscriptions/OPEN_ADD_PAYMENT_DIALOG';
export const CLOSE_ADD_PAYMENT_DIALOG = 'subscriptions/CLOSE_ADD_PAYMENT_DIALOG';

// Start a free trial request
export const ADD_PAYMENT_METHOD_REQUEST = 'subscriptions/ADD_PAYMENT_METHOD_REQUEST';
export const ADD_PAYMENT_METHOD_SUCCESS = 'subscriptions/ADD_PAYMENT_METHOD_SUCCESS';
export const ADD_PAYMENT_METHOD_FAILURE = 'subscriptions/ADD_PAYMENT_METHOD_FAILURE';

// Fetch the list of payment methods from stripe
export const GET_PAYMENT_METHODS_REQUEST = 'subscriptions/GET_PAYMENT_METHODS_REQUEST';
export const GET_PAYMENT_METHODS_SUCCESS = 'subscriptions/GET_PAYMENT_METHODS_SUCCESS';
export const GET_PAYMENT_METHODS_FAILURE = 'subscriptions/GET_PAYMENT_METHODS_FAILURE';

// Set the default payment method
export const SET_SELECTED_PAYMENT_METHOD = 'subscriptions/SET_SELECTED_PAYMENT_METHOD';

// Fetch the users subscription status from stripe
export const GET_CURRENT_SUBSCRIPTION_REQUEST = 'subscriptions/GET_CURRENT_SUBSCRIPTION_REQUEST';
export const GET_CURRENT_SUBSCRIPTION_SUCCESS = 'subscriptions/GET_CURRENT_SUBSCRIPTION_SUCCESS';
export const GET_CURRENT_SUBSCRIPTION_FAILURE = 'subscriptions/GET_CURRENT_SUBSCRIPTION_FAILURE';

// Fetch subscription customer id.
export const GET_SUBSCRIPTION_CUSTOMER_ID_REQUEST =
  'subscriptions/GET_SUBSCRIPTION_CUSTOMER_ID_REQUEST';
export const GET_SUBSCRIPTION_CUSTOMER_ID_SUCCESS =
  'subscriptions/GET_SUBSCRIPTION_CUSTOMER_ID_SUCCESS';
export const GET_SUBSCRIPTION_CUSTOMER_ID_FAILURE =
  'subscriptions/GET_SUBSCRIPTION_CUSTOMER_ID_FAILURE';

// Fetch usage stats
export const GET_USAGE_STATS_REQUEST = 'subscriptions/GET_USAGE_STATS_REQUEST';
export const GET_USAGE_STATS_SUCCESS = 'subscriptions/GET_USAGE_STATS_SUCCESS';
export const GET_USAGE_STATS_FAILURE = 'subscriptions/GET_USAGE_STATS_FAILURE';
export const SET_USAGE_STATS_LOADING_STATUS = 'subscriptions/SET_USAGE_STATS_LOADING_STATUS';

// Object delete request from usage stats
export const DELETE_OBJECT_FROM_USAGE_STATS_REQUEST =
  'subscriptions/DELETE_OBJECT_FROM_USAGE_STATS_REQUEST';
export const DELETE_OBJECT_FROM_USAGE_STATS_SUCCESS =
  'subscriptions/DELETE_OBJECT_FROM_USAGE_STATS_SUCCESS';
export const DELETE_OBJECT_FROM_USAGE_STATS_FAILURE =
  'subscriptions/DELETE_OBJECT_FROM_USAGE_STATS_FAILURE';

export const disablePaymentPopupRequest = () => ({
  type: DISABLE_PAYMENT_POPUP_REQUEST,
});

export const disablePaymentPopupSuccess = ({ disablePaymentOptionsPopup }) => ({
  type: DISABLE_PAYMENT_POPUP_SUCCESS,
  disablePaymentOptionsPopup,
});

export const disablePaymentPopupFailure = (error) => ({
  type: DISABLE_PAYMENT_POPUP_FAILURE,
  error,
});

export const enterStripe = () => ({
  type: ENTER_STRIPE,
});

export const exitStripe = () => ({
  type: EXIT_STRIPE,
});

export const openChooseSubscription = () => ({
  type: OPEN_CHOOSE_SUBSCRIPTION,
});

export const exitChooseSubscription = () => ({
  type: EXIT_CHOOSE_SUBSCRIPTION,
});

export const setSubscriptionsVars = ({
  productPrice,
  productName,
  trialValid,
  yearly,
  details,
}) => ({
  type: SET_SUBSCRIPTION_VARS,
  productPrice,
  productName,
  trialValid,
  yearly,
  details,
});

export const getSubscriptionsRequest = () => ({
  type: GET_SUBSCRIPTIONS_REQUEST,
});

export const getSubscriptionsSuccess = ({ subscriptions }) => ({
  type: GET_SUBSCRIPTIONS_SUCCESS,
  subscriptions,
});

export const getSubscriptionsFailure = (error) => ({
  type: GET_SUBSCRIPTIONS_FAILURE,
  error,
});

export const getCurrentSubscriptionRequest = () => ({
  type: GET_CURRENT_SUBSCRIPTION_REQUEST,
});

export const getCurrentSubscriptionSuccess = ({ subscription }) => ({
  type: GET_CURRENT_SUBSCRIPTION_SUCCESS,
  subscription,
});

export const getCurrentSubscriptionFailure = (error) => ({
  type: GET_CURRENT_SUBSCRIPTION_FAILURE,
  error,
});

/**
  Dispatch an action to fetch usage stats
  @param {Array[String]} metrics // Array of metrics
*/
export const getUsageStatsRequest = ({ metrics }) => ({
  type: GET_USAGE_STATS_REQUEST,
  metrics,
});

export const getUsageStatsSuccess = ({ metric, usageData }) => ({
  type: GET_USAGE_STATS_SUCCESS,
  metric,
  usageData,
});

export const getUsageStatsFailure = ({ metrics, error }) => ({
  type: GET_USAGE_STATS_FAILURE,
  metrics,
  error,
});

export const setUsageStatsLoadingStatus = ({ status }) => ({
  type: SET_USAGE_STATS_LOADING_STATUS,
  status,
});

/**
  Dispatch an action to delete an object from Usage Stats and request the usage stats again
  @param {String} objectType // Type of the object
  @param {Array[String]} metrics // Array of usage metrics
  @param {Object} props // Props for the object
*/
export const deleteObjectFromUsageStatsRequest = ({ objectType, metrics, props = {} }) => ({
  type: DELETE_OBJECT_FROM_USAGE_STATS_REQUEST,
  objectType,
  metrics,
  props,
});

export const deleteObjectFromUsageStatsSuccess = ({ objectType, metrics, props }) => ({
  type: DELETE_OBJECT_FROM_USAGE_STATS_SUCCESS,
  objectType,
  metrics,
  props,
});

export const deleteObjectFromUsageStatsFailure = ({ objectType, metrics, props, error }) => ({
  type: DELETE_OBJECT_FROM_USAGE_STATS_FAILURE,
  objectType,
  metrics,
  props,
  error,
});

/**
  Dispatch an action to fetch Subscription Customer Id from dc_subscriptions table
*/
export const getSubscriptionCustomerIdRequest = () => ({
  type: GET_SUBSCRIPTION_CUSTOMER_ID_REQUEST,
});

export const getSubscriptionCustomerIdSuccess = ({ customerId, isExempt }) => ({
  type: GET_SUBSCRIPTION_CUSTOMER_ID_SUCCESS,
  customerId,
  isExempt,
});

export const getSubscriptionCustomerIdFailure = ({ error }) => ({
  type: GET_SUBSCRIPTION_CUSTOMER_ID_FAILURE,
  error,
});

// Request / Success / Failure actions for updating customer subscription in stripe
export const updateSubscriptionRequest = ({ productName, yearly, paymentMethodId = null }) => ({
  type: UPDATE_SUBSCRIPTION_REQUEST,
  productName,
  yearly,
  paymentMethodId,
});

export const updateSubscriptionSuccess = () => ({
  type: UPDATE_SUBSCRIPTION_SUCCESS,
});

export const updateSubscriptionFailure = ({ error }) => ({
  type: UPDATE_SUBSCRIPTION_FAILURE,
  error,
});

// Request / Success / Failure actions for updating customer subscription in stripe
export const cancelSubscriptionRequest = () => ({
  type: CANCEL_SUBSCRIPTION_REQUEST,
});

export const cancelSubscriptionSuccess = () => ({
  type: CANCEL_SUBSCRIPTION_SUCCESS,
});

export const cancelSubscriptionFailure = ({ error }) => ({
  type: CANCEL_SUBSCRIPTION_FAILURE,
  error,
});

// Request / Success / Failure actions for getting subcription price in stripe
export const getSubscriptionPriceRequest = () => ({
  type: GET_SUBSCRIPTION_PRICE_REQUEST,
});

export const getSubscriptionPriceSuccess = ({ amountDue }) => ({
  type: GET_SUBSCRIPTION_PRICE_SUCCESS,
  amountDue,
});

export const getSubscriptionPriceFailure = ({ error }) => ({
  type: GET_SUBSCRIPTION_PRICE_FAILURE,
  error,
});

// Request / Success / Failure actions for submitting a payment to stripe
export const submitPaymentRequest = ({
  productName,
  yearly,
  cardElement,
  billingDetails,
  paymentMethodID,
}) => ({
  type: SUBMIT_PAYMENT_REQUEST,
  productName,
  yearly,
  cardElement,
  billingDetails,
  paymentMethodID,
});

export const submitPaymentSuccess = () => ({
  type: SUBMIT_PAYMENT_SUCCESS,
});

export const submitPaymentFailure = ({ error }) => ({
  type: SUBMIT_PAYMENT_FAILURE,
  error,
});

// Request / Success / Failure actions for starting a free trial
export const startFreeTierRequest = ({ productName, yearly, redirect = true }) => ({
  type: START_FREE_TIER_REQUEST,
  productName,
  yearly,
  redirect,
});

export const startFreeTierSuccess = () => ({
  type: START_FREE_TIER_SUCCESS,
});

export const startFreeTierFailure = ({ error }) => ({
  type: START_FREE_TIER_FAILURE,
  error,
});

export const openAddPaymentDialog = () => ({
  type: OPEN_ADD_PAYMENT_DIALOG,
});

export const closeAddPaymentDialog = () => ({
  type: CLOSE_ADD_PAYMENT_DIALOG,
});

// Request / Success / Failure actions for adding a payment method
export const addPaymentMethodRequest = ({ cardElement, billingDetails }) => ({
  type: ADD_PAYMENT_METHOD_REQUEST,
  cardElement,
  billingDetails,
});

export const addPaymentMethodSuccess = ({ paymentMethod }) => ({
  type: ADD_PAYMENT_METHOD_SUCCESS,
  paymentMethod,
});

export const addPaymentMethodFailure = ({ error }) => ({
  type: ADD_PAYMENT_METHOD_FAILURE,
  error,
});

// Request / Success / Failure actions for getting list of payment methods
export const getPaymentMethodsRequest = () => ({
  type: GET_PAYMENT_METHODS_REQUEST,
});

export const getPaymentMethodsSuccess = ({ paymentMethods }) => ({
  type: GET_PAYMENT_METHODS_SUCCESS,
  paymentMethods,
});

export const getPaymentMethodsFailure = ({ error }) => ({
  type: GET_PAYMENT_METHODS_FAILURE,
  error,
});

export const setSelectedPaymentMethod = ({ paymentMethodId }) => ({
  type: SET_SELECTED_PAYMENT_METHOD,
  paymentMethodId,
});
