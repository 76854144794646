import {
  BAD_GATEWAY,
  BAD_REQUEST,
  GATEWAY_TIMEOUT,
  INTERNAL_SERVER_ERROR,
} from 'http-status-codes';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { retrieveLineageGraph } from '../../api/session.api';
import { TOAST_ERROR, TOAST_LONG } from '../../constants/toast';
import { addToast } from '../actions/toast.actions';

import {
  OPEN_VIEW_SLICED_WORKFLOW,
  retrieveSlicedWorkflowDataFailure,
  retrieveSlicedWorkflowDataSuccess,
} from '../actions/viewSlicedWorkflow.action';

import { selectSession } from '../selectors/session.selector';
import { selectAccessToken } from './selectors';

function* retrieveSlicedWorkflowDataWorker({ objectType, objectId }) {
  try {
    const accessToken = yield select(selectAccessToken);
    const sessionId = yield select(selectSession);
    const params = {
      sessionId,
      objectType,
      objectId,
    };

    const response = yield call(retrieveLineageGraph, accessToken, params);
    yield put(retrieveSlicedWorkflowDataSuccess(response.data));
  } catch (error) {
    yield put(retrieveSlicedWorkflowDataFailure(error.response.data));
    let toastMessage = `Oops, something went wrong when retrieving the workflow data. `;
    if (
      (error.response.data.includes('Timeout') &&
        error.response.status === INTERNAL_SERVER_ERROR) ||
      error.response.status === GATEWAY_TIMEOUT ||
      error.response.status === BAD_GATEWAY
    ) {
      toastMessage += 'Connection timed out.';
    } else if (
      error.response.status === INTERNAL_SERVER_ERROR ||
      error.response.status === BAD_REQUEST
    ) {
      toastMessage += `${error.response.data}`;
    }

    yield put(
      addToast({
        toastType: TOAST_ERROR,
        length: TOAST_LONG,
        message: toastMessage,
      }),
    );
  }
}

// Spins up the saga watchers
export default function* () {
  yield takeLatest(OPEN_VIEW_SLICED_WORKFLOW, retrieveSlicedWorkflowDataWorker);
}
