import { ROOT_FOLDER } from '../../constants/workspace';
import {
  CLOSE_MOVE_MENU,
  OPEN_MOVE_MENU,
  SET_CURRENT_FOLDER_BREADCRUMB,
} from '../actions/workspacev2.actions';

export const initialState = {
  folderStack: [ROOT_FOLDER],
  moveMenuObjects: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_FOLDER_BREADCRUMB: {
      return {
        ...state,
        folderStack: action.folderBreadcrumb,
      };
    }
    case OPEN_MOVE_MENU:
      return {
        ...state,
        moveMenuObjects: action.objects,
      };
    case CLOSE_MOVE_MENU:
      return {
        ...state,
        moveMenuObjects: initialState.moveMenuObjects,
      };
    default:
      return state;
  }
};
