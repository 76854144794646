import { put, race, select, take } from 'redux-saga/effects';
import { CONTEXTS } from '../../../constants';
import { sendMessageRequest } from '../../actions/messages.actions';
import { selectContext } from '../../selectors/context.selector';
import { selectSession } from '../../selectors/session.selector';
import { resetContext, setContext } from '../../slices/context.slice';
import { tryExitSessionRequest } from '../../slices/session.slice';
import { selectServerWaiting } from '../selectors';

export function* waitAndCloseSesssion() {
  // get session ID
  const sessionId = yield select(selectSession);
  // wait until the session context is set to working
  while (true) {
    const action = yield take(setContext.type);
    if (action.payload === CONTEXTS.WORKING) break;
  }
  // Wait until the session context is set to resting.
  while (true) {
    const [action] = yield race([take(setContext.type), take(resetContext.type)]);
    if (action === undefined || action.payload === CONTEXTS.REST) {
      yield put(tryExitSessionRequest(sessionId));
      break;
    }
  }
}

export function* submitUtteranceInSession(utteranceList, notWhileWaiting = false) {
  // Watch for resting
  // when resting run the utterances in the utteranceList array
  let utteranceIndex = 0;
  while (utteranceIndex < utteranceList.length) {
    const context = yield select(selectContext);
    const serverWaiting = yield select(selectServerWaiting);
    if (context === CONTEXTS.REST && (!notWhileWaiting || !serverWaiting)) {
      const sessionID = yield select(selectSession);
      const { message, utteranceMetadata } = utteranceList[utteranceIndex];
      yield put(sendMessageRequest({ message, sessionID, utteranceMetadata }));
      utteranceIndex++;
    }
  }
}
