/**
 * This file handles the concurrency of context actions.
 */
import { LOCATION_CHANGE } from 'redux-first-history';
import { call, cancel, delay, fork, put, race, select, take, takeEvery } from 'redux-saga/effects';
import { CONTEXTS } from '../../constants';
import { paths } from '../../constants/paths';
import { getCurrentTime } from '../../utils/time';
import { getDatasetList } from '../actions/dataset.actions';
import { getSavedExpressions } from '../actions/expressions.actions';
import { getSessionModels } from '../actions/model.actions';
import { selectContext, selectStartTime } from '../selectors/context.selector';
import {
  setContext,
  startUpdatingPendingDuration,
  stopUpdatingPendingDuration,
  updatePendingDuration,
} from '../slices/context.slice';
import { checkContextRequest } from '../slices/dataAssistant.slice';
import { resetQuestionRecommendations } from '../slices/recommendations.slice';
import { selectCurrentLocation } from './selectors';
import { loadDataChatSessionHelper } from './session.saga';

const INTERVAL = 1000; // 1 second.

// Updates the amount of time that has elapsed since the context began in x second intervals
export function* pendingDurationTimer() {
  while (true) {
    yield delay(INTERVAL);
    const currentTime = getCurrentTime(); // current time in milliseconds
    const startTime = yield select(selectStartTime);
    const duration = currentTime - startTime; // time elapsed since the context began.
    yield put(updatePendingDuration(duration));
  }
}

// Watches for calls that start and stop tracking the pending duration.
// Starts on startSessionRequest and initial sendMessageRequest of a context
// Pauses on location change
// Stops on exitSessionRequest, sendMessageFailure, receiveMessageFailure
export function* updatePendingDurationWatcher() {
  let lastTask;
  while (true) {
    const { start } = yield race({
      start: take(startUpdatingPendingDuration.type),
      stop: take(stopUpdatingPendingDuration.type),
      location: take(LOCATION_CHANGE),
    });

    if (lastTask) {
      yield cancel(lastTask);
    }
    if (start) {
      lastTask = yield fork(pendingDurationTimer);
    }
  }
}

export function* contextWatcher() {
  const context = yield select(selectContext);
  if (context === CONTEXTS.REST) {
    const location = yield select(selectCurrentLocation);
    if (location?.includes(paths.dataChatSession)) {
      // If we are in a datachat session, we need to refresh the dataspace
      const success = yield call(loadDataChatSessionHelper);
      // if we successfully get the dataspace, we need to check the context
      if (success) yield put(checkContextRequest());
    } else {
      yield put(getDatasetList());
    }
    yield put(getSessionModels());
    yield put(getSavedExpressions());
  } else if (context !== CONTEXTS.REST) yield put(resetQuestionRecommendations());
}

export default function* () {
  yield takeEvery(setContext.type, contextWatcher);
  yield fork(updatePendingDurationWatcher);
}
