import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import { ReactComponent as ExportFigureIcon } from '../../../../../assets/icons/download.svg';
import { isWorkflowEditorPage } from '../../../../../constants/paths';
import { showReplayDisableToast } from '../../../../../constants/toast';
import { exportDatasetRequest } from '../../../../../store/actions/file_download.actions';
import { addToast } from '../../../../../store/actions/toast.actions';
import { selectCurrentLocation } from '../../../../../store/sagas/selectors';
import { selectIsStepwise } from '../../../../../store/selectors/context.selector';
import { PLOTS_NOT_DOWNLOADABLE } from '../../../constants';

/**
 * A functional component to render a button UI to export visual element
 */
const ExportFigureButton = (props) => {
  // redux dispatch hook
  const dispatch = useDispatch();

  const {
    isReplaying,
    isStepwise,
    isWorkflowEditor,
    sessionType,
    messageType,
    data,
    updateModalPng,
  } = props;

  // download the table or chart
  const handleExportButtonClick = () => {
    // show toast during not stepwise replaying
    if (isReplaying && !isStepwise) {
      showReplayDisableToast(addToast, 'export', dispatch);
      return;
    }

    if (messageType === 'table' && data.name) {
      const dsName = data.name;
      dispatch(
        exportDatasetRequest({
          datasetName: dsName,
          version: data.version,
        }),
      );
    } else if (messageType === 'viz') {
      updateModalPng();
    }
  };

  // Returns true if the export button should be shown, otherwise, returns false.
  const showExportFigureButton = useCallback(
    () => {
      // Disable export for describe tables
      if (data?.title === `${data?.name}_Describe`) {
        return false;
      }

      if (data && messageType && sessionType && !isWorkflowEditor) {
        switch (messageType) {
          case 'viz':
            // Don't show button if plot doesn't render or if plotly chart isn't downloadable
            return data.typeVersion === 2
              ? data.plot.series && data.values.reference && !data.isDependencyGraph
              : data.chartType && !PLOTS_NOT_DOWNLOADABLE.includes(data.chartType);
          case 'table':
            return (
              data.name && data.version !== undefined && data.name.toLowerCase() !== 'describe'
            );
          default:
            return false;
        }
      } else {
        return false;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, isWorkflowEditor],
  );

  // helper function for checking each series has a slider
  const chartHasSlider = useCallback(() => {
    for (const series of data.plot.series) {
      if (!series?.group?.slider) {
        return false;
      }
    }
    return true;
  }, [data]);

  return (
    <>
      {showExportFigureButton() ? (
        <MenuItem
          key={`Export ${messageType === 'table' ? 'table' : 'chart'}`}
          className="Menu-Item-Container"
          onClick={handleExportButtonClick}
        >
          <div
            className={`${isReplaying && !isStepwise ? 'Menu-Item Replaying' : 'Menu-Item'}`}
            data-cy="Menu-Item-Export-Figure"
          >
            <ExportFigureIcon className="Menu-Item-Icon" />
            <span className="Menu-Item-Text">
              {messageType === 'viz' && data.typeVersion === 2 && chartHasSlider()
                ? 'Export slide'
                : `Export ${messageType === 'table' ? 'table' : 'chart'}`}
            </span>
          </div>
        </MenuItem>
      ) : null}
    </>
  );
};

ExportFigureButton.propTypes = {
  isReplaying: PropTypes.bool.isRequired,
  isStepwise: PropTypes.bool.isRequired,
  isWorkflowEditor: PropTypes.bool.isRequired,
  sessionType: PropTypes.string.isRequired,
  messageType: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  updateModalPng: PropTypes.func,
};

ExportFigureButton.defaultProps = {
  updateModalPng: () => {},
};

const mapStateToProps = (state) => ({
  sessionType: state.session.sessionType,
  isStepwise: selectIsStepwise(state),
  isReplaying: state.context.isReplaying,
  isWorkflowEditor: isWorkflowEditorPage(selectCurrentLocation(state)),
});

export default connect(mapStateToProps, {})(ExportFigureButton);
