// TODO: Convert API_SERVICES to PascalCase - required by eslint
// eslint-disable-next-line @typescript-eslint/naming-convention
export const enum API_SERVICES {
  CATALOG = 'catalogService',
  CHAT = 'chatService',
  DOWNLOAD = 'downloadService',
  SESSION = 'sessionService',
  LOG_USER_ACTION = 'logUserActionService',
  AVA = 'avaService',
  CHART = 'chartService',
  INSIGHTS_BOARD = 'insightsBoardService',
  WORKSPACEV2 = 'workspacev2Service',
  UPLOAD = 'uploadService',
  AUTH = 'authService',
  DATASET = 'datasetService',
  SUBSCRIPTIONS = 'subscriptionsService',
  DATASPACE = 'dataspaceService',
  CHARTSPACE = 'chartspaceService',
  TASK = 'taskService',
  NODES = 'nodesService',
  CONFIG = 'configService',
  EXTERNAL_STRIPE = 'externalStripeService',
  RECOMMENDATIONS = 'recommendationsService',
  DATA_ASSISTANT = 'dataAssistantService',
  LICENSE = 'licenseService',
  DATABASE_BROWSER = 'databaseBrowserService',
  INTERRUPT = 'interruptService',
  FILE_MANAGER = 'fileManagerService',
  OAUTH = 'oauthService',
  EMBED = 'embedService',
}
