/* eslint-disable react/require-default-props */
import { Boolean } from '@carbon/icons-react';
import { mdiCalendarClock, mdiDecimal, mdiNumeric, mdiTimelapse } from '@mdi/js';
import Icon from '@mdi/react';
import AbcIcon from '@mui/icons-material/Abc';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CodeIcon from '@mui/icons-material/Code';
import DataObjectIcon from '@mui/icons-material/DataObject';
import useTheme from '@mui/material/styles/useTheme';
import React from 'react';
import { COLUMN_TYPES, OTHER_TYPES } from '../ChartBuilder/utils/constants';

interface ColumnTypeIconProps {
  type: string;
  color?: string;
  size?: number; // size in rem
  className?: string;
}

const ColumnTypeIcon: React.FC<ColumnTypeIconProps> = ({
  type,
  className = '',
  color = '',
  size = 1.3,
}) => {
  const iconSize = `${size}em`;
  const theme = useTheme();
  if (!color) {
    color = theme.palette.text.secondary;
  }

  switch (type?.toLowerCase()) {
    case COLUMN_TYPES.INTEGER:
      return <Icon className={className} path={mdiNumeric} size={iconSize} color={color} />;
    case COLUMN_TYPES.FLOAT:
      return <Icon className={className} path={mdiDecimal} size={iconSize} color={color} />;
    case COLUMN_TYPES.TIMESTAMP:
      return <Icon className={className} path={mdiCalendarClock} size={iconSize} color={color} />;
    case COLUMN_TYPES.INTERVAL:
      return <Icon className={className} path={mdiTimelapse} size={iconSize} color={color} />;
    case COLUMN_TYPES.DATE:
      return <CalendarMonthIcon className={className} style={{ fontSize: iconSize, color }} />;
    case COLUMN_TYPES.TIME:
      return <AccessTimeIcon className={className} style={{ fontSize: iconSize, color }} />;
    case COLUMN_TYPES.STRING:
      return <AbcIcon className={className} style={{ fontSize: iconSize, color }} />;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    case OTHER_TYPES.EXPRESSION:
      return <CodeIcon className={className} style={{ fontSize: iconSize, color }} />;
    case COLUMN_TYPES.BOOLEAN:
      return <Boolean className={className} style={{ fontSize: iconSize, color }} />;
    case COLUMN_TYPES.JSON:
      return <DataObjectIcon className={className} style={{ fontSize: iconSize, color }} />;
    default:
      return <></>;
  }
};

export default ColumnTypeIcon;
