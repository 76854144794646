import QueryStatsIcon from '@mui/icons-material/QueryStats';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { APPS } from '../../../../../constants/apps';
import { drillThroughRequest } from '../../../../../store/actions/drill_through.actions';
import { appsSelector } from '../../../../../store/sagas/selectors';

/**
 * A functional component to render a UI button to drill through the session of the chart
 */
const DrillThroughButton = (props) => {
  // redux dispatch hook
  const dispatch = useDispatch();

  const { data, isLoading, messageType, messageTypeVersion, apps } = props;

  // Creates and navigates a user to a new session.
  // This new session has the current chart and the chart's dataset loaded by default
  const drillThrough = () => {
    // Construct snapshot data, used to open the chart-backing snapshot as a new session
    const object = {
      objectType: 'Snapshot',
      // uuid: snapshotUUID,
      // objectName: snapshotName,
      AppID: apps.find((app) => app.name === APPS.AVA).id,
    };
    dispatch(drillThroughRequest({ object, drillThroughSpec: data }));
  };

  // if chart is not in loading, trigger the drill through
  const onClickHandler = () => {
    if (!isLoading) {
      drillThrough();
    }
  };

  return (
    messageType === 'viz' &&
    messageTypeVersion === 2 && (
      <MenuItem key="drillThrough" className="Menu-Item-Container" onClick={onClickHandler}>
        <div className="Menu-Item" data-cy="Menu-Item-Drill-Through">
          <QueryStatsIcon className="Menu-Item-Icon" />
          <span className="Menu-Item-Text">Drill Through</span>
        </div>
      </MenuItem>
    )
  );
};

DrillThroughButton.propTypes = {
  data: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  messageType: PropTypes.string.isRequired,
  messageTypeVersion: PropTypes.number.isRequired,
  apps: PropTypes.array.isRequired,
};

// The snapshot name and UUID will be undefined for objects that don't have backing snapshots, ex. notes
DrillThroughButton.defaultProps = {
  isLoading: false,
};

const mapStateToProps = (state) => ({
  apps: appsSelector(state),
});

export default connect(mapStateToProps, {})(DrillThroughButton);
