import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';

import PopupState, { anchorRef, bindMenu, bindToggle } from 'material-ui-popup-state';
import { isWorkflowEditorPage } from '../../../../constants/paths';
import { selectCurrentLocation } from '../../../../store/sagas/selectors';
import { HEADER_TIMEOUT } from '../helpers/constants';

import './ButtonColumnContainer.scss';

// A general container component to wrap the vertical menu buttons
// in the far-right corner of the chart header
const ButtonColumnContainer = (props) => {
  const {
    children,
    frames,
    isInsightsBoard,
    isPopOutChart,
    isWorkflowEditor,
    messageType,
    messageTypeVersion,
  } = props;

  // Holds the timeout for closing the menu with mouseAway
  const [mouseAwayTimeout, setMouseAwayTimeout] = useState(null);

  // Controls the mouse position for positioning the dropdown menu
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  // Update the mouse position to where the click happened
  // By default, the popup menu is anchored to the button
  // This method prevents the popup menu from moving if the anchor (button) moves
  const updateMousePosition = (event, popupState) => {
    // Avoid click bubbling to parent to trigger the link option
    event.stopPropagation();

    setMousePosition({ x: event.pageX, y: event.pageY });
    popupState.open();
  };

  /** Sets 1 second mouse away timer to close popup. */
  const handleMouseLeave = (popupState) => {
    const timer = setTimeout(() => {
      popupState.close();
    }, HEADER_TIMEOUT);
    setMouseAwayTimeout(timer);
  };

  /** Resets the mouse away timeout */
  const handleMouseOver = () => {
    clearTimeout(mouseAwayTimeout);
    setMouseAwayTimeout(null);
  };

  // When the 'More options' dropdown is empty, we don't want to display the button
  const isDropdownEmpty = () => {
    if (children.length === 0) {
      // Hide the button if there are no children components passed
      return true;
    } else if (messageType === 'table') {
      // Hide the button when tables are viewed in a larger window on an IB or WF editor
      return isPopOutChart && (isInsightsBoard || isWorkflowEditor);
    } else if (messageType === 'note') {
      // Hide the button when notes are in the WF editor
      return isWorkflowEditor;
    } else if (messageType === 'viz' && messageTypeVersion !== '2') {
      // Hide the button when a plotly popout chart doesn't have a slider in the WF editor
      return isPopOutChart && isWorkflowEditor && frames === undefined;
    } else if (messageType === 'text' && isPopOutChart) {
      return true;
    }
    return false;
  };

  return isDropdownEmpty() ? null : (
    <>
      <PopupState variant="popover">
        {(popupState) => (
          <>
            <Tooltip key="chart" title="More options">
              <div
                className="ChartHeader-Button"
                ref={anchorRef(popupState)}
                {...bindToggle(popupState)}
                onClick={(event) => updateMousePosition(event, popupState)}
                data-cy="ChartHeader-Button-More-Options"
                data-testid="ChartHeader-Button-More-Options"
              >
                <MoreVertIcon className="ChartHeader-Icon-Lg" />
              </div>
            </Tooltip>
            <Menu
              className="ChartHeader-Dropdown-Menu"
              data-testid="ChartHeader-Dropdown-Menu"
              disableAutoFocusItem
              anchorReference="anchorPosition"
              anchorPosition={{ top: mousePosition.y, left: mousePosition.x }}
              {...bindMenu(popupState)}
              onClick={(e) => {
                // prevent click bubbling to parent to trigger the ChartHeader-Link's onClick
                e.stopPropagation();

                // if one of our children was clicked, close the menu
                if (e.currentTarget.contains(e.relatedTarget ?? e.target)) popupState.close();
              }}
              MenuListProps={{
                onMouseOver: handleMouseOver,
                onFocus: handleMouseOver,
                onMouseLeave: () => handleMouseLeave(popupState),
              }}
            >
              {children}
            </Menu>
          </>
        )}
      </PopupState>
    </>
  );
};

ButtonColumnContainer.propTypes = {
  // The buttons to be mapped to the vertical menu
  children: PropTypes.array,
  // The slider information for plotly charts
  frames: PropTypes.array,
  // Are we on an IB?
  isInsightsBoard: PropTypes.bool,
  // Is this object being viewed in a larger window/pop out modal?
  isPopOutChart: PropTypes.bool,
  // Are we in the WF editor?
  isWorkflowEditor: PropTypes.bool.isRequired,
  // The type of the object (viz, table, note, etc.)
  messageType: PropTypes.string.isRequired,
  // The version (1/2) corresponding to (plotly/echarts)
  messageTypeVersion: PropTypes.number,
};

ButtonColumnContainer.defaultProps = {
  children: [],
  frames: undefined,
  isInsightsBoard: false,
  isPopOutChart: false,
  messageTypeVersion: 1,
};

const mapStateToProps = (state) => ({
  isWorkflowEditor: isWorkflowEditorPage(selectCurrentLocation(state)),
});

export default connect(mapStateToProps, {})(ButtonColumnContainer);
