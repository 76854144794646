import { put, call, take } from 'typed-redux-saga';
import { createAlertChannel } from './alert-channels';
import { confirmDeleteObjectAlert, CONFIRM_BUTTON_KEY } from '../../../constants/dialog.constants';
import { closeDialog } from '../../actions/dialog.actions';
import { updateChartFailure } from '../../slices/chartspace.slice';
import { updateDatasetFailure } from '../../slices/dataspace.slice';
import { addToast } from '../../actions/toast.actions';
import { TOAST_ERROR, TOAST_LONG, TOAST_BOTTOM_RIGHT } from '../../../constants/toast';

// Error thrown when the user cancels the operation
export const USER_CANCELLATION_ERROR_MSG = 'User cancelled';

/**
 * Confirms the deletion of an object.
 * This should be wrapped in a try/catch block to handle user cancellation --
 * Pairs well with handleUpdateError()
 * @param objectName The name of the object for which we want to confirm deletion
 * @returns {Generator}
 */
export function* confirmDeleteObject(objectName: string | undefined): Generator {
  const alertChannel = yield* call(
    createAlertChannel,
    confirmDeleteObjectAlert(
      objectName ?? 'object',
      'The underlying data will not be deleted.',
      'from your session',
    ),
  );
  const keyChoice = yield* take(alertChannel);
  yield* put(closeDialog());
  if (keyChoice !== CONFIRM_BUTTON_KEY) {
    throw new Error(USER_CANCELLATION_ERROR_MSG);
  }
}

/**
 * Handles for an error caught during an update operation of chartspace or dataspace
 * @param objectType The type of the object, either 'chart' or 'dataset'
 * @param error The error that occurred
 * @returns {Generator}
 */
export function* handleUpdateError(objectType: string, error: Error | unknown): Generator {
  let errMsg = `Failed to update ${objectType}`;

  const failureAction = (
    objectType === 'chart'
      ? updateChartFailure
      : objectType === 'dataset'
      ? updateDatasetFailure
      : () => {}
  ) as (payload: {
    error: Error;
  }) => ReturnType<typeof updateChartFailure | typeof updateDatasetFailure>;

  if (error instanceof Error) {
    yield* put(failureAction({ error }));

    // If the error is a user cancellation, we don't want to show a toast
    if (error.message === USER_CANCELLATION_ERROR_MSG) return;
    errMsg += `: ${error.message}`;
  }

  yield put(
    addToast({
      toastType: TOAST_ERROR,
      message: errMsg,
      length: TOAST_LONG,
      position: TOAST_BOTTOM_RIGHT,
    }),
  );
}
