export const RESET_CHARTS = 'chart/RESET_CHARTS';
export const UPDATE_SELECTED_CHART = 'chart/UPDATE_SELECTED_CHART';
export const SAVE_CHART_TAB_ORDER = 'chart/SAVE_CHART_TAB_ORDER';
export const UPDATE_CHART_LIST = 'chart/UPDATE_CHART_LIST';

export const SHARE_CHART_REQUEST = 'chart/SHARE_CHART_REQUEST';
export const SHARE_CHART_SUCESS = 'chart/SHARE_CHART_SUCESS';
export const SHARE_CHART_FAILURE = 'chart/SHARE_CHART_FAILURE';

export const MODIFY_CHART_REQUEST = 'chart/MODIFY_CHART_REQUEST';
export const MODIFY_CHART_SUCCESS = 'chart/MODIFY_CHART_SUCCESS';
export const MODIFY_CHART_FAILURE = 'chart/MODIFY_CHART_FAILURE';

export const SAMPLE_SESSIONLESS_DATASET_REQUEST = 'chart/SAMPLE_SESSIONLESS_DATASET_REQUEST';
export const SAMPLE_SESSIONLESS_DATASET_SUCCESS = 'chart/SAMPLE_SESSIONLESS_DATASET_SUCCESS';
export const SAMPLE_SESSIONLESS_DATASET_FAILURE = 'chart/SAMPLE_SESSIONLESS_DATASET_FAILURE';

/**
 * Action for resetting the chart reducer's state
 */
export const resetCharts = () => ({ type: RESET_CHARTS });

/**
 * Action for updating the selected chart in the ChartDisplayPanel
 * @param {String} chartName external name of the chart to select
 */
export const updateSelectedChart = (chartName) => ({
  type: UPDATE_SELECTED_CHART,
  chartName,
});

/**
 * Action for saving the order of a session's chart tab list
 * @param {String} sessionId id of the session to save the tab order for
 * @param {Array} tabOrderList list of chart external names to persist in redux
 */
export const saveChartTabOrder = (sessionId, tabOrderList) => ({
  type: SAVE_CHART_TAB_ORDER,
  sessionId,
  tabOrderList,
});

/**
 * Retrieves chart link from server.
 */
export const shareChartRequest = (chartType, objectId, destination) => ({
  type: SHARE_CHART_REQUEST,
  chartType,
  objectId,
  destination,
});

export const shareChartSuccess = ({ link }) => ({
  type: SHARE_CHART_SUCESS,
  link,
});

export const shareChartFailure = ({ error }) => ({
  type: SHARE_CHART_FAILURE,
  error,
});

/**
 * Modifies a chart spec
 */
export const modifyChartRequest = ({
  aggregate,
  bins,
  callback,
  caption,
  dataSampleLimit,
  insightsBoardId,
  presentation,
  publicationId,
  series,
  transforms,
}) => ({
  type: MODIFY_CHART_REQUEST,
  aggregate,
  bins,
  callback,
  caption,
  dataSampleLimit,
  insightsBoardId,
  presentation,
  publicationId,
  series,
  transforms,
});

export const modifyChartSuccess = () => ({
  type: MODIFY_CHART_SUCCESS,
});

export const modifyChartFailure = ({ error }) => ({
  type: MODIFY_CHART_FAILURE,
  error,
});

// Retrieve sessionless data
export const sampleSessionlessDatasetRequest = ({
  callback,
  insightsBoardId,
  isTable = false,
  numRows,
  pipelinerDatasetId,
  publicationId,
  referenceString,
  workspaceUuid,
}) => ({
  type: SAMPLE_SESSIONLESS_DATASET_REQUEST,
  callback,
  insightsBoardId,
  isTable,
  numRows,
  pipelinerDatasetId,
  publicationId,
  referenceString,
  workspaceUuid,
});

export const sampleSessionlessDatasetSuccess = ({
  computeSpec,
  isTable = false,
  pipelinerDatasetId,
  result,
  tableSampleRowCount,
  usedCompute,
}) => ({
  type: SAMPLE_SESSIONLESS_DATASET_SUCCESS,
  computeSpec,
  isTable,
  pipelinerDatasetId,
  result,
  tableSampleRowCount,
  usedCompute,
});

export const sampleSessionlessDatasetFailure = ({
  computeSpec,
  error,
  isTable = false,
  pipelinerDatasetId,
  usedCompute,
}) => ({
  type: SAMPLE_SESSIONLESS_DATASET_FAILURE,
  computeSpec,
  error,
  isTable,
  pipelinerDatasetId,
  usedCompute,
});
