import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectIsPopOutEditable } from '../../../store/typedSelectors';
import {
  useAlertInfo,
  useEditingInfo,
  useModalPng,
  useModeBar,
  usePanMode,
} from './helpers/visualCustomHooks';

/**
 * A component to provide state for visuals in a session
 */
const SessionStateProvider = (props) => {
  const {
    children,
    data,
    isPopOutChart,
    messageType,
    messageTypeVersion,
    setEditingModal,
    update,
  } = props;

  // state to control play/pause slider
  const [sliderPlaying, setSliderPlaying] = useState(false);

  // state to control if table is renaming
  const [textboxLoading, setTextboxLoading] = useState(null);

  // state to control the copy message
  const [copyMsg, setCopyMsg] = useState('Copy link to clipboard');

  // state to control mode option button for chart
  const [showModeBar, toggleModeBar] = useModeBar();

  // state to control pan mode for chart
  const [isPanMode, togglePanMode] = usePanMode();

  // state to control chart export png
  const [showModalPng, updateModalPng] = useModalPng();

  // state to control alert info for echarts
  const [alertOpen, alertText, openCaptionAlert, setAlertOpen] = useAlertInfo();

  // state to control if the chart is editable in popout
  const isPopoutEditable = useSelector(selectIsPopOutEditable);

  // state to control editing functionality
  const { chartEditingMode, showEditMenu, keepChanges, toggleChartEditingMode } = useEditingInfo(
    data,
    isPopOutChart,
    messageType,
    messageTypeVersion,
    setEditingModal,
    update,
  );

  const toggleChartEditingModeAndClosePanMode = useCallback(
    (saveChanges, editMode) => {
      toggleChartEditingMode(saveChanges, editMode);
      togglePanMode(false);
    },
    [toggleChartEditingMode, togglePanMode],
  );

  return (
    <>
      {children(
        sliderPlaying,
        setSliderPlaying,
        textboxLoading,
        setTextboxLoading,
        copyMsg,
        setCopyMsg,
        showModeBar,
        toggleModeBar,
        isPanMode,
        togglePanMode,
        showModalPng,
        updateModalPng,
        alertOpen,
        alertText,
        setAlertOpen,
        openCaptionAlert,
        chartEditingMode,
        showEditMenu,
        keepChanges,
        toggleChartEditingModeAndClosePanMode,
        isPopoutEditable,
      )}
    </>
  );
};

SessionStateProvider.propTypes = {
  children: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  isPopOutChart: PropTypes.bool.isRequired,
  messageType: PropTypes.string.isRequired,
  messageTypeVersion: PropTypes.number,
  setEditingModal: PropTypes.func.isRequired,
  update: PropTypes.object,
};

SessionStateProvider.defaultProps = {
  messageTypeVersion: 0,
  update: undefined,
};

export default SessionStateProvider;
