import * as fuzzysearch from 'fuzzysearch';
import levenshtein from 'js-levenshtein';
import { COLUMN_VALUE_ENTITY } from '../constants/signal';
// Used in saga for raw suggestions in array format
export const isPrompt = (suggestion) => suggestion[0] === null;
export const isAttribute = (suggestion) => suggestion[1] !== null;
// Used in Textbox/SuggestionList for suggestions in JSON format
// For backward compatability, suggestion entry could also be just a String.
// A String var x should be interpreted equivalently as {"value": x, "name": x}.
// JSON Format Specification:
/**
 * value: text to be populated in Textbox
 * name: text shown in SuggestionList
 * description: text to be shown in grey font folllowing name in SuggestionList
 * annotation: optional metadata depending on the speicific entity type
 *
 * i.e for COLUMN_VALUE, there are COMPLETE/PARTIAL
 */
export const hasDescription = (suggestion) => {
  if (typeof suggestion === 'object') {
    return suggestion.description !== undefined;
  }
  return false;
};

export const removeSignal = (val) => {
  const re = /<[A-Z]*?>/g;
  if (val.includes('<FILE>') || val.includes('<VAL>')) {
    return val.replace(re, '').trim();
  }
  return val.replace(re, '');
};
const retrieveSignal = (val) => {
  const re = /<[A-Z]*?>/g;
  const res = re.exec(val);
  if (res) {
    const tag = res[0];
    return tag.substring(1, tag.length - 1);
  }
  return false;
};

export const getSuggestionName = (suggestion) => {
  let name = suggestion;
  if (typeof suggestion === 'object') {
    // eslint-disable-next-line
    name = suggestion.name;
  }
  if (typeof name !== 'string') name = JSON.stringify(name);
  if (!name) return name;
  return removeSignal(name);
};

export const getSuggestionExplain = (suggestion) => {
  if (typeof suggestion === 'object') {
    // eslint-disable-next-line
    return suggestion.explain || '';
  }
  return '';
};

export const getSuggestionExplainClasses = (suggestion) => {
  const classes = [];
  if (typeof suggestion === 'object' && Array.isArray(suggestion.classes)) {
    // eslint-disable-next-line
    classes.concat(suggestion.classes || []);
  }
  return classes.join(' ');
};

export const getSuggestionValue = (suggestion) => {
  let value = suggestion;
  if (typeof suggestion === 'object') {
    // eslint-disable-next-line
    value = suggestion.value;
  }
  if (typeof value !== 'string') value = JSON.stringify(value);
  if (!value) return value;
  return removeSignal(value);
};

export const getTag = (suggestion) => {
  let value = suggestion;
  if (typeof suggestion === 'object') {
    // eslint-disable-next-line
    value = suggestion.value;
  }
  if (typeof value !== 'string') value = JSON.stringify(value);
  if (!value) return false;
  return retrieveSignal(value);
};

export const getTagFromPrompts = (prompts) => {
  if (prompts && prompts.length > 0 && prompts[0]) {
    return retrieveSignal(prompts[0]);
  }
  return false;
};

/** *
 * returns the ranking of suggestion based on the partial
 * matches of prefix and a suggestion
 */
export const getRanking = (suggestions, curWord) => {
  const rank = [];
  const prefix = COLUMN_VALUE_ENTITY.getCleanText(curWord);
  const prefixLen = prefix.length;
  let score = 0;
  for (let i = 0, len = suggestions.length; i < len; i++) {
    let suggestion = suggestions[i];
    if (typeof suggestion !== 'string') {
      suggestion = suggestion.value;
    }
    if (typeof suggestion === 'string') {
      // make sure it comes at the top for exact match
      if (suggestion === prefix) {
        score = 0;
        // makes sure that suggestions come at last if prefix is larger
      } else if (suggestion.length < prefixLen) {
        score = 1;
        // gives some priority to matching prefix
      } else if (suggestion.startsWith(prefix)) {
        score = 0.1;
        // gives some priority if the continuous word is in the suggestion
      } else if (suggestion.includes(prefix)) {
        score = 0.2;
        // otherwise ranking is based on the normalized edit distance
      } else {
        score = levenshtein(prefix, suggestion) / suggestion.length;
      }
      rank.push([suggestion, score]);
    }
  }
  return rank;
};

export const suggestionFuzzyFilter = (suggestion, partialInputValue) => {
  suggestion = getSuggestionValue(suggestion);
  suggestion = suggestion.toLowerCase();
  return fuzzysearch(partialInputValue, suggestion);
};

export const sortFilenames = (filteredSuggestions, partialInputValue) => {
  if (partialInputValue.length > 0) {
    const ranking = getRanking(filteredSuggestions, partialInputValue);
    ranking.sort((a, b) => a[1] - b[1]);
    return ranking.map((a) => a[0]);
  }
  return filteredSuggestions.map((a) => (typeof a !== 'string' ? a.value : a));
};

export const wrapWithTag = (text) => {
  return `${COLUMN_VALUE_ENTITY.OPEN_TAG}${text}${COLUMN_VALUE_ENTITY.CLOSE_TAG}`;
};
