import Link from '@mui/material/Link';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import { TypeAnimation } from 'react-type-animation';
import { CLASS } from '../../../../../constants';
import { buildLoadingMessageSequence } from '../../../../../utils/dataAssistant';
import './TextMessage.scss';

// HOC for LinesEllipsis component so it is responsive.
const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

/**
 * Renders the Text inside the message.
 * @param {string} data text to render
 * @param {string} promptClass class of the message
 * @param {string} id id of the message
 * @param {bool} showTypeEffect whether or not to use the typewriter effect
 * @returns
 */

const TextMessage = ({ data, promptClass, id, showTypeEffect }) => {
  const update = promptClass === CLASS.UPDATE;
  const generating = promptClass === CLASS.UPDATE && String(data).includes('Generating');
  const failure = promptClass === CLASS.FAILURE;

  // whether or not to use typewriter effect
  const [useTypewriter, setUseTypewriter] = React.useState(showTypeEffect);

  // whether to show the "more" link in a summary response
  // NOTE - we need this so we avoid showing unwanted long summaries.
  // we should be able to remove this when our summaries are better.
  const [showMore, setShowMore] = React.useState(false);

  const renderShowMoreLink = ({ showOnClick = true }) => (
    <Link className="TextMessage-Expand" onClick={() => setShowMore(showOnClick)}>
      {showOnClick ? 'More' : 'Less'}
    </Link>
  );

  const renderText = () => {
    if (useTypewriter) {
      // if we are using the typewriter effect then we should render the typewriter effect.
      // if the message is an update but not generating, then we should render the message as is.
      if (update && !generating) return String(data);
      let sequence;
      if (generating) {
        // render the rotating sequence of loading messages
        sequence = buildLoadingMessageSequence();
      } else {
        // render the typewriter effect for the message
        sequence = [500, String(data), 1000, () => setUseTypewriter(false)];
      }

      return (
        <TypeAnimation
          // wait 500ms, render the string, wait 1000ms, then set useTypewriter to false
          sequence={sequence}
          speed={80}
          deletionSpeed={90}
          repeat={Infinity}
        />
      );
    }
    return String(data);
  };

  return (
    <div className="TextMessage" id={id} key={id} data-testid={id}>
      {showMore ? (
        <div
          data-testid={`${id}-message`}
          className={classNames('TextMessage-Text', {
            failure,
            analyzing: update && !generating,
            update,
          })}
        >
          {renderText()}
          {renderShowMoreLink({ showOnClick: false })}
        </div>
      ) : (
        <ResponsiveEllipsis
          data-testid={`${id}-message`}
          className={classNames('TextMessage-Text', {
            failure,
            analyzing: update && !generating,
            update,
          })}
          text={renderText()}
          maxLine="5"
          basedOn="letters"
          trimRight
          ellipsis={
            <>
              ...
              {renderShowMoreLink({ showOnClick: true })}
            </>
          }
        />
      )}
    </div>
  );
};

TextMessage.propTypes = {
  data: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  promptClass: PropTypes.string,
  showTypeEffect: PropTypes.bool,
};

TextMessage.defaultProps = {
  promptClass: null,
  showTypeEffect: false,
};
export default TextMessage;
