// Helper to convert an object to URLSearchParams
export default function buildQueryParams(params: Record<string, any>): URLSearchParams {
  const queryParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (value === null || value === undefined) return;
    if (Array.isArray(value)) {
      value.forEach((item) => queryParams.append(key, item.toString()));
    } else {
      queryParams.append(key, value.toString());
    }
  });
  return queryParams;
}
