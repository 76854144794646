import { call, getContext, put, select } from 'redux-saga/effects';
import { API_SERVICES } from '../../../constants/api';
import { TOAST_LONG, TOAST_WARNING } from '../../../constants/toast';
import { addToast } from '../../actions/toast.actions';
import { fileUploadFailure } from '../../actions/upload.actions';
import { selectUserID } from '../selectors';
import { fileStorageLimitReachedWorker } from './alert-channels';

export function* putFileResponseHandler(data) {
  // For each entry in the response body, add a toast
  for (const entry of data) {
    if (entry.error !== '') {
      yield put(
        addToast({
          toastType: TOAST_WARNING,
          length: TOAST_LONG,
          message: `Error uploading sheet ${entry.sheet_name}`,
        }),
      );
    }
  }
}

export function* putFileConflictHandler(accessToken, file, error) {
  try {
    const userID = yield select(selectUserID);
    const authService = yield getContext(API_SERVICES.AUTH);
    const resp = yield call(authService.getUserPermissions, userID, accessToken);
    const permissions = resp.data.values;
    const fileUploadFeature = permissions.find((e) => e.name === 'File Storage');
    const fileUploadLimit = fileUploadFeature.value;
    yield call(fileStorageLimitReachedWorker, fileUploadLimit);
    yield put(fileUploadFailure({ file, error }));
  } catch (err) {
    yield call(fileStorageLimitReachedWorker);
    yield put(fileUploadFailure({ file, error }));
  }
}
