export const getFileValidateTypeDetectType = (source) =>
  new Promise((resolve) => {
    resolve(
      `.${source.name
        .split('.')
        .pop()
        .toLowerCase()}`,
    );
  });
export default getFileValidateTypeDetectType;
