import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React from 'react';

import {
  CONNECTION_DATABASES_TYPES,
  DATABASE_TYPES_DISPLAY_NAMES,
  DEPRECATED_CONNECTION_DATABASES_TYPES,
} from '../../constants/connection';

// Note: disabling the @typescript-eslint/no-explicit-any to match the type of the onChange event
interface DatabaseTabsProps {
  onDBChange:
    | ((event: React.SyntheticEvent<Element, Event>, value: any) => void) // eslint-disable-line @typescript-eslint/no-explicit-any
    | undefined;
  selectedDb: string;
  editMode: boolean;
}

const DatabaseTabs: React.FC<DatabaseTabsProps> = ({ onDBChange, selectedDb, editMode }) => {
  const getConnectionDatabases = () => {
    // If a deprecated database type is being edited, we include the current selected database type in the list of database types
    const deprecatedDbs: string[] = editMode
      ? DEPRECATED_CONNECTION_DATABASES_TYPES.filter(
          (db) => db.toLowerCase() !== selectedDb.toLowerCase(),
        )
      : DEPRECATED_CONNECTION_DATABASES_TYPES;

    // Remove any deprecated database types
    const CONN_DBS = { ...DATABASE_TYPES_DISPLAY_NAMES };
    deprecatedDbs.forEach((db) => {
      delete CONN_DBS[db];
    });

    return CONN_DBS;
  };

  return (
    <Box className="ConnectionEditor-Content-Container-TabsContainer">
      <Tabs
        data-cy="ce-tabs"
        className="ConnectionEditor-Content-Container-TabsContainer-Tabs"
        variant="scrollable"
        allowScrollButtonsMobile
        value={DATABASE_TYPES_DISPLAY_NAMES[selectedDb.toUpperCase()]}
        onChange={(event, value: string) => {
          const dbType = CONNECTION_DATABASES_TYPES?.[value?.toUpperCase()];
          if (onDBChange) onDBChange(event, dbType);
        }}
        scrollButtons={false}
        TabIndicatorProps={{
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            height: '100%',
          },
        }}
      >
        {Object.entries(getConnectionDatabases()).map(([key, value]) => (
          <Tab
            data-cy={`ce-tab-${value}`}
            className="ConnectionEditor-Content-Container-TabsContainer-Tabs-Tab"
            disableRipple
            disableTouchRipple
            key={key}
            label={value}
            value={value}
            disabled={editMode && value.toUpperCase() !== selectedDb.toUpperCase()} // Disable the rest of the tabs apart from the editing tab in the edit mode
            style={
              editMode && value !== selectedDb
                ? { color: 'var(--mui-palette-text-mildLight)' }
                : { color: 'inherit' }
            }
          />
        ))}
      </Tabs>
    </Box>
  );
};

export default DatabaseTabs;
