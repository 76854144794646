export const SUBMIT_USER_FEEDBACK_REQUEST = 'userFeedback/SUBMIT_USER_FEEDBACK_REQUEST';
export const SUBMIT_USER_FEEDBACK_SUCCESS = 'userFeedback/SUBMIT_USER_FEEDBACK_SUCCESS';
export const SUBMIT_USER_FEEDBACK_FAILURE = 'userFeedback/SUBMIT_USER_FEEDBACK_FAILURE';

export const GET_USER_FEEDBACK_REQUEST = 'userFeedback/GET_USER_FEEDBACK_REQUEST';
export const GET_USER_FEEDBACK_SUCCESS = 'userFeedback/GET_USER_FEEDBACK_SUCCESS';
export const GET_USER_FEEDBACK_FAILURE = 'userFeedback/GET_USER_FEEDBACK_FAILURE';

export const EDIT_USER_FEEDBACK_REQUEST = 'userFeedback/EDIT_USER_FEEDBACK_REQUEST';
export const EDIT_USER_FEEDBACK_SUCCESS = 'userFeedback/EDIT_USER_FEEDBACK_SUCCESS';
export const EDIT_USER_FEEDBACK_FAILURE = 'userFeedback/EDIT_USER_FEEDBACK_FAILURE';

// request action for submitting user feedback
// TODO - add generic parameters, needed for building request body.
export const submitUserFeedbackRequest = ({ vote, context, content, sessionId }) => ({
  type: SUBMIT_USER_FEEDBACK_REQUEST,
  vote,
  context,
  content,
  sessionId,
});

// success action for submitting user feedback
export const submitUserFeedbackSuccess = ({ feedbackRecord }) => ({
  type: SUBMIT_USER_FEEDBACK_SUCCESS,
  feedbackRecord,
});

// failure action for submitting user feedback
export const submitUserFeedbackFailure = ({ error }) => ({
  type: SUBMIT_USER_FEEDBACK_FAILURE,
  error,
});

export const getUserFeedbackRequest = ({ sessionId }) => ({
  type: GET_USER_FEEDBACK_REQUEST,
  sessionId,
});

export const getUserFeedbackSuccess = ({ feedbackList }) => ({
  type: GET_USER_FEEDBACK_SUCCESS,
  feedbackList,
});

export const getUserFeedbackFailure = ({ error }) => ({
  type: GET_USER_FEEDBACK_FAILURE,
  error,
});

export const editUserFeedbackRequest = ({ feedbackId, vote }) => ({
  type: EDIT_USER_FEEDBACK_REQUEST,
  feedbackId,
  vote,
});

export const editUserFeedbackSuccess = ({ feedbackRecord }) => ({
  type: EDIT_USER_FEEDBACK_SUCCESS,
  feedbackRecord,
});

export const editUserFeedbackFailure = ({ error }) => ({
  type: EDIT_USER_FEEDBACK_FAILURE,
  error,
});
