import React from 'react';
import TextWithTooltip from '../../../common/TextWithTooltip';
import './ColumnHeader.scss';
import { useColumnSeparator } from './tableHooks';

/**
 * Column Header for Tables
 *
 * @param {*} props
 * @returns ColumnHeader Component
 */
const ColumnHeader: React.FC<{
  getEndAdornment?: (col: string) => React.ReactNode | null;
  onDoubleClick?: () => void;
  columnText: string;
  tableId: string;
}> = ({ columnText, tableId, onDoubleClick = () => {}, getEndAdornment = () => null }) => {
  const separatorId = `${tableId}-${columnText}-column`;
  useColumnSeparator(separatorId, onDoubleClick);

  return (
    <div className="column-header-container">
      <div className="column-header-text">
        <TextWithTooltip text={columnText} />
        {getEndAdornment(columnText)}
      </div>
      <div id={separatorId} className="column-separator" />
    </div>
  );
};

export default ColumnHeader;
