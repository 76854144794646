import { ChartTypes } from 'translate_dc_to_echart';

/**
 * Generates the series for a scatter chart
 * @param {Object} fields The key/value pairs for the menu input fields
 * @returns {Array} The series array of objects that instructs the translation
 * layer how to plot the chart
 */
export const generateScatterSeries = (fields) => {
  const group = {};
  const mark = {};
  let markLine = {};

  Object.keys(fields).forEach((field) => {
    switch (field) {
      case 'x-axis':
        [mark.x] = fields[field];
        break;
      case 'y-axis':
        [mark.y] = fields[field];
        break;
      case 'group':
        [mark.shape] = fields[field];
        [mark.color] = fields[field];
        break;
      case 'subplot':
      case 'slider':
        [group[field]] = fields[field];
        break;
      case 'label':
        [mark[field]] = fields[field];
        break;
      case 'markLine':
        markLine = fields[field];
        break;
      default:
        break;
    }
  });

  const newSeries = [];
  newSeries.push({
    type: ChartTypes.scatter,
    mark,
    markLine,
    group,
  });

  return newSeries;
};

/**
 * Generates the series for a bubble chart
 * @param {Object} fields The key/value pairs for the menu input fields
 * @returns {Array} The series array of objects that instructs the translation
 * layer how to plot the chart
 */
export const generateBubbleSeries = (fields) => {
  const group = {};
  const mark = {};
  let markLine = {};

  Object.keys(fields).forEach((field) => {
    switch (field) {
      case 'x-axis':
        [mark.x] = fields[field];
        break;
      case 'y-axis':
        [mark.y] = fields[field];
        break;
      case 'bubbleSize':
        [mark.size] = fields[field];
        break;
      case 'color':
        [mark[field]] = fields[field];
        break;
      case 'subplot':
      case 'slider':
        [group[field]] = fields[field];
        break;
      case 'label':
        [mark[field]] = fields[field];
        break;
      case 'markLine':
        markLine = fields[field];
        break;
      default:
        break;
    }
  });

  const newSeries = [];
  newSeries.push({
    type: ChartTypes.bubble,
    mark,
    markLine,
    group,
  });

  return newSeries;
};

/**
 * Generates the series for a donut chart
 * @param {Object} fields The key/value pairs for the menu input fields
 * @returns {Array} The series array of objects that instructs the translation
 * layer how to plot the chart
 */
export const generateDonutSeries = (fields) => {
  const group = {};
  const mark = {};

  Object.keys(fields).forEach((field) => {
    switch (field) {
      case 'sliceSize':
        [mark.x] = fields[field];
        break;
      case 'split':
        if (!fields.sliceSize) {
          [mark.x] = fields[field];
        } else {
          [group.color] = fields[field];
        }
        break;
      case 'subplot':
      case 'slider':
        [group[field]] = fields[field];
        break;
      default:
        break;
    }
  });

  const newSeries = [];
  newSeries.push({
    type: ChartTypes.donut,
    mark,
    group,
  });

  return newSeries;
};

/**
 * Generates the series for a heatmap
 * @param {Object} fields The key/value pairs for the menu input fields
 * @returns {Array} The series array of objects that instructs the translation
 * layer how to plot the chart
 */
export const generateHeatmapSeries = (fields) => {
  const group = {};
  const mark = {};

  Object.keys(fields).forEach((field) => {
    switch (field) {
      case 'x-axis':
        [mark.x] = fields[field];
        break;
      case 'y-axis':
        [mark.y] = fields[field];
        break;
      case 'density':
        [mark[field]] = fields[field];
        break;
      case 'subplot':
      case 'slider':
        [group[field]] = fields[field];
        break;
      default:
        break;
    }
  });

  const newSeries = [];
  newSeries.push({
    type: ChartTypes.heatmap,
    mark,
    group,
  });

  return newSeries;
};

/**
 * Generates the series for a line chart
 * @param {Object} fields The key/value pairs for the menu input fields
 * @returns {Array} The series array of objects that instructs the translation
 * layer how to plot the chart
 */
export const generateLineSeries = (fields) => {
  const group = {};
  const mark = {};
  let markLine = {};
  let numSeries = 0;

  Object.keys(fields).forEach((field) => {
    switch (field) {
      case 'x-axis':
        [mark.x] = fields[field];
        break;
      case 'y-axis':
        // The number of series is determined by number of columns on the y-axis
        numSeries = fields[field].length;
        break;
      case 'group':
        [mark.shape] = fields[field];
        [mark.color] = fields[field];
        break;
      case 'subplot':
      case 'slider':
        [group[field]] = fields[field];
        break;
      case 'label':
        [mark[field]] = fields[field];
        break;
      case 'markLine':
        markLine = fields[field];
        break;
      default:
        break;
    }
  });

  const newSeries = [];
  for (let i = 0; i < numSeries; i++) {
    newSeries.push({
      type: ChartTypes.line,
      mark: {
        ...mark,
        y: fields['y-axis'][i],
      },
      markLine,
      group,
    });
  }

  return newSeries;
};

/**
 * Generates the series for a stacked area chart
 * @param {Object} fields The key/value pairs for the menu input fields
 * @returns {Array} The series array of objects that instructs the translation
 * layer how to plot the chart
 */
export const generateStackedAreaSeries = (fields) => {
  const group = {};
  const mark = {};
  let markLine = {};

  Object.keys(fields).forEach((field) => {
    switch (field) {
      case 'x-axis':
        [mark.x] = fields[field];
        break;
      case 'y-axis':
        // The number of series is determined by number of columns on the y-axis
        mark.y = fields[field];
        break;
      case 'group':
        [mark.shape] = fields[field];
        [mark.color] = fields[field];
        break;
      case 'subplot':
      case 'slider':
        [group[field]] = fields[field];
        break;
      case 'markLine':
        markLine = fields[field];
        break;
      default:
        break;
    }
  });

  const newSeries = [];
  newSeries.push({
    type: ChartTypes.stackedArea,
    mark,
    markLine,
    group,
  });

  return newSeries;
};

/**
 * Generates the series for a box plot
 * @param {Object} fields The key/value pairs for the menu input fields
 * @returns {Array} The series array of objects that instructs the translation
 * layer how to plot the chart
 */
export const generateBoxplotSeries = (fields) => {
  const group = {};
  const mark = {};
  let markLine = {};

  Object.keys(fields).forEach((field) => {
    switch (field) {
      case 'x-axis':
        [mark.x, mark.color] = fields[field];
        break;
      case 'y-axis':
        [mark.y] = fields[field];
        break;
      case 'subplot':
      case 'slider':
        [group[field]] = fields[field];
        break;
      case 'markLine':
        markLine = fields[field];
        break;
      default:
        break;
    }
  });

  const newSeries = [];
  newSeries.push({
    type: ChartTypes.box,
    mark,
    markLine,
    group,
  });

  return newSeries;
};

/**
 * Generates the series for a violin chart
 * @param {Object} fields The key/value pairs for the menu input fields
 * @returns {Array} The series array of objects that instructs the translation
 * layer how to plot the chart
 */
export const generateViolinSeries = (fields) => {
  const group = {};
  const mark = {};
  let markLine = {};

  Object.keys(fields).forEach((field) => {
    switch (field) {
      case 'x-axis':
        [mark.x] = fields[field];
        break;
      case 'y-axis':
        [mark.y] = fields[field];
        break;
      case 'subplot':
      case 'slider':
      case 'split':
        [group[field]] = fields[field];
        break;
      case 'markLine':
        markLine = fields[field];
        break;
      default:
        break;
    }
  });

  const newSeries = [];
  newSeries.push({
    type: ChartTypes.violin,
    mark,
    markLine,
    group,
  });

  return newSeries;
};

/**
 * Generates the series for a ridgeline chart
 * @param {Object} fields The key/value pairs for the menu input fields
 * @returns {Array} The series array of objects that instructs the translation
 * layer how to plot the chart
 */
export const generateRidgelineSeries = (fields) => {
  const group = {};
  const mark = {};

  Object.keys(fields).forEach((field) => {
    switch (field) {
      case 'x-axis':
        [mark.x] = fields[field];
        break;
      case 'y-axis':
        [mark.y] = fields[field];
        break;
      case 'subplot':
      case 'slider':
      case 'split':
        [group[field]] = fields[field];
        break;
      default:
        break;
    }
  });

  const newSeries = [];
  newSeries.push({
    type: ChartTypes.ridgeline,
    mark,
    group,
  });

  return newSeries;
};

/**
 * Generates the series for a bar chart
 * @param {Object} fields The key/value pairs for the menu input fields
 * @returns {Array} The series array of objects that instructs the translation
 * layer how to plot the chart
 */
export const generateBarSeries = (fields) => {
  const group = {};
  const mark = {};
  let markLine = {};
  let numSeries = 0;

  Object.keys(fields).forEach((field) => {
    switch (field) {
      case 'x-axis':
        [mark.x] = fields[field];
        break;
      case 'y-axis':
        // The number of series is determined by number of columns on the y-axis
        numSeries = fields[field].length;
        break;
      case 'overlay':
        [mark.overlay] = fields[field];
        break;
      case 'group':
        [mark.shape] = fields[field];
        [mark.color] = fields[field];
        break;
      case 'subplot':
      case 'slider':
        [group[field]] = fields[field];
        break;
      case 'markLine':
        markLine = fields[field];
        break;
      default:
        break;
    }
  });

  const newSeries = [];

  for (let i = 0; i < numSeries; i++) {
    newSeries.push({
      type: ChartTypes.bar,
      mark: {
        ...mark,
        y: fields['y-axis'][i],
      },
      markLine,
      group,
    });
  }

  return newSeries;
};

/**
 * Generates the series for a horizontal bar chart
 * @param {Object} fields The key/value pairs for the menu input fields
 * @returns {Array} The series array of objects that instructs the translation
 * layer how to plot the chart
 */
export const generateHorizontalBarSeries = (fields) => {
  const group = {};
  const mark = {};
  let markLine = {};
  let numSeries = 0;

  Object.keys(fields).forEach((field) => {
    switch (field) {
      case 'x-axis':
        // The number of series is determined by number of columns on the x-axis
        numSeries = fields[field].length;
        break;
      case 'y-axis':
        [mark.y] = fields[field];
        break;
      case 'overlay':
        [mark.overlay] = fields[field];
        break;
      case 'group':
        [mark.shape] = fields[field];
        [mark.color] = fields[field];
        break;
      case 'subplot':
      case 'slider':
        [group[field]] = fields[field];
        break;
      case 'markLine':
        markLine = fields[field];
        break;
      default:
        break;
    }
  });

  const newSeries = [];

  for (let i = 0; i < numSeries; i++) {
    newSeries.push({
      type: ChartTypes.bar,
      mark: {
        ...mark,
        x: fields['x-axis'][i],
      },
      markLine,
      group,
    });
  }

  return newSeries;
};

/**
 * Generates the series for a histogram
 * @param {Object} fields The key/value pairs for the menu input fields
 * @returns {Array} The series array of objects that instructs the translation
 * layer how to plot the chart
 */
export const generateHistogramSeries = (fields) => {
  const group = {};
  const mark = {};
  let markLine = {};

  Object.keys(fields).forEach((field) => {
    switch (field) {
      case 'x-axis':
        [mark.x] = fields[field];
        break;
      case 'y-axis':
        [mark.y] = fields[field];
        break;
      case 'subplot':
      case 'slider':
        [group[field]] = fields[field];
        break;
      case 'markLine':
        markLine = fields[field];
        break;
      default:
        break;
    }
  });

  const newSeries = [];
  newSeries.push({
    type: ChartTypes.histogram,
    mark,
    markLine,
    group,
  });

  return newSeries;
};

/**
 * Generates the series for a stacked bar chart
 * @param {Object} fields The key/value pairs for the menu input fields
 * @returns {Array} The series array of objects that instructs the translation
 * layer how to plot the chart
 */
export const generateStackedBarSeries = (fields) => {
  const group = {};
  const mark = {};
  let markLine = {};

  Object.keys(fields).forEach((field) => {
    switch (field) {
      case 'x-axis':
        [mark.x] = fields[field];
        break;
      case 'y-axis':
        [mark.y] = fields[field];
        break;
      case 'partition':
        [mark.color] = fields[field];
        break;
      case 'subplot':
      case 'slider':
        [group[field]] = fields[field];
        break;
      case 'markLine':
        markLine = fields[field];
        break;
      default:
        break;
    }
  });

  const newSeries = [];
  newSeries.push({
    type: ChartTypes.stackedBar,
    mark,
    markLine,
    group,
  });

  return newSeries;
};

/**
 * Generates the series for a single metric chart
 * @param {Object} fields The key/value pairs for the menu input fields
 * @returns {Array} The series array of objects that instructs the translation
 * layer how to plot the chart
 */
export const generateSingleMetricSeries = (fields) => {
  const mark = {};

  Object.keys(fields).forEach((field) => {
    switch (field) {
      case 'column':
        [mark.column] = fields[field];
        break;
      default:
        break;
    }
  });

  const newSeries = [];
  newSeries.push({
    type: ChartTypes.singleMetric,
    mark,
  });

  return newSeries;
};

/**
 * Generates the series for a chart based on the chart type
 * @param {Object} fields The key/value pairs for the menu input fields
 * @param {String} chartType The chart type
 * @returns {Array} The generated series
 */
export const generateSeries = (fields, chartType) => {
  // Delete field keys if they have null or empty values
  Object.keys(fields).forEach((field) => {
    if (fields[field] === undefined || fields[field] === null || fields[field].length === 0) {
      delete fields[field];
    }
  });

  // Format series and presentation as expected by translation layer
  let newSeries = [];
  switch (chartType) {
    case ChartTypes.scatter:
      newSeries = generateScatterSeries(fields);
      break;
    case ChartTypes.bubble:
      newSeries = generateBubbleSeries(fields);
      break;
    case ChartTypes.donut:
      newSeries = generateDonutSeries(fields);
      break;
    case ChartTypes.heatmap:
      newSeries = generateHeatmapSeries(fields);
      break;
    case ChartTypes.line:
      newSeries = generateLineSeries(fields);
      break;
    case ChartTypes.stackedArea:
      newSeries = generateStackedAreaSeries(fields);
      break;
    case ChartTypes.box:
      newSeries = generateBoxplotSeries(fields);
      break;
    case ChartTypes.violin:
      newSeries = generateViolinSeries(fields);
      break;
    case ChartTypes.ridgeline:
      newSeries = generateRidgelineSeries(fields);
      break;
    case ChartTypes.bar:
      newSeries = generateBarSeries(fields);
      break;
    case ChartTypes.horizBar:
      newSeries = generateHorizontalBarSeries(fields);
      break;
    case ChartTypes.stackedBar:
      newSeries = generateStackedBarSeries(fields);
      break;
    case ChartTypes.singleMetric:
      newSeries = generateSingleMetricSeries(fields);
      break;
    case ChartTypes.histogram:
      newSeries = generateHistogramSeries(fields);
      break;
    default:
      break;
  }

  return newSeries;
};
