import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import React from 'react';
import { ReactComponent as PauseIcon } from '../../../../../assets/icons/pause.svg';
import { ReactComponent as PlayIcon } from '../../../../../assets/icons/play.svg';

/**
 * A functional component to show button UI to play or pause slider for plotly chart
 */
const SliderButton = (props) => {
  const { sliderPlaying, setSliderPlaying, messageType, frames } = props;

  const playSlider = () => {
    setSliderPlaying(true);
  };

  const pauseSlider = () => {
    setSliderPlaying(false);
  };

  const showPlayButton = messageType === 'viz' && frames !== undefined && !sliderPlaying;
  const showPauseButton = messageType === 'viz' && frames !== undefined && sliderPlaying;

  return (
    <>
      {showPlayButton ? (
        <MenuItem key="play" className="Menu-Item-Container" onClick={playSlider}>
          <div className="Menu-Item" data-cy="Menu-Item-Slider">
            <PlayIcon className="Menu-Item-Icon" />
            <span className="Menu-Item-Text">Play slider</span>
          </div>
        </MenuItem>
      ) : null}
      {showPauseButton ? (
        <MenuItem key="pause" className="Menu-Item-Container" onClick={pauseSlider}>
          <div className="Menu-Item" data-cy="Menu-Item-Slider">
            <PauseIcon className="Menu-Item-Icon" />
            <span className="Menu-Item-Text">Pause slider</span>
          </div>
        </MenuItem>
      ) : null}
    </>
  );
};

SliderButton.propTypes = {
  sliderPlaying: PropTypes.bool.isRequired,
  setSliderPlaying: PropTypes.func.isRequired,
  messageType: PropTypes.string.isRequired,
  frames: PropTypes.array,
};

SliderButton.defaultProps = {
  frames: undefined,
};

export default SliderButton;
