import {
  EMAIL_RESET_TOKEN_FAILURE,
  EMAIL_RESET_TOKEN_REQUEST,
  EMAIL_RESET_TOKEN_SUCCESS,
  GET_PERMISSIONS_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_REQUESTING,
  LOGIN_SUCCESS,
  LOGOUT,
  LOGOUT_FAILURE,
  LOGOUT_REQUESTING,
  LOGOUT_SUCCESS,
  OTP_FAILURE,
  OTP_REQUESTING,
  OTP_SUCCESS,
  REFRESH_ERROR,
  REFRESH_REQUESTING,
  REFRESH_SUCCESS,
  REGISTER_FAILURE,
  REGISTER_REQUESTING,
  REGISTER_SUCCESS,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_FROM_MENU_FAILURE,
  RESET_PASSWORD_FROM_MENU_SUCCESS,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  SNOWFLAKE_LOGIN_FAILURE,
  SNOWFLAKE_LOGIN_REQUEST,
  SNOWFLAKE_LOGIN_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  UPDATE_PROFILE_FAILURE_ACKNOWLEDGED,
  UPDATE_PROFILE_REQUESTING,
  UPDATE_PROFILE_SUCCESS,
} from '../actions/auth.actions';

export const initialState = {
  user: undefined,
  /**
   * User's token for `Authorization: Bearer <token>` HTTP header.
   *
   * @type {string}
   */ // JSDoc type tag enable use in TypeScript files w/out assertions or casting.
  accessToken: undefined,
  accessExp: undefined,
  refreshToken: undefined,
  refreshExp: undefined,
  email: undefined,
  name: undefined,
  isAuthenticated: false,
  isRequesting: false,
  error: undefined,
  isResetPasswordRequesting: false,
  isLoggingOut: false,
  permissions: {},
  isRequestingRefresh: false,
  updateProfileFailed: false,
  snowflakeLoginError: undefined,
  snowflakeLoginRequesting: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUESTING:
      return {
        ...state,
        isRequesting: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: action.user,
        name: action.name,
        accessToken: action.accessToken,
        accessExp: action.accessExp,
        refreshToken: action.refreshToken,
        refreshExp: action.refreshExp,
        email: action.email,
        isAuthenticated: true,
        isRequesting: false,
        error: undefined,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        error: action.error,
        isRequesting: false,
      };
    case SNOWFLAKE_LOGIN_REQUEST:
      return {
        ...state,
        snowflakeLoginRequesting: true,
      };
    case SNOWFLAKE_LOGIN_SUCCESS:
      return {
        ...state,
        snowflakeLoginRequesting: false,
        snowflakeLoginError: undefined,
      };
    case SNOWFLAKE_LOGIN_FAILURE:
      return {
        ...state,
        snowflakeLoginRequesting: false,
        snowflakeLoginError: action.error,
      };
    case LOGOUT_REQUESTING:
      return {
        ...state,
        isLoggingOut: true,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        isLoggingOut: false,
      };
    case LOGOUT_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoggingOut: false,
      };
    case LOGOUT:
      return {
        ...state,
        user: undefined,
        email: undefined,
        accessToken: undefined,
        accessExp: undefined,
        refreshToken: undefined,
        refreshExp: undefined,
        isAuthenticated: false,
      };
    case REGISTER_REQUESTING:
      return {
        ...state,
        isRequesting: true,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        error: undefined,
      };
    case REGISTER_FAILURE:
      return {
        ...state,
        error: action.error,
        isRequesting: false,
      };
    case OTP_REQUESTING:
      return {
        ...state,
        isRequesting: true,
      };
    case OTP_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        error: undefined,
      };
    case OTP_FAILURE:
      return {
        ...state,
        error: action.error,
        isRequesting: false,
      };
    case REFRESH_REQUESTING:
      return {
        ...state,
        isRequestingRefresh: true,
      };
    case REFRESH_SUCCESS:
      return {
        ...state,
        accessToken: action.accessToken,
        accessExp: action.accessExp,
        refreshExp: action.refreshExp,
        isAuthenticated: true,
        isRequestingRefresh: false,
      };
    case REFRESH_ERROR:
      return {
        ...state,
        isRequestingRefresh: false,
      };
    case EMAIL_RESET_TOKEN_REQUEST:
      return {
        ...state,
        isResetPasswordRequesting: true,
      };
    case EMAIL_RESET_TOKEN_SUCCESS:
      return {
        ...state,
        isResetPasswordRequesting: false,
        error: undefined,
      };
    case EMAIL_RESET_TOKEN_FAILURE:
      return {
        ...state,
        isResetPasswordRequesting: false,
      };
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isResetPasswordRequesting: true,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isResetPasswordRequesting: false,
        error: undefined,
      };
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        isResetPasswordRequesting: false,
      };
    case RESET_PASSWORD_FROM_MENU_SUCCESS:
      return {
        ...state,
        isResetPasswordRequesting: false,
        hasResetPassword: true,
      };
    case RESET_PASSWORD_FROM_MENU_FAILURE:
      return {
        ...state,
        isResetPasswordRequesting: false,
        error: action.error,
      };
    case GET_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissions: action.permissions,
        role: action.role,
      };
    case UPDATE_PROFILE_REQUESTING:
      return { ...state };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        name: action.name,
      };
    case UPDATE_PROFILE_FAILURE:
      return {
        ...state,
        updateProfileFailed: true,
      };
    case UPDATE_PROFILE_FAILURE_ACKNOWLEDGED:
      return {
        ...state,
        updateProfileFailed: false,
      };
    default:
      return state;
  }
};
