import { Tag as antTag, TagProps } from 'antd';
import styled, { css } from 'styled-components';

interface ITagProps extends TagProps {
  $noMargin?: boolean;
  // add new props here
}

const Tag = styled(antTag)<ITagProps>`
  ${(props) =>
    props.$noMargin &&
    css`
      margin: 0;
    `}
`;

export default Tag;
