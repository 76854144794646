export const OPEN_MODEL_MENU = 'model/OPEN_MODEL_MENU';
export const CLOSE_MODEL_MENU = 'model/CLOSE_MODEL_MENU';

export const GET_PUBLISHED_MODEL_DATA_REQUEST = 'model/GET_PUBLISHED_MODEL_DATA_REQUEST';
export const GET_PUBLISHED_MODEL_DATA_SUCCESS = 'model/GET_PUBLISHED_MODEL_DATA_SUCCESS';
export const GET_PUBLISHED_MODEL_DATA_FAILURE = 'model/GET_PUBLISHED_MODEL_DATA_FAILURE';

export const GET_SESSION_MODELS_REQUEST = 'model/GET_SESSION_MODELS_REQUEST';
export const GET_SESSION_MODELS_SUCCESS = 'model/GET_SESSION_MODELS_SUCCESS';
export const  GET_SESSION_MODELS_FAILURE= 'model/GET_SESSION_MODELS_FAULURE';

export const SELECT_MODEL_TO_PROFILE = 'model/SELECT_MODEL_TO_PROFILE';

/**
 * For opening and closing the published model menu dialog
 */
export const openModelProfiler = () => ({
  type: OPEN_MODEL_MENU,
});
export const closeModelProfiler = () => ({
  type: CLOSE_MODEL_MENU,
});

/**
 * For requesting a published model's metadata
 */
export const getPublishedModelDataRequest = ({
  publicationId,
  apiName,
  apiKey,
  apiSecret,
  requireToken,
}) => ({
  type: GET_PUBLISHED_MODEL_DATA_REQUEST,
  publicationId,
  apiName,
  apiKey,
  apiSecret,
  requireToken,
});
export const getPublishedModelDataSuccess = ({ publicationId, model }) => ({
  type: GET_PUBLISHED_MODEL_DATA_SUCCESS,
  publicationId,
  model,
});
export const getPublishedModelDataFailure = ({ error }) => ({
  type: GET_PUBLISHED_MODEL_DATA_FAILURE,
  error,
});


/**
 * Sets the selected publication to view in the model profiler
 */
export const selectModelToProfile = ({ publicationId }) => ({
  type: SELECT_MODEL_TO_PROFILE,
  publicationId,
});

export const getSessionModels = () => ({
  type: GET_SESSION_MODELS_REQUEST,
});

export const getSessionModelsSuccess = ({ models }) => ({
  type: GET_SESSION_MODELS_SUCCESS,
  models,
});

export const getSessionModelFailure = ({error}) =>({
  type: GET_SESSION_MODELS_FAILURE,
  error,
})
