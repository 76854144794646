export const FILE_UPLOAD_REQUEST = 'upload/FILE_UPLOAD_REQUEST';
export const FILE_UPLOAD_FAILURE = 'upload/FILE_UPLOAD_FAILURE';
export const FILE_UPLOAD_SUCCESS = 'upload/FILE_UPLOAD_SUCCESS';
export const FILE_UPLOAD_CANCEL = 'upload/FILE_UPLOAD_CANCEL';
export const CLEAR_CACHE_RECORD = 'upload/CLEAR_CACHE_RECORD';
export const FILE_UPLOAD_CONFLICT = 'upload/FILE_UPLOAD_CONFLICT';
export const DELAY_LOAD_REQUEST = 'upload/DELAY_LOAD_REQUEST';
export const LOAD_IMMEDIATELY = 'upload/LOAD_IMMEDIATELY';

export const OPEN_FILE_MODAL = 'upload/OPEN_FILE_MODAL';
export const CLOSE_FILE_MODAL = 'upload/CLOSE_FILE_MODAL';

export const fileUploadRequest = ({ filePond, file, isCache = false }) => ({
  type: FILE_UPLOAD_REQUEST,
  file,
  filePond,
  isCache,
});

export const fileUploadSuccess = ({ file, isCache = false }) => ({
  type: FILE_UPLOAD_SUCCESS,
  file,
  isCache,
});

export const fileUploadFailure = ({ file, error }) => ({
  type: FILE_UPLOAD_FAILURE,
  file,
  error,
});

export const fileUploadCancel = ({ error }) => ({
  type: FILE_UPLOAD_CANCEL,
  error,
});

export const loadImmediatelyAlert = (datasetsToLoadImmediately, filesToLoadImmediately) => ({
  type: LOAD_IMMEDIATELY,
  datasetsToLoadImmediately,
  filesToLoadImmediately,
});

export const clearCacheRecord = () => ({
  type: CLEAR_CACHE_RECORD,
});

export const fileUploadConflict = ({ filePond, file }) => ({
  type: FILE_UPLOAD_CONFLICT,
  filePond,
  file,
});

export const openFileModal = () => ({
  type: OPEN_FILE_MODAL,
});

export const closeFileModal = () => ({
  type: CLOSE_FILE_MODAL,
});
