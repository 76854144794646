import axios, { CancelTokenSource } from 'axios';
import { Task } from 'redux-saga';
import {
  all,
  call,
  cancel,
  cancelled,
  delay,
  fork,
  getContext,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'typed-redux-saga';
import { createWorkspace } from '../../api/workspacev2.api';
import { ReduxSagaContext } from '../../configureStore';
import { API_SERVICES } from '../../constants/api';
import { DefaultErrorMessage } from '../../constants/databaseBrowser';
import { HOME_OBJECTS } from '../../constants/home_screen';
import {
  getErrorToastConfig,
  getInfoToastConfig,
  getSuccessToastConfig,
  TOAST_ERROR,
  TOAST_SHORT,
} from '../../constants/toast';
import { loadDatasets, loadDatasetsKwargs } from '../../constants/utterance_templates';
import { WORKSPACE_ACCESS_TYPES } from '../../constants/workspace';
import {
  AddPreviewTablePayload,
  Connections,
  CreateDatasetsRequestPayload,
  GetBrowserTableMetadataRequestPayload,
  ListNamespacesRequestPayload,
  ListNamespaceTablesRequestPayload,
  Namespace,
  RefreshPreviewTablePayload,
  RequestStatus,
  SetConnectionPayload,
  TryModifySelectedEntryPayload,
} from '../../types/databaseBrowser.types';
import { HomeObjectKeys, HomeObjectKeysTypes, HomeObjects } from '../../utils/homeScreen/types';
import { openConnectionEditorRequest, setEditMode } from '../actions/connection.actions';
import { setTab } from '../actions/home_screen.actions';
import { addToast } from '../actions/toast.actions';
import { setCurrentFolder } from '../actions/workspacev2.actions';
import { closeDatasetCreator, openDatasetCreator } from '../reducers/datasetCreator.reducer';
import {
  selectDatabaseBrowserOpenConnection,
  selectDatabaseFilterTerm,
  selectDatabaseNamespaces,
  selectDatabaseRequestingNamespacesStatus,
  selectNamespace,
  selectNumSelectedTables,
  selectSelectedTables,
} from '../selectors/dbBrowser.selector';
import { selectSession } from '../selectors/session.selector';
import {
  addPreviewTable,
  closeDatabaseBrowser,
  connectionListFailure,
  connectionListRequest,
  connectionListSuccess,
  createDatasetsCancel,
  createDatasetsComplete,
  createDatasetsRequest,
  getBrowserTableMetadataFailure,
  getBrowserTableMetadataRequest,
  getBrowserTableMetadataSuccess,
  listNamespacesFailure,
  listNamespacesRequest,
  listNamespacesSuccess,
  listNamespaceTablesCancelled,
  listNamespaceTablesFailure,
  listNamespaceTablesRequest,
  listNamespaceTablesSuccess,
  modifySelectedFolder,
  modifySelectedTable,
  openDatabaseBrowser,
  refreshPreviewTable,
  setConnection,
  setDatabaseFilterTerm,
  tryModifySelectedEntry,
} from '../slices/dbBrowser.slice';
import { triggerInitialUtterance } from '../slices/initial_utterance.slice';
import { onAppClick } from '../slices/session.slice';
import { getDatachatObjects, getHomeScreenObjectsRequestWorker } from './home_screen.saga';
import { selectAccessToken, selectUserID } from './selectors';
import { datasetAlreadyExistWorker, datasetsAlreadyExistWorker } from './utils/alert-channels';
import { submitUtteranceInSession } from './utils/session';

/**
 * Fetches a pages worth of namespaces for a given database
 */
export function* listNamespacesRequestWorker({ payload }: ListNamespacesRequestPayload) {
  const { connectionUUID, page, refresh } = payload;

  try {
    const accessToken = yield* select(selectAccessToken);
    const dbBrowserService = (yield* getContext(
      API_SERVICES.DATABASE_BROWSER,
    )) as ReduxSagaContext[API_SERVICES.DATABASE_BROWSER];

    const response = yield* call(
      dbBrowserService.listNamespaces,
      accessToken,
      connectionUUID,
      page,
      refresh,
    );

    const { namespaces, totalNamespaceCount, time: lastUpdated } = response.data;

    yield* put(
      listNamespacesSuccess({
        connectionUUID,
        namespaces,
        lastUpdated,
        refresh,
        totalNamespaceCount,
      }),
    );
  } catch (error: any) {
    yield* put(
      listNamespacesFailure({
        connectionUUID,
        error: error?.message ?? DefaultErrorMessage.Namespace,
      }),
    );
  }
}

/** Fetches a pages worth of tables for a given database and namespace */
export function* listNamespaceTablesRequestWorker({ payload }: ListNamespaceTablesRequestPayload) {
  const { connectionUUID, namespace, page, refresh } = payload;
  const cancelToken: CancelTokenSource = axios.CancelToken.source();

  try {
    const accessToken = yield* select(selectAccessToken);
    const dbBrowserService = (yield* getContext(
      API_SERVICES.DATABASE_BROWSER,
    )) as ReduxSagaContext[API_SERVICES.DATABASE_BROWSER];

    // Select the filter term
    const filterTerm = yield* select(selectDatabaseFilterTerm);

    const response = yield* call(
      dbBrowserService.listNamespaceTables,
      accessToken,
      connectionUUID,
      namespace,
      filterTerm,
      page,
      refresh,
      cancelToken,
    );

    const { tables, totalTableCount, time: lastUpdated } = response.data;

    yield* put(
      listNamespaceTablesSuccess({
        connectionUUID,
        namespace,
        tables,
        totalTableCount,
        lastUpdated,
        refresh,
      }),
    );
  } catch (error: any) {
    if (axios.isCancel(error)) {
      yield* put(listNamespaceTablesCancelled());
    } else {
      yield* put(
        listNamespaceTablesFailure({
          connectionUUID,
          namespace,
          error: error?.message ?? DefaultErrorMessage.Tables,
        }),
      );
    }
  } finally {
    if (yield* cancelled()) {
      cancelToken.cancel();
    }
  }
}

/**
 * Fetches a pages worth of namespaces for a given database
 */
export function* getBrowserTableMetadataRequestWorker({
  payload,
}: GetBrowserTableMetadataRequestPayload) {
  const { connectionUUID, namespace, table, refresh } = payload;

  try {
    const accessToken = yield* select(selectAccessToken);
    const dbBrowserService = (yield* getContext(
      API_SERVICES.DATABASE_BROWSER,
    )) as ReduxSagaContext[API_SERVICES.DATABASE_BROWSER];

    const response = yield* call(
      dbBrowserService.getBrowserTableMetadata,
      accessToken,
      connectionUUID,
      namespace,
      table,
      refresh,
    );

    const { rows, columns, time: lastUpdated } = response.data;

    yield* put(
      getBrowserTableMetadataSuccess({
        connectionUUID,
        namespace,
        table,
        rows,
        columns,
        lastUpdated,
        refresh,
      }),
    );
  } catch (error: any) {
    yield* put(
      getBrowserTableMetadataFailure({
        connectionUUID,
        namespace,
        table,
        error: error?.message ?? DefaultErrorMessage.TableMetadata,
      }),
    );
  }
}

export function* setDatabaseFilterTermWorker({
  payload,
}: ReturnType<typeof setDatabaseFilterTerm>) {
  const { connectionUUID, delay: delayTime = 300 } = payload;

  // Delay to debounce rapid consecutive actions
  if (delayTime) {
    yield* delay(delayTime);
  }

  // Select all namespaces
  const namespaces: { [namespace: string]: Namespace } = yield* select(selectDatabaseNamespaces);

  // Find only the namespaces that already requested data
  const namespaceNames = Object.keys(namespaces).filter(
    (namespace) => namespaces[namespace].requestingTablesStatus !== RequestStatus.Unrequested,
  );

  // Fork and keep track of tasks for each namespace
  const tasks = yield* namespaceNames.map((namespace) =>
    fork(
      listNamespaceTablesRequestWorker,
      listNamespaceTablesRequest({ connectionUUID, namespace, page: 0, refresh: true }),
    ),
  );

  // Wait for new setDatabaseFilterTerm action to cancel ongoing tasks
  yield take(setDatabaseFilterTerm.type);
  for (const task of tasks ?? []) {
    yield* cancel(task);
  }
}

function* openDatabaseBrowserWorker() {
  const connection = yield* select(selectDatabaseBrowserOpenConnection);

  // Fet the connection list
  yield* put(connectionListRequest());
  yield* race([take(connectionListSuccess.type), take(connectionListFailure.type)]);

  // If the connection is already selected, open it
  if (connection !== null) {
    yield* put(setConnection({ connection }));
  } else {
    // Otherwise, select the connection
    yield* put(openConnectionEditorRequest({}));
    yield* put(setEditMode({ editMode: false }));
  }
}

function* setConnectionWorker({ payload }: SetConnectionPayload) {
  const { connection } = payload;
  const connectionUUID = connection?.[HomeObjectKeys.UUID];
  if (connectionUUID !== undefined) {
    const listNamespacesRequestStatus = yield* select(selectDatabaseRequestingNamespacesStatus);
    if (listNamespacesRequestStatus === RequestStatus.Unrequested) {
      yield* put(listNamespacesRequest({ connectionUUID, page: 0, refresh: false }));
    }
  }
}

function* connectionListRequestWorker() {
  try {
    const accessToken: string = yield select(selectAccessToken);
    const dcObjectData: Connections = yield call(
      getDatachatObjects,
      accessToken,
      HomeObjects.CONNECTION,
    );
    yield put(connectionListSuccess({ connections: dcObjectData }));
  } catch (error: any) {
    const errorMessage: string = error?.message ?? DefaultErrorMessage.Database;
    yield* put(connectionListFailure(errorMessage));
  }
}

export function* tryModifySelectedEntryWorker({ payload }: TryModifySelectedEntryPayload) {
  const { namespace, table } = payload;
  const connection = yield* select(selectDatabaseBrowserOpenConnection);
  const isReadOnly = connection?.[HomeObjectKeys.READ_ONLY];
  const selectedTables = yield* select(selectSelectedTables);
  const namespaceSelectedTables = selectedTables[namespace] ?? [];
  const numSelectedTables = yield* select(selectNumSelectedTables);
  if (
    !isReadOnly && // If connection is not read only
    namespaceSelectedTables.length === 0 && // And we haven't selected this schema or its tables yet
    numSelectedTables > 0 // And we have selected tables from other schemas
  ) {
    // Don't allow as loading won't work
    yield* put(
      addToast({
        toastType: TOAST_ERROR,
        length: TOAST_SHORT,
        message: 'Only read-only Databases can load from multiple schema',
      }),
    );
    return;
  }

  if (table) {
    yield* put(modifySelectedTable({ namespace, table }));
  } else {
    yield* put(modifySelectedFolder({ namespace }));
  }
}

function* addPreviewTableWorker({ payload }: AddPreviewTablePayload) {
  const { namespace, table } = payload;
  const connection = yield* select(selectDatabaseBrowserOpenConnection);
  if (!connection) return;
  const namespaceObject: Namespace | undefined = yield* select(selectNamespace, namespace);
  const status = namespaceObject?.tables?.[table]?.metadataStatus ?? RequestStatus.Unrequested;
  if (RequestStatus.Unrequested === status) {
    yield put(
      getBrowserTableMetadataRequest({
        connectionUUID: connection[HomeObjectKeys.UUID],
        namespace,
        table,
        refresh: false,
      }),
    );
  }
}

function* refreshPreviewTableWorker({ payload }: RefreshPreviewTablePayload) {
  const { namespace, table } = payload;
  const connection = yield* select(selectDatabaseBrowserOpenConnection);
  if (!connection) return;
  yield put(
    getBrowserTableMetadataRequest({
      connectionUUID: connection[HomeObjectKeys.UUID],
      namespace,
      table,
      refresh: true,
    }),
  );
}

export function* createFolder({
  accessToken,
  folderName,
  parentUUID,
  folderObjects,
}: {
  accessToken: string;
  folderName: string;
  parentUUID?: string;
  folderObjects: { [uuid: string]: HomeObjectKeysTypes };
}) {
  const existingFolder = Object.values(folderObjects).find(
    (obj: HomeObjectKeysTypes) =>
      obj[HomeObjectKeys.NAME] === folderName && obj[HomeObjectKeys.PARENT_ID] === parentUUID,
  );
  if (!existingFolder) {
    // Create new folder
    const response = yield* call(createWorkspace, accessToken, folderName, parentUUID);
    return response.data.uuid;
  }
  return existingFolder[HomeObjectKeys.UUID];
}

/**
 * Checks if the selected tables already exist in the DB Browser and asks the user if they want to
 * overwrite any of those datasets
 *
 * @param selectedTables Tables selected in the DB Browser
 * @param datasetObjects Current list of Dataset DC Objects
 * @param inSession If this was called from within a session
 * @returns List of tables to overwrite, list of tables to skip, and a boolean to cancel the
 * operation
 */
export function* handleExistingDatasets(
  selectedTables: { [namespace: string]: string[] },
  datasetObjects: { [uuid: string]: HomeObjectKeysTypes },
  inSession: boolean = false,
) {
  // Get the existing datasets
  const existing = [];
  for (const schema in selectedTables) {
    if (selectedTables.hasOwnProperty(schema)) {
      for (const table of selectedTables[schema]) {
        const existingDataset = Object.values(datasetObjects).find(
          (obj) => obj[HomeObjectKeys.NAME] === table,
        );
        if (existingDataset) {
          existing.push(existingDataset);
        }
      }
    }
  }

  let overwriteTables = new Map();
  let skipTables = new Map(); // list of table to skip
  let cancelCreate = false;
  const hasDatasets = existing.length > 0;
  if (inSession && hasDatasets) {
    // If in a session, don't ask and do not overwrite
    skipTables = new Map(existing.map((dataset) => [dataset[HomeObjectKeys.NAME], dataset]));
  } else if (hasDatasets) {
    type ResType = {
      overwrites: HomeObjectKeysTypes[];
      removals: HomeObjectKeysTypes[];
      cancel: boolean;
    };
    let result: ResType | unknown;

    // If there is only one dataset, call the single dataset alert dialog
    if (existing.length === 1) {
      result = yield* call(datasetAlreadyExistWorker, existing[0]);
    } else {
      result = yield* call(datasetsAlreadyExistWorker, existing);
    }
    // Call alert dialog to ask user if they want to overwrite or remove existing datasets
    const { overwrites, removals, cancel: cancelSelected } = result as ResType;
    if (cancelSelected) {
      // If cancel, all dataset overwrites should be skipped
      skipTables = new Map(existing.map((dataset) => [dataset[HomeObjectKeys.NAME], dataset]));
      cancelCreate = true;
    } else {
      overwriteTables = new Map(
        overwrites.map((dataset) => [dataset[HomeObjectKeys.NAME], dataset]),
      );
      skipTables = new Map(removals.map((dataset) => [dataset[HomeObjectKeys.NAME], dataset]));
    }
  }
  return { overwriteTables, skipTables, cancel: cancelCreate };
}

/**
 * Creates a new session (if not in a session yet) and loads the given datasets
 *
 * @param tablesToLoad List of tables and uuids to load
 * @param inSession If this action was triggered in a session or not
 */
export function* handleSessionLoading(
  tablesToLoad: { name: string; uuid: string }[],
  inSession: boolean,
) {
  if (tablesToLoad.length === 0) return;
  const loadDataUtterance = loadDatasets(tablesToLoad.map((table) => table.name));
  const utteranceMetadata = loadDatasetsKwargs(tablesToLoad);

  // Load datasets in session
  if (!inSession) {
    yield put(onAppClick({}));
    yield put(triggerInitialUtterance({ message: loadDataUtterance, utteranceMetadata }));
  } else {
    yield* call(
      submitUtteranceInSession,
      [{ message: loadDataUtterance, utteranceMetadata }],
      true,
    );
  }

  yield* put(closeDatabaseBrowser());
  yield* put(closeDatasetCreator());
}

/**
 *
 * @param selectedTables Tables selected in the database browser
 * @param connectionId Id of the current connection
 * @param connectionFolderId Id of the folder coresponding ot the connectionId
 * @param skipTables Datasets that already exist and are NOT selected to overwrite
 * @param overwriteTables Datasets that already exist and are selected to overwrite
 * @param folderObjects A list of all folder objects
 * @returns A list of successfully created datasets and a list of datasets that failed to be created
 */
export function* createDatasets(
  selectedTables: { [namespace: string]: string[] },
  connectionId: string,
  connectionFolderId: string,
  skipTables: Map<string, HomeObjectKeysTypes>,
  overwriteTables: Map<string, HomeObjectKeysTypes>,
  folderObjects: { [uuid: string]: HomeObjectKeysTypes },
) {
  const accessToken = yield* select(selectAccessToken);
  const allSuccessfulTables: { name: string; uuid: string }[] = [];
  const allFailedTables: string[] = [];

  // Create Persisted Dataset for each selected Table
  for (const schema in selectedTables) {
    if (selectedTables.hasOwnProperty(schema)) {
      if (Object.keys(selectedTables[schema]).length === 0) {
        continue;
      }
      const schemaFolderId = yield* call(createFolder, {
        accessToken,
        folderName: schema,
        parentUUID: connectionFolderId,
        folderObjects,
      });
      for (const table of selectedTables[schema]) {
        try {
          const existingObject = overwriteTables?.get(table);
          if (!skipTables?.get(table)) {
            const params = {
              connectionId,
              schema,
              tableName: table,
              parentUUID: schemaFolderId,
            };

            const datasetService = (yield* getContext(
              API_SERVICES.DATASET,
            )) as ReduxSagaContext[API_SERVICES.DATASET];
            if (existingObject) {
              yield call(
                datasetService.deleteDataset,
                accessToken,
                existingObject[HomeObjectKeys.UUID],
              );
            }
            const res = yield* call(datasetService.createDatasetFromDatabase, accessToken, params);
            if (res.status === 200) {
              const resBody = res.data;
              const dataset = { name: resBody.name, uuid: resBody.uuid };
              allSuccessfulTables.push(dataset);
            } else {
              throw new Error('Failed to create dataset');
            }
          }
        } catch (e) {
          allFailedTables.push(table);
        }
      }
    }
  }

  return { allSuccessfulTables, allFailedTables };
}

/**
 * Handles creating and loading datasets in the database browser
 *
 * @param action - createDatasetsRequest action
 * @param action.payload - payload of the action
 * @param action.payload.openInSession - boolean to indicate if the datasets should be loaded in a
 *                                       session
 */
export function* createDatasetsWorker({ payload }: CreateDatasetsRequestPayload) {
  const { openInSession } = payload;

  // Get essential data from the store
  const [connection, selectedTables, accessToken, session, userId] = yield* all([
    select(selectDatabaseBrowserOpenConnection),
    select(selectSelectedTables),
    select(selectAccessToken),
    select(selectSession),
    select(selectUserID),
  ]);

  if (!connection) return;

  // Filter to only include objects owned by the user
  const ownerFilter = (obj: HomeObjectKeysTypes) => obj[HomeObjectKeys.OWNER] === userId;
  // Fetch existing folders and datasets in parallel
  const [folderObjects, datasetObjects] = yield* all([
    call(getDatachatObjects, accessToken, HomeObjects.FOLDER, ownerFilter),
    call(getDatachatObjects, accessToken, HomeObjects.DATASET, ownerFilter),
  ]);

  // Create a folder for the connection
  const connectionFolderId = yield* call(createFolder, {
    accessToken,
    folderName: connection[HomeObjectKeys.NAME],
    parentUUID: undefined,
    folderObjects,
  });

  // Handle existing datasets
  const {
    overwriteTables,
    skipTables,
    cancel: cancelSelected,
  } = yield call(handleExistingDatasets, selectedTables, datasetObjects, Boolean(session));

  // Cancel the operation if the user selected to cancel
  if (cancelSelected) {
    yield* put(createDatasetsCancel());
  }

  // Create datasets for each selected table
  const { allSuccessfulTables, allFailedTables } = yield call(
    createDatasets,
    selectedTables,
    connection[HomeObjectKeys.ID],
    connectionFolderId,
    skipTables,
    overwriteTables,
    folderObjects,
  );

  // Display toast message
  if (allSuccessfulTables.length === 0 && skipTables.size === 0 && allFailedTables.length === 0) {
    yield* put(addToast(getErrorToastConfig('No datasets imported')));
    yield* put(createDatasetsComplete({ failedDatasets: allFailedTables, successfulDatasets: [] }));
    return;
  } else if (
    allSuccessfulTables.length === 0 &&
    skipTables.size === 0 &&
    allFailedTables.length > 0
  ) {
    yield* put(addToast(getErrorToastConfig('Failed to import all datasets')));
    yield* put(createDatasetsComplete({ failedDatasets: allFailedTables, successfulDatasets: [] }));
    return;
  } else if (allFailedTables.length > 0) {
    yield* put(addToast(getErrorToastConfig(`Failed to import ${allFailedTables.join(', ')}`)));
  } else if (allSuccessfulTables.length > 0) {
    yield* put(addToast(getSuccessToastConfig('All datasets imported successfully')));
  } else if (skipTables.size > 0) {
    yield* put(addToast(getInfoToastConfig('All datasets already imported')));
  }

  // Load datasets in session
  if (openInSession) {
    // Construct list of imported tables
    const importedTables: { name: string; uuid: string }[] = [
      // Newly Created Datasets
      ...allSuccessfulTables,
      // Datasets that already exist
      ...Array.from(skipTables?.values() ?? []).map((o) => ({
        name: (o as HomeObjectKeysTypes)[HomeObjectKeys.NAME],
        uuid: (o as HomeObjectKeysTypes)[HomeObjectKeys.UUID],
      })),
    ];
    yield* call(handleSessionLoading, importedTables, Boolean(session));
  } else if (allSuccessfulTables.length > 0) {
    // Refresh home screen objects in parallel
    yield* all([
      call(getHomeScreenObjectsRequestWorker, { objectType: HOME_OBJECTS.DATASET }),
      call(getHomeScreenObjectsRequestWorker, { objectType: HOME_OBJECTS.FOLDER }),
    ]);

    // Update home screen workspace
    yield* put(setTab({ tab: HomeObjects.ALL }));
    yield* put(
      setCurrentFolder({
        folder: {
          uuid: connectionFolderId,
          objectName: connection[HomeObjectKeys.NAME],
          accessType: WORKSPACE_ACCESS_TYPES.OWNER,
        },
      }),
    );
    // Close DB Browser
    yield* put(closeDatabaseBrowser());
    yield* put(closeDatasetCreator());
  }

  // Dispatch completion action
  yield* put(
    createDatasetsComplete({
      failedDatasets: allFailedTables,
      successfulDatasets: allSuccessfulTables,
    }),
  );
}

function* watchsetDatabaseFilterTerm() {
  let task: Task | undefined;
  while (true) {
    // TODO: Fix the type of the action
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: Ignore TypeScript warning about missing payload property
    const action: ReturnType<typeof setDatabaseFilterTerm> = yield* take(
      setDatabaseFilterTerm.type,
    );
    if (task) {
      yield* cancel(task);
    }
    task = yield* fork(setDatabaseFilterTermWorker, action);
  }
}

export default function* dbBrowserSaga() {
  yield* takeLatest(listNamespacesRequest.type, listNamespacesRequestWorker);
  yield* takeEvery(listNamespaceTablesRequest.type, listNamespaceTablesRequestWorker);
  yield* takeLatest(getBrowserTableMetadataRequest.type, getBrowserTableMetadataRequestWorker);
  yield* fork(watchsetDatabaseFilterTerm); // custom watcher for setDatabaseFilterTerm
  yield* takeLatest(openDatabaseBrowser.type, openDatabaseBrowserWorker);
  yield* takeLatest(openDatasetCreator.type, openDatabaseBrowserWorker);
  yield* takeLatest(setConnection.type, setConnectionWorker);
  yield* takeLatest(connectionListRequest.type, connectionListRequestWorker);
  yield* takeEvery(tryModifySelectedEntry.type, tryModifySelectedEntryWorker);
  yield* takeLatest(createDatasetsRequest.type, createDatasetsWorker);
  yield* takeEvery(addPreviewTable.type, addPreviewTableWorker);
  yield* takeEvery(refreshPreviewTable.type, refreshPreviewTableWorker);
}
