import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { ReactComponent as PublishIcon } from '../../../../../assets/icons/publish-add.svg';
import { CONTEXTS, VISUAL_TYPES } from '../../../../../constants';
import { isWorkflowEditorPage } from '../../../../../constants/paths';
import { showReplayDisableToast } from '../../../../../constants/toast';
import { openPublishMenu } from '../../../../../store/actions/insights_board.actions';
import { addToast } from '../../../../../store/actions/toast.actions';
import { selectCurrentLocation } from '../../../../../store/sagas/selectors';
import { selectContext, selectIsStepwise } from '../../../../../store/selectors/context.selector';

/**
 * A functional component to render button UI to publish visuals from session to IB
 */
const PublishButton = (props) => {
  // redux dispatch hook
  const dispatch = useDispatch();

  const context = useSelector(selectContext);

  const { isReplaying, isStepwise, isWorkflowEditor, objectId, data, messageType, update } = props;

  // Publishing is not yet supported for tab visuals
  const isTabVisual = messageType === VISUAL_TYPES.TABVISUAL;

  // Returns true if the Publish Chart Button should be shown, otherwise, returns false
  const showPublishChartButton = () => {
    // Check for flag in configuration for testing
    if (data.config?.displayPublish === true) {
      return true;
    }
    // Publish functionality will only work if and only if objectId
    // is defined and not in insights board
    return (
      objectId &&
      !isTabVisual &&
      !isWorkflowEditor &&
      !data.isDependencyGraph &&
      data.title !== `${data.name}_Describe`
      // Disable publishing for describe tables
    );
  };

  const isDisabled = (isReplaying && !isStepwise) || context !== CONTEXTS.REST;

  const handleOnClick = () => {
    if (isDisabled) {
      showReplayDisableToast(addToast, 'publish', dispatch);
    } else {
      dispatch(
        openPublishMenu({
          messageType,
          objectId,
          data,
          update,
        }),
      );
    }
  };

  return (
    <>
      {showPublishChartButton() ? (
        <MenuItem
          key="publish"
          data-testid="publish-menu-item"
          className="Menu-Item-Container"
          onClick={handleOnClick}
        >
          <div className={`Menu-Item${isDisabled ? ' Replaying' : ''}`} data-cy="Menu-Item-Publish">
            <PublishIcon className="Menu-Item-Icon" />
            <span className="Menu-Item-Text">Publish</span>
          </div>
        </MenuItem>
      ) : null}
    </>
  );
};

PublishButton.propTypes = {
  isReplaying: PropTypes.bool.isRequired,
  isStepwise: PropTypes.bool.isRequired,
  isWorkflowEditor: PropTypes.bool.isRequired,
  objectId: PropTypes.string,
  messageType: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  update: PropTypes.object,
};

PublishButton.defaultProps = {
  objectId: undefined,
  update: null,
};

const mapStateToProps = (state) => ({
  isStepwise: selectIsStepwise(state),
  isReplaying: state.context.isReplaying,
  isWorkflowEditor: isWorkflowEditorPage(selectCurrentLocation(state)),
});

export default connect(mapStateToProps, {})(PublishButton);
