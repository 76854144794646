// eslint-disable-next-line no-restricted-syntax
import debounce from 'lodash/debounce';
import { useCallback, useEffect } from 'react';

/**
 * Debounce the function call with the given delay
 *
 * @param fn memoized function with it's dependencies
 * @param delay the delay in ms
 * @param deps the dependencies to trigger the debounce
 */
const useDebounce = (fn: () => void, delay: number, deps: any[] = []) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFn = useCallback(debounce(fn, delay), [fn, delay]);

  useEffect(() => {
    debouncedFn();
    return () => debouncedFn.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps]);
};

export default useDebounce;
