/**
 * Constants used by the Workflow Editor
 */

export const REPLAY_STATUS = {
  NONE: 'none', // no replay session currently running
  STARTING: 'starting', // session is being setup for replay
  ENDING: 'ending', // replay session is being ended
  READY: 'ready', // session is ready for the next utterance
  WORKING: 'working', // current utterance is being executed
  QUESTIONING: 'questioning', // waiting for the user's answer to a question
  FAILURE: 'failure', // execution of current utterance failed
};

export const EXEC_STATUS = {
  RUN: 'run',
  NOT_RUN: 'not run',
  NEXT: 'next',
};

// Max number of changes that the changeLog will log.
export const MAX_CHANGE_DEPTH = 100;

export const CHANGE_TYPES = {
  ADD: 'add',
  EDIT: 'edit',
  DELETE: 'delete',
  SPLIT: 'split',
  MERGE: 'merge',
  REORDER: 'reorder',
  BULK_ADD: 'bulk add',
  BULK_EDIT: 'bulk edit',
  BULK_DELETE: 'bulk delete',
};

export const EDITABLE_OBJECTS = {
  RECIPE: 'recipe',
  CHART: 'chart',
  DATASET_OBJECT: 'dataset',
};

// used for resetting the caret position after manually focusing on an utterance
export const CARET_POSITION = {
  START: 'start',
  END: 'end',
};
