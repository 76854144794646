export const OPEN_PRIVATE_EMBED_PAGE = 'embed/OPEN_PRIVATE_EMBED_PAGE';
export const RESET_PRIVATE_EMBED_PAGE = 'embed/RESET_PRIVATE_EMBED_PAGE';

export const GET_PUBLICATION_REQUEST = 'embed/GET_PUBLICATION_REQUEST';
export const GET_PUBLICATION_SUCCESS = 'embed/GET_PUBLICATION_SUCCESS';
export const GET_PUBLICATION_FAILURE = 'embed/GET_PUBLICATION_FAILURE';

export const SUBMIT_ACTION_REQUEST = 'embed/SUBMIT_ACTION_REQUEST';
export const SUBMIT_ACTION_SUCCESS = 'embed/SUBMIT_ACTION_SUCCESS';
export const SUBMIT_ACTION_FAILURE = 'embed/SUBMIT_ACTION_FAILURE';

export const GET_ACTION_RESULT_REQUEST = 'embed/GET_ACTION_RESULT_REQUEST';
export const GET_ACTION_RESULT_SUCCESS = 'embed/GET_ACTION_RESULT_SUCCESS';
export const GET_ACTION_RESULT_ERROR = 'embed/GET_ACTION_RESULT_ERROR';
export const GET_ACTION_RESULT_FAILURE = 'embed/GET_ACTION_RESULT_FAILURE';

export const GET_REFRESHED_CHART_REQUEST = 'embed/GET_REFRESHED_CHART_REQUEST';
export const GET_REFRESHED_CHART_SUCCESS = 'embed/GET_REFRESHED_CHART_SUCCESS';
export const GET_REFRESHED_CHART_FAILURE = 'embed/GET_REFRESHED_CHART_FAILURE';
export const POLL_COMPLETE_REFRESHED_CHART = 'embed/POLL_COMPLETE_REFRESHED_CHART';

export const EXPORT_PREDICTIONS_TO_CSV = 'model/EXPORT_PREDICTIONS_TO_CSV';

/**
 * For navigating to a private embedded page
 */
export const openPrivateEmbedPage = (pubId, pubType) => ({
  type: OPEN_PRIVATE_EMBED_PAGE,
  pubId,
  pubType,
});

/**
 * For resetting the
 */
export const resetPrivateEmbedPage = () => ({ type: RESET_PRIVATE_EMBED_PAGE });

/**
 * For retrieving a publication from the server
 */
export const getPublicationRequest = ({
  pubId,
  pubType,
  apiKey,
  refreshId,
  apiSecret,
  accessToken,
}) => ({
  type: GET_PUBLICATION_REQUEST,
  pubId,
  pubType,
  apiKey,
  refreshId,
  apiSecret,
  accessToken,
});

export const getPublicationSuccess = ({ pubType, rawData }) => ({
  type: GET_PUBLICATION_SUCCESS,
  pubType,
  rawData,
});

export const getPublicationFailure = ({ pubType, error }) => ({
  type: GET_PUBLICATION_FAILURE,
  pubType,
  error,
});

/**
 * For submitting an action on a publication
 */
export const submitActionRequest = ({ pubId, pubType, actionType, actionInput }) => ({
  type: SUBMIT_ACTION_REQUEST,
  pubId,
  pubType,
  actionType,
  actionInput,
});
export const submitActionSuccess = ({ actionId, actionType, data }) => ({
  type: SUBMIT_ACTION_SUCCESS,
  actionId,
  actionType,
  data,
});
export const submitActionFailure = ({ actionType, error }) => ({
  type: SUBMIT_ACTION_FAILURE,
  actionType,
  error,
});

/**
 * For retrieving the result of a publication action
 */
export const getActionResultRequest = ({ pubId, pubType, actionId, actionType }) => ({
  type: GET_ACTION_RESULT_REQUEST,
  pubId,
  pubType,
  actionId,
  actionType,
});
export const getActionResultSuccess = ({ pubId, actionId, actionType, data }) => ({
  type: GET_ACTION_RESULT_SUCCESS,
  pubId,
  actionId,
  actionType,
  data,
});
export const getActionResultError = ({ pubId, actionId, actionType, data }) => ({
  type: GET_ACTION_RESULT_ERROR,
  pubId,
  actionId,
  actionType,
  data,
});
export const getActionResultFailure = ({ pubId, actionId, actionType, error }) => ({
  type: GET_ACTION_RESULT_FAILURE,
  pubId,
  actionId,
  actionType,
  error,
});

/**
 * Refreshes a published chart
 * NOTE: Specific to embedded chart use
 */
export const getRefreshedChartRequest = ({ pubId, apiKey, apiSecret, accessToken }) => ({
  type: GET_REFRESHED_CHART_REQUEST,
  pubId,
  apiKey,
  apiSecret,
  accessToken,
});

export const getRefreshedChartSuccess = () => ({ type: GET_REFRESHED_CHART_SUCCESS });

export const getRefreshedChartFailure = ({ error }) => ({
  type: GET_REFRESHED_CHART_FAILURE,
  error,
});

/**
 * Retrieves the result of a chart refresh from the server
 * NOTE: Specific to embedded chart use
 */
export const pollForRefreshedChartRequest = ({
  pubId,
  pubType,
  actionId,
  apiKey,
  apiSecret,
  accessToken,
}) => ({
  type: POLL_COMPLETE_REFRESHED_CHART,
  pubId,
  pubType,
  actionId,
  apiKey,
  apiSecret,
  accessToken,
});

/**
 * Triggers the export of the prediction table's data
 * NOTE: Specific to embedded model use
 */
export const exportPredictionsToCsv = ({ columns, data, modelOutput, modelName }) => ({
  type: EXPORT_PREDICTIONS_TO_CSV,
  columns,
  data,
  modelOutput,
  modelName,
});
