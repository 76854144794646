/**
 * This file handles the communication of session requests
 * between the client and the server.
 */
import { CONFLICT, OK } from 'http-status-codes';
import { push } from 'redux-first-history';
import {
  actionChannel,
  all,
  call,
  cancel,
  fork,
  put,
  race,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import {
  exitSession,
  getAllUserSessions,
  getSessionCollaborators,
  getSessionInfo,
  postCreateSession,
  saveSessionName,
} from '../../api/session.api';
import { HOME_OBJECTS } from '../../constants/home_screen';
import { paths } from '../../constants/paths';
import { UNNAMED_SESSION } from '../../constants/session';
import { TOAST_BOTTOM_RIGHT, TOAST_INFO, TOAST_SHORT } from '../../constants/toast';
import { authenticate } from '../../utils/authenticate';
import { clearUtteranceHistory } from '../actions/chat.actions';
import { createAlertChannelRequest } from '../actions/dialog.actions';
import { closeExplorer } from '../actions/explorer.actions';
import {
  GET_HOME_SCREEN_OBJECTS_FAILURE,
  GET_HOME_SCREEN_OBJECTS_SUCCESS,
  getHomeScreenObjectsRequest,
} from '../actions/home_screen.actions';
import { clearScreen } from '../actions/messages.actions';
import { stopPollingMechanism } from '../actions/poll.actions';
import { deleteTableObjects } from '../actions/tableObject.actions';
import { addToast } from '../actions/toast.actions';
import { clearCacheRecord } from '../actions/upload.actions';
import {
  selectSession,
  selectSessionCollaborators,
  selectSessionType,
} from '../selectors/session.selector';
import {
  getChartspaceFailure,
  getChartspaceRequest,
  getChartspaceSuccess,
} from '../slices/chartspace.slice';
import { resetContext, stopUpdatingPendingDuration } from '../slices/context.slice';
import {
  getCurrentDcDatasetIdFailure,
  getCurrentDcDatasetIdRequest,
  getCurrentDcDatasetIdSuccess,
  getDataspaceFailure,
  getDataspaceRequest,
  getDataspaceSuccess,
} from '../slices/dataspace.slice';
import { deleteSheetStateBySession } from '../slices/dataspaceTable.slice';
import { openLoadCard } from '../slices/loadCard.slice';
import { getUserProfilePicturesRequest } from '../slices/profilePictures.slice';
import {
  SESSION_TYPES,
  exitSessionFailure,
  exitSessionRequest,
  exitSessionSuccess,
  getSessionNameFailure,
  getSessionNameRequest,
  getSessionNameSuccess,
  isEndingSession,
  onAppClick,
  onAppClickFailure,
  onAppClickSuccess,
  onSessionClick,
  receiveSessionNotificationsFailure,
  receiveSessionNotificationsRequest,
  resetSessionId,
  saveSessionNameFailure,
  saveSessionNameRequest,
  saveSessionNameSuccess,
  setDefaultSessionName,
  setSessionCollaborators,
  startSessionSuccess,
  tryExitSessionRequest,
} from '../slices/session.slice';
import { selectAccessToken, selectEmail, selectUserConfig } from './selectors';
import {
  activeDashboardForbiddenAlert,
  reloadForbiddenAlert,
  sessionConcurrencyLimitAlert,
  sessionNameAlreadyExistsAlert,
  tryExitSessionAlert,
} from './utils/alert-channels';
import { callAPIWithRetry } from './utils/retry';

// Start a New Session
// In a new session,
// the server will do some startup work and eventually send a DONE skill event
// with the message content "Alright, what's next?" (or some variant).
// This indicates that the server is ready to receive an utterance.

// Reload existing session
// For reload, the client fetches data from the Redis Message Store of the corresponding session.
// If the last utterance in the workflow has not been completed,
// the backend will send a DONE skill event which will reset the context.

/**
 * Get and set session name corresponding to sessionId if name exists
 * @param {String} sesionId id of the session
 */
export function* getSessionNameRequestWorker({ payload: action }) {
  const { sessionId } = action;
  try {
    const accessToken = yield select(selectAccessToken);
    if (sessionId) {
      const response = yield call(getSessionInfo, accessToken, sessionId);
      const sessionData = response.data;
      const sessionName =
        sessionData.name.String && sessionData.name.Valid
          ? sessionData.name.String
          : UNNAMED_SESSION;
      return yield put(getSessionNameSuccess({ sessionName, sessionData }));
    }
    throw new Error('Session ID is not defined');
  } catch (error) {
    yield put(getSessionNameFailure({ error }));
    return yield put(setDefaultSessionName());
  }
}

/**
 * Handles app click logic based on the current session type.
 *
 * @param {object} action - ON_APP_CLICK action
 * @param {import('../slices/session.slice').OnAppClickPayload} action.payload
 */
export function* appClickWorker({ payload }) {
  const { sessionType, objectId, objectType, prefix } = payload;

  // Try to spin up a new session
  const accessToken = yield select(selectAccessToken);
  try {
    const { data } = yield call(
      postCreateSession,
      accessToken,
      sessionType,
      objectId,
      objectType,
      prefix,
    );
    yield put(onAppClickSuccess({ data, requestAction: payload }));
  } catch (error) {
    yield put(onAppClickFailure({ error, requestAction: payload }));
  }
}

/**
 * Success side-effects of appClickWorker
 * @param {object} object
 * @param {import('../slices/session.slice').OnAppClickSuccessPayload} object.payload
 * */
export function* appClickSuccessWorker({ payload }) {
  try {
    const { data, requestAction } = payload;
    const { showLoadCard, sessionType } = requestAction;
    // Clear the sessionId for drill through
    yield put(resetSessionId());

    // Clear any messages that may be left in redux store
    yield put(clearScreen());

    const userConfig = yield select(selectUserConfig);
    if (showLoadCard && userConfig.loadCard) {
      yield put(openLoadCard({ showWhileLoading: false }));
    }
    if (sessionType === SESSION_TYPES.CHAT) {
      const path = `${paths.dataChatSession}/${data.sessionId}`;
      yield put(push(path));
    }
  } catch (error) {
    yield put(createAlertChannelRequest({ error }));
  }
}

/**
 * Failure side-effects of appClickWorker
 * @param {Object} action - ON_APP_CLICK_FAILURE action
 */
export function* appClickFailureWorker({ payload: action }) {
  try {
    const { error } = action;
    if (error?.response?.status === CONFLICT) {
      yield* sessionConcurrencyLimitAlert();
    } else {
      yield put(createAlertChannelRequest({ error }));
    }
  } catch (error) {
    yield put(createAlertChannelRequest({ error }));
  }
}

/**
 * Proceed to an active session
 * @param {String} sessionId session id to proceed to
 */
export function* sessionClickWorker({ payload: action }) {
  const { sessionId, sessionType } = action;

  if (sessionType === SESSION_TYPES.CHAT) {
    yield put(clearScreen());
    yield put(push(`${paths.dataChatSession}/${sessionId}`));
  } else if (sessionType === SESSION_TYPES.DASHBOARD) {
    yield* activeDashboardForbiddenAlert();
  } else {
    yield* reloadForbiddenAlert();
  }
}

export function* tryExitSessionWorker({ payload: sessionId }) {
  const isClosing = true;
  const yesActions = [() => exitSessionRequest(sessionId)];
  yield* tryExitSessionAlert(isClosing, yesActions);
}

/**
 * Sends an exit session's request and acknowledges a server response.
 *
 * We do not need to handle errors from this request because
 * the server is responsible for managing the session state
 * between the client and the server.
 */
export function* exitSessionWorker({ payload }) {
  try {
    yield put(isEndingSession({ isEnding: true }));
    const accessToken = yield select(selectAccessToken);
    // By default, kill the "current" session (or in other words the last visited session)
    let sessionId = yield select(selectSession);
    /* eslint-disable */
    if (payload) sessionId = payload;
    const res = yield exitSession(accessToken, sessionId);
    // Refresh session list after any session change
    yield put(getHomeScreenObjectsRequest({ objectType: HOME_OBJECTS.SESSION, refreshing: true }));
    yield race([take(GET_HOME_SCREEN_OBJECTS_FAILURE), take(GET_HOME_SCREEN_OBJECTS_SUCCESS)]);
    // TODO: Drop everything.
    // Session is being exited. Stop all tasks related to a session.
    // Resets the context, stops tracking the time elapsed and stops polling for messages.
    yield all([
      put(clearCacheRecord()),
      put(resetContext()),
      put(stopUpdatingPendingDuration()),
      put(stopPollingMechanism()),
      put(closeExplorer()),
    ]);
    // As utterance history is in global state, we need
    // to explicitly clear it out when exiting a session
    yield put(clearUtteranceHistory());
    // session data use map is in global state. clear it

    yield all([
      put(deleteSheetStateBySession(sessionId)), // clean cached table state in grid mode
      put(deleteTableObjects(sessionId)), // Remove cached table formatting state
      put(exitSessionSuccess(sessionId)),
    ]);
  } catch (error) {
    yield put(exitSessionFailure({ error }));
  } finally {
    yield put(isEndingSession({ isEnding: false }));
  }
}

/**
 * Get collaborators who left and joined the session.
 * @param {Array | undefined} collaborators - The current list of collaborators.
 * @param {Array | undefined} oldCollaborators - The previous list of collaborators.
 * @returns {Object} An object containing two arrays: `leftCollaborators` and `joinedCollaborators`.
 */
export function getCollaboratorChanges(collaborators = [], oldCollaborators = []) {
  const collaboratorsEmail = collaborators.map((collaborator) => collaborator.email);
  const oldCollaboratorsEmail = oldCollaborators.map((collaborator) => collaborator.email);

  const currentCollabSet = new Set(collaboratorsEmail);
  const oldCollabSet = new Set(oldCollaboratorsEmail);

  const leftCollaborators = oldCollaborators.filter((x) => !currentCollabSet.has(x.email));
  const joinedCollaborators = collaborators.filter((x) => !oldCollabSet.has(x.email));

  return {
    leftCollaborators,
    joinedCollaborators,
  };
}

/**
 * Retrieves the session notification by comparing the current collaborators
 * and the previous collaborators. Then, notifications will be sent on who has left
 * the session.
 */
export function* sessionNotificationWorker() {
  try {
    const sessionType = yield select(selectSessionType);
    if (sessionType === SESSION_TYPES.CHAT) {
      const accessToken = yield select(selectAccessToken);
      const session = yield select(selectSession);
      const userEmail = yield select(selectEmail);
      // Retrieve current collaborators
      const response = yield* callAPIWithRetry({
        apiFn: getSessionCollaborators,
        args: [accessToken, session],
      });
      if (response.status === OK) {
        const collaborators = response.data;
        const oldCollaborators = yield select(selectSessionCollaborators);
        // Retrieve collaborators who left the session
        const { leftCollaborators, joinedCollaborators } = getCollaboratorChanges(
          collaborators,
          oldCollaborators,
        );
        // Send notification
        yield all(
          leftCollaborators
            .filter((collaborator) => userEmail !== collaborator.email)
            .map((collaborator) =>
              put(
                addToast({
                  toastType: TOAST_INFO,
                  length: TOAST_SHORT,
                  message: `${collaborator.email} has stopped collaborating on this session`,
                  position: TOAST_BOTTOM_RIGHT,
                }),
              ),
            ),
        );

        // If there are new collaborators, fetch their profile pictures
        if (joinedCollaborators.length > 0) {
          yield put(
            getUserProfilePicturesRequest({ userIds: joinedCollaborators.map((jc) => jc.id) }),
          );
        }

        // Update the collaborators in the redux store
        yield put(setSessionCollaborators(collaborators));
      }
    }
  } catch (error) {
    yield put(createAlertChannelRequest({ error }));
  }
}

/* Save a session name if the session name doesn't already exist.
 */
export function* saveSessionNameWorker({ payload: action }) {
  const { sessionId, sessionName, isNameSystemGenerated, counter = 1 } = action;
  try {
    const accessToken = yield select(selectAccessToken);

    const res = yield getAllUserSessions(accessToken);
    for (var i = 0; i < res.data.length; i++) {
      if (
        res.data[i].name.Valid &&
        sessionName === res.data[i].name.String &&
        sessionId !== res.data[i].sessionID
      ) {
        if (isNameSystemGenerated && counter < 20) {
          // Recursively call with a new session name until we get an acceptable name
          // We will never have more than 20 sessions, so add that backup failure condition
          counter++;
          sessionName =
            counter === 2 ? `${sessionName}_${counter}` : `${sessionName.slice(0, -1)}${counter}`;
          yield* saveSessionNameWorker({ sessionId, sessionName, isNameSystemGenerated, counter });
          return;
        } else {
          // Otherwise, exit with the error that the session name already exists
          yield* sessionNameAlreadyExistsAlert();
          yield put(
            saveSessionNameFailure({
              error: new Error(`The session name ${sessionName} is already used.`),
            }),
          );
          return;
        }
      }
    }

    yield saveSessionName(accessToken, sessionId, sessionName);
    yield put(saveSessionNameSuccess({ sessionName }));
    yield put(getHomeScreenObjectsRequest({ objectType: HOME_OBJECTS.SESSION }));
  } catch (error) {
    yield put(saveSessionNameFailure({ error }));
  }
}

/**
 * Adds every session notification request to the queue and sends it to the server in sequence.
 * This ensures that the session notification requests are received by the server in order.
 */
export function* sessionNotificationQueue() {
  const receiveSessionNotificationRequestAction = yield actionChannel(
    receiveSessionNotificationsRequest.type,
  );
  while (true) {
    const receive = yield take(receiveSessionNotificationRequestAction);
    yield call(
      authenticate(sessionNotificationWorker, receiveSessionNotificationsFailure),
      receive,
    );
  }
}

/**
 * Manages when the session notifications queue is running or resting
 */
export function* sessionNotificationManager() {
  // Start the session notification listener when a session is successfully started.
  // Since we may resume the previous session after refresh, we need to listen to
  // both START_SESSION and RESUME_SESSION to restart the session notification queue
  while (yield race([take(startSessionSuccess.type)])) {
    // Start the session notification listener
    const messageQueue = yield fork(sessionNotificationQueue);
    // Stop listening for session notification requests when a user attempts to start or exit a session
    yield race([take(onAppClick.type), take(exitSessionRequest.type)]);
    yield cancel(messageQueue);
  }
}

// ensure all session name requests are taken in order
// this is to prevent an issue when loading a session, where
// we request a session name and wait for the success/failure
// during the call, we make another request for the session name
// if this second request fails, the session will fail to start correctly.
export function* getSessionNameRequestWatcher() {
  const getSessionNameRequestChannel = yield actionChannel(getSessionNameRequest);
  while (true) {
    const action = yield take(getSessionNameRequestChannel);
    yield call(getSessionNameRequestWorker, action);
  }
}

export function* loadDataChatSessionHelper() {
  yield put(getChartspaceRequest());
  yield race([take(getChartspaceSuccess), take(getChartspaceFailure)]);
  yield put(getDataspaceRequest());
  const { success } = yield race({
    success: take(getDataspaceSuccess),
    failure: take(getDataspaceFailure),
  });
  // get the current dataset id
  yield put(getCurrentDcDatasetIdRequest());
  yield race([take(getCurrentDcDatasetIdSuccess), take(getCurrentDcDatasetIdFailure)]);
  return success;
}

export default function* apiWatcher() {
  yield fork(sessionNotificationManager);
  yield takeLatest(onAppClick.type, appClickWorker);
  yield takeLatest(onAppClickSuccess.type, appClickSuccessWorker);
  yield takeLatest(onAppClickFailure.type, appClickFailureWorker);
  yield takeLatest(onSessionClick.type, sessionClickWorker);
  yield takeLatest(tryExitSessionRequest.type, tryExitSessionWorker);
  yield takeLatest(exitSessionRequest.type, authenticate(exitSessionWorker, exitSessionFailure));
  yield fork(getSessionNameRequestWatcher);
  yield takeLatest(
    saveSessionNameRequest.type,
    authenticate(saveSessionNameWorker, saveSessionNameFailure),
  );
}
