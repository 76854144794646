/**
 * This file manages the actions used for restarting
 * and stopping the polling mechanism
 */
export const RESTART_POLLING_MECHANISM = 'poll/RESTART_POLLING_MECHANISM';
export const STOP_POLLING_MECHANISM = 'poll/STOP_POLLING_MECHANISM';

export const restartPollingMechanism = () => ({
  type: RESTART_POLLING_MECHANISM,
});

export const stopPollingMechanism = () => ({
  type: STOP_POLLING_MECHANISM,
});
