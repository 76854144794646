export const GET_CREDENTIALS_REQUEST = 'credentials/GET_CREDENTIALS_REQUEST';
export const GET_CREDENTIALS_SUCCESS = 'credentials/GET_CREDENTIALS_SUCCESS';
export const GET_CREDENTIALS_FAILURE = 'credentials/GET_CREDENTIALS_FAILURE';

export const CREATE_CREDENTIAL_REQUEST = 'credentials/CREATE_CREDENTIAL_REQUEST';
export const CREATE_CREDENTIAL_SUCCESS = 'credentials/CREATE_CREDENTIAL_SUCCESS';
export const CREATE_CREDENTIAL_FAILURE = 'credentials/CREATE_CREDENTIAL_FAILURE';

export const DELETE_CREDENTIAL_REQUEST = 'credentials/DELETE_CREDENTIAL_REQUEST';
export const DELETE_CREDENTIAL_SUCCESS = 'credentials/DELETE_CREDENTIAL_SUCCESS';
export const DELETE_CREDENTIAL_FAILURE = 'credentials/DELETE_CREDENTIAL_FAILURE';

export const SET_DEFAULT_CREDENTIAL_REQUEST = 'credentials/SET_DEFAULT_CREDENTIAL_REQUEST';
export const SET_DEFAULT_CREDENTIAL_SUCCESS = 'credentials/SET_DEFAULT_CREDENTIAL_SUCCESS';
export const SET_DEFAULT_CREDENTIAL_FAILURE = 'credentials/SET_DEFAULT_CREDENTIAL_FAILURE';

export const GET_CREDENTIAL_BY_ID_REQUEST = 'credentials/GET_CREDENTIAL_BY_ID_REQUEST';
export const GET_CREDENTIAL_BY_ID_SUCCESS = 'credentials/GET_CREDENTIAL_BY_ID_SUCCESS';
export const GET_CREDENTIAL_BY_ID_FAILURE = 'credentials/GET_CREDENTIAL_BY_ID_FAILURE';

export const SET_CREDENTIALS_ACTION = 'credentials/SET_CREDENTIALS_ACTION';

// get credentials request / success / failure
export const getCredentialsRequest = () => ({
  type: GET_CREDENTIALS_REQUEST,
});

export const getCredentialsSuccess = ({ credentials }) => ({
  type: GET_CREDENTIALS_SUCCESS,
  credentials,
});

export const getCredentialsFailure = ({ error }) => ({
  type: GET_CREDENTIALS_FAILURE,
  error,
});

// create credential request / success / failure
export const createCredentialRequest = ({ credentialPayload }) => ({
  type: CREATE_CREDENTIAL_REQUEST,
  credentialPayload,
});

export const createCredentialSuccess = () => ({
  type: CREATE_CREDENTIAL_SUCCESS,
});

export const createCredentialFailure = ({ error }) => ({
  type: CREATE_CREDENTIAL_FAILURE,
  error,
});

// delete credential request / success / failure
export const deleteCredentialRequest = ({ credentialId }) => ({
  type: DELETE_CREDENTIAL_REQUEST,
  credentialId,
});

export const deleteCredentialSuccess = ({ credentialId }) => ({
  type: DELETE_CREDENTIAL_SUCCESS,
  credentialId,
});

export const deleteCredentialFailure = ({ error }) => ({
  type: DELETE_CREDENTIAL_FAILURE,
  error,
});

// get credential by ID request / success / failure
export const getCredentialByIdRequest = ({ credentialId }) => ({
  type: GET_CREDENTIALS_REQUEST,
  credentialId,
});

export const getCredentialByIdSuccess = ({ credential }) => ({
  type: GET_CREDENTIALS_SUCCESS,
  credential,
});

export const getCredentialByIdFailure = ({ error }) => ({
  type: GET_CREDENTIALS_FAILURE,
  error,
});

// Sets the current credential action and the ID of the object you are performing
// the action on. When not null, the CredentialsAction component will be rendered
// if null, it will not be rendered.
export const setCredentialsAction = (
  { actionType, objectId } = { actionType: null, objectId: null },
) => ({
  type: SET_CREDENTIALS_ACTION,
  actionType,
  objectId,
});

// set default credential request / success / failure
// if remove default is true, the is_default flag will be set to false for the credential id.
export const setDefaultCredentialRequest = ({ credentialId, removeDefault }) => ({
  type: SET_DEFAULT_CREDENTIAL_REQUEST,
  credentialId,
  removeDefault,
});

export const setDefaultCredentialSuccess = () => ({
  type: SET_DEFAULT_CREDENTIAL_SUCCESS,
});

export const setDefaultCredentialFailure = ({ error }) => ({
  type: SET_DEFAULT_CREDENTIAL_FAILURE,
  error,
});
