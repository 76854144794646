import Grid from '@mui/material/Grid';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import React from 'react';
import { registerPlugin } from 'react-filepond';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import '../../components/ConnectionEditor/ConnectionEditor.scss';
import {
  CONNECTION_DATABASES_TYPES,
  CONNECTION_NAME,
  DISCARD_CONNECTION_FIELD_IDS,
  INPUT_FIELD_INFO_MAP,
} from '../../constants/connection';
import { BigQueryConnectionFields } from '../../constants/form/bigquery';
import { HOME_OBJECTS, HOME_OBJECT_KEYS } from '../../constants/home_screen';
import { WORKSPACE_ACCESS_TYPES } from '../../constants/workspace';
import ConnectionFileInput from './Inputs/ConnectionFileInput';
import CustomTextField, { getTFInfoProps } from './Inputs/CustomTextField';
import OAuthToggle from './Inputs/OAuthToggle';
import QueryInput from './Inputs/QueryInput';

registerPlugin(
  FilePondPluginFileEncode,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize,
);

/** The different input types in our connection forms */
export const FORM_INPUT_TYPES = {
  TEXT: 'text',
  NUMBER: 'number',
  PASSWORD: 'password',
  FILE: 'file',
  CHECKBOX: 'checkbox',
  OAuthToggle: 'OAuthToggle',
  URL: 'url',
};

/** Database fields for connection types */
export const DATABASE_CONNECTION_FIELDS = {
  DATASETS: {
    id: 'datasets',
    label: 'Datasets',
    value: [],
    type: FORM_INPUT_TYPES.TEXT,
  },
};

/** Yup Validation Schemas for connections */
export const YUP_VALIDATION_SCHEMAS = {
  DATASETS: Yup.array().of(
    Yup.object().shape({
      datasetName: Yup.string()
        .max(20, 'Dataset Name must be at most 20 charecters')
        .required('Required'),
      query: Yup.string().required('Required'),
    }),
  ),
};

export const getInputInfo = (field, databaseType) => {
  const databaseMap = INPUT_FIELD_INFO_MAP[databaseType];
  const info = databaseMap?.[field.id];
  // Remove all the html tags from the string
  if (info) return info.replace(/(<([^>]+)>)/gi, '').trim();
  return '';
};

export const addConnectionObjectKeys = (connection) => {
  return {
    ...connection,
    [HOME_OBJECT_KEYS.ID]: connection.ObjectFk,
    [HOME_OBJECT_KEYS.TABLE_ID]: `${HOME_OBJECTS.CONNECTION}-${connection.ObjectFk}`,
    [HOME_OBJECT_KEYS.UUID]: connection.Uuid,
    [HOME_OBJECT_KEYS.ACCESS_TYPE]: connection.AccessType,
    [HOME_OBJECT_KEYS.NAME]: connection.Name,
    [HOME_OBJECT_KEYS.TYPE]: HOME_OBJECTS.CONNECTION,
    [HOME_OBJECT_KEYS.OWNER_NAME]: connection.OwnerName,
    [HOME_OBJECT_KEYS.OWNER_EMAIL]: connection.OwnerEmail,
    [HOME_OBJECT_KEYS.OWNER_ID]: connection.Owner,
    [HOME_OBJECT_KEYS.IS_SHARED]: connection.AccessType !== WORKSPACE_ACCESS_TYPES.OWNER,
    [HOME_OBJECT_KEYS.CREATED]: new Date(connection.CreateInstant),
    [HOME_OBJECT_KEYS.LAST_ACTIVE]: new Date(connection.AccessInstant),
    [HOME_OBJECT_KEYS.LAST_MODIFIED]: new Date(connection.UpdateInstant),
    [HOME_OBJECT_KEYS.VISIBILITY]: connection.Visibility,
    [HOME_OBJECT_KEYS.HAS_INDEPENDENT_ACCESS]: connection.HasIndependentAccess,
  };
};

/** Using a yup schema, we check to see if the given field is required */
export const isRequiredField = (schema, id) => {
  return schema?.fields[id]?._exclusive?.required || false;
};

// Returns Input field component wrapped with Grid item component
export const getDbInputField = (formik, field, databaseType, validationSchema) => {
  const { values, handleBlur, setFieldValue, handleChange, touched, errors } = formik;

  // Return null if field is Database Type and Read Only fields
  if (DISCARD_CONNECTION_FIELD_IDS.has(field.id)) {
    return null;
  }

  // If field.id is datasets, render field array component with datasetName and Query
  if (field.id === 'datasets') {
    return <QueryInput key={field.id} formik={formik} field={field} />;
  }
  switch (field.type) {
    case FORM_INPUT_TYPES.FILE: {
      return (
        <ConnectionFileInput
          key={field.id}
          field={field}
          formik={formik}
          validationSchema={validationSchema}
          toolTipInfo={getInputInfo(field, databaseType)}
        />
      );
    }
    case FORM_INPUT_TYPES.OAuthToggle: {
      return <OAuthToggle key={field.id} field={field} formik={formik} />;
    }
    default:
      if (field.hideIfFn && field.hideIfFn(values)) {
        return null;
      }
      return (
        <Grid key={field.id} item xs={12} sm={6}>
          <CustomTextField
            data-testid={`db-input-field-${field.id}`}
            data-cy={`ce-tf-${field.id}`}
            fullWidth
            variant="outlined"
            id={field.id}
            required={isRequiredField(validationSchema, field.id)}
            label={field.label}
            type={field.type}
            value={values[field.id] || ''}
            autoComplete={field?.autocomplete}
            error={touched[field.id] && Boolean(errors[field.id])}
            helperText={touched[field.id] && errors[field.id]}
            InputProps={getTFInfoProps(field, databaseType)}
            onChange={(e) => {
              const { value } = e.currentTarget;
              handleChange(e);
              setFieldValue(field.id, value.toString());
            }}
            onBlur={handleBlur}
          />
        </Grid>
      );
  }
};

export const cleanConnectionValues = (values) => {
  const params = { ...values };

  params.credentialFile =
    params.credentialFile?.trim().length > 0 && !params?.useOAuth
      ? params.credentialFile
      : undefined;

  // Remove id field from each dataset field
  if (params?.datasets?.length > 0) {
    params.datasets = params.datasets.map((dataset) => ({
      datasetName: dataset.datasetName,
      query: dataset.query,
    }));
  } else {
    params.datasets = [];
  }

  // If connection is read-only, remove datachat workspace
  if (params.datachatWorkspace && params.readOnly) {
    params.datachatWorkspace = '';
  }

  // if the database type is BigQuery, set database name to dataset name
  if (params.databaseType === CONNECTION_DATABASES_TYPES.BIGQUERY) {
    // Remove leading and trailing spaces
    params.databaseName = params.datasetName;
  }

  return params;
};

export const refactorConnectionValues = (values) => {
  const params = { ...values };

  // Adding ID to each dataset field. Had to add ID here to remove react keys warning
  if (params?.datasets?.length > 0) {
    params.datasets = params.datasets.map((dataset) => ({
      id: uuidv4(),
      datasetName: dataset.datasetName,
      query: dataset.query,
    }));
  } else {
    params.datasets = [];
  }

  // Convert databaseName and stagingDatabaseName to datasetName and stagingDatasetName
  if (params.databaseType === CONNECTION_DATABASES_TYPES.BIGQUERY) {
    params.datasetName = params.databaseName;
    params.stagingDatasetName = params.stagingDatabaseName;
    delete params.databaseName;
    delete params.stagingDatabaseName;
  }

  return params;
};

/*
  Note: The keys for the payload defined here should match the conn_spec keys generated
  by function get_connection_detail in conn_util.py to perform testing connection accurately
*/
export const refactorKeysForDbTest = (databaseType, values) => {
  // Clean connection values before testing
  const cleanValues = { ...cleanConnectionValues(values) };

  const getFieldValue = (key) => {
    return cleanValues?.[key] || '';
  };

  const payload = {};

  switch (databaseType) {
    case CONNECTION_DATABASES_TYPES.SNOWFLAKE:
      payload.Username = getFieldValue('username');
      payload.Password = getFieldValue('password');
      payload.DataBaseName = getFieldValue('databaseName');
      payload.HostName = getFieldValue('hostname');
      payload.ComputeWarehouse = getFieldValue('computeWarehouse');
      payload.DataChatWorkspace = getFieldValue('datachatWorkspace');
      break;

    case CONNECTION_DATABASES_TYPES.BIGQUERY:
      payload.ProjectName = getFieldValue(BigQueryConnectionFields.projectName);

      // connection payload's database name is BigQuery connection's dataset name
      payload.DatabaseName = getFieldValue(BigQueryConnectionFields.datasetName);

      payload.EncodedCredentialsFile = getFieldValue(BigQueryConnectionFields.credentialFile);
      break;

    case CONNECTION_DATABASES_TYPES.CONNECTIONSTRING:
      payload.ConnectionName = getFieldValue('connectionName');
      payload.ConnectionString = getFieldValue('connString');
      break;

    case CONNECTION_DATABASES_TYPES.PRESTO:
      payload.url = getFieldValue('url');
      payload.port = getFieldValue('port');
      payload.catalog = getFieldValue('catalog');
      payload.schema = getFieldValue('schema');
      payload.datasets = getFieldValue('datasets');
      break;

    case CONNECTION_DATABASES_TYPES.DATABRICKS:
      payload.AccessToken = getFieldValue('accessToken');
      payload.HostName = getFieldValue('hostname');
      payload.Catalog = getFieldValue('catalog');
      payload.PortNumber = getFieldValue('portNumber');
      payload.DataBaseName = getFieldValue('databaseName');
      payload.HttpPath = getFieldValue('httpPath');
      break;

    default:
      // For MicrosoftSQLServer, MySql, Postgres, Cockroach, Redshift
      payload.Username = getFieldValue('username');
      payload.Password = getFieldValue('password');
      payload.DataBaseName = getFieldValue('databaseName');
      payload.HostName = getFieldValue('hostname');
      payload.PortNumber = getFieldValue('portNumber');
      break;
  }

  if (
    !payload.Password &&
    !payload.EncodedCredentialsFile &&
    !payload.EncodedExternalCredentialsFile &&
    !payload.AccessToken &&
    !payload.ConnectionString
  ) {
    payload.CredentialID = getFieldValue('credentialID');
  }
  const useOAuth = getFieldValue('useOAuth') ?? false;

  return {
    name: cleanValues[CONNECTION_NAME],
    payload,
    type: databaseType,
    read_only: cleanValues.readOnly,
    test: true,
    useOAuth,
  };
};

/**
  Handles exceptions while testing connections
  @param {Error} error // Error response from the web backend
  @param {Object} payload // Connection Payload
  @returns {string} errorMessage
*/
export const getTestConnectionErrorMessage = (error, payload) => {
  const { data: errorData } = error?.response ?? {};
  const { databaseType } = payload ?? {};
  let errorMessage = 'Please ensure your credentials are valid.';

  if (databaseType === CONNECTION_DATABASES_TYPES.SNOWFLAKE) {
    if (errorData.includes('READ FROM DATABASE')) {
      errorMessage = 'Incorrect credentials or your account must be granted read permissions.';
    } else if (errorData.includes('CREATE DATABASE')) {
      errorMessage =
        'Your account should have permission to create databases and workspace databases manually or use a read-only account.';
    } else if (errorData.includes('DC DATABASE')) {
      errorMessage = 'Make sure your account can access the workspace or use a read-only account.';
    }
  } else if (databaseType === CONNECTION_DATABASES_TYPES.BIGQUERY) {
    if (errorData.includes('Incorrect project name')) {
      errorMessage = 'Please ensure your project and dataset names are valid.';
    } else if (errorData.includes('Incorrect dataset name')) {
      errorMessage = 'Please ensure your dataset name is valid.';
    }
  }
  return errorMessage;
};
