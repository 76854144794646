import { call, getContext, put, select, takeLatest } from 'redux-saga/effects';
import { getConfig } from '../../api/config.api';
import { API_SERVICES } from '../../constants/api';
import { loadCoralogixRum } from '../../utils/coralogixRum';
import { loadGoogleTagManagerTrackingScript } from '../../utils/googleTagManager';
import { loadHubspotTrackingScript, setHubspotIdentity } from '../../utils/hubspot';
import {
  configFailure,
  configRequest,
  configSuccess,
  loadStripeFailure,
  loadStripeSuccess,
} from '../reducers/config.reducer';
import {
  selectAccessToken,
  selectCoralogixEnv,
  selectCoralogixRumEnabled,
  selectCoralogixVersion,
  selectEmail,
  selectGoogleTagManagerTracking,
  selectHubspotTracking,
  selectPaymentSystemEnabled,
} from './selectors';

// Get config from the server. This should be called when App mounts.
// Configuration refers to deployment-wide settings for web app.
function* configRequestWorker() {
  try {
    const config = yield call(getConfig);
    yield put(
      configSuccess({
        changePasswordPopup: config.data.change_password_popup,
        deploymentMode: config.data.deployment_mode,
        paymentEnabled: config.data.payment_enabled,
        showMyAccountMenuOption: config.data.show_my_account_menu_option,
        autoConnectToDb: config.data.auto_connect_to_db,
        allowedDatabases: config.data.allowed_databases,
        allowMultipleConnections: config.data.allow_multiple_connections,
        loginUsername: config.data.login_username,
        authBackendName: config.data.auth_backend_name,
        helpLink: config.data.help_link,
        supportCalendarLink: config.data.support_calendar_link,
        docSearchAPIKey: config.data.doc_search_api_key,
        canAccessAlgolia: config.data.can_access_algolia,
        hubspotTracking: config.data.hubspot_tracking,
        googleTagManagerTracking: config.data.google_tag_manager_tracking,
        askEnabled: config.data.ask_enabled,
        googleClientID: config.data.google_client_id,
        coralogixRumEnabled: config.data.coralogix_rum_enabled,
        coralogixVersion: config.data.coralogix_version,
        coralogixEnv: config.data.coralogix_env,
        hubspotRegistrationLink: config.data.hubspot_registration_link,
        auth0ClientId: config.data.auth0_client_id,
        auth0Domain: config.data.auth0_domain,
        snowflakeOAuthClientID: config.data.snowflake_oauth_client_id,
        snowflakeOAuthAccountName: config.data.snowflake_oauth_account_name,
      }),
    );
  } catch (err) {
    yield put(configFailure(err));
  }
}

// Get the stripe object if the payment system is enabled.
// if payment system is not enabled, return null for object.
export function* loadStripeRequestWorker() {
  try {
    const accessToken = yield select(selectAccessToken);
    const paymentEnabled = yield select(selectPaymentSystemEnabled);
    const { loadStripe } = yield getContext(API_SERVICES.EXTERNAL_STRIPE);
    yield call(loadStripe.setLoadParameters, { advancedFraudSignals: paymentEnabled });
    let stripe;
    if (paymentEnabled) {
      const { getPaymentConfig } = yield getContext(API_SERVICES.CONFIG);
      const response = yield call(getPaymentConfig, accessToken);
      const { publishableKey } = response.data;
      stripe = yield call(loadStripe, publishableKey);
    } else {
      stripe = null;
    }
    yield put(loadStripeSuccess(stripe));
  } catch (error) {
    yield put(loadStripeFailure(error));
  }
}

// when the config request is completed, load the hubspot tracking script
// if a user is logged in (their auth info is persisted in the local storage),
// set the hubspot identity to match their email - This accounts for users that
// are logged in, but not a contact in hubspot when we turn this on
function* loadHubspotTrackingScriptWorker() {
  const hubspotTracking = yield select(selectHubspotTracking);
  if (hubspotTracking) {
    const email = yield select(selectEmail);
    if (email) {
      // if email exists in redux (user is logged in), then set their identity
      yield call(setHubspotIdentity, email);
    }
    // load the hubspot tracking script
    yield call(loadHubspotTrackingScript);
  }
}

// when the config request is completed, load the Google Tag Manager tracking script
function* loadGoogleTagManagerTrackingScriptWorker() {
  const googleTagManagerTracking = yield select(selectGoogleTagManagerTracking);
  if (googleTagManagerTracking) {
    yield call(loadGoogleTagManagerTrackingScript);
  }
}

function* loadCoralogixRumWorker() {
  const cxRumEnabled = yield select(selectCoralogixRumEnabled);
  if (cxRumEnabled) {
    const cxEnv = yield select(selectCoralogixEnv);
    const cxVersion = yield select(selectCoralogixVersion);

    if (cxEnv && cxVersion) {
      yield call(loadCoralogixRum, cxEnv, cxVersion);
    }
  }
}

export default function* () {
  yield takeLatest(configRequest.type, configRequestWorker);
  yield takeLatest(configSuccess.type, loadStripeRequestWorker);
  yield takeLatest(configSuccess.type, loadHubspotTrackingScriptWorker);
  yield takeLatest(configSuccess.type, loadGoogleTagManagerTrackingScriptWorker);
  yield takeLatest(configSuccess.type, loadCoralogixRumWorker);
}
