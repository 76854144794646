import ArrowBackIosNew from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { FEATURE_IMPORTANCE, VISUAL_TYPES } from '../../../../../constants';
import { AVA_IDS, CAROUSEL_SUPPORTED_TYPES } from '../../../../../constants/dataAssistant';
import { ScreenSizeContext } from '../../../../DataAssistant/DataAssistant';
import './CarouselMessage.scss';
import ChartMessage from './ChartMessage';
import TextMessage from './TextMessage';

/**
 *
 * @param {func} setSelectedChart sets state in parent component to track selected chart
 * @param {array} charts array of charts to render. each item in array is also an array where
 * the first item is the chart name and the second item is the chart data
 * @param {string} id id of the message
 * @param {bool} inView whether or not the message is in view
 * @param {object} tabvisualSummary summary of the visualizations used to answer the question
 * @param {bool} showTypeEffect whether or not to show the typing effect
 */

const CarouselMessage = ({
  setSelectedChart,
  charts,
  id,
  objectId,
  inView,
  tabvisualSummary,
  showTypeEffect,
}) => {
  const isSmallScreen = useContext(ScreenSizeContext);

  const filteredCharts = charts.filter((chart) => {
    return CAROUSEL_SUPPORTED_TYPES.includes(chart[1].type);
  });
  // move feature importance to the front of the array
  const featureImportanceIndex = filteredCharts.findIndex(
    (chart) => chart[0] === FEATURE_IMPORTANCE,
  );
  if (featureImportanceIndex !== -1) {
    const foundElement = filteredCharts.splice(featureImportanceIndex, 1);
    filteredCharts.unshift(foundElement[0]);
  }
  const [selectedItem, setSelectedItem] = useState(0);

  useEffect(() => {
    if (filteredCharts.length === 0) return;
    setSelectedChart(filteredCharts[0][0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (filteredCharts.length === 0) return null;

  const renderSummary = () => {
    const chartName = filteredCharts[selectedItem][0];
    if (!tabvisualSummary || !tabvisualSummary[chartName]) {
      return null;
    }

    return (
      <TextMessage
        data={`To create this visualization, ${tabvisualSummary[chartName].step_summary}`}
        id={`tabvisual-summary-${chartName}`}
        key={`tabvisual-summary-${chartName}`}
        showTypeEffect={showTypeEffect}
      />
    );
  };

  /**
   * Helper to render the chart data.
   * @param {Array} chart first item is the chart name, second item is the chart data
   * @returns Element to render
   */
  const renderData = (chart, index) => {
    const chartData = chart[1];
    switch (chartData.type) {
      case VISUAL_TYPES.VIZ: {
        return (
          <ChartMessage
            key={`carousel-${id}-${index}`}
            externalName={chartData.external_name}
            id={`${id}-${index}`}
            message={{ chart: chartData }}
            objectId={chartData.additional_info?.dc_chart_id || objectId}
            inView={inView}
            update={chartData.update}
            parentObjectId={objectId}
          />
        );
      }
      default:
        // currently only supports viz
        return null;
    }
  };

  /**
   * Helper for changing the selected chart.
   * @param {Number} index
   */
  const onChangeHandler = (index) => {
    if (selectedItem !== index) {
      setSelectedItem(index);
      // set the chart in the partent component
      setSelectedChart(filteredCharts[index][0]);
    }
  };

  return (
    <div
      className="CarouselMessageWrapper"
      key={AVA_IDS.MSG_CAROUSEL_WRAPPER(id)}
      data-testid={AVA_IDS.MSG_CAROUSEL_WRAPPER(id)}
    >
      <Paper
        elevation={0}
        className={classNames('CarouselMessage', { SmallScreenSize: isSmallScreen })}
      >
        {renderSummary()}
        <Carousel
          autoPlay={false}
          infiniteLoop={false}
          onChange={onChangeHandler}
          selectedItem={selectedItem}
          showStatus={false}
          showThumbs={false}
          renderArrowPrev={(onClickHandler) => (
            <IconButton
              className="CarouselMessage-NavButton Prev"
              data-testid={AVA_IDS.MSG_CAROUSEL_PREV(id)}
              onClick={onClickHandler}
              size="small"
            >
              <ArrowBackIosNew fontSize="inherit" />
            </IconButton>
          )}
          renderArrowNext={(onClickHandler) => (
            <IconButton
              className="CarouselMessage-NavButton Next"
              data-testid={AVA_IDS.MSG_CAROUSEL_NEXT(id)}
              onClick={onClickHandler}
              size="small"
            >
              <ArrowForwardIos fontSize="inherit" />
            </IconButton>
          )}
          renderItem={(item, { isSelected }) => (isSelected ? item : null)}
        >
          {filteredCharts.map((chart, index) => renderData(chart, index))}
        </Carousel>
      </Paper>
    </div>
  );
};

CarouselMessage.propTypes = {
  setSelectedChart: PropTypes.func.isRequired,
  tabvisualSummary: PropTypes.object,
  charts: PropTypes.array.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  inView: PropTypes.bool,
  showTypeEffect: PropTypes.bool,
  objectId: PropTypes.string.isRequired,
};

CarouselMessage.defaultProps = {
  tabvisualSummary: null,
  inView: true,
  showTypeEffect: false,
};

export default CarouselMessage;
