import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { ECHARTS_HYBRID_TYPE } from 'translate_dc_to_echart';
import { CONTEXTS } from '../../../constants';
import { isWorkflowEditorPage } from '../../../constants/paths';
import { EMPTY_COLUMN } from '../../../pages/authenticated/constants';
import { selectCurrentLocation, selectGroupedTableObjects } from '../../../store/sagas/selectors';
import {
  selectContext,
  selectIsReplaying,
  selectIsStepwise,
} from '../../../store/selectors/context.selector';
import { capitalize } from '../../../utils/case';
import DatasetTextbox from '../../BenDatasetName/DatasetTextbox';
import DatasetTextboxContent from '../../BenDatasetName/DatasetTextboxContent';
import { TYPES_TO_NAMES } from '../constants';

/**
 * TODO: better to be furthur refactor in header refactor project
 *
 * A functional component to render either
 * text or editable text box for visual names,
 * and row modify input for table
 */
const HeaderInfo = (props) => {
  const {
    messages,
    isWorkflowEditor,
    context,
    messageType,
    data,
    externalName,
    isInsightsBoard,
    objectId,
    tableTitle,
    updateTableTitle,
    setTextboxLoading,
    tableObjects,
    forgotten,
  } = props;

  // Backwards compatibility fix
  const dataTitle = data.title && !data.title.startsWith('Sample for') ? data.title : data.name;

  /**
   * Decides the version of the chart
   */
  const getDatasetVersion = () => {
    let datasetVersion;
    switch (messageType) {
      case 'table': {
        if (data.name && data.title === data.name) {
          datasetVersion = data.version;
        }
        break;
      }
      default:
        datasetVersion = 0;
    }
    if (datasetVersion) {
      return `v${datasetVersion}`;
    }
    return '';
  };

  const getColumnInfo = () => {
    const id = objectId || `${data.name}_${data.version}`;
    const visibilityModel = tableObjects?.[id]?.columnsState?.columns?.columnVisibilityModel;
    if (visibilityModel?.[EMPTY_COLUMN] === true) {
      return 'All columns are hidden';
    }
    return '';
  };

  /**
   * When a table is collapsed, the trimmed utterance will be displayed
   * in the chart header. This is so the user knows how the table was
   * generated.
   */
  const getTrimmedUtterance = () => {
    let utterance = '';
    // Go through the messages and find the corresponding utterance
    messages.forEach((message) => {
      if (typeof message.icon === 'string' && message.icon === 'table') {
        if (
          message.chart.objectId === objectId &&
          typeof message.userTextMessage.data !== 'undefined'
        ) {
          utterance = message.userTextMessage.data;
        }
      }
    });

    // Remove the strong tags from the utterance and trim it
    utterance = utterance.replace(/<strong>|<\/strong>/g, '');
    const words = utterance.split(' ');

    if (utterance !== '') {
      return `(produced by ${words[0].toLowerCase()}) ·`;
    }
    return '';
  };

  /**
   * Decides the name of the chart
   */
  const getChartName = () => {
    let chartName;
    switch (messageType) {
      case 'viz':
        if (data.typeVersion === 2) {
          if (data.plot.series.some((ser) => ser.type === 'single_metric')) {
            return '';
          }
          const isHybridPlot = data.plot?.type === ECHARTS_HYBRID_TYPE;
          chartName = isHybridPlot
            ? capitalize(ECHARTS_HYBRID_TYPE)
            : capitalize(data.plot.series[0].type.split('_')[0]);
          if (chartName in TYPES_TO_NAMES) {
            chartName = TYPES_TO_NAMES[chartName];
          }
        } else if (data.chartType) {
          chartName = capitalize(data.chartType);
          if (chartName in TYPES_TO_NAMES) {
            chartName = TYPES_TO_NAMES[chartName];
          }
        }
        break;
      case 'pivot table':
        chartName = data.name;
        break;
      case 'table': {
        // Special header for dataset name
        // Tables cannot be renamed in insights boards and workflow editor
        chartName =
          !isInsightsBoard && !isWorkflowEditor ? (
            <Tooltip key="doubleClick" title="Double-click to edit">
              <div>
                <DatasetTextbox
                  name={dataTitle}
                  disabled={context !== CONTEXTS.REST}
                  version={data.version}
                  datasetTextboxLoadingChange={(e) => setTextboxLoading(e)}
                />
              </div>
            </Tooltip>
          ) : isInsightsBoard ? (
            <DatasetTextboxContent
              name={tableTitle}
              disabled={false}
              datasetTextboxLoadingChange={(e) => setTextboxLoading(e)}
              saveNameCallback={updateTableTitle}
              isInsightsBoard={isInsightsBoard}
            />
          ) : (
            <div>{dataTitle}</div>
          );

        break;
      }
      default:
        chartName = '';
    }
    return chartName;
  };

  return (
    <span
      key={`header-info-${objectId}`}
      className="ChartHeader-Info"
      title={externalName ?? undefined}
    >
      <span className="ChartHeader-Title" onClick={(e) => e.stopPropagation()}>
        {getChartName()}
      </span>
      {isWorkflowEditor && (
        <span className="ChartHeader-Version" onClick={(e) => e.stopPropagation()}>
          {getDatasetVersion()}
        </span>
      )}
      {messageType === 'viz' && data.layout && (
        <div className="ChartHeader-RowInfo">{data.layout.title}</div>
      )}
      {messageType === 'table' && forgotten ? (
        <span className="ChartHeader-Forgotten">Forgotten</span>
      ) : (
        <>
          {messageType === 'table' && (
            <div className="ChartHeader-RowInfo">
              {getChartName() ? `${getTrimmedUtterance()}` : ''}
              &nbsp;&nbsp;
            </div>
          )}
          {messageType === 'table' && getColumnInfo() && (
            <div className="ChartHeader-ColumnInfo">{getColumnInfo()}</div>
          )}
        </>
      )}
      {(messageType === 'note' || messageType === 'annotation') && (
        <div className="ChartHeader-Info" style={{ paddingLeft: 0 }}>
          <div className="ChartHeader-Title">Note</div>
          <div className="ChartHeader-NoteContent">
            {`says: ${data.text.length > 25 ? `${data.text.substring(0, 25)} ...` : data.text}`}
          </div>
        </div>
      )}
    </span>
  );
};

HeaderInfo.propTypes = {
  objectId: PropTypes.string,
  messages: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  messageType: PropTypes.string.isRequired,
  externalName: PropTypes.string,
  isInsightsBoard: PropTypes.bool,
  isWorkflowEditor: PropTypes.bool.isRequired,
  context: PropTypes.string.isRequired,
  tableTitle: PropTypes.string,
  updateTableTitle: PropTypes.func,
  setTextboxLoading: PropTypes.func,
  tableObjects: PropTypes.object,
  forgotten: PropTypes.bool,
};

HeaderInfo.defaultProps = {
  objectId: undefined,
  isInsightsBoard: false,
  externalName: '',
  tableObjects: undefined,
  tableTitle: undefined,
  updateTableTitle: () => {},
  setTextboxLoading: () => {},
  forgotten: false,
};

const mapStateToProps = (state) => ({
  messages: state.messages,
  context: selectContext(state),
  isReplaying: selectIsReplaying(state),
  isStepwise: selectIsStepwise(state),
  isWorkflowEditor: isWorkflowEditorPage(selectCurrentLocation(state)),
  sessionID: state.session.session,
  tableObjects: selectGroupedTableObjects(state),
});

export default connect(mapStateToProps, {})(HeaderInfo);
