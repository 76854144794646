import * as Yup from 'yup';
import {
  DATABASE_CONNECTION_FIELDS,
  FORM_INPUT_TYPES,
  YUP_VALIDATION_SCHEMAS,
} from '../../utils/connection/connection';

import { createDatabaseType } from './__helpers__';

const NAME = 'Databricks';

export default {
  meta: {
    name: NAME,
  },
  body: {
    validationSchema: Yup.object().shape({
      databaseType: Yup.string().required('Required'),
      databaseName: Yup.string().required('Required'),
      catalog: Yup.string().required('Required'),
      hostname: Yup.string().required('Required'),
      portNumber: Yup.string().required('Required'),
      httpPath: Yup.string().required('Required'),
      accessToken: Yup.string().required('Required'),
      datasets: YUP_VALIDATION_SCHEMAS.DATASETS,
      readOnly: Yup.boolean().required('Required'),
    }),
    fields: [
      { ...createDatabaseType, value: NAME },
      {
        id: 'databaseName',
        label: 'Database',
        value: '',
        type: FORM_INPUT_TYPES.TEXT,
      },
      {
        id: 'catalog',
        label: 'Catalog',
        value: 'hive_metastore',
        type: FORM_INPUT_TYPES.TEXT,
      },
      {
        id: 'hostname',
        label: 'Hostname',
        value: '',
        type: FORM_INPUT_TYPES.TEXT,
      },
      {
        id: 'portNumber',
        label: 'Port Number',
        value: '443',
        type: FORM_INPUT_TYPES.NUMBER,
      },
      {
        id: 'httpPath',
        label: 'HTTP Path',
        value: '',
        type: FORM_INPUT_TYPES.TEXT,
      },
      {
        id: 'accessToken',
        label: 'Access Token',
        value: '',
        autocomplete: 'new-password',
        fieldType: 'password',
        type: FORM_INPUT_TYPES.PASSWORD,
      },
      DATABASE_CONNECTION_FIELDS.DATASETS,
      {
        id: 'readOnly',
        label: 'readOnly',
        value: false,
        isRequired: true,
        type: FORM_INPUT_TYPES.CHECKBOX,
      },
    ],
  },
};
