type ClientIdSecretPair = {
  clientId: string | null;
  secret: string | null;
};

/**
 * If accessToken is not provided, it means that the user is not logged-in
 * Instead, we will use the query params to get the client_id and secret
 * to make the request
 * @returns {ClientIdSecretPair}
 */
export const getClientIdSecretPair = (): ClientIdSecretPair => {
  const query = new URLSearchParams(window.location.search);
  return { clientId: query.get('client_id'), secret: query.get('secret') };
};
