import { GATEWAY_TIMEOUT, INTERNAL_SERVER_ERROR } from 'http-status-codes';
import isEmpty from 'lodash/isEmpty';
import { call, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  deleteDatabaseConnection,
  getConnection,
  postCopyDBConnection,
  postDatabaseConnection,
  putDatabaseConnection,
  testAllDbConnections,
} from '../../api/settings.api';
import { listWorkspaceByUuid } from '../../api/workspacev2.api';
import { CONNECTION_NAME } from '../../constants/connection';
import {
  CANCEL_BUTTON_KEY,
  CONFIRM_BUTTON_KEY,
  confirmDeleteObjectAlert,
  confirmDeleteObjectSharedAlert,
} from '../../constants/dialog.constants';
import { HOME_OBJECTS, HOME_OBJECT_KEYS } from '../../constants/home_screen';
import {
  TOAST_ERROR,
  TOAST_SHORT,
  getErrorToastConfig,
  getSuccessToastConfig,
} from '../../constants/toast';
import { WORKSPACE_ACCESS_TYPES } from '../../constants/workspace';
import { authenticate } from '../../utils/authenticate';
import {
  addConnectionObjectKeys,
  getTestConnectionErrorMessage,
  refactorKeysForDbTest,
} from '../../utils/connection/connection';
import { HomeObjectKeys } from '../../utils/homeScreen/types';
import {
  CLOSE_CONNECTION_EDITOR,
  DELETE_CONNECTION_REQUEST,
  GET_CONNECTION_OBJECT_DATA_REQUEST,
  OPEN_CONNECTION_EDITOR_REQUEST,
  TEST_CONNECTION_REQUEST,
  UPDATE_CONNECTION_REQUEST,
  UPDATE_CONNECTION_SUCCESS,
  clearConnection,
  closeConnectionEditor,
  deleteConnectionFailure,
  deleteConnectionSuccess,
  getConnectionObjectDataFailure,
  getConnectionObjectDataSuccess,
  openConnectionEditorFailure,
  openConnectionEditorSuccess,
  setSelectedDb,
  testConnectionFailure,
  testConnectionSuccess,
  updateConnectionFailure,
  updateConnectionSuccess,
} from '../actions/connection.actions';
import { closeDialog } from '../actions/dialog.actions';
import { getHomeScreenObjectsRequest } from '../actions/home_screen.actions';
import { addToast, dismissAllToasts } from '../actions/toast.actions';
import { selectDatabaseBrowserHideNavigation } from '../selectors/dbBrowser.selector';
import {
  connectionListFailure,
  connectionListRequest,
  connectionListSuccess,
} from '../slices/dbBrowser.slice';
import { isObjectShared } from './home_screen.saga';
import { selectAccessToken, selectConnectionObject } from './selectors';
import { createAlertChannel } from './utils/alert-channels';

/**
  Worker to open a new or existing connection editor
  @param {String} uuid   // Connection Object uuid
  @param {Object} object  // Connection Object
*/
export function* openConnectionEditorRequestWorker({ uuid, object }) {
  yield put(openConnectionEditorSuccess({ uuid, object }));
}

/**
  Worker to open a new or existing connection editor
  @param {Object} object  // Connection Object
*/
export function* getConnectionObjectDataRequestWorker({ uuid }) {
  try {
    const accessToken = yield select(selectAccessToken);
    let connectionObject = yield select(selectConnectionObject);
    if (isEmpty(connectionObject)) {
      // Fetch the connection object
      const connectionObjectResponse = yield call(listWorkspaceByUuid, accessToken, uuid);
      const key = Object.keys(connectionObjectResponse.data)[0];
      connectionObject = addConnectionObjectKeys(connectionObjectResponse.data[key]);
    }
    // Fetch the connection data
    const connectionDataResponse = yield call(
      getConnection,
      accessToken,
      connectionObject[HOME_OBJECT_KEYS.ID],
    );
    yield put(
      getConnectionObjectDataSuccess({
        uuid,
        object: connectionObject,
        data: connectionDataResponse.data,
      }),
    );
    // set the selected DB (db selected in connection editor).
    yield put(setSelectedDb({ database: connectionDataResponse.data.databaseType }));
  } catch (error) {
    yield put(getConnectionObjectDataFailure({ uuid, error }));
  }
}

/**
 * Worker to create or update the connection
 *
 * @param {object} action
 * @param {object} action.object // Connection Object
 * @param {boolean} action.isEditing // Connection data
 * @param {object} [action.payload] // Connection data
 * @param {string} [action.saveAsName] // Name of the connection to be saved as
 */
export function* updateConnectionRequestWorker({ object, payload, isEditing, saveAsName }) {
  const accessToken = yield select(selectAccessToken);
  try {
    let objectId = object[HomeObjectKeys.ID];
    if (isEditing) {
      if (saveAsName) {
        // Save Connection As
        const { data } = yield call(postCopyDBConnection, accessToken, objectId, saveAsName);
        objectId = data.id;
      } else {
        // Edit Connection
        yield call(putDatabaseConnection, accessToken, objectId, payload);
      }
    } else {
      // Create Connection
      yield call(postDatabaseConnection, accessToken, payload);
    }
    const successMessage = `Connection ${payload?.[CONNECTION_NAME]} ${
      isEditing && !saveAsName ? 'edited' : 'created'
    }  successfully`;
    yield put(addToast(getSuccessToastConfig(successMessage)));
    yield put(updateConnectionSuccess({ payload }));
    yield put(
      getHomeScreenObjectsRequest({ objectType: HOME_OBJECTS.CONNECTION, refreshing: true }),
    );
    yield put(connectionListRequest());
  } catch (error) {
    const errorMessage = `Failed to ${isEditing ? 'edit' : 'create'} connection`;
    yield put(addToast(getErrorToastConfig(errorMessage)));
    yield put(updateConnectionFailure({ error }));
  }
}

/**
  Worker to delete the connection
  @param {Object} object  // Connection Object
*/
export function* deleteConnectionRequestWorker({ object }) {
  try {
    const accessToken = yield select(selectAccessToken);
    const {
      [HOME_OBJECT_KEYS.UUID]: objectUuid,
      [HOME_OBJECT_KEYS.ID]: objectId,
      [HOME_OBJECT_KEYS.ACCESS_TYPE]: accessType,
      [HOME_OBJECT_KEYS.NAME]: objectName,
    } = object;

    // Check if the connection object is owned by the user
    const isConnectionOwner = accessType === WORKSPACE_ACCESS_TYPES.OWNER;
    // Check if the connection is shared with others
    const isConnectionSharedWithUsers = yield* isObjectShared(objectUuid);

    let alertChannel;
    // Check if the object is shared with other users, if so raise a delete shared object alert
    if (isConnectionOwner && isConnectionSharedWithUsers) {
      alertChannel = yield createAlertChannel(confirmDeleteObjectSharedAlert(objectName));
    } else {
      alertChannel = yield createAlertChannel(confirmDeleteObjectAlert(objectName));
    }
    const keyChoice = yield take(alertChannel);
    if (keyChoice === CONFIRM_BUTTON_KEY) {
      yield put(closeDialog());
      yield call(deleteDatabaseConnection, accessToken, objectId);
      yield put(deleteConnectionSuccess({ object }));
    } else if (keyChoice === CANCEL_BUTTON_KEY) {
      yield put(closeDialog());
      yield put(
        deleteConnectionFailure({ object, error: 'User canceled delete connection request' }),
      );
    }
  } catch (error) {
    yield put(
      addToast({
        toastType: TOAST_ERROR,
        length: TOAST_SHORT,
        message: `Failed to delete the connection`,
      }),
    );
    yield put(deleteConnectionFailure({ object, error }));
  }
}

/**
  Worker to test the connection
  @param {String} payload // Payload values for the connection
  @param {String} payload // Payload values for the connection
*/
export function* testConnectionRequestWorker({ databaseType, payload }) {
  try {
    const accessToken = yield select(selectAccessToken);
    const newPayload = yield call(refactorKeysForDbTest, databaseType, payload);

    // Api call on dc app servicerver to perfrom db tests which supports all kinds of existing db types
    yield call(testAllDbConnections, accessToken, newPayload);
    yield put(dismissAllToasts());
    yield put(addToast(getSuccessToastConfig('Database connection successful.')));
    return yield put(testConnectionSuccess({ payload }));
  } catch (error) {
    yield put(dismissAllToasts());
    // Check if the test connection is timed out
    if (
      (error.response.data.includes('Timeout') &&
        error.response.status === INTERNAL_SERVER_ERROR) ||
      error.response.status === GATEWAY_TIMEOUT
    ) {
      yield put(addToast(getErrorToastConfig('Database Connection timed out.')));
    } else {
      const errMessage = getTestConnectionErrorMessage(error, payload);
      yield put(addToast(getErrorToastConfig(errMessage)));
    }
    return yield put(testConnectionFailure({ payload, error }));
  }
}

function* closeConnectionEditorWorker() {
  yield put(clearConnection());
}

function* updateConnectionSuccessWorker() {
  yield put(connectionListRequest());
  // wait for the connection list to be updated
  const action = yield take([connectionListSuccess.type, connectionListFailure.type]);
  if (action.type === connectionListSuccess.type) {
    // hideNav is only true when we do not want to show the database browser navigation tree. If
    // we only close the connection editor while the navigation is hidden, the database browser will
    // only show the Dataset Preview placeholder
    const hideNav = yield select(selectDatabaseBrowserHideNavigation);
    if (!hideNav) {
      yield put(closeConnectionEditor());
    }
  }
}

export default function* () {
  yield takeEvery(
    OPEN_CONNECTION_EDITOR_REQUEST,
    authenticate(openConnectionEditorRequestWorker, openConnectionEditorFailure),
  );
  yield takeEvery(UPDATE_CONNECTION_REQUEST, updateConnectionRequestWorker);
  yield takeEvery(DELETE_CONNECTION_REQUEST, deleteConnectionRequestWorker);
  yield takeEvery(GET_CONNECTION_OBJECT_DATA_REQUEST, getConnectionObjectDataRequestWorker);
  yield takeEvery(TEST_CONNECTION_REQUEST, testConnectionRequestWorker);
  yield takeLatest(CLOSE_CONNECTION_EDITOR, closeConnectionEditorWorker);
  yield takeLatest(UPDATE_CONNECTION_SUCCESS, updateConnectionSuccessWorker);
}
