import React from 'react';
import { DependencyGraph, updateWorkflowDiagram } from 'translate_dc_to_echart';
import { DCEChart } from '../../../../chart-library/src/DCEChart';

type Props = {
  data: {
    values: {
      nodes: [],
      edges: [],
    },
  },
  theme: {},
  width: Number,
  height: Number,
  openCaptionAlert: () => mixed,
};

export default class DependencyGraphECharts extends React.PureComponent<Props> {
  constructor(props) {
    super(props);
    this.echartsRef = React.createRef(null);
    const { nodes, edges } = this.props.data.values;
    this.dependencyGraph = new DependencyGraph(nodes, edges, this.props.height, this.props.width);
  }

  componentDidMount() {
    const ecInstance = this.echartsRef.current?.getEchartsInstance();
    this.dependencyGraph.updateGraphSetting(this.props.height, this.props.width);
    const { option } = this.dependencyGraph.export();
    const graph = this.dependencyGraph;

    if (graph.noDisplayUtt.length > 0) {
      const utts = graph.noDisplayUtt.join(', ').replace(/, ([^,]*)$/, ' and $1');
      this.props.openCaptionAlert(`Nodes for ${utts} have been removed.`, true).then(() => {
        // resize the chart with the prompt banner rendered.
        ecInstance.resize();
        updateWorkflowDiagram(ecInstance, option);
      });
    } else {
      updateWorkflowDiagram(ecInstance, option);
    }

    // Add event listener to trim the graph / zoom in
    ecInstance.on('click', (params) => {
      if (params.dataType === 'node') {
        graph.hideNonLinearageNodes(params.name);
        updateWorkflowDiagram(ecInstance, graph.export().option);
      }
    });

    // Add event listener to show the entire graph / zoom out
    ecInstance.getZr().on('dblclick', () => {
      if (graph.showingLineage) {
        graph.showAllNodes();
        updateWorkflowDiagram(ecInstance, graph.export().option);
      }
    });
  }

  componentDidUpdate(prevProps) {
    /*
      The reason why componentDidMount and componentDidUpdate share the same code is that
      when the canvas of the chart is resized, we need to regenereate the chart options
      for eCharts and manually reset the options so that the chart will respond to
      the resizing event.
    */

    if (prevProps.width !== this.props.width || prevProps.height !== this.props.height) {
      const ecInstance = this.echartsRef.current?.getEchartsInstance();

      this.dependencyGraph.updateGraphSetting(this.props.height, this.props.width);
      const { option } = this.dependencyGraph.export();

      updateWorkflowDiagram(ecInstance, option);
    }
  }

  render() {
    return (
      <DCEChart
        ref={this.echartsRef}
        data={
          this.echartsRef.current?.getEchartsInstance().getOption()
            ? this.echartsRef.current?.getEchartsInstance().getOption()
            : {} // send empty option if no echarts instance exists yet
        }
        theme={this.props.theme}
        width={this.props.width}
      />
    );
  }
}
