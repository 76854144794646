/**
 * Share to Facebook Workplace URL, `u` is the encoded URI of the link to share
 */
export const SHARE_TO_FACEBOOK_WORKPLACE_URL: string =
  'https://work.facebook.com/sharer.php?display=page&u=';

/**
 * General documentation for DataChat
 */
export const DATACHAT_DOCS_URL: string = 'https://docs.datachat.ai';

/**
 * Documentation page for loading data from a Database
 */
export const DATACHAT_DOCS_LOAD_A_DATABASE_URL: string = `${DATACHAT_DOCS_URL}/datagrid/load-data/databases#browse-and-load-a-database`;

/**
 * Documentation page for loading data from a Dataset
 */
export const DATACHAT_DOCS_DATASET_URL: string = `${DATACHAT_DOCS_URL}/datagrid/load-data/datasets`;

/**
 * Story page for Files to Datasets migration
 */
export const FILES_TO_DATASETS_STORY: string =
  'http://datachat.ai/stories/files-to-datasets-migration';

/**
 * Link to the DataChat documentation for aggregating data
 */
export const AGGREGATE_DOCS_URL: string = `${DATACHAT_DOCS_URL}/datachat/work-with-your-data/aggregate`;

/**
 * Link to the DataChat documentation for wrangling (cleaning) data
 */
export const WRANGLE_DOCS_URL: string = `${DATACHAT_DOCS_URL}/datachat/work-with-your-data/wrangle`;
