import { Column, MarkLine, Series, SeriesGroupKeys, SeriesMarkKeys } from 'translate_dc_to_echart';

/** Chart Builder input names. */
export enum InputType {
  x = 'x-axis',
  y = 'y-axis',
  group = 'group',
  overlay = 'overlay',
  density = 'density',
  bubbleSize = 'bubbleSize',
  subplot = 'subplot',
  slider = 'slider',
  markLine = 'markLine',
  smooth = 'smooth',
  color = 'color',
  label = 'label',
  sliceSize = 'sliceSize',
  size = 'size',
  split = 'split',
  partition = 'partition',
  column = 'column',
}

/**
 * Chart Builder Fields variable which manages input/column selection pairs.
 *
 * The fields object maintains: { [InputType]: colNames }
 *   InputType - user-facing text for the input field
 *   colNames - string[] of selected columnNames for that input
 */
export type Fields = Partial<{
  [key in InputType]: Column[] | string[] | boolean | MarkLine; // ToDo: Remove string[]
}>;

/** FieldGeneration mapping specification. Maps the series keys to the fields object keys. */
export interface FieldGenMapping {
  mark: {
    [key in SeriesMarkKeys]?: InputType | ((group: Series['group']) => InputType);
  };
  group: {
    [key in SeriesGroupKeys]?: InputType;
  };
}
