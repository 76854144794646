import { RequestStatus } from './databaseBrowser.types';

export enum IntegrationProvider {
  BIGQUERY = 'google',
  SNOWFLAKE = 'snowflake',
}

export const INTEGRATION_PROVIDERS = [IntegrationProvider.BIGQUERY, IntegrationProvider.SNOWFLAKE];
export interface Integration {
  deleting?: boolean;
  [key: string]: any;
}
export interface IntegrationsState {
  open: boolean;
  reAuthenticationDialog: {
    open: boolean;
    provider?: IntegrationProvider;
    detail: string | null;
  };
  integrations: Partial<Record<IntegrationProvider, Integration>>;
  requestStatus: RequestStatus;
}
