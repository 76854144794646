import { CONFLICT, FORBIDDEN, OK } from 'http-status-codes';
import isEmpty from 'lodash/isEmpty';
import { instanceOf } from 'prop-types';
import { push } from 'redux-first-history';
import {
  call,
  delay,
  fork,
  getContext,
  put,
  race,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import { deleteFiles } from '../../api/file_manager.api';
import {
  getWorkflow,
  postCreateWorkflow,
  postRestoreWorkflow,
  putWorkflow,
} from '../../api/workflow.api';
import { CLASS, CMD, CONTEXTS, EXIT_CODE, SKILL_EVENTS } from '../../constants';
import { API_SERVICES } from '../../constants/api';
import { OVERWRITE_WORKFLOW } from '../../constants/dialog.constants';
import { CHANGE_TYPES, EDITABLE_OBJECTS, REPLAY_STATUS } from '../../constants/editor';
import { HOME_OBJECTS, HOME_OBJECT_KEYS } from '../../constants/home_screen';
import { paths } from '../../constants/paths';
import {
  TOAST_BOTTOM_RIGHT,
  TOAST_LONG,
  TOAST_SUCCESS,
  TOAST_TOP_MIDDLE,
  TOAST_WARNING,
  UPDATING_DATASET_TOAST,
} from '../../constants/toast';
import { saveWorkflowVersion } from '../../constants/utterance_templates';
import { SKILL_METADATA_SCHEMA, WORKFLOW_VERSION_STATUSES } from '../../constants/workflow';
import { WORKSPACE_ACCESS_TYPES } from '../../constants/workspace';
import { getUserMessageId } from '../../utils/chat_id';
import { canEditorSave, isComment, removeCommentPrefix, toDCW } from '../../utils/editor';
import { capitalizeFirstLetter } from '../../utils/string';
import { scrollToEditorUtterance } from '../../utils/utterances';
import { getVersionStatus } from '../../utils/workflow';
import { GET_DATASET_LIST_SUCCESS, resetDatasets } from '../actions/dataset.actions';
import { closeDialog, createAlertChannelRequest, openAlertDialog } from '../actions/dialog.actions';
import {
  CREATE_WORKFLOW_DATASET_FAILURE,
  CREATE_WORKFLOW_DATASET_REQUEST,
  CREATE_WORKFLOW_DATASET_SUCCESS,
  CREATE_WORKFLOW_REQUEST,
  DELETE_SELECTION,
  DELETE_UTTERANCE,
  DELETE_WORKFLOW_DATASET,
  DELETE_WORKFLOW_REQUEST,
  END_FRONTEND_REPLAY_REQUEST,
  GET_WORKFLOW_FAILURE,
  GET_WORKFLOW_REQUEST,
  GET_WORKFLOW_SUCCESS,
  INITIALIZE_EDITOR,
  NAVIGATE_TO_WORKFLOW_EDITOR,
  REDO_CHANGE,
  RESET_EDITOR,
  RESTORE_VERSION_FAILURE,
  RESTORE_VERSION_REQUEST,
  RESTORE_VERSION_SUCCESS,
  SAVE_WORKFLOW_REQUEST,
  SET_EDITOR_OBJECT_INFORMATION,
  START_FRONTEND_REPLAY_FAILURE,
  START_FRONTEND_REPLAY_REQUEST,
  START_FRONTEND_REPLAY_SUCCESS,
  SUBMIT_UTTERANCE_REQUEST,
  SUBMIT_UTTERANCE_SUCCESS,
  TRY_SAVE_WORKFLOW,
  UNDO_CHANGE,
  UPDATE_CHART_DATASET_FAILURE,
  UPDATE_CHART_DATASET_REQUEST,
  UPDATE_CHART_DATASET_SUCCESS,
  UPDATE_DC_OBJECT_DATASET_FAILURE,
  UPDATE_DC_OBJECT_DATASET_REQUEST,
  UPDATE_DC_OBJECT_DATASET_SUCCESS,
  addUtterance,
  createWorkflowDatasetFailure,
  createWorkflowDatasetRequest,
  createWorkflowDatasetSuccess,
  createWorkflowFailure,
  createWorkflowRequest,
  createWorkflowSuccess,
  deleteWorkflowFailure,
  deleteWorkflowSuccess,
  editUtterance,
  endFrontendReplayFailure,
  endFrontendReplaySuccess,
  finishLoading,
  getWorkflowFailure,
  getWorkflowRequest,
  getWorkflowSuccess,
  initializeEditor,
  pauseReplay,
  receivedQuestion,
  restoreVersionFailure,
  restoreVersionRequest,
  restoreVersionSuccess,
  saveWorkflowFailure,
  saveWorkflowRequest,
  saveWorkflowSuccess,
  startFrontendReplayFailure,
  startFrontendReplayRequest,
  startFrontendReplaySuccess,
  submitUtteranceFailure,
  submitUtteranceRequest,
  submitUtteranceSuccess,
  updateChartDatasetFailure,
  updateChartDatasetRequest,
  updateChartDatasetSuccess,
  updateDcObjectDatasetFailure,
  updateDcObjectDatasetRequest,
  updateDcObjectDatasetSuccess,
} from '../actions/editor.actions';
import { goToIndexRequest } from '../actions/header.actions';
import {
  DELETE_OBJECT_FAILURE,
  DELETE_OBJECT_SUCCESS,
  deleteObjectRequest,
} from '../actions/home_screen.actions';
import { SEND_INTERRUPT_SUCCESS } from '../actions/interrupt.actions';
import {
  ADD_USER_MESSAGE,
  RECEIVE_MESSAGES_SUCCESS,
  clearScreen,
  sendMessageRequest,
} from '../actions/messages.actions';
import { addToast } from '../actions/toast.actions';
import { getWorkflowPreviewRequest } from '../actions/utterances_preview.actions';
import { selectContext } from '../selectors/context.selector';
import { selectCurrentDataset } from '../selectors/dataspace.selector';
import { selectSession } from '../selectors/session.selector';
import { setContext } from '../slices/context.slice';
import {
  SESSION_TYPES,
  exitSessionFailure,
  exitSessionSuccess,
  onAppClick,
  onAppClickFailure,
  onAppClickSuccess,
  tryExitSessionRequest,
} from '../slices/session.slice';
import {
  selectAccessToken,
  selectActiveApp,
  selectEditorChangeIndex,
  selectEditorChangeLog,
  selectEditorEdited,
  selectEditorExecIndex,
  selectEditorHasWorkflowDataset,
  selectEditorIsReplaying,
  selectEditorIsStepwise,
  selectEditorMetadata,
  selectEditorObjectID,
  selectEditorObjectType,
  selectEditorReplayStatus,
  selectEditorUtteranceList,
  selectEditorVersionMetadata,
  selectEditorVersions,
  selectEditorWorkflow,
  selectEditorWorkflowDataset,
  selectEditorWorkflowId,
  selectIsAuthenticated,
  selectUserID,
} from './selectors';
import { callWithPolling } from './utils/saga_utils';
import { onSessionEnter } from './routes.saga';

// inject store so we can access redux from this file
// ----------------------------------------------------
let store;
export const injectStoreToEditorSaga = (_store) => {
  store = _store;
};
// ----------------------------------------------------

/**
 * Sequentially executes a set of tasks to initialize the workflow editor for a given
 * workflowId and version. This is called when first opening the editor and when
 * restoring an older version of the workflow as the current version. If `version` is
 * not defined, the latest version of the workflow will be used.
 */
function* initializeEditorWorker({ workflowId, version, autoSave }) {
  // 1. Attempt workflow migration and/or restore an older version as current
  yield put(restoreVersionRequest({ workflowId, version }));
  const { restoreSuccess } = yield race({
    restoreSuccess: take(RESTORE_VERSION_SUCCESS),
    restoreFailure: take(RESTORE_VERSION_FAILURE),
  });

  // Only do steps 2 if restoring was successful or migrating the latest version (version is null)
  if (restoreSuccess || version === null) {
    // 2. Fetch workflow content
    yield put(getWorkflowRequest({ workflowId }));
    yield race({
      fetchSuccess: take(GET_WORKFLOW_SUCCESS),
      fetchFailure: take(GET_WORKFLOW_FAILURE),
    });
  }

  // 3. Autostart session
  yield put(startFrontendReplayRequest({ stepwise: !autoSave }));
  yield race({
    startSuccess: take(START_FRONTEND_REPLAY_SUCCESS),
    startFailure: take(START_FRONTEND_REPLAY_FAILURE),
  });

  // 4. Stop loading indicator
  yield put(finishLoading());
}

/**
 * Sends a request to restore (and possibly migrate) a workflow's version as
 * the latest version of the workflow.
 */
function* restoreVersionRequestWorker({ workflowId, version }) {
  try {
    const accessToken = yield select(selectAccessToken);
    const response = yield call(postRestoreWorkflow, accessToken, workflowId, version);
    if (response.status === OK) yield put(restoreVersionSuccess());
    else throw Error(response.message);
  } catch (error) {
    yield put(restoreVersionFailure(error.message));
    // Don't display error message if restoration was due to permission issues.
    if (error.response.status === FORBIDDEN) return;
    const displayError =
      version !== null
        ? `Encountered an error restoring version ${version} of the workflow as the latest version.`
        : 'Encountered an error updating the workflow to the latest version of the application.';
    yield put(
      addToast({
        toastType: TOAST_WARNING,
        length: TOAST_LONG,
        message: displayError,
        position: TOAST_TOP_MIDDLE,
      }),
    );
  }
}

/**
 * Requests a workflow from the backend via a GET request to /workflow
 *
 * @param {Object} - { workflowId: Number }
 */
function* getWorkflowRequestWorker({ workflowId, refresh }) {
  try {
    const accessToken = yield select(selectAccessToken);
    const response = yield call(getWorkflow, accessToken, workflowId);
    const { canModify, workflow, versions, ownerEmail, uuid, snapshotUUID, snapshotName, dataset } =
      response.data;
    // eslint-disable-next-line camelcase
    const { app_id, current_version, name } = workflow;
    const userId = yield select(selectUserID);
    const owned = userId === workflow.user_id;
    const versionMap = {};
    versions.forEach((versionObject) => {
      versionObject.status = getVersionStatus(versionObject.dcw_data);
      versionMap[versionObject.version] = versionObject;
    });

    yield put(
      getWorkflowSuccess({
        metadata: {
          // eslint-disable-next-line camelcase
          appId: app_id,
          canModify,
          // eslint-disable-next-line camelcase
          headVersion: current_version,
          name: name.String,
          owned,
          workflowId,
          ownerEmail,
          uuid,
          snapshotUUID,
          snapshotName,
          dataset,
        },
        versions: versionMap,
        refresh,
      }),
    );
    const execIndex = yield select(selectEditorExecIndex);
    const utteranceList = yield select(selectEditorUtteranceList);
    // If the replay has ended, append a new utterance form
    if (refresh && execIndex === utteranceList.length) {
      yield put(addUtterance(utteranceList.length));
    }
  } catch (error) {
    yield put(getWorkflowFailure({ error }));
    const isAuthenticated = yield select(selectIsAuthenticated);
    yield put(goToIndexRequest({ isAuthenticated }));
    yield put(createAlertChannelRequest({ error }));
  }
}

function* startFrontendReplayRequestWorker({ stepwise }) {
  const workflowMetadata = yield select(selectEditorMetadata);
  const { workflowId } = workflowMetadata;
  const activeApp = yield select(selectActiveApp);

  try {
    // Active session must be closed first
    if (activeApp) throw Error('Session already in progress.');

    // Create a fresh testing session for replaying the workflow
    yield put(
      onAppClick({
        sessionType: SESSION_TYPES.WORKFLOW_EDITOR,
        objectId: workflowId,
        objectType: HOME_OBJECTS.RECIPE,
      }),
    );
    // Wait for the session to be initialized
    const { createSuccess, createFailure } = yield race({
      createSuccess: take(onAppClickSuccess.type),
      createFailure: take(onAppClickFailure.type),
    });
    if (createFailure) {
      // Failed to create session, alert dialog handled in other saga
      throw new Error('Session Creation Error');
    }
    // Session successfully created, wait for the session to start
    yield call(onSessionEnter, {
      id: createSuccess.payload.data.sessionId,
      sessionType: createSuccess.payload.data.sessionType,
    });
    // Enable frontend driven replay controls
    yield put(startFrontendReplaySuccess({ stepwise }));
  } catch (error) {
    yield put(startFrontendReplayFailure({ error }));
    yield put(createAlertChannelRequest({ error }));
  }
}

/**
 * Submits an utterance with metadata and then waits for it to finish.
 */
function* submitSaveUtteranceRequestWorker() {
  // Send message request with provided metadata
  const metadata = yield select(selectEditorMetadata);
  const saveUtterance = saveWorkflowVersion(metadata.name, metadata.ownerEmail);
  const sessionID = yield select(selectSession);

  yield put(sendMessageRequest({ message: saveUtterance, sessionID }));

  // Wait for utterance to finish
  let uncompleted = true;
  while (uncompleted) {
    const { receivedMessages, editorClosed, endingReplay } = yield race({
      receivedMessages: take(RECEIVE_MESSAGES_SUCCESS),
      editorClosed: take(RESET_EDITOR),
      endingReplay: take(END_FRONTEND_REPLAY_REQUEST),
    });
    // End the watcher once the editor is closed
    if (editorClosed || endingReplay) uncompleted = false;
    else if (receivedMessages) {
      for (let msgIndex = 0; msgIndex < receivedMessages.messages.length; msgIndex++) {
        const msg = receivedMessages.messages[msgIndex];
        const msgContent = msg.message;
        if (
          msgContent?.exitCode === EXIT_CODE.SUCCESS &&
          msgContent?.skillEvent === SKILL_EVENTS.EXIT_CODE
        ) {
          yield put(getWorkflowRequest({ workflowId: metadata.workflowId, refresh: true }));
          // Show a toast to the user informing them that an utterance was removed
          yield put(
            addToast({
              toastType: TOAST_SUCCESS,
              length: TOAST_LONG,
              message: 'Successfully saved and verified the workflow!',
              position: TOAST_TOP_MIDDLE,
            }),
          );
          uncompleted = false;
        }
      }
    }
  }

  const hasWorkflowDataset = yield select(selectEditorHasWorkflowDataset);
  if (hasWorkflowDataset)
    // Update the higher order object with the new dataset
    yield put(createWorkflowDatasetRequest());
}

/**
 * Submits an utterance with metadata and then waits for it to finish.
 */
function* submitUtteranceRequestWorker({ saveToWorkflow, addStep }) {
  const isStepwise = yield select(selectEditorIsStepwise);
  const workflow = yield select(selectEditorWorkflow);
  const utteranceList = yield select(selectEditorUtteranceList);
  const execIndex = yield select(selectEditorExecIndex);
  const sessionID = yield select(selectSession);
  const nextKey = utteranceList[execIndex];
  const nextUtt = workflow[nextKey];

  let context = yield select(selectContext);

  while (context !== CONTEXTS.REST) {
    yield take(setContext.type);
    context = yield select(selectContext);
  }
  // Don't allow submitting empty utterances.
  if (isEmpty(nextUtt.value.trim())) {
    // Pause the replay if necessary
    if (!isStepwise) yield put(pauseReplay());
    // Update utterance error
    if (isEmpty(nextUtt.value.trim())) {
      yield put(submitUtteranceFailure('Cannot run an empty step.'));
    }
    // Return without doing further work
    return;
  }

  const comment = isComment(nextUtt.value);
  const utterance = comment ? removeCommentPrefix(nextUtt.value) : nextUtt.value;
  const metadata = nextUtt.edited ? SKILL_METADATA_SCHEMA() : nextUtt.metadata;
  metadata.utterance = undefined; // set utterance to undefined as this is sent separately
  const { answers } = metadata;

  // Send message request with provided metadata
  yield put(
    sendMessageRequest({
      message: utterance,
      utteranceMetadata: {
        ...metadata,
        is_comment: comment, // Whether the utterance is just a comment
        exec_index: execIndex, // Used by the backend to insert comments into the workflow when saving
      },
      sessionID,
    }),
  );

  // Wait for utterance to finish
  let uncompleted = true;
  while (uncompleted) {
    const { receivedMessages, editorClosed, endingReplay, interrupted } = yield race({
      receivedMessages: take(RECEIVE_MESSAGES_SUCCESS),
      editorClosed: take(RESET_EDITOR),
      endingReplay: take(END_FRONTEND_REPLAY_REQUEST),
      interrupted: take(SEND_INTERRUPT_SUCCESS),
    });
    // End the watcher once the editor is closed
    if (editorClosed || endingReplay) uncompleted = false;
    else if (interrupted) {
      uncompleted = false;
      yield put(
        submitUtteranceFailure('Your step was interrupted. Check the chat for more details'),
      );
    } else if (receivedMessages) {
      for (let msgIndex = 0; msgIndex < receivedMessages.messages.length; msgIndex++) {
        const msg = receivedMessages.messages[msgIndex];
        const msgContent = msg.message;
        if (
          msgContent?.exitCode === EXIT_CODE.SUCCESS &&
          msgContent?.skillEvent === SKILL_EVENTS.EXIT_CODE
        ) {
          uncompleted = false;
          yield put(
            submitUtteranceSuccess(
              getUserMessageId(),
              msgContent.additionalInfo,
              saveToWorkflow,
              addStep,
            ),
          );
          break;
        } else if (
          msgContent?.exitCode === EXIT_CODE.FAILURE &&
          msgContent?.skillEvent === SKILL_EVENTS.EXIT_CODE
        ) {
          uncompleted = false;
          yield put(
            submitUtteranceFailure("Looks like that didn't work. Check the chat for more details."),
          );
          break;
        }
        // Auto-answer: If the skill prompts a question, automatically submit answer saved in workflow
        else if (msgContent?.class === CLASS.QUESTION) {
          yield put(receivedQuestion());
          if (
            msgContent?.additionalInfo?.questionKey &&
            msgContent?.additionalInfo?.questionKey in answers
          ) {
            let savedAnswer = answers[msgContent?.additionalInfo?.questionKey];
            // Convert true & false to "Yes" and "No" respectively
            if (instanceOf(savedAnswer, Boolean))
              savedAnswer = savedAnswer
                ? capitalizeFirstLetter(CMD.YES)
                : capitalizeFirstLetter(CMD.NO);
            yield put(sendMessageRequest({ message: savedAnswer }));
          }
        }
      }
    }
  }
}

/**
 * Compares the utterance returned by the server with the one in the workflow
 * and updates the workflow if there is a difference.
 * @param {Object} action Content of an ADD_USER_UTT action
 */
function* updateWorkflowUtterance(action) {
  const replayStatus = yield select(selectEditorReplayStatus);
  if (replayStatus === REPLAY_STATUS.WORKING) {
    const execIndex = yield select(selectEditorExecIndex);
    const utteranceList = yield select(selectEditorUtteranceList);
    const workflow = yield select(selectEditorWorkflow);
    const uttKey = utteranceList[execIndex];
    const uttObject = workflow[uttKey];

    if (action.message?.display) {
      // Check display form of the utterance if it exists
      if (action.message?.display !== uttObject.value) {
        // Check display form of the utterance first
        yield put(editUtterance(uttKey, action.message?.display));
      }
    } else if (action.message?.data !== uttObject.value) {
      // Otherwise, check the actual utterance for comparison
      yield put(editUtterance(uttKey, action.message.data));
    }
  }
}

/**
 * Handles automatically submitting the next utterance for continuous replays.
 *
 * Called after each successfully executed utterance and once when the replay initially starts.
 */
function* handleNextReplayStep({ saveToWorkflow, addStep }) {
  const execIndex = yield select(selectEditorExecIndex);
  const utteranceList = yield select(selectEditorUtteranceList);
  const isStepwise = yield select(selectEditorIsStepwise);
  const metadata = yield select(selectEditorMetadata);

  // End of replay has been hit
  if (execIndex === utteranceList.length) {
    // Pause the replay if not already in stepwise
    if (!isStepwise) yield put(pauseReplay());
    // Do not proceed further if user does not have owner/editor status
    if (!metadata.canModify) return;

    if (saveToWorkflow) {
      // Attempt saving via `VersionWorkflow` skill
      const edited = yield select(selectEditorEdited);
      const versionMetadata = yield select(selectEditorVersionMetadata);
      const { version, status } = versionMetadata;
      const replayingLatest = version === metadata.headVersion;
      const isUnverified = status !== WORKFLOW_VERSION_STATUSES.GREEN;
      // Only automatically save if edited OR replaying the latest version and it's not verified
      if (edited || (replayingLatest && isUnverified)) {
        yield fork(submitSaveUtteranceRequestWorker);
        return;
      }
    }

    // Append an utterance to the workflow
    if (addStep) yield put(addUtterance(utteranceList.length));

    return;
  }

  const workflow = yield select(selectEditorWorkflow);
  const nextKey = utteranceList[execIndex];
  const nextUtt = workflow[nextKey];

  // Don't automatically submit next utterance when in a stepwise replay,
  // a breakpoint is hit, the utterance is not confirmed, or the utterance is empty.
  if (isStepwise || nextUtt.breakpoint || isEmpty(nextUtt.value.trim())) {
    if (!isStepwise) {
      yield put(pauseReplay());
      if (isEmpty(nextUtt.value.trim())) {
        yield put(submitUtteranceFailure('Cannot run an empty step.'));
      }
    }
    return;
  }

  // If next utterance is being edited, pause replay
  const debouncedUtt = yield select((state) => state.editor.debouncedUtt);
  if (debouncedUtt === nextKey && !isStepwise) {
    yield put(pauseReplay());
    yield put(
      addToast({
        toastType: TOAST_WARNING,
        length: TOAST_LONG,
        message: "Pausing replay because a step cannot be run while it's being edited.",
        position: TOAST_TOP_MIDDLE,
      }),
    );
    return;
  }

  // Submit the next utterance in the replay
  yield put(submitUtteranceRequest(true, saveToWorkflow, addStep));
}

/**
 * Handles ending and optionally restarting replays.
 * @param {Boolean} restart -  whether to restart the replay after ending the current session
 * @param {Boolean} stepwise - whether the restarted replay should begin in stepwise mode
 */
function* endFrontendReplayRequestWorker({ restart, stepwise }) {
  // Exit any existing session
  yield put(tryExitSessionRequest());
  const { success } = yield race({
    success: take(exitSessionSuccess.type),
    failure: take(exitSessionFailure.type),
  });
  yield put(clearScreen());
  yield put(resetDatasets());

  if (success) {
    yield put(endFrontendReplaySuccess());
    if (restart) yield put(startFrontendReplayRequest({ stepwise }));
  } else yield put(endFrontendReplayFailure());
}

export function* trySaveWorkflowWorker() {
  const isEdited = yield select(selectEditorEdited);
  const hasWorkflowDataset = yield select(selectEditorHasWorkflowDataset);
  const isReplaying = yield select(selectEditorIsReplaying);

  const execIndex = yield select(selectEditorExecIndex);
  const workflow = yield select(selectEditorWorkflow);
  // Only consider non-empty steps when checking replay position
  const filteredWorkflow = Object.entries(workflow).filter(([, utt]) => !isEmpty(utt.value.trim()));
  // If the replay isn't over, make sure it's running
  if (execIndex < filteredWorkflow.length) {
    const isStepwise = yield select(selectEditorIsStepwise);
    if (isReplaying) {
      // If we're replaying, resume if we're paused
      if (isStepwise) yield put(submitUtteranceRequest(true, true, false));
    } else {
      // If we're not replaying , start the replay
      yield put(startFrontendReplayRequest({ stepwise: false }));
    }

    // Tell the user why we're replaying
    if (hasWorkflowDataset) yield put(addToast(UPDATING_DATASET_TOAST));

    return;
  }

  // If the user didn't click save, the session has just ended.
  // Therefore, we need to wait for the datasets to update
  yield take(GET_DATASET_LIST_SUCCESS);

  // Do normal check for saving, unless we've enabled Save for a Dataset
  if (canEditorSave(isEdited, isReplaying) || (hasWorkflowDataset && isEdited)) {
    yield put(saveWorkflowRequest());
  }
}

/**
 * Handles saving changes to an existing workflow
 */
function* saveWorkflowRequestWorker() {
  const accessToken = yield select(selectAccessToken);
  const workflowMetadata = yield select(selectEditorMetadata);
  const { workflowId } = workflowMetadata;
  const versionMetadata = yield select(selectEditorVersionMetadata);
  const { header } = versionMetadata;
  const workflow = yield select(selectEditorWorkflow);
  const utteranceList = yield select(selectEditorUtteranceList);
  const edited = yield select(selectEditorEdited);

  try {
    // Construct dcw file contents
    const dcwData = toDCW(edited, versionMetadata, header, utteranceList, workflow);
    // Save a new version of the current workflow
    yield call(putWorkflow, accessToken, workflowId, dcwData);
    yield put(saveWorkflowSuccess());
    yield put(getWorkflowRequest({ workflowId, refresh: true }));
    yield put(getWorkflowPreviewRequest({ workflowId, latestOnly: true }));
  } catch (error) {
    yield put(saveWorkflowFailure());
    yield put(createAlertChannelRequest({ error }));
  }
}

/**
 * Handles creating a new workflow
 */
function* createWorkflowRequestWorker({ name, overwrite }) {
  const accessToken = yield select(selectAccessToken);
  const workflowMetadata = yield select(selectEditorMetadata);
  const { workflowId } = workflowMetadata;
  const versionMetadata = yield select(selectEditorVersionMetadata);
  const { header } = versionMetadata;
  const workflow = yield select(selectEditorWorkflow);
  const utteranceList = yield select(selectEditorUtteranceList);
  const edited = yield select(selectEditorEdited);

  try {
    // Construct dcw file contents
    const versions = yield select(selectEditorVersions);
    const dcwData = edited
      ? toDCW(edited, versionMetadata, header, utteranceList, workflow)
      : versions[versionMetadata.version].dcw_data;
    // If name is provided, save a new version of the workflow with that name
    const response = yield call(postCreateWorkflow, accessToken, {
      name,
      data: dcwData,
      originWorkflowId: workflowId,
      overwrite,
    });
    if (response.status === OK && 'workflow_id' in response.data) {
      yield put(createWorkflowSuccess());
      // Immediately navigate to the newly created workflow
      yield put(push(paths.editorView(EDITABLE_OBJECTS.RECIPE, response.data.workflow_id)));
    } else if (response.error) {
      throw response.error;
    }
  } catch (error) {
    if (error.response.status === CONFLICT) {
      store.dispatch(
        openAlertDialog({
          title: 'Workflow Already Exists',
          descriptions: [
            `You already have a workflow named ${name}. Would you like to overwrite it?`,
            `This will create a new version of ${name}.`,
          ],
          dialogType: OVERWRITE_WORKFLOW,
          buttons: [
            {
              label: 'Cancel',
              key: 'cancel',
              onClick: () => store.dispatch(closeDialog()),
            },
            {
              label: 'Overwrite',
              key: 'submit',
              onClick: () => {
                store.dispatch(closeDialog());
                store.dispatch(createWorkflowRequest({ name, overwrite: true }));
              },
            },
          ],
        }),
      );
    } else {
      yield put(createWorkflowFailure());
      yield put(createAlertChannelRequest({ error }));
    }
  }
}

/**
 * Handles deleting the workflow loaded into the editor.
 */
function* deleteWorkflowRequestWorker() {
  try {
    const accessToken = yield select(selectAccessToken);
    const metadata = yield select(selectEditorMetadata);
    const { workflowId } = metadata;
    const fileName = `${workflowId}.dcw`;
    yield call(deleteFiles, accessToken, [fileName]);
    const isAuthenticated = yield select(selectIsAuthenticated);
    yield put(goToIndexRequest({ isAuthenticated }));
    yield put(deleteWorkflowSuccess());
  } catch (error) {
    yield put(deleteWorkflowFailure({ error }));
    yield put(createAlertChannelRequest({ error }));
  }
}

/**
 * If all utterances in a workflow are deleted, create a new one
 */
function* emptyWorkflowHandler() {
  const utteranceList = yield select(selectEditorUtteranceList);
  if (utteranceList.length === 0) {
    yield put(addUtterance(0));
  }
}

/**
 * Handles after affects from undoing or redoing a change.
 * - Displays error messages that occur from undoing/redoing
 * - Scrolls the chatbox to undone/redone changes.
 */
function* undoRedoHandler(action) {
  if ('error' in action) {
    yield put(
      addToast({
        toastType: TOAST_WARNING,
        length: TOAST_LONG,
        message: `End the session before ${
          action.type === UNDO_CHANGE ? 'undoing' : 'redoing'
        } changes to replayed steps.`,
        position: TOAST_TOP_MIDDLE,
      }),
    );
    return;
  }
  // Wait for a short time to give added redux time to rerender any added lines
  yield delay(100);
  let changeIndex = yield select(selectEditorChangeIndex);
  // If the change was redone, then the change index needs to be decremented
  if (action.type === REDO_CHANGE) changeIndex--;
  const changeLog = yield select(selectEditorChangeLog);
  if (changeIndex < 0 || changeIndex >= changeLog.length) return;
  const changeObject = changeLog[changeIndex];
  const changedLines = [];
  if (action.type === UNDO_CHANGE && 'undoIndex' in changeObject) {
    // When undoing a bulk deletion (adding multiple lines), flicker all added lines
    if (changeObject.type === CHANGE_TYPES.BULK_DELETE) {
      changeObject.deletions.forEach((line) => changedLines.push(line.uttIndex));
    }
    scrollToEditorUtterance(changeObject.undoIndex, changedLines);
  }
  if (action.type === REDO_CHANGE && 'redoIndex' in changeObject) {
    // When redoing a bulk addition (adding multiple lines), flicker all added lines
    if (changeObject.type === CHANGE_TYPES.BULK_ADD) {
      if (changeObject.editLog) changedLines.push(changeObject.editLog.uttIndex);
      changeObject.insertions.forEach((line) => changedLines.push(line.uttIndex));
    }
    scrollToEditorUtterance(changeObject.redoIndex, changedLines);
  }
}

export function* deleteWorkflowDatasetWorker() {
  const workflowDataset = yield select(selectEditorWorkflowDataset);
  yield put(
    deleteObjectRequest({
      object: {
        [HOME_OBJECT_KEYS.TYPE]: HOME_OBJECTS.DATASET,
        [HOME_OBJECT_KEYS.ACCESS_TYPE]: WORKSPACE_ACCESS_TYPES.OWNER,
        [HOME_OBJECT_KEYS.UUID]: workflowDataset.uuid,
        [HOME_OBJECT_KEYS.NAME]: workflowDataset.name,
      },
    }),
  );
  const { success } = yield race({
    success: take(DELETE_OBJECT_SUCCESS),
    failure: take(DELETE_OBJECT_FAILURE),
  });
  if (success) {
    const isAuthenticated = yield select(selectIsAuthenticated);
    yield put(goToIndexRequest({ isAuthenticated }));
  }
}

/**
 * navigates the user to the editor view for the given object
 *
 * @param {String} objectType The higher order object we are editing: Chart, Datset Object or Recipe
 * @param {String} objectId - The id of the object we are editing
 */
export function* navigateToWorkflowEditorWorker({ objectType, objectId, autoSave }) {
  yield put(push(paths.editorView(objectType, objectId, autoSave)));
}

/**
 * Gets the workflow id of the object we are editing and initializes the editor.
 * On workflow id retrieval error, we navigate back to the homepage
 *
 * @param {String} objectType The higher order object we are editing: Chart, Datset Object or Recipe
 * @param {String} objectId - The id of the object we are editing
 */
export function* setEditorObjectInformationWorker({ objectType, objectId, autoSave }) {
  const accessToken = yield select(selectAccessToken);
  let workflowId;
  try {
    switch (objectType) {
      case EDITABLE_OBJECTS.RECIPE:
        workflowId = objectId;
        break;
      case EDITABLE_OBJECTS.CHART: {
        const chartService = yield getContext(API_SERVICES.CHART);
        const res = yield call(chartService.getChartWorkflow, { accessToken, dcChartId: objectId });
        workflowId = res.data;
        break;
      }
      // TODO: implement dataset object path during #38306
      case EDITABLE_OBJECTS.DATASET_OBJECT: {
        const workspacev2Service = yield getContext(API_SERVICES.WORKSPACEV2);
        const res = yield call(workspacev2Service.getWorkspaceDatasetWorkflow, {
          accessToken,
          objectId,
        });
        workflowId = res.data.id;
        break;
      }
      default:
        throw Error(`Object type ${objectType} not implemented`);
    }
  } catch (err) {
    // Navigate to index if we fail to get the workflow id
    const isAuthenticated = yield select(selectIsAuthenticated);
    yield put(goToIndexRequest({ isAuthenticated }));
    yield put(
      addToast({
        toastType: TOAST_WARNING,
        length: TOAST_LONG,
        message: 'Failed to load workflow',
        position: TOAST_BOTTOM_RIGHT,
      }),
    );
    return;
  }
  yield put(initializeEditor({ workflowId, autoSave }));
}

/**
 * Creates a new dataset that associates the given pipeline with the current workflow.
 *
 * @param {string} pipelinerDatasetId
 */
export function* createWorkflowDatasetRequestWorker() {
  const accessToken = yield select(selectAccessToken);
  const sessionId = yield select(selectSession);
  const workflowID = yield select(selectEditorWorkflowId);
  const currentDatasetInfo = yield select(selectCurrentDataset);
  try {
    const pipelinerDatasetId = currentDatasetInfo?.dataset_id;
    if (!pipelinerDatasetId) throw Error('No pipeline ID provided');

    // Materialize the dataset
    const datasetService = yield getContext(API_SERVICES.DATASET);
    yield call(callWithPolling, {
      fn: datasetService.materializeDataset,
      accessToken,
      args: { pipelinerDatasetId, sessionId },
    });

    // Create a new dataset for the workflow

    const dataspaceService = yield getContext(API_SERVICES.DATASPACE);
    const sessionCatalogName = 'WorkflowDataset';
    const res = yield call(
      dataspaceService.postDataset,
      accessToken,
      sessionId,
      pipelinerDatasetId,
      workflowID,
      sessionCatalogName,
    );
    const dcDatasetId = res.data.dc_dataset_id;

    yield put(createWorkflowDatasetSuccess({ dcDatasetId }));
  } catch (error) {
    yield put(createWorkflowDatasetFailure({ error }));
  }
}

/**
 * Branching logic for which dc_dataset foreign key to update.
 *
 * @param {string} dcDatasetId
 */
export function* createWorkflowDatasetSuccessWorker({ dcDatasetId }) {
  const objectType = yield select(selectEditorObjectType);
  const id = yield select(selectEditorObjectID);

  if (objectType === EDITABLE_OBJECTS.CHART) {
    yield put(updateChartDatasetRequest({ dcDatasetId, dcChartId: id }));
  } else if (objectType === EDITABLE_OBJECTS.DATASET_OBJECT) {
    yield put(updateDcObjectDatasetRequest({ dcDatasetId, dcObjectId: id }));
  } else if (objectType === EDITABLE_OBJECTS.RECIPE) {
    // Do nothing
  } else {
    // We fail if the type is not valid
    yield put(createWorkflowDatasetFailure({ error: 'Invalid object type' }));
  }
}

/**
 * Updates the dataset associated with a chart.
 *
 * @param {string} dcDatasetId
 * @param {int} dcChartId
 */
export function* updateChartDatasetRequestWorker({ dcDatasetId, dcChartId }) {
  const accessToken = yield select(selectAccessToken);
  const sessionId = yield select(selectSession);
  try {
    const chartService = yield getContext(API_SERVICES.CHART);
    yield call(chartService.patchChart, { accessToken, dcChartId, dcDatasetId, sessionId });
    yield put(updateChartDatasetSuccess());
  } catch (error) {
    yield put(updateChartDatasetFailure({ error }));
  }
}

/**
 * Updates the dataset associated with a dc_object.
 *
 * @param {string} dcDatasetId
 * @param {int} dcChartId
 */
export function* updateDcObjectDatasetRequestWorker({ dcDatasetId, dcObjectId }) {
  const accessToken = yield select(selectAccessToken);
  const sessionId = yield select(selectSession);
  try {
    const { patchWorkspaceDataset } = yield getContext(API_SERVICES.WORKSPACEV2);
    yield call(patchWorkspaceDataset, {
      accessToken,
      objectId: dcObjectId,
      dcDatasetId,
      sessionId,
    });
    yield put(updateDcObjectDatasetSuccess());
  } catch (error) {
    yield put(updateDcObjectDatasetFailure({ error }));
  }
}

function* updateDatasetSuccessWorker() {
  yield put(
    addToast({
      toastType: TOAST_SUCCESS,
      length: TOAST_LONG,
      message: 'Successfully Updated Dataset',
      position: TOAST_TOP_MIDDLE,
    }),
  );
}

/**
 * Handles displaying a toast when a dataset update fails.
 */
function* updateDatasetFailureWorker() {
  yield put(
    addToast({
      toastType: TOAST_WARNING,
      length: TOAST_LONG,
      message: 'Failed to update dataset',
      position: TOAST_TOP_MIDDLE,
    }),
  );
}

export default function* apiWatcher() {
  yield takeLatest(INITIALIZE_EDITOR, initializeEditorWorker);
  yield takeLatest(START_FRONTEND_REPLAY_REQUEST, startFrontendReplayRequestWorker);
  yield takeLatest([START_FRONTEND_REPLAY_SUCCESS, SUBMIT_UTTERANCE_SUCCESS], handleNextReplayStep);
  yield takeLatest(SUBMIT_UTTERANCE_REQUEST, submitUtteranceRequestWorker);
  yield takeLatest(ADD_USER_MESSAGE, updateWorkflowUtterance);
  yield takeLatest(END_FRONTEND_REPLAY_REQUEST, endFrontendReplayRequestWorker);
  yield takeLatest(GET_WORKFLOW_REQUEST, getWorkflowRequestWorker);
  yield takeLatest(TRY_SAVE_WORKFLOW, trySaveWorkflowWorker);
  yield takeLatest(SAVE_WORKFLOW_REQUEST, saveWorkflowRequestWorker);
  yield takeLatest(CREATE_WORKFLOW_REQUEST, createWorkflowRequestWorker);
  yield takeLatest(RESTORE_VERSION_REQUEST, restoreVersionRequestWorker);
  yield takeLatest(DELETE_WORKFLOW_REQUEST, deleteWorkflowRequestWorker);
  yield takeLatest([DELETE_UTTERANCE, DELETE_SELECTION], emptyWorkflowHandler);
  yield takeLatest([UNDO_CHANGE, REDO_CHANGE], undoRedoHandler);
  yield takeLatest(DELETE_WORKFLOW_DATASET, deleteWorkflowDatasetWorker);
  yield takeLatest(NAVIGATE_TO_WORKFLOW_EDITOR, navigateToWorkflowEditorWorker);
  yield takeLatest(SET_EDITOR_OBJECT_INFORMATION, setEditorObjectInformationWorker);
  yield takeLatest(UPDATE_CHART_DATASET_REQUEST, updateChartDatasetRequestWorker);
  yield takeLatest(UPDATE_DC_OBJECT_DATASET_REQUEST, updateDcObjectDatasetRequestWorker);
  yield takeLatest(
    [
      UPDATE_CHART_DATASET_FAILURE,
      UPDATE_DC_OBJECT_DATASET_FAILURE,
      CREATE_WORKFLOW_DATASET_FAILURE,
    ],
    updateDatasetFailureWorker,
  );
  yield takeLatest(
    [UPDATE_CHART_DATASET_SUCCESS, UPDATE_DC_OBJECT_DATASET_SUCCESS],
    updateDatasetSuccessWorker,
  );
  yield takeLatest(CREATE_WORKFLOW_DATASET_REQUEST, createWorkflowDatasetRequestWorker);
  yield takeLatest(CREATE_WORKFLOW_DATASET_SUCCESS, createWorkflowDatasetSuccessWorker);
}
