import { put, select, takeLeading } from 'redux-saga/effects';
import { loadDatasets } from '../../constants/utterance_templates';
import { closeDatasetCreator, loadUploadedDatasets } from '../reducers/datasetCreator.reducer';
import { selectSession } from '../selectors/session.selector';
import { onAppClick } from '../slices/session.slice';
import { selectUploadedDatasets } from '../typedSelectors';
import { submitUtteranceInSession } from './utils/session';
import { triggerInitialUtterance } from '../slices/initial_utterance.slice';

export function* handleLoadUploadedDatasets() {
  const uploadedDatasets = yield select(selectUploadedDatasets);
  yield put(closeDatasetCreator());

  const loadDatasetsStep = loadDatasets(uploadedDatasets);
  const sessionId = yield select(selectSession);

  if (sessionId) {
    yield* submitUtteranceInSession([{ message: loadDatasetsStep }], true);
    return;
  }

  // start a new session & submit utterance
  yield put(onAppClick({}));
  // the session is open, just submit utterance
  yield put(triggerInitialUtterance({ message: loadDatasetsStep }));
}

export default function* datasetCreatorWatcher() {
  yield takeLeading(loadUploadedDatasets.type, handleLoadUploadedDatasets);
}
