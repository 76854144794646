import { AxiosError, isAxiosError } from 'axios';
import isString from 'lodash/isString';
import { ErrorDetails, ErrorResponse } from '../../../types/errorCodes/errorCodes.types';
import { ErrorHandler } from '../../../types/errorHandler.types';

/**
 * Base class for all error handlers. This class should be extended by all error handlers.
 * Contains logic for extracting error details from an error object for different contexts
 *
 */
export abstract class BaseErrorHandler implements ErrorHandler {
  /** To be implemented for the specifc error handled by a child class */
  abstract canHandle(errorDetails?: ErrorDetails): boolean;
  /** To be implemented for the specifc error handled by a child class */
  abstract handle(errorDetails?: ErrorDetails): void;

  /** Decodes an axios error into an 'ErrorDetails' object */
  static getErrorDetailsFromAPI(rawError: unknown): ErrorDetails | undefined {
    if (!isAxiosError(rawError)) return undefined;
    const error = rawError as AxiosError<ErrorResponse>;

    // Handle the case where data is a string
    let data = error.response?.data;
    const message = data || error.message;
    if (isString(data)) {
      data = {};
    }

    return {
      error_code: data?.error_code ?? error.code,
      detail: data?.detail ?? error.message,
      provider: data?.provider,
      status: error.status,
      message,
    };
  }

  /** Decodes a message store error into an 'ErrorDetails' object */
  static getErrorDetailsFromMessageError(
    error: unknown,
    errorDetails?: ErrorDetails,
  ): ErrorDetails | undefined {
    if (errorDetails && error instanceof Error && error.message) {
      return { ...errorDetails, detail: error.message };
    }
    return errorDetails;
  }

  canHandleMessageError(error: unknown, errorDetails?: ErrorDetails): boolean {
    return this.canHandle(BaseErrorHandler.getErrorDetailsFromMessageError(error, errorDetails));
  }

  canHandleAPIError(error: unknown): boolean {
    return this.canHandle(BaseErrorHandler.getErrorDetailsFromAPI(error));
  }

  handleMessageError(error: unknown, errorDetails?: ErrorDetails): void {
    this.handle(BaseErrorHandler.getErrorDetailsFromMessageError(error, errorDetails));
  }

  handleAPIError(error: unknown): void {
    this.handle(BaseErrorHandler.getErrorDetailsFromAPI(error));
  }
}
