import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import React from 'react';

import './TabsContainer.scss';

import TabContent from './TabContent';

/**
 * Component for rendering tabs for the TabVisual component.
 */
const TabsContainer = (props) => {
  const { tabs, tabContents, selectedTab, selectedTabIndex, changeSelectedTab } = props;

  /**
   * Helper function for checking if a tab has sub tabs.
   * @param {Number} tabIndex index of tab to check
   * @returns true if target tab has sub tabs, false otherwise
   */
  const containsSubTabs = (tabIndex) => {
    return Array.isArray(tabs[tabIndex]?.content);
  };

  /**
   * Updates the selected tab.
   * @param {React.SyntheticEvent} _ event fired upon clicking a tab
   * @param {Number} newSelectedTabIndex index of the new selected tab
   */
  const handleTabChange = (event, newSelectedTabIndex) => {
    const newSelectedTab = containsSubTabs(newSelectedTabIndex)
      ? tabs[newSelectedTabIndex].content[0]
      : tabs[newSelectedTabIndex].label;
    changeSelectedTab(event, newSelectedTab, newSelectedTabIndex);
  };

  /**
   * Helper function to prevent clicks from bubbling outside the TabsContainer.
   * - ex. prevents `linkToUserMsg` in `VisualHeaderContainer` from triggering
   *
   * @param {ClickEvent} event click event from inside
   */
  const stopPropagationHelper = (event) => {
    event.stopPropagation();
  };

  return (
    <div className="tabvisual-wrapper" onClick={stopPropagationHelper}>
      <Tabs
        className="tabvisual-tabs"
        value={selectedTabIndex}
        variant="scrollable"
        onChange={handleTabChange}
      >
        {tabs.map((tab, index) => (
          <Tab
            key={`tabvisual-tab-${tab.label}`}
            className="tabvisual-tab"
            value={index}
            disableRipple
            label={
              <TabContent
                tab={tab}
                tabIndex={index}
                tabContents={tabContents}
                selectedTab={selectedTab}
                selectedTabIndex={selectedTabIndex}
                changeSelectedTab={changeSelectedTab}
              />
            }
          />
        ))}
      </Tabs>
    </div>
  );
};

TabsContainer.propTypes = {
  // from parent component
  tabs: PropTypes.array.isRequired,
  tabContents: PropTypes.object.isRequired,
  selectedTab: PropTypes.string.isRequired,
  selectedTabIndex: PropTypes.number.isRequired,
  changeSelectedTab: PropTypes.func.isRequired,
};

export default TabsContainer;
