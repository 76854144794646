import Link from '@mui/material/Link';
import {
  FORBIDDEN,
  INTERNAL_SERVER_ERROR,
  OK,
  PARTIAL_CONTENT,
  TOO_MANY_REQUESTS,
} from 'http-status-codes';
import React from 'react';
import { push } from 'redux-first-history';
import { call, getContext, put, race, select, take, takeLatest } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { editAskAvaCache, getGeneratedCode } from '../../api/askAva.api';
import { API_SERVICES } from '../../constants/api';
import { ASK_AVA_RECIPE_NAME, GEL_CODE_TYPE } from '../../constants/askAva';
import { REPLAY_STATUS } from '../../constants/editor';
import { isChatPage, paths } from '../../constants/paths';
import {
  TOAST_BOTTOM_RIGHT,
  TOAST_ERROR,
  TOAST_INDEFINITE,
  TOAST_SHORT,
  TOAST_SUCCESS,
  TOAST_WARNING,
} from '../../constants/toast';
import { FEEDBACK_CONTEXT } from '../../constants/userFeedback';
import { GRID_MODE_RIGHT_PANE } from '../../pages/authenticated/DataGrid/constants';
import { generateWorkflowResponseForAskAvaEditor, getDatasetOptions } from '../../utils/askAva';
import {
  ASK_QUESTION_FROM_GRID_REQUEST,
  CANCEL_QUERY_REQUEST,
  EDIT_ASK_AVA_CACHE_FAILURE,
  EDIT_ASK_AVA_CACHE_REQUEST,
  EDIT_ASK_AVA_CACHE_SUCCESS,
  INITIALIZE_ASK_AVA,
  REDIRECT_TO_BILLING_PAGE_REQUEST,
  RESET_ASK_AVA,
  SUBMIT_ASK_AVA_QUERY_REQUEST,
  SUBMIT_ASK_AVA_USER_FEEDBACK_REQUEST,
  askQuestionFromGridFailure,
  askQuestionFromGridSuccess,
  cancelQueryFailure,
  cancelQuerySuccess,
  editAskAvaCacheFailure,
  editAskAvaCacheRequest,
  editAskAvaCacheSuccess,
  generateAskAvaSolutionSuccess,
  redirectToBillingRequest,
  resetAskAva,
  setAskAvaCachedGel,
  setAskAvaSelectedDatasets,
  setAskAvaSessionContext,
  setAskAvaSolutionCompleted,
  setAskAvaSolutionFailed,
  setAskAvaUpdatedGel,
  setAskQuestionParams,
  showAskAvaSteps,
  submitAskAvaQueryFailure,
  submitAskAvaQueryRequest,
  submitAskAvaQuerySuccess,
  submitAskAvaUserFeedbackFailure,
  submitAskAvaUserFeedbackSuccess,
} from '../actions/askAva.action';
import {
  GET_BASE_DATASETS_FAILURE,
  GET_BASE_DATASETS_SUCCESS,
  GET_DATASET_LIST_SUCCESS,
  getBaseDatasetsRequest,
} from '../actions/dataset.actions';
import {
  ADD_UTTERANCE,
  DELETE_SELECTION,
  DELETE_UTTERANCE,
  EDIT_UTTERANCE,
  MERGE_UTTERANCES,
  PASTE_SELECTION,
  RECEIVED_QUESTION,
  REDO_CHANGE,
  SPLIT_UTTERANCE,
  START_ASK_AVA_REPLAY,
  SUBMIT_UTTERANCE_FAILURE,
  SUBMIT_UTTERANCE_SUCCESS,
  UNDO_CHANGE,
  loadAskAvaEditor,
  resetEditor,
  startAskAvaReplay,
  submitUtteranceRequest,
} from '../actions/editor.actions';
import { addToast, dismissAllToasts } from '../actions/toast.actions';
import {
  SUBMIT_USER_FEEDBACK_FAILURE,
  SUBMIT_USER_FEEDBACK_SUCCESS,
  submitUserFeedbackRequest,
} from '../actions/userFeedback.action';
import { selectDatasetList } from '../selectors/dataset.selector';
import { selectPaneOpenStatusDict } from '../selectors/dataspaceTable.selector';
import { selectAppId, selectSession } from '../selectors/session.selector';
import {
  selectAccessToken,
  selectAskAvaApiCode,
  selectAskAvaCodeIsEdited,
  selectAskAvaRequestId,
  selectAskAvaSelectedDatasets,
  selectAskAvaSessionContext,
  selectAskAvaVectorId,
  selectBaseDatasets,
  selectEditorExecIndex,
  selectEditorMetadata,
  selectEditorReplayStatus,
  selectEditorUtteranceList,
  selectEditorWorkflow,
  selectEmail,
  selectGeneratedCode,
  selectName,
  selectSelectedAskAvaOutputLanguage,
} from './selectors';
import { setRestingContext } from './utils/context';

// inject store so we can access redux from this file
// ----------------------------------------------------
let store;
export const injectStoreToAskAvaSaga = (_store) => {
  store = _store;
};
// ----------------------------------------------------

function* initializeAskAvaWorker() {
  const askAvaSessionId = yield select(selectAskAvaSessionContext);
  const sessionId = yield select(selectSession);

  if (sessionId !== askAvaSessionId) {
    // if there is no ava session context, or the session context is
    // different from the current session, then reset ask ava
    yield put(resetAskAva());
  } else {
    // if the session context is the same as the current session and
    // there exists some generated code in the ask ava state,
    // load the ask ava editor
    const recipeMetadata = yield select(selectEditorMetadata);
    const generatedCode = yield select(selectGeneratedCode);
    const gelCode = generatedCode[GEL_CODE_TYPE].code;
    if (gelCode.length > 0 && recipeMetadata.name !== ASK_AVA_RECIPE_NAME) {
      // only initialize the ask ava steps in the editor reducer if:
      // 1. the editor reducer has been cleared (action resetEditor has been dispatched)
      // 2. there is some gelcode to be initialized.
      const appId = yield select(selectAppId);
      const userName = yield select(selectName);
      const ownerEmail = yield select(selectEmail);
      const { recipe, stepList, metadata } = yield call(generateWorkflowResponseForAskAvaEditor, {
        appId,
        ownerEmail,
        userName,
        gelCode,
      });
      yield put(loadAskAvaEditor({ recipe, stepList, metadata }));
    }
  }
  yield put(
    setAskAvaSessionContext({
      sessionId,
    }),
  );
}

/**
 * reaches out to llm api and gets generated code based on query and gen code language type
 * @param {string} query unstructured question / prompt from user
 */
function* submitAskAvaQueryRequestWorker({ query, selectedDatasets, sessionId, skipCache }) {
  try {
    // close any open toasts
    yield put(dismissAllToasts());
    // reset the recipe editor
    yield put(resetEditor());
    // requested language type
    const language = yield select(selectSelectedAskAvaOutputLanguage);

    // get the response
    // select required items for the request
    const datasetList = yield select(selectDatasetList);
    // get dataset versions
    const datasetVersions = {};
    Object.entries(datasetList).forEach((dataset) => {
      datasetVersions[dataset[0]] = Object.keys(dataset[1]);
    });
    const accessToken = yield select(selectAccessToken);
    const requestId = uuidv4();
    const datasets = selectedDatasets.map((dataset) => ({
      name: dataset.name,
      version: dataset.version,
    }));
    // TODO: remove cancel condition when we store ask response in db
    const { response, cancel } = yield race({
      response: call(getGeneratedCode, accessToken, {
        request_id: requestId,
        session_id: sessionId,
        query,
        target: language,
        dataset_versions: datasetVersions,
        datasets,
        force_generate: skipCache,
      }),
      cancel: take(RESET_ASK_AVA),
    });
    if (cancel) {
      return;
    }

    const generatedCode = response.data[language];
    const {
      prompt,
      question,
      dataset_info: datasetInfo,
      api_code: apiCode,
      vector_id: vectorId,
      summary_response: summaryResponse,
      message,
      status,
    } = response.data;

    let summary;
    if (status === OK && summaryResponse?.summary) {
      // show the solution summary if it exists in the response payload
      summary = summaryResponse.summary;
    } else if (status === OK && !summaryResponse?.summary) {
      // if the status is ok, but there is no summary, then we haven't generated a summary yet
      // this is the case for questions cached before we added the summary field
      summary =
        "I haven't generated a summary for this question yet. Click the regenerate button to see the summary.";
    } else if (status === PARTIAL_CONTENT) {
      // if the status is partial content, then the model failed to generate code, but provided
      // a reason why in the message field
      summary = message;
    } else {
      summary = null;
    }

    yield put(
      submitAskAvaQuerySuccess({
        requestId,
        generatedCode,
        language,
        question,
        prompt,
        usedDatasets: datasetInfo,
        selectedDatasets: selectedDatasets.map((dataset) => ({
          name: dataset.name,
          type: 'dataset',
          version: dataset.version,
        })),
        apiCode,
        vectorId,
        // if there is a summary return it
        // if there is no summary, but the status is partial content, return the message
        // otherwise return null - we don't want to display a message for old cached responses
        summary,
      }),
    );
    const currentSessionId = yield select(selectSession);
    // if the session id has changed, then don't load the ask ava editor
    if (currentSessionId !== sessionId) return;
    if (language === GEL_CODE_TYPE) {
      const appId = yield select(selectAppId);
      const userName = yield select(selectName);
      const ownerEmail = yield select(selectEmail);
      const { recipe, stepList, metadata } = yield call(generateWorkflowResponseForAskAvaEditor, {
        appId,
        ownerEmail,
        userName,
        gelCode: generatedCode,
      });

      if (generatedCode.length > 0) {
        yield put(loadAskAvaEditor({ recipe, stepList, metadata }));
        // only start a continuous replay if the score was not low confidence
        yield put(startAskAvaReplay({ autoStart: true }));
      }
    }
    return;
  } catch (error) {
    let message;
    let toastType = TOAST_ERROR;
    if (!error?.response?.data?.message) {
      // if there is no specific message from the Backend
      if (error?.response?.status === TOO_MANY_REQUESTS) {
        // case where we hit nginx limit (status = 429 and no error message)
        message = 'Too many requests. Try after 1 minute.';
      } else if (error?.response?.status === INTERNAL_SERVER_ERROR) {
        message =
          'Sorry, we are unable to answer your question. Please try rephrasing your question.';
      } else {
        message =
          'Sorry, this service is currently unavailable. We apologize for the inconvenience. Please try again.';
      }
    } else if (error?.response?.status === FORBIDDEN) {
      // display message where user can add their own credentials
      message = (
        <span>
          You&apos;ve exceeded your Ask quota.
          <Link
            color="inherit"
            sx={{
              marginLeft: '0.5rem',
              color: 'inherit',
            }}
            onClick={() => store.dispatch(redirectToBillingRequest())}
          >
            Click here to upgrade your subscription
          </Link>
        </span>
      );

      toastType = TOAST_WARNING;
    } else {
      message = error?.response?.data?.message;
    }
    yield put(
      addToast({
        toastType,
        length: TOAST_INDEFINITE,
        message,
        position: TOAST_BOTTOM_RIGHT,
      }),
    );
    yield put(submitAskAvaQueryFailure({ error }));
    yield call(setRestingContext);
  }
}

/**
 * This worker wraps the submitUserFeedback generic functionality
 * @param {string} vote 1 or 0 - whether ava produced useful results
 * @param {string} language selected language type
 */
function* submitAskAvaUserFeedbackRequestWorker({ vote, language }) {
  const generatedCode = yield select(selectGeneratedCode);
  const sessionId = yield select(selectAskAvaSessionContext);
  const apiCode = yield select(selectAskAvaApiCode);
  const requestId = yield select(selectAskAvaRequestId);
  const isEdited = yield select(selectAskAvaCodeIsEdited);
  const { prompt, code, finalFailed, originalFailed, finalCompleted, originalCompleted } =
    generatedCode[language];
  yield put(
    submitUserFeedbackRequest({
      vote,
      context: FEEDBACK_CONTEXT.ASK_AVA,
      content: {
        request_id: requestId,
        prompt,
        [language]: code,
        api_code: apiCode,
        edited: isEdited,
        final_failed: finalFailed,
        original_failed: originalFailed,
        final_completed: finalCompleted,
        original_completed: originalCompleted,
      },
      sessionId,
    }),
  );
  // race condition for the success or failure of teh user feedback request
  const { success, failure } = yield race({
    success: take(SUBMIT_USER_FEEDBACK_SUCCESS),
    failure: take(SUBMIT_USER_FEEDBACK_FAILURE),
  });
  if (success) {
    const metadata = { vote };
    const vectorId = yield select(selectAskAvaVectorId);
    yield put(editAskAvaCacheRequest({ vectorId, metadata }));
    // if request was successful, yield the ask ava specific success action
    yield put(submitAskAvaUserFeedbackSuccess({ vote, language }));
    // add a success toast
    yield put(
      addToast({
        toastType: TOAST_SUCCESS,
        length: TOAST_SHORT,
        message: 'Thanks for your feedback',
        position: TOAST_BOTTOM_RIGHT,
      }),
    );
  } else {
    yield put(submitAskAvaUserFeedbackFailure({ error: failure.error }));
  }
}

function* startAskAvaReplayWorker({ autoStart }) {
  if (autoStart) {
    yield put(submitUtteranceRequest(true, false, false));
  }
}

// whenever a recipe editor step as completed successfully,
// 1. if generating solution is false, restart the solution (case when user answers question in session)
// 2. if user has reached end of replay, complete the solution
function* handleAskAvaStepCompletionWorker() {
  const execIndex = yield select(selectEditorExecIndex);
  const utteranceList = yield select(selectEditorUtteranceList);

  // End of replay has been hit
  if (execIndex >= utteranceList.length) {
    const isEdited = yield select(selectAskAvaCodeIsEdited);
    yield put(generateAskAvaSolutionSuccess());
    yield put(setAskAvaSolutionCompleted(isEdited));
    const vectorId = yield select(selectAskAvaVectorId);
    const metadata = { successful_execution: true };
    const generatedCode = yield select(selectGeneratedCode);
    const { code } = generatedCode[GEL_CODE_TYPE];

    if (isEdited) {
      // if the user edited the recipe and ran it to completion, update cached code and confidence level
      metadata.gel_code = code;
      // Edited recipes should be high confidence, automatically
    }

    // update vector db cache
    yield put(editAskAvaCacheRequest({ vectorId, metadata }));

    if (isEdited) {
      // if cached code was updated is successfully, update the latest cached code
      const { cacheUpdateSuccess } = yield race({
        cacheUpdateSuccess: take(EDIT_ASK_AVA_CACHE_SUCCESS),
        cacheUpdateFailure: take(EDIT_ASK_AVA_CACHE_FAILURE),
      });
      if (cacheUpdateSuccess) {
        yield put(setAskAvaCachedGel({ code }));
      } else {
        // updating the cached code failed, inform the user
        yield put(
          addToast({
            toastType: TOAST_ERROR,
            length: TOAST_SHORT,
            message: 'Error saving the edited solution',
          }),
        );
      }
    }
  }
}

// this side effects runs whenever a workflow editor function is dispatched that
// changes the GEL. We do this to preserve the Ask Ava state, without needing
// preserve the state in the editor reducer.
function* updateAskAvaFromEditorWorker() {
  const sessionId = yield select(selectSession);
  const paneOpenStatusDict = yield select(selectPaneOpenStatusDict);
  const openPanes = paneOpenStatusDict?.[sessionId];
  const isAskAvaOpen = openPanes?.indexOf(GRID_MODE_RIGHT_PANE.ASK.name) > -1;
  if (isAskAvaOpen) {
    const recipe = yield select(selectEditorWorkflow);
    const utteranceList = yield select(selectEditorUtteranceList);
    const steps = utteranceList.map((key) => ({
      text: recipe[key].value,
      valid: recipe[key].valid,
    }));
    yield put(setAskAvaUpdatedGel({ code: steps }));
  }
}

// this worker runs everytime the dataset list is fetched
// we will update the selected datasets if one of the selected datasets was forgotten
function* updateSelectedDatasetsWorker() {
  // we don't want to run this logic unless we are on the chat page
  if (!isChatPage()) return;
  // whenever we get the list of datasets
  // we need to get the base datasets
  yield put(getBaseDatasetsRequest());
  yield race({ success: take(GET_BASE_DATASETS_SUCCESS), error: take(GET_BASE_DATASETS_FAILURE) });

  const selectedDatasets = yield select(selectAskAvaSelectedDatasets);
  const datasetList = yield select(selectDatasetList);
  const baseDatasets = yield select(selectBaseDatasets);
  const { versionOptions } = yield call(getDatasetOptions, datasetList, baseDatasets);
  // get the base versions (version 1 of source datasets)
  const baseVersions = versionOptions.filter((option) => option.base);
  if (selectedDatasets.length === 0 && baseVersions.length > 0) {
    // case 1: no datasets are selected, and there are source datasets
    yield put(setAskAvaSelectedDatasets({ datasets: baseVersions }));
  } else {
    // case 2: there are selected datasets, and we need to check if any of them were forgotten
    const newSelectedDatasets = selectedDatasets.filter((dataset) => {
      // get datasetVersion info.
      // If name was changed, datasetVersion will be false
      const datasetVersion = datasetList?.[dataset.name]?.[dataset.version];
      if (datasetVersion) {
        // if the datasetVersion exist
        return !datasetVersion.forgotten;
      }
      return false;
    });
    if (newSelectedDatasets.length < selectedDatasets.length) {
      // if a dataset name was changed or forgotten, update the selected datasets
      yield put(setAskAvaSelectedDatasets({ datasets: newSelectedDatasets }));
    }
  }
}

// whenever an utterance fails or a question is received,
// interrupt the ask ava solution
function* handleInterruptAskAvaWorker() {
  const replayStatus = yield select(selectEditorReplayStatus);
  if (replayStatus === REPLAY_STATUS.FAILURE) {
    const isEdited = yield select(selectAskAvaCodeIsEdited);
    yield put(showAskAvaSteps());
    yield put(setAskAvaSolutionFailed(isEdited));
  }
}

function* askQuestionFromGridRequestWorker({ query, selectedDatasets, skipCache }) {
  try {
    // set ask ava redux state with the query and selected datasets
    yield put(setAskQuestionParams({ query, selectedDatasets }));
    yield put(askQuestionFromGridSuccess());

    const sessionId = yield select(selectSession);
    // send request to nl2code server
    yield put(
      submitAskAvaQueryRequest({
        query,
        selectedDatasets,
        sessionId,
        skipCache,
      }),
    );
  } catch (error) {
    yield put(askQuestionFromGridFailure({ error }));
  }
}

/**
 * Cancels a running query.
 * @param {import('../../types/task.types').Task} task
 */
export function* cancelQueryRequestWorker({ task }) {
  const accessToken = yield select(selectAccessToken);
  const sessionId = yield select(selectSession);

  const avaService = yield getContext(API_SERVICES.AVA);

  try {
    yield call(avaService.cancelRunningQuery, { accessToken, sessionId, taskId: task.id });
    yield put(cancelQuerySuccess());
  } catch (error) {
    yield put(cancelQueryFailure({ error }));
  }
}

function* redirectBillingRequestWorker() {
  yield put(dismissAllToasts());
  yield put(push(paths.account));
}

function* editAskAvaCacheRequestWorker({ vectorId, metadata }) {
  try {
    const accessToken = yield select(selectAccessToken);
    const sessionId = yield select(selectSession);
    yield call(editAskAvaCache, { accessToken, vectorId, sessionId, metadata });
    yield put(editAskAvaCacheSuccess());
  } catch (error) {
    yield put(editAskAvaCacheFailure({ error }));
  }
}

export default function* () {
  yield takeLatest(INITIALIZE_ASK_AVA, initializeAskAvaWorker);
  yield takeLatest(SUBMIT_ASK_AVA_QUERY_REQUEST, submitAskAvaQueryRequestWorker);
  yield takeLatest(SUBMIT_ASK_AVA_USER_FEEDBACK_REQUEST, submitAskAvaUserFeedbackRequestWorker);
  yield takeLatest(START_ASK_AVA_REPLAY, startAskAvaReplayWorker);
  yield takeLatest(SUBMIT_UTTERANCE_SUCCESS, handleAskAvaStepCompletionWorker);
  yield takeLatest(GET_DATASET_LIST_SUCCESS, updateSelectedDatasetsWorker);
  yield takeLatest(
    [
      UNDO_CHANGE,
      REDO_CHANGE,
      ADD_UTTERANCE,
      EDIT_UTTERANCE,
      DELETE_UTTERANCE,
      SPLIT_UTTERANCE,
      MERGE_UTTERANCES,
      PASTE_SELECTION,
      DELETE_SELECTION,
    ],
    updateAskAvaFromEditorWorker,
  );
  yield takeLatest([RECEIVED_QUESTION, SUBMIT_UTTERANCE_FAILURE], handleInterruptAskAvaWorker);
  yield takeLatest(CANCEL_QUERY_REQUEST, cancelQueryRequestWorker);
  yield takeLatest(REDIRECT_TO_BILLING_PAGE_REQUEST, redirectBillingRequestWorker);
  yield takeLatest(EDIT_ASK_AVA_CACHE_REQUEST, editAskAvaCacheRequestWorker);
  yield takeLatest(ASK_QUESTION_FROM_GRID_REQUEST, askQuestionFromGridRequestWorker);
}
