import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Dialog from '@mui/material/Dialog';
import { InlineWidget } from 'react-calendly';

import { setOfficeHoursMenuVisibility } from '../../store/actions/settings.actions';
import { selectEmail, selectName } from '../../store/sagas/selectors';
import './index.scss';

type Props = {
  supportCalendarLink: string,
};

const CalendlyPopup = (props: Props) => {
  const dispatch = useDispatch();
  const name = useSelector(selectName);
  const email = useSelector(selectEmail);
  const { supportCalendarLink } = props;
  const onClose = () => dispatch(setOfficeHoursMenuVisibility({ open: false }));
  return (
    <Dialog
      style={{ height: '500px' }}
      open
      className="CalendlyPopup-Dialog"
      maxWidth="sm"
      fullWidth
      onClose={onClose}
    >
      <div className="CalendlyPopup" data-cy="CalendlyPopup">
        <div className="CalendlyPopup-Header">
          <div className="CalendlyPopup-Header-Title" />
          <div
            className="CalendlyPopup-Header-CloseButton"
            data-cy="calendlyPopup-header-close-btn"
            onClick={onClose}
          >
            &times;
          </div>
        </div>
        <div className="CalendlyPopup-Body">
          <InlineWidget
            url={supportCalendarLink}
            prefill={{
              email,
              name,
            }}
            styles={{
              marginTop: '-40px',
              height: '100%',
              width: '100%',
            }}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default CalendlyPopup;
