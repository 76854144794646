// heuristic value used by google sheet tab
export const MAX_NAME_LENGTH: number = 50;

export const TOP_MENU_ITEMS_DIRECT_ACTIONS: { [index: string]: string } = {
  load: 'load',
  plot: 'plot',
  pivot: 'pivot',
  askAva: 'askAva',
};

export const NESTED_MENU_ITEMS: { [index: string]: string } = {
  FORMAT: 'format',
  CHANGE_TYPE: 'changeType',
};

// This class name is used for ben.jsx and suggestionListportal.jsx for react portal element
export const RIGHT_PANEL_NAME: string = 'Plot-And-Chat-Display-Panel';

export const PLOT_DISPLAY_ELEMENT_ID: string = 'PlotDisplayPanel';

export const CHAT_PANEL_ELEMENT_ID: string = 'ChatPanel';

export const ACCORDION_SUMMARY_HEIGHT: number = 40;
export const ACCORDION_CONTENT_MIN_HEIGHT: number = 100;

// An array of initial sizes of the panes based on the full height of the window of 16 inch macbook pro
// The available height for all 3 panes is 989px, we use 960px here(480 + 240 + 240)
export const GRID_MODE_RIGHT_PANE: { [index: string]: { [index: string]: string | number } } = {
  VIZ: {
    name: 'viz-pane',
    title: 'Visualization',
    height: 480,
  },
  ASK: {
    name: 'ask-pane',
    title: 'Ask',
    height: 240,
  },
  LOG: {
    name: 'log-pane',
    title: 'Log',
    height: 240,
  },
};
