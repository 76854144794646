export const GET_CROSS_FILTERS_REQUEST = 'crossFilter/GET_CROSS_FILTERS_REQUEST';
export const GET_CROSS_FILTERS_SUCCESS = 'crossFilter/GET_CROSS_FILTERS_SUCCESS';
export const GET_CROSS_FILTERS_FAILURE = 'crossFilter/GET_CROSS_FILTERS_FAILURE';

export const SAVE_CROSS_FILTERS_REQUEST = 'crossFilter/SAVE_CROSS_FILTERS_REQUEST';
export const SAVE_CROSS_FILTERS_SUCCESS = 'crossFilter/SAVE_CROSS_FILTERS_SUCCESS';
export const SAVE_CROSS_FILTERS_FAILURE = 'crossFilter/SAVE_CROSS_FILTERS_FAILURE';

export const CREATE_CROSS_FILTER = 'crossFilter/CREATE_CROSS_FILTER';
export const CREATE_CROSS_FILTER_GROUP = 'crossFilter/CREATE_CROSS_FILTER_GROUP';

export const UPDATE_CROSS_FILTER = 'crossFilter/UPDATE_CROSS_FILTER';
export const UPDATE_CROSS_FILTER_GROUP = 'crossFilter/UPDATE_CROSS_FILTER_GROUP';

export const DELETE_CROSS_FILTER = 'crossFilter/DELETE_CROSS_FILTER';
export const DELETE_CROSS_FILTER_GROUP = 'crossFilter/DELETE_CROSS_FILTER_GROUP';

export const TOGGLE_CROSS_FILTER_GROUP = 'crossFilter/TOGGLE_CROSS_FILTER_GROUP';

// Get the state of the saved cross filters from the BE
export const getCrossFiltersRequest = ({ insightsBoardId }) => ({
  type: GET_CROSS_FILTERS_REQUEST,
  insightsBoardId,
});

/**
 * Save the cross filters from the BE to the reducer
 * @param {Object} crossFilterGroups The cross filter groups returned by the GET
 */
export const getCrossFiltersSuccess = ({ crossFilterGroups }) => ({
  type: GET_CROSS_FILTERS_SUCCESS,
  crossFilterGroups,
});

// Error retrieving the cross filters from the BE
export const getCrossFiltersFailure = ({ error }) => ({
  type: GET_CROSS_FILTERS_FAILURE,
  error,
});

// Save the current state of the cross filters to the BE
export const saveCrossFiltersRequest = ({ insightsBoardId }) => ({
  type: SAVE_CROSS_FILTERS_REQUEST,
  insightsBoardId,
});

/**
 * Save the cross filters to the BE
 * @param {Object} crossFilterGroups The new cross filters groups returned by the PATCH
 */
export const saveCrossFiltersSuccess = ({ crossFilterGroups }) => ({
  type: SAVE_CROSS_FILTERS_SUCCESS,
  crossFilterGroups,
});

// Error saving the cross filters to the BE
export const saveCrossFiltersFailure = ({ error }) => ({
  type: SAVE_CROSS_FILTERS_FAILURE,
  error,
});

/**
 * Create a new cross filter in the reducer state
 * @param {String} column The name of the column, ex. 'Fare'
 * @param {String} columnType The type of the column, ex. 'string'
 * @param {String} expression The filter expression, ex. 'is less than'
 * @param {String} groupId The id of the existing group to add the new filter to
 * @param {Array} value The value(s) of the filter
 */
export const createCrossFilter = ({ column, columnType, expression, groupId, value = [null] }) => ({
  type: CREATE_CROSS_FILTER,
  column,
  columnType,
  expression,
  groupId,
  value,
});

/**
 * Create a new group in the reducer state
 * @param {{}Array} crossFilters The cross filters to be added to the new group
 * @param {Boolean} enabled Whether the group is enabled or not
 * @param {String} name The name of the new group
 */
export const createCrossFilterGroup = ({ crossFilters = [], enabled = true, name }) => ({
  type: CREATE_CROSS_FILTER_GROUP,
  crossFilters,
  enabled,
  name,
});

/**
 * Update a cross filter in the reducer state
 * @param {String} column The name of the column, ex. 'Fare'
 * @param {String} columnType The type of the column, ex. 'string'
 * @param {String} expression The filter expression, ex. 'is less than'
 * @param {String} groupId The id of the group to find & update the filter
 * @param {Integer} index The index of the cross filter (within the group's array) to be updated
 * @param {Object} updatedCrossFilter The updated cross filter information
 */
export const updateCrossFilter = ({
  column,
  columnType,
  expression,
  groupId,
  index,
  value = [null],
}) => ({
  type: UPDATE_CROSS_FILTER,
  column,
  columnType,
  expression,
  groupId,
  index,
  value,
});

/**
 * Update a group in the reducer state
 * @param {Array} excludedPublications Pubs to add exclusion for in dc_cross_filter_group_scope
 * @param {String} groupId The id of the group to be updated
 * @param {Array} includedPublications Pubs to remove exclusion for in dc_cross_filter_group_scope
 * @param {String} name The new name of the group
 */
export const updateCrossFilterGroup = ({
  excludedPublications,
  groupId,
  includedPublications,
  name,
}) => ({
  type: UPDATE_CROSS_FILTER_GROUP,
  excludedPublications,
  groupId,
  includedPublications,
  name,
});

/**
 * Delete a cross filter in the reducer state
 * @param {Integer} index The index of the cross filter (within the group's array) to be deleted
 * @param {String} groupId The id of the group to delete the filter from
 */
export const deleteCrossFilter = ({ index, groupId }) => ({
  type: DELETE_CROSS_FILTER,
  groupId,
  index,
});

/**
 * Delete a group in the reducer state
 * @param {String} groupId The id of the group to be deleted
 */
export const deleteCrossFilterGroup = ({ groupId }) => ({
  type: DELETE_CROSS_FILTER_GROUP,
  groupId,
});

/**
 * Toggles a group enabled/disabled in the reducer state
 * @param {String} groupId The id of the group to be toggled
 */
export const toggleCrossFilterGroup = ({ groupId }) => ({
  type: TOGGLE_CROSS_FILTER_GROUP,
  groupId,
});
