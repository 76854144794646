import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import isEqual from 'lodash/isEqual';
import React from 'react';
import TextWithTooltip from '../../common/TextWithTooltip';
import DataChatChip from '../../DataChatChip/DataChatChip';
import ColumnTypeIcon from '../../common/ColumnTypeIcon';
import DataChatAutocompleteInput from '../../common/DataChatAutocompleteInput';
import DataChatOutlinedInput from '../../common/DataChatOutlinedInput';
import '../ChartBuilder.scss';
import { getUniqueValuesByLocator } from '../utils/columns';
import {
  COLUMN_TYPES,
  disallowedFilterTypes,
  getFilterPredicateValueCountMap,
  numericColumnTypes,
  shouldExprUseACIfPossible,
} from '../utils/constants';

// Placeholder text for the filter input fields
const FILTER_INPUT_PLACEHOLDERS = {
  COLUMN: 'Select a column',
  EXPRESSION: 'Select a filter',
  VALUE: (columnType) =>
    numericColumnTypes.includes(columnType.toLowerCase()) ? 'Number' : 'Text',
};

/**
 * Get the string representation of a filter. Also used for the filter chip label.
 * @param {Object} o The filter object
 * @param {string} o.column The name of the column
 * @param {string} o.expression The filter expression
 * @param {[]string} o.value The filter value
 * @returns {string} The concatenated name of the filter
 */
export const generateFilterText = ({ column, expression, value }) =>
  `${column} ${expression} ${value}`;

/**
 * Get the name of a filter as a React node.
 * @param {Object} o The filter object
 * @param {string} o.column The name of the column
 * @param {string} o.expression The filter expression
 * @param {[]string} o.value The filter value
 * @returns {React.ReactHTMLElement} The concatenated name of the filter
 */
export const generateFilterName = ({ column, expression, value }) => {
  return (
    <TextWithTooltip
      fontSize="12px"
      text={
        <>
          {`${column} ${expression} `}
          {value
            ?.filter((v) => v)
            ?.map((v) => (
              <span key={v} className="filter-tag">
                {v}
              </span>
            ))}
        </>
      }
    />
  );
};

/**
 * Gets the column info for a given column name
 * @param {string} columnName name of the column
 * @returns {Object} column info
 */
const getColumnByName = (columnsTypes, columnName) => {
  if (!columnsTypes || !columnName) return {};
  return columnsTypes.find((c) => c.name === columnName);
};

/**
 * Extract filter values from the filter transform. The values may be
 * a single value or a range of values.
 * @param {Object} filter The filter transform
 * @returns {Array} [filterValue, filterValue2]
 */
const extractFilterValues = (filter) => {
  if (!filter.value) return [null, null];

  let filterVal0 = null;
  let filterVal1 = null;

  if (Array.isArray(filter.value)) {
    [filterVal0 = null, filterVal1 = null] = filter.value;
  } else {
    filterVal0 = filter.value;
  }

  return [filterVal0, filterVal1];
};

// Default state for the constructed filter
const defaultState = {
  columnType: '',
  column: '',
  disabled: false,
  expression: '',
  value: [null, null],
};

/**
 * Extract the filter transform properties from the transform
 * @param {Array} columnsTypes Array of column type objects
 * @param {Array} transform The transform objects to extract from
 * @returns The extracted properties
 */
const initFilterState = (columnsTypes, editingFilter, transforms) => {
  if (!editingFilter) return defaultState;

  // ToDo: Support editing any filter in the filter group
  const transform = transforms?.[0] ?? {};

  const { column, columnType, expression } = transform;
  const value = extractFilterValues(transform);

  return { columnType, column, expression, value };
};

type Props = {
  columnsTypes: [], // Types for each column in the underlying dataset
  rows: [], // Rows in the underlying dataset
  transforms: [], // Specific transforms to apply to the dataset
  applyTransform(
    type: String, // The type of transform
    column: String, // The column name to transform
    expression: String, // The transform expression ex. 'is equal to'
    value: any, // The values selected for the transform
    columnType: String, // The type for the selected column ex. 'Boolean'
  ): () => void, // Adds a new transform to the array of transforms if it's not a duplicate
  deleteTransform(i: Number): void, // Deletes the transform from the array of transforms at the given index
  updateTransform(i: Number, transform: Object): void, // Updates the transform at the given index
  toggleTransformDisabledAtIndex(i: Number): void, // Toggles the disabled property of the transform at the given index
  displayFilterChips: Boolean, // Whether or not to display the filter chips
  editingFilter: Boolean, // Whether or not we're editing an existing filter
};

class Filter extends React.Component<Props> {
  constructor(props) {
    super(props);

    const { columnType, column, expression, value } = initFilterState(
      props.columnsTypes,
      props.editingFilter,
      props.transforms,
    );

    this.state = {
      columnType,
      column,
      error: {
        valueError: [null, null],
      },
      expression,
      value,
    };
  }

  componentDidUpdate = (prevProps) => {
    if (
      this.props.editingFilter !== prevProps.editingFilter ||
      !isEqual(this.props.transforms, prevProps.transforms)
    ) {
      const { columnType, column, expression, value } = initFilterState(
        this.props.columnsTypes,
        this.props.editingFilter,
        this.props.transforms,
      );

      this.setState({
        columnType,
        column,
        expression,
        value,
      });
    }
  };

  /**
   * Gets the available columns, filtered if we have a filterType selected
   * TODO: Date, Time, and Timestamp are not supported yet, so we filter them out
   * Return all of the columns if we haven't selected a filter type
   * @returns {Array} available columns
   */
  getColumnOptions = () =>
    Array.from(
      new Set(
        this.props.columnsTypes
          .filter((item) => !disallowedFilterTypes.includes(item.type?.toLowerCase()))
          .map((item) => item.name),
      ),
    );

  // Updates the current filter expression if the column changes
  updateFilterExpr = (column, expression) => {
    if (!column || !expression) return '';

    const columnObj = getColumnByName(this.props.columnsTypes, column);
    const columnType = columnObj.type;
    const filterPredicates = Object.keys(getFilterPredicateValueCountMap(columnType));

    // If the new filterPredicates include the current expression, keep it
    if (filterPredicates.includes(expression)) return expression;
    // Otherwise, clear the expression
    return '';
  };

  /**
   * Validate that the user has correctly filled out the value input(s)
   * @param {String} input The user input to validate
   * @returns {String} The error message if the input is invalid, null otherwise
   */
  validateInput = (input) => {
    // Input cannot be invalid if the user has not selected a column
    const columnType = this.state.columnType.toLowerCase();
    if (!columnType || input === '') return null;

    switch (columnType) {
      case COLUMN_TYPES.INTEGER:
        if (!Number.isInteger(Number(input)) || input.endsWith('.')) {
          return 'Must be an integer';
        }
        break;
      case COLUMN_TYPES.FLOAT:
      case COLUMN_TYPES.NUMBER:
        if (Number.isNaN(Number(input))) return 'Must be a number';
        break;
      default:
        break;
    }

    return null;
  };

  render() {
    const { column, columnType, error, expression, value } = this.state;
    const {
      columnsTypes,
      displayFilterChips = true,
      editingFilter,
      rows,
      transforms = [],
    } = this.props;

    const filterPredicates = getFilterPredicateValueCountMap(columnType);
    const numValues = filterPredicates[expression];

    // We only need this information to populate value suggestions
    // Which appears after selecting a column & expression
    let nUnique = 0;
    let uniqueValues = [];
    if (column && columnType && expression) {
      // Locate by index for row data in shape [[]]
      const locator = columnsTypes.map((item) => item.name).indexOf(column);
      uniqueValues = getUniqueValuesByLocator(locator, columnType, rows);
      nUnique = uniqueValues?.length ?? 0;
    }

    /**
     * Computes if values are filled by casting necessary values to booleans
     * @returns {boolean} flag indicating whether values are filled
     */
    const areValuesFilled = () => {
      switch (numValues) {
        case 0:
          return true;
        case 1:
          return Boolean(value[0]);
        case 2:
          return Boolean(value[0]) && Boolean(value[1]);
        default:
          return false;
      }
    };

    /**
     * Determines if autocomplete should be used for providing input options
     */
    const useACInput =
      shouldExprUseACIfPossible(columnType)[expression] &&
      nUnique < 500 &&
      FILTER_INPUT_PLACEHOLDERS.VALUE(columnType).toLowerCase() !== COLUMN_TYPES.DATE;

    const renderColumnWithTooltip = (renderOptionProps, option) => {
      const colType = columnsTypes.find((col) => col.name === option)?.type;
      return (
        <Tooltip title={option} key={option} placement="bottom">
          <li {...renderOptionProps} value={option}>
            <div className="CB-column-option" value>
              <div className="CB-column-option-label">
                <ColumnTypeIcon type={colType} />
                <span>{option}</span>
              </div>
              <span className="CB-column-option-subtext">{columnType}</span>
            </div>
          </li>
        </Tooltip>
      );
    };

    return (
      <>
        <div className="borderless-row">
          <div className="label">Column</div>
          <DataChatAutocompleteInput
            data-cy="filterColumn-input"
            data-testid="filterColumn-input"
            key={column}
            autoHighlight
            placeholder={FILTER_INPUT_PLACEHOLDERS.COLUMN}
            options={this.getColumnOptions()}
            value={column}
            listboxProps={{ style: { maxHeight: '10rem' } }}
            getOptionLabel={(option) => option}
            renderOption={(renderOptionProps, option) =>
              renderColumnWithTooltip(renderOptionProps, option)
            }
            onChange={(_, newSelection) => {
              if (!newSelection) {
                this.setState((prev) => ({
                  column: '',
                  columnType: '',
                  error: { ...prev.error, valueError: [null, null] },
                  expression: '',
                  value: [null, null],
                }));
              } else {
                const columnObj = getColumnByName(this.props.columnsTypes, newSelection);
                this.setState((prev) => ({
                  column: newSelection,
                  columnType: columnObj.type,
                  error: { ...prev.error, valueError: [null, null] },
                  expression: this.updateFilterExpr(newSelection, expression),
                  value: [null, null],
                }));
              }
            }}
          />
        </div>
        <div className="borderless-row">
          <div className="label">Expression</div>
          <DataChatAutocompleteInput
            data-cy="filterExpression-input"
            data-testid="filterExpression-input"
            key={expression}
            autoHighlight
            placeholder={FILTER_INPUT_PLACEHOLDERS.EXPRESSION}
            listboxProps={{ style: { maxHeight: '10rem' } }}
            options={column ? Object.keys(filterPredicates) : []}
            value={expression}
            getOptionLabel={(option) => option}
            onChange={(_, newSelection) => {
              if (!newSelection) {
                this.setState((prev) => ({
                  error: { ...prev.error, valueError: [null, null] },
                  expression: '',
                  value: [null, null],
                }));
              } else {
                this.setState((prev) => ({
                  error: { ...prev.error, valueError: [null, null] },
                  expression: newSelection,
                  value: [null, null],
                }));
              }
            }}
          />
        </div>
        {column && numValues > 0 && (
          // TODO - use a datepicker for dates
          <div className="borderless-row">
            <div className="label">Value</div>
            {/**
             * We want to provide AC whenever convenient.
             * 1000000 unique vals arbitrarily chosen as AC cutoff
             * Probably need to find at which point we run into performance issues
             */}
            {Array.from({ length: numValues }, (_, i) => {
              const setValue = (val) => {
                this.setState((prev) => {
                  // Perform validation and add the new value & error to the correct index
                  const newValue = [...prev.value];
                  const newValueError = [...prev.error.valueError];

                  newValue.splice(i, 1, val);
                  newValueError.splice(i, 1, this.validateInput(val));

                  return {
                    value: newValue,
                    error: { ...prev.error, valueError: newValueError },
                  };
                });
              };
              return useACInput ? (
                <DataChatAutocompleteInput
                  error={Boolean(error.valueError[i])}
                  inlineLabel={error.valueError[i]}
                  data-cy={`filterValue${i}-input`}
                  data-testid={`filterValue${i}-input`}
                  key={`${column}${expression}${i}`}
                  autoHighlight
                  listboxProps={{ style: { maxHeight: '10rem' } }}
                  placeholder={FILTER_INPUT_PLACEHOLDERS.VALUE(columnType)}
                  value={value[i]}
                  getOptionLabel={(option) => option}
                  onInputChange={(__, newValue) => setValue(newValue)}
                  options={uniqueValues}
                  freeSolo
                />
              ) : (
                <DataChatOutlinedInput
                  error={Boolean(error.valueError[i])}
                  label={error.valueError[i]}
                  data-cy={`filterValue${i}-input`}
                  data-testid={`filterValue${i}-input`}
                  key={`${column}${expression}${i}`}
                  type={FILTER_INPUT_PLACEHOLDERS.VALUE(columnType)}
                  listboxProps={{ style: { maxHeight: '10rem' } }}
                  placeholder={FILTER_INPUT_PLACEHOLDERS.VALUE(columnType)}
                  sx={{ width: '100%' }}
                  value={value[i]}
                  getOptionLabel={(option) => option}
                  onChange={(e) => setValue(e.target.value)}
                />
              );
            })}
          </div>
        )}
        {/* The button & chip for adding a new filter */}
        {!editingFilter && (
          <div className="borderless-row-apply-button">
            <Button
              className="apply-button"
              data-testid="filter-apply-button"
              onClick={() => {
                this.props.applyTransform({
                  column,
                  columnType,
                  disabled: false,
                  expression,
                  type: 'filter',
                  value: filterPredicates[expression] === 2 ? value : value.slice(0, 1),
                });
                this.setState({
                  column: '',
                  columnType: '',
                  error: { valueError: [null, null] },
                  expression: '',
                  value: [null, null],
                });
              }}
              color="primary"
              variant="outlined"
              size="small"
              disabled={
                Boolean(error.valueError[0]) ||
                Boolean(error.valueError[1]) ||
                !(column && expression && areValuesFilled())
              }
              style={{ marginLeft: 'auto' }}
            >
              Apply
            </Button>
            {displayFilterChips &&
              transforms
                .filter((t) => !t.expression.startsWith('axis range'))
                .map((t, i) => {
                  const labelText = generateFilterName({
                    column: t.column,
                    expression: t.expression,
                    value: t.value,
                  });
                  return (
                    <DataChatChip
                      id={`transform-${t.type}-${i}`}
                      key={labelText}
                      label={labelText}
                      disabled={false}
                      size="medium"
                      onDelete={() => this.props.deleteTransform(i)}
                      onClick={() => this.props.toggleTransformDisabledAtIndex(i)}
                      active={!t.disabled}
                      styles={{
                        margin: '5px',
                      }}
                    />
                  );
                })}
          </div>
        )}
        {/* The buttons & functionality for editing an existing filter */}
        {editingFilter && (
          <div className="borderless-row-update-button">
            <Button
              className="remove-button"
              data-testid="filter-remove-button"
              onClick={() => this.props.deleteTransform()}
              color="primary"
              variant="outlined"
              size="small"
            >
              Remove
            </Button>
            <Button
              className="apply-button"
              data-testid="filter-save-button"
              onClick={() => {
                this.props.updateTransform({
                  column,
                  columnType,
                  expression,
                  type: 'filter',
                  value: filterPredicates[expression] === 2 ? value : value.slice(0, 1),
                });
                this.setState({
                  column: '',
                  columnType: '',
                  error: { valueError: [null, null] },
                  expression: '',
                  value: [null, null],
                });
              }}
              color="primary"
              variant="outlined"
              size="small"
              disabled={!(column && expression && areValuesFilled())}
              style={{ marginLeft: '5px' }}
            >
              Save
            </Button>
          </div>
        )}
      </>
    );
  }
}

// Set the displayName to be accessed by the container
Filter.displayName = 'Filter';
export default Filter;
