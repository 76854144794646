import { call, getContext, put, select, takeLatest } from 'typed-redux-saga';
import { ChartResponse } from '../../api/chartspace.api';
import { ReduxSagaContext } from '../../configureStore';
import { API_SERVICES } from '../../constants/api';
import { NavigationItemStatus, NavigationTabs } from '../../constants/session';
import { selectChartById, selectCharts } from '../selectors/chartspace.selector';
import { selectDatasetList } from '../selectors/dataspace.selector';
import { selectCurrentSessionNavigationTab, selectSession } from '../selectors/session.selector';
import {
  Chart,
  DcChartIdPayload,
  UpdateChartRequestPayload,
  getChartspaceFailure,
  getChartspaceRequest,
  getChartspaceSuccess,
  setCurrentChart,
  updateChartRequest,
  updateChartSuccess,
} from '../slices/chartspace.slice';
import { setCurrentNavigationTab } from '../slices/session.slice';
import { selectAccessToken } from './selectors';
import { callAPIWithRetry } from './utils/retry';
import { confirmDeleteObject, handleUpdateError } from './utils/update_space_helpers';

const chartResponseToChart = (chartResponse: ChartResponse): Chart => ({
  id: `${chartResponse.dc_chart_id}`,
  dc_dataset_id: chartResponse.dc_dataset_id,
  name: chartResponse.name,
  typeVersion: chartResponse.chart_spec.typeVersion,
  status: chartResponse.status,
  chart_spec: {
    ...chartResponse.chart_spec,
    objectId: `${chartResponse.dc_chart_id}`,
  },
  source: chartResponse.source,
  created: chartResponse.created,
  updated: chartResponse.updated,
});

// fetches the chartspace from the api
export function* getChartspaceRequestWorker() {
  try {
    const session = yield* select(selectSession);

    const accessToken = yield* select(selectAccessToken);
    if (!session) {
      throw new Error('No session id');
    }

    // typed-redux-saga doesn't support getContext.
    // The return type of getContext is unknown so we have to cast it
    const chartspaceService = (yield* getContext(
      API_SERVICES.CHARTSPACE,
    )) as ReduxSagaContext[API_SERVICES.CHARTSPACE];
    const response = yield* callAPIWithRetry({
      apiFn: chartspaceService.getChartspace,
      args: [accessToken, session],
    });
    const chartResponseList = response.data;
    const chartspace: { [dcChartId: string]: Chart } = {};
    Object.values(chartResponseList).forEach((chart) => {
      chartspace[chart.dc_chart_id] = chartResponseToChart(chart);
    });

    const previousCharts = yield* select(selectCharts);
    const chartList = Object.values(chartspace);
    const datasetList = yield* select(selectDatasetList);
    // if the number of charts has changed and the dataset list is empty,
    // that means we refreshed the page. We don't want to navigate to the chartspace in this case
    if (Object.values(previousCharts)?.length !== chartList.length && datasetList.length > 0) {
      const currentNavigationTab = yield* select(selectCurrentSessionNavigationTab);
      // if the number of charts has changed and it is not the initial request
      const newChart = chartList.find(
        (chart) => !(chart.id in previousCharts) && chart.status === NavigationItemStatus.ACTIVE,
      );
      // find the new chart and set the current tab to chart space
      if (newChart && currentNavigationTab !== NavigationTabs.CHART_SPACE) {
        // navigate to the chartspace if the new chart is active
        // and the current tab is not already the chartspace
        yield* put(
          setCurrentNavigationTab({ sessionId: session, tab: NavigationTabs.CHART_SPACE }),
        );
        yield* put(setCurrentChart({ dcChartId: newChart.id }));
      }
    }
    yield* put(getChartspaceSuccess(chartspace));
  } catch (error) {
    if (error instanceof Error) {
      yield* put(getChartspaceFailure({ error }));
    }
  }
}

// changes the status of a chart
export function* updateChartRequestWorker(action: { payload: UpdateChartRequestPayload }) {
  const { payload } = action;
  const { chartSpec, dcChartID, status, name } = payload;
  try {
    if (status === NavigationItemStatus.HIDDEN) {
      const chartByID = yield* select(selectChartById, dcChartID);
      yield* call(confirmDeleteObject, chartByID?.name);
    }

    const sessionID = yield* select(selectSession);
    const accessToken = yield* select(selectAccessToken);
    if (!sessionID || !dcChartID) {
      throw new Error('Need both session and dc_chart IDs');
    }

    const chartspaceService = (yield* getContext(
      API_SERVICES.CHARTSPACE,
    )) as ReduxSagaContext[API_SERVICES.CHARTSPACE];
    const response = yield* call(
      chartspaceService.updateChart,
      accessToken,
      sessionID,
      dcChartID,
      name,
      status,
      chartSpec,
    );

    const chart = chartResponseToChart(response.data);
    yield* put(updateChartSuccess(chart));
  } catch (error) {
    yield* call(handleUpdateError, 'chart', error);
  }
}

// watcher on the setCurrentChart action
// sets the current chart to promoted if it is not already
export function* setCurrentChartWorker(action: { payload: DcChartIdPayload }) {
  const { payload } = action;
  const charts = yield* select(selectCharts);
  const chart = payload.dcChartId !== null ? charts[payload.dcChartId] : null;
  if (chart && chart.status !== NavigationItemStatus.ACTIVE) {
    yield* put(
      updateChartRequest({
        dcChartID: chart.id,
        status: NavigationItemStatus.ACTIVE,
      }),
    );
  }
}

export default function* chartspaceSaga() {
  yield* takeLatest(getChartspaceRequest, getChartspaceRequestWorker);
  yield* takeLatest(updateChartRequest, updateChartRequestWorker);
  yield* takeLatest(setCurrentChart, setCurrentChartWorker);
}
