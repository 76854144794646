import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import React from 'react';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/x-close.svg';

/**
 * A functional component to render a button UI to remove it from IB
 */
const RemovePublicationButton = (props) => {
  const { publicationId, removeItemFromIBLayout, isPopOutChart } = props;

  const onClickHandler = () => {
    removeItemFromIBLayout(publicationId);
  };

  return (
    !isPopOutChart && (
      <MenuItem key="remove" className="Menu-Item-Container" onClick={onClickHandler}>
        <div className="Menu-Item" data-cy="Menu-Item-Remove">
          <CloseIcon className="Menu-Item-Icon" />
          <span className="Menu-Item-Text">Remove publication</span>
        </div>
      </MenuItem>
    )
  );
};

RemovePublicationButton.propTypes = {
  publicationId: PropTypes.number.isRequired,
  removeItemFromIBLayout: PropTypes.func.isRequired,
  isPopOutChart: PropTypes.bool.isRequired,
};

export default RemovePublicationButton;
