import isEqual from 'lodash/isEqual';
import uniqWith from 'lodash/uniqWith';
import { promptColumnTypes } from '../../utils/formatting/type';
import {
  getSuggestionName,
  sortFilenames,
  suggestionFuzzyFilter,
} from '../../utils/suggestions_utils';

// Types of supported input components
export const INPUT_TYPES = {
  SELECT: 'select',
  MULTI_SELECT: 'multi-select',
  MULTI_SELECT_PARTIAL: 'multi-select-partial',
  MULTI_CHECKBOX: 'multi-checkbox',
  TEXTFIELD: 'textfield',
  REPEATING_FORM: 'repeating-form',
  MULTI_TEXTFIELD: 'multi-textfield',
  TEXTBOX: 'textbox',
  TOGGLE_BUTTON: 'toggle-button',
  DATASET_VIEWER: 'dataset-viewer',
  VARIABLE: 'variable',
};
export const PREDICATE_OPTIONS = [
  'is between the values',
  'is not between the values',
  'is equal to',
  'is not equal to',
  'is greater than or equal to',
  'is greater than ',
  'is less than',
  'is less than or equal to',
  'is null',
  'is not null',
];

export const EXTRACT_OPTIONS = [
  'century',
  'day',
  'decade',
  'day of week',
  'day of year',
  'first day of month',
  'first day of week',
  'hour',
  'microsecond',
  'millisecond',
  'minute',
  'month',
  'quarter',
  'second',
  'seconds since epoch',
  'week',
  'year',
  'YYYYMM',
  'YYYYQ',
];

export const CORE_REQUIRED_PAGES = [
  'ROOT',
  'Bar',
  'Scatter',
  'StackedBar',
  'Bubble',
  'LineChart',
  'Donut',
  'Histogram',
  'Box',
  'HorizontalBar',
  'HeatMap',
];

export const PREDICATE_TEMORAL_OPTIONS = [
  'is between the dates',
  'is not between the dates',
  'is after',
  // 'is not after',
  'is before',
  // 'is not before',
  'is null',
  'is not null',
  'is in the last',
  'is not in the last',
  'is equal to',
  'is not equal to',
  'is on or after',
  'is on or before',
];

export const PREDICATE_TEMPORAL_EXPRESSIONS = [
  'the aggregate date maximum',
  'the aggregate date minimum',
  'the value of the column',
  'the date',
];

export const defineSkills = {
  PREDICATE: 'Predicate',
  EXTRACT: 'Extract',
  AGGREGATE: 'Agg',
};

export const LEFT_ALIGHNED_PAGES = [
  'KEEP_SIMPLE',
  'PREDICATE_AND',
  'PREDICATE_OR',
  'DROP_SIMPLE',
  'DROP_AND',
  'DROP_OR',
  'EXPRESSION_NAME_SETTING',
  'SAMPLE_ROOT',
  defineSkills.PREDICATE,
  defineSkills.AGGREGATE,
  defineSkills.EXTRACT,
];

// Page keys for pages can used by all skill recipes.
export const DEFAULT_PAGES = {
  SUBMIT: 'SUBMIT',
  DISABLED: '_DISABLED_',
};

// Plot page names
export const PLOT_PAGES = {
  ROOT: 'ROOT',
  OPTIONS: 'OPTIONS',
  FOREACH: 'FOREACH',
  SUBPLOT: 'SUBPLOT',
  SPLIT: 'SPLIT',
  ALTERNATE_SUBPLOT: 'ALTERNATE_SUBPLOT',
  SLIDING: 'SLIDING',
  ALTERNATE_SLIDING: 'ALTERNATE_SLIDING',
  ALTERNATE_SLIDING_2: 'ALTERNATE_SLIDING_2',
  FILTER: 'FILTER',
  SMOOTH: 'SMOOTH',
};

export const COMMON_TERMS = {
  USING_REFERENCE: 'using reference',
  GROUP: 'group',
  FOR_EACH: 'for each',
  WITH_FILTER: 'with filter',
};

export const VALIDATION_REGEX = {
  PERCENTAGE: /^[1-9][0-9]?$|^100$/,
  COMMA_SEPARATED_COLUMN_NAMES: /^(([a-zA-Z0-9_-])+(\s*,\s*)?)+$/,
  COLUMN_NAME: /^[a-zA-Z0-9_]+$/,
  NUMERIC: /^-?[0-9]*\.?[0-9]+$/,
  POSITIVE_NUMERIC: /^[0-9]*\.?[0-9]+$/,
  COMMA_SEPARATED_NUMERIC_LIST: /^(-?[0-9]*\.?[0-9]+(\s*,\s*)?)+$/,
  POSITIVE_INTEGER: /^[0-9]+$/,
  EXPRESSION_NAME: /^[\sa-zA-Z0-9_-]+$/,
  CHARTLET_NAME: /^[\sa-zA-Z0-9_-]+$/,
  VALUE_OR_COLUMN: /^[\sa-zA-Z0-9_-]+$/,
  ANYTHING: /.*/,
  DATE_REGEX_FULL:
    /^((1|$)([0-2]|$)|(0|$)([1-9]|$))(-|$)(((3|$)([01]|$))|([12]|$)([0-9]|$)|(0|$)([1-9]|$))(-|$)([0-9]|$){4}/,
  DATE_REGEX_PARTIAL: /[0-9]{2}-[0-9]{2}-[0-9]{4}/,
};

// Quick utterance variables to fill
export const QUICK_UTTERANCE_VARIABLES = {
  DATASET_NAME: 'DATASET_NAME',
  SESSION_NAME: 'SESSION_NAME',
};

// Top Menu Item Reference Name for Ava, Ben only
export const MENU_ITEMS_BEN = {
  LOAD: 'Load',
  DATASET: 'Dataset',
  ROW: 'Row',
  COLUMN: 'Column',
  PLOT: 'Plot',
  ML: 'ML',
  DEFINE: 'Define',
  NOTE: 'Note',
  SAVE: 'Save',
};

// For Ava and Ben
export const MENU_ICONS_AVAILABLE_WHEN_NO_DATASET = [
  MENU_ITEMS_BEN.LOAD,
  MENU_ITEMS_BEN.NOTE,
  MENU_ITEMS_BEN.SAVE,
  MENU_ITEMS_BEN.DATASET,
];

export const MENU_ITEMS_AVAILABLE_WHEN_NO_DATASET = [
  'Create',
  'Database',
  'Snapshot',
  'File',
  'URL',
  'Workflow Data',
  'Workflow',
  'Record Note',
  'Save',
  'Save as...',
];

export const DISABLED_TOOLTIP_MESSAGE = 'Please load data before using these skills';
export const DISABLED_TOOLTIP_MESSAGE_WORKING =
  'Please wait till your previous request has completed';

export const SKILLS_TO_EXCLUDE_FOR_USE_UTTERANCE = ['record', 'saveAs', 'workflowData'];

// Plot options pages
export const PLOT_OPTIONS = {
  FOREACH: {
    id: PLOT_PAGES.FOREACH,
    label: 'For each column',
    inputs: [
      [
        {
          id: 'EachOfVar',
          label: 'Column to parition by:',
          options: { autocomplete: true, list: [], filterOut: [COMMON_TERMS.USING_REFERENCE] },
          input_type: INPUT_TYPES.SELECT,
          keyword: ' for each',
          comma_separated: true,
          strong_tagged: false,
          append_keyword: ',',
        },
      ],
    ],
    prev: PLOT_PAGES.OPTIONS,
    next: PLOT_PAGES.OPTIONS,
  },
  FILTER: {
    id: PLOT_PAGES.FILTER,
    label: 'Filter',
    prompt: 'Which column or predicate expression would you like to filter by?',
    inputs: [
      [
        {
          id: 'Filter',
          label: 'Column or expression to filter by.',
          options: {
            autocomplete: true,
            list: [],
            filterOut: [COMMON_TERMS.USING_REFERENCE, COMMON_TERMS.FOR_EACH],
          },
          input_type: INPUT_TYPES.TEXTBOX,
          keyword: ' with filter',
          comma_separated: true,
          strong_tagged: false,
        },
      ],
    ],
    prev: PLOT_PAGES.OPTIONS,
    next: PLOT_PAGES.OPTIONS,
  },
  SPLIT: {
    id: PLOT_PAGES.SPLIT,
    label: 'Split',
    inputs: [
      [
        {
          id: 'splitVar',
          label: 'Column:',
          options: { autocomplete: true, list: [] },
          input_type: INPUT_TYPES.SELECT,
          keyword: 'split by',
          comma_separated: true,
          strong_tagged: false,
          append_keyword: ', ',
        },
      ],
    ],
    prev: PLOT_PAGES.OPTIONS,
    next: PLOT_PAGES.OPTIONS,
  },
  SMOOTH: {
    id: PLOT_PAGES.SMOOTH,
    label: 'Smooth',
    inputs: [
      [
        {
          id: 'smoothing',
          label: 'Use smooth lines.',
          options: {
            autocomplete: false,
            list: ['smoothing'],
          },
          input_type: 'multi-checkbox',
          keyword: 'with',
          comma_separated: false,
          strong_tagged: false,
          append_keyword: ', ',
        },
      ],
    ],
    prev: PLOT_PAGES.OPTIONS,
    next: PLOT_PAGES.OPTIONS,
  },
  // Currently, there are 2 different keywords that trigger AC for subplot.
  // Some plots (eg: bar) need the keyword ", and creating subplots using" for populating AC suggestions
  // while other plots (eg: histogram) need the keyword "and creating subplots using".
  SUBPLOT: {
    id: PLOT_PAGES.SUBPLOT,
    label: 'Create Subplots',
    mutex: [PLOT_PAGES.SLIDING],
    inputs: [
      [
        {
          id: 'slidingVar',
          label: 'Column:',
          options: { autocomplete: true, list: [] },
          input_type: INPUT_TYPES.SELECT,
          keyword: ' and creating subplots using ',
          comma_separated: true,
          strong_tagged: false,
          append_keyword: ', ',
        },
      ],
    ],
    prev: PLOT_PAGES.OPTIONS,
    next: PLOT_PAGES.OPTIONS,
  },
  ALTERNATE_SUBPLOT: {
    id: PLOT_PAGES.ALTERNATE_SUBPLOT,
    label: 'Create Subplots',
    mutex: [PLOT_PAGES.ALTERNATE_SLIDING],
    inputs: [
      [
        {
          id: 'subVar',
          label: 'Column:',
          options: { autocomplete: true, list: [] },
          input_type: INPUT_TYPES.SELECT,
          keyword: ' and creating subplots using ',
          comma_separated: true,
          strong_tagged: false,
          append_keyword: ', ',
        },
      ],
    ],
    prev: PLOT_PAGES.OPTIONS,
    next: PLOT_PAGES.OPTIONS,
  },
  // Currently, there are 3 different keywords that trigger AC for sliding.
  // Some plots (eg: bar) need the keyword ", and sliding by" for populating AC suggestions
  // while other plots (eg: donut) need the keyword ", sliding by" or "and sliding by" (eg: histogram).
  SLIDING: {
    id: PLOT_PAGES.SLIDING,
    label: 'Slider',
    mutex: [PLOT_PAGES.SUBPLOT],
    inputs: [
      [
        {
          label: 'Column:',
          options: { autocomplete: true, list: [] },
          input_type: INPUT_TYPES.SELECT,
          keyword: ' and sliding by ',
          comma_separated: true,
          strong_tagged: false,
          append_keyword: ', ',
        },
      ],
    ],
    prev: PLOT_PAGES.OPTIONS,
    next: PLOT_PAGES.OPTIONS,
  },

  ALTERNATE_SLIDING: {
    id: PLOT_PAGES.ALTERNATE_SLIDING,
    label: 'Slider',
    mutex: [PLOT_PAGES.ALTERNATE_SUBPLOT],
    inputs: [
      [
        {
          label: 'Column:',
          options: { autocomplete: true, list: [] },
          input_type: INPUT_TYPES.SELECT,
          keyword: 'and sliding by ',
          comma_separated: true,
          strong_tagged: false,
          append_keyword: ', ',
        },
      ],
    ],
    prev: PLOT_PAGES.OPTIONS,
    next: PLOT_PAGES.OPTIONS,
  },

  ALTERNATE_SLIDING_2: {
    id: PLOT_PAGES.ALTERNATE_SLIDING_2,
    label: 'Slider',
    mutex: [PLOT_PAGES.SUBPLOT],
    inputs: [
      [
        {
          label: 'Column:',
          options: { autocomplete: true, list: [] },
          input_type: INPUT_TYPES.SELECT,
          keyword: 'sliding by ',
          comma_separated: true,
          strong_tagged: false,
          append_keyword: ', ',
        },
      ],
    ],
    prev: PLOT_PAGES.OPTIONS,
    next: PLOT_PAGES.OPTIONS,
  },
};

export const PREDICATES = {
  KEEP: 'KEEP_ROOT',
  DROP: 'DROP_ROOT',
};

/**
 * Takes raw user input and converts it into a formatted string to be used in
 * the construction of the utterance.
 * @param userInput - raw user input
 * @param commaSeparated - whether to comma separate a list of values
 * @param strongTagged - whether to strong tag the user input
 * @returns formatted user input
 */
export const formatInputValue = ({
  userInput,
  commaSeparated,
  strongTagged,
  emphasisTag = false,
  keepWhitespace = false,
}) => {
  if (Array.isArray(userInput)) {
    // user input is array
    return userInput
      .map((value) => {
        if (strongTagged && value)
          return `<strong>${typeof value === 'string' ? value : value.value}</strong>`;
        return value;
      })
      .join(commaSeparated ? ', ' : ' ');
  } else if (typeof userInput === 'string') {
    // user input is string

    let userInputClean = userInput;
    // Remove whitespace
    if (!keepWhitespace) {
      userInputClean = userInput.replace(/\s*$/, '');
    }
    let tagUserInput = userInput;
    if (userInput && (strongTagged || emphasisTag)) {
      // use original input for tagged values
      if (emphasisTag && (userInput === 'TAB' || userInput === 'SPACE')) {
        tagUserInput = `<em contenteditable="false">${tagUserInput}</em>`;
      }
      if (strongTagged) {
        tagUserInput = `<strong>${tagUserInput}</strong>`;
      }
      return tagUserInput;
    }
    return userInputClean;
  }
  return userInput;
};

/**
 * Checks whether a user given input value is complete (not empty).
 * Note: Checkbox components always return true,
 * because by their nature they are optional
 * @param inputValue - value to check for completeness
 * @param inputType - type of input component
 * @returns true if the inputValue is "completed", false otherwise
 */
export const isCompleted = (inputValue, inputType, regex) => {
  switch (inputType) {
    case INPUT_TYPES.SELECT:
    case INPUT_TYPES.TEXTFIELD:
      if (regex) {
        return inputValue.trim().match(regex) && inputValue !== '';
      }
      return inputValue !== '';
    case INPUT_TYPES.TEXTBOX:
      return inputValue !== '';
    case INPUT_TYPES.MULTI_SELECT:
    case INPUT_TYPES.MULTI_SELECT_PARTIAL:
      return JSON.stringify(inputValue) !== JSON.stringify([]);
    case INPUT_TYPES.CHECKBOX:
    case INPUT_TYPES.MULTI_CHECKBOX:
    case INPUT_TYPES.REPEATING_FORM:
    case INPUT_TYPES.TOGGLE_BUTTON:
    default:
      return true;
  }
};

/**
 * Given an input type returns the initial input value for that input component
 * @param inputType - type of input component
 */
export const getInitialInputValue = (inputType) => {
  switch (inputType) {
    case INPUT_TYPES.SELECT:
    case INPUT_TYPES.TEXTFIELD:
    case INPUT_TYPES.TEXTBOX:
    case INPUT_TYPES.TOGGLE_BUTTON:
      return '';
    case INPUT_TYPES.MULTI_SELECT:
    case INPUT_TYPES.MULTI_CHECKBOX:
    case INPUT_TYPES.MULTI_SELECT_PARTIAL:
      return [];
    case INPUT_TYPES.CHECKBOX:
      return false;
    case INPUT_TYPES.REPEATING_FORM:
      return [];
    default:
      return '';
  }
};

const appendValues = (
  append,
  appendGeneratedCount,
  currentPageValues,
  inputPage,
  inputs,
  index,
) => {
  let value = '';
  if (currentPageValues[inputPage.id]?.value) {
    value = currentPageValues[inputPage.id]?.value;
  } else if (inputPage.default_value) {
    value = inputPage.default_value;
  } else if (inputPage.default_value_generator) {
    value = inputPage.default_value_generator(inputs, currentPageValues, index);
    const counts = appendGeneratedCount[inputPage.keyword_for_repeat];
    appendGeneratedCount[inputPage.keyword_for_repeat] = counts ? counts + 1 : 1;
  }
  if (append[inputPage.keyword_for_repeat]) {
    append[inputPage.keyword_for_repeat].push(value);
  } else {
    append[inputPage.keyword_for_repeat] = [value];
  }
};

const insertValue = (append, appendGeneratedCount, input) => {
  for (const entry of Object.entries(append)) {
    if (
      appendGeneratedCount[entry[0]] !== entry[1].length &&
      entry[0] === input.insertRepeatInput &&
      entry[1].length > 0
    ) {
      return `${entry[0] + entry[1].join(', ')} `;
    }
  }
  return '';
};

// Remove extra space from utterance function
const removeExtraSpace = (string) => {
  // regex to capture <strong> tag
  const taggedStr = string.match(/<strong>([\s\S]+?)<\/strong>/g);

  if (taggedStr !== null) {
    string = string.replace(/  +/g, ' ');

    const taggedModifiedStr = string.match(/<strong>([\s\S]+?)<\/strong>/g);

    for (let i = 0; i < taggedStr.length; i++) {
      string = string.replace(taggedModifiedStr[i], taggedStr[i]);
    }
  } else {
    string = string.replace(/  +/g, ' ');
  }
  return string;
};

/**
 * Returns the composed utterance given the inputs and default order to combine them.
 * If currentPageKey is defined the utterance will only be defined up to before
 * that page's portion of the utterance.
 * @param defaultOrder - default order in which to compose the utterance
 * @param pages - map of page recipes
 * @param currentPageKey - current page being displayed
 * If currentPageValues and inputIndex are specified, the input for
 * @param currentPageValues - optional - user given input values of the current page
 * @param inputIndex - optional - index of the input component to build the AC prompt for
 * @param repeatingFormInputIndex - optional - index of values to stop at for repeating forms
 *
 * @returns - Utterance fromatted for utterance
 */
export const getComposedUtterance = ({
  defaultOrder,
  pages,
  currentPageKey,
  currentPageValues = undefined,
  inputIndex = undefined,
  pageState = undefined,
  currentInput,
}) => {
  let utterance = '';
  const append = {};
  const appendGeneratedCount = {};
  for (let i = 0; i < defaultOrder.length; i++) {
    const pageId = defaultOrder[i];
    const page = pages[pageId];
    const { repeatIndex, appendInputs } = page;

    if (currentPageKey && pageId === currentPageKey) {
      if (inputIndex !== undefined && currentPageValues !== undefined) {
        let inputs = [...page.inputs];
        const appendInput = appendInputs?.[repeatIndex] ? appendInputs?.[repeatIndex] : [];
        if (appendInput) {
          inputs.splice(repeatIndex + 1, 0, ...appendInput);
        }
        inputs = inputs.concat.apply([], inputs);
        for (let j = 0; j < inputs?.length; j++) {
          const input = inputs[j];
          const { id } = input;
          if (append) {
            utterance += insertValue(append, appendGeneratedCount, input);
          }
          // If the input is optional and does not have a value filled in (is not "completed")
          // and the input is NOT the one autocomplete needs to provide suggestions for,
          // do not append its keyword to the utterance. This allows the prompt required for populating
          // autocomplete suggestions to be constructed correctly when some optional inputs are not filled in.
          if (input.ignore_ac_creation !== true) {
            if (j !== inputIndex && input.optional && !currentPageValues[id].completed) {
              utterance += '';
            } else {
              utterance += `${input.keyword} `;
            }

            if (currentInput.id === id) break;

            utterance += `${formatInputValue({
              userInput: currentPageValues[id].value,
              commaSeparated: input.comma_separated,
              strongTagged: input.strong_tagged,
              emphasisTag: input.emphasized_tag,
            })} `;

            if (
              typeof input.append_keyword !== 'undefined' &&
              currentPageValues[input.id]?.completed
            ) {
              utterance += ` ${input.append_keyword} `;
            }
          } else {
            appendValues(append, appendGeneratedCount, currentPageValues, input, inputs, j);
          }
        }
      }
      break;
    }
    if (pageState[pageId] && page.inputs) {
      let inputs = [...page.inputs];
      const appendInput = appendInputs?.[repeatIndex] ? appendInputs?.[repeatIndex] : [];
      if (appendInput) {
        inputs.splice(repeatIndex + 1, 0, ...appendInput);
      }
      inputs = inputs.concat.apply([], inputs);

      for (let k = 0; k < inputs.length; k++) {
        const inputPage = inputs[k];
        if (inputPage.ignore_ac_creation !== true) {
          if (append) {
            utterance += insertValue(append, appendGeneratedCount, inputPage);
          }
          if (currentPageValues[inputPage.id].completed) {
            utterance += `${inputPage.keyword} ${currentPageValues[inputPage.id].value} `;
            // Remove all extra spaces before commas
            utterance = utterance.replace(/ +,/g, ',');

            if (
              typeof inputPage.append_keyword !== 'undefined' &&
              currentPageValues[inputPage.id].completed
            ) {
              utterance += ` ${inputPage.append_keyword} `;
            }
          } else if (typeof inputPage.default_value !== 'undefined') {
            utterance += `${inputPage.default_value} `;
          }
        } else {
          appendValues(append, appendGeneratedCount, currentPageValues, inputPage, inputs, k);
        }
      }
    }
  }
  return utterance.trim();
};

// This function appends extractedItems to the end of the string.
// If there are parital items appended at the end of the string, this function removes them by keyword_for_repeat
// and appends new set of extractedItems.
export const appendAtEnd = (utterance, inputPage, extractedItems) => {
  const replaceTerm = '{replace}';
  const lastIndex =
    utterance.indexOf(inputPage.keyword_for_repeat) + inputPage.keyword_for_repeat?.length;
  let newUtterance = `${utterance.slice(0, lastIndex)} ${replaceTerm}`;
  newUtterance = newUtterance.replace(replaceTerm, extractedItems.join(', '));
  return newUtterance;
};

/**
 *
 * @param {object} o
 * @param {string[]} o.defaultOrder
 * @param {Record<string, UtteranceComposerPageType>} o.pages
 * @param {import('./UtteranceComposer.types').EntityState} o.currentPageValues
 * @returns
 */
export const getComposedUtteranceFinal = ({
  defaultOrder,
  pages,
  currentPageValues = undefined,
}) => {
  let utterance = '';
  const append = {};
  const appendGeneratedCount = {};
  let removeTrailingCommas = true;

  for (let i = 0; i < defaultOrder.length; i++) {
    const pageId = defaultOrder[i];
    const page = pages[pageId];
    const currentInput = page.inputs ? page.inputs : [];
    const appendInputs = page?.appendInputs?.[page.repeatIndex]
      ? page.appendInputs[page.repeatIndex]
      : [];
    let inputs = [...currentInput];
    if (appendInputs) {
      inputs.splice(page.repeatIndex + 1, 0, ...appendInputs);
    }
    inputs = inputs.concat.apply([], inputs);
    for (let k = 0; k < inputs.length; k++) {
      const inputPage = inputs[k];
      if (
        currentPageValues[inputPage.id]?.completed === false &&
        currentPageValues[inputPage.id].optional === false &&
        Object.keys(append)?.length === 0
      ) {
        return utterance;
      }
      if (inputPage.ignore_ac_creation !== true) {
        if (append) {
          utterance += insertValue(append, appendGeneratedCount, inputPage);
        }
        if (currentPageValues[inputPage.id].completed) {
          if (inputPage.keyword) {
            utterance += ` ${inputPage.keyword}`;
          }
          utterance = utterance.trim();
          if (inputPage.earlier_keyword) {
            // run the toAdd thing for each subobject matching "datePart"
            // which should be a constant in "Extract.recpie"
            // then join the outputs. This is for the currentPageValues object
            const itemsExtracted = [];
            for (const property in currentPageValues) {
              if (property.includes(inputPage.entries_to_search)) {
                itemsExtracted.push(
                  `${formatInputValue({
                    userInput: currentPageValues[property].value,
                    commaSeparated: inputPage.comma_separated,
                    strongTagged: inputPage.strong_tagged,
                    emphasisTag: inputPage.emphasized_tag,
                    keepWhitespace: inputPage.allow_commas_and_spaces,
                  })}`,
                );
              }
            }
            const reg = new RegExp(
              `${inputPage.earlier_keyword[0]}[a-zA-Z]+${inputPage.earlier_keyword[1]}`,
            );
            utterance = utterance.replace(
              reg,
              inputPage.earlier_keyword[0] +
                itemsExtracted.join(', ') +
                inputPage.earlier_keyword[1],
            );
          } else {
            utterance += ` ${formatInputValue({
              userInput: currentPageValues[inputPage.id].value,
              commaSeparated: inputPage.comma_separated,
              strongTagged: inputPage.strong_tagged,
              keepWhitespace: inputPage.allow_commas_and_spaces,
              emphasisTag: inputPage.emphasized_tag,
            })}`;
          }
          // Remove all extra spaces before commas
          utterance = utterance.replace(/ +,/g, ',');

          // Remove all the extra spaces
          utterance = removeExtraSpace(utterance);
          if (
            typeof inputPage.append_keyword !== 'undefined' &&
            currentPageValues[inputPage.id].completed
          ) {
            utterance += ` ${inputPage.append_keyword} `;
          }
        } else if (typeof inputPage.default_value !== 'undefined') {
          utterance += ` ${inputPage.default_value} `;
        }
      } else if (inputPage.ignore_ac_creation === true) {
        appendValues(append, appendGeneratedCount, currentPageValues, inputPage, inputs, k);

        // Append comma separated values at end of the string.
        if (inputPage.appendAtEnd && inputPage.keyword_for_repeat) {
          const extractedItems = append[inputPage.keyword_for_repeat];
          if (inputPage.keyword) {
            utterance += ` ${inputPage.keyword}`;
          }
          utterance = appendAtEnd(utterance, inputPage, extractedItems);
        } else if (append) {
          utterance += insertValue(append, appendGeneratedCount, inputPage);
        }
      }
      if (inputPage === inputs[inputs.length - 1]) {
        // Ensuring all the extra space is removed
        utterance = removeExtraSpace(utterance);
        if (inputPage.allow_commas_and_spaces) {
          removeTrailingCommas = false;
        }
      }
    }
  }

  if (!removeTrailingCommas) {
    return utterance;
  }
  // Remove trailing commas and spaces
  return utterance.replace(/\s*,\s*$/, '').trim();
};

export const getDisplayedSuggestionValue = (suggestion) => {
  return `${getSuggestionName(suggestion) ? getSuggestionName(suggestion) : ''}${
    suggestion.description ? suggestion.description : ''
  }`;
};

export const getAutocompleteOptions = ({ options, suggestions, disabled, inputValue }) => {
  if (disabled) return [];
  if (options.autocomplete) {
    let returnValues = suggestions.map((suggestion) => getDisplayedSuggestionValue(suggestion));
    if (options.filterOut?.length > 0) {
      returnValues = returnValues.filter((suggestion) => !options.filterOut.includes(suggestion));
    }
    if (options.filterGenerator) {
      returnValues = options.filterGenerator(returnValues);
    }
    if (options.partialMatch && inputValue) {
      const partialInputValue = inputValue.toLowerCase().trim();
      const filteredSuggestions = uniqWith(suggestions, isEqual).filter((suggestion) =>
        suggestionFuzzyFilter(suggestion, partialInputValue),
      );
      return sortFilenames(filteredSuggestions, partialInputValue);
    }
    return returnValues;
  }
  // Return predefined options list
  return options.list;
};

export const checkSinglePageStatus = (currentPage, entityState) => {
  let inputs = [];
  inputs = inputs.concat.apply([], currentPage.inputs);
  for (const input of inputs) {
    if (!entityState[input.id].completed && !entityState[input.id].optional) {
      return false;
    }
  }
  return true;
};

/**
 * getExtractedColumnName returns a name for a new column that was extracted
 * from an existing column.
 *
 * @param {string} extractFrom name of column that's being extracted from
 * @param {string} datePart signifies how the column is being extracted (e.g. year, decade, YYYYMM)
 * @returns {string} the column name that's being extracted
 */
export const getExtractedColumnName = (extractFrom, datePart) => {
  // capitalize the first letter of each word and remove spaces
  datePart = datePart.split(' ').reduce((exTo, name) => {
    return exTo + name.charAt(0).toUpperCase() + name.slice(1);
  }, '');
  return extractFrom + datePart;
};

export const getDeaultNewColumnNames = (inputs, entityState, index) => {
  let value = '';
  if (entityState[inputs[index - 1]?.id]?.value && entityState[inputs[index - 2]?.id]?.value) {
    entityState[inputs[index - 2].id].value
      .split(' ')
      .forEach((input) => (value += input.charAt(0).toUpperCase() + input.slice(1)));
    const column = entityState[inputs[index - 1].id].value;
    const columnName = column.charAt(0).toUpperCase() + column.slice(1);
    value += columnName;
  } else {
    value = '';
  }
  return value;
};

export const filterPredicate = (suggestions) => {
  if (suggestions.includes('is greater than')) {
    return PREDICATE_OPTIONS;
  } else if (suggestions.includes('is after')) {
    return PREDICATE_TEMORAL_OPTIONS;
  }
  return suggestions;
};

export const filterTemporalPredicate = (suggestions) => {
  if (suggestions.includes('Today')) {
    return PREDICATE_TEMPORAL_EXPRESSIONS;
  }
  return suggestions;
};

/**
 * given the most recent entityState, checks the completeness of each Page.
 * @param entityState
 * @returns {*}
 */
export const checkPageStatus = (entityState, formGroup, currentSkill, pageState) => {
  const newState = { ...pageState };
  const { pages } = formGroup[currentSkill];
  Object.keys(pages).forEach((page) => {
    let currentState = true;
    if (!pages[page].page_navigator) {
      // also check the appended inputs if exists
      let appendInputs = [];
      let inputs = [...pages?.[page]?.inputs];
      if (
        pages[page].repeatIndex !== undefined &&
        pages[page].appendInputs?.[pages[page].repeatIndex]
      ) {
        appendInputs = pages[page].appendInputs[pages[page].repeatIndex];
      }
      if (appendInputs) {
        inputs.splice(page.repeatIndex + 1, 0, ...appendInputs);
      }
      inputs = inputs.concat.apply([], inputs);
      for (const input of inputs) {
        if (!entityState[input.id]?.completed && !input.optional) {
          currentState = false;
        }
      }
      newState[page] = currentState;
    }
  });
  return newState;
};

export const checkColumnType = (prompts) => {
  let columnType = '';
  if (prompts) {
    const prompt = prompts[0];
    if (prompt?.includes(promptColumnTypes.String)) {
      columnType = promptColumnTypes.String;
    } else if (
      prompt?.includes(promptColumnTypes.Float) ||
      prompt?.includes(promptColumnTypes.Integer)
    ) {
      columnType = promptColumnTypes.Number;
    } else if (prompt?.includes(promptColumnTypes.Date)) {
      columnType = promptColumnTypes.Date;
    }
  }
  return columnType;
};

export const TOGGLE_STYLE = {
  minWidth: '48%',
  maxWidth: '48%',
  paddingRight: '2%',
};
