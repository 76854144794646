import React from 'react';
import Loader from '../components/common/Loader';
import { LOADING_SCREEN_CLASS } from '../utils/router_utils';

import '../styles/LoadingScreen.scss';

/**
 * A generic loading screen
 * @param message - The message to display
 */
const LoadingScreen = ({ message }: { message?: string }) => {
  return (
    <div className={LOADING_SCREEN_CLASS} data-cy="LoadingScreen" data-testid="LoadingScreen">
      <div className="LoadingScreen-Text">{message}</div>
      <Loader />
    </div>
  );
};

LoadingScreen.defaultProps = {
  message: '',
};

export default LoadingScreen;
