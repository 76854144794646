import Typography from '@mui/material/Typography';
import React from 'react';
import './index.scss';

type Props = {
  children: React.ReactNode,
  text: String,
  props: Object,
  className: String,
};

const IconWithText = ({ children, text, props }: Props) => {
  return (
    <div {...props} className={`IconWithText-Root ${props?.className || ''}`}>
      {children}
      <Typography component="span" className="IconWithText-Root-Text">
        {text}
      </Typography>
    </div>
  );
};

export default IconWithText;
