import type { PayloadAction } from '@reduxjs/toolkit';
import {
  actionChannel,
  all,
  call,
  cancel,
  delay,
  fork,
  getContext,
  put,
  race,
  select,
  take,
  takeLatest,
  takeLeading,
} from 'typed-redux-saga';
import type { ReduxSagaContext } from '../../configureStore';
import { CONTEXTS } from '../../constants';
import { API_SERVICES } from '../../constants/api';
import { TOAST_ERROR, TOAST_SHORT } from '../../constants/toast';
import {
  type CreateTaskRequestPayload,
  type CreateTaskSuccessPayload,
  type GotActiveTasksPayload,
  type Task,
  TaskStatus,
  TaskType,
} from '../../types/task.types';
import { TaskAlreadyStartedError } from '../../utils/errorHandling/handlers/TaskAlreadyStartedErrorHandler';
import {
  CANCEL_QUERY_FAILURE,
  CANCEL_QUERY_SUCCESS,
  cancelQueryRequest,
} from '../actions/askAva.action';
import { closeDialog } from '../actions/dialog.actions';
import {
  SEND_INTERRUPT_FAILURE,
  SEND_INTERRUPT_SUCCESS,
  sendInterruptRequest,
} from '../actions/interrupt.actions';
import { addToast } from '../actions/toast.actions';
import { selectContext } from '../selectors/context.selector';
import { selectSession } from '../selectors/session.selector';
import { selectCurrentTask, selectCurrentTaskId } from '../selectors/task.selector';
import { startUpdatingPendingDuration } from '../slices/context.slice';
import { initializeSession, resetSession, resetSessionId } from '../slices/session.slice';
import {
  CancelCurrentTask,
  CancelCurrentTaskFailure,
  CancelCurrentTaskSuccess,
  CreateTaskFailure,
  CreateTaskRequest,
  CreateTaskSuccess,
  GotActiveTasks,
  Reset,
} from '../slices/task.slice';
import { selectAccessToken } from './selectors';
import { createAlertChannel, createTaskFailedAlertChannel } from './utils/alert-channels';
import { setAskingContext, setRestingContext, setWorkingContext } from './utils/context';
import { callAPIWithRetry } from './utils/retry';

/** When we try to cancel the current task, but no current task exists. */
export const NO_TASK_TO_CANCEL_ERROR = 'no current task to cancel';
export const FAILED_TO_CANCEL_TASK_ERROR = 'failed to cancel task';

function* fetchActiveTasks(sessionId: string) {
  // initialize the task service
  const taskService = (yield* getContext(API_SERVICES.TASK)) as ReduxSagaContext[API_SERVICES.TASK];

  // get the current access token
  const accessToken = yield* select(selectAccessToken);

  // request active tasks from task service
  const response = yield* callAPIWithRetry({
    apiFn: taskService.getTasks,
    args: [accessToken, sessionId, [TaskStatus.NEW, TaskStatus.STARTED], [TaskType.BLOCKING]],
  });

  // handle errors
  if (response.status !== 200) throw new Error('failed to get active tasks');

  // extract active tasks from response
  const activeTasks = response.data;

  return activeTasks;
}

interface ErrorWithResponse extends Error {
  response?: {
    status: number;
    data: unknown;
  };
}

/** Check if the error has a response object. */
export const isErrorWithResponse = (
  error: ErrorWithResponse | Error | unknown,
): error is ErrorWithResponse => error != null && typeof error === 'object' && 'response' in error;

/** Check if the task a Question. */
export const isQuestionTask = (task: Task): boolean => task.description.startsWith('Question:');

/**
 * Creates a task and sets the context to working if the context is not already set to working.
 * Wrapper for CreateTaskRequest, which has some side-effects and returns the task id.
 */
export function* CreateTask(
  description = '',
): Generator<unknown, { success: boolean; newTaskId: string }> {
  // Try to create a task & wait for creation
  yield put(CreateTaskRequest({ description }));
  const { success } = yield race({
    success: take(CreateTaskSuccess.type),
    failure: take(CreateTaskFailure.type),
  });

  // Success (ready to start work)
  if (success) {
    yield put(startUpdatingPendingDuration());
    const newTaskId = yield* select(selectCurrentTaskId);

    const sessionId = yield* select(selectSession);
    if (!sessionId) throw new Error('missing session id');

    yield* put(GotActiveTasks({ activeTasks: yield* call(fetchActiveTasks, sessionId) }));

    return { success: true, newTaskId };
  }

  // Failure (should not start any work)
  return { success: false, newTaskId: '' };
}

export function* CreateTaskRequestWorker({ payload }: PayloadAction<CreateTaskRequestPayload>) {
  const sessionID: string = yield select(selectSession);
  const accessToken: string = yield select(selectAccessToken);

  try {
    // typed-redux-saga doesn't support getContext.
    // The return type of getContext is unknown so we have to cast it
    const taskService = (yield* getContext(
      API_SERVICES.TASK,
    )) as ReduxSagaContext[API_SERVICES.TASK];
    const response = yield* call(
      taskService.postTask,
      accessToken,
      sessionID,
      payload.taskType,
      payload.timeout,
      payload.parentTaskId,
      payload.description,
    );
    // handle failed request
    if (response.status !== 200 || !response.data.id) {
      const error: ErrorWithResponse = new Error('Failed to create task');
      error.response = {
        status: response.status,
        data: response.data,
      };
      throw error;
    }
    // handle response
    yield* put(
      CreateTaskSuccess({
        isQuestion: isQuestionTask(response.data),
        newTaskId: response.data.id,
      }),
    );
  } catch (error: unknown) {
    if (isErrorWithResponse(error) && error.response?.status === 409) {
      // handle 409 error
      yield* put(
        addToast({
          message: 'The server is still working on your last request. Please try again later',
          toastType: TOAST_ERROR,
          length: TOAST_SHORT,
        }),
      );
    } else {
      yield* call(createTaskFailedAlertChannel, error);
    }
    // finally put the task creation failure
    yield* put(CreateTaskFailure());
  }
}

/**
 * Handle task creation failures.
 *
 * Set the PRE_TASK context back to RESTING.
 * We must do this because we will not handle this case via the CheckActiveTasks polling.
 */
export function* CreateTaskFailureWorker() {
  yield call(setRestingContext);
}

/**
 * Handles task cancellation successes.
 *
 * Set the PRE_TASK context back to RESTING.
 * We must do this because we will not handle this case via the CheckActiveTasks polling.
 */
export function* CancelCurrentTaskSuccessWorker() {
  yield call(setRestingContext);
}

/**
 * Handle task creation successes.
 *
 * Set the PRE_TASK context to WORKING.
 * We must do this because we will not handle this case via the CheckActiveTasks polling.
 */
export function* CreateTaskSuccessWorker({ payload }: PayloadAction<CreateTaskSuccessPayload>) {
  yield call(payload.isQuestion ? setAskingContext : setWorkingContext);
}

/**
 * Cancel a task with a 'New' status.
 * @param task The current task.
 */
export function* CancelNewTask(task: Task) {
  // get the access token
  const accessToken = yield* select(selectAccessToken);

  // get the task service
  const tasks = (yield* getContext(API_SERVICES.TASK)) as ReduxSagaContext[API_SERVICES.TASK];

  // try cancelling the task
  try {
    // request to cancel the task
    yield* call(tasks.cancelTask, accessToken, task.sessionId, task.id);

    // put success
    yield put(CancelCurrentTaskSuccess());
  } catch (error: unknown) {
    // throw TaskAlreadyStartedError
    if (error instanceof TaskAlreadyStartedError) throw error;

    // put failure
    yield put(CancelCurrentTaskFailure({ error }));
  }
}

/**
 * Cancel a task with a 'Started' status.
 * @param task The task to cancel
 */
export function* CancelStartedTask(task: Task) {
  // get context
  const context = yield* select(selectContext);

  switch (context) {
    case CONTEXTS.ASKING: {
      // cancel using the nl2code execution cancellation
      yield put(cancelQueryRequest({ task }));
      break;
    }
    default:
      // cancel using the skill cancellation
      yield put(sendInterruptRequest());
      break;
  }
}

/**
 * Chose our task cancellation route based on the task's status.
 * @param task The task to cancel.
 */
export function* CancelTask(task: Task | null) {
  // if there is no task to cancel, return early
  if (task === null) return;

  // cancel the task based on its status
  if (task.taskStatus === TaskStatus.NEW) {
    // try cancelling the new task
    try {
      // cancel the new task
      yield* call(CancelNewTask, task);
    } catch (error: unknown) {
      // if the task has already started, cancel the started task instead
      if (error instanceof TaskAlreadyStartedError) yield* call(CancelStartedTask, task);
    }
  } else if (task.taskStatus === TaskStatus.STARTED) {
    // cancel the started task
    yield* call(CancelStartedTask, task);
  }
}

/** Cancels the current task. */
export function* CancelCurrentTaskWorker() {
  // get current task
  const currentTask = yield* select(selectCurrentTask);

  try {
    // cancel current task
    yield* fork(CancelTask, currentTask);

    // race downstream actions
    const { success, cancelQueryFailure, cancelSkillFailure } = yield* race({
      // Cancelled task with 'New' status
      success: take(CancelCurrentTaskSuccess),
      failure: take(CancelCurrentTaskFailure),
      // Cancelled nl2code execution
      cancelQuerySuccess: take(CANCEL_QUERY_SUCCESS),
      cancelQueryFailure: take(CANCEL_QUERY_FAILURE),
      // Cancelled skill
      cancelSkillSuccess: take(SEND_INTERRUPT_SUCCESS),
      cancelSkillFailure: take(SEND_INTERRUPT_FAILURE),
    });

    // put action based on the result
    if (success) return; // early return to prevent putting a second success action
    else if (cancelQueryFailure || cancelSkillFailure)
      // failed to cancel task, put failure
      yield put(CancelCurrentTaskFailure({ error: FAILED_TO_CANCEL_TASK_ERROR }));
  } catch (error: unknown) {
    yield put(CancelCurrentTaskFailure({ error }));
  }

  // task cancelled, put success
  yield put(CancelCurrentTaskSuccess());
}

/**
 * This saga prompts the user to cancel the expired tasks.
 *
 * @param tasks - expired tasks
 */
export function* HandleExpiredTasks(tasks: Task[]) {
  // prompt the user to cancel the expired tasks
  const alertChannel = yield* call(createAlertChannel, {
    title: '',
    descriptions: ['A skill has expired. Do you want to cancel it?'],
    buttons: [
      {
        key: 'no',
        label: 'No',
      },
      {
        key: 'yes',
        label: 'Yes',
        primary: true,
      },
    ],
    id: 'expired-tasks',
    dialogType: 'expired-tasks',
  });

  // wait for the user to cancel the tasks or navigate away
  const userAction = yield* take(alertChannel);

  /** Whether the dialog should close without cancelling the tasks. */
  const shouldClose = !userAction || userAction === 'no';

  // if the user doesn't cancel the tasks, return
  if (shouldClose) {
    yield* put(closeDialog());
    return;
  }

  // cancel all expired tasks
  yield* all(tasks.map((t) => call(CancelTask, t)));

  // close the dialog
  yield* put(closeDialog());
}

/**
 * This saga checks for expired tasks and prompts the user when they are found.
 *
 * After prompting the user, the saga will keep checking for expired tasks
 * until they are resolved.
 *
 * If new expired tasks are found, the user will be prompted again.
 */
export function* CheckForExpiredTasks() {
  /** Set of task IDs that have been seen by the user. */
  const seen = new Set<string>();

  // loop forever while checking active task expiration times
  while (true) {
    const { payload } = yield* take<typeof GotActiveTasks>(GotActiveTasks.type);
    const active = (payload?.activeTasks ?? []) as Task[];

    const expired = active.filter(
      (t) => t.expirationTime && new Date(t.expirationTime) < new Date(),
    );

    // if there are no expired tasks or the user has already seen them, continue
    if (expired.length === 0 || expired.every((t) => seen.has(t.id))) continue;

    // update the set of seen expired tasks
    expired.forEach((t) => seen.add(t.id));

    // block until the user has resolved the new expired tasks
    yield call(HandleExpiredTasks, expired);
  }
}

export function* ActiveTasksPoller() {
  // if there is no session id, throw an error
  const sessionId = yield* select(selectSession);
  if (!sessionId) throw new Error('missing session id');

  // create channels for session reset
  const resetSessionChannels = [
    yield* actionChannel(resetSession.type),
    yield* actionChannel(resetSessionId.type),
  ];

  // start saga to handle expired tasks
  const expiredTasksHandler = yield* fork(CheckForExpiredTasks);

  /** Minimum, current, and maximum time values to use when polling for active tasks. */
  const t = {
    min: 250, // .25 seconds
    /** Milliseconds to wait between polls. */
    val: 250,
    max: 3000, // 3 seconds
  };

  // forever loop to poll active tasks
  while (true) {
    const activeTasks = yield* call(fetchActiveTasks, sessionId);
    // put the active tasks into the store
    yield* put(GotActiveTasks({ activeTasks }));

    const { stopPoll } = yield* race({
      shouldPoll: delay(t.val),
      stopPoll: race(resetSessionChannels.map((channel) => take(channel))),
    });

    // if we should not poll, break the loop
    if (stopPoll) break;

    /**
     * If there's any active task, reset the delay time.
     * Otherwise increase the delay time by 10% until it reaches the max.
     */
    t.val = activeTasks.length > 0 ? t.min : Math.min(t.val * 1.1, t.max);
  }

  // close the channels
  for (const channel of resetSessionChannels) channel.close();

  // cancel the expired tasks handler
  yield cancel(expiredTasksHandler);

  // reset the task state
  yield* put(Reset());
}

/**
 * Use the ActiveTaskPoller to determine the context status.
 *
 * We maintain a PRE_TASK context because we expect a task to be started soon,
 * and we don't want to accidentally switch to a resting context.
 */
export function* CheckActiveTasks({ payload }: PayloadAction<GotActiveTasksPayload>) {
  // If we're in the pre-task context, we expect that a task will be started soon
  // So we don't want to change the context yet
  const context = (yield select(selectContext)) as string;
  const isPreContext = context === CONTEXTS.PRE_TASK;
  if (isPreContext) return;

  // If there are active tasks, we want to set the context to working
  // If there aren't active tasks, we want to set the context to resting
  const hasActiveTasks = Boolean(payload.activeTasks?.length);

  // We also need to determine if we have a skill or a question running
  // In order to resolve the correct context for cancellation purposes
  const isQuestion = payload.activeTasks?.some(isQuestionTask);

  yield call(
    !hasActiveTasks ? setRestingContext : isQuestion ? setAskingContext : setWorkingContext,
  );
}

export default function* taskSaga() {
  yield* takeLatest(CreateTaskRequest.type, CreateTaskRequestWorker);
  yield* takeLatest(CreateTaskFailure.type, CreateTaskFailureWorker);
  yield* takeLatest(CreateTaskSuccess.type, CreateTaskSuccessWorker);
  yield* takeLatest(CancelCurrentTaskSuccess.type, CancelCurrentTaskSuccessWorker);
  yield* takeLatest(initializeSession.type, ActiveTasksPoller);
  yield* takeLeading(CancelCurrentTask.type, CancelCurrentTaskWorker);
  yield* takeLatest(GotActiveTasks.type, CheckActiveTasks);
}
