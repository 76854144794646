import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { connect } from 'react-redux';
import { CMD, CONTEXTS } from '../../constants';
import { isWorkflowEditorPage } from '../../constants/paths';
import { sendMessageRequest } from '../../store/actions/messages.actions';
import { closePopOutModal } from '../../store/actions/popout.actions';
import { selectCurrentLocation } from '../../store/sagas/selectors';
import {
  selectContext,
  selectIsReplaying,
  selectIsStepwise,
  selectLastStep,
} from '../../store/selectors/context.selector';
import '../../styles/DataChatLink.scss';
import { focusOnTextbox } from '../../utils/textbox';

/**
 * This component is used to render hyperlink syted text buttons that when clicked
 * submits a DataChat step.
 */
const DataChatLink = (props) => {
  let command = props.command || props.children;
  if (Array.isArray(command)) {
    command = command[0] + ReactDOMServer.renderToStaticMarkup(command[1]);
  }
  const isYesNoCancel =
    typeof command === 'string' && [CMD.YES, CMD.NO, CMD.CANCEL].includes(command.toLowerCase());

  let title = '';
  if (props.tooltip) {
    title = props.tooltip;
  } else if (props.disable) {
    title = 'This link is no longer active.';
  } else if (props.isWorkflowEditor && !isYesNoCancel) {
    // TODO (Refactor) - Disabling links in the editor will no longer be needed following #19106
    title = 'This link is disabled during this replay.';
  } else if (props.isReplaying && !props.isStepwise) {
    title = 'This link will be activated when the replay has finished.';
  } else {
    title = `Click to ${props.verb || 'run'} the step "${command}"`;
  }

  const disabled =
    props.disable ||
    (props.isWorkflowEditor && !isYesNoCancel) ||
    (props.isReplaying && !props.isStepwise) ||
    props.context !== CONTEXTS.REST;

  const onClick = (e) => {
    if (disabled) {
      // do nothing if disabled
    } else if (props.handleClick !== undefined) {
      // custom handle click behavior
      e.stopPropagation(); // avoid highlighting the client message
      props.handleClick();
    } else {
      // default behavior
      e.stopPropagation(); // avoid highlighting the client message
      if (props.disable || (props.isReplaying && !props.isStepwise)) return;
      // if the popup is open, close it.
      props.closePopOutModal();
      props.sendMessageRequest({ message: command });
      focusOnTextbox();
      title = `Click to ${props.verb} the step "${command}"`;
    }
  };

  return (
    <span
      className={classNames('DataChatLink', 'link', { disabled })}
      onClick={onClick}
      title={title}
    >
      {props.children}
    </span>
  );
};

DataChatLink.propTypes = {
  children: PropTypes.node.isRequired,
  command: PropTypes.string,
  disable: PropTypes.bool,
  tooltip: PropTypes.string,
  verb: PropTypes.string,
  isReplaying: PropTypes.bool,
  isStepwise: PropTypes.bool,
  isWorkflowEditor: PropTypes.bool,
  handleClick: PropTypes.func,
  closePopOutModal: PropTypes.func.isRequired,
  sendMessageRequest: PropTypes.func.isRequired,
  context: PropTypes.string.isRequired,
};

DataChatLink.defaultProps = {
  command: undefined,
  disable: false,
  isReplaying: false,
  isStepwise: false,
  isWorkflowEditor: false,
  tooltip: '',
  verb: 'run',
  handleClick: undefined,
};

const mapStateToProps = (state) => ({
  lastStep: selectLastStep(state),
  isReplaying: selectIsReplaying(state),
  isStepwise: selectIsStepwise(state),
  context: selectContext(state),
  isWorkflowEditor: isWorkflowEditorPage(selectCurrentLocation(state)),
});

export default connect(mapStateToProps, {
  sendMessageRequest,
  closePopOutModal,
})(DataChatLink);
