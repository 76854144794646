export const MODIFY_RECIPE_START_LINE =
  '++ <Chart Modify Start: Do not remove this and the lines starting with "++" below>';
export const MODIFY_RECIPE_END_LINE = '++ <Chart Modify End>';

export const CHART_RECIPE_START_LINE =
  '++ <Chart Recipe Start: Do not remove this and the lines starting with "++" below>';
export const CHART_RECIPE_END_LINE = '++ <Chart Recipe End>';

export const VIZ_RECIPE_START_LINE =
  '++ <Chart Recipe Start (Visualize): Do not remove this and the lines starting with "++" below>';
export const VIZ_RECIPE_END_LINE = '++ <Chart Recipe End>';

export const COMMENT_LINE_START = '--';
export const MODIFIER_LINE_START = '++';
// NOTE: Update this regex if the above constants change
export const NON_UTTERANCE_PATTERN = /^(\+\+|--).*$/;

export const CHART_MODIFY = 'chart_modify';
export const CHART_RECIPE = 'chart_recipe';
export const VIZ_RECIPE = 'viz_recipe';

export const WORKFLOW_VERSION_STATUSES = {
  GREEN: 'green', // 'raw_text_verified' = true, and 'edited' = false
  YELLOW: 'yellow', // 'raw_text_verified' = false, and 'edited' = false
  RED: 'red', // 'edited' = true
};

export const WORKFLOW_VERSION_TYPES = {
  COMPLETE: 'complete',
  PARENT: 'parent',
  TRIMMED: 'trimmed',
};

export const SKILL_METADATA_SCHEMA = () => ({
  skill_uuid: null,
  skill: null,
  kwargs: {},
  utterance: '',
  inputs: {},
  outputs: {},
  recipe: {},
  answers: {},
  current_datasets: {},
  session_startup: false,
  is_comment: false,
});
