/* Session Constants */
import AutoFixHigh from '@mui/icons-material/AutoFixHigh';
import PieChart from '@mui/icons-material/PieChart';
import TableChart from '@mui/icons-material/TableChart';

// no default export for SvgIconTypeMap so need to disable eslint rule
// eslint-disable-next-line
import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/types';
import { openLoadCard } from '../store/slices/loadCard.slice';
import { AppDispatch } from '../configureStore';
import { openChartBuilder } from '../store/actions/chart_builder.actions';
import {
  ButtonOptions,
  connectionButtonDefinition,
  uploadButtonDefinition,
} from './buttonDefinitions';

export const UNNAMED_SESSION: string = 'Unnamed';

export const SESSION_PREFIXES: { [key: string]: string } = {
  TESTING: 'Testing',
  REFRESH: 'Refresh',
};

export enum NavigationTabs {
  DATA_SPACE = 'Data Space',
  CHART_SPACE = 'Chart Space',
  DATA_ASSISTANT = 'Data Assistant',
  NO_TAB_SELECTED = 'No Tab Selected',
}

export const NAVIGATION_TABS_INFO: {
  [key: string]: {
    Icon: OverridableComponent<SvgIconTypeMap>;
    externalName: string;
  };
} = {
  [NavigationTabs.DATA_SPACE]: {
    Icon: TableChart,
    externalName: 'Data tab',
  },
  [NavigationTabs.CHART_SPACE]: {
    Icon: PieChart,
    externalName: 'Chart tab',
  },
  [NavigationTabs.DATA_ASSISTANT]: {
    Icon: AutoFixHigh,
    externalName: 'Data Assistant',
  },
};

// This set includes all tabs that have objects (currently dataspace and chartspace)
export const TABS_WITH_OBJECTS: Set<string> = new Set([
  NavigationTabs.DATA_SPACE,
  NavigationTabs.CHART_SPACE,
]);

export const OBJECT_TYPES_BY_TAB: {
  [key: string]: string;
} = {
  [NavigationTabs.CHART_SPACE]: 'Chart',
  [NavigationTabs.DATA_SPACE]: 'Dataset',
};

export const NAVIGATION_SLIDE_TIME: number = 75;
export const NAVIGATION_POINTER_SIZE: number = 10;

export enum NavigationItemStatus {
  ACTIVE = 'active',
  AVAILABLE = 'available',
  HIDDEN = 'hidden',
}

export enum NavigationItemSource {
  SKILL = 'skill',
  DATA_ASSISTANT = 'data_assistant',
  OTHER = 'other',
}

export const SOURCE_TITLE_MAP: Record<string, string> = {
  [NavigationItemSource.SKILL]: 'User',
  [NavigationItemSource.DATA_ASSISTANT]: 'Data Assistant',
  [NavigationItemSource.OTHER]: 'Other',
};

/**
 * Definition for the complex buttons in the navigation list.
 * These define the 'New Object' button functionality in the dataspace & chartspace
 * We should have 1 buttonOptions object for each tab in the NavigationTabs enum
 */
export const navigationNewButtonDict: Record<string, ButtonOptions> = {
  [NavigationTabs.DATA_SPACE]: {
    primary: {
      action: (dispatch: AppDispatch) => dispatch(openLoadCard({ showWhileLoading: true })),
    },
    dropdown: {
      Connect: connectionButtonDefinition,
      Upload: uploadButtonDefinition,
    },
  },
  [NavigationTabs.CHART_SPACE]: {
    primary: { action: (dispatch: AppDispatch) => dispatch(openChartBuilder()) },
  },
  [NavigationTabs.DATA_ASSISTANT]: {},
  [NavigationTabs.NO_TAB_SELECTED]: {},
};
