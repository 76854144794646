import { AxiosError } from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectExperimentalFlag } from '../../../store/sagas/selectors';
import ChartBuilderContainer from '../../ChartBuilder/ChartBuilderContainer';
import ChartEditButton from './ButtonRow/ButtonColumn/ChartEditButton';
import DrillThroughButton from './ButtonRow/ButtonColumn/DrillThroughButton';
import LineBreak from './ButtonRow/ButtonColumn/LineBreak';
import PanModeButton from './ButtonRow/ButtonColumn/PanModeButton';
import RefreshButton from './ButtonRow/ButtonColumn/RefreshButton';
import RemovePublicationButton from './ButtonRow/ButtonColumn/RemovePublicationButton';
import SliderButton from './ButtonRow/ButtonColumn/SliderButton';
import ToolboxButton from './ButtonRow/ButtonColumn/ToolboxButton';
import ButtonColumnContainer from './ButtonRow/ButtonColumnContainer';
import CancelButton from './ButtonRow/CancelButton';
import CrossFilterIndicator from './ButtonRow/CrossFilterIndicator';
import PopOutButton from './ButtonRow/PopOutButton';
import RefreshStatus from './ButtonRow/RefreshStatus';
import WorkflowButton from './ButtonRow/WorkflowButton';
import ButtonRowContainer from './ButtonRowContainer';
import HeaderInfo from './HeaderInfo';
import InsightsBoardStateProvider from './InsightsBoardStateProvider';
import VisualCaption from './VisualCaption';
import VisualContainer from './VisualContainer';
import VisualDisplayContainer from './VisualDisplayContainer';
import VisualDisplayFilter from './VisualDisplayFilter';
import VisualHeaderContainer from './VisualHeaderContainer';
import { shouldOpenChartBuilder } from './helpers/utils';

/**
 * A component to replace orginal chart for IB, This component needs further refactor in the future
 */
const InsightsBoardVisual = (props) => {
  const {
    objectId,
    data,
    publicationId,
    messageType,
    messageTypeVersion,
    isRefreshing,
    lastRefreshTime,
    lastRefreshFailed,
    refreshMessage,
    handleRefresh,
    hasChartLinked,
    userHasPermissions,
    userCanModify,
    modifyChartCallback,
    insightsBoardEditMode,
    workflowId,
    clientId,
    secret,
    useSnapshot,
    removeItemFromIBLayout,
    isDragInProgress,
    isPopOutChart,
    editAnnotation,
    editCrossFilters,
    setEditingModal,
    fontSizes,
    customization,
    insightsBoardId,
    isInsightsBoardCreator,
    error,
    isLoading,
    isFailed,
    rowCount,
    updateRowCount,
    pipelinerDatasetId,
  } = props;
  const devMode = useSelector(selectExperimentalFlag);

  return (
    <InsightsBoardStateProvider
      data={data}
      isPopOutChart={isPopOutChart}
      setEditingModal={setEditingModal}
      customization={customization}
      publicationId={publicationId}
      messageType={messageType}
      messageTypeVersion={messageTypeVersion}
    >
      {({
        sliderPlaying,
        setSliderPlaying,
        showModeBar,
        toggleModeBar,
        isPanMode,
        togglePanMode,
        showModalPng,
        updateModalPng,
        tableTitle,
        updateTableTitle,
        chartEditingMode,
        showEditMenu,
        keepChanges,
        toggleChartEditingModeAndClosePanMode,
        openIBFontMenu,
        toggleIBFontMenu,
      }) => (
        <VisualContainer
          objectId={objectId}
          contextId={publicationId}
          messageType={messageType}
          isInsightsBoard
        >
          <VisualHeaderContainer
            messageType={messageType}
            isInsightsBoard
            chartEditingMode={chartEditingMode}
            isDragInProgress={isDragInProgress}
          >
            <HeaderInfo
              objectId={objectId}
              messageType={messageType}
              data={data}
              tableTitle={tableTitle}
              updateTableTitle={(newName) => updateTableTitle({ newName, publicationId })}
              isInsightsBoard
              rowCount={rowCount}
            />
            <ButtonRowContainer>
              <RefreshStatus
                isRefreshing={isRefreshing}
                lastRefreshFailed={lastRefreshFailed}
                lastRefreshTime={lastRefreshTime}
                refreshMessage={refreshMessage}
                hasChartLinked={hasChartLinked}
                userHasPermissions={userHasPermissions}
                handleRefresh={handleRefresh}
                isPopOutChart={isPopOutChart}
              />
              <CancelButton
                showButton={isPanMode}
                onClick={togglePanMode}
                tooltipLabel="Leave Pan Mode"
                dataCy="cancel-pan-mode-button"
              />
              <CrossFilterIndicator
                data={data}
                editCrossFilters={editCrossFilters}
                messageType={messageType}
                messageTypeVersion={messageTypeVersion}
                publicationId={publicationId}
              />
              <WorkflowButton
                hasChartLinked={hasChartLinked}
                insightsBoardEditMode={insightsBoardEditMode}
                messageType={messageType}
                workflowId={workflowId}
                publicationId={publicationId}
                useSnapshot={useSnapshot}
                clientId={clientId}
                secret={secret}
                isPopOutChart={isPopOutChart}
              />
              <PopOutButton
                objectId={objectId}
                messageType={messageType}
                isPopOutChart={isPopOutChart}
                isInsightsBoard
              />
              <ButtonColumnContainer
                chartEditingMode={chartEditingMode}
                data={data}
                frames={data.frames}
                isInsightsBoard
                isInsightsBoardCreator={isInsightsBoardCreator}
                isPopOutChart={isPopOutChart}
                messageType={messageType}
                messageTypeVersion={messageTypeVersion}
              >
                <RefreshButton
                  isRefreshing={isRefreshing}
                  lastRefreshFailed={lastRefreshFailed}
                  lastRefreshTime={lastRefreshTime}
                  messageType={messageType}
                  refreshMessage={refreshMessage}
                  hasChartLinked={hasChartLinked}
                  userHasPermissions={userHasPermissions}
                  handleRefresh={handleRefresh}
                  isPopOutChart={isPopOutChart}
                />
                <RemovePublicationButton
                  publicationId={publicationId}
                  removeItemFromIBLayout={removeItemFromIBLayout}
                  isPopOutChart={isPopOutChart}
                />
                <SliderButton
                  messageType={messageType}
                  frames={data.frames}
                  sliderPlaying={sliderPlaying}
                  setSliderPlaying={setSliderPlaying}
                />
                <LineBreak
                  isInsightsBoard
                  isPopOutChart={isPopOutChart}
                  messageType={messageType}
                  prevComponentName="SliderButton"
                />
                {devMode && (
                  <DrillThroughButton
                    data={data}
                    messageType={messageType}
                    messageTypeVersion={messageTypeVersion}
                    isLoading={isLoading}
                  />
                )}
                <ChartEditButton
                  chartEditingMode={chartEditingMode}
                  data={data}
                  editAnnotation={editAnnotation}
                  isInsightsBoard
                  isInsightsBoardCreator={isInsightsBoardCreator}
                  messageType={messageType}
                  messageTypeVersion={messageTypeVersion}
                  toggleChartEditingMode={toggleChartEditingModeAndClosePanMode}
                  toggleIBFontMenu={toggleIBFontMenu}
                  isLoading={isLoading}
                />
                <ToolboxButton
                  messageType={messageType}
                  messageTypeVersion={messageTypeVersion}
                  data={data}
                  toggleModeBar={toggleModeBar}
                />
                <PanModeButton
                  messageType={messageType}
                  messageTypeVersion={messageTypeVersion}
                  data={data}
                  togglePanMode={togglePanMode}
                />
              </ButtonColumnContainer>
            </ButtonRowContainer>
          </VisualHeaderContainer>
          <VisualDisplayContainer
            contextId={publicationId}
            error={error}
            isInsightsBoard
            isFailed={isFailed}
            messageType={messageType}
            messageTypeVersion={messageTypeVersion}
          >
            <VisualDisplayFilter
              chart={{ data, type: messageType, typeVersion: messageTypeVersion }}
              chartEditingMode={chartEditingMode}
              contextId={publicationId}
              fontSizes={fontSizes}
              isLoading={isLoading}
              isFailed={isFailed}
              insightsBoardId={insightsBoardId}
              isInsightsBoard
              isInsightsBoardCreator={isInsightsBoardCreator}
              keepChanges={keepChanges}
              objectId={objectId}
              openIBFontMenu={openIBFontMenu}
              publicationId={publicationId}
              rowCount={rowCount}
              setSliderPlaying={setSliderPlaying}
              showEditMenu={showEditMenu}
              showModalPng={showModalPng}
              showModeBar={showModeBar}
              isPanMode={isPanMode}
              sliderPlaying={sliderPlaying}
              toggleChartEditingMode={toggleChartEditingModeAndClosePanMode}
              toggleIBFontMenu={toggleIBFontMenu}
              toggleModeBar={toggleModeBar}
              updateModalPng={updateModalPng}
              isPopOutChart={isPopOutChart}
              updateRowCount={updateRowCount}
            />
            {shouldOpenChartBuilder({
              data,
              chartEditingMode,
              messageType,
              messageTypeVersion,
            }) && (
              <ChartBuilderContainer
                datasetName={data.values.reference.params.dataset}
                datasetVersion={data.values.reference.params.version}
                dcSpec={data}
                chartEditingMode
                objectId={objectId}
                toggleChartEditingMode={toggleChartEditingModeAndClosePanMode}
                insightsBoardId={insightsBoardId}
                isSessionless
                pipelinerDatasetId={pipelinerDatasetId}
                publicationId={publicationId}
                modifyChartCallback={() => modifyChartCallback(publicationId)}
                userCanModify={userCanModify}
              />
            )}
          </VisualDisplayContainer>
          <VisualCaption messageType={messageType} update={data} isInsightsBoard />
        </VisualContainer>
      )}
    </InsightsBoardStateProvider>
  );
};

InsightsBoardVisual.propTypes = {
  objectId: PropTypes.string,
  data: PropTypes.object.isRequired,
  publicationId: PropTypes.number,
  messageType: PropTypes.string.isRequired,
  messageTypeVersion: PropTypes.number.isRequired,
  modifyChartCallback: PropTypes.func.isRequired,
  userCanModify: PropTypes.bool.isRequired,
  isRefreshing: PropTypes.bool,
  lastRefreshTime: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
  lastRefreshFailed: PropTypes.bool,
  refreshMessage: PropTypes.string,
  handleRefresh: PropTypes.func,
  hasChartLinked: PropTypes.bool,
  userHasPermissions: PropTypes.bool,
  useSnapshot: PropTypes.bool,
  insightsBoardEditMode: PropTypes.bool,
  workflowId: PropTypes.number,
  clientId: PropTypes.string,
  secret: PropTypes.string,
  removeItemFromIBLayout: PropTypes.func,
  isDragInProgress: PropTypes.bool,
  setEditingModal: PropTypes.func,
  fontSizes: PropTypes.object,
  customization: PropTypes.object,
  insightsBoardId: PropTypes.string,
  isInsightsBoardCreator: PropTypes.bool,
  isPopOutChart: PropTypes.bool,
  editAnnotation: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  editCrossFilters: PropTypes.func,
  error: PropTypes.oneOfType([
    PropTypes.instanceOf(AxiosError),
    PropTypes.instanceOf(Error),
    PropTypes.oneOf([null]),
  ]),
  isLoading: PropTypes.bool,
  isFailed: PropTypes.bool,
  rowCount: PropTypes.number,
  updateRowCount: PropTypes.func,
  pipelinerDatasetId: PropTypes.string,
};

InsightsBoardVisual.defaultProps = {
  objectId: undefined,
  refreshMessage: undefined,
  hasChartLinked: false,
  insightsBoardId: null,
  userHasPermissions: false,
  insightsBoardEditMode: false,
  isInsightsBoardCreator: undefined,
  fontSizes: {},
  customization: {},
  useSnapshot: false,
  isRefreshing: false,
  lastRefreshFailed: false,
  isDragInProgress: false,
  clientId: null,
  secret: null,
  workflowId: undefined,
  publicationId: 0,
  isPopOutChart: false,
  error: null,
  editAnnotation: undefined,
  editCrossFilters: () => {},
  removeItemFromIBLayout: () => {},
  setEditingModal: () => {},
  handleRefresh: () => {},
  isLoading: false,
  isFailed: false,
  rowCount: null,
  updateRowCount: () => {},
  pipelinerDatasetId: null,
};

export default InsightsBoardVisual;
