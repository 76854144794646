import axios from 'axios';
import { endpoints } from '../constants/endpoints';

/**
 * Logs a user action.
 * @param {import('../constants/user_actions')} action
 * @param {string | null} note
 * @param {string} accessToken
 * @returns
 */
export const postLogUserAction = (action, note, accessToken) =>
  axios.post(
    endpoints.logUserAction,
    {},
    {
      params: {
        action,
        note,
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

export const getUserActionResetPassword = (accessToken, userID) =>
  axios.get(endpoints.userActionResetPassword(userID), {
    params: {},
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
