import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DefaultErrorMessage, EmptyPlaceholderText } from '../../../../constants/databaseBrowser';
import {
  selectDatabaseHasFetchedAllNamespaces,
  selectDatabaseNamspacesErrorByID,
  selectDatabaseRequestingNamespacesStatusById,
  selectNamespacesByConnectionId,
} from '../../../../store/selectors/dbBrowser.selector';
import { listNamespacesRequest } from '../../../../store/slices/dbBrowser.slice';
import {
  isStatusFailed,
  isStatusRequesting,
  isStatusUnrequested,
} from '../../../../utils/home_screen';
import TextWithTooltip from '../../../common/TextWithTooltip';
import StatusPlaceholder from '../../../StatusPlaceholder';
import { NAMESPACE_PAGE_SIZE } from '../../constants';
import StickyWrapper, {
  StickyWrapperContentProps,
  StickyWrapperOption,
} from '../../StickyWrapper/StickyWrapper';
import TableList from '../Tables/TableList';
import NamespaceItem from './NamespaceItem';
import './NamespaceList.scss';

const NamespaceList: React.FC<StickyWrapperContentProps> = ({ parents }) => {
  // Pull out information from props
  const parentConnUUID = parents?.[0]?.id ?? '';

  const dispatch = useDispatch();

  // Redux state
  const status = useSelector(selectDatabaseRequestingNamespacesStatusById(parentConnUUID));
  const errorMessage = useSelector(selectDatabaseNamspacesErrorByID(parentConnUUID));
  const namespaces = useSelector(selectNamespacesByConnectionId(parentConnUUID));
  const allNamespacesFetched = useSelector(selectDatabaseHasFetchedAllNamespaces(parentConnUUID));

  // computed state
  const options: StickyWrapperOption[] = Object.keys(namespaces).map((n) => ({
    id: parentConnUUID,
    name: n,
  }));
  const isEmpty = Object.keys(namespaces).length === 0;

  const fetchNamespaces = () => {
    if (!allNamespacesFetched) {
      // determine the next page to fetch by dividing the number of namespaces by the page size
      // We should only have a multiple of the page size number of namespaces, the math.floor
      // will ensure that we are providing an integer to the listNamespacesRequest action
      const nextPage = Math.floor(Object.values(namespaces).length / NAMESPACE_PAGE_SIZE);
      dispatch(
        listNamespacesRequest({ connectionUUID: parentConnUUID, page: nextPage, refresh: false }),
      );
    }
  };

  React.useEffect(() => {
    if (isStatusUnrequested(status)) {
      fetchNamespaces();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isStatusRequesting(status) || isStatusFailed(status) || isEmpty) {
    // Return a placeholder if we are still fetching namespaces
    // or if we failed to fetch namespaces
    // or if the database is empty
    return (
      <Box
        className="DatabaseBrowser-NamespaceList-Navigation-Placeholder"
        data-testid="NamespaceList"
      >
        <StatusPlaceholder
          status={status}
          errorText={errorMessage ?? DefaultErrorMessage.Namespace}
          isEmpty={isEmpty}
          emptyText={EmptyPlaceholderText.Database}
          smallLoader
        />
      </Box>
    );
  }

  return (
    <Box data-testid="NamespaceList">
      <StickyWrapper
        options={options}
        Content={NamespaceItem}
        parents={parents}
        renderChildren={(args: StickyWrapperContentProps) => (
          <TableList parents={[...args.parents, args.current]} current={args.current} />
        )}
      />
      {!allNamespacesFetched ? (
        <Button
          key="fetch-more-namespaces"
          data-testid="fetch-more-namespaces"
          size="large"
          onClick={fetchNamespaces}
          className="DatabaseBrowser-Navigation-FetchMore"
          variant="outlined"
          fullWidth
          sx={{ marginTop: '10px' }}
        >
          <TextWithTooltip text="Load More Namespaces" />
        </Button>
      ) : null}
    </Box>
  );
};

export default NamespaceList;
