import { push } from 'redux-first-history';
import {
  all,
  call,
  delay,
  getContext,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import {
  attachPaymentMethod,
  cancelSubscription,
  getCurrentSubscription,
  getPaymentMethods,
  getSubscriptionCustomerId,
  getSubscriptionPrice,
  getSubscriptions,
  getUsageStats,
  updateSubscription,
} from '../../api/subscriptions.api';
import { API_SERVICES } from '../../constants/api';
import { HOME_OBJECTS } from '../../constants/home_screen';
import { paths } from '../../constants/paths';
import { TOAST_ERROR, TOAST_LONG } from '../../constants/toast';
import { getInvoicesRequest, getUpcomingInvoiceRequest } from '../actions/invoice.actions';
import {
  ADD_PAYMENT_METHOD_REQUEST,
  CANCEL_SUBSCRIPTION_REQUEST,
  DELETE_OBJECT_FROM_USAGE_STATS_REQUEST,
  DISABLE_PAYMENT_POPUP_REQUEST,
  GET_CURRENT_SUBSCRIPTION_FAILURE,
  GET_CURRENT_SUBSCRIPTION_REQUEST,
  GET_CURRENT_SUBSCRIPTION_SUCCESS,
  GET_PAYMENT_METHODS_REQUEST,
  GET_SUBSCRIPTIONS_REQUEST,
  GET_SUBSCRIPTION_CUSTOMER_ID_REQUEST,
  GET_SUBSCRIPTION_PRICE_REQUEST,
  GET_USAGE_STATS_FAILURE,
  GET_USAGE_STATS_REQUEST,
  GET_USAGE_STATS_SUCCESS,
  START_FREE_TIER_REQUEST,
  SUBMIT_PAYMENT_REQUEST,
  UPDATE_SUBSCRIPTION_REQUEST,
  addPaymentMethodFailure,
  addPaymentMethodSuccess,
  cancelSubscriptionFailure,
  cancelSubscriptionSuccess,
  closeAddPaymentDialog,
  deleteObjectFromUsageStatsFailure,
  deleteObjectFromUsageStatsSuccess,
  disablePaymentPopupFailure,
  disablePaymentPopupRequest,
  disablePaymentPopupSuccess,
  getCurrentSubscriptionFailure,
  getCurrentSubscriptionRequest,
  getCurrentSubscriptionSuccess,
  getPaymentMethodsFailure,
  getPaymentMethodsSuccess,
  getSubscriptionCustomerIdFailure,
  getSubscriptionCustomerIdSuccess,
  getSubscriptionPriceFailure,
  getSubscriptionPriceSuccess,
  getSubscriptionsFailure,
  getSubscriptionsRequest,
  getSubscriptionsSuccess,
  getUsageStatsFailure,
  getUsageStatsRequest,
  getUsageStatsSuccess,
  setUsageStatsLoadingStatus,
  startFreeTierFailure,
  startFreeTierSuccess,
  submitPaymentFailure,
  submitPaymentSuccess,
  updateSubscriptionFailure,
  updateSubscriptionSuccess,
} from '../actions/subscriptions.actions';
import { addToast } from '../actions/toast.actions';
import {
  exitSessionFailure,
  exitSessionSuccess,
  tryExitSessionRequest,
} from '../slices/session.slice';
import { selectProductName, selectProductPrice, selectTrialValid } from '../typedSelectors';
import { raceGenerator } from './home_screen.saga';
import {
  selectAccessToken,
  selectCurrentSubscription,
  selectFreeTier,
  selectIsCurrentSubscriptionActive,
  selectStripeObject,
  selectSubscriptionCustomerId,
  selectSubscriptionStatus,
  selectTrialTier,
  selectYearly,
} from './selectors';
import { retry401 } from './utils/retry';

export function* disablePaymentPopupRequestWorker() {
  try {
    const subscriptionsService = yield getContext(API_SERVICES.SUBSCRIPTIONS);
    const response = yield* retry401(subscriptionsService.disablePaymentPopup);
    yield put(disablePaymentPopupSuccess(response.data));
  } catch (error) {
    yield put(disablePaymentPopupFailure({ error }));
  }
}

function* getSubscriptionsRequestWorker() {
  try {
    const accessToken = yield select(selectAccessToken);
    const subscriptions = yield call(getSubscriptions, accessToken);
    yield put(getSubscriptionsSuccess({ subscriptions: subscriptions.data }));
  } catch (err) {
    yield put(getSubscriptionsFailure(err));
  }
}

/**
  Worker to fetch usage stats
  @param {Array[String]} metrics // Array of metrics
*/
export function* getUsageStatsWorker({ metrics }) {
  try {
    const accessToken = yield select(selectAccessToken);

    const responses = yield all(
      metrics.reduce((object, metric) => {
        object[metric] = call(getUsageStats, accessToken, metric);
        return object;
      }, {}),
    );

    yield all(
      Object.entries(responses).map(([metric, response]) => {
        return put(getUsageStatsSuccess({ metric, usageData: response.data }));
      }),
    );

    yield put(setUsageStatsLoadingStatus({ status: false }));
  } catch (error) {
    yield put(getUsageStatsFailure({ metrics, error }));
  }
}

/**
  Worker to delete an object from Usage Stats and request the usage stats again
  @param {String} objectType // Type of the object
  @param {Array[String]} metrics // Array of usage metrics
  @param {Object} props // Props for the object
*/
export function* deleteObjectFromUsageStatsRequestWorker({ objectType, metrics, props }) {
  try {
    switch (objectType) {
      case HOME_OBJECTS.SESSION: {
        const { sessionId } = props;
        yield put(tryExitSessionRequest(sessionId));
        // Check if there is any failure exiting the session
        yield* raceGenerator(exitSessionSuccess.type, exitSessionFailure.type);
        break;
      }
      default:
        // Object Type is not supported
        return yield put(
          deleteObjectFromUsageStatsFailure({
            objectType,
            metrics,
            props,
            error: 'Object type is not supported',
          }),
        );
    }

    // Get Usage Metrics
    yield put(getUsageStatsRequest({ metrics }));
    yield* raceGenerator(GET_USAGE_STATS_SUCCESS, GET_USAGE_STATS_FAILURE);

    return yield put(deleteObjectFromUsageStatsSuccess({ objectType, metrics, props }));
  } catch (error) {
    return yield put(deleteObjectFromUsageStatsFailure({ objectType, metrics, props }));
  }
}

/**
  Worker to fetch Subscription Customer Id from dc_subscriptions table
*/
export function* getSubscriptionCustomerIdRequestWorker() {
  try {
    const accessToken = yield select(selectAccessToken);
    const response = yield call(getSubscriptionCustomerId, accessToken);
    yield put(
      getSubscriptionCustomerIdSuccess({
        customerId: response.data?.customerId || '',
        isExempt: response.data?.isExempt || false,
      }),
    );
  } catch (error) {
    yield put(getSubscriptionCustomerIdFailure({ error }));
  }
}

function* getCurrentSubscriptionRequestWorker() {
  try {
    const accessToken = yield select(selectAccessToken);
    const { data } = yield call(getCurrentSubscription, accessToken);
    yield put(
      getCurrentSubscriptionSuccess({
        subscription: data.subscription,
      }),
    );
  } catch (error) {
    yield put(getCurrentSubscriptionFailure({ error }));
  }
}

// poll the current-subscription endpoint until we recieve a non null value
// we need to do this because this endpoint fetches the subscription id from
// management db and uses that to get the current subscription from stripe.
// The issue with this is that we don't populate that table with the subscription id
// until after the payment succeeds - we need to make sure that subscription id is there
// before we navigate the the Account page.
// Note - we only need to do this when a user first starts a subscription.
function* waitForCurrentSubscription() {
  let currentSubscription = yield select(selectCurrentSubscription);
  let iter = 0;
  while (!currentSubscription && iter < 5) {
    yield put(getCurrentSubscriptionRequest());
    yield* raceGenerator(GET_CURRENT_SUBSCRIPTION_SUCCESS, GET_CURRENT_SUBSCRIPTION_FAILURE);
    currentSubscription = yield select(selectCurrentSubscription);
    yield delay(1000);
    iter++;
  }
}

/**
 * Worker that runs the logic for starting a subscription with free trial
 * @param {string} productName name of subscription
 * @param {boolean} yearly represents the length of billing cycle
 */
export function* startFreeTierRequestWorker({ productName, yearly, redirect }) {
  if (productName === undefined) {
    const trialTier = yield select(selectTrialTier);
    const freeTier = yield select(selectFreeTier);
    const tier = freeTier || trialTier;
    if (tier) {
      productName = tier.name;
    } else {
      return yield put(startFreeTierFailure({ error: new Error('no free tier or tier to trial') }));
    }
  }
  const subscriptionStatus = yield select(selectSubscriptionStatus);
  const customerID = yield select(selectSubscriptionCustomerId);
  yearly = Boolean(yearly);
  try {
    const subscriptionsService = yield getContext(API_SERVICES.SUBSCRIPTIONS);
    const accessToken = yield select(selectAccessToken);
    // If customer already exists
    if (subscriptionStatus) {
      // update subscription
      yield call(subscriptionsService.updateSubscription, accessToken, { productName, yearly });
    } else {
      if (!customerID) {
        // create customer
        yield call(subscriptionsService.createCustomer, accessToken);
      }
      // create subscription for that customer
      yield call(subscriptionsService.createSubscription, accessToken, { productName, yearly });
    }
    // wait for currentSubscription
    yield* waitForCurrentSubscription();

    // go to the Billing home page
    if (redirect) yield put(push(paths.account));
    return yield put(startFreeTierSuccess());
  } catch (error) {
    yield put(
      addToast({
        toastType: TOAST_ERROR,
        length: TOAST_LONG,
        message: 'An error occurred, please try again.',
      }),
    );
    return yield put(startFreeTierFailure({ error }));
  }
}

/**
 * updates a current users subscription to the selected subscription
 * @param {string} productName name of subscription
 * @param {boolean} yearly represents the length of billing cycle
 * @param {string} paymentMethodId stripe id of the payment method used for subscription
 */
function* updateSubscriptionRequestWorker({ productName, yearly, paymentMethodId }) {
  try {
    const accessToken = yield select(selectAccessToken);
    // call update subscription with desired subscription type
    yield call(updateSubscription, accessToken, { productName, yearly, paymentMethodId });
    yield put(updateSubscriptionSuccess());
    // go to billing home page.
    yield put(push(paths.account));
  } catch (error) {
    yield put(
      addToast({
        toastType: TOAST_ERROR,
        length: TOAST_LONG,
        message: 'An error occurred, please try again.',
      }),
    );
    yield put(updateSubscriptionFailure({ error }));
  }
}

/**
 * Fetches the prorated price based on an change to users subscription
 */
function* getSubscriptionPriceRequestWorker() {
  try {
    // get the currentlly selected product and pay interval
    const productName = yield select(selectProductName);
    const productPrice = yield select(selectProductPrice);
    const yearly = yield select(selectYearly);
    const trialValid = yield select(selectTrialValid);
    const subscriptionStatus = yield select(selectSubscriptionStatus);
    const hasActive = yield select(selectIsCurrentSubscriptionActive);
    let amountDue;
    if (hasActive && Boolean(subscriptionStatus)) {
      // if user has an active subscription that is not a free trial
      const accessToken = yield select(selectAccessToken);
      const response = yield call(getSubscriptionPrice, accessToken, {
        productName,
        yearly,
      });
      ({ amountDue } = response.data);
    } else if (trialValid) {
      // if a user selects a subscription with a free trial and that user
      // has not yet used their free trial
      amountDue = 0;
    } else {
      // if user does not have active subscription AND they selected a subscription
      // that doesn't allow a free trial
      amountDue = productPrice;
    }
    yield put(getSubscriptionPriceSuccess({ amountDue }));
  } catch (error) {
    yield put(getSubscriptionPriceFailure({ error }));
  }
}

/**
 * submit a payment to stripe for a subscription
 * @param {string} productName name of subscription
 * @param {boolean} yearly represents the length of billing cycle
 * @param {*} cardElement stripe credit card element
 * @param {Oject} billingDetails billing details for users payment method
 */
export function* submitPaymentRequestWorker({
  productName,
  yearly,
  cardElement,
  billingDetails,
  paymentMethodID,
}) {
  try {
    const accessToken = yield select(selectAccessToken);
    const subscriptionStatus = yield select(selectSubscriptionStatus);
    const stripe = yield select(selectStripeObject);
    const subscriptionsService = yield getContext(API_SERVICES.SUBSCRIPTIONS);
    // If the user has a status, they already have a customer ID
    if (subscriptionStatus) {
      // if the user is currently on a trial
      if (!paymentMethodID) {
        // create payment method
        const { error, paymentMethod } = yield call(stripe.createPaymentMethod, {
          type: 'card',
          card: cardElement,
          billing_details: billingDetails,
        });
        if (error) throw error;
        // attach that payment method to a customer
        yield call(subscriptionsService.attachPaymentMethod, accessToken, paymentMethod.id);
        paymentMethodID = paymentMethod.id;
      }
      // update the subscriptions with the payment method
      yield call(subscriptionsService.updateSubscription, accessToken, {
        productName,
        yearly,
        paymentMethodId: paymentMethodID,
      });
    } else {
      // if user does not have a status
      // create a customer
      yield call(subscriptionsService.createCustomer, accessToken);
      // create a subscription for that customer
      const response = yield call(subscriptionsService.createSubscription, accessToken, {
        productName,
        yearly,
      });
      // fetch the clientSecret from the paymentIntent of the latest invoice
      const { clientSecret } = response.data;
      // Use card Element to tokenize payment details
      const { error } = yield call(stripe.confirmCardPayment, clientSecret, {
        payment_method: paymentMethodID ?? {
          card: cardElement,
          billing_details: billingDetails,
        },
        setup_future_usage: 'off_session',
      });

      if (error) throw error;
    }
    // wait for currentSubscription
    yield* waitForCurrentSubscription();
    yield put(submitPaymentSuccess());
    yield put(disablePaymentPopupRequest());
    yield put(push(paths.account));
  } catch (error) {
    yield put(
      addToast({
        toastType: TOAST_ERROR,
        length: TOAST_LONG,
        message:
          error?.response?.data?.error || error?.message || 'An error occurred, please try again.',
      }),
    );
    yield put(submitPaymentFailure({ error }));
  }
}

/**
 * add a payment method to a user
 * @param {*} cardElement card element provided from stripe
 * @param {*} billingDetails billing details associated with the payment method
 */
function* addPaymentMethodRequestWorker({ cardElement, billingDetails }) {
  try {
    const accessToken = yield select(selectAccessToken);
    const stripe = yield select(selectStripeObject);
    const { error, paymentMethod } = yield call(stripe.createPaymentMethod, {
      type: 'card',
      card: cardElement,
      billing_details: billingDetails,
    });
    if (error) throw error;
    // attach that payment method to a customer
    yield call(attachPaymentMethod, accessToken, paymentMethod.id);
    const newPaymentMethod = {
      exp_month: paymentMethod.card.exp_month,
      exp_year: paymentMethod.card.exp_year,
      last4: paymentMethod.card.last4,
      brand: paymentMethod.card.brand,
      id: paymentMethod.id,
    };
    yield put(addPaymentMethodSuccess({ paymentMethod: newPaymentMethod }));
    yield put(closeAddPaymentDialog());
  } catch (error) {
    yield put(
      addToast({
        toastType: TOAST_ERROR,
        length: TOAST_LONG,
        message:
          error?.response?.data?.error || error?.message || 'An error occurred, please try again.',
      }),
    );
    yield put(addPaymentMethodFailure({ error }));
  }
}

/**
 * Get all payment methods associated with a user
 */
function* getPaymentMethodsRequestWorker() {
  try {
    const accessToken = yield select(selectAccessToken);
    const { data } = yield call(getPaymentMethods, accessToken);
    yield put(getPaymentMethodsSuccess({ paymentMethods: data.payment_methods }));
  } catch (error) {
    yield put(getPaymentMethodsFailure({ error }));
  }
}

/**
 * Cancel a users current subscriptions.
 * When the cancel request completes, Fetch the updated invoices and subscriptions
 */
function* cancelSubscriptionRequestWorker() {
  try {
    const accessToken = yield select(selectAccessToken);
    yield call(cancelSubscription, accessToken);
    yield put(cancelSubscriptionSuccess());
    yield put(getInvoicesRequest());
    yield put(getSubscriptionsRequest());
    yield put(getCurrentSubscriptionRequest());
    yield put(getUpcomingInvoiceRequest());
  } catch (error) {
    yield put(cancelSubscriptionFailure({ error }));
  }
}

export default function* () {
  yield takeEvery(DISABLE_PAYMENT_POPUP_REQUEST, disablePaymentPopupRequestWorker);
  yield takeLatest(GET_SUBSCRIPTIONS_REQUEST, getSubscriptionsRequestWorker);
  yield takeLatest(GET_SUBSCRIPTION_CUSTOMER_ID_REQUEST, getSubscriptionCustomerIdRequestWorker);
  yield takeLatest(GET_USAGE_STATS_REQUEST, getUsageStatsWorker);
  yield takeLatest(DELETE_OBJECT_FROM_USAGE_STATS_REQUEST, deleteObjectFromUsageStatsRequestWorker);
  yield takeLatest(GET_CURRENT_SUBSCRIPTION_REQUEST, getCurrentSubscriptionRequestWorker);
  yield takeLatest(UPDATE_SUBSCRIPTION_REQUEST, updateSubscriptionRequestWorker);
  yield takeLatest(GET_SUBSCRIPTION_PRICE_REQUEST, getSubscriptionPriceRequestWorker);
  yield takeLatest(SUBMIT_PAYMENT_REQUEST, submitPaymentRequestWorker);
  yield takeLatest(START_FREE_TIER_REQUEST, startFreeTierRequestWorker);
  yield takeLatest(ADD_PAYMENT_METHOD_REQUEST, addPaymentMethodRequestWorker);
  yield takeLatest(GET_PAYMENT_METHODS_REQUEST, getPaymentMethodsRequestWorker);
  yield takeLatest(CANCEL_SUBSCRIPTION_REQUEST, cancelSubscriptionRequestWorker);
}
