import { SERVER_IDLE, SERVER_WAITING } from '../actions/server_status.action';

const initialState = {
  serverWaiting: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SERVER_WAITING:
      return { ...state, serverWaiting: true };
    case SERVER_IDLE:
      return { ...state, serverWaiting: false };
    default:
      return state;
  }
};
