export const CHECK_FOR_FREE_TRIAL_REQUEST =
  'subscriptionCheckFreeTrial/CHECK_FOR_FREE_TRIAL_REQUEST';
export const CHECK_FOR_FREE_TRIAL_SUCCESS =
  'subscriptionCheckFreeTrial/CHECK_FOR_FREE_TRIAL_SUCCESS';
export const CHECK_FOR_FREE_TRIAL_FAILURE =
  'subscriptionCheckFreeTrial/CHECK_FOR_FREE_TRIAL_FAILURE';

export const canUseFreeTrialRequest = () => ({
  type: CHECK_FOR_FREE_TRIAL_REQUEST,
});

export const canUseFreeTrialSuccess = ({ canUseFreeTrial }) => ({
  type: CHECK_FOR_FREE_TRIAL_SUCCESS,
  canUseFreeTrial,
});

export const canUseFreeTrialFailure = (error) => ({
  type: CHECK_FOR_FREE_TRIAL_FAILURE,
  error,
});
