/**
 * @fileoverview This file contains utilities for the input fields on the Chart Builder.
 */

import React, { ReactElement } from 'react';
import ColumnTypeIcon from '../../common/ColumnTypeIcon';

/**
 * Renders an input's column option with its type icon & name
 * @param columnName The name of the column
 * @param columnType The type of the column
 * @returns The column option with it's label and type
 */
export const renderColumnOption = (columnName: string, columnType: string): ReactElement => (
  <div className="CB-column-option" title={columnName}>
    <div className="CB-column-option-label">
      <ColumnTypeIcon type={columnType} />
      <span>{columnName}</span>
    </div>
  </div>
);

/**
 * If multi-select, Updates the autocomplete selection with an array of items
 * If single-select, Replace the autocomplete selection with the new single item
 * @param selection The current selection from autocomplete
 * @param isSingleSelect Do we want our autocomplete to only select a single item?
 * @returns The updated selection value
 */
export const updateInputSelection = (selection: string[], isSingleSelect: boolean): string[] => {
  const updatedSelection = isSingleSelect ? [selection[selection.length - 1]] : selection;
  return updatedSelection.filter((item) => item !== undefined);
};
