import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { CONTEXTS } from '../../../constants';
import { EMPTY_COLUMN } from '../../../pages/authenticated/constants';
import { selectContext } from '../../../store/selectors/context.selector';
import '../ChartBuilder.scss';
import Dropdown from '../UtilComponents/Dropdown';
import { defaultFooterDropdownState } from '../utils/constants';
import { getComponentName } from '../utils/manageChart';

/**
 * A functional component to wrap the chart builder's footer
 * Conditionally renders each child component, controlled via the linked Dropdown components
 * @returns {JSX.Element} The chart builder's footer
 */
const FooterDropdownContainer = (props) => {
  const {
    children,
    fields,
    hasTranslationError,
    isLoading,
    isSessionless,
    options,
    renderChart,
    saveChanges,
    userCanModify,
    tableObjects,
    datasetPair,
  } = props;

  const [dropdowns, setDropdowns] = useState(defaultFooterDropdownState);

  const context = useSelector(selectContext);
  const nonrestingContext = context !== CONTEXTS.REST;

  // Conditions to remove a component from the dropdown list
  // This should only be used for components that are under development
  const shouldRemove = (componentName) => {
    if (!componentName) return true;

    // Temporarily disable the data sample when sessionless
    return componentName === 'DataSample' && isSessionless;
  };

  // Conditions to render the dropdown component
  const shouldRender = (componentName) => {
    switch (componentName) {
      case 'DataSample':
        return dropdowns.DataSample && options.datasetName && options.datasetVersion;
      case 'Describe':
        return dropdowns.Describe;
      default:
        return false;
    }
  };

  // Toggles a dropdown open/closed
  const toggleDropdown = (componentName) => {
    // Close the other dropdown(s) if they're open
    switch (componentName) {
      case 'DataSample':
        if (dropdowns.Describe) {
          setDropdowns((curr) => ({ ...curr, Describe: false }));
        }
        break;
      case 'Describe':
        if (dropdowns.DataSample) {
          setDropdowns((curr) => ({ ...curr, DataSample: false }));
        }
        break;
      default:
        break;
    }

    // Toggle the dropdown that was clicked
    setDropdowns((curr) => ({ ...curr, [componentName]: !curr[componentName] }));
  };

  const getColumnInfo = () => {
    const visibilityModel =
      tableObjects?.[datasetPair]?.columnsState?.columns?.columnVisibilityModel;
    if (visibilityModel?.[EMPTY_COLUMN]) {
      return 'All columns are hidden';
    }
    return '';
  };

  return (
    <div className="CB-Footer">
      <div className="CB-Footer-Buttons-Container">
        <div className="CB-Footer-Buttons-Left">
          {children.map((child) => {
            const componentName = getComponentName(child);

            return shouldRemove(componentName) ? null : (
              <Dropdown
                componentName={componentName}
                expanded={dropdowns[componentName]}
                fields={fields}
                format="footer"
                isLoading={isLoading}
                key={`${componentName}-Dropdown`}
                onClick={() => toggleDropdown(componentName)}
              />
            );
          })}
        </div>
        {dropdowns?.DataSample && <span className="info-text">{getColumnInfo()}</span>}
        {(!isSessionless || userCanModify) && (
          <div className="CB-Submit">
            <Button
              onClick={saveChanges}
              variant="outlined"
              color="primary"
              size="large"
              disabled={!renderChart || isLoading || hasTranslationError || nonrestingContext}
              data-cy="CB-Save-Changes"
            >
              Submit
            </Button>
          </div>
        )}
      </div>
      {children.map((child) => {
        const componentName = getComponentName(child);
        return shouldRemove(componentName) ? null : shouldRender(componentName) && child;
      })}
    </div>
  );
};

FooterDropdownContainer.propTypes = {
  // Children components, currently Describe & DataSample
  children: PropTypes.arrayOf(PropTypes.node),
  // The chart builder's required & optional field selections, ex. { group: 'SibSp' }
  fields: PropTypes.object.isRequired,
  // Whether or not the translation layer threw an error back to the chart builder
  hasTranslationError: PropTypes.bool,
  // The chart's loading status
  isLoading: PropTypes.bool,
  // If the chart builder is currently in-session or sessionless
  isSessionless: PropTypes.bool,
  // The dataset info & selected chart type
  options: PropTypes.object.isRequired,
  // Should we pass information to the translation layer & render the chart?
  renderChart: PropTypes.bool,
  // Function for submitting the current chart
  saveChanges: PropTypes.func.isRequired,
  // ToDo: Unknown
  userCanModify: PropTypes.bool,
  // Persistent information for the data sample table loaded in chart builder
  tableObjects: PropTypes.object,
  // The id of the current dataset: <datasetName>_<datasetVersion>
  datasetPair: PropTypes.string.isRequired,
};

FooterDropdownContainer.defaultProps = {
  children: [],
  hasTranslationError: false,
  isLoading: false,
  isSessionless: false,
  renderChart: false,
  userCanModify: false,
  tableObjects: {},
};

export default FooterDropdownContainer;
