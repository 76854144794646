import { all } from 'redux-saga/effects';
import {
  call,
  getContext,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
  takeLeading,
} from 'typed-redux-saga';
import { DatasetResponse } from '../../api/dataspace.api';
import { ReduxSagaContext } from '../../configureStore';
import { CLASS } from '../../constants';
import { API_SERVICES } from '../../constants/api';
import {
  DISCARD_CHANGES_KEY,
  SAVE_CHANGES_KEY,
  unsavedDatasetChangesAlert,
} from '../../constants/dialog.constants';
import { HOME_OBJECTS } from '../../constants/home_screen';
import { MessageSourceType } from '../../constants/nodes';
import { NavigationItemStatus } from '../../constants/session';
import {
  TOAST_ERROR,
  TOAST_INDEFINITE,
  TOAST_SHORT,
  TOAST_SUCCESS,
  TOAST_WARNING,
  TOAST_WORKING,
} from '../../constants/toast';
import { ROOT_FOLDER } from '../../constants/workspace';
import { HomeObjectKeys, HomeObjectKeysTypes } from '../../utils/homeScreen/types';
import { closeDialog } from '../actions/dialog.actions';
import { describeAndSendUtteranceRequest, sendMessageRequest } from '../actions/messages.actions';
import { addToast, dismissAllToasts } from '../actions/toast.actions';
import {
  selectActiveDatasets,
  selectColumnRenameMap,
  selectCurrentDatasetDataSpace,
  selectCurrentWorkerDataset,
  selectDatasetById,
  selectDatasets,
  selectDatasetsByNameVersion,
  selectDuplicateDatasetName,
  selectSelectedDatasetName,
  selectSelectedDatasetVersion,
} from '../selectors/dataspace.selector';
import { selectSession } from '../selectors/session.selector';
import { discardAll, saveAll } from '../slices/catalog.slice';
import {
  Dataset,
  DcDatasetIdPayload,
  SaveDatasetAsPayload,
  UpdateDatasetPayload,
  Upsert,
  closeSaveDatasetAs,
  discardDatasetEdits,
  duplicateDatasetCanceled,
  duplicateDatasetConfirmed,
  getCurrentDcDatasetIdFailure,
  getCurrentDcDatasetIdRequest,
  getCurrentDcDatasetIdSuccess,
  getDataspaceFailure,
  getDataspaceRequest,
  getDataspaceSuccess,
  handleUnsavedChanges,
  saveDatasetAs,
  saveDatasetAsFailure,
  saveDatasetAsSuccess,
  saveDatasetsAs,
  setCurrentDatasetFailure,
  setCurrentDatasetRequest,
  setCurrentDatasetSuccess,
  submitDatasetEdits,
  updateDatasetFailure,
  updateDatasetRequest,
  updateDatasetSuccess,
} from '../slices/dataspace.slice';
import { getNodesSuccess } from '../slices/nodes.slice';
import { showMessageDialogHelper } from './dataAssistant.saga';
import { getDatachatObjects } from './home_screen.saga';
import { selectAccessToken } from './selectors';
import { createAlertChannel } from './utils/alert-channels';
import { waitForRestingContext } from './utils/context';
import { callAPIWithRetry } from './utils/retry';
import { callWithPolling } from './utils/saga_utils';
import { confirmDeleteObject, handleUpdateError } from './utils/update_space_helpers';
import { createWorkspaceObjectRequestWorker } from './workspacev2.saga';

export const datasetResponseToDataset = (datasetResponse: DatasetResponse): Dataset => ({
  id: datasetResponse.dc_dataset_id,
  pipeliner_dataset_id: datasetResponse.pipeliner_dataset_id,
  name: datasetResponse.name,
  status: datasetResponse.status,
  version: datasetResponse.version,
  source: datasetResponse.source,
});

export function* getDataspaceRequestWorker() {
  try {
    const sessionID = yield* select(selectSession);
    // need to enfore type here because imported from js
    const accessToken = yield* select(selectAccessToken);
    if (!sessionID) {
      throw new Error('No session id');
    }
    const dataspaceService = (yield* getContext(
      API_SERVICES.DATASPACE,
    )) as ReduxSagaContext[API_SERVICES.DATASPACE];
    const response = yield* callAPIWithRetry({
      apiFn: dataspaceService.getDataspace,
      args: [accessToken, sessionID],
    });
    const datasetList = response.data;
    const dataspace: { [dcDatasetId: string]: Dataset } = {};
    Object.values(datasetList).forEach((dataset) => {
      dataspace[dataset.dc_dataset_id] = datasetResponseToDataset(dataset);
    });

    yield* put(
      getDataspaceSuccess({
        datasets: dataspace,
        sessionID,
      }),
    );
  } catch (error) {
    if (error instanceof Error) {
      yield* put(getDataspaceFailure({ error }));
    }
  }
}

// changes the status of a dataset
export function* updateDatasetRequestWorker(action: { payload: UpdateDatasetPayload }) {
  const { payload } = action;
  const { dcDatasetID, status, name } = payload;

  try {
    if (status === NavigationItemStatus.HIDDEN) {
      const dataset = yield* select(selectDatasetById, dcDatasetID);
      yield* call(confirmDeleteObject, dataset?.name);
    }

    const sessionID = yield* select(selectSession);
    const accessToken = yield* select(selectAccessToken);
    if (!sessionID || !dcDatasetID) {
      throw new Error('Need both session and dc_dataset IDs');
    }

    const dataspaceService = (yield* getContext(
      API_SERVICES.DATASPACE,
    )) as ReduxSagaContext[API_SERVICES.DATASPACE];
    const response = yield* call(
      dataspaceService.updateDataset,
      accessToken,
      sessionID,
      dcDatasetID,
      name,
      status,
    );

    const datasets = response.data.map((datasetResponse) =>
      datasetResponseToDataset(datasetResponse),
    );
    yield* put(updateDatasetSuccess({ sessionId: sessionID, datasets }));
  } catch (error) {
    yield* call(handleUpdateError, 'dataset', error);
  }
}

// watcher for setCurrentDatasetRequest action
// we need to promote the dataset if it is not already promoted
export function* setCurrentDatasetRequestWorker(action: { payload: DcDatasetIdPayload }) {
  try {
    const { payload } = action;
    if (payload.dcDatasetId === null) {
      throw new Error('Dataset ID is null');
    }
    const dataset = yield* select(selectDatasetById, payload.dcDatasetId);
    if (dataset && dataset.status === NavigationItemStatus.ACTIVE) {
      yield* put(setCurrentDatasetSuccess(payload));
      return;
    }
    yield* put(
      updateDatasetRequest({
        dcDatasetID: payload.dcDatasetId,
        status: NavigationItemStatus.ACTIVE,
      }),
    );
    const { failure } = yield* race({
      success: take(updateDatasetSuccess),
      failure: take(updateDatasetFailure),
    });
    if (failure) {
      throw failure.payload.error;
    }
    yield* put(setCurrentDatasetSuccess(payload));
  } catch (error) {
    if (error instanceof Error) {
      yield* put(setCurrentDatasetFailure({ error }));
      return;
    }
    yield* put(setCurrentDatasetFailure({ error: new Error('An unknown error occurred') }));
  }
}

export function* getCurrentDcDatasetIdRequestWorker() {
  try {
    const sessionId = yield* select(selectSession);
    const accessToken = yield* select(selectAccessToken);
    if (!sessionId) {
      throw new Error('No session id');
    }
    const dataspaceService = (yield* getContext(
      API_SERVICES.DATASPACE,
    )) as ReduxSagaContext[API_SERVICES.DATASPACE];
    const response = yield* callAPIWithRetry({
      apiFn: dataspaceService.getCurrentDcDatasetId,
      args: [accessToken, sessionId],
    });
    const currentDcDatasetId = response.data;
    yield* put(getCurrentDcDatasetIdSuccess(currentDcDatasetId));
  } catch (error) {
    if (error instanceof Error) {
      yield* put(getCurrentDcDatasetIdFailure({ error }));
    }
  }
}

/**
 * requestPostAppDatasets sends a POST /app/datasets request to create a dataset
 * object from an in-session dataset.
 *
 * @param name in-session dataset name
 * @param version in-session dataset version
 * @param objectName desired dataset object name
 * @param parentUUID desired dataset object name
 * @throws {Error} user-friendly error message
 */
export function* requestPostAppDatasets(
  name: string,
  version: number,
  objectName?: string,
  objectUUID?: string,
  parentUUID?: string,
) {
  const accessToken = (yield select(selectAccessToken)) as string;
  const sessionId = (yield select(selectSession)) as string | undefined;
  if (!sessionId) {
    throw new Error('missing session');
  }
  const dataspaceContext = (yield getContext(
    API_SERVICES.DATASPACE,
  )) as ReduxSagaContext[API_SERVICES.DATASPACE];
  try {
    yield call(
      dataspaceContext.postAppDataset,
      accessToken,
      sessionId,
      name,
      version,
      objectName,
      objectUUID,
      parentUUID,
    );
  } catch (e) {
    // TODO use e.response to create user-friendly error message
    throw new Error('failed to create dataset');
  }
}

export function* materializePipeline(pipelinerDatasetId: string, sessionId: string) {
  const accessToken = yield* select(selectAccessToken);
  const datasetService = (yield getContext(
    API_SERVICES.DATASET,
  )) as ReduxSagaContext[API_SERVICES.DATASET];

  // typed-redux-sagas fails to recognize this as valid, so disable it here
  //
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  yield call(callWithPolling, {
    fn: datasetService.materializeDataset,
    accessToken,
    args: { pipelinerDatasetId, sessionId },
  });
}

export function* saveDatasetsAsWorker(action: { payload: string }) {
  const name = action.payload;
  const datasets = yield* select(selectDatasets);
  const activeDatasets = Object.values(datasets).filter(
    (dataset) => dataset.status === NavigationItemStatus.ACTIVE,
  );

  if (activeDatasets.length === 0) {
    yield* put(
      addToast({
        toastType: TOAST_ERROR,
        length: TOAST_SHORT,
        message: 'No active datasets to save',
      }),
    );
    return;
  }

  const pipelinerDatasetIds = activeDatasets.map((dataset) => dataset.pipeliner_dataset_id);
  const sessionId = yield* select(selectSession);

  yield* put(
    addToast({
      toastType: TOAST_WORKING,
      length: TOAST_INDEFINITE,
      message: `Saving ${activeDatasets.length} dataset${activeDatasets.length > 1 ? 's' : ''}`,
    }),
  );

  try {
    // typed-redux-sagas fails to recognize this as valid, so disable it here
    //
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    yield all(
      pipelinerDatasetIds.map((pipelinerDatasetId) =>
        call(materializePipeline, pipelinerDatasetId, sessionId),
      ),
    );
  } catch (e) {
    yield put(dismissAllToasts());
    yield* put(
      addToast({
        toastType: TOAST_ERROR,
        length: TOAST_SHORT,
        message: 'Failed to materialize datasets',
      }),
    );
  }

  let parentUUID: string;
  try {
    // typed-redux-sagas fails to recognize this as valid, so disable it here
    //
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const response: { data: { uuid: string } } = yield* call(createWorkspaceObjectRequestWorker, {
      parentFolder: ROOT_FOLDER,
      name,
    });
    parentUUID = response.data.uuid;
  } catch (e) {
    yield put(dismissAllToasts());
    yield* put(
      addToast({
        toastType: TOAST_ERROR,
        length: TOAST_SHORT,
        message: 'Failed to create folder for datasets',
      }),
    );
  }

  try {
    // create datasets with parent
    yield all(
      activeDatasets.map((dataset) =>
        call(requestPostAppDatasets, dataset.name, dataset.version, dataset.name, parentUUID),
      ),
    );
  } catch (e) {
    yield put(dismissAllToasts());
    yield* put(
      addToast({
        toastType: TOAST_ERROR,
        length: TOAST_SHORT,
        message: 'Failed to create all dataset objects',
      }),
    );
  }

  yield put(dismissAllToasts());
  yield* put(
    addToast({
      toastType: TOAST_SUCCESS,
      length: TOAST_SHORT,
      message: `Saved ${activeDatasets.length} dataset${activeDatasets.length > 1 ? 's' : ''}`,
    }),
  );
}

export function* saveDatasetAsWorker(action: { payload: SaveDatasetAsPayload }) {
  const { name, upsert } = action.payload;

  const selectedDataset = {
    name: yield* select(selectSelectedDatasetName),
    version: yield* select(selectSelectedDatasetVersion),
  };

  // if no current dataset exists, put failure
  if (selectedDataset === null) {
    yield put(saveDatasetAsFailure({ error: new Error('no selected dataset') }));
    return;
  }

  switch (upsert) {
    case Upsert.INSERT:
      // request app service to create this dataset object
      try {
        yield call(requestPostAppDatasets, selectedDataset.name, selectedDataset.version, name);
      } catch (e) {
        let message = `Failed to create dataset ${name}`;
        if (e instanceof Error) message += `: ${e.message}`;
        yield put(addToast({ toastType: TOAST_ERROR, length: TOAST_SHORT, message }));
        yield put(saveDatasetAsFailure({ error: new Error('failed to create dataset object') }));
        return;
      }

      // put success toast
      yield* put(
        addToast({
          toastType: TOAST_SUCCESS,
          length: TOAST_SHORT,
          message: `Successfully created ${name}`,
        }),
      );
      break;
    case Upsert.UPDATE: {
      // get all of this user's datasets
      const accessToken = yield* select(selectAccessToken);
      const datasets = yield* call(getDatachatObjects, accessToken, HOME_OBJECTS.DATASET);

      // find a dataset named `name` that's owned by the user
      const dataset = Object.values<HomeObjectKeysTypes>(datasets).find(
        (ds) => !ds[HomeObjectKeys.IS_SHARED] && ds[HomeObjectKeys.NAME] === name,
      );

      if (!dataset) {
        yield put(saveDatasetAsFailure({ error: new Error('no dataset found') }));
        return;
      }

      const objectUUID = dataset[HomeObjectKeys.UUID];

      // request app service to update this dataset object
      try {
        yield call(
          requestPostAppDatasets,
          selectedDataset.name,
          selectedDataset.version,
          undefined, // name
          objectUUID,
          undefined, // parentUUID
        );
      } catch (e) {
        let message = `Failed to update dataset ${name}`;
        if (e instanceof Error) message += `: ${e.message}`;
        yield put(addToast({ toastType: TOAST_ERROR, length: TOAST_SHORT, message }));
        yield put(saveDatasetAsFailure({ error: new Error('failed to update dataset object') }));
        return;
      }

      // put success toast
      yield* put(
        addToast({
          toastType: TOAST_SUCCESS,
          length: TOAST_SHORT,
          message: `Successfully updated ${name}`,
        }),
      );
      break;
    }
    default: {
      yield put(saveDatasetAsFailure({ error: new Error('invalid upsert type') }));
      return;
    }
  }

  yield put(saveDatasetAsSuccess(name));
  yield put(closeSaveDatasetAs());
}

// Verify current is valid if the user hasn't set it manually
// i.e the status of the dataset has changed
export function* checkCurrentDatasetWorker() {
  const targetDataset = yield* select(selectCurrentDatasetDataSpace);
  // If this dataset is already active, it doesn't need to change
  if (targetDataset?.status === NavigationItemStatus.ACTIVE) {
    return;
  }

  if (targetDataset) {
    // Otherwise try to find an active dataset with the same
    // name, and set that as the current dataset
    const datasetsByNameVersion = yield* select(selectDatasetsByNameVersion);
    const sameNameDatasets = Object.values(datasetsByNameVersion[targetDataset.name] || {})
      .filter((dataset) => dataset.status === NavigationItemStatus.ACTIVE)
      .sort((a, b) => b.version - a.version);
    if (sameNameDatasets.length > 0) {
      yield* put(setCurrentDatasetSuccess({ dcDatasetId: sameNameDatasets[0].id }));
      return;
    }
  }

  // Finally, use the first (per datasetOrder)
  // active dataset
  const activeDatasets = yield* select(selectActiveDatasets);
  yield* put(setCurrentDatasetSuccess({ dcDatasetId: activeDatasets[0]?.id ?? null }));
}

export function* duplicateDatasetConfirmedWorker({ payload }: { payload: DcDatasetIdPayload }) {
  const dataset = yield* select(selectDatasetById, payload.dcDatasetId ?? undefined);
  if (!dataset) {
    yield* put(duplicateDatasetCanceled());
    return;
  }
  const newName = yield* select(selectDuplicateDatasetName);
  if (newName === dataset.name) {
    yield* put(
      addToast({
        toastType: TOAST_WARNING,
        length: TOAST_SHORT,
        message: 'The dataset already has that name, no action was taken.',
      }),
    );
    return;
  }

  yield* put(
    describeAndSendUtteranceRequest({
      message: {
        skill: 'NameDataset',
        kwargs: {
          dataset_entity: JSON.stringify({
            dataset_name: dataset.name,
            version: dataset.version,
          }),
          name: newName,
          override: true,
          use_it: true,
        },
      },
      callback: null,
      sendRaw: false,
      muted: true,
    }),
  );

  let message;
  while (message === undefined) {
    const { payload: nodesPayload } = yield* take(getNodesSuccess);
    const { messages } = nodesPayload;
    message = messages.find(
      (m) => m.src_type === MessageSourceType.Server && m.class !== undefined,
    );
  }
  if (message.class !== CLASS.FAILURE) {
    // If we didn't fail, no need to prompt user
    return;
  }
  yield* call(showMessageDialogHelper, message.data);
}

export function* submitDatasetEditsWorker() {
  // select the current dataset name and version
  const sessionID = yield* select(selectSession);
  const currentDataset = yield* select(selectCurrentDatasetDataSpace);
  const currentWorkerDataset = yield* select(selectCurrentWorkerDataset);
  if (!currentDataset || !currentWorkerDataset) {
    yield put(discardDatasetEdits());
    yield put(
      addToast({
        toastType: TOAST_ERROR,
        length: TOAST_SHORT,
        message: 'Failed to rename columns',
      }),
    );
    return;
  }

  // if dataset and version is not selected, we need send use utterance first
  if (
    currentDataset.name !== currentWorkerDataset.name ||
    currentDataset.version !== currentWorkerDataset.version
  ) {
    const useUtterance = `Use the dataset ${currentDataset.name}, version ${currentDataset.version}`;
    yield put(sendMessageRequest({ message: useUtterance, sessionID }));
    // wait for the skill to finish
    yield call(waitForRestingContext);
  }

  // Select the rename column map
  const columnRenameMap = yield* select(selectColumnRenameMap);

  // contstruct rename utterance
  const renameList: string[] = [];
  Object.keys(columnRenameMap).forEach((oldColumn) => {
    renameList.push(`${oldColumn} to ${columnRenameMap[oldColumn]}`);
  });
  const renameUtterance = `Rename the column ${renameList.join(', ')}`;
  yield put(sendMessageRequest({ message: renameUtterance, sessionID }));

  // clear the editing state
  yield put(discardDatasetEdits());
}

export function* unsavedDatasetChangesDialog() {
  // Display the unsaved changes alert
  const alertChannel = yield* createAlertChannel(unsavedDatasetChangesAlert());
  const keyChoice = yield* take(alertChannel);

  // Either submit or discard both dataset and annotation changes
  if (keyChoice === SAVE_CHANGES_KEY) yield all([put(submitDatasetEdits()), put(saveAll())]);
  if (keyChoice === DISCARD_CHANGES_KEY) yield all([put(discardDatasetEdits()), put(discardAll())]);

  yield put(closeDialog());
}

export default function* dataspaceSaga() {
  yield* takeLatest(getDataspaceRequest, getDataspaceRequestWorker);
  yield* takeLatest(updateDatasetRequest, updateDatasetRequestWorker);
  yield* takeLatest(setCurrentDatasetRequest, setCurrentDatasetRequestWorker);
  yield* takeLatest(getCurrentDcDatasetIdRequest, getCurrentDcDatasetIdRequestWorker);
  yield* takeLatest(
    [getCurrentDcDatasetIdSuccess, getDataspaceSuccess, updateDatasetSuccess],
    checkCurrentDatasetWorker,
  );
  yield* takeEvery(duplicateDatasetConfirmed, duplicateDatasetConfirmedWorker);
  yield* takeEvery(saveDatasetsAs, saveDatasetsAsWorker);
  yield* takeLeading(saveDatasetAs, saveDatasetAsWorker);
  yield* takeLatest(submitDatasetEdits.type, submitDatasetEditsWorker);
  yield* takeLeading(handleUnsavedChanges.type, unsavedDatasetChangesDialog);
}
