// Status displayed on Billing page
export const statusActive = 'Active';
export const statusInactive = 'Inactive';

// Possible active statuses as sent by stripe
export const stripeStatusActive = 'active';
export const stripeStatusTrialing = 'trialing';

// Regex to match both active statuses
export const stripeActiveSubscriptionRegex = /^(active|trialing)$/;
