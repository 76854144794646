import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { Stripe } from '@stripe/stripe-js';

export interface ConfigReducerType {
  changePasswordPopup: boolean;
  deploymentMode: string;
  paymentEnabled: boolean;
  showMyAccountMenuOption: boolean;
  autoConnectToDb: boolean;
  allowedDatabases: string[];
  allowMultipleConnections: boolean;
  loginUsername: boolean;
  authBackendName: string[];
  isRequesting: boolean | undefined;
  error: unknown | undefined;
  stripe: Stripe | null;
  hubspotTracking: boolean; // boolean, if this is true, the web app loads hubspot tracking script
  googleTagManagerTracking: boolean; // boolean, if this is true, the web app loads Google Tag Manager tracking script
  askEnabled: boolean; // boolean, if this is true, we show the Ask Ava Feature.
  docSearchAPIKey: string;
  helpLink: string;
  canAccessAlgolia: boolean;
  supportCalendarLink: string;
  googleClientID: string;
  coralogixRumEnabled: boolean;
  coralogixEnv: string;
  coralogixVersion: string;
  hubspotRegistrationLink?: string;
  // Snowflake OAuth
  snowflakeOAuthClientID: string;
  snowflakeOAuthAccountName: string;
  // Auth0 Environment Variables
  auth0ClientId: string;
  auth0Domain: string;
}

export interface ConfigSuccessPayloadType {
  changePasswordPopup: boolean;
  deploymentMode: string;
  paymentEnabled: boolean;
  showMyAccountMenuOption: boolean;
  autoConnectToDb: boolean;
  allowedDatabases: string[];
  allowMultipleConnections: boolean;
  loginUsername: boolean;
  authBackendName: string[];
  hubspotTracking: boolean; // boolean, if this is true, the web app loads hubspot tracking script
  googleTagManagerTracking: boolean; // boolean, if this is true, the web app loads Google Tag Manager tracking script
  askEnabled: boolean; // boolean, if this is true, we show the Ask Ava Feature.
  docSearchAPIKey: string;
  helpLink: string;
  canAccessAlgolia: boolean;
  supportCalendarLink: string;
  googleClientID: string;
  coralogixRumEnabled: boolean;
  coralogixEnv: string;
  coralogixVersion: string;
  hubspotRegistrationLink?: string;
  snowflakeOAuthClientID: string;
  snowflakeOAuthAccountName: string;
  // Auth0 Environment Variables
  auth0ClientId: string;
  auth0Domain: string;
}

export const configInitState: ConfigReducerType = {
  changePasswordPopup: false,
  deploymentMode: 'default',
  paymentEnabled: false,
  showMyAccountMenuOption: false,
  autoConnectToDb: false,
  allowedDatabases: [],
  allowMultipleConnections: true,
  loginUsername: false,
  authBackendName: [],
  isRequesting: false,
  error: undefined,
  stripe: null,
  hubspotTracking: false, // boolean, if this is true, the web app loads hubspot tracking script
  googleTagManagerTracking: false, // boolean, if this is true, the web app loads Google Tag Manager tracking script
  askEnabled: false, // boolean, if this is true, we show the Ask Ava Feature.
  docSearchAPIKey: '',
  helpLink: '',
  canAccessAlgolia: false,
  supportCalendarLink: '',
  googleClientID: '',
  coralogixRumEnabled: false,
  coralogixEnv: '',
  coralogixVersion: '',
  snowflakeOAuthClientID: '',
  snowflakeOAuthAccountName: '',
  // Auth0 Environment Variables
  auth0ClientId: '',
  auth0Domain: '',
};

const configSlice = createSlice({
  name: 'config',
  initialState: configInitState,
  reducers: {
    loadStripeSuccess(state, action: PayloadAction<Stripe | null>) {
      state.stripe = action.payload;
    },
    loadStripeFailure(state, action: PayloadAction<unknown | undefined>) {
      state.error = action.payload;
    },
    configRequest(state) {
      state.isRequesting = true;
    },
    configFailure(state, action: PayloadAction<unknown | undefined>) {
      state.error = action.payload;
      state.isRequesting = false;
    },
    configSuccess(state, action: PayloadAction<ConfigSuccessPayloadType>) {
      state.changePasswordPopup = action.payload.changePasswordPopup;
      state.deploymentMode = action.payload.deploymentMode;
      state.paymentEnabled = action.payload.paymentEnabled;
      state.showMyAccountMenuOption = action.payload.showMyAccountMenuOption;
      state.autoConnectToDb = action.payload.autoConnectToDb;
      state.allowedDatabases = action.payload.allowedDatabases;
      state.allowMultipleConnections = action.payload.allowMultipleConnections;
      state.loginUsername = action.payload.loginUsername;
      state.authBackendName = action.payload.authBackendName;
      state.hubspotTracking = Boolean(action.payload.hubspotTracking);
      state.googleTagManagerTracking = Boolean(action.payload.googleTagManagerTracking);
      state.askEnabled = Boolean(action.payload.askEnabled);
      state.docSearchAPIKey = action.payload.docSearchAPIKey;
      state.helpLink = action.payload.helpLink;
      state.canAccessAlgolia = Boolean(action.payload.canAccessAlgolia);
      state.supportCalendarLink = action.payload.supportCalendarLink;
      state.googleClientID = action.payload.googleClientID;
      state.isRequesting = false;
      state.error = undefined;
      state.coralogixRumEnabled = action.payload.coralogixRumEnabled;
      state.coralogixEnv = action.payload.coralogixEnv;
      state.coralogixVersion = action.payload.coralogixVersion;
      state.hubspotRegistrationLink = action.payload.hubspotRegistrationLink;
      state.snowflakeOAuthClientID = action.payload.snowflakeOAuthClientID;
      state.snowflakeOAuthAccountName = action.payload.snowflakeOAuthAccountName;
      // Auth0 Environment Variables
      state.auth0ClientId = action.payload.auth0ClientId;
      state.auth0Domain = action.payload.auth0Domain;
    },
  },
});

export const { loadStripeSuccess, loadStripeFailure, configRequest, configFailure, configSuccess } =
  configSlice.actions;
export default configSlice.reducer;
