import {
  ADD_VIZ_TO_DASHBOARD,
  CANCEL_DASHBOARD,
  CLEAR_DASHBOARD_CHARTS,
  CLEAR_DASHBOARD_PAGE,
  GENERATE_DASHBOARD_FAILURE,
  GENERATE_DASHBOARD_REQUEST,
  GENERATE_DASHBOARD_SUCCESS,
  GET_DASHBOARDLIST_SUCCESS,
  GET_DASHBOARD_SUCCESS,
  GET_USERS_FOR_SHARING_DASHBOARD_FAILURE,
  GET_USERS_FOR_SHARING_DASHBOARD_REQUEST,
  GET_USERS_FOR_SHARING_DASHBOARD_SUCCESS,
  GET_USERS_WITH_DASHBOARD_ACCESS_FAILURE,
  GET_USERS_WITH_DASHBOARD_ACCESS_REQUEST,
  GET_USERS_WITH_DASHBOARD_ACCESS_SUCCESS,
  HIDE_OVERWRITE_SUCCESS_INDICATOR,
  HIDE_SHARE_SUCCESS_INDICATOR,
  MODIFY_DASHBOARD_CHART,
  OVERWRITE_DASHBOARD_FAILURE,
  OVERWRITE_DASHBOARD_REQUEST,
  OVERWRITE_DASHBOARD_SUCCESS,
  RELOAD_DASHBOARD,
  RELOAD_DASHBOARD_SUCCESS,
  RESET_DASHBOARD_SAVED_STATE,
  RESET_DASHBOARD_SHARED_STATE,
  SAVE_CHART_ITEM_INFO,
  SAVE_CHART_ITEM_TITLE,
  SAVE_DASHBOARD_FAILURE,
  SAVE_DASHBOARD_REQUEST,
  SAVE_DASHBOARD_SUCCESS,
  SET_DASHBOARD_META_INFO,
  SET_ID_TO_UPDATE,
  SHARE_DASHBOARD_FAILURE,
  SHARE_DASHBOARD_REQUEST,
  SHARE_DASHBOARD_SUCCESS,
  SHOW_OVERWRITE_SUCCESS_INDICATOR,
  SHOW_SHARE_SUCCESS_INDICATOR,
} from '../actions/dashboard.actions';

export const DASHBOARD_MODES = {
  view: 'VIEW',
  create: 'CREATE',
};

export const initialState = {
  current: { layout: [], charts: [] }, // The charts and layouts that are currently being modified.
  dashboards: [], // The list of all dashboards of a user
  usersWithAccess: [], // List of users who have access to the current dashboard.
  usersToBeSharedWith: [], // List of users that dashboard can be shared with.
  id: undefined,
  dashboardName: undefined, // Name of the dashboard
  sessionName: undefined, // Name of the replay session
  appId: undefined, // Id of the app powering the session
  charts: [], // The original list of charts generated by the replay session.
  layout: [], // The original arrangement of charts stored in the database.
  lastModifiedDate: undefined, // The last time that the dashboard was saved.
  dateCreated: undefined, // The time when the dashboard was created.
  creatorName: undefined, // Name of the dashboard creator.
  creatorEmail: undefined, // Email of the dashboard creator.
  hasGeneratedDashboards: false,
  isGenerating: false, // Indicates if dashboard is being generated via replay session
  isOverwriting: false, // Indicates if dashboard overwrite request is still in the process.
  isSaved: false, // Indicates if current dashboard has been saved in database.
  isRequestingUsersWithDashboardAccess: false, // Indicates if the request for users with dashboard access is still in process.
  isRequestingUsersForDashboardSharing: false, // Indicates if the request for users with permissions for dashboard access is still in process.
  lastRefreshedTime: undefined, // The time when the dashboard was last replayed.
  showOverwriteSuccessIndicator: false, // A boolean indicating if the success indicator should be shown.
  showShareSuccessIndicator: false, // A boolean indicating if the success indicator should be shown.
  numShared: 0, // Integer representing the number of people that the dashboard has been shared with.
  idToUpdate: undefined, // Integer representing the chart id to be updated.
};

/**
 * @param {Object} state
 * @param {Object} action
 * @returns {Object} new state
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_VIZ_TO_DASHBOARD: {
      const newCharts = [...state.charts, action.newViz];
      return {
        ...state,
        current: {
          layout: [...state.current.layout],
          charts: newCharts,
        },
        charts: newCharts,
      };
    }
    case CANCEL_DASHBOARD:
      return { ...state, isGenerating: false };
    case CLEAR_DASHBOARD_PAGE:
      return { ...initialState, dashboards: [...state.dashboards] };
    case CLEAR_DASHBOARD_CHARTS:
      return {
        ...state,
        current: { charts: [], layout: [...state.current.layout] },
        charts: [],
      };
    case GET_DASHBOARDLIST_SUCCESS:
      return { ...state, dashboards: action.dashboards, hasGeneratedDashboards: false };
    case GET_DASHBOARD_SUCCESS:
      return {
        ...state,
        id: action.id,
        layout: action.layout,
        lastModifiedDate: action.lastModifiedDate,
        dateCreated: action.dateCreated,
        creatorName: action.name,
        creatorEmail: action.email,
      };
    case SET_DASHBOARD_META_INFO:
      return {
        ...state,
        dashboardName: action.dashboardName,
        sessionName: action.sessionName,
        appId: action.appId,
      };
    case GENERATE_DASHBOARD_REQUEST:
      return {
        ...state,
        dashboardName: action.dashboardName,
        sessionName: action.sessionName,
        appId: action.appId,
        isGenerating: true,
        lastRefreshedTime: undefined,
      };
    case RELOAD_DASHBOARD:
      return {
        ...state,
        appId: action.appId,
        isGenerating: true,
        lastRefreshedTime: undefined,
      };
    case RELOAD_DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboardName: action.dashboardName,
        sessionName: action.sessionName,
        isGenerating: false,
        lastRefreshedTime: new Date().getTime(),
      };
    case GENERATE_DASHBOARD_SUCCESS:
      return {
        ...state,
        isGenerating: false,
        hasGeneratedDashboards: true,
        lastRefreshedTime: new Date().getTime(),
      };
    case GENERATE_DASHBOARD_FAILURE:
      return { ...state, hasGeneratedDashboards: true, isGenerating: false };
    case SAVE_CHART_ITEM_INFO: {
      const newCharts = [...state.current.charts];
      newCharts[action.idx] = { ...state.current.charts[action.idx], info: action.info };
      return { ...state, current: { ...state.current, charts: newCharts } };
    }
    case SAVE_CHART_ITEM_TITLE: {
      const newCharts = [...state.current.charts];
      newCharts[action.idx] = { ...state.current.charts[action.idx], title: action.title };
      return { ...state, current: { ...state.current, charts: newCharts } };
    }
    case SAVE_DASHBOARD_REQUEST:
      return { ...state, dashboardName: action.dashboardName };
    case SAVE_DASHBOARD_SUCCESS:
      return { ...state, isSaved: true };
    case SAVE_DASHBOARD_FAILURE:
      return { ...state, isSaved: false };
    case RESET_DASHBOARD_SAVED_STATE:
      return { ...state, isSaved: false };
    case OVERWRITE_DASHBOARD_REQUEST:
      return { ...state, isOverwriting: true };
    case OVERWRITE_DASHBOARD_SUCCESS:
      return { ...state, isOverwriting: false };
    case OVERWRITE_DASHBOARD_FAILURE:
      return { ...state, isOverwriting: false };
    case SHOW_OVERWRITE_SUCCESS_INDICATOR:
      return { ...state, showOverwriteSuccessIndicator: true };
    case HIDE_OVERWRITE_SUCCESS_INDICATOR:
      return { ...state, showOverwriteSuccessIndicator: false };
    case SHARE_DASHBOARD_REQUEST:
      return { ...state };
    case SHARE_DASHBOARD_SUCCESS:
      return { ...state, numShared: action.numShared };
    case SHARE_DASHBOARD_FAILURE:
      return { ...state };
    case SHOW_SHARE_SUCCESS_INDICATOR:
      return { ...state, showShareSuccessIndicator: true };
    case HIDE_SHARE_SUCCESS_INDICATOR:
      return { ...state, showShareSuccessIndicator: false };
    case RESET_DASHBOARD_SHARED_STATE:
      return { ...state };
    case GET_USERS_WITH_DASHBOARD_ACCESS_REQUEST:
      return { ...state, isRequestingUsersWithDashboardAccess: true };
    case GET_USERS_WITH_DASHBOARD_ACCESS_SUCCESS:
      return {
        ...state,
        isRequestingUsersWithDashboardAccess: false,
        usersWithAccess: action.usersWithAccess,
      };
    case GET_USERS_WITH_DASHBOARD_ACCESS_FAILURE:
      return { ...state, isRequestingUsersWithDashboardAccess: false };
    case GET_USERS_FOR_SHARING_DASHBOARD_REQUEST: {
      return { ...state, isRequestingUsersForDashboardSharing: true };
    }
    case GET_USERS_FOR_SHARING_DASHBOARD_SUCCESS: {
      return {
        ...state,
        usersToBeSharedWith: action.usersToBeSharedWith,
        isRequestingUsersForDashboardSharing: false,
      };
    }
    case GET_USERS_FOR_SHARING_DASHBOARD_FAILURE: {
      return { ...state, isRequestingUsersForDashboardSharing: false };
    }
    case SET_ID_TO_UPDATE: {
      const charts = [...state.charts];
      if (charts[action.idToUpdate] !== undefined) {
        charts[action.idToUpdate].isLoading = true;
      }
      return { ...state, idToUpdate: action.idToUpdate, charts };
    }
    case MODIFY_DASHBOARD_CHART: {
      const charts = [...state.charts];
      if (action.data) {
        charts[state.idToUpdate] = action.data;
      } else {
        charts[state.idToUpdate].data = { ...charts[state.idToUpdate].data };
      }
      charts[state.idToUpdate].isLoading = false;
      return {
        ...state,
        current: {
          ...state.current,
          charts,
        },
        charts,
      };
    }
    default:
      return state;
  }
};
