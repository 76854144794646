// This file contains functions for managing the echarts spec

/**
 * Gets the width of the echart instance
 * @param {Object} ref reference to the echarts instance
 * @returns {number} width of echarts instance container
 */
export const getWidth = (ref) => {
  const ecInstance = ref.current?.getEchartsInstance();
  if (ecInstance) {
    // force resizing since echart resize is asynchronous
    // (i.e. ecInstance.getWidth() could be old value)
    ecInstance.resize();
    return ecInstance.getWidth();
  }
  return null;
};

/**
 * Gets the height of the echart instance
 * @param {Object} ref reference to the echarts instance
 * @returns {number} height of echarts instance container
 */
export const getHeight = (ref) => {
  const ecInstance = ref.current?.getEchartsInstance();
  if (ecInstance) {
    // force resizing since echart resize is asynchronous
    // (i.e. ecInstance.getWidth() could be old value)
    ecInstance.resize();
    return ecInstance.getHeight();
  }
  return null;
};

/**
 * Gets the current option of an echart instance
 * @param {Object} ref reference to the echarts instance
 * @returns {?Object} echarts option
 */
export const getCurrentSpec = (ref) => {
  const ecInstance = ref.current?.getEchartsInstance();
  return ecInstance ? ecInstance.getOption() : null;
};

/**
 * Removes the datazoom cursor so we can move text/annotations in Customize
 * @param {Object} ref reference to the echarts instance
 */
export const disableToolbox = (ref) => {
  const ecInstance = ref.current?.getEchartsInstance();
  ecInstance.dispatchAction({
    type: 'takeGlobalCursor',
    key: 'dataZoomSelect',
    dataZoomSelectActive: false,
  });
};

/**
 * This function will return the position of the center of the
 * annotation's text in pixel in the given echart
 * @param {Object} ref the reference of echart instance
 * @param {Object} item an annotation object
 * @returns the position of the center of the annotation's text in pixel
 */
export const getCenterPointPixelValues = (ref, item) => {
  const ecInstance = ref.current?.getEchartsInstance();
  const xy = ecInstance.convertToPixel(
    {
      xAxisId: `annotation_cartesian_${item.gridIndex}`,
      yAxisId: `annotation_cartesian_${item.gridIndex}`,
    },
    [item.coordX, item.coordY],
  );
  return xy;
};
