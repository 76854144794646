import TableChart from '@mui/icons-material/TableChart';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeConnectionEditor } from '../../../../store/actions/connection.actions';
import { selectSelectedTablesByNamespace } from '../../../../store/selectors/dbBrowser.selector';
import { addPreviewTable, tryModifySelectedEntry } from '../../../../store/slices/dbBrowser.slice';
import TextWithTooltip from '../../../common/TextWithTooltip';
import { StickyWrapperContentProps } from '../../StickyWrapper/StickyWrapper';
import './TableItem.scss';

const TableItem: React.FC<StickyWrapperContentProps> = ({ parents, current }) => {
  // Pull out information from props
  const connUUID = parents?.[0]?.id ?? '';
  const namespace = parents?.[1]?.name ?? '';
  const table = current.name;

  // Redux state
  const dispatch = useDispatch();
  const selectedTables = useSelector(selectSelectedTablesByNamespace(connUUID, namespace));

  const handleTableClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(closeConnectionEditor());
    dispatch(addPreviewTable({ namespace, table }));
  };

  const handleCheckbox = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(tryModifySelectedEntry(namespace, table));
  };

  // We do not render anything if we don't have valid data
  if (!(connUUID && namespace && table)) return null;
  return (
    <ListItem
      className="DatabaseBrowser-Navigation-TableItem"
      onClick={(e: React.MouseEvent) => handleTableClick(e)}
    >
      <Box className="DatabaseBrowser-Navigation-TableItem-Title">
        <ListItemIcon>
          <TableChart
            className="DatabaseBrowser-Navigation-TableItem-Button-TableIcon"
            fontSize="medium"
          />
        </ListItemIcon>
        <TextWithTooltip className="DatabaseBrowser-Navigation-table" text={table} />
      </Box>
      <Box className="DatabaseBrowser-Navigation-TableItem-Secondary">
        <Checkbox
          id={`checkbox-${table}`}
          data-testid={`checkbox-${table}`}
          size="small"
          onClick={handleCheckbox}
          checked={selectedTables.includes(table)}
          inputProps={{ 'aria-label': `checkbox-${table}` }}
        />
      </Box>
    </ListItem>
  );
};

export default TableItem;
