import { GATEWAY_TIMEOUT } from 'http-status-codes';
import {
  call,
  cancelled,
  delay,
  getContext,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { handleSpecVersion } from 'translate_dc_to_echart';
import { deleteDataset, getBaseDatasets, getDatasetNamesTable } from '../../api/datasets.api';
import { retrieveSessionColumnStatsAsync } from '../../api/session.api';
import { getDataFromStorage } from '../../components/ChartData/dataUtils';
import { CONTEXTS, DEFAULT_NUM_ROWS_GRID, SAMPLE_TRIAL_COUNT } from '../../constants';
import { API_SERVICES } from '../../constants/api';
import { TOAST_ERROR, TOAST_SHORT, TOAST_SUCCESS } from '../../constants/toast';
import { utteranceUseDataset } from '../../constants/utterance_templates';
import { makePercentageBackoff } from '../../utils/backoff_calculations';
import { addDatasetToList } from '../actions/chart_selection.actions';
import {
  GET_BASE_DATASETS_REQUEST,
  GET_DATASET_LIST_REQUEST,
  GET_DATASET_LIST_SUCCESS,
  RETRIEVE_COLUMN_STATS_REQUEST,
  RETRIEVE_FORGOTTEN_DATASET,
  SAMPLE_DATASET_REQUEST,
  SAMPLE_SESSION_DATASET_REQUEST,
  UPDATE_DATASET_FAILURE,
  UPDATE_DATASET_REQUEST,
  UPDATE_DATASET_SUCCESS,
  UPDATE_SELECTED_DATASET,
  getBaseDatasetsFailure,
  getBaseDatasetsSuccess,
  getDatasetListFailure,
  getDatasetListSuccess,
  retrieveColumnStatsFailure,
  retrieveColumnStatsSuccess,
  sampleDatasetRequest,
  sampleSessionDatasetFailure,
  sampleSessionDatasetSuccess,
  updateDatasetFailure,
  updateDatasetSuccess,
} from '../actions/dataset.actions';
import { describeAndSendUtteranceRequest, sendMessageRequest } from '../actions/messages.actions';
import { addToast } from '../actions/toast.actions';
import { selectContext, selectIsReplaying } from '../selectors/context.selector';
import {
  selectHiddenDatasetsByNameVersion,
  selectSelectedDatasetName,
  selectSelectedDatasetVersion,
  selectVisibleDatasetsByNameVersion,
} from '../selectors/dataspace.selector';
import { selectSession } from '../selectors/session.selector';
import { saveTabOrder } from '../slices/dataspaceTable.slice';
import { selectAccessToken, selectSessionDatasetStorage, selectUserID } from './selectors';
import { waitForRestingContext } from './utils/context';
import { takeEachDataset } from './utils/custom_effects';
import { selectDatasetRequest } from './utils/dataset';
import { formatObjectValuessAsString } from './utils/format_describe_data';
import { callWithPolling } from './utils/saga_utils';

export function* retrieveForgottenDatasetWorker({
  datasetName,
  pipelinerDatasetId,
  sendUseUtterance,
  version,
}) {
  if (sendUseUtterance) {
    yield put(sendMessageRequest({ message: utteranceUseDataset(datasetName, version) }));
  }
  // Waits for the BE to finish processing the use utterance before sampling
  yield call(waitForRestingContext);
  yield put(sampleDatasetRequest({ isTable: true, numRows: 100, pipelinerDatasetId }));
}

// Generate an utterance from JSON and send it.
export function* getDatasetListWorker() {
  try {
    const accessToken = yield select(selectAccessToken);
    let session = yield select(selectSession);

    let response = yield call(getDatasetNamesTable, accessToken, session);
    // Handle when the session has not been initialized yet and the backend runs into a KeyError
    if (response.status >= 300 || response.data?.visible_datasets?.Error) {
      yield delay(1000);
      session = yield select(selectSession);
      response = yield call(getDatasetNamesTable, accessToken, session);
      const datasetList = response.data.visible_datasets;
      const hiddenDatasetList = response.data.hidden_datasets;
      const currentDataset = response.data.current_dataset;
      if (datasetList.Error) {
        yield put(getDatasetListFailure({ error: datasetList }));
      } else {
        yield put(getDatasetListSuccess({ datasetList, hiddenDatasetList, currentDataset }));
        // if the datset list is empty, we need clear the tab order in gridMode redux store
        if (!Object.keys(datasetList).length) {
          yield put(saveTabOrder({ sessionId: session, tabOrderList: [] }));
        }
      }
    } else {
      const datasetList = response.data.visible_datasets;
      const hiddenDatasetList = response.data.hidden_datasets;
      const currentDataset = response.data.current_dataset;
      // Signal a success of update
      yield put(getDatasetListSuccess({ datasetList, hiddenDatasetList, currentDataset }));
      // if the datset list is empty, we need clear the tab order in gridMode redux store
      if (!Object.keys(datasetList).length) {
        yield put(saveTabOrder({ sessionId: session, tabOrderList: [] }));
      }
    }
  } catch (error) {
    yield put(getDatasetListFailure({ error }));
  }
}

function* retrieveColumnStatWorker() {
  // ToDo: Refactor this endpoint to use pipelinerDatasetId
  // And remove dependence on datasetName and version
  const datasetName = yield select(selectSelectedDatasetName);
  const version = yield select(selectSelectedDatasetVersion);
  const datasetList = yield select(selectVisibleDatasetsByNameVersion);
  const hiddenDatasetList = yield select(selectHiddenDatasetsByNameVersion);
  const pipelinerDatasetId =
    datasetList[datasetName]?.[version]?.dataset_id ??
    hiddenDatasetList?.[datasetName]?.[version]?.dataset_id;

  const session = yield select(selectSession);
  const datasetStorage = yield select(selectSessionDatasetStorage);
  const datasetData = getDataFromStorage({
    datasetStorage,
    isSession: true,
    pipelinerDatasetId,
  });
  const { columnStat } = datasetData;
  if (columnStat) {
    yield put(retrieveColumnStatsSuccess({ data: columnStat, pipelinerDatasetId }));
    return;
  }
  try {
    const accessToken = yield select(selectAccessToken);
    const params = {
      dataset: datasetName,
      sessionId: session,
      version,
    };

    // Send action request
    const percentageBackoff = makePercentageBackoff();
    let responseFinish = yield call(retrieveSessionColumnStatsAsync, accessToken, params);
    while (responseFinish.status === 202) {
      yield delay(percentageBackoff());
      responseFinish = yield call(retrieveSessionColumnStatsAsync, accessToken, params);
    }
    // format the result
    const data = formatObjectValuessAsString(responseFinish.data.result);

    yield put(retrieveColumnStatsSuccess({ data, pipelinerDatasetId }));
  } catch (error) {
    if (error.response.status === GATEWAY_TIMEOUT) {
      yield put(
        addToast({
          toastType: TOAST_ERROR,
          length: TOAST_SHORT,
          message: 'Request timed out. Please use "Dataset" > "Describe" in the sidebar.',
        }),
      );
    } else {
      yield put(
        addToast({
          toastType: TOAST_ERROR,
          length: TOAST_SHORT,
          message: 'Something went wrong while retrieving the column statistics. Please try again.',
        }),
      );
    }
    yield put(retrieveColumnStatsFailure());
  }
}

/**
 * Worker that creates side-effects to changes in the dataset reducer.
 * For example, automatically updating the current dataset, or fetching samples of datasets
 */
function* datasetSideEffectHandler() {
  const selectedDatasetName = yield select((state) => state.dataset.selectedDatasetName);
  const selectedDatasetVersion = yield select((state) => state.dataset.selectedDatasetVersion);
  const currentDataset = yield select((state) => state.dataset.currentDataset);
  const context = yield select(selectContext);
  const isReplaying = yield select(selectIsReplaying);

  const selectedDatasets = yield select((state) => state.chartSelection.selectedDatasets);

  const datasetList = yield select((state) => state.dataset.datasetList);
  const hiddenDatasetList = yield select((state) => state.dataset.hiddenDatasetList);

  // Get the pipelinerDatasetId from the list of datasets in the session
  const pipelinerDatasetId =
    datasetList[selectedDatasetName]?.[selectedDatasetVersion]?.dataset_id ??
    hiddenDatasetList?.[selectedDatasetName]?.[selectedDatasetVersion]?.dataset_id;

  // within the dataset list, go through the values for each object
  for (const datasetName in datasetList) {
    // https://stackoverflow.com/questions/1963102/what-does-the-jslint-error-body-of-a-for-in-should-be-wrapped-in-an-if-statemen
    if (datasetList.hasOwnProperty(datasetName)) {
      for (const datasetVersion in datasetList[datasetName]) {
        if (datasetList[datasetName].hasOwnProperty(datasetVersion)) {
          const currentDS = datasetList[datasetName][datasetVersion];
          const { name, version } = currentDS;

          // this loop and these if checks will add the dataset to selectedDatasets
          // if the dataset is not already in selectedDatasets
          for (let datasetIndex = 0; datasetIndex < selectedDatasets.length; datasetIndex++) {
            if (
              selectedDatasets[datasetIndex].name === name &&
              selectedDatasets[datasetIndex].version === version
            ) {
              break;
            } else if (datasetIndex === selectedDatasets.length - 1) {
              // add this dataset to the selectedDatasets list
              yield put(addDatasetToList({ name, version }));
            }
          }
          if (selectedDatasets.length === 0) {
            yield put(addDatasetToList({ name, version }));
          }
        }
      }
    }
  }

  // if a dataset has been selected for viewing...
  if (selectedDatasetName && selectedDatasetVersion) {
    // ... but the current dataset is not set in the backend (e.g. loading a snapshot)
    if (currentDataset?.[1] === 0 && context !== CONTEXTS.WORKING && !isReplaying) {
      // then auto update current to match the selected dataset
      yield put(
        describeAndSendUtteranceRequest({
          message: {
            skill: 'SetCurrentDataset',
            kwargs: {
              dataset_entity: JSON.stringify({
                dataset_name: selectedDatasetName,
                version: selectedDatasetVersion,
              }),
            },
          },
        }),
      );
    }

    const datasetStorage = yield select(selectSessionDatasetStorage);
    const tableData = getDataFromStorage({
      datasetStorage,
      isSession: true,
      pipelinerDatasetId,
    });

    // ... but the tables don't have enough data
    const existingNumRows = tableData?.rows?.length ?? 0;
    if (existingNumRows < DEFAULT_NUM_ROWS_GRID) {
      yield put(
        sampleDatasetRequest({
          isTable: true,
          numRows: DEFAULT_NUM_ROWS_GRID,
          pipelinerDatasetId,
        }),
      );
    }
  }
}

export function* sampleSessionDatasetWorker({
  callback,
  dcChartId,
  isTable,
  numRows,
  pipelinerDatasetId,
  selectedColumns,
  referenceString,
  workspaceUuid,
}) {
  const sessionId = yield select(selectSession);

  // Exit without required params
  if (sessionId == null || (!pipelinerDatasetId && !(dcChartId && selectedColumns))) return;

  const isFatTable = Boolean(dcChartId && selectedColumns);
  const datasetStorage = yield select(selectSessionDatasetStorage);

  // Get the current data from the storage
  // Don't perform formatting on data because we're potentially appending to existing data
  const currentData = { ...datasetStorage[referenceString] };
  const currRowNum = currentData?.rows?.length ?? 0;

  // Determine if we already have the full dataset in the reducer state
  const totalRowCount = currentData?.totalRowCount;
  const hasFullDataset = totalRowCount && currRowNum >= totalRowCount;

  // Determine the number of rows to display for tables
  const tableSampleRowCount = numRows === undefined ? totalRowCount : numRows;

  // If we already the whole dataset or enough data, then we don't need to request
  if (hasFullDataset || (currRowNum >= numRows && !isFatTable)) {
    yield put(
      sampleSessionDatasetSuccess({
        isTable,
        pipelinerDatasetId,
        result: currentData,
        ...(isTable && { tableSampleRowCount }),
      }),
    );
    if (callback) callback();
    return;
  }

  // Get an abortController to cancel the api call
  const abortController = new AbortController();

  try {
    // Fetch the remaning data incrementally
    const accessToken = yield select(selectAccessToken);

    // Number of rows to fetch depends on the offset/how many rows we already have
    let incremRows = numRows;
    if (currRowNum > 0 && numRows > currRowNum) incremRows -= currRowNum;
    // If the numRows is not defined, we want to retrieve ALL data and offset needs to be 0
    const offset = numRows ? currRowNum : 0;

    // get dataset service
    const datasetService = yield getContext(API_SERVICES.DATASET);

    // get data and description responses
    let { data } = yield call(callWithPolling, {
      accessToken,
      fn: datasetService.retrievePipelinerDataset,
      signal: abortController.signal,
      args: { numRows: incremRows, offset, pipelinerDatasetId, sessionId, workspaceUuid },
      retry: true,
    });

    if (data.data || data.rows) {
      // Handle for different spec versions
      const specVersion = data.spec_version || data.schema?.spec_version;
      if (specVersion) data = handleSpecVersion(data, specVersion);

      // Add the previously-loaded data if we fetched with a row offset
      if (offset > 0) data.rows = [...currentData.rows, ...data.rows];

      // Call for success, and trigger the callback function if it exists
      yield put(
        sampleSessionDatasetSuccess({
          isTable,
          pipelinerDatasetId,
          result: data,
          ...(isTable && { tableSampleRowCount }),
        }),
      );
      if (callback) yield call(callback);
    } else if (data.forgotten) {
      yield put(
        sampleSessionDatasetSuccess({
          forgotten: true,
          isTable,
          pipelinerDatasetId,
          result: {},
          tableSampleRowCount: 0,
        }),
      );
      if (callback) callback();
    } else if (data.Error) {
      throw new Error('Backend Internal Error!');
    }
  } catch (error) {
    // Get the sampling trial count from the currentData
    const trialCount = isTable
      ? currentData?.tableSamplingTrialCount ?? 0
      : currentData?.samplingTrialCount ?? 0;

    // Retry if we haven't reached the max number of trials
    if (trialCount < SAMPLE_TRIAL_COUNT) {
      yield put(sampleSessionDatasetFailure({ dcChartId, isTable, pipelinerDatasetId }));
      yield delay(1000);
      yield put(
        sampleDatasetRequest({
          callback,
          dcChartId,
          selectedColumns,
          isTable,
          numRows,
          pipelinerDatasetId,
        }),
      );
    } else {
      yield put(sampleSessionDatasetFailure({ dcChartId, error, isTable, pipelinerDatasetId }));
    }
  } finally {
    if (yield cancelled()) {
      abortController.abort('Operation canceled');
    }
  }
}

export function* getBaseDatasetsRequestWorker() {
  try {
    const sessionId = yield select(selectSession);
    const userId = yield select(selectUserID);
    const accessToken = yield select(selectAccessToken);
    const response = yield call(getBaseDatasets, accessToken, sessionId, userId);
    const { data } = response;
    yield put(getBaseDatasetsSuccess({ baseDatasets: data }));
  } catch (error) {
    yield put(getBaseDatasetsFailure({ error }));
  }
}

export function* deleteDatasetHelper({ uuid }) {
  const accessToken = yield select(selectAccessToken);
  yield call(deleteDataset, accessToken, uuid);
}

export function* updateDatasetRequestWorker({
  datasetObject,
  sessionId,
  datasetName,
  datasetVersion,
}) {
  const accessToken = yield select(selectAccessToken);
  try {
    const datasetService = yield getContext(API_SERVICES.DATASET);
    yield call(
      datasetService.putDatasetFromSession,
      accessToken,
      sessionId,
      datasetObject.uuid,
      datasetName,
      datasetVersion,
    );
    yield put(updateDatasetSuccess({ datasetObject }));
  } catch (error) {
    yield put(updateDatasetFailure({ datasetObject, error }));
  }
}

export function* updateDatasetSuccessWorker({ datasetObject }) {
  yield put(
    addToast({
      toastType: TOAST_SUCCESS,
      length: TOAST_SHORT,
      message: `${datasetObject.name ?? 'Dataset'} saved`,
    }),
  );
}

export function* updateDatasetFailureWorker({ datasetObject }) {
  yield put(
    addToast({
      toastType: TOAST_ERROR,
      length: TOAST_SHORT,
      message: `${datasetObject.name ?? 'Dataset'} failed to save`,
    }),
  );
}

export default function* () {
  yield takeEvery(GET_DATASET_LIST_REQUEST, getDatasetListWorker);
  yield takeLatest(RETRIEVE_COLUMN_STATS_REQUEST, retrieveColumnStatWorker);
  yield takeLatest([GET_DATASET_LIST_SUCCESS, UPDATE_SELECTED_DATASET], datasetSideEffectHandler);
  yield takeLatest(GET_BASE_DATASETS_REQUEST, getBaseDatasetsRequestWorker);
  yield takeLatest(RETRIEVE_FORGOTTEN_DATASET, retrieveForgottenDatasetWorker);
  yield takeEvery(UPDATE_DATASET_REQUEST, updateDatasetRequestWorker);
  yield takeEvery(UPDATE_DATASET_SUCCESS, updateDatasetSuccessWorker);
  yield takeEvery(UPDATE_DATASET_FAILURE, updateDatasetFailureWorker);
  yield takeEachDataset(SAMPLE_DATASET_REQUEST, selectDatasetRequest);
  yield takeEvery(SAMPLE_SESSION_DATASET_REQUEST, sampleSessionDatasetWorker);
}
