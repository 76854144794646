import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { VISUAL_TYPES } from '../../../constants';
import { createPublicLinkRequest } from '../../../store/actions/share.actions';
import ChartMessage from '../Business/AvaConversation/Messages/ChartMessage';
import './ShareLinkDialog.scss';

const getChartId = (message) => message?.additional_info?.dc_chart_id;
const getTabChartParentId = (message) => getChartId(message);
/* Returns the dc chart id of the child chart. If not present, we return the parent's dc chart id */
const getTabChartChildID = (tabChartMessage, chartName) =>
  getChartId(tabChartMessage?.data?.tab_contents?.[chartName]) ||
  getTabChartParentId(tabChartMessage);

const CHART_ID_MAPPER_FN = {
  [VISUAL_TYPES.VIZ]: getChartId,
  [VISUAL_TYPES.TABVISUAL]: getTabChartChildID,
};

// renders preview content in the sharkLinkDialog component
// if the node contains a tab visual, also render a dropdown so the user
// can select which chart to share
const SharePreview = ({ data, visualType }) => {
  const dispatch = useDispatch();
  const [currentChart, setCurrentChart] = React.useState(null);
  const getObjectId = CHART_ID_MAPPER_FN[visualType];
  const objectId = getObjectId ? getObjectId(data, currentChart) : '';

  useEffect(() => {
    // set which chart to show
    if (visualType === VISUAL_TYPES.TABVISUAL) {
      // if the visual type is a tab visual set the first chart as the current chart
      setCurrentChart(Object.keys(data?.data?.tab_contents || {})[0]);
    } else {
      setCurrentChart(data);
    }
    return () => {
      setCurrentChart(null);
    };
  }, [data, visualType]);

  const handleCreateLink = (dcChartId) => {
    if (!currentChart) return;
    const chartType = visualType === VISUAL_TYPES.VIZ ? 'chart' : visualType;
    dispatch(createPublicLinkRequest({ dcChartId, chartType }));
  };

  const renderData = () => {
    // otherwise, render the chart / table
    switch (visualType) {
      // if the message is a tab visual, render the chart that the user selected
      case VISUAL_TYPES.TABVISUAL: {
        const tabData = data?.data?.tab_contents || {};
        return (
          <ChartMessage
            externalName={currentChart}
            id="share-preview"
            key="share-preview"
            message={{ chart: tabData[currentChart] }}
            objectId={tabData[currentChart]?.additional_info?.dc_chart_id}
            inView
            update={tabData[currentChart]?.update}
          />
        );
      }
      case VISUAL_TYPES.VIZ: {
        return (
          <ChartMessage
            externalName={currentChart}
            id="share-preview"
            key="share-preview"
            message={{ chart: data }}
            objectId={objectId}
            inView
            update={data?.update}
          />
        );
      }
      default:
        return null;
    }
  };

  if (!currentChart) {
    return null;
  }

  return (
    <>
      <DialogContent className="SharePreview-Content">
        {currentChart && visualType === VISUAL_TYPES.TABVISUAL && (
          <Select
            className="SharePreview-Content-ChartSelector"
            onChange={(e) => setCurrentChart(e.target.value)}
            value={currentChart}
            size="small"
            variant="filled"
            disableUnderline
          >
            {Object.keys(data?.data?.tab_contents || {}).map((chart) => (
              <MenuItem key={`ava-share-preview-${chart}}`} value={chart}>
                {chart}
              </MenuItem>
            ))}
          </Select>
        )}
        <div className="SharePreview-Content-Chart">{renderData()}</div>
        <div className="SharePreview-Content-Info">
          <InfoOutlined sx={{ color: 'action.active' }} />
          <Typography variant="body2">Anyone with this link can view.</Typography>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCreateLink(objectId)} variant="contained">
          Create link
        </Button>
      </DialogActions>
    </>
  );
};

SharePreview.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  visualType: PropTypes.string,
};

SharePreview.defaultProps = {
  visualType: null,
};

export default SharePreview;
