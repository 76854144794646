import { type ChartSpecInfo, type TabSpecInfo } from '../api/chartspace.api';
import { SOURCE_TITLE_MAP } from '../constants/session';
import {
  ChartListSortOptions,
  ChartGroupByKey,
  type ChartWithParent,
  type Chart,
} from '../store/slices/chartspace.slice';

export const getSortedCharts = (charts: ChartWithParent[], sort: string): ChartWithParent[] => {
  return charts.sort((a, b) => {
    switch (sort) {
      case ChartListSortOptions.OldestFirst:
        return new Date(a.created).getTime() - new Date(b.created).getTime();
      case ChartListSortOptions.NewestFirst:
        return new Date(b.created).getTime() - new Date(a.created).getTime();
      case ChartListSortOptions.NameAscending:
        return a.name.localeCompare(b.name);
      case ChartListSortOptions.NameDescending:
        return b.name.localeCompare(a.name);
      default:
        return 0;
    }
  });
};

export const groupCharts = (
  charts: ChartWithParent[],
  groupBy: ChartGroupByKey | null,
): { [key: string]: ChartWithParent[] } => {
  if (!groupBy) {
    return { '': charts };
  }
  return charts.reduce((acc: { [key: string]: ChartWithParent[] }, chart: ChartWithParent) => {
    let key = chart[groupBy];
    if (groupBy === ChartGroupByKey.Source) {
      key = SOURCE_TITLE_MAP[key];
    }
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(chart);
    return acc;
  }, {});
};

export const sortGroupedCharts = (
  groupedCharts: { [groupBy: string]: ChartWithParent[] },
  sortParam: string,
) => {
  return (
    Object.keys(groupedCharts)
      // Sort the groups
      .sort((a, b) => {
        switch (sortParam) {
          case ChartListSortOptions.OldestFirst: {
            // Get the created dates of the charts in each group
            const createdDatesA = groupedCharts[a].map((chart) =>
              new Date(chart.created).getTime(),
            );
            const createdDatesB = groupedCharts[b].map((chart) =>
              new Date(chart.created).getTime(),
            );
            // Sort the groups by the oldest created date
            return Math.max(...createdDatesA) - Math.max(...createdDatesB);
          }
          case ChartListSortOptions.NewestFirst: {
            const createdDatesA = groupedCharts[a].map((chart) =>
              new Date(chart.created).getTime(),
            );
            const createdDatesB = groupedCharts[b].map((chart) =>
              new Date(chart.created).getTime(),
            );
            // Sort the groups by the newest created date
            return Math.max(...createdDatesB) - Math.max(...createdDatesA);
          }
          case ChartListSortOptions.NameAscending:
            return a.localeCompare(b);
          case ChartListSortOptions.NameDescending:
            return b.localeCompare(a);
          default:
            return 0;
        }
      })
      // Sort the charts within each group
      .reduce((acc: { [key: string]: ChartWithParent[] }, key: string) => {
        acc[key] = getSortedCharts(groupedCharts[key], sortParam);
        return acc;
      }, {})
  );
};

/*
 *
 * This function takes in the tabSpec and chartList and builds the tabContents
 * It is meant to bridge the gap between the tabSpec stored
 * in the database and the expected format in the TabVisual
 * @param tabSpec - The tabSpec from the chartSpec
 * @param charts - The map of charts from the store
 * @returns - The tab_contents
 */
export const buildTabContentsMessage: (
  tabSpec: TabSpecInfo[],
  charts: Record<string, Chart>,
) => Record<string, ChartSpecInfo | null> = (tabSpec, charts) => {
  // If there is no tabSpec, return empty tabContents
  if (!tabSpec) {
    return {};
  }

  const tabContents = tabSpec.reduce((acc, tab) => {
    tab.content.forEach((chart) => {
      const chartData = charts[chart.dc_chart_id];
      if (chartData) {
        acc[chart.name] = chartData.chart_spec;
      } else {
        acc[chart.name] = null;
      }
    });
    return acc;
  }, {} as Record<string, ChartSpecInfo | null>);

  return tabContents;
};

/**
 * this function takes in a list of charts and returns the available filters for parent and source
 * @param charts - the list of charts
 * @returns - the available filters
 */
export const getAvailableChartFilters = (
  charts: ChartWithParent[],
): { [ChartGroupByKey.Parent]: Set<string>; [ChartGroupByKey.Source]: Set<string> } => {
  const sources = new Set<string>();
  const parents = new Set<string>();
  charts.forEach((chart) => {
    if (!sources.has(chart.source)) sources.add(chart.source);
    if (!parents.has(chart.parent)) parents.add(chart.parent);
  });
  return { [ChartGroupByKey.Source]: sources, [ChartGroupByKey.Parent]: parents };
};
