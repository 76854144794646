import {
  COLUMN_TYPES,
  DISPLAY_TYPES,
  FALSY_BOOL_VALUES,
  FORMATTING_REGEX,
  MAX_FRACTION_DIGITS,
  TRUTHY_BOOL_VALUES,
  VALUE_FORMATTERS,
} from './constants';
import { isNumeric } from './type';
import { scientificToNumber } from './type_conversion';

/**
 * Convert a value in a boolean column to be one of "false", "true", or "null"
 */
export const formatBoolean = (rawValue: string | boolean | number | null | undefined): string => {
  if (rawValue != null) {
    if (FALSY_BOOL_VALUES.includes(rawValue)) return 'False';
    if (TRUTHY_BOOL_VALUES.includes(rawValue)) return 'True';
  }
  return 'null';
};

/**
 * Simple to string function with handling for empty values
 */
const toString = (str: unknown): string => {
  if (!str && !(str === 0 || str === '0')) return 'null';
  return String(str);
};

/**
 * Removed formatting applied to values in the BE.
 *  - Scientific notation, dollar signs, and percent signs
 */
const removeFormatting = (rawValue: number | string): number => {
  if (!Number.isNaN(rawValue)) return Number(rawValue);
  const rawValueString = toString(rawValue);
  const inScientificNotation = rawValueString.match(FORMATTING_REGEX.SCIENTIFIC);
  const inDollarFormatting = rawValueString.match(FORMATTING_REGEX.DOLLAR);
  const inPercentFormatting = rawValueString.match(FORMATTING_REGEX.PERCENT);
  const containsCommas = rawValueString.includes(',');
  let value = inScientificNotation ? scientificToNumber(rawValueString) : rawValueString;
  if (typeof value === 'string') {
    value = containsCommas ? value.split(',').join('') : value;
    value = inDollarFormatting ? value.slice(1, value.length) : value;
    value = inPercentFormatting ? value.slice(0, value.length - 1) : value;
  }
  return Number(value);
};

function formatCurrency(number: number, removeCommas = false): string {
  // Check if the original number has any decimal places
  const hasDecimals = number.toString().includes('.');
  const minDecimals = hasDecimals ? 2 : 0;

  return number
    .toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: minDecimals,
      maximumFractionDigits: 2,
      useGrouping: !removeCommas,
    })
    .replace('$-', '-$'); // Handle negative numbers correctly
}

function formatFloat(number: number, highPrecision = false, exactDecimals?: number): string {
  const defaultPrecision = 2;
  const precision =
    exactDecimals !== undefined
      ? exactDecimals
      : highPrecision
      ? MAX_FRACTION_DIGITS
      : defaultPrecision;

  const options: Intl.NumberFormatOptions = {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
    useGrouping: false,
  };

  return number.toLocaleString('en-US', options);
}

function formatInteger(number: number): string {
  return Math.round(number).toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    useGrouping: false,
  });
}

function formatPercent(number: number): string {
  // Format percentage to 2 decimal places without trailing zeros
  return number.toLocaleString('en-US', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

function formatZipCode(zipCode: string): string {
  return zipCode.padStart(5, '0');
}

interface FormatValueParams {
  value: string | number | null | undefined;
  columnType: (typeof COLUMN_TYPES)[keyof typeof COLUMN_TYPES]; // e.g. 'Float', 'Integer'
  displayType?: (typeof DISPLAY_TYPES)[keyof typeof DISPLAY_TYPES]; // e.g. 'DollarSign', 'PercentSign'
  highPrecision?: boolean;
  toScientific?: boolean;
}

/**
 * Format a value based on its column type, display type, and value formatter settings
 */
export const formatValue = ({
  value: rawValue,
  columnType,
  displayType,
  highPrecision = false,
  toScientific = false,
}: FormatValueParams): string => {
  // Early returns for non-numeric values
  if (rawValue === null || rawValue === undefined) return 'null';
  // Early return for string values that aren't a special display type (currency, percentage)
  if (columnType === COLUMN_TYPES.STRING && !displayType) return String(rawValue);

  // For exact decimal preservation, check if the original value is a string with decimals
  const isExactDecimal = typeof rawValue === 'string' && rawValue.includes('.');
  const originalDecimals = isExactDecimal ? rawValue.split('.')[1].length : 0;

  // Convert to number and handle NaN
  let number = Number(removeFormatting(rawValue));
  if (Number.isNaN(number)) {
    return String(rawValue);
  }

  // Handle scientific notation if requested
  if (toScientific) {
    const [mantissa] = number.toExponential().split('e');
    number = Number(mantissa);
  }

  // Format based on display type
  switch (displayType) {
    case DISPLAY_TYPES.DOLLAR_SIGN:
      // Always use comma separators for currency
      return formatCurrency(number, false);

    case DISPLAY_TYPES.PERCENT_SIGN:
      return formatPercent(number);

    case DISPLAY_TYPES.ZIP_CODE:
      return formatZipCode(rawValue.toString());

    default:
      // No comma separators for all other numeric values
      if (columnType === COLUMN_TYPES.FLOAT) {
        if (isExactDecimal) {
          return formatFloat(number, highPrecision, originalDecimals);
        }
        return formatFloat(number, highPrecision);
      }
      if (columnType === COLUMN_TYPES.INTEGER) {
        return formatInteger(number);
      }
      return String(number);
  }
};

interface FieldInfo {
  type: (typeof COLUMN_TYPES)[keyof typeof COLUMN_TYPES];
  displayType?: (typeof DISPLAY_TYPES)[keyof typeof DISPLAY_TYPES];
}

interface GridValueFormatterParams {
  value: any;
  [key: string]: any;
}

/**
 * A function to retrieve the correct default formatter for a column
 */
export const getValueFormatter = (
  field: FieldInfo,
  valueFormatterType: (typeof VALUE_FORMATTERS)[keyof typeof VALUE_FORMATTERS] = '',
  isScientific = false,
) => {
  switch (valueFormatterType) {
    case VALUE_FORMATTERS.NUMBER:
      return (params: GridValueFormatterParams) => {
        return formatValue({
          columnType: field.type,
          displayType: field.displayType,
          value: params.value,
          highPrecision: false,
        });
      };
    case VALUE_FORMATTERS.NUMBER_HIGH_PRECISION:
      return (params: GridValueFormatterParams) => {
        return formatValue({
          columnType: field.type,
          displayType: field.displayType,
          value: params.value,
          highPrecision: true,
        });
      };
    case VALUE_FORMATTERS.COMMA_SEPARATED_HIGH_PRECISION:
      return (params: GridValueFormatterParams) => {
        return formatValue({
          columnType: field.type,
          displayType: field.displayType,
          value: params.value,
          highPrecision: true,
        });
      };
    case VALUE_FORMATTERS.SCIENTIFIC:
      return (params: GridValueFormatterParams) => {
        return formatValue({
          columnType: field.type,
          displayType: field.displayType,
          value: params.value,
          highPrecision: false,
          toScientific: true,
        });
      };
    case VALUE_FORMATTERS.SCIENTIFIC_HIGH_PRECISION:
      return (params: GridValueFormatterParams) => {
        return formatValue({
          columnType: field.type,
          displayType: field.displayType,
          value: params.value,
          highPrecision: true,
          toScientific: true,
        });
      };
    default:
      if (isNumeric(field.type) && isScientific) {
        return (params: GridValueFormatterParams) => {
          return formatValue({
            columnType: field.type,
            displayType: field.displayType,
            value: params.value,
            highPrecision: false,
            toScientific: true,
          });
        };
      }
      return (params: GridValueFormatterParams) => {
        return formatValue({
          columnType: field.type,
          displayType: field.displayType,
          value: params.value,
        });
      };
  }
};
