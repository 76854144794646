// TODO: #IB Delete file because replaced with new IB
export const START_DASHBOARD = 'dashboard/START_DASHBOARD';
export const STOP_GENERATING_DASHBOARD = 'dashboard/STOP_GENERATING_DASHBOARD';

export const ADD_VIZ_TO_DASHBOARD = 'dashboard/ADD_VIZ_TO_DASHBOARD';

export const CLEAR_DASHBOARD_PAGE = 'dashboard/CLEAR_DASHBOARD_PAGE';
export const CLEAR_DASHBOARD_CHARTS = 'dashboard/CLEAR_DASHBOARD_CHARTS';

export const CANCEL_DASHBOARD = `dashboard/CANCEL_DASHBOARD`;

export const RESTORE_LAYOUT = 'dashboard/RESTORE_LAYOUT';

export const DELETE_DASHBOARD_REQUEST = 'dashboard/DELETE_DASHBOARD_REQUEST';
export const DELETE_DASHBOARD_SUCCESS = 'dashboard/DELETE_DASHBOARD_SUCCESS';
export const DELETE_DASHBOARD_FAILURE = 'dashboard/DELETE_DASHBOARD_FAILURE';

export const GENERATE_DASHBOARD_REQUEST = 'dashboard/GENERATE_DASHBOARD_REQUEST';
export const GENERATE_DASHBOARD_SUCCESS = 'dashboard/GENERATE_DASHBOARD_SUCCESS';
export const GENERATE_DASHBOARD_FAILURE = 'dashboard/GENERATE_DASHBOARD_FAILURE';

export const CREATE_DASHBOARD_FROM_CHAT = 'session/CREATE_DASHBOARD_FROM_CHAT';

export const GET_DASHBOARDLIST_REQUEST = 'dashboard/GET_DASHBOARDLIST_REQUEST';
export const GET_DASHBOARDLIST_SUCCESS = 'dashboard/GET_DASHBOARDLIST_SUCCESS';
export const GET_DASHBOARDLIST_FAILURE = 'dashboard/GET_DASHBOARDLIST_FAILURE';

export const GET_DASHBOARD_REQUEST = 'dashboard/GET_DASHBOARD_REQUEST';
export const GET_DASHBOARD_SUCCESS = 'dashboard/GET_DASHBOARD_SUCCESS';
export const GET_DASHBOARD_FAILURE = 'dashboard/GET_DASHBOARD_FAILURE';

export const SAVE_CHART_ITEM_TITLE = 'dashboard/SAVE_CHART_ITEM_TITLE';
export const SAVE_CHART_ITEM_INFO = 'dashboard/SAVE_CHART_ITEM_INFO';
export const SAVE_DASHBOARD_LAYOUT = 'dashboard/SAVE_DASHBOARD_LAYOUT';

export const SAVE_DASHBOARD = 'dashboard/SAVE_DASHBOARD';

export const SAVE_DASHBOARD_REQUEST = 'dashboard/SAVE_DASHBOARD_REQUEST';
export const SAVE_DASHBOARD_SUCCESS = 'dashboard/SAVE_DASHBOARD_SUCCESS';
export const SAVE_DASHBOARD_FAILURE = 'dashboard/SAVE_DASHBOARD_FAILURE';
export const RESET_DASHBOARD_SAVED_STATE = 'dashboard/RESET_DASHBOARD_SAVED_STATE';

export const OVERWRITE_DASHBOARD_REQUEST = 'dashboard/OVERWRITE_DASHBOARD_REQUEST';
export const OVERWRITE_DASHBOARD_SUCCESS = 'dashboard/OVERWRITE_DASHBOARD_SUCCESS';
export const OVERWRITE_DASHBOARD_FAILURE = 'dashboard/OVERWRITE_DASHBOARD_FAILURE';

export const SAVE_DASHBOARD_LAYOUT_REQUEST = 'dashboard/SAVE_DASHBOARD_LAYOUT_REQUEST';
export const SAVE_DASHBOARD_LAYOUT_SUCCESS = 'dashboard/SAVE_DASHBOARD_LAYOUT_SUCCESS';
export const SAVE_DASHBOARD_LAYOUT_FAILURE = 'dashboard/SAVE_DASHBOARD_LAYOUT_FAILURE';

export const SHARE_DASHBOARD_REQUEST = 'dashboard/SHARE_DASHBOARD_REQUEST';
export const SHARE_DASHBOARD_SUCCESS = 'dashboard/SHARE_DASHBOARD_SUCCESS';
export const SHARE_DASHBOARD_FAILURE = 'dashboard/SHARE_DASHBOARD_FAILURE';
export const RESET_DASHBOARD_SHARED_STATE = 'dashboard/RESET_DASHBOARD_SHARED_STATE';

export const GET_USERS_WITH_DASHBOARD_ACCESS_REQUEST =
  'dashboard/GET_USERS_WITH_DASHBOARD_ACCESS_REQUEST';
export const GET_USERS_WITH_DASHBOARD_ACCESS_SUCCESS =
  'dashboard/GET_USERS_WITH_DASHBOARD_ACCESS_SUCCESS';
export const GET_USERS_WITH_DASHBOARD_ACCESS_FAILURE =
  'dashboard/GET_USERS_WITH_DASHBOARD_ACCESS_FAILURE';

export const GET_USERS_FOR_SHARING_DASHBOARD_REQUEST =
  'dashboard/GET_USERS_FOR_SHARING_DASHBOARD_REQUEST';
export const GET_USERS_FOR_SHARING_DASHBOARD_SUCCESS =
  'dashboard/GET_USERS_FOR_SHARING_DASHBOARD_SUCCESS';
export const GET_USERS_FOR_SHARING_DASHBOARD_FAILURE =
  'dashboard/GET_USERS_FOR_SHARING_DASHBOARD_FAILURE';

export const REMOVE_DASHBOARD_ACCESS_REQUEST = 'dashboard/REMOVE_DASHBOARD_ACCESS_REQUEST';
export const REMOVE_DASHBOARD_ACCESS_SUCCESS = 'dashboard/REMOVE_DASHBOARD_ACCESS_SUCCESS';
export const REMOVE_DASHBOARD_ACCESS_FAILURE = 'dashboard/REMOVE_DASHBOARD_ACCESS_FAILURE';

export const VIEW_DASHBOARD = 'dashboard/VIEW_DASHBOARD';

export const SHOW_OVERWRITE_SUCCESS_INDICATOR = 'dashboard/SHOW_OVERWRITE_SUCCESS_INDICATOR';
export const HIDE_OVERWRITE_SUCCESS_INDICATOR = 'dashboard/HIDE_OVERWRITE_SUCCESS_INDICATOR';

export const SHOW_SHARE_SUCCESS_INDICATOR = 'dashboard/SHOW_SHARE_SUCCESS_INDICATOR';
export const HIDE_SHARE_SUCCESS_INDICATOR = 'dashboard/HIDE_SHARE_SUCCESS_INDICATOR';

export const SET_ID_TO_UPDATE = 'dashboard/SET_ID_TO_UPDATE';
export const MODIFY_DASHBOARD_CHART = 'dashboard/MODIFY_DASHBOARD_CHART';

export const RELOAD_DASHBOARD = 'dashboard/RELOAD_DASHBOARD';
export const RELOAD_DASHBOARD_SUCCESS = 'dashboard/RELOAD_DASHBOARD_SUCCESS';

export const SET_DASHBOARD_META_INFO = 'dashboard/SET_DASHBOARD_META_INFO';

/**
 * Starts a dashboard by handling session logic
 * @param {*} param0
 */
export const startDashboard = ({ dashboardName, sessionName, appId, dashboardId }) => ({
  type: START_DASHBOARD,
  dashboardName,
  sessionName,
  appId,
  dashboardId,
});

// About to reload a dashboard
// Does not have associated saga worker
// Only set the corresponding appId and isGeneratingDashboard to true
export const reloadDashboard = ({ appId }) => ({
  type: RELOAD_DASHBOARD,
  appId,
});

// Reload dashboard success
// Now, we have retrieved the name of the underlying session
// We need to save it in the state for future refresh
// Also set isGeneratingDashboard to false
export const reloadDashboardSuccess = ({ sessionName, dashboardName }) => ({
  type: RELOAD_DASHBOARD_SUCCESS,
  sessionName,
  dashboardName,
});

/**
 * Append visualization to dashboard.current.charts
 * @param {Object} newViz
 */
export const addVizToDashboard = ({ newViz }) => ({
  type: ADD_VIZ_TO_DASHBOARD,
  newViz,
});

/**
 * Clear dashboard state to initialState
 */
export const clearDashboardPage = () => ({
  type: CLEAR_DASHBOARD_PAGE,
});

/**
 * Clear dashboard charts, retaining the remaining state
 */
export const clearDashboardCharts = () => ({
  type: CLEAR_DASHBOARD_CHARTS,
});

/**
 * Cancel dashboard generation
 */
export const cancelDashboard = () => ({
  type: CANCEL_DASHBOARD,
});

/**
 * Shows alert dialog to user if attemping to switch app mode while a session is active
 */
export const createDashboardFromChat = () => ({
  type: CREATE_DASHBOARD_FROM_CHAT,
});

/**
 * Attempt to delete dashboard from database
 *
 * @param {String} dashboardName Name of the dashboard
 * @param {String} sessionName Name of the session that powers the dashboard
 * @param {Number} appId Id of the app that powers the dashboard
 * @param {Number} dashboardId Id of the dashboard
 */
export const deleteDashboardRequest = ({
  dashboardName,
  sessionName,
  appId,
  dashboardId,
  creatorEmail,
}) => ({
  type: DELETE_DASHBOARD_REQUEST,
  dashboardName,
  sessionName,
  appId,
  dashboardId,
  creatorEmail,
});

export const deleteDashboardSuccess = () => ({
  type: DELETE_DASHBOARD_SUCCESS,
});

export const deleteDashboardFailure = ({ error }) => ({
  type: DELETE_DASHBOARD_FAILURE,
  error,
});

/**
 * Save chart title in redux store
 */
export const saveChartItemTitle = ({ title, idx }) => ({
  type: SAVE_CHART_ITEM_TITLE,
  title,
  idx,
});

/**
 * Save chart info in redux store
 */
export const saveChartItemInfo = ({ info, idx }) => ({
  type: SAVE_CHART_ITEM_INFO,
  info,
  idx,
});

/**
 * Save dashboard layout to redux store
 *
 * @param {Object} layout The arrangement of the charts
 */
export const saveDashboardLayout = ({ layout }) => ({
  type: SAVE_DASHBOARD_LAYOUT,
  layout,
});

/**
 * Creates a new dashboard using the current specifications
 *
 * @param {String} dashboardName Name of the dashboard
 */
export const saveDashboard = ({ dashboardName }) => ({
  type: SAVE_DASHBOARD,
  dashboardName,
});

/**
 * Send request to server for saving dashboard into database.
 *
 * @param {String} dashboardName Name of the dashboard
 * @param {String} sessionName Name of the session powering the dashboard
 * @param {Number} appId Id of the app that the session is based on
 * @param {Object} layout Grid position of all chart items within the dashboard
 */
export const saveDashboardRequest = ({ dashboardName, sessionName, appId, layout }) => ({
  type: SAVE_DASHBOARD_REQUEST,
  dashboardName,
  sessionName,
  appId,
  layout,
});

export const saveDashboardSuccess = () => ({
  type: SAVE_DASHBOARD_SUCCESS,
});

export const saveDashboardFailure = ({ error }) => ({
  type: SAVE_DASHBOARD_FAILURE,
  error,
});

/**
 * Overwrites the current dashboard in the database.
 */
export const overwriteDashboardRequest = () => ({
  type: OVERWRITE_DASHBOARD_REQUEST,
});

export const overwriteDashboardSuccess = () => ({
  type: OVERWRITE_DASHBOARD_SUCCESS,
});

export const overwriteDashboardFailure = ({ error }) => ({
  type: OVERWRITE_DASHBOARD_FAILURE,
  error,
});

/**
 * Save layout for specific dashboard in database
 */
export const saveDashboardLayoutRequest = ({ layout, name }) => ({
  type: SAVE_DASHBOARD_LAYOUT_REQUEST,
  layout,
  name,
});

export const saveDashboardLayoutSuccess = () => ({
  type: SAVE_DASHBOARD_LAYOUT_SUCCESS,
});

export const saveDashboardLayoutFailure = ({ error }) => ({
  type: SAVE_DASHBOARD_LAYOUT_FAILURE,
  error,
});

/**
 * Initiates a request to obtain all dashboards owned by a user.
 *
 */
export const getDashboardListRequest = () => ({
  type: GET_DASHBOARDLIST_REQUEST,
});

export const getDashboardListSuccess = ({ dashboards }) => ({
  type: GET_DASHBOARDLIST_SUCCESS,
  dashboards,
});

export const getDashboardListFailure = ({ error }) => ({
  type: GET_DASHBOARDLIST_FAILURE,
  error,
});

/**
 * Get dashboard data for viewing
 * @param {Object} Name
 */
export const getDashboardRequest = ({ dashboardId }) => ({
  type: GET_DASHBOARD_REQUEST,
  dashboardId,
});

export const getDashboardSuccess = ({
  id,
  layout,
  lastModifiedDate,
  dateCreated,
  name,
  email,
}) => ({
  type: GET_DASHBOARD_SUCCESS,
  id,
  layout,
  lastModifiedDate,
  dateCreated,
  name,
  email,
});

export const getDashboardFailure = ({ error }) => ({
  type: GET_DASHBOARD_FAILURE,
  error,
});

/**
 * Generate dashboard from replay session file
 */
export const generateDashboardRequest = ({ dashboardName, sessionName, appId, isRefreshing }) => ({
  type: GENERATE_DASHBOARD_REQUEST,
  dashboardName,
  sessionName,
  appId,
  isRefreshing,
});

export const setDashboardMetaInfo = ({ dashboardName, sessionName, appId }) => ({
  type: SET_DASHBOARD_META_INFO,
  dashboardName,
  sessionName,
  appId,
});

export const generateDashboardSuccess = () => ({
  type: GENERATE_DASHBOARD_SUCCESS,
});

export const generateDashboardFailure = ({ description }) => ({
  type: GENERATE_DASHBOARD_FAILURE,
  description,
});

/**
 * Resets the saved state to default.
 */
export const resetDashboardSavedState = () => ({
  type: RESET_DASHBOARD_SAVED_STATE,
});

/**
 * Displays the save success indicator
 */
export const showOverwriteSuccessIndicator = () => ({
  type: SHOW_OVERWRITE_SUCCESS_INDICATOR,
});

/**
 * Hides the save success indicator
 */
export const hideOverwriteSuccessIndicator = () => ({
  type: HIDE_OVERWRITE_SUCCESS_INDICATOR,
});

/**
 * Send request to server for sharing dashboard into database.
 *
 * @param {Array} emails The list of email addresses for the dashboard to be shared with.
 * @param {String} origin The domain and port. Used for generating emails to shared users.
 */
export const shareDashboardRequest = ({ emails, origin }) => ({
  type: SHARE_DASHBOARD_REQUEST,
  emails,
  origin,
});

export const shareDashboardSuccess = ({ numShared }) => ({
  type: SHARE_DASHBOARD_SUCCESS,
  numShared,
});

export const shareDashboardFailure = ({ error }) => ({
  type: SHARE_DASHBOARD_FAILURE,
  error,
});

/**
 * Resets the shared state to default.
 */
export const resetDashboardSharedState = () => ({
  type: RESET_DASHBOARD_SHARED_STATE,
});

/**
 * Sends request to server for retrieving list of users that have access to the dashboard.
 */
export const getUsersWithDashboardAccessRequest = () => ({
  type: GET_USERS_WITH_DASHBOARD_ACCESS_REQUEST,
});

export const getUsersWithDashboardAccessSuccess = ({ usersWithAccess }) => ({
  type: GET_USERS_WITH_DASHBOARD_ACCESS_SUCCESS,
  usersWithAccess,
});

export const getUsersWithDashboardAccessFailure = ({ error }) => ({
  type: GET_USERS_WITH_DASHBOARD_ACCESS_FAILURE,
  error,
});

/**
 * Sends request to server for retrieving list of users that dashboard can be shared with.
 */
export const getUsersForSharingDashboardsRequest = () => ({
  type: GET_USERS_FOR_SHARING_DASHBOARD_REQUEST,
});

export const getUsersForSharingDashboardsSuccess = ({ usersToBeSharedWith }) => ({
  type: GET_USERS_FOR_SHARING_DASHBOARD_SUCCESS,
  usersToBeSharedWith,
});

export const getUsersForSharingDashboardsFailure = ({ error }) => ({
  type: GET_USERS_FOR_SHARING_DASHBOARD_FAILURE,
  error,
});

/**
 * Displays the share success indicator.
 */
export const showShareSuccessIndicator = () => ({
  type: SHOW_SHARE_SUCCESS_INDICATOR,
});

/**
 * Hides the share success indicator.
 */
export const hideShareSuccessIndicator = () => ({
  type: HIDE_SHARE_SUCCESS_INDICATOR,
});

/**
 * Sends request to server for removing dashboard access to a given user.
 */
export const removeDashboardAccessRequest = ({ email }) => ({
  type: REMOVE_DASHBOARD_ACCESS_REQUEST,
  email,
});

export const removeDashboardAccessSuccess = () => ({
  type: REMOVE_DASHBOARD_ACCESS_SUCCESS,
});

export const removeDashboardAccessFailure = () => ({
  type: REMOVE_DASHBOARD_ACCESS_FAILURE,
});

export const setIdToUpdate = ({ idToUpdate }) => ({
  type: SET_ID_TO_UPDATE,
  idToUpdate,
});

export const modifyDashboardChart = ({ data }) => ({
  type: MODIFY_DASHBOARD_CHART,
  data,
});

export const restoreLayout = () => ({
  type: RESTORE_LAYOUT,
});

export const stopGeneratingDashboard = () => ({
  type: STOP_GENERATING_DASHBOARD,
});
