export const CREATE_PUBLIC_LINK_REQUEST = 'share/CREATE_PUBLIC_LINK_REQUEST';
export const CREATE_PUBLIC_LINK_SUCCESS = 'share/CREATE_PUBLIC_LINK_SUCCESS';
export const CREATE_PUBLIC_LINK_FAILURE = 'share/CREATE_PUBLIC_LINK_FAILURE';

export const OPEN_LINK_DIALOG = 'share/OPEN_LINK_DIALOG';
export const CLOSE_LINK_DIALOG = 'share/CLOSE_LINK_DIALOG';

export const createPublicLinkRequest = ({ dcChartId, chartType }) => ({
  type: CREATE_PUBLIC_LINK_REQUEST,
  dcChartId,
  chartType,
});

export const createPublicLinkSuccess = ({ publicLink }) => ({
  type: CREATE_PUBLIC_LINK_SUCCESS,
  publicLink,
});

export const createPublicLinkFailure = ({ error }) => ({ type: CREATE_PUBLIC_LINK_FAILURE, error });

export const openLinkDialog = (shareData = {}) => ({
  type: OPEN_LINK_DIALOG,
  shareData,
});

export const closeLinkDialog = () => ({ type: CLOSE_LINK_DIALOG });
