import { RootState } from '../../configureStore';

export const selectQuestionRecommendations = (state: RootState) =>
  state.recommendations.recommendations.questions.data;
export const selectQuestionRecommendationsLoading = (state: RootState) =>
  state.recommendations.recommendations.questions.loading;
export const selectQuestionRecommendationsError = (state: RootState) =>
  state.recommendations.recommendations.questions.error;
export const selectQuestionRecommendationsLoadingStatus = (state: RootState) =>
  state.recommendations.recommendations.questions.loadingStatus;

export const selectObjectivesRecommendations = (state: RootState) =>
  state.recommendations.recommendations.objectives.data;
export const selectObjectivesRecommendationsLoading = (state: RootState) =>
  state.recommendations.recommendations.objectives.loading;
export const selectObjectivesRecommendationsError = (state: RootState) =>
  state.recommendations.recommendations.objectives.error;
export const selectObjectivesRecommendationsLoadingStatus = (state: RootState) =>
  state.recommendations.recommendations.objectives.loadingStatus;

export const selectObjectivesRecommendationsRegisterStatus = (state: RootState) =>
  state.recommendations.recommendations.objectives.registrationStatus;
