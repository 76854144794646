import axios, { AxiosResponse } from 'axios';
import { ChartSpec } from 'translate_dc_to_echart';
import { endpoints } from '../constants/endpoints';
import { NavigationItemSource, NavigationItemStatus } from '../constants/session';

export type ChartData = ChartDataV2 | ChartDataV3;

export type ChartDataV2 = {
  spec_version: 'v2';
  rows: ChartSpec['values']['rows'];
  columns: ChartSpec['values']['columns'];
  totalRowCount: number;
};

export type ChartDataV3 = {
  spec_version: 'v3';
  rows: ChartSpec['values']['rows'];
  columns: ChartSpec['values']['columns'];
};

export interface ChartSpecInfo {
  objectId: string;
  src_type?: string;
  type: string;
  typeVersion: number;
  data: {
    values: ChartSpec['values'];
    caption?: string;
    chartType?: string;
    typeVersion?: number; // Version of the chart type
    plot?: ChartSpec['plot']; // Plot info
    config?: object;
    tabs?: TabSpecInfo[];
    tabContents?: Record<string, ChartSpecInfo | null>;
  };
  update?: object;
}

export interface TabSpecInfo {
  label: string;
  content: Array<{
    name: string;
    dc_chart_id: string;
  }>;
}

export interface ChartResponse {
  dc_chart_id: string;
  name: string;
  chart_spec: ChartSpecInfo;
  dc_dataset_id: string;
  status: NavigationItemStatus;
  source: NavigationItemSource;
  typeVersion: number;
  created: string;
  updated: string;
}

export type ChartspaceResponse = Array<ChartResponse>;

export const getChartspace = (
  accessToken: string,
  sessionID: string,
): Promise<AxiosResponse<ChartspaceResponse>> =>
  axios.get(endpoints.sessionCharts(sessionID), {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

export const updateChart = (
  accessToken: string,
  sessionID: string,
  dcChartId: string,
  name?: string,
  status?: NavigationItemStatus,
  chartSpec?: ChartSpecInfo,
): Promise<AxiosResponse<ChartResponse>> =>
  axios.patch(
    endpoints.singleSessionChart(sessionID, dcChartId),
    { name, status, chart_spec: chartSpec },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
