import { saveToLS } from '../../utils/local_storage';
import {
  CHECK_LICENSE_EXPIRY_FAILURE,
  CHECK_LICENSE_EXPIRY_REQUEST,
  CHECK_LICENSE_EXPIRY_SUCCESS,
  SAVE_LICENSE_TIME_STAMP_TO_LS,
} from '../actions/license.actions';

export const initialState = {
  isCheckingLicenseExpiry: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CHECK_LICENSE_EXPIRY_REQUEST:
      return {
        ...state,
        isCheckingLicenseExpiry: true,
      };
    case CHECK_LICENSE_EXPIRY_SUCCESS:
      return {
        ...state,
        isCheckingLicenseExpiry: false,
      };
    case CHECK_LICENSE_EXPIRY_FAILURE:
      return {
        ...state,
        isCheckingLicenseExpiry: false,
      };
    case SAVE_LICENSE_TIME_STAMP_TO_LS:
      // Save timestamp in local storage
      saveToLS('licenseTimestamp', action.timeStamp);
      return {
        ...state,
      };
    default:
      return state;
  }
};
