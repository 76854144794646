import { CLOSE_ALL_DIALOG, CLOSE_DIALOG, OPEN_ALERT_DIALOG } from '../actions/dialog.actions';

export const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_ALERT_DIALOG: {
      // Check if an action with the same dialogType already exists.
      const existingDialog = state.find((dialog) => dialog.dialogType === action.dialogType);
      if (existingDialog) {
        return state;
      }

      let entry = {};
      if (action.version === 0 || action.version === undefined) {
        entry = {
          version: 0,
          title: action.title,
          descriptions: action.descriptions,
          collapsedDescriptions: action.collapsedDescriptions,
          inputs: action.inputs,
          buttons: action.buttons,
          dialogType: action.dialogType,
          dialogProps: action.dialogProps,
          id: action.id,
          closeButton: action.closeButton,
        };
      } else if (action.version === 1) {
        entry = {
          version: 1,
          dialogType: action.dialogType,
          dialogParams: action.dialogParams,
        };
      } else {
        return state;
      }

      return [...state, entry];
    }
    case CLOSE_DIALOG:
      // Assume the order of closing will always be from the head.
      return state.slice(0, state.length - 1);
    case CLOSE_ALL_DIALOG:
      return [];
    default:
      return state;
  }
};
