export const GET_WORKFLOW_PREVIEW_REQUEST = 'utterances_preview/GET_WORKFLOW_PREVIEW_REQUEST';
export const GET_WORKFLOW_PREVIEW_API_KEY_REQUEST =
  'utterances_preview/GET_WORKFLOW_PREVIEW_API_KEY_REQUEST';
export const GET_WORKFLOW_PREVIEW_FAILURE = 'utterances_preview/GET_WORKFLOW_PREVIEW_FAILURE';
export const GET_WORKFLOW_PREVIEW_SUCCESS = 'utterances_preview/GET_WORKFLOW_PREVIEW_SUCCESS';

export const GET_SESSION_PREVIEW_REQUEST = 'utterances_preview/GET_SESSION_PREVIEW_REQUEST';
export const GET_SESSION_PREVIEW_SUCCESS = 'utterances_preview/GET_SESSION_PREVIEW_SUCCESS';
export const GET_SESSION_PREVIEW_FAILURE = 'utterances_preview/GET_SESSION_PREVIEW_FAILURE';

export const CLEAR_UTTERANCES_PREVIEW = 'utterances_preview/CLEAR_UTTERANCES_PREVIEW';

export const getWorkflowPreviewRequest = ({ workflowId, latestOnly = false }) => ({
  type: GET_WORKFLOW_PREVIEW_REQUEST,
  workflowId,
  latestOnly,
});

export const getWorkflowPreviewAPIKeyRequest = ({ workflowId, clientId, secret }) => ({
  type: GET_WORKFLOW_PREVIEW_API_KEY_REQUEST,
  workflowId,
  clientId,
  secret,
});

export const getWorkflowPreviewFailure = ({ workflowId, error }) => ({
  type: GET_WORKFLOW_PREVIEW_FAILURE,
  workflowId,
  error,
});

export const getWorkflowPreviewSuccess = ({ workflowId, latestVersion, data, name }) => ({
  type: GET_WORKFLOW_PREVIEW_SUCCESS,
  workflowId,
  latestVersion,
  data,
  name,
});

export const getSessionPreviewRequest = ({ sessionId }) => ({
  type: GET_SESSION_PREVIEW_REQUEST,
  sessionId,
});

export const getSessionPreviewSuccess = ({ sessionId, userMessages, sessionName }) => ({
  type: GET_SESSION_PREVIEW_SUCCESS,
  sessionId,
  userMessages,
  sessionName,
});

export const getSessionPreviewFailure = ({ error }) => ({
  type: GET_SESSION_PREVIEW_FAILURE,
  error,
});

export const clearUtterancesPreview = () => ({
  type: CLEAR_UTTERANCES_PREVIEW,
});
