import { put, select, takeEvery } from 'redux-saga/effects';
import { getAllDefinedExpressions } from '../../api/expressions.api';
import {
  GET_SAVED_EXPRESSIONS,
  getSavedExpressionsFailure,
  getSavedExpressionsSuccess,
} from '../actions/expressions.actions';
import { selectSession } from '../selectors/session.selector';
import { selectAccessToken, selectSelectedDataset } from './selectors';
import { callAPIWithRetry } from './utils/retry';

/**
 * Attempts to retrieve the current saved expressions
 */
function* openSavedExpressionWorker() {
  try {
    // Don't bother getting expressions if they don't exist.
    const currentDataset = yield select(selectSelectedDataset);
    if (Object.keys(currentDataset).length === 0) {
      yield put(
        getSavedExpressionsSuccess({
          expressions: [],
        }),
      );
    }

    const sessionID = yield select(selectSession);
    const accessToken = yield select(selectAccessToken);
    const response = yield callAPIWithRetry({
      apiFn: getAllDefinedExpressions,
      args: [accessToken, sessionID, []],
    });
    const { data } = response;
    yield put(
      getSavedExpressionsSuccess({
        expressions: data?.current ? data?.current : [],
      }),
    );
  } catch (error) {
    yield put(getSavedExpressionsFailure({ error }));
  }
}

export default function* () {
  yield takeEvery(GET_SAVED_EXPRESSIONS, openSavedExpressionWorker);
}
