/**
 * This file handles the polling mechanism for receiving messages from
 * the server.
 */
import { cancel, delay, fork, put, race, select, take } from 'redux-saga/effects';

import { postLogUserAction } from '../../api/log_user_action.api';
import { USER_ACTION_SESSION_INACTIVE } from '../../constants/user_actions';
import { makePercentageBackoff } from '../../utils/backoff_calculations';
import { minutesToMilliseconds } from '../../utils/time';
import { getGraphUpdateRequest } from '../actions/graphMode.actions';
import { receiveMessagesRequest } from '../actions/messages.actions';
import { RESTART_POLLING_MECHANISM, STOP_POLLING_MECHANISM } from '../actions/poll.actions';
import { selectSession } from '../selectors/session.selector';
import { receiveSessionNotificationsRequest } from '../slices/session.slice';
import { selectAccessToken } from './selectors';

/** Total time (in ms) polling before a user is considered inactive. */
const POLL_TIME_TIL_INACTIVE = minutesToMilliseconds(20);

export function* receiveMessagesPoller() {
  /** Whether or not the polling user is considered inactive. */
  let inactive = false;

  /** Total time (in ms) spent polling. */
  let total = 0;

  const percentageBackoff = makePercentageBackoff();
  while (true) {
    // request messages and graph update
    yield put(getGraphUpdateRequest());
    yield put(receiveMessagesRequest());

    // calculate time to wait for next poll
    const waitFor = percentageBackoff();

    // update total time spent polling
    total += waitFor;

    // check if the user is inactive
    if (!inactive && total >= POLL_TIME_TIL_INACTIVE) {
      const accessToken = yield select(selectAccessToken);
      const SessionID = yield select(selectSession);
      const note = JSON.stringify({ SessionID });
      yield postLogUserAction(USER_ACTION_SESSION_INACTIVE, note, accessToken);
      inactive = true;
    }

    // wait for next poll
    yield delay(waitFor);
  }
}

/**
 * Poll the server for session notification on a 2 seconds period.
 */
export function* sessionNotificationPoller() {
  yield delay(500); // allows time for the server to start up
  // eslint-disable-next-line
  let backoffSum = 0;
  while (true) {
    yield put(receiveSessionNotificationsRequest());
    const backoff = 2000;
    // eslint-disable-next-line
    backoffSum += backoff;
    yield delay(backoff);
  }
}

/**
 * Watches for actions that trigger the polling mechanism.
 */
export default function* () {
  let lastTask;
  let noti;
  while (true) {
    const { start } = yield race({
      start: take(RESTART_POLLING_MECHANISM),
      cancel: take(STOP_POLLING_MECHANISM),
    });
    if (lastTask) {
      yield cancel(lastTask);
    }
    if (noti) {
      yield cancel(noti);
    }
    if (start) {
      lastTask = yield fork(receiveMessagesPoller);
      noti = yield fork(sessionNotificationPoller);
    }
  }
}
