/**
 * This file contains utility functions that check types.
 *
 */
import { gridDateComparator, gridStringOrNumberComparator } from '@mui/x-data-grid-pro';
import { COLUMN_TYPES } from './constants';

/**
 * Column types from prompts from BE
 * @type {{Integer: string, Float: string, Number: string, String: string, Date: string}}
 */
export const promptColumnTypes = {
  Number: 'Number',
  String: 'String',
  Date: 'month-date-year',
  Float: 'Float',
  Integer: 'Integer',
};

export const numericTypes = new Set(['number', 'integer', 'float', 'numeric']);

/**
 * @param {string} type a type filed returned from back end
 * @returns {boolean} whether the type is numeric
 */
export const isNumeric = (type) => (type ? numericTypes.has(type.toLowerCase()) : false);

/**
 * handling for representation types
 */
export const REPRESENTATION_TYPES = {
  STRING: 'String',
  BOOL: 'Boolean',
  DATE: 'Date',
  TIMESTAMP: 'Timestamp',
  TIME: 'Time',
  INTEGER: 'Integer',
  FLOAT: 'Float',
  INTERVAL: 'Interval',
};
export const representationNumericTypes = new Set([
  REPRESENTATION_TYPES.INTEGER,
  REPRESENTATION_TYPES.FLOAT,
]);
export const isNumericRepresentationType = (type) => {
  return representationNumericTypes.has(type);
};

export const temporalTypes = new Set([
  REPRESENTATION_TYPES.DATE,
  REPRESENTATION_TYPES.TIMESTAMP,
  REPRESENTATION_TYPES.TIME,
  REPRESENTATION_TYPES.INTERVAL,
]);
export const isTemporal = (type) => (type ? temporalTypes.has(type) : false);

/**
 * Returns the appropriate sort comparator for a given column.
 *
 * @param {object} field - Information about the column from the back end.
 * @returns {function} - a sort comparator for the column
 */
export const getSortComparator = (name, type) => {
  return (v1, v2) => {
    // Treats null and undefined values as the largest value when sorting
    if (!v1 && v1 !== 0) return 1;
    if (!v2 && v2 !== 0) return -1;

    // Handles Case for Dates and Timestamps
    if (type === COLUMN_TYPES.DATE || type === COLUMN_TYPES.TIMESTAMP) {
      const dateTime1 = new Date(v1);
      const dateTime2 = new Date(v2);
      if (dateTime1.toString() !== 'Invalid Date' && dateTime2.toString() !== 'Invalid Date') {
        return gridDateComparator(dateTime1, dateTime2);
      }
    }

    // Handles all other cases
    return gridStringOrNumberComparator(v1, v2);
  };
};
