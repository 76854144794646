import produce from 'immer';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { shouldUseBackendCompute } from 'translate_dc_to_echart';
import { DEFAULT_NUM_ROWS_IB } from '../../constants';
import { getValidFiltersByPub } from '../../pages/authenticated/InsightsBoardPage/CrossFilter/CrossFilterUtils';
import { sampleDatasetRequest } from '../../store/actions/dataset.actions';
import {
  selectCrossFilterGroups,
  selectSessionlessDatasetStorage,
} from '../../store/sagas/selectors';
import { InsightsBoardDatasetClaim, PublicationDatasetClaim } from '../../types/claim.type';
import InsightsBoardVisual from '../DisplayPanel/Visuals/InsightsBoardVisual';
import usePipelinerDatasetDescription from '../usePipelinerDatasetDescription';
import { shouldRetrieveChartData, useDataFromStorage, useTablePagination } from './dataUtils';

const InsightsBoardData = (props) => {
  const {
    embedded,
    insightsBoardId,
    publicationId,
    data,
    messageType,
    lastRefreshTime,
    messageTypeVersion,
    pipelinerDatasetId,
  } = props;
  const dispatch = useDispatch();
  const crossFilterGroups = useSelector(selectCrossFilterGroups);

  const description = usePipelinerDatasetDescription(
    pipelinerDatasetId,
    insightsBoardId
      ? new InsightsBoardDatasetClaim(insightsBoardId)
      : new PublicationDatasetClaim(publicationId),
    { totalRowCount: !embedded }, // request total row count if not embedded
  );

  /** @type {number | null} */
  const totalRowCount = embedded
    ? data.values.totalRowCount // embedded precomputes total row count
    : description.kind === 'data'
    ? description.data.totalRowCount ?? null
    : null;

  const [chartData, setChartData] = useState(data);

  /** isChart tracks whether this InsightsBoardData is for a chart. */
  const isChart = messageType === 'viz' && messageTypeVersion === 2;

  /** isTable tracks whether this InsightsBoardData is for a table. */
  const isTable = messageType === 'table';

  const { dataSampleLimit = null, rows } = data?.values ?? {};
  const retrieveChartData =
    isChart &&
    shouldRetrieveChartData({ dataSampleLimit, rows, totalRowCount: totalRowCount ?? 0 });

  /** Valid cross filters for this data. */
  const validFilters = useMemo(
    () =>
      getValidFiltersByPub({
        columns: data.values?.columns ?? [],
        crossFilterGroups,
        publicationId,
      }).validFilters,
    [data.values?.columns, crossFilterGroups, publicationId],
  );

  /** Memoized compute spec to prevent re-renders from InsightsBoardPage. */
  const computeSpec = useMemo(() => {
    const { aggregate = [], bins = [], transforms = [] } = data?.values ?? {};
    return { aggregate, bins, transforms: [...transforms, ...validFilters] };
  }, [data?.values, validFilters]);

  // request dataset sample when this data uses a pipeliner dataset to back a chart or table
  useEffect(() => {
    if (pipelinerDatasetId && (isTable || retrieveChartData)) {
      dispatch(
        sampleDatasetRequest({
          ...(isChart && { computeSpec }),
          insightsBoardId,
          isTable,
          numRows: isTable
            ? Math.min(totalRowCount ?? Number.POSITIVE_INFINITY, DEFAULT_NUM_ROWS_IB)
            : dataSampleLimit,
          pipelinerDatasetId,
          publicationId,
        }),
      );
    }
  }, [
    computeSpec,
    data,
    dataSampleLimit,
    dispatch,
    embedded,
    insightsBoardId,
    isChart,
    isTable,
    lastRefreshTime,
    pipelinerDatasetId,
    publicationId,
    retrieveChartData,
    totalRowCount,
  ]);

  /** Whether this data is from the result of a backend compute. */
  const usedCompute = shouldUseBackendCompute({ computeSpec, numRows: dataSampleLimit });

  // get dataset storage
  const datasetStorage = useSelector(selectSessionlessDatasetStorage);

  // get data
  const {
    data: dataFromStorage,
    error,
    isFailed,
    isLoading,
  } = useDataFromStorage({
    computeSpec,
    datasetStorage,
    isTable,
    pipelinerDatasetId,
    usedCompute,
  });

  useEffect(() => {
    // Don't add data from redux if we have something other than a table or chart
    if (isEmpty(dataFromStorage) || (!isChart && !isTable)) return;

    let newChartData;
    if (isChart) {
      // get columns by prefering dataFromStorage, defering to data, and defaulting to []
      const columns = dataFromStorage?.columns ?? data.values?.columns ?? [];

      newChartData = produce(data, (draftData) => {
        draftData.values = {
          columns,
          rows: dataFromStorage.rows,
          spec_version: dataFromStorage.spec_version,
          totalRowCount: description?.data?.totalRowCount,
          aggregate: data.values.aggregate,
          bins: data.values.bins,
          transforms: validFilters.concat(data.values?.transforms ?? []),
          dataSampleLimit: data.values.dataSampleLimit,
          reference: {
            ...data.values.reference,
            params: {
              ...data.values.reference.params,
            },
          },
        };
      });
    } else if (isTable) {
      newChartData = produce(data, () => {
        return {
          ...data,
          rows: dataFromStorage.rows,
          columns: dataFromStorage.columns,
          totalRowCount: description?.data?.totalRowCount,
          spec_version: dataFromStorage.spec_version,
          tableSampleRowCount: dataFromStorage.tableSampleRowCount,
        };
      });
    }

    if (!isEqual(chartData, newChartData)) setChartData(newChartData);
  }, [
    description,
    crossFilterGroups,
    dataFromStorage,
    data,
    dispatch,
    isChart,
    isTable,
    isLoading,
    isFailed,
    messageTypeVersion,
    publicationId,
    validFilters,
    chartData,
  ]);

  // get paginated row count
  const [paginatedRowCount, setPaginatedRowCount] = useTablePagination({
    data: dataFromStorage,
    dispatch,
    insightsBoardId,
    isTable,
    pipelinerDatasetId,
    publicationId,
  });

  // For legacy (non-snapshot) chart, the chart dataset reference call is expected to fail
  return isChart || isTable ? (
    <InsightsBoardVisual
      {...props}
      data={chartData || {}}
      error={error}
      useSnapshot
      isFailed={Boolean(isFailed) || description.kind === 'error'}
      isLoading={isLoading || description.kind === 'loading'}
      rowCount={paginatedRowCount}
      updateRowCount={setPaginatedRowCount}
    />
  ) : (
    <InsightsBoardVisual {...props} />
  );
};

InsightsBoardData.propTypes = {
  embedded: PropTypes.bool,
  insightsBoardId: PropTypes.string,
  publicationId: PropTypes.number,
  data: PropTypes.object,
  messageType: PropTypes.string,
  lastRefreshTime: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  messageTypeVersion: PropTypes.number,
  pipelinerDatasetId: PropTypes.string,
};

InsightsBoardData.defaultProps = {
  embedded: false,
  insightsBoardId: null,
  messageTypeVersion: 1,
  publicationId: null,
  data: null,
  messageType: null,
  lastRefreshTime: null,
  pipelinerDatasetId: null,
};

export default InsightsBoardData;
