import { Position } from 'reactflow';

/**
 * time in ms that relayouting the graph takes
 */
export const RELAYOUT_DURATION = 300;

/**
 * supported layout algorithms
 */
export const LAYOUT_ALGORITHM = {
  LAYERED_DOWN: 'LAYERED_DOWN',
  LAYERED_RIGHT: 'LAYERED_RIGHT',
};

/**
 * mapping from layout algo to position of target and source handles
 * source handle = inputs, target handle = outputs
 */
export const HANDLE_POSITION_MAP = {
  [LAYOUT_ALGORITHM.LAYERED_DOWN]: {
    SOURCE: Position.Bottom,
    TARGET: Position.Top,
  },
  [LAYOUT_ALGORITHM.LAYERED_RIGHT]: {
    SOURCE: Position.Right,
    TARGET: Position.Left,
  },
};

/**
 * layout algorithm configurations
 *
 * see https://www.eclipse.org/elk/documentation.html for documentation
 */
export const LAYOUT_CONFIG = {
  [LAYOUT_ALGORITHM.LAYERED_DOWN]: {
    'elk.algorithm': 'layered',
    'elk.direction': 'DOWN',
    'elk.hierarchyHandling': 'INCLUDE_CHILDREN',
    'elk.spacing.nodeNode': 60, // spacing between sibling nodes
    'elk.layered.spacing.nodeNodeBetweenLayers': 60, // spacing between parent and child
    'elk.layered.spacing.edgeNodeBetweenLayers': 100, // spacing between parent and children
    'elk.layered.nodePlacement.bk.fixedAlignment': 'BALANCED',
    'elk.layered.layering.strategy': 'INTERACTIVE',
    'elk.edgeRouting': 'POLYLINE',
  },
  [LAYOUT_ALGORITHM.LAYERED_RIGHT]: {
    'elk.algorithm': 'layered',
    'elk.direction': 'RIGHT',
    'elk.hierarchyHandling': 'INCLUDE_CHILDREN',
    'elk.spacing.nodeNode': 40, // spacing between sibling nodes
    'elk.layered.spacing.nodeNodeBetweenLayers': 60, // spacing between parent and child
    'elk.layered.spacing.edgeNodeBetweenLayers': 100, // spacing between parent and children
    'elk.layered.nodePlacement.bk.fixedAlignment': 'BALANCED',
    'elk.layered.layering.strategy': 'INTERACTIVE',
    'elk.edgeRouting': 'POLYLINE',
  },
};
