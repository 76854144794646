import {
  CHECK_FOR_FREE_TRIAL_FAILURE,
  CHECK_FOR_FREE_TRIAL_REQUEST,
  CHECK_FOR_FREE_TRIAL_SUCCESS,
} from '../actions/subscriptionCheckFreeTrial.actions';

export const initialState = {
  canUseFreeTrial: null,
  isRequesting: false,
  error: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CHECK_FOR_FREE_TRIAL_REQUEST:
      return {
        ...state,
        isRequesting: true,
      };
    case CHECK_FOR_FREE_TRIAL_SUCCESS:
      return {
        ...state,
        canUseFreeTrial: action.canUseFreeTrial,
        isRequesting: false,
        error: undefined,
      };
    case CHECK_FOR_FREE_TRIAL_FAILURE:
      return {
        ...state,
        error: action.error,
        isRequesting: false,
      };
    default:
      return state;
  }
};
