import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import SubTabContent from './SubTabContent';

const selectMenuOptions = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  getcontentanchorel: null,
};

/**
 * Component for rendering the menu item for a sub tab
 */
const TabContent = (props) => {
  const [openSubTabMenu, setOpenSubTabMenu] = useState(false);
  const { tab, tabIndex, tabContents, selectedTab, selectedTabIndex, changeSelectedTab } = props;

  /**
   * Updates the selected sub tab.
   * @param {String} newSelectedTab label of the new selected tab
   * @param {Number} newSelectedIndex index into the tab list of the new selected tab
   */
  const handleSubTabChange = (event, newSelectedTabIndex) => {
    event.stopPropagation();
    const newSelectedTab = event.target.value;
    setOpenSubTabMenu(false);
    changeSelectedTab(event, newSelectedTab, newSelectedTabIndex);
  };

  return (
    <div className="tab-content">
      <p className="tab-label">{tab.label}</p>
      {Array.isArray(tab?.content) && tab.content.length > 1 && selectedTabIndex === tabIndex ? (
        <Select
          open={openSubTabMenu}
          onOpen={() => setOpenSubTabMenu(true)}
          onClose={() => setOpenSubTabMenu(false)}
          MenuProps={selectMenuOptions}
          value={selectedTab}
          renderValue={(value) => value}
          onChange={(event) => handleSubTabChange(event, selectedTabIndex)}
          variant="standard"
        >
          {tab.content?.map((subTab) => (
            <MenuItem key={`tabvisual-subtab-${subTab}`} className="sub-tab" value={subTab}>
              <SubTabContent subTabLabel={subTab} subTabContent={tabContents[subTab]} />
            </MenuItem>
          ))}
        </Select>
      ) : null}
    </div>
  );
};

TabContent.propTypes = {
  // from parent component
  tab: PropTypes.object.isRequired,
  tabIndex: PropTypes.number.isRequired,
  tabContents: PropTypes.object.isRequired,
  selectedTab: PropTypes.string.isRequired,
  selectedTabIndex: PropTypes.number.isRequired,
  changeSelectedTab: PropTypes.func.isRequired,
};

export default TabContent;
