import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import { PropTypes } from 'prop-types';
import React from 'react';
import { cleanFields } from '../../../echart-library/utils/chartBuilderIO';
import InfoButton from '../../InfoButton';
import DataChatAutocompleteInput from '../../common/DataChatAutocompleteInput';
import '../ChartBuilder.scss';
import ChipDropdown from '../UtilComponents/ChipDropdown';
import { iconMapping } from '../utils/constants';
import { autofillInputs } from '../utils/generateFields';
import { renderColumnOption, updateInputSelection } from '../utils/input_utils';
import { convertCamelCase, disableField, filterColumns } from '../utils/manageChart';
import AxisConfig from './AxisConfig';
import ComplexColumnProvider from './ComplexColumnProvider';

/**
 * Renders the optional input fields that may be filled in addition to the required fields
 * @returns {JSX.Element} The optional fields section within the menu
 */
const OptionalFields = (props) => {
  const {
    aggregates,
    applyAxisConfig,
    applyBin,
    bins,
    chooseDataSampleLimit,
    columnsTypes,
    fields,
    isLoadingDataset,
    options,
    transforms,
    updateChart,
    updateSmoothingField,
  } = props;
  const { type } = options.chartType;
  const { optional } = iconMapping[type];

  /**
   * Renders/wraps autocomplete options with tooltips
   * @param {Object} renderOptionProps props to pass to the option ex. key, event handlers, id etc.
   * @param {string} option the text to be displayed for the option
   * @returns the AC option wrapped in a <Tooltip>
   */
  const renderOptionWithTooltip = (renderOptionProps, option) => {
    const colType = columnsTypes.find((column) => column.name === option)?.type;
    return (
      <Tooltip title={option} key={option} placement="bottom">
        <li {...renderOptionProps} data-testid={`option-${option}`}>
          {renderColumnOption(option, colType)}
        </li>
      </Tooltip>
    );
  };

  // Gets the className for the row. The last row has a different '-bottom' class
  const getRowClass = (idx) =>
    optional.length - 1 === idx && type !== 'line' ? 'borderless-row-bottom' : 'borderless-row';

  return (
    <>
      {optional.map((input, idx) => (
        <ComplexColumnProvider
          aggregates={aggregates}
          applyBin={applyBin}
          bins={bins}
          chartType={type}
          chooseDataSampleLimit={chooseDataSampleLimit}
          columnsTypes={columnsTypes}
          fields={fields}
          inputName={input}
          key={input}
          updateChart={updateChart}
        >
          {(getComplexColumn, getComplexOptions, handleComplexAction) => (
            <div className={getRowClass(idx)} key={input}>
              <div className="label">
                {convertCamelCase(input)}
                {input === 'sliceSize' && (
                  <InfoButton
                    content={`Use the aggregate of the selected column's values, grouped by the split column, instead of the entire dataset.`}
                    tooltipWidth={205}
                  />
                )}
                <AxisConfig
                  applyAxisConfig={applyAxisConfig}
                  columnNames={fields[input]}
                  columnsTypes={columnsTypes}
                  inputName={input}
                  transforms={transforms}
                />
              </div>
              <DataChatAutocompleteInput
                blurOnSelect
                data-cy={`${input}-input`}
                data-testid={`${input}-input`}
                disableCloseOnSelect={false}
                disabled={disableField(type, input, fields)}
                isLoading={isLoadingDataset}
                multiple
                placeholder="Optional"
                value={fields[input] || []}
                onChange={(_, newSelection) => {
                  if (newSelection.length === 0) handleComplexAction(null);
                  newSelection = updateInputSelection(newSelection, true);
                  let updatedFields = cleanFields({ ...fields, [input]: newSelection });

                  // Autofill any inputs as a side-effect of the { [inputName]: newSelectionValue } pair
                  updatedFields = autofillInputs({
                    fields: updatedFields,
                    chartType: type,
                    inputName: input,
                    newSelectionValue: newSelection,
                  });

                  updateChart({ updatedFields });
                }}
                options={filterColumns(fields, columnsTypes, input, type)}
                renderOption={(renderOptionProps, option, { selected }) =>
                  renderOptionWithTooltip(renderOptionProps, option, selected, input)
                }
                renderTags={(values, getTagProps) =>
                  values.map((chipValue, index) => (
                    <ChipDropdown
                      {...getTagProps({ index })}
                      // Use the underlying column name, not the chip name
                      items={getComplexOptions(fields[input]?.[index])}
                      key={getComplexColumn(chipValue)}
                      selectedItem={getComplexColumn(chipValue)}
                      onChanged={(opt) => handleComplexAction(opt)}
                    />
                  ))
                }
              />
            </div>
          )}
        </ComplexColumnProvider>
      ))}
      {/*
      Special case: smooth expects 'true' value
      Set value to null instead of false to clear from fields
      */}
      {type === 'line' && (
        <div className="borderless-row-bottom" key="smooth">
          <div className="label">Smooth</div>
          <Grid className="grid" component="label" display="flex" alignItems="center">
            <Grid item>Off</Grid>
            <Grid item>
              <Switch
                data-cy="smooth-switch"
                data-testid="smooth-switch"
                name="smooth-switch"
                checked={fields.smooth || false}
                onChange={updateSmoothingField}
              />
            </Grid>
            <Grid item>On</Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

OptionalFields.propTypes = {
  // Aggregation details for the current chart
  aggregates: PropTypes.array,
  // Applies the new axis min/max to the chart spec
  applyAxisConfig: PropTypes.func.isRequired,
  // Applies the new bin details to the chart spec, and callbacks to checkFields
  applyBin: PropTypes.func.isRequired,
  // Bin details for the current chart
  bins: PropTypes.array,
  // The function to set the data sample limit
  chooseDataSampleLimit: PropTypes.func.isRequired,
  // The column/type information in array of object format, ex. [{ name: 'Age', type: 'Float' }]
  columnsTypes: PropTypes.array.isRequired,
  // The chart builder's required & optional field selections, ex. { group: 'SibSp' }
  fields: PropTypes.object.isRequired,
  // Is the dataset currently loading?
  isLoadingDataset: PropTypes.bool.isRequired,
  // The dataset info & selected chart type
  options: PropTypes.object.isRequired,
  // Specific transforms to apply to the dataset
  transforms: PropTypes.array,
  // Updates the chart spec state
  updateChart: PropTypes.func.isRequired,
  // Updates the smoothing field in the chart spec
  updateSmoothingField: PropTypes.func.isRequired,
};

OptionalFields.defaultProps = {
  aggregates: [],
  bins: [],
  transforms: [],
};

// Set the displayName to be accessed by the container
OptionalFields.displayName = 'OptionalFields';

export default OptionalFields;
