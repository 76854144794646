import useTheme from '@mui/material/styles/useTheme';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { closeFileModal } from '../../store/actions/upload.actions';
import { selectSession } from '../../store/selectors/session.selector';
import Modal from '../Modal/Modal';
import UploadButton from '../UploadFileManager/UploadButton';

import './index.scss';

const SimpleFileUpload = (props) => {
  const { session } = props;
  const theme = useTheme();

  const titleName = session ? 'Upload and Load Files' : 'Upload Files';

  return (
    <Modal
      theme={theme}
      title={titleName}
      closeProps={{ onClick: () => props.closeFileModal() }}
      whiteBackground
      maxWidth="sm"
    >
      <div className="SimpleFileUpload">
        <UploadButton />
      </div>
    </Modal>
  );
};

SimpleFileUpload.propTypes = {
  closeFileModal: PropTypes.func.isRequired,
  session: PropTypes.string,
};

SimpleFileUpload.defaultProps = {
  session: undefined,
};

const mapStateToProps = (state) => ({
  session: selectSession(state),
});

export default connect(mapStateToProps, { closeFileModal })(SimpleFileUpload);
