import React from 'react';
import ContentEditable from 'react-contenteditable';
import sanitizeHtml from 'sanitize-html';

import { MAX_NAME_LENGTH } from '../../pages/authenticated/DataGrid/constants';
import { cleanDatasetName, removeDisallowedCharacters } from '../../utils/string';

type Props = {
  className: string,
  name: string,
  disabled: Boolean,
  saveNameCallback: () => mixed,
  isInsightsBoard: Boolean,
  contentEditableRef: React.RefObject,
};

class DatasetTextboxContent extends React.Component<Props> {
  constructor(props) {
    super(props);
    this.contentEditable = React.createRef();
    this.textboxRef = props.contentEditableRef ?? React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.state = {
      // Sanitize name every time it is set in state to avoid XSS attacks
      name: sanitizeHtml(props.name),
      oldName: sanitizeHtml(props.name),
      focused: false,
    };
  }

  // For when the user clicks outside
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('dblclick', this.handleDoubleClickOutside);
  }

  componentDidUpdate(prevProps) {
    const { name } = this.props;

    if (prevProps.name !== name) {
      this.handleNamePropsChange();
    }
  }

  // For when the user clicks outside
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.removeEventListener('dblclick', this.handleDoubleClickOutside);
  }

  handleClickOutside = (event) => {
    // Do nothing if disabled or unmounted
    if (this.props.disabled || !this.textboxRef) return;
    const clicked = this.textboxRef.current.contains(event.target);
    const { focused } = this.state;
    // If textbox was focused and clicked away, save name
    if (focused && !clicked) {
      this.setState((prevState) => ({ name: prevState.oldName, focused: false }));
    }
  };

  handleDoubleClickOutside = (event) => {
    // Do nothing if disabled or unmounted
    if (this.props.disabled || !this.textboxRef) return;
    const clicked = this.textboxRef.current.contains(event.target);
    const { focused } = this.state;
    // If the header is double clicked, focus on that element
    if (focused !== clicked) {
      this.setState({ focused: true });
      const { current } = this.contentEditable;
      current.focus();

      // Move the cursor to the end of the text
      const range = document.createRange();
      range.selectNodeContents(current);
      // Collapse range to the end
      range.collapse(false);
      const selection = window.getSelection();
      // Remove other selections and add the new one
      selection.removeAllRanges();
      selection.addRange(range);
    }
  };

  // Update the entered dataset name
  handleChange = (event) => {
    const newName = sanitizeHtml(event.target.value);
    // only changes the actual state of the ContentEditable, if the new name is within the character limit
    if (newName.length < MAX_NAME_LENGTH) {
      this.setState({ name: newName });
    }
  };

  handleKeyDown = (event) => {
    const newName = event.target.textContent;
    const allowedKeys = ['Backspace', 'Escape', 'ArrowLeft', 'ArrowRight', 'Enter'];

    // prevents the user from typing in more than 50 characters
    if (newName.trim().length >= MAX_NAME_LENGTH && !allowedKeys.includes(event.key)) {
      if (!event.metaKey || event.key !== 'a') {
        event.preventDefault();
      }
    } else {
      switch (event.key) {
        case 'Enter':
          this.contentEditable.current.blur();
          this.saveName();
          break;
        case 'Escape':
          this.setState((prevState) => ({ name: prevState.oldName }));
          this.contentEditable.current.blur();
          break;
        default:
          break;
      }
    }
  };

  handleNamePropsChange = () => {
    this.setState({ name: this.props.name, oldName: this.props.name });
  };

  // Save the dataset name
  async saveName() {
    let newDatasetName = cleanDatasetName(this.state.name);
    newDatasetName = removeDisallowedCharacters(newDatasetName);
    // Check name is different after cleaning
    if (newDatasetName === this.state.oldName) {
      this.setState((prevState) => ({ name: prevState.oldName }));
      return;
    }

    await this.props.saveNameCallback(newDatasetName);
    if (!this.props.isInsightsBoard) this.setState((prevState) => ({ name: prevState.oldName }));
    else this.setState((prevState) => ({ oldName: prevState.name }));
  }

  render() {
    return (
      <div
        className={this.props.className}
        ref={this.textboxRef}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        {/* <Tooltip title="Double click to edit"> */}
        <ContentEditable
          innerRef={this.contentEditable}
          html={this.state.name}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
          disabled={this.props.disabled || !this.state.focused}
          spellCheck={false}
          data-testid={`DatasetTextboxContent-${this.props.name}`}
          // onFocus={() =}
        />
        {/* </Tooltip> */}
      </div>
    );
  }
}

export default DatasetTextboxContent;
