/**
 * This file contains functions for formatting messages from the backend.
 */
import qs from 'qs';

import { SKILL_LEVELS } from '../constants/settings';

export const formatServerTextMessage = (msg, appView) => {
  /**
   * Server text messages can either contain plain strings, or a json object as data.
   * If json objects, they should have the splitBySkillLevel flag set to true.
   * Note that in any new sessions, json objects will not be generated.
   *
   * If a json object, the format is:
   * {
   *   "1": "string to use for skill level 1",
   *   "2": "string to be used for skill level 2",
   *   "3": "skill level 3 txt"
   * }
   */
  const { splitBySkillLevel, data } = msg;
  if (!splitBySkillLevel) {
    if (data !== null && data.constructor === Object && (data[appView] || data.default)) {
      return data[appView] ? data[appView] : data.default;
    }
    return data;
  }

  // The following codes are left to support backward compatibilities
  // If there are json format messages in existing session, we can render them
  // assuming all users are all the highest skill level we supported SKILL_LEVELS[2] = '3'
  const legacySkillLevel = SKILL_LEVELS[2];
  // Default skill level 0 to empty string
  let result = '';
  for (let i = 0; i < SKILL_LEVELS.length; i++) {
    // Get the highest skill level's text up to the one we're on.
    result = SKILL_LEVELS[i] in data ? data[SKILL_LEVELS[i]] : result;
    if (legacySkillLevel === SKILL_LEVELS[i]) {
      if (result.constructor === Object && (data[appView] || data.default)) {
        return result[appView] ? result[appView] : result.default;
      }
      return result;
    }
  }
  return result;
};

// Parse str for query params, return muted value.  Default to false
export const checkQsMuted = (str) => {
  try {
    const parsed = qs.parse(str);
    if (parsed.muted === 'true') return true;
  } catch (err) {
    /* ignored */
  }
  return false;
};

/**
 * Helper to determine if a message is a table message with no rows or columns.
 * @param {Object} message message object
 * @returns {Boolean} true if message is a table message with no rows or columns, false otherwise
 */
export const isEmptyTableMessage = (message) => {
  return (
    // is table object
    message?.type === 'table' &&
    // with no rows or no columns
    (message?.data?.totalRowCount === 0 || message?.data?.totalColumnCount === 0)
  );
};

/**
 * Helper to turn a table message into an informational message about an empty table.
 * @param {Object} message message object
 * @param {String|Object} data optional data field of message object
 * @returns {Object} informational message object
 */
export const replaceEmptyTableMessage = (
  message,
  data = 'The resulting dataset appears to be empty.',
) => {
  const emptyTableMessage = {
    ...message,
    class: 'informational',
    data,
    importance: 10,
    type: 'text',
  };
  // remove unnecessary fields
  delete emptyTableMessage.object_id;
  delete emptyTableMessage.external_name;
  return emptyTableMessage;
};
