import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { Series, SeriesGroupKeys, SeriesMarkKeys } from 'translate_dc_to_echart';
import { Fields, InputType } from '../types/chartBuilderIO';

/** Convert an unknown value to an array. */
export const makeArray = (value: any | any[]) => (Array.isArray(value) ? value : [value]);

/**
 * Creates a Fields object from the series.mark object.
 * The series.group documentation is a special case for donut charts.
 * @param mapping The chartType-specific mapping of the mark keys to the input types
 * @param mark The mark object from the Series
 * @param group The group object from the Series
 * @returns Chart Builder Fields
 */
export const mapMarkToFields = (
  mapping: Partial<Record<SeriesMarkKeys, InputType | ((group: Series['group']) => InputType)>>,
  mark: Series['mark'],
  group?: Series['group'],
): Fields => {
  const result: Partial<Fields> = {};
  if (!mark) return result;

  return Object.entries(mark).reduce((acc, [key, value]) => {
    // Get the InputType from the mapping
    const temp = mapping[key as SeriesMarkKeys];
    // Special case for donut charts
    const inputType = typeof temp === 'function' ? temp(group) : temp;
    // Add the {[InputType]: value} pair to the result
    if (inputType) {
      const curr = acc[inputType];
      const next = makeArray(value);
      acc[inputType] = curr && Array.isArray(curr) ? [...curr, ...next] : next;
    }
    return acc;
  }, result); // Initial value
};

/**
 * Creates a Fields object from the series.group object.
 * @param mapping The chartType-specific mapping of the group keys to the input types
 * @param group The group object from the Series
 * @returns Chart Builder Fields
 */
export const mapGroupToFields = (
  mapping: Partial<Record<SeriesGroupKeys, InputType>>,
  group: Series['group'],
): Fields => {
  const result: Partial<Fields> = {};
  if (!group) return result;

  // Loop through the group object and map the keys to the fields
  return Object.entries(group).reduce((acc, [input, value]) => {
    // Get the InputType from the mapping
    const inputType = mapping[input as SeriesGroupKeys];
    // Add the {[InputType]: value} pair to the result
    if (inputType) {
      const curr = acc[inputType];
      const next = makeArray(value);
      acc[inputType] = curr && Array.isArray(curr) ? [...curr, ...next] : next;
    }
    return acc;
  }, result); // Initial value
};

/**
 * Removes bad values from the fields object.
 * @param fields
 * @returns Cleaned fields
 */
export const cleanFields = (fields: Fields) => {
  for (const [key, value] of Object.entries(fields)) {
    // Special handling for non-array & non-string values
    if (!Array.isArray(value) && typeof value !== 'string') {
      if ((typeof value === 'boolean' && value) || (typeof value === 'object' && !isEmpty(value))) {
        fields[key as InputType] = value; // Clean value (keep)
      } else {
        delete fields[key as InputType]; // Unclean value (remove)
      }
      continue;
    }

    const val = makeArray(value);
    const resultValue = val?.filter((item) => !!item); // Filter out falsy values

    if (!resultValue || resultValue.length === 0) {
      delete fields[key as InputType];
    } else {
      fields[key as InputType] = resultValue;
    }
  }

  return fields;
};

/**
 * Merges multiple field objects (from multiple series)
 * that might result from generateFields()
 * @param fields1 field object 1
 * @param fields2 field object 2
 * @returns merged fields
 */
export const mergeFields = (
  fields1: Partial<Fields>,
  fields2: Partial<Fields>,
): Partial<Fields> => {
  if (Object.keys(fields2).length === 0) return fields1;

  const combinedFields: Partial<Fields> = {};

  for (const key of new Set([...Object.keys(fields1), ...Object.keys(fields2)])) {
    const keyTyped = key as keyof Fields;
    const value1 = fields1[keyTyped];
    const value2 = fields2[keyTyped];

    if (typeof value1 === 'boolean' || typeof value2 === 'boolean') {
      // Handle boolean fields like InputTypes.smooth
      combinedFields[keyTyped] = value1 ?? value2;
    } else if (!Array.isArray(value1) || !Array.isArray(value2)) {
      // Handle non-array fields like InputTypes.markLine
      combinedFields[keyTyped] = value1 ?? value2;
    } else if (Array.isArray(value1) && Array.isArray(value2) && !isEqual(value1, value2)) {
      // Handle array fields like InputTypes.x
      combinedFields[keyTyped] = [...value1, ...value2] as string[];
    } else {
      // Handle any other edge cases
      combinedFields[keyTyped] = value1 ?? value2;
    }
  }

  return combinedFields;
};
