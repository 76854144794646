import { createSelector } from 'reselect';
import { EMPTY_COLUMN } from '../../pages/authenticated/constants';
import { RootState } from '../../configureStore';
import { selectSession } from './session.selector';

export const selectSheetDict = (state: RootState) => state.dataspaceTable.sheetDict;
export const selectSortModel = createSelector(
  [
    selectSheetDict,
    selectSession,
    (_state: RootState, datasetName: string) => datasetName,
    (_state: RootState, _datasetName: string, datasetVersion: number) => datasetVersion,
  ],
  (sheetDict, sessionId, datasetName, datasetVersion) => {
    if (sessionId) {
      return (
        sheetDict[sessionId]?.[datasetName]?.[datasetVersion]?.columns?.sorting?.sortModel ?? []
      );
    }
    return [];
  },
);

export const selectValueFormatterMap = createSelector(
  [
    selectSheetDict,
    selectSession,
    (_state: RootState, datasetName: string) => datasetName,
    (_state: RootState, _datasetName: string, datasetVersion: number) => datasetVersion,
  ],
  (sheetDict, sessionId, datasetName, datasetVersion) => {
    if (sessionId) {
      return (
        sheetDict[sessionId]?.[datasetName]?.[datasetVersion]?.columns?.columns
          ?.valueFormatterMap ?? {}
      );
    }
    return {};
  },
);

export const selectDimensions = createSelector(
  [
    selectSheetDict,
    selectSession,
    (_state: RootState, datasetName: string) => datasetName,
    (_state: RootState, _datasetName: string, datasetVersion: number) => datasetVersion,
  ],
  (sheetDict, sessionId, datasetName, datasetVersion) => {
    if (sessionId) {
      return (
        sheetDict[sessionId]?.[datasetName]?.[datasetVersion]?.columns?.columns?.dimensions ?? {}
      );
    }
    return {};
  },
);

export const selectColumnVisibilityModel = createSelector(
  [
    selectSheetDict,
    selectSession,
    (_state: RootState, datasetName: string) => datasetName,
    (_state: RootState, _datasetName: string, datasetVersion: number) => datasetVersion,
  ],
  (sheetDict, sessionId, datasetName, datasetVersion) => {
    if (sessionId) {
      return (
        sheetDict[sessionId]?.[datasetName]?.[datasetVersion]?.columns?.columns
          ?.columnVisibilityModel ?? { [EMPTY_COLUMN]: false }
      );
    }
    return {};
  },
);

export const selectOrderedFields = createSelector(
  [
    selectSheetDict,
    selectSession,
    (_state: RootState, datasetName: string) => datasetName,
    (_state: RootState, _datasetName: string, datasetVersion: number) => datasetVersion,
  ],
  (sheetDict, sessionId, datasetName, datasetVersion): string[] => {
    if (sessionId) {
      return (
        sheetDict[sessionId]?.[datasetName]?.[datasetVersion]?.columns?.columns?.orderedFields ?? []
      );
    }
    return [];
  },
);

export const selectPaneOpenStatusDict = (state: RootState) =>
  state.dataspaceTable.paneOpenStatusDict;
export const selectPaneOpenStatusInSession = createSelector(
  [selectPaneOpenStatusDict, selectSession],
  (paneOpenStatusDict, sessionId) => {
    if (sessionId) {
      return paneOpenStatusDict?.[sessionId] ?? [];
    }
    return [];
  },
);
export const selectAutoOpenPaneName = (state: RootState) => state.dataspaceTable.autoOpenPaneName;
export const selectTabOrderDict = (state: RootState) => state.dataspaceTable.tabOrderDict;
