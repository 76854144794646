import {
  LOAD_UTTERANCE_FAILURE,
  LOAD_UTTERANCE_REQUEST,
  LOAD_UTTERANCE_SUCCESS,
} from '../actions/utterance.actions';

export const initialState = {
  utterance: '', // Entity-parsed utterance in HTML
  error: undefined,
  isRequesting: false,
};

/**
 * @param {Object} state
 * @param {Object} action
 * @returns {Object} new state
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_UTTERANCE_REQUEST:
      return {
        ...state,
        error: undefined,
        isRequesting: true,
      };
    case LOAD_UTTERANCE_SUCCESS:
      return {
        ...state,
        utterance: action.utterance,
        isRequesting: false,
      };
    case LOAD_UTTERANCE_FAILURE:
      return {
        ...state,
        error: action.error,
        isRequesting: false,
      };
    default:
      return state;
  }
};
