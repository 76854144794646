import isEmpty from 'lodash/isEmpty';
import { all, call, put, select, takeLeading } from 'typed-redux-saga';
import { getSupportedDisplayTypes } from '../../components/LoadCard/constants';
import { filterObjects } from '../../components/LoadCard/load_card_utils';
import { HomeObjectKeysTypes, HomeObjects } from '../../utils/homeScreen/types';
import {
  closeLoadCard,
  openLoadCard,
  openLoadCardFailure,
  openLoadCardSuccess,
} from '../slices/loadCard.slice';
import { getHomeScreenObjectsRequestWorker } from './home_screen.saga';
import { selectExperimentalFlag, selectObjectsByFolder } from './selectors';

/**
 * Requests the lists of objects that users can choose to load into the dataspace
 */
export function* openLoadCardRequestWorker(action: { payload: { showWhileLoading?: boolean } }) {
  const devMode = (yield* select(selectExperimentalFlag)) as boolean;

  try {
    yield all([
      call(getHomeScreenObjectsRequestWorker as GeneratorFunction, {
        objectType: HomeObjects.FOLDER,
      }),
      call(getHomeScreenObjectsRequestWorker as GeneratorFunction, {
        objectType: HomeObjects.DATASET,
      }),
      call(getHomeScreenObjectsRequestWorker as GeneratorFunction, {
        objectType: HomeObjects.DATAFILE,
      }),
    ]);

    // If we didn't show the load card while loading,
    // then check if we have objects before opening it
    if (action.payload.showWhileLoading === false) {
      const allObjects = yield* select(selectObjectsByFolder);
      const loadFilteredObjects = Object.entries(allObjects).reduce((acc, [, objects]) => {
        const filteredFolderObjects = filterObjects({
          keepObjectTypes: getSupportedDisplayTypes(devMode),
          objects,
        });
        return [...acc, ...filteredFolderObjects];
      }, [] as HomeObjectKeysTypes[]);

      if (isEmpty(loadFilteredObjects)) {
        yield put(closeLoadCard());
        return;
      }
    }

    yield put(openLoadCardSuccess());
  } catch (error) {
    if (error instanceof Error) {
      yield put(openLoadCardFailure({ error }));
    }
  }
}

export default function* loadCardSaga() {
  yield* takeLeading(openLoadCard, openLoadCardRequestWorker);
}
