import { HOME_OBJECTS } from '../constants/home_screen';
import { WORKSPACE_ACCESS_TYPES, workspaceObjectTypes } from '../constants/workspace';

/**
 * Get string representing owner of Workspace Object
 *
 * @param row - Object that has OwnerName and OwnerEmail properties
 * @param userEmail - Email of the logged in user - to display as 'You'
 * @returns string representing the owner
 */
export const getOwner = (row, userEmail) =>
  userEmail === row.OwnerEmail ? 'You' : `${row.OwnerName}[${row.OwnerEmail}]`;

// get the standdard home screen types (HOME_OBJECTS.{TYPE}) from workspace types
export const mapWorkpaceTypeToHomescreenType = (workspaceType) => {
  switch (workspaceType) {
    case workspaceObjectTypes.WORKFLOW:
      return HOME_OBJECTS.RECIPE;
    case workspaceObjectTypes.INSIGHTS_BOARD:
      return HOME_OBJECTS.INSIGHTS_BOARD;
    case workspaceObjectTypes.SNAPSHOT:
      return HOME_OBJECTS.SNAPSHOT;
    case workspaceObjectTypes.CONNECTION:
      return HOME_OBJECTS.CONNECTION;
    case workspaceObjectTypes.FOLDER:
      return HOME_OBJECTS.FOLDER;
    case workspaceObjectTypes.QUERY:
      return HOME_OBJECTS.QUERY;
    case workspaceObjectTypes.DATAFILE:
      return HOME_OBJECTS.DATAFILE;
    case workspaceObjectTypes.DATASET:
      return HOME_OBJECTS.DATASET;
    default:
      return workspaceType;
  }
};

// get the workspace types from standard home screen types (HOME_OBJECTS.{TYPE})
export const mapHomescreenTypeToWorkspaceType = (homescreenType) => {
  switch (homescreenType) {
    case HOME_OBJECTS.RECIPE:
      return workspaceObjectTypes.WORKFLOW;
    case HOME_OBJECTS.INSIGHTS_BOARD:
      return workspaceObjectTypes.INSIGHTS_BOARD;
    case HOME_OBJECTS.SNAPSHOT:
      return workspaceObjectTypes.SNAPSHOT;
    case HOME_OBJECTS.FOLDER:
      return workspaceObjectTypes.FOLDER;
    case HOME_OBJECTS.CONNECTION:
      return workspaceObjectTypes.CONNECTION;
    case HOME_OBJECTS.QUERY:
      return workspaceObjectTypes.QUERY;
    case HOME_OBJECTS.DATAFILE:
      return workspaceObjectTypes.DATAFILE;
    case HOME_OBJECTS.DATASET:
      return workspaceObjectTypes.DATASET;
    default:
      return homescreenType;
  }
};
export const canModify = (accessType) =>
  accessType === WORKSPACE_ACCESS_TYPES.OWNER || accessType === WORKSPACE_ACCESS_TYPES.EDITOR;
