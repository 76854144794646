import { AxiosError } from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import { isDataChatSessionPage } from '../../../constants/paths';
import { isCopyLinkEnabled } from '../../../utils/env';
import ChartBuilderContainer from '../../ChartBuilder/ChartBuilderContainer';
import AlertBanner from './AlertBanner';
import AlertButton from './ButtonRow/ButtonColumn/AlertButton';
import ChartEditButton from './ButtonRow/ButtonColumn/ChartEditButton';
import CopyLinkButton from './ButtonRow/ButtonColumn/CopyLinkButton';
import ExportBulkFigureButton from './ButtonRow/ButtonColumn/ExportBulkFigureButton';
import ExportFigureButton from './ButtonRow/ButtonColumn/ExportFigureButton';
import ExportPlotDataButton from './ButtonRow/ButtonColumn/ExportPlotDataButton';
import PanModeButton from './ButtonRow/ButtonColumn/PanModeButton';
import PublishButton from './ButtonRow/ButtonColumn/PublishButton';
import SlicedWorkflowButton from './ButtonRow/ButtonColumn/SlicedWorkflowButton';
import SliderButton from './ButtonRow/ButtonColumn/SliderButton';
import ToolboxButton from './ButtonRow/ButtonColumn/ToolboxButton';
import UseButton from './ButtonRow/ButtonColumn/UseButton';
import WorkspaceButton from './ButtonRow/ButtonColumn/WorkspaceButton';
import ButtonColumnContainer from './ButtonRow/ButtonColumnContainer';
import CancelButton from './ButtonRow/CancelButton';
import CopyStatus from './ButtonRow/CopyStatus';
import PopOutButton from './ButtonRow/PopOutButton';
import RenameIndicator from './ButtonRow/RenameIndicator';
import ButtonRowContainer from './ButtonRowContainer';
import ChartHeaderLink from './ChartHeaderLink/ChartHeaderLink';
import HeaderInfo from './HeaderInfo';
import SelectBox from './SelectBox';
import SessionStateProvider from './SessionStateProvider';
import VisualCaption from './VisualCaption';
import VisualContainer from './VisualContainer';
import VisualDisplayContainer from './VisualDisplayContainer';
import VisualDisplayFilter from './VisualDisplayFilter';
import VisualHeaderContainer from './VisualHeaderContainer';
import { shouldOpenChartBuilder } from './helpers/utils';

/**
 * A component to replace chart for a regular session (Notebook, Grid, Graph, Workflow Editor)
 */
const SessionVisual = (props) => {
  const {
    autoScroll,
    error,
    forgotten,
    isFailed,
    isLoading,
    isPopOutChart,
    msgContext,
    rowCount,
    selectedCount,
    setEditingModal,
    updateRowCount,
  } = props;

  return (
    <SessionStateProvider
      data={msgContext.chart.data}
      isPopOutChart={isPopOutChart}
      messageType={msgContext.chart.type}
      messageTypeVersion={msgContext.chart.typeVersion}
      setEditingModal={setEditingModal}
      update={msgContext.chart.update}
    >
      {(
        sliderPlaying,
        setSliderPlaying,
        textboxLoading,
        setTextboxLoading,
        copyMsg,
        setCopyMsg,
        showModeBar,
        toggleModeBar,
        isPanMode,
        togglePanMode,
        showModalPng,
        updateModalPng,
        alertOpen,
        alertText,
        setAlertOpen,
        openCaptionAlert,
        chartEditingMode,
        showEditMenu,
        keepChanges,
        toggleChartEditingModeAndClosePanMode,
        isPopoutEditable,
      ) => (
        <VisualContainer
          objectId={msgContext.chart.objectId}
          contextId={msgContext.id}
          messageType={msgContext.chart.type}
          isPopOutChart={isPopOutChart}
          forgotten={forgotten}
        >
          <VisualHeaderContainer
            messageType={msgContext.chart.type}
            chartEditingMode={chartEditingMode}
            isSelecting={selectedCount > 0}
            userMsgId={msgContext.chart.userMsgId}
            clickToScroll
          >
            {/*
            TODO: Need to remove this component when the GridSession has been deprecated.
             */}
            {!isDataChatSessionPage() && (
              <SelectBox
                objectId={msgContext.chart.objectId}
                data={msgContext.chart.data}
                isSelecting={selectedCount > 0}
                isPopOutChart={isPopOutChart}
              />
            )}
            <HeaderInfo
              objectId={msgContext.chart.objectId}
              messageType={msgContext.chart.type}
              data={msgContext.chart.data}
              externalName={msgContext.chart.externalName}
              setTextboxLoading={setTextboxLoading}
              forgotten={forgotten}
            />

            <ButtonRowContainer>
              <CopyStatus copyMsg={copyMsg} setCopyMsg={setCopyMsg} />
              <RenameIndicator textboxLoading={textboxLoading} />
              <CancelButton
                showButton={isPanMode}
                onClick={togglePanMode}
                tooltipLabel="Leave Pan Mode"
                dataCy="cancel-pan-mode-button"
              />
              {isDataChatSessionPage() && <ChartHeaderLink dcChartId={msgContext.id} />}
              {!isDataChatSessionPage() && (
                <PopOutButton
                  objectId={msgContext.chart.objectId}
                  messageType={msgContext.chart.type}
                  isPopOutChart={isPopOutChart}
                  disable={forgotten}
                />
              )}
              {forgotten ? (
                <UseButton
                  messageType={msgContext.chart.type}
                  data={msgContext.chart.data}
                  isDropDownButton={false}
                ></UseButton>
              ) : (
                <ButtonColumnContainer
                  chartEditingMode={chartEditingMode}
                  data={msgContext.chart.data}
                  frames={msgContext.chart.data.frames}
                  isPopOutChart={isPopOutChart}
                  messageType={msgContext.chart.type}
                  messageTypeVersion={msgContext.chart.typeVersion}
                >
                  {isCopyLinkEnabled() && (
                    <CopyLinkButton
                      copyMsg={copyMsg}
                      isDependencyGraph={msgContext.chart.data.isDependencyGraph}
                      setCopyMsg={setCopyMsg}
                      messageType={msgContext.chart.type}
                      objectId={msgContext.chart.objectId}
                    />
                  )}
                  <ChartEditButton
                    chartEditingMode={chartEditingMode}
                    isLoading={isLoading}
                    data={msgContext.chart.data}
                    isPopoutEditable={isPopoutEditable}
                    messageType={msgContext.chart.type}
                    messageTypeVersion={msgContext.chart.typeVersion}
                    toggleChartEditingMode={toggleChartEditingModeAndClosePanMode}
                  />
                  <ExportFigureButton
                    messageType={msgContext.chart.type}
                    data={msgContext.chart.data}
                    updateModalPng={updateModalPng}
                  />
                  <ExportBulkFigureButton
                    messageType={msgContext.chart.type}
                    objectId={msgContext.chart.objectId}
                    data={msgContext.chart.data}
                    isPopOutChart={isPopOutChart}
                  />
                  <ExportPlotDataButton
                    messageType={msgContext.chart.type}
                    data={msgContext.chart.data}
                  />
                  <AlertButton
                    alertText={alertText}
                    messageTypeVersion={msgContext.chart.typeVersion}
                    setAlertOpen={setAlertOpen}
                  />
                  <SliderButton
                    messageType={msgContext.chart.type}
                    frames={msgContext.chart.data.frames}
                    sliderPlaying={sliderPlaying}
                    setSliderPlaying={setSliderPlaying}
                  />
                  {isDataChatSessionPage() && (
                    <PopOutButton
                      objectId={msgContext.chart.objectId}
                      messageType={msgContext.chart.type}
                      isPopOutChart={isPopOutChart}
                      disable={forgotten}
                      isMenuItem
                    />
                  )}
                  <PublishButton
                    objectId={msgContext.chart.objectId}
                    messageType={msgContext.chart.type}
                    data={msgContext.chart.data}
                    update={msgContext.chart.update}
                    rowCount={rowCount}
                  />
                  <ToolboxButton
                    messageType={msgContext.chart.type}
                    messageTypeVersion={msgContext.chart.typeVersion}
                    data={msgContext.chart.data}
                    toggleModeBar={toggleModeBar}
                  />
                  <PanModeButton
                    messageType={msgContext.chart.type}
                    messageTypeVersion={msgContext.chart.typeVersion}
                    data={msgContext.chart.data}
                    togglePanMode={togglePanMode}
                  />
                  <WorkspaceButton
                    objectId={msgContext.chart.objectId}
                    messageType={msgContext.chart.type}
                  />
                  <UseButton messageType={msgContext.chart.type} data={msgContext.chart.data} />
                  {isDataChatSessionPage() && (
                    <SlicedWorkflowButton
                      data={msgContext.chart.data}
                      dcChartId={msgContext.chart.objectId}
                      isPopOutChart={isPopOutChart}
                      type={msgContext.chart.type}
                    />
                  )}
                </ButtonColumnContainer>
              )}
            </ButtonRowContainer>
          </VisualHeaderContainer>
          <AlertBanner alertOpen={alertOpen} alertText={alertText} />
          <VisualDisplayContainer
            autoScroll={autoScroll}
            clickToScroll
            contextId={msgContext.id}
            error={error}
            isFailed={isFailed}
            messageType={msgContext.chart.type}
            messageTypeVersion={msgContext.chart.typeVersion}
          >
            <VisualDisplayFilter
              chart={msgContext.chart}
              chartEditingMode={chartEditingMode}
              isLoading={isLoading}
              isFailed={isFailed}
              contextId={msgContext.id}
              keepChanges={keepChanges}
              objectId={msgContext.chart.objectId}
              openCaptionAlert={openCaptionAlert}
              rowCount={rowCount}
              setSliderPlaying={setSliderPlaying}
              showEditMenu={showEditMenu}
              showModalPng={showModalPng}
              showModeBar={showModeBar}
              isPanMode={isPanMode}
              sliderPlaying={sliderPlaying}
              toggleModeBar={toggleModeBar}
              toggleChartEditingMode={toggleChartEditingModeAndClosePanMode}
              updateModalPng={updateModalPng}
              updateRowCount={updateRowCount}
              isPopOutChart={isPopOutChart}
            />
            {shouldOpenChartBuilder({
              data: msgContext.chart.data,
              chartEditingMode,
              messageType: msgContext.chart.type,
              messageTypeVersion: msgContext.chart.typeVersion,
            }) && (
              <ChartBuilderContainer
                datasetName={msgContext.chart.data.values.reference.params.dataset}
                datasetVersion={msgContext.chart.data.values.reference.params.version}
                dcSpec={msgContext.chart.data}
                objectId={msgContext.chart.objectId}
                update={msgContext.chart.update}
                externalName={msgContext.chart.externalName}
                chartEditingMode
                toggleChartEditingMode={toggleChartEditingModeAndClosePanMode}
              />
            )}
          </VisualDisplayContainer>
          <VisualCaption
            messageType={msgContext.chart.type}
            update={msgContext.chart.update || msgContext.chart.data}
          />
        </VisualContainer>
      )}
    </SessionStateProvider>
  );
};

SessionVisual.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.instanceOf(AxiosError),
    PropTypes.instanceOf(Error),
    PropTypes.oneOf([null]),
  ]),
  forgotten: PropTypes.bool,
  isFailed: PropTypes.bool,
  isLoading: PropTypes.bool,
  msgContext: PropTypes.object.isRequired,
  selectedCount: PropTypes.number.isRequired,
  autoScroll: PropTypes.bool.isRequired,
  rowCount: PropTypes.number,
  setEditingModal: PropTypes.func,
  isPopOutChart: PropTypes.bool,
  updateRowCount: PropTypes.func,
};

SessionVisual.defaultProps = {
  error: null,
  forgotten: false,
  isFailed: false,
  isLoading: false,
  rowCount: null,
  isPopOutChart: false,
  updateRowCount: () => {},
  setEditingModal: () => {},
};

export default SessionVisual;
