import Chip, { ChipPropsSizeOverrides } from '@mui/material/Chip';
import React from 'react';
import { OverridableStringUnion } from '@mui/types';

import './DataChatChip.scss';

type Props = {
  id: string;
  label: React.ReactNode;
  disabled: boolean;
  size: OverridableStringUnion<'small' | 'medium', ChipPropsSizeOverrides>;
  onDelete: () => void;
  onClick: () => void;
  active: boolean;
};

/**
 * Wrapper component around material-ui Chip component.
 * Provides styling to the Chip component to keep this consistent throughout the app.
 */
class DataChatChip extends React.Component<Props> {
  render() {
    return (
      <Chip
        id={this.props.id}
        label={this.props.label}
        disabled={this.props.disabled}
        className={!this.props.active ? 'datachat-chip chip-inactive' : 'datachat-chip'}
        size={this.props.size}
        onDelete={this.props.onDelete}
        onClick={this.props.onClick}
        variant="filled"
        color="primary"
      />
    );
  }
}

export default DataChatChip;
