import { IntegrationProvider } from '../../types/integrations.types';

export enum OAuthContextType {
  DB_BROWSER = 'db_browser',
  INTEGRATIONS_MENU = 'integrations_menu',
  SESSION = 'session',
  NONE = 'none',
}

export type SessionContextDetails = {
  sessionId: string;
};

export type OAuthPassthroughState = {
  /** What part of the web app we were in when we started the OAuth flow */
  context: OAuthContextType;
  /** The Provider that is redirecting the request */
  databaseProvider: IntegrationProvider;
  /** Whether or not to close the window after the oAuthFlow is complete */
  closeOnRedirect?: boolean;
  /** Details corresponding to a specific context type */
  contextDetails?: SessionContextDetails;
};

export interface ProviderConfig {
  name: string;
  Logo: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  clientIdSelector: (state: any) => string;
  additionalSelectors?: {
    [key: string]: (state: any) => any;
  };
}
