import Button from '@mui/material/Button';
import { EChartsOption } from 'echarts';
import cloneDeep from 'lodash/cloneDeep';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ChartSpec, ChartTypes, getDatasetFromECOption } from 'translate_dc_to_echart';
import DataChatAutocompleteInput from '../../../common/DataChatAutocompleteInput';
import '../../ChartBuilder.scss';
import ChipDropdown from '../../UtilComponents/ChipDropdown';
import { InputFields, Line, Lines } from '../../types/ChartBuilder';
import {
  UNSUPPORTED_LINE_OPTIONS_CHART_TYPES,
  DELETE,
  DISABLED_COL_TYPES,
  LineOptions,
  LineOptionsArray,
  LineOptionsMap,
  UNSUPPORTED_BOXPLOT_OPTIONS,
} from './constants';
import {
  constructMarkLine,
  createAutocompleteInput,
  getAxisInfo,
  getAxisLimit,
  getChipTitle,
  renderOptionWithTooltip,
  validateUserInput,
} from './helpers';

/**
 * Renders the lines input field.
 */
const LinesField = ({
  chartSpec,
  chartType,
  echartsRef,
  fields,
  isLoadingDataset,
  updateChart,
}: {
  chartSpec: ChartSpec;
  chartType: ChartTypes;
  echartsRef: React.RefObject<any>;
  fields: InputFields;
  isLoadingDataset: boolean;
  updateChart: (fields: InputFields) => void;
}) => {
  const xDependency = chartSpec.plot.series?.[0]?.mark?.x;
  const yDependency = chartSpec.plot.series?.[0]?.mark?.y;
  const linesDependency = fields.markLine?.data;

  const xColumns = useMemo(() => xDependency ?? [], [xDependency]);
  const yColumns = useMemo(() => yDependency ?? [], [yDependency]);
  const lines = useMemo(() => linesDependency ?? [], [linesDependency]);

  // [ [x1, y1], [x2, y2] ]
  const [coordinates, setCoordinates] = useState<string[][]>([
    ['', ''],
    ['', ''],
  ]);
  const [xConst, setXConst] = useState<string>(''); // 'x1'
  const [yConst, setYConst] = useState<string>(''); // 'y1'
  const [hasError, setHasError] = useState<boolean>(false);

  // Load the keyIterator with the highest key value from the lines data
  const [keyIterator, setKeyIterator] = useState<number>(
    Math.max(
      0, // If there are no lines, start at 0 (-1 + 1)
      ...lines.flatMap((l) => (Array.isArray(l) ? Number(l[0].key ?? -1) : Number(l.key ?? -1))),
    ) + 1,
  );

  const cleanupState = () => {
    setCoordinates([
      ['', ''],
      ['', ''],
    ]);
    setXConst('');
    setYConst('');
  };

  // We must depend on the echartsRef to get the FE computed axis min/max
  let echartsOption: EChartsOption = {};
  if (echartsRef.current && echartsRef.current.getEchartsInstance)
    echartsOption = echartsRef.current.getEchartsInstance().getOption();

  const { columns, rows } = getDatasetFromECOption(echartsOption, chartSpec.values);

  const [xColType, xColIsNumeric, xValues, xMinRaw, xMaxRaw] = useMemo(
    () => getAxisInfo(xColumns, columns, rows),
    [xColumns, columns, rows],
  );
  const [yColType, yColIsNumeric, yValues, yMinRaw, yMaxRaw] = useMemo(
    () => getAxisInfo(yColumns, columns, rows),
    [yColumns, columns, rows],
  );

  const xMin = getAxisLimit(echartsOption.xAxis, xMinRaw, 'min');
  const xMax = getAxisLimit(echartsOption.xAxis, xMaxRaw, 'max');
  const yMin = getAxisLimit(echartsOption.yAxis, yMinRaw, 'min');
  const yMax = getAxisLimit(echartsOption.yAxis, yMaxRaw, 'max');

  const isDisabled = DISABLED_COL_TYPES.includes(xColType) || DISABLED_COL_TYPES.includes(yColType);

  const xColFreeSolo = xColIsNumeric && !UNSUPPORTED_LINE_OPTIONS_CHART_TYPES.includes(chartType);
  const yColFreeSolo = yColIsNumeric;

  const lineOptions = UNSUPPORTED_LINE_OPTIONS_CHART_TYPES.includes(chartType)
    ? LineOptionsArray.filter((option) => !UNSUPPORTED_BOXPLOT_OPTIONS.includes(option))
    : LineOptionsArray;

  /**
   * Callback to update the markLine data in the chart spec.
   * @param {Array} updatedLines The updated lines data
   * @returns {void}
   */
  const updateLines = useCallback(
    (updatedLines: Lines): void => {
      const updatedFields = cloneDeep(fields);
      updatedFields.markLine = constructMarkLine(updatedLines);
      updateChart(updatedFields);
    },
    [fields, updateChart],
  );

  /**
   * Deletes a line within the markLine data.
   */
  const deleteLine = (index: number): void => {
    const updatedFields = cloneDeep(fields);
    updatedFields.markLine.data = lines.filter((_, i) => i !== index) as Lines;
    updateChart(updatedFields);
  };

  /**
   * Apply a change to the lines data.
   * @param type The type of the line
   * @param index The index of the line to apply the change to
   */
  const applyChange = (type: 'coord' | 'xAxis' | 'yAxis', index: number): void => {
    let newLine;
    switch (type) {
      case 'coord':
        newLine = [
          {
            coord: coordinates[0],
            key: keyIterator,
          },
          { coord: coordinates[1] },
        ];
        break;
      case 'xAxis':
        newLine = {
          xAxis: xConst,
          key: keyIterator,
        };
        break;
      case 'yAxis':
        newLine = {
          yAxis: yConst,
          key: keyIterator,
        };
        break;
      default:
        break;
    }
    cleanupState();
    if (newLine === undefined) return;
    const newLines = [...lines];
    newLines[index] = newLine;
    setKeyIterator((prev) => prev + 1);
    updateLines(newLines as Lines);
  };

  /**
   * Constructs the new Line based on the new selection (last element in the selection array).
   * Returns all of the Lines with the newly-constructed Line appended.
   * Called when the user selects a new line option.
   */
  const handleChipCreation = (selection: Lines | LineOptions[]): Lines => {
    if (!selection.length) return [];

    // selection var looks like [...Lines, LineOptions]
    const selectedLineOption = selection.pop() as LineOptions;
    const previousSelections: Lines = cloneDeep(selection) as Lines;

    // String mins will be Infinity, so we select the first value from the array of vals
    const defaultXMin = String(xColFreeSolo ? xMin : xValues[0]);
    const defaultXMax = String(xColFreeSolo ? xMax : xValues[xValues.length - 1]);
    const defaultYMin = String(yColFreeSolo ? yMin : yValues[0]);
    const defaultYMax = String(yColFreeSolo ? yMax : yValues[yValues.length - 1]);

    let lineOption;
    switch (selectedLineOption) {
      case LineOptions.MIN:
      case LineOptions.MAX:
      case LineOptions.AVG:
        lineOption = { type: LineOptionsMap[selectedLineOption], key: keyIterator };
        break;
      case LineOptions.HORIZ:
        lineOption = { yAxis: defaultYMin, key: keyIterator };
        break;
      case LineOptions.VERT:
        lineOption = { xAxis: defaultXMin, key: keyIterator };
        break;
      default:
        // Only key the first coordinate to prevent duplicates
        lineOption = [
          { coord: [defaultXMin, defaultYMin], key: keyIterator },
          { coord: [defaultXMax, defaultYMax] },
        ];
    }

    // Increment keyIterator once, regardless of the case
    setKeyIterator((prev) => prev + 1);
    return [...previousSelections, lineOption] as Lines;
  };

  useEffect(() => {
    // Checks if the coordinate is within the bounds of the chart.
    const isInRange = (axis: string, coord?: number | string) => {
      const [min, max, values, freeSolo] =
        axis === 'x' ? [xMin, xMax, xValues, xColFreeSolo] : [yMin, yMax, yValues, yColFreeSolo];
      return freeSolo ? Number(coord) >= min && Number(coord) <= max : values.includes(coord);
    };

    // Checks if the line is within the bounds of the chart.
    const lineInRange = (line: Line | Line[]): boolean => {
      if (Array.isArray(line)) {
        return line.every(
          (point) => isInRange('x', point?.coord?.[0]) && isInRange('y', point?.coord?.[1]),
        );
      } else if (line.type) {
        return true;
      } else if (line.xAxis !== undefined) {
        return isInRange('x', line.xAxis);
      } else if (line.yAxis !== undefined) {
        return isInRange('y', line.yAxis);
      }
      return false;
    };

    // Filter out lines that no longer fit in the chart bounds
    const newLines = lines.filter(lineInRange);
    if (lines.length !== newLines.length) updateLines(newLines as Lines);
  }, [lines, xMin, xMax, yMin, yMax, xColFreeSolo, yColFreeSolo, xValues, yValues, updateLines]);

  /**
   * Get options for our chip dropdown menu.
   */
  const getOptions = (index: number, chipValue?: Line | Line[]) => {
    if (!chipValue) return []; // Quit without chipValue

    const parsedChipValue = Array.isArray(chipValue) ? chipValue : [chipValue];
    if (parsedChipValue[0].type) return [DELETE]; // Early return for MIN, MAX, AVG

    // Determine line type to set the values and errors.
    const lineType = parsedChipValue[0].coord
      ? 'coord'
      : parsedChipValue[0].xAxis
      ? 'xAxis'
      : 'yAxis';

    // Current values in the chip's menu which come from the component state.
    const values = {
      coord: [
        [coordinates[0][0], coordinates[0][1]],
        [coordinates[1][0], coordinates[1][1]],
      ],
      xAxis: xConst,
      yAxis: yConst,
    };

    // Get the errors for the current values.
    const errors = {
      coord: coordinates.flatMap((coord) => [
        validateUserInput(xColFreeSolo, xMin, xMax, xValues, coord[0]),
        validateUserInput(yColFreeSolo, yMin, yMax, yValues, coord[1]),
      ]),
      xAxis: [validateUserInput(xColFreeSolo, xMin, xMax, xValues, xConst)],
      yAxis: [validateUserInput(yColFreeSolo, yMin, yMax, yValues, yConst)],
    }[lineType];

    setHasError(errors.some((error) => error !== ''));

    /** Render inputs based on line type */
    const inputs = {
      coord: (
        <>
          <div className="dropdown-row">
            {createAutocompleteInput(
              'coordStartX',
              'Start X',
              values.coord[0][0],
              (_, newValue) => setCoordinates([[newValue, values.coord[0][1]], values.coord[1]]),
              xValues,
              xColFreeSolo,
              errors[0],
            )}
            {createAutocompleteInput(
              'coordStartY',
              'Start Y',
              values.coord[0][1],
              (_, newValue) => setCoordinates([[values.coord[0][0], newValue], values.coord[1]]),
              yValues,
              yColFreeSolo,
              errors[1],
            )}
          </div>
          <div className="dropdown-row">
            {createAutocompleteInput(
              'coordEndX',
              'End X',
              values.coord[1][0],
              (_, newValue) => setCoordinates([values.coord[0], [newValue, values.coord[1][1]]]),
              xValues,
              xColFreeSolo,
              errors[2],
            )}
            {createAutocompleteInput(
              'coordEndY',
              'End Y',
              values.coord[1][1],
              (_, newValue) => setCoordinates([values.coord[0], [values.coord[1][0], newValue]]),
              yValues,
              yColFreeSolo,
              errors[3],
            )}
          </div>
        </>
      ),
      xAxis: (
        <div className="dropdown-row">
          {createAutocompleteInput(
            'xConstLineInput',
            'X-value',
            values.xAxis,
            (_, newValue) => setXConst(newValue),
            xValues,
            xColFreeSolo,
            errors[0],
          )}
        </div>
      ),
      yAxis: (
        <div className="dropdown-row">
          {createAutocompleteInput(
            'yConstLineInput',
            'Y-value',
            values.yAxis,
            (_, newValue) => setYConst(newValue),
            yValues,
            yColFreeSolo,
            errors[0],
          )}
        </div>
      ),
    }[lineType];

    /** Render 'Delete' & 'Apply' buttons for the chip dropdown menu */
    const buttons = (
      <div className="dropdown-row-buttons">
        <Button
          onClick={(e) => {
            e.stopPropagation();
            deleteLine(index);
          }}
          color="primary"
          variant="outlined"
          size="small"
        >
          Delete
        </Button>
        <Button
          color="primary"
          variant="outlined"
          size="small"
          style={{ marginLeft: '5px' }}
          onClick={() => applyChange(lineType, index)}
          disabled={hasError}
        >
          Apply
        </Button>
      </div>
    );

    return [
      <div key={index}>
        {inputs}
        {buttons}
      </div>,
    ];
  };

  return (
    <div className="borderless-row" key="lines-row">
      <div className="customize-label">Lines</div>
      <DataChatAutocompleteInput
        freeSolo
        data-cy="lines-input"
        data-testid="lines-input"
        multiple
        isLoading={isLoadingDataset}
        placeholder="Select lines"
        value={lines}
        limitTags={-1}
        blurOnSelect
        disabled={isDisabled}
        tooltip={isDisabled ? 'Not supported for Date or Timestamp' : ''}
        onChange={(_, newSelection) => updateLines(handleChipCreation(newSelection))}
        options={lineOptions.filter(
          (option) =>
            !lines.find((line) =>
              Array.isArray(line) ? line[0].name === option : line.name === option,
            ),
        )}
        renderOption={(renderOptionProps, option) =>
          renderOptionWithTooltip(renderOptionProps, option)
        }
        renderTags={(values, getTagProps) =>
          values.map((val, index) => (
            <ChipDropdown
              {...getTagProps({ index })}
              items={getOptions(index, val)}
              selectedItem={getChipTitle(val)}
              key={Array.isArray(val) ? val[0].key : val.key}
              onChanged={(opt?: string | typeof DELETE) => {
                if (opt === DELETE) {
                  deleteLine(index);
                }
              }}
              onClose={() => setTimeout(() => cleanupState(), 500)}
              onOpen={() => {
                if (Array.isArray(val) && val[0].coord !== undefined) {
                  setCoordinates([val[0].coord, val[1].coord]);
                  setXConst('');
                  setYConst('');
                } else if (val.xAxis !== undefined) {
                  setXConst(val.xAxis);
                  setCoordinates([
                    ['', ''],
                    ['', ''],
                  ]);
                  setYConst('');
                } else if (val.yAxis !== undefined) {
                  setYConst(val.yAxis);
                  setCoordinates([
                    ['', ''],
                    ['', ''],
                  ]);
                  setXConst('');
                }
              }}
            />
          ))
        }
      />
    </div>
  );
};

// Set the displayName to be accessed by the container
LinesField.displayName = 'LinesField';

export default LinesField;
