import { EnhancedStore } from '@reduxjs/toolkit';
import { openAlertDialog } from '../../../store/actions/dialog.actions';
import { ErrorDetails } from '../../../types/errorCodes/errorCodes.types';
import { isDatabaseAuthErrorCode } from '../pipelinerErrorHandling';
import { BaseErrorHandler } from './BaseErrorHandler';

// inject store so we can access redux from this file
// ----------------------------------------------------
let store: EnhancedStore;
export const injectStoreToDatabaseAuthErrorHandlers = (_store: EnhancedStore) => {
  store = _store;
};
// ----------------------------------------------------

/**
 * Handles the db_auth_0001 error code from the Pipliner. This error code is thrown when the user
 * does not have access to the underlying connection in a session.
 */
export class DatabaseAuthErrorHandler extends BaseErrorHandler {
  canHandle = (errorDetails?: ErrorDetails): boolean => {
    return isDatabaseAuthErrorCode(errorDetails?.error_code);
  };

  handle = (errorDetails?: ErrorDetails): void => {
    // DO something with error
    store.dispatch(
      openAlertDialog({
        title: 'Uh-Oh!',
        descriptions: [
          errorDetails?.detail ??
            'You do not have access to database connections that are required for this session.',
          'Please contact the session owner to grant you access.',
        ],
        buttons: [],
        dialogType: 'database-auth-error',
      }),
    );
  };
}
