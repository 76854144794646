import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { COPY_TIMEOUT } from '../helpers/constants';

// A functional component to render a message for the copy status
const CopyStatus = (props) => {
  const { copyMsg, setCopyMsg } = props;

  // Reset the copy message after 2 seconds
  useEffect(() => {
    setTimeout(() => {
      setCopyMsg('Copy link to clipboard');
    }, COPY_TIMEOUT);
  }, [copyMsg, setCopyMsg]);

  return (
    <>
      {copyMsg !== 'Copy link to clipboard' && (
        <div className="ChartHeader-RefreshTime" data-cy="ChartHeader-Copy-Indicator">
          <div className="ChartLoadingIndicator-Text">{copyMsg}</div>
        </div>
      )}
    </>
  );
};

CopyStatus.propTypes = {
  // The message, either 'Copy link to clipboard' or 'Link copied to clipboard'
  copyMsg: PropTypes.string.isRequired,
  // Hook for setting the copy message
  setCopyMsg: PropTypes.func.isRequired,
};

export default CopyStatus;
