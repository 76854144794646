import { push } from 'redux-first-history';
import { put, select, takeLatest } from 'redux-saga/effects';
import { paths } from '../../constants/paths';
import { authenticate } from '../../utils/authenticate';
import {
  GO_TO_INDEX_REQUEST,
  TRY_GO_TO_INDEX_REQUEST,
  goToIndexFailure,
  goToIndexRequest,
  goToIndexSuccess,
} from '../actions/header.actions';
import { selectIsAuthenticated } from './selectors';
import { tryExitSessionAlert } from './utils/alert-channels';

export function* tryGoToIndexWorker(action) {
  const isClosing = false; // Just leaving, not closing the session
  const isAuthenticated = yield select(selectIsAuthenticated);
  const yesActions = [
    () =>
      goToIndexRequest({
        isAuthenticated:
          action.isAuthenticated === undefined ? isAuthenticated : action.isAuthenticated,
      }),
  ];
  yield* tryExitSessionAlert(isClosing, yesActions);
}

export function* goToIndexWorker(action) {
  try {
    // Location change code here
    const path = action.isAuthenticated ? paths.index : paths.login;
    yield put(push(path));

    yield put(goToIndexSuccess());
  } catch (error) {
    yield put(goToIndexFailure({ error }));
  }
}

export default function* apiWatcher() {
  yield takeLatest(TRY_GO_TO_INDEX_REQUEST, authenticate(tryGoToIndexWorker, goToIndexFailure));
  yield takeLatest(GO_TO_INDEX_REQUEST, authenticate(goToIndexWorker, goToIndexFailure));
}
