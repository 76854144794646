import Button from '@mui/material/Button';
import { format } from 'date-fns';
import isEqual from 'lodash/isEqual';
import React from 'react';
import { connect } from 'react-redux';
import { ChartSpec } from 'translate_dc_to_echart';
import { RootState } from '../../../../configureStore';
import {
  CONTACT_FORM_DEFAULTS,
  CONTACT_FORM_ERROR_DETAILS,
  CONTACT_FORM_GENERAL_ERROR_MESSAGE_TEMPLATE,
} from '../../../../constants';
import { openContactForm } from '../../../../store/actions/contact_form.actions';
import { closeDialog } from '../../../../store/actions/dialog.actions';
import { selectSession } from '../../../../store/selectors/session.selector';
import { genericErrorBoundaryTitle } from '../../../common/ErrorBoundary/constants';
import LoadingComponent from '../../../LoadingComponent/LoadingComponent';
import { DEFAULT_MSG, getUserFacingMessage } from './util/errorMessages';

type DCPlotV2ErrorBoundaryProps = {
  children: React.ReactNode;
  closeDialog: () => void;
  dcSpec: ChartSpec;
  isExportingCopy: boolean;
  isLoading: boolean;
  objectId: string;
  openContactForm: ({
    subject,
    content,
    messageType,
    hiddenDetails,
  }: {
    subject: string;
    content: string;
    messageType: string;
    hiddenDetails?: string;
  }) => void;
  sessionId: string | undefined;
  /** Sets the error in the parent. Used when parent needs to know about errors. */
  setError: (hasError: boolean) => void;
};

interface DCPlotV2ErrorBoundaryState {
  caughtError?: { error: Error; errorInfo: React.ErrorInfo };
  errorMessage?: string;
  hasError: boolean;
}

export class DCPlotV2ErrorBoundary extends React.Component<
  DCPlotV2ErrorBoundaryProps,
  DCPlotV2ErrorBoundaryState
> {
  constructor(props: DCPlotV2ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, errorMessage: undefined, caughtError: undefined };
  }

  static getDerivedStateFromError(error: Error, errorInfo: React.ErrorInfo) {
    return {
      caughtError: { error, errorInfo },
      errorMessage: getUserFacingMessage(error?.message),
      hasError: true,
    };
  }

  componentDidUpdate(prevProps: DCPlotV2ErrorBoundaryProps) {
    if (this.props.isExportingCopy) return;

    // Retry translation if any of these props change
    if (
      !isEqual(
        prevProps.dcSpec?.values?.dataSampleLimit,
        this.props.dcSpec.values.dataSampleLimit,
      ) ||
      !isEqual(prevProps.dcSpec.specUpdate, this.props.dcSpec.specUpdate) ||
      !isEqual(prevProps.dcSpec.plot.series, this.props.dcSpec.plot.series) ||
      !isEqual(prevProps.dcSpec.values.transforms, this.props.dcSpec.values.transforms) ||
      !isEqual(prevProps.dcSpec.plot.presentation, this.props.dcSpec.plot.presentation) ||
      !isEqual(prevProps.dcSpec.values.aggregate, this.props.dcSpec.values.aggregate)
    ) {
      if (this.props.setError) this.props.setError(false);
      this.setState({ hasError: false });
    }
  }

  componentDidCatch() {
    if (this.props.setError) {
      this.props.setError(true);
    }
  }

  onReportClick = () => {
    const { sessionId } = this.props;
    const { error } = this.state.caughtError ?? {};

    // Creating the hidden content
    const pathName = window.location.pathname;

    // Creating visible content
    const timeStamp = format(Date.now(), 'MMM d y K:mm a');

    // Format Error Details
    let technicalDetails = '';
    if (this.props?.objectId) {
      technicalDetails += `\nObjectID: ${this.props.objectId}\n`;
    }
    if (this.state.caughtError) {
      technicalDetails += `\nError Details: ${this.state.caughtError.error.message}\n`;
      technicalDetails += `\nError Stack: ${this.state.caughtError.error.stack}\n`;
    }

    const content = {
      subject: CONTACT_FORM_DEFAULTS.GENERAL_ERROR_SUBJECT,
      content: CONTACT_FORM_GENERAL_ERROR_MESSAGE_TEMPLATE(timeStamp, error?.message),
      messageType: CONTACT_FORM_DEFAULTS.SUBJECT_BUG_REPORT,
      hiddenDetails: CONTACT_FORM_ERROR_DETAILS(error, sessionId, pathName) + technicalDetails,
    };

    // Opening the contact form
    this.props.openContactForm(content);
    this.props.closeDialog();
  };

  render() {
    const { isLoading } = this.props;
    const { hasError, errorMessage } = this.state;

    const renderPlot = () => this.props.children;
    const renderLoading = () => <LoadingComponent />;
    const renderError = () => (
      <div className="dc-plot-v2-error-container">
        <h4 className="dc-plot-v2-error-title">{genericErrorBoundaryTitle('Chart')}</h4>
        <h4 className="dc-plot-v2-error-description">{errorMessage}</h4>
        {/* Only add the Report Error button when we catch an unexpected error */}
        {errorMessage === DEFAULT_MSG && (
          <Button className="ReportButton" variant="outlined" onClick={this.onReportClick}>
            Report Error
          </Button>
        )}
      </div>
    );
    return isLoading ? renderLoading() : hasError ? renderError() : renderPlot();
  }
}

const mapStateToProps = (state: RootState) => ({
  sessionId: selectSession(state),
});

export default connect(mapStateToProps, {
  closeDialog,
  openContactForm,
})(DCPlotV2ErrorBoundary);
