import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Divider from '@mui/material/Divider';
import Grow from '@mui/material/Grow';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { AVA_IDS } from '../../../../../constants/dataAssistant';
import Text from '../../../../ChatPanel/ChatContext/BubbleComponent/Text';
import { ScreenSizeContext } from '../../../../DataAssistant/DataAssistant';
import './AvaValidation.scss';

/**
 * Component for rendering the validation of an answer message from the Ask skill
 * (ask_prompt === 'answer').
 * @param {Object} message - information about the message
 * @param {Object} anchorRef - anchor element for the menu
 * @param {Function} handleClose - function to close the menu
 * @param {Array} tabvisualRecipe - array of steps for the rendered chart.
 * if not provided, default is []
 */

const AvaValidation = ({ recipe, tabvisualRecipe, anchorEl, handleClose }) => {
  const isMobile = useContext(ScreenSizeContext);
  const open = Boolean(anchorEl);
  const copyToClipboard = () => {
    // If there is a workflow for the tab chart, copy only that workflow and not
    // the visualize statement
    /* Example
    For the Workflow:
    1. Use the dataset Hotel_Reservations, version 1
    2. Visualize booking_status with optimization
      a. Compute the count of records for each booking_status,
          no_of_special_requests, market_segment_type
      b. Plot a bubble chart with the x-axis market_segment_type,
          the y-axis no_of_special_requests,
          the bubble size CountOfRecords,
          the bubble color booking_status
    only steps 1, a and b are copied
  */
    const finalWorkflow =
      tabvisualRecipe && tabvisualRecipe.length > 0
        ? [...recipe.slice(0, -1), ...tabvisualRecipe]
        : recipe;
    navigator.clipboard.writeText(finalWorkflow.join('\r\n'));
  };
  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      data-testid={AVA_IDS.VALIDATION_PANEL}
      placement={isMobile ? 'auto-end' : 'left-start'}
      role={undefined}
      sx={{ zIndex: 1 }}
      disablePortal
      transition
      className="AvaValidation-Container"
    >
      {({ TransitionProps }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: 'right top',
          }}
        >
          <Paper elevation={8} className="AvaValidation">
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList>
                <div className="AvaValidation-Header">
                  <Typography fontWeight="bold">Workflow</Typography>
                  <Tooltip
                    title="Steps DataChat generated to answer your question."
                    enterTouchDelay={isMobile ? 0 : 700}
                    leaveTouchDelay={isMobile ? 5000 : 1500}
                  >
                    <InfoOutlined fontSize="small" sx={{ color: 'action.active' }} />
                  </Tooltip>

                  <Tooltip
                    title="Copy Workflow"
                    enterTouchDelay={isMobile ? 0 : 700}
                    leaveTouchDelay={isMobile ? 5000 : 1500}
                  >
                    <Button
                      startIcon={
                        <ContentCopyIcon fontSize="small" sx={{ color: 'action.active' }} />
                      }
                      onClick={copyToClipboard}
                      name="copy_workflow"
                    />
                  </Tooltip>
                </div>
                <Stack
                  direction="column"
                  spacing={0.5}
                  divider={
                    <Divider sx={{ width: '100%', height: 0 }} orientation="horizontal" flexItem />
                  }
                >
                  {recipe.map((step, index) => (
                    <Box key={`validation-step-${step}`} sx={{ display: 'flex', gap: '1rem' }}>
                      <Typography variant="body2" fontWeight="bold">
                        {index + 1}
                      </Typography>
                      <Typography variant="body2" color="text.secondary" component="div">
                        <Text data={step} expanded={false} />
                      </Typography>
                    </Box>
                  ))}
                  {tabvisualRecipe.map((tabvisualStep, index) => (
                    <Box
                      key={`validation-step-${tabvisualStep}`}
                      sx={{ display: 'flex', gap: '1rem' }}
                    >
                      <Typography variant="body2" fontWeight="bold" sx={{ paddingLeft: '2rem' }}>
                        {String.fromCharCode(index + 65).toLowerCase()}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {parse(tabvisualStep)}
                      </Typography>
                    </Box>
                  ))}
                </Stack>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

AvaValidation.propTypes = {
  recipe: PropTypes.array.isRequired,
  anchorEl: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  tabvisualRecipe: PropTypes.array,
};

AvaValidation.defaultProps = {
  anchorEl: null,
  tabvisualRecipe: [],
};

export default AvaValidation;
