import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import React, { useState } from 'react';
import './StickyWrapper.scss';

export interface StickyWrapperOption {
  /** This is the id of the option, if not provided, we will use name instead */
  id?: string;
  /** This is the label of the option */
  name: string;
}

export interface StickyWrapperContentProps {
  parents: StickyWrapperOption[];
  current: StickyWrapperOption;
}

interface StickyWrapperProps {
  /** List of options to render */
  options: StickyWrapperOption[];
  /** This represents the content to render in the title */
  Content?: React.FC<StickyWrapperContentProps>;
  /** Callback when an option is clicked */
  onClick?: (id: string, name: string) => void;
  /** Parents of the current options in order of "oldest" to "youngest" */
  parents: StickyWrapperOption[];
  /** Generic Children prop */
  renderChildren?: (args: StickyWrapperContentProps) => React.ReactNode;
  /** Overrides local expanded State */
  expandedOverride?: string[];
}

const StickyWrapper: React.FC<StickyWrapperProps> = ({
  options,
  renderChildren,
  parents,
  Content,
  onClick = () => {},
  expandedOverride = undefined,
}) => {
  const [expanded, setExpanded] = useState<string[]>([]);

  const handleChange = (id: string, option: string) => {
    if (!expandedOverride) {
      // Do Not update the local state if we have an override
      setExpanded(
        expanded.includes(option) ? expanded.filter((o) => o !== option) : [...expanded, option],
      );
    }
    onClick(id, option);
  };

  return (
    <div className={`StickyWrapper${parents.length === 0 ? ' root-level' : ''}`}>
      {options.map((current) => {
        const level = parents.length;
        const leftSpacing = 8 * level;
        const topValue = level * 40;
        const isExpanded = (expandedOverride ?? expanded).includes(current.name);
        return (
          <MuiAccordion
            key={current.name}
            disableGutters
            elevation={0}
            expanded={isExpanded}
            sx={{
              '&:before': {
                display: 'none',
              },
            }}
            onChange={() => handleChange(current.id ?? '', current.name)}
          >
            <MuiAccordionSummary
              className="StickyWrapper-Summary"
              style={{
                position: 'sticky',
                top: topValue,
                zIndex: 10 - level,
                paddingLeft: leftSpacing,
              }}
              expandIcon={<ArrowDropDownIcon data-testid={`arrow-${current.name}`} />}
            >
              {Content ? <Content parents={parents} current={current} /> : current.name}
            </MuiAccordionSummary>
            <MuiAccordionDetails style={{ marginLeft: leftSpacing }}>
              {isExpanded && renderChildren && renderChildren({ parents, current })}
            </MuiAccordionDetails>
          </MuiAccordion>
        );
      })}
    </div>
  );
};

export default StickyWrapper;
