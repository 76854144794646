import { PayloadAction } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import { push } from 'redux-first-history';
import { take } from 'redux-saga/effects';
import { SagaGenerator, call, getContext, put, select, takeEvery } from 'typed-redux-saga';
import { accessUpdatedMessage } from '../../components/AccessDialog/constants';
import { ReduxSagaContext } from '../../configureStore';
import { API_SERVICES } from '../../constants/api';
import {
  CANCEL_BUTTON_KEY,
  GENERAL_ERROR_MESSAGE,
  createLeaveSessionCollaborationAlert,
} from '../../constants/dialog.constants';
import { paths } from '../../constants/paths';
import { TOAST_ERROR, TOAST_LONG, TOAST_SHORT, TOAST_SUCCESS } from '../../constants/toast';
import {
  AccessDialogSavePayloads,
  AccessorByObjectUUID,
  AccessorByUserId,
  GetAccessorsRequestPayload,
  OpenAccessDialogRequestPayload,
} from '../../types/accessDialog.types';
import { OrganizationUserInfo } from '../../types/organization.types';
import { HomeObjectKeys, HomeObjectKeysTypes, HomeObjects } from '../../utils/homeScreen/types';
import { closeDialog } from '../actions/dialog.actions';
import {
  GET_HOME_SCREEN_OBJECTS_FAILURE,
  GET_HOME_SCREEN_OBJECTS_SUCCESS,
  getHomeScreenObjectsRequest,
} from '../actions/home_screen.actions';
import {
  GET_USERS_IN_ORGANIZATION_FAILURE,
  GET_USERS_IN_ORGANIZATION_SUCCESS,
  getUsersInOrganizationRequest,
} from '../actions/organization.action';
import { addToast } from '../actions/toast.actions';
import { selectAccessDialogAccessorsByUuid } from '../selectors/accessDialog.selector';
import {
  accessDialogSaveFailure,
  accessDialogSaveRequest,
  accessDialogSaveSuccess,
  closeAccessDialog,
  getAccessorsFailure,
  getAccessorsRequest,
  getAccessorsSuccess,
  openAccessDialogFailure,
  openAccessDialogRequest,
  openAccessDialogSuccess,
} from '../slices/accessDialog.slice';
import { getOrganizationProfilePicturesRequest } from '../slices/profilePictures.slice';
import { raceGenerator } from './home_screen.saga';
import { getDefaultOrganizationDetails } from './organization.saga';
import { selectAccessToken, selectUserID, selectUsersInOrganization } from './selectors';
import { createAlertChannel } from './utils/alert-channels';
import { getAccessorsFromSessionCollaborators, getSessionOwnerID } from './utils/session_utils';
import {
  accessDialogSaveWorkspaceRequestWorker,
  getAccessorsWorkspaceRequestWorker,
  openAccessDialogWorkspaceRequestWorker,
} from './workspacev2.saga';

/**
 * Save changes within the AccessDialog modal for a session.
 */
export function* accessDialogSaveNonWorkspaceRequestWorker({
  payload,
}: PayloadAction<AccessDialogSavePayloads>): SagaGenerator<void, any> {
  const accessToken: string = yield select(selectAccessToken);
  const currentSignedInUserId: number = yield select(selectUserID);
  const { objectType, payload: editedCollaborators, uuid: sessionId } = payload;

  const { addedCollaborators, deletedCollaborators } = Object.entries(editedCollaborators).reduce(
    (acc, [userId, { name, type: accessType, email }]) => {
      const collaborator = { name, email, id: Number(userId) };
      if (accessType === '') {
        acc.deletedCollaborators.push(collaborator); // If accessType is empty, delete the collaborator
      } else {
        acc.addedCollaborators.push(collaborator); // If accessType is not empty, add the collaborator
      }
      return acc;
    },
    {
      addedCollaborators: [] as { name: string; email: string; id: number }[],
      deletedCollaborators: [] as { name: string; email: string; id: number }[],
    },
  );

  const isCurrentUserDeleted = deletedCollaborators.some(
    (collaborator) => collaborator.id === currentSignedInUserId,
  );

  try {
    // Show an alert if the current user is deleted from collaborators
    if (isCurrentUserDeleted) {
      const alertChannel = yield createAlertChannel(createLeaveSessionCollaborationAlert());
      const keyChoice = yield take(alertChannel);

      // Proceed only if the user confirms
      if (keyChoice === CANCEL_BUTTON_KEY) {
        yield put(closeAccessDialog());
        yield put(closeDialog());
        return;
      }
    }

    const sessionService = (yield getContext(
      API_SERVICES.SESSION,
    )) as ReduxSagaContext[API_SERVICES.SESSION];
    yield call(
      sessionService.patchSessionCollaborators,
      accessToken,
      sessionId,
      addedCollaborators.map((c) => c.email),
      deletedCollaborators.map((c) => c.email),
    );
    yield put(getHomeScreenObjectsRequest({ objectType, refreshing: false }));
    yield call(raceGenerator, GET_HOME_SCREEN_OBJECTS_SUCCESS, GET_HOME_SCREEN_OBJECTS_FAILURE);
    yield put(closeAccessDialog());
    yield put(
      accessDialogSaveSuccess({ uuid: sessionId, payload: editedCollaborators, objectType }),
    );
    if (addedCollaborators.length > 0) {
      yield put(
        addToast({
          toastType: TOAST_SUCCESS,
          length: TOAST_SHORT,
          message: accessUpdatedMessage(addedCollaborators),
        }),
      );
    }

    // Redirect to Home if the current user was deleted
    if (isCurrentUserDeleted) {
      yield put(closeDialog());
      yield put(push(paths.home));
    }
  } catch (error) {
    yield put(
      accessDialogSaveFailure({ uuid: sessionId, payload: editedCollaborators, objectType }),
    );
    yield put(
      addToast({
        toastType: TOAST_ERROR,
        length: TOAST_LONG,
        message: GENERAL_ERROR_MESSAGE,
      }),
    );
  }
}

/**
 * Open the AccessDialog modal for a session.
 */
export function* openAccessDialogNonWorkspaceRequestWorker({
  payload,
}: PayloadAction<OpenAccessDialogRequestPayload>) {
  const { uuid: sessionId, data } = payload;

  let accessors: AccessorByObjectUUID = yield select(selectAccessDialogAccessorsByUuid, sessionId);
  const usersByOrganization: OrganizationUserInfo = yield select(selectUsersInOrganization);

  try {
    const { organizationId } = yield call(getDefaultOrganizationDetails);

    // Get the list of users in organization if not fetched
    if (!usersByOrganization?.[organizationId]) {
      // Get the users in the organization
      yield put(getUsersInOrganizationRequest());
      yield* raceGenerator(GET_USERS_IN_ORGANIZATION_SUCCESS, GET_USERS_IN_ORGANIZATION_FAILURE);

      // Get the user profile pictures for the organization
      yield put(getOrganizationProfilePicturesRequest());
    }

    // Get the list of accessors corresponding to the uuid
    if (!accessors || isEmpty(accessors)) {
      yield put(getAccessorsRequest({ data, uuid: sessionId }));
      yield* raceGenerator(getAccessorsSuccess.type, getAccessorsFailure.type);
      accessors = yield select(selectAccessDialogAccessorsByUuid, sessionId);
    }

    // Successfully open the access dialog
    yield put(openAccessDialogSuccess({ data, uuid: sessionId }));
  } catch (error: any) {
    yield put(openAccessDialogFailure({ data, error, uuid: sessionId }));
    yield put(
      addToast({
        toastType: TOAST_ERROR,
        length: TOAST_LONG,
        message: GENERAL_ERROR_MESSAGE,
      }),
    );
  }
}

/**
 * Get the accessors (collaborators) for a session.
 */
export function* getAccessorsNonWorkspaceRequestWorker({
  payload,
}: PayloadAction<GetAccessorsRequestPayload>) {
  const { uuid: sessionId } = payload;

  try {
    const sessionOwnerId: number = yield call(getSessionOwnerID, sessionId);
    const sessionAccessors: AccessorByUserId = yield call(
      getAccessorsFromSessionCollaborators,
      sessionId,
      sessionOwnerId,
    );

    yield put(
      getAccessorsSuccess({
        accessors: sessionAccessors,
        uuid: sessionId,
      }),
    );
  } catch (error: any) {
    yield put(getAccessorsFailure({ uuid: sessionId, error }));
  }
}

/**
 * Utility to test if the incoming payload is a session object.
 */
export const isSessionPayload = ({
  data,
  objectType,
}: {
  data?: HomeObjectKeysTypes;
  objectType?: string;
}) => data?.[HomeObjectKeys.TYPE] === HomeObjects.SESSION || objectType === HomeObjects.SESSION;

export function* chooseGetAccessorsRequestWorker({
  payload,
}: PayloadAction<GetAccessorsRequestPayload>) {
  if (isSessionPayload(payload)) {
    yield call(getAccessorsNonWorkspaceRequestWorker, { payload } as unknown as any);
  } else {
    yield call(getAccessorsWorkspaceRequestWorker, { payload } as unknown as any);
  }
}

export function* chooseOpenAccessDialogRequestWorker({
  payload,
}: PayloadAction<OpenAccessDialogRequestPayload>) {
  if (isSessionPayload(payload)) {
    yield call(openAccessDialogNonWorkspaceRequestWorker, { payload } as unknown as any);
  } else {
    yield call(openAccessDialogWorkspaceRequestWorker, { payload } as unknown as any);
  }
}

export function* chooseAccessDialogSaveRequestWorker({
  payload,
}: PayloadAction<AccessDialogSavePayloads>) {
  if (isSessionPayload(payload)) {
    yield call(accessDialogSaveNonWorkspaceRequestWorker, { payload } as unknown as any);
  } else {
    yield call(accessDialogSaveWorkspaceRequestWorker, { payload } as unknown as any);
  }
}

export default function* accessDialogSaga() {
  yield* takeEvery(getAccessorsRequest.type, chooseGetAccessorsRequestWorker);
  yield* takeEvery(openAccessDialogRequest.type, chooseOpenAccessDialogRequestWorker);
  yield* takeEvery(accessDialogSaveRequest.type, chooseAccessDialogSaveRequestWorker);
}
