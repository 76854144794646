export const OPEN_CHART_BUILDER = 'chartBuilder/OPEN_CHART_BUILDER';
export const CLOSE_CHART_BUILDER = 'chartBuilder/CLOSE_CHART_BUILDER';

export const OPEN_PIVOT_BUILDER = 'chartBuilder/OPEN_PIVOT_BUILDER';
export const CLOSE_PIVOT_BUILDER = 'chartBuilder/CLOSE_PIVOT_BUILDER';

export const openChartBuilder = () => ({
  type: OPEN_CHART_BUILDER,
});

export const closeChartBuilder = () => ({
  type: CLOSE_CHART_BUILDER,
});


export const openPivotBuilder = () => ({
  type: OPEN_PIVOT_BUILDER,
});

export const closePivotBuilder = () => ({
  type: CLOSE_PIVOT_BUILDER,
});
