import { put, race, take, takeEvery } from 'redux-saga/effects';
import { RECEIVE_MESSAGES_EMPTY } from '../actions/messages.actions';
import { closeNetworkStatus } from '../actions/settings.actions';

const ignoreActionTypes = ['TOKEN_REFRESH'];

function monitorableAction(action) {
  return (
    action.type.includes('REQUEST') &&
    ignoreActionTypes.every((fragment) => !action.type.includes(fragment))
  );
}

function identifyAction(action) {
  return action.type.split('_').slice(0, -1).join('_');
}
function getSuccessType(action) {
  return `${identifyAction(action)}_SUCCESS`;
}
function getFailType(action) {
  return `${identifyAction(action)}_FAILURE`;
}

function* monitor(monitoredAction) {
  const { empty, success } = yield race({
    empty: take(RECEIVE_MESSAGES_EMPTY),
    success: take(getSuccessType(monitoredAction)),
    fail: take(getFailType(monitoredAction)),
  });

  if (empty || success) {
    // User has reconnected. Close network status.
    yield put(closeNetworkStatus());
  }
}

export default function* () {
  yield takeEvery(monitorableAction, monitor);
}
