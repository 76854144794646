import produce from 'immer';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { sampleDatasetRequest } from '../../store/actions/dataset.actions';
import {
  selectHiddenDatasetsByNameVersion,
  selectVisibleDatasetsByNameVersion,
} from '../../store/selectors/dataspace.selector';

/**
 * Hash a string into a number
 * @param {String} string The input string
 * @returns {Integer} The hash of the string
 */
export function hashCode(string) {
  let hash = 0;
  for (let i = 0; i < string.length; i++) {
    hash = (hash << 5) - hash + string.charCodeAt(i); // eslint-disable-line no-bitwise
    hash &= hash; // eslint-disable-line no-bitwise
  }
  return hash;
}

/**
 * Format the data returned from our endpoint into a format that the tables expect.
 * This is necessary because the MUI DataGridPro requires the data to be in this specific format
 * https://mui.com/x/api/data-grid/data-grid-pro/
 * We should only do this on sampled datasets (~100 rows), because it could become very slow
 * @param {Object} data From the endpoint
 * @returns Formatted data
 */
export const handleTableDataFormatting = (data) => {
  const { columns, rows } = data;

  // Return if we're missing the necessary info to perform formatting
  // Or if the data is already formatted
  if (!columns || !rows || !Array.isArray(rows[0])) {
    return data;
  }

  // Convert each row of the data into an object
  // From   [ val, val, ... ]
  // To     [{ name: val }, { name: val }, ...]
  return {
    ...data,
    rows: rows.map((row) =>
      row.reduce((acc, datapoint, index) => {
        const colName = columns[index].name;
        return { ...acc, [colName]: datapoint };
      }, {}),
    ),
  };
};

/**
 * Merges the row data, column data, and the update into the dcSpec
 * @param {Object} data The original message data
 * @param {Object} update The updates to the message data
 * @param {Integer} dataSampleLimit The number of rows to sample from the dataset
 * @param {Object} datasetStorage (optional) The row & column information stored in redux
 * @param {number} totalRowCount the total row count of the dataset
 * Legacy chart messages will instead have row & column data stored in 'data'
 * @returns The updated chart spec
 */
export const addDataAndUpdateToChartSpec = (
  data,
  update,
  dataSampleLimit,
  totalRowCount,
  datasetStorage = null,
) =>
  produce(data, (draftData) => {
    draftData.chart.data.plot = {
      series: data.chart.data.plot.series,
      ...(update.spec?.series && { series: update.spec.series }),
    };
    draftData.chart.data.plot.presentation = {
      ...data.chart.data.plot.presentation,
      ...(update.spec?.presentation && {
        horizontal: update.spec.presentation.horizontal,
        shouldAutoscale: update.spec.presentation.shouldAutoscale,
        smooth: update.spec.presentation.smooth,
      }),
      ...(update.spec?.presentation?.annotations && {
        annotations: update.spec.presentation.annotations,
      }),
      ...(update.spec?.presentation.bins && { bins: update.spec.presentation.bins }),
      ...(update.spec?.presentation?.colorOverride && {
        colorOverride: update.spec.presentation.colorOverride,
      }),
    };
    draftData.chart.data.plot.presentation.title = {
      ...data.chart.data.plot.presentation.title,
      ...(update.spec?.presentation?.title?.text && {
        text: update.spec.presentation.title.text,
      }),
      ...(update.spec?.presentation?.title?.textStyle && {
        textStyle: update.spec.presentation.title.textStyle,
      }),
    };
    draftData.chart.data.plot.presentation.xaxis = {
      ...data.chart.data.plot.presentation.xaxis,
      ...(update.spec?.presentation?.xaxis?.axisLabel && {
        axisLabel: update.spec.presentation.xaxis.axisLabel,
      }),
      ...(update.spec?.presentation?.xaxis?.nameTextStyle && {
        nameTextStyle: update.spec.presentation.xaxis.nameTextStyle,
      }),
      ...(update.spec?.presentation?.xaxis?.text && {
        text: update.spec.presentation.xaxis.text,
      }),
    };
    draftData.chart.data.plot.presentation.yaxis = {
      ...data.chart.data.plot.presentation.yaxis,
      ...(update.spec?.presentation?.yaxis?.axisLabel && {
        axisLabel: update.spec.presentation.yaxis.axisLabel,
      }),
      ...(update.spec?.presentation?.yaxis?.nameTextStyle && {
        nameTextStyle: update.spec.presentation.yaxis.nameTextStyle,
      }),
      ...(update.spec?.presentation?.yaxis?.text && {
        text: update.spec.presentation.yaxis.text,
      }),
    };
    draftData.chart.data.plot.presentation.overlayAxis = {
      ...data.chart.data.plot.presentation.overlayAxis,
      ...(update.spec?.presentation?.overlayAxis?.axisLabel && {
        axisLabel: update.spec.presentation.overlayAxis.axisLabel,
      }),
      ...(update.spec?.presentation?.overlayAxis?.nameTextStyle && {
        nameTextStyle: update.spec.presentation.overlayAxis.nameTextStyle,
      }),
      ...(update.spec?.presentation?.overlayAxis?.text && {
        text: update.spec.presentation.overlayAxis.text,
      }),
    };
    draftData.chart.data.values = {
      ...data.chart.data.values,
      ...(update.spec?.aggregate && { aggregate: update.spec.aggregate }),
      ...(update.spec?.bins && { bins: update.spec.bins }),
      ...(update.spec?.transforms && { transforms: update.spec.transforms }),
      ...(datasetStorage?.columns && { columns: datasetStorage.columns }),
      ...(datasetStorage?.rows && {
        rows: datasetStorage.rows.slice(0, dataSampleLimit ?? datasetStorage.rows.length),
      }),
      totalRowCount,
      // dataSampleLimit information should be merged from the update before this fn
      dataSampleLimit,
    };
  });

/**
 * Guarantee the order of the keys within an object
 * @param {Object} unordered The unordered object
 * @returns The ordered object
 */
const orderKeys = (unordered) =>
  Object.keys(unordered)
    .sort()
    .reduce((obj, key) => {
      obj[key] = unordered[key];
      return obj;
    }, {});

export const getComputedDatasetReferenceString = ({ contextReferenceString, computeSpec }) => {
  let { aggregate = [], bins = [], transforms = [] } = computeSpec;

  aggregate = aggregate.map((agg) => orderKeys(agg));
  bins = bins.map((bin) => orderKeys(bin));
  transforms = transforms.map((t) => orderKeys(t));

  // Note that we must guarantee the order of the keys in order to get a consistent hash
  const orderedComputeSpec = { aggregate, bins, transforms };
  return hashCode(`${contextReferenceString}_${JSON.stringify(orderedComputeSpec)}`);
};

/**
 * This is used for indexing the datasetStorage.
 *
 * Returns the dataset reference string for a given dcChartId or pipelinerDatasetId,
 * including the computeSpec if usedCompute.
 *
 * @param {Object} o
 * @param {String} [o.dcChartId] The dcChartId of the chart
 * @param {Object} [o.computeSpec] The compute spec for the dataset:
 * { aggregate, bins, transforms }
 * @param {String} [o.pipelinerDatasetId] The pipelinerDatasetId of the backing dataset
 * @param {Boolean} [o.usedCompute] Did we use the backend compute to get the data?
 * @returns
 */
export const getDatasetReferenceString = ({
  dcChartId = null,
  computeSpec = {},
  pipelinerDatasetId = null,
  usedCompute = false,
}) => {
  // Prefer a dcChartId over the pipelinerDatasetId
  const contextReferenceString = dcChartId ?? pipelinerDatasetId;

  const referenceString = usedCompute
    ? getComputedDatasetReferenceString({
        contextReferenceString,
        computeSpec,
      })
    : contextReferenceString;

  return referenceString;
};

/**
 * Get the data out of our dataStorage reducers for tables/charts while session/sessionless
 * @param {String} dcChartId The dcChartId of the chart
 * @param {Object} computeSpec The compute spec for the dataset:
 * { aggregate, bins, transforms }
 * @param {Object} datasetStorage The datasetStorage from dataset.reducer or chart.reducer
 * @param {Boolean} isSample Are we getting a portion of the FE data, or everything we have?
 * Always use a small portion of the FE data when doing transformations (ex. table formatting)
 * But not when reading properties directly from the data (ex. getting totalRowCount)
 * Note: 'isSample' has no relation to totalRowCount
 * @param {String} pipelinerDatasetId The pipelinerDatasetId of the backing dataset
 * @param {Boolean} usedCompute Did we use the backend compute to get the data?
 * @returns
 */
export const getDataFromStorage = ({
  dcChartId = null,
  computeSpec,
  datasetStorage,
  isSample = false,
  pipelinerDatasetId,
  usedCompute = false,
}) => {
  // get reference string
  const referenceString = getDatasetReferenceString({
    dcChartId,
    computeSpec,
    pipelinerDatasetId,
    usedCompute,
  });

  // if we don't have a reference string, return an empty object
  if (referenceString === null) return {};

  // get data
  const data = datasetStorage?.[referenceString] ?? {};

  // if there is no data or we don't want a sample, return all data we have
  if (isEmpty(data) || !isSample) return data;

  // get a sample of the data
  const sample = { ...data, rows: data.rows?.slice(0, data.tableSampleRowCount) ?? [] };

  // return sample
  return sample;
};

/**
 * Custom React hook for getting data from the session/sessionless dataset storage
 * @param {object} o
 * @param {Object} o.datasetStorage The datasetStorage from dataset.reducer or chart.reducer
 * @param {String} o.pipelinerDatasetId The pipelinerDatasetId of the backing dataset
 * @param {Object} [o.computeSpec] The compute spec for the dataset: { aggregate, bins, transforms }
 * @param {string} [o.dcChartId] The dcChartId of the chart
 * @param {Boolean} [o.hasMessageData] Does the object already have message data?
 * This supports backwards compatibility before charts retrieved from dataset references
 * @param {boolean} [o.isTable] Are we getting the data for a table? Tables use a separate
 * loading status and different formatting from the format stored in the reducer
 * @param {Boolean} [o.usedCompute] Did we use the backend compute to get the data?
 * @returns {{
 *  data: {[key: string]: any},
 *  error: { message: String, status: Integer },
 *  isFailed: Boolean,
 *  isLoading: Boolean
 * }}
 */
export const useDataFromStorage = ({
  dcChartId = null,
  computeSpec,
  datasetStorage,
  hasMessageData = false,
  isTable = false,
  pipelinerDatasetId,
  usedCompute = false,
}) => {
  // get unformatted data from storage
  const unformattedData = getDataFromStorage({
    dcChartId,
    computeSpec,
    datasetStorage,
    isSample: isTable,
    pipelinerDatasetId,
    usedCompute,
  });

  // get formatted data
  const formattedData = useMemo(
    () => (isTable ? handleTableDataFormatting(unformattedData) : {}),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [unformattedData?.columns, unformattedData?.tableSampleRowCount],
  );

  // get data
  let data = {};
  if (!hasMessageData)
    if (isTable) data = { ...formattedData };
    else data = { ...unformattedData };

  return hasMessageData
    ? // If we have message data, return an empty object
      {
        data,
        error: null,
        isFailed: false,
        isLoading: false,
      }
    : isTable
    ? // If we have a table, return the formatted data
      {
        data,
        error: unformattedData?.tableError ?? null,
        isFailed: unformattedData?.isTableSampleFailed ?? false,
        isLoading: unformattedData?.isTableSampleLoading ?? false,
      }
    : // If we have a chart, then we don't need to do any formatting
      {
        data,
        error: unformattedData?.chartError ?? null,
        isFailed: unformattedData?.isSampleFailed ?? false,
        isLoading: unformattedData?.isSampleLoading ?? false,
      };
};

/**
 * Custom hook to get a pipelinerDatasetId from the store when in a session.
 * Providing a pipelinerDatasetId will override the store's pipelinerDatasetId.
 *
 * @param {Object} o
 * @param {String} o.datasetName The name of the dataset
 * @param {String} o.datasetVersion The version of the dataset
 * @param {String} [o.pipelinerDatasetId] The pipelinerDatasetId of the backing dataset
 * @returns {String} The pipelinerDatasetId of the backing dataset
 */
export const useSessionPipelinerDatasetId = ({
  datasetName,
  datasetVersion,
  pipelinerDatasetId,
}) => {
  const datasetList = useSelector(selectVisibleDatasetsByNameVersion);
  const hiddenDatasetList = useSelector(selectHiddenDatasetsByNameVersion);

  // Sometimes we get pipelinerDatasetId conditionally from the store. Always prefer the one from props
  return (
    pipelinerDatasetId ??
    datasetList[datasetName]?.[datasetVersion]?.dataset_id ??
    hiddenDatasetList?.[datasetName]?.[datasetVersion]?.dataset_id
  );
};

/**
 * Handle for table pagination by calling the session/sessionless action to get more rows.
 * The saga will handle determining if we need to call the API or not, then update the store
 * with the new data & rowCount
 *
 * @param {Object} data The data from the store
 * @param {Function} dispatch The dispatch function
 * @param {String} insightsBoardId The current IB's id
 * @param {Boolean} isTable Whether or not we're getting data for a table
 * @param {String} pipelinerDatasetId The pipelinerDatasetId of the backing dataset
 * @param {Integer} publicationId The id of the publication, used for claims on embedded objects
 * @returns {Array.<[Number, Function]>} The rowCount and the callback to update the rowCount
 */
export const useTablePagination = ({
  data,
  dispatch,
  insightsBoardId,
  isTable,
  pipelinerDatasetId,
  publicationId,
}) => {
  // Create the pagination callback
  const updateRowCount = useCallback(
    (numRows) => {
      dispatch(
        sampleDatasetRequest({
          insightsBoardId,
          isTable,
          numRows: parseInt(numRows, 10),
          pipelinerDatasetId,
          publicationId,
        }),
      );
    },
    [dispatch, insightsBoardId, isTable, pipelinerDatasetId, publicationId],
  );

  // Create a stable reference for rowCount. Number of rows pulled out of the reducer
  // This will equal tableSampleRowCount if { isTable }
  const rowCount = useMemo(() => data?.rows?.length ?? null, [data?.rows?.length]);

  if (!isTable) return [];
  return [rowCount, updateRowCount];
};

/**
 * Determines if more data needs to be retrieved,
 * given the following information from the chart spec.
 *
 * @param {Object} o
 * @param {number} o.dataSampleLimit The number of rows to sample from the dataset
 * @param {Object} o.rows The rows of the dataset
 * @param {number} o.totalRowCount The total row count of the dataset
 */
export const shouldRetrieveChartData = ({ dataSampleLimit, rows, totalRowCount }) =>
  !rows ||
  (dataSampleLimit && rows?.length < dataSampleLimit) ||
  (!dataSampleLimit && totalRowCount && rows?.length < totalRowCount);
