import { FILE_MANAGER_MODE } from '../../constants/settings';
import {
  AUTO_OPEN_BROWSE_FILES,
  LIST_FILES_FAILURE,
  LIST_FILES_REQUEST,
  LIST_FILES_SUCCESS,
  SET_FILE_MANAGER_MODE,
} from '../actions/file_manager.actions';

export const initialState = {
  files: [], // List of files that are stored in the user's server directory.
  listFilesError: undefined, // Indicates the error received by the list files API call.
  deleteFilesError: undefined, // Indicates the error received by the delete files API call.
  isRequestingListFiles: false, // Indicates if the API call has been made to list files and is awaiting a response.
  isRequestingDeleteFiles: false, // Indicates if the API call has been made to delete files and is awaiting a response.
  fileManagerMode: FILE_MANAGER_MODE.browse, // The current selected file manager mode.
  openBrowseFiles: false, // Indicates whether we should automatically click on "Browse" when we get to the file menu
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LIST_FILES_REQUEST:
      return {
        ...state,
        isRequestingListFiles: true,
      };
    case LIST_FILES_SUCCESS:
      return {
        ...state,
        files: action.files,
        isRequestingListFiles: false,
      };
    case LIST_FILES_FAILURE:
      return {
        ...state,
        listFilesError: action.error,
        isRequestingListFiles: false,
      };
    case SET_FILE_MANAGER_MODE:
      return { ...state, fileManagerMode: action.mode };
    case AUTO_OPEN_BROWSE_FILES:
      return { ...state, openBrowseFiles: action.openBrowseFiles };
    default:
      return state;
  }
};
