import { BASE_TEN } from '../constants';
import {
  CHART_RECIPE_END_LINE,
  CHART_RECIPE_START_LINE,
  MODIFIER_LINE_START,
  MODIFY_RECIPE_END_LINE,
  MODIFY_RECIPE_START_LINE,
  VIZ_RECIPE_END_LINE,
  VIZ_RECIPE_START_LINE,
  WORKFLOW_VERSION_STATUSES,
} from '../constants/workflow';

/**
 * Takes the contents of a dcw file and returns the file's header and the workflow content.
 * @param {String} workflow to be unpacked
 * @returns header, workflow content, and verified & edited statuses
 */
export const unpackWorkflowFile = (workflow) => {
  let isJsonFormatted = true;
  // Check format of the workflow data
  try {
    JSON.parse(workflow);
  } catch (error) {
    isJsonFormatted = false;
  }
  if (isJsonFormatted) {
    // JSON formatted workflow
    const parsed = JSON.parse(workflow);
    const lines = [];
    for (let i = 0; i < parsed.utterance_list.length; i++) {
      const uttId = parsed.utterance_list[i];
      const uttObject = parsed.workflow_data[uttId];
      // Add the utterance to the array of lines
      let utt = uttObject.utterance;
      // Include ansers with the utterance
      if (typeof uttObject.answers === 'object' && Object.keys(uttObject.answers).length > 0) {
        utt += ` ${JSON.stringify(uttObject.answers)}`;
      }
      // TODO: Replace `-- ${utt}` with addCommentPrefix function
      // NOTE: Currently, adding imports to this file can break jest tests. Fixing #23655 will resolve this.
      if (uttObject.is_comment) lines.push(`-- ${utt}`);
      else lines.push(utt);

      if (uttObject.recipe?.chart_modify?.length > 0) {
        // Add any chart modification lines
        uttObject.recipe.chart_modify.forEach((modifyLine) => {
          lines.push(modifyLine);
        });
      }
      if (uttObject.recipe?.chart_recipe?.length > 0) {
        // Add any chart recipe lines
        uttObject.recipe.chart_recipe.forEach((rxLine) => {
          lines.push(rxLine);
        });
      } else if (uttObject.recipe?.viz_recipe?.length > 0) {
        // Add any viz recipe lines
        uttObject.recipe.viz_recipe.forEach((rxLine) => {
          lines.push(rxLine);
        });
      }
    }
    return {
      header: { appName: parsed.app_dir_name, appVersion: parsed.app_version },
      content: lines.join('\n'),
    };
  }
  // Legacy formatted workflow
  const lines = workflow.split('\n'); // split all lines into array
  // Extract the app name and version from the first line
  const firstline = lines.shift().split(' ');
  const appName = firstline[1].split(':')[1];
  const appVersion = firstline[2].split(':')[1];
  return { header: { appName, appVersion }, content: lines.join('\n') };
};

/**
 * Given the head information and content of a workflow file, constructs JSON formatted dcw_data
 * @param {Object} header { appName, appVersion }
 * @param {String} content Lines that make up a workflow
 * @returns JSON formatted workflow
 */
export const packWorkflowFile = (header, content) => {
  let uttId = 1;
  const utteranceList = [];
  const workflowData = {};
  // Trim utterances of extra whitespace and filter out empty lines
  const lines = content
    .split('\n')
    .map((utt) => utt.trim())
    .filter((utt) => utt !== '');

  while (lines.length > 0) {
    // Remove the next line from the workflow
    let line = lines.shift();
    if (line === MODIFY_RECIPE_START_LINE && uttId > 1) {
      // The start of 'chart_modify' recipe found. The complete recipe is extracted and added
      // the the previous utterance (uttId is must be greater than 1 for there to be a previous)
      // A complete recipe takes the following form:
      // ++ <Chart Modify Start: Do not remove this and the lines starting with "++" below>
      // ++ Modifier line(s) which start with "++"
      // ++ <Chart Modify End>
      const chartModify = [line];
      while (lines.length > 0) {
        if (
          lines[0].startsWith(MODIFIER_LINE_START) &&
          lines[0] !== MODIFY_RECIPE_START_LINE &&
          lines[0] !== CHART_RECIPE_START_LINE &&
          lines[0] !== CHART_RECIPE_END_LINE
        ) {
          // Modifier line detected, add to chartModify block
          const nextLine = lines.shift();
          chartModify.push(nextLine);
          if (nextLine === MODIFY_RECIPE_END_LINE) {
            // End of 'chart_modify' detected, add to previous utterance's recipe
            workflowData[uttId - 1].recipe.chart_modify = chartModify;
            break;
          }
        } else {
          break; // The chart modification block is incomplete, these lines are discarded
        }
      }
    } else if (line === CHART_RECIPE_START_LINE && uttId > 1) {
      // The start of 'chart_recipe' recipe found. The complete recipe is extracted and added
      // the the previous utterance (uttId is must be greater than 1 for there to be a previous)
      // A complete recipe takes the following form:
      // ++ <Chart Recipe Start: Do not remove this and the lines starting with "++" below>
      // ++ Modifier line(s) which start with "++"
      // ++ <Chart Recipe End>
      const chartRx = [line];
      while (lines.length > 0) {
        if (
          lines[0].startsWith(MODIFIER_LINE_START) &&
          lines[0] !== MODIFY_RECIPE_START_LINE &&
          lines[0] !== MODIFY_RECIPE_END_LINE &&
          lines[0] !== CHART_RECIPE_START_LINE
        ) {
          // Modifier line detected, add to chartRx block
          const nextLine = lines.shift();
          chartRx.push(nextLine);
          if (nextLine === CHART_RECIPE_END_LINE) {
            // End of 'chart_recipe' detected, add to previous utterance's recipe
            workflowData[uttId - 1].recipe.chart_recipe = chartRx;
            break;
          }
        } else {
          break; // The chart recipe block is incomplete, these lines are discarded
        }
      }
    } else if (line === VIZ_RECIPE_START_LINE && uttId > 1) {
      // The start of 'viz_recipe' recipe found. The complete recipe is extracted and added
      // the the previous utterance (uttId is must be greater than 1 for there to be a previous)
      // A complete recipe takes the following form:
      // ++ <Chart Recipe Start (Visualize): Do not remove this and the lines starting with "++" below>
      // ++ Modifier line(s) which start with "++"
      // ++ <Chart Recipe End>
      const chartRx = [line];
      while (lines.length > 0) {
        if (
          lines[0].startsWith(MODIFIER_LINE_START) &&
          lines[0] !== MODIFY_RECIPE_START_LINE &&
          lines[0] !== MODIFY_RECIPE_END_LINE &&
          lines[0] !== CHART_RECIPE_START_LINE
        ) {
          // Modifier line detected, add to chartRx block
          const nextLine = lines.shift();
          chartRx.push(nextLine);
          if (nextLine === VIZ_RECIPE_END_LINE) {
            // End of 'viz_recipe' detected, add to previous utterance's recipe
            workflowData[uttId - 1].recipe.viz_recipe = chartRx;
            break;
          }
        } else {
          break; // The chart recipe block is incomplete, these lines are discarded
        }
      }
    } else if (line.startsWith(MODIFIER_LINE_START)) {
      // Modifier line found without being surrounded modification start and end lines
      // These lines are removed because it cannot be verified if the recipe is complete
      continue;
    } else {
      let answers = {};
      const matchResult = line.match(/{(?:[^{}]*)*}$/);
      if (Array.isArray(matchResult) && matchResult.length === 1) {
        try {
          // Add the first matchResult object found (if a user adds additional matchResult objects in the editor these will be removed)
          // Replace easy to fix issues with these that may arise due to Python dictionary formatting of the answers
          answers = JSON.parse(
            matchResult[0].replace(/True/g, 'true').replace(/False/g, 'false').replace(/'/g, '"'),
          );

          // Remove answers object from utterance line
          for (let i = 0; i < matchResult.length; i++) line = line.replace(matchResult[i], '');
          // Remove any trailing whitepspace
          line = line.trim();
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e.message);
        }
      }
      // New utterance is added to the workflow
      workflowData[uttId] = {
        answers,
        current_datasets: {},
        inputs: {},
        is_comment: false,
        kwargs: {},
        outputs: {},
        recipe: {},
        skill: null,
        skill_uuid: null,
        utterance: line,
      };
      utteranceList.push(uttId);
      uttId++;
    }
  }

  return {
    workflow_data: workflowData,
    app_dir_name: header.appName,
    app_version: header.appVersion,
    edited: true,
    raw_text_verified: false,
    upgraded: false,
    last_verified: null,
    utterance_list: utteranceList,
  };
};

/**
 * Returns the frontend display status for a given workflow version
 * @param {String} workflow to be unpacked
 */
export const getVersionStatus = (workflow) => {
  let isJsonFormatted = true;
  // Check format of the workflow data
  try {
    JSON.parse(workflow);
  } catch (error) {
    isJsonFormatted = false;
  }
  if (isJsonFormatted) {
    // JSON formatted workflow
    const parsed = JSON.parse(workflow);
    if (parsed.raw_text_verified && !parsed.edited) {
      return WORKFLOW_VERSION_STATUSES.GREEN;
    } else if (!parsed.raw_text_verified && !parsed.edited) {
      return WORKFLOW_VERSION_STATUSES.YELLOW;
    }
    return WORKFLOW_VERSION_STATUSES.RED;
  }
  // Legacy formatted workflow
  return WORKFLOW_VERSION_STATUSES.RED;
};

/**
 * Returns the workflow id from the workflow web path
 * @param {String} pathName
 */
export const getWorkflowIdFromWorkflowPath = (pathName) => {
  const path = 'workflow/';
  return parseInt(pathName.slice(pathName.indexOf(path) + path.length), BASE_TEN);
};

/**
 * Replaces the spaces with underscores and vice versa with a flag
 * @param {String} text
 * @param {Boolean} spaces_to_underscores
 */
export const replaceSpacesUnderscores = (text, spacesToUnderscores) => {
  if (spacesToUnderscores) return text.replace(/ /g, '_');
  return text.replace(/_/g, ' ');
};
