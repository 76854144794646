import {
  DELETE_TABLE_OBJECTS,
  SAVE_TABLE_COLUMN_ORDER,
  SAVE_TABLE_FORMATTING,
} from '../actions/tableObject.actions';

const initialState = {
  // maps sessionID or IB Name to table object id's
  parentMap: {
    // parentID: { objectID: {valueFormatterMap: {columnName: "scientific"} } }
  },
};

export default (state = initialState, action) => {
  const copyState = { ...state };
  switch (action.type) {
    case SAVE_TABLE_FORMATTING:
      return {
        ...state,
        parentMap: {
          ...state.parentMap,
          [action.parentId]: {
            ...state.parentMap?.[action.parentId],
            [action.id]: {
              columnsState: { ...state.parentMap?.[action.parentId]?.[action.id]?.columnsState },
              valueFormatterMap: action.valueFormatterMap,
            },
          },
        },
      };
    case SAVE_TABLE_COLUMN_ORDER:
      return {
        ...state,
        parentMap: {
          ...state.parentMap,
          [action.parentId]: {
            ...state.parentMap?.[action.parentId],
            [action.id]: {
              columnsState: action.columnsState,
              valueFormatterMap: {
                ...state.parentMap?.[action.parentId]?.[action.id]?.valueFormatterMap,
              },
            },
          },
        },
      };
    case DELETE_TABLE_OBJECTS:
      delete copyState.parentMap?.[action.parentId];
      return copyState;
    default:
      return state;
  }
};
