import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { FormikProps, FormikValues } from 'formik';
import React from 'react';
import { FilePond } from 'react-filepond';
import '../../../components/ConnectionEditor/ConnectionEditor.scss';
import { FORM_GRID } from '../../../constants/connection';
import getFileValidateTypeDetectType from '../../get_file_validate_type_detect_type';
import InfoTooltip from '../InfoTooltip';
import { isRequiredField } from '../connection';

const ConnectionFileInput: React.FC<{
  field: { id: string; type: string; hideIfFn?: (values: FormikValues) => boolean };
  formik: FormikProps<any>;
  validationSchema: any;
  toolTipInfo: string;
}> = ({ field, formik, validationSchema, toolTipInfo }) => {
  const { values, setFieldValue } = formik;
  // If the field has a hideIfFn and it returns true, don't render the field
  if (field.hideIfFn && field.hideIfFn(values)) {
    return null;
  }
  return (
    <Grid item key={field.id} xs={12}>
      <Stack>
        <Stack className={`${FORM_GRID}-CredentialsStack`} direction="row">
          <Typography>
            Credential File:
            {isRequiredField(validationSchema, field.id) && (
              <span className="ConnectionEditor-Asterisk"> *</span>
            )}
          </Typography>
          {toolTipInfo && <InfoTooltip text={toolTipInfo} />}
        </Stack>
        <FilePond
          name="files"
          onupdatefiles={(files) => {
            if (files.length > 0) {
              const encodedFile = files[0].getFileEncodeBase64String();
              setFieldValue(field.id, encodedFile);
            } else {
              setFieldValue(field.id, null);
            }
          }}
          maxFiles={1}
          acceptedFileTypes={['.json']}
          fileValidateTypeDetectType={getFileValidateTypeDetectType}
          maxFileSize={1000000} // 1MB
          labelTapToCancel="" // No support for Tap to Cancel
          allowFileEncode
          labelIdle={
            values[field.id]
              ? 'File exists.  Please upload another to update it.'
              : 'Click here to upload your BigQuery credential file.'
          }
          credits={false}
        />
      </Stack>
    </Grid>
  );
};

export default ConnectionFileInput;
