import FileSaver, { saveAs } from 'file-saver';
import JSZip from 'jszip';
import isString from 'lodash/isString';

/**
 * Function for convert base64 image to blob
 * @param {string} b64Data base64 url
 * @param {number} sliceSize slice size
 * @returns blob
 */
export const b64toBlob = (b64Data, sliceSize = 512) => {
  const parts = b64Data.split(';base64,');
  const contentType = parts[0].split(':')[1];
  [, b64Data] = parts;
  const byteCharacters = atob(b64Data);

  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

/**
 * Helper function for getting the exported image filename from echart spec
 * @param {string} text title text from echart spec
 * @returns processed filename
 */
const getImageFilename = (text) => {
  if (text === '') {
    // when charts have to titles, use default title
    return 'chart';
  }
  let chartTitleSplit = isString(text) ? text : text.join('');
  chartTitleSplit = chartTitleSplit.split(/[^a-zA-Z0-9]/);
  chartTitleSplit = chartTitleSplit.map(
    (string) => string.charAt(0).toUpperCase() + string.slice(1),
  );
  return chartTitleSplit.join('');
};

/**
 * This function receives a list of blob and compress to a .zip file
 * @param {Blob[]} blobs a list of blobs
 * @param {String[]} sliderValList slider value list
 * @param {String} filename the main filename
 * @returns A JSZip object
 */
async function saveToZip(blobs, sliderValList, filename) {
  const zip = new JSZip();
  for (let i = 0; i < blobs.length; i++) {
    zip.file(`${filename}_${sliderValList[i]}.png`, blobs[i], { base64: true });
  }
  return zip;
}

/**
 * Helper function for compressing all blobs to a .zip file
 * @param {Blob[]} blobs the blobs list for each slider
 * @param {String[]} sliderValList slider value list
 * @param {String} title the title of the chart
 */
export async function saveAsZip(blobs, sliderValList, title) {
  const filename = getImageFilename(title);
  const zipFileName = title === '' ? 'charts' : filename;
  await saveToZip(blobs, sliderValList, filename).then(async (res) => {
    await res.generateAsync({ type: 'blob' }).then(function (content) {
      saveAs(content, `${zipFileName}.zip`);
    });
  });
}

/**
 * Export the chart image as .png when 'Export Chart' is clicked
 * Plotly uses a custom context menu to open a modal to name the chart
 * but here we name the chart by its title
 * @param {Object} ecInstance echart instance
 */
export const exportPlot = (ecInstance) => {
  const option = ecInstance.getOption();

  // Parse the exportedName from the chart title
  const exportedName = getImageFilename(option.title[0].text);

  const img = new Image();
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const fontSize = 30;

  function saveImg() {
    canvas.width = img.width;
    canvas.height = img.height + fontSize;
    ctx.drawImage(img, 0, 0, img.width, img.height);
    canvas.toBlob((blob) => {
      FileSaver.saveAs(blob, exportedName);
    });
  }

  img.onload = saveImg;
  // Exports chart image; returns a base64 URL
  img.src = ecInstance.getDataURL({
    pixelRatio: 2,
    backgroundColor: '#fff',
  });
};
