import { CONNECTION_DATABASES_TYPES } from '../../constants/connection';
import {
  CLEAR_CONNECTION,
  CLOSE_CONNECTION_EDITOR,
  GET_CONNECTION_OBJECT_DATA_REQUEST,
  GET_CONNECTION_OBJECT_DATA_SUCCESS,
  OPEN_CONNECTION_EDITOR_SUCCESS,
  SET_EDIT_MODE,
  SET_SELECTED_DB,
  TEST_CONNECTION_FAILURE,
  TEST_CONNECTION_REQUEST,
  TEST_CONNECTION_SUCCESS,
  UPDATE_CONNECTION_FAILURE,
  UPDATE_CONNECTION_REQUEST,
  UPDATE_CONNECTION_SUCCESS,
} from '../actions/connection.actions';
import { closeDatasetCreator, openDatasetCreator } from './datasetCreator.reducer';

export const initialState = {
  object: {}, // Connection object when connection editor is opened
  data: {}, // Connection data when the existing connection is opened
  isOpen: false, // Boolean to check if connection editor is open
  isLoading: false, // Loading until the connection data is fetched
  isSubmitting: false, // Boolean to check if we are submitting the connection form
  editMode: false, // Boolean to check if connection editor page is in edit mode or new connection.
  isTesting: false, // Boolean to load while testing the connection
  selectedDb: CONNECTION_DATABASES_TYPES.BIGQUERY, // Database type of connection form
};

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_CONNECTION_EDITOR_SUCCESS:
      return {
        ...state,
        isOpen: true,
        object: action.object,
      };
    case openDatasetCreator.type:
      return {
        ...state,
        isOpen: true,
        object: initialState.object,
      };

    case GET_CONNECTION_OBJECT_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GET_CONNECTION_OBJECT_DATA_SUCCESS:
      return {
        ...state,
        object: action.object,
        data: action.data,
        isLoading: false,
      };

    case UPDATE_CONNECTION_REQUEST:
      return {
        ...state,
        isSubmitting: true,
      };

    case UPDATE_CONNECTION_FAILURE:
      return {
        ...state,
        isSubmitting: false,
      };

    case UPDATE_CONNECTION_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        data: {
          ...action.payload,
          password: state.data.password,
          accessToken: state.data.accessToken,
        },
      };

    case SET_EDIT_MODE:
      return {
        ...state,
        editMode: action.editMode,
      };

    case TEST_CONNECTION_REQUEST: {
      return {
        ...state,
        isTesting: true,
      };
    }

    case TEST_CONNECTION_SUCCESS:
      return {
        ...state,
        isTesting: false,
      };

    case TEST_CONNECTION_FAILURE:
      return {
        ...state,
        isTesting: false,
      };

    case CLEAR_CONNECTION: {
      return {
        ...state,
        object: initialState.object,
        data: initialState.data,
        isLoading: initialState.isLoading,
        editMode: initialState.editMode,
        isTesting: initialState.isTesting,
      };
    }

    case SET_SELECTED_DB:
      return {
        ...state,
        selectedDb: action.database,
      };

    case CLOSE_CONNECTION_EDITOR:
    case closeDatasetCreator.type:
      return {
        ...state,
        isOpen: false,
        object: initialState.object,
      };

    default:
      return state;
  }
};
