export const GET_SAVED_EXPRESSIONS = 'expressions/GET_SAVED_EXPRESSIONS';
export const GET_SAVED_EXPRESSIONS_SUCCESS = 'expressions/GET_SAVED_EXPRESSIONS_SUCCESS';
export const GET_SAVED_EXPRESSIONS_FAILURE = 'expressions/GET_SAVED_EXPRESSIONS_FAILURE';

/**
 * For requesting saved expressions
 */
export const getSavedExpressions = () => ({
  type: GET_SAVED_EXPRESSIONS,
});

export const getSavedExpressionsSuccess = ({ expressions }) => ({
  type: GET_SAVED_EXPRESSIONS_SUCCESS,
  expressions,
});

export const getSavedExpressionsFailure = () => ({
  type: GET_SAVED_EXPRESSIONS_FAILURE,
});
