export const OPEN_UTTERANCE_COMPOSER = 'utterance_composer/OPEN_UTTERANCE_COMPOSER';
export const OPEN_SKILL_COMPOSER = 'utterance_composer/OPEN_SKILL_COMPOSER';
export const CLOSE_SKILL_COMPOSER = 'utterance_composer/CLOSE_SKILL_COMPOSER';
export const OPEN_UTTERANCE_COMPOSER_GROUP = 'utterance_composer/OPEN_UTTERANCE_COMPOSER_GROUP';
export const LOAD_SUGGESTIONS_REQUEST_FORM = 'utterance_composer/LOAD_SUGGESTIONS_REQUEST_FORM';
export const LOAD_SUGGESTIONS_SUCCESS_FORM = 'utterance_composer/LOAD_SUGGESTIONS_SUCCESS_FORM';
export const LOAD_SUGGESTIONS_FAILURE_FORM = 'utterance_composer/LOAD_SUGGESTIONS_FAILURE_FORM';
export const LOAD_PARTIAL_SUGGESTIONS_REQUEST_FORM =
  'utterance_composer/LOAD_PARTIAL_SUGGESTIONS_REQUEST_FORM';
export const CLEAR_UTTERANCE_COMPOSER = 'utterance_composer/CLEAR_UTTERANCE_COMPOSER';
export const HANDLE_USER_INPUT = 'utterance_composer/HANDLE_USER_INPUT';
export const HANDLE_PAGE_NAVIGATOR = 'utterance_composer/HANDLE_PAGE_NAVIGATOR';
export const SKILL_CHANGE = 'utterance_composer/SKILL_CHANGE';
export const DELETE_VALUES_INPUT = 'utterance_composer/DELETE_VALUES_INPUT';
export const SET_FORM_FIELD = 'utterance_composer/SET_FORM_FIELD';
export const SAVE_USER_INPUT = 'utterance_composer/SAVE_USER_INPUT';
export const SET_NEXT_PAGE = 'utterance_composer/SET_NEXT_PAGE';
export const CLEAR_SUGGESTIONS_FORM = 'utterance_composer/CLEAR_SUGGESTIONS';
export const EXECUTE_UTTERANCE = 'utterance_composer/EXECUTE_UTTERANCE';
export const EXECUTE_UTTERANCE_GROUP = 'utterance_composer/EXECUTE_UTTERANCE_GROUP';
export const NAVIGATE_NEXT_PAGE = 'utterance_composer/NAVIGATE_NEXT_PAGE';
export const NAVIGATE_PREV_PAGE = 'utterance_composer/NAVIGATE_PREV_PAGE';
export const SET_CURRENT_PAGE = 'utterance_composer/SET_CURRENT_PAGE';
export const INSERT_INPUT = 'utterance_composer/INSERT_INPUT';
export const DELETE_INPUT = 'utterance_composer/DELETE_INPUT';
export const CLEAR_PAGE_COMPLETED = 'utterance_composer/CLEAR_PAGE_COMPLETED';
export const SAVE_SELECTED_DATASET = 'utterance_composer/SAVE_SELECTED_DATASET';
export const UPDATE_SINGLE_SELECT_DATA = 'utterance_composer/UPDATE_SINGLE_SELECT_DATA';

export const openUtteranceComposerGroup = ({
  recipes,
  dataset,
  sessionName = undefined,
  openByCellMenu = false,
  disableAutoFocus = false,
}) => ({
  type: OPEN_UTTERANCE_COMPOSER_GROUP,
  recipes,
  dataset,
  sessionName,
  openByCellMenu,
  disableAutoFocus,
});

/**
 * @param {{
 *  recipe: {[key: string]: any},
 *  dataset: (string | number)[]
 * }} args
 */
export const openSkillComposer = ({ recipe, dataset }) => ({
  type: OPEN_SKILL_COMPOSER,
  recipe,
  dataset,
});

export const closeSkillComposer = () => ({
  type: CLOSE_SKILL_COMPOSER,
});

export const loadSuggestionRequestForm = ({ message, id }) => ({
  type: LOAD_SUGGESTIONS_REQUEST_FORM,
  message,
  id,
});

export const loadSuggestionsSuccessForm = ({ suggestions, message, id, prompts }) => ({
  type: LOAD_SUGGESTIONS_SUCCESS_FORM,
  suggestions,
  message,
  id,
  prompts,
});

export const clearSuggstions = () => ({
  type: CLEAR_SUGGESTIONS_FORM,
});

export const loadPartialSuggestionRequestForm = ({ message, id, prompts }) => ({
  type: LOAD_PARTIAL_SUGGESTIONS_REQUEST_FORM,
  message,
  id,
  prompts,
});

export const insertInput = ({ skillName, pageName, inputIndex }) => ({
  type: INSERT_INPUT,
  skillName,
  pageName,
  inputIndex,
});

export const deleteInput = ({ skillName, pageName, inputIndex, appendIndex }) => ({
  type: DELETE_INPUT,
  skillName,
  pageName,
  inputIndex,
  appendIndex,
});

export const loadSuggestionsFailureForm = ({ error, id, message }) => ({
  type: LOAD_SUGGESTIONS_FAILURE_FORM,
  error,
  id,
  message,
});

export const clearUtteranceComposer = () => ({
  type: CLEAR_UTTERANCE_COMPOSER,
});

// Utterance Execution
export const executeComposedUtterance = () => ({
  type: EXECUTE_UTTERANCE,
});

// Utterance Execution
export const executeComposedUtteranceGroup = ({ utterance }) => ({
  type: EXECUTE_UTTERANCE_GROUP,
  utterance,
});

// Page Navigation
export const navigateToNextPage = () => ({
  type: NAVIGATE_NEXT_PAGE,
});

export const navigateToPreviousPage = () => ({
  type: NAVIGATE_PREV_PAGE,
});

export const setCurrentPage = ({ page }) => ({
  type: SET_CURRENT_PAGE,
  page,
});

export const clearPageCompleted = () => ({
  type: CLEAR_PAGE_COMPLETED,
});

export const saveSelectedDataset = ({ selectedDataset }) => ({
  type: SAVE_SELECTED_DATASET,
  selectedDataset,
});

export const updateSingleSelectData = (textToInsertIntoSingleSelectForm) => ({
  type: UPDATE_SINGLE_SELECT_DATA,
  textToInsertIntoSingleSelectForm,
});

export const handleUserInput = ({ currentSkill, pathState, newValue }) => ({
  type: HANDLE_USER_INPUT,
  currentSkill,
  pathState,
  newValue,
});

export const handlePageNavigator = ({ currentSkill, pathState, value, navigatorId }) => ({
  type: HANDLE_PAGE_NAVIGATOR,
  currentSkill,
  pathState,
  value,
  navigatorId,
});

export const handleSkillChange = ({ skill }) => ({
  type: SKILL_CHANGE,
  skill,
});
