import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DefaultErrorMessage, EmptyPlaceholderText } from '../../../../constants/databaseBrowser';
import {
  selectColumnMetadata,
  selectTableLastUpdated,
  selectTableMetadataError,
  selectTableMetadataRequestStatus,
} from '../../../../store/selectors/dbBrowser.selector';
import { getBrowserTableMetadataRequest } from '../../../../store/slices/dbBrowser.slice';
import { isStatusSuccess, isStatusUnrequested } from '../../../../utils/home_screen';
import RefreshButton from '../../../RefreshButton';
import StatusPlaceholder from '../../../StatusPlaceholder';
import TextWithTooltip from '../../../common/TextWithTooltip';
import { StickyWrapperContentProps } from '../../StickyWrapper/StickyWrapper';
import './MetadataItem.scss';

const MetadataItem: React.FC<StickyWrapperContentProps> = ({ parents, current }) => {
  // Pull out information from props
  const connUUID = parents?.[0]?.id ?? '';
  const namespace = parents?.[1]?.name ?? '';
  const { name } = current;

  // Redux state
  const dispatch = useDispatch();
  const tableMetadata = useSelector(selectColumnMetadata(connUUID, namespace, name));
  const tableMetadataEmpty = tableMetadata?.length === 0;
  const errorMessage = useSelector(selectTableMetadataError(connUUID, namespace, name));
  const tableMetadataStatus = useSelector(
    selectTableMetadataRequestStatus(connUUID, namespace, name),
  );
  const tableMetadataLastUpdated = useSelector(selectTableLastUpdated(connUUID, namespace, name));
  const fetchMetadata = ({ refresh }: { refresh: boolean }) =>
    dispatch(
      getBrowserTableMetadataRequest({
        connectionUUID: connUUID,
        refresh,
        namespace,
        table: name,
      }),
    );

  React.useEffect(() => {
    if (isStatusUnrequested(tableMetadataStatus)) {
      fetchMetadata({ refresh: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // We do not render anything if we don't have valid data
  if (!(connUUID && namespace && name)) return null;
  return (
    <Box className="DatabaseBrowser-Navigation-Metadata">
      <Box className="DatabaseBrowser-Navigation-Metadata-Refresh">
        <RefreshButton
          tooltip="Refresh Column Metadata"
          testId="refresh-columns"
          status={tableMetadataStatus}
          lastUpdated={tableMetadataLastUpdated}
          requestingPlaceholder="Fetching columns"
          onClick={() => fetchMetadata({ refresh: true })}
        />
        <Divider
          className="DatabaseBrowser-Navigation-Entry-Margin-Divider"
          orientation="vertical"
          flexItem
        />
      </Box>
      {!isStatusSuccess(tableMetadataStatus) || tableMetadataEmpty ? (
        <StatusPlaceholder
          status={tableMetadataStatus}
          errorText={errorMessage ?? DefaultErrorMessage.TableMetadata}
          alertTextProps={{ variant: 'body2' }}
          isEmpty={tableMetadataEmpty}
          emptyText={EmptyPlaceholderText.TableMetadata}
          smallLoader
        />
      ) : (
        <Box>
          <Table className="DatabaseBrowser-Navigation-Metadata-Table" size="small">
            <TableBody>
              {tableMetadata?.map((metadata) => (
                <TableRow key={`db-browser-metadata-${name}-${metadata.name}`}>
                  <TableCell>
                    <TextWithTooltip text={metadata.name} />
                  </TableCell>
                  <TableCell className="DatabaseBrowser-Navigation-Metadata-Table-Value">
                    <TextWithTooltip text={metadata.type} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      )}
    </Box>
  );
};

export default MetadataItem;
