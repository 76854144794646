import {
  CLOSE_REPLAY_CONTROLLER,
  OPEN_REPLAY_CONTROLLER,
  REPOSITION_REPLAY_CONTROLLER,
  SET_REPLAY_CONTROLLER_POS_X,
  SET_REPLAY_CONTROLLER_POS_Y,
} from '../actions/replay_controller.actions';

export const REPLAY_CONTROLLER_WIDTH = 40;
export const REPLAY_CONTROLLER_HEIGHT = 232;

// 20em = 20 * font size = 20 * 16
// If font size is changed, this will have to be updated
const chatPanelWidth = 20 * 16;

const NotebookPosX = window.innerWidth - chatPanelWidth - REPLAY_CONTROLLER_WIDTH; // X position of the Replay Controller in Notebook mode
const NotebookPosY = window.innerHeight / 2 - REPLAY_CONTROLLER_HEIGHT / 2; // Y position of the Replay Controller in Notebook mode

export const initialState = {
  showReplayController: false, // Indicates if the Replay Controller should be open.
  posX: NotebookPosX,
  posY: NotebookPosY,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_REPLAY_CONTROLLER:
      return {
        showReplayController: true,
        posX: NotebookPosX,
        posY: NotebookPosY,
      };
    case CLOSE_REPLAY_CONTROLLER:
      return {
        ...state,
        showReplayController: false,
      };
    case SET_REPLAY_CONTROLLER_POS_X:
      return {
        ...state,
        posX: action.posX,
      };
    case SET_REPLAY_CONTROLLER_POS_Y:
      return {
        ...state,
        posY: action.posY,
      };
    case REPOSITION_REPLAY_CONTROLLER: {
      return {
        ...state,
        posX: action.xFactor * state.posX,
        posY: action.yFactor * state.posY,
      };
    }
    default:
      return state;
  }
};
