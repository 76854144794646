import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ChartSpecInfo } from '../../api/chartspace.api';
import { NavigationItemSource, NavigationItemStatus } from '../../constants/session';

export interface UpdateChartRequestPayload {
  dcChartID: string;
  status?: NavigationItemStatus;
  name?: string;
  chartSpec?: ChartSpecInfo;
}

export interface DcChartIdPayload {
  dcChartId: string | null;
}

export interface ChangeChartFilterPayload {
  category: ChartFilterKey;
  filter: string;
}

export interface Chart {
  chart_spec: ChartSpecInfo;
  source: NavigationItemSource;
  dc_dataset_id: string;
  id: string;
  // Name of the chart (dc_chart_catalog.name)
  name: string;
  status: NavigationItemStatus;
  // Version of the message (ex. 2)
  typeVersion: number;
  created: string;
  updated: string;
}

export interface ChartWithParent extends Chart {
  parent: string;
}

export enum ChartListSortOptions {
  NewestFirst = 'Newest First',
  OldestFirst = 'Oldest First',
  NameAscending = 'Name (Ascending)',
  NameDescending = 'Name (Descending)',
}

export enum ChartGroupByKey {
  Parent = 'parent',
  Source = 'source',
  None = '',
}

export enum ChartFilterKey {
  Parent = 'parent',
  Source = 'source',
}

export const CHART_FILTER_TITLE_MAP: Record<ChartFilterKey, string> = {
  [ChartFilterKey.Parent]: 'Dataset',
  [ChartFilterKey.Source]: 'Source',
};

export const CHART_GROUP_BY_OPTIONS: ChartGroupByKey[] = [
  ChartGroupByKey.None,
  ChartGroupByKey.Parent,
  ChartGroupByKey.Source,
];

export const CHART_GROUP_TITLE_MAP: Record<ChartGroupByKey, string> = {
  [ChartGroupByKey.None]: '',
  [ChartGroupByKey.Source]: 'Source',
  [ChartGroupByKey.Parent]: 'Dataset',
};

export interface ChartspaceState {
  charts: { [key: string]: Chart };
  currentDcChartId: string | null;
  loading: boolean;
  requested: boolean;
  error: string | null;
  sort: string;
  groupBy: ChartGroupByKey;
  filters: {
    [ChartFilterKey.Parent]: string[];
    [ChartFilterKey.Source]: string[];
  };
}

const initialState: ChartspaceState = {
  charts: {},
  currentDcChartId: null,
  loading: false,
  requested: false,
  error: null,
  sort: ChartListSortOptions.NewestFirst,
  groupBy: ChartGroupByKey.None,
  filters: {
    [ChartFilterKey.Parent]: [],
    [ChartFilterKey.Source]: [],
  },
};

const chartspaceSlice = createSlice({
  name: 'chartspace',
  initialState,
  reducers: {
    getChartspaceRequest: (state) => {
      state.loading = true;
      state.requested = true;
      state.error = null;
    },
    getChartspaceSuccess: (state, { payload }: PayloadAction<Record<string, Chart>>) => {
      state.charts = payload;
      state.loading = false;
    },
    getChartspaceFailure: (state, { payload }: PayloadAction<{ error: Error }>) => {
      state.loading = false;
      state.error = payload.error.message;
    },
    updateChartRequest: {
      reducer: (state) => {
        state.error = null;
      },
      prepare: (payload: UpdateChartRequestPayload) => ({ payload }),
    },
    updateChartSuccess: (state, { payload }: PayloadAction<Chart>) => {
      // first set the chart in the store
      state.charts[payload.id] = payload;
      // then update the current chart if necessary
      if (
        // if the chart was current, but is being deselected or hidden
        // we need to set a new current chart
        payload.status !== NavigationItemStatus.ACTIVE &&
        state.currentDcChartId === payload.id
      ) {
        // set the new current chart to the first promoted chart we find
        const newCurrent = Object.values(state.charts).find(
          (c) => c.status === NavigationItemStatus.ACTIVE && c.id !== payload.id,
        );
        state.currentDcChartId = newCurrent?.id || null;
      } else if (payload.status === NavigationItemStatus.ACTIVE && !state.currentDcChartId) {
        // if the chart is being promoted, but there is no current chart
        // we need to set the promoted chart as current
        state.currentDcChartId = payload.id;
      }
    },
    updateChartFailure: (state, { payload }: PayloadAction<{ error: Error }>) => {
      state.error = payload.error.message;
    },
    setCurrentChart: (state, { payload }: PayloadAction<DcChartIdPayload>) => {
      state.currentDcChartId = payload.dcChartId;
    },
    setChartListSortParameter: (state, { payload }: PayloadAction<string>) => {
      state.sort = payload;
    },
    setChartListGroupByParameter: (state, { payload }: PayloadAction<ChartGroupByKey>) => {
      state.groupBy = payload;
    },
    setChartListFilterParameter: (state, { payload }: PayloadAction<ChangeChartFilterPayload>) => {
      if (!state.filters[payload.category]) {
        // if the filter key doesn't exist, create it
        state.filters[payload.category] = [payload.filter];
      } else if (!state.filters[payload.category].includes(payload.filter)) {
        // if the filter value doesn't exist, add it
        state.filters[payload.category].push(payload.filter);
      }
    },
    removeChartListFilterParameter: (
      state,
      { payload }: PayloadAction<ChangeChartFilterPayload>,
    ) => {
      if (state.filters[payload.category]) {
        // filter out the value from the filter key
        state.filters[payload.category] = state.filters[payload.category].filter(
          (value) => value !== payload.filter,
        );
      }
    },
  },
});

export const {
  getChartspaceRequest,
  getChartspaceSuccess,
  getChartspaceFailure,
  updateChartRequest,
  updateChartSuccess,
  updateChartFailure,
  setCurrentChart,
  setChartListSortParameter,
  setChartListGroupByParameter,
  setChartListFilterParameter,
  removeChartListFilterParameter,
} = chartspaceSlice.actions;

export default chartspaceSlice.reducer;
