import { RootState } from 'configureStore';
import { createSelector } from 'reselect';
import { ConfigValue } from 'types/config.types';

const createConfigValue = <T>(value: T | null | undefined): ConfigValue<T> => ({
  value: value || null,
  isSet:
    value !== null &&
    value !== undefined &&
    value !== '' &&
    value !== '""' && // Handle JSON-stringified empty string
    String(value).trim().length > 0, // Handle whitespace-only strings
});

export const selectAuth0ClientId = (state: RootState) => state.config.auth0ClientId;
export const selectAuth0Domain = (state: RootState) => state.config.auth0Domain;

export const selectGoogleClientID = createSelector(
  [(state: RootState) => state.config.googleClientID],
  (clientId): ConfigValue<string> => createConfigValue(clientId),
);
