import AccountTreeRoundedIcon from '@mui/icons-material/AccountTreeRounded';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { VISUAL_TYPES } from '../../../../../constants';
import { openViewSlicedWorkflow } from '../../../../../store/actions/viewSlicedWorkflow.action';

/**
 * A functional component to render a button UI to show sliced workflow graph in popup window
 */
const SlicedWorkflowButton = ({ data, dcChartId, isPopOutChart, type }) => {
  // redux dispatch hook
  const dispatch = useDispatch();

  const handleViewSlicedWorkflow = () => {
    let objectType;
    switch (type) {
      case VISUAL_TYPES.PIVOT: {
        // This is a workaround for nginx not liking spaces in the URL
        objectType = 'pivot';
        break;
      }
      case VISUAL_TYPES.VIZ: {
        objectType = 'chart';
        break;
      }
      case VISUAL_TYPES.TABLE: {
        objectType = 'dataset';
        break;
      }
      default:
        objectType = type;
        break;
    }
    dispatch(openViewSlicedWorkflow({ objectType, objectId: dcChartId }));
  };

  /*
   * This button should only show up on
   *  - dataets in a session
   *  - plots in a session (excluding the normal recipe diagram)
   *  It should not show up on an insights board
   */
  const showViewSlicedWorkflowButton = () => {
    return (
      [VISUAL_TYPES.VIZ, VISUAL_TYPES.TABLE, VISUAL_TYPES.TABVISUAL, VISUAL_TYPES.PIVOT].includes(
        type,
      ) &&
      !data.isDependencyGraph &&
      !isPopOutChart &&
      // This means the result is a table but NOT a dataset
      // Such tables will have an empty Recipe Diagram.
      !(data.name !== undefined && data.name !== data.title && VISUAL_TYPES.TABLE === type)
    );
  };

  return (
    <>
      {showViewSlicedWorkflowButton() ? (
        <MenuItem
          key="slicedWorkflow"
          className="Menu-Item-Container"
          onClick={handleViewSlicedWorkflow}
        >
          <div className="Menu-Item" data-cy="Menu-Item-Sliced-Workflow">
            <AccountTreeRoundedIcon className="Menu-Item-Icon" />
            <span className="Menu-Item-Text">View Lineage</span>
          </div>
        </MenuItem>
      ) : null}
    </>
  );
};

SlicedWorkflowButton.propTypes = {
  data: PropTypes.object.isRequired,
  isPopOutChart: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  dcChartId: PropTypes.string.isRequired,
};

export default SlicedWorkflowButton;
