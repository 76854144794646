import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import isEqual from 'lodash/isEqual';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../configureStore';
import {
  selectSessionlessDatasetStorage,
  selectUserConfig,
} from '../../../../store/sagas/selectors';
import {
  selectAllSafeToShare,
  selectAnnotationColumnNames,
  selectDatasetAnnotationErrorMessage,
  selectDatasetAnnotationIsLoading,
} from '../../../../store/selectors/catalog.selector';
import { selectObjectByObjectFk } from '../../../../store/selectors/home_screen.selector';
import { toggleSafeToShare } from '../../../../store/slices/catalog.slice';
import { FeatureTypes } from '../../../../types/feature.types';
import { HomeObjectKeys, HomeObjectKeysTypes } from '../../../../utils/homeScreen/types';
import useDeepCompareMemoize from '../../../../utils/hooks/useDeepCompareMemoize.hook';
import { hasFeatureEnabled } from '../../../../utils/userconfig_selector';
import { useDataFromStorage } from '../../../ChartData/dataUtils';
import Table from '../../../DisplayPanel/Charts/Table/Table';
import './CatalogEditPanel.scss';
import DeleteColumnButton from './DeleteColumnButton';
import { formatCatalogSample, getMissingColumns } from './utils';

export type Props = {
  datasetId: string;
};

const MemoizedTable = React.memo(Table, (prevProps, nextProps) => {
  // Only re-render if the data, loading state, or column header end adornment changes
  return (
    isEqual(prevProps.data, nextProps.data) &&
    prevProps.isLoading === nextProps.isLoading &&
    prevProps.isFailed === nextProps.isFailed &&
    prevProps.getColumnHeaderEndAdornment === nextProps.getColumnHeaderEndAdornment
  );
});

const ColumnAnnotationEditor: React.FC<Props> = ({ datasetId }) => {
  const dispatch = useDispatch();
  // Fetch the annotations from the store
  const annotatedColumnsNames = useSelector((state: RootState) =>
    selectAnnotationColumnNames(state, datasetId),
  );
  const datasetObject = useDeepCompareMemoize<HomeObjectKeysTypes | undefined>(
    useSelector((state: RootState) => selectObjectByObjectFk(state, datasetId)),
  );
  const pipelinerDatasetId = datasetObject?.[HomeObjectKeys.PIPELINER_DATASET_ID] ?? '';
  const datasetStorage = useSelector(selectSessionlessDatasetStorage);
  const userConfig = useSelector(selectUserConfig);
  const isLoadingAnnotation = useSelector((state: RootState) =>
    selectDatasetAnnotationIsLoading(state, datasetId),
  );
  const error = useSelector((state: RootState) =>
    selectDatasetAnnotationErrorMessage(state, datasetId),
  );
  const annotationHasError = Boolean(error);

  const {
    data: tableData,
    isLoading,
    isFailed,
  } = useDataFromStorage({
    pipelinerDatasetId,
    datasetStorage,
    isTable: false, // We don't want the auto formatting to happen
  });
  const hasFetchedData = !isFailed && !isLoading && tableData?.columns && tableData?.rows;
  const allColumns =
    tableData?.columns?.map((col: { name: string; type: string }) => col.name) ?? [];
  const allSafeToShare = useSelector((state: RootState) =>
    selectAllSafeToShare(state, datasetId, allColumns),
  );

  // Create formatted columns for column annotations that don't exist in the actual data
  const missingColumns = getMissingColumns(annotatedColumnsNames, tableData?.columns || []);

  // Memoize the missing columns to prevent unnecessary re-renders
  const memoizedMissingColumns =
    useDeepCompareMemoize<{ name: string; type: string }[]>(missingColumns);

  // Combine the actual columns with the missing columns
  const allCols = missingColumns.concat(tableData?.columns || []);

  // transform row data from list of lists to list of objects
  const data = React.useMemo(() => {
    if (!hasFetchedData) return [];
    return formatCatalogSample(tableData?.rows || [], tableData?.columns || [], missingColumns);
  }, [tableData, hasFetchedData, missingColumns]);

  const getColumnHeaderEndAdornment = useCallback(
    (columnName: string) => {
      const missingColumn = memoizedMissingColumns?.find((col) => col.name === columnName);
      return missingColumn ? <DeleteColumnButton column={columnName} /> : null;
    },
    [memoizedMissingColumns],
  );

  const handleToggleAll = () => {
    if (tableData?.columns) {
      dispatch(toggleSafeToShare({ datasetId, allColumns }));
    }
  };

  return (
    <Box className="CatalogEditPanel-Columns">
      <Box className="CatalogEditPanel-Columns-TitleContainer">
        <Box className="CatalogEditPanel-Columns-TitleContainer-Text">
          <Typography variant="h5">Column Definitions</Typography>
          <Tooltip title="Provide additional information for the Data Assistant to understand each column.">
            <InfoOutlinedIcon sx={{ width: '20px' }} />
          </Tooltip>
        </Box>
        {/* Enable this feature once the Data Assistant actually reads data from datasets */}
        {hasFeatureEnabled(userConfig, FeatureTypes.READABLE_COLUMN_ANNOTATIONS) && (
          <Tooltip
            title={
              allSafeToShare
                ? 'Selecting this option prevents the Data Assistant from reading any data in this dataset.'
                : 'Selecting this option allows the Data Assistant to read all data in this dataset. This feature requires sending column data to the LLM.'
            }
            placement="left"
          >
            <Button
              onClick={handleToggleAll}
              variant="outlined"
              size="small"
              disabled={isLoading || isFailed || isLoadingAnnotation || annotationHasError}
            >
              <Typography>
                {allSafeToShare ? 'Mark All as Not Readable' : 'Mark All as Readable'}
              </Typography>
            </Button>
          </Tooltip>
        )}
      </Box>
      <MemoizedTable
        data={{
          data,
          title: 'Column Definitions',
          schema: {
            fields: isLoading ? [] : allCols,
          },
        }}
        isFailed={isFailed}
        rowCount={data?.length}
        isLoading={isLoading}
        showColumnAnnotations={!isLoading}
        disableCellKeyActions
        disableColumnMenu
        getColumnHeaderEndAdornment={getColumnHeaderEndAdornment}
      />
    </Box>
  );
};

export default ColumnAnnotationEditor;
