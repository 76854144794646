import { IntegrationProvider } from '../../../types/integrations.types';
import { OAuthPassthroughState } from '../oauth.types';

/* Google's OAuth 2.0 authorization endpoint */
const GOOGLE_OAUTH2_ENDPOINT = 'https://accounts.google.com/o/oauth2/v2/auth';

/**
 * Triggers the OAuth 2.0 flow for the specified provider.
 *
 * @param provider - The integration provider (BigQuery or Snowflake)
 * @param clientId - The OAuth 2.0 client ID
 * @param redirectUri - The redirect URI to return to after authorization
 * @param passthroughState - The state to pass to the redirect URI
 * @param additionalParams - Additional parameters specific to the provider
 */
export const authorizeOAuth = (
  provider: IntegrationProvider,
  clientId: string,
  redirectUri: string,
  passthroughState: OAuthPassthroughState,
  additionalParams: Record<string, string> = {},
) => {
  const baseParams = {
    client_id: clientId,
    redirect_uri: redirectUri,
    response_type: 'code',
    state: JSON.stringify(passthroughState),
  };

  let authParams: URLSearchParams;
  let url: string = '';

  switch (provider) {
    case IntegrationProvider.BIGQUERY:
      authParams = new URLSearchParams({
        ...baseParams,
        scope: 'https://www.googleapis.com/auth/bigquery',
        access_type: 'offline',
        include_granted_scopes: 'true',
        prompt: 'consent',
        ...additionalParams,
      });
      url = `${GOOGLE_OAUTH2_ENDPOINT}?${authParams.toString()}`;
      break;

    case IntegrationProvider.SNOWFLAKE:
      authParams = new URLSearchParams({
        ...baseParams,
        ...additionalParams,
      });
      if (!additionalParams.authenticationUri) {
        throw new Error('Authentication URI is required for Snowflake OAuth');
      }
      url = `${additionalParams.authenticationUri}?${authParams.toString()}`;
      break;

    default:
      throw new Error(`Unsupported provider: ${provider}`);
  }

  if (passthroughState.closeOnRedirect) {
    // Open OAuth Flow in a new tab & close the window after redirecting
    window.open(url, '_blank');
  } else {
    // Redirect the user to the OAuth
    window.location.href = url;
  }
};

/**
 * Triggers the OAuth 2.0 flow to authorize BigQuery.
 */
export const authorizeBigQuery = (
  clientId: string,
  redirectUri: string,
  passthroughState: OAuthPassthroughState,
) => {
  authorizeOAuth(IntegrationProvider.BIGQUERY, clientId, redirectUri, passthroughState);
};

/**
 * Triggers the OAuth 2.0 flow to authorize Snowflake.
 */
export const authorizeSnowflake = (
  clientId: string,
  clientSecret: string,
  redirectUri: string,
  authenticationUri: string,
  passthroughState: OAuthPassthroughState,
) => {
  authorizeOAuth(IntegrationProvider.SNOWFLAKE, clientId, redirectUri, passthroughState, {
    client_secret: clientSecret,
    authenticationUri,
  });
};
