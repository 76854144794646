import axios from 'axios';
import { dcAppServiceEndpoints } from '../constants/endpoints';
import { handleDCAppServiceCall } from '../utils/errorHandling/errorHandlers.api';
import { trimLeadingWhitespace } from '../utils/utterances';

export const getComplete = (message, accessToken, sessionId, isSelfInterrupting) => {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  if (isSelfInterrupting) {
    headers['Interrupt-Scope'] = sessionId;
    headers.Timestamp = Date.now();
  }

  return handleDCAppServiceCall(() =>
    axios.get(dcAppServiceEndpoints.complete, {
      headers,
      params: {
        sessionId,
        message: trimLeadingWhitespace(message),
      },
    }),
  );
};
