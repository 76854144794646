import Close from '@mui/icons-material/Close';
import Save from '@mui/icons-material/Save';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import Settings from '@mui/icons-material/Settings';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { getDatabaseConnectionHeaders } from '../../api/settings.api';
import {
  BUTTON,
  BUTTONS_CONTAINER,
  CE,
  CONNECTION_DATABASES,
  CONNECTION_DATABASES_TYPES,
  CONNECTION_NAME,
  CONTENT,
  CONTENT_CONTAINER,
  DISPLAY_NAME_TOOLTIP,
  FORM,
  FORM_SCROLL_CONTAINER,
  OUTLINED_BUTTON,
  READ_ONLY,
  TITLE,
  USE_STORAGE_API,
} from '../../constants/connection';
import { CONNECTION_INVALID_FILE_NAME, SAVE_AS_CONNECTION } from '../../constants/dialog.constants';
import { HOME_OBJECT_KEYS } from '../../constants/home_screen';
import { VALID_CONNECTION_NAME } from '../../constants/name_validation';
import {
  closeConnectionEditor,
  getConnectionObjectDataRequest,
  setEditMode,
  setSelectedDb,
  testConnectionRequest,
  updateConnectionRequest,
} from '../../store/actions/connection.actions';
import { openAlertDialogV1 } from '../../store/actions/dialog.actions';
import {
  selectAccessToken,
  selectConnectionData,
  selectConnectionObject,
  selectIsConnectionTesting,
  selectIsEditMode,
  selectIsSubmitting,
  selectSelectedDb,
} from '../../store/sagas/selectors';
import {
  selectDatabaseBrowserHideNavigation,
  selectDatabaseBrowserNumConnections,
} from '../../store/selectors/dbBrowser.selector';
import InfoTooltip from '../../utils/connection/InfoTooltip';
import CustomTextField from '../../utils/connection/Inputs/CustomTextField';
import {
  cleanConnectionValues,
  getDbInputField,
  refactorConnectionValues,
} from '../../utils/connection/connection';
import TextWithTooltip from '../common/TextWithTooltip';
import './ConnectionEditor.scss';
import DatabaseTabs from './DatabaseTabs';

const ConnectionEditor = () => {
  // Redux State
  const dispatch = useDispatch();
  const connObject = useSelector(selectConnectionObject);
  const connData = useSelector(selectConnectionData);
  const isTesting = useSelector(selectIsConnectionTesting);
  const isEditMode = useSelector(selectIsEditMode);
  const selectedDb = useSelector(selectSelectedDb);
  const accessToken = useSelector(selectAccessToken);
  const numConnections = useSelector(selectDatabaseBrowserNumConnections);
  const hideNavigation = useSelector(selectDatabaseBrowserHideNavigation);
  const isSubmitting = useSelector(selectIsSubmitting);

  // Local State
  const formikRef = useRef(null);

  React.useEffect(() => {
    // Connection Editor is in edit mode
    if (Object.keys(connObject).length > 0) {
      // get the UUID
      const { Uuid } = connObject;
      // Get connection details and connection object corresponding to uuid
      dispatch(setEditMode({ editMode: true }));
      dispatch(getConnectionObjectDataRequest({ uuid: Uuid }));
    }
  }, [dispatch, connObject]);

  const getInitialValues = () => {
    const { fields } = CONNECTION_DATABASES[selectedDb].body;
    let initialValues = {};
    // If edit connection, load the connection data to Formik initial values
    if (isEditMode && !isEmpty(connData)) {
      initialValues = refactorConnectionValues(connData);
    } else if (!fields) return initialValues;
    // If new connection load the initial values with values in the database file
    else {
      // Load the initial field data to Formik initial values
      fields.forEach((field) => {
        // Default the Read Only Input to checked for all database types
        if (field.id === READ_ONLY) {
          return (initialValues[READ_ONLY] = true);
        }
        return (initialValues[field.id] = field.value);
      });

      // Set datachatWorkspace as DATACHATWORKSPACE for Snowflake connection
      if (initialValues.databaseType === CONNECTION_DATABASES_TYPES.SNOWFLAKE) {
        initialValues.datachatWorkspace = 'DATACHATWORKSPACE';
      }
      // Set connection name as empty string initially
      initialValues[CONNECTION_NAME] = '';
    }
    return initialValues;
  };

  // Add Connection Name validation to the schema provided.
  const addConnectionNameValidation = (schema) => {
    // Adds the connection name validation to the existing schema
    const newSchema = schema.shape({
      [CONNECTION_NAME]: Yup.string().trim().required('Required'),
    });
    return newSchema;
  };

  const checkDuplicateConnectionName = async (connectionName, isSaveAs) => {
    try {
      // In edit mode, check duplicate if the name is same as in db.
      if (isEditMode && connData[CONNECTION_NAME] === connectionName) {
        // if name is from save as, return false, else if from save return true
        return !isSaveAs;
      }
      await getDatabaseConnectionHeaders(accessToken, connectionName);
      return true;
    } catch (error) {
      return false;
    }
  };

  // Defining formik validations
  const validateFormikFields = async (values) => {
    const errors = {};

    // Validate Connection Name
    // Only validate if connection name field is focused.
    if (document.activeElement.id === CONNECTION_NAME) {
      const { [CONNECTION_NAME]: connectionName } = values;
      // If connection name field is empty
      if (connectionName === '') {
        errors[CONNECTION_NAME] = 'Required';
        // Check for valid regex pattern
      } else if (!VALID_CONNECTION_NAME.test(connectionName)) {
        errors[CONNECTION_NAME] = CONNECTION_INVALID_FILE_NAME;
        // Check for duplicate connection name
      } else if (!(await checkDuplicateConnectionName(connectionName, false))) {
        errors[CONNECTION_NAME] = 'Connection name already exists';
      }
    }
    // If connection name field is not focussed or went our of focus (onBlur)
    // with invalid validation, keep the error message in the formik errors
    // We do this to avoid not showing error message when connection name field is out of focus
    else if (CONNECTION_NAME in formikRef.current?.errors ?? {}) {
      errors[CONNECTION_NAME] = formikRef.current.errors[CONNECTION_NAME];
    }
    return errors;
  };

  /**
   * Extracts values from the formic object and formats them for our reducers
   */
  const extractFormikValues = (formik) => {
    return {
      ...formik.values,
      password: formik.touched.password ? formik.values.password : undefined,
      accessToken: formik.touched.accessToken ? formik.values.accessToken : undefined,
    };
  };

  const handleDbValueChange = (_, newValue) => {
    dispatch(setSelectedDb({ database: newValue }));
  };

  /** Handles saving the form */
  const handleSubmitForm = (formik) => {
    const values = extractFormikValues(formik);
    dispatch(
      updateConnectionRequest({
        object: connObject,
        payload: cleanConnectionValues(values),
        isEditing: isEditMode,
      }),
    );
  };

  const handleTestConnection = (formik) => {
    const values = extractFormikValues(formik);
    dispatch(
      testConnectionRequest({
        databaseType: selectedDb,
        payload: cleanConnectionValues(values),
      }),
    );
  };

  /**
   * This will be called when the user clicks on save as button
   *
   * @param {*} values
   */
  const handleSaveAsConnection = (formik) => {
    // open alert dialog for name
    const values = extractFormikValues(formik);
    dispatch(
      openAlertDialogV1({
        dialogType: SAVE_AS_CONNECTION,
        dialogParams: {
          connectionPayload: cleanConnectionValues(values),
          connectionObject: connObject,
        },
      }),
    );
  };

  const renderCheckBox = (id, text, formik) => {
    return (
      <div>
        <Checkbox
          id={id}
          checked={formik.values[id] || false}
          onChange={(e) => {
            const { checked } = e.target;
            formik.handleChange(e);
            formik.setFieldValue(id, checked);
          }}
          sx={{
            // Override the default checked color
            '&.Mui-checked': {
              color: 'var(--mui-palette-primary-main)',
            },
          }}
        />
        <Typography alignSelf="center" variant="caption">
          {text}
        </Typography>
      </div>
    );
  };

  // selectedDb should always be set, but if it isn't, return null to prevent page crash
  if (!selectedDb) {
    return null;
  }

  const validationSchema = addConnectionNameValidation(
    CONNECTION_DATABASES[selectedDb].body.validationSchema,
  );

  const hideCloseButton = numConnections === 0 || hideNavigation;
  return (
    <div className={CE}>
      <Box className={`${TITLE}-Container`}>
        <TextWithTooltip
          className={`${TITLE}-Name`}
          dataTestid="ce-title-name"
          text={isEditMode ? `Edit ${connObject[HOME_OBJECT_KEYS.NAME]}` : 'Add Connection'}
        />
      </Box>
      <div className={CONTENT}>
        <Container className={CONTENT_CONTAINER} maxWidth="xl">
          <DatabaseTabs
            onDBChange={handleDbValueChange}
            selectedDb={selectedDb}
            editMode={isEditMode}
          />
          <Formik
            innerRef={formikRef}
            validationSchema={validationSchema}
            validate={validateFormikFields}
            initialValues={getInitialValues(selectedDb)}
            enableReinitialize
            onSubmit={() => {
              // Do nothing to prevent page reload
              // Reference: https://github.com/jaredpalmer/formik/issues/2505#issuecomment-867579344
            }}
          >
            {(formik) => (
              <form className={FORM} onSubmit={formik.handleSubmit}>
                <Box className={FORM_SCROLL_CONTAINER}>
                  <Box style={{ flexGrow: 1 }}>
                    <Grid container columnSpacing={3} rowSpacing={4}>
                      <Grid key={CONNECTION_NAME} item xs={12} sm={6}>
                        <CustomTextField
                          data-testid={`db-input-field-${CONNECTION_NAME}`}
                          fullWidth
                          variant="outlined"
                          label="Display Name"
                          required
                          id={CONNECTION_NAME}
                          type="text"
                          value={formik.values[CONNECTION_NAME]}
                          error={
                            formik.touched[CONNECTION_NAME] &&
                            Boolean(formik.errors[CONNECTION_NAME])
                          }
                          helperText={
                            formik.touched[CONNECTION_NAME] && formik.errors[CONNECTION_NAME]
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <InfoTooltip text={DISPLAY_NAME_TOOLTIP} />
                              </InputAdornment>
                            ),
                          }}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </Grid>

                      {CONNECTION_DATABASES[selectedDb].body.fields.map((field) => {
                        return getDbInputField(formik, field, selectedDb, validationSchema);
                      })}
                      <Grid key={READ_ONLY} item xs={12}>
                        <Stack direction="row" spacing={3} justifyContent="center">
                          {renderCheckBox(READ_ONLY, 'Read Only', formik)}
                          {selectedDb === CONNECTION_DATABASES_TYPES.BIGQUERY && (
                            <Tooltip title="Selecting this option will improve transfer speeds, but may result in increased BigQuery costs">
                              {renderCheckBox(
                                USE_STORAGE_API,
                                "Use BigQuery's Storage API",
                                formik,
                              )}
                            </Tooltip>
                          )}
                        </Stack>
                      </Grid>
                    </Grid>
                    <hr className={`${FORM}-horizontal-rule`} />
                  </Box>
                  <Box className={BUTTONS_CONTAINER}>
                    {!hideCloseButton ? (
                      <Button
                        className={`${BUTTONS_CONTAINER}-Close-Button`}
                        size="large"
                        onClick={() => dispatch(closeConnectionEditor())}
                        variant="outlined"
                        startIcon={<Close data-testid="ConnectionEditor-CloseIcon" />}
                        color="error"
                      >
                        Close
                      </Button>
                    ) : (
                      <></>
                    )}
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
                      <LoadingButton
                        data-testid="ce-test-btn"
                        className={OUTLINED_BUTTON}
                        variant="outlined"
                        loadingPosition="center"
                        startIcon={<Settings />}
                        size="large"
                        loading={isTesting}
                        onClick={() => handleTestConnection(formik, selectedDb)}
                        disabled={
                          (isEditMode ? !formik.isValid : !(formik.isValid && formik.dirty)) ||
                          isSubmitting
                        }
                      >
                        Test
                      </LoadingButton>
                      {isEditMode && (
                        <Button
                          data-testid="ce-save-as-btn"
                          className={OUTLINED_BUTTON}
                          startIcon={<SaveAsIcon />}
                          variant="outlined"
                          size="large"
                          onClick={() => handleSaveAsConnection(formik)}
                          disabled={!formik.isValid || isSubmitting}
                        >
                          Save As
                        </Button>
                      )}
                      <Button
                        data-testid="ce-save-btn"
                        className={BUTTON}
                        startIcon={<Save />}
                        variant="contained"
                        type="submit"
                        size="large"
                        disabled={!(formik.isValid && formik.dirty) || isSubmitting}
                        onClick={() => handleSubmitForm(formik)}
                      >
                        Save
                      </Button>
                    </Stack>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </div>
    </div>
  );
};

export default ConnectionEditor;
