/* eslint-disable class-methods-use-this */
import Status from 'http-status-codes';
import { ErrorDetails } from '../../../types/errorCodes/errorCodes.types';
import { BaseErrorHandler } from './BaseErrorHandler';

export class TaskAlreadyStartedError extends Error {}

export class TaskAlreadyStartedErrorHandler extends BaseErrorHandler {
  canHandle(errorDetails?: ErrorDetails): boolean {
    if (!errorDetails) return false;
    return (
      errorDetails.status === Status.FORBIDDEN &&
      errorDetails.message === 'Task must have new status'
    );
  }

  handle(): void {
    throw new TaskAlreadyStartedError();
  }
}
