import axios from 'axios';
import { endpoints } from '../constants/endpoints';

/**
 * Retrieves a profile picture from storage.
 *
 * Contains two API calls to:
 *   1. Get the profile picture download URL
 *   2. Download the profile picture
 *
 * @param {string} accessToken The user's access token
 * @param {string} filename The profile picture filename
 * @returns {import('../types/download.types').GetProfilePictureResponse}
 */
export const getProfilePicture = (accessToken, filename) =>
  // Get the profile picture download URL
  axios
    .get(`${endpoints.profilePictureData}/${filename}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    // Then download the profile picture
    .then((resp) => {
      const { pfpUrl } = resp.data;
      return axios.get(pfpUrl, {
        responseType: 'arraybuffer',
      });
    });

export const getDownload = (accessToken, session, file) =>
  axios
    .get(endpoints.download, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        session,
        file,
      },
    })
    .then((resp) => {
      // Download file from pre-signed URL. Emulate 302 redirect behavior, but don't include unnecessary headers
      const { downloadUrl } = resp.data;
      return axios.get(downloadUrl);
    });
