import axios from 'axios';

import { endpoints } from '../constants/endpoints';

export const headFiles = (accessToken, filename) =>
  axios.head(endpoints.files, {
    headers: { Authorization: `Bearer ${accessToken}` },
    params: { file_name: filename },
  });

export const createFilePondServerConfig = (accessToken) => ({
  process: (fieldName, file, metadata, load, error, progress, abort) => {
    const formData = new FormData();
    formData.append(fieldName, file, file.name);
    if (metadata) {
      formData.append(fieldName, JSON.stringify(metadata));
    }

    const abortController = new AbortController();

    // Create a file to get a pre-signed URL to upload to
    axios
      .post(
        endpoints.files,
        {
          file_name: file.name,
          file_size: file.size.toString(),
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      )
      .then((resp) => {
        // We have a URL and method to upload to
        const { uploadDest, uploadUrl, id } = resp.data;

        // Different upload process depending on where we're sending the file to
        // See `fileUploadUrlHandler` in `file.go`
        switch (uploadDest) {
          case 'management_server':
            axios
              .post(endpoints.file, formData, {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
                signal: abortController.signal,
                onUploadProgress: (e) => {
                  progress(e.lengthComputable, e.loaded, e.total);
                },
              })
              .then(() => {
                load(id); // id will eventually be the "serverId" of this file after processing
              })
              .catch((err) => {
                // Error uploading to management server
                error(err.response);
              });
            break;
          case 'shared_storage':
            // S3-compatible APIs use PUT to upload data
            // Since the management server is not directly handling this request, we need to let it know that the upload
            // is complete after we're done uploading to shared storage
            axios
              .put(uploadUrl, file, {
                headers: {
                  'Content-Type': 'application/octet-stream',
                },
                signal: abortController.signal,
                onUploadProgress: (e) => {
                  progress(e.lengthComputable, e.loaded, e.total);
                },
              })
              .then(() => {
                load(id); // id will eventually be the "serverId" of this file after processing
              })
              .catch((err) => {
                // Error uploading to shared storage
                error(err.response);
              });
            break;
          default:
            error(`got unsupported upload destination "${uploadDest}"`);
            break;
        }
      })
      .catch((err) => {
        // Error when getting pre-signed URL
        error(err.response);
      });

    return {
      abort: () => {
        // Signal axios requests that the operation was canceled
        abortController.abort('Operation canceled by user');

        // Signal FilePond that the operation was canceled
        abort();
      },
    };
  },
});

export const putFiles = (accessToken, dataFileId) =>
  axios.put(`${endpoints.files}/${dataFileId}`, null, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
