import { useEffect, useMemo, useState } from 'react';
import { MAX_CELL_LIMIT } from './constants';
import {
  combineCalculatedWidths,
  determineMaxWidths,
  getTruncatedBounds,
  getUpdatedValueFormatters,
  handleExceededCellLimit,
} from './utils';

/**
 * Returns the expanded widths of each column in the table.
 *
 * @param {Object} data - contains row (data.rows) and column (data.columns) data
 * @param {number} lowerBound - the lower bound of the rows to be displayed, defaults to 0
 * @param {number} upperBound - the upper bound of the rows to be displayed, defaults to rows.length
 * @param {Object} valueFormatterMap - the value formatter map
 * @param {boolean} ignoreCachedValues - if true, ignores the cached values and recalculates
 * @returns {Object} - the expanded widths of each column in the table, with the column name as the
 *                     key and the expanded width as the value
 */
export const useExpandedWidths = (
  data,
  valueFormatterMap,
  lowerBound,
  upperBound,
  ignoreCachedValues,
  customWidthAdjustment = null,
) => {
  // previousBounds is used to determine if the bounds have changed
  const [previousBounds, setPreviousBounds] = useState({ lowerBound: 0, upperBound: 0 });
  // prevExpandedWidths is used to store the expanded widths of the previous bounds
  const [prevExpandedWidths, setPrevExpandedWidths] = useState({});
  const [prevValueFormatters, setPrevValueFormatters] = useState({});

  const expandedWidths = useMemo(() => {
    // if data is not ready, return empty array
    if (!(data?.rows && data?.columns && valueFormatterMap)) {
      return [];
    }
    const { rows, columns: cols } = data;

    // base values without caching
    let newBounds = { lowerBound: lowerBound || 0, upperBound: upperBound || rows.length };
    let updatedValueFormatters = {};

    if (!ignoreCachedValues) {
      // get the row range that has not been included in the column width calculation yet
      newBounds = getTruncatedBounds(newBounds, previousBounds);
      // find the columns where the value formatters have changed
      updatedValueFormatters = getUpdatedValueFormatters(valueFormatterMap, prevValueFormatters);
    }

    // if the value formatters have changed, recalculate the expanded widths for those columns
    const updatedColumns = Object.keys(updatedValueFormatters);

    let updatedValueFormatterWidths = {};

    if (updatedColumns.length > 0 && prevValueFormatters.length !== 0) {
      const bounds = {
        lowerBound: Math.min(newBounds.lowerBound, previousBounds.lowerBound),
        upperBound: Math.max(newBounds.upperBound, previousBounds.upperBound),
      };
      const colsSubset = cols.filter((col) => updatedColumns.includes(col.name));
      if (updatedColumns.length * (bounds.lowerBound - bounds.upperBound) > MAX_CELL_LIMIT) {
        updatedValueFormatterWidths = handleExceededCellLimit({
          rows,
          cols: colsSubset,
          valueFormatterMap,
          prevExpandedWidths,
        });
      } else {
        updatedValueFormatterWidths = determineMaxWidths(
          rows,
          colsSubset,
          bounds,
          updatedValueFormatters,
        );
      }
    }

    // do not recalculate if cell count is greater than a certain value
    const rowCount = newBounds.upperBound - newBounds.lowerBound;
    const cellCount = cols.length * rowCount;
    const widths =
      cellCount > MAX_CELL_LIMIT
        ? handleExceededCellLimit({
            rows,
            cols,
            rowBounds: newBounds,
            valueFormatterMap,
            prevExpandedWidths,
          })
        : determineMaxWidths(rows, cols, newBounds, valueFormatterMap, customWidthAdjustment);

    // join the previous prevExpandedWidths with widths by taking the max width for each column
    const newWidths = combineCalculatedWidths([
      ignoreCachedValues ? {} : prevExpandedWidths,
      widths,
      updatedValueFormatterWidths,
    ]);

    // update the previous bounds, prevExpandedWidths, and prevValueFormatters
    setPrevExpandedWidths(newWidths);
    setPrevValueFormatters(valueFormatterMap);
    setPreviousBounds({
      lowerBound: Math.min(newBounds.lowerBound, previousBounds.lowerBound),
      upperBound: Math.max(newBounds.upperBound, previousBounds.upperBound),
    });
    return newWidths;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.rows, data?.columns, lowerBound, upperBound, valueFormatterMap]);
  return expandedWidths;
};

// applies a double click listener to the column separator in DataGridPro components
export const useColumnSeparator = (separatorId, onDoubleClick) => {
  useEffect(() => {
    const columnSeparator = document.getElementById(separatorId);
    const handleDoubleClick = (e) => {
      const x = e.clientX;
      const y = e.clientY;
      const rect = columnSeparator.getBoundingClientRect();
      const isInColumnSeparator =
        rect.left <= x && x <= rect.right && rect.top <= y && y <= rect.bottom;
      if (isInColumnSeparator) {
        onDoubleClick(e);
      }
    };

    document.addEventListener('dblclick', handleDoubleClick);

    return () => {
      document.removeEventListener('dblclick', handleDoubleClick);
    };
  }, [separatorId, onDoubleClick]);
};
