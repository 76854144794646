export const GET_USERS_IN_ORGANIZATION_REQUEST = 'organization/GET_USERS_IN_ORGANIZATION_REQUEST';
export const GET_USERS_IN_ORGANIZATION_FAILURE = 'organization/GET_USERS_IN_ORGANIZATION_FAILURE';
export const GET_USERS_IN_ORGANIZATION_SUCCESS = 'organization/GET_USERS_IN_ORGANIZATION_SUCCESS';

/**
  Dispatch action to fetch users in organization corresponding to organization id
   or default organization id.
  @param {number} id // Organization Id
  If no organization id is provided, this action considers the default organization id.
*/
export const getUsersInOrganizationRequest = () => ({
  type: GET_USERS_IN_ORGANIZATION_REQUEST,
});

export const getUsersInOrganizationFailure = ({ id, error }) => ({
  type: GET_USERS_IN_ORGANIZATION_FAILURE,
  id,
  error,
});

export const getUsersInOrganizationSuccess = ({ id, data }) => ({
  type: GET_USERS_IN_ORGANIZATION_SUCCESS,
  id,
  data,
});
