import {
  REPRESENTATION_TYPES,
  representationNumericTypes,
  temporalTypes,
} from '../../utils/formatting/type';

// Specification for pivotGrid for formatting for numerical values
const FORMATS = {
  DECIMAL: { type: 'fixedPoint', precision: 2 },
  [REPRESENTATION_TYPES.DATE]: 'yyyy-MM-dd',
  [REPRESENTATION_TYPES.TIMESTAMP]: 'EEE, MMM dd, yyyy, hh:mm:ss a',
  [REPRESENTATION_TYPES.TIME]: 'HH:mm:ss ',
};

const getFormat = (type) => {
  if (type === REPRESENTATION_TYPES.DATE) {
    return FORMATS[[REPRESENTATION_TYPES.DATE]];
  }
  if (type === REPRESENTATION_TYPES.TIMESTAMP) {
    return FORMATS[REPRESENTATION_TYPES.TIMESTAMP];
  }
  if (type === REPRESENTATION_TYPES.TIME) {
    return FORMATS[REPRESENTATION_TYPES.TIME];
  }
  return FORMATS.DECIMAL;
};

const CUSTOM_SUMMARY_PROCESS = {
  START: 'start',
  CALCULATE: 'calculate',
  FINALIZE: 'finalize',
};

// custom summary functions for counting unique values
const calculateCountUnique = (options) => {
  if (options.summaryProcess === CUSTOM_SUMMARY_PROCESS.START) {
    options.totalValue = 0; // Sum
    options.set = new Set();
  }
  if (options.summaryProcess === CUSTOM_SUMMARY_PROCESS.CALCULATE) {
    if (!options.set.has(options.value)) {
      options.totalValue += 1;
      options.set.add(options.value);
    }
  }
};

// custom summary functions for calculating standard deviation
const calculateStDev = (options) => {
  if (options.summaryProcess === CUSTOM_SUMMARY_PROCESS.START) {
    options.totalValue = 0; // Sum
    options.values = []; // Sum of squared values
    options.n = 0;
  }
  if (options.summaryProcess === CUSTOM_SUMMARY_PROCESS.CALCULATE) {
    options.totalValue += options.value;
    options.values.push(options.value);
    options.n++;
  }
  if (options.summaryProcess === CUSTOM_SUMMARY_PROCESS.FINALIZE) {
    const { n, values } = options;
    if (values.length !== 0) {
      const mean = values.reduce((a, b) => a + b) / n;
      options.totalValue = Math.sqrt(
        values.map((x) => (x - mean) ** 2).reduce((a, b) => a + b) / n,
      );
    } else {
      options.totalValue = 0;
    }
  }
};

// custom summary functions for calculating median
const calculateMedian = (options) => {
  if (options.summaryProcess === CUSTOM_SUMMARY_PROCESS.START) {
    options.values = [];
    options.n = 0;
  }
  if (options.summaryProcess === CUSTOM_SUMMARY_PROCESS.CALCULATE) {
    options.values.push(options.value);
    options.n++;
  }
  if (options.summaryProcess === CUSTOM_SUMMARY_PROCESS.FINALIZE) {
    // sorting first. The array is smaller than a threshhold (500), no need for optimization
    const { values, n } = options;
    const sorted = Array.from(values).sort((a, b) => a - b);
    const middle = Math.ceil(sorted.length / 2);

    if (n % 2 === 0) {
      options.totalValue = (sorted[middle - 1] + sorted[middle]) / 2;
    } else {
      options.totalValue = sorted[middle];
    }
  }
};

// post processing function for proportion summary type
const postProcessProportion = (e) => {
  return e.value() / e.grandTotal().value();
};

// Mapping between the summary type and calculation methods
export const PIVOT_OPERATION_MAP = {
  average: {
    summaryType: 'avg',
    format: (type) => (type ? FORMATS.DECIMAL : ''),
    calculateCustomSummary: null,
    calculateSummaryValue: null,
    summaryDisplayMode: null,
    supportedCols: [representationNumericTypes],
  },
  'count of': {
    summaryType: 'count',
    format: () => {},
    calculateCustomSummary: null,
    calculateSummaryValue: null,
    summaryDisplayMode: null,
  },
  'distinct count of': {
    summaryType: 'custom',
    format: () => {},
    calculateCustomSummary: calculateCountUnique,
    calculateSummaryValue: null,
    summaryDisplayMode: null,
  },
  'percentage of': {
    summaryType: 'sum',
    summaryDisplayMode: 'percentOfGrandTotal',
    format: (type) => (type ? FORMATS.DECIMAL : ''),
    calculateCustomSummary: null,
    calculateSummaryValue: null,
    supportedCols: [representationNumericTypes],
  },
  maximum: {
    summaryType: 'max',
    summaryDisplayMode: null,
    format: (type) => getFormat(type),
    calculateCustomSummary: null,
    calculateSummaryValue: null,
    supportedCols: [representationNumericTypes, temporalTypes],
  },
  median: {
    summaryType: 'custom',
    format: (type) => getFormat(type),
    calculateCustomSummary: calculateMedian,
    calculateSummaryValue: null,
    summaryDisplayMode: null,
    supportedCols: [representationNumericTypes, temporalTypes],
  },
  minimum: {
    summaryType: 'min',
    calculateCustomSummary: null,
    format: (type) => getFormat(type),
    calculateSummaryValue: null,
    summaryDisplayMode: null,
    supportedCols: [representationNumericTypes, temporalTypes],
  },
  total: {
    summaryType: 'sum',
    format: () => FORMATS.DECIMAL,
    calculateCustomSummary: null,
    calculateSummaryValue: null,
    summaryDisplayMode: null,
    supportedCols: [representationNumericTypes],
  },
  'standard deviation of': {
    summaryType: 'custom',
    format: () => FORMATS.DECIMAL,
    calculateCustomSummary: calculateStDev,
    calculateSummaryValue: null,
    summaryDisplayMode: null,
    supportedCols: [representationNumericTypes],
  },
  'proportion of': {
    summaryType: 'count',
    format: () => FORMATS.DECIMAL,
    calculateCustomSummary: null,
    calculateSummaryValue: postProcessProportion,
    summaryDisplayMode: null,
  },
};

export const STRING_EXPRESSIONS = ['count of', 'distinct count of', 'proportion of'];

export const NUMERIC_EXPRESSIONS = [
  'average',
  'maximum',
  'minimum',
  'total',
  'median',
  'standard deviation of',
  'percentage of',
  'count of',
  'distinct count of',
  'proportion of',
];

export const TEMPORAL_EXPRESSIONS = [
  'maximum',
  'minimum',
  'median',
  'count of',
  'distinct count of',
  'proportion of',
];

// TODO - move to JSON skill submission
// create the partial utterance based on the inputs
export const createUtterance = (summaryType, summaryColumn, columns, rows) => {
  let utterance = 'Pivot on';
  if (!summaryType) {
    return [utterance, false];
  }
  utterance += ` ${summaryType} `;

  if (!summaryColumn || !summaryColumn.name) {
    return [utterance, false];
  }
  utterance += `${summaryColumn.name} `;

  if (!rows?.length > 0) {
    return [utterance, false];
  }
  utterance += `creating rows from ${rows.map((col) => col.name).join(', ')}`;

  if (!columns?.length > 0) {
    return [utterance, false];
  }
  utterance += ` and columns from ${columns.map((col) => col.name).join(', ')}`;

  return [utterance, true];
};

export const RECORD_ID = {
  dcID: 'records',
  internalID: 'record id',
};

/**
* Format the base data for internal processing
* The pivot table library requires data to be in the format:

 [
  { "*col1": "row1", "*col2": "row1", "*col3": "row1", "record id": 0 },
  { "*col1": "row2", "*col2": "row2", "*col3": "row2", "record id": 1 },
  ...
 ]
 */
export const formatPivotData = (rows, cols) => {
  return rows.map((row, i) => {
    const newRow = row.reduce((acc, value, j) => {
      const colName = `*${cols[j].name}`;
      return { ...acc, [colName]: value };
    }, {});
    newRow[RECORD_ID.internalID] = i;
    return newRow;
  });
};

export const PIVOT_ROW_LIMIT = 1000;
