import { SENDERS, VISUAL_TYPES } from '../../constants';

// Commands
export const CLEAR_SCREEN = 'message/CLEAR_SCREEN';
export const GOODBYE_COMMAND = 'message/GOODBYE_COMMAND';

// Communication
export const SEND_MESSAGE_REQUEST = 'message/SEND_MESSAGE_REQUEST';
export const SEND_MESSSAGE_BYPASS = 'message/SEND_MESSAGE_BYPASS';
export const SEND_MESSAGE_SUCCESS = 'message/SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_FAILURE = 'message/SEND_MESSAGE_FAILURE';

export const RECEIVE_MESSAGES_REQUEST = 'message/RECEIVE_MESSAGES_REQUEST';
export const RECEIVE_MESSAGES_EMPTY = 'message/RECEIVE_MESSAGES_EMPTY';
export const RECEIVE_MESSAGES_SUCCESS = 'message/RECEIVE_MESSAGES_SUCCESS';
export const RECEIVE_MESSAGES_FAILURE = 'message/RECEIVE_MESSAGES_FAILURE';

export const PARSE_MESSAGES_FAILURE = 'message/PARSE_MESSAGES_FAILURE';

export const DESCRIBE_AND_SEND_UTTERANCE_REQUEST = 'message/DESCRIBE_AND_SEND_UTTERANCE_REQUEST';
export const DESCRIBE_AND_SEND_UTTERANCE_SUCCESS = 'message/DESCRIBE_AND_SEND_UTTERANCE_SUCCESS';
export const DESCRIBE_AND_SEND_UTTERANCE_FAILURE = 'message/DESCRIBE_AND_SEND_UTTERANCE_FAILURE';

export const FORGET_DATASET_REQUEST = 'message/FORGET_DATASET_REQUEST';
export const FORGET_DATASET_SUCCESS = 'message/FORGET_DATASET_SUCCESS';
export const FORGET_DATASET_FAILURE = 'message/FORGET_DATASET_FAILURE';

export const DISABLE_AUTOMATIC_DOWNLOAD = 'message/DISABLE_AUTOMATIC_DOWNLOAD';

// Chat Panel
export const ADD_USER_MESSAGE = 'message/ADD_USER_MESSAGE';

// Messages reload
export const RELOAD_MESSAGES = 'message/RELOAD_MESSAGES';
export const RELOAD_MESSAGES_SUCCESS = 'message/RELOAD_MESSAGES_SUCCESS';
export const RELOAD_MESSAGES_FAILURE = 'message/RELOAD_MESSAGES_FAILURE';

export const PLOT_SUCCESS = 'message/PLOT_SUCCESS';

export const CHART_UPDATE = 'message/CHART_UPDATE';

export const SKILL_FAILED = 'message/SKILL_FAILED';

export const SKILL_QUESTION = 'message/SKILL_QUESTION';

/**
 * Creates a message object from JSON payload.
 * @param {import('../slices/nodes.slice').Message} payload - JSON payload being converted
 */
export const createMessage = (payload) => {
  const message = {
    data: payload.data,
    display: payload.display,
    type: payload.type,
    typeVersion: payload.typeVersion,
    class: payload.class,
    skillEvent: payload.skill_event,
    exitCode: payload.exitCode,
    sender: payload.instruction ? SENDERS.USER : SENDERS.SERVER,
    muted: payload.muted, // If true, do not display this message.
    objectId: payload.object_id,
    version: payload.version,
    externalName: payload.external_name,
    reason: payload.reason,
    instruction: payload.instruction,
    skillMessageType: payload.skill_message_type,
    splitBySkillLevel: payload.splitBySkillLevel,
    fromUser: payload.from_user,
    importance: payload.importance,
    additionalInfo: payload.additional_info,
    dataUsage: payload.data_usage,
    skipParse: payload.skipParse, // Parse utterance by the frontend and not backend
    utteranceMetadata: payload.utteranceMetadata,
  };

  if (payload.type === VISUAL_TYPES.TABVISUAL) {
    const tabContents = Object.entries(message.data.tab_contents).reduce(
      (acc, [label, content]) => {
        return {
          ...acc,
          [label]: {
            data: content.data,
            type: content.type,
            typeVersion: content.typeVersion,
            objectId: content.object_id,
            version: content.version,
            externalName: content.external_name,
            additionalInfo: content.additional_info,
          },
        };
      },
      {},
    );
    message.data = { tabs: payload.data.tabs, tabContents };
  }

  return message;
};

export const sendMessageRequest = ({
  message,
  waitForResponse = true,
  waitForCacheFileUpload = false,
  dashboardDcChartId = undefined,
  utteranceMetadata = {},
  sessionID = '',
  muted = false,
}) => ({
  type: SEND_MESSAGE_REQUEST,
  message: {
    data: message,
    type: 'text',
    sender: SENDERS.USER,
    utteranceMetadata,
  },
  waitForResponse,
  waitForCacheFileUpload,
  dashboardDcChartId,
  sessionID,
  muted,
});

/**
 * Sends a message directly.
 * All utterances programatically composed should be sent through this venue
 * since these do not go in to the user utterance queue
 * @param {Object} message -  message that's being sent
 */
export const sendMessageBypass = ({ message, sessionID }) => ({
  type: SEND_MESSSAGE_BYPASS,
  message: createMessage(message),
  sessionID,
});

export const addUserMessage = ({ data, display }) => ({
  type: ADD_USER_MESSAGE,
  message: {
    data,
    display,
    type: 'text',
    sender: SENDERS.USER,
  },
});

export const sendMessageSuccess = () => ({
  type: SEND_MESSAGE_SUCCESS,
});

export const sendMessageFailure = ({ error }) => ({
  type: SEND_MESSAGE_FAILURE,
  error,
});

export const receiveMessagesRequest = () => ({
  type: RECEIVE_MESSAGES_REQUEST,
});

// Generate utterance from JSON and send it
export const describeAndSendUtteranceRequest = ({ message, callback, sendRaw, muted = false }) => ({
  type: DESCRIBE_AND_SEND_UTTERANCE_REQUEST,
  message,
  callback,
  sendRaw,
  muted,
});
export const describeAndSendUtteranceSuccess = () => ({
  type: DESCRIBE_AND_SEND_UTTERANCE_SUCCESS,
});

export const describeAndSendUtteranceFailure = ({ error }) => ({
  type: DESCRIBE_AND_SEND_UTTERANCE_FAILURE,
  error,
});

// Request to forget dataset with an alert
export const forgetDatasetRequest = ({ dataset, callback }) => ({
  type: FORGET_DATASET_REQUEST,
  dataset,
  callback,
});

export const forgetDatasetSuccess = () => ({
  type: FORGET_DATASET_SUCCESS,
});

export const forgetDatasetFailure = ({ error }) => ({
  type: FORGET_DATASET_FAILURE,
  error,
});

export const receiveMessagesEmpty = () => ({
  type: RECEIVE_MESSAGES_EMPTY,
});

export const receiveMessagesSuccess = ({ messages }) => ({
  type: RECEIVE_MESSAGES_SUCCESS,
  messages: messages.map((message) => ({
    message: createMessage(message),
  })),
});

export const receiveMessagesFailure = ({ error }) => ({
  type: RECEIVE_MESSAGES_FAILURE,
  error,
});

export const parseMessagesFailure = ({ error }) => ({
  type: PARSE_MESSAGES_FAILURE,
  error,
});

export const POLL_MESSAGES_FAILURE = 'message/POLL_MESSAGES_FAILURE';
export const pollMessagesFailure = ({ error }) => ({
  type: POLL_MESSAGES_FAILURE,
  error,
});

export const clearScreen = () => ({
  type: CLEAR_SCREEN,
});

export const goodbye = (numKeystrokes) => ({
  type: GOODBYE_COMMAND,
  numKeystrokes,
});

export const disableAutomaticDownload = ({ contextId, messageId }) => ({
  type: DISABLE_AUTOMATIC_DOWNLOAD,
  contextId,
  messageId,
});

export const reloadMessages = ({ sessionId }) => {
  return {
    type: RELOAD_MESSAGES,
    sessionId,
  };
};

export const reloadMessagesSuccess = () => {
  return {
    type: RELOAD_MESSAGES_SUCCESS,
  };
};

export const reloadMessagesFailure = ({ error }) => {
  return {
    type: RELOAD_MESSAGES_FAILURE,
    error,
  };
};

/**
 * Signal that one particular chart has finished plotting
 * used during session reload to determine when we can take off the loading screen
 */
export const plotSuccess = () => {
  return {
    type: PLOT_SUCCESS,
  };
};

export const addChartUpdate = (objectId, version, spec) => {
  return {
    type: CHART_UPDATE,
    objectId,
    version,
    spec,
  };
};

/**
 * Handles messages of class CLASS.FAILED
 *
 * @param {object} obj
 * @param {Error} obj.error - Error object
 * @param {import('../../types/errorCodes/errorCodes.types').ErrorDetails} obj.errorDetails
 * @returns
 */
export const skillFailed = ({ error, errorDetails }) => {
  return {
    type: SKILL_FAILED,
    error,
    errorDetails,
  };
};

export const skillQuestion = ({ data }) => ({
  type: SKILL_QUESTION,
  data,
});
