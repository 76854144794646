import axios from 'axios';
import { nl2codeEndpoints } from '../constants/endpoints';

export const getGeneratedCode = (accessToken, payload) =>
  axios.post(nl2codeEndpoints.generateCode, payload, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${accessToken}`,
    },
    params: { sessionId: payload.session_id },
  });

/**
 * Cancels a running query.
 * @param {String} accessToken The access token of the user.
 * @param {String} sessionId The session in which the query is running.
 * @param {String} taskId The task ID of the query to cancel.
 * @returns {void}
 */
export const cancelRunningQuery = ({ accessToken, sessionId, taskId }) =>
  axios.post(
    nl2codeEndpoints.cancelQuery,
    { taskId },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        sessionId,
      },
    },
  );

export const editAskAvaCache = ({ accessToken, vectorId, sessionId, metadata }) =>
  axios.patch(
    nl2codeEndpoints.metadata,
    { vector_id: vectorId, metadata },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        sessionId,
      },
    },
  );
