// TODO: Properly define z-indices for strict-layering
exports.sassVars = {
  app: {
    snackbarszIndex: 10001,
  },
  chatPanel: {
    fullscreenzIndex: 1,
  },
  collapseButton: {
    collapsezIndex: 2,
  },
  collapsibleTree: {
    circlezIndex: 200,
    textzIndex: 800,
    labelContainerzIndex: 800,
    gzIndex: 100,
    svgzIndex: 850,
    pathzIndex: 500,
    tooltipzIndex: 1,
  },
  dashboardGrid: {
    reorderButtonzIndex: 1,
  },
  dcLegend: {
    legendBoxzIndex: 10,
  },
  dcPlot: {
    contextMenuzIndex: 900,
  },
  header: {
    ddMenuzIndex: 500,
  },
  page: {
    pagezIndex: 0,
  },
  textBox: {
    textBoxzIndex: 0,
  },
  plotlyComponentContainer: {
    loadingIndicatorContainerzIndex: 2,
  },
};

exports.theme = {
  palette: {
    primary: '#791ABF',
    primary_h: '#54B7FF',
    primary_dark: '#556DAB',

    header_primary: '#FFFFFF',

    secondary: '#DC3131',
    secondary_h: '#CD2C2C',

    black: '#111210',
    black_h: '#282A25',
    black_l: '#282A25',

    white: '#FFFFFF',
    nearly_white: '#F7F7F7',

    gray: '#707070',
    gray_h: '#B9B9B9',
    gray_med_dark: '#4e5a5f',
    grey_dark: '#adadad',

    lightgray: '#F0F0F0',
    lightgray_h: '#FAFAFA',

    lightgrayblue: '#F1F6FA',
    blue: '#61BCFF',

    green: '#2E7D32',
    green_h: '#1B5E20',
    green_bright: '#52BE80',

    orange: '#FF9800',
    orange_h: '#FB8C00',

    red: '#F44336',
    red_h: '#E53935',
  },
  dimensions: {
    headerHeight: 70,
  },
  typography: {
    fontFamily: 'Source Sans Pro, sans-serif',
    h1: {
      fontSize: 26,
      fontWeight: 400,
      paddingTop: 15,
      paddingBottom: 10,
      fontFamily: 'Source Sans Pro, sans-serif',
    },
    h2: {
      fontSize: 24,
      fontWeight: 500,
      paddingTop: 12,
      paddingBottom: 8,
      fontFamily: 'Source Sans Pro, sans-serif',
    },
    small: {
      fontSize: 14,
      fontFamily: 'Source Sans Pro, sans-serif',
    },
  },
};
