/** Available publication types */
export enum PublicationTypes {
  MODEL = 'model',
  CHART = 'chart',
}

/** Actions that can be performed on publications */
export enum PublicationActions {
  /** For use on 'model' publications.
   * Runs a prediction on a sample of dependent variables */
  PREDICT = 'predict',
  /** For use on 'model' publications.
   * Runs a prediction on a sample of dependent variables */
  PROFILE = 'profile',
  /** For use on 'chart' publications.
   * Refresh the chart to get the latest version */
  REFRESH = 'refresh',
}

/** Status types of publication actions */
export enum PublicationActionStatuses {
  COMPLETE = 'COMPLETE', // Action finished successfully
  INCOMPLETE = 'INCOMPLETE', // Worker is still running the action
  ERROR = 'ERROR', // Action finished unsuccessfully
  FAILURE = 'FAILURE', // Caught errors when performing an action (Not an actual action status)
}
