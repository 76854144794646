export const OPEN_HELP_DROP_DOWN_MENU = 'settings/OPEN_HELP_DROP_DOWN_MENU';
export const CLOSE_HELP_DROP_DOWN_MENU = 'settings/CLOSE_HELP_DROP_DOWN_MENU';
export const OPEN_PROFILE_DROP_DOWN_MENU = 'settings/OPEN_PROFILE_DROP_DOWN_MENU';
export const CLOSE_PROFILE_DROP_DOWN_MENU = 'settings/CLOSE_PROFILE_DROP_DOWN_MENU';

export const GET_USER_CONFIG_REQUEST = 'settings/GET_USER_CONFIG_REQUEST';
export const GET_USER_CONFIG_SUCCESS = 'settings/GET_USER_CONFIG_SUCCESS';
export const GET_USER_CONFIG_FAILURE = 'settings/GET_USER_CONFIG_FAILURE';

export const UPDATE_USER_CONFIG_REQUEST = 'settings/UPDATE_USER_CONFIG_REQUEST';
export const UPDATE_USER_CONFIG_SUCCESS = 'settings/UPDATE_USER_CONFIG_SUCCESS';
export const UPDATE_USER_CONFIG_FAILURE = 'settings/UPDATE_USER_CONFIG_FAILURE';

export const TOGGLE_SUGGESTIONS_ANALYSIS = 'settings/TOGGLE_SUGGESTIONS_ANALYSIS';

export const OPEN_UPLOAD_FILE_MANAGER = 'settings/OPEN_UPLOAD_FILE_MANAGER';
export const CLOSE_UPLOAD_FILE_MANAGER = 'settings/CLOSE_UPLOAD_FILE_MANAGER';
export const HAS_OPENED_UPLOAD_FILE_MANAGER_ONCE = 'settings/HAS_OPENED_UPLOAD_FILE_MANAGER_ONCE';

export const HAS_OPENED_DELETE_FILE_MANAGER_ONCE = 'settings/HAS_OPENED_DELETE_FILE_MANAGER_ONCE';

export const OPEN_EDITPAD = 'settings/OPEN_EDITPAD';
export const CLOSE_EDITPAD = 'settings/CLOSE_EDITPAD';

export const OPEN_INSIGHTSBOARD_SHARE_MENU = 'settings/OPEN_INSIGHTSBOARD_SHARE_MENU';
export const CLOSE_INSIGHTSBOARD_SHARE_MENU = 'settings/CLOSE_INSIGHTSBOARD_SHARE_MENU';

export const OPEN_SELECT_SAVE_CARD = 'settings/OPEN_SELECT_SAVE_CARD';
export const CLOSE_SELECT_SAVE_CARD = 'settings/CLOSE_SELECT_SAVE_CARD';

export const OPEN_INSIGHTS_BOARD_CREATOR = 'settings/OPEN_INSIGHTS_BOARD_CREATOR';
export const CLOSE_INSIGHTS_BOARD_CREATOR = 'settings/CLOSE_INSIGHTS_BOARD_CREATOR';

export const SET_HOME_MODE = 'settings/SET_HOME_MODE';

export const GET_USER_RECORD_REQUEST = 'settings/GET_USER_RECORD_REQUEST';
export const GET_USER_RECORD_SUCCESS = 'settings/GET_USER_RECORD_SUCCESS';
export const GET_USER_RECORD_FAILURE = 'settings/GET_USER_RECORD_FAILURE';

export const SAVE_USER_RECORD_REQUEST = 'settings/SAVE_USER_RECORD_REQUEST';
export const SAVE_USER_RECORD_SUCCESS = 'settings/SAVE_USER_RECORD_SUCCESS';
export const SAVE_USER_RECORD_FAILURE = 'settings/SAVE_USER_RECORD_FAILURE';

export const SHOW_NETWORK_STATUS = 'settings/SHOW_NETWORK_STATUS';
export const CLOSE_NETWORK_STATUS = 'settings/CLOSE_NETWORK_STATUS';

export const OPEN_SETTINGS_MENU = 'settings/OPEN_SETTINGS_MENU';
export const CHANGE_SETTINGS_VIEW = 'settings/CHANGE_SETTINGS_VIEW';
export const TRY_CHANGE_SETTINGS_VIEW = 'settings/TRY_CHANGE_SETTINGS_VIEW';
export const CLOSE_SETTINGS_MENU = 'settings/CLOSE_SETTINGS_MENU';
export const TRY_CLOSE_SETTINGS_MENU = 'settings/TRY_CLOSE_SETTINGS_MENU';

export const OPEN_USER_SELECTOR = 'settings/OPEN_USER_SELECTOR';
export const CLOSE_USER_SELECTOR = 'settings/CLOSE_USER_SELECTOR';
export const SET_ORGANIZATION_USERS_SELECT_STATE = 'settings/SET_ORGANIZATION_USERS_SELECT_STATE';
export const MODIFY_ORGANIZATION_USERS_SELECT_STATE =
  'settings/MODIFY_ORGANIZATION_USERS_SELECT_STATE';

export const OPEN_EDIT_EXISTING_DB_ALERT = 'settings/OPEN_EDIT_EXISTING_DB_ALERT';
export const OPEN_DB_ALERT_FAILURE = 'settings/OPEN_DB_ALERT_FAILURE';

export const SET_THEME = 'settings/SET_THEME';
export const OPEN_UTTERANCE_EXT_TIMEOUT_FORM = 'settings/OPEN_UTTERANCE_EXT_TIMEOUT_FORM';
export const CLOSE_UTTERANCE_EXT_TIMEOUT_FORM = 'settings/CLOSE_UTTERANCE_EXT_TIMEOUT_FORM';
export const OPEN_RENAME_EXISTING_DB_ALERT = 'settings/OPEN_RENAME_EXISTING_DB_ALERT';

export const SET_OFFICE_HOURS_MENU_VISIBILITY = 'settings/SET_OFFICE_HOURS_MENU_VISIBILITY';

/**
 * Opens the drop down menu.
 */
export const openHelpDropDownMenu = () => ({
  type: OPEN_HELP_DROP_DOWN_MENU,
});

/**
 * Closes the drop down menu.
 */
export const closeHelpDropDownMenu = () => ({
  type: CLOSE_HELP_DROP_DOWN_MENU,
});

/**
 * Tries to open the profile drop down menu.
 */
export const openProfileDropDownMenu = () => ({
  type: OPEN_PROFILE_DROP_DOWN_MENU,
});

/**
 * Tries to close the profile drop down menu.
 */
export const closeProfileDropDownMenu = () => ({
  type: CLOSE_PROFILE_DROP_DOWN_MENU,
});

/**
 * Dispatches a request to retrieve the UserConfig object.
 */
export const getUserConfigRequest = () => ({
  type: GET_USER_CONFIG_REQUEST,
});

/**
 * Indicates that the getUserConfig request has succeeded.
 *
 * @param {Object} config Data containing the user's configuration settings.
 */
export const getUserConfigSuccess = ({ config }) => ({
  type: GET_USER_CONFIG_SUCCESS,
  config,
});

/**
 * Indicates that the getUserConfig request has failed.
 *
 * @param {Object} error  Error
 */
export const getUserConfigFailure = ({ error }) => ({
  type: GET_USER_CONFIG_FAILURE,
  error,
});

export const updateUserConfigRequest = ({ config }) => ({
  type: UPDATE_USER_CONFIG_REQUEST,
  config,
});

export const updateUserConfigSuccess = ({ config }) => ({
  type: UPDATE_USER_CONFIG_SUCCESS,
  config,
});

export const updateUserConfigFailure = ({ error }) => ({
  type: UPDATE_USER_CONFIG_FAILURE,
  error,
});

/**
 * Decides if the response time for suggestions API call should be displayed or not.
 */
export const toggleSuggestionsAnalysis = () => ({
  type: TOGGLE_SUGGESTIONS_ANALYSIS,
});

/**
 * Dispatches an action that opens the file manager.
 */
export const openUploadFileManager = () => ({
  type: OPEN_UPLOAD_FILE_MANAGER,
});

/**
 * Dispatches an action that closes the file manager.
 */
export const closeUploadFileManager = () => ({
  type: CLOSE_UPLOAD_FILE_MANAGER,
});

/**
 * Dispatches an action that signifies that the upload file manager has already been opened once.
 */
export const hasOpenedUploadFileManagerOnce = () => ({
  type: HAS_OPENED_UPLOAD_FILE_MANAGER_ONCE,
});

export const openEditPad = () => ({
  type: OPEN_EDITPAD,
});

export const closeEditPad = () => ({
  type: CLOSE_EDITPAD,
});

/**
 * Dispatches an action that opens the Insights Board Share Menu
 */
export const openInsightsBoardShareMenu = () => ({
  type: OPEN_INSIGHTSBOARD_SHARE_MENU,
});

/**
 * Dispatches an action that closes the Insights Board Share Menu
 */
export const closeInsightsBoardShareMenu = () => ({
  type: CLOSE_INSIGHTSBOARD_SHARE_MENU,
});

/**
 * Dispatches an action that opens the modal for saving a trimmed workflow
 */
export const openSelectSaveCard = () => ({
  type: OPEN_SELECT_SAVE_CARD,
});

/**
 * Dispatches an action that closes the modal for saving a trimmed workflow
 */
export const closeSelectSaveCard = () => ({
  type: CLOSE_SELECT_SAVE_CARD,
});

/**
 * Dispatches an action that opens the InsightsBoard Creator
 */
export const openInsightsBoardCreator = () => ({
  type: OPEN_INSIGHTS_BOARD_CREATOR,
});

/**
 * Dispatches an action that closes the InsightsBoard Creator
 */
export const closeInsightsBoardCreator = () => ({
  type: CLOSE_INSIGHTS_BOARD_CREATOR,
});

/**
 * Dispatches an action that changes the mode of the homepage.
 *
 * @param {String} homeMode Mode of the homepage
 */
export const setHomeMode = (homeMode) => ({
  homeMode,
  type: SET_HOME_MODE,
});

/**
 * Dispatches an action that requests the record like phone number for a user
 */
export const getUserRecordRequest = () => ({
  type: GET_USER_RECORD_REQUEST,
});

/**
 * Dispatches an action on succesfully receiving the user's record like phone number
 */
export const getUserRecordSuccess = ({ phoneNumber, organizationID, organizationName }) => ({
  type: GET_USER_RECORD_SUCCESS,
  phoneNumber,
  organizationID,
  organizationName,
});

/**
 * Dispatches an action on failing to receiving the user's record like phone number
 */
export const getUserRecordFailure = ({ error }) => ({
  type: GET_USER_RECORD_FAILURE,
  error,
});

/**
 * Dispatches an action that attempts to save the user's record like phone number
 */
export const saveUserRecordRequest = ({ phoneNumber }) => ({
  type: SAVE_USER_RECORD_REQUEST,
  phoneNumber,
});

/**
 * Dispatches an action on succesfully saving the user's record like phone number
 */
export const saveUserRecordSuccess = () => ({
  type: SAVE_USER_RECORD_SUCCESS,
});

/**
 * Dispatches an action on failing saving the user's record like phone number
 */
export const saveUserRecordFailure = ({ error }) => ({
  type: SAVE_USER_RECORD_FAILURE,
  error,
});

export const showNetworkStatus = () => ({
  type: SHOW_NETWORK_STATUS,
});

export const closeNetworkStatus = () => ({
  type: CLOSE_NETWORK_STATUS,
});

/**
 * Dispatches an action that opens the settings menu.
 *
 * @param {String} view The selected setting view
 */
export const openSettingsMenu = (view) => ({
  type: OPEN_SETTINGS_MENU,
  view,
});

/**
 * Dispatches an action that changes the selected setting view.
 *
 * @param {String} view The selected setting view
 * @param {Number} connectionId Externally requested connection to edit
 */
export const changeSettingsView = (view, connectionId) => ({
  type: CHANGE_SETTINGS_VIEW,
  view,
  connectionId,
});

/**
 * Dispatches an action that attempts to close the settings menu if allowed.
 */
export const tryChangeSettingsView = (view, connectionId) => ({
  type: TRY_CHANGE_SETTINGS_VIEW,
  view,
  connectionId,
});

/**
 * Dispatches an action that closes the settings menu.
 */
export const closeSettingsMenu = () => ({
  type: CLOSE_SETTINGS_MENU,
});

/**
 * Dispatches an action that opens the user selector menu.
 */
export const openUserSelector = () => ({
  type: OPEN_USER_SELECTOR,
});

/**
 * Dispatches an action that closes the user selector menu.
 */
export const closeUserSelector = () => ({
  type: CLOSE_USER_SELECTOR,
});

/**
 * Dispatches an action that opens the user selector menu.
 */
export const setOrganizationUsersSelectState = (users) => ({
  type: SET_ORGANIZATION_USERS_SELECT_STATE,
  users,
});

/**
 *  Dispatches an action that removes a user from the list of users to notify.
 */
export const modifyOrganizationUsersSelectState = (email, selected) => ({
  type: MODIFY_ORGANIZATION_USERS_SELECT_STATE,
  email,
  selected,
});

/**
 * Dispatches an action that attempts to close the settings menu if allowed.
 */
export const tryCloseSettingsMenu = () => ({
  type: TRY_CLOSE_SETTINGS_MENU,
});

/**
 * Toggles an alert signalling the user is about to edit properties of an existing database.
 */
export const openEditExistingDatabaseAlert = (callbackObj) => ({
  type: OPEN_EDIT_EXISTING_DB_ALERT,
  fields: callbackObj.fields,
  values: callbackObj.values,
  postRequest: callbackObj.postRequest,
  actions: callbackObj.actions,
});

export const openRenameExistingDatabaseAlert = (callbackObj) => ({
  type: OPEN_RENAME_EXISTING_DB_ALERT,
  fields: callbackObj.fields,
  values: callbackObj.values,
  handleOnSubmit: callbackObj.handleOnSubmit,
  actions: callbackObj.actions,
  newConName: callbackObj.newConName,
});

export const openDatabaseAlertFailure = (callbackObj) => ({
  type: OPEN_DB_ALERT_FAILURE,
  error: callbackObj.error,
});

export const setTheme = ({ theme }) => ({
  type: SET_THEME,
  theme,
});

export const openUtteranceTimeoutExtensionForm = (message, autoInterruptTime) => ({
  type: OPEN_UTTERANCE_EXT_TIMEOUT_FORM,
  message,
  autoInterruptTime,
});

export const closeUtteranceTimeoutExtensionForm = () => ({
  type: CLOSE_UTTERANCE_EXT_TIMEOUT_FORM,
});

export const setOfficeHoursMenuVisibility = ({ open }) => ({
  type: SET_OFFICE_HOURS_MENU_VISIBILITY,
  open,
});
