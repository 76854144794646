import { PayloadAction } from '@reduxjs/toolkit';
import { HomeObjectKeysTypes } from '../utils/homeScreen/types';

export enum RequestStatus {
  Success = 'Success',
  Failure = 'Failure',
  Requesting = 'Requesting',
  Unrequested = 'Unrequested',
  RequestingMore = 'RequestingMore',
  Refresh = 'Refreshing',
}

export type BrowserColumn = {
  name: string;
  isNullable: boolean;
  type: string;
};

/**
 * Represents the state of a table in the database
 */
export type BrowserTable = {
  /** The name of the table */
  name: string;
  /** A list of columns in the table */
  columns: BrowserColumn[] | null;
  /** A list of rows in the table */
  rows: any[][] | null;
  /** The status of the request to get row/column metadata */
  metadataStatus: RequestStatus;
  /** The last time the metadata was updated */
  lastUpdated: string | null;
  /** The error message from the metadata request */
  errorMessage: string | null;
};

/**
 * Represents a namespace within a database
 */
export type Namespace = {
  /** The tables in the namespace */
  tables: { [tableName: string]: BrowserTable };
  /** The status of the request to get the tables */
  requestingTablesStatus: RequestStatus;
  /** The total number of tables in the namespace */
  totalTableCount: number;
  /** The last time that the namespace's tables were updated */
  lastUpdated: string | null;
  /** the error message from the list tables request */
  errorMessage: string | null;
};

/**
 * Represents the state of a database
 */
export type Database = {
  /** The namespaces in the database */
  namespaces: { [namespace: string]: Namespace };
  /** The tables that are selected by namespace */
  selectedTables: { [namespace: string]: string[] };
  /** Keeps track of each table the user has selected to preview */
  selectedPreviewTables: { [namespace: string]: string[] };
  /** The current table that the user is previewing */
  selectedPreview: { namespace: string; table: string } | null;
  /** The total number of namespaces in the database */
  totalNamespaceCount: number;
  /** The status of the request to get the list of namespaces */
  requestingNamespacesStatus: RequestStatus;
  /** Last time the database's list of namespaces was updated */
  lastUpdated: string | null;
  /** The error message from the list namespaces request */
  errorMessage: string | null;
  /** The term to filter tables by */
  filterTerm: string;
};

export type Databases = { [connectionUUID: string]: Database };
/** Object mapping Homescreen ObjectID to Connection Object */
export type Connections = { [connectionUUID: string]: HomeObjectKeysTypes };

/**
 * Represents the state of the database browser
 */
export type DatabaseBrowserState = {
  /** Whether the database browser is open */
  open: boolean;
  /** The connection that is currently open */
  openConnection: HomeObjectKeysTypes | null;
  /** Inforamation for each database by name */
  databases: Databases;
  /** List of Connection home screen objects */
  connections: Connections;
  /** Status of the request to get the connection list */
  connectionsRequestStatus: RequestStatus;
  /** Status of the request to create a dataset */
  creatingDatasetRequestStatus: RequestStatus;
  /** List of datasets that the application failed to import from the database */
  failedDatasetCreation: string[];
  /** Whether or not the left navigation panel is hidden */
  hideNavigation: boolean;
};

/** ========= Action Payloads ========= */
export type OpenDatabaseBrowserPayload = PayloadAction<{
  /** The connection that is being opened */
  connection: HomeObjectKeysTypes | null;
  /** Whether to show the left navigation panel or not */
  hideNavigation?: boolean;
}>;

export type SetConnectionPayload = PayloadAction<{
  /** The connection to open in the database browser */
  connection?: HomeObjectKeysTypes | null;
}>;
export type ConnectionListSuccessPayload = PayloadAction<{
  /** A object whose values are each one of the user's home screen connection objects  */
  connections: Connections;
}>;
export type ConnectionListFailurePayload = PayloadAction<{ error: string }>;

export type CreateDatasetsRequestPayload = PayloadAction<{
  /** Whether or not we want to open the datasets in a session after creating them */
  openInSession: boolean;
}>;
export type CreateDatasetsCompletePayload = PayloadAction<{
  /** The datasets that we failed to create */
  failedDatasets: string[];
  /** The datasets that we were successfully able to create */
  successfulDatasets: { name: string; uuid: string }[];
}>;

export type TryModifySelectedEntryPayload = PayloadAction<{
  /** The namespace of the table */
  namespace: string;
  /** The table to add or remove from the selected tables */
  table?: string;
}>;
export type ModifySelectedTablePayload = PayloadAction<{
  /** The namespace of the table */
  namespace: string;
  /** The table to add or remove from the selected tables */
  table: string;
}>;
export type ModifySelectedFolderPayload = PayloadAction<{
  /** The namespace of the tables we want to select or deselect */
  namespace: string;
}>;

export type AddPreviewTablePayload = PayloadAction<{
  /** The namespace of the table */
  namespace: string;
  /** The table to add a preview of */
  table: string;
}>;
export type RefreshPreviewTablePayload = PayloadAction<{
  /** The namespace of the table */
  namespace: string;
  /** The table to remove a preview of */
  table: string;
}>;
export type RemovePreviewTablePayload = PayloadAction<{
  /** The namespace of the table */
  namespace: string;
  /** The table to remove a preview of */
  table: string;
}>;
export type SelectPreviewTablePayload = PayloadAction<{
  /** The namespace of the table */
  namespace: string;
  /** The table to display */
  table: string;
}>;

export type ListNamespacesRequestPayload = PayloadAction<{
  /** The home screen uuid of the database we are requesting namespaces for */
  connectionUUID: string;
  /** The page number of the request */
  page: number;
  /** If we would like to fetch from our cache or directly query they database */
  refresh: boolean;
}>;
export type ListNamespacesSuccessPayload = PayloadAction<{
  /** The home screen uuid of the database we successfully fetched namespaces for */
  connectionUUID: string;
  /** If we fetched from our cache or directly query they database */
  refresh: boolean;
  /** A page of namespaces */
  namespaces: string[];
  /** The date this data was last fetched from the source database */
  lastUpdated: string;
  /** The total number of namespaces in the database */
  totalNamespaceCount: number;
}>;
export type ListNamespacesFailurePayload = PayloadAction<{
  /** The home screen uuid of the database we are requesting namespaces for */
  connectionUUID: string;
  /** The reason the request failed */
  error: string;
}>;

export type ListNamespaceTablesRequestPayload = PayloadAction<{
  /** The home screen uuid of the database we are requesting namespaces for */
  connectionUUID: string;
  /** The namespace of the database we are requesting namespaces for */
  namespace: string;
  /** If we fetched from our cache or directly query the database */
  refresh: boolean;
  /** The page number of the request */
  page: number;
}>;
export type ListNamespaceTablesSuccessPayload = PayloadAction<{
  /** The home screen uuid of the database we successfully fetched tables for */
  connectionUUID: string;
  /** The namespace of the database we successfully fetched tables for */
  namespace: string;
  /** If we fetched from our cache or directly query the database */
  refresh: boolean;
  /** The date this data was last fetched from the source database */
  lastUpdated: string;
  /** A page of table names */
  tables: string[];
  /** The total number of tables in the namespace */
  totalTableCount: number;
}>;
export type ListNamespaceTablesFailurePayload = PayloadAction<{
  /** The home screen uuid of the database we failed to request tables for */
  connectionUUID: string;
  /** The namespace of the database we failed to fetched tables for */
  namespace: string;
  /** The reason the request failed */
  error: string;
}>;

export type GetBrowserTableMetadataRequestPayload = PayloadAction<{
  /** The home screen uuid of the database we are requesting metadata for */
  connectionUUID: string;
  /** The namespace of the table we are requesting metadata for */
  namespace: string;
  /** The table we are requesting metadata for */
  table: string;
  /** If we would like to fetch from our cache or directly query the database */
  refresh: boolean;
}>;
export type GetBrowserTableMetadataSuccessPayload = PayloadAction<{
  /** The home screen uuid of the table we successfully fetched table metadata for */
  connectionUUID: string;
  /** The namespace of the table we successfully fetched table metadata for */
  namespace: string;
  /** The table we successfully fetched table metadata for */
  table: string;
  /** If we fetched from our cache or directly queried the database */
  refresh: boolean;
  /** a sample of the table */
  rows: any[][];
  /** column metadata */
  columns: BrowserColumn[];
  /** The date this data was last fetched from the source database */
  lastUpdated: string;
}>;
export type GetBrowserTableMetadataFailurePayload = PayloadAction<{
  /** The home screen uuid of the table we failed to fetch metadata for */
  connectionUUID: string;
  /** The namespace of the table we failed to fetch metadata for */
  namespace: string;
  /** The table we failed to fetch metadata for */
  table: string;
  /** The reason the request failed */
  error: string;
}>;

export type SetDatabaseFilterTermPayload = PayloadAction<{
  /** The home screen uuid of the database to apply the filter to */
  connectionUUID: string;
  /** The term to filter tables by */
  filterTerm: string;
  /** The amount of time to delay the network request */
  delay?: number;
}>;
