import { Row as antRow, RowProps } from 'antd';
import styled, { css } from 'styled-components';

interface IRowProps extends RowProps {
  // add new props here
  height?: string | number;
}

const Row = styled(antRow)<IRowProps>`
  ${(props) =>
    props.height &&
    css`
      height: ${props.height === 'number' ? `${props.height}px` : props.height};
    `}
`;

export default Row;
