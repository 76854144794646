import GlobalStyles from '@mui/material/GlobalStyles';
import useTheme from '@mui/material/styles/useTheme';
import isNumber from 'lodash/isNumber';
import isObject from 'lodash/isObject';
import React from 'react';

/**
 * Creates an object mapping CSS variable names to MUI theme properties.
 * This allows our themes to be the source of truth for styling done in CSS.
 * This is passed to GlobalStyles so that any component can access it.
 * Many things are already exported for us by CssVarsProvider.
 * You can inspect any element in the browser to see these.
 *
 * All variables should be of the form --mui-palette-primary-main.
 * This corresponds to theme.palette.primary.main.
 */
export const CSSVarsFromMUI = () => {
  const theme = useTheme();
  const cssVars = {
    '--mui-typography-font-family': theme.typography.fontFamily,
    '--mui-spacing-factor': theme.spacing(1), // Will not be used directly, but in the spacing SCSS function
  };
  for (const [textElement, config] of Object.entries(theme.typography)) {
    if (!isObject(config)) continue;

    let { fontSize } = config;
    // MUI Default values are strings with 'rem' units.
    // But allows pixels as well, we convert to rem for consistency.
    if (isNumber(fontSize)) fontSize = theme.typography.pxToRem(fontSize);

    // For example: --mui-typography-h1-font-size
    // Look at the keys under typography here: https://mui.com/material-ui/customization/default-theme/
    const keyPrefix = `--mui-typography-${textElement}`;
    cssVars[`${keyPrefix}-font-size`] = fontSize;
    cssVars[`${keyPrefix}-font-weight`] = config.fontWeight;
  }
  return (
    <GlobalStyles
      styles={{
        ':root': cssVars,
      }}
    />
  );
};
