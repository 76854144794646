import Box from '@mui/material/Box';
import React from 'react';
import ConnectionEditor from '../../ConnectionEditor/ConnectionEditor';
import './DatabaseBrowserConnectionDetails.scss';

const DatabaseBrowserConnectionDetails = () => {
  return (
    <Box className="DatabaseBrowserConnectionDetails-Container">
      <Box className="ConnectionEditor-Container">
        <ConnectionEditor />
      </Box>
    </Box>
  );
};

export default DatabaseBrowserConnectionDetails;
