import { fork, put, take } from 'redux-saga/effects';
import { repositionExplorer } from '../actions/explorer.actions';
import { repositionReplayController } from '../actions/replay_controller.actions';
import { WINDOW_RESIZE } from '../actions/window.actions';

/**
 * Watcher that repositions draggable components after the window is resized
 */
function* windowResizeRepositionWatcher() {
  let prevWindowWidth = window.innerWidth;
  let prevWindowHeight = window.innerHeight;
  while (true) {
    const { windowWidth, windowHeight } = yield take(WINDOW_RESIZE);
    // Factors used for repositioning draggable components
    const xFactor = windowWidth / prevWindowWidth;
    const yFactor = windowHeight / prevWindowHeight;
    yield put(repositionReplayController(xFactor, yFactor));
    yield put(repositionExplorer(xFactor, yFactor));
    // Update previous sizes
    prevWindowWidth = windowWidth;
    prevWindowHeight = windowHeight;
  }
}

// Spins up the saga watchers
export default function* () {
  yield fork(windowResizeRepositionWatcher);
}
