import mapKeys from 'lodash/mapKeys';
import omit from 'lodash/omit';
import {
  CREATE_CREDENTIAL_FAILURE,
  CREATE_CREDENTIAL_REQUEST,
  CREATE_CREDENTIAL_SUCCESS,
  DELETE_CREDENTIAL_FAILURE,
  DELETE_CREDENTIAL_REQUEST,
  DELETE_CREDENTIAL_SUCCESS,
  GET_CREDENTIALS_FAILURE,
  GET_CREDENTIALS_REQUEST,
  GET_CREDENTIALS_SUCCESS,
  SET_CREDENTIALS_ACTION,
  SET_DEFAULT_CREDENTIAL_FAILURE,
  SET_DEFAULT_CREDENTIAL_REQUEST,
  SET_DEFAULT_CREDENTIAL_SUCCESS,
} from '../actions/credentials.action';

const initialState = {
  credentialsAction: null, // the current credential action being performed
  credentials: {}, // object containing all user credentials
  requesting: false, // true if FE is requesting management service
  actionCredentialId: null, // id of the credential in which the user is performing an action
  error: null, // keep track of error
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CREDENTIALS_REQUEST:
      return { ...state, requesting: true };
    case GET_CREDENTIALS_SUCCESS:
      return {
        ...state,
        requesting: false,
        credentials: { ...state.credentials, ...mapKeys(action.credentials, 'Id') },
      };
    case GET_CREDENTIALS_FAILURE:
      return { ...state, error: action.error, requesting: false };
    case CREATE_CREDENTIAL_REQUEST:
      return { ...state, requesting: true };
    case CREATE_CREDENTIAL_SUCCESS:
      return {
        ...state,
        requesting: false,
      };
    case CREATE_CREDENTIAL_FAILURE:
      return { ...state, requesting: false, error: action.error };
    case DELETE_CREDENTIAL_REQUEST:
      return { ...state, requesting: true };
    case DELETE_CREDENTIAL_SUCCESS:
      return {
        ...state,
        requesting: false,
        credentials: omit(state.credentials, action.credentialId),
      };
    case DELETE_CREDENTIAL_FAILURE:
      return { ...state, requesting: false, error: action.error };
    case SET_CREDENTIALS_ACTION:
      return {
        ...state,
        credentialsAction: action.actionType,
        actionCredentialId: action.objectId,
      };
    case SET_DEFAULT_CREDENTIAL_REQUEST:
      return { ...state, requesting: true };
    case SET_DEFAULT_CREDENTIAL_SUCCESS:
      return { ...state, requesting: false };
    case SET_DEFAULT_CREDENTIAL_FAILURE:
      return { ...state, error: action.error, requesting: false };

    default:
      return state;
  }
};
