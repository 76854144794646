import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  JSON: { input: any; output: any };
  UUID: { input: any; output: any };
};

export type ApiKey = BaseResult & {
  __typename?: 'ApiKey';
  error?: Maybe<Scalars['String']['output']>;
  items?: Maybe<Array<ApiKeyNode>>;
  paging?: Maybe<Paging>;
};

export type ApiKeyInput = {
  apiKey?: InputMaybe<Scalars['UUID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  requireToken?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ApiKeyNode = BaseNode & {
  __typename?: 'ApiKeyNode';
  apiKey: Scalars['UUID']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  requireToken: Scalars['Boolean']['output'];
  secret: Scalars['String']['output'];
  user: UserNode;
  userId: Scalars['ID']['output'];
};

export enum AuthType {
  Google = 'google',
  Password = 'password',
}

/** The "BaseNode" interface defines an interface implemented by return types: */
export type BaseNode = {
  /** A unique identifier for this record. Do not request 'id' if 'distinct' is 'true' */
  id: Scalars['ID']['output'];
};

/** Provides a common interface for return data. */
export type BaseResult = {
  /** A string error message returned by the API and may be displayed to the user by the client. This is separate from 'errors' which provides useful feedback during client development. */
  error?: Maybe<Scalars['String']['output']>;
  /** A list of returned objects that implement the BaseNode interface. */
  items?: Maybe<Array<BaseNode>>;
  /** Pagination metadata. (see Paging) */
  paging?: Maybe<Paging>;
};

export type EmailTokenNode = BaseNode & {
  __typename?: 'EmailTokenNode';
  activated?: Maybe<Scalars['String']['output']>;
  created: Scalars['String']['output'];
  email: Scalars['String']['output'];
  expires: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  token: Scalars['String']['output'];
  user?: Maybe<UserNode>;
};

export enum FeatureEnum {
  AdminPortal = 'admin_portal',
  ApiKeys = 'api_keys',
  CreateOrganizations = 'create_organizations',
  CudApiKeys = 'cud_api_keys',
  CudExternalOauthClients = 'cud_external_oauth_clients',
  CudPublications = 'cud_publications',
  CudUsers = 'cud_users',
  DeleteOrganization = 'delete_organization',
  GrantRoles = 'grant_roles',
  InsightBoards = 'insight_boards',
  Organizations = 'organizations',
  UpdateOrganization = 'update_organization',
  Users = 'users',
}

export type Features = {
  __typename?: 'Features';
  adminPortal: Scalars['Boolean']['output'];
  apiKeys: Scalars['Boolean']['output'];
  createOrganizations: Scalars['Boolean']['output'];
  cudApiKeys: Scalars['Boolean']['output'];
  cudExternalOauthClients: Scalars['Boolean']['output'];
  cudPublications: Scalars['Boolean']['output'];
  cudUsers: Scalars['Boolean']['output'];
  deleteOrganization: Scalars['Boolean']['output'];
  grantRoles?: Maybe<Array<Scalars['String']['output']>>;
  insightBoards: Scalars['Boolean']['output'];
  organizations: Scalars['Boolean']['output'];
  updateOrganization: Scalars['Boolean']['output'];
  users: Scalars['Boolean']['output'];
};

export type InsightsBoard = BaseResult & {
  __typename?: 'InsightsBoard';
  error?: Maybe<Scalars['String']['output']>;
  items?: Maybe<Array<InsightsBoardNode>>;
  paging?: Maybe<Paging>;
};

export type InsightsBoardNode = BaseNode & {
  __typename?: 'InsightsBoardNode';
  apiKey?: Maybe<ApiKeyNode>;
  apiKeyId?: Maybe<Scalars['ID']['output']>;
  dateCreated: Scalars['String']['output'];
  dateLastModified: Scalars['String']['output'];
  dateLastOpened: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  object?: Maybe<ObjectNode>;
  owner?: Maybe<UserNode>;
  ownerId?: Maybe<Scalars['ID']['output']>;
};

export type InvitationInput = {
  confirmPassword: Scalars['String']['input'];
  email: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
};

export type LoginPayload = {
  __typename?: 'LoginPayload';
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
  user?: Maybe<UserNode>;
};

export type LogoutPayload = {
  __typename?: 'LogoutPayload';
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Mutation for accepting the invitation based on token. */
  acceptInvitation: Scalars['Boolean']['output'];
  /**
   * Mutation for creating a new organization.
   * Will return the new organization details.
   * Only users with the 'create_organizations' feature can create the organization.
   */
  createOrganization: OrganizationNode;
  /**
   * Mutation for inviting new users to the organization via email.
   * Will return the list of invited users.
   * Only users with the 'cud_users' feature can invite new users.
   */
  createUsers: Array<UserNode>;
  /**
   * Mutation for removing the API key.
   * Will return true if the API key is removed successfully.
   * Only users with the 'cud_api_keys' feature can remove the API key.
   */
  removeApiKey: Scalars['Boolean']['output'];
  /**
   * Mutation for removing the organization.
   * Will return true if the organization is removed successfully.
   * Only users with the 'create_organizations' feature can remove the organization.
   */
  removeOrganization: Scalars['Boolean']['output'];
  /**
   * Mutation for removing the user.
   * Will return true if the user is removed successfully.
   * Only users with the 'cud_users' feature can remove the user.
   */
  removeUser: Scalars['Boolean']['output'];
  /**
   * Mutation for sharing the insights board with other users, closing the existing access or making it public.
   * Will return the insights board.
   * Only users with the 'cud_publications' feature can share the insights board.
   */
  shareInsightsBoard: InsightsBoardNode;
  /**
   * Mutation for transferring ownership of a user's data to another user.
   * Will return the new user.
   * Only users with the 'cud_users' feature can transfer the data.
   */
  transferUser: UserNode;
  /**
   * Mutation for updating the API key details.
   * Will return the updated API key.
   * Only users with the 'cud_api_keys' feature can update the API key.
   */
  updateApiKey: ApiKeyNode;
  /**
   * Mutation for updating organization details.
   * Will return the updated user.
   * Only users with the 'ud_organization' feature can update the organization.
   */
  updateOrganization: OrganizationNode;
  /**
   * Mutation for updating the user details.
   * Will return the updated user.
   * Only users with the 'cud_users' feature can update the user.
   */
  updateUser: UserNode;
};

export type MutationAcceptInvitationArgs = {
  input: InvitationInput;
  token: Scalars['String']['input'];
};

export type MutationCreateOrganizationArgs = {
  input: OrganizationNodeInput;
};

export type MutationCreateUsersArgs = {
  emails: Array<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  role: Scalars['String']['input'];
};

export type MutationRemoveApiKeyArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRemoveOrganizationArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRemoveUserArgs = {
  id: Scalars['ID']['input'];
};

export type MutationShareInsightsBoardArgs = {
  emails: Array<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  requireToken: Scalars['Boolean']['input'];
};

export type MutationTransferUserArgs = {
  newId: Scalars['ID']['input'];
  oldId: Scalars['ID']['input'];
};

export type MutationUpdateApiKeyArgs = {
  id: Scalars['ID']['input'];
  input: ApiKeyInput;
};

export type MutationUpdateOrganizationArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  input: OrganizationNodeInput;
};

export type MutationUpdateUserArgs = {
  id: Scalars['ID']['input'];
  input: UserNodeInput;
};

export type ObjectAccessNode = BaseNode & {
  __typename?: 'ObjectAccessNode';
  id: Scalars['ID']['output'];
  objectId: Scalars['ID']['output'];
  type: Scalars['String']['output'];
  user?: Maybe<UserNode>;
  userId?: Maybe<Scalars['ID']['output']>;
  visibility?: Maybe<Scalars['String']['output']>;
};

export type ObjectNode = BaseNode & {
  __typename?: 'ObjectNode';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  objectAccess?: Maybe<Array<ObjectAccessNode>>;
};

export type Organization = BaseResult & {
  __typename?: 'Organization';
  error?: Maybe<Scalars['String']['output']>;
  items?: Maybe<Array<OrganizationNode>>;
  paging?: Maybe<Paging>;
};

export type OrganizationNode = BaseNode & {
  __typename?: 'OrganizationNode';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  userLimit?: Maybe<Scalars['Int']['output']>;
  users?: Maybe<Array<UserNode>>;
};

export type OrganizationNodeInput = {
  name: Scalars['String']['input'];
  userLimit?: InputMaybe<Scalars['Int']['input']>;
};

/** Pagination metadata returned with each paginated request. */
export type Paging = {
  __typename?: 'Paging';
  /** The number of requested records per page. */
  limit?: Maybe<Scalars['Int']['output']>;
  /** The page number returned. */
  page?: Maybe<Scalars['Int']['output']>;
  /** The total number of records available matching the given request. */
  total?: Maybe<Scalars['Int']['output']>;
};

/** An object containing pagination request values. */
export type PagingInput = {
  /** The number or records requested. */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** The page number requested. */
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type Query = {
  __typename?: 'Query';
  /**
   * The "api_key" query
   * Will return the api key with the given id.
   * Only users with api_keys feature can access this query.
   */
  apiKey: ApiKeyNode;
  /**
   * The "api_keys" query
   * Will return the paginated list of api keys based on given organization or user.
   * Only users with api_keys feature can access this query.
   */
  apiKeys: ApiKey;
  emailToken: EmailTokenNode;
  /**
   * The "insights_board" query
   * Will return the insights board with the given id.
   * Only users with insight_boards feature can access this query.
   */
  insightsBoard: InsightsBoardNode;
  /**
   * The "insights_boards" query
   * Will return the paginated list of insights boards based on given organization or user.
   * Only users with insight_boards feature can access this query.
   */
  insightsBoards: InsightsBoard;
  /**
   * The "me" query
   * Will return the currently logged in user.
   */
  me: UserNode;
  /**
   * The "organization" query
   * Will return the organization with the given id.
   * Only users with the update_organization feature can access this query.
   */
  organization: OrganizationNode;
  /**
   * The "organizations" query
   * Will return the paginated list of organizations based on the given filters.
   * Only users with the organizations feature can access this query.
   */
  organizations: Organization;
  /**
   * The "user" query
   * Will return the user with the given id.
   * Only users with the users feature can access this query.
   */
  user: UserNode;
  /**
   * The "users" query
   * If no arguments are passed, this will return all users.
   * If any arguments are passed, this will return the paginated list of users based on the given filters.
   * Only users with the users feature can access this query.
   */
  users: User;
};

export type QueryApiKeyArgs = {
  id: Scalars['ID']['input'];
};

export type QueryApiKeysArgs = {
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  paging?: InputMaybe<PagingInput>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryEmailTokenArgs = {
  token: Scalars['String']['input'];
};

export type QueryInsightsBoardArgs = {
  id: Scalars['ID']['input'];
};

export type QueryInsightsBoardsArgs = {
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  paging?: InputMaybe<PagingInput>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryOrganizationArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryOrganizationsArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
  paging?: InputMaybe<PagingInput>;
};

export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};

export type QueryUsersArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  paging?: InputMaybe<PagingInput>;
};

export type RoleNode = BaseNode & {
  __typename?: 'RoleNode';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type User = BaseResult & {
  __typename?: 'User';
  error?: Maybe<Scalars['String']['output']>;
  items?: Maybe<Array<UserNode>>;
  paging?: Maybe<Paging>;
};

export type UserNode = BaseNode & {
  __typename?: 'UserNode';
  authType: AuthType;
  email: Scalars['String']['output'];
  emailVerified: Scalars['Boolean']['output'];
  features?: Maybe<Features>;
  id: Scalars['ID']['output'];
  isServiceAccount: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  organization?: Maybe<OrganizationNode>;
  organizationId?: Maybe<Scalars['ID']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  profilePictureFileName?: Maybe<Scalars['String']['output']>;
  registeredAt?: Maybe<Scalars['String']['output']>;
  role: RoleNode;
  roleId: Scalars['ID']['output'];
};

export type UserNodeInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
};

export type ApiKeyAttributesFragment = {
  __typename?: 'ApiKeyNode';
  id: string;
  name: string;
  apiKey: any;
  requireToken: boolean;
  user: {
    __typename?: 'UserNode';
    id: string;
    email: string;
    name: string;
    phoneNumber?: string | null;
    profilePictureFileName?: string | null;
    registeredAt?: string | null;
    emailVerified: boolean;
    role: { __typename?: 'RoleNode'; id: string; name: string };
  };
};

export type OrganizationAttributesFragment = {
  __typename?: 'OrganizationNode';
  id: string;
  name: string;
  userLimit?: number | null;
};

export type PublicationAccessAttributesFragment = {
  __typename?: 'ObjectAccessNode';
  id: string;
  type: string;
  visibility?: string | null;
  user?: {
    __typename?: 'UserNode';
    id: string;
    email: string;
    name: string;
    phoneNumber?: string | null;
    profilePictureFileName?: string | null;
    registeredAt?: string | null;
    emailVerified: boolean;
    role: { __typename?: 'RoleNode'; id: string; name: string };
  } | null;
};

export type InsightsBoardAttributesFragment = {
  __typename?: 'InsightsBoardNode';
  id: string;
  name: string;
  createdAt: string;
  apiKey?: {
    __typename?: 'ApiKeyNode';
    id: string;
    name: string;
    apiKey: any;
    requireToken: boolean;
  } | null;
  owner?: {
    __typename?: 'UserNode';
    id: string;
    email: string;
    name: string;
    phoneNumber?: string | null;
    profilePictureFileName?: string | null;
    registeredAt?: string | null;
    emailVerified: boolean;
    role: { __typename?: 'RoleNode'; id: string; name: string };
  } | null;
  object?: {
    __typename?: 'ObjectNode';
    objectAccess?: Array<{
      __typename?: 'ObjectAccessNode';
      id: string;
      type: string;
      visibility?: string | null;
      user?: {
        __typename?: 'UserNode';
        id: string;
        email: string;
        name: string;
        phoneNumber?: string | null;
        profilePictureFileName?: string | null;
        registeredAt?: string | null;
        emailVerified: boolean;
        role: { __typename?: 'RoleNode'; id: string; name: string };
      } | null;
    }> | null;
  } | null;
};

export type UserAttributesFragment = {
  __typename?: 'UserNode';
  id: string;
  email: string;
  name: string;
  phoneNumber?: string | null;
  profilePictureFileName?: string | null;
  registeredAt?: string | null;
  emailVerified: boolean;
  role: { __typename?: 'RoleNode'; id: string; name: string };
};

export type UserAttributesWithOrgFragment = {
  __typename?: 'UserNode';
  id: string;
  email: string;
  name: string;
  phoneNumber?: string | null;
  profilePictureFileName?: string | null;
  registeredAt?: string | null;
  emailVerified: boolean;
  organization?: { __typename?: 'OrganizationNode'; id: string; name: string } | null;
  role: { __typename?: 'RoleNode'; id: string; name: string };
};

export type FeatureAttributesFragment = {
  __typename?: 'Features';
  adminPortal: boolean;
  organizations: boolean;
  users: boolean;
  insightBoards: boolean;
  apiKeys: boolean;
  createOrganizations: boolean;
  updateOrganization: boolean;
  deleteOrganization: boolean;
  cudUsers: boolean;
  cudPublications: boolean;
  cudApiKeys: boolean;
  grantRoles?: Array<string> | null;
  cudExternalOauthClients: boolean;
};

export type AuthUserAttributesFragment = {
  __typename?: 'UserNode';
  id: string;
  email: string;
  name: string;
  organizationId?: string | null;
  phoneNumber?: string | null;
  profilePictureFileName?: string | null;
  registeredAt?: string | null;
  emailVerified: boolean;
  role: { __typename?: 'RoleNode'; id: string; name: string };
  features?: {
    __typename?: 'Features';
    adminPortal: boolean;
    organizations: boolean;
    users: boolean;
    insightBoards: boolean;
    apiKeys: boolean;
    createOrganizations: boolean;
    updateOrganization: boolean;
    deleteOrganization: boolean;
    cudUsers: boolean;
    cudPublications: boolean;
    cudApiKeys: boolean;
    grantRoles?: Array<string> | null;
    cudExternalOauthClients: boolean;
  } | null;
};

export type EmailTokenAttributesFragment = {
  __typename?: 'EmailTokenNode';
  id: string;
  email: string;
  token: string;
  created: string;
  expires: string;
  activated?: string | null;
  user?: {
    __typename?: 'UserNode';
    id: string;
    email: string;
    name: string;
    phoneNumber?: string | null;
    profilePictureFileName?: string | null;
    registeredAt?: string | null;
    emailVerified: boolean;
    organization?: { __typename?: 'OrganizationNode'; id: string; name: string } | null;
    role: { __typename?: 'RoleNode'; id: string; name: string };
  } | null;
};

export type UpdateApiKeyMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: ApiKeyInput;
}>;

export type UpdateApiKeyMutation = {
  __typename?: 'Mutation';
  updateApiKey: {
    __typename?: 'ApiKeyNode';
    id: string;
    name: string;
    apiKey: any;
    requireToken: boolean;
    user: {
      __typename?: 'UserNode';
      id: string;
      email: string;
      name: string;
      phoneNumber?: string | null;
      profilePictureFileName?: string | null;
      registeredAt?: string | null;
      emailVerified: boolean;
      role: { __typename?: 'RoleNode'; id: string; name: string };
    };
  };
};

export type DeleteApiKeyMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteApiKeyMutation = { __typename?: 'Mutation'; removeApiKey: boolean };

export type CreateOrganizationMutationVariables = Exact<{
  input: OrganizationNodeInput;
}>;

export type CreateOrganizationMutation = {
  __typename?: 'Mutation';
  createOrganization: {
    __typename?: 'OrganizationNode';
    id: string;
    name: string;
    userLimit?: number | null;
  };
};

export type UpdateOrganizationMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: OrganizationNodeInput;
}>;

export type UpdateOrganizationMutation = {
  __typename?: 'Mutation';
  updateOrganization: {
    __typename?: 'OrganizationNode';
    id: string;
    name: string;
    userLimit?: number | null;
  };
};

export type DeleteOrganizationMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteOrganizationMutation = { __typename?: 'Mutation'; removeOrganization: boolean };

export type ShareInsightsBoardMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  requireToken: Scalars['Boolean']['input'];
  emails: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type ShareInsightsBoardMutation = {
  __typename?: 'Mutation';
  shareInsightsBoard: {
    __typename?: 'InsightsBoardNode';
    id: string;
    name: string;
    createdAt: string;
    apiKey?: {
      __typename?: 'ApiKeyNode';
      id: string;
      name: string;
      apiKey: any;
      requireToken: boolean;
    } | null;
    owner?: {
      __typename?: 'UserNode';
      id: string;
      email: string;
      name: string;
      phoneNumber?: string | null;
      profilePictureFileName?: string | null;
      registeredAt?: string | null;
      emailVerified: boolean;
      role: { __typename?: 'RoleNode'; id: string; name: string };
    } | null;
    object?: {
      __typename?: 'ObjectNode';
      objectAccess?: Array<{
        __typename?: 'ObjectAccessNode';
        id: string;
        type: string;
        visibility?: string | null;
        user?: {
          __typename?: 'UserNode';
          id: string;
          email: string;
          name: string;
          phoneNumber?: string | null;
          profilePictureFileName?: string | null;
          registeredAt?: string | null;
          emailVerified: boolean;
          role: { __typename?: 'RoleNode'; id: string; name: string };
        } | null;
      }> | null;
    } | null;
  };
};

export type CreateOrganizationUsersMutationVariables = Exact<{
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  emails: Array<Scalars['String']['input']> | Scalars['String']['input'];
  role: Scalars['String']['input'];
}>;

export type CreateOrganizationUsersMutation = {
  __typename?: 'Mutation';
  createUsers: Array<{
    __typename?: 'UserNode';
    id: string;
    email: string;
    name: string;
    phoneNumber?: string | null;
    profilePictureFileName?: string | null;
    registeredAt?: string | null;
    emailVerified: boolean;
    organization?: { __typename?: 'OrganizationNode'; id: string; name: string } | null;
    role: { __typename?: 'RoleNode'; id: string; name: string };
  }>;
};

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UserNodeInput;
}>;

export type UpdateUserMutation = {
  __typename?: 'Mutation';
  updateUser: {
    __typename?: 'UserNode';
    id: string;
    email: string;
    name: string;
    phoneNumber?: string | null;
    profilePictureFileName?: string | null;
    registeredAt?: string | null;
    emailVerified: boolean;
    organization?: { __typename?: 'OrganizationNode'; id: string; name: string } | null;
    role: { __typename?: 'RoleNode'; id: string; name: string };
  };
};

export type CreateUsersMutationVariables = Exact<{
  emails: Array<Scalars['String']['input']> | Scalars['String']['input'];
  role: Scalars['String']['input'];
}>;

export type CreateUsersMutation = {
  __typename?: 'Mutation';
  createUsers: Array<{
    __typename?: 'UserNode';
    id: string;
    email: string;
    name: string;
    phoneNumber?: string | null;
    profilePictureFileName?: string | null;
    registeredAt?: string | null;
    emailVerified: boolean;
    organization?: { __typename?: 'OrganizationNode'; id: string; name: string } | null;
    role: { __typename?: 'RoleNode'; id: string; name: string };
  }>;
};

export type TransferUserMutationVariables = Exact<{
  oldId: Scalars['ID']['input'];
  newId: Scalars['ID']['input'];
}>;

export type TransferUserMutation = {
  __typename?: 'Mutation';
  transferUser: {
    __typename?: 'UserNode';
    id: string;
    email: string;
    name: string;
    phoneNumber?: string | null;
    profilePictureFileName?: string | null;
    registeredAt?: string | null;
    emailVerified: boolean;
    organization?: { __typename?: 'OrganizationNode'; id: string; name: string } | null;
    role: { __typename?: 'RoleNode'; id: string; name: string };
  };
};

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteUserMutation = { __typename?: 'Mutation'; removeUser: boolean };

export type AcceptInvitationMutationVariables = Exact<{
  token: Scalars['String']['input'];
  input: InvitationInput;
}>;

export type AcceptInvitationMutation = { __typename?: 'Mutation'; acceptInvitation: boolean };

export type ListApiKeysQueryVariables = Exact<{
  paging: PagingInput;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type ListApiKeysQuery = {
  __typename?: 'Query';
  apiKeys: {
    __typename?: 'ApiKey';
    error?: string | null;
    paging?: { __typename?: 'Paging'; total?: number | null } | null;
    items?: Array<{
      __typename?: 'ApiKeyNode';
      id: string;
      name: string;
      apiKey: any;
      requireToken: boolean;
      user: {
        __typename?: 'UserNode';
        id: string;
        email: string;
        name: string;
        phoneNumber?: string | null;
        profilePictureFileName?: string | null;
        registeredAt?: string | null;
        emailVerified: boolean;
        role: { __typename?: 'RoleNode'; id: string; name: string };
      };
    }> | null;
  };
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'UserNode';
    id: string;
    email: string;
    name: string;
    organizationId?: string | null;
    phoneNumber?: string | null;
    profilePictureFileName?: string | null;
    registeredAt?: string | null;
    emailVerified: boolean;
    role: { __typename?: 'RoleNode'; id: string; name: string };
    features?: {
      __typename?: 'Features';
      adminPortal: boolean;
      organizations: boolean;
      users: boolean;
      insightBoards: boolean;
      apiKeys: boolean;
      createOrganizations: boolean;
      updateOrganization: boolean;
      deleteOrganization: boolean;
      cudUsers: boolean;
      cudPublications: boolean;
      cudApiKeys: boolean;
      grantRoles?: Array<string> | null;
      cudExternalOauthClients: boolean;
    } | null;
  };
};

export type ListOrganizationsQueryVariables = Exact<{
  paging: PagingInput;
  name?: InputMaybe<Scalars['String']['input']>;
}>;

export type ListOrganizationsQuery = {
  __typename?: 'Query';
  organizations: {
    __typename?: 'Organization';
    error?: string | null;
    paging?: { __typename?: 'Paging'; total?: number | null } | null;
    items?: Array<{
      __typename?: 'OrganizationNode';
      id: string;
      name: string;
      userLimit?: number | null;
    }> | null;
  };
};

export type GetOrganizationQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetOrganizationQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'OrganizationNode';
    id: string;
    name: string;
    userLimit?: number | null;
  };
};

export type GetOrganizationNameQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetOrganizationNameQuery = {
  __typename?: 'Query';
  organization: { __typename?: 'OrganizationNode'; id: string; name: string };
};

export type ListPublicationsQueryVariables = Exact<{
  paging: PagingInput;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type ListPublicationsQuery = {
  __typename?: 'Query';
  insightsBoards: {
    __typename?: 'InsightsBoard';
    error?: string | null;
    paging?: { __typename?: 'Paging'; total?: number | null } | null;
    items?: Array<{
      __typename?: 'InsightsBoardNode';
      id: string;
      name: string;
      createdAt: string;
      apiKey?: {
        __typename?: 'ApiKeyNode';
        id: string;
        name: string;
        apiKey: any;
        requireToken: boolean;
      } | null;
      owner?: {
        __typename?: 'UserNode';
        id: string;
        email: string;
        name: string;
        phoneNumber?: string | null;
        profilePictureFileName?: string | null;
        registeredAt?: string | null;
        emailVerified: boolean;
        role: { __typename?: 'RoleNode'; id: string; name: string };
      } | null;
      object?: {
        __typename?: 'ObjectNode';
        objectAccess?: Array<{
          __typename?: 'ObjectAccessNode';
          id: string;
          type: string;
          visibility?: string | null;
          user?: {
            __typename?: 'UserNode';
            id: string;
            email: string;
            name: string;
            phoneNumber?: string | null;
            profilePictureFileName?: string | null;
            registeredAt?: string | null;
            emailVerified: boolean;
            role: { __typename?: 'RoleNode'; id: string; name: string };
          } | null;
        }> | null;
      } | null;
    }> | null;
  };
};

export type ListUsersQueryVariables = Exact<{
  paging: PagingInput;
  name?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type ListUsersQuery = {
  __typename?: 'Query';
  users: {
    __typename?: 'User';
    error?: string | null;
    paging?: { __typename?: 'Paging'; total?: number | null } | null;
    items?: Array<{
      __typename?: 'UserNode';
      id: string;
      email: string;
      name: string;
      phoneNumber?: string | null;
      profilePictureFileName?: string | null;
      registeredAt?: string | null;
      emailVerified: boolean;
      organization?: { __typename?: 'OrganizationNode'; id: string; name: string } | null;
      role: { __typename?: 'RoleNode'; id: string; name: string };
    }> | null;
  };
};

export type GetUserQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetUserQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'UserNode';
    id: string;
    email: string;
    name: string;
    phoneNumber?: string | null;
    profilePictureFileName?: string | null;
    registeredAt?: string | null;
    emailVerified: boolean;
    organization?: { __typename?: 'OrganizationNode'; id: string; name: string } | null;
    role: { __typename?: 'RoleNode'; id: string; name: string };
  };
};

export type GetEmailTokenQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;

export type GetEmailTokenQuery = {
  __typename?: 'Query';
  emailToken: {
    __typename?: 'EmailTokenNode';
    id: string;
    email: string;
    token: string;
    created: string;
    expires: string;
    activated?: string | null;
    user?: {
      __typename?: 'UserNode';
      id: string;
      email: string;
      name: string;
      phoneNumber?: string | null;
      profilePictureFileName?: string | null;
      registeredAt?: string | null;
      emailVerified: boolean;
      organization?: { __typename?: 'OrganizationNode'; id: string; name: string } | null;
      role: { __typename?: 'RoleNode'; id: string; name: string };
    } | null;
  };
};

export const UserAttributesFragmentDoc = gql`
  fragment UserAttributes on UserNode {
    id
    email
    name
    role {
      id
      name
    }
    phoneNumber
    profilePictureFileName
    registeredAt
    emailVerified
  }
`;
export const ApiKeyAttributesFragmentDoc = gql`
  fragment ApiKeyAttributes on ApiKeyNode {
    id
    name
    apiKey
    user {
      ...UserAttributes
    }
    requireToken
  }
  ${UserAttributesFragmentDoc}
`;
export const OrganizationAttributesFragmentDoc = gql`
  fragment OrganizationAttributes on OrganizationNode {
    id
    name
    userLimit
  }
`;
export const PublicationAccessAttributesFragmentDoc = gql`
  fragment PublicationAccessAttributes on ObjectAccessNode {
    id
    type
    visibility
    user {
      ...UserAttributes
    }
  }
  ${UserAttributesFragmentDoc}
`;
export const InsightsBoardAttributesFragmentDoc = gql`
  fragment InsightsBoardAttributes on InsightsBoardNode {
    id
    name
    createdAt: dateCreated
    apiKey {
      id
      name
      apiKey
      requireToken
    }
    owner {
      ...UserAttributes
    }
    object {
      objectAccess {
        ...PublicationAccessAttributes
      }
    }
  }
  ${UserAttributesFragmentDoc}
  ${PublicationAccessAttributesFragmentDoc}
`;
export const FeatureAttributesFragmentDoc = gql`
  fragment FeatureAttributes on Features {
    adminPortal
    organizations
    users
    insightBoards
    apiKeys
    createOrganizations
    updateOrganization
    deleteOrganization
    cudUsers
    cudPublications
    cudApiKeys
    grantRoles
    cudExternalOauthClients
  }
`;
export const AuthUserAttributesFragmentDoc = gql`
  fragment AuthUserAttributes on UserNode {
    id
    email
    name
    role {
      id
      name
    }
    organizationId
    phoneNumber
    profilePictureFileName
    registeredAt
    emailVerified
    features {
      ...FeatureAttributes
    }
  }
  ${FeatureAttributesFragmentDoc}
`;
export const UserAttributesWithOrgFragmentDoc = gql`
  fragment UserAttributesWithOrg on UserNode {
    ...UserAttributes
    organization {
      id
      name
    }
  }
  ${UserAttributesFragmentDoc}
`;
export const EmailTokenAttributesFragmentDoc = gql`
  fragment EmailTokenAttributes on EmailTokenNode {
    id
    email
    token
    created
    expires
    activated
    user {
      ...UserAttributesWithOrg
    }
  }
  ${UserAttributesWithOrgFragmentDoc}
`;
export const UpdateApiKeyDocument = gql`
  mutation UpdateApiKey($id: ID!, $input: ApiKeyInput!) {
    updateApiKey(id: $id, input: $input) {
      ...ApiKeyAttributes
    }
  }
  ${ApiKeyAttributesFragmentDoc}
`;
export type UpdateApiKeyMutationFn = Apollo.MutationFunction<
  UpdateApiKeyMutation,
  UpdateApiKeyMutationVariables
>;

/**
 * __useUpdateApiKeyMutation__
 *
 * To run a mutation, you first call `useUpdateApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApiKeyMutation, { data, loading, error }] = useUpdateApiKeyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApiKeyMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateApiKeyMutation, UpdateApiKeyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateApiKeyMutation, UpdateApiKeyMutationVariables>(
    UpdateApiKeyDocument,
    options,
  );
}
export type UpdateApiKeyMutationHookResult = ReturnType<typeof useUpdateApiKeyMutation>;
export type UpdateApiKeyMutationResult = Apollo.MutationResult<UpdateApiKeyMutation>;
export type UpdateApiKeyMutationOptions = Apollo.BaseMutationOptions<
  UpdateApiKeyMutation,
  UpdateApiKeyMutationVariables
>;
export const DeleteApiKeyDocument = gql`
  mutation DeleteApiKey($id: ID!) {
    removeApiKey(id: $id)
  }
`;
export type DeleteApiKeyMutationFn = Apollo.MutationFunction<
  DeleteApiKeyMutation,
  DeleteApiKeyMutationVariables
>;

/**
 * __useDeleteApiKeyMutation__
 *
 * To run a mutation, you first call `useDeleteApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApiKeyMutation, { data, loading, error }] = useDeleteApiKeyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteApiKeyMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteApiKeyMutation, DeleteApiKeyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteApiKeyMutation, DeleteApiKeyMutationVariables>(
    DeleteApiKeyDocument,
    options,
  );
}
export type DeleteApiKeyMutationHookResult = ReturnType<typeof useDeleteApiKeyMutation>;
export type DeleteApiKeyMutationResult = Apollo.MutationResult<DeleteApiKeyMutation>;
export type DeleteApiKeyMutationOptions = Apollo.BaseMutationOptions<
  DeleteApiKeyMutation,
  DeleteApiKeyMutationVariables
>;
export const CreateOrganizationDocument = gql`
  mutation CreateOrganization($input: OrganizationNodeInput!) {
    createOrganization(input: $input) {
      ...OrganizationAttributes
    }
  }
  ${OrganizationAttributesFragmentDoc}
`;
export type CreateOrganizationMutationFn = Apollo.MutationFunction<
  CreateOrganizationMutation,
  CreateOrganizationMutationVariables
>;

/**
 * __useCreateOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationMutation, { data, loading, error }] = useCreateOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrganizationMutation,
    CreateOrganizationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateOrganizationMutation, CreateOrganizationMutationVariables>(
    CreateOrganizationDocument,
    options,
  );
}
export type CreateOrganizationMutationHookResult = ReturnType<typeof useCreateOrganizationMutation>;
export type CreateOrganizationMutationResult = Apollo.MutationResult<CreateOrganizationMutation>;
export type CreateOrganizationMutationOptions = Apollo.BaseMutationOptions<
  CreateOrganizationMutation,
  CreateOrganizationMutationVariables
>;
export const UpdateOrganizationDocument = gql`
  mutation UpdateOrganization($id: ID!, $input: OrganizationNodeInput!) {
    updateOrganization(id: $id, input: $input) {
      ...OrganizationAttributes
    }
  }
  ${OrganizationAttributesFragmentDoc}
`;
export type UpdateOrganizationMutationFn = Apollo.MutationFunction<
  UpdateOrganizationMutation,
  UpdateOrganizationMutationVariables
>;

/**
 * __useUpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationMutation, { data, loading, error }] = useUpdateOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrganizationMutation,
    UpdateOrganizationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>(
    UpdateOrganizationDocument,
    options,
  );
}
export type UpdateOrganizationMutationHookResult = ReturnType<typeof useUpdateOrganizationMutation>;
export type UpdateOrganizationMutationResult = Apollo.MutationResult<UpdateOrganizationMutation>;
export type UpdateOrganizationMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrganizationMutation,
  UpdateOrganizationMutationVariables
>;
export const DeleteOrganizationDocument = gql`
  mutation DeleteOrganization($id: ID!) {
    removeOrganization(id: $id)
  }
`;
export type DeleteOrganizationMutationFn = Apollo.MutationFunction<
  DeleteOrganizationMutation,
  DeleteOrganizationMutationVariables
>;

/**
 * __useDeleteOrganizationMutation__
 *
 * To run a mutation, you first call `useDeleteOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrganizationMutation, { data, loading, error }] = useDeleteOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteOrganizationMutation,
    DeleteOrganizationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>(
    DeleteOrganizationDocument,
    options,
  );
}
export type DeleteOrganizationMutationHookResult = ReturnType<typeof useDeleteOrganizationMutation>;
export type DeleteOrganizationMutationResult = Apollo.MutationResult<DeleteOrganizationMutation>;
export type DeleteOrganizationMutationOptions = Apollo.BaseMutationOptions<
  DeleteOrganizationMutation,
  DeleteOrganizationMutationVariables
>;
export const ShareInsightsBoardDocument = gql`
  mutation ShareInsightsBoard($id: ID!, $requireToken: Boolean!, $emails: [String!]!) {
    shareInsightsBoard(id: $id, requireToken: $requireToken, emails: $emails) {
      ...InsightsBoardAttributes
    }
  }
  ${InsightsBoardAttributesFragmentDoc}
`;
export type ShareInsightsBoardMutationFn = Apollo.MutationFunction<
  ShareInsightsBoardMutation,
  ShareInsightsBoardMutationVariables
>;

/**
 * __useShareInsightsBoardMutation__
 *
 * To run a mutation, you first call `useShareInsightsBoardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareInsightsBoardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareInsightsBoardMutation, { data, loading, error }] = useShareInsightsBoardMutation({
 *   variables: {
 *      id: // value for 'id'
 *      requireToken: // value for 'requireToken'
 *      emails: // value for 'emails'
 *   },
 * });
 */
export function useShareInsightsBoardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ShareInsightsBoardMutation,
    ShareInsightsBoardMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ShareInsightsBoardMutation, ShareInsightsBoardMutationVariables>(
    ShareInsightsBoardDocument,
    options,
  );
}
export type ShareInsightsBoardMutationHookResult = ReturnType<typeof useShareInsightsBoardMutation>;
export type ShareInsightsBoardMutationResult = Apollo.MutationResult<ShareInsightsBoardMutation>;
export type ShareInsightsBoardMutationOptions = Apollo.BaseMutationOptions<
  ShareInsightsBoardMutation,
  ShareInsightsBoardMutationVariables
>;
export const CreateOrganizationUsersDocument = gql`
  mutation CreateOrganizationUsers($organizationId: ID, $emails: [String!]!, $role: String!) {
    createUsers(organizationId: $organizationId, emails: $emails, role: $role) {
      ...UserAttributesWithOrg
    }
  }
  ${UserAttributesWithOrgFragmentDoc}
`;
export type CreateOrganizationUsersMutationFn = Apollo.MutationFunction<
  CreateOrganizationUsersMutation,
  CreateOrganizationUsersMutationVariables
>;

/**
 * __useCreateOrganizationUsersMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationUsersMutation, { data, loading, error }] = useCreateOrganizationUsersMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      emails: // value for 'emails'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useCreateOrganizationUsersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrganizationUsersMutation,
    CreateOrganizationUsersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOrganizationUsersMutation,
    CreateOrganizationUsersMutationVariables
  >(CreateOrganizationUsersDocument, options);
}
export type CreateOrganizationUsersMutationHookResult = ReturnType<
  typeof useCreateOrganizationUsersMutation
>;
export type CreateOrganizationUsersMutationResult =
  Apollo.MutationResult<CreateOrganizationUsersMutation>;
export type CreateOrganizationUsersMutationOptions = Apollo.BaseMutationOptions<
  CreateOrganizationUsersMutation,
  CreateOrganizationUsersMutationVariables
>;
export const UpdateUserDocument = gql`
  mutation UpdateUser($id: ID!, $input: UserNodeInput!) {
    updateUser(id: $id, input: $input) {
      ...UserAttributesWithOrg
    }
  }
  ${UserAttributesWithOrgFragmentDoc}
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options,
  );
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const CreateUsersDocument = gql`
  mutation CreateUsers($emails: [String!]!, $role: String!) {
    createUsers(emails: $emails, role: $role) {
      ...UserAttributesWithOrg
    }
  }
  ${UserAttributesWithOrgFragmentDoc}
`;
export type CreateUsersMutationFn = Apollo.MutationFunction<
  CreateUsersMutation,
  CreateUsersMutationVariables
>;

/**
 * __useCreateUsersMutation__
 *
 * To run a mutation, you first call `useCreateUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUsersMutation, { data, loading, error }] = useCreateUsersMutation({
 *   variables: {
 *      emails: // value for 'emails'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useCreateUsersMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateUsersMutation, CreateUsersMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUsersMutation, CreateUsersMutationVariables>(
    CreateUsersDocument,
    options,
  );
}
export type CreateUsersMutationHookResult = ReturnType<typeof useCreateUsersMutation>;
export type CreateUsersMutationResult = Apollo.MutationResult<CreateUsersMutation>;
export type CreateUsersMutationOptions = Apollo.BaseMutationOptions<
  CreateUsersMutation,
  CreateUsersMutationVariables
>;
export const TransferUserDocument = gql`
  mutation TransferUser($oldId: ID!, $newId: ID!) {
    transferUser(oldId: $oldId, newId: $newId) {
      ...UserAttributesWithOrg
    }
  }
  ${UserAttributesWithOrgFragmentDoc}
`;
export type TransferUserMutationFn = Apollo.MutationFunction<
  TransferUserMutation,
  TransferUserMutationVariables
>;

/**
 * __useTransferUserMutation__
 *
 * To run a mutation, you first call `useTransferUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransferUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transferUserMutation, { data, loading, error }] = useTransferUserMutation({
 *   variables: {
 *      oldId: // value for 'oldId'
 *      newId: // value for 'newId'
 *   },
 * });
 */
export function useTransferUserMutation(
  baseOptions?: Apollo.MutationHookOptions<TransferUserMutation, TransferUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TransferUserMutation, TransferUserMutationVariables>(
    TransferUserDocument,
    options,
  );
}
export type TransferUserMutationHookResult = ReturnType<typeof useTransferUserMutation>;
export type TransferUserMutationResult = Apollo.MutationResult<TransferUserMutation>;
export type TransferUserMutationOptions = Apollo.BaseMutationOptions<
  TransferUserMutation,
  TransferUserMutationVariables
>;
export const DeleteUserDocument = gql`
  mutation DeleteUser($id: ID!) {
    removeUser(id: $id)
  }
`;
export type DeleteUserMutationFn = Apollo.MutationFunction<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(
    DeleteUserDocument,
    options,
  );
}
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;
export const AcceptInvitationDocument = gql`
  mutation AcceptInvitation($token: String!, $input: InvitationInput!) {
    acceptInvitation(token: $token, input: $input)
  }
`;
export type AcceptInvitationMutationFn = Apollo.MutationFunction<
  AcceptInvitationMutation,
  AcceptInvitationMutationVariables
>;

/**
 * __useAcceptInvitationMutation__
 *
 * To run a mutation, you first call `useAcceptInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInvitationMutation, { data, loading, error }] = useAcceptInvitationMutation({
 *   variables: {
 *      token: // value for 'token'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptInvitationMutation,
    AcceptInvitationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AcceptInvitationMutation, AcceptInvitationMutationVariables>(
    AcceptInvitationDocument,
    options,
  );
}
export type AcceptInvitationMutationHookResult = ReturnType<typeof useAcceptInvitationMutation>;
export type AcceptInvitationMutationResult = Apollo.MutationResult<AcceptInvitationMutation>;
export type AcceptInvitationMutationOptions = Apollo.BaseMutationOptions<
  AcceptInvitationMutation,
  AcceptInvitationMutationVariables
>;
export const ListApiKeysDocument = gql`
  query ListApiKeys($paging: PagingInput!, $organizationId: ID, $userId: ID) {
    apiKeys(paging: $paging, organizationId: $organizationId, userId: $userId) {
      paging {
        total
      }
      error
      items {
        ...ApiKeyAttributes
      }
    }
  }
  ${ApiKeyAttributesFragmentDoc}
`;

/**
 * __useListApiKeysQuery__
 *
 * To run a query within a React component, call `useListApiKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useListApiKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListApiKeysQuery({
 *   variables: {
 *      paging: // value for 'paging'
 *      organizationId: // value for 'organizationId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useListApiKeysQuery(
  baseOptions: Apollo.QueryHookOptions<ListApiKeysQuery, ListApiKeysQueryVariables> &
    ({ variables: ListApiKeysQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListApiKeysQuery, ListApiKeysQueryVariables>(ListApiKeysDocument, options);
}
export function useListApiKeysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListApiKeysQuery, ListApiKeysQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListApiKeysQuery, ListApiKeysQueryVariables>(
    ListApiKeysDocument,
    options,
  );
}
export function useListApiKeysSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ListApiKeysQuery, ListApiKeysQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ListApiKeysQuery, ListApiKeysQueryVariables>(
    ListApiKeysDocument,
    options,
  );
}
export type ListApiKeysQueryHookResult = ReturnType<typeof useListApiKeysQuery>;
export type ListApiKeysLazyQueryHookResult = ReturnType<typeof useListApiKeysLazyQuery>;
export type ListApiKeysSuspenseQueryHookResult = ReturnType<typeof useListApiKeysSuspenseQuery>;
export type ListApiKeysQueryResult = Apollo.QueryResult<
  ListApiKeysQuery,
  ListApiKeysQueryVariables
>;
export const MeDocument = gql`
  query Me {
    me {
      ...AuthUserAttributes
    }
  }
  ${AuthUserAttributesFragmentDoc}
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<MeQuery, MeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const ListOrganizationsDocument = gql`
  query ListOrganizations($paging: PagingInput!, $name: String) {
    organizations(paging: $paging, name: $name) {
      paging {
        total
      }
      error
      items {
        ...OrganizationAttributes
      }
    }
  }
  ${OrganizationAttributesFragmentDoc}
`;

/**
 * __useListOrganizationsQuery__
 *
 * To run a query within a React component, call `useListOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOrganizationsQuery({
 *   variables: {
 *      paging: // value for 'paging'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useListOrganizationsQuery(
  baseOptions: Apollo.QueryHookOptions<ListOrganizationsQuery, ListOrganizationsQueryVariables> &
    ({ variables: ListOrganizationsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListOrganizationsQuery, ListOrganizationsQueryVariables>(
    ListOrganizationsDocument,
    options,
  );
}
export function useListOrganizationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListOrganizationsQuery,
    ListOrganizationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListOrganizationsQuery, ListOrganizationsQueryVariables>(
    ListOrganizationsDocument,
    options,
  );
}
export function useListOrganizationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListOrganizationsQuery,
    ListOrganizationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ListOrganizationsQuery, ListOrganizationsQueryVariables>(
    ListOrganizationsDocument,
    options,
  );
}
export type ListOrganizationsQueryHookResult = ReturnType<typeof useListOrganizationsQuery>;
export type ListOrganizationsLazyQueryHookResult = ReturnType<typeof useListOrganizationsLazyQuery>;
export type ListOrganizationsSuspenseQueryHookResult = ReturnType<
  typeof useListOrganizationsSuspenseQuery
>;
export type ListOrganizationsQueryResult = Apollo.QueryResult<
  ListOrganizationsQuery,
  ListOrganizationsQueryVariables
>;
export const GetOrganizationDocument = gql`
  query GetOrganization($id: ID!) {
    organization(id: $id) {
      ...OrganizationAttributes
    }
  }
  ${OrganizationAttributesFragmentDoc}
`;

/**
 * __useGetOrganizationQuery__
 *
 * To run a query within a React component, call `useGetOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrganizationQuery(
  baseOptions: Apollo.QueryHookOptions<GetOrganizationQuery, GetOrganizationQueryVariables> &
    ({ variables: GetOrganizationQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(
    GetOrganizationDocument,
    options,
  );
}
export function useGetOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationQuery, GetOrganizationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(
    GetOrganizationDocument,
    options,
  );
}
export function useGetOrganizationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetOrganizationQuery,
    GetOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(
    GetOrganizationDocument,
    options,
  );
}
export type GetOrganizationQueryHookResult = ReturnType<typeof useGetOrganizationQuery>;
export type GetOrganizationLazyQueryHookResult = ReturnType<typeof useGetOrganizationLazyQuery>;
export type GetOrganizationSuspenseQueryHookResult = ReturnType<
  typeof useGetOrganizationSuspenseQuery
>;
export type GetOrganizationQueryResult = Apollo.QueryResult<
  GetOrganizationQuery,
  GetOrganizationQueryVariables
>;
export const GetOrganizationNameDocument = gql`
  query GetOrganizationName($id: ID!) {
    organization(id: $id) {
      id
      name
    }
  }
`;

/**
 * __useGetOrganizationNameQuery__
 *
 * To run a query within a React component, call `useGetOrganizationNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrganizationNameQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOrganizationNameQuery,
    GetOrganizationNameQueryVariables
  > &
    ({ variables: GetOrganizationNameQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrganizationNameQuery, GetOrganizationNameQueryVariables>(
    GetOrganizationNameDocument,
    options,
  );
}
export function useGetOrganizationNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrganizationNameQuery,
    GetOrganizationNameQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrganizationNameQuery, GetOrganizationNameQueryVariables>(
    GetOrganizationNameDocument,
    options,
  );
}
export function useGetOrganizationNameSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetOrganizationNameQuery,
    GetOrganizationNameQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetOrganizationNameQuery, GetOrganizationNameQueryVariables>(
    GetOrganizationNameDocument,
    options,
  );
}
export type GetOrganizationNameQueryHookResult = ReturnType<typeof useGetOrganizationNameQuery>;
export type GetOrganizationNameLazyQueryHookResult = ReturnType<
  typeof useGetOrganizationNameLazyQuery
>;
export type GetOrganizationNameSuspenseQueryHookResult = ReturnType<
  typeof useGetOrganizationNameSuspenseQuery
>;
export type GetOrganizationNameQueryResult = Apollo.QueryResult<
  GetOrganizationNameQuery,
  GetOrganizationNameQueryVariables
>;
export const ListPublicationsDocument = gql`
  query ListPublications($paging: PagingInput!, $organizationId: ID, $userId: ID) {
    insightsBoards(paging: $paging, organizationId: $organizationId, userId: $userId) {
      paging {
        total
      }
      error
      items {
        ...InsightsBoardAttributes
      }
    }
  }
  ${InsightsBoardAttributesFragmentDoc}
`;

/**
 * __useListPublicationsQuery__
 *
 * To run a query within a React component, call `useListPublicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPublicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPublicationsQuery({
 *   variables: {
 *      paging: // value for 'paging'
 *      organizationId: // value for 'organizationId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useListPublicationsQuery(
  baseOptions: Apollo.QueryHookOptions<ListPublicationsQuery, ListPublicationsQueryVariables> &
    ({ variables: ListPublicationsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListPublicationsQuery, ListPublicationsQueryVariables>(
    ListPublicationsDocument,
    options,
  );
}
export function useListPublicationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListPublicationsQuery, ListPublicationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListPublicationsQuery, ListPublicationsQueryVariables>(
    ListPublicationsDocument,
    options,
  );
}
export function useListPublicationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListPublicationsQuery,
    ListPublicationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ListPublicationsQuery, ListPublicationsQueryVariables>(
    ListPublicationsDocument,
    options,
  );
}
export type ListPublicationsQueryHookResult = ReturnType<typeof useListPublicationsQuery>;
export type ListPublicationsLazyQueryHookResult = ReturnType<typeof useListPublicationsLazyQuery>;
export type ListPublicationsSuspenseQueryHookResult = ReturnType<
  typeof useListPublicationsSuspenseQuery
>;
export type ListPublicationsQueryResult = Apollo.QueryResult<
  ListPublicationsQuery,
  ListPublicationsQueryVariables
>;
export const ListUsersDocument = gql`
  query ListUsers($paging: PagingInput!, $name: String, $email: String, $organizationId: ID) {
    users(paging: $paging, name: $name, email: $email, organizationId: $organizationId) {
      paging {
        total
      }
      error
      items {
        ...UserAttributesWithOrg
      }
    }
  }
  ${UserAttributesWithOrgFragmentDoc}
`;

/**
 * __useListUsersQuery__
 *
 * To run a query within a React component, call `useListUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUsersQuery({
 *   variables: {
 *      paging: // value for 'paging'
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useListUsersQuery(
  baseOptions: Apollo.QueryHookOptions<ListUsersQuery, ListUsersQueryVariables> &
    ({ variables: ListUsersQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListUsersQuery, ListUsersQueryVariables>(ListUsersDocument, options);
}
export function useListUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListUsersQuery, ListUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListUsersQuery, ListUsersQueryVariables>(ListUsersDocument, options);
}
export function useListUsersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ListUsersQuery, ListUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ListUsersQuery, ListUsersQueryVariables>(
    ListUsersDocument,
    options,
  );
}
export type ListUsersQueryHookResult = ReturnType<typeof useListUsersQuery>;
export type ListUsersLazyQueryHookResult = ReturnType<typeof useListUsersLazyQuery>;
export type ListUsersSuspenseQueryHookResult = ReturnType<typeof useListUsersSuspenseQuery>;
export type ListUsersQueryResult = Apollo.QueryResult<ListUsersQuery, ListUsersQueryVariables>;
export const GetUserDocument = gql`
  query GetUser($id: ID!) {
    user(id: $id) {
      ...UserAttributesWithOrg
    }
  }
  ${UserAttributesWithOrgFragmentDoc}
`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables> &
    ({ variables: GetUserQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
}
export function useGetUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
}
export function useGetUserSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserQuery, GetUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserSuspenseQueryHookResult = ReturnType<typeof useGetUserSuspenseQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetEmailTokenDocument = gql`
  query GetEmailToken($token: String!) {
    emailToken(token: $token) {
      ...EmailTokenAttributes
    }
  }
  ${EmailTokenAttributesFragmentDoc}
`;

/**
 * __useGetEmailTokenQuery__
 *
 * To run a query within a React component, call `useGetEmailTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGetEmailTokenQuery(
  baseOptions: Apollo.QueryHookOptions<GetEmailTokenQuery, GetEmailTokenQueryVariables> &
    ({ variables: GetEmailTokenQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEmailTokenQuery, GetEmailTokenQueryVariables>(
    GetEmailTokenDocument,
    options,
  );
}
export function useGetEmailTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetEmailTokenQuery, GetEmailTokenQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEmailTokenQuery, GetEmailTokenQueryVariables>(
    GetEmailTokenDocument,
    options,
  );
}
export function useGetEmailTokenSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetEmailTokenQuery, GetEmailTokenQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetEmailTokenQuery, GetEmailTokenQueryVariables>(
    GetEmailTokenDocument,
    options,
  );
}
export type GetEmailTokenQueryHookResult = ReturnType<typeof useGetEmailTokenQuery>;
export type GetEmailTokenLazyQueryHookResult = ReturnType<typeof useGetEmailTokenLazyQuery>;
export type GetEmailTokenSuspenseQueryHookResult = ReturnType<typeof useGetEmailTokenSuspenseQuery>;
export type GetEmailTokenQueryResult = Apollo.QueryResult<
  GetEmailTokenQuery,
  GetEmailTokenQueryVariables
>;
