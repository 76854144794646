/* eslint-disable */
import axios from 'axios';
import qs from 'qs';
import { endpoints } from '../constants/endpoints';

export const getApiKeys = (accessToken) =>
  axios.get(endpoints.apikey, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

export const deleteApiKey = (accessToken, id) =>
  axios.delete(`${endpoints.apikey}/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

export const updateApiKey = (accessToken, id, apikey) => {
  const { name, user_id, secret, require_token, external_sources } = apikey; // eslint-disable-line camelcase
  axios.put(
    `${endpoints.apikey}/${id}`,

    qs.stringify({
      name,
      user_id,
      secret,
      require_token,
      external_sources: external_sources.toString(),
    }),
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
};

export const getApiKey = (accessToken, id) =>
  axios.get(`${endpoints.apikey}/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
