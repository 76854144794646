export type TColors = {
  transparent: string;

  // background
  baseBg: string;
  layoutBg: string;

  // text
  primaryText: string;
  secondaryText: string;
  darkText: string;

  // button
  buttonLnk: string;
  buttonText: string;

  // form
  formLabel: string;

  // common
  primary: string;

  success: string;
  successLight: string;
  successDark: string;

  error: string;
  errorLight: string;
  errorDark: string;

  border: string;
};

const general = {
  white100: '#ffffff',

  green100: '#1b5e20',
  green80: '#2e7d32',
  green60: '#4caf50',

  red100: '#932020',
  red80: '#d32f2f',
  red60: '#db5757',

  blue100: '#3182bd',

  purple100: '#791abf',
  purple10: '#fbf0ff',

  dark100: '#000000DE', // 87%
  dark80: '#4e5a5f',
  dark40: '#E0E0E0',
  dark10: '#f7f7f7',
};

const background = {
  baseBg: general.white100,
  layoutBg: general.dark10,
  secondaryBg: general.purple10,
};

const text = {
  primaryText: general.dark100,
  secondaryText: general.dark80,
  darkText: general.dark100,
};

const button = {
  buttonLnk: general.blue100,
  buttonText: general.dark80,
};

const form = {
  formLabel: general.dark80,
};

const common = {
  primary: general.purple100,

  success: general.green80,
  successLight: general.green60,
  successDark: general.green100,

  error: general.red80,
  errorLight: general.red60,
  errorDark: general.red100,

  border: general.dark40,

  transparent: 'transparent',
};

const colors: TColors = {
  ...background,
  ...text,
  ...button,
  ...form,
  ...common,
};

export default colors;
