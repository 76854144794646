import FeedbackOutlined from '@mui/icons-material/FeedbackOutlined';
import IconButton from '@mui/material/IconButton';
import React from 'react';

type Props = {
  id: string;
  handleClick: () => void;
};
export default function ContactAction({ id, handleClick }: Props) {
  return (
    <IconButton onClick={handleClick} data-testid={`${id}-icon-button`} size="small">
      <FeedbackOutlined fontSize="inherit" />
    </IconButton>
  );
}
