import { Presentation, Series } from 'translate_dc_to_echart';
import { FieldGenMapping, Fields } from '../../types/chartBuilderIO';
import {
  cleanFields,
  mapGroupToFields,
  mapMarkToFields,
  mergeFields,
} from '../../utils/chartBuilderIO';

export const MISSING_MAPPING =
  'Missing fieldGenMapping specification. This mapping is required for editing charts.';

/**
 * Manages input/output utilities for the Chart Builder.
 */
export default class ChartBuilderIO {
  /** The [SeriesKeys: InputType] mapping for the current series type. */
  fieldGenMapping: FieldGenMapping;

  constructor(fieldGenMapping: FieldGenMapping) {
    this.fieldGenMapping = fieldGenMapping;
  }

  /**
   * Generates the Fields object when editing a chart from the Chart Builder.
   * @param series - The series array from the chart spec
   * @param presentation - The presentation object from the chart spec
   */
  public generateFields = (series: Series[], presentation: Presentation): Fields => {
    // Throw if the mapping is missing
    if (!this.fieldGenMapping || !this.fieldGenMapping.mark || !this.fieldGenMapping.group)
      throw new Error(MISSING_MAPPING);

    let fields = {};
    for (const ser of series) {
      const { mark, group, markLine } = ser;

      // Generate fields for the current series
      const currFields = cleanFields({
        ...mapMarkToFields(this.fieldGenMapping.mark, mark, group),
        ...mapGroupToFields(this.fieldGenMapping.group, group),
        markLine,
        smooth: presentation.smooth,
      });

      // Merge fields from prev & current series
      fields = mergeFields(currFields, fields);
    }

    return fields;
  };

  /**
   * Manages the series object generation when submitting a chart from the Chart Builder.
   */
  public static generateSeries = () => {
    throw new Error('Not implemented');
  };
}
