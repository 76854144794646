import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { SketchPicker } from 'react-color';

import '../styles/EditModeMenu.scss';

export type Props = {
  dataCy: string,
  colorData: [],
  columnData: [],
  label: string,
  tooltip: string,
  classes: { dropdownPopup: {} },
  recolor: () => mixed,
};

class ColorEditor extends React.Component<Props> {
  constructor(props) {
    super(props);

    this.state = {
      color: this.props.colorData.length > 0 ? this.props.colorData[0] : '#FFF',
      displayColorPicker: false,
      columnIdx: this.props.columnData.length > 0 ? 0 : '',
      dropdownText: this.props.columnData.length > 0 ? this.props.columnData[0] : 'Column',
    };

    this.color = {
      width: '20px',
      height: '20px',
      borderRadius: '2px',
    };

    this.swatch = {
      padding: '5px',
      display: 'inline-block',
      cursor: 'pointer',
    };

    this.popover = {
      position: 'absolute',
      bottom: 0,
      left: 0,
      zIndex: '2',
    };
  }

  onColorPickerClosed = () => {
    this.setState({ displayColorPicker: false });
  };

  onColorChangeCompleted = async (color) => {
    const colorData = [...this.props.colorData];
    colorData[this.state.columnIdx] = color.hex;
    this.props.recolor(colorData);
    this.setState({ color: color.hex });
  };

  // Add columns to the dropdown
  addColumnNames = () => {
    const columns = [];
    const { columnData, colorData } = this.props;
    for (let i = 0; i < columnData.length; i++) {
      columns.push(
        <MenuItem
          key={`option${columnData[i]}`}
          value={i}
          data-color={colorData[i]}
          data-name={columnData[i]}
          classes={{ root: this.props.classes.dropdownPopup }}
        >
          {
            // Strip out any HTML in the column name
            columnData[i] !== undefined ? String(columnData[i]).replace(/(<([^>]+)>)/gi, '') : ''
          }
        </MenuItem>,
      );
    }
    return columns;
  };

  dropdownHandleClick = (e) => {
    // Show color picker
    const { colorData, columnData } = this.props;
    const index = e.target.value;
    this.setState({
      columnIdx: index,
      color: colorData[index],
      dropdownText: columnData[index],
    });
  };

  colorSwatchHandleClick = () => {
    this.setState((prevState) => ({
      displayColorPicker: !prevState.displayColorPicker,
    }));
  };

  render() {
    const { columnData, dataCy } = this.props;
    const { displayColorPicker, columnIdx, dropdownText, color } = this.state;
    return (
      <div className="menu-row">
        <label className="row-label">{this.props.label}</label>
        <div className="row-controls">
          {columnData[0] !== undefined && columnData.length > 1 && (
            <Select
              className="actions-select"
              title={this.props.tooltip}
              value={columnIdx}
              onChange={this.dropdownHandleClick}
              disabled={!dropdownText}
              variant="standard"
            >
              {this.addColumnNames()}
            </Select>
          )}
          <div
            data-cy={dataCy !== undefined ? dataCy : ''}
            style={this.swatch}
            onClick={this.colorSwatchHandleClick}
          >
            <div style={{ ...this.color, backgroundColor: color?.replace(/"/g, '') }} />
          </div>
          {displayColorPicker ? (
            <div className="color-selector" style={this.popover}>
              <Tooltip title="Close Color Selector">
                <IconButton className="close-button" onClick={this.onColorPickerClosed}>
                  <CancelIcon />
                </IconButton>
              </Tooltip>
              <div>
                <SketchPicker
                  color={this.state.color}
                  onChange={this.onColorChangeCompleted}
                  disableAlpha
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default ColorEditor;
