import { ECharts, EChartsOption } from 'echarts';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import React from 'react';
import { connect } from 'react-redux';
import {
  ChartTypes,
  DATA_MAX,
  DATA_MIN,
  DOMUtils,
  MANUAL_RERENDER_TYPES,
  TitleTypes,
  addToolbox,
  applyColorOverride,
  autoscale,
  autoscaleOption,
  cleanData,
  createSeries,
  filterDisabled,
  filterNoneAggregates,
  getHorizontalGridIndex,
  getMinMax,
  getTextFont,
  initDataset,
  mapAnnotationToGraphic,
  performComputations,
  resetAxes,
  resetAxesOption,
  resizeChart,
  setAxisLabel,
  setOption,
  shouldUseBackendCompute,
  sort,
  translateDCtoEChart,
  updateCBsAnnotationAndText,
  updateRidgelineHeight,
  updateSampleNotice,
  updateSingleMetric,
  updateTitleWidth,
  updateVisualMap,
} from 'translate_dc_to_echart';
import { DCEChart } from '../../../../chart-library/src/DCEChart';
import { TOAST_INFO, TOAST_SHORT, TOAST_SUCCESS } from '../../../../constants/toast';
import {
  consumeChartSpecQueue,
  queueBulkExportSpec,
  updateBulkExportProgress,
} from '../../../../store/actions/bulk_charts_export.action';
import { addToast } from '../../../../store/actions/toast.actions';
import {
  selectExportObjectID,
  selectIsExportedFromChartBuilder,
  selectIsExportedFromPopOutChart,
  selectIsExportingRequestInitialized,
} from '../../../../store/sagas/selectors';
import './DCPlotV2.scss';
import { b64toBlob, exportPlot, saveAsZip } from './util/chartExport';

// Props of dcSpec
type dcSpecProps = {
  specUpdate: Boolean, // Flag that indicates if the chart spec has updates
  values: {
    complete: Boolean, // Flag that indicates if the full dataset is retrieved
    transforms: [{}], // Specific transforms to apply to the dataset
    metadata: {
      order: {}, // Order to sort the data in
      labels: {}, // Labels to use for the bins
    }, // Metadata related to the underlying dataset
    rows: [], // Rows in the underlying dataset
    columns: [], // Columns in the underlying dataset
    aggregate: [], // Information about any aggregation performed
    bins: [], // Information about any binning performed
    dataSampleLimit: Number,
    totalRowCount?: Number,
    reference: {
      type: string,
      params: {
        /**
         * The dataset's name
         */
        dataset: string,
      },
    },
  }, // Data used to plot the chart
  caption: String,
  plot: {
    series: [], // Info about the chart type, columns to use for axes, group, slider or subplot
    type: String, // Chart type
    presentation: {
      annotations: [], // Annotations added to the chart
      theme: {}, // Specific color theme to use for the chart
      title: {
        text: any,
        textStyle: {
          fontSize: Number,
        },
      }, // Chart title and styles
      xaxis: {
        text: any,
        axisLabel: {
          fontSize: Number,
          prefix?: String,
          suffix?: String,
        },
        nameTextStyle: {
          fontSize: Number,
        },
      }, // X-axis title and styles
      yaxis: {
        text: any,
        axisLabel: {
          fontSize: Number,
          prefix?: String,
          suffix?: String,
        },
        nameTextStyle: {
          fontSize: Number,
        },
      }, // Y-axis title and styles
      overlayAxis: {
        text: any,
      }, // Overlay axis title and styles
      colorOverride: [], // Colors to override default values with
      horizontal: boolean, // Flag that indicates if the chart is a horizontal chart
      shouldAutoscale: boolean, // Flag that indicates if the chart should shouldAutoscale
    },
  }, // Plot specific info
};

type Props = {
  addToast: () => mixed, // Adds a toast message with a given type and text
  chartEditingMode: Boolean, // Flag that indicates if the chart is being edited
  customUpdate: Boolean, // Flag that indicates if the translation layer needs to be retriggered to apply chart updates
  chartSpec: {
    // chart spec information when the DCPlotV2 is a copy from bulk exporting
    dcSpec: dcSpecProps,
    update: {
      version: number,
      spec: any,
    },
    option: {},
    theme: {},
  }, // chartSpec when users click the bulk exporting button
  dcSpec: dcSpecProps, // Info required to plot the chart
  exportObjectID: String, // the object id of the exporting chart
  consumeChartSpecQueue: () => mixed,
  height: Number, // Chart's height determined by ReactResizeDetector
  inChartBuilder: Boolean, // Flag that indicates if the chart is in the chart builder
  isExportingRequestInitialized: Boolean, // Flag that indicates if there is slider chart is exporting
  isPopOutChart: Boolean, // Flag that indicates if it is a popout chart
  isExportedFromPopOutChart: Boolean, // Flag that indicates if exporting is from a popout chart
  isExportedFromChartBuilder: Boolean, // Flag that indicates if exporting is from the chart builder
  isExportingCopy: Boolean, // Flag that indicates if the chart component instance is for exporting
  keepChanges: Boolean, // Flag that indicates if the chart edits should be saved
  objectId: String, // Unique identifier for each chart object
  queueBulkExportSpec: () => mixed,
  openCaptionAlert: () => mixed, // Sets the clean data prompt and conditionally shows a banner on the chart
  setCaption: () => mixed, // Updates the chart caption
  forwardedRef: React.Ref, // Reference to the echart
  setCustomUpdate: () => mixed, // Sets the flag to retrigger translation to apply chart updates
  setIsProcessingExport: () => mixed,
  isPanMode: boolean, // Flag that indicates if the chart toolbar/more chart options should be displayed
  // TODO: Are showModalPng and updateModalPng used for exporting echarts?
  showModalPng: Boolean, // Flag that indicates if the rename modal should be shown when exporting a chart
  theme: {}, // Color theme to apply to the chart
  updateModalPng: () => mixed, // Toggles the display of the rename modal when exporting a chart
  updateGraphicAnnotation: () => mixed, // Updates the presentation's annotations for a chart in the chart builder
  updateAnnotationTextField: () => mixed, // Updates the visibility and interactibility of each annotation/text for a chart in the chart builder
  updateBulkExportProgress: () => mixed, // Updates the current download progress when exporting charts with sliders
  width: Number, // Chart's width determined by ReactResizeDetector
  responsive: Boolean, // If we want the chart to resize font/spacing with resize
};

export class DCPlotV2 extends React.Component<Props> {
  constructor(props) {
    super(props);
    // Create a ref to the echart instance if one is not provided
    const echartsRef = this.props.forwardedRef ?? React.createRef(null);
    this.state = {
      minXDefault: null,
      maxXDefault: null,
      minYDefault: null,
      maxYDefault: null,
      panActive: false,
      /**
       * Object that maps grid indices to datazoom stack
       */
      zoomStack: {},
      /**
       * `true` if the datazoom cursor is active, otherwise `false`
       */
      zoomActive: true,
      /**
       * Index of grid where we double-clicked for zoom out
       */
      zoomingOut: [],
      showMessage: false,
      echartsRef,
      /**
       * Array of objects that track event bindings
       * @type {{eventName: string, handler: () => {}, getZr?: boolean}[]}
       */
      bindings: [],
      defaultSizing: null,
    };
    // a flag for tracking if a dragend event is triggered by annotations/texts in an echart
    // this flag is used to prevent click event generated by drag event
    this.isDragendTriggered = false;
  }

  componentDidMount() {
    const ecInstance = this.state.echartsRef.current?.getEchartsInstance();

    if (this.props.isExportingCopy) {
      this.processBulkExportingFromEchart();
      return;
    }

    this.translateUpdate({
      shouldTranslate: true,
      shouldUpdate: true,
      isFreshUpdate: true,
    });

    // bind basic event handlers for this chart
    if (!this.props.inChartBuilder) {
      this.bindEventHandlers([
        { eventName: 'datazoom', handler: this.handlers.datazoom },
        { eventName: 'dblclick', handler: this.handlers.dblclick, getZr: true },
        { eventName: 'globalcursortaken', handler: this.handlers.globalcursortaken },
      ]);
    }

    // if this chart has a slider, bind slider related events
    if (this.props.dcSpec.plot.series.some((ser) => ser.group?.slider !== undefined))
      this.bindEventHandlers([
        { eventName: 'timelinechanged', handler: this.handlers.timelinechanged },
        { eventName: 'contextmenu', handler: this.handlers.contextmenu, getZr: true },
        { eventName: 'click', handler: this.handlers.click, getZr: true },
        { eventName: 'mousemove', handler: this.handlers.mouseMoveOverTimeline, getZr: true },
      ]);
    else if (!this.props.inChartBuilder) {
      // only bind this mousemove event if this chart does not have a slider
      this.bindEventHandlers([
        { eventName: 'mousemove', handler: this.handlers.mouseMoveSetCursor, getZr: true },
      ]);
    }

    // Enable the datazoom by default
    if (!this.props.inChartBuilder) {
      ecInstance.dispatchAction({
        type: 'takeGlobalCursor',
        key: 'dataZoomSelect',
        dataZoomSelectActive: true,
      });
    }

    if (this.props.inChartBuilder && this.props.dcSpec?.plot?.presentation?.annotations) {
      // render annotations for chart builder
      const { width } = this.props;
      const { height } = this.props;
      const { presentation } = this.props.dcSpec.plot;
      const presentationCopy = cloneDeep(presentation);
      updateCBsAnnotationAndText(
        presentationCopy,
        ecInstance,
        width,
        height,
        this.props.updateGraphicAnnotation,
        this.props.updateAnnotationTextField,
      );
    }
  }

  // for copied instance only when exporting chart
  // prevent re-rendering if copied chart spec is unchanged
  shouldComponentUpdate(nextProps) {
    if (this.props.chartSpec && isEqual(this.props.chartSpec, nextProps.chartSpec)) {
      return false;
    }
    return true;
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.isExportingCopy) {
      this.processBulkExportingFromEchart();
      return;
    }

    const ecInstance: ECharts = this.state.echartsRef.current?.getEchartsInstance();
    const options = ecInstance.getOption();
    if (
      this.props.responsive &&
      !this.state.defaultSizing &&
      options?.grid &&
      options?.title &&
      options?.legend &&
      options?.xAxis &&
      options?.yAxis &&
      options?.visualMap &&
      options?.textStyle &&
      options?.timeline &&
      options?.tooltip
    ) {
      // set default sizing for chart builder when chart is first rendered
      const defaultOptions = {
        grid: options.grid,
        title: options.title,
        xAxis: options.xAxis,
        yAxis: options.yAxis,
        legend: options.legend,
        visualMap: options.visualMap,
        textStyle: options.textStyle,
        timeline: options.timeline,
        tooltip: options.tooltip,
      };
      this.setState({
        defaultSizing: defaultOptions,
      });
    }

    if (
      !isEqual(
        prevProps.dcSpec?.plot?.presentation?.shouldAutoscale,
        this.props.dcSpec?.plot?.presentation?.shouldAutoscale,
      )
    ) {
      if (!this.props.dcSpec?.plot?.presentation?.shouldAutoscale) {
        this.resetAxesWithDefaultMinMax();
      } else {
        autoscale(ecInstance);
      }
    }

    // if users click chart exporting
    if (!prevProps.showModalPng && this.props.showModalPng) {
      // bind "finished" handler so that chart will be exported when chart finishes rendering
      // note that "finished" handler will unbind itself when exporting is done.
      this.bindEventHandlers([{ eventName: 'finished', handler: this.handlers.finished }]);
      // intentionally trigger a "finished" event by calling setOption with empty option
      setOption(ecInstance, {});
      return;
    }

    // Watch for an update from <Customize /> and apply changes to the instance
    if (this.props.customUpdate) {
      this.applyCustomize();
      this.props.setCustomUpdate(false);
      // Exit so we don't re-translate
      return;
    }

    // Re-translate from props when data fields change (for chartbuilder)
    if (
      !isEqual(prevProps.dcSpec.specUpdate, this.props.dcSpec.specUpdate) ||
      !isEqual(prevProps.dcSpec.plot.series, this.props.dcSpec.plot.series) ||
      !isEqual(prevProps.dcSpec.values.transforms, this.props.dcSpec.values.transforms) ||
      !isEqual(prevProps.dcSpec.plot.presentation, this.props.dcSpec.plot.presentation) ||
      !isEqual(prevProps.dcSpec.values.aggregate, this.props.dcSpec.values.aggregate) ||
      !isEqual(prevProps.dcSpec.values.bins, this.props.dcSpec.values.bins) ||
      !isEqual(prevProps.dcSpec.values.rows, this.props.dcSpec.values.rows) ||
      !isEqual(prevProps.width, this.props.width) ||
      !isEqual(prevProps.height, this.props.height)
    ) {
      // check if the new dc spec adds/removes a slider and update bindings accordingly
      if (
        prevProps.dcSpec.plot.series.every((ser) => ser.group?.slider === undefined) && // old dc spec didn't have a slider
        this.props.dcSpec.plot.series.some((ser) => ser.group?.slider !== undefined) // new dc spec has a slider
      ) {
        this.bindEventHandlers([
          { eventName: 'timelinechanged', handler: this.handlers.timelinechanged },
          { eventName: 'contextmenu', handler: this.handlers.contextmenu, getZr: true },
          { eventName: 'click', handler: this.handlers.click, getZr: true },
          { eventName: 'mousemove', handler: this.handlers.mouseMoveOverTimeline, getZr: true },
        ]);
      } else if (
        prevProps.dcSpec.plot.series.some((ser) => ser.group?.slider !== undefined) && // old dc spec had a slider
        this.props.dcSpec.plot.series.every((ser) => ser.group?.slider === undefined) // new dc spec doesn't have a slider
      )
        this.unbindEventHandlers([
          { eventName: 'timelinechanged', handler: this.handlers.timelinechanged },
          { eventName: 'contextmenu', handler: this.handlers.contextmenu, getZr: true },
          { eventName: 'click', handler: this.handlers.click, getZr: true },
          { eventName: 'mousemove', handler: this.handlers.mouseMoveOverTimeline, getZr: true },
        ]);

      this.translateUpdate({
        shouldTranslate: true,
        shouldUpdate: true,
        isFreshUpdate: false,
        width: this.props.width,
      });
    }
    if (prevProps.isPanMode !== this.props.isPanMode) {
      if (this.props.isPanMode) {
        ecInstance.dispatchAction({
          type: 'takeGlobalCursor',
          key: 'dataZoomSelect',
          dataZoomSelectActive: false,
          panSelected: true,
        });
      } else {
        ecInstance.dispatchAction({
          type: 'takeGlobalCursor',
          key: 'dataZoomSelect',
          dataZoomSelectActive: true,
          panSelected: false,
        });
      }
    }

    // If transitioning out of edit mode ((on IB) || (ABC 'Add Caption')), check to save or discard changes
    if (prevProps.chartEditingMode && !this.props.chartEditingMode) {
      if (!this.props.keepChanges) {
        // If not saving changes in ABC
        this.props.setCaption(this.state.initialCaption);
      }
    }

    /*
    Recompute legend & graphic position & size on resize & enter/exit edit mode

    There's also an edge case (the else if block) where ECharts is initialized (an empty chart has
    been rendered), but the react resize detector hasn't passed height & width.
    This happens on the first `componentDidUpdate` after `componentDidMount`. Subsequent
    `componentDidUpdate`s will have defined height & width from the resize detector.

    We force a resize on this edge case because an `AlertBanner` may be pushing the chart down, and
    ECharts needs to handle a resize to display correctly.

    We can't perform this resize in `componentDidMount` because we needed to populate `option`
    first.
    */
    if (
      this.props.width &&
      this.props.height &&
      (prevProps.width !== this.props.width ||
        prevProps.height !== this.props.height ||
        prevProps.chartEditingMode !== this.props.chartEditingMode)
    ) {
      // ToDo: Width and height are sometimes triggering this during initial render
      this.handleResize(ecInstance);
    } else if (this.props.width === undefined && this.props.height === undefined) {
      ecInstance.resize();
    }

    // responsive charts
    const defaultSizingSet = this.state.defaultSizing !== null;
    const initialResize = prevState.defaultSizing === null;
    const dimensionsChanged =
      prevProps.width !== this.props.width || prevProps.height !== this.props.height;
    if (this.props.responsive && defaultSizingSet && (initialResize || dimensionsChanged)) {
      this.handleResizeChart(ecInstance);
    }
    // re-apply our interactable annotations and texts if in chart builder
    // since if series, presentation or aggregate changed, the annotations should
    // also be adjusted(e.g. increase x axis font size will change(decrease) the size of coordinate system)
    if (
      (this.props.inChartBuilder &&
        (!isEqual(prevProps.dcSpec.specUpdate, this.props.dcSpec.specUpdate) ||
          !isEqual(prevProps.dcSpec.plot.series, this.props.dcSpec.plot.series) ||
          !isEqual(prevProps.dcSpec.plot.presentation, this.props.dcSpec.plot.presentation))) ||
      !isEqual(prevProps.dcSpec.values.aggregate, this.props.dcSpec.values.aggregate)
    ) {
      const presentation = cloneDeep(this.props.dcSpec.plot.presentation);
      updateCBsAnnotationAndText(
        presentation,
        ecInstance,
        this.props.width,
        this.props.height,
        this.props.updateGraphicAnnotation,
        this.props.updateAnnotationTextField,
      );
    }

    // if exporting multiple charts from current instance
    // if exporting fromthe popup/chart builder, then queue the chart spec from
    // popup/chart builder's instance
    if (
      this.props.isExportingRequestInitialized &&
      prevProps.isExportingRequestInitialized !== this.props.isExportingRequestInitialized &&
      ((this.props.objectId === this.props.exportObjectID &&
        (this.props.isExportedFromPopOutChart ? this.props.isPopOutChart : true)) ||
        (this.props.inChartBuilder && this.props.isExportedFromChartBuilder))
    ) {
      // all necessary when initialize a bulk exporting from the selected chart
      // and save them to the store
      const currentDcSpec = cloneDeep(this.props.dcSpec);
      const currentOption = cloneDeep(
        this.state.echartsRef.current?.getEchartsInstance().getOption(),
      );
      // queue current echart spec to the store
      this.props.queueBulkExportSpec({
        chartSpec: {
          dcSpec: currentDcSpec,
          option: currentOption,
          width: this.props.width,
          height: this.props.height,
          theme: this.props.theme,
        },
      });
      this.props.addToast({
        toastType: TOAST_SUCCESS,
        length: TOAST_SHORT,
        message: 'A new exporting request has been added to the queue!',
      });
    }
  };

  componentWillUnmount() {
    const ecInstance = this.state.echartsRef.current?.getEchartsInstance();
    if (ecInstance) {
      // clean echart instance when unmounting component(e.g. lazy rendering)
      ecInstance.clear();
      ecInstance.dispose();
    }
  }

  /**
   * Update echarts option with all fns that require a mounted component
   * note that graphic will be updated at very end since they need accurate pixel value
   * @param {Object} ecInstance current echarts instance
   * @param {Object} dcSpec JSON message sent from the BE
   */
  updateOption = (ecInstance, dcSpec) => {
    let option = ecInstance.getOption();
    const { width, height, inChartBuilder } = this.props;
    if (option) {
      // initialize default min max for toolbox
      this.initialDefaultMinMax(option);

      option = {
        ...option,
        visualMap: updateVisualMap(dcSpec, height, option?.visualMap),
        series: updateRidgelineHeight(option, dcSpec),
        title: updateTitleWidth(option.title, width),
        toolbox: addToolbox(
          option,
          dcSpec,
          ecInstance,
          inChartBuilder,
          this.resetAxesWithDefaultMinMax,
        ),
      };

      // Merge options to update the EChart properties that changed from the prev operations
      const mergeOptions = ['title', 'visualMap', 'series', 'toolbox'];
      setOption(ecInstance, option, 'replaceMerge', mergeOptions);

      ecInstance.dispatchAction({
        type: 'takeGlobalCursor',
        key: 'dataZoomSelect',
        dataZoomSelectActive: true,
      });
    }
  };

  /**
   * Process a chart for a smaller display size.
   * @param {Object} ecInstance EC instance
   * @returns {void}
   */
  handleResizeChart = (ecInstance) => {
    const option = ecInstance.getOption();
    const resizeOptions = resizeChart(
      option,
      this.state.defaultSizing,
      this.props.height,
      this.props.width,
    );

    setOption(ecInstance, resizeOptions);
    // force update to re-render the changes to chart
    this.forceUpdate();
  };

  /**
   * Conditionally translates message from BE to echarts option and updates echarts option
   * @param {Object} data message from BE
   * @param {boolean} shouldTranslate flag indicating if the message from BE should be translated
   * to echarts option
   * @param {boolean} isFreshUpdate flag indicating if the update is being processed
   * for the first time
   * @returns {void}
   */
  translateUpdate = ({ data, shouldTranslate, shouldUpdate, isFreshUpdate }) => {
    const ecInstance = this.state.echartsRef.current?.getEchartsInstance();
    if (!ecInstance) return;
    if (!data) ({ dcSpec: data } = this.props);
    if (!(data?.values?.rows?.length >= 0)) return;

    const { height, width } = this.props;

    // TRANSLATE the message to the echart format
    if (shouldTranslate) {
      let option = translateDCtoEChart(
        data,
        ecInstance,
        this.props.theme,
        this.props.inChartBuilder,
        this.props.openCaptionAlert,
        isFreshUpdate,
        width,
        height,
      );

      if (data?.plot?.presentation?.shouldAutoscale) {
        option = autoscaleOption(option, data);
      } else {
        const minXDefault =
          this.state.minXDefault ||
          option.xAxis
            ?.filter((item) => !item.id?.startsWith('annotation_cartesian'))
            .map((item) => item.min);

        // if it has been autoscaled previously, minXDefault = "dataMin"
        // if now shouldAutoscale is false we need to reset the axes to the default min/max
        if (minXDefault.includes(DATA_MIN)) {
          const maxXDefault =
            this.state.minXDefault ||
            option.xAxis
              ?.filter((item) => !item.id?.startsWith('annotation_cartesian'))
              .map((item) => item.max);
          const minYDefault =
            this.state.minXDefault ||
            option.yAxis
              ?.filter((item) => !item.id?.startsWith('annotation_cartesian'))
              .map((item) => item.min);
          const maxYDefault =
            this.state.minXDefault ||
            option.yAxis
              ?.filter((item) => !item.id?.startsWith('annotation_cartesian'))
              .map((item) => item.max);

          option = resetAxesOption(option, minXDefault, maxXDefault, minYDefault, maxYDefault);
        }
      }
      // TODO: Test this merge option. In update it was notMerge: true, in didMount it was empty
      // TODO: Figure out what replaceMode, etc. to use to make this update most efficiently
      // At the moment it replaces the entire object; this is probably unnecessary
      setOption(ecInstance, option, 'notMerge');
    }

    // UPDATE the echart options based on the mounted properties (mostly width/height)
    // These functions update chart layout properties (legend position, title width, etc.)
    if (shouldUpdate) this.updateOption(ecInstance, data);

    mapAnnotationToGraphic(
      this.props.inChartBuilder,
      data.plot.presentation.annotations,
      ecInstance,
      this.props.width,
      this.props.height,
    );
  };

  /**
   * Performs functions that calculate position/sizing based on container width/height
   * @param {Object} ecInstance current echarts instance
   */
  handleResize = (ecInstance) => {
    // call resize() api proactively so echart's width and height will always up-to-date
    ecInstance.resize();

    const { inChartBuilder, dcSpec, width, height } = this.props;
    let option = ecInstance.getOption();

    option = {
      ...option,
      graphic: updateSingleMetric(updateSampleNotice(option.graphic, width), width),
      title: updateTitleWidth(option.title, width),
      visualMap: updateVisualMap(dcSpec, height, option?.visualMap),
    };

    // Merge options to update the EChart properties that changed from the prev operations
    const mergeOptions = ['visualMap', 'title', 'graphic'];
    setOption(ecInstance, option, 'replaceMerge', Array.from(mergeOptions));

    const presentation = cloneDeep(this.props.dcSpec.plot.presentation);
    // update annotations at very end since we need accurate pixel value
    // e.g. visualMap, title, etc will influence the actual pixel values.
    if (inChartBuilder) {
      // if in cb, update with interactable annotations
      updateCBsAnnotationAndText(
        presentation,
        ecInstance,
        this.props.width,
        this.props.height,
        this.props.updateGraphicAnnotation,
        this.props.updateAnnotationTextField,
      );
    } else {
      mapAnnotationToGraphic(
        this.props.inChartBuilder,
        presentation.annotations,
        ecInstance,
        this.props.width,
        this.props.height,
      );
    }
  };

  /**
   * Watches for changes to the title, xaxis, yaxis, and colorOverride from the props
   * and applies the changes to the instance. Annotation edits are handled in <Customize />
   */
  applyCustomize = () => {
    const { title, xaxis, yaxis, colorOverride, overlayAxis, horizontal, annotations } =
      this.props.dcSpec.plot.presentation;

    const ecInstance = this.state.echartsRef.current?.getEchartsInstance();
    let option = ecInstance.getOption();
    const mergeOptions = new Set();

    if (annotations) {
      const { width } = this.props;
      const { height } = this.props;
      const presentationCopy = cloneDeep(this.props.dcSpec.plot.presentation);

      updateCBsAnnotationAndText(
        presentationCopy,
        ecInstance,
        width,
        height,
        this.props.updateGraphicAnnotation,
        this.props.updateAnnotationTextField,
      );
      option = ecInstance.getOption();
    }

    // Check that each property exists and is different,
    // so that we don't do extra merge options
    if (
      title &&
      option.title[0] &&
      (title.text !== option.title[0].text ||
        title.textStyle.fontSize !== option.title[0].textStyle.fontSize)
    ) {
      option.title[0].text = title.text;
      option.title[0].textStyle.fontSize = title.textStyle.fontSize;
      mergeOptions.add('title');
    }
    if (
      xaxis &&
      option.xAxis[0] &&
      (xaxis.text !== option.xAxis[0].name ||
        xaxis.nameTextStyle.fontSize !== option.xAxis[0].nameTextStyle.fontSize ||
        xaxis?.axisLabel.fontSize !== option.xAxis[0]?.axisLabel.fontSize ||
        xaxis?.axisLabel?.prefix !== undefined ||
        xaxis?.axisLabel?.suffix !== undefined)
    ) {
      option.xAxis.forEach((x, i) => {
        if (!option.xAxis[i]._overlayAxis) {
          option.xAxis[i].name = xaxis.text;
        }
        option.xAxis[i].nameTextStyle.fontSize = xaxis.nameTextStyle.fontSize;
        option.xAxis[i].axisLabel.fontSize = xaxis?.axisLabel.fontSize;
        setAxisLabel(option.xAxis[i], xaxis?.axisLabel?.prefix, xaxis?.axisLabel?.suffix);
      });
      mergeOptions.add('xAxis');
    }

    if (
      yaxis &&
      option.yAxis[0] &&
      (yaxis.text !== option.yAxis[0].name ||
        yaxis.nameTextStyle.fontSize !== option.yAxis[0].nameTextStyle.fontSize ||
        yaxis?.axisLabel.fontSize !== option.yAxis[0]?.axisLabel.fontSize ||
        yaxis?.axisLabel?.prefix !== undefined ||
        yaxis?.axisLabel?.suffix !== undefined)
    ) {
      option.yAxis.forEach((y, i) => {
        if (!option.yAxis[i]._overlayAxis) {
          option.yAxis[i].name = yaxis.text;
        }
        option.yAxis[i].nameTextStyle.fontSize = yaxis.nameTextStyle.fontSize;
        option.yAxis[i].axisLabel.fontSize = yaxis?.axisLabel.fontSize;
        setAxisLabel(option.yAxis[i], yaxis?.axisLabel?.prefix, yaxis?.axisLabel?.suffix);
      });
      mergeOptions.add('yAxis');
    }

    if (overlayAxis && !horizontal) {
      option.yAxis.forEach((y, i) => {
        if (option.yAxis[i]._overlayAxis) {
          option.yAxis[i].name = overlayAxis.text;
        }
      });
      mergeOptions.add('yAxis');
    }

    if (overlayAxis && horizontal) {
      option.xAxis.forEach((y, i) => {
        if (option.xAxis[i]._overlayAxis) {
          option.xAxis[i].name = overlayAxis.text;
        }
      });
      mergeOptions.add('xAxis');
    }

    // only when colorOverride is different than before, we re-render series
    // merge series will trigger refresh-like rerendering which provides bad UX
    let shouldUpdateSeries = false;
    let shouldUpdateGraphic = false;
    if (colorOverride && colorOverride.length > 0) {
      [shouldUpdateSeries, shouldUpdateGraphic] = applyColorOverride(
        this.props.dcSpec,
        option,
        colorOverride,
      );
    }
    if (shouldUpdateSeries) mergeOptions.add('series');
    if (shouldUpdateGraphic) mergeOptions.add('graphic');

    setOption(ecInstance, option, 'replaceMerge', Array.from(mergeOptions));
  };

  /**
   * Given an array of bindings, binds each binding and stores it in state.
   *
   * This should be used instead of `ecInstance.on` so we can properly track bindings for
   * removal.
   *
   * The same event may have multiple handlers, but be careful that race conditions don't occur if
   * one of them is called before the others.
   */
  bindEventHandlers(bindings: Array<{ eventName: string, handler: Function, getZr?: boolean }>) {
    const ecInstance: ECharts = this.state.echartsRef.current?.getEchartsInstance();

    for (const { eventName, handler, getZr = false } of bindings) {
      if (getZr) ecInstance.getZr().on(eventName, handler);
      else ecInstance.on(eventName, handler);
    }

    this.setState((state) => ({
      bindings: [...state.bindings, ...bindings],
    }));
  }

  /**
   * Given an array of event bindings, unbinds each binding and removes it from state.
   *
   * This should be used instead of `ecInstance.off` to make sure we're unbinding the correct
   * handler.
   *
   * The same event may have multiple handlers, so we need both the event name and the handler to
   * ensure we're unbinding the correct one.
   */
  unbindEventHandlers(bindings: Array<{ eventName: string, handler: Function, getZr?: boolean }>) {
    const ecInstance: ECharts = this.state.echartsRef.current?.getEchartsInstance();

    for (const { eventName, handler, getZr = false } of bindings) {
      if (getZr) ecInstance.getZr().off(eventName, handler);
      else ecInstance.off(eventName, handler);
    }

    this.setState((state) => ({
      bindings: state.bindings.filter((binding) =>
        bindings.every((b) => b.eventName !== binding.eventName && b.handler !== binding.handler),
      ),
    }));
  }

  /**
   * Process the exporting
   */
  processBulkExportingFromEchart() {
    const ecInstance = this.state.echartsRef.current?.getEchartsInstance();
    const imagesList = [];
    const sliderValList = [];
    const option = ecInstance.getOption();

    ecInstance.on('timelinechanged', this.handlers.timelinechanged);
    const sliderVals = option.timeline[0]?.data;
    let currentSliderIndex = option.timeline[0]?.currentIndex;
    let counter = 0;
    ecInstance.on(
      'finished',
      function () {
        // report current progress to redux store
        // and do not report too frequently: once for every two values
        if (counter % 2 === 0) {
          this.props.updateBulkExportProgress({
            progress: Math.floor((counter / sliderVals.length) * 100),
          });
        }
        // push 64base usl to the list
        imagesList.push(
          ecInstance.getDataURL({
            pixelRatio: 2,
            backgroundColor: '#fff',
          }),
        );

        // save current slider value since exporting may not start from index 0
        sliderValList.push(sliderVals[currentSliderIndex]);
        counter += 1;
        currentSliderIndex += 1;
        currentSliderIndex %= sliderVals.length;

        if (counter <= sliderVals.length) {
          // slightly limit the speed of dispatch action, dispatch too frequently could
          // exhaust browser computing resources and therefore freeze the page
          setTimeout(() => {
            ecInstance.dispatchAction({
              type: 'timelineChange',
              currentIndex: currentSliderIndex,
            });
          }, 200);
        } else {
          // all slider values are processed
          const blobs = [];
          // convert each base64 url to blob
          for (const base64Url of imagesList) {
            const blob = b64toBlob(base64Url);
            blobs.push(blob);
          }
          saveAsZip(blobs, sliderValList, option.title[0].text);
          ecInstance.off('finished');
          ecInstance.off('timelinechanged');
          ecInstance.clear();
          this.props.setIsProcessingExport(false, () => {
            this.props.consumeChartSpecQueue();
          });
        }
      }.bind(this),
    );

    // trigger the initial event
    counter += 1;
    currentSliderIndex += 1;
    currentSliderIndex %= sliderVals.length;
    ecInstance.dispatchAction({ type: 'timelineChange', currentIndex: currentSliderIndex });
  }

  /**
   * This function will initialize the states from echart's axes
   * @param {Object} option echart option
   */
  initialDefaultMinMax(option) {
    if (
      this.state.minXDefault === null ||
      this.state.minXDefault?.every((item) => item === null) ||
      this.state.maxXDefault === null ||
      this.state.maxXDefault?.every((item) => item === null) ||
      this.state.minYDefault === null ||
      this.state.minYDefault?.every((item) => item === null) ||
      this.state.maxYDefault === null ||
      this.state.maxYDefault?.every((item) => item === null)
    ) {
      const minXDefault = option.xAxis
        ?.filter((item) => !item.id?.startsWith('annotation_cartesian'))
        .map((item) => item.min)
        .filter((item) => item !== DATA_MIN);
      const maxXDefault = option.xAxis
        ?.filter((item) => !item.id?.startsWith('annotation_cartesian'))
        .map((item) => item.max)
        .filter((item) => item !== DATA_MAX);
      const minYDefault = option.yAxis
        ?.filter((item) => !item.id?.startsWith('annotation_cartesian'))
        .map((item) => item.min)
        .filter((item) => item !== DATA_MIN);
      const maxYDefault = option.yAxis
        ?.filter((item) => !item.id?.startsWith('annotation_cartesian'))
        .map((item) => item.max)
        .filter((item) => item !== DATA_MAX);

      this.setState({
        minXDefault,
        maxXDefault,
        minYDefault,
        maxYDefault,
      });
    }
  }

  /**
   * This function will reset the axes to original state
   * i.e. Reset Axes
   */
  resetAxesWithDefaultMinMax = () => {
    const ecInstance = this.state.echartsRef.current?.getEchartsInstance();
    const { minXDefault, maxXDefault, minYDefault, maxYDefault } = this.state;
    resetAxes(ecInstance, minXDefault, maxXDefault, minYDefault, maxYDefault);
  };

  /**
   * Event handlers to be used with...
   * ```js
   * ecInstance.on('event-name', this.handlers.eventName);
   * ```
   *
   * We store these so that we can use them when unbinding events, as the handler's reference will
   * be compared when using...
   * ```js
   * ecInstance.off('event-name', this.handlers.eventName);
   * ```
   */
  handlers = {
    click: (params) => {
      // Get click x position
      const clickX = params.offsetX;

      // Get the object which was clicked on
      let tgt = params.topTarget;
      if (tgt !== undefined) {
        // Traverse up its parents until a parent has component info
        while (tgt.__ecComponentInfo === undefined && tgt.parent) {
          tgt = tgt.parent;
        }

        // If the parent component is the timeline, then something on the timeline was clicked on
        if (tgt?.__ecComponentInfo?.mainType === 'timeline') {
          // Get the notches on the timeline
          const timelineChildren = tgt._children[0]._children;
          const notches = timelineChildren.filter((e) => e.shape.symbolType === 'roundrect');

          // Compute distances from each notch to the click point (x-distance is sufficient), and take lowest
          const notchDistancesFromClick = notches.map((e) => Math.abs(e.transform[4] - clickX));
          const { min } = getMinMax(notchDistancesFromClick);
          const minDistIndex = notchDistancesFromClick.indexOf(min);

          // Move slider to position closest to click
          this.state.echartsRef.current.getEchartsInstance().dispatchAction({
            type: 'timelineChange',
            currentIndex: minDistIndex,
          });
        }
      }
    },
    contextmenu: (e) => e.stop(),
    datazoom: (params) => {
      if (!params.batch?.[0]?.from) return;

      const batchZero = params.batch[0];

      const matches = batchZero.dataZoomId.match(/\d+$/);
      const zoomIDKey = parseInt(matches[0], 10);

      const { zoomStack: unzoomStack, zoomingOut: unzoomingOut } = this.state;
      const zoomStack = JSON.parse(JSON.stringify(unzoomStack));
      const zoomingOut = JSON.parse(JSON.stringify(unzoomingOut));

      let { showMessage } = this.state;
      if (!matches[0]) return;

      // Add to zoom history
      if (zoomStack[zoomIDKey]) {
        zoomStack[zoomIDKey].push(params.batch);
      } else {
        zoomStack[zoomIDKey] = [params.batch];
      }
      const index = zoomingOut.indexOf(zoomIDKey);
      if (index > -1) {
        zoomingOut.splice(index, 1);
      }

      if (!showMessage) {
        this.props.addToast({
          toastType: TOAST_INFO,
          length: TOAST_SHORT,
          message: 'Double click to zoom out.',
        });
        showMessage = true;
      }
      this.setState({ zoomStack, zoomingOut, showMessage });
    },
    dblclick: (event) => {
      // If there is no topTarget, we clicked somewhere on the canvas that isn't a grid
      // If we're currently panning, then don't allow double-click to zoom out
      if (!event.topTarget || this.state.panActive) return;

      const echartsInstance = this.state.echartsRef.current.getEchartsInstance();
      const {
        zoomStack: unparsedZoomStack,
        zoomActive: unparsedZoomActive,
        zoomingOut: unparsedZoomingOut,
      } = this.state;
      const zoomStack = JSON.parse(JSON.stringify(unparsedZoomStack));
      const zoomActive = JSON.parse(JSON.stringify(unparsedZoomActive));
      const zoomingOut = JSON.parse(JSON.stringify(unparsedZoomingOut));

      if (!zoomActive || event.target?.parent?.__ecComponentInfo?.mainType === 'toolbox') {
        return;
      }
      const option = echartsInstance.getOption();
      const dataZooms = option.dataZoom.filter((dz) => dz !== null);

      const pointInPixel = [event.offsetX, event.offsetY];
      let gridIndexSelected = -1;

      // Get grid that was selected (vertically-indexed)
      for (let i = 0; i < option.grid.length; i++) {
        if (echartsInstance.containPixel({ gridIndex: i }, pointInPixel)) {
          gridIndexSelected = i;
          break;
        }
      }

      // Convert the vertically-indexed grid to horizontally-indexed
      // So that it refers to the same grid as saved in the initial zoom
      const numGrids = option.grid.length;
      const numRows = Math.ceil(numGrids / 3);
      gridIndexSelected = getHorizontalGridIndex(gridIndexSelected, numRows);

      // Detect if we have an overlay by checking the yAxes (xAxes if horizontal)
      const { horizontal } = this.props.dcSpec.plot.presentation;
      const hasOverlay = horizontal
        ? option.xAxis.some((x) => x._overlayAxis)
        : option.yAxis.some((y) => y._overlayAxis);

      // ToDo: hasOverlay works correctly, but zooming out is still broken for horizontals

      // Exit if no grid was selected
      if (gridIndexSelected === -1) return;

      // Just started zooming? Then, remove the most recent zoom.
      if (!zoomingOut.includes(gridIndexSelected) && zoomStack[gridIndexSelected]) {
        zoomStack[gridIndexSelected].pop();
        zoomingOut.push(gridIndexSelected);
      }

      // If returning to the original zoom, update the datazoom properties
      if (!zoomStack[gridIndexSelected] || zoomStack[gridIndexSelected]?.length === 0) {
        // Only reset the zoom for the selected grid index
        // We can reset all if 'hasOverlay' because we don't allow subplots for overlays
        dataZooms.forEach((dz) => {
          const { xAxisIndex, yAxisIndex } = dz;
          if (
            (xAxisIndex && xAxisIndex.includes(gridIndexSelected)) ||
            (yAxisIndex && yAxisIndex.includes(gridIndexSelected)) ||
            hasOverlay
          ) {
            dz.start = 0;
            dz.end = 100;
            dz.startValue = null;
            dz.endValue = null;
          }
        });

        option.dataZoom = dataZooms;

        setOption(echartsInstance, option);

        echartsInstance.dispatchAction({
          type: 'takeGlobalCursor',
          key: 'dataZoomSelect',
          // Activate or inactivate.
          dataZoomSelectActive: true,
        }); // dispatch this to update brush

        const index = zoomingOut.indexOf(gridIndexSelected);
        if (index > -1) {
          zoomingOut.splice(index, 1);
        }

        // Clean up the zoomStack
        delete zoomStack[gridIndexSelected];

        this.setState({ zoomStack, zoomingOut });
        return;
      }

      // If we're returning to another zoomed level (after multiple zoom-ins),
      // pop the most recent zoom (at the grid index)
      const zoomOption = zoomStack[gridIndexSelected].pop();

      // Update the dataZooms to the popped zoomOption
      if (!hasOverlay) {
        // Update the datazooms for the selected grid
        dataZooms.forEach((dz) => {
          const { xAxisIndex, yAxisIndex } = dz;
          if (xAxisIndex && xAxisIndex.includes(gridIndexSelected)) {
            dz.start = null;
            dz.end = null;
            dz.startValue = zoomOption[0].startValue;
            dz.endValue = zoomOption[0].endValue;
          }
          if (yAxisIndex && yAxisIndex.includes(gridIndexSelected)) {
            dz.start = null;
            dz.end = null;
            dz.startValue = zoomOption[1].startValue;
            dz.endValue = zoomOption[1].endValue;
          }
        });
      } else {
        // Update the datazooms for a single grid, with an overlay axis
        // Change x-zoom
        dataZooms[0].start = null;
        dataZooms[0].end = null;
        dataZooms[0].startValue = zoomOption[0].startValue;
        dataZooms[0].endValue = zoomOption[0].endValue;

        // Change y-zoom
        dataZooms[1].start = null;
        dataZooms[1].end = null;
        dataZooms[1].startValue = zoomOption[1].startValue;
        dataZooms[1].endValue = zoomOption[1].endValue;

        // Change overlay-zoom
        dataZooms[2].start = null;
        dataZooms[2].end = null;
        dataZooms[2].startValue = zoomOption[2].startValue;
        dataZooms[2].endValue = zoomOption[2].endValue;
      }

      option.dataZoom = dataZooms;
      setOption(echartsInstance, option);

      echartsInstance.dispatchAction({
        type: 'takeGlobalCursor',
        key: 'dataZoomSelect',
        // Activate or inactivate.
        dataZoomSelectActive: true,
      }); // dispatch this to update brush

      this.setState({ zoomStack, zoomingOut });
    },
    globalcursortaken: (params) => {
      const ecInstance = this.state.echartsRef.current?.getEchartsInstance();
      const zoomActive = params.dataZoomSelectActive || false;

      // Reset the zoom
      if (params.resetZoom) {
        this.setState({ zoomStack: {}, zoomingOut: [] });
        return;
      }

      // toggle panning
      if (params.panSelected) {
        ecInstance.getZr().setCursorStyle('move');
        this.setState((state) => {
          const { dataZoom } = ecInstance.getOption();

          dataZoom.forEach((dz) => dz && (dz.moveOnMouseMove = !state.panActive));

          setOption(ecInstance, {
            dataZoom,
          });
          return { panActive: !state.panActive };
        });
        ecInstance.getZr().setCursorStyle('move');
        return;
      }
      ecInstance.getZr().setCursorStyle('pointer');

      if ((zoomActive || params.key === 'brush' || params.panDisable) && this.state.panActive) {
        const { dataZoom } = ecInstance.getOption();
        for (let i = 0; i < dataZoom.length; i++) {
          dataZoom[i].moveOnMouseMove = false;
        }
        this.setState({ panActive: false, zoomActive }, () => {
          setOption(ecInstance, {
            dataZoom,
            tooltip: [{ show: !zoomActive }],
          });
        });
      } else if (zoomActive !== this.state.zoomActive) {
        // Update the zoomActive state
        this.setState({ zoomActive });
        setOption(ecInstance, { tooltip: [{ show: !zoomActive }] });
      }
    },
    finished: () => {
      const ecInstance = this.state.echartsRef.current.getEchartsInstance();
      if (!this.props.inChartBuilder && this.state.panActive)
        ecInstance.getZr().setCursorStyle('move');
      if (this.props.showModalPng) {
        this.props.updateModalPng();
        exportPlot(ecInstance);
      }
      // unbind the finished when exporting is done
      this.unbindEventHandlers([{ eventName: 'finished', handler: this.handlers.finished }]);
    },
    mouseMoveSetCursor: () => {
      if (this.state.panActive) {
        const ecInstance = this.state.echartsRef.current.getEchartsInstance();
        ecInstance.getZr().setCursorStyle('move');
      }
    },
    mouseMoveOverTimeline: (params) => {
      const ecInstance = this.state.echartsRef.current.getEchartsInstance();
      let tgt = params.topTarget;
      if (tgt !== undefined) {
        // Traverse up its parents until a parent has component info
        while (tgt.__ecComponentInfo === undefined && tgt.parent) {
          tgt = tgt.parent;
        }
        if (tgt.__ecComponentInfo?.mainType === 'timeline') {
          ecInstance.getZr().setCursorStyle('pointer');
        } else if (this.state.panActive) {
          ecInstance.getZr().setCursorStyle('move');
        }
      }
    },
    timelinechanged: (params) => {
      const { isExportingCopy } = this.props;
      let dcSpec;
      const ecInstance = this.state.echartsRef.current?.getEchartsInstance();
      // load spec and update from different props if instance is a copy
      if (isExportingCopy) {
        const { chartSpec } = this.props;
        ({ dcSpec } = chartSpec);
      } else {
        ({ dcSpec } = this.props);
      }
      const dcSpecSeries = dcSpec.plot.series;

      let option: EChartsOption = ecInstance.getOption();

      if (!isExportingCopy) {
        // Turn off animation for the new instance
        option.animation = false;
      }

      // Get the new value to filter
      const newSliderValue = option.timeline[0].data[params.currentIndex];
      // Get the column that we're applying the filter to
      const sliderColumn = option.timeline[0].metadata.column;

      // update slider title with new value
      const sliderTitle = option.title.find((t) => t !== null && t.id === TitleTypes.SLIDER);
      sliderTitle.text = `{column|${sliderColumn}: }{value|${newSliderValue}}`;

      // bar charts need manual rerendering
      if (dcSpecSeries.some((e) => MANUAL_RERENDER_TYPES.includes(e.type))) {
        // reset the datasets
        option.dataset = [];
        option.series = [];

        // Filter any disabled or None items
        const aggregate = filterNoneAggregates(dcSpec.values.aggregate);
        const bins = filterDisabled(dcSpec.values.bins);
        const transforms = filterDisabled(dcSpec.values.transforms);

        // Determine if we used the backend compute to get our data
        const usedCompute = shouldUseBackendCompute({
          computeSpec: { aggregate, bins, transforms },
          numRows: dcSpec.values.dataSampleLimit ?? null,
        });

        // Re-apply cleaning strategies
        const isFreshUpdate = false; // If true, we would display the data cleaning prompt for every slide change
        if (!usedCompute) {
          dcSpec = cleanData(dcSpec, isFreshUpdate);
        } else {
          dcSpec = cloneDeep(dcSpec);
        }
        option.dataset = initDataset(dcSpec);

        option = performComputations({
          aggregate,
          bins,
          dcSpec,
          option,
          sliderColumn,
          transforms,
          newSliderValue,
          usedCompute,
        });

        // recreate the series and datasets that come with them
        createSeries(dcSpec, option, this.props.width, this.props.height, ecInstance, {
          sliderValue: newSliderValue,
        });
        if (dcSpecSeries[0].type === ChartTypes.donut) {
          let legendSeries = [];
          const nameWidths = [];

          for (let i = 0; i < option.series.length; i++) {
            const dataMap = option.series[i].data.map((item) => item.name);
            const temp2 = new Set(dataMap);
            const temp3 = Array.from(temp2);
            const legendSeriesPart = temp3.map((legendItem) => {
              const itemName = typeof legendItem === 'string' ? legendItem.trim() : legendItem;
              nameWidths.push(DOMUtils.getTextParams(itemName, getTextFont(12), 'width'));
              return { name: legendItem || 'null' };
            });
            legendSeries.push(...legendSeriesPart);
          }
          legendSeries = sort(legendSeries, (x) => x.name);

          if (option.legend[0]) option.legend[0].data = legendSeries;
        }

        // also re-apply customized color if we have one since series are recreated above
        if (
          (dcSpec?.plot?.presentation?.colorOverride &&
            dcSpec.plot.presentation.colorOverride.length) > 0
        ) {
          const { colorOverride } = dcSpec.plot.presentation;
          applyColorOverride(dcSpec, option, colorOverride);
        }

        // update series if it is ridgeline
        option.series = updateRidgelineHeight(option, dcSpec);

        setOption(ecInstance, option, 'replaceMerge', [
          'dataset',
          'series',
          'title',
          'visualMap',
          'legend',
        ]);

        // following option setting is not necessary for copied instance
        if (isExportingCopy) {
          return;
        }

        // turn animation back on now that data is updated
        option.animation = true;

        setOption(ecInstance, option, null, null, true, true);
        if (this.props.dcSpec?.plot?.presentation?.shouldAutoscale) autoscale(ecInstance);
        return;
      }

      option.dataset.map((e) => {
        if (e.id === 'slider_filter') {
          e.transform.config.value = newSliderValue;
        }
        return e;
      });

      if (isExportingCopy) {
        setOption(ecInstance, option, 'replaceMerge', ['dataset']);
        return;
      }

      setOption(ecInstance, option);
      // turn animation back on now that data is updated
      option.animation = true;
      setOption(ecInstance, option, null, null, true, true);
      if (this.props.dcSpec?.plot?.presentation?.shouldAutoscale) autoscale(ecInstance);
    },
  };

  render() {
    return (
      <>
        {this.props.isExportingCopy ? (
          // this is an invisible echart copy: all downloading processes will be made on this instance
          <DCEChart
            ref={this.state.echartsRef}
            data={this.props.chartSpec.option}
            theme={this.props.chartSpec.theme}
            metadata={this.props.chartSpec.dcSpec?.values?.metadata}
          />
        ) : (
          <>
            <DCEChart
              ref={this.state.echartsRef}
              data={
                this.state.echartsRef.current?.getEchartsInstance().getOption()
                  ? this.state.echartsRef.current?.getEchartsInstance().getOption()
                  : {} // send empty option if no echarts instance exists yet
              }
              theme={this.props.theme}
              metadata={this.props.dcSpec?.values?.metadata}
            />
          </>
        )}
      </>
    );
  }
}

DCPlotV2.displayName = 'DCPlotV2';

const mapStateToProps = (state) => ({
  theme: state.settings.chartTheme,
  isExportingRequestInitialized: selectIsExportingRequestInitialized(state),
  exportObjectID: selectExportObjectID(state),
  isExportedFromPopOutChart: selectIsExportedFromPopOutChart(state),
  isExportedFromChartBuilder: selectIsExportedFromChartBuilder(state),
});

const ConnectedDCPlotV2 = connect(
  mapStateToProps,
  {
    addToast,
    queueBulkExportSpec,
    consumeChartSpecQueue,
    updateBulkExportProgress,
  },
  null,
  { forwardRef: true },
)(DCPlotV2);

export default React.forwardRef((props, ref) => (
  <ConnectedDCPlotV2 {...props} forwardedRef={ref} />
));
