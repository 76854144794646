import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  createLlmCredential,
  deleteLlmCredential,
  getLlmCredentials,
  removeDefaultLlmCredential,
  setDefaultLlmCredential,
} from '../../api/credentials.api';
import { TOAST_BOTTOM_RIGHT, TOAST_ERROR, TOAST_LONG } from '../../constants/toast';
import {
  CREATE_CREDENTIAL_REQUEST,
  DELETE_CREDENTIAL_REQUEST,
  GET_CREDENTIALS_FAILURE,
  GET_CREDENTIALS_REQUEST,
  GET_CREDENTIALS_SUCCESS,
  SET_DEFAULT_CREDENTIAL_REQUEST,
  createCredentialFailure,
  createCredentialSuccess,
  deleteCredentialFailure,
  deleteCredentialSuccess,
  getCredentialsFailure,
  getCredentialsRequest,
  getCredentialsSuccess,
  setCredentialsAction,
  setDefaultCredentialFailure,
  setDefaultCredentialSuccess,
} from '../actions/credentials.action';
import { addToast } from '../actions/toast.actions';
import { raceGenerator } from './home_screen.saga';
import { selectAccessToken } from './selectors';

function* getCredentialsRequestWorker() {
  const accessToken = yield select(selectAccessToken);
  try {
    // fetch the list of credentials
    const response = yield call(getLlmCredentials, accessToken);
    const { data } = response;
    data.forEach((credential) => {
      // for each credential add an 'id' column (neccessary for material table)
      credential.id = credential.Id;
      // convert the date to a readable string
      credential.Date = new Date(credential.Date).toLocaleString();
    });
    // add to redux
    yield put(getCredentialsSuccess({ credentials: data }));
  } catch (error) {
    yield put(
      addToast({
        toastType: TOAST_ERROR,
        length: TOAST_LONG,
        message: 'Failed to get credentials',
        position: TOAST_BOTTOM_RIGHT,
      }),
    );
    yield put(getCredentialsFailure({ error }));
  }
}

function* createCredentialRequestWorker({ credentialPayload }) {
  // when request is sent, close the CredentialsAction component
  yield put(setCredentialsAction({ actionType: null }));
  const accessToken = yield select(selectAccessToken);
  try {
    // make a call to create the credential
    yield call(createLlmCredential, accessToken, credentialPayload);
    // refresh the list of credentials - we need to do this because the default
    // flag on a different credential could have changed if is_default was set.
    yield put(getCredentialsRequest());
    yield raceGenerator(GET_CREDENTIALS_SUCCESS, GET_CREDENTIALS_FAILURE);
    yield put(createCredentialSuccess());
  } catch (error) {
    yield put(
      addToast({
        toastType: TOAST_ERROR,
        length: TOAST_LONG,
        message: 'Failed to create credential',
        position: TOAST_BOTTOM_RIGHT,
      }),
    );
    yield put(createCredentialFailure({ error }));
  }
}

function* deleteCredentialRequestWorker({ credentialId }) {
  // when request is sent, close the CredentialsAction component
  yield put(setCredentialsAction({ actionType: null }));
  const accessToken = yield select(selectAccessToken);
  try {
    // delete the response
    const response = yield call(deleteLlmCredential, accessToken, credentialId);
    const { data } = response;
    // delete the deleted credential from redux
    yield put(deleteCredentialSuccess({ credentialId: data.connection_id }));
  } catch (error) {
    yield put(
      addToast({
        toastType: TOAST_ERROR,
        length: TOAST_LONG,
        message: 'Failed to delete credential',
        position: TOAST_BOTTOM_RIGHT,
      }),
    );
    yield put(deleteCredentialFailure({ error }));
  }
}

function* setDefaultCredentialRequestWorker({ credentialId, removeDefault }) {
  // when request is sent, close the CredentialsAction component
  yield put(setCredentialsAction({ actionType: null }));
  const accessToken = yield select(selectAccessToken);
  try {
    // set the default credential in the db
    if (removeDefault) {
      yield call(removeDefaultLlmCredential, accessToken, credentialId);
    } else {
      yield call(setDefaultLlmCredential, accessToken, credentialId);
    }
    // refresh the list of credentials - we need to do this because the default
    // flag on a different credential could have changed if is_default was set.
    yield put(getCredentialsRequest());
    yield raceGenerator(GET_CREDENTIALS_SUCCESS, GET_CREDENTIALS_FAILURE);
    yield put(setDefaultCredentialSuccess());
  } catch (error) {
    yield put(
      addToast({
        toastType: TOAST_ERROR,
        length: TOAST_LONG,
        message: 'Failed to set a default credential',
        position: TOAST_BOTTOM_RIGHT,
      }),
    );
    yield put(setDefaultCredentialFailure({ error }));
  }
}

export default function* () {
  yield takeLatest(GET_CREDENTIALS_REQUEST, getCredentialsRequestWorker);
  yield takeLatest(CREATE_CREDENTIAL_REQUEST, createCredentialRequestWorker);
  yield takeLatest(DELETE_CREDENTIAL_REQUEST, deleteCredentialRequestWorker);
  yield takeLatest(SET_DEFAULT_CREDENTIAL_REQUEST, setDefaultCredentialRequestWorker);
}
