import InfoOutlined from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import '../../components/ConnectionEditor/ConnectionEditor.scss';
import { FORM_GRID } from '../../constants/connection';

const InfoTooltip: React.FC<{ text: string }> = ({ text }) => {
  return (
    <Tooltip title={text}>
      <IconButton className={`${FORM_GRID}-IconButton`} disableRipple>
        <InfoOutlined />
      </IconButton>
    </Tooltip>
  );
};

export default InfoTooltip;
