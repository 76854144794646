import AccountTreeOutlined from '@mui/icons-material/AccountTreeOutlined';
import AccountTreeRoundedIcon from '@mui/icons-material/AccountTreeRounded';
import GridOnIcon from '@mui/icons-material/GridOn';
import GridOnOutlined from '@mui/icons-material/GridOnOutlined';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import MenuBookOutlined from '@mui/icons-material/MenuBookOutlined';

// Chat Panel
export const CHAT_PANEL = {
  BOTTOM: '3.3em',
  HEIGHT: '19em',
};

// Types of Visual Outputs
export const VISUAL_TYPES = {
  VIZ: 'viz',
  TABLE: 'table',
  NOTE: 'note',
  TABVISUAL: 'tabvisual',
  PIVOT: 'pivot table',
  TEXT: 'text',
};

export const FEATURE_IMPORTANCE = 'Feature Importance';

// Chat Bubble
export const NOTE_MESSAGE = 'Saved note.';
export const CONFIRM_LOAD_DIR_MESSAGE = 'confirm_load_from_dir';
export const FILLER_PROMPTS = new Set([
  'How can I help you?',
  "Alright, what's next?",
  'What can I do next?',
  'Anything else I can do for you?',
  CONFIRM_LOAD_DIR_MESSAGE,
]);
export const FAIL_PHRASES = [
  'oops',
  'error',
  'invalid',
  'sorry',
  'afraid',
  'unable',
  'oh no',
  'incorrect',
  'I did not understand',
  '?',
  'each step of the recipe',
];

export const REPLAY_MESSAGES = {
  START: 'Replay started.',
  RESTARTED: 'Replay restarted.',
  END: 'Replay ended.',
  FAILED: 'Replay failed. Edit this recipe in the Recipe Editor to fix it.',
  FAIL: 'That action failed, continuing replay.',
  TRY_AGAIN: 'That action failed, please try again.',
  ADD: 'Enter the new action to be run.',
  SKIP: "I've skipped that action.",
  CANCEL_ADD: "OK, I won't add an action.",
  CONTINUE: 'Replay the rest of the recipe until the next break point.',
  LAST_STEP: 'Last step',
};

export const SAVE_SESSION_MESSAGES = {
  SUCCESS: (name = '') => `I saved this replay as a verified version of the recipe ${name}.`,
  FAILURE: () => `I'm sorry, I couldn't save that recipe due to an error.`,
};

// Messages used to control a stepwise replay
export const REPLAY_CONTROLS = {
  ADD: 'Add action',
  SKIP: 'Skip action',
  PAUSE: 'Pause replay',
  CONTINUE: 'Continue replay',
  ADD_BREAK: 'Add breakpoint',
  REMOVE_BREAK: 'Remove breakpoint',
  END: 'End replay',
  RESTART: 'Restart replay',
};

// Messages corresponding to 'ready' class
export const SESSION_READY_MESSAGES = {
  MORNING:
    "Good morning! I'm Ava, your data analytics assistant. You can talk to me using DataChat English.",
  AFTERNOON:
    "Good afternoon! I'm Ava, your data analytics assistant. You can talk to me using DataChat English.",
  EVENING:
    "Good evening! I'm Ava, your data analytics assistant. You can talk to me using DataChat English.",
};

// Commands
export const CMD = {
  YES: 'yes',
  NO: 'no',
  CANCEL: 'cancel',
  TOGGLE_SUGGESTIONS_ANALYSIS: 'toggle suggestions',
};

// Header
export const HEADER = {
  COLOR: 'rgb(255, 255, 255)', // Set to rgb because Cypress checks for rgb and not hex.
  MAX_INFO_LENGTH: 35, // Max length of header info field
  SLOGAN: 'Insights, Simplified',
};

export const DASHBOARD = {
  PLACEHOLDER_COUNT: 3,
};

export const HOME_PAGE = {
  WEB: 'web',
  ASK: 'ask',
};

// User Configurations
export const DEFAULT_USER_CONFIG = {
  color: '#FFFFFF',
  loadCard: true,
  autoPopup: true,
  autoSizeColumns: false,
  showGELCommand: false,
  currentDatasetReminder: false,
  defaultHomePage: HOME_PAGE.WEB,
  hasAppName: true,
  hasUserIdentity: true,
  hasClearScreenSkill: true,
  hasDashboards: true,
  hasSuggestionsAnalysisSkill: true,
  hasGoodbyeSkill: true,
  hasLogoDisplay: true,
  hasClickableLogo: true,
  hasHeaderInfoDisplay: true,
  hasMenuDisplay: true,
  hasUploadFileManager: true,
  hasDeleteFileManager: true,
  hasLogout: true,
  experimentFlag: false,
  newInsightsBoardFlag: true,
  defaultAppViews: {},
  utteranceTimeout: 0,
};

export const HOME_MODES = {
  app: 'Apps',
  dashboard: 'Insights Board',
};

// App Context
export const CONTEXTS = {
  // Period between a start session request and the first message that indicates that the server is ready
  // It means that the server is booting up.
  STARTUP: 'starting up',
  // Period when the server is not doing anything
  // It means the server is free
  REST: 'resting',
  // Period when the server is preparing to start a task.
  // It means the server is preparing to do something.
  PRE_TASK: 'pre-task',
  // Period between any message and the first message that indicates that the server is ready
  // It means that the server is currently working on something.
  WORKING: 'working',
  // The server is working on a question. Replaced AskContext.GENERATING
  ASKING: 'asking',
};

/** CONTEXTS states that we can cancel */
export const CANCELLABLE_CONTEXTS = [CONTEXTS.WORKING, CONTEXTS.ASKING];

// Skill Event
// Used to indicate the server's state with respect to an ongoing skill.
export const SKILL_EVENTS = {
  // Used to indicate that the text should be displayed in a new text bubble.
  NEW_BUBBLE: 'new bubble',
  // Used to indicate that the context is still happening and the text should be appended to the current bubble.
  IN_CONVERSATION: 'in conversation',
  // Used to indicate that the context is completed (an utterance has fully run)
  DONE: 'done',
  // Signal FE to open a new tab with the link included
  // However, it is a bit weird to make it a type of "skill_event"
  // as it is more of an action.
  OPEN_LINK: 'open link',
  NAVIGATE: 'navigate',
  // Used to indicate that the message is a link to copy to the user's clipboard
  COPY_LINK: 'copy link',
  // Contains skill's exit code, sent when a message sets it's exit code
  EXIT_CODE: 'exit code',
};

// Server Status
export const CLASS = {
  READY: 'ready',
  SUCCESS: 'success',
  QUESTION: 'question',
  UPDATE: 'update',
  CANCEL: 'cancel',
  FAILURE: 'failure',
  INFORMATIONAL: 'informational',
  START_FAILURE: 'start failure', // Indicates that the app failed to start
};

/**
 * Skill exit codes
 */
export const EXIT_CODE = {
  FAILURE: 'FAILURE',
  SUCCESS: 'SUCCESS',
  UNKNOWN: 'Unknown',
};

/**
 * Execution status of a skill
 */
export const SKILL_STATUS = {
  EXECUTED: 'Executed',
  EXECUTING: 'Executing',
  UNEXECUTED: 'Unexecuted',
};

/**
 * Visibility status of data products
 */
export const PRODUCT_STATUS = {
  VISIBLE: 'visible',
  HIDDEN: 'hidden',
};

/**
 * Types of data products
 */
export const PRODUCT_TYPE = {
  AUTO_CHART: 'auto_chart',
  CHART: 'chart',
  DATASET: 'dataset',
  FILE: 'file',
  MODEL: 'stored_ml_model',
  NOTE: 'note',
  TABLE: 'table',
};

export const INFORMATIONAL = {
  DATASET: "OK, here's a sample of the dataset.",
};

// Placeholders
export const PLACEHOLDERS = {
  WORKING: 'Still working and have been for ',
  RESTING: 'Enter a skill',
  MAX_LENGTH: 120,
};

// Password Input
export const PASSWORD_REQUIREMENTS = {
  MIN_LENGTH: 14,
  MIN_SCORE: 4,
};

// Name Input
export const NAME_REQUIREMENTS = {
  MIN_LENGTH: 1,
  MAX_LENGTH: 150,
};

// Message Senders
export const SENDERS = {
  USER: 'user',
  SERVER: 'DataChatApp',
};

// Timeout in milliseconds
export const TIMEOUT = Object.freeze({
  HIGHLIGHT: 2000,
  FLICKER: 1000,
  SHOW_CHAT_TRAY: 3000,
  CHAT_TRAY_DEBOUNCE: 500,
  GOODBYE: 500,
  DROP_DOWN_MENU: 500, // Equal to duration of transition
});

// App Login Constants
export const SB_CONFIG = Object.freeze({
  SB_COLS: { xs: 3, xxs: 2 },
  SB_MARGINS: [20, 40],
  SB_BPS: { xs: 480, xxs: 0 },
  SB_PAD: [50, 20],
});

// Dashboard Constants
export const DASHBOARD_CONFIG = Object.freeze({
  SB_COLS: {
    lg: 12,
    md: 10,
    sm: 6,
    xs: 4,
    xxs: 2,
  },
  SB_MARGINS: [20, 40],
  SB_BPS: {
    lg: 1200,
    md: 996,
    sm: 768,
    xs: 480,
    xxs: 0,
  },
  SB_PAD: [50, 20],
});

export const UPDATE_SCREEN_DIMENSIONS_DEBOUNCE_TIMING = 1000;

export const NOTIFICATION_AGREEMENT = 'I have read and agree to this message.';

export const MATERIAL_TABLE_SCROLL_SELECTOR = '[style="overflow-y: auto;"]';

// File Cache Extensions
// TODO: Share constant with BE
export const CACHE_EXT = 'tmp_cache';

// Linter failure (for example on the dashboard utterance boxes)
export const LINTER_FAILURE = 'Empty Utterance';

export const REPLAY_START = 'Replay the recipe ';

// Skill Message Type Constants
export const SKILL_MESSAGE_FIXED = 'fixed'; // Type for the fixed message at the top of a skill message panel
export const SKILL_MESSAGE_CHART = 'chart'; // Type for a message with a chart and its description

// Skill Prefixes for Messages
export const VISUALIZE_PREFIX = 'viz';

// custom type used to represent integer and float representation types
export const NUMERIC_TYPE = 'numeric';

// Dataset data types (see type list in representation.py)
export const COLUMN_TYPES = {
  INTEGER: 'Integer',
  FLOAT: 'Float',
  STRING: 'String',
  BOOLEAN: 'Boolean',
  DATE: 'Date',
  TIME: 'Time',
  TIMESTAMP: 'Timestamp',
  INTERVAL: 'Interval',
  JSON: 'JSON',
};

// Date Units:
export const DATE_PARTS = ['Years', 'Months', 'Weeks', 'Days', 'Hours', 'Minutes', 'Seconds'];

export const SUPPORTED_DATE_UNITS = ['Days', 'Months', 'Years'];

export const SUPPORTED_TIME_UNITS = ['Hours', 'Minutes', 'Seconds'];

export const SESSION_VIEW = Object.freeze({
  NOTEBOOK: {
    Name: 'notebook',
    Icon: MenuBookIcon,
    OutlinedIcon: MenuBookOutlined,
    MinimumInformationImportance: 0,
  },
  GRID: {
    Name: 'grid',
    Icon: GridOnIcon,
    OutlinedIcon: GridOnOutlined,
    MinimumInformationImportance: 50,
  },
  GRAPH: {
    Name: 'graph',
    Icon: AccountTreeRoundedIcon,
    OutlinedIcon: AccountTreeOutlined,
    MinimumInformationImportance: 50,
  },
});

export const DEFAULT_NUM_ROWS_GRID = 100;

// ToDo: Temporarily reduce to 1 for when a user tries to load a dataset that is too large
// Set back to 2 when retrieval is expected to fail less
export const SAMPLE_TRIAL_COUNT = 3;
// Compute is a blocking call, so we don't want to retry if it fails.
export const COMPUTE_SAMPLE_TRIAL_COUNT = 0;
export const DEFAULT_NUM_ROWS_IB = 500;

// Indicates the default importance so we can treat those specifically.
export const DEFAULT_INFORMATION_IMPORTANCE = 100;
// Indicates the minimum importance to pop up the chat panel for.
export const INFORMATION_IMPORTANCE_TO_NOTIFY_USER = 75;

// Used to convert string to integer in Base 10
export const BASE_TEN = 10;

// Keyboard key code constants
export const KEYBOARD_KEYS = {
  ARROW_DOWN: 'ArrowDown',
  ARROW_UP: 'ArrowUp',
  BACKSPACE: 'Backspace',
  DELETE: 'Delete',
  ENTER: 'Enter',
  ESC: 'Escape',
};

// https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/button
export const MOUSE_BUTTONS = {
  LEFT: 0,
  WHEEL: 1,
  RIGHT: 2,
};

export const CONTACT_FORM_DEFAULTS = {
  ERROR_SUBJECT: 'Skill Failure Investigation',
  GENERAL_ERROR_SUBJECT: 'General Error Investigation',
  AVA_ERROR_SUBJECT: 'Ava Error Investigation',
  INSIGHTS_BOARD_ERROR_SUBJECT: 'Insights Board Error Investigation',
  SUBJECT_QUESTION: 'Question',
  SUBJECT_BUG_REPORT: 'Bug Report',
  SUBJECT_FEEDBACK: 'Feedback',
  SUBJECT_FEATURE_REQUEST: 'Feature Request',
  SUBJECT_OTHER: 'Other',
};

export const CONTACT_FORM_ERROR_MESSAGE_TEMPLATE = (
  utterance,
  errorText,
  sessionId,
  utteranceUUID,
) => {
  if (utterance && utteranceUUID) {
    return `Session ID:\n${sessionId}\n\nUtterance #${utteranceUUID}:\n${utterance}\n\nError Message:\n${errorText}`;
  }
  return `Session ID:\n${sessionId}\n\nError Message:\n${errorText}`;
};

export const CONTACT_FORM_GENERAL_ERROR_MESSAGE_TEMPLATE = (timeStamp, errorText) => {
  let textContent = '';
  textContent += timeStamp ? `Report Time:\n${timeStamp}\n\n` : ``;
  textContent += errorText ? `Error Message:\n${errorText}\n\n` : ``;
  textContent += `Additional Details:\nPlease provide any details about the issue, such as what action you were taking.\n\n`;
  return textContent;
};

/**
 * Constructs the error details for the contact form
 * @param {*} error
 * @param {string} session
 * @param {string} pathName
 * @returns string
 */
export const CONTACT_FORM_ERROR_DETAILS = (error, session, pathName) => {
  let textContent = '';
  try {
    if (error.isAxiosError) {
      const data = decodeURIComponent(error.config?.data);
      textContent += `apiURL: ${error.config?.url}\n data:\n${data}\n Method:${error.config?.method}\n\n`;
    }
    if (session) {
      textContent += `SessionID:\n ${session} \n\n`;
    }
    if (pathName) {
      textContent += `PathName: \n ${pathName}`;
    }
    return textContent;
  } catch {
    return '';
  }
};

// this is the name of the root node for every session
// Every session has a root node that is the parent of all other nodes
export const SESSION_ROOT_NODE = 'SESSION_ROOT_NODE';
