import axios from 'axios';
import { endpoints, stripeEndpoints } from '../constants/endpoints';

export const getConfig = () => axios.get(endpoints.web_app_config);

export const getPaymentConfig = (accessToken) =>
  axios.get(stripeEndpoints.config, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
