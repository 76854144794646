import {
  DROP_SUGGESTIONS,
  LOAD_SUGGESTIONS_FAILURE,
  LOAD_SUGGESTIONS_REQUEST,
  LOAD_SUGGESTIONS_SUCCESS,
  SHOW_SUGGESTIONS_LOADING_INDICATOR,
} from '../actions/suggestions.actions';

export const initialState = {
  textboxId: undefined,
  suggestions: [],
  prompts: [],
  autocompleteChannel: {},
  newAutoCompleteResponse: false,
  // variables for storing suggestions for constrained textboxes
  partialSuggestions: [],
  partialPrompts: [],
  // To identify which textbox and field the suggestions should go to
  associatedTextboxId: undefined,
  associatedFieldId: undefined,
  error: undefined,
  isRequesting: false,
  showLoadingIndicator: false,
  timeOfRequest: undefined, // Time at which request was sent.
  timeOfResponse: undefined, // Time at which response was received.
  timeTaken: undefined, // Time taken between the response and request.
  lastRequestUtterance: undefined, // the utterance which is currently being requested
  successMessage: undefined, // The latest patrial utterance which successfully received its suggestion list
  res: undefined, // The list of suggestions as pairs of suggestion result and prompt result
};

/**
 * @param {Object} state
 * @param {Object} action
 * @returns {Object} new state
 */
export default (state = initialState, action) => {
  const { autocompleteChannel } = state;
  let { suggestions, prompts } = state;
  let hasContextId;
  let textboxId;
  let hasTextboxId;
  let fieldId;
  let hasFieldId;
  switch (action.type) {
    case LOAD_SUGGESTIONS_REQUEST:
      if (action.textboxId === 0) {
        suggestions = undefined;
        prompts = [];
      } else {
        autocompleteChannel[action.textboxId] = { suggestions: [], prompts: [], res: undefined };
      }
      return {
        ...state,
        textboxId: action.textboxId,
        error: undefined,
        isRequesting: true,
        timeOfRequest: action.timeOfRequest,
        suggestions,
        prompts,
        autocompleteChannel,
        lastRequestUtterance: action.message,
      };
    case LOAD_SUGGESTIONS_SUCCESS:
      // While mocking auto-complete response in test mode,
      // we don't have ContextId for convenience
      hasContextId = action.suggestions[action.suggestions.length - 1] === 'ContextId';
      hasTextboxId = action.suggestions[action.suggestions.length - 2] === 'TextboxId';
      textboxId = action.prompts[action.prompts.length - 2];
      hasFieldId = action.suggestions[action.suggestions.length - 3] === 'FieldId';
      fieldId = action.prompts[action.prompts.length - 3];
      if (!hasContextId || !hasTextboxId || !hasFieldId) {
        // Invalid AC response with incomplete identifiers
        return {
          ...state,
          isRequesting: false,
          showLoadingIndicator: false,
          timeOfResponse: action.timeOfResponse,
          timeTaken: action.timeTaken,
          successMessage: action.message,
          lastRequestUtterance: undefined,
        };
      }

      // Delete Identifiers after extraction
      if (hasContextId) {
        action.suggestions = action.suggestions.slice(0, -1);
        action.prompts = action.prompts.slice(0, -1);
      }

      if (hasTextboxId) {
        action.suggestions = action.suggestions.slice(0, -1);
        action.prompts = action.prompts.slice(0, -1);
      }

      if (hasFieldId) {
        action.suggestions = action.suggestions.slice(0, -1);
        action.prompts = action.prompts.slice(0, -1);
      }

      if (textboxId === 0 && fieldId === 0) {
        // suggestions for the main textbox
        return {
          ...state,
          suggestions: action.suggestions,
          prompts: action.prompts,
          res: action.res,
          isRequesting: false,
          showLoadingIndicator: false,
          timeOfResponse: action.timeOfResponse,
          timeTaken: action.timeTaken,
          successMessage: action.message,
          lastRequestUtterance: undefined,
        };
      }
      // Partial suggestions for constrained textbox

      autocompleteChannel[textboxId] = {
        suggestions: action.suggestions,
        res: action.res,
        prompts: action.prompts,
        fieldId,
      };
      return {
        ...state,
        autocompleteChannel,
        newAutoCompleteResponse: !state.newAutoCompleteResponse,
        isRequesting: false,
        showLoadingIndicator: false,
        timeOfResponse: action.timeOfResponse,
        timeTaken: action.timeTaken,
        successMessage: action.message,
        lastRequestUtterance: undefined,
      };
    case LOAD_SUGGESTIONS_FAILURE:
      return {
        ...state,
        error: action.error,
        isRequesting: false,
        showLoadingIndicator: false,
        timeOfResponse: action.timeOfResponse,
        timeTaken: action.timeTaken,
        successMessage: undefined,
        lastRequestUtterance: undefined,
      };
    case DROP_SUGGESTIONS:
      return {
        ...initialState,
      };

    case SHOW_SUGGESTIONS_LOADING_INDICATOR:
      return {
        ...state,
        showLoadingIndicator: true,
      };
    default:
      return state;
  }
};
