import {
  GET_SAVED_EXPRESSIONS_FAILURE,
  GET_SAVED_EXPRESSIONS_SUCCESS,
} from '../actions/expressions.actions';

const initialState = {
  expressions: [], // Each expression has a name and type property.
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SAVED_EXPRESSIONS_FAILURE:
      return { ...state };
    case GET_SAVED_EXPRESSIONS_SUCCESS:
      return { ...state, expressions: action.expressions };
    default:
      return state;
  }
};
