export const HUBSPOT_TRACKING_SCRIPT = 'https://js.hs-scripts.com/22317235.js';

// load the tracking script from hubspot
// https://app.hubspot.com/settings/22317235/analytics-and-tracking/tracking
// and append it to the document body
export const loadHubspotTrackingScript = () => {
  const script = document.createElement('script');
  script.src = HUBSPOT_TRACKING_SCRIPT;
  script.async = true;
  script.defer = true;
  script.type = 'text/javascript';
  script.id = 'hs-script-loader';
  document.body.appendChild(script);
};

/**
 * get the _hsq array and call the following functions from hubspot tracking api
 * identify - If a user with this email does not exist in hubspot, create
 *             a new user. calling this function will not send anything to hubspot.
 *             You need to call trackPageView in order to identify the user
 * trackPageView - This will record a page view for the identified user.
 * @param {string} email email of the current user
 */
export const setHubspotIdentity = (email) => {
  window._hsq = window._hsq || [];
  const { _hsq } = window;
  _hsq.push(['identify', { email }]);
  _hsq.push(['trackPageView']);
};
