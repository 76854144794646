import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectUserConfig } from '../../store/sagas/selectors';
import { selectGoogleClientID } from '../../store/selectors/config.selector';
import {
  selectSnowflakeOAuthAccountName,
  selectSnowflakeOAuthClientID,
} from '../../store/selectors/oauth.selector';
import { FeatureTypes } from '../../types/feature.types';
import { IntegrationProvider } from '../../types/integrations.types';
import useOAuthButton from '../../utils/hooks/useOAuthButton.hook';
import { hasFeatureEnabled } from '../../utils/userconfig_selector';
import { OAuthPassthroughState } from './oauth.types';
import './styles/OAuthButton.scss';

interface OAuthButtonProps {
  passthroughState: OAuthPassthroughState;
}

const OAuthButton: React.FC<OAuthButtonProps> = ({ passthroughState }) => {
  const { databaseProvider: provider } = passthroughState;
  const { connected, isDeleting, integration, handleAuth, handleDisconnect, name, Logo } =
    useOAuthButton(provider, passthroughState);
  const userConfig = useSelector(selectUserConfig);
  const snowflakeOAuthClientID = useSelector(selectSnowflakeOAuthClientID);
  const snowflakeOAuthAccountName = useSelector(selectSnowflakeOAuthAccountName);
  const googleClientID = useSelector(selectGoogleClientID);

  if (
    provider === IntegrationProvider.SNOWFLAKE &&
    (!hasFeatureEnabled(userConfig, FeatureTypes.SnowflakeOAuth) ||
      !snowflakeOAuthClientID ||
      !snowflakeOAuthAccountName)
  ) {
    return null;
  }

  if (
    provider === IntegrationProvider.BIGQUERY &&
    (!hasFeatureEnabled(userConfig, FeatureTypes.DatabaseOAuth) || !googleClientID.isSet)
  ) {
    return null;
  }

  return (
    <Box className="IntegrationOption">
      <Box
        onClick={handleAuth}
        className={`OAuthButton${connected ? '' : ' enabled'}`}
        data-testid={`connect-button-${provider}`}
      >
        <Logo className="OAuthButton-Logo" />
        {connected ? (
          <Box className="OAuthButton-Text-Container">
            <Typography className="OAuthButton-Text">{`Connected to ${name}`}</Typography>
            <Typography className="OAuthButton-SubText">{integration?.email}</Typography>
          </Box>
        ) : (
          <Typography className="OAuthButton-Text">{`Connect to ${name}`}</Typography>
        )}
      </Box>
      {connected && (
        <Button
          color="error"
          onClick={handleDisconnect}
          className="DisconnectButton"
          data-testid="disconnect-button"
          disabled={isDeleting}
        >
          <Typography className="DisconnectButton-Text">Disconnect</Typography>
        </Button>
      )}
    </Box>
  );
};

export default OAuthButton;
