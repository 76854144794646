import * as Yup from 'yup';
import {
  DATABASE_CONNECTION_FIELDS,
  FORM_INPUT_TYPES,
  YUP_VALIDATION_SCHEMAS,
} from '../../utils/connection/connection';

import { createDatabaseType } from './__helpers__';

const NAME = 'ConnectionString';

export default {
  meta: {
    name: NAME,
  },
  body: {
    validationSchema: Yup.object().shape({
      connString: Yup.string().required('Required'),
      datasets: YUP_VALIDATION_SCHEMAS.DATASETS,
      readOnly: Yup.boolean().required('Required'),
    }),
    fields: [
      { ...createDatabaseType, value: NAME },
      {
        id: 'connString',
        label: 'Connection String',
        value: '',
        type: FORM_INPUT_TYPES.TEXT,
      },
      DATABASE_CONNECTION_FIELDS.DATASETS,
      {
        id: 'readOnly',
        label: 'readOnly',
        value: false,
        isRequired: true,
        type: FORM_INPUT_TYPES.CHECKBOX,
      },
    ],
  },
};
