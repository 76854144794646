import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'configureStore';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';

import { MAX_DESCRIPTION_CHAR_COUNT } from './CatalogEditPanel';
import CollapsibleDescription from '../../../AlertDialog/AlertDialogV1/CollapsibleDescription';
import {
  selectDatasetAnnotationErrorMessage,
  selectDatasetAnnotationIsLoading,
  selectDatasetAnnotations,
  selectDatasetEdits,
} from '../../../../store/selectors/catalog.selector';
import { selectDatasetNameByObjectFk } from '../../../../store/selectors/home_screen.selector';
import { edit, selectDataset } from '../../../../store/slices/catalog.slice';
import useDebounce from '../../../../utils/hooks/useDebounce.hook';

import './EditDatasetAnnotation.scss';

export const DESCRIBE_YOUR_DATASET = 'Describe your dataset...';

export type Props = {
  datasetId: string;
};

const DatasetAnnotationEditor: React.FC<Props> = ({ datasetId }) => {
  const dispatch = useDispatch();
  const annotations = useSelector(selectDatasetAnnotations);
  const editedAnnotations = useSelector(selectDatasetEdits);
  const isLoading = useSelector((state: RootState) =>
    selectDatasetAnnotationIsLoading(state, datasetId),
  );
  const error = useSelector((state: RootState) =>
    selectDatasetAnnotationErrorMessage(state, datasetId),
  );
  const hasError = Boolean(error);
  /** Name of dataset whose annotation is being edited. Defaults to `Dataset` if unnamed. */
  const name = useSelector(selectDatasetNameByObjectFk(datasetId)) ?? 'Dataset';

  const editedAnnotation = editedAnnotations[datasetId]?.annotation ?? '';
  const annotation =
    (annotations[datasetId]?.kind === 'data' && annotations[datasetId]?.data?.annotation) ?? '';
  const [localAnnotation, setLocalAnnotation] = React.useState<string>(
    editedAnnotation || annotation || '',
  );
  const [warning, setWarning] = React.useState<boolean>(false);

  // debounce editing the annotation
  useDebounce(() => dispatch(edit(datasetId, localAnnotation)), 250, [dispatch, localAnnotation]);

  // Update the local state when the edited annotation is discarded
  React.useEffect(() => {
    if (editedAnnotation === '') {
      setLocalAnnotation(annotation || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedAnnotation]);

  // Update the local state when the annotation from the database changes, or when the selected dataset changes
  React.useEffect(() => {
    setLocalAnnotation(editedAnnotation || annotation || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [annotation, datasetId]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (hasError) return;
    let newValue = e.target.value;
    if (newValue.length > MAX_DESCRIPTION_CHAR_COUNT) {
      newValue = newValue.substring(0, MAX_DESCRIPTION_CHAR_COUNT);
      setWarning(true);
      setTimeout(() => setWarning(false), 800);
    }
    setLocalAnnotation(newValue);
  };

  return (
    <Box className="DatasetAnnotationEditor">
      <Box className="DatasetAnnotationEditor-TitleContainer">
        <Typography variant="h5">{`${name} Definition`}</Typography>
        <Tooltip title="Provide a description of this dataset so the Data Assistant can better answer questions on this dataset">
          <InfoOutlinedIcon sx={{ width: '20px' }} />
        </Tooltip>
      </Box>
      {isLoading ? (
        <Skeleton variant="rectangular" width="100%" height="9rem" />
      ) : hasError ? (
        <Box width="100%" height="9rem" className="DatasetAnnotationEditor-Error">
          <Container maxWidth="sm" className="DatasetAnnotationEditor-Error-Container">
            <Typography>
              {'Failed to load dataset annotation. Click '}
              <span className="click-text" onClick={() => dispatch(selectDataset(datasetId))}>
                here
              </span>
              {' to try again.'}
            </Typography>
            <CollapsibleDescription title="Error Details" descriptions={[error as string]} />
          </Container>
        </Box>
      ) : (
        <TextField
          data-testid="catalog-edit-panel-textfield"
          value={hasError ? error : localAnnotation}
          className={warning ? 'warning' : ''}
          multiline
          error={hasError}
          minRows={4}
          maxRows={8}
          fullWidth
          onChange={onChange}
          placeholder={DESCRIBE_YOUR_DATASET}
          helperText={`${localAnnotation?.length ?? 0}/${MAX_DESCRIPTION_CHAR_COUNT} characters`}
        />
      )}
    </Box>
  );
};

export default DatasetAnnotationEditor;
