// vote constants
// export const UP_VOTE = true;
// export const DOWN_VOTE = false;
export const UP_VOTE = 1;
export const NEUTRAL_VOTE = 0;
export const DOWN_VOTE = -1;

// object containing all contexts available for submitting user feedback
export const FEEDBACK_CONTEXT = {
  ASK_AVA: 'Ask',
  ASK_BUSINESS: 'AskBusiness',
};
