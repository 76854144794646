import isEmpty from 'lodash/isEmpty';
import Chart from '../../../echart-library/ChartMapping';
import { SAMPLING_ALL_VALUES_PHRASE, iconMapping } from './constants';

/**
 * Get the component name of a child
 * Requires that displayName is set as a static property in the component
 * @param {React.ReactNode} child The child component
 * @returns The child component's name
 */
export const getComponentName = (child) => {
  if (!child) return null;

  const { displayName } = child?.type;

  // If the displayName is 'Connect(ComponentName)', extract & return 'ComponentName'
  if (displayName.includes('Connect')) {
    const regExp = /\(([^)]+)\)/;
    return regExp.exec(displayName)[1];
  }

  return displayName;
};

/**
 * Converts 'camelCase' to 'Camel Case'
 * @param {string} text text to be formatted
 * @returns {string} formatted string
 */
export const convertCamelCase = (text) =>
  text.replace(/([A-Z])/g, ' $1').replace(/^./, (s) => s.toUpperCase());

/**
 * Decide if the current input field should be disabled
 * @param {String} type the type of chart
 * @param {String} input the name of the current input field
 * @param {Object} fields the field state from ChartBuilder
 * @returns {Boolean} True if current field should be disabled
 */
export const disableField = (type, input, fields) => {
  if (type === 'donut') return false;
  switch (input) {
    case 'group':
      return !!fields.overlay;
    case 'overlay':
      return !!(fields.subplot || fields.group);
    case 'slider':
      return !!fields.subplot;
    case 'subplot':
      return !!(fields.slider || fields.overlay);
    default:
      return false;
  }
};

/**
 * Filters the columns that will display as options in the input fields.
 * @param {Object} fields The key/value pairs for the menu input fields
 * @param {import('translate_dc_to_echart').Column[]} columnsTypes Table column names & types
 * @param {String} input The name of the input field (ex. 'x-axis')
 * @param {import('translate_dc_to_echart').ChartTypes} type The chart type
 * @returns {Array} The columns to supply in the input dropdown
 */
export const filterColumns = (fields, columnsTypes, input, type) => {
  // Skip the current input if currently swapping chart types and it will be disabled
  if (disableField(type, input, fields) || !columnsTypes || isEmpty(columnsTypes)) return [];

  // Convert the fields object to be { [InputType]: Column[] } rather than { [InputType]: string[] }
  // ToDo: Update fields to be { [InputType]: Column[] }
  const smarterFields = Object.entries(fields).reduce((acc, [key, value]) => {
    if (Array.isArray(value)) {
      acc[key] = fields[key].map((col) => columnsTypes.find((c) => c.name === col));
    } else {
      acc[key] = value;
    }
    return acc;
  }, {});

  const res = Chart[type].runColumnFilters({
    input,
    fields: smarterFields,
    cols: columnsTypes,
  });
  return res.map((col) => col.name ?? col);
};

/**
 * Validates that all required inputs are filled before rendering the chart
 * @param {Object} fields the key/value pairs for the menu input fields
 * @param {Object} options the dataset/snapshot/chartType details
 * @returns {Boolean} True if required inputs are filled, False if not
 */
export const hasRequiredFields = (fields, options) => {
  const { required } = iconMapping[options.chartType.type];
  const fieldsKeys = Object.keys(fields);

  // Check if all required keys exist in fields and have a value
  for (const value of required) {
    if (value === 'aggregate') {
      continue;
    }
    if (!fieldsKeys.includes(value) || fields[value] === null || fields[value].length === 0) {
      return false;
    }
  }
  return true;
};

/**
 * Creates the chart spec object which we pass to translation & CreateEChart skill
 * Pass the state & prop values that you would like to update
 * Then the rest of the data will be parsed from the existing chart spec
 * ex. { props: { rows: this.props.rows } }
 *
 * @param {Object} chartSpec (required) Previous chart spec object
 * @param {Object} fields:   (required) Current field key/value pairs. ex. {'x-axis': 'Fare'}
 * @param {Object} options:  (required) CB options for dataset params & chart type
 * @param {Object} state:    (optional) State values of the CB to be updated in the chart spec
 * @param {Object} props:    (optional) Prop values of the CB to be updated in the chart spec
 * @returns The updated chart spec object
 */
export const constructChartSpec = ({
  chartSpec,
  options,
  state,
  props,
  dataSampleLimit,
  totalRowCount,
}) => {
  // Can't attempt destructuring from 'undefined', so initialize these if they weren't passed
  if (!state) state = {};
  if (!props) props = {};

  // These are the optional params of state and props. Fall back to parsing from chart spec if not supplied
  const {
    datasetName = chartSpec.values?.reference?.params?.datasetName,
    datasetVersion = chartSpec.values?.reference?.params?.datasetVersion,
  } = options;

  const {
    aggregate = chartSpec.values?.aggregate,
    series = chartSpec.plot.series,
    presentation = chartSpec.plot.presentation,
    transforms = chartSpec.values?.transforms,
    bins = chartSpec.values?.bins,
    caption = chartSpec.caption,
  } = state;

  const {
    sessionId = chartSpec.values?.reference?.params?.sessionId ??
      chartSpec.values?.reference?.params?.session_id,
  } = props;

  // If we're using the backend compute, then we need to use the computed data
  // Be careful, because we will also have the raw data available
  const columns = props?.usedCompute
    ? props.computedColumns
    : props?.columnsTypes || chartSpec.values?.columns;
  const rows = props?.usedCompute ? props.computedRows : props.rows || chartSpec.values?.rows;

  // _.isEqual() doesn't always trigger, so provide an easy-to-grab boolean
  // to check if an update needs to be processed in the translation layer
  const specUpdate = !chartSpec.specUpdate || false;

  const dataSampleLimitToSet = dataSampleLimit
    ? dataSampleLimit === SAMPLING_ALL_VALUES_PHRASE
      ? undefined
      : dataSampleLimit
    : chartSpec.values?.dataSampleLimit;

  return {
    ...chartSpec,
    caption,
    typeVersion: 2,
    specUpdate,
    plot: {
      ...chartSpec.plot,
      series,
      presentation,
    },
    values: {
      ...chartSpec.values,
      rows,
      columns,
      aggregate,
      transforms,
      bins,
      dataSampleLimit: dataSampleLimitToSet,
      totalRowCount: chartSpec.values?.totalRowCount ?? totalRowCount,
      reference: {
        ...chartSpec.values?.reference,
        type: 'dataset',
        params: {
          ...chartSpec.values?.reference?.params,
          session_id: sessionId,
          dataset: datasetName,
          version: datasetVersion,
        },
      },
    },
  };
};

export const initializePresentation = (presentation = {}) => ({
  ...presentation,
  title: {
    ...presentation.title,
    text: presentation.title?.text || [],
    textStyle: {
      fontSize: 18,
      ...presentation.title?.textStyle,
    },
  },
  xaxis: {
    ...presentation.xaxis,
    nameTextStyle: {
      fontSize: 18,
      ...presentation.xaxis?.nameTextStyle,
    },
    axisLabel: {
      fontSize: 12,
      ...presentation.xaxis?.axisLabel,
    },
    text: presentation.xaxis?.text || '',
  },
  yaxis: {
    ...presentation.yaxis,
    nameTextStyle: {
      fontSize: 18,
      ...presentation.yaxis?.nameTextStyle,
    },
    axisLabel: {
      fontSize: 12,
      ...presentation.yaxis?.axisLabel,
    },
    text: presentation.yaxis?.text || '',
  },
  overlayAxis: {
    ...presentation.overlayAxis,
    text: presentation.overlayAxis?.text || '',
  },
  annotations: presentation.annotations || [],
  colorOverride: presentation.colorOverride || [],
});

export const initializeChartType = (chartEditingMode, series, horizontal) => {
  if (chartEditingMode) {
    const currType = series[0].type === 'bar' && horizontal ? 'horizontal_bar' : series[0].type;
    return iconMapping[currType];
  }
  return iconMapping.bar;
};
