import groupBy from 'lodash/groupBy';
import { HOME_OBJECTS, HOME_OBJECT_KEYS } from '../constants/home_screen';

/**
 * This function unifies the home objects into single array of objects
 * @param {Dictionary} objects Array of objects.
 */
export const unifyAllObjects = (objects) => {
  const unifiedObjects = [];
  Object.keys(objects).forEach((key) => unifiedObjects.push(...Object.values(objects[key])));
  return unifiedObjects;
};

/**
 * This function returns the grouped results of objects
 *  corresponding to key property in each object.
 * @param {List} data List of Objects
 */
export const unUnifyObjects = (data) => {
  const newObjects = groupBy(data, HOME_OBJECT_KEYS.TYPE);
  newObjects[HOME_OBJECTS.ALL] = data;
  return newObjects;
};

/**
 * Removes the refIndex property and takes in each object and returns the array of objects.
 * Only applicable to Fuse.js results
 * @param {List} objects List of Objects.
 */
export const sanitizeFuseResults = (data) => {
  return data.map((object) => {
    return object.item;
  });
};
