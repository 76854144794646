export const FILE_DOWNLOAD_REQUEST = 'file_download/FILE_DOWNLOAD_REQUEST';
export const FILE_DOWNLOAD_SUCCESS = 'file_download/FILE_DOWNLOAD_SUCCESS';
export const FILE_DOWNLOAD_FAILURE = 'file_download/FILE_DOWNLOAD_FAILURE';
export const EXPORT_DATASET_REQUEST = 'file_download/EXPORT_DATASET_REQUEST';
export const EXPORT_DATASET_SUCCESS = 'file_download/EXPORT_DATASET_SUCCESS';
export const EXPORT_DATASET_FAILURE = 'file_download/EXPORT_DATASET_FAILURE';

export const fileDownloadRequest = ({ file }) => ({
  type: FILE_DOWNLOAD_REQUEST,
  file,
});

export const fileDownloadSuccess = () => ({
  type: FILE_DOWNLOAD_SUCCESS,
});

export const fileDownloadFailure = ({ error }) => ({
  type: FILE_DOWNLOAD_FAILURE,
  error,
});

export const exportDatasetRequest = ({ datasetName, version, filename = undefined }) => ({
  type: EXPORT_DATASET_REQUEST,
  datasetName,
  version,
  filename,
});

export const exportDatasetSuccess = (file) => ({
  type: EXPORT_DATASET_SUCCESS,
  file,
});

export const exportDatasetFailure = ({ error }) => ({
  type: EXPORT_DATASET_FAILURE,
  error,
});
