import queryString from 'query-string';
import { paths } from '../constants/paths';

export const showHeader = () => {
  const queries = queryString.parse(window.location.search);
  return !(queries.showHeader === 'false');
};

export const LOADING_SCREEN_CLASS = 'LoadingScreenContainer';
export const isShowingPageLoader = () => {
  return document.getElementsByClassName(LOADING_SCREEN_CLASS).length > 0;
};

// Focus can only be assigned to fields that are visible, so as long as the
// loading screen is covering the component the focus will fail.
export const establishFocus = (ref) => {
  let iterations = 0;
  const interval = setInterval(() => {
    if (!isShowingPageLoader() && ref && ref.current) {
      clearInterval(interval);
      ref.current.focus();
    }

    if (iterations > 20) {
      clearInterval(interval);
    }

    iterations += 1;
  }, 200);
};

// Regex for matching the datachat page url (web/session/<session_id>)
export const datachatPageRegex = new RegExp(`${paths.dataChatSession}/(.+)`);
