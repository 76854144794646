import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { v4 as uuidv4 } from 'uuid';
import { CHANGE_TYPES, REPLAY_STATUS } from '../../constants/editor';
import { HOME_OBJECT_KEYS } from '../../constants/home_screen';
import {
  UTTERANCE_STATE,
  addCommentPrefix,
  isComment,
  loadVersionHelper,
  removeCommentPrefix,
} from '../../utils/editor';
import {
  logAddition,
  logBulkAddUtterance,
  logBulkDeleteUtterance,
  logBulkEditUtterance,
  logDelete,
  logEdit,
  logMerge,
  logReorder,
  logSplit,
  redoNextChange,
  undoLastChange,
} from '../../utils/editor/change_log';
import {
  addUtteranceHelper,
  bulkAddUtteranceHelper,
  bulkDeleteUtterancesHelper,
  bulkEditUtterancesHelper,
  deleteUtteranceHelper,
  editUtteranceHelper,
  mergeUtterancesHelper,
  reorderUtteranceHelper,
  splitUtteranceHelper,
} from '../../utils/editor/changes';
import { UPDATE_DATASET_REQUEST, UPDATE_DATASET_SUCCESS } from '../actions/dataset.actions';
import {
  ADD_UTTERANCE,
  APPLY_FOCUS,
  COMMENT_SELECTION,
  COPY_SELECTION,
  DELETE_SELECTION,
  DELETE_UTTERANCE,
  DELETE_WORKFLOW_FAILURE,
  DELETE_WORKFLOW_REQUEST,
  DESELECT_ALL,
  EDIT_UTTERANCE,
  END_ASK_AVA_REPLAY,
  END_FRONTEND_REPLAY_FAILURE,
  END_FRONTEND_REPLAY_REQUEST,
  END_FRONTEND_REPLAY_SUCCESS,
  FINISH_LOADING,
  GET_WORKFLOW_FAILURE,
  GET_WORKFLOW_SUCCESS,
  INITIALIZE_EDITOR,
  LOAD_ASK_AVA_EDITOR,
  LOAD_VERSION,
  MERGE_UTTERANCES,
  PASTE_SELECTION,
  PAUSE_REPLAY,
  RECEIVED_QUESTION,
  REDO_CHANGE,
  REORDER_UTTERANCE,
  RESET_EDITOR,
  SAVE_WORKFLOW_SUCCESS,
  SELECT_ALL,
  SET_DEBOUNCE_FLAG,
  SET_EDITOR_OBJECT_INFORMATION,
  SET_NAME_SAVE_FAILED,
  SPLIT_UTTERANCE,
  START_ASK_AVA_REPLAY,
  START_FRONTEND_REPLAY_FAILURE,
  START_FRONTEND_REPLAY_REQUEST,
  START_FRONTEND_REPLAY_SUCCESS,
  SUBMIT_UTTERANCE_FAILURE,
  SUBMIT_UTTERANCE_REQUEST,
  SUBMIT_UTTERANCE_SUCCESS,
  TOGGLE_BREAKPOINT,
  TOGGLE_COMMENT,
  UNDO_CHANGE,
  UPDATE_SELECTION,
} from '../actions/editor.actions';
import { OBJECT_RENAME_SUCCESS } from '../actions/home_screen.actions';

/**
 * ! Please keep alphabetized by property name and inline comments up-to-date
 */
const initialState = {
  // used to apply focus to an utterance
  manualFocus: {
    uttIndex: -1,
    position: '',
  },
  objectType: '', // The type of object we are editing (Recipe, Dataset, Chart, etc.)
  objectID: '', // The ID of the object we are editing
  changeIndex: 0, // index into changeLog, if equal to length, no changes have been undone
  changeLog: [], // log of edits made to the workflow
  // set to the uttKey of the line that is being edited. used to determine if the
  // textbox has unsynced changes when attempting to run an utterance
  debouncedUtt: null,
  edited: false, // true of this workflow has unsaved edits, otherwise false
  error: null, // error object { message (String) }
  workflowNameSaveFailed: false, // Indicates that name saving failed and needs to be acknowledged
  execIndex: -1, // utteranceList[execIndex] --> key of next utterance to be replayed
  isReplaying: false, // true if a replay is in progress, false otherwise
  isStepwise: false, // true if the replay is in stepwise mode, false otherwise
  loading: true, // true if editor is loading, otherwise false
  // workflow level metadata
  metadata: {
    appId: undefined, // app id associated with workflow; likely '1' for Ava
    canModify: false, // true if workflow can be modified by the user, otherwise false
    headVersion: undefined, // latest saved version of the workflow
    name: undefined, // name of workflow
    owned: false, // true if workflow is owned by the user, otherwise false
    workflowId: undefined, // id of workflow currently being edited
    uuid: undefined,
    dataset: null, // dataset linked to this workflow (if it exists)
  },
  utteranceList: [], // ordered and editable list of uttKeys
  utteranceListOriginal: [], // original order of uttKeys stored in saved workflow
  previewing: false, // true iff workflow is being previewed
  replayStatus: REPLAY_STATUS.NONE, // status of current replay
  selection: [], // utterance keys of selected utterances
  versions: {}, // map of version numbers to workflow objects
  // version level metadata
  versionMetadata: {
    edited: null, // whether this version was edited from the previous version
    header: {}, // { appName, appVersion }, used in saving edited workflows
    rawTextVerified: null, // verified status of this version
    sessionStartupCount: 0, // count of starting utterances that are uneditable and get auto-run
    status: null, // verification status
    version: -1, // version of the currently viewed workflow
    date: null, // date this version was saved
  },
  workflow: {}, // map of utterance keys to the utterance object
  updatingDataset: false, // true if current replay is to update linked dataset
};

/**
 * Helper function used to merge the current editor state with the freshly fetched version.
 * Used to retain user message bubble ids after a refresh so scoll highlighting still works.
 */
const mergeWorkflowsAfterRefresh = (
  oldUtteranceList,
  oldWorkflow,
  newUtteranceList,
  newWorkflow,
) => {
  if (oldUtteranceList.length === newUtteranceList.length) {
    const workflow = {};
    const utteranceList = [];
    for (let i = 0; i < newUtteranceList.length; i++) {
      const oldUttKey = oldUtteranceList[i];
      const oldUttObject = oldWorkflow[oldUttKey];
      const newUttKey = newUtteranceList[i];
      const newUttObject = newWorkflow[newUttKey];
      // Update the user message id
      newUttObject.usrMsgId = oldUttObject.usrMsgId;
      workflow[oldUttKey] = newUttObject;
      utteranceList.push(oldUttKey);
    }
    return { newUtteranceList: utteranceList, newWorkflow: workflow };
  }
  return { newUtteranceList, newWorkflow };
};

const editorReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_EDITOR:
      return initialState;
    case SET_EDITOR_OBJECT_INFORMATION:
      return { ...state, objectType: action.objectType, objectId: action.objectId };
    case INITIALIZE_EDITOR:
      return { ...state, loading: true };
    case FINISH_LOADING:
      return { ...state, loading: false };
    /**
     * Updates state after successfully fetching workflow data
     * @param {Object} metadata - workflow level metadata
     * @param {Array} versions - array of workflow versions
     */
    case GET_WORKFLOW_SUCCESS: {
      const { headVersion, canModify } = action.metadata;
      const versionContent = action.versions[headVersion];
      const { versionMetadata, workflow, utteranceList, userName } =
        loadVersionHelper(versionContent);
      if (action.refresh) {
        // Workflow is saved during a replay, need to retain replay state, but update workflow
        const { newUtteranceList, newWorkflow } = mergeWorkflowsAfterRefresh(
          state.utteranceList,
          state.workflow,
          utteranceList,
          workflow,
        );
        return {
          ...state,
          edited: false,
          error: null,
          loading: false,
          metadata: { ...action.metadata, userName },
          utteranceList: newUtteranceList,
          utteranceListOriginal: newUtteranceList,
          versions: action.versions,
          versionMetadata,
          workflow: newWorkflow,
          changeIndex: 0,
          changeLog: [],
        };
      }
      return {
        ...state,
        edited: false,
        error: null,
        execIndex: -1,
        isReplaying: false,
        isStepwise: false,
        loading: false,
        metadata: { ...action.metadata, userName },
        utteranceList,
        utteranceListOriginal: utteranceList,
        previewing: !canModify, // unowned workflows can only be previewed
        replayStatus: REPLAY_STATUS.NONE,
        versions: action.versions,
        versionMetadata,
        workflow,
        changeIndex: 0,
        changeLog: [],
      };
    }
    case GET_WORKFLOW_FAILURE:
      return { ...state, loading: false };
    // For starting a replay of the workflow
    case START_FRONTEND_REPLAY_REQUEST:
      return { ...state, replayStatus: REPLAY_STATUS.STARTING };
    case START_FRONTEND_REPLAY_SUCCESS:
      return {
        ...state,
        replayStatus: REPLAY_STATUS.READY,
        isStepwise: action.stepwise,
        isReplaying: true,
        execIndex: 0,
        previewing: !state.metadata.canModify, // unowned workflows can only be previewed
      };
    case START_FRONTEND_REPLAY_FAILURE:
      return { ...state, error: action.error, replayStatus: REPLAY_STATUS.NONE };
    case END_FRONTEND_REPLAY_REQUEST:
      return { ...state, replayStatus: REPLAY_STATUS.ENDING };
    /**
     * Handles state update after a replay ends successfully or not.
     * The only difference is that if there is a failure, the `error` is set.
     */
    case END_FRONTEND_REPLAY_SUCCESS:
    case END_FRONTEND_REPLAY_FAILURE: {
      const { workflow, utteranceList } = state;
      const newWorkflow = { ...workflow };
      const deletions = [];
      // Reset any error flags that were set from the last replay
      utteranceList.forEach((uttKey, uttIndex) => {
        const uttObject = newWorkflow[uttKey];
        newWorkflow[uttKey] = { ...uttObject, error: null, contextId: null };
        if (newWorkflow[uttKey].ignore) deletions.push({ uttKey, uttIndex, uttObject });
      });
      let updatedState = {
        ...state,
        error: action.type === END_FRONTEND_REPLAY_FAILURE ? action.error : state.error,
        replayStatus: REPLAY_STATUS.NONE,
        isReplaying: false,
        isStepwise: false,
        execIndex: -1,
        workflow: newWorkflow,
        updatingDataset: false,
      };

      if (!isEmpty(deletions)) {
        updatedState = {
          ...updatedState,
          ...logBulkDeleteUtterance(updatedState, deletions),
          ...bulkDeleteUtterancesHelper(updatedState, deletions),
        };
      }

      return updatedState;
    }
    /**
     * Updates state to reflect that an utterance is being executed.
     * @param {Boolean} continueReplay - if true, `isStepwise` flag is set to false
     */
    case SUBMIT_UTTERANCE_REQUEST: {
      const { execIndex, utteranceList, workflow } = state;
      const uttKey = utteranceList[execIndex];
      return {
        ...state,
        replayStatus: REPLAY_STATUS.WORKING,
        isStepwise: action.continueReplay ? false : state.isStepwise,
        // Reset any existing error with the utterance
        workflow: { ...workflow, [uttKey]: { ...workflow[uttKey], error: null } },
      };
    }
    /**
     * Handles state changes after an utterances is successfully executed.
     * @param {Object} answers - dictionary containing answers to skill questions
     */
    case SUBMIT_UTTERANCE_SUCCESS: {
      const { execIndex, utteranceList, workflow } = state;
      const uttKey = utteranceList[execIndex];
      const newUttObject = cloneDeep(workflow[uttKey]);

      const { additionalInfo } = action;
      if (!isEmpty(additionalInfo)) {
        if (additionalInfo.ignore) newUttObject.ignore = true;
        if ('answers' in additionalInfo) newUttObject.metadata.answers = additionalInfo.answers;
      }
      // Set usrMsgId to allow scrolling the session panel
      newUttObject.usrMsgId = isComment(newUttObject.value) ? null : action.usrMsgId;
      // Pertinent for Ask Ava - if utterance is successful, it is therefore valid.
      newUttObject.valid = 1;
      return {
        ...state,
        replayStatus: REPLAY_STATUS.READY,
        execIndex: state.execIndex + 1,
        workflow: { ...workflow, [uttKey]: newUttObject },
      };
    }
    /**
     * Handles state changes after utterance execution fails.
     * @param {String} error - error message for a given utterance.
     */
    case SUBMIT_UTTERANCE_FAILURE: {
      const { execIndex, utteranceList, workflow } = state;
      const uttKey = utteranceList[execIndex];
      return {
        ...state,
        isStepwise: true,
        replayStatus: REPLAY_STATUS.FAILURE,
        workflow: { ...workflow, [uttKey]: { ...workflow[uttKey], error: action.error } },
        updatingDataset: false,
      };
    }
    case RECEIVED_QUESTION:
      return { ...state, replayStatus: REPLAY_STATUS.QUESTIONING };
    case PAUSE_REPLAY:
      return { ...state, isStepwise: true, updatingDataset: false };
    // For saving changes made to the workflow
    case SAVE_WORKFLOW_SUCCESS:
      return { ...state, edited: action.edited };
    case DELETE_WORKFLOW_REQUEST:
      return { ...state, loading: true };
    case DELETE_WORKFLOW_FAILURE:
      return { ...state, loading: false };
    case UNDO_CHANGE:
      return { ...state, ...undoLastChange(state) };
    case REDO_CHANGE:
      return { ...state, ...redoNextChange(state) };
    case ADD_UTTERANCE: {
      // Create utterance object for new utterance
      const newUttObject = UTTERANCE_STATE({ added: true });
      const uttKey = uuidv4();
      return {
        ...state,
        ...logAddition(state, action.uttIndex, uttKey),
        ...addUtteranceHelper(state, action.uttIndex, uttKey, newUttObject),
      };
    }
    case SET_DEBOUNCE_FLAG: {
      return { ...state, debouncedUtt: action.uttKey };
    }
    case EDIT_UTTERANCE: {
      const oldValue = state.workflow[action.uttKey].value;
      return {
        ...state,
        debouncedUtt: null,
        ...logEdit(state, action.uttKey, oldValue, action.newValue),
        ...editUtteranceHelper(state, action.uttKey, action.newValue),
      };
    }
    case TOGGLE_COMMENT: {
      const oldValue = state.workflow[action.uttKey].value;
      let newValue;
      if (isComment(oldValue)) newValue = removeCommentPrefix(oldValue);
      else newValue = addCommentPrefix(oldValue);
      return {
        ...state,
        ...logEdit(state, action.uttKey, oldValue, newValue),
        ...editUtteranceHelper(state, action.uttKey, newValue),
      };
    }
    case DELETE_UTTERANCE: {
      const { utteranceList, workflow } = state;
      const uttIndex = utteranceList.indexOf(action.uttKey);
      return {
        ...state,
        ...logDelete(state, uttIndex, action.uttKey, workflow[action.uttKey]),
        ...deleteUtteranceHelper(state, uttIndex, action.uttKey),
      };
    }
    case APPLY_FOCUS: {
      const { uttIndex, position } = action;
      return { ...state, manualFocus: { uttIndex, position } };
    }
    case SPLIT_UTTERANCE: {
      const { uttIndex, firstHalf, secondHalf } = action;
      const { utteranceList, workflow } = state;
      const uttKey = utteranceList[uttIndex];
      // info needed to edit the first line and undo the edit
      const edit = {
        uttKey,
        oldValue: workflow[uttKey].value,
        newValue: firstHalf,
      };
      // newly added utterance
      const newUttObject = UTTERANCE_STATE({
        added: true,
        edited: true,
        utterance: secondHalf,
      });
      // info needed to add a new line after the first one
      const addition = {
        uttIndex: uttIndex + 1,
        uttKey: uuidv4(),
        uttObject: newUttObject,
      };
      return {
        ...state,
        ...splitUtteranceHelper(state, edit, addition),
        ...logSplit(state, edit, addition),
      };
    }
    case MERGE_UTTERANCES: {
      const { uttIndex } = action;
      const { utteranceList, workflow } = state;
      const uttKey = utteranceList[uttIndex];
      const deleteIndex = uttIndex + 1;
      const deleteKey = utteranceList[deleteIndex];
      const deleteObject = cloneDeep(workflow[deleteKey]);
      const oldValue = workflow[uttKey].value;
      let newValue;
      if (
        // first step ends in strong tab (and not em tag)
        oldValue.endsWith('</strong>') &&
        !oldValue.endsWith('</em></strong>') &&
        // second step starts with strong tab (and not em tag)
        deleteObject.value.startsWith('<strong') &&
        !deleteObject.value.startsWith('<strong><em')
      ) {
        // merge the ending strong tag of the first line with the starting strong tag of the second
        const firstPart = oldValue.replace(/<\/strong>$/, '');
        const secondPart = deleteObject.value.replace(/^<strong>/, '');
        // merge the two adjusted lines
        newValue = firstPart + secondPart;
      } else {
        // simply merge the two lines together
        newValue = oldValue + deleteObject.value;
      }
      // info needed to delete the second line and undo the deletion
      const deletion = {
        uttIndex: deleteIndex,
        uttKey: deleteKey,
        uttObject: deleteObject,
      };
      // info needed to edit the first line and undo the edit
      const edit = { uttKey, oldValue, newValue };
      return {
        ...state,
        ...mergeUtterancesHelper(state, edit, deletion),
        ...logMerge(state, edit, deletion),
      };
    }
    case REORDER_UTTERANCE: {
      const { newUtteranceList, startIndex, endIndex } = action;
      const oldUtteranceList = state.utteranceList;
      return {
        ...state,
        ...logReorder(state, oldUtteranceList, newUtteranceList, startIndex, endIndex),
        ...reorderUtteranceHelper(state, action.newUtteranceList),
      };
    }
    /**
     * Updates the current selected utterances.
     *
     * For cases depending on whether the utterance is being selected or unselecting and whether or
     * not the shift key is currently pressed.
     *
     * Case 1: shift key is being pressed & utterance is being selected
     *   Select everything between the last selected utterance and the utterance being selected.
     * Case 2: shift key is being pressed & utterance is being unselected
     *   Do nothing.
     * Case 3: Shift key is unpressed & utterance is being selected
     *   Select the utterance.
     * Case 4: shift key is unpressed & utterance is being unselected
     *   Deselect the utterance.
     *
     * @param {Number} uttKey - utterance key
     * @param {Boolean} selecting - true if the user wants to select this utterance otherwise false
     * @param {Boolean} shifting - true if the user is shifting selecting an utterance
     */
    case UPDATE_SELECTION: {
      const { uttKey, selecting, shifting } = action;
      const { selection, utteranceList } = state;
      // shallow copy of selection to not mutate this.state
      const newSelection = [...selection];
      if (shifting && selecting) {
        // Case 1: select everything between last selected utterance and this utterance
        const iLastSel = selection.length === 0 ? 0 : utteranceList.indexOf(selection.at(-1));
        const iThisUtt = utteranceList.indexOf(uttKey); // uttOrder index of this utterance
        if (iLastSel === iThisUtt) return state;
        const iStart = iThisUtt > iLastSel ? iLastSel : iThisUtt; // determine start index
        const iEnd = iThisUtt > iLastSel ? iThisUtt + 1 : iLastSel; // determine end index
        newSelection.push(
          ...utteranceList.slice(iStart, iEnd).filter((key) => newSelection.indexOf(key) === -1),
        );
      } else if (shifting && !selecting) {
        // Case 2: do nothing
        // TODO if we every want to do bulk-deselections, this is the case to overwrite
        return state;
      } else if (!shifting && selecting) {
        // case 3: select the utterance
        newSelection.push(uttKey);
      } else if (!shifting && !selecting) {
        // case 4: unselect the utterance
        newSelection.splice(newSelection.indexOf(uttKey), 1);
      }
      return { ...state, selection: newSelection };
    }
    case SELECT_ALL:
      return { ...state, selection: [...state.utteranceList] };
    case DESELECT_ALL:
      return { ...state, selection: [] };
    case COPY_SELECTION: {
      const { selection, workflow, utteranceList } = state;
      if (selection.length === 0) return state;
      // indices of selected utterances
      const uttIndices = selection.map((uttKey) => utteranceList.indexOf(uttKey));
      // sort indices of selected utterances in ascending order
      // by default sort works alphabetically, so a function must be provided
      uttIndices.sort((a, b) => a - b);
      // construct array of copied utterances
      const utterances = uttIndices.map((uttIndex) => {
        const uttKey = utteranceList[uttIndex];
        return workflow[uttKey].value;
      });
      // construct copied utterance string and write to clipboard
      navigator.clipboard.writeText(utterances.join('\n'));
      return state;
    }
    /**
     * This handler is used by UtteranceEditor to paste multiple utterances after a given utterance.
     *
     * @param {Number} uttIndex Index into utteranceList to insert the new utterance(s)
     * @param {Array} utterances List of utterances to be inserted after at uttIndex
     */
    case PASTE_SELECTION: {
      let editLog;
      let updatedState = { ...state };
      // Edit the first line
      if (action.editObject) {
        const { uttKey, newValue } = action.editObject;
        const uttIndex = state.utteranceList.indexOf(uttKey);
        const oldValue = updatedState.workflow[uttKey].value;
        updatedState = { ...updatedState, ...editUtteranceHelper(state, uttKey, newValue) };
        editLog = { type: CHANGE_TYPES.EDIT, uttIndex, uttKey, oldValue, newValue };
      }

      const insertions = action.utterances.map((utterance, i) => {
        const uttObject = UTTERANCE_STATE({ edited: true, added: true });
        uttObject.value = utterance;
        return {
          uttKey: uuidv4(),
          uttIndex: action.uttIndex + i,
          uttObject,
        };
      });
      // ensure that insertions are sorted by uttIndex
      insertions.sort((deletion1, deletion2) => deletion1.uttIndex - deletion2.uttIndex);
      return {
        ...updatedState,
        ...logBulkAddUtterance(updatedState, insertions, editLog),
        ...bulkAddUtteranceHelper(updatedState, insertions),
      };
    }
    /**
     * Convert selected lines into commented lines.
     */
    case COMMENT_SELECTION: {
      const { selection, workflow } = state;
      if (selection.length === 0) return state;
      const commented = selection.map((uttKey) => isComment(workflow[uttKey].value));
      const shouldComment = commented.some((v) => !v);
      const edits = [];
      selection.forEach((uttKey, listIndex) => {
        if (!shouldComment && commented[listIndex]) {
          edits.push({
            uttKey,
            oldValue: workflow[uttKey].value,
            newValue: removeCommentPrefix(workflow[uttKey].value),
          });
        } else if (shouldComment && !commented[listIndex]) {
          edits.push({
            uttKey,
            oldValue: workflow[uttKey].value,
            newValue: addCommentPrefix(workflow[uttKey].value),
          });
        }
      });
      return {
        ...state,
        ...logBulkEditUtterance(state, edits),
        ...bulkEditUtterancesHelper(state, edits),
      };
    }
    /**
     * Deletes the current selected utterances.
     */
    case DELETE_SELECTION: {
      const { selection, workflow, utteranceList } = state;
      if (selection.length === 0) return state;
      const deletions = selection.map((uttKey) => ({
        uttKey,
        uttIndex: utteranceList.indexOf(uttKey),
        uttObject: workflow[uttKey],
      }));
      // ensure that deletions are sorted by uttIndex
      deletions.sort((deletion1, deletion2) => deletion1.uttIndex - deletion2.uttIndex);
      // copy deleted utterances to the clipboard
      navigator.clipboard.writeText(deletions.map((del) => del.uttObject?.value).join('\n'));
      return {
        ...state,
        ...logBulkDeleteUtterance(state, deletions),
        ...bulkDeleteUtterancesHelper(state, deletions),
        selection: [], // clear selected uttKeys
      };
    }
    /**
     * Toggles an utterances breakpoint
     * @param {Number} uttKey - utterance to toggle breakpoint
     */
    case TOGGLE_BREAKPOINT: {
      const newUttObject = cloneDeep(state.workflow[action.uttKey]);
      newUttObject.breakpoint = !newUttObject.breakpoint;
      return { ...state, workflow: { ...state.workflow, [action.uttKey]: newUttObject } };
    }
    /**
     * Loads a different version of the workflow into the editor
     * @param {Number} version - version to load
     * @param {Boolean} previewing - whether the user is just previewing the workflow
     */
    case LOAD_VERSION: {
      const { version, previewing } = action;
      const { versions } = state;
      const versionContent = versions[version];
      const { versionMetadata, workflow, utteranceList } = loadVersionHelper(versionContent);
      return {
        ...state,
        edited: false,
        utteranceList,
        utteranceListOriginal: utteranceList,
        previewing,
        versionMetadata,
        workflow,
        changeIndex: 0,
        changeLog: [],
      };
    }
    case SET_NAME_SAVE_FAILED: {
      return {
        ...state,
        workflowNameSaveFailed: action.setFailed,
      };
    }
    case LOAD_ASK_AVA_EDITOR: {
      return {
        ...state,
        edited: false,
        error: null,
        execIndex: -1,
        isReplaying: false,
        isStepwise: false,
        loading: false,
        metadata: { ...action.metadata },
        utteranceList: action.stepList,
        utteranceListOriginal: action.stepList,
        previewing: false,
        replayStatus: REPLAY_STATUS.NONE,
        // versions: action.versions, TODO - add versions if applicable
        // versionMetadata, TODO - add versionMetadata if applicable
        workflow: action.recipe,
        changeIndex: 0,
        changeLog: [],
      };
    }
    case START_ASK_AVA_REPLAY:
      return {
        ...state,
        replayStatus: REPLAY_STATUS.READY,
        isStepwise: true,
        isReplaying: true,
        execIndex: 0,
        previewing: false,
      };
    case END_ASK_AVA_REPLAY:
      return {
        ...state,
        replayStatus: REPLAY_STATUS.NONE,
        isReplaying: false,
        isStepwise: false,
        execIndex: -1,
      };
    case UPDATE_DATASET_SUCCESS:
      if (state.metadata.dataset.id === action.dataset.id) {
        return {
          ...state,
          metadata: {
            ...state.metadata,
            dataset: {
              ...state.metadata.dataset,
              pipeliner_dataset_id: action.dataset.pipeliner_dataset_id,
            },
          },
        };
      }
      return state;
    case UPDATE_DATASET_REQUEST:
      return {
        ...state,
        updatingDataset: false,
      };
    case OBJECT_RENAME_SUCCESS: {
      const { object, newName } = action;
      if (object[HOME_OBJECT_KEYS.UUID] === state.metadata.dataset.uuid) {
        return {
          ...state,
          metadata: {
            ...state.metadata,
            dataset: {
              ...state.metadata.dataset,
              name: newName,
            },
          },
        };
      }
      return state;
    }
    default:
      return state;
  }
};

export default (state = initialState, action) => {
  try {
    return editorReducer(state, action);
  } catch (error) {
    // Add error parameter to action to be accessed by sagas
    action.error = error;
    // eslint-disable-next-line no-console
    console.warn(error);
    return state;
  }
};
