import { createSlice } from '@reduxjs/toolkit';
import {
  AccessDialogSavePayloads,
  Accessor,
  GetAccessorsFailurePayload,
  GetAccessorsRequestPayload,
  GetAccessorsSuccessPayload,
  GetOrganizationAccessFailurePayload,
  GetOrganizationAccessRequestPayload,
  GetOrganizationAccessSuccessPayload,
  OpenAccessDialogFailurePayload,
  OpenAccessDialogRequestPayload,
  OpenAccessDialogSuccessPayload,
} from '../../types/accessDialog.types';
import { HomeObjectKeysTypes } from '../../utils/homeScreen/types';

type AccessDialogState = {
  /** Users who can access the object. */
  accessors: Record<string, Record<string, Accessor>>;
  /** Whether the state of the dialog is being loaded. */
  isLoading: boolean;
  /** Whether the state of the dialog is being saved. */
  isSaving: boolean;
  /** Whether to show the dialog. */
  show: boolean;
  /** UUID of the object whose access is being changed. */
  uuid: string;
  /** Home object data of the object being accessed. */
  data: HomeObjectKeysTypes | Record<string, never>;
  /** Organization access selection state */
  organizationAccess: object;
};

const initialState: AccessDialogState = {
  accessors: {},
  isLoading: false,
  isSaving: false,
  show: false,
  uuid: '',
  data: {},
  organizationAccess: {},
};

const accessDialogSlice = createSlice({
  name: 'accessDialog',
  initialState,
  reducers: {
    clearAccessors: (state: AccessDialogState) => {
      state.accessors = initialState.accessors;
    },

    closeAccessDialog: (state: AccessDialogState) => {
      Object.assign(state, initialState);
    },

    /**
     * Get the list of all shared users of the object
     */
    getAccessorsRequest: {
      prepare: (payload: GetAccessorsRequestPayload) => ({ payload }),
      reducer: () => {},
    },

    getAccessorsSuccess: (state: AccessDialogState, { payload }: GetAccessorsSuccessPayload) => {
      const { accessors, uuid } = payload;
      state.accessors = {
        ...state.accessors,
        [uuid]: accessors,
      };
    },

    getAccessorsFailure: {
      prepare: (payload: GetAccessorsFailurePayload) => ({ payload }),
      reducer: () => {},
    },

    accessDialogSaveRequest: {
      prepare: (payload: AccessDialogSavePayloads) => ({ payload }),
      reducer: (state: AccessDialogState) => {
        state.isSaving = true;
      },
    },

    accessDialogSaveSuccess: {
      prepare: (payload: AccessDialogSavePayloads) => ({ payload }),
      reducer: (state: AccessDialogState) => {
        state.isSaving = false;
      },
    },

    accessDialogSaveFailure: {
      prepare: (payload: AccessDialogSavePayloads) => ({ payload }),
      reducer: (state: AccessDialogState) => {
        state.isSaving = false;
      },
    },

    openAccessDialogRequest: {
      prepare: (payload: OpenAccessDialogRequestPayload) => ({ payload }),
      reducer: (state: AccessDialogState) => {
        state.isLoading = true;
        state.show = true;
      },
    },

    openAccessDialogSuccess: (
      state: AccessDialogState,
      { payload }: OpenAccessDialogSuccessPayload,
    ) => {
      const { data, uuid } = payload;
      state.isLoading = false;
      state.data = data;
      state.uuid = uuid;
    },

    openAccessDialogFailure: {
      prepare: (payload: OpenAccessDialogFailurePayload) => ({ payload }),
      reducer: (state: AccessDialogState) => {
        state.isLoading = false;
        state.show = false;
      },
    },

    getOrganizationAccessRequest: {
      prepare: (payload: GetOrganizationAccessRequestPayload) => ({ payload }),
      reducer: () => {},
    },

    getOrganizationAccessSuccess: (
      state: AccessDialogState,
      { payload }: GetOrganizationAccessSuccessPayload,
    ) => {
      const { organizationAccess } = payload;
      state.organizationAccess = organizationAccess;
    },

    getOrganizationAccessFailure: {
      prepare: (payload: GetOrganizationAccessFailurePayload) => ({ payload }),
      reducer: () => {},
    },
  },
});

export const {
  clearAccessors,
  closeAccessDialog,
  getAccessorsRequest,
  getAccessorsSuccess,
  getAccessorsFailure,
  accessDialogSaveRequest,
  accessDialogSaveSuccess,
  accessDialogSaveFailure,
  openAccessDialogRequest,
  openAccessDialogSuccess,
  openAccessDialogFailure,
  getOrganizationAccessRequest,
  getOrganizationAccessSuccess,
  getOrganizationAccessFailure,
} = accessDialogSlice.actions;

export default accessDialogSlice.reducer;
