export const OPEN_POP_OUT_MODAL = 'popout/OPEN_POP_OUT_MODAL';
export const CLOSE_POP_OUT_MODAL = 'popout/CLOSE_POP_OUT_MODAL';
// Action that opens the chart pop out modal with the selected chart
export const openPopOutModal = ({ chartObjectId, isPopoutEditable = true }) => ({
  type: OPEN_POP_OUT_MODAL,
  chartObjectId,
  isPopoutEditable,
});

// Action that closes the chart pop out modal
export const closePopOutModal = () => ({
  type: CLOSE_POP_OUT_MODAL,
});
