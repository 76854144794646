import { SeriesGroupKeys, SeriesMarkKeys } from 'translate_dc_to_echart';
import ChartModules from '../modules/ChartModules';
import { InputType } from '../types/chartBuilderIO';
import {
  disallowWithMultiYAxis,
  isNumeric,
  maximumOfOneColumnWithGroup,
  notDuplicatedWithOverlay,
  notDuplicatedWithXAxis,
  notDuplicatedWithYAxis,
} from '../utils/columnSelection';
import ChartType from './ChartType';

export default class Bar extends ChartType {
  static fieldGenMapping = {
    mark: {
      [SeriesMarkKeys.x]: InputType.x,
      [SeriesMarkKeys.y]: InputType.y,
      [SeriesMarkKeys.overlay]: InputType.overlay,
      [SeriesMarkKeys.color]: InputType.group,
    },
    group: {
      [SeriesGroupKeys.subplot]: InputType.subplot,
      [SeriesGroupKeys.slider]: InputType.slider,
    },
  };

  static columnFilters = {
    [InputType.x]: [notDuplicatedWithYAxis, notDuplicatedWithOverlay],
    [InputType.y]: [
      notDuplicatedWithXAxis,
      notDuplicatedWithOverlay,
      maximumOfOneColumnWithGroup,
      isNumeric,
    ],
    [InputType.overlay]: [disallowWithMultiYAxis, notDuplicatedWithYAxis, notDuplicatedWithXAxis],
    [InputType.group]: [disallowWithMultiYAxis],
  };

  constructor() {
    super(new ChartModules(Bar.columnFilters, Bar.fieldGenMapping));
  }
}
