import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectHasDatasetEdits } from '../../../../store/selectors/catalog.selector';
import { discardAll, saveAll } from '../../../../store/slices/catalog.slice';
import CatalogEditPanel from '../EditPanel/CatalogEditPanel';
import CatalogNavigationMenu from '../NavigationMenu/CatalogNavigationMenu';
import './DatachatCatalogContent.scss';

const DatachatCatalogContent = () => {
  const dispatch = useDispatch();
  const hasEdits = useSelector(selectHasDatasetEdits);

  return (
    <DialogContent className="DatachatCatalog-Content" data-testid="DatachatCatalog-content">
      <Box className="NavigationCollapse">
        <CatalogNavigationMenu />
        <DialogActions sx={{ zIndex: 11 }}>
          <Button
            onClick={() => dispatch(discardAll())}
            startIcon={<DeleteIcon />}
            data-testid="discard-all"
            title="Discard"
            variant="outlined"
            color="error"
            disabled={!hasEdits}
          >
            Discard
          </Button>
          <Button
            onClick={() => dispatch(saveAll())}
            startIcon={<SaveIcon />}
            data-testid="save-all"
            title="Save All"
            variant="contained"
            disabled={!hasEdits}
          >
            Save All
          </Button>
        </DialogActions>
      </Box>
      <Box className="PanelGroup">
        <CatalogEditPanel />
      </Box>
    </DialogContent>
  );
};

export default DatachatCatalogContent;
