import {
  CLOSE_FILE_MODAL,
  FILE_UPLOAD_CANCEL,
  FILE_UPLOAD_CONFLICT,
  FILE_UPLOAD_FAILURE,
  FILE_UPLOAD_REQUEST,
  FILE_UPLOAD_SUCCESS,
  LOAD_IMMEDIATELY,
  OPEN_FILE_MODAL,
} from '../actions/upload.actions';

export const initialState = {
  filesRequesting: 0,
  datasetsToLoadImmediately: [],
  filesToLoadImmediately: [],
  fileModalOpen: false,
};

/**
 * @param {Object} state
 * @param {Object} action
 * @returns {Object} new state
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case FILE_UPLOAD_REQUEST:
      return {
        ...state,
        filesRequesting: state.filesRequesting + 1,
      };
    case FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        filesRequesting: Math.max(state.filesRequesting - 1, 0),
      };
    case FILE_UPLOAD_FAILURE:
      // UploadButton.componentWillUnmount passes in null for action.file
      // If this is ever modified to use action.file, this must be changed or handled
      return {
        ...state,
        filesRequesting: Math.max(state.filesRequesting - 1, 0),
        error: action.error,
      };
    case FILE_UPLOAD_CANCEL:
      return {
        ...state,
        filesRequesting: 0,
        error: action.error,
      };
    case FILE_UPLOAD_CONFLICT:
      return state;
    case LOAD_IMMEDIATELY:
      return {
        ...state,
        datasetsToLoadImmediately: action.datasetsToLoadImmediately,
        filesToLoadImmediately: action.filesToLoadImmediately,
      };
    case OPEN_FILE_MODAL:
      return {
        ...state,
        fileModalOpen: true,
      };
    case CLOSE_FILE_MODAL:
      return {
        ...state,
        fileModalOpen: false,
      };
    default:
      return state;
  }
};
