import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Loadable from 'react-loadable';
import { connect } from 'react-redux';
import ReactResizeDetector from 'react-resize-detector';
import sanitizeHtml from 'sanitize-html';
import { FAT_TABLE_LENGTH } from '../../../constants/chart';
import { MessageTypes } from '../../../constants/nodes';
import { isWorkflowEditorPage } from '../../../constants/paths';
import LoadingScreen from '../../../pages/LoadingScreen';
import { selectCurrentLocation } from '../../../store/sagas/selectors';
import { SESSION_TYPES } from '../../../store/slices/session.slice';
import { useSessionPipelinerDatasetId } from '../../ChartData/dataUtils';
import Text from '../../ChatPanel/ChatContext/BubbleComponent/Text';
import Annotation from '../Charts/Annotation';
import PivotTable from '../Charts/PivotTable/PivotTable';
import {
  MAX_PIVOT_HEIGHT,
  MAX_PIVOT_WIDTH,
  PIVOT_PADDING,
  PIVOT_ROW_HEIGHT,
} from '../Charts/PivotTable/constants';
import Table from '../Charts/Table/Table';
import { PAGE_SIZES } from '../Charts/Table/constants';
import DCPlotV2 from '../Charts/dcplotV2/DCPlotV2';
import DCPlotV2ErrorBoundary from '../Charts/dcplotV2/DCPlotV2ErrorBoundary';
import DependencyGraphECharts from '../Charts/dcplotV2/DependencyGraph';

const DatachatPlot = Loadable({
  loader: () => import('../Charts/dcplot/dcplot'),
  loading() {
    return <LoadingScreen />;
  },
});

/**
 * Decides which chart to render based on the message type.
 * TODO: this component likely needs furthur refactor
 */
const VisualDisplayFilter = (props) => {
  const {
    objectId,
    sessionType,
    chart,
    fontSizes,
    publicationId,
    insightsBoardId,
    isInsightsBoard,
    isWorkflowEditor,
    isInsightsBoardCreator,
    showModeBar,
    toggleModeBar,
    isPanMode,
    sliderPlaying,
    setSliderPlaying,
    showModalPng,
    updateModalPng,
    openCaptionAlert,
    rowCount,
    chartEditingMode,
    showEditMenu,
    keepChanges,
    toggleChartEditingMode,
    toggleIBFontMenu,
    openIBFontMenu,
    isPopOutChart,
    forgotten,
    updateRowCount,
    isTabVisual,
    isFailed,
    isLoading,
  } = props;

  const { externalName, type: messageType, typeVersion: messageTypeVersion, data, update } = chart;

  const [caption, setCaption] = useState(messageType === MessageTypes.Viz && data.caption);

  const [isFatTable] = useState(data.values?.columns?.length > FAT_TABLE_LENGTH);

  const pipelinerDatasetId = useSessionPipelinerDatasetId({
    datasetName: data.name,
    datasetVersion: data.version,
  });

  /**
   * Determines whether the current chart can be edited
   */
  const isChartEditable = () => {
    return (
      (((messageType === MessageTypes.Viz && messageTypeVersion === 2) ||
        (messageType === MessageTypes.Viz && messageTypeVersion !== 2 && !isInsightsBoard) ||
        (messageType === MessageTypes.Note && !isInsightsBoard)) &&
        !isWorkflowEditor &&
        !data.isDependencyGraph &&
        !(
          messageType === MessageTypes.Viz &&
          (data.chartType === 'graph' || data.chartType === 'sunburst')
        )) ||
      (messageType === MessageTypes.Annotation && isInsightsBoardCreator)
    );
  };

  /**
   * Select the appropriate render method based on the message type.
   */
  switch (messageType) {
    case MessageTypes.Viz: {
      /**
       * Select ECharts (2) or Plotly (<2 or undefined) based on the messageTypeVersion
       */
      switch (messageTypeVersion) {
        case 2: {
          return data.isDependencyGraph ? (
            <ReactResizeDetector handleWidth handleHeight>
              {({ width, height, targetRef }) => (
                <div ref={targetRef} style={{ width: '100%', height: '100%' }}>
                  <DependencyGraphECharts
                    width={width}
                    height={height}
                    data={data}
                    openCaptionAlert={openCaptionAlert}
                    showModalPng={showModalPng}
                    updateModalPng={updateModalPng}
                  />
                </div>
              )}
            </ReactResizeDetector>
          ) : (
            <ReactResizeDetector handleWidth handleHeight>
              {({ width, height, targetRef }) => (
                <div ref={targetRef} style={{ width: '100%', height: '100%' }}>
                  <DCPlotV2ErrorBoundary dcSpec={data} isLoading={isLoading} objectId={objectId}>
                    <DCPlotV2
                      width={width}
                      openCaptionAlert={openCaptionAlert}
                      height={height}
                      objectId={objectId}
                      externalName={externalName}
                      dcSpec={data}
                      chartEditingMode={chartEditingMode}
                      showEditMenu={showEditMenu}
                      caption={caption}
                      setCaption={setCaption}
                      showModalPng={showModalPng}
                      updateModalPng={updateModalPng}
                      keepChanges={keepChanges}
                      insightsBoardId={insightsBoardId}
                      isPanMode={isPanMode}
                      isPopOutChart={isPopOutChart}
                      isFatTable={isFatTable}
                    />
                  </DCPlotV2ErrorBoundary>
                </div>
              )}
            </ReactResizeDetector>
          );
        }
        default:
          return (
            <DatachatPlot
              id={objectId}
              externalName={externalName}
              data={cloneDeep({
                ...data,
                // Hides the Plotly logo
                config: { displayModeBar: false, displaylogo: false, ...data.config },
              })}
              caption={caption}
              setCaption={setCaption}
              update={update}
              fromDashboard={sessionType === SESSION_TYPES.DASHBOARD}
              displayBorder={false}
              showModeBar={showModeBar}
              toggleModeBar={toggleModeBar}
              chartEditingMode={chartEditingMode}
              showEditMenu={showEditMenu}
              isEditable={isChartEditable()}
              toggleEditMode={toggleChartEditingMode}
              keepChanges={keepChanges}
              sliderPlaying={sliderPlaying}
              setSliderPlaying={setSliderPlaying}
              showModalPng={showModalPng}
              updateModalPng={updateModalPng}
              fontSizes={fontSizes}
              publicationId={publicationId}
              insightsBoardId={insightsBoardId}
              toggleIBFontMenu={toggleIBFontMenu}
              openIBFontMenu={openIBFontMenu}
            />
          );
      }
    }
    case MessageTypes.Table: {
      if (forgotten) return null;
      return (
        <Table
          data={update?.spec?.data ? update.spec.data : data}
          id={objectId}
          isFailed={isFailed}
          isInsightsBoard={isInsightsBoard}
          isLoading={isLoading}
          isTabVisual={isTabVisual}
          pageSizeOptions={[...PAGE_SIZES].slice(1)}
          publicationId={publicationId}
          rowCount={rowCount}
          updateRowCount={updateRowCount}
          pipelinerDatasetId={pipelinerDatasetId}
        />
      );
    }
    case MessageTypes.Pivot: {
      const { dimensions } = data.values;
      let height = (dimensions[0] + 3) * PIVOT_ROW_HEIGHT;
      height = height > MAX_PIVOT_HEIGHT ? MAX_PIVOT_HEIGHT : height;
      let outerHeight = height + PIVOT_PADDING;
      if (isInsightsBoard) {
        outerHeight += 40;
      }
      return (
        <PivotTable
          height={height}
          outerHeight={outerHeight}
          insightsBoardId={insightsBoardId}
          width={isInsightsBoard ? '100%' : MAX_PIVOT_WIDTH}
          isPopOutChart={isPopOutChart}
          tableData={data}
          objectId={objectId}
          publicationId={publicationId}
        />
      );
    }
    case MessageTypes.Text:
      return (
        <div className="TextContentWrapper">
          <Text data={data.text} />
        </div>
      );
    case MessageTypes.Annotation:
      return <Annotation data={data} />;
    default:
      return (
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(data, {
              // This list is from `worker/dc/core/worker/action.py`
              allowedTags: [
                'a',
                'br',
                'b',
                'i',
                's',
                'u',
                'ul',
                'ol',
                'li',
                'table',
                'th',
                'tr',
                'td',
                'thead',
                'tbody',
                'span',
              ],
            }),
          }}
        />
      );
  }
};

VisualDisplayFilter.propTypes = {
  objectId: PropTypes.string,
  isWorkflowEditor: PropTypes.bool.isRequired,
  sessionType: PropTypes.string,
  chart: PropTypes.object.isRequired,
  fontSizes: PropTypes.object,
  publicationId: PropTypes.number,
  insightsBoardId: PropTypes.string,
  isInsightsBoard: PropTypes.bool,
  isInsightsBoardCreator: PropTypes.bool,
  showModeBar: PropTypes.bool,
  toggleModeBar: PropTypes.func,
  isLoading: PropTypes.bool,
  isFailed: PropTypes.bool,
  isPanMode: PropTypes.bool,
  sliderPlaying: PropTypes.bool,
  setSliderPlaying: PropTypes.func,
  showModalPng: PropTypes.bool,
  updateModalPng: PropTypes.func,
  openCaptionAlert: PropTypes.func,
  rowCount: PropTypes.number,
  chartEditingMode: PropTypes.bool,
  showEditMenu: PropTypes.bool,
  keepChanges: PropTypes.bool,
  openIBFontMenu: PropTypes.bool,
  toggleChartEditingMode: PropTypes.func,
  toggleIBFontMenu: PropTypes.func,
  isPopOutChart: PropTypes.bool,
  forgotten: PropTypes.bool,
  updateRowCount: PropTypes.func,
  isTabVisual: PropTypes.bool,
};

VisualDisplayFilter.defaultProps = {
  sessionType: undefined,
  isInsightsBoardCreator: false,
  chartEditingMode: false,
  showEditMenu: false,
  keepChanges: false,
  fontSizes: {},
  isInsightsBoard: false,
  rowCount: null,
  objectId: undefined,
  publicationId: 0,
  insightsBoardId: null,
  showModeBar: false,
  isFailed: false,
  isLoading: false,
  isPanMode: false,
  sliderPlaying: false,
  showModalPng: false,
  openIBFontMenu: false,
  isPopOutChart: false,
  forgotten: false,
  isTabVisual: false,
  toggleIBFontMenu: () => {},
  updateModalPng: () => {},
  setSliderPlaying: () => {},
  toggleModeBar: () => {},
  openCaptionAlert: () => {},
  toggleChartEditingMode: () => {},
  updateRowCount: () => {},
};

const mapStateToProps = (state) => ({
  sessionType: state.session.sessionType,
  isWorkflowEditor: isWorkflowEditorPage(selectCurrentLocation(state)),
});

export default connect(mapStateToProps, {})(VisualDisplayFilter);
