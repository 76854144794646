import React from 'react';
import { TypeAnimation } from 'react-type-animation';
import { LoadingBouncingCircles } from '../common/LoadingBouncingCircles';
import './LoadingComponent.scss';

const getRandomLoadingMessage = () => {
  const messages = [
    'Wrangling data...',
    'Crunching numbers...',
    'Herding cells...',
    'Data incoming!',
    'Sorting chaos...',
    'Data, assemble!',
    'Rows, roll out!',
    'Cooking up data...',
    'Spreadsheet magic...',
  ];
  return messages[Math.floor(Math.random() * messages.length)];
};

/** Maximum primary text characters (to not overlap with spinner) */
const PRIMARY_TEXT_MAX_LENGTH = 30;
/** Secondary text displayed after some time spent loading */
export const SECONDARY_TEXT = 'Your data is taking a while to load, please wait';
/** Loading time taken (ms) before displaying this secondary text */
export const SECONDARY_TEXT_TIMEOUT = 15000;

/**
 * Get an error to display to developers when the primary text is too long.
 * @param primaryText The component's primaryText prop.
 * @throws {Error} If the primary text is too long.
 */
export const validatePrimaryText = (primaryText: string) => {
  if (primaryText.length > PRIMARY_TEXT_MAX_LENGTH) {
    throw new Error(
      `Primary text is too long: got: ${primaryText.length} characters, max: ${PRIMARY_TEXT_MAX_LENGTH}`,
    );
  }
};

type LoadingComponentProps = {
  primaryText?: string;
  secondaryText?: string;
  secondaryTextTimeout?: number;
};

/**
 * Displays a loading spinner with a centered primary text
 * and a secondary text that appears after the secondaryTextTimeout.
 */
const LoadingComponent: React.FC<LoadingComponentProps> = ({
  primaryText,
  secondaryText = SECONDARY_TEXT,
  secondaryTextTimeout = SECONDARY_TEXT_TIMEOUT,
}) => {
  // Generate a random message if primaryText is not provided
  const displayText = primaryText ?? getRandomLoadingMessage();

  /**
   * Make sure that the primary text is not too long
   * so that it can fit in the center of the loading spinner.
   */
  validatePrimaryText(displayText);

  return (
    <div className="LoadingComponent" data-testid="loading-component">
      <div className="LoadingComponent-LoadingBouncingCircles">
        <LoadingBouncingCircles />
      </div>
      <div className="LoadingComponent-LoadingText">{displayText}</div>
      {/* Type the secondary text after a timeout if the loading takes too long */}
      <TypeAnimation
        className="LoadingComponent-SecondaryText"
        cursor={false}
        sequence={[secondaryTextTimeout, secondaryText]}
        speed={80}
      />
    </div>
  );
};

export default LoadingComponent;
