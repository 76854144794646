import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import omit from 'lodash/omit';

export type ValueFormatterMap = { [column: string]: string };
export interface UpdateValueFormatterMapPayload {
  valueFormatterMap: ValueFormatterMap;
  name: string;
  sessionId: string;
  version: number;
}

export type ColumnVisibilityModel = { [column: string]: boolean };
export type UpdateColumnVisibilityModelPayload = {
  columnVisibilityModel: ColumnVisibilityModel;
  name: string;
  sessionId: string;
  version: number;
};

export type SortModel = { field: string; sort: string }[];
export interface UpdateSortModelPayload {
  name: string;
  sessionId: string;
  sortModel: SortModel;
  version: number;
}

export type OrderedFields = string[];
export interface UpdateOrderedFieldsPayload {
  name: string;
  sessionId: string;
  orderedFields: OrderedFields;
  version: number;
}

export type Dimensions = { [column: string]: { width: number } };
export interface UpdateDimensionsPayload {
  name: string;
  sessionId: string;
  dimensions: Dimensions;
  version: number;
}

export interface DeleteSheetStateByNamePayload {
  sessionId: string;
  name: string;
}

export interface SaveTabOrderPayload {
  sessionId: string;
  tabOrderList: string[];
}

export interface SavePaneOpenStatusPayload {
  sessionId: string;
  paneOpenList: string[];
}

export interface SheetDict {
  [sessionId: string]: {
    [name: string]: {
      [version: number]: {
        columns: {
          columns: {
            columnVisibilityModel: ColumnVisibilityModel;
            dimensions: Dimensions;
            orderedFields: OrderedFields;
            valueFormatterMap: ValueFormatterMap;
          };
          sorting: {
            sortModel: SortModel;
          };
        };
      };
    };
  };
}

export type TabOrderDict = { [sessionId: string]: string[] };
export type PaneOpenStatusDict = { [sessionId: string]: string[] };

export interface DataspaceTableState {
  forgetName: string;
  sheetDict: SheetDict;
  tabOrderDict: TabOrderDict;
  paneOpenStatusDict: PaneOpenStatusDict;
  autoOpenPaneName: string;
}

const initialState: DataspaceTableState = {
  forgetName: '', // the name that is forgot by the tab
  sheetDict: {}, // Data structure which persists the state of the DataSpreadsheet
  tabOrderDict: {},
  paneOpenStatusDict: {}, // indicates which pane in grid mode is open
  autoOpenPaneName: '', // indicates which pane should be auto-opened
};

// Create the sheetDict object path if it doesn't exist yet
const initializeSheetDict = (
  state: DataspaceTableState,
  sessionId: string,
  name: string,
  version: number,
) => {
  state.sheetDict[sessionId] = state.sheetDict[sessionId] ?? {};
  state.sheetDict[sessionId][name] = state.sheetDict[sessionId][name] ?? {};
  state.sheetDict[sessionId][name][version] = state.sheetDict[sessionId][name][version] ?? {};
  state.sheetDict[sessionId][name][version].columns =
    state.sheetDict[sessionId][name][version].columns ?? {};
  state.sheetDict[sessionId][name][version].columns.columns =
    state.sheetDict[sessionId][name][version].columns.columns ?? {};
  state.sheetDict[sessionId][name][version].columns.sorting =
    state.sheetDict[sessionId][name][version].columns.sorting ?? {};
};

const dataspaceTableSlice = createSlice({
  name: 'dataspaceTable',
  initialState,
  reducers: {
    updateSortModel: (state, { payload }: PayloadAction<UpdateSortModelPayload>) => {
      const { sortModel, name, sessionId, version } = payload;
      if (state.forgetName !== name) {
        initializeSheetDict(state, sessionId, name, version);
        state.sheetDict[sessionId][name][version].columns.sorting.sortModel = sortModel;
      }
    },
    updateColumnVisibilityModel: (
      state,
      { payload }: PayloadAction<UpdateColumnVisibilityModelPayload>,
    ) => {
      const { columnVisibilityModel, name, sessionId, version } = payload;
      if (state.forgetName !== name) {
        initializeSheetDict(state, sessionId, name, version);
        state.sheetDict[sessionId][name][version].columns.columns.columnVisibilityModel =
          columnVisibilityModel;
      }
    },
    updateValueFormatterMap: (
      state,
      { payload }: PayloadAction<UpdateValueFormatterMapPayload>,
    ) => {
      const { valueFormatterMap, name, sessionId, version } = payload;
      if (state.forgetName !== name) {
        initializeSheetDict(state, sessionId, name, version);
        state.sheetDict[sessionId][name][version].columns.columns.valueFormatterMap =
          valueFormatterMap;
      }
    },
    updateOrderedFields: (state, { payload }: PayloadAction<UpdateOrderedFieldsPayload>) => {
      const { orderedFields, name, sessionId, version } = payload;
      if (state.forgetName !== name) {
        initializeSheetDict(state, sessionId, name, version);
        state.sheetDict[sessionId][name][version].columns.columns.orderedFields = orderedFields;
      }
    },
    updateDimensions: (state, { payload }: PayloadAction<UpdateDimensionsPayload>) => {
      const { dimensions, name, sessionId, version } = payload;
      if (state.forgetName !== name) {
        initializeSheetDict(state, sessionId, name, version);
        state.sheetDict[sessionId][name][version].columns.columns.dimensions = dimensions;
      }
    },
    /**
     * Dispatch action to clear the spreadsheet state in Grid mode when ending a session
     */
    deleteSheetStateBySession: (state, { payload }: PayloadAction<string>) => {
      state.forgetName = '';
      state.sheetDict = omit(state.sheetDict, payload);
      state.tabOrderDict = omit(state.tabOrderDict, payload);
      state.paneOpenStatusDict = omit(state.paneOpenStatusDict, payload);
      state.autoOpenPaneName = '';
    },
    /**
     * Dispatch action to clear the spreadsheet state in Grid mode when forget dataset in the tab
     */
    deleteSheetStateByName: (state, { payload }: PayloadAction<DeleteSheetStateByNamePayload>) => {
      const { name, sessionId } = payload;
      state.forgetName = name;
      state.sheetDict = {
        ...state.sheetDict,
        [sessionId]: omit(state.sheetDict[sessionId], name),
      };
    },
    /**
     * Reset the name of forget dataset
     */
    resetForgetName: (state) => {
      state.forgetName = '';
    },
    /**
     * Dispatch action to save the spreadsheet state
     */
    saveTabOrder: (state, { payload }: PayloadAction<SaveTabOrderPayload>) => {
      const { sessionId, tabOrderList } = payload;
      state.tabOrderDict[sessionId] = tabOrderList;
    },
    /**
     * Dispatch action to save which panel is open in Grid mode
     */
    savePaneOpenStatus: (state, { payload }: PayloadAction<SavePaneOpenStatusPayload>) => {
      const { sessionId, paneOpenList } = payload;
      state.paneOpenStatusDict[sessionId] = paneOpenList;
    },
    /**
     * Dispatch action to auto open a pane in Grid mode
     */
    setAutoOpenPane: (state, { payload }: PayloadAction<string>) => {
      state.autoOpenPaneName = payload;
    },
  },
});

export const {
  setAutoOpenPane,
  savePaneOpenStatus,
  resetForgetName,
  saveTabOrder,
  deleteSheetStateByName,
  deleteSheetStateBySession,
  updateSortModel,
  updateColumnVisibilityModel,
  updateValueFormatterMap,
  updateOrderedFields,
  updateDimensions,
} = dataspaceTableSlice.actions;

export default dataspaceTableSlice.reducer;
