import { SeriesGroupKeys, SeriesMarkKeys } from 'translate_dc_to_echart';
import ChartModules from '../modules/ChartModules';
import { InputType } from '../types/chartBuilderIO';
import { isNumeric } from '../utils/columnSelection';
import ChartType from './ChartType';

export default class Heatmap extends ChartType {
  static fieldGenMapping = {
    mark: {
      [SeriesMarkKeys.x]: InputType.x,
      [SeriesMarkKeys.y]: InputType.y,
      [SeriesMarkKeys.density]: InputType.density,
    },
    group: {
      [SeriesGroupKeys.subplot]: InputType.subplot,
      [SeriesGroupKeys.slider]: InputType.slider,
    },
  };

  static columnFilters = {
    [InputType.density]: [isNumeric],
  };

  constructor() {
    super(new ChartModules(Heatmap.columnFilters, Heatmap.fieldGenMapping));
  }
}
