import { createSelector } from 'reselect';
import { RootState } from '../configureStore';
import { IntegrationProvider } from '../types/integrations.types';

/*
 * This file is equivalent to selectors.js in Typescript.
 * Selectors that are used in TypeScript will need to be moved here.
 * TODO: Move all selectors to this file, and rename to selectors.ts.
 */

// Dataset Creator
export const selectDatasetCreatorIsOpen = (state: RootState) => state.datasetCreator.isOpen;
export const selectUploadedDatasets = (state: RootState) => state.datasetCreator.uploadedDatasets;

// Payments
export const selectProductPrice = (state: RootState) => state.subscriptions.productPrice;
export const selectProductName = (state: RootState) => state.subscriptions.productName;
export const selectTrialValid = (state: RootState) => state.subscriptions.trialValid;
export const selectSubscriptionDetails = (state: RootState) => state.subscriptions.details;

// Current Subscription
export const selectPaymentMethods = createSelector(
  (state: RootState) => state.subscriptions.paymentMethods,
  (paymentMethods) => {
    if (paymentMethods) return Object.values(paymentMethods);
    return [];
  },
);
export const selectHasPaymentMethod = createSelector(
  selectPaymentMethods,
  (paymentMethods) => paymentMethods.length > 0,
);

// Auth
export const selectGoogleSSOClientID = (state: RootState): string => state.config.googleClientID;
export const selectAuthIsRequesting = (state: RootState): boolean => state.auth.isRequesting;
export const selectLoginUsername = (state: RootState): boolean => state.config.loginUsername;

export const selectClientIDByProvider = (state: RootState, provider: string): string => {
  switch (provider) {
    case IntegrationProvider.BIGQUERY:
      return selectGoogleSSOClientID(state);
    default:
      return '';
  }
};

// ViewSlicedWorkflow
export const selectIsSlicedWorkflowPopoutOpen = (state: RootState): boolean =>
  state.viewSlicedWorkflow.isSlicedWorkflowPopoutOpen;
export const selectSlicedWorkflowData = (state: RootState): { [key: string]: any } =>
  state.viewSlicedWorkflow.slicedWorkflowData;
export const selectIsSlicedWorkflowDataLoading = (state: RootState): boolean =>
  state.viewSlicedWorkflow.isSlicedWorkflowDataLoading;

// popout
export const selectPopoutSelectedChart = (state: RootState): string => state.popout.selectedChart;
export const selectPopoutIsOpen = (state: RootState): boolean => state.popout.isPopOutModalOpen;
export const selectIsPopOutEditable = (state: RootState): string => state.popout.isPopoutEditable;
