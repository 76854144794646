import DialogContentText from '@mui/material/DialogContentText';
import Box from '@mui/material/Box';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import React from 'react';

export type CollapsibleDescriptionProps = {
  title: string;
  descriptions?: string[];
};

const CollapsibleDescription: React.FC<CollapsibleDescriptionProps> = ({ title, descriptions }) => {
  const [expanded, setExpanded] = React.useState(false);

  // If there are no descriptions, do not render the component
  if (!descriptions || descriptions.length === 0) return null;

  return (
    <>
      <Box
        className="collapsed-description"
        onClick={() => setExpanded((prev) => !prev)}
        sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
      >
        {title}
        <ArrowDropDownIcon
          sx={{
            transform: expanded ? 'rotate(0deg)' : 'rotate(-90deg)',
            transition: 'transform 0.2s ease-in-out',
          }}
        />
      </Box>
      {expanded &&
        descriptions.map((description) => {
          return (
            <DialogContentText id={description} key={description}>
              {description}
            </DialogContentText>
          );
        })}
    </>
  );
};

export default CollapsibleDescription;
