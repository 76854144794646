import Error from '@mui/icons-material/Error';
import PropTypes from 'prop-types';
import React from 'react';
import ChartLoadingIndicator from '../../../common/ChartLoadingIndicator';

/**
 * A functional component to render a button UI to refresh in IB
 */
const RefreshStatus = (props) => {
  const { isRefreshing, lastRefreshFailed, refreshMessage, isPopOutChart } = props;

  return (
    <>
      {!isPopOutChart && lastRefreshFailed && !isRefreshing && (
        <div
          className="ChartHeader-RefreshWarning"
          data-cy="ChartHeader-Refresh-Warning"
          title="Refresh Failed"
        >
          <Error fontSize="inherit" />
        </div>
      )}
      {!isPopOutChart && isRefreshing && (
        <div className="ChartHeader-RefreshTime" data-cy="ChartHeader-Refresh-Indicator">
          <ChartLoadingIndicator message={refreshMessage} />
        </div>
      )}
    </>
  );
};

RefreshStatus.propTypes = {
  isRefreshing: PropTypes.bool.isRequired,
  lastRefreshFailed: PropTypes.bool.isRequired,
  refreshMessage: PropTypes.string.isRequired,
  isPopOutChart: PropTypes.bool.isRequired,
};

export default RefreshStatus;
