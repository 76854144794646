/**
 * Utility functions used by the Workflow Editor
 */
import isEmpty from 'lodash/isEmpty';
import { v4 as uuidv4 } from 'uuid';
import { SESSION_CONCURRENCY_LIMIT_ALERT } from '../../constants/dialog.constants';

/* eslint-disable camelcase */
import {
  CHART_MODIFY,
  CHART_RECIPE,
  CHART_RECIPE_END_LINE,
  CHART_RECIPE_START_LINE,
  COMMENT_LINE_START,
  MODIFIER_LINE_START,
  MODIFY_RECIPE_END_LINE,
  MODIFY_RECIPE_START_LINE,
  SKILL_METADATA_SCHEMA,
  VIZ_RECIPE,
  VIZ_RECIPE_END_LINE,
  VIZ_RECIPE_START_LINE,
} from '../../constants/workflow';
import { packWorkflowFile } from '../workflow';

export const atSessionLimit = (dialogs) =>
  dialogs.find((dialog) => dialog?.dialogType === SESSION_CONCURRENCY_LIMIT_ALERT);

/**
 * Helper function returns whether an utterance is a comment.
 * @param {String} utterance
 * @returns true if utterance is a comment, false otherwise
 */
export const isComment = (utterance) => utterance.trim().startsWith(COMMENT_LINE_START);

/**
 * Helper function that returns the passed string beginning with the comment prefix.
 * @param {String} utterance
 * @returns `-- ${utterance}`
 */
export const addCommentPrefix = (utterance) => `${COMMENT_LINE_START} ${utterance}`;

/**
 * Helper function that returns the passed string with the comment prefix and any
 * leading whitespace removed.
 * @param {String} utterance
 * @returns utterance with '--' prefix removed
 */
export const removeCommentPrefix = (utterance) => utterance.replace(/^(\s*--\s*)/, '');

/**
 * Extracts relevant information from an utterance's recipe and
 * formats it for display in the workflow editor.
 * @param {Object} recipe
 * @returns formatted recipe
 */
export const formatUtteranceRecipe = (recipe) => {
  const formattedRecipe = {};
  if (typeof recipe === 'object' && Object.keys(recipe).length > 0) {
    if (CHART_MODIFY in recipe) {
      // Clean and parse chart modification for display in editor
      let chartModification = recipe[CHART_MODIFY];
      chartModification = chartModification.filter((line) => {
        return line !== MODIFY_RECIPE_START_LINE && line !== MODIFY_RECIPE_END_LINE;
      });
      chartModification = chartModification.map((line) => {
        const trimmed = line.replace(MODIFIER_LINE_START, '').trim();
        return JSON.parse(trimmed);
      });
      if (chartModification.length === 1) {
        const firstIndex = 0;
        chartModification = chartModification[firstIndex];
      }
      formattedRecipe[CHART_MODIFY] = chartModification;
    } else if (CHART_RECIPE in recipe) {
      // Clean chart recipe for display in editor
      let chartRecipe = recipe[CHART_RECIPE];
      chartRecipe = chartRecipe.filter((line) => {
        return line !== CHART_RECIPE_START_LINE && line !== CHART_RECIPE_END_LINE;
      });
      chartRecipe = chartRecipe.map((line) => line.replace(MODIFIER_LINE_START, '').trim());
      formattedRecipe[CHART_RECIPE] = chartRecipe;
    } else if (VIZ_RECIPE in recipe) {
      // Clean viz recipe for display in editor
      let vizRecipe = recipe[VIZ_RECIPE];
      vizRecipe = vizRecipe.filter((line) => {
        return line !== VIZ_RECIPE_START_LINE && line !== VIZ_RECIPE_END_LINE;
      });
      vizRecipe = vizRecipe.map((line) => line.replace(MODIFIER_LINE_START, '').trim());
      formattedRecipe[VIZ_RECIPE] = vizRecipe;
    }
  }
  return formattedRecipe;
};

/** State of an utterance loaded in the workflow editor */
export const UTTERANCE_STATE = ({
  // Variables saved in workflow
  answers = {},
  current_datasets = {},
  inputs = {},
  is_comment = false,
  kwargs = {},
  outputs = {},
  utterance = '',
  recipe = {},
  session_startup = false,
  skill = null,
  skill_uuid = null,
  // Editor variables (do not save)
  added = false,
  breakpoint = false,
  edited = false,
  error = null,
  ignore = false,
  usrMsgId = null,
  valid = 1,
} = {}) => {
  return {
    added, // true if the utterance is added to the workflow
    breakpoint, // true if the replay should stop before running this skill
    edited, // set to true when originalUtterance does not equal value
    error, // error with executing the skill
    ignore, // true if this skill should be ignored when saving
    // variables in metadata are saved with the utterance metadata
    metadata: {
      answers, // answers to skill questions
      current_datasets, // current datasets at the time of the skill's execution
      inputs, // skill products used as input to this skill
      kwargs, // key word arguments for a skill's execute function
      outputs, // skill products produced by this utterance
      recipe, // chart recipe or modification spec
      session_startup, // whether the utterance is part of session startup
      skill, // class name for the skill
      skill_uuid, // uuid of the skill
    },
    // original utterance when added to the workflow
    originalUtterance: is_comment ? `${COMMENT_LINE_START} ${utterance}` : utterance,
    usrMsgId, // id of the corresponding chat message
    // value displayed in the editor for this skill
    value: is_comment ? `${COMMENT_LINE_START} ${utterance}` : utterance,
    valid, // used in "Ask Ava" (editor only mode) - 1 if valid, 0 if potentially invalid
  };
};

/**
 * Parses a version's conent for variables needed for the workflow editor
 * @param {Object} versionContent - version related informatioin
 * @returns
 */
export const loadVersionHelper = (versionContent) => {
  let workflow = versionContent.dcw_data;
  try {
    workflow = JSON.parse(workflow);
  } catch (error) {
    // legacy formatted workflow
    const lines = workflow.split('\n');
    const firstLine = lines.shift().split(' ');
    const appName = firstLine[1].split(':')[1];
    const appVersion = firstLine[2].split(':')[1];
    workflow = packWorkflowFile({ appName, appVersion }, lines.join('\n'));
  }

  const userName = versionContent.user_name;
  const utterances = workflow.workflow_data;
  const utteranceList = workflow.utterance_list;
  const workflowMap = {};
  let sessionStartupCount = 0;
  if (utteranceList.length > 0) {
    for (let i = 0; i < utteranceList.length; i++) {
      // Use the key provided in the saved workflow object, as it's useful for debugging
      const uttKey = utteranceList[i];
      workflowMap[uttKey] = UTTERANCE_STATE(utterances[uttKey]);
      if (utterances[uttKey].session_startup) sessionStartupCount++;
    }
  } else {
    // Ensure empty workflows display an empty line
    const uttKey = uuidv4(); // use uuid as it's not part of the original workflow
    utteranceList.push(uttKey);
    workflowMap[uttKey] = UTTERANCE_STATE({ added: true });
  }

  const appName = workflow.app_dir_name;
  const appVersion = workflow.app_version;
  const versionMetadata = {
    date: versionContent.date,
    header: { appName, appVersion },
    status: versionContent.status,
    version: versionContent.version,
    rawTextVerified: workflow.raw_text_verified,
    edited: workflow.edited,
    sessionStartupCount,
  };
  return { versionMetadata, workflow: workflowMap, utteranceList, userName };
};

/**
 * Constructs a dcw_data string from
 *
 * @param {Boolean} workflowEdited true iff the workflow has been edited
 * @param {Object} versionMetadata metadata of the version that was edited
 * @param {Object} header { appName, appVersion }
 * @param {String} status WORKFLOW_VERSION_STATUS from src/constants/workflow.js
 * @param {Array} utteranceList Array of utterance keys
 * @param {Object} workflow Map of utterance keys to utterance objects
 * @returns
 */
export const toDCW = (workflowEdited, versionMetadata, header, utteranceList, workflow) => {
  // Final values to save to the dcw_data object
  const workflow_data = {};
  const utterance_list = [];
  for (let i = 0; i < utteranceList.length; i++) {
    const { metadata, value, edited, ignore } = workflow[utteranceList[i]];
    // Ensure empty utterances are not added to a workflow
    if (isEmpty(value.trim()) || ignore) continue;
    const { answers, kwargs, session_startup, skill, skill_uuid, recipe } = metadata;
    const is_comment = isComment(value);
    const utterance = is_comment ? removeCommentPrefix(value) : value;
    const skillMetadata = SKILL_METADATA_SCHEMA();
    skillMetadata.answers = answers;
    skillMetadata.utterance = utterance;
    skillMetadata.is_comment = is_comment;
    skillMetadata.session_startup = session_startup;
    if (!is_comment || !edited) {
      skillMetadata.kwargs = kwargs;
      skillMetadata.recipe = recipe;
      skillMetadata.skill = skill;
      skillMetadata.skill_uuid = skill_uuid;
    }
    // We use the index + 1 as the key to match the current way keys are assigned by the backend
    workflow_data[i + 1] = skillMetadata;
    utterance_list.push(i + 1);
  }

  const dcw = {
    app_dir_name: header.appName,
    app_version: header.appVersion,
    edited: workflowEdited || versionMetadata.edited,
    last_verified: null,
    raw_text_verified: workflowEdited || versionMetadata.rawTextVerified,
    upgraded: false,
    utterance_list,
    workflow_data,
  };
  return dcw;
};

export const canEditorSave = (isEdited, isReplaying) => isEdited && !isReplaying;
