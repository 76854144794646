import { getCurrentTime } from '../../utils/time';

export const LOAD_SUGGESTIONS_REQUEST = 'suggestions/LOAD_SUGGESTIONS_REQUEST';
export const LOAD_SUGGESTIONS_SUCCESS = 'suggestions/LOAD_SUGGESTIONS_SUCCESS';
export const LOAD_SUGGESTIONS_FAILURE = 'suggestions/LOAD_SUGGESTIONS_FAILURE';
export const DROP_SUGGESTIONS = 'suggestions/DROP_SUGGESTIONS';
export const SHOW_SUGGESTIONS_LOADING_INDICATOR = 'suggestions/SHOW_SUGGESTIONS_LOADING_INDICATOR';

export const loadSuggestionsRequest = ({ message, contextId, textboxId, fieldId }) => ({
  type: LOAD_SUGGESTIONS_REQUEST,
  textboxId,
  message,
  timeOfRequest: getCurrentTime(),
  contextId,
  fieldId,
});

export const loadSuggestionsSuccess = ({
  suggestions,
  prompts,
  timeOfResponse,
  timeTaken,
  message,
  res,
}) => ({
  type: LOAD_SUGGESTIONS_SUCCESS,
  suggestions,
  prompts,
  timeOfResponse,
  timeTaken,
  message,
  res,
});

export const loadSuggestionsFailure = ({ error, timeOfResponse, timeTaken }) => ({
  type: LOAD_SUGGESTIONS_FAILURE,
  error,
  timeOfResponse,
  timeTaken,
});

export const dropSuggestions = () => ({
  type: DROP_SUGGESTIONS,
});

export const showSuggestionsLoadingIndicator = () => ({
  type: SHOW_SUGGESTIONS_LOADING_INDICATOR,
});
