import { ChartTypes, DCColTypes } from 'translate_dc_to_echart';

// Column types that are not supported for this lines feature
export const DISABLED_COL_TYPES = [DCColTypes.DATE, DCColTypes.DATETIME];

// For when !xColFreeSolo or !yColFreeSolo
export const ERROR_MESSAGE_POINTS = 'Select a data point';
// For when xColFreeSolo or yColFreeSolo
export const ERROR_MESSAGE_BOUNDS = 'Out of axis bounds';
// For when the value is empty
export const ERROR_MESSAGE_EMPTY = 'Enter a value';
// For when xColFreeSolo or yColFreeSolo and the value is not a number
export const ERROR_MESSAGE_NUMBER = 'Enter a number';

export const LINE_COLOR = 'black';
export const LINE_END_SYMBOL = 'none';
export const LINE_TYPE = 'solid';
export const DELETE = 'Delete';

export enum LineOptions {
  MIN = 'Minimum',
  MAX = 'Maximum',
  AVG = 'Average',
  HORIZ = 'Horizontal',
  VERT = 'Vertical',
  COORD = 'Coordinates',
}

export const LineOptionsArray = Object.values(LineOptions);

export const LineOptionsMap: { [key in LineOptions]: string } = {
  [LineOptions.MIN]: 'min',
  [LineOptions.MAX]: 'max',
  [LineOptions.AVG]: 'average',
  [LineOptions.HORIZ]: 'horizontal',
  [LineOptions.VERT]: 'vertical',
  [LineOptions.COORD]: 'coordinates',
};

export const LineOptionsDisplay: { [key in (typeof LineOptionsMap)[LineOptions]]: string } = {
  [LineOptionsMap[LineOptions.MIN]]: 'Min',
  [LineOptionsMap[LineOptions.MAX]]: 'Max',
  [LineOptionsMap[LineOptions.AVG]]: 'Avg',
  [LineOptionsMap[LineOptions.HORIZ]]: 'Horiz',
  [LineOptionsMap[LineOptions.VERT]]: 'Vert',
  [LineOptionsMap[LineOptions.COORD]]: 'Coord',
};

export const UNSUPPORTED_BOXPLOT_OPTIONS: LineOptions[] = [
  LineOptions.MIN,
  LineOptions.MAX,
  LineOptions.AVG,
];
export const UNSUPPORTED_LINE_OPTIONS_CHART_TYPES: ChartTypes[] = [
  ChartTypes.box,
  ChartTypes.violin,
  ChartTypes.stackedBar,
];
