import isEmpty from 'lodash/isEmpty';
import {
  CONFIRM_LOAD_DIR_MESSAGE,
  FILLER_PROMPTS,
  NOTE_MESSAGE,
  REPLAY_MESSAGES,
  SENDERS,
  SKILL_EVENTS,
  VISUAL_TYPES,
} from '../../constants';
import {
  addObject,
  createMessageAndUpdateId,
  getContextId,
  getServerMessageId,
  getUserMessageId,
  incrementContextId,
  incrementServerMessageId,
  resetContextId,
  resetServerMessageId,
  resetUserMessageId,
} from '../../utils/chat_id';
import { checkQsMuted, isEmptyTableMessage, replaceEmptyTableMessage } from '../../utils/messages';
import {
  ADD_USER_MESSAGE,
  CHART_UPDATE,
  CLEAR_SCREEN,
  DISABLE_AUTOMATIC_DOWNLOAD,
  RECEIVE_MESSAGES_SUCCESS,
  SEND_MESSAGE_REQUEST,
} from '../actions/messages.actions';

/**
 * A context represents a slice of information exchange between the client and the server.
 * Each context is identified by ID and contains a message that
 * the user has sent, a list of messages that the server responded with,
 * JSON data for the chart and an icon type that corresponds to the chart.
 */
export const emptyContext = {
  id: undefined, // Context ID.
  userTextMessage: {}, // List of objects containing textual data by the user.
  serverTextMessages: [], // List of lists of objects containing textual message data by the server.
  chart: {}, // Chart data.
  icon: {}, // Chart type.
};

export const initialContext = {
  id: 0,
  userTextMessage: {},
  serverTextMessages: [],
  chart: {},
  icon: {},
};

export const initialState = [initialContext];

/**
 * Creates a new context with the message that the user entered.
 *
 * @param {Object} state
 * @param {Object} message
 * @returns {Object} new state
 */
export const addMessageToState = (state, action) => {
  if (action.dashboardDcChartId) {
    state[action.dashboardDcChartId].userTextMessage.data = action.message;
    return state;
  }
  if (checkQsMuted(action.message.data)) return state;
  const newContext = { ...emptyContext };
  incrementContextId(); // Ensure that this new context will have an ID that is greater than the previous context by 1.
  newContext.id = getContextId();
  const messageWithID = createMessageAndUpdateId(action.message);
  newContext.userTextMessage = messageWithID;
  return [...state, newContext];
};

/**
 * Assigns the message an ID then increments the global message ID counter.
 * @param {Object} message A message object
 */
export const assignMessageId = (message) => {
  message.id = getServerMessageId();
  incrementServerMessageId(); // Increment server message ID afterwards so that the ID begins with 0.
};

export const modifyState = (state, action) => {
  if (action.dashboardDcChartId !== undefined) {
    // TODO: do not use a hardcode constant
    let chartContextId = -1;
    for (let i = 0; i < state.length; i++) {
      if (!isEmpty(state[i].chart)) {
        chartContextId++;
      }
      if (chartContextId === action.dashboardDcChartId) {
        state[i].userTextMessage.data = action.message.data;
        break;
      }
    }
  }
  return state;
};

/**
 * Updates the existing context or creates a new context when required.
 * @param {Object} state
 * @returns {Object} new state
 */
export const addMessagesToState = (state, action) => {
  let newState = [...state]; // Prepares the new state
  action.messages.forEach(({ message }) => {
    const { type, data, skillEvent, sender, skillMessageType } = message;
    // Create new chat context
    if (
      skillEvent === SKILL_EVENTS.NEW_BUBBLE ||
      skillEvent === SKILL_EVENTS.IN_CONVERSATION ||
      skillEvent === SKILL_EVENTS.DONE ||
      sender === SENDERS.USER ||
      data === REPLAY_MESSAGES.END ||
      data === NOTE_MESSAGE ||
      data === CONFIRM_LOAD_DIR_MESSAGE ||
      skillMessageType
    ) {
      const newContext = { ...emptyContext }; // Clone an empty context.
      incrementContextId(); // Ensure that this new context will have an ID that is greater than the previous context by 1
      newContext.id = getContextId();
      resetServerMessageId(); // Ensure that the first serverMessageId is 0
      assignMessageId(message);
      if (FILLER_PROMPTS.has(data)) {
        newState = [...newState, newContext];
        return;
      } // Drop filler messages
      if (skillMessageType) {
        newContext.skillMessageType = skillMessageType;
      } // Tag skill messages
      switch (type) {
        case 'text':
        case 'file':
          if (sender === SENDERS.USER) {
            const messageWithID = createMessageAndUpdateId(message);
            newContext.userTextMessage = messageWithID;
          } else {
            newContext.serverTextMessages = [message];
          }
          break;
        case 'collapsible':
          newContext.serverTextMessages = [message];
          break;
        default:
          // Chart, table, tabbed chart messages
          if (isEmptyTableMessage(message)) {
            // replace empty table with an informative message
            newContext.serverTextMessages = [replaceEmptyTableMessage(message)];
            break;
          }
          newContext.chart = message;
          newContext.chart.userMsgId = getUserMessageId();
          newContext.icon =
            message && message.data && message.data.chartType
              ? message.data.iconType || message.data.chartType
              : type;
          if (message.objectId) {
            addObject(message.objectId, type);
          }
          if (message.type === VISUAL_TYPES.TABVISUAL) {
            // map each object nested within the tabvisual object
            const { tabContents } = message.data;
            Object.values(tabContents).forEach((tab) => {
              if (tab.objectId) {
                addObject(tab.objectId, tab.type);
              }
            });
          }
          break;
      }
      newState = [...newState, newContext];
    } else {
      if (FILLER_PROMPTS.has(data) || skillEvent === SKILL_EVENTS.EXIT_CODE) {
        return;
      } // Drop filler messages
      // Update existing chat context
      const currentContext = { ...newState[newState.length - 1] };
      switch (type) {
        case 'text':
        case 'collapsible':
        case 'file':
          // Append message to existing server chat bubble.
          assignMessageId(message);
          currentContext.serverTextMessages = [...currentContext.serverTextMessages, message];
          break;

        default: {
          // Chart, table, tabbed chart messages
          if (isEmptyTableMessage(message)) {
            // replace empty table with an informative message
            currentContext.serverTextMessages = [
              ...currentContext.serverTextMessages,
              replaceEmptyTableMessage(message),
            ];
            break;
          }
          currentContext.chart = message;
          currentContext.chart.userMsgId = getUserMessageId();
          const chartTypeExists = message && message.data && message.data.chartType;
          if (chartTypeExists)
            currentContext.icon = message.data.iconType || message.data.chartType;
          else currentContext.icon = type;
          if (message.objectId) {
            addObject(message.objectId, type);
          }
          if (message.type === VISUAL_TYPES.TABVISUAL) {
            // map each object nested within the tabvisual object
            const { tabContents } = message.data;
            Object.values(tabContents).forEach((tab) => {
              if (tab.objectId) {
                addObject(tab.objectId, tab.type);
              }
            });
          }
          break;
        }
      }
      const leadingContexts = newState.slice(0, newState.length - 1); // All contexts except the current context.
      newState = [...leadingContexts, currentContext];
    }
  });
  return newState;
};

const updateChart = (state, action) => {
  const currentContext = state[state.length - 1];
  for (let i = state.length - 1; i >= 0; i--) {
    const ctx = state[i];
    if (ctx.chart && ctx.chart.objectId === action.objectId) {
      ctx.chart.update = { ...action };
      currentContext.icon = ctx.icon;
      currentContext.link = ctx.id;
      return state;
    } else if (ctx?.chart?.type === VISUAL_TYPES.TABVISUAL && ctx.chart?.data?.tabContents) {
      for (const value of Object.values(ctx.chart?.data?.tabContents)) {
        if (value.objectId === action.objectId) {
          value.update = { ...action };
          currentContext.icon = ctx.icon;
          currentContext.link = ctx.id;
          return state;
        }
      }
    }
  }
  return state;
};

/**
 * Resets the state.
 */
export const resetState = () => {
  resetContextId();
  resetServerMessageId();
  resetUserMessageId();
  return initialState;
};

const disableAutomaticDownload = (state, action) => {
  if (state[action.contextId] && state[action.contextId].serverTextMessages[action.messageId - 1]) {
    state[action.contextId].serverTextMessages[action.messageId - 1].data.method = 'manual';
  }
  return state;
};

/**
 * Handles messages that are received from the server and
 * sent by the user.
 *
 * @param {Object} state
 * @param {Object} action
 * @returns {Object} new state
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case SEND_MESSAGE_REQUEST:
      return modifyState(state, action);
    case RECEIVE_MESSAGES_SUCCESS:
      return addMessagesToState(state, action);
    case CHART_UPDATE:
      return updateChart(state, action);
    case ADD_USER_MESSAGE:
      return addMessageToState(state, action);
    case CLEAR_SCREEN:
      return resetState();
    case DISABLE_AUTOMATIC_DOWNLOAD:
      return disableAutomaticDownload(state, action);
    default:
      return state;
  }
};
