import { call, put, select, takeEvery } from 'redux-saga/effects';
import { createAlertChannelRequest } from '../actions/dialog.actions';
import {
  GET_SESSION_PREVIEW_REQUEST,
  GET_WORKFLOW_PREVIEW_API_KEY_REQUEST,
  GET_WORKFLOW_PREVIEW_REQUEST,
  getSessionPreviewFailure,
  getSessionPreviewSuccess,
  getWorkflowPreviewFailure,
  getWorkflowPreviewSuccess,
} from '../actions/utterances_preview.actions';

import { getSessionInfo } from '../../api/session.api';
import { getWorkflow, getWorkflowAPIKey } from '../../api/workflow.api';
import { SESSION_ROOT_NODE } from '../../constants';
import { UNNAMED_SESSION } from '../../constants/session';
import { unpackWorkflowFile } from '../../utils/workflow';
import { getNodesWorker } from './nodes.saga';
import { selectAccessToken } from './selectors';
import { filterMessages, getClientMessages } from './utils/messages';
import { getWorkflowData } from './utils/workflow';

function* getWorkflowPreviewRequestHelper({ workflowId, latestOnly, data }) {
  const { content, name, workflowData, workflowVersions } = getWorkflowData(data);
  const versions = {};
  if (latestOnly) {
    // Process and store only the latest version of the workflow
    const lines = content.split('\n').filter((line) => line !== '');
    versions[workflowData.current_version] = lines;
  } else {
    // Process and store all versions of the workflow
    workflowVersions.shift();
    workflowVersions.forEach((workflowVersion) => {
      const unpacked = unpackWorkflowFile(workflowVersion.dcw_data);
      const versionLines = unpacked.content.split('\n').filter((line) => line !== '');
      versions[workflowVersion.version] = versionLines;
    });
  }
  yield put(
    getWorkflowPreviewSuccess({
      workflowId,
      latestVersion: workflowData.current_version,
      name,
      data: versions,
    }),
  );
}

export function* getWorkflowPreviewRequestWorker({ workflowId, latestOnly }) {
  try {
    const accessToken = yield select(selectAccessToken);
    // last parameter (preview) of getWorkflow is true - this prevents the backend from updating
    // last accessed time when getting an utterance preview.
    const response = yield call(getWorkflow, accessToken, workflowId, true);
    yield* getWorkflowPreviewRequestHelper({ workflowId, latestOnly, data: response.data });
  } catch (error) {
    yield put(getWorkflowPreviewFailure({ workflowId, error }));
  }
}

export function* getWorkflowPreviewAPIKeyRequestWorker({ workflowId, clientId, secret }) {
  try {
    const response = yield call(getWorkflowAPIKey, workflowId, clientId, secret);
    yield* getWorkflowPreviewRequestHelper({ workflowId, data: response.data, latestOnly: true });
  } catch (error) {
    yield put(getWorkflowPreviewFailure({ workflowId, error }));
  }
}

/**
 * @param {Number} sessionId get the id of the session hoovered
 * @returns the user utterances data and the name of the session
 */
export function* getSessionPreviewRequestWorker({ sessionId }) {
  try {
    let sessionName = UNNAMED_SESSION;

    // get session's user-facing messages
    const response = yield call(getNodesWorker, sessionId, SESSION_ROOT_NODE, 0);
    const { messages } = response.data;
    const { generalMessages } = filterMessages(messages);
    const userMessages = getClientMessages(generalMessages);

    // get name of session
    const accessToken = yield select(selectAccessToken);
    const sessionInfo = yield call(getSessionInfo, accessToken, sessionId);
    const { name } = sessionInfo.data;
    if (name.String && name.Valid) {
      sessionName = name.String;
    }

    yield put(getSessionPreviewSuccess({ sessionId, userMessages, sessionName }));
  } catch (error) {
    yield put(getSessionPreviewFailure({ error }));
    yield put(createAlertChannelRequest({ error }));
  }
}

export default function* () {
  yield takeEvery(GET_WORKFLOW_PREVIEW_REQUEST, getWorkflowPreviewRequestWorker);
  yield takeEvery(GET_WORKFLOW_PREVIEW_API_KEY_REQUEST, getWorkflowPreviewAPIKeyRequestWorker);
  yield takeEvery(GET_SESSION_PREVIEW_REQUEST, getSessionPreviewRequestWorker);
}
