import isEqual from 'lodash/isEqual';
import { useRef } from 'react';

/**
 * Memoize the value and only update if the value changes
 *
 * @param value the value to memoize
 */
const useDeepCompareMemoize = <T>(value: T): T | undefined => {
  const ref = useRef<T>();

  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
};

export default useDeepCompareMemoize;
