import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';

/**
 * A workflow is determined to be edited if the order of the utterances has changed,
 * or if at least one of the utterances has been marked as edited.
 * @param {Object} state - editor reducer state
 * @param {Object} newWorkflow  - updated workflow object
 * @param {Array} newUtteranceList - updated utterance list
 * @returns true if the workflow has been edited, false otherwise
 */
export const isEdited = (state, newWorkflow, newUtteranceList) => {
  const { utteranceListOriginal } = state;
  // Filter out blank utterances, as they are not saved with the workflow
  const utteranceListFiltered = newUtteranceList.filter(
    (key) => !isEmpty(newWorkflow[key].value.trim()),
  );
  // First, check if the lengths of the original and edited workflows differ
  if (utteranceListFiltered.length === utteranceListOriginal.length) {
    for (let i = 0; i < utteranceListFiltered.length; i++) {
      // Second, check if the uttKeys at each index differ
      if (utteranceListFiltered[i] !== utteranceListOriginal[i]) return true;
      // Third, check if the 'edited' flag of each index is true
      if (newWorkflow[utteranceListFiltered[i]]?.edited) return true;
    }
    return false;
  }
  return true;
};

/**
 * Validates an utterance index for editability.
 * @param {Object} state - editor reducer state
 * @param {Number} uttIndex - index to validate
 */
const validateUttIndex = (state, uttIndex) => {
  const { execIndex } = state;
  // Ensure an utterance is not added before the active utterance
  if (uttIndex < execIndex) throw Error(`Replay has already passed UttIndex ${uttIndex}.`);
};

/**
 * Validates an utterance key for editability.
 * @param {Object} state - editor reducer state
 * @param {Number} uttKey key to validate
 */
const validateUttKey = (state, uttKey) => {
  const { utteranceList } = state;
  const uttIndex = utteranceList.indexOf(uttKey);
  if (uttIndex === -1) throw Error(`UttKey ${uttKey} not found in workflow.`);
  validateUttIndex(state, uttIndex);
};

/**
 * Helper function to add an utterance to the workflow.
 * @param {Object} state - editor reducer state
 * @param {Number} uttIndex - index into utteranceList to insert an utterance
 * @param {Number} uttKey - key to given the new utterance
 * @param {Object} uttObject - utterance object to insert into the workflow
 * @returns object to update the editor reducer state
 */
export const addUtteranceHelper = (state, uttIndex, uttKey, uttObject) => {
  validateUttIndex(state, uttIndex);
  const { workflow, utteranceList } = state;
  // Add the new utterance object to the workflow map
  const newWorkflow = { ...workflow, [uttKey]: uttObject };
  // Insert the utterance key into the utteranceList
  const newUtteranceList = [...utteranceList];
  newUtteranceList.splice(uttIndex, 0, uttKey);
  return {
    workflow: newWorkflow,
    utteranceList: newUtteranceList,
    edited: isEdited(state, newWorkflow, newUtteranceList),
  };
};

/**
 * Helper function to update an utterance in the workflow.
 * @param {Object} state - editor reducer state
 * @param {Number} uttKey - key of the utterance to be updated
 * @param {String} newValue - new value of the utterance
 * @returns object to update the editor reducer state
 */
export const editUtteranceHelper = (state, uttKey, newValue) => {
  validateUttKey(state, uttKey);
  const { workflow } = state;
  const uttObject = cloneDeep(workflow[uttKey]);
  if (newValue.trim() === uttObject.value.trim()) throw Error(`No change detected.`);
  const edited = newValue.trim() !== uttObject.originalUtterance.trim();
  uttObject.edited = edited; // Update utterance's edited status
  uttObject.value = newValue; // Update textbox value
  uttObject.metadata.answers = {}; // Clear answers object
  const newWorkflow = { ...workflow, [uttKey]: uttObject };
  return {
    workflow: newWorkflow,
    edited: isEdited(state, newWorkflow, state.utteranceList),
  };
};

/**
 * Helper function to delete an utterance from the workflow.
 * @param {Object} state - editor reducer state
 * @param {Number} uttIndex - index of the utterance to delete
 * @param {Number} uttKey - key of the utterance to delete
 * @returns object to update the editor reducer state
 */
export const deleteUtteranceHelper = (state, uttIndex, uttKey) => {
  validateUttIndex(state, uttIndex);
  const { workflow, utteranceList, selection } = state;
  // Remove the utterance object from the workflow
  const newWorkflow = { ...workflow };
  delete newWorkflow[uttKey];
  // Remove the utterance key from the utterance key list
  const newUtteranceList = [...utteranceList];
  newUtteranceList.splice(uttIndex, 1);
  // Remove the utterance key from the selection
  const selIndex = selection.indexOf(uttKey);
  const updatedSelection = [...selection];
  if (selIndex !== -1) updatedSelection.splice(selIndex, 1);
  return {
    workflow: newWorkflow,
    utteranceList: newUtteranceList,
    selection: updatedSelection,
    edited: isEdited(state, newWorkflow, newUtteranceList),
  };
};

/**
 * Helper function to split an utterance into two.
 * This can be simplified to being a combination of an edit and a addition.
 * @param {Object} state editor reducer state
 * @param {Object} edit contains info needed for editing an utterance
 * @param {Object} addition contains info needed for adding an utterance to the workflow
 * @returns object to update the editor reducer state
 */
export const splitUtteranceHelper = (state, edit, addition) => {
  const { workflow, utteranceList } = state;
  const newWorkflow = cloneDeep(workflow);
  const newUtteranceList = [...utteranceList];
  // edit the current line to the first half of the split utterance
  newWorkflow[edit.uttKey].value = edit.newValue;
  // add a newline with the content of the seconf half of the line
  newWorkflow[addition.uttKey] = addition.uttObject;
  newUtteranceList.splice(addition.uttIndex, 0, addition.uttKey);
  return {
    workflow: newWorkflow,
    utteranceList: newUtteranceList,
    edited: isEdited(state, newWorkflow, newUtteranceList),
  };
};

/**
 * Helper function to merge two utterances together (i.e. remove a newline between them).
 * This can be simplified to being a combination of an edit and a deletion.
 * @param {Object} state editor reducer state
 * @param {Object} edit contains info needed for making an edit
 * @param {Object} deletion contains info needed for making a deletion
 * @returns object to update the editor reducer state
 */
export const mergeUtterancesHelper = (state, edit, deletion) => {
  const { workflow, utteranceList, selection } = state;
  const newWorkflow = cloneDeep(workflow);
  const newUtteranceList = [...utteranceList];
  newWorkflow[edit.uttKey].value = edit.newValue;
  delete newWorkflow[deletion.uttKey];
  newUtteranceList.splice(deletion.uttIndex, 1);
  // Remove the utterance key from the selection
  const selIndex = selection.indexOf(deletion.uttKey);
  const updatedSelection = [...selection];
  if (selIndex !== -1) updatedSelection.splice(selIndex, 1);
  return {
    workflow: newWorkflow,
    utteranceList: newUtteranceList,
    selection: updatedSelection,
    edited: isEdited(state, newWorkflow, newUtteranceList),
  };
};

/**
 * Helper function to reorder utterances in the workflow.
 * @param {Object} state - editor reducer state
 * @param {Array} newUtteranceList - new utteranceList
 * @returns object to update the editor reducer state
 */
export const reorderUtteranceHelper = (state, newUtteranceList) => {
  const { utteranceList, execIndex } = state;
  for (let i = 0; i < execIndex; i++) {
    if (utteranceList[i] !== newUtteranceList[i]) {
      throw Error('Order of replayed utterances being changed.');
    }
  }
  return { utteranceList: newUtteranceList, ...isEdited(state, state.workflow, newUtteranceList) };
};

/**
 * Helper function to insert multiple utterances into the workflow.
 * @param {Object} state - editor reducer state
 * @param {Array} insertions - array of objects, each containing info needed for insertion
 * @returns object to update the editor reducer state
 */
export const bulkAddUtteranceHelper = (state, insertions) => {
  const { workflow, utteranceList } = state;
  // construct new workflow & utteranceList
  const newUtteranceList = [...utteranceList];
  const newWorkflow = { ...workflow };
  for (let i = 0; i < insertions.length; i++) {
    validateUttIndex(state, insertions[i].uttIndex);
    newUtteranceList.splice(insertions[i].uttIndex, 0, insertions[i].uttKey);
    newWorkflow[insertions[i].uttKey] = insertions[i].uttObject;
  }
  return {
    workflow: newWorkflow,
    utteranceList: newUtteranceList,
    edited: isEdited(state, newWorkflow, newUtteranceList),
  };
};

/**
 * TODO Helper function to edit multiple utterances from the workflow.
 * @param {Object} state - editor reducer state
 * @param {Array} deletions - array of objects, each containing info needed for deletion
 * @returns object to update the editor reducer state
 */
export const bulkEditUtterancesHelper = (state, edits) => {
  const { workflow, utteranceList } = state;
  const newWorkflow = { ...workflow };
  const newUtteranceList = [...utteranceList];
  // utteranceList using their indices.
  for (let i = edits.length - 1; i >= 0; i--) {
    const uttObject = cloneDeep(newWorkflow[edits[i].uttKey]);
    const edited = edits[i].newValue.trim() !== uttObject.originalUtterance.trim();
    uttObject.edited = edited; // Update utterance's edited status
    uttObject.value = edits[i].newValue; // Update textbox value
    uttObject.metadata.answers = {}; // Clear answers object
    newWorkflow[edits[i].uttKey] = uttObject;
  }
  return {
    workflow: newWorkflow,
    edited: isEdited(state, newWorkflow, newUtteranceList),
  };
};

/**
 * Helper function to delete multiple utterances from the workflow.
 * @param {Object} state - editor reducer state
 * @param {Array} deletions - array of objects, each containing info needed for deletion
 * @returns object to update the editor reducer state
 */
export const bulkDeleteUtterancesHelper = (state, deletions) => {
  const { workflow, utteranceList } = state;
  const newWorkflow = { ...workflow };
  const newUtteranceList = [...utteranceList];
  // deletions must occur in reverse order because uttKeys are removed from
  // utteranceList using their indices.
  for (let i = deletions.length - 1; i >= 0; i--) {
    validateUttIndex(state, deletions[i].uttIndex);
    newUtteranceList.splice(deletions[i].uttIndex, 1);
    delete newWorkflow[deletions[i].uttKey];
  }
  return {
    workflow: newWorkflow,
    utteranceList: newUtteranceList,
    edited: isEdited(state, newWorkflow, newUtteranceList),
  };
};
