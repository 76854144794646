import axios from 'axios';
import { endpoints } from '../constants/endpoints';

export const postInterrupt = (accessToken, sessionId) =>
  axios.post(endpoints.interrupt, null, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    params: {
      session: sessionId,
    },
  });

export const postUtteranceTimeoutExtension = (accessToken, sessionId, timeoutExtension) =>
  axios.post(endpoints.extendUtteranceTimeout, null, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    params: {
      session: sessionId,
      utterance_timeout: timeoutExtension,
    },
  });
