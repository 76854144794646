import AutoFixHigh from '@mui/icons-material/AutoFixHigh';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { KEYBOARD_KEYS } from '../../../constants';
import { ASK_AVA_CHARACTER_LIMIT } from '../../../constants/askAva';
import { selectIsEditingDataset } from '../../../store/selectors/dataspace.selector';
import { handleUnsavedChanges } from '../../../store/slices/dataspace.slice';
import { selectHasDatasetEdits } from '../../../store/selectors/catalog.selector';
import './AskEmbedded.scss';

const ASK_EMBEDDED_INPUT_ID = 'DataAssistant-AskEmbeddedInput';

const AskEmbeddedInput = ({
  id,
  submitAction,
  helperText,
  disabled,
  inputHistory,
  placeholder,
}) => {
  const dispatch = useDispatch();
  // tracked input value for the text input
  const [askInputValue, setAskInputValue] = useState('');
  const [stagedInputValue, setStagedInputValue] = useState('');

  const isEditingDataset = useSelector(selectIsEditingDataset);
  const hasAnnotationEdits = useSelector(selectHasDatasetEdits);

  const textFieldRef = useRef();
  const inputRef = useRef();

  // input history location
  const [inputHistoryLocation, setInputHistoryLocation] = useState(-1);

  // submit the question request
  const handleSubmit = () => {
    if (isEditingDataset || hasAnnotationEdits) {
      dispatch(handleUnsavedChanges());
      return;
    }
    submitAction(askInputValue);
  };

  // allow users to submit the question request by pressing enter
  const handleKeyDown = (e) => {
    if (e.key === KEYBOARD_KEYS.ENTER && !e.shiftKey) {
      e.preventDefault();
      if (askInputValue.length > 0) {
        handleSubmit();
      }
    } else if (e.key === KEYBOARD_KEYS.ESC) {
      e.preventDefault();
      // if the escape key is pressed, blur the input field
      // inputRef.current.blur();
      inputRef.current.blur();
    } else if (e.key === KEYBOARD_KEYS.ARROW_DOWN && e.metaKey) {
      e.preventDefault();
      if (inputHistoryLocation > -1) {
        // if the input location is greater than -1 (signifying empty input)
        // then set the new input location
        setInputHistoryLocation(inputHistoryLocation - 1);
      }
    } else if (e.key === KEYBOARD_KEYS.ARROW_UP && e.metaKey) {
      e.preventDefault();
      if (inputHistoryLocation < inputHistory.length - 1) {
        // if the input location is less than the last index of the history
        // then set the new location
        if (inputHistoryLocation === -1) {
          // if the input location is -1, then save the current input value
          setStagedInputValue(askInputValue);
        }
        setInputHistoryLocation(inputHistoryLocation + 1);
      }
    }
  };

  // whenever the inputHistory location changes, update the input value
  useEffect(() => {
    if (inputHistoryLocation === -1) {
      // if the input history location is -1, then set the input value to empty
      setAskInputValue(stagedInputValue);
    } else {
      // get the input History index based on the location
      const inputHistoryIndex = inputHistory.length - inputHistoryLocation - 1;
      // set the input value to the input history value
      setAskInputValue(inputHistory[inputHistoryIndex]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputHistoryLocation]);

  useEffect(() => {
    // whenever the input history changes, reset the input history location
    // which sets the input value to empty
    setStagedInputValue('');
    setAskInputValue('');
    setInputHistoryLocation(-1);
  }, [inputHistory.length]);

  const renderHelperInfo = () => {
    if (askInputValue.length === ASK_AVA_CHARACTER_LIMIT) {
      return <span className="AskEmbedded-Input-Helper-Info">Character limit reached</span>;
    } else if (helperText) {
      return <span className="AskEmbedded-Input-Helper-Info">{helperText}</span>;
    }
    return null;
  };

  const handleInputChange = (e) => {
    if (e.target.value.length >= ASK_AVA_CHARACTER_LIMIT) {
      // if the input value is exceeds the character limit, take only the
      // first set of characters up to the limit.
      setAskInputValue(e.target.value.slice(0, ASK_AVA_CHARACTER_LIMIT));
    } else {
      setAskInputValue(e.target.value);
    }
  };
  return (
    <TextField
      id={id}
      name={id}
      ref={textFieldRef}
      inputRef={inputRef}
      className="AskEmbedded-Input"
      value={askInputValue}
      onChange={handleInputChange}
      onKeyDown={handleKeyDown}
      multiline
      variant="outlined"
      disabled={disabled}
      maxRows={8}
      size="small"
      placeholder={placeholder}
      helperText={
        <div className="AskEmbedded-Input-Helper">
          {renderHelperInfo()}
          {askInputValue.length === ASK_AVA_CHARACTER_LIMIT && (
            <span className="AskEmbedded-Input-Helper-Ratio">{`${askInputValue.length}/${ASK_AVA_CHARACTER_LIMIT}`}</span>
          )}
        </div>
      }
      FormHelperTextProps={{ component: 'div' }}
      InputProps={{
        endAdornment: (
          <IconButton
            aria-label="submit"
            color="primary"
            size="small"
            onClick={() => handleSubmit()}
            variant="contained"
            disabled={disabled || askInputValue.trim().length === 0}
          >
            <AutoFixHigh />
          </IconButton>
        ),
      }}
    />
  );
};

AskEmbeddedInput.propTypes = {
  id: PropTypes.string,
  submitAction: PropTypes.func.isRequired,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  disabled: PropTypes.bool.isRequired,
  inputHistory: PropTypes.arrayOf(PropTypes.string),
  placeholder: PropTypes.string,
};

AskEmbeddedInput.defaultProps = {
  id: ASK_EMBEDDED_INPUT_ID,
  helperText: null,
  inputHistory: [],
  placeholder: 'Ask DataChat...',
};

export default AskEmbeddedInput;
