import ThumbDown from '@mui/icons-material/ThumbDown';
import ThumbDownOutlined from '@mui/icons-material/ThumbDownOutlined';
import ThumbUp from '@mui/icons-material/ThumbUp';
import ThumbUpOutlined from '@mui/icons-material/ThumbUpOutlined';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DOWN_VOTE, UP_VOTE } from '../../../../../../constants/userFeedback';
import { selectDataAssistantFeedbackList } from '../../../../../../store/selectors/dataAssistant.selector';
import {
  editAskFeedbackRequest,
  submitAskFeedbackRequest,
} from '../../../../../../store/slices/dataAssistant.slice';

/**
 * Returns both the thumb up and thumb down buttons for feedback on responses.
 */
const FeedbackActions = ({ id, vectorId, metadata }) => {
  const dispatch = useDispatch();
  const feedbackList = useSelector(selectDataAssistantFeedbackList);
  // handle when user clicks thumbs up or thumbs down
  const handleFeedbackClick = (newVote) => {
    if (feedbackList?.[vectorId] && feedbackList[vectorId].vote === newVote) {
      // if feedback already exists and is the same as the new vote, remove it
      dispatch(
        editAskFeedbackRequest({
          feedbackRecord: feedbackList[vectorId],
          vectorId,
          vote: 0, // 0 means remove feedback
        }),
      );
    } else if (feedbackList?.[vectorId] && feedbackList[vectorId].vote !== newVote) {
      // if feedback already exists, edit it
      dispatch(
        editAskFeedbackRequest({
          feedbackRecord: feedbackList[vectorId],
          vectorId,
          vote: newVote,
        }),
      );
    } else {
      // otherwise, create a new feedback record
      dispatch(submitAskFeedbackRequest({ vectorId, vote: newVote, metadata }));
    }
  };

  return (
    <>
      <IconButton
        size="small"
        data-testid={`${id}-thumb-up`}
        key={`${id}-thumb-up`}
        onClick={() => handleFeedbackClick(UP_VOTE)}
      >
        {feedbackList?.[vectorId]?.vote === UP_VOTE ? (
          <ThumbUp fontSize="inherit" />
        ) : (
          <ThumbUpOutlined fontSize="inherit" />
        )}
      </IconButton>
      <IconButton
        size="small"
        data-testid={`${id}-thumb-down`}
        key={`${id}-thumb-down`}
        onClick={() => handleFeedbackClick(DOWN_VOTE)}
      >
        {feedbackList?.[vectorId]?.vote === DOWN_VOTE ? (
          <ThumbDown fontSize="inherit" />
        ) : (
          <ThumbDownOutlined fontSize="inherit" />
        )}
      </IconButton>
    </>
  );
};

FeedbackActions.propTypes = {
  id: PropTypes.string.isRequired,
  vectorId: PropTypes.string.isRequired,
  metadata: PropTypes.object.isRequired,
};

export default FeedbackActions;
