import { createSelector } from 'reselect';
import { RootState } from '../../../configureStore';

export const selectPipelinerDatasets = (state: RootState) => state.pipelinerDatasets;

export const selectPipelinerDataset = createSelector(
  [selectPipelinerDatasets, (_: RootState, id: string) => id],
  (datasets, id) => datasets[id] ?? null,
);

export const selectPipelinerDatasetDescription = createSelector(
  [selectPipelinerDataset],
  (dataset) => dataset?.description ?? null,
);
