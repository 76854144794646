import Grid from '@mui/material/Grid';
import React from 'react';
import Spinner from 'react-spinkit';
import '../../styles/ChartLoadingIndicator.scss';

/**
 * A screen that indicates that the server is starting up.
 */

type Props = {
  message: string,
};
class ChartLoadingIndicator extends React.Component<Props> {
  render() {
    return (
      <Grid container spacing={1} direction="row-reverse">
        <Grid item className="ChartLoadingIndicator">
          <Spinner fadeIn="none" name="ball-clip-rotate" />
        </Grid>
        <Grid item className="ChartLoadingIndicator-Text">
          {!this.props.message ? (
            'Refreshing Item...'
          ) : (
            <span>{this.props.message.replace(/<[^>]*>?/gm, '')}</span>
          )}
        </Grid>
      </Grid>
    );
  }
}

export default ChartLoadingIndicator;
