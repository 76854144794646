/**
 * Decides the bottom pixel value of the Chat Panel.
 * Sets it to always be from the top border of the Textbox.
 */
export const getChatPanelBottomPosition = () => {
  const textbox = document.getElementsByClassName('Textbox Lexical')[0];
  const textboxHeight = textbox && textbox.offsetHeight;
  const pixels = `${textboxHeight}px`;
  return pixels;
};

/**
 * Decides the height of the textbox based on the second latest context.
 * This provides sufficient information to the user when the ChatPanel pops up.
 * @param {List} contexts List of context objects
 */
export const getContextualChatPanelHeight = (contexts) => {
  const secondLatestContextId = contexts && contexts.length > 1 && contexts.length - 2;
  // DOM element of the most recent message with class user
  const secondLatestContext = document.getElementById(`chat-context-${secondLatestContextId}`);
  if (!secondLatestContext) return null; // Do nothing if the second latest context does not exist.
  // Y position of the user message
  const secondLatestContextY = secondLatestContext.getBoundingClientRect().y;
  // Vertical height of the user message
  const secondLatestContextHeight = secondLatestContext.getBoundingClientRect().height;
  // Y position of the chat tray
  const textbox = document.getElementsByClassName('Textbox Lexical')[0];
  const chatTrayY = textbox && textbox.getBoundingClientRect().y;
  // Numerical pixel offset from the bottom of the screen
  const chatTrayHeight = chatTrayY - secondLatestContextY + secondLatestContextHeight * 1.7;
  const pixels = `${chatTrayHeight}px`;
  return pixels;
};
