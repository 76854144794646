import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../../../ChatPanel/ChatContext/BubbleComponent/Icon';

/**
 * Component for rendering the menu item for a sub tab
 */
const SubTabContent = (props) => {
  const { subTabLabel, subTabContent } = props;
  let subtabtype;
  if (subTabContent?.type) {
    // get the icon based on the specific chart type
    if (subTabContent.type === 'viz') {
      subtabtype = subTabContent.data.chartType;
    } else {
      subtabtype = subTabContent.type;
    }
    return (
      // Add tooltip to sub tab menu items if tooltip in data is not empty
      <Tooltip
        title={subTabContent.data?.tooltip ? subTabContent.data.tooltip : ''}
        placement="right"
      >
        <div className="sub-tab-content">
          <div className="sub-tab-icon">
            <Icon iconType={subtabtype} />
          </div>
          <span>{subTabLabel}</span>
        </div>
      </Tooltip>
    );
  }
  return subTabLabel;
};

SubTabContent.propTypes = {
  // from parent component
  subTabLabel: PropTypes.string.isRequired,
  subTabContent: PropTypes.object.isRequired,
};

export default SubTabContent;
