import type { RefObject } from 'react';
import { VISUAL_TYPES } from '.';
import { truncate } from '../utils/string';

export enum AskPromptClass {
  QUESTION = 'question',
  ANSWER = 'answer',
  FAILURE = 'failure',
  INFORMATION = 'information',
  UPDATE = 'update',
}

// chart types supported by CarouselChart component
export const CAROUSEL_SUPPORTED_TYPES: string[] = [VISUAL_TYPES.VIZ];

/**
 * Unique ids for elements in the ava application.
 * Ids in this list are expected to be unique or a function that creates unique id.
 */
export const AVA_IDS = {
  CHAT_WINDOW: 'ask-chat-window',
  CONVERSATION: 'ask-conversation',
  SWEEP_BUTTON: 'ask-sweep-button',
  VALIDATION_PANEL: 'ask-validation-panel',
  EXAMPLE: (exampleName: string) => `ask-example-${exampleName}`,
  MSG_CHART_WRAPPER: (msgId: string) => `${msgId}-chart-wrapper`,
  MSG_TABLE_WRAPPER: (msgId: string) => `${msgId}-table-wrapper`,
  MSG_CAROUSEL_WRAPPER: (msgId: string) => `${msgId}-carousel-wrapper`,
  MSG_CAROUSEL_PREV: (msgId: string) => `${msgId}-carousel-prev`,
  MSG_CAROUSEL_NEXT: (msgId: string) => `${msgId}-carousel-next`,
};

/**
 * Helper function to scroll to the bottom of the conversation.
 * @param scrollRef Reference to the scrollable element.
 */
export const scrollToConversationBottom = (scrollRef: RefObject<HTMLDivElement>) => {
  const container = scrollRef?.current;
  if (container) container.scrollTo({ top: container.scrollHeight });
};

// Default max character length for displayed object names (conversations, files, datasets, etc.)
export const AVA_MAX_NAME_LENGTH = 32;

/**
 * Error messages for Ava errors displayed using toasts.
 */
export const AVA_TOAST_ERRORS = {
  DELETE_CONVERSATION: (name: string) => {
    const truncatedName = truncate(name, AVA_MAX_NAME_LENGTH);
    return `Couldn't delete conversation "${truncatedName}". Try again later.`;
  },
  SUBMIT_QUESTION: "Couldn't submit question to Ava. Try again later.",
  SUBMIT_FEEDBACK: "Couldn't submit feedback. Try again later.",
  UPDATE_FEEDBACK: "Couldn't update feedback. Try again later.",
};

// loading messages for when Ava is generating code
export const LOADING_MESSAGES: string[] = [
  'Good things come to those who wait...',
  'Brick by brick, my citizens, brick by brick...',
  'Data governance in action...',
  'Crunching numbers and telling jokes...',
  'Data fairies are working their magic...',
  'Fetching data faster than a speeding bullet...',
  'Turning data into gold (not literally)...',
  'Great things are worth waiting for...',
  'Data is like coffee, it takes time to brew...',
  'Data disco in progress Groovy insights coming...',
  'Unveiling the power of your data...',
  'Swapping time and space...',
  'Converting raw data into actionable insights is our specialty...',
  'A zettabyte (ZB) is equal to one trillion gigabytes (GB)...',
  'Insights are en route...',
  'Data magic in action...',
  'Catching fireflies in the data field!',
  "The world's largest data center is as big as 92 football fields...",
  'Dotting i’s and crossing t’s...',
  'When nothing is going right, go left...',
  'Great works are performed not by strength but by perseverance...',
  'Excellence takes time...',
  'While data loads, opportunity knocks. Stay ready!',
  'Data analysis served with a smile...',
  'Step by step, hand in hand, we achieve.',
  'Rome wasn’t built in a day',
  "Sit tight, friend! We're on the case...",
  "Just a moment, and we'll have your insights ready...",
  'Your insights are getting a personal touch. Almost there!',
  "We're data buddies, making things happen together!",
];

export const AVA_ERROR_MESSAGES = {
  BAD_REQUEST: 'The request was invalid. Please try rephrasing your question.',
  UNAUTHORIZED: 'You are not authorized to perform this action. Please sign in again.',
  FORBIDDEN: 'You do not have permission to perform this action.',
  NOT_FOUND: 'The requested resource was not found.',
  TOO_MANY_REQUESTS:
    'Oops! It looks like you currently have a request in the queue. If this is not the case, an error might have occurred while processing your previous request. Try again in 2 minutes.',
  CLIENT_ERROR: 'There was an issue with your request. Please try again.',
} as const;

// How long to poll for status of question after connection error
export const QUESTION_FAILURE_TIMEOUT = 30000;
