/**
 * @fileoverview Dataset saga utils
 *
 * Contains functions for handling dataset requests and context-independent operations,
 * which include puts to multiple different actions & reducer states.
 */

import { put } from 'redux-saga/effects';
import { shouldUseBackendCompute } from 'translate_dc_to_echart';
import { getDatasetReferenceString } from '../../../components/ChartData/dataUtils';
import { sampleSessionlessDatasetRequest } from '../../actions/chart.actions';
import { computeDatasetRequest, sampleSessionDatasetRequest } from '../../actions/dataset.actions';

/**
 * Chooses between session & sessionless sampling, and pushing the
 * aggregation, binning, & filtering to the BE independent of context
 *
 * Also constructs the reference string for the dataset
 *
 * @param {Object} computeSpec The compute spec for the dataset:
 * { aggregate, bins, transforms }
 * @param {String} insightsBoardId The id of the current IB (for use in claims)
 * @param {Number} numRows Number of rows to sample
 * @param {String} pipelinerDatasetId Pipeline ID of the dataset
 * @param {String} publicationId The id of the current publication (for use in claims)
 * @param {String} dcObjectId The uuid of the workspace object (for use in claims)
 */
export function* selectDatasetRequest(params) {
  const {
    computeSpec,
    insightsBoardId,
    numRows,
    pipelinerDatasetId,
    publicationId,
    dcChartId,
    dcObjectId,
    useCache = true,
  } = params;

  // We should always try to use backend compute if we're requesting the whole dataset (for charts)
  const useBackendCompute =
    Boolean(pipelinerDatasetId) && shouldUseBackendCompute({ computeSpec, numRows });

  // Construct the reference string
  const referenceString = getDatasetReferenceString({
    computeSpec,
    dcChartId,
    pipelinerDatasetId,
    usedCompute: useBackendCompute,
  });

  if (useBackendCompute) {
    yield put(
      computeDatasetRequest({
        ...params,
        referenceString,
        workspaceUuid: dcObjectId,
        useCache,
      }),
    );
  } else if (insightsBoardId || publicationId || dcObjectId) {
    yield put(
      sampleSessionlessDatasetRequest({ ...params, referenceString, workspaceUuid: dcObjectId }),
    );
  } else {
    yield put(
      sampleSessionDatasetRequest({ ...params, referenceString, workspaceUuid: dcObjectId }),
    );
  }
}
