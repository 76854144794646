import axios, { AxiosResponse } from 'axios';
import { SESSION_ROOT_NODE } from '../constants';
import { dcAppServiceEndpoints } from '../constants/endpoints';
import { Message, Node } from '../store/slices/nodes.slice';
import { handleDCAppServiceCall } from '../utils/errorHandling/errorHandlers.api';

export interface GetNodesResponse {
  currentThread: string[];
  messages: Message[];
  nodes: { [nodeId: string]: Node };
  offset: number;
}

export const getNodes = (
  accessToken: string,
  sessionId: string,
  currentNodeId: string = SESSION_ROOT_NODE,
  currentNodeOffset: number = 0,
): Promise<AxiosResponse<GetNodesResponse>> => {
  // if accessToken is not provided, it means that the user is not logged in
  // Instead, we will use the query params to get the client_id and secret
  // to make the request
  let clientId;
  let secret;
  const headers: { Authorization?: string } = {};
  if (!accessToken) {
    const query = new URLSearchParams(window.location.search);
    clientId = query.get('client_id');
    secret = query.get('secret');
  } else {
    headers.Authorization = `Bearer ${accessToken}`;
  }
  return handleDCAppServiceCall(() =>
    axios.get(dcAppServiceEndpoints.messageStore, {
      headers,
      params: {
        sessionId,
        uuid: currentNodeId,
        offset: currentNodeOffset,
        client_id: clientId,
        secret,
      },
    }),
  );
};

export const insertMessage = (
  accessToken: string,
  sessionId: string,
  type: string,
  message?: Message,
  parent?: string,
  metadata?: { [key: string]: string | number | boolean | null },
): Promise<AxiosResponse<Node>> => {
  return handleDCAppServiceCall(() =>
    axios.put(
      `${dcAppServiceEndpoints.messageStore}/node`,
      {
        node_id: parent,
        node_type: type,
        payload: message,
        metadata,
      },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
        params: { sessionId },
      },
    ),
  );
};
