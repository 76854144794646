export const CHECK_LICENSE_EXPIRY_REQUEST = 'license/CHECK_LICENSE_EXPIRY_REQUEST';
export const CHECK_LICENSE_EXPIRY_SUCCESS = 'license/CHECK_LICENSE_EXPIRY_SUCCESS';
export const CHECK_LICENSE_EXPIRY_FAILURE = 'license/CHECK_LICENSE_EXPIRY_FAILURE';

export const SAVE_LICENSE_TIME_STAMP_TO_LS = 'license/SAVE_LICENSE_TIME_STAMP_TO_LS';

export const checkLicenseExpiryRequest = () => ({
  type: CHECK_LICENSE_EXPIRY_REQUEST,
});

export const checkLicenseExpirySuccess = () => ({
  type: CHECK_LICENSE_EXPIRY_SUCCESS,
});

export const checkLicenseExpiryFailure = ({ error }) => ({
  type: CHECK_LICENSE_EXPIRY_FAILURE,
  error,
});

export const saveLicenseTimestampToLS = ({ timeStamp }) => ({
  type: SAVE_LICENSE_TIME_STAMP_TO_LS,
  timeStamp,
});
