type FormattedObject = {
  [key: string]: string | FormattedObject;
};

type DescribeData = {
  [key: string]: any | DescribeData;
};

export function formatObjectValuessAsString(data: DescribeData): FormattedObject {
  const formatValue = (value: any | DescribeData): string | FormattedObject => {
    if (typeof value === 'object' && value !== null) {
      return Object.fromEntries(
        Object.entries(value).map(([key, innerValue]) => [key, formatValue(innerValue)]),
      );
    }
    return String(value);
  };

  return Object.fromEntries(Object.entries(data).map(([key, value]) => [key, formatValue(value)]));
}
