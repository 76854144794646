import Launch from '@mui/icons-material/Launch';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import React, { FC, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { VISUAL_TYPES } from '../../../../../../constants';

import {
  NAVIGATION_TABS_INFO,
  NavigationItemStatus,
  NavigationTabs,
} from '../../../../../../constants/session';
import { selectCharts } from '../../../../../../store/selectors/chartspace.selector';
import { selectDatasets } from '../../../../../../store/selectors/dataspace.selector';
import {
  selectCurrentSessionNavigationTab,
  selectSession,
} from '../../../../../../store/selectors/session.selector';
import { setCurrentChart } from '../../../../../../store/slices/chartspace.slice';
import {
  addToChartSpaceRequest,
  addToDataSpaceRequest,
} from '../../../../../../store/slices/dataAssistant.slice';
import { setCurrentDatasetRequest } from '../../../../../../store/slices/dataspace.slice';
import { setCurrentNavigationTab } from '../../../../../../store/slices/session.slice';
import { ScreenSizeContext } from '../../../../../DataAssistant/DataAssistant';
import './AddToSpaceLink.scss';

interface AddToSpaceLinkProps {
  /** What type of visual output this link acts on. 'viz' or 'table'. */
  visualType: string;
  /** ID of the chart or dataset this link acts on. Either a dc_dataset or dc_chart's uuid. */
  id: string;
}

const AddToSpaceLink: FC<AddToSpaceLinkProps> = ({ visualType, id }) => {
  const dispatch = useDispatch();
  const sessionId = useSelector(selectSession);
  const currentTab = useSelector(selectCurrentSessionNavigationTab);
  const isSmallScreen = useContext(ScreenSizeContext);
  const datasets = useSelector(selectDatasets);
  const charts = useSelector(selectCharts);

  // don't show the link if the visual is a text
  if (visualType === VISUAL_TYPES.TEXT) return null;

  /** The space, either dataspace or chartspace, that this link acts on. */
  const space =
    visualType === VISUAL_TYPES.TABLE ? NavigationTabs.DATA_SPACE : NavigationTabs.CHART_SPACE;

  /**
   * Whether this link's object is viewable in its space.
   *
   * For datasets, at least one version of the dataset must be active.
   *
   * For charts, the chart must be active.
   */
  let viewable = false;
  if (space === NavigationTabs.DATA_SPACE) {
    // the dataset is viewable if at least one version of it is active
    viewable = Object.values(datasets)
      .filter((dataset) => dataset.name === datasets[id]?.name)
      .some((dataset) => dataset.status === NavigationItemStatus.ACTIVE);
  } else if (space === NavigationTabs.CHART_SPACE) {
    // the chart is viewable if it is active
    viewable = charts[id]?.status === NavigationItemStatus.ACTIVE;
  }

  /**
   * Handles the click event on the link.
   *
   * If the object is already active in its space, this function sets the object
   * as the current object in its space.
   *
   * If the object is not active in its space, this function adds the object to its space.
   *
   * The function also navigates to the space if the current tab is not the space.
   */
  const handleClick = () => {
    if (viewable) {
      // set the current object in its space
      if (space === NavigationTabs.DATA_SPACE) {
        dispatch(setCurrentDatasetRequest({ dcDatasetId: id }));
      } else if (space === NavigationTabs.CHART_SPACE) {
        dispatch(setCurrentChart({ dcChartId: id }));
      }
    } else {
      // object is not active, add the object to its space
      /* eslint-disable-next-line no-lonely-if */ // disabling to help readability
      if (space === NavigationTabs.DATA_SPACE) {
        dispatch(addToDataSpaceRequest({ dcDatasetId: id }));
      } else if (space === NavigationTabs.CHART_SPACE) {
        dispatch(addToChartSpaceRequest({ dcChartId: id }));
      }
    }
    if (currentTab !== space) dispatch(setCurrentNavigationTab({ sessionId, tab: space }));
  };

  /** User-facing name for the space this link adds to. */
  const userSpaceName: string = NAVIGATION_TABS_INFO[space].externalName;

  /**
   * The link will read "View in <userSpaceName>" if this link's object is
   * viewable, otherwise it will read "Add to <userSpaceName>"
   */
  const linkText = `${viewable ? 'View in' : 'Add to'} ${userSpaceName}`;

  return (
    <Link
      className={classNames('AddToSpaceLink', { SmallScreenSize: isSmallScreen })}
      onClick={handleClick}
      role="link"
    >
      <Launch fontSize="small" />
      <Typography variant="body2">{linkText}</Typography>
    </Link>
  );
};

export default AddToSpaceLink;
