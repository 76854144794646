import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import type {
  CancelCurrentTaskFailurePayload,
  CreateTaskRequestPayload,
  CreateTaskSuccessPayload,
  GotActiveTasksPayload,
  Task,
} from '../../types/task.types';

export interface TaskState {
  currentTaskId: string;
  /**
   * The active tasks for the current session.
   *
   * `undefined`, if there is no current session.
   */
  activeTasks?: Task[];
}

export const initialState: TaskState = {
  currentTaskId: '',
};

const taskSlice = createSlice({
  name: 'task',
  initialState,
  reducers: {
    CreateTaskRequest: {
      reducer: () => {},
      prepare: (payload: CreateTaskRequestPayload) => ({ payload }),
    },
    CreateTaskFailure: () => {},
    CreateTaskSuccess: (state: TaskState, { payload }: PayloadAction<CreateTaskSuccessPayload>) => {
      state.currentTaskId = payload.newTaskId;
    },
    GotActiveTasks: (state: TaskState, { payload }: PayloadAction<GotActiveTasksPayload>) => {
      state.activeTasks = payload.activeTasks;
    },
    Reset() {
      return initialState;
    },
    CancelCurrentTask: () => {},
    CancelCurrentTaskFailure: {
      reducer: () => {},
      prepare: (payload: CancelCurrentTaskFailurePayload) => ({ payload }),
    },
    CancelCurrentTaskSuccess: () => {},
  },
});

export const {
  CreateTaskRequest,
  CreateTaskFailure,
  CreateTaskSuccess,
  GotActiveTasks,
  Reset,
  CancelCurrentTask,
  CancelCurrentTaskFailure,
  CancelCurrentTaskSuccess,
} = taskSlice.actions;

export default taskSlice.reducer;
