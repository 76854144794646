import parse from 'html-react-parser';
import React from 'react';

import { NON_UTTERANCE_PATTERN } from '../../constants/workflow';
import './index.scss';

/**
 * List of utterances with optional search highlighting,
 * click handler, and placeholder text.
 */
type Props = {
  utterances: [{}], // Utterances to display in form: {id: int|'Next', data: string, next: boolean} (Reqd)
  onUttClick: () => mixed, // Click handler for utterance, passes entry from utterances (Optional)
  placeholder: string, // If utterances is empty, display this (Reqd)
};
export default class UtterancesList extends React.Component<Props> {
  render() {
    return this.props.utterances.length === 0 ? (
      <div className="UtterancesList-Empty">
        <div>{this.props.placeholder}</div>
      </div>
    ) : (
      <ul className="UtterancesList">
        {this.props.utterances.map((utt) => {
          const nonUtt = NON_UTTERANCE_PATTERN.test(utt.data);
          const clickable = !utt.next && this.props.onUttClick;
          return (
            <li
              className={`UtterancesList-Item ${utt.next ? 'NextUtterance' : ''}
              ${nonUtt ? 'NonUtterance' : ''} ${clickable ? 'Clickable' : ''}`}
              onClick={clickable ? () => this.props.onUttClick(utt) : undefined}
              key={utt.id}
            >
              <span className="UtterancesList-Item-Id">{utt.id}</span>
              <span className="UtterancesList-Content">{nonUtt ? utt.data : parse(utt.data)}</span>
            </li>
          );
        })}
      </ul>
    );
  }
}
