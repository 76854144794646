import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { push } from 'redux-first-history';
import {
  call,
  getContext,
  put,
  select,
  takeEvery,
  takeLatest,
  takeLeading,
} from 'typed-redux-saga';
import { disconnectOAuth, GetOAuthsResponse } from '../../api/oauth.api';
import { OAuthContextType, OAuthPassthroughState } from '../../components/OAuthButtons/oauth.types';
import { ReduxSagaContext } from '../../configureStore';
import { API_SERVICES } from '../../constants/api';
import {
  getErrorToastConfig,
  getSuccessToastConfig,
  TOAST_ERROR,
  TOAST_SHORT,
} from '../../constants/toast';
import { IntegrationProvider } from '../../types/integrations.types';
import { addToast } from '../actions/toast.actions';
import {
  disconnectIntegrationFailure,
  disconnectIntegrationRequest,
  disconnectIntegrationSuccess,
  getIntegrationsFailure,
  getIntegrationsRequest,
  getIntegrationsSuccess,
  putOAuthRequest,
} from '../slices/integrations.slice';
import { selectAccessToken } from './selectors';
import { retry401 } from './utils/retry';

export function* disconnectIntegrationRequestWorker({
  payload,
}: PayloadAction<IntegrationProvider>) {
  try {
    const accessToken = yield* select(selectAccessToken);
    yield* call(disconnectOAuth, accessToken, payload);

    yield* put(disconnectIntegrationSuccess(payload));
  } catch (error) {
    yield* put(disconnectIntegrationFailure(payload));
  }
}

export function* disconnectIntegrationFailedWorker({
  payload,
}: PayloadAction<IntegrationProvider>) {
  yield put(
    addToast({
      toastType: TOAST_ERROR,
      length: TOAST_SHORT,
      message: `Failed to disconnect ${payload.toLowerCase()} integration`,
    }),
  );
}

export function* putOAuthRequestWorker({
  payload,
}: PayloadAction<{ passthroughState: OAuthPassthroughState; code: string }>) {
  const accessToken: string = yield* select(selectAccessToken);
  const { passthroughState, code } = payload;
  let toastConfig = getSuccessToastConfig(
    `Successfully connected to ${passthroughState.databaseProvider}`,
  );
  try {
    const oAuthService = (yield* getContext(
      API_SERVICES.OAUTH,
    )) as ReduxSagaContext[API_SERVICES.OAUTH];
    // Submit code to server
    const res = yield* call(
      oAuthService.putOAuth,
      accessToken,
      passthroughState.databaseProvider,
      code,
    );
    if (res.status !== 200) {
      throw new Error('Failed to connect to database provider');
    }
  } catch {
    // Update the toast message an type to be an error
    toastConfig = getErrorToastConfig('Failed to connect to database provider');
  }
  // If the passthroughState has a closeOnRedirect flag, close the window
  if (passthroughState.closeOnRedirect) {
    window.close();
  }

  // Redirect to web app w/ success or failure message
  if (
    passthroughState.context === OAuthContextType.SESSION &&
    passthroughState.contextDetails?.sessionId
  ) {
    yield put(
      push({ pathname: `/web/session/${passthroughState.contextDetails.sessionId}`, search: '' }),
    );
  } else {
    yield put(push({ pathname: '/web', search: '' }));
  }
  yield put(addToast(toastConfig));
}

export function* getIntegrationsRequestWorker() {
  try {
    const oAuthService = (yield* getContext(
      API_SERVICES.OAUTH,
    )) as ReduxSagaContext[API_SERVICES.OAUTH];
    const oAuths = (yield* retry401(oAuthService.getOAuths)) as AxiosResponse<GetOAuthsResponse>;

    // Structure the oAuths object to be { [IntegrationProvider]: { [key: string]: any } }
    const formattedOAuths = oAuths.data.reduce(
      (acc: Record<IntegrationProvider, any>, { provider, payload }) => {
        acc[provider] = payload;
        return acc;
      },
      {
        [IntegrationProvider.BIGQUERY]: null,
        [IntegrationProvider.SNOWFLAKE]: null,
      } as Record<IntegrationProvider, any>,
    );

    yield put(getIntegrationsSuccess(formattedOAuths));
  } catch (error) {
    yield put(getIntegrationsFailure());
  }
}

export default function* integrationsSaga() {
  yield* takeEvery(disconnectIntegrationRequest.type, disconnectIntegrationRequestWorker);
  yield* takeLatest(disconnectIntegrationFailure.type, disconnectIntegrationFailedWorker);

  // Send the authorization code for a given OAuth provider to the server
  yield* takeLeading(putOAuthRequest.type, putOAuthRequestWorker);

  // Get the OAuths for the user
  yield* takeLatest(getIntegrationsRequest.type, getIntegrationsRequestWorker);
}
