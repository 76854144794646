import { call, put, select, takeLatest } from 'redux-saga/effects';
import { listExamples } from '../../api/workspacev2.api';
import { GLOBAL_USER_EMAIL } from '../../constants/home_screen';
import { workspaceObjectTypes } from '../../constants/workspace';
import {
  GET_EXAMPLES_REQUEST,
  getExamplesFailure,
  getExamplesSuccess,
} from '../actions/examples.action';
import { selectAccessToken, selectExperimentalFlag } from './selectors';

function* getExamplesRequestWorker() {
  try {
    const devMode = yield select(selectExperimentalFlag);
    const accessToken = yield select(selectAccessToken);
    const response = yield call(listExamples, accessToken);

    const exampleObjectType = devMode
      ? workspaceObjectTypes.DATASET
      : workspaceObjectTypes.DATAFILE;

    // Examples must include a type, name, description, and email
    // Email is used to fire Load utterance for shared
    // If they don't, then don't put them in redux
    const examples = response.data
      .filter(
        (example) =>
          example.type &&
          example.name &&
          example.description &&
          example.email &&
          example.objectType === exampleObjectType,
      )
      .map((example) => ({ ...example, isShared: example.email === GLOBAL_USER_EMAIL }));

    yield put(getExamplesSuccess({ examples }));
  } catch (error) {
    yield put(getExamplesFailure({ error }));
  }
}

export default function* () {
  yield takeLatest(GET_EXAMPLES_REQUEST, getExamplesRequestWorker);
}
