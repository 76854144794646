/**
 * Possible types of a task.
 */
export enum TaskType {
  /**
   * A "Blocking" task must have a "Done" status before any other
   * tasks can be "Started" in its session.
   */
  BLOCKING = 'Blocking',
}

/**
 * Possible statues of a task.
 *
 * Status updates will always follow the order:
 * 1. New
 * 2. Started
 * 3. Done
 */
export enum TaskStatus {
  NEW = 'New',
  STARTED = 'Started',
  DONE = 'Done',
}

/** Task statuses that we can cancel */
export const CancellableTasks = [TaskStatus.NEW, TaskStatus.STARTED];

export type Task = {
  /** ID of this task. */
  id: string;
  /** ID of session this task belongs to. */
  sessionId: string;
  /** (Optional) ID of task that parents this task. */
  parentTaskId: null | string;
  /** Type of this task. */
  taskType: TaskType;
  /** Status of this task. */
  taskStatus: TaskStatus;
  /** Description of this task. */
  description: string;
  /** Time that this task was created. */
  creationTime: string;
  /** Time that this task was last updated. */
  updateTime: string;
  /** (Optional) Time that this task will expire at. */
  expirationTime: string | null;
};

export type CreateTaskRequestPayload = {
  taskType?: TaskType;
  timeout?: number;
  parentTaskId?: string;
  description?: string;
};

export type CreateTaskSuccessPayload = { isQuestion: boolean; newTaskId: string };

export type GotActiveTasksPayload = {
  activeTasks?: Task[];
};

export type CancelCurrentTaskFailurePayload = {
  error?: any;
};
