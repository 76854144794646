import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CancelIcon from '@mui/icons-material/Cancel';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import TextWithTooltip from '../../common/TextWithTooltip';
import '../ChartBuilder.scss';

/**
 * Renders the a chip with a clickable dropdown menu
 * @returns {JSX.Element} A MaterialUI Chip with an onClick dropdown menu
 */
const ChipDropdown = ({ items, selectedItem, onChanged, onOpen, onClose }) => {
  // The dropdown anchor
  const [anchorEl, setAnchorEl] = useState(null);

  const hasItems = items.length > 0;
  const hasFormItem = items.some((item) => React.isValidElement(item));

  /**
   * Open & close the dropdown menu
   * @param {React.MouseEvent} event The click event
   * @param {EventTarget} target The click event's target
   */
  const handleAnchor = (event, target) => {
    event.stopPropagation();
    setAnchorEl(target);
  };

  /**
   * Handles a click on the chip.
   * @param {React.MouseEvent} event The click event
   */
  const handleChipClick = hasItems
    ? (event) => {
        handleAnchor(event, event.currentTarget);
        onOpen();
      }
    : null;

  /**
   * Handle closing the dropdown menu. Disable for form items
   * @param {React.MouseEvent} event The close event
   */
  const handleClose = (event) => {
    // Don't close the menu if there is a form item (ex. <Bin />)
    if (!hasFormItem) handleAnchor(event, null);
  };

  /**
   * Handle a keystroke
   * @param {React.KeyboardEvent} event The keyboard event
   */
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
    } else if (event.key === 'Escape') {
      event.preventDefault();
      handleAnchor(event, null);
    }
  };

  /**
   * Call onChanged with the new selection and close the dropdown
   * @param {React.MouseEvent} event The click event
   * @param {String|Object} selection If the user clicked a MenuItem, the text of the item
   * If the user submitted a form, this will be the form submission object
   */
  const handleSelection = (event, selection) => {
    onChanged(selection);
    handleAnchor(event, null);
  };

  // Render a close button when we have a form item
  const renderCloseButton = () => {
    return (
      <IconButton
        className="close-button"
        onClick={(e) => {
          handleAnchor(e, null);
          onClose();
        }}
      >
        <CancelIcon />
      </IconButton>
    );
  };

  /**
   * Render the items within the chip dropdown menu
   * @param {String | React.FunctionComponent} item The current item from items
   * @returns If the item input is a string, a MenuItem component
   * If the item input is a component, the component itself with an added applyAndClose() prop
   */
  const renderItem = (item) => {
    // Add a applyAndClose() prop to each child component
    // This is used to close the dropdown menu, with handling for processing a form submission
    if (React.isValidElement(item)) {
      return React.cloneElement(item, { applyAndClose: handleSelection });
    }

    return (
      <MenuItem key={item} onClick={(e) => handleSelection(e, item)}>
        {item}
      </MenuItem>
    );
  };

  return (
    <div className="ChipDropdown-wrapper">
      <Chip
        className="ChipDropdown"
        color="primary"
        data-cy={`chip-dropdown-${selectedItem}`}
        data-testid={`chip-dropdown-${selectedItem}`}
        deleteIcon={hasItems ? <ArrowDropDownIcon /> : null}
        label={<TextWithTooltip text={selectedItem} className="ChipDropdown-label" />}
        onClick={handleChipClick} // Handle a click on the chip's body
        onDelete={handleChipClick} // Handle a click on the chip's dropdown arrow icon
        size="small"
      />
      {hasItems && (
        <Menu
          className="ChipDropdown"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          // Stop the click event from bubbling up to the parent input
          onClick={(e) => e.stopPropagation()}
          onClose={(e) => {
            e.stopPropagation(); // ToDo: Is this necessary?
            handleClose(e, null);
          }}
          onKeyDown={handleKeyPress}
          sx={{ overflow: 'visible' }}
        >
          {/* Render a close button for a form menu */}
          {hasFormItem && renderCloseButton()}
          {/* Render the dropdown items */}
          {items.map((item) => renderItem(item))}
        </Menu>
      )}
    </div>
  );
};

ChipDropdown.propTypes = {
  // The list of items to display in the dropdown
  items: PropTypes.array,
  // The currently selected item
  selectedItem: PropTypes.string.isRequired,
  // The function to call when the user selects a new item
  onChanged: PropTypes.func.isRequired,
  // The function to call when the user opens the dropdown
  onOpen: PropTypes.func,
  // The function to call when the user closes the dropdown
  onClose: PropTypes.func,
};

ChipDropdown.defaultProps = {
  items: [],
  onOpen: () => {},
  onClose: () => {},
};

export default ChipDropdown;
