import React, { FC, ReactNode } from 'react';
import { useAuth } from './hooks/auth.hook';
import { EUserRole } from '../../types/user.types';
import { FeatureEnum } from '../../generated/graphql';

interface PermissionWrapperProps {
  children: ReactNode;
  roles?: EUserRole[]; // Array of roles that should be given access to the route
  privileges?: FeatureEnum[]; // Array of privileges required to render the component
}

/**
 *  PermissionWrapper component for rendering components based on user roles and privileges
 */
const PermissionWrapper: FC<PermissionWrapperProps> = ({ children, roles, privileges }) => {
  const { attributes, isLoading, hasAccessToFeature } = useAuth();

  if (isLoading) {
    return null;
  }

  // Check if the user has the required roles
  const hasRequiredRoles = !roles?.length || roles?.includes(attributes.role.name);

  // Check if the user has the required privileges
  const hasRequiredPrivileges =
    !privileges?.length || privileges.every((privilege) => hasAccessToFeature(privilege));

  if (hasRequiredRoles && hasRequiredPrivileges) {
    // User has both the required roles and privileges, render the nested component
    return <>{children}</>;
  }

  return null;
};

export { PermissionWrapper, useAuth };

export { AuthProvider, AuthStateContext } from './context/auth.context';
