import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import React from 'react';

import './index.scss';

type Props = {
  // Applied to OutlinedInput component directly
  autoFocus: Boolean,
  defaultValue: any,
  disabled: Boolean,
  id: Number,
  label: String,
  helperText: string,
  helperTextEnabled: Boolean,
  multiline: Boolean,
  name: String,
  onChange: () => mixed,
  onBlur: () => mixed,
  onKeyDown: () => mixed,
  onFocus: () => mixed,
  placeholder: String,
  rows: Number,
  rowsMax: Number,
  type: String,
  value: String,
  catchAll: {}, // Catch all for all input props. To be used with spread operator.
  // Applied to FormControl
  error: Boolean,
  required: Boolean,
  inputRef: any,
  'data-testid': String,
};

/**
 * Wrapper component around material-ui OutlinedInput component.
 * Provides styling to the OutlinedInput component to keep this consistent throughout the app.
 * For a complete list of OutlinedInput props go to https://material-ui.com/api/outlined-input/
 */
class DataChatOutlinedInput extends React.Component<Props> {
  render() {
    return (
      <FormControl
        className="DataChatOutlinedInput"
        error={this.props.error}
        margin="dense"
        required={this.props.required}
        variant="outlined"
        fullWidth
      >
        {this.props.label && (
          <InputLabel htmlFor={this.props.id} disabled={this.props.disabled}>
            {this.props.label}
          </InputLabel>
        )}

        <OutlinedInput
          autoComplete="off"
          data-testid={this.props['data-testid']}
          inputRef={this.props.inputRef}
          autoFocus={this.props.autoFocus}
          defaultValue={this.props.defaultValue}
          disabled={this.props.disabled}
          fullWidth
          id={this.props.id}
          // Label is only used to determine the width of the notch in the input's outline.
          // When the input is required, an * is added to the end of the label,
          // but this is not accounted for by the 'label' property, so it must be explicitly added
          label={this.props.label && `${this.props.label} ${this.props.required ? '*' : ''}`}
          multiline={this.props.multiline}
          name={this.props.name}
          onChange={this.props.onChange}
          onBlur={this.props.onBlur}
          onKeyDown={this.props.onKeyDown}
          onFocus={this.props.onFocus}
          placeholder={this.props.placeholder}
          rows={this.props.rows}
          type={this.props.type}
          value={this.props.value}
          size="small"
          {...this.props.catchAll}
        />

        {this.props.helperTextEnabled && <FormHelperText>{this.props.helperText}</FormHelperText>}
      </FormControl>
    );
  }
}

export default DataChatOutlinedInput;
