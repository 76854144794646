export enum PipelinerErrorCode {
  // General errors
  GENERAL_UNKNOWN = 'general_0001',

  // Object errors
  OBJECT_NOT_FOUND = 'object_0001',
  OBJECT_HAS_CHILDREN = 'object_0002',

  // OAuth errors
  OAUTH_NOT_FOUND = 'oauth_0001',
  OAUTH_INVALID = 'oauth_0002',

  // Connection Auth Error
  DB_CONNECTION_AUTH_ERROR = 'db_connection_403', // Connection not shared
}
