export const BULK_EXPORT_REQUEST = 'export_chart/BULK_EXPORT_REQUEST';
export const BULK_EXPORT_LOAD_SPEC = 'export_chart/BULK_EXPORT_LOAD_SPEC';
export const BULK_EXPORT_REPORT_PROGRESS = 'export_chart/BULK_REPORT_PROGRESS';
export const BULK_EXPORT_CANCEL_CURRENT_EXPORTING =
  'export_chart/BULK_EXPORT_CANCEL_CURRENT_EXPORTING';
export const BULK_EXPORT_CONSUME_CHARTSPEC_QUEUE =
  'export_chart/BULK_EXPORT_CONSUME_CHARTSPEC_QUEUE';

/** request bulk charts exporting action */
export const requestBulkExport = ({
  exportObjectID,
  isExportedFromPopOutChart,
  isExportedFromChartBuilder,
}) => ({
  type: BULK_EXPORT_REQUEST,
  exportObjectID,
  isExportedFromPopOutChart,
  isExportedFromChartBuilder,
});

/** load necessary spec for parparing exporting */
export const queueBulkExportSpec = ({ chartSpec }) => ({
  type: BULK_EXPORT_LOAD_SPEC,
  chartSpec,
});

export const consumeChartSpecQueue = () => ({
  type: BULK_EXPORT_CONSUME_CHARTSPEC_QUEUE,
});

/** exit bulk charts exporting action */
export const updateBulkExportProgress = ({ progress }) => ({
  type: BULK_EXPORT_REPORT_PROGRESS,
  progress,
});

/** cancel the exporting that is currently being processed */
export const cancelCurrentExporting = () => ({
  type: BULK_EXPORT_CANCEL_CURRENT_EXPORTING,
});
