import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface DatasetObject {
  name: string;
  uuid: string;
}
export interface DatasetCreatorState {
  isOpen: boolean;
  uploadedDatasets: DatasetObject[];
}

export const initialState: DatasetCreatorState = {
  isOpen: false,
  uploadedDatasets: [],
};

/**
 * A slice combines action types, creators, and reducers into a single object.
 * It also allows us to safely mutate state in the reducer.
 * https://redux-toolkit.js.org/tutorials/typescript#define-slice-state-and-action-types
 *
 * This slice is used to control the state of the dataset creator dialog.
 */
export const datasetCreatorSlice = createSlice({
  name: 'datasetCreator',
  initialState,
  reducers: {
    openDatasetCreator: (state) => {
      state.isOpen = true;
    },
    closeDatasetCreator: () => {
      return initialState;
    },
    addUploadedDatasets: (state, action: PayloadAction<DatasetObject[]>) => {
      if (!state.isOpen) return;
      action.payload
        .filter((dataset) => !state.uploadedDatasets.includes(dataset)) // dedupe, we don't use a set since that requires another immer plugin, and this is a small array
        .forEach((dataset) => state.uploadedDatasets.push(dataset)); // add to uploadedDatasets
    },
    loadUploadedDatasets: () => {},
  },
});

// Note that these are action creators, but also have a .type property for the action type.
export const {
  openDatasetCreator,
  closeDatasetCreator,
  addUploadedDatasets,
  loadUploadedDatasets,
} = datasetCreatorSlice.actions;

export default datasetCreatorSlice.reducer;
