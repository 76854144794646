export const SET_LAST_UPDATE = 'graphMode/SET_LAST_UPDATE';
export const RESET_GRAPH_MODE = 'graphMode/RESET_GRAPH_MODE';

export const GET_GRAPH_UPDATE_REQUEST = 'graphMode/GET_GRAPH_UPDATE_REQUEST';
export const GET_GRAPH_UPDATE_SUCCESS = 'graphMode/GET_GRAPH_UPDATE_SUCCESS';
export const GET_GRAPH_UPDATE_FAILURE = 'graphMode/GET_GRAPH_UPDATE_FAILURE';

export const ON_NODES_CHANGE = 'graphMode/ON_NODES_CHANGE';
export const ON_EDGES_CHANGE = 'graphMode/ON_EDGES_CHANGE';
export const SET_LAYOUT_ALGORITHM = 'graphMode/SET_LAYOUT_ALGORITHM';

/**
 * Set lastUpdate variable
 * @param {String} lastUpdate timestamp of last fetched dependency graph updates
 */
export const setLastUpdate = (lastUpdate) => ({ type: SET_LAST_UPDATE, lastUpdate });

/**
 * Reset the graph mode reducer
 */
export const resetGraphMode = () => ({ type: RESET_GRAPH_MODE });

/**
 * Request updates to the dependency graph
 */
export const getGraphUpdateRequest = () => ({ type: GET_GRAPH_UPDATE_REQUEST });

/**
 * Update graphMode reducer after successfully fetching graph updates
 * @param {*} nodeUpdates
 * @param {*} edgeUpdates
 * @param {*} skillUpdates
 * @param {*} productUpdates
 * @returns
 */
export const getGraphUpdateSuccess = (nodeUpdates, edgeUpdates, skillUpdates, productUpdates) => ({
  type: GET_GRAPH_UPDATE_SUCCESS,
  nodeUpdates,
  edgeUpdates,
  skillUpdates,
  productUpdates,
});

/**
 * Error occured as a result of fetching graph updates
 * @param {Error} error error object
 */
export const getGraphUpdateFailure = (error) => ({ type: GET_GRAPH_UPDATE_FAILURE, error });

/**
 * Update nodes using NodeChanges (see https://reactflow.dev/docs/api/types/#nodechange)
 * @param {NodeChange[]} edgeChanges array of NodeChange objects
 */
export const onNodesChange = (nodeChanges) => ({
  type: ON_NODES_CHANGE,
  nodeChanges,
});

/**
 * Update edges using EdgeChanges (see https://reactflow.dev/docs/api/types/#edgechange)
 * @param {EdgeChange[]} edgeChanges array of EdgeChange objects
 */
export const onEdgesChange = (edgeChanges) => ({
  type: ON_EDGES_CHANGE,
  edgeChanges,
});

/**
 * Update the algorithm used to layout nodes in the graph
 * @param {String} layoutAlgorithm one of enum LAYOUT_ALGORITHM
 */
export const setLayoutAlgorithm = (layoutAlgorithm) => ({
  type: SET_LAYOUT_ALGORITHM,
  layoutAlgorithm,
});
