import React, { createContext, FC, memo, ReactNode, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { TAuth } from '../reducers/auth.reducer';
import { FeatureEnum, useMeQuery } from '../../../generated/graphql';
import { selectAccessToken } from '../../../store/sagas/selectors';

/**
 * Convert snake_case to camelCase
 */
function snakeToCamel(str: string) {
  return str.replace(/_\w/g, (m) => m[1].toUpperCase());
}

export const AuthStateContext = createContext<TAuth>({
  isLoading: false,
  attributes: null,
  isAuthenticated: false,
  login: () => {
    return null;
  },
  logout: () => {
    return new Promise(() => null);
  },
  restoreToken: () => {
    return null;
  },
  hasAccessToFeature: () => {
    return false;
  },
});

interface IAuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: FC<IAuthProviderProps> = memo(({ children }: IAuthProviderProps) => {
  const [state, setState] = useState({
    isLoading: true,
    attributes: null,
    isAuthenticated: false,
  } as TAuth);

  const accessToken: string = useSelector(selectAccessToken);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { data, loading, refetch } = useMeQuery();

  useEffect(() => {
    refetch();
  }, [accessToken, refetch]);

  useEffect(() => {
    setState({
      isLoading: loading,
      attributes: data?.me,
      isAuthenticated: !!data?.me,
    } as TAuth);
  }, [data, loading]);

  /**
   * Check whether the access to the given feature is granted in the features object
   */
  const hasAccessToFeature = useCallback(
    (feature: FeatureEnum) => {
      /**
       * Convert feature enum values to snakeCase as
       * codegen generates the Enum keys and values as follows: "PascalCase: snake_case"
       */
      return !!state.attributes?.features && state.attributes?.features?.[snakeToCamel(feature)];
    },
    [state.attributes?.features],
  );

  return (
    <AuthStateContext.Provider value={{ ...state, hasAccessToFeature }}>
      {children}
    </AuthStateContext.Provider>
  );
});

AuthProvider.displayName = 'AuthProvider';
