// TODO: create action for fetching insights board list

export const CREATE_NEW_INSIGHTS_BOARD_REQUEST = 'insightsBoard/CREATE_NEW_INSIGHTS_BOARD_REQUEST';
export const CREATE_NEW_INSIGHTS_BOARD_SUCCESS = 'insightsBoard/CREATE_NEW_INSIGHTS_BOARD_SUCCESS';
export const CREATE_NEW_INSIGHTS_BOARD_FAILURE = 'insightsBoard/CREATE_NEW_INSIGHTS_BOARD_FAILURE';

export const PUBLISH_TO_INSIGHTS_BOARD_REQUEST = 'insightsBoard/PUBLISH_TO_INSIGHTS_BOARD_REQUEST';
export const PUBLISH_TO_INSIGHTS_BOARD_SUCCESS = 'insightsBoard/PUBLISH_TO_INSIGHTS_BOARD_SUCCESS';
export const PUBLISH_TO_INSIGHTS_BOARD_FAILURE = 'insightsBoard/PUBLISH_TO_INSIGHTS_BOARD_FAILURE';

export const UPDATE_INSIGHTS_BOARD_REQUEST = 'insightsBoard/UPDATE_INSIGHTS_BOARD_REQUEST';
export const UPDATE_INSIGHTS_BOARD_SUCCESS = 'insightsBoard/UPDATE_INSIGHTS_BOARD_SUCCESS';
export const UPDATE_INSIGHTS_BOARD_FAILURE = 'insightsBoard/UPDATE_INSIGHTS_BOARD_FAILURE';
export const CLEAR_INSIGHTS_BOARD_UPDATE_ERROR = 'insightsBoard/CLEAR_INSIGHTS_BOARD_UPDATE_ERROR';

export const CREATE_ANNOTATION_REQUEST = 'insightsBoard/CREATE_ANNOTATION_REQUEST';
export const CREATE_ANNOTATION_SUCCESS = 'insightsBoard/CREATE_ANNOTATION_SUCCESS';
export const CREATE_ANNOTATION_FAILURE = 'insightsBoard/CREATE_ANNOTATION_FAILURE';

export const EDIT_ANNOTATION_REQUEST = 'insightsBoard/EDIT_ANNOTATION_REQUEST';
export const EDIT_ANNOTATION_SUCCESS = 'insightsBoard/EDIT_ANNOTATION_SUCCESS';
export const EDIT_ANNOTATION_FAILURE = 'insightsBoard/EDIT_ANNOTATION_FAILURE';

export const RELOAD_INSIGHTS_BOARD_REQUEST = 'insightsBoard/RELOAD_INSIGHTS_BOARD_REQUEST';
export const RELOAD_INSIGHTS_BOARD_SUCCESS = 'insightsBoard/RELOAD_INSIGHTS_BOARD_SUCCESS';
export const RELOAD_INSIGHTS_BOARD_FAILURE = 'insightsBoard/RELOAD_INSIGHTS_BOARD_FAILURE';

export const GET_INSIGHTS_BOARD_LAYOUT_REQUEST = 'insightsBoard/GET_INSIGHTS_BOARD_LAYOUT_REQUEST';
export const GET_INSIGHTS_BOARD_LAYOUT_SUCCESS = 'insightsBoard/GET_INSIGHTS_BOARD_LAYOUT_SUCCESS';
export const GET_INSIGHTS_BOARD_LAYOUT_FAILURE = 'insightsBoard/GET_INSIGHTS_BOARD_LAYOUT_FAILURE';

export const GET_SNAPSHOTS_REQUEST = 'insightsBoard/GET_SNAPSHOTS_REQUEST';
export const GET_SNAPSHOTS_SUCCESS = 'insightsBoard/GET_SNAPSHOTS_SUCCESS';
export const GET_SNAPSHOTS_FAILURE = 'insightsBoard/GET_SNAPSHOTS_FAILURE';

export const OPEN_PUBLISH_MENU = 'insightsBoard/OPEN_PUBLISH_MENU';
export const CLOSE_PUBLISH_MENU = 'insightsBoard/CLOSE_PUBLISH_MENU';

export const UPDATE_INSIGHTS_BOARD_ITEM_FONT_REQUEST =
  'insightsBoard/UPDATE_INSIGHTS_BOARD_ITEM_FONT_REQUEST';
export const UPDATE_INSIGHTS_BOARD_ITEM_FONT_SUCCESS =
  'insightsBoard/UPDATE_INSIGHTS_BOARD_ITEM_FONT_SUCCESS';
export const UPDATE_INSIGHTS_BOARD_ITEM_FONT_FAILURE =
  'insightsBoard/UPDATE_INSIGHTS_BOARD_ITEM_FONT_FAILURE';

export const CLEAR_INSIGHTS_BOARD_CURRENT_LAYOUT =
  'insightsBoard/CLEAR_INSIGHTS_BOARD_CURRENT_LAYOUT';

export const TOGGLE_INSIGHTS_BOARD_VIEW_MODE = 'insightsBoard/TOGGLE_INSIGHTS_BOARD_VIEW_MODE';
export const TRIGGER_INSIGHTS_BOARD_UNSAVED_CHANGES =
  'insightsBoard/TRIGGER_INSIGHTS_BOARD_UNSAVED_CHANGES';

export const TOGGLE_INSIGHTS_BOARD_DATABASE_SAVE_IN_PROGRESS =
  'insightsBoard/TOGGLE_INSIGHTS_BOARD_DATABASE_SAVE_IN_PROGRESS';

export const DELETE_INSIGHTS_BOARD_PUBLICATIONS_REQUEST =
  'insightsBoard/DELETE_INSIGHTS_BOARD_PUBLICATIONS_REQUEST';
export const DELETE_INSIGHTS_BOARD_PUBLICATIONS_SUCCESS =
  'insightsBoard/DELETE_INSIGHTS_BOARD_PUBLICATIONS_SUCCESS';
export const DELETE_INSIGHTS_BOARD_PUBLICATIONS_FAILURE =
  'insightsBoard/DELETE_INSIGHTS_BOARD_PUBLICATIONS_FAILURE';

export const TOGGLE_INSIGHTS_BOARD_UNSAVED_CHANGES =
  'insightsBoard/TOGGLE_INSIGHTS_BOARD_UNSAVED_CHANGES';

export const SET_PUBLICATION_CUSTOMIZATION = 'chart/SET_PUBLICATION_CUSTOMIZATION';
export const SAVE_PUBLICATION_CUSTOMIZATIONS_REQUEST =
  'chart/SAVE_PUBLICATION_CUSTOMIZATIONS_REQUEST';
export const SAVE_PUBLICATION_CUSTOMIZATIONS_FAILURE =
  'chart/SAVE_PUBLICATION_CUSTOMIZATIONS_FAILURE';
export const SAVE_PUBLICATION_CUSTOMIZATIONS_SUCCESS =
  'chart/SAVE_PUBLICATION_CUSTOMIZATIONS_SUCCESS';

export const GET_INSIGHTS_BOARD_PUBLICATION_LIBRARY_FAILURE =
  'insightsBoard/GET_INSIGHTS_BOARD_PUBLICATION_LIBRARY_FAILURE';

/**
 * Attempt to create a new insights boatrd
 * @param {String} name Name of the new insights board
 */
export const createNewInsightsBoardRequest = ({ name, shouldGoToPage, shouldPublishItem }) => ({
  type: CREATE_NEW_INSIGHTS_BOARD_REQUEST,
  name,
  shouldGoToPage,
  shouldPublishItem,
});

export const publishToInsightsBoardRequest = ({ ibName }) => ({
  type: PUBLISH_TO_INSIGHTS_BOARD_REQUEST,
  ibName,
});

export const createNewInsightsBoardSuccess = () => ({
  type: CREATE_NEW_INSIGHTS_BOARD_SUCCESS,
});

export const publishToInsightsBoardSuccess = () => ({
  type: PUBLISH_TO_INSIGHTS_BOARD_SUCCESS,
});

export const createNewInsightsBoardFailure = ({ error }) => ({
  type: CREATE_NEW_INSIGHTS_BOARD_FAILURE,
  error,
});

export const publishToInsightsBoardFailure = ({ error }) => ({
  type: PUBLISH_TO_INSIGHTS_BOARD_FAILURE,
  error,
});

/**
 * Attempt to update an insights board
 * @param {String} id id of the insights board
 * @param {Object} payload contens to update
 */
export const updateInsightsBoardRequest = (id, payload, isFromHeader = false) => ({
  type: UPDATE_INSIGHTS_BOARD_REQUEST,
  id,
  payload,
  isFromHeader,
});

export const updateInsightsBoardSuccess = (payload, isFromHeader) => {
  return {
    type: UPDATE_INSIGHTS_BOARD_SUCCESS,
    payload,
    isFromHeader,
  };
};

export const updateInsightsBoardFailure = (error) => ({
  type: UPDATE_INSIGHTS_BOARD_FAILURE,
  error,
});

export const clearInsightsBoardUpdateError = () => ({
  type: CLEAR_INSIGHTS_BOARD_UPDATE_ERROR,
});

/**
 * Send a refresh publication request for current insights board
 *
 * @param {Array} ids List of publication IDs to refresh
 */
export const reloadInsightsBoardRequest = ({ ids }) => ({
  type: RELOAD_INSIGHTS_BOARD_SUCCESS,
  ids,
});

export const reloadInsightsBoardSuccess = () => ({
  type: RELOAD_INSIGHTS_BOARD_SUCCESS,
});

export const reloadInsightsBoardFailure = ({ error }) => ({
  type: RELOAD_INSIGHTS_BOARD_FAILURE,
  error,
});

export const openPublishMenu = ({
  messageType,
  objectId = undefined,
  data = undefined,
  update = undefined,
  datasetName,
  datasetVersion,
}) => ({
  type: OPEN_PUBLISH_MENU,
  messageType,
  objectId,
  data,
  update,
  datasetName,
  datasetVersion,
});

export const closePublishMenu = () => ({
  type: CLOSE_PUBLISH_MENU,
});

/**
 * Update a publication in an IB's font scaling
 */
export const updateInsightsBoardItemFontRequest = ({
  insightsBoardId,
  publicationId,
  newFontScaling,
}) => ({
  type: UPDATE_INSIGHTS_BOARD_ITEM_FONT_REQUEST,
  insightsBoardId,
  publicationId,
  newFontScaling,
});

export const updateInsightsBoardItemFontSuccess = () => ({
  type: UPDATE_INSIGHTS_BOARD_ITEM_FONT_SUCCESS,
});

export const updateInsightsBoardItemFontFailure = ({ error }) => ({
  type: UPDATE_INSIGHTS_BOARD_ITEM_FONT_FAILURE,
  error,
});
// Get the layout for an insights board
export const getInsightsBoardLayoutRequest = ({ insightsBoardId }) => ({
  type: GET_INSIGHTS_BOARD_LAYOUT_REQUEST,
  insightsBoardId,
});

export const getInsightsBoardLayoutSuccess = ({ insightsBoardLayout }) => ({
  type: GET_INSIGHTS_BOARD_LAYOUT_SUCCESS,
  insightsBoardLayout,
});

export const getInsightsBoardLayoutFailure = ({ error }) => ({
  type: GET_INSIGHTS_BOARD_LAYOUT_FAILURE,
  error,
});

// Get all snapshots for a user
export const getSnapshotsRequest = () => ({
  type: GET_SNAPSHOTS_REQUEST,
});

export const getSnapshotsSuccess = ({ snapshots }) => ({
  type: GET_SNAPSHOTS_SUCCESS,
  snapshots,
});

export const getSnapshotsFailure = ({ error }) => ({
  type: GET_SNAPSHOTS_FAILURE,
  error,
});

export const clearInsightsBoardCurrentLayout = () => ({
  type: CLEAR_INSIGHTS_BOARD_CURRENT_LAYOUT,
});

// Toggle the edit mode flag within the current layout
export const toggleInsightsBoardEditMode = () => ({
  type: TOGGLE_INSIGHTS_BOARD_VIEW_MODE,
});

// Toggle whether a database operation is in progress and thus if
// if user should be prompted to save
export const toggleInsightsBoardDatabaseSaveInProgress = ({ inProgress }) => ({
  type: TOGGLE_INSIGHTS_BOARD_DATABASE_SAVE_IN_PROGRESS,
  inProgress,
});

export const toggleInsightsBoardUnsavedChanges = ({ toggleValue }) => ({
  type: TOGGLE_INSIGHTS_BOARD_UNSAVED_CHANGES,
  toggleValue,
});

export const deleteInsightsBoardPublicationsRequest = ({
  insightsBoardId,
  publicationIdList,
  callback,
}) => ({
  type: DELETE_INSIGHTS_BOARD_PUBLICATIONS_REQUEST,
  insightsBoardId,
  publicationIdList,
  callback,
});

export const deleteInsightsBoardPublicationsSuccess = ({ updatedLayout }) => ({
  type: DELETE_INSIGHTS_BOARD_PUBLICATIONS_SUCCESS,
  updatedLayout,
});

export const deleteInsightsBoardPublicationsFailure = ({ error }) => ({
  type: DELETE_INSIGHTS_BOARD_PUBLICATIONS_FAILURE,
  error,
});

// Create a new annotation
export const createAnnotationRequest = ({ insightsBoardId, text, color, callback }) => ({
  type: CREATE_ANNOTATION_REQUEST,
  insightsBoardId,
  text,
  color,
  callback,
});

export const createAnnotationSuccess = () => ({
  type: CREATE_ANNOTATION_SUCCESS,
});

export const createAnnotationFailure = ({ error }) => ({
  type: CREATE_ANNOTATION_FAILURE,
  error,
});

// Edit an existing annotation
export const editAnnotationRequest = ({
  insightsBoardId,
  publicationId,
  text,
  color,
  callback,
}) => ({
  type: EDIT_ANNOTATION_REQUEST,
  insightsBoardId,
  publicationId,
  text,
  color,
  callback,
});

export const editAnnotationSuccess = () => ({
  type: EDIT_ANNOTATION_SUCCESS,
});

export const editAnnotationFailure = ({ error }) => ({
  type: EDIT_ANNOTATION_FAILURE,
  error,
});

export const setPublicationCustomization = ({ publicationId, tableName }) => ({
  type: SET_PUBLICATION_CUSTOMIZATION,
  publicationId,
  tableName,
});

export const savePublicationCustomizationsRequest = (insightsBoardId) => ({
  type: SAVE_PUBLICATION_CUSTOMIZATIONS_REQUEST,
  insightsBoardId,
});

export const savePublicationCustomizationsFailure = () => ({
  type: SAVE_PUBLICATION_CUSTOMIZATIONS_FAILURE,
});

export const savePublicationCustomizationsSuccess = () => ({
  type: SAVE_PUBLICATION_CUSTOMIZATIONS_SUCCESS,
});

export const getInsightsBoardPublicationLibraryFailure = ({ error }) => ({
  type: GET_INSIGHTS_BOARD_PUBLICATION_LIBRARY_FAILURE,
  error,
});
