import { OK } from 'http-status-codes';
import { call, delay, fork, put, race, select, take, takeLatest } from 'redux-saga/effects';

// import { databaseAlertFailure } from '../../constants/dialog.constants';
import { getUserPermissions } from '../../api/auth.api';
import { getConfig, getUserRecord, postUserRecord, putConfig } from '../../api/settings.api';
import { DEFAULT_USER_CONFIG } from '../../constants';
import { authenticate } from '../../utils/authenticate';
import { getContextId } from '../../utils/chat_id';
import { getPermissionsSuccess } from '../actions/auth.actions';
import { createAlertChannelRequest } from '../actions/dialog.actions';
import {
  GET_USER_CONFIG_REQUEST,
  GET_USER_RECORD_REQUEST,
  OPEN_DB_ALERT_FAILURE,
  OPEN_EDIT_EXISTING_DB_ALERT,
  OPEN_RENAME_EXISTING_DB_ALERT,
  OPEN_UPLOAD_FILE_MANAGER,
  SAVE_USER_RECORD_REQUEST,
  TRY_CHANGE_SETTINGS_VIEW,
  TRY_CLOSE_SETTINGS_MENU,
  UPDATE_USER_CONFIG_REQUEST,
  changeSettingsView,
  closeSettingsMenu,
  getUserConfigFailure,
  getUserConfigSuccess,
  getUserRecordFailure,
  getUserRecordRequest,
  getUserRecordSuccess,
  hasOpenedUploadFileManagerOnce,
  saveUserRecordFailure,
  saveUserRecordSuccess,
  updateUserConfigFailure,
  updateUserConfigSuccess,
} from '../actions/settings.actions';
import { dropSuggestions, loadSuggestionsRequest } from '../actions/suggestions.actions';
import { selectSession } from '../selectors/session.selector';
import {
  selectAccessToken,
  selectTimeOfResponse,
  selectUserConfig,
  selectUserID,
} from './selectors';
import {
  editExistingDatabaseAlertWorker,
  renameExistingDatabaseAlertWorker,
  tryLeaveSettingsUploadMenuAlert,
} from './utils/alert-channels';
// 2s is a bit too short as all requests in the file require db query
// 4s seems enough
const REQUEST_TIMEOUT = 15000;

/**
 * Update User Config in Redux State
 * Note: this was moved from settings.reducer.js so that state from other reducers could be accessed
 * @param {Object} userConfig An object containing user configurations
 */
const updateUserConfig = (userConfig) => {
  const newUserConfig = { ...DEFAULT_USER_CONFIG };
  for (const key of Object.keys(userConfig)) {
    if (key === 'defaultAppViews') {
      newUserConfig[key] = {};
    } else {
      newUserConfig[key] = userConfig[key];
    }
  }
  return newUserConfig;
};

/**
 * Gets user config and permissions object for a user. In case of an error, the function
 * returns a default header color.
 */
export function* getUserConfigRequestWorker() {
  try {
    const accessToken = yield select(selectAccessToken);
    const { response } = yield race({
      response: call(getConfig, accessToken),
      timeout: delay(REQUEST_TIMEOUT),
    });
    if (response) {
      const config = response.data;
      yield put(getUserConfigSuccess({ config: updateUserConfig(config) }));
    } else {
      const error = {
        message: 'GetConfig request timed out',
      };
      yield put(getUserConfigFailure({ error }));
    }

    const userId = yield select(selectUserID);
    const { permissionResponse } = yield race({
      permissionResponse: call(getUserPermissions, userId, accessToken),
      timeout: delay(REQUEST_TIMEOUT),
    });

    if (permissionResponse) {
      const permissions = permissionResponse.data.values.reduce((prev, next) => {
        prev[next.name] = next.value;
        return prev;
      }, {});
      const role = permissionResponse.data.name;
      yield put(getPermissionsSuccess({ permissions, role }));
    } else {
      const error = {
        message: 'GetConfig request timed out',
      };
      yield put(getUserConfigFailure({ error }));
    }
  } catch (error) {
    yield put(getUserConfigFailure({ error }));

    yield put(createAlertChannelRequest({ error }));
  }
}

/**
 * Checks whether the settings menu can be closed.
 */
export function* tryCloseSettingsMenuWorker() {
  yield* tryLeaveSettingsUploadMenuAlert(closeSettingsMenu, null); // closeSettingsMenu takes no args
}

/**
 * Checks whether the settings upload page can be navigated away from.
 */
export function* tryLeaveSettingsUploadMenuWorker({ view }) {
  yield* tryLeaveSettingsUploadMenuAlert(changeSettingsView, view);
}

export function* updateUserConfigRequestWorker({ config }) {
  try {
    const accessToken = yield select(selectAccessToken);
    const existingConfig = yield select(selectUserConfig);
    const newConfig = {
      ...existingConfig,
      ...config,
    };
    const { response } = yield race({
      response: call(putConfig, accessToken, newConfig),
      timeout: delay(REQUEST_TIMEOUT),
    });
    if (response) {
      config = response.data;
      yield put(dropSuggestions());
      yield put(updateUserConfigSuccess({ config: updateUserConfig(config) }));
    } else {
      yield put(
        updateUserConfigFailure({
          error: 'UpdateConfig Request Timeout',
        }),
      );
    }
  } catch (error) {
    yield put(updateUserConfigFailure({ error }));
    yield put(createAlertChannelRequest({ error }));
  }
}

/**
 * Listens for when the user first opens the upload file manager and
 * registers that the event has happened.
 */
export function* hasOpenedUploadFileManagerOnceWorker() {
  yield take(OPEN_UPLOAD_FILE_MANAGER);
  yield put(hasOpenedUploadFileManagerOnce());
}

/**
 * Gets the record (for example, phone number) of a user.
 */
export function* getUserRecordRequestWorker() {
  try {
    const accessToken = yield select(selectAccessToken);
    const session = yield select(selectSession);
    const { response } = yield race({
      response: call(getUserRecord, accessToken),
      timeout: delay(REQUEST_TIMEOUT),
    });
    if (response) {
      const { data } = response;
      yield put(getUserRecordSuccess(data));
    } else {
      yield put(
        getUserRecordFailure({
          error: 'UpdateConfig Request Timeout',
        }),
      );
    }
    const hasSuggestionInCache = yield select(selectTimeOfResponse);
    // Automatically call suggestions if session exists
    if (session && hasSuggestionInCache !== undefined)
      yield put(loadSuggestionsRequest({ message: ' ', contextId: getContextId() }));
  } catch (error) {
    yield put(getUserRecordFailure({ error }));
    yield put(createAlertChannelRequest({ error }));
  }
}

/**
 * Saves the record (for example, phone number) of a user in the database.
 */
export function* saveUserRecordRequestWorker({ phoneNumber }) {
  try {
    const accessToken = yield select(selectAccessToken);
    const { response } = yield race({
      response: call(postUserRecord, accessToken, { phoneNumber }),
      timeout: delay(REQUEST_TIMEOUT),
    });
    if (response.status === OK) {
      yield put(saveUserRecordSuccess());
      yield put(getUserRecordRequest());
    }
  } catch (error) {
    yield put(saveUserRecordFailure({ error }));
    yield put(createAlertChannelRequest({ error }));
  }
}

/**
 * Calls a worker in the alert channel saga to trigger alert
 */
export function* openExistingDatabaseAlertWorker(callbackObj) {
  yield* editExistingDatabaseAlertWorker(callbackObj);
}

export function* databaseAlertFailureWorker(callbackObj) {
  const { error } = callbackObj;
  yield put(createAlertChannelRequest({ error }));
}

/**
 * Listens for getUserConfigRequest actions from the client.
 */
export default function* apiWatcher() {
  yield takeLatest(
    GET_USER_CONFIG_REQUEST,
    authenticate(getUserConfigRequestWorker, getUserConfigFailure),
  );
  yield takeLatest(
    UPDATE_USER_CONFIG_REQUEST,
    authenticate(updateUserConfigRequestWorker, updateUserConfigFailure),
  );
  yield takeLatest(
    GET_USER_RECORD_REQUEST,
    authenticate(getUserRecordRequestWorker, getUserRecordFailure),
  );
  yield takeLatest(
    SAVE_USER_RECORD_REQUEST,
    authenticate(saveUserRecordRequestWorker, saveUserRecordFailure),
  );

  yield fork(hasOpenedUploadFileManagerOnceWorker);
  yield takeLatest(TRY_CLOSE_SETTINGS_MENU, tryCloseSettingsMenuWorker);
  yield takeLatest(TRY_CHANGE_SETTINGS_VIEW, tryLeaveSettingsUploadMenuWorker);
  yield takeLatest(OPEN_RENAME_EXISTING_DB_ALERT, renameExistingDatabaseAlertWorker);
  yield takeLatest(OPEN_EDIT_EXISTING_DB_ALERT, openExistingDatabaseAlertWorker);
  yield takeLatest(OPEN_DB_ALERT_FAILURE, databaseAlertFailureWorker);
}
