/**
 * @fileoverview Generates a MaterialTable with custom rendering and sorting functions
 */
import MaterialTable, { Column } from '@material-table/core';
import React, { useCallback, useMemo, useState } from 'react';
import './GenericMaterialTable.scss';
import GenericMaterialTableRow from './GenericMaterialTableRow';
import { SortOrders } from './column_utils';
import { EMPTY_RESULTS_MESSAGE, TABLE_HEIGHT, WithProperties } from './constants';

/**
 * Allows accessing the selected rows from the GenericMaterialTableRow component
 * without rerendering the entire table.
 * We can do something similar for state of other custom components of MaterialTable if needed.
 */
export const SelectedRowContext = React.createContext<{ selectedRows: WithProperties[] }>({
  selectedRows: [],
});

interface GenericMaterialTableProps<T extends WithProperties> {
  // Gets the column definitions for the table
  getColumns: (sortOrder: SortOrders) => Column<T>[];
  // Implements logic for handling row clicks
  handleRowClick: (object: T, event: React.MouseEvent<HTMLElement>) => void;
  // Implements logic for handling row double clicks
  handleRowDblClick: (object: T, event: React.MouseEvent<HTMLElement>) => void;
  // The data to display in the table
  rowData: T[];
  // The selected rows in the table
  selectedRows: WithProperties[];
  /**
   * Supports nesting functionality by looking up the relationship between child & parent objects
   */
  // Key of the parent's property
  childProperty?: string;
  // Key of the child's property that matches the parent's property
  parentProperty?: string;
  /** max height of the table */
  maxBodyHeight?: string | number;
  /** Min height of the table */
  minBodyHeight?: string | number;
}

const GenericMaterialTable = <T extends WithProperties>({
  getColumns,
  handleRowClick,
  handleRowDblClick,
  rowData,
  selectedRows,
  childProperty,
  parentProperty,
  maxBodyHeight = TABLE_HEIGHT,
  minBodyHeight = TABLE_HEIGHT,
}: GenericMaterialTableProps<T>): React.ReactElement => {
  const [currSortOrder, setCurrSortOrder] = useState<SortOrders>(SortOrders.DESC);

  /**
   * Get the column definitions for the table
   * Refer to ./column_utils for example column definitions
   */
  const columns: Column<T>[] = useMemo(
    () => getColumns(currSortOrder),
    [currSortOrder, getColumns],
  );

  // Get the table rows with selection highlighting
  const getRow = useCallback(
    (rowProps: { data: T }) => {
      return (
        <GenericMaterialTableRow
          handleRowClick={handleRowClick}
          handleRowDblClick={handleRowDblClick}
          rowProps={rowProps}
        />
      );
    },
    [handleRowClick, handleRowDblClick],
  );

  return (
    <SelectedRowContext.Provider value={{ selectedRows }}>
      <div className="GenericMaterialTable" data-testid="GenericMaterialTable">
        {/* https://material-table.com/#/docs/all-props */}
        <MaterialTable
          data={rowData}
          columns={columns}
          components={{ Row: getRow }}
          onOrderChange={(_, ord) => setCurrSortOrder(ord as SortOrders)}
          localization={{
            body: { emptyDataSourceMessage: EMPTY_RESULTS_MESSAGE },
          }}
          options={{
            draggable: false, // Flag for drag & drop headers
            filtering: false, // Flag to activate or disable filtering feature of column
            tableLayout: 'fixed', // To make columns width algorithm auto or fixed
            paging: false, // Flag for the paging feature
            toolbar: false, // Flag for showing the toolbar
            actionsColumnIndex: -1, // Order of actions column
            overflowY: 'initial', // Needed for sticky header
            thirdSortClick: false, // Flag to allow unsorted state on third header click
            maxBodyHeight,
            minBodyHeight,
            padding: 'dense',
          }}
          {...(parentProperty && childProperty
            ? {
                parentChildData: (row: T, rows: T[]) =>
                  rows.find((a) => a[parentProperty as keyof T] === row[childProperty as keyof T]),
              }
            : {})}
        />
      </div>
    </SelectedRowContext.Provider>
  );
};

GenericMaterialTable.defaultProps = {
  childProperty: null,
  parentProperty: null,
};

export default GenericMaterialTable;
