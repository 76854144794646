import { Presentation, Series } from 'translate_dc_to_echart';
import ChartModules from '../modules/ChartModules';
import { FilterParams } from '../types/columnSelection';

/**
 * The base class for all chart types.
 */
export default class ChartType {
  /** The modules containing varying implementations per ChartType. */
  modules: ChartModules;

  constructor(modules: ChartModules) {
    this.modules = modules;
  }

  /** Filter the Chart Builder column options. */
  public runColumnFilters = (params: FilterParams) =>
    this.modules.ColumnSelection.runColumnFilters(params);

  /** Generate the Fields object when editing a chart from the Chart Builder. */
  public generateFields = (series: Series[], presentation: Presentation) =>
    this.modules.ChartBuilderIO.generateFields(series, presentation);
}
