import { mdiDatabaseSearch } from '@mdi/js';
import Cable from '@mui/icons-material/Cable';
import Chat from '@mui/icons-material/Chat';
import Description from '@mui/icons-material/Description';
import Equalizer from '@mui/icons-material/Equalizer';
import Folder from '@mui/icons-material/Folder';
import InsertDriveFile from '@mui/icons-material/InsertDriveFile';
import ManageAccountsOutlined from '@mui/icons-material/ManageAccountsOutlined';
import Search from '@mui/icons-material/Search';
import Star from '@mui/icons-material/Star';
import Storage from '@mui/icons-material/Storage';
import TableChart from '@mui/icons-material/TableChart';
import CircularProgress from '@mui/material/CircularProgress';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import IconWithText from '../components/IconWithText';
import { selectDefaultHomeObjects, selectUsersInOrganizationById } from '../store/sagas/selectors';
import {
  connectionColor,
  datafileColor,
  datasetColor,
  folderColor,
  ibColor,
  queryColor,
  searchColor,
  sessionColor,
  snapshotColor,
  workflowColor,
} from '../styles/_colors.scss';
import { HomeObjectActions } from '../utils/homeScreen/homeScreenActions';
import { PERMISSION_DATABASE_CONNECTIONS } from '../utils/permissions';
import { APPS } from './apps';

// inject store so we can access redux from this file
// ----------------------------------------------------
let store;
export const injectStoreToHomeScreenConstants = (_store) => {
  store = _store;
};
// ----------------------------------------------------

export const ACTIVE_TAB_CLASS = '.Home-Body-Active';

/**
 * Enum of home screen object types.  String value should
 * be presentable as the type of an object.  These types
 * also map to tabs on the home screen.
 *
 * If changing value of these variables, also change the corresponding
 * variable in dcobject.go (WA_{objectType})
 */
export const HOME_OBJECTS = {
  SESSION: 'Session',
  RECIPE: 'Workflow',
  INSIGHTS_BOARD: 'Insights Board',
  SNAPSHOT: 'Snapshot',
  CONNECTION: 'Database',
  ALL: 'My Work',
  FOLDER: 'Folder',
  SEARCH: 'Search',
  QUERY: 'Query',
  DATAFILE: 'File',
  ASK_SESSION: 'Conversation',
  DATASET: 'Dataset',
};

// home screen object types that are supported in the dc_object table
export const DC_OBJECTS = [
  HOME_OBJECTS.RECIPE,
  HOME_OBJECTS.INSIGHTS_BOARD,
  HOME_OBJECTS.DATASET,
  HOME_OBJECTS.SNAPSHOT,
  HOME_OBJECTS.CONNECTION,
  HOME_OBJECTS.FOLDER,
  HOME_OBJECTS.QUERY,
  HOME_OBJECTS.DATAFILE,
];

// home screen objects to include in featured cards
export const RECENT_OBJECT_TYPES = [
  HOME_OBJECTS.SESSION,
  HOME_OBJECTS.RECIPE,
  HOME_OBJECTS.INSIGHTS_BOARD,
  HOME_OBJECTS.SNAPSHOT,
  HOME_OBJECTS.CONNECTION,
  HOME_OBJECTS.QUERY,
  HOME_OBJECTS.DATAFILE,
  HOME_OBJECTS.DATASET,
];

// mapping of home object type to a permission
// Only include object types that have a mapping to the permissions object
export const HOME_OBJECTS_PERMISSION = {
  [HOME_OBJECTS.CONNECTION]: PERMISSION_DATABASE_CONNECTIONS,
};

// initial tab when user navigates to home screen
export const PRIMARY_TAB = HOME_OBJECTS.ALL;

/**
 * Map above Enum values to the name of a category for that type.
 */
export const HOME_OBJECT_CATEGORIES = {
  [HOME_OBJECTS.SESSION]: 'Sessions',
  [HOME_OBJECTS.RECIPE]: 'Workflows',
  [HOME_OBJECTS.INSIGHTS_BOARD]: 'Insights Boards',
  [HOME_OBJECTS.SNAPSHOT]: 'Snapshots',
  [HOME_OBJECTS.CONNECTION]: 'Databases',
  [HOME_OBJECTS.ALL]: 'My Work',
  [HOME_OBJECTS.FOLDER]: 'Folders',
  [HOME_OBJECTS.SEARCH]: 'Search Results',
  [HOME_OBJECTS.QUERY]: 'Queries',
  [HOME_OBJECTS.DATAFILE]: 'Files',
  [HOME_OBJECTS.DATASET]: 'Datasets',
};

/**
 * Map home object types to a description of them.  This can be helpful to display to the user.
 */
export const HOME_OBJECT_DESCRIPTIONS = {
  [HOME_OBJECTS.SESSION]: 'An instance of an app where you can work with your data.',
  [HOME_OBJECTS.RECIPE]: 'A list of steps that can be saved and replayed.',
  [HOME_OBJECTS.INSIGHTS_BOARD]: 'A dashboard-like tool for telling stories with your data.',
  [HOME_OBJECTS.SNAPSHOT]:
    'A collection of datasets from a session that can be loaded into different sessions.',
  [HOME_OBJECTS.CONNECTION]: 'A collection of all the databases',
  [HOME_OBJECTS.ALL]: 'All results of your search.',
  [HOME_OBJECTS.FOLDER]: 'A filesystem for DataChat Objects.',
  [HOME_OBJECTS.SEARCH]: 'Results of your search.',
  [HOME_OBJECTS.QUERY]: 'A list of SQL Query objects that you can use to load datasets',
  [HOME_OBJECTS.DATAFILE]: 'A list of files containing data',
  [HOME_OBJECTS.DATASET]: 'A collection of datasets that can be loaded into sessions',
};

export const HOME_OBJECT_KEYS = {
  ID: 'objectId',
  TABLE_ID: 'id', // For material table
  UUID: 'uuid', // Not universal
  ACCESS_TYPE: 'accessType', // Not universal
  NAME: 'objectName',
  TYPE: 'objectType',
  OWNER_NAME: 'ownerName',
  OWNER_EMAIL: 'ownerEmail', // Not universal
  OWNER_ID: 'ownerId',
  IS_SHARED: 'isShared',
  CREATED: 'created',
  LAST_ACTIVE: 'lastActive',
  LAST_MODIFIED: 'dateLastModified', // Not universal
  VISIBILITY: 'visibility',
  HAS_INDEPENDENT_ACCESS: 'hasIndependentAccess', // Will be true for object owner or if the object is shared independently with other user. False if the object is shared with organization.
  READ_ONLY: 'readOnly', // Not universal
  EXAMPLE_FK: 'exampleFk', // Not universal
};

// Map home object keys to names that are more user-friendly
export const HOME_OBJECT_ACTIVE_NAMES = {
  [HOME_OBJECT_KEYS.ID]: 'Id',
  [HOME_OBJECT_KEYS.NAME]: 'Name',
  [HOME_OBJECT_KEYS.TYPE]: 'Object Type',
  [HOME_OBJECT_KEYS.OWNER_NAME]: 'Owner Name',
  [HOME_OBJECT_KEYS.IS_SHARED]: 'Shared',
  [HOME_OBJECT_KEYS.CREATED]: 'Created',
  [HOME_OBJECT_KEYS.LAST_ACTIVE]: 'Last Active',
  [HOME_OBJECT_KEYS.LAST_MODIFIED]: 'Last Modified',
};

/**
 * Map home object types to icons representing them.  Note these are the component, not an element.
 */
export const HOME_OBJECT_ICONS = {
  [HOME_OBJECTS.ALL]: Star,
  [HOME_OBJECTS.SESSION]: Chat,
  [HOME_OBJECTS.RECIPE]: Description,
  [HOME_OBJECTS.INSIGHTS_BOARD]: Equalizer,
  [HOME_OBJECTS.SNAPSHOT]: Storage,
  [HOME_OBJECTS.CONNECTION]: Cable,
  [HOME_OBJECTS.FOLDER]: Folder,
  [HOME_OBJECTS.SEARCH]: Search,
  [HOME_OBJECTS.QUERY]: (props) => (
    <SvgIcon {...props}>
      <path d={mdiDatabaseSearch} />
    </SvgIcon>
  ),
  [HOME_OBJECTS.DATAFILE]: InsertDriveFile,
  [HOME_OBJECTS.DATASET]: TableChart,
};

export const HOME_OBJECT_COLORS = {
  [HOME_OBJECTS.ALL]: 'var(--mui-palette-primary-main)',
  [HOME_OBJECTS.SESSION]: sessionColor,
  [HOME_OBJECTS.RECIPE]: workflowColor,
  [HOME_OBJECTS.INSIGHTS_BOARD]: ibColor,
  [HOME_OBJECTS.SNAPSHOT]: snapshotColor,
  [HOME_OBJECTS.CONNECTION]: connectionColor,
  [HOME_OBJECTS.FOLDER]: folderColor,
  [HOME_OBJECTS.SEARCH]: searchColor,
  [HOME_OBJECTS.QUERY]: queryColor,
  [HOME_OBJECTS.DATAFILE]: datafileColor,
  [HOME_OBJECTS.DATASET]: datasetColor,
};

export const HOME_SCREEN_REFRESH_INTERVAL = 60000; // refresh home screen data every 60 seconds

export const APP_ICONS = {
  [APPS.MANAGEMENT]: ManageAccountsOutlined,
};

export const ACTION_TYPES = {
  ALL_ACTIONS: 'allActions',
  SPECIFIC_ACTIONS: 'specificActions',
  FEATURED_ACTIONS: 'featuredActions',
};

// TODO: this should be used in a TS file, comment out for future use
// type HomeObjectIconProps = {
//   objectType: String,
//   props: Object,
//   app: Object,
//   textOverlay: Boolean,
// };

export const homeObjectIcon = ({
  objectType,
  props = {},
  app = {},
  textOverlay = '',
  tooltip = '',
  processing = false,
}) => {
  const appIcon = APP_ICONS[app?.name];
  let Icon;
  if (objectType === HOME_OBJECTS.SESSION && appIcon) {
    Icon = appIcon;
  } else {
    Icon = HOME_OBJECT_ICONS[objectType];
  }

  if (processing && objectType === HOME_OBJECTS.SESSION) {
    // show a loading spinner for session icons when they are processing (i.e. deleting)
    return <CircularProgress color="inherit" size="1.5rem" />;
  }
  return textOverlay ? (
    <Tooltip title={tooltip}>
      <>
        <IconWithText props={props} text={textOverlay}>
          <Icon htmlColor={HOME_OBJECT_COLORS[objectType]} />
        </IconWithText>
      </>
    </Tooltip>
  ) : (
    <Tooltip title={tooltip}>
      <>
        <Icon {...props} htmlColor={HOME_OBJECT_COLORS[objectType]} />
      </>
    </Tooltip>
  );
};

export const sessionDisplayName = (app) => {
  let displayName;
  if (app.name === APPS.DATACHAT) {
    // Unify is the default session
    displayName = 'Session';
  } else if (app.name === APPS.AVA) {
    // DataGrid session is in dev mode only
    displayName = 'DataGrid Session';
  } else if (app.name === APPS.AVA_INCUBATING) {
    // Ava Incubating is developer-only
    displayName = 'Session (Incubating)';
  } else {
    // For all other sessions, for example, Management, will show Management Session as name
    displayName = `${app.name} Session`;
  }
  return displayName;
};

/**
 * Fuse options for search bar
 */
export const FUSE_OPTIONS = {
  findAllMatches: true,
  includeScore: true,
  ignoreLocation: true,
  keys: [HOME_OBJECT_KEYS.NAME],
  shouldSort: true,
};

export const REQUEST_STATUS = {
  UNREQUESTED: 'unrequested',
  REQUESTED: 'requested',
  SUCCESS: 'success',
  FAILURE: 'failure',
  REFRESH: 'refreshing',
};

export const STRING_CONSTANTS = {
  LAST_OPENED: 'Last Opened',
};

export const GLOBAL_USER_EMAIL = 'DataChat@datachat.ai';

export const homeActionId = (name) => `home-action-${name}`;

// If we want to reference an action's element by ID,
// Expose it in this object.
export const HOME_ACTION_IDS = {
  OPEN: homeActionId('open'),
  DELETE: homeActionId('delete'),
  HIDE: homeActionId('hide'),
  RENAME: homeActionId('rename'),
  EDIT: homeActionId('edit'),
  SAVE_AS: homeActionId('save-as'),
  SHARE: homeActionId('share'),
  MOVE: homeActionId('move'),
  REFRESH: homeActionId('refresh'),
  MORE_OPTIONS: homeActionId('more-options'),
  OPEN_DATACHAT: homeActionId('open-datachat'),
  LOAD_FOLDERS_AND_DATASETS: homeActionId('load-folders-and-datasets'),
  OPEN_CATALOG: homeActionId('open-catalog'),
};

// Used for home screen filter options when user wants to remove filter or choose all objects.
export const DEFAULT_FILTER_OPTION = 'default';

// Date specific filter options
export const LAST_ACTIVE_FILTER_OPTIONS = {
  TODAY: 'Today',
  LAST_7_DAYS: 'Last 7 days',
  LAST_30_DAYS: 'Last 30 days',
  THIS_YEAR: 'This year',
  LAST_YEAR: 'Last year',
};

// Visibility specific filter options
export const OBJECT_VISIBILITY_FILTER_OPTIONS = {
  SHOW_HIDDEN: 'Show Hidden',
  HIDE_HIDDEN: 'Hide Hidden',
};

// Filter Options that are shown on the top bar
export const HOME_OBJECT_TABLE_FILTERS = {
  [HOME_OBJECT_KEYS.TYPE]: {
    LABEL: 'Object Type',
    GET_MENU_OPTIONS: () => {
      const state = store.getState();
      const defaultHomeObjects = selectDefaultHomeObjects(state);
      return [{ value: DEFAULT_FILTER_OPTION, name: 'All Objects' }, ...defaultHomeObjects];
    },
  },
  [HOME_OBJECT_KEYS.OWNER_ID]: {
    LABEL: 'Owner',
    GET_MENU_OPTIONS: () => {
      const state = store.getState();
      const usersInOrganizationById = selectUsersInOrganizationById(state);
      if (!isEmpty(usersInOrganizationById)) {
        const userObjects = [{ value: DEFAULT_FILTER_OPTION, name: 'All Users' }];
        usersInOrganizationById.forEach(({ id, name }) => {
          userObjects.push({ value: id, name });
        });
        return userObjects;
      }
      return [];
    },
  },
  [HOME_OBJECT_KEYS.LAST_ACTIVE]: {
    LABEL: 'Last Active',
    GET_MENU_OPTIONS: () => {
      const currentYear = new Date().getFullYear();
      const dateFilters = [{ value: DEFAULT_FILTER_OPTION, name: 'All Dates' }];

      Object.entries(LAST_ACTIVE_FILTER_OPTIONS).forEach(([, value]) => {
        if (value === LAST_ACTIVE_FILTER_OPTIONS.THIS_YEAR) {
          return dateFilters.push({
            value,
            name: value.concat(' ', `(${currentYear.toString()})`),
          });
        } else if (value === LAST_ACTIVE_FILTER_OPTIONS.LAST_YEAR) {
          return dateFilters.push({
            value,
            name: value.concat(' ', `(${(currentYear - 1).toString()})`),
          });
        }
        return dateFilters.push({ value, name: value });
      });

      return dateFilters;
    },
  },
  [HOME_OBJECT_KEYS.VISIBILITY]: {
    LABEL: 'Visibility',
    GET_MENU_OPTIONS: () => [
      { value: DEFAULT_FILTER_OPTION, name: OBJECT_VISIBILITY_FILTER_OPTIONS.HIDE_HIDDEN },
      OBJECT_VISIBILITY_FILTER_OPTIONS.SHOW_HIDDEN, // Here value and name are OBJECT_VISIBILITY_FILTER_OPTIONS.SHOW_HIDDEN
    ],
  },
};

/**
 * Object actions that will only be visible in dev mode. This object should map an object's type to
 * an array of experimental actions. For example:
 * ```js
 * {
 *   [HOME_OBJECTS.SESSION]: [HomeObjectActions.OPEN_DATAGRID_ACTION]
 * }
 * ```
 */
export const EXPERIMENTAL_OBJECT_ACTIONS_BY_TYPE = {
  [HOME_OBJECTS.SESSION]: [HomeObjectActions.OPEN_DATAGRID_ACTION],
};

// Number of actions to appear in the HomeScreenObjectTable header.
export const NUMBER_OF_ACTIONS_IN_HEADER = 3;

export const HOME_TEST_IDS = {
  TABLE_ROW: 'home-object-table-row',
};
