/**
 * This file sets up global state for the web application.
 * It relies mainly on two middlewares:
 * 1. Saga for application state
 * 2. History for browser state
 */
import { Action, AnyAction, configureStore, Dispatch, Middleware } from '@reduxjs/toolkit';
import * as externalStripeService from '@stripe/stripe-js/pure';
import { createBrowserHistory } from 'history';
import { unstable_batchedUpdates } from 'react-dom';
import { batchedSubscribe } from 'redux-batched-subscribe';
import { createReduxHistoryContext } from 'redux-first-history';
import { createLogger } from 'redux-logger';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import * as avaService from './api/askAva.api';
import * as authService from './api/auth.api';
import * as catalogService from './api/catalog.api';
import * as chartService from './api/chart.api';
import * as chartspaceService from './api/chartspace.api';
import * as chatService from './api/chat.api';
import * as configService from './api/config.api';
import * as dataAssistantService from './api/dataAssistant.api';
import * as databaseBrowserService from './api/databaseBrowser.api';
import * as datasetService from './api/datasets.api';
import * as dataspaceService from './api/dataspace.api';
import * as downloadService from './api/download.api';
import * as embedService from './api/embed.api';
import * as fileManagerService from './api/file_manager.api';
import * as uploadService from './api/files.api';
import * as insightsBoardService from './api/insights_board.api';
import * as interruptService from './api/interrupt.api';
import * as licenseService from './api/licence.api';
import * as logUserActionService from './api/log_user_action.api';
import * as nodesService from './api/nodes.api';
import * as oAuthService from './api/oauth.api';
import * as recommendationsService from './api/recommendations.api';
import * as sessionService from './api/session.api';
import * as subscriptionsService from './api/subscriptions.api';
import * as taskService from './api/task.api';
import * as workspacev2Service from './api/workspacev2.api';
import { API_SERVICES } from './constants/api';
import { DEV, TEST } from './constants/env';
import createRootReducer from './store/reducers';
import rootSaga from './store/sagas';

const reduxSagaContext = {
  [API_SERVICES.AVA]: avaService,
  [API_SERVICES.CHAT]: chatService,
  [API_SERVICES.DOWNLOAD]: downloadService,
  [API_SERVICES.SESSION]: sessionService,
  [API_SERVICES.LOG_USER_ACTION]: logUserActionService,
  [API_SERVICES.INSIGHTS_BOARD]: insightsBoardService,
  [API_SERVICES.CHART]: chartService,
  [API_SERVICES.WORKSPACEV2]: workspacev2Service,
  [API_SERVICES.UPLOAD]: uploadService,
  [API_SERVICES.AUTH]: authService,
  [API_SERVICES.DATASET]: datasetService,
  [API_SERVICES.SUBSCRIPTIONS]: subscriptionsService,
  [API_SERVICES.DATASPACE]: dataspaceService,
  [API_SERVICES.CHARTSPACE]: chartspaceService,
  [API_SERVICES.NODES]: nodesService,
  [API_SERVICES.CONFIG]: configService,
  [API_SERVICES.EXTERNAL_STRIPE]: externalStripeService,
  [API_SERVICES.RECOMMENDATIONS]: recommendationsService,
  [API_SERVICES.DATA_ASSISTANT]: dataAssistantService,
  [API_SERVICES.LICENSE]: licenseService,
  [API_SERVICES.DATABASE_BROWSER]: databaseBrowserService,
  [API_SERVICES.INTERRUPT]: interruptService,
  [API_SERVICES.TASK]: taskService,
  [API_SERVICES.CATALOG]: catalogService,
  [API_SERVICES.FILE_MANAGER]: fileManagerService,
  [API_SERVICES.OAUTH]: oAuthService,
  [API_SERVICES.EMBED]: embedService,
};

const sagaMiddleware = createSagaMiddleware({
  context: reduxSagaContext,
});

export type ReduxSagaContext = typeof reduxSagaContext;

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
  reduxTravelling: DEV,
});

const middleware = [sagaMiddleware, routerMiddleware];

if (DEV && !TEST) {
  middleware.push(
    createLogger({
      collapsed: true,
      colors: {
        title: (action: Action<string>) =>
          action.type.match(/(FAILURE)|(Failure)/)
            ? 'red'
            : action.type.match(/(^persist)|(\w*SUCCESS\b)|(\w*Success\b)/)
            ? 'green'
            : action.type.match(/(^auth)/)
            ? '#000080' // navy
            : action.type.match(/(^apps)/)
            ? '#00c0c0' // complementary-red-bright
            : action.type.match(/(^session\/)/)
            ? '#d68b00' // orange-dark
            : action.type.match(/(^context\/)|(^poll\/)/)
            ? '#adadad' // black soft
            : action.type.match(/^message\/RECEIVE_MESSAGES/)
            ? '#4B0082' // indigo
            : action.type.match(/^suggestions\/LOAD_SUGGESTIONS/)
            ? '#800080' // purple
            : action.type.match(/^message\/SEND_MESSAGE/)
            ? '#0084bf' // blue-bright
            : '#4e5a5f', // grey-med-dark
        prevState: () => '#9E9E9E',
        action: () => '#03A9F4',
        nextState: () => '#4CAF50',
        error: () => '#F20404',
      },
    }) as Middleware<object, any, Dispatch<AnyAction>>,
  );
}

const store = configureStore({
  reducer: createRootReducer(routerReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(middleware),
  // batchedSubscribe usage from its readme:
  // https://github.com/tappleby/redux-batched-subscribe#debounced-subscribe-handlers
  enhancers: (defaultEnhancers) =>
    defaultEnhancers.concat([batchedSubscribe(unstable_batchedUpdates)]),
});

sagaMiddleware.run(rootSaga);

const persistor = persistStore(store);
const history = createReduxHistory(store);

/**
 * Listen for changes in the browser history & scroll to the element with the hash ID.
 * Ex. localhost/web/session#section1, scroll to element in the DOM with the ID section1
 * @param location - The location object from the history object
 * @param location.hash - The hash of the location object
 * @returns void
 */
history.listen(({ location }) => {
  let elem = null;
  if (location?.hash) elem = document.querySelector(location.hash);
  if (elem) elem.scrollIntoView(true);
});

export { history, persistor, store };

// Get types for the store to be used in Typescript
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
