import { createSelector } from 'reselect';
import { RootState } from '../../configureStore';
import { HOME_OBJECTS } from '../../constants/home_screen';
import { HomeObjectKeys, HomeObjectKeysTypes } from '../../utils/homeScreen/types';

export const selectSelectedRows = (state: RootState): HomeObjectKeysTypes[] =>
  state.homeScreen.selectedRows;

export const selectSelectedFeatureCard = (state: RootState): HomeObjectKeysTypes =>
  state.homeScreen.selectedFeatureCard;

/** Finds the name of the dataset with the given object_fk, if it exists. */
export const selectDatasetNameByObjectFk =
  (fk: string) =>
  (state: RootState): string | undefined => {
    const datasets = state.homeScreen.objects[HOME_OBJECTS.DATASET] as Record<
      string,
      HomeObjectKeysTypes
    >;
    const dataset = Object.values(datasets).find((ds) => ds[HomeObjectKeys.OBJECT_FK] === fk);
    return dataset?.[HomeObjectKeys.NAME];
  };

/** Finds the object with the given object_fk, if it exists. */
export const selectObjectByObjectFk = createSelector(
  (state: RootState) =>
    state.homeScreen.objects as Record<
      string, // object type
      Record<string, HomeObjectKeysTypes> // object UUID to object
    >,
  (_: RootState, fk: string) => fk,
  (objects, fk) => {
    // merge objects across types
    const objectsByUUID = Object.values(objects).reduce((acc, objs) => ({ ...acc, ...objs }), {});

    // return the object with the given fk
    return Object.values(objectsByUUID).find((obj) => obj[HomeObjectKeys.OBJECT_FK] === fk);
  },
);
export const selectObjectsByType = (type: string) => (state: RootState) =>
  state.homeScreen.objects[type] as Record<string, HomeObjectKeysTypes>;
