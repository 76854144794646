export const DEFAULT_SESSION_ID = 'riq93jgsm21awq0g';

export const paths = {
  unauthorized: '/web/unauthorized',
  login: '/web/login',
  snowflakeLogin: '/web/snowflake/login',
  register: '/web/register',
  registerAsk: '/web/register/ask',
  emailResetToken: '/web/reset_password',
  verifyEmail: '/web/verify_email',
  resetPassword: '/web/reset_password/confirm',
  index: '/',
  ask: '/ask/',
  home: '/web/',
  homeScreenTabs: '/web/home/',
  chat: '/web/chat',
  dataChatSession: '/web/session',
  cloudauth: '/web/cloudauth',
  dashboard: '/web/insights',
  insightsBoard: (insightsBoardId) => {
    return `/web/insights/${insightsBoardId}`;
  },
  dashboardReloadView: (dashboardSessionId, dashboardAppId) =>
    `${paths.dashboard}/view/${dashboardSessionId}/${dashboardAppId}`,
  dashboardView: (dashboardName, dashboardSessionName, dashboardAppId, dashboardId) =>
    `${paths.dashboard}/view/${dashboardName}/${dashboardSessionName}/${dashboardAppId}/${dashboardId}`,
  dashboardList: '/web/insights/all',
  sessionSnapshot: '/web/chat/snapshot',
  session: `/web/chat/1?session=${DEFAULT_SESSION_ID}`,
  editor: '/web/editor',
  editorView: (objectType, objectId, autoSave = false) =>
    `${paths.editor}/${objectType}/${objectId}${autoSave ? '?autoSave=true' : ''}`,
  embed: '/web/embed/v1',
  embedView: (pubType, pubId, apiKey, apiSecret) =>
    `${paths.embed}/${pubType}/${pubId}?client_id=${apiKey}&secret=${apiSecret}`,
  privateEmbed: '/web/embed/private/v1',
  privateEmbedView: (pubType, pubId) => `${paths.privateEmbed}/${pubType}/${pubId}`,
  discourseSSO: '/web/sso/discourse',
  insightsBoardView: '/web/insights',
  payment: '/web/payments',
  account: '/web/payments/account',
  oauth: '/oauth',
  oauthConsent: '/web/oauth/consent',

  admin: 'web/admin',

  // Organizations
  organizations: '/web/admin/organizations',
  organization: (organizationId) => `/web/admin/organizations/${organizationId}`,
  organizationUser: (organizationId, userId) =>
    `/web/admin/organizations/${organizationId}/users/${userId}`,

  // Users
  users: '/web/admin/users',
  user: (userId) => `/web/admin/users/${userId}`,

  // Publications
  publications: '/web/admin/publications',

  // API keys
  apiKeys: '/web/admin/api-keys',

  oauthClients: '/web/admin/oauth-clients',

  acceptInvitation: (token) => `/web/accept-invite/${token}`,
};

export const UNAUTHENTICATED_PAGES = [
  paths.login,
  paths.register,
  paths.registerAsk,
  paths.resetPassword,
  paths.emailResetToken,
  paths.verifyEmail,
  paths.embed,
];

/**
 * Returns true if the current page is a dashboard view page. Otherwise, false.
 */
export const isDashboardViewPage = () =>
  window.location.pathname.startsWith(`${paths.dashboard}/view/`);

/**
 * Returns true if the current page is a chat page (including snapshot). Otherwise, false.
 */
export const isChatPage = () => window.location.pathname.startsWith(paths.chat);

export const isInsightsBoardPage = () => window.location.pathname.startsWith(paths.dashboard);

export const isWorkflowEditorPage = () => window.location.pathname.startsWith(paths.editor);

export const isDataChatSessionPage = () =>
  window.location.pathname.startsWith(paths.dataChatSession);

// constants for browser router actions.
export const POP = 'POP';
export const PUSH = 'PUSH';
export const REPLACE = 'REPLACE';
