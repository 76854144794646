import { EnhancedStore } from '@reduxjs/toolkit';
import {
  AUTHENTICATION_ERROR_DESCRIPTIONS,
  AUTHENTICATION_ERROR_TITLE,
  BAD_REQUEST_ERROR_DESCRIPTIONS,
  BAD_REQUEST_ERROR_TITLE,
  ENDPOINT_NOT_FOUND_DESCRIPTIONS,
  ENDPOINT_NOT_FOUND_TITLE,
  FORBIDDEN_ERROR_DESCRIPTIONS,
  FORBIDDEN_ERROR_TITLE,
} from '../../../constants/dialog.constants';
import { handleClientError } from '../../../store/actions/dialog.actions';
import { ErrorDetails, ErrorResponse } from '../../../types/errorCodes/errorCodes.types';
import { BaseErrorHandler } from './BaseErrorHandler';

// inject store so we can access redux from this file
let store: EnhancedStore;
export const injectStoreToClient4xxErrorHandlers = (_store: EnhancedStore) => {
  store = _store;
};

type DelayedAction = {
  action: () => void;
  delay: number; // delay in milliseconds
};

type ErrorActions = {
  [key: number]: DelayedAction;
};

/**
 * Handles 4xx client error codes. These errors occur when the client request is invalid,
 * unauthorized, or otherwise problematic.
 */
export class Client4xxErrorHandler extends BaseErrorHandler {
  private errorActions: ErrorActions;

  constructor(errorActions: ErrorActions = {}) {
    super();
    this.errorActions = {
      403: {
        action: () => {
          window.location.href = `${window.location.origin}/web`;
        },
        delay: 2000, // Default 2 second delay for login redirect
      },
      ...errorActions,
    };
  }

  canHandle = (errorDetails?: ErrorDetails): boolean => {
    if (errorDetails?.status) {
      const { status } = errorDetails;
      return status >= 400 && status < 500;
    }
    return false;
  };

  private executeDelayedAction = (status: number | undefined) => {
    if (status) {
      const delayedAction = this.errorActions[status];
      if (delayedAction) {
        setTimeout(() => {
          delayedAction.action();
        }, delayedAction.delay);
      }
    }
  };

  handle = (errorDetails: ErrorDetails): void => {
    const { status, message } = errorDetails;
    let title = 'Error';
    let descriptions = ['An error occurred while processing your request.'];
    let collapsedDescriptions: ErrorResponse[] = [
      'An error occurred while processing your request.',
    ];
    let buttons: {
      label: string;
      key: string;
      onClick: () => void;
      variant?: string;
      color?: string;
    }[] = [];

    switch (status) {
      case 400:
        title = BAD_REQUEST_ERROR_TITLE;
        descriptions = BAD_REQUEST_ERROR_DESCRIPTIONS;
        collapsedDescriptions = [message ?? BAD_REQUEST_ERROR_TITLE];
        break;
      case 401:
        title = AUTHENTICATION_ERROR_TITLE;
        descriptions = AUTHENTICATION_ERROR_DESCRIPTIONS;
        collapsedDescriptions = [message ?? AUTHENTICATION_ERROR_TITLE];
        buttons = [
          {
            label: 'Login',
            key: 'login',
            onClick: () => {
              window.location.href = `${window.location.origin}/web`;
            },
            variant: 'contained',
            color: 'primary',
          },
        ];
        break;
      case 403:
        title = FORBIDDEN_ERROR_TITLE;
        descriptions = FORBIDDEN_ERROR_DESCRIPTIONS;
        collapsedDescriptions = [message ?? FORBIDDEN_ERROR_TITLE];
        break;
      case 404:
        title = ENDPOINT_NOT_FOUND_TITLE;
        descriptions = ENDPOINT_NOT_FOUND_DESCRIPTIONS;
        collapsedDescriptions = [message ?? ENDPOINT_NOT_FOUND_TITLE];
        break;
      default:
        title = 'Client Error';
        descriptions = [
          `An error occurred (Status: ${status})`,
          'Please try again or contact support if the problem persists.',
        ];
        collapsedDescriptions = [message ?? `An error occurred (Status: ${status})`];
        break;
    }

    const alertConfig = {
      title,
      descriptions,
      collapsedDescriptions,
      buttons,
      dialogType: 'client-error',
      fullWidth: true,
      maxWidth: 'md',
    };

    store.dispatch(handleClientError(alertConfig));

    this.executeDelayedAction(status);
  };
}
