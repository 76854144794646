import Skeleton from '@mui/material/Skeleton';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import ReactResizeDetector from 'react-resize-detector';
import { AVA_IDS } from '../../../../../constants/dataAssistant';
import { openPopOutModal } from '../../../../../store/actions/popout.actions';
import SessionData from '../../../../ChartData/SessionData';
import ErrorBoundary from '../../../../common/ErrorBoundary/ErrorBoundary';
import { ScreenSizeContext } from '../../../../DataAssistant/DataAssistant';
import DCPlotV2 from '../../../../DisplayPanel/Charts/dcplotV2/DCPlotV2';
import DCPlotV2ErrorBoundary from '../../../../DisplayPanel/Charts/dcplotV2/DCPlotV2ErrorBoundary';
import './ChartMessage.scss';

const MIN_CHART_WIDTH = 500;

const ChartMessage = ({ externalName, id, message, objectId, inView, update, parentObjectId }) => {
  const dispatch = useDispatch();

  const isSmallScreen = useContext(ScreenSizeContext);

  const handleChartClick = () => {
    if (!isSmallScreen) return;
    dispatch(
      openPopOutModal({ chartObjectId: parentObjectId || objectId, isPopoutEditable: false }),
    );
  };

  return (
    <ReactResizeDetector handleWidth handleHeight key={objectId}>
      {({ width, height, targetRef }) => (
        <div
          className={classNames('ChartMessage', { SmallScreenSize: isSmallScreen })}
          data-testid={AVA_IDS.MSG_CHART_WRAPPER(id)}
          key={objectId}
          ref={targetRef}
          onClick={handleChartClick}
        >
          {inView ? (
            <SessionData key={id} message={message} objectId={objectId} update={update}>
              {(msgContext, error, isFailed, isLoading) =>
                isFailed ? (
                  <ErrorBoundary type="echart" hasError={isFailed} error={error} />
                ) : (
                  width !== undefined && (
                    <div
                      className={classNames('ChartMessage-PlotContainer', {
                        SmallScreenSize: isSmallScreen,
                      })}
                      style={
                        width >= MIN_CHART_WIDTH
                          ? { width: '100%', height: '100%' }
                          : {
                              width: `${(MIN_CHART_WIDTH / width) * 100}%`,
                              aspectRatio: 'inherit',
                              transform: `scale(${width / MIN_CHART_WIDTH})`,
                              transformOrigin: 'top left',
                            }
                      }
                    >
                      <DCPlotV2ErrorBoundary
                        dcSpec={msgContext.chart.data}
                        isLoading={isLoading}
                        objectId={objectId}
                      >
                        <DCPlotV2
                          width={width}
                          height={height}
                          objectId={objectId}
                          externalName={externalName}
                          dcSpec={msgContext.chart.data}
                          caption=""
                          setCaption={() => {}}
                          chartEditingMode={false}
                          openCaptionAlert={() => {}}
                          showModalPng={false}
                          updateModalPng={() => {}}
                          keepChanges={false}
                          insightsBoardId={undefined}
                          isPanMode={false}
                          isPopOutChart={false}
                          isFatTable={false}
                          responsive
                        />
                      </DCPlotV2ErrorBoundary>
                    </div>
                  )
                )
              }
            </SessionData>
          ) : (
            <Skeleton variant="rounded" width="100%" height="100%" />
          )}
        </div>
      )}
    </ReactResizeDetector>
  );
};

ChartMessage.propTypes = {
  externalName: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  message: PropTypes.object.isRequired,
  objectId: PropTypes.string.isRequired,
  inView: PropTypes.bool,
  update: PropTypes.object,
  parentObjectId: PropTypes.string,
};

ChartMessage.defaultProps = {
  externalName: '',
  inView: true,
  update: {},
  parentObjectId: undefined,
};

export default ChartMessage;
