import React from 'react';
import ReactMarkdown from 'react-markdown';
import { connect } from 'react-redux';
import remarkGfm from 'remark-gfm';
import { unified } from 'unified';

import rehypeParse from 'rehype-parse';
import rehypeSanitize from 'rehype-sanitize';
import rehypeStringify from 'rehype-stringify';

import { editAnnotationRequest } from '../../../store/actions/insights_board.actions';
import '../../../styles/Annotation.scss';

type Props = {
  data: Object,
};
/**
 * A container that describes the user's thoughts or observations.
 */
class Annotation extends React.PureComponent<Props> {
  constructor(props) {
    super(props);
    this.state = {
      text: null,
    };
  }

  componentDidMount = () => {
    this.sanitize(this.props.data.text).then((result) =>
      this.setState({
        text: result,
      }),
    );
  };

  componentDidUpdate = (prevProps) => {
    // Update contents of the note after refresh when the user edits that particular note in the workflow editor
    if (prevProps.data.text !== this.props.data.text) {
      this.sanitize(this.props.data.text).then((result) => this.setState({ text: result }));
    }
  };

  // Sanitize against XSS attacks
  sanitize = async (text: String) => {
    const result = await unified()
      .use(rehypeParse, { fragment: true })
      .use(remarkGfm)
      .use(rehypeSanitize)
      .use(rehypeStringify)
      .process(text.replace(/&nbsp;/gi, ' '));
    return result.value;
  };

  render() {
    const { data } = this.props;
    const { text } = this.state;

    return (
      <div style={{ backgroundColor: data.choice }} className="Annotation">
        <ReactMarkdown className="annotation-content" remarkPlugins={[remarkGfm]}>
          {text?.replace(/\n/gi, '&nbsp; \n')}
        </ReactMarkdown>
      </div>
    );
  }
}

export default connect(null, {
  editAnnotationRequest,
})(Annotation);
