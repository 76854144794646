import { getValueFormatter } from '../../../../utils/formatting/column_formatting';
import {
  CELL_TYPES,
  COLUMN_HEADER_WIDTH_ADJUSTMENT,
  DEFAULT_TABLE_EXPANSION_LIMIT,
  MARGIN_WIDTH,
  MAX_CELL_LIMIT,
  MAX_COLUMN_WIDTH,
  PAGE_SIZES,
  PAGINATION_WIDTH,
} from './constants';

/**
 * Calculates the length of the text when inside a given div
 *
 * @param {string} text - The text to measure the width of
 * @param {HTML div object} containerDiv - A div the styling for the text
 * @return {number} The width of the text when inserted inside containerDiv
 */
const determineTextWidth = (text, containerDiv) => {
  // Insert the text into the container div
  const invisibleCell = document.createElement('div');
  invisibleCell.innerHTML = text;
  // Fit the div to the size of it's contents
  invisibleCell.style = 'width: max-content; max-width: none; min-width: none;';
  containerDiv.append(invisibleCell);

  // Calculate the width of our temporary cell
  const width = Math.ceil(invisibleCell.clientWidth + 1);

  // Remove the cell from the DOM
  invisibleCell.remove();

  return Math.ceil(width);
};

/**
 * Determines the max width for each column for a given subset of rows provided.
 *
 * @param {array[object]} rows - A list of rows in the dataset. Each object in the list should
 *                               contain key, value pairs with column_name as the key and the cell
 *                               value as the value
 * @param {array[object]} cols - A list of columns in the dataset. Each object should have a name
 *                               key with a value of the column name.
 * @param {object{lowerBound: int, upperBound: int}} rowBounds - the range of rows to calculate
 * @param {object} valueFormatterMap - A list of columns and their specified value formatter type
 *                               i.e. {col1: 'scientific'}
 */
export const determineMaxWidths = (
  rows,
  cols,
  rowBounds,
  valueFormatterMap,
  customWidthAdjustment,
) => {
  // Create temporary container to apply the correct styling to insert our text into
  const invisibleContainer = document.createElement('div');
  invisibleContainer.style =
    'visibility: invisible; position: absolute; font-size: 15px; top: 0; left: 0; font-family: "Source Sans Pro",sans-serif;';
  document.body.append(invisibleContainer);

  // Dictionary to store widths for each column. Memory complexity O(numCols)
  const maxWidths = {};

  const displayedRows = rows.slice(rowBounds.lowerBound, rowBounds.upperBound);

  // Calculate width for each cell shown: Time complexity O(numCols * numRowsShown(max of 50))
  for (let i = 0; i < cols.length; i++) {
    const colName = cols[i].name;
    const colType = cols[i].type;
    maxWidths[colName] =
      determineTextWidth(colName, invisibleContainer) +
      (customWidthAdjustment ?? COLUMN_HEADER_WIDTH_ADJUSTMENT);

    // grab the appropriate value formatter
    const columnValueFormatter = getValueFormatter(
      cols[i],
      valueFormatterMap[colName] ? valueFormatterMap[colName] : 'none',
      valueFormatterMap[colName]?.includes('scientific'),
    );

    // Calculate the max width for the current column
    for (let j = 0; j < displayedRows.length; j++) {
      // Calculate max width
      const cellWidth =
        determineTextWidth(
          columnValueFormatter
            ? columnValueFormatter({ value: displayedRows[j][colName], type: colType })
            : displayedRows[j][colName],
          invisibleContainer,
        ) + MARGIN_WIDTH;
      const newMaxWidth = maxWidths[colName] < cellWidth ? cellWidth : maxWidths[colName];
      if (newMaxWidth >= MAX_COLUMN_WIDTH) {
        // If max width exceeded, continue to next column
        maxWidths[colName] = MAX_COLUMN_WIDTH;
        break;
      } else {
        maxWidths[colName] = newMaxWidth;
      }
    }
  }
  invisibleContainer.remove(invisibleContainer);

  return maxWidths;
};

/**
 * calculates the total width of a table
 *
 * @param {object{name: string, width: number}} columnWidths - list of column names and widths
 * @param {array[string]} hiddenCols - a list of columns that are hidden in the table
 * @param {boolean} hasPagination - whether or not the table has pagination buttons
 * @return the total width of the table
 */
export const calculateTotalWidth = (columnWidths, hiddenCols, hasPagination = false) => {
  // filters out hidden column and then sums the remaining column widths
  const newTotalWidth = Object.entries(columnWidths)
    .filter(([columnName]) => !hiddenCols.includes(columnName))
    .reduce((acc, [, columnWidth]) => acc + Number(columnWidth), 2);
  // Account for min width of the pagination options
  return Math.max(newTotalWidth, hasPagination ? PAGINATION_WIDTH : 0);
};

/**
 * Create a list of page size options from the constant PAGE_SIZES
 *
 * @param {number} pageSize - the number of rows shown on the page
 * @param {number} numRows - the number of rows available in the dataset
 * @return {array[number]} - (truncated) list of page size options
 */
export const calculatePageSizeOptions = (pageSize, numRows) => {
  // truncates PAGE_SIZES if pageSize is within its bounds
  return pageSize < DEFAULT_TABLE_EXPANSION_LIMIT
    ? [pageSize]
    : numRows < PAGE_SIZES[PAGE_SIZES.length - 1]
    ? [...PAGE_SIZES.filter((sizeOption) => sizeOption < numRows), numRows]
    : PAGE_SIZES;
};

/**
 * Creates list of hidden columns from MUIs column visibility model
 *
 * @param {object} model - each key:value pair is columnName:boolean false if hidden, true if shown
 * @return {array[string]} - list of hidden columns
 *  */
export const getHiddenColsFromModel = (model) => {
  return Object.entries(model)
    .filter(([, shown]) => !shown)
    .map(([column]) => column);
};

/**
 * Determines what the contents of the cell represent
 *
 * @param smartParseObj - return value of web/web_app/src/utils/string.js::smartParseObject
 * @returns the type of cell
 */
export const getCellType = (smartParseObj) => {
  // Checks if the cell is a table (empty or filled)
  if (
    typeof smartParseObj === 'string' &&
    smartParseObj.includes('nested-table') &&
    smartParseObj.endsWith('</table>')
  ) {
    return smartParseObj.includes('</td>') ? CELL_TYPES.TABLE : CELL_TYPES.NULL;
  }
  // Checks if the cell is regular text or linked text
  if (!(smartParseObj instanceof Object)) {
    const isLink =
      smartParseObj.search(
        /dc-skill-link|dc-tabchart-preview-link|dc-modify-skill-link|dc-redirect-link|<strong|<a|<sup|<table/gm,
      ) >= 0;
    return isLink ? CELL_TYPES.LINK : CELL_TYPES.PLAIN_TEXT;
  }
  // Checks if the cell is
  if (smartParseObj instanceof Object) {
    return CELL_TYPES.JSON;
  }
  // Default - null
  return CELL_TYPES.NULL;
};

/**
 * Either return the previously calculated expanded widths or calculate the expanded widths
 * based on a random sample of the data.
 *
 * @param {list} params.rows - list of row information
 * @param {list} params.cols - list of column information, name and type
 * @param {object} params.valueFormatterMap - dictionary of column name to value formatter
 * @param {object} params.prevExpandedWidths - dictionary of column name to width
 * @return {object} - dictionary of column name to width
 */
export const handleExceededCellLimit = ({
  rows,
  cols,
  rowBounds,
  valueFormatterMap,
  prevExpandedWidths,
}) => {
  // return the previously calculated expanded widths
  const prevExpandedWidthsIsEmpty =
    !prevExpandedWidths || Object.keys(prevExpandedWidths).length === 0;

  if (prevExpandedWidthsIsEmpty) {
    const lowerBound = rowBounds.lowerBound || 0;
    const upperBound = rowBounds.upperBound || rows.length;
    // Create a random sample of data by picking random rows in the rowBounds range
    const sample = Array.from(
      { length: Math.floor(MAX_CELL_LIMIT / cols.length) },
      () => rows[Math.floor(lowerBound + Math.random() * (upperBound - lowerBound))],
    );
    const bounds = { lowerBound: 0, upperBound: sample.length };
    // Calculate max widths based on sample
    return determineMaxWidths(sample, cols, bounds, valueFormatterMap);
  }

  return prevExpandedWidths;
};

/**
 * remove rows from currBounds that are in prevBounds
 * @param {object} currBounds - the current bounds of the table
 * @param {object} prevBounds - the previous bounds of the table
 * @return {object} - the truncated bounds
 */
export const getTruncatedBounds = (currBounds, prevBounds) => {
  const truncatedBounds = { ...currBounds };
  if (
    prevBounds.lowerBound === currBounds.lowerBound &&
    prevBounds.upperBound < currBounds.upperBound
  ) {
    truncatedBounds.lowerBound = Math.max(currBounds.lowerBound, prevBounds.upperBound);
  } else if (
    prevBounds.upperBound === currBounds.upperBound &&
    prevBounds.lowerBound > currBounds.lowerBound
  ) {
    truncatedBounds.upperBound = Math.min(currBounds.upperBound, prevBounds.lowerBound);
  }
  return truncatedBounds;
};

/**
 *
 * @param {list[object]} listOfWidths - list of dictionaries of column name to width
 * @returns {object} - dictionary of column name to width
 */
export const combineCalculatedWidths = (listOfWidths) => {
  // Base Case: no input
  if (!listOfWidths?.length || listOfWidths.length === 0) {
    return [];
  }
  // Join inputs
  const widths = listOfWidths[0];
  for (let i = 1; i < listOfWidths.length; i++) {
    for (const [key, value] of Object.entries(listOfWidths[i])) {
      widths[key] = Math.max(widths[key] || 0, value);
    }
  }
  return widths;
};

/**
 * Compare two value formatter maps and return the keys that have been updated
 *
 * @param {object} map1 - dictionary of column name to value formatter
 * @param {object} map2 - dictionary of column name to value formatter
 * @returns {object} - dictionary of column name to value formatter
 */
export const getUpdatedValueFormatters = (map1, map2) => {
  return Object.keys(map1).reduce(
    (acc, key) => (map1[key] !== map2[key] ? { ...acc, [key]: map1[key] } : acc),
    {},
  );
};
