/** Possible claims to prove access to a dataset. */
export type DatasetClaim =
  | SessionDatasetClaim
  | WorkspaceDatasetClaim
  | InsightsBoardDatasetClaim
  | PublicationDatasetClaim;

/** Claim to prove access to a dataset through a session. */
export class SessionDatasetClaim {
  kind = 'session';

  sessionId: string;

  constructor(sessionId: string) {
    this.sessionId = sessionId;
  }
}

/** Claim to prove access to a dataset through a workspace dataset. */
export class WorkspaceDatasetClaim {
  kind = 'workspace';

  workspaceUuid: string;

  constructor(workspaceUuid: string) {
    this.workspaceUuid = workspaceUuid;
  }
}

/** Claim to prove access to a dataset through an insights board. */
export class InsightsBoardDatasetClaim {
  kind = 'insightsBoard';

  insightsBoardId: string;

  constructor(insightsBoardId: string) {
    this.insightsBoardId = insightsBoardId;
  }
}

/** Claim to prove access to a dataset through a publication. */
export class PublicationDatasetClaim {
  kind = 'publication';

  publicationId: string;

  constructor(publicationId: string) {
    this.publicationId = publicationId;
  }
}
