import { AxiosError } from 'axios';
import { put } from 'typed-redux-saga';
import { addToast } from '../../store/actions/toast.actions';
import { TOAST_ERROR, TOAST_LONG, TOAST_BOTTOM_RIGHT } from '../../constants/toast';
import { AVA_ERROR_MESSAGES, AVA_TOAST_ERRORS } from '../../constants/dataAssistant';

export class HttpError extends Error {
  readonly status?: number;

  constructor(message: string, status?: number) {
    super(message);
    this.status = status;
    this.name = status && status < 500 ? 'HttpClientError' : 'HttpServerError';

    // Required for proper instanceof checks in ES5
    Object.setPrototypeOf(this, HttpError.prototype);
  }
}

const DEFAULT_ERROR_MESSAGES: Record<number, string> = {
  400: AVA_ERROR_MESSAGES.BAD_REQUEST,
  401: AVA_ERROR_MESSAGES.UNAUTHORIZED,
  403: AVA_ERROR_MESSAGES.FORBIDDEN,
  404: AVA_ERROR_MESSAGES.NOT_FOUND,
  429: AVA_ERROR_MESSAGES.TOO_MANY_REQUESTS,
};

/**
 * Handles HTTP errors from API calls
 * @param error - The error caught from an API call
 * @param customErrorMessages - Optional map of status codes to custom error messages
 * @param showToast - Whether to show a toast notification (default true)
 * @throws HttpError with appropriate message
 */
export function* handleHttpError(
  error: unknown,
  customErrorMessages?: Partial<Record<number, string>>,
  showToast = true,
): Generator {
  let errorMessage: string;
  let toastMessage: string = AVA_TOAST_ERRORS.SUBMIT_QUESTION; // Default toast message
  let status: number | undefined;

  if (error instanceof AxiosError && error.response) {
    status = error.response.status;

    // Get error message for the thrown error
    errorMessage =
      customErrorMessages?.[status] ??
      error.response.data?.message ??
      DEFAULT_ERROR_MESSAGES[status] ??
      (status < 500
        ? AVA_ERROR_MESSAGES.CLIENT_ERROR
        : 'An unexpected error occurred. Please try again later.');

    // For 4xx errors, use the specific error message in the toast
    if (status >= 400 && status < 500) {
      toastMessage = errorMessage;
    }
  } else {
    // Handle non-HTTP errors
    errorMessage =
      error instanceof Error
        ? error.message
        : 'An unexpected error occurred. Please try again later.';
  }

  // Show toast if enabled
  if (showToast) {
    yield* put(
      addToast({
        toastType: TOAST_ERROR,
        length: TOAST_LONG,
        message: toastMessage,
        position: TOAST_BOTTOM_RIGHT,
      }),
    );
  }

  throw new HttpError(errorMessage, status);
}
