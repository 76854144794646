/**
 * @fileoverview Contains typing for home_screen constants
 * This file is intended to help us slowly migrate ./home_screen.js to TypeScript
 */
import { Action, Store } from 'redux'; // Import Store type from Redux
import UtterancesPreviewer from '../../components/UtterancesPreviewer';
import { RootState } from '../../configureStore';
import { EDITABLE_OBJECTS } from '../../constants/editor';
import { HOME_ACTION_IDS, HOME_OBJECTS, REQUEST_STATUS } from '../../constants/home_screen';
import { WORKSPACE_VISIBILITY } from '../../constants/workspace';
import { openConnectionEditorRequest } from '../../store/actions/connection.actions';
import { closeDialog, openAlertDialogV1 } from '../../store/actions/dialog.actions';
import { navigateToWorkflowEditor } from '../../store/actions/editor.actions';
import {
  bulkLoadDatasetRequest,
  createNewObjectRequest,
  deleteObjectsRequest,
  objectOpenRequest,
  objectRenameRequest,
} from '../../store/actions/home_screen.actions';
import { editSnapshotRequest, refreshSnapshotRequest } from '../../store/actions/snapshot.actions';
import { changeAccessRequest, openMoveMenu } from '../../store/actions/workspacev2.actions';
import { selectObjectDeleteStatus, selectUserID } from '../../store/sagas/selectors';
import { openAccessDialogRequest } from '../../store/slices/accessDialog.slice';
import { getOpenActionToolTip, getRowType, openDialog } from '../home_screen';
import { canModify } from '../workspace';

import { CATALOG_BETA_CHIP_TEXT, open } from '../../store/slices/catalog.slice';
import { openDatabaseBrowser } from '../../store/slices/dbBrowser.slice';
import {
  HomeObjectAction,
  HomeObjectActionResult,
  HomeObjectKeys,
  HomeObjectKeysTypes,
  HomeObjects,
} from './types';
import { SAVE_AS_CONNECTION } from '../../constants/dialog.constants';

// Define the type of the store, possibly including undefined if it's not immediately initialized
let store: Store<RootState, Action<string>> | undefined;

// Function to inject the store, with the parameter typed correctly
export const injectStoreToHomeScreenConstantsTypeScript = (
  _store: Store<RootState, Action<string>>,
): void => {
  store = _store;
};
const dispatch = (action: (...args: any[]) => any, actionArgs: any): void => {
  if (store !== undefined) store.dispatch(action(actionArgs));
};

const getState = () => {
  if (store !== undefined) return store.getState();
  return {};
};

/**
 *
 * Actions by name.  All actions here should be a function returning an object.
 * This object should match the material-table spec: https://material-table.com/#/docs/features/actions.
 * Plus, a key property to differentiate the actions.
 * All actions should accept null as a parameter, to display an
 * action's icon without need of specific data.
 */
export const HomeObjectActions: { [actionKey: string]: HomeObjectAction } = {
  OPEN_ACTION: (rows: HomeObjectKeysTypes[]) => {
    const row = rows[0];
    const { [HomeObjectKeys.TYPE]: type, [HomeObjectKeys.ID]: objectId } = row;
    const action: HomeObjectActionResult = {
      key: HOME_ACTION_IDS.OPEN,
      tooltip: getOpenActionToolTip(type),
      onClick: (event: MouseEvent) => {
        event.stopPropagation();
        if (store !== undefined) store.dispatch(objectOpenRequest({ object: row }));
      },
      disabled: rows.length > 1,
    };
    // Override action properties by type
    switch (type) {
      case HOME_OBJECTS.SESSION:
        // Note that iconProps are ignored for HomeScreenActions,
        // But not for MaterialTable
        action.iconProps = {
          // UtterancesPreviewer will replace MUI component, and handle showing a preview
          component: UtterancesPreviewer,
          sessionId: objectId,
        };
        break;
      case HOME_OBJECTS.RECIPE:
        action.tooltip = 'Replay Workflow';
        action.iconProps = {
          component: UtterancesPreviewer,
          workflowId: objectId,
        };
        break;
      default:
        break;
    }
    return action;
  },
  OPEN_DATAGRID_ACTION: (rows: HomeObjectKeysTypes[]) => {
    const row = rows[0];
    const action = {
      key: HOME_ACTION_IDS.OPEN_DATACHAT,
      tooltip: 'Open DataGrid Session',
      onClick: (event: MouseEvent) => {
        event.stopPropagation();
        dispatch(objectOpenRequest, {
          object: row,
        });
      },
      disabled: rows.length > 1,
    };
    return action;
  },
  RENAME_ACTION: (rows: HomeObjectKeysTypes[]) => {
    const row = rows[0];
    const type = getRowType(row);
    const objectName = row?.[HomeObjectKeys.NAME] || '';
    const action = {
      key: HOME_ACTION_IDS.RENAME,
      tooltip: `Rename ${type}`,
      onClick: (event: MouseEvent) => {
        event.stopPropagation();
        openDialog({
          placeholder: `Enter a new name for the ${type}`,
          objectName,
          closeEvent: () => {
            if (store !== undefined) store.dispatch(closeDialog());
          },
          title: `Rename ${type}`,
          clickEvent: () => {
            const state = store !== undefined ? store.getState() : undefined;
            if (row) {
              dispatch(objectRenameRequest, {
                object: row,
                newName: state?.homeScreen?.dialogValue,
              });
            }
          },
        });
      },
      disabled: row[HomeObjectKeys.IS_SHARED] || rows.length !== 1,
    };

    return action;
  },
  DELETE_ACTION: (rows: HomeObjectKeysTypes[]) => {
    const state = getState();
    const type = rows.length === 1 ? getRowType(rows[0]) : 'objects';
    const objectDeleteStatus = selectObjectDeleteStatus(state);
    const isDeleting = rows.some(
      (row) => objectDeleteStatus[row.objectType] === REQUEST_STATUS.REQUESTED,
    );
    const containsShared = rows.some((row) => row[HomeObjectKeys.IS_SHARED]);
    const action = {
      key: HOME_ACTION_IDS.DELETE,
      tooltip: `Delete ${type}`,
      onClick: (event: MouseEvent) => {
        event.stopPropagation();
        dispatch(deleteObjectsRequest, { objects: rows });
      },
      disabled: containsShared || isDeleting,
    };

    return action;
  },
  OPEN_CATALOG_ACTION: (rows: HomeObjectKeysTypes[]) => {
    const state = getState();
    const objectDeleteStatus = selectObjectDeleteStatus(state);
    const isDeleting = rows.some(
      (row) => objectDeleteStatus[row.objectType] === REQUEST_STATUS.REQUESTED,
    );
    return {
      key: HOME_ACTION_IDS.OPEN_CATALOG,
      disabled: isDeleting || rows.length > 1,
      onClick: () => dispatch(open, rows[0][HomeObjectKeys.OBJECT_FK]),
      tooltip: 'Add Definitions',
      betaText: CATALOG_BETA_CHIP_TEXT,
    };
  },
  HIDE_ACTION: (rows: HomeObjectKeysTypes[]) => {
    const state = getState();
    const userId = selectUserID(state);
    const type = rows.length === 1 ? getRowType(rows[0]) : 'object';
    const someHidden = rows.some(
      (row) => row[HomeObjectKeys.VISIBILITY] === WORKSPACE_VISIBILITY.HIDDEN,
    );
    const someVisible = rows.some(
      (row) => row[HomeObjectKeys.VISIBILITY] === WORKSPACE_VISIBILITY.VISIBLE,
    );
    const hasIndependentAccessToAll = rows.some(
      (row) => !row[HomeObjectKeys.HAS_INDEPENDENT_ACCESS],
    );

    let tooltip = '';
    if (rows.length === 1) {
      // Single Object
      tooltip = someHidden ? `Unhide ${type}` : `Hide ${type}`;
    } else if (someVisible) {
      // Multiple Objects, some or all visible
      tooltip = `Hide ${type}s`;
    } else {
      // Multiple Objects, all hidden
      tooltip = `Unhide ${type}s`;
    }

    const action: HomeObjectActionResult = {
      key: HOME_ACTION_IDS.HIDE,
      tooltip,
      onClick: (event: MouseEvent) => {
        event.stopPropagation();
        const allHidden = rows.every(
          (row) => row[HomeObjectKeys.VISIBILITY] === WORKSPACE_VISIBILITY.HIDDEN,
        );
        rows.forEach((row) => {
          const payload = {
            [userId]: {
              type: row[HomeObjectKeys.ACCESS_TYPE],
              // Change visibility to hidden if all are visible, visible if all are hidden
              visibility: allHidden ? WORKSPACE_VISIBILITY.VISIBLE : WORKSPACE_VISIBILITY.HIDDEN,
              change_visibility_only: true,
            },
          };
          const uuid = row[HomeObjectKeys.UUID];
          const objectType = row[HomeObjectKeys.TYPE];
          dispatch(changeAccessRequest, { uuid, payload, objectType });
        });
      },
      disabled: hasIndependentAccessToAll, // Disable if the object is shared with organization
    };

    return action;
  },
  SAVE_AS: (rows: HomeObjectKeysTypes[]) => {
    const row = rows[0];
    return {
      key: HOME_ACTION_IDS.SAVE_AS,
      disabled: row[HomeObjectKeys.IS_SHARED] || rows.length > 1,
      onClick: () => {
        if (row[HomeObjectKeys.TYPE] === HomeObjects.CONNECTION) {
          dispatch(openAlertDialogV1, {
            dialogType: SAVE_AS_CONNECTION,
            dialogParams: {
              connectionObject: row,
            },
          });
        } else {
          openDialog({
            title: 'Save As',
            placeholder: `Enter a new name for the ${row[HomeObjectKeys.TYPE]}`,
            clickEvent: () => {
              const state = getState() as { homeScreen: { dialogValue: string } };
              const dialogValue = state?.homeScreen?.dialogValue;
              dispatch(createNewObjectRequest, {
                objectType: row.objectType,
                args: {
                  name: dialogValue,
                  overwrite: false,
                  objectId: row[HomeObjectKeys.ID],
                },
              });
              dispatch(closeDialog, {});
            },
            objectName: row[HomeObjectKeys.NAME],
            closeEvent: () => {
              dispatch(closeDialog, {});
            },
          });
        }
      },
      tooltip: 'Save As',
    };
  },
  SHARE: (rows: HomeObjectKeysTypes[]) => {
    const row = rows[0];
    const accessType = row[HomeObjectKeys.ACCESS_TYPE];
    const isSession = row[HomeObjectKeys.TYPE] === HomeObjects.SESSION;
    return {
      key: HOME_ACTION_IDS.SHARE,
      disabled: (!isSession && !canModify(accessType)) || rows.length > 1,
      onClick: () => {
        dispatch(openAccessDialogRequest, { uuid: row[HomeObjectKeys.UUID], data: row });
      },
      tooltip: 'Share',
    };
  },
  MOVE: (rows: HomeObjectKeysTypes[]) => {
    const canModifyAll = rows.every((row) => canModify(row[HomeObjectKeys.ACCESS_TYPE]));

    return {
      key: HOME_ACTION_IDS.MOVE,
      disabled: !canModifyAll,
      onClick: () => {
        dispatch(openMoveMenu, { objects: rows });
      },
      tooltip: 'Move',
    };
  },
  EDIT_WORKFLOW: (rows: HomeObjectKeysTypes[]) => {
    const row = rows[0];
    return {
      key: HOME_ACTION_IDS.EDIT,
      onClick: (event: MouseEvent) => {
        event.stopPropagation();
        dispatch(navigateToWorkflowEditor, {
          objectType: EDITABLE_OBJECTS.RECIPE,
          objectId: row[HomeObjectKeys.ID],
        });
      },
      tooltip: `Edit ${getRowType(row)}`,
      disabled: rows.length > 1,
    };
  },
  EDIT_SNAPSHOT: (rows: HomeObjectKeysTypes[]) => ({
    key: HOME_ACTION_IDS.EDIT,
    onClick: (event: MouseEvent) => {
      event.stopPropagation();
      dispatch(editSnapshotRequest, { id: rows[0][HomeObjectKeys.ID] });
    },
    tooltip: `Edit ${getRowType(rows[0])}`,
    disabled: !canModify(rows[0][HomeObjectKeys.ACCESS_TYPE]) || rows.length > 1,
  }),
  EDIT_CONNECTION: (rows: HomeObjectKeysTypes[]) => ({
    key: HOME_ACTION_IDS.EDIT,
    onClick: (event: MouseEvent) => {
      event.stopPropagation();
      dispatch(openConnectionEditorRequest, {
        uuid: rows[0][HomeObjectKeys.UUID],
        object: rows[0],
      });
      dispatch(openDatabaseBrowser, { connection: rows[0] });
    },
    disabled: !canModify(rows[0][HomeObjectKeys.ACCESS_TYPE]) || rows.length > 1,
    tooltip: `Edit ${getRowType(rows[0])}`,
  }),
  EDIT_DATASET_SOURCE: (rows: HomeObjectKeysTypes[]) => ({
    key: HOME_ACTION_IDS.EDIT,
    onClick: (event: MouseEvent) => {
      event.stopPropagation();
      // Get the correct uuid param
      const objectId = rows[0][HomeObjectKeys.UUID];
      dispatch(navigateToWorkflowEditor, {
        objectType: EDITABLE_OBJECTS.DATASET_OBJECT,
        objectId,
      });
    },
    // For now only workflow source can be edited
    disabled:
      !rows[0].WorkflowFileFk || !canModify(rows[0][HomeObjectKeys.ACCESS_TYPE]) || rows.length > 1,
    tooltip: `Edit ${getRowType(rows[0])}`,
  }),
  REFRESH_SNAPSHOT: (rows: HomeObjectKeysTypes[]) => ({
    key: HOME_ACTION_IDS.REFRESH,
    onClick: (event: MouseEvent) => {
      event.stopPropagation();
      dispatch(refreshSnapshotRequest, {
        object: rows[0],
      });
    },
    tooltip: `Refresh ${getRowType(rows[0])}`,
    disabled: rows.length > 1,
  }),
  LOAD_FOLDERS_AND_DATASETS: (rows: HomeObjectKeysTypes[]) => {
    const loadableObjectTypes = new Set([HomeObjects.FOLDER, HomeObjects.DATASET]);
    const objectTypes = rows.map((row) => row[HomeObjectKeys.TYPE]);

    // only HomeObjects.FOLDER and HomeObjects.Dataset are allow in objectTypes.
    // disable the action if any other object type is present.
    const isDisabled = objectTypes.some((type) => !loadableObjectTypes.has(type));

    let tooltip = 'Load Object';
    if (objectTypes.length === 1 && objectTypes[0] === HomeObjects.FOLDER) {
      tooltip = 'Load Folder';
    } else if (objectTypes.length === 1 && objectTypes[0] === HomeObjects.DATASET) {
      tooltip = 'Load Dataset';
    }

    if (rows.length > 1) tooltip = `${tooltip}s`;

    return {
      key: HOME_ACTION_IDS.LOAD_FOLDERS_AND_DATASETS,
      onClick: (event: MouseEvent) => {
        event.stopPropagation();
        // Get folders
        const folders = rows.filter((object) => object[HomeObjectKeys.TYPE] === HomeObjects.FOLDER);
        // Get datasets
        const datasets = rows.filter(
          (object) => object[HomeObjectKeys.TYPE] === HomeObjects.DATASET,
        );
        dispatch(bulkLoadDatasetRequest, { folders, datasets });
      },
      tooltip,
      disabled: isDisabled,
    };
  },
};

// list of object actions in order of most importance, for each object type.
export const ObjectActionsByType: { [homeObjectType: string]: HomeObjectAction[] } = {
  [HomeObjects.SESSION]: [
    HomeObjectActions.OPEN_ACTION,
    HomeObjectActions.SHARE,
    HomeObjectActions.RENAME_ACTION,
    HomeObjectActions.DELETE_ACTION,
    HomeObjectActions.OPEN_DATAGRID_ACTION,
  ],
  [HomeObjects.RECIPE]: [
    HomeObjectActions.OPEN_ACTION,
    HomeObjectActions.EDIT_WORKFLOW,
    HomeObjectActions.SHARE,
    HomeObjectActions.RENAME_ACTION,
    HomeObjectActions.DELETE_ACTION,
    HomeObjectActions.SAVE_AS,
    HomeObjectActions.HIDE_ACTION,
    HomeObjectActions.MOVE,
  ],
  [HomeObjects.INSIGHTS_BOARD]: [
    HomeObjectActions.OPEN_ACTION,
    HomeObjectActions.SHARE,
    HomeObjectActions.RENAME_ACTION,
    HomeObjectActions.DELETE_ACTION,
    HomeObjectActions.HIDE_ACTION,
    HomeObjectActions.MOVE,
  ],
  [HomeObjects.SNAPSHOT]: [
    HomeObjectActions.OPEN_ACTION,
    HomeObjectActions.REFRESH_SNAPSHOT,
    HomeObjectActions.EDIT_SNAPSHOT,
    HomeObjectActions.SHARE,
    HomeObjectActions.DELETE_ACTION,
    HomeObjectActions.RENAME_ACTION,
    HomeObjectActions.HIDE_ACTION,
    HomeObjectActions.MOVE,
  ],
  [HomeObjects.CONNECTION]: [
    HomeObjectActions.OPEN_ACTION,
    HomeObjectActions.EDIT_CONNECTION,
    HomeObjectActions.SHARE,
    HomeObjectActions.RENAME_ACTION,
    HomeObjectActions.DELETE_ACTION,
    HomeObjectActions.SAVE_AS,
    HomeObjectActions.HIDE_ACTION,
    HomeObjectActions.MOVE,
  ],
  [HomeObjects.FOLDER]: [
    HomeObjectActions.OPEN_ACTION,
    HomeObjectActions.SHARE,
    HomeObjectActions.RENAME_ACTION,
    HomeObjectActions.DELETE_ACTION,
    HomeObjectActions.HIDE_ACTION,
    HomeObjectActions.MOVE,
    HomeObjectActions.LOAD_FOLDERS_AND_DATASETS,
  ],
  [HomeObjects.QUERY]: [
    HomeObjectActions.OPEN_ACTION,
    HomeObjectActions.SHARE,
    HomeObjectActions.RENAME_ACTION,
    HomeObjectActions.DELETE_ACTION,
    HomeObjectActions.HIDE_ACTION,
  ],
  [HomeObjects.DATAFILE]: [
    HomeObjectActions.OPEN_ACTION,
    HomeObjectActions.SHARE,
    HomeObjectActions.DELETE_ACTION,
    HomeObjectActions.HIDE_ACTION,
    HomeObjectActions.MOVE,
  ],
  [HomeObjects.DATASET]: [
    HomeObjectActions.OPEN_ACTION,
    HomeObjectActions.EDIT_DATASET_SOURCE,
    HomeObjectActions.SHARE,
    HomeObjectActions.RENAME_ACTION,
    HomeObjectActions.DELETE_ACTION,
    HomeObjectActions.HIDE_ACTION,
    HomeObjectActions.MOVE,
    HomeObjectActions.LOAD_FOLDERS_AND_DATASETS,
    HomeObjectActions.OPEN_CATALOG_ACTION,
  ],
};

/**
 * Actions that can be performed on multiple objects
 */
export const MultiObjectActions = [
  HomeObjectActions.LOAD_FOLDERS_AND_DATASETS,
  HomeObjectActions.MOVE,
  HomeObjectActions.DELETE_ACTION,
  HomeObjectActions.HIDE_ACTION,
];

/**
 * Actions that can be performed on a single object. Some actions branch for different object types.
 * the EDIT action is an example of this, there are different edit actions for workflows, datasets,
 * and databases. The branching is handled in getSingleObjectActions ()
 */
const NonBranchingSingleObjectActions = [
  HomeObjectActions.RENAME_ACTION,
  HomeObjectActions.SHARE,
  HomeObjectActions.SAVE_AS,
  HomeObjectActions.OPEN_CATALOG_ACTION,
];

/**
 * Gets the "single" object actions.
 *
 * Handles branching conflicting actions for different object types.
 *
 * This function takes multiple object types into account even though the result list of actions is
 * for a single object. This is because we allways display all actions as either enabled or disabled
 *
 * @param {string[]} objectTypes - List of object types
 * @returns {HomeObjectAction[]} - List of actions
 */
export const getSingleObjectActions = (objectTypes: string[]) => {
  let actions = NonBranchingSingleObjectActions;
  const dummyAction: HomeObjectAction = () => ({
    key: HOME_ACTION_IDS.EDIT,
    tooltip: 'Edit',
    disabled: true,
    onClick: () => {},
  });
  // Special handling for branching editing paths
  // if the object has no edit action, we use a dummy edit action
  if (objectTypes.length === 1) {
    // If we only have 1 object type, we use that edit action
    switch (objectTypes[0]) {
      case HomeObjects.RECIPE:
        actions = [HomeObjectActions.EDIT_WORKFLOW, ...actions];
        break;
      case HomeObjects.CONNECTION:
        actions = [HomeObjectActions.EDIT_CONNECTION, ...actions];
        break;
      case HomeObjects.DATASET:
        actions = [HomeObjectActions.EDIT_DATASET_SOURCE, ...actions];
        break;
      default:
        actions = [dummyAction, ...actions];
        break;
    }
  } else {
    // If we have multiple object types or the object has no edit action we use a dummy edit action
    actions = [dummyAction, ...actions];
  }

  // Remove duplicates
  return [...new Set(actions)];
};

/**
 * Get the valid actions for the given object types. This function filters out actions
 * that are not compatible with the given object types.
 * @param {string[]} objectTypes - List of object types
 * @returns {HomeObjectAction[]} - List of actions
 */
export const getValidActions = (objectTypes: string[]) => {
  let actions = [];

  // Get all actions for the given object types
  for (const objectType of objectTypes) {
    const objectActions = ObjectActionsByType[objectType];
    if (!objectActions) continue;
    actions.push(...objectActions);
  }

  // Remove non multi type actions if there are multiple object types
  if (objectTypes.length > 1) {
    actions = actions.filter((action) => MultiObjectActions.includes(action));
  }

  // Special case: Sessions are not compatible with the move operation
  if (objectTypes.includes(HomeObjects.SESSION)) {
    actions = actions.filter((action) => action !== HomeObjectActions.MOVE);
  }

  // Remove duplicates
  return [...new Set(actions)];
};
