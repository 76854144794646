/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Rnd } from 'react-rnd';
import { VISUAL_TYPES } from '../../constants/index';
import { NavigationTabs } from '../../constants/session';
import { TOAST_BOTTOM_RIGHT, TOAST_ERROR, TOAST_LONG } from '../../constants/toast';
import { createMessage } from '../../store/actions/messages.actions';
import { closePopOutModal } from '../../store/actions/popout.actions';
import { addToast } from '../../store/actions/toast.actions';
import { closeViewSlicedWorkflow } from '../../store/actions/viewSlicedWorkflow.action';
import { initialContext } from '../../store/reducers/messages.reducer';
import { selectChartById } from '../../store/selectors/chartspace.selector';
import { selectMessageByObjectId } from '../../store/selectors/nodes.selector';
import { selectCurrentSessionNavigationTab } from '../../store/selectors/session.selector';
import {
  selectIsSlicedWorkflowDataLoading,
  selectIsSlicedWorkflowPopoutOpen,
  selectPopoutIsOpen,
  selectPopoutSelectedChart,
  selectSlicedWorkflowData,
} from '../../store/typedSelectors';
import InsightsBoardData from '../ChartData/InsightsBoardData';
import SessionData from '../ChartData/SessionData';
import SessionVisual from '../DisplayPanel/Visuals/SessionVisual';
import TabVisual from '../DisplayPanel/Visuals/TabVisual';
import ErrorBoundary from '../common/ErrorBoundary/ErrorBoundary';
import './PopOutModal.scss';
import SlicedWorkflowPopOut from './SlicedWorkflowPopOut';

const PopOutModal = ({
  charts = [],
  insightsBoardId = null,
  isInsightsBoard = false,
  modifyChartCallback = () => {},
}) => {
  let rndRef = useRef();
  const dispatch = useDispatch();

  const [chartBuilderIsOpen, setChartBuilderIsOpen] = useState(false);

  // Popout reducer
  const isPopOutModalOpen = useSelector(selectPopoutIsOpen);
  const selectedObjectId = useSelector(selectPopoutSelectedChart);

  // ViewSlicedWorkflow reducer
  const isSlicedWorkflowDataLoading = useSelector(selectIsSlicedWorkflowDataLoading);
  const isSlicedWorkflowPopoutOpen = useSelector(selectIsSlicedWorkflowPopoutOpen);
  const slicedWorkflowData = useSelector(selectSlicedWorkflowData);

  // Session selector
  const currentSessionNavigationTab = useSelector(selectCurrentSessionNavigationTab);

  // Nodes & Chartspace selectors
  // Select the correct message from our nodes/chartspace that corresponds to the given selectedObjectId
  // (dc_chart_id, dc_dataset_id, publication_id)
  const rawNodeMessage = useSelector((state) => selectMessageByObjectId(state, selectedObjectId));
  const rawChartspaceMessage =
    useSelector((state) => selectChartById(state, selectedObjectId))?.chart_spec ?? {};

  /** Closes this component */
  const close = useCallback(() => {
    dispatch(closePopOutModal());
    dispatch(closeViewSlicedWorkflow());
  }, [dispatch]);

  const keyDownOnESC = useCallback(
    (e) => {
      if (e.key === 'Escape') {
        e.preventDefault();
        close();
      }
    },
    [close],
  );

  // Hide our popOutModal if we're editing it by opening the chart builder
  const handleChartBuilder = (openChartBuilder) => {
    // Reset position if opening the chart builder
    if (openChartBuilder) rndRef.updatePosition({ x: 0, y: 0 });
    setChartBuilderIsOpen(openChartBuilder);
  };

  useEffect(() => {
    window.addEventListener('keydown', keyDownOnESC);
    window.addEventListener('popstate', close); // Navigate back in history

    return () => {
      close();
      window.removeEventListener('keydown', keyDownOnESC);
      window.removeEventListener('popstate', close);
    };
  }, [close, keyDownOnESC]);

  let chartContext;
  let chart;
  let objId;
  let pipelinerDatasetId;
  /* If this pop up is used to show the sliced workflow. */
  if (isSlicedWorkflowPopoutOpen) {
    if (isSlicedWorkflowDataLoading) {
      chart = {
        externalName: 'SlicedRecipeDiagramPopoutLoading',
        data: {},
        type: 'viz',
        fromUser: true,
        isViewSlicedWorkflowPopout: true,
        id: 0,
      };
    }
    // When the data retrival fails
    if (!isSlicedWorkflowDataLoading && slicedWorkflowData) {
      chart = {
        externalName: 'SlicedRecipeDiagramPopout',
        data: slicedWorkflowData,
        type: 'viz',
        typeVersion: 2, // 2 for echarts
        fromUser: true,
        isViewSlicedWorkflowPopout: true,
        id: 0,
      };
    }
  }
  // If charts are explicitly passed from props, assume that the pop-up is displayed in an insights board
  // Such separate handling is required because the structure of the underlying chart object
  // is different for normal charts vs insights board publications
  else if (isPopOutModalOpen && charts && charts.length > 0) {
    // Find the correct chart to display in the pop up. Use explicitly passed plot list
    chart = charts.find((c) => c.publicationId === selectedObjectId);
    objId = chart.publicationId;
    pipelinerDatasetId = chart.pipelineId?.Valid ? chart.pipelineId?.String : null;
  } else if (isPopOutModalOpen && selectedObjectId) {
    // Our FE visualization pipeline expects a FE representation of messages. so we call the createMessage function to format
    // the message stored in the node. In the messages reducer, this is the "chart" key of the message Context
    chart =
      currentSessionNavigationTab === NavigationTabs.CHART_SPACE
        ? rawChartspaceMessage
        : createMessage(rawNodeMessage);

    // Create a dummy message context object to wrap the message
    chartContext = { ...initialContext, chart };
    objId = chartContext?.object_id;
  }

  // If we don't have a chartContext, chart, objId, or pipelinerDatasetId, then we failed to pop out the chart
  useEffect(() => {
    if (!chart || (!chart && !chartContext && !objId && !pipelinerDatasetId)) {
      close();
      dispatch(
        addToast({
          message: `Failed to pop out chart.`,
          toastType: TOAST_ERROR,
          position: TOAST_BOTTOM_RIGHT,
          length: TOAST_LONG,
        }),
      );
    }
  }, [chart, chartContext, close, dispatch, objId, pipelinerDatasetId]);

  // set the initial of Rnd based on the table type, if it is a dataset table in Ben, we need a specific number for data grid to render
  let initialHeight = '90vh';
  if (
    !isEmpty(chart) &&
    (chart.type === 'table' || chart.type === 'pivot table') &&
    !(chart.data?.name === chart.data?.title && chart.type === 'table')
  ) {
    initialHeight = 'auto';
  }

  const refreshMessage = chart?.updates?.Valid ? chart?.updates?.String : '';
  const backDropClassName = `Backdrop${chartBuilderIsOpen ? '-CB' : ''}`;
  return (
    !isEmpty(chart) && (
      <div className={backDropClassName}>
        <div
          data-testid="Chart-Popout-Container"
          className={`Chart-Popout-Container${chartBuilderIsOpen ? '-CB' : ''} ${
            (chart.type === 'table' || chart.type === 'pivot table') && 'table'
          }`}
        >
          <Rnd
            default={{
              x: 0,
              y: 0,
              height: `${initialHeight}`,
              width: 'min-content',
            }}
            style={{
              position: 'relative',
            }}
            ref={(c) => {
              rndRef = c;
            }}
            // To prevent the pop-up from being dragged out of view
            bounds=".Backdrop"
            dragHandleClassName="ChartHeader"
            enableResizing={{
              top: false,
              right: false,
              bottom: true,
              left: true,
              topRight: false,
              topLeft: true,
              bottomRight: true,
              bottomLeft: true,
            }}
            minWidth="200px"
            maxWidth="90vw"
            minHeight="115px"
            // react-rnd adds a 'user-select:none' attribute to the document body
            // to prevent ugly text selection during drag. Explicitly setting enableUserSelectHack
            // to false enables selection of components wrapped in <Rnd>.
            // Reference: https://github.com/bokuweb/react-rnd/issues/461
            enableUserSelectHack={false}
          >
            <div style={{ width: 'inherit', height: 'inherit', maxWidth: '90vw' }}>
              <Tooltip title="Close chart pop-up">
                <IconButton className="Close-Button" onClick={close} size="medium">
                  <CancelIcon />
                </IconButton>
              </Tooltip>
              <ErrorBoundary type="visual" msgContext={chartContext}>
                {isInsightsBoard ? (
                  <InsightsBoardData
                    key={objId} // rerender with new object
                    objectId={objId}
                    externalName={chart.externalName}
                    contextId={chart.id}
                    data={chart.data}
                    update={chart.update}
                    sender={chart.sender}
                    messageType={chart.type}
                    messageTypeVersion={chart.typeVersion}
                    linkTo={chart.toLinkId}
                    userMsgId={chart.userMsgId}
                    fromUser={chart.fromUser}
                    publicationId={chart.publicationId}
                    lastRefreshTime={chart.lastRefreshTime}
                    refreshMessage={refreshMessage}
                    fontSizes={chart.fontSizes}
                    workflowId={chart.workflowId}
                    isInsightsBoard={isInsightsBoard}
                    insightsBoardId={insightsBoardId}
                    chartFlex={false}
                    clickToScroll={false}
                    displayBorder={false}
                    isPopOutChart
                    showHeader
                    userCanModify
                    setEditingModal={handleChartBuilder}
                    modifyChartCallback={modifyChartCallback}
                    customization={chart.customization}
                    pipelinerDatasetId={pipelinerDatasetId}
                  />
                ) : isSlicedWorkflowPopoutOpen ? (
                  <SlicedWorkflowPopOut key={chart.id} contextId={chart.id} chart={chart} />
                ) : chartContext.chart.type === VISUAL_TYPES.TABVISUAL ? (
                  <TabVisual
                    key={chartContext.id}
                    tabVisualContext={chartContext}
                    autoScroll={false}
                    selectedCount={0}
                    isPopOutChart
                  />
                ) : (
                  <SessionData
                    key={chartContext.id}
                    message={chartContext}
                    objectId={chartContext.chart.objectId}
                    update={chartContext.chart.update}
                  >
                    {(
                      msgContext,
                      error,
                      isFailed,
                      isLoading,
                      rowCount,
                      updateRowCount,
                      forgotten,
                    ) => (
                      <SessionVisual
                        msgContext={msgContext}
                        autoScroll={false}
                        selectedCount={0}
                        setEditingModal={handleChartBuilder}
                        error={error}
                        forgotten={forgotten}
                        isFailed={isFailed}
                        isLoading={isLoading}
                        isPopOutChart
                        rowCount={rowCount}
                        updateRowCount={updateRowCount}
                      />
                    )}
                  </SessionData>
                )}
              </ErrorBoundary>
            </div>
          </Rnd>
        </div>
      </div>
    )
  );
};

PopOutModal.propTypes = {
  charts: PropTypes.array,
  insightsBoardId: PropTypes.string,
  isInsightsBoard: PropTypes.bool,
  modifyChartCallback: PropTypes.func,
};

export default PopOutModal;
