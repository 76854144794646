import { CONFLICT, FORBIDDEN } from 'http-status-codes';
import isEmpty from 'lodash/isEmpty';
import { all, call, getContext, put, select, take, takeEvery } from 'redux-saga/effects';
import {
  changeWorkspaceAccess,
  createWorkspace,
  deleteWorkspaceObject,
  getWorkspaceChildren,
  listOrganizationAccess,
  listWorkspaceAccessors,
  moveWorkspaceObject,
} from '../../api/workspacev2.api';
import { API_SERVICES } from '../../constants/api';
import {
  CANCEL_BUTTON_KEY,
  GENERAL_ERROR_MESSAGE,
  moveDcObjectToSharedFolderAlert,
} from '../../constants/dialog.constants';
import { HOME_OBJECTS, HOME_OBJECT_KEYS } from '../../constants/home_screen';
import { TOAST_ERROR, TOAST_LONG, TOAST_SUCCESS } from '../../constants/toast';
import { ROOT_FOLDER } from '../../constants/workspace';
import { convertDcObjectToHomeScreenObject, getFolderBreadcrumb } from '../../utils/home_screen';
import { HomeObjectKeys, HomeObjects } from '../../utils/homeScreen/types';
import { canModify, mapWorkpaceTypeToHomescreenType } from '../../utils/workspace';
import { closeDialog, createAlertChannelRequest } from '../actions/dialog.actions';
import {
  GET_HOME_SCREEN_OBJECTS_FAILURE,
  GET_HOME_SCREEN_OBJECTS_SUCCESS,
  getHomeScreenObjectsRequest,
  setSelectedRows,
} from '../actions/home_screen.actions';
import {
  GET_USERS_IN_ORGANIZATION_FAILURE,
  GET_USERS_IN_ORGANIZATION_SUCCESS,
  getUsersInOrganizationRequest,
} from '../actions/organization.action';
import { addToast } from '../actions/toast.actions';
import {
  CHANGE_ACCESS_FAILURE,
  CHANGE_ACCESS_REQUEST,
  CHANGE_ACCESS_SUCCESS,
  CREATE_WORKSPACE_OBJECT_REQUEST,
  MOVE_DC_OBJECT_REQUEST,
  SET_CURRENT_FOLDER,
  changeAccessFailure,
  changeAccessRequest,
  changeAccessSuccess,
  closeMoveMenu,
  createWorkspaceObjectFailure,
  createWorkspaceObjectSuccess,
  moveDcObjectFailure,
  moveDcObjectSuccess,
  setCurrentFolderBreadcrumb,
} from '../actions/workspacev2.actions';
import { selectAccessDialogAccessorsByUuid } from '../selectors/accessDialog.selector';
import {
  accessDialogSaveFailure,
  accessDialogSaveSuccess,
  closeAccessDialog,
  getAccessorsFailure,
  getAccessorsRequest,
  getAccessorsSuccess,
  getOrganizationAccessFailure,
  getOrganizationAccessRequest,
  getOrganizationAccessSuccess,
  openAccessDialogFailure,
  openAccessDialogSuccess,
} from '../slices/accessDialog.slice';
import { getOrganizationProfilePicturesRequest } from '../slices/profilePictures.slice';
import { raceGenerator } from './home_screen.saga';
import { getDefaultOrganizationDetails } from './organization.saga';
import {
  selectAccessToken,
  selectFolderStack,
  selectHomeScreenObjects,
  selectUsersInOrganization,
} from './selectors';
import {
  createAlertChannel,
  generalErrorWorker,
  objectAlreadyExistsAlert,
} from './utils/alert-channels';

/**
  Get the list of all shared users of the object
  @param {String} uuid // uuid of the object
*/
export function* getAccessorsWorkspaceRequestWorker({ payload }) {
  const { uuid } = payload;

  try {
    const accessToken = yield select(selectAccessToken);
    const response = yield call(listWorkspaceAccessors, accessToken, uuid);

    yield put(
      getAccessorsSuccess({
        uuid,
        accessors: response.data,
      }),
    );
  } catch (error) {
    yield put(getAccessorsFailure({ uuid, error }));
  }
}

/**
  Open Share Access Dialog Box
  @param {String} uuid // uuid of the object
  @param {String} data // Data of the object
*/
export function* openAccessDialogWorkspaceRequestWorker({ payload }) {
  const { uuid, data } = payload;

  try {
    const usersInOrganization = yield select(selectUsersInOrganization);
    const accessors = yield select(selectAccessDialogAccessorsByUuid, uuid);
    const { organizationId } = yield* getDefaultOrganizationDetails();

    // Get the list of accessors corresponding to the uuid
    if (!accessors || isEmpty(accessors)) {
      yield put(getAccessorsRequest({ data, uuid }));
      yield* raceGenerator(getAccessorsSuccess.type, getAccessorsFailure.type);
    }

    // Get the list of users in organization if not fetched
    if (!usersInOrganization || !usersInOrganization?.[organizationId]) {
      yield put(getUsersInOrganizationRequest());
      yield* raceGenerator(GET_USERS_IN_ORGANIZATION_SUCCESS, GET_USERS_IN_ORGANIZATION_FAILURE);

      // Get the user profile pictures for the organization
      yield put(getOrganizationProfilePicturesRequest());
    }

    // Get organization access data for the object
    yield put(getOrganizationAccessRequest({ uuid }));
    yield* raceGenerator(getOrganizationAccessSuccess.type, getOrganizationAccessFailure.type);

    yield put(openAccessDialogSuccess({ uuid, data }));
  } catch (error) {
    yield put(openAccessDialogFailure({ uuid, data, error }));
    yield put(
      addToast({
        toastType: TOAST_ERROR,
        length: TOAST_LONG,
        message: GENERAL_ERROR_MESSAGE,
      }),
    );
  }
}

/**
  Updates the workspaces access of the users
  @param {String} uuid // uuid of the object
  @param {Object} payload // Object with key as user and value as access type
  @param {String} objectType // Optional, if defined will fetch all HomescreenObjects of this type
*/
export function* changeAccessRequestWorker({ uuid, payload, objectType }) {
  try {
    const accessToken = yield select(selectAccessToken);
    yield call(changeWorkspaceAccess, accessToken, uuid, payload);
    if (objectType) yield put(getHomeScreenObjectsRequest({ objectType }));
    yield put(changeAccessSuccess({ uuid, payload }));
  } catch (error) {
    yield put(changeAccessFailure({ uuid, payload }));
  }
}

/**
   Worker to change the access permissions of the users of an object
    and refresh the home screen objects corresponding to the objectType
  @param {String} uuid uuid of an object
  @param {Object} payload -> {
    "<userId>": {
      "type" : <"Viewer or Editor">
      "visibility" : <"visible" or "hidden">
    }
  }
  @param {String} objectType Type of the object
*/
export function* accessDialogSaveWorkspaceRequestWorker({ payload }) {
  const { uuid, payload: reducerPayload, objectType } = payload;

  try {
    yield put(changeAccessRequest({ uuid, payload: reducerPayload }));
    yield* raceGenerator(CHANGE_ACCESS_SUCCESS, CHANGE_ACCESS_FAILURE);
    yield put(getHomeScreenObjectsRequest({ objectType }));
    yield* raceGenerator(GET_HOME_SCREEN_OBJECTS_SUCCESS, GET_HOME_SCREEN_OBJECTS_FAILURE);

    yield put(closeAccessDialog());
    yield put(accessDialogSaveSuccess({ uuid, payload: reducerPayload, objectType }));
    yield put(
      addToast({
        toastType: TOAST_SUCCESS,
        length: TOAST_LONG,
        message: 'Access Updated.',
      }),
    );
  } catch (error) {
    yield put(accessDialogSaveFailure({ uuid, payload: reducerPayload, objectType }));
    yield put(
      addToast({
        toastType: TOAST_ERROR,
        length: TOAST_LONG,
        message: GENERAL_ERROR_MESSAGE,
      }),
    );
  }
}

export function* getFolderBreadcrumbHelper(folder) {
  // get the current list of all folders
  const allFolders = yield select(selectHomeScreenObjects, HOME_OBJECTS.FOLDER);
  // get current breadcrumb
  const currentBreadcrumb = yield select(selectFolderStack);
  // get the new breadcrumb
  const newBreadcrumb = yield call(getFolderBreadcrumb, allFolders, folder, currentBreadcrumb);
  //
  return newBreadcrumb;
}

export function* setCurrentFolderWorker({ folder }) {
  const folderBreadcrumb = yield* getFolderBreadcrumbHelper(folder);
  yield put(setCurrentFolderBreadcrumb({ folderBreadcrumb }));
  // When ever we chang the folder, we need to clear the selected rows
  yield put(setSelectedRows([]));
}

export function* deleteWorkspaceObjectHelper({ uuid }) {
  // get access token
  const accessToken = yield select(selectAccessToken);
  yield call(deleteWorkspaceObject, accessToken, uuid);
}

/**
   Worker to create a new workspace object (folder)
  @param {String} name name of the object
  @param {Object} parentFolder destination folder for new folder
  This worker will test for the following two cases before calling the api
  1. is the folder name a duplicate
  2. does the user have access to the parent folder
  @returns {{uuid: string, id: int} | null} UUID and ID of created folder
*/
export function* createWorkspaceObjectRequestWorker({ name, parentFolder }) {
  try {
    // Test if a folder with the name already exists
    const dcObjects = yield select(selectHomeScreenObjects, HOME_OBJECTS.FOLDER);
    const isDuplicate = dcObjects.some((obj) => obj.Name === name);
    if (isDuplicate || name === ROOT_FOLDER[HOME_OBJECT_KEYS.NAME]) {
      const conflictError = new Error(
        `A(n) ${HOME_OBJECTS.FOLDER} with the name ${name} already exists`,
      );
      conflictError.code = CONFLICT;
      throw conflictError;
    }
    // test if the user has editor access to the target parent folder.
    if (!canModify(parentFolder[HOME_OBJECT_KEYS.ACCESS_TYPE])) {
      const forbiddenError = new Error(
        `You are not authorized to create an object in this folder.`,
      );
      forbiddenError.code = FORBIDDEN;
      throw forbiddenError;
    }

    const parentUuid = parentFolder === ROOT_FOLDER ? null : parentFolder[HOME_OBJECT_KEYS.UUID];
    const accessToken = yield select(selectAccessToken);
    const response = yield call(createWorkspace, accessToken, name, parentUuid);
    yield put(createWorkspaceObjectSuccess());
    return response;
  } catch (error) {
    if (error?.code === CONFLICT) {
      yield* objectAlreadyExistsAlert(HOME_OBJECTS.FOLDER, name);
    } else {
      yield* generalErrorWorker(error);
    }
    yield put(createWorkspaceObjectFailure({ error }));
    return null;
  }
}

/**
  Move dc object into a destination folder
  * Only editor and owners can move objects
  * TEMPORARY: Prevent editors from moving shared objects into unshared folders
  @param {Object} object // object to be moved
  @param {Object} destination // folder user is moving object to
*/

export function* moveDcObjectRequestWorker({ object, destination }) {
  try {
    const accessToken = yield select(selectAccessToken);
    // init vars
    let isShared = false; // do multiple users have access to destination folder
    let ownerHasAccess = false; // object owner have access to destination folder
    let destinationUuid;
    if (destination === ROOT_FOLDER) {
      // if the destination is the users Root folder
      isShared = false;
      ownerHasAccess = true;
      destinationUuid = null;
    } else {
      destinationUuid = destination[HOME_OBJECT_KEYS.UUID];

      // Check if the destination folder is shared with organization
      const organizationAccessObject = yield call(
        listOrganizationAccess,
        accessToken,
        destinationUuid,
      );
      const hasOrganizationAccess = !isEmpty(organizationAccessObject.data);

      // Fetch list of all accessors for the destination uuid
      const workspaceAccessorsResponse = yield call(
        listWorkspaceAccessors,
        accessToken,
        destinationUuid,
      );

      const usersWithAccess = workspaceAccessorsResponse.data;
      isShared =
        (usersWithAccess && Object.values(usersWithAccess).length > 1) || hasOrganizationAccess;
      const ownerId = object[HOME_OBJECT_KEYS.OWNER_ID];
      ownerHasAccess = ownerId in usersWithAccess;
    }
    if (!ownerHasAccess) {
      // TODO: remove this when user can view all shared folders
      // cancel the move object request and display error to user
      const newError = new Error(
        `The object owner of ${object[HOME_OBJECT_KEYS.NAME]} (${
          object[HOME_OBJECT_KEYS.OWNER_NAME]
        }) does not have access to ${destination[HOME_OBJECT_KEYS.NAME]}. Please grant ${
          object[HOME_OBJECT_KEYS.OWNER_NAME]
        } access before moving this object.`,
      );
      yield put(createAlertChannelRequest({ error: newError }));
      throw newError;
    }
    if (isShared) {
      // if the destination is shared, alert user that other users with access to destination
      // will now have access to the object
      const alertChannel = yield createAlertChannel(
        moveDcObjectToSharedFolderAlert(
          object[HOME_OBJECT_KEYS.NAME],
          destination[HOME_OBJECT_KEYS.NAME],
        ),
      );
      const keyChoice = yield take(alertChannel);
      yield put(closeDialog());
      if (keyChoice === CANCEL_BUTTON_KEY) {
        throw new Error('User canceled move object');
      }
    }

    const uuid = object[HOME_OBJECT_KEYS.UUID];
    yield call(moveWorkspaceObject, accessToken, uuid, {
      dst_object_id: destinationUuid,
    });
    // we need to fetch all folders for folder search function
    yield put(getHomeScreenObjectsRequest({ objectType: HOME_OBJECTS.FOLDER, refreshing: true }));
    // we need to refresh objects in redux with the type of the moved object
    // this is because we've updated the parent on the moved object and this should be reflected
    yield put(
      getHomeScreenObjectsRequest({ objectType: object[HOME_OBJECT_KEYS.TYPE], refreshing: true }),
    );
    yield put(closeMoveMenu());
    yield put(moveDcObjectSuccess());
  } catch (error) {
    yield put(moveDcObjectFailure({ error }));
  }
}

/**
  Worker to get the organization access data for an object.
  @param {String} uuid // uuid of the object
*/
export function* getOrganizationAccessRequestWorker({ payload }) {
  const { uuid } = payload;

  try {
    const accessToken = yield select(selectAccessToken);
    const response = yield call(listOrganizationAccess, accessToken, uuid);

    yield put(
      getOrganizationAccessSuccess({
        uuid,
        organizationAccess: response.data,
      }),
    );
  } catch (error) {
    yield put(getOrganizationAccessFailure({ uuid, error }));
  }
}

/**
 * This saga takes a workspace folder object and returns a flattened list of its descendants.
 *
 * This saga uses `/api/workspacev2/{uuid}/children` to traverse each level of the workspace
 * hierarchy.
 *
 * @param {Object} root workspace folder object to get descendants of
 * @returns {Array} descendants of root
 */
export function* getDescendants(root) {
  const accessToken = yield select(selectAccessToken);
  /**
   * Array of workspace folder objects whose children will be added to the descendants list.
   *
   * This array is initialized with the root folder and will be updated with each iteration.
   */
  let folders = [root];
  /**
   * Array of workspace objects that will be returned by this saga.
   */
  const descendants = [];
  while (folders.length > 0) {
    // map folder objects to their uuids and then map those uuids to getWorkspaceChildren calls
    let children = yield all(
      folders
        .map((folder) => folder[HomeObjectKeys.UUID])
        .map((uuid) => call(getWorkspaceChildren, accessToken, uuid)),
    );
    // map responses to workspace objects & flatten the results
    children = children
      .map((response) => {
        const flatObjList = Object.values(response.data).flat();
        return flatObjList.map((flatObj) => {
          const homeScreenObjectType = mapWorkpaceTypeToHomescreenType(flatObj.ObjectType);
          return convertDcObjectToHomeScreenObject(flatObj, homeScreenObjectType);
        });
      })
      .flat();
    // add these children to the list of all descendants
    descendants.splice(descendants.length - 1, 0, ...children);
    // set folder children to the list of folders to search
    folders = children.filter((child) => child[HomeObjectKeys.TYPE] === HomeObjects.FOLDER);
  }
  return descendants;
}

/**
 * requestHeadWorkspaceV2 sends a HEAD /api/workspacev2?name=...&type=... request.
 *
 * @param {object} o
 * @param {string} o.name name of workspace object
 * @param {string} o.type type of workspace object
 * @returns {import('axios').AxiosResponse} on 200 response
 * @throws {import('axios').AxiosError} any error that was thrown making the
 * request including non 2xx responses
 */
export function* requestHeadWorkspaceV2({ name, type }) {
  const accessToken = yield select(selectAccessToken);
  const workspacev2 = yield getContext(API_SERVICES.WORKSPACEV2);
  return yield call(workspacev2.headWorkspaceNameType, accessToken, name, type);
}

export default function* () {
  yield takeEvery(SET_CURRENT_FOLDER, setCurrentFolderWorker);
  yield takeEvery(CHANGE_ACCESS_REQUEST, changeAccessRequestWorker);
  yield takeEvery(CREATE_WORKSPACE_OBJECT_REQUEST, createWorkspaceObjectRequestWorker);
  yield takeEvery(MOVE_DC_OBJECT_REQUEST, moveDcObjectRequestWorker);
  yield takeEvery(getOrganizationAccessRequest.type, getOrganizationAccessRequestWorker);
}
