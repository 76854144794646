/** Minimum time (in ms) to wait. */
export const defaultMinBackoff = 200;

/** Percentage of total wait time to wait. */
export const defaultPercentForBackoff = 0.05;

/** Time (in ms) that's a percentage of the total time already waited,
 * but with a minimum backoff.
 */
export const percentageBackoff = (
  total: number,
  minBackoff = defaultMinBackoff,
  percentForBackoff = defaultPercentForBackoff,
) => {
  const backoff = Math.max(total * percentForBackoff, minBackoff);
  return backoff;
};

export const makePercentageBackoff = (
  minBackoff = defaultMinBackoff,
  percentForBackoff = defaultPercentForBackoff,
) => {
  // Closure so caller doesn't need to track this
  let backoffSum = 0;
  return () => {
    const backoff = percentageBackoff(backoffSum, minBackoff, percentForBackoff);
    backoffSum += backoff;
    return backoff;
  };
};
