import { call, put, select, takeLatest } from 'redux-saga/effects';
import { deleteFileById, getFiles } from '../../api/file_manager.api';
import { authenticate } from '../../utils/authenticate';
import { createAlertChannelRequest } from '../actions/dialog.actions';
import {
  LIST_FILES_REQUEST,
  TRY_SET_FILE_MANAGER_MODE,
  changeFileManagerMode,
  listFilesFailure,
  listFilesSuccess,
} from '../actions/file_manager.actions';
import { selectAccessToken } from './selectors';
import { tryLeaveSettingsUploadMenuAlert } from './utils/alert-channels';

export function* deleteFileHelper({ fileId }) {
  // get access token
  const accessToken = yield select(selectAccessToken);
  yield call(deleteFileById, accessToken, fileId);
}

/**
 * Makes a request to retrieve a list of files from the user's server directory.
 */
export function* listFilesRequestWorker() {
  try {
    const accessToken = yield select(selectAccessToken);
    const response = yield call(getFiles, accessToken);
    const files = response.data;
    yield put(listFilesSuccess({ files }));
  } catch (error) {
    yield put(listFilesFailure({ error }));

    // TODO: Consider status code handling, e.g, 400, 405
    yield put(createAlertChannelRequest({ error }));
  }
}

/**
 * Checks whether the settings upload page can be navigated away from.
 */
export function* tryChangeFileManagerMenuWorker({ mode }) {
  yield* tryLeaveSettingsUploadMenuAlert(changeFileManagerMode, mode);
}

/**
 * Listens for the most recent listFilesRequest and deleteFilesRequest action calls.
 */
export default function* apiWatcher() {
  yield takeLatest(LIST_FILES_REQUEST, authenticate(listFilesRequestWorker, listFilesFailure));
  yield takeLatest(TRY_SET_FILE_MANAGER_MODE, tryChangeFileManagerMenuWorker);
}
