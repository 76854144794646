import {
  CREATE_PUBLIC_LINK_REQUEST,
  CREATE_PUBLIC_LINK_SUCCESS,
  CREATE_PUBLIC_LINK_FAILURE,
  OPEN_LINK_DIALOG,
  CLOSE_LINK_DIALOG,
} from '../actions/share.actions';

const initialState = {
  creatingPublicLink: false,
  publicLink: null,
  linkDialogOpen: false,
  shareData: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CREATE_PUBLIC_LINK_REQUEST:
      return {
        ...state,
        creatingPublicLink: true,
      };
    case CREATE_PUBLIC_LINK_SUCCESS:
      return {
        ...state,
        creatingPublicLink: false,
        publicLink: action.publicLink,
      };
    case CREATE_PUBLIC_LINK_FAILURE:
      return {
        ...state,
        creatingPublicLink: false,
      };
    case OPEN_LINK_DIALOG:
      return {
        ...state,
        linkDialogOpen: true,
        shareData: action?.shareData || state.shareData,
      };
    case CLOSE_LINK_DIALOG:
      return {
        ...state,
        linkDialogOpen: false,
        publicLink: null,
        shareData: {},
      };
    default:
      return state;
  }
}
