import { call, put, select, takeLatest } from 'redux-saga/effects';
import { editUserFeedback, getUserFeedback, postUserFeedback } from '../../api/userFeedback.api';
import {
  EDIT_USER_FEEDBACK_REQUEST,
  GET_USER_FEEDBACK_REQUEST,
  SUBMIT_USER_FEEDBACK_REQUEST,
  editUserFeedbackFailure,
  editUserFeedbackSuccess,
  getUserFeedbackFailure,
  getUserFeedbackSuccess,
  submitUserFeedbackFailure,
  submitUserFeedbackSuccess,
} from '../actions/userFeedback.action';
import { selectAccessToken, selectUserID } from './selectors';

/**
 * @param {int} vote 1 or -1, indicates an upvote or downvote
 * @param {object} content object containing information about what user is giving feedback on
 * @param {string} context the context in which the user is giving feedback
 * @param {string} sessionId OPTIONAL
 */
function* submitUserFeedbackRequestWorker({ vote, context, content, sessionId }) {
  try {
    const accessToken = yield select(selectAccessToken);
    const userId = yield select(selectUserID);

    const response = yield call(
      postUserFeedback,
      accessToken,
      userId,
      context,
      vote,
      content,
      sessionId,
    );
    const feedbackRecord = response.data;
    yield put(submitUserFeedbackSuccess({ feedbackRecord }));
  } catch (error) {
    yield put(submitUserFeedbackFailure({ error }));
  }
}

function* getUserFeedbackRequestWorker({ sessionId }) {
  try {
    const accessToken = yield select(selectAccessToken);
    const response = yield call(getUserFeedback, accessToken, sessionId);
    const feedbackList = response.data;
    yield put(getUserFeedbackSuccess({ feedbackList }));
  } catch (error) {
    yield put(getUserFeedbackFailure({ error }));
  }
}

function* editUserFeedbackRequestWorker({ feedbackId, vote }) {
  try {
    const accessToken = yield select(selectAccessToken);
    const response = yield call(editUserFeedback, accessToken, feedbackId, vote);
    const feedbackRecord = response.data;
    yield put(editUserFeedbackSuccess({ feedbackRecord }));
  } catch (error) {
    yield put(editUserFeedbackFailure({ error }));
  }
}

export default function* () {
  yield takeLatest(SUBMIT_USER_FEEDBACK_REQUEST, submitUserFeedbackRequestWorker);
  yield takeLatest(GET_USER_FEEDBACK_REQUEST, getUserFeedbackRequestWorker);
  yield takeLatest(EDIT_USER_FEEDBACK_REQUEST, editUserFeedbackRequestWorker);
}
