export const OPEN_ALERT_DIALOG = 'dialog/OPEN_ALERT_DIALOG';
export const CLOSE_DIALOG = 'dialog/CLOSE_DIALOG';
export const CLOSE_ALL_DIALOG = 'dialog/CLOSE_ALL_DIALOG';
export const CREATE_ALERT_REQUEST = 'dialog/CREATE_ALERT_REQUEST';
export const HANDLE_ERROR_DIALOG = 'dialog/HANDLE_ERROR_DIALOG';
let dialogId = 0;

/**
 * @deprecated Use openAlertDialogV1 instead.
 * Opens a new alert dialog and closes the opened dialog if any.
 * Parameters map to properties of the dialog prop in the
 * AlertDialog component.
 *
 * @param {import("components/AlertDialog/types").AlertDialogV0Type} params
 */
export const openAlertDialog = ({
  title,
  descriptions = [],
  collapsedDescriptions = [],
  dialogProps = {},
  inputs = [],
  buttons = [],
  dialogType,
  closeButton = {},
  delayedAction,
}) => ({
  type: OPEN_ALERT_DIALOG,
  title,
  descriptions,
  collapsedDescriptions,
  dialogProps,
  inputs,
  buttons,
  dialogType,
  closeButton,
  delayedAction,
  id: dialogId++,
});

/**
 * Opens a new alert dialog.
 *
 * @param {Partial<import("components/AlertDialog/types").AlertDialogV1Type>} params
 */
export const openAlertDialogV1 = ({ dialogType, dialogParams }) => ({
  type: OPEN_ALERT_DIALOG,
  version: 1,
  dialogType,
  dialogParams,
});

/**
 * Closes the opened dialog if any.
 */
export const closeDialog = () => ({
  type: CLOSE_DIALOG,
});

/**
 * Closes all the alert dialogs if any.
 */
export const closeAllDialog = () => ({
  type: CLOSE_ALL_DIALOG,
});

export const createAlertChannelRequest = ({ error }) => ({
  type: CREATE_ALERT_REQUEST,
  error,
});

export const handleErrorDialog = (errorDetails, errorActions) => ({
  type: HANDLE_ERROR_DIALOG,
  errorDetails,
  errorActions,
});

export const HANDLE_CLIENT_ERROR = 'dialog/HANDLE_CLIENT_ERROR';
export const handleClientError = (alertConfig) => ({
  type: HANDLE_CLIENT_ERROR,
  alertConfig,
});
