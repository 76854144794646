import { ErrorDetails } from '../../types/errorCodes/errorCodes.types';
import { PipelinerErrorCode } from '../../types/errorCodes/pipelinerErrorCodes.types';
import { IntegrationProvider } from '../../types/integrations.types';

export const isDatabaseAuthErrorCode = (code: unknown): boolean => {
  if (typeof code !== 'string') return false;
  return [PipelinerErrorCode.DB_CONNECTION_AUTH_ERROR].includes(code as PipelinerErrorCode);
};

export const isOAuthErrorCode = (code: unknown): boolean => {
  if (typeof code !== 'string') return false;
  return [PipelinerErrorCode.OAUTH_INVALID, PipelinerErrorCode.OAUTH_NOT_FOUND].includes(
    code as PipelinerErrorCode,
  );
};

export const getProviderFromString = (provider?: string): IntegrationProvider | null => {
  for (const integrationProvider of Object.values(IntegrationProvider)) {
    if (provider === integrationProvider) return integrationProvider;
  }
  return null;
};

export const getProviderFromError = (
  errorDetails?: ErrorDetails | null,
): { provider: IntegrationProvider | null; detail: string | null } => {
  // If the error code is an OAuth error, return null
  if (!isOAuthErrorCode(errorDetails?.error_code)) return { provider: null, detail: null };

  const { provider, detail } = errorDetails ?? {};
  return { provider: getProviderFromString(provider), detail: detail ?? null };
};
