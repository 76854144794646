import { SeriesGroupKeys, SeriesMarkKeys } from 'translate_dc_to_echart';
import ChartModules from '../modules/ChartModules';
import { InputType } from '../types/chartBuilderIO';
import ChartType from './ChartType';

export default class Scatter extends ChartType {
  static fieldGenMapping = {
    mark: {
      [SeriesMarkKeys.x]: InputType.x,
      [SeriesMarkKeys.y]: InputType.y,
      [SeriesMarkKeys.color]: InputType.group,
      [SeriesMarkKeys.label]: InputType.label,
    },
    group: {
      [SeriesGroupKeys.subplot]: InputType.subplot,
      [SeriesGroupKeys.slider]: InputType.slider,
    },
  };

  static columnFilters = {};

  constructor() {
    super(new ChartModules(Scatter.columnFilters, Scatter.fieldGenMapping));
  }
}
