import {
  CLOSE_MODEL_MENU,
  GET_PUBLISHED_MODEL_DATA_REQUEST,
  GET_PUBLISHED_MODEL_DATA_SUCCESS,
  GET_SESSION_MODELS_FAILURE,
  GET_SESSION_MODELS_SUCCESS,
  OPEN_MODEL_MENU,
} from '../actions/model.actions';

// const model = {
//   apiName: String,
//   requireToken: Boolean,
//   publicationId: Number,
//   loaded: Boolean,
//   modelName: String,
// }

const initialState = {
  showModelProfiler: false,
  models: [], // See 'model' above
  sessionModelList: [],
  error: null, // use this info when opening a form to notify the user
};

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_MODEL_MENU:
      return { ...state, showModelProfiler: true };
    case CLOSE_MODEL_MENU:
      return initialState;

    // For retrieving a published model spec from the server
    case GET_PUBLISHED_MODEL_DATA_REQUEST:
      return {
        ...state,
        error: null,
        models: [
          ...state.models,
          {
            publicationId: action.publicationId,
            loaded: false,
            apiName: action.apiName,
            apiKey: action.apiKey,
            apiSecret: action.apiSecret,
            requireToken: action.requireToken,
          },
        ],
      };
    case GET_PUBLISHED_MODEL_DATA_SUCCESS:
      return {
        ...state,
        models: state.models.map((model) => {
          if (model.publicationId === action.publicationId) {
            return { ...model, ...action.model, loaded: true };
          }
          return model;
        }),
        error: null,
      };
    case GET_SESSION_MODELS_SUCCESS:
      return {
        ...state,
        error: null,
        sessionModelList: action.models,
      };
    case GET_SESSION_MODELS_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};
