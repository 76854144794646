/**
 * Converts a number of bytes into a readable format with a unit prefix.
 * Usage: formatBytes(1024);       // 1 KB
 *        formatBytes('1024');     // 1 KB
 *        formatBytes(1234);       // 1.21 KB
 *        formatBytes(1234, 3);    // 1.205 KB
 *
 * @param {Integer} bytes
 * @param {Integer} precision
 *
 * See https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
 */
export const formatBytes = (bytes, precision = 2) => {
  bytes = parseFloat(bytes);
  if (bytes === 0 || Number.isNaN(bytes)) return '';

  // 1 KB = 1024 Bytes
  const k = 1024;
  const dm = precision < 0 ? 0 : precision;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const sizeIndex = Math.floor(Math.log(bytes) / Math.log(k));
  const temp = k ** sizeIndex;
  return `${parseFloat((bytes / temp).toFixed(dm))} ${sizes[sizeIndex]}`;
};
