/**
 * This file contains the validation functions for handling automatic updates to the chart spec
 * when 1. Selecting columns, and 2. Handling chart type switches
 */

import cloneDeep from 'lodash/cloneDeep';
import { AggregateExpressions, ChartTypes } from 'translate_dc_to_echart';
import { cleanFields } from '../../../echart-library/utils/chartBuilderIO';
import { AXIS_CONFIG_INPUTS } from '../Menu/AxisConfig';
import {
  CHART_BUILDER_SAMPLE_LIMIT,
  defaultAggregate,
  getAggregatedInputs,
  getBinnedInputs,
  iconMapping,
} from './constants';
import { constructAggregate, filterAggregate } from './manageAggregates';
import { generateHistogramBins } from './manageBins';
import { filterColumns } from './manageChart';

/**
 * Helper function for validateAggregate()
 * Handles aggregate validation for swapping chart types
 * @param {Array} aggregates The chartSpec's aggregate object (only one)
 * @param {Object} updatedFields The updated fields (after a new selection or field validation)
 * @param {Array} columnsTypes The dataset's column & type information
 * @param {String} chartType The current chart type
 * @param {Number} rowCount The number of rows in the chart builder
 * @returns {Array} The resulting aggregate objects
 */
const validateAggregateOnChartTypeSwap = (
  aggregates,
  updatedFields,
  columnsTypes,
  chartType,
  rowCount,
) => {
  let validAggregates = [];

  // Wrapper to construct an aggregate using the fn params
  const constructThisAggregate = (expression) => {
    const [newAggregate] = constructAggregate(chartType, expression, updatedFields);
    return [...validAggregates, newAggregate];
  };

  // The aggregated inputs for the new chartType
  const newAggregatedInputs = getAggregatedInputs(chartType).filter((z) => z !== 'overlay');

  // Array of columns that we have in our aggregated inputs
  const aggregatedColumns = newAggregatedInputs
    .reduce((acc, curr) => acc.concat(updatedFields[curr]?.flat()), [])
    .filter((z) => z);

  // Perform the aggregate validation for each aggregated column
  aggregatedColumns.forEach((colName) => {
    // The type of the current column, ex. String, Float
    const colType = columnsTypes.find((col) => col.name === colName)?.type;

    // The current aggregate object to validate (if any)
    const currAggregate = aggregates.find((a) => a.columns.includes(colName));
    const allowedAggregates = filterAggregate(chartType, colType);

    // The aggregate expressions, ex. Min, Max, Count, etc.
    const currExpression = currAggregate?.expression;
    const defaultExpression = defaultAggregate(chartType, updatedFields, columnsTypes, rowCount);

    // Choose our expression for constructing our aggregate
    const chosenExpression =
      currExpression && currExpression !== 'None' && allowedAggregates.includes(currExpression)
        ? currExpression
        : defaultExpression;

    // Always create an aggregate with the chosen expression
    validAggregates = constructThisAggregate(chosenExpression);
  });

  return validAggregates;
};

/**
 * Helper function for validateAggregate()
 * Handles aggregate validation for updating a chart builder field
 * @param {Array} aggregates The chartSpec's aggregate object (only one)
 * @param {Object} updatedFields The updated fields (after a new selection or field validation)
 * @param {Array} columnsTypes The dataset's column & type information
 * @param {String} chartType The current chart type
 * @param {Number} rowCount The number of rows in the chart builder
 * @returns {Array} The resulting aggregate objects
 */
const validateAggregateOnFieldSwap = (
  aggregates,
  updatedFields,
  columnsTypes,
  chartType,
  rowCount,
) => {
  const aggColumns = aggregates[0]?.columns || [];

  // Check if the aggregate expression is still valid for the selected columns
  for (const colName of aggColumns) {
    const colType = columnsTypes.find((col) => col.name === colName)?.type;
    const aggregateOptions = filterAggregate(chartType, colType);
    const currExpression = aggregates[0]?.expression;

    // If the expression isn't one of our available options, swap back to default expression
    if (!aggregateOptions.includes(currExpression)) {
      return constructAggregate(
        chartType,
        defaultAggregate(chartType, updatedFields, columnsTypes, rowCount),
        updatedFields,
      );
    }
  }

  // Keep the current aggregates
  return aggregates;
};

/**
 * Validate the aggregate for the given inputs. Used on chart type switch and field updates
 * @param {Array} aggregates The chartSpec's aggregate object (only one)
 * @param {Object} updatedFields The updated fields (after a new selection or field validation)
 * @param {Array} columnsTypes The dataset's column & type information
 * @param {Number} rowCount The number of rows in the chart builder
 * @param {String} chartType The current chart type
 * @param {String} oldChartType (optional) The previous chart type if swapping
 * @returns {Array} The aggregates that are still valid
 */
export const validateAggregate = (
  aggregates,
  updatedFields,
  columnsTypes,
  rowCount,
  chartType,
  oldChartType,
) => {
  if (oldChartType && chartType) {
    return validateAggregateOnChartTypeSwap(
      aggregates,
      updatedFields,
      columnsTypes,
      chartType,
      rowCount,
    );
  }

  // If no aggregate exists, construct one with the default expression
  // For consistency, we should always have an aggregate in the chartSpec, even if the expression is 'None'
  const expression =
    aggregates[0]?.expression || defaultAggregate(chartType, updatedFields, columnsTypes, rowCount);
  aggregates = constructAggregate(chartType, expression, updatedFields);

  return validateAggregateOnFieldSwap(aggregates, updatedFields, columnsTypes, chartType, rowCount);
};

/**
 * Validate the bins for the given inputs. Used on chart type switch and field updates
 * @param {Array} bins The chartSpec's bin objects
 * @param {Object} fields The updated fields (after a new selection or field validation)
 * @param {Array} columnsTypes The dataset's column & type information
 * @param {Number} totalRowCount The number of rows in the chart builder
 * @param {String} chartType The current chart type
 * @returns {Array} The bin information that is still valid
 */
export const validateBins = (bins, fields, columnsTypes, totalRowCount, chartType) => {
  const isHistogram = chartType === ChartTypes.histogram;
  const hasNoBins = !bins || bins.length === 0;

  // Quit early if we don't have any bins
  // If we're using histogram, we will autofill bins
  if (hasNoBins && !isHistogram) return [];

  // Columns that we're currently binning
  const binColumns = bins.map((b) => b.column);
  // Inputs that allow binning
  const binnedInputs = getBinnedInputs(chartType);
  const updatedBins = cloneDeep(bins);

  // Check that each bin still applies to at least one valid input
  for (const [binIndex, binCol] of Object.entries(binColumns)) {
    let isBinAllowed = false;

    // Check each input to see if it allows binning, and if we're using the current bin on that input
    // If we find one, we can keep the current bin
    for (const [inputName, columnNames] of Object.entries(fields)) {
      const isInputBinned = columnNames.includes(binCol);
      const inputAllowsBin = binnedInputs.includes(inputName);

      // We can keep the current bin
      if (isInputBinned && inputAllowsBin) {
        isBinAllowed = true;
        break;
      }
    }

    // If we didn't find a valid input for the bin, delete the bin
    if (!isBinAllowed) updatedBins.splice(binIndex, 1);
  }

  // Autofill the bin for histograms if we have none
  if (isHistogram && updatedBins.length === 0)
    return generateHistogramBins(fields['x-axis'], columnsTypes, totalRowCount);

  // Return the validated bins
  return updatedBins;
};

/**
 * Validate the dataSampleLimit for the new chart type
 * Protects against retrieving the whole dataset when we're performing FE computations
 *
 * TODO: Remove conditions to return CHART_BUILDER_SAMPLE_LIMIT as we support more
 * features being computed in the BE (ex. Remove binning after #37913)
 *
 * @param {String} chartType The type of the chart
 * @param {Array | undefined} aggregates The specified aggregates
 * @returns {Number} The new dataSampleLimit
 */
export const validateDataSampleLimit = (chartType, aggregates) => {
  // Check if we have a 'None' aggregate expression
  const hasNoneAggregate =
    aggregates && aggregates.some((agg) => agg.expression === AggregateExpressions.none);

  // Set the dataSampleLimit to CHART_BUILDER_DATA_SAMPLE_LIMIT since we're doing FE computations
  if (hasNoneAggregate || ['boxplot', 'violin'].includes(chartType)) {
    return CHART_BUILDER_SAMPLE_LIMIT;
  }

  // Otherwise, set dataSampleLimit to null
  return null;
};

/**
 * Validate the fields on chart type switch, preserving the ones that are still applicable
 * @param {Object} fields The input:value pairs from the chart builder menu
 * @param {String} chartType The chart type
 * @param {Object} columnsTypes The column/type information from the dataset
 * @returns {Object} The input:value pairs that still apply in the new chart type
 */
export const validateFields = (fields, chartType, columnsTypes) => {
  const { required, optional } = iconMapping[chartType];

  // Input fields before the chart type swap
  const oldFieldsKeys = Object.keys(fields);
  // Input fields after the chart type swap
  const newFields = {};

  // Loop through each key in the old fields, checking if it's still allowed in new chart type
  for (const field of oldFieldsKeys) {
    if (required.includes(field) || optional?.includes(field)) {
      const allowedColumns = filterColumns(fields, columnsTypes, field, chartType);
      newFields[field] = [];

      // Is the input field a multi-select?
      const isMultiSelect =
        (field === 'y-axis' && (chartType === 'line' || chartType === 'stacked_area')) ||
        (field === 'x-axis' && chartType === 'boxplot');

      // Check which values of the array to keep
      for (const [i, col] of Object.entries(fields[field])) {
        if (allowedColumns.includes(col)) {
          if (isMultiSelect || i === '0') {
            newFields[field].push(col);
          }
        }
      }
    }
  }

  return cleanFields(newFields);
};

/**
 * Validate the transforms for the given inputs
 * @param {Array} transforms The chartSpec's transform objects
 * @param {Object} fields The input:value pairs from the chart builder menu
 * @returns The transforms that are still valid
 */
export const validateTransforms = (transforms, fields) => {
  if (!transforms || !fields) return [];

  // Get the columns that are currently selected in the AXIS_CONFIG_INPUTS
  // ex. x-axis might have the columns ['Fare', 'PassengerId']
  const selectedColumns = [];
  AXIS_CONFIG_INPUTS.forEach((inputName) => {
    const columns = fields[inputName] || [];
    selectedColumns.push(...columns);
  });

  // Decide which 'axis range' transforms to keep.
  // We can keep the transform if the transformed column still exists in the AXIS_CONFIG_INPUTS
  const axisRangeTransforms = transforms.filter((t) => {
    const isColumnSelected = selectedColumns.includes(t.column);
    return t.expression.startsWith('axis range') && isColumnSelected;
  });

  // Always keep non- 'axis range' transforms
  const otherTransforms = transforms.filter((t) => !t.expression.startsWith('axis range'));

  // Return the validated transforms
  return [...axisRangeTransforms, ...otherTransforms];
};
