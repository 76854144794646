import Error from '@mui/icons-material/Error';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import React from 'react';
import { ReactComponent as RefreshIcon } from '../../../../../assets/icons/rotate-r.svg';
import { convertToCalenderDate } from '../../../../../utils/time';

/**
 * A functional component to render a button UI to refresh in IB
 */
const RefreshButton = (props) => {
  const {
    isRefreshing,
    lastRefreshFailed,
    lastRefreshTime,
    messageType,
    hasChartLinked,
    userHasPermissions,
    handleRefresh,
    isPopOutChart,
  } = props;

  const renderRefreshFailed = lastRefreshFailed;
  const renderRefreshTime = lastRefreshTime;
  const renderRefreshButton =
    !isPopOutChart &&
    !isRefreshing &&
    hasChartLinked &&
    userHasPermissions &&
    messageType !== 'pivot table';

  return (
    <>
      {!renderRefreshButton && null}
      {renderRefreshButton && (
        <MenuItem key="Refresh Data" className="Menu-Item-Container" onClick={handleRefresh}>
          <div className="Menu-Item" data-cy="Menu-Item-Refresh">
            <RefreshIcon className="Menu-Item-Icon" />
            <div className="Menu-Item-Text-Container">
              <span className="Menu-Item-Text">Refresh Data</span>
              {renderRefreshTime && (
                <div className="Menu-Item-Subtext">
                  {`Last Refreshed: ${convertToCalenderDate(lastRefreshTime)}`}
                </div>
              )}
              {renderRefreshFailed && (
                <div className="Menu-Item-Subtext" title="Refresh Failed">
                  <Error fontSize="inherit" />
                </div>
              )}
            </div>
          </div>
        </MenuItem>
      )}
    </>
  );
};

RefreshButton.propTypes = {
  isRefreshing: PropTypes.bool.isRequired,
  lastRefreshTime: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
  lastRefreshFailed: PropTypes.bool.isRequired,
  messageType: PropTypes.string.isRequired,
  hasChartLinked: PropTypes.bool.isRequired,
  userHasPermissions: PropTypes.bool.isRequired,
  handleRefresh: PropTypes.func.isRequired,
  isPopOutChart: PropTypes.bool.isRequired,
};

export default RefreshButton;
