import axios from 'axios';
import qs from 'qs';
import { AuthType } from '../constants/auth';
import { endpoints } from '../constants/endpoints';

export const getSnowflakeLogin = () => axios.get(endpoints.snowflakeAuth);

/**
 * Submits a login request
 *
 * @param {import('../constants/auth')AuthType} authType
 * @param {import('../constants/auth').AuthParams} [loginParams]
 * @returns
 */
export const getLogin = (authType, loginParams) => {
  const config = {
    params: {
      auth_type: authType,
    },
  };

  // Configure the request based on the auth type
  switch (authType) {
    case AuthType.PASSWORD:
      config.auth = {
        username: loginParams.email,
        password: loginParams.password,
      };
      break;
    case AuthType.AUTH0:
      config.headers = {
        Authorization: `Bearer ${loginParams.accessToken}`,
      };
      break;
    case AuthType.GOOGLE:
      config.headers = {
        Authorization: `Bearer ${loginParams.idToken}`,
      };
      break;
    case AuthType.OTP:
      config.headers = {
        Authorization: `Bearer ${loginParams.otpToken?.trim() ?? ''}`,
      };
      config.params.email = loginParams.email;
      break;
    // IAP and SAML do not require additional configuration
    case AuthType.SAML:
    case AuthType.IAP:
    default:
      break;
  }

  return axios.get(endpoints.login, config);
};

export const postOtpLink = (email) =>
  axios.post(endpoints.otp, qs.stringify({ email }), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

export const postRefresh = (refreshToken) =>
  axios.post(endpoints.refresh, null, {
    params: {
      token: refreshToken,
    },
  });

export const postRegister = ({
  authType,
  key,
  email,
  name,
  password,
  defaultHomePage,
  authToken,
  adAttribution = {},
}) => {
  return axios.post(
    endpoints.register,
    qs.stringify({
      name,
      key,
      defaultHomePage,
      authToken,
      adAttribution,
    }),
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      auth: {
        username: email,
        password,
      },
      params: {
        auth_type: authType,
      },
    },
  );
};

export const getIsUserRegistered = (subjectIdentifier, serviceProvider) => {
  return axios.get(endpoints.isUserRegistered, {
    params: {
      subject_identifier: subjectIdentifier,
      service_provider: serviceProvider,
    },
  });
};

export const postResetPassword = (origin, email) =>
  axios.post(endpoints.emailResetToken, qs.stringify({ origin, email }), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

export const postResetPasswordConfirm = (resetToken, password) =>
  axios.post(endpoints.resetPassword, qs.stringify({ password }), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    params: {
      token: resetToken,
    },
  });

export const getResetPasswordTokenVerification = (resetToken) =>
  axios.get(endpoints.verifyPasswordToken, {
    params: {
      token: resetToken,
    },
  });

export const postLogout = (accessToken) =>
  axios.post(
    endpoints.logout,
    {},
    {
      params: {},
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

export const postResetPasswordFromMenu = (password, currentPassword, email, accessToken) =>
  axios.post(endpoints.resetPasswordFromMenu, qs.stringify({ password, currentPassword, email }), {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

export const getUserPermissions = (userID, accessToken) =>
  axios.get(endpoints.userRole(userID), {
    params: {},
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

export const getAllUserRoles = (accessToken) =>
  axios.get(endpoints.allUserRoles, {
    params: {},
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

export const getAllRoles = (accessToken, includeFeatures) =>
  axios.get(endpoints.role, {
    params: {
      include_features: includeFeatures,
    },
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

export const postRole = (accessToken, name, features) =>
  axios.post(endpoints.role, JSON.stringify({ name, features }), {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

export const putRole = (accessToken, oldName, name, features) =>
  axios.put(endpoints.role, JSON.stringify({ old_name: oldName, name, features }), {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

export const deleteRole = (accessToken, id) =>
  axios.delete(`${endpoints.role}/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

export const updateUserRole = (accessToken, userId, newRoleId) =>
  axios.put(endpoints.userRole(userId), qs.stringify({ role: newRoleId }), {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

export const getAllFeatures = (accessToken) =>
  axios.get(endpoints.feature, {
    params: {},
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

export const updateUserProfile = (accessToken, payload) =>
  axios.put(endpoints.userProfile, payload, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

export const snowflakeLogin = () => {
  return axios.get(endpoints.snowflakeAuth);
};
