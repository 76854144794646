import axios from 'axios';
import { endpoints } from '../constants/endpoints';

export const postVerifyEmail = (email, token) =>
  axios.post(
    endpoints.verifyEmail,
    {},
    {
      params: {
        email,
        token,
      },
    },
  );

/**
 *
 * @param {string} email - email to send the verification email to
 * @param {Record<string, any>} queryParams - query parameters to forward to the
 *                                            verification email link
 * @returns {Promise<AxiosResponse>}
 */
export const postSendVerificationEmail = (email, queryParams) => {
  return axios.post(
    endpoints.sendVerificationEmail,
    {},
    {
      params: {
        email,
        ...queryParams,
      },
    },
  );
};
