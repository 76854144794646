import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/system/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { ReactComponent as CopyLinkIcon } from '../../../assets/icons/copy-link.svg';
import { ASK_AVA_SHARE_TYPES } from '../../../constants/askAva';
import './ShareLinkDialog.scss';

// Content for sharing copying the link for a publication.
// The user can either copy the link or
// the embed code to share the widget.
const ShareLink = ({ publicLink }) => {
  // state variables for the link and embed code
  const [linkText, setLinkText] = React.useState('');
  const [embedCode, setEmbedCode] = React.useState('');

  // state variable for the copy button
  const [copied, setCopied] = React.useState(false);

  // state variable for the tab
  const [tab, setTab] = React.useState(ASK_AVA_SHARE_TYPES.LINK);

  // on mount, fetch the shared link
  React.useEffect(() => {
    setLinkText(publicLink);
    setEmbedCode(
      `<iframe src="${publicLink}" width="100%" height="100%" frameborder="0"></iframe>`,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicLink]);

  // when the user switches tabs, reset the copied state
  const handleTabChange = (e, newValue) => {
    setTab(newValue);
    setCopied(false);
  };

  const copyLinkToClipboard = () => {
    navigator.clipboard.writeText(tab === ASK_AVA_SHARE_TYPES.LINK ? linkText : embedCode);
    setCopied(true);
  };

  // render the correct text field based on share type
  const renderTabContent = () => {
    if (tab === ASK_AVA_SHARE_TYPES.LINK) {
      return (
        <TextField
          fullWidth
          value={linkText}
          onChange={(e) => setLinkText(e.target.value)}
          sx={{ borderRadius: '4px' }}
        />
      );
    } else if (tab === ASK_AVA_SHARE_TYPES.EMBED) {
      return (
        <TextField
          fullWidth
          value={embedCode}
          onChange={(e) => setEmbedCode(e.target.value)}
          multiline
          rows={5}
          sx={{ borderRadius: '4px' }}
        />
      );
    }
    return null;
  };

  return (
    <>
      <DialogContent>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: '1rem' }}>
          <Tabs value={tab} onChange={handleTabChange}>
            <Tab label="Share Link" value={ASK_AVA_SHARE_TYPES.LINK} />
            <Tab label="Embed Code" value={ASK_AVA_SHARE_TYPES.EMBED} />
          </Tabs>
        </Box>
        {renderTabContent()}
      </DialogContent>
      <DialogActions>
        <Tooltip title={copied ? 'Copied!' : 'Copy to clipboard'} leaveDelay={copied ? 1000 : 0}>
          <span>
            <Button onClick={copyLinkToClipboard} variant="contained" startIcon={<CopyLinkIcon />}>
              Copy
            </Button>
          </span>
        </Tooltip>
      </DialogActions>
    </>
  );
};

ShareLink.propTypes = {
  publicLink: PropTypes.string,
};

ShareLink.defaultProps = {
  publicLink: '',
};

export default ShareLink;
