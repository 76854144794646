interface ITypography {
  size: number;
  height: number;
  weight: number;
}

interface TTypographyText extends ITypography {
  bold?: number;
}

export type TTitle = {
  1: ITypography;
  2: ITypography;
  3: ITypography;
  4: ITypography;
};

export type TText = {
  10: TTypographyText;
  20: TTypographyText;
  30: TTypographyText;
  40: TTypographyText;
  50: TTypographyText;
};

export type TTypography = {
  title: TTitle;
  text: TText;
};

const typography: TTypography = {
  title: {
    1: { size: 40, height: 47.7, weight: 700 },
    2: { size: 24, height: 28.6, weight: 700 },
    3: { size: 18, height: 21.5, weight: 700 },
    4: { size: 16, height: 19.1, weight: 700 },
  },
  text: {
    10: { size: 12, height: 14.3, weight: 400, bold: 500 },
    20: { size: 14, height: 19.9, weight: 400, bold: 600 },
    30: { size: 16, height: 22.8, weight: 400, bold: 500 },
    40: { size: 18, height: 25.7, weight: 500, bold: 500 },
    50: { size: 24, height: 29, weight: 400, bold: 500 },
  },
};

export default typography;
