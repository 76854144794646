/**
 * This file contains functions for retrieving, incrementing and reseting
 * the contextIDs and serverMessageIDs
 * These IDs are used as identifiers for contexts and server messages created during a chat session.
 */

let contextId = 0;
let serverMessageId = 0;

// Object ID to Context ID reverse map
// The mapping is maintained such that if we have an utterance referring to an object
// (presumably from different context object)
// we can use the mapping to build
let reverseMap = {};
// The counters are useful for constructing descriptive texts.
// For example, modify the first bubble chart, second note.
let typeStats = {};

// Context ID
export const resetContextId = () => {
  reverseMap = {};
  typeStats = {};
  contextId = 0;
};
export const incrementContextId = () => contextId++;
export const getContextId = () => contextId;

export const addObject = (objectId, type) => {
  if (type in typeStats) {
    typeStats[type] += 1;
  } else {
    typeStats[type] = 1;
  }
  reverseMap[objectId] = { contextId, type, counter: typeStats[type] };
};
export const getContextIdForObject = (objectId) => {
  return reverseMap[objectId];
};

// Server Message ID
export const resetServerMessageId = () => (serverMessageId = 0);
export const incrementServerMessageId = () => serverMessageId++;
export const getServerMessageId = () => serverMessageId;

// User Message ID
let userMessageId = 0;
export const resetUserMessageId = () => (userMessageId = 0);
export const incrementUserMessageId = () => userMessageId++;
export const getUserMessageId = () => userMessageId;

export const createMessageAndUpdateId = (message) => {
  const currentUserMessageId = getUserMessageId();
  message.id = currentUserMessageId;
  incrementUserMessageId();
  return message;
};
