import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  getIndicatorTooltip,
  getValidFiltersByPub,
} from '../../../../pages/authenticated/InsightsBoardPage/CrossFilter/CrossFilterUtils';
import { selectCrossFilterGroups } from '../../../../store/sagas/selectors';

/**
 * Indicates the number of cross filters that apply to the given data
 */
const CrossFilterIndicator = ({
  data,
  editCrossFilters,
  messageType,
  messageTypeVersion,
  publicationId,
}) => {
  const { chartType } = data;

  // Select the cross filter information from the store
  const crossFilterGroups = useSelector(selectCrossFilterGroups);

  // Get the column information from the spec. Nested differently for tables/charts
  const columns = useMemo(
    () => data.values?.columns || data.schema?.fields || [],
    [data.values?.columns, data.schema?.fields],
  );

  const { validFilterGroups, validFilters } = useMemo(
    () => getValidFiltersByPub({ columns, crossFilterGroups, publicationId }),
    [columns, crossFilterGroups, publicationId],
  );

  // Get the groupId of the first valid filter group
  // We use this to open the cross filter modal to the first valid filter group when clicking the indicator
  const firstValidFilterGroup = Object.keys(validFilterGroups)[0];

  // Invalid if it's Plotly and we tried to create some cross filters
  const invalidObject =
    (messageType === 'viz' || messageType === 'table') &&
    messageTypeVersion === 1 &&
    !isEmpty(Object.values(crossFilterGroups).filter((g) => g.enabled));

  return validFilters.length > 0 || invalidObject ? (
    <Tooltip
      key="cross-filter"
      title={
        <div style={{ whiteSpace: 'pre-line' }}>
          {getIndicatorTooltip({
            chartType,
            filters: validFilters,
            invalidObject,
            messageType,
          })}
        </div>
      }
    >
      <div className="ChartHeader-Button">
        <Badge
          badgeContent={invalidObject ? '!' : validFilters.length}
          color={invalidObject ? 'error' : 'primary'}
          className="ChartHeader-Badge"
          onClick={() => editCrossFilters(firstValidFilterGroup)}
          data-testid={invalidObject ? 'cross-filter-non-indicator' : 'cross-filter-indicator'}
        >
          <FilterAltIcon className="ChartHeader-Icon-Lg" sx={{ transform: 'scale(1.15)' }} />
        </Badge>
      </div>
    </Tooltip>
  ) : null;
};

CrossFilterIndicator.propTypes = {
  data: PropTypes.object.isRequired,
  editCrossFilters: PropTypes.func.isRequired,
  messageType: PropTypes.string.isRequired,
  messageTypeVersion: PropTypes.number.isRequired,
  publicationId: PropTypes.number.isRequired,
};

export default CrossFilterIndicator;
