import { AxiosError } from 'axios';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { scrollToUserMessage } from '../../../utils/utterances';
import ErrorBoundary from '../../common/ErrorBoundary/ErrorBoundary';

/**
 * A container component to wrap visual filter
 */
const VisualDisplayContainer = (props) => {
  const chartRef = useRef();

  const {
    children,
    className,
    error,
    messageType,
    messageTypeVersion,
    isInsightsBoard,
    isFailed,
    contextId,
    reason,
    autoScroll,
    clickToScroll,
    userMsgId,
  } = props;

  useEffect(() => {
    if (autoScroll && chartRef?.current) {
      chartRef.current.scrollIntoView(true);
    }
  }, [autoScroll, messageType]);

  /**
   * Scrolls to the corresponding user message bubble in the Chat Panel.
   * @param {string} toLinkId id of the linked element
   */
  const handleClick = () => {
    if (clickToScroll) scrollToUserMessage(userMsgId - 1);
  };

  const displayContainerClassName = classNames(
    // base classname, which is always applied
    'Chart',
    // optional passed in classname(s)
    className,
    {
      // styling specific to insights board
      'Insights-Board-Chart': isInsightsBoard,
      // maintains aspect ratio for non-insights-board charts
      'Fit-Aspect-Ratio': !isInsightsBoard && messageType === 'viz',
    },
  );

  return (
    <div
      className={displayContainerClassName}
      ref={chartRef}
      id={`chart-${contextId}`}
      title={reason}
      data-context-id={contextId}
      onClick={messageType === 'note' ? handleClick : () => {}}
      data-testid="chart-container"
    >
      <ErrorBoundary
        error={error}
        hasError={isFailed}
        type={messageTypeVersion === 2 ? 'echart' : 'chart'}
      >
        {children}
      </ErrorBoundary>
    </div>
  );
};

VisualDisplayContainer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  error: PropTypes.oneOfType([
    PropTypes.instanceOf(AxiosError),
    PropTypes.instanceOf(Error),
    PropTypes.oneOf([null]),
  ]),
  messageType: PropTypes.string.isRequired,
  messageTypeVersion: PropTypes.number,
  contextId: PropTypes.string.isRequired,
  isInsightsBoard: PropTypes.bool,
  isFailed: PropTypes.bool,
  reason: PropTypes.string,
  autoScroll: PropTypes.bool,
  clickToScroll: PropTypes.bool,
  userMsgId: PropTypes.number,
};

VisualDisplayContainer.defaultProps = {
  className: undefined,
  error: null,
  isInsightsBoard: false,
  isFailed: false,
  reason: null,
  autoScroll: false,
  clickToScroll: false,
  userMsgId: 0,
  messageTypeVersion: undefined,
};

export default VisualDisplayContainer;
