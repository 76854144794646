/* GET NOTIFICATIONS */
export const GET_SEEN_NOTIFICATIONS_REQUEST = 'notification/GET_SEEN_NOTIFICATIONS_REQUEST';
export const GET_SEEN_NOTIFICATIONS_FAILURE = 'notification/GET_SEEN_NOTIFICATIONS_FAILURE';
export const GET_SEEN_NOTIFICATIONS_SUCCESS = 'notification/GET_SEEN_NOTIFICATIONS_SUCCESS';

export const GET_UNSEEN_NOTIFICATIONS_REQUEST = 'notification/GET_UNSEEN_NOTIFICATIONS_REQUEST';
export const GET_UNSEEN_NOTIFICATIONS_FAILURE = 'notification/GET_UNSEEN_NOTIFICATIONS_FAILURE';
export const GET_UNSEEN_NOTIFICATIONS_SUCCESS = 'notification/GET_UNSEEN_NOTIFICATIONS_SUCCESS';

export const GET_ALL_NOTIFICATIONS_REQUEST = 'notification/GET_ALL_NOTIFICATIONS_REQUEST';
export const GET_ALL_NOTIFICATIONS_FAILURE = 'notification/GET_ALL_NOTIFICATIONS_FAILURE';
export const GET_ALL_NOTIFICATIONS_SUCCESS = 'notification/GET_ALL_NOTIFICATIONS_SUCCESS';

/* ACKNOWLEDGE OR TERMINATE NOTIFICATION */
export const ACKNOWLEDGE_NOTIFICATION_REQUEST = 'notification/ACKNOWLEDGE_NOTIFICATION_REQUEST';
export const TERMINATE_NOTIFICATION_REQUEST = 'notification/TERMINATE_NOTIFICATION_REQUEST';

/* CREATE NOTIFICATION */
export const CREATE_NOTIFICATION_REQUEST = 'notification/CREATE_NOTIFICATION_REQUEST';
export const CREATE_NOTIFICATION_FAILURE = 'notification/CREATE_NOTIFICATION_FAILURE';
export const CREATE_NOTIFICATION_SUCCESS = 'notification/CREATE_NOTIFICATION_SUCCESS';

/**
 * Dispatches a request for all seen notifications
 */
export const getSeenNotificationsRequest = () => {
  return {
    type: GET_SEEN_NOTIFICATIONS_REQUEST,
  };
};

/**
 * Dispatches a request for all unseen notifications
 */
export const getUnseenNotificationsRequest = () => {
  return {
    type: GET_UNSEEN_NOTIFICATIONS_REQUEST,
  };
};

/**
 * Dispatches a request for all notifications
 */
export const getAllNotificationsRequest = () => {
  return {
    type: GET_ALL_NOTIFICATIONS_REQUEST,
  };
};

/**
 * Dispatches a request to record that a user viewed or acknowledged a notification.
 */
export const acknowledgeNotificationRequest = ({ notificationIds }) => ({
  type: ACKNOWLEDGE_NOTIFICATION_REQUEST,
  notificationIds,
});

/**
 * Dispatches a request to record that a user no longer wishes to see a notification.
 */
export const terminateNotificationRequest = ({ notificationId }) => ({
  type: TERMINATE_NOTIFICATION_REQUEST,
  notificationId,
});

/**
 * Indicates that the request to retrieve seen notification has failed.
 */
export const getSeenNotificationsFailure = ({ error }) => ({
  type: GET_SEEN_NOTIFICATIONS_FAILURE,
  error,
});

/**
 * Indicates that the seen notification request succeeded.
 */
export const getSeenNotificationsSuccess = ({ seenNotifications }) => ({
  type: GET_SEEN_NOTIFICATIONS_SUCCESS,
  seenNotifications,
});

/**
 * Indicates that the request to retrieve unseen notifications has failed.
 */
export const getUnseenNotificationsFailure = ({ error }) => ({
  type: GET_UNSEEN_NOTIFICATIONS_FAILURE,
  error,
});

/**
 * Indicates that the unseen notifications request succeeded.
 */
export const getUnseenNotificationsSuccess = ({ unseenNotifications }) => ({
  type: GET_UNSEEN_NOTIFICATIONS_SUCCESS,
  unseenNotifications,
});

/**
 * Indicates that the request to retrieve unseen notifications has failed.
 */
export const getAllNotificationsFailure = ({ error }) => ({
  type: GET_ALL_NOTIFICATIONS_FAILURE,
  error,
});

/**
 * Indicates that the unseen notifications request succeeded.
 */
export const getAllNotificationsSuccess = ({ allNotifications }) => ({
  type: GET_ALL_NOTIFICATIONS_SUCCESS,
  allNotifications,
});

/**
 * Create a new notification (management users only)
 */
export const createNotificationRequest = ({ newNotification }) => {
  return {
    type: CREATE_NOTIFICATION_REQUEST,
    newNotification,
  };
};

/**
 * Create Notification was a success
 */
export const createNotificationSuccess = () => ({
  type: CREATE_NOTIFICATION_SUCCESS,
});

/**
 * Create Notification was a Failure
 */
export const createNotificationFailure = ({ error }) => ({
  type: CREATE_NOTIFICATION_FAILURE,
  error,
});

/**
 * Get Payment Notification
 */
export const getSubscriptions = ({ error }) => ({
  type: CREATE_NOTIFICATION_FAILURE,
  error,
});
