import isNaN from 'lodash/isNaN';
import { formatValue } from '../../../utils/formatting/column_formatting';
import { COLUMN_TYPES } from '../../../utils/formatting/constants';
import { isNumericRepresentationType, REPRESENTATION_TYPES } from '../../../utils/formatting/type';
import {
  BOOL_STATS,
  ColumnStatKey,
  DATE_STATS,
  DEFAULT_STATS,
  NULL_THRESHOLD,
  NUMERIC_STATS,
  STAT_UNAVAILABLE,
  STR_STATS,
  ValueClass,
} from './constant';

/**
 * Get the keys for the column statistics based on the column type
 * @param type - the column type
 * @returns the keys for the column statistics
 */
export const getKeys = (type: string): string[] => {
  if (isNumericRepresentationType(type)) {
    return NUMERIC_STATS;
  } else if (type === REPRESENTATION_TYPES.STRING) {
    return STR_STATS;
  } else if (type === REPRESENTATION_TYPES.DATE || type === REPRESENTATION_TYPES.TIMESTAMP) {
    return DATE_STATS;
  } else if (type === REPRESENTATION_TYPES.BOOL) {
    return BOOL_STATS;
  }
  return DEFAULT_STATS;
};

/**
 * Get the class name for the value in the column statistics
 * @param percent - the percentage of null values
 * @returns the class name for the value
 */
const getValueClass = (percent: number): ValueClass => {
  return percent >= NULL_THRESHOLD.HIGH
    ? ValueClass.WARN_HIGH
    : percent >= NULL_THRESHOLD.MEDIUM
    ? ValueClass.WARN_MEDIUM
    : ValueClass.VALUE;
};

/**
 * Get the formatted value for the column statistics
 * @param columnStat - a map of column statistic keys and their values
 * @param key - the key of the column statistic
 * @param colType - the column type
 * @returns the formatted value, tooltip value, and value class name
 */
export const getFormattedStat = (
  columnStat: { [key: string]: any },
  key: string,
  colType: string,
) => {
  let value = columnStat[key];
  let tooltipValue = value;
  let valueClassName = ValueClass.VALUE;
  const numericValue = value !== undefined && value !== '' && value !== null ? Number(value) : NaN;

  switch (key) {
    case ColumnStatKey.Mean:
      if (typeof value === 'string') {
        value = value.replace(',', '');
      }
      if (isNaN(numericValue))
        return { value: STAT_UNAVAILABLE, tooltipValue: STAT_UNAVAILABLE, valueClassName };
      value = formatValue({
        value: numericValue,
        columnType: COLUMN_TYPES.FLOAT,
      });
      tooltipValue = value;
      break;
    case ColumnStatKey.ApproxUnique:
      if (typeof value !== 'string') {
        if (isNaN(numericValue))
          return { value: STAT_UNAVAILABLE, tooltipValue: STAT_UNAVAILABLE, valueClassName };
        value = formatValue({
          value: numericValue,
          columnType: COLUMN_TYPES.FLOAT,
        });
      }
      tooltipValue = `${value} (Approx)`;
      break;
    case ColumnStatKey.Null: {
      // Get the count of non null values from the columnStat
      const numericCount =
        columnStat?.Count !== undefined && columnStat?.Count !== '' && columnStat?.Count !== null
          ? Number(columnStat.Count.replace(',', ''))
          : NaN;

      // if we have bad values, return N/A
      if (isNaN(numericCount) || isNaN(numericValue))
        return { value: STAT_UNAVAILABLE, tooltipValue: STAT_UNAVAILABLE, valueClassName };

      // Get the total number of rows
      const numRows = numericCount + numericValue;

      // Calculate the percentage of null values
      const percent = formatValue({
        value: (numericValue / numRows) * 100,
        columnType: COLUMN_TYPES.FLOAT,
      });

      // Set the value, tooltip value, and value class name
      tooltipValue = `${value} (${percent}%)`;
      value = `${percent}%`;
      valueClassName = getValueClass(Number(percent));
      break;
    }
    default: {
      if (isNumericRepresentationType(colType)) {
        // Default for numeric columns
        if (isNaN(numericValue))
          return { value: STAT_UNAVAILABLE, tooltipValue: STAT_UNAVAILABLE, valueClassName };
        value = formatValue({
          value: numericValue,
          columnType: COLUMN_TYPES.FLOAT,
        });
        tooltipValue = value;
      }
    }
  }
  return { value, tooltipValue, valueClassName };
};
