import PropTypes from 'prop-types';
import React from 'react';

import AlertBanner from '../DisplayPanel/Visuals/AlertBanner';
import HeaderInfo from '../DisplayPanel/Visuals/HeaderInfo';
import VisualContainer from '../DisplayPanel/Visuals/VisualContainer';
import VisualDisplayContainer from '../DisplayPanel/Visuals/VisualDisplayContainer';
import VisualDisplayFilter from '../DisplayPanel/Visuals/VisualDisplayFilter';
import VisualHeaderContainer from '../DisplayPanel/Visuals/VisualHeaderContainer';

import { useAlertInfo } from '../DisplayPanel/Visuals/helpers/visualCustomHooks';

/**
 * A functional component to render
 * the sliced workflow pop out window
 * after click the button on the header in a session
 */
const SlicedWorkflowPopOut = (props) => {
  const { objectId, contextId, chart } = props;

  // state to control alert info for echarts
  const [alertOpen, alertText, openCaptionAlert] = useAlertInfo();

  const { type, typeVersion, data } = chart;

  return (
    <VisualContainer objectId={objectId} contextId={contextId} messageType={type}>
      <VisualHeaderContainer messageType={type}>
        <HeaderInfo objectId={objectId} messageType={type} data={data} />
      </VisualHeaderContainer>
      <AlertBanner alertOpen={alertOpen} alertText={alertText} />
      <VisualDisplayContainer
        messageType={type}
        messageTypeVersion={typeVersion}
        contextId={contextId}
      >
        <VisualDisplayFilter
          chart={chart}
          contextId={contextId}
          openCaptionAlert={openCaptionAlert}
        />
      </VisualDisplayContainer>
    </VisualContainer>
  );
};

SlicedWorkflowPopOut.propTypes = {
  objectId: PropTypes.string,
  contextId: PropTypes.string.isRequired,
  chart: PropTypes.object.isRequired,
};

SlicedWorkflowPopOut.defaultProps = {
  objectId: '',
};

export default SlicedWorkflowPopOut;
