import CheckIcon from '@mui/icons-material/Check';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import React from 'react';
import './SubMenuItem.scss';

interface SubMenuItemProps {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  label: string;
  showCheckMark?: boolean;
  exampleText?: string;
  trailingIcon?: React.FC;
  startIcon?: React.FC<{ className?: string }>;
  noCheckMarks?: boolean;
}

const SubMenuItem: React.FC<SubMenuItemProps> = ({
  onClick,
  showCheckMark,
  label,
  exampleText,
  trailingIcon: TrailingIcon,
  startIcon: StartIcon,
  noCheckMarks,
}) => {
  return (
    <MenuItem
      onClick={onClick}
      className={`SubMenuItem${TrailingIcon ? ' NoEndPadding' : ''}${
        !noCheckMarks ? ' NoStartPadding' : ''
      }`}
    >
      <div>
        {!noCheckMarks && (
          <CheckIcon
            className={`Checkmark ${showCheckMark ? 'selected-option' : 'unselected-option'}`}
          />
        )}
        {StartIcon && <StartIcon className="StartIcon" />}
        <Typography>{label}</Typography>
      </div>
      <span className="example-text">{exampleText}</span>
      {TrailingIcon && <TrailingIcon />}
    </MenuItem>
  );
};

SubMenuItem.defaultProps = {
  exampleText: '', // No example Text
  trailingIcon: undefined, // No trailing Icon
  startIcon: undefined, // No start Icon
  noCheckMarks: false,
  showCheckMark: false,
};

export default SubMenuItem;
