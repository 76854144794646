import {
  ERROR_EXPLAINED,
  GEL_CODE_TYPE,
  PYTHON_CODE_TYPE,
  SQL_CODE_TYPE,
} from '../../constants/askAva';
import {
  ASK_QUESTION_FROM_GRID_SUCCESS,
  CLOSE_ASK_AVA_ALERT,
  EDIT_ASK_AVA_CACHE_SUCCESS,
  GENERATE_ASK_AVA_SOLUTION_SUCCESS,
  HIDE_ASK_AVA_STEPS,
  RESET_ASK_AVA,
  SET_ASK_AVA_CACHED_GEL,
  SET_ASK_AVA_SELECTED_DATASETS,
  SET_ASK_AVA_SESSION_CONTEXT,
  SET_ASK_AVA_SOLUTION_COMPLETED,
  SET_ASK_AVA_SOLUTION_FAILED,
  SET_ASK_AVA_UPDATED_GEL,
  SET_ASK_QUESTION_PARAMS,
  SHOW_ASK_AVA_STEPS,
  SUBMIT_ASK_AVA_QUERY_FAILURE,
  SUBMIT_ASK_AVA_QUERY_REQUEST,
  SUBMIT_ASK_AVA_QUERY_SUCCESS,
  SUBMIT_ASK_AVA_USER_FEEDBACK_FAILURE,
  SUBMIT_ASK_AVA_USER_FEEDBACK_REQUEST,
  SUBMIT_ASK_AVA_USER_FEEDBACK_SUCCESS,
} from '../actions/askAva.action';

// initial state for the generated code results
const INITIAL_GEN_CODE_STATE = {
  submitted: false, // - submitted: true if user successfully generates code for the language
  score: null,
  alert: false,
  originalCode: [], // - original response generated by the llm
  lastCachedCode: [], // - most recent cached response
  code: [], // - code: array containing generated code
  feedbackVisible: false, // - feedbackVisible: set to true when user generates code. set to false when user submits feedback
  prompt: null,
  isSavedEdited: true, // whether the user has edited a saved version of the generated code recipe
  finalFailed: false, // whether the generated code failed in execution
  originalFailed: false, // whether the *original* generated code failed in execution
  finalCompleted: false, // whether the generated code executed to the end
  originalCompleted: false, // whether the *original* generated code executed to the end
};

// helper function which returns the generated code, formatted for the redux state.
const getFormattedResults = (generatedCode, score, prompt, summary) => {
  // if the generated code is empty, and the summary is not null, then the error is explained
  const isErrorExplained = generatedCode.length === 0 && summary !== null;
  return {
    ...INITIAL_GEN_CODE_STATE,
    submitted: true,
    score: isErrorExplained ? ERROR_EXPLAINED : score,
    alert: !isErrorExplained,
    originalCode: generatedCode,
    code: generatedCode,
    lastCachedCode: generatedCode,
    prompt,
    feedbackVisible: true,
  };
};

const initialState = {
  sessionId: null, // sesion context for ask ava state
  selectedLanguage: GEL_CODE_TYPE, // current selected language tab in module
  requestingGeneratedCode: false, // Boolean that indicates if FE is requesting generated code from LLM
  generatingSolution: false, // Boolean that indicates if LLM results are processing or LLM-generated steps are being run in a session
  showSteps: false, // Boolean that indicates whether solution is being shown to user
  solutionComplete: false, // Boolean that indicates if all LLM-generated steps have been run in a session
  generatedCode: {
    [GEL_CODE_TYPE]: INITIAL_GEN_CODE_STATE,
    [PYTHON_CODE_TYPE]: INITIAL_GEN_CODE_STATE,
    [SQL_CODE_TYPE]: INITIAL_GEN_CODE_STATE,
  },
  requestId: null, // id of the query request to the server
  submittedQuery: '', // when a user submits a code gen request, this is saved.
  savingRecipe: false, // true if user decides to save their generated code
  feedback: null, // can be either 1 or -1 - indicates user feedback for generated code
  isSubmittingFeedback: false, // boolean to indicate if request is being made to server.
  selectedDatasets: [], // currently selected datasets in form
  submittedSelectedDatasets: [], // datasets a user has has when asking question
  usedDatasets: [], // datasets returned from server that were used to generatate the code
  apiCode: [], // python code returned from server
  recipeName: '', // name of recipe in save dialog
  summary: null, // summary response from server
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ASK_AVA_SESSION_CONTEXT:
      return {
        ...state,
        sessionId: action.sessionId,
      };
    case SET_ASK_QUESTION_PARAMS:
      return {
        ...state,
        submittedQuery: action.query,
        submittedSelectedDatasets: action.selectedDatasets,
        solutionComplete: false,
        showSteps: false,
      };
    case SUBMIT_ASK_AVA_QUERY_REQUEST:
      return {
        ...state,
        requestingGeneratedCode: true,
      };
    case SUBMIT_ASK_AVA_QUERY_SUCCESS: {
      // set generated code to be a new object if the submitted query doesn't match the previously submitted query
      // if querys match, update the existing generated code object with the results from that request.
      const { generatedCode, score, prompt, summary } = action;
      const formattedGeneratedCode = getFormattedResults(generatedCode, score, prompt, summary);
      const newGeneratedCode =
        action.query === state.submittedQuery
          ? {
              ...state.generatedCode,
              [action.language]: formattedGeneratedCode,
            }
          : {
              ...initialState.generatedCode,
              [action.language]: formattedGeneratedCode,
            };
      return {
        ...state,
        requestingGeneratedCode: false,
        generatedCode: newGeneratedCode,
        requestId: action.requestId,
        submittedQuery: action.question,
        feedback: null,
        usedDatasets: action.usedDatasets,
        submittedSelectedDatasets: action.selectedDatasets,
        apiCode: action.apiCode,
        vectorId: action.vectorId,
        summary,
      };
    }
    case SUBMIT_ASK_AVA_QUERY_FAILURE:
      return {
        ...state,
        generatedCode: initialState.generatedCode,
        summary: initialState.summary,
        requestingGeneratedCode: false,
      };
    case SUBMIT_ASK_AVA_USER_FEEDBACK_REQUEST:
      return { ...state, isSubmittingFeedback: true, feedback: action.vote };
    case SUBMIT_ASK_AVA_USER_FEEDBACK_SUCCESS:
      return {
        ...state,
        isSubmittingFeedback: false,
        generatedCode: {
          ...state.generatedCode,
          [action.language]: {
            ...state.generatedCode[action.language],
            feedbackVisible: false,
          },
        },
      };
    case SUBMIT_ASK_AVA_USER_FEEDBACK_FAILURE:
      return { ...state, isSubmittingFeedback: false, error: action.error, feedback: null };
    case RESET_ASK_AVA:
      return { ...initialState, open: state.open };
    case CLOSE_ASK_AVA_ALERT: {
      const newGeneratedCodeObject = { ...state.generatedCode };
      newGeneratedCodeObject[state.selectedLanguage].alert = false;
      return {
        ...state,
        generatedCode: newGeneratedCodeObject,
      };
    }
    case SET_ASK_AVA_SELECTED_DATASETS:
      return {
        ...state,
        selectedDatasets: action.datasets,
      };
    case GENERATE_ASK_AVA_SOLUTION_SUCCESS:
      return { ...state, solutionComplete: true };
    case SHOW_ASK_AVA_STEPS:
      return {
        ...state,
        showSteps: true,
      };
    case HIDE_ASK_AVA_STEPS:
      return {
        ...state,
        showSteps: false,
      };
    case SET_ASK_AVA_UPDATED_GEL:
      return {
        ...state,
        generatedCode: {
          ...state.generatedCode,
          [state.selectedLanguage]: {
            ...state.generatedCode[state.selectedLanguage],
            code: action.code,
            isSavedEdited: true,
            // reset finalFailed and finalCompleted flags
            // as the now edited solution has not been run yet
            finalFailed: false,
            finalCompleted: false,
          },
        },
      };
    case SET_ASK_AVA_CACHED_GEL:
      return {
        ...state,
        generatedCode: {
          ...state.generatedCode,
          [state.selectedLanguage]: {
            ...state.generatedCode[state.selectedLanguage],
            lastCachedCode: action.code,
          },
        },
      };
    case SET_ASK_AVA_SOLUTION_FAILED:
      return {
        ...state,
        generatedCode: {
          ...state.generatedCode,
          [state.selectedLanguage]: {
            ...state.generatedCode[state.selectedLanguage],
            // only set originalFailed to true if the code is not edited
            originalFailed: action.isEdited
              ? state.generatedCode[state.selectedLanguage].originalFailed
              : true,
            finalFailed: true,
          },
        },
      };
    case SET_ASK_AVA_SOLUTION_COMPLETED:
      return {
        ...state,
        generatedCode: {
          ...state.generatedCode,
          [state.selectedLanguage]: {
            ...state.generatedCode[state.selectedLanguage],
            // only set originalCompleted to true if the code is not edited
            originalCompleted: action.isEdited
              ? state.generatedCode[state.selectedLanguage].originalCompleted
              : true,
            finalCompleted: true,
          },
        },
      };
    case EDIT_ASK_AVA_CACHE_SUCCESS:
      if (action.newVectorId) {
        return {
          ...state,
          vectorId: action.newVectorId,
        };
      }
      return state;
    case ASK_QUESTION_FROM_GRID_SUCCESS:
      return {
        ...state,
        generatedCode: initialState.generatedCode,
      };
    default:
      return state;
  }
};
