import People from '@mui/icons-material/People';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { HOME_OBJECT_KEYS } from '../constants/home_screen';

type Props = {
  rowData: Object,
};

const SharedIndicator = ({ rowData }: Props) =>
  rowData[HOME_OBJECT_KEYS.IS_SHARED] && (
    <Tooltip title={`Owned by ${rowData[HOME_OBJECT_KEYS.OWNER_NAME]}`}>
      <People className="SharedIndicator" fontSize="small" />
    </Tooltip>
  );

export default SharedIndicator;
