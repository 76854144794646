import { InputType } from '../../types/chartBuilderIO';
import { ColumnFilters, FilterParams } from '../../types/columnSelection';
import {
  isNumeric,
  mergeFilters,
  notDuplicatedWithXAxis,
  notDuplicatedWithYAxis,
  onlyDuplicatedWithOtherSelections,
} from '../../utils/columnSelection';

/**
 * Manages the column selection filters for the Chart Builder inputs.
 */
export default class ColumnSelection {
  /** Filter the Chart Builder column options per input on all chart types. */
  private static globalFilters: Partial<ColumnFilters> = {
    [InputType.x]: [notDuplicatedWithYAxis],
    [InputType.y]: [notDuplicatedWithXAxis],
    [InputType.overlay]: [isNumeric],
    [InputType.label]: [onlyDuplicatedWithOtherSelections],
  };

  /** Filter the Chart Builder column options per input per chart type. */
  filters: Partial<ColumnFilters>;

  constructor(filters: Partial<ColumnFilters>) {
    // Merge the global filters with the provided filters
    this.filters = mergeFilters(ColumnSelection.globalFilters, filters);
  }

  /**
   * Filter the Chart Builder column options.
   * @param input - The input in which we are selecting a column
   * @param fields - The Chart Builder's input/column selection pairs
   * @param cols - The column type information
   */
  runColumnFilters = ({ input, fields, cols }: FilterParams) => {
    // Get the filters for the input
    const filters = this.filters[input] ?? [];
    // Apply the filters, updating the accumulator (cols) per filter
    return filters.reduce((acc, filter) => filter({ input, fields, cols: acc }), cols);
  };
}
