import axios from 'axios';
import qs from 'qs';
import { dcAppServiceEndpoints, endpoints } from '../constants/endpoints';
import { handleDCAppServiceCall } from '../utils/errorHandling/errorHandlers.api';
import { addAuthToAxiosConfig } from './datasets.api';

export const getInsightsBoardsList = (accessToken) =>
  axios.get(`${endpoints.insightsBoard}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

// Retrieve all of the cross filters for the specified IB
export const getInsightsBoardCrossFilters = (accessToken, insightsBoardId) => {
  return axios.get(
    endpoints.insightsBoardCrossFilters(insightsBoardId),
    addAuthToAxiosConfig(accessToken, {}),
  );
};
/**
 * Save the specified cross filters for the specified IB
 * cross_filter_groups (update), cross_filters_to_remove (delete), groups_to_remove (delete)
 * Returns the updated cross filter information
 */
export const saveInsightsBoardCrossFilters = (
  accessToken,
  insightsBoardId,
  // eslint-disable-next-line camelcase
  { cross_filter_groups, cross_filters_to_remove, groups_to_remove },
) =>
  axios.patch(
    endpoints.insightsBoardCrossFilters(insightsBoardId),
    {
      cross_filter_groups,
      cross_filters_to_remove,
      groups_to_remove,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

export const getInsightsBoardVariables = (accessToken, id) =>
  axios.get(`${endpoints.insightsBoard}/${id}/variables`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

export const getWorkflowArguments = (accessToken, id) =>
  axios.get(`${endpoints.insightsBoard}/${id}/workflow_arguments`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

export const postAnnotation = (accessToken, insightsBoardId, text, color) =>
  axios.post(
    `${endpoints.insightsBoard}/${insightsBoardId}/annotation`,
    qs.stringify({ text, color }),
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );

export const putAnnotation = (accessToken, insightsBoardId, publicationId, text, color) =>
  axios.put(
    `${endpoints.insightsBoard}/${insightsBoardId}/annotation`,
    qs.stringify({ id: publicationId, text, color }),
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );

export const linkVariable = (accessToken, id, workflowArgumentIDs, variableID) =>
  axios.put(
    `${endpoints.insightsBoard}/${id}/variable/${variableID}/workflow_arguments`,
    {},
    {
      params: {
        workflow_argument_ids: JSON.stringify(workflowArgumentIDs),
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

export const deleteInsightsBoardVariables = (accessToken, variablesList) =>
  axios.delete(`${endpoints.insightsBoard}/variable`, {
    params: {
      variable_ids: JSON.stringify(variablesList),
    },
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

export const postVariable = (accessToken, insightsBoardId, newVariable) =>
  axios.post(`${endpoints.insightsBoard}/${insightsBoardId}/variable`, newVariable, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });

export const putVariable = (accessToken, insightsBoardId, variableID, updatedVariable) =>
  axios.put(
    `${endpoints.insightsBoard}/${insightsBoardId}/variable/${variableID}`,
    updatedVariable,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    },
  );

export const getInsightsBoardLayout = (accessToken, id) =>
  axios.get(`${endpoints.insightsBoard}/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

export const getInsightsBoardLayoutAPIKey = (id, apiKey, apiSecret) =>
  axios.get(`${endpoints.insightsBoard}/${id}`, {
    params: {
      client_id: apiKey,
      secret: apiSecret,
    },
  });

export const getInsightsBoardContent = (accessToken, id, pubIds) =>
  axios.get(`${endpoints.insightsBoard}/${id}/content`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      pub_ids: JSON.stringify(pubIds),
    },
  });

export const getInsightsBoardContentAPIKey = (id, apiKey, apiSecret, pubIds) =>
  axios.get(`${endpoints.insightsBoard}/${id}/content`, {
    params: {
      client_id: apiKey,
      secret: apiSecret,
      pub_ids: JSON.stringify(pubIds),
    },
  });

export const postInsightsBoard = (accessToken, insightsBoard) =>
  axios.post(endpoints.insightsBoard, insightsBoard, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });

export const putInsightsBoard = (accessToken, id, insightsBoard) =>
  axios.put(`${endpoints.insightsBoard}/${id}`, insightsBoard, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });

export const putInsightsBoardItemFont = (
  accessToken,
  { insightsBoardId, publicationId, newFontScaling },
) =>
  axios.put(`${endpoints.insightsBoardFonts}/${insightsBoardId}/${publicationId}`, newFontScaling, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });

export const deleteInsightsBoard = (accessToken, id) =>
  axios.delete(`${endpoints.insightsBoard}/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

export const getUsersInOrganization = (accessToken, organizationID) =>
  axios.get(`${endpoints.organization}/${organizationID}/user`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

// Kept for existing code
export const getUsersInOrganizationAPI = async (accessToken, organizationID) => {
  const response = await fetch(`${endpoints.organization}/${organizationID}/user`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getUsersWithAccessAPI = async (accessToken, insightsBoardId) => {
  const response = await fetch(`${endpoints.insightsBoard}/${insightsBoardId}/user`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const putShareInsightsBoardAPI = async (accessToken, insightsBoardId, userData) => {
  await fetch(`${endpoints.insightsBoard}/${insightsBoardId}/user/${userData.id}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });
};

export const deleteInsightsBoardAccessAPI = async (accessToken, insightsBoardId, userData) => {
  await fetch(`${endpoints.insightsBoard}/${insightsBoardId}/user/${userData.id}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });
};
export const refreshInsightsBoardPublication = (accessToken, pubId, parameters = {}) =>
  axios.post(`${endpoints.embedChart}/${pubId}/refresh`, parameters, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

export const getRefreshPublicationStatus = (accessToken, pubId, refreshId) =>
  axios.get(`${endpoints.embedChart}/${pubId}/refresh/${refreshId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

export const getSnapshotList = (accessToken) =>
  axios.get(`${endpoints.snapshot}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

export const deleteInsightsBoardPublications = (accessToken, insightsBoardId, publicationIdList) =>
  axios.delete(endpoints.insightsBoardPublications(insightsBoardId), {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    data: {
      publication_id_list: publicationIdList,
    },
  });

export const deleteInsightsBoardCharts = (accessToken, dcChartIdList) =>
  handleDCAppServiceCall(() =>
    axios.delete(dcAppServiceEndpoints.deleteCharts, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        chart_id_list: dcChartIdList,
      },
    }),
  );

export const getInsightsBoardDcChartIDs = (accessToken, insightsBoardId) =>
  axios.get(`${endpoints.insightsBoardCharts}/${insightsBoardId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

export const postPublicationCustomizations = (accessToken, insightsBoardId, customizations) =>
  axios.post(
    `${endpoints.table}`,
    {
      insightsBoardId,
      customizations,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
