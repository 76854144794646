import Cable from '@mui/icons-material/Cable';
import Close from '@mui/icons-material/Close';
import TableChart from '@mui/icons-material/TableChart';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HOME_OBJECT_KEYS } from '../../../constants/home_screen';
import {
  selectDatabaseBrowserOpenConnection,
  selectPreviewColumnData,
  selectPreviewTableData,
  selectPreviewTables,
  selectSelectedPreview,
  selectTableLastUpdated,
  selectTableMetadataError,
  selectTableMetadataRequestStatus,
} from '../../../store/selectors/dbBrowser.selector';
import {
  refreshPreviewTable,
  removePreviewTable,
  selectPreviewTable,
} from '../../../store/slices/dbBrowser.slice';
import { RequestStatus } from '../../../types/databaseBrowser.types';
import { isStatusRequesting, isStatusSuccess } from '../../../utils/home_screen';
import { HomeObjectKeys } from '../../../utils/homeScreen/types';
import RefreshButton from '../../RefreshButton';
import RefreshText from '../../RefreshText/RefreshText';
import StatusPlaceholder from '../../StatusPlaceholder';
import './DatabaseBrowserPreview.scss';
import DatabasePreviewTable from './DatabasePreviewTable';

// Id for keys, etc. of schema and table name
const schemaTableId = ({ namespace, table }) => `${namespace}-${table}`;

// Renders the tabs for the tables that have been selected for preview,
// AND the sample rows for the the active selected table.
const DatabaseBrowserPreview = () => {
  const dispatch = useDispatch();

  const connection = useSelector(selectDatabaseBrowserOpenConnection);
  const connId = connection?.[HomeObjectKeys.UUID];
  const selectedPreviewTable = useSelector(selectSelectedPreview(connId)) || {};
  const { namespace, table } = selectedPreviewTable;
  const tableRowData = useSelector(selectPreviewTableData(connId, namespace, table));
  const tableColumnData = useSelector(selectPreviewColumnData(connId, namespace, table));
  const errorMessage = useSelector(selectTableMetadataError(connId, namespace, table));
  const tableRowDataEmpty = tableRowData?.length === 0;
  const tableRowDataStatus = useSelector(
    selectTableMetadataRequestStatus(connId, namespace, table),
  );

  const currentTableRowLastUpdated = useSelector(selectTableLastUpdated(connId, namespace, table));
  const refreshTable = (tableName) => dispatch(refreshPreviewTable(namespace, tableName));
  const previewTables = useSelector(selectPreviewTables(connId, namespace));

  const handleCloseTab = (e, previewTable) => {
    e.stopPropagation();
    dispatch(removePreviewTable({ namespace, table: previewTable }));
  };

  const getRefreshButton = (tableName) => (
    <Box className="DatabaseBrowser-Preview-Refresh-Container">
      <RefreshButton
        tooltip="Refresh Table Preview"
        disabled={isStatusRequesting(tableRowDataStatus)}
        status={tableRowDataStatus}
        requestingPlaceholder="Fetching metadata"
        onClick={() => refreshTable(tableName)}
      />
      <RefreshText lastUpdated={currentTableRowLastUpdated} status={tableRowDataStatus} />
    </Box>
  );

  if (selectedPreviewTable.table) {
    return (
      <Box
        sx={{ width: '100%' }}
        data-testid="DatabaseBrowser-Preview"
        className="DatabaseBrowser-Preview"
      >
        <Tabs
          value={schemaTableId(selectedPreviewTable)}
          component="div"
          variant="scrollable"
          scrollButtons="auto"
        >
          {previewTables.map((previewTable) => (
            <Tab
              key={`db-browser-preview-${namespace}-${previewTable}`}
              sx={{ position: 'relative' }}
              value={`${namespace}-${previewTable}`}
              label={previewTable}
              onClick={() => dispatch(selectPreviewTable({ namespace, table: previewTable }))}
              icon={
                <div
                  className="DatabaseBrowser-Preview-TabClose"
                  onClick={(e) => handleCloseTab(e, previewTable)}
                >
                  <Close fontSize="small" />
                </div>
              }
              iconPosition="end"
              disableRipple
            />
          ))}
        </Tabs>
        {!isStatusSuccess(tableRowDataStatus) || tableRowDataEmpty ? (
          <>
            <StatusPlaceholder
              status={tableRowDataStatus}
              errorText={errorMessage}
              retryFn={() => refreshTable(selectedPreviewTable.table)}
              skeletonProps={{ variant: 'rounded', width: '100%', height: '100%' }}
              isEmpty={tableRowDataEmpty}
              emptyText="No rows in table."
            />
            {tableRowDataStatus === RequestStatus.Failure
              ? getRefreshButton(selectedPreviewTable.table)
              : null}
          </>
        ) : (
          <DatabasePreviewTable
            tableId={`${connection[HOME_OBJECT_KEYS.UUID]}-${selectedPreviewTable}`}
            rows={tableRowData}
            columns={tableColumnData}
          />
        )}
        {currentTableRowLastUpdated &&
          isStatusSuccess(tableRowDataStatus) &&
          getRefreshButton(selectedPreviewTable.table)}
      </Box>
    );
  }
  return (
    <Box className="DatabaseBrowser-Preview-Placeholder">
      <Box className="DatabaseBrowser-Preview-Icon-Container">
        {connection ? <TableChart /> : <Cable />}
      </Box>
      <Typography variant="h6">
        {connection ? 'Click a table to show a preview' : 'Select a database'}
      </Typography>
    </Box>
  );
};

export default DatabaseBrowserPreview;
