import axios from 'axios';
import { endpoints, stripeEndpoints } from '../constants/endpoints';

export const hasSubscriptionAccess = (accessToken) =>
  axios.get(`${endpoints.showSubscriptionPopup}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

export const getCurrentSubscription = (accessToken) =>
  axios.get(`${stripeEndpoints.currentSubscription}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

export const getSubscriptions = (accessToken) =>
  axios.get(`${stripeEndpoints.getSubscriptions}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });

export const getInvoices = (accessToken) =>
  axios.get(`${stripeEndpoints.getInvoices}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });

export const getUpcomingInvoice = (accessToken) =>
  axios.get(`${stripeEndpoints.upcomingInvoice}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });

export const getSubscriptionCustomerId = (accessToken) =>
  axios.get(`${stripeEndpoints.getCustomerId}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });

export const getUsageStats = (accessToken, metric) =>
  axios.get(`${endpoints.usageStats(metric)}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

export const createCustomer = (accessToken) =>
  axios.post(
    stripeEndpoints.createCustomer,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

export const createSubscription = (accessToken, payload) =>
  axios.post(stripeEndpoints.createSubscription, payload, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });

export const updateSubscription = (accessToken, payload) =>
  axios.post(stripeEndpoints.updateSubscription, payload, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });

export const getSubscriptionPrice = (
  accessToken,
  {
    productName,
    yearly,
    address = {}, // Required if user is new (no customer id), should include line1,city,state,postal_code
  },
) =>
  axios.get(stripeEndpoints.subscriptionPrice, {
    headers: { Authorization: `Bearer ${accessToken}` },
    params: { productName, yearly, ...address },
  });

export const attachPaymentMethod = (accessToken, paymentMethodId) =>
  axios.put(
    stripeEndpoints.attachPaymentMethod,
    {
      paymentMethodId,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

export const getPaymentMethods = (accessToken) =>
  axios.get(stripeEndpoints.paymentMethods, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });

export const cancelSubscription = (accessToken) =>
  axios.post(
    stripeEndpoints.cancelSubscription,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
