import Close from '@mui/icons-material/Close';
import Search from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Tooltip from '@mui/material/Tooltip';
import debounce from 'lodash/debounce';
import React, { useMemo, useRef, useState } from 'react';
import { KEYBOARD_KEYS } from '../../constants';
import { DEBOUNCE_TIME } from './search_bar_utils';
import './SearchBar.scss';

type SearchBarProps = {
  className?: string;
  handleSearchChange?: null | ((arg: string) => void);
  handleSearchSubmit: (arg: string) => void;
  shouldRenderStartAdornment?: boolean;
  shouldRenderEndAdornment?: boolean;
};

const SearchBar: React.FC<SearchBarProps> = (props) => {
  const {
    className,
    handleSearchChange,
    handleSearchSubmit,
    shouldRenderStartAdornment,
    shouldRenderEndAdornment,
  } = props;

  const searchBarRef = useRef<HTMLInputElement>(null);
  const [userText, setUserText] = useState('');

  // Focus the search bar
  const handleInputClick = () => {
    if (searchBarRef.current) searchBarRef.current.focus();
  };

  // Un-focus the search bar
  const handleInputBlur = () => {
    if (searchBarRef.current) searchBarRef.current.blur();
  };

  // Handle keyboard events while focused on the search bar
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case KEYBOARD_KEYS.ENTER:
        handleSearchSubmit(userText);
        handleInputBlur();
        break;
      case KEYBOARD_KEYS.ESC:
        handleInputBlur();
        break;
      default:
        break;
    }
  };

  const debouncedChangeHandler = useMemo(
    () =>
      debounce((value: string) => {
        if (handleSearchChange) handleSearchChange(value);
      }, DEBOUNCE_TIME),
    [handleSearchChange],
  );

  // Update the search results as the user types with a debounce
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserText(e.target.value);
    debouncedChangeHandler(e.target.value);
  };

  // Clear the search bar and reset the search results
  const handleInputClear = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setUserText('');
    handleSearchSubmit('');
    handleInputBlur();
  };

  // A search icon that appears at the beginning of the search bar
  const renderStartAdornment = shouldRenderStartAdornment && (
    <InputAdornment position="start">
      <IconButton disabled size="small">
        <Search data-testid="SearchIcon" />
      </IconButton>
    </InputAdornment>
  );

  // A close icon that appears at the end of the search bar
  const renderEndAdornment = shouldRenderEndAdornment && userText && (
    <InputAdornment position="end">
      <Tooltip title="Clear">
        <span>
          <IconButton onClick={handleInputClear} size="small">
            <Close data-testid="CloseIcon" />
          </IconButton>
        </span>
      </Tooltip>
    </InputAdornment>
  );

  return (
    <OutlinedInput
      inputRef={searchBarRef}
      data-testid="SearchBar"
      className={className}
      fullWidth
      placeholder="Search"
      value={userText}
      onClick={handleInputClick}
      onChange={handleInputChange}
      onKeyDown={handleKeyDown}
      startAdornment={renderStartAdornment}
      endAdornment={renderEndAdornment}
    />
  );
};

SearchBar.displayName = 'SearchBar';

SearchBar.defaultProps = {
  className: 'SearchBar',
  handleSearchChange: null,
  shouldRenderStartAdornment: true,
  shouldRenderEndAdornment: true,
};

export default React.memo(SearchBar);
