export const LOGIN_REQUESTING = 'auth/LOGIN_REQUESTING';
export const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'auth/LOGIN_FAILURE';

export const LOGOUT_REQUESTING = 'auth/LOGOUT_REQUESTING';
export const LOGOUT_SUCCESS = 'auth/LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'auth/LOGOUT_FAILURE';
export const LOGOUT = 'auth/LOGOUT';

export const REFRESH_REQUESTING = 'auth/REFRESH_REQUESTING';
export const REFRESH_SUCCESS = 'auth/REFRESH_SUCCESS';
export const REFRESH_ERROR = 'auth/REFRESH_ERROR';

export const AUTHENTICATION_ALERT = 'auth/AUTHENTICATION_ALERT';

export const PUT_AUTHENTICATED_FAILURE = 'auth/PUT_AUTHENTICATED_FAILURE';

export const LOGIN_REGISTER_OATH_REQUEST = 'auth/LOGIN_REGISTER_OATH_REQUEST';

export const REGISTER_REQUESTING = 'auth/REGISTER_REQUESTING';
export const REGISTER_SUCCESS = 'auth/REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'auth/REGISTER_FAILURE';

export const OTP_REQUESTING = 'auth/OTP_REQUESTING';
export const OTP_SUCCESS = 'auth/OTP_SUCCESS';
export const OTP_FAILURE = 'auth/OTP_FAILURE';

export const EMAIL_RESET_TOKEN_REQUEST = 'auth/EMAIL_RESET_TOKEN_REQUEST';
export const EMAIL_RESET_TOKEN_SUCCESS = 'auth/EMAIL_RESET_TOKEN_SUCCESS';
export const EMAIL_RESET_TOKEN_FAILURE = 'auth/EMAIL_RESET_TOKEN_FAILURE';

export const RESET_PASSWORD_REQUEST = 'auth/RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'auth/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'auth/RESET_PASSWORD_FAILURE';

export const VERIFY_PASSWORD_TOKEN_REQUEST = 'auth/VERIFY_PASSWORD_TOKEN_REQUEST';

export const RESET_PASSWORD_FROM_MENU_SUCCESS = 'auth/RESET_PASSWORD_FROM_MENU_SUCCESS';
export const RESET_PASSWORD_FROM_MENU_FAILURE = 'auth/RESET_PASSWORD_FROM_MENU_FAILURE';

export const SEND_EMAIL_VERIFY_REQUEST = 'auth/SEND_EMAIL_VERIFY_REQUEST';
export const SEND_EMAIL_VERIFY_SUCCESS = 'auth/SEND_EMAIL_VERIFY_SUCCESS';
export const SEND_EMAIL_VERIFY_FAILURE = 'auth/SEND_EMAIL_VERIFY_FAILURE';

export const VERIFY_ACCOUNT_REQUEST = 'auth/VERIFY_ACCOUNT_REQUEST';
export const VERIFY_ACCOUNT_SUCCESS = 'auth/VERIFY_ACCOUNT_SUCCESS';
export const VERIFY_ACCOUNT_FAILURE = 'auth/VERIFY_ACCOUNT_FAILURE';

export const GET_PERMISSIONS_SUCCESS = 'auth/GET_PERMISSIONS_SUCCESS';

export const UPDATE_PROFILE_REQUESTING = 'auth/RENAME_REQUESTING';
export const UPDATE_PROFILE_SUCCESS = 'auth/RENAME_SUCCESS';
export const UPDATE_PROFILE_FAILURE = 'auth/RENAME_FAILURE';
export const UPDATE_PROFILE_FAILURE_ACKNOWLEDGED = 'auth/UPDATE_PROFILE_FAILURE_ACKNOWLEDGED';

export const SNOWFLAKE_LOGIN_REQUEST = 'auth/SNOWFLAKE_LOGIN_REQUEST';
export const SNOWFLAKE_LOGIN_SUCCESS = 'auth/SNOWFLAKE_LOGIN_SUCCESS';
export const SNOWFLAKE_LOGIN_FAILURE = 'auth/SNOWFLAKE_LOGIN_FAILURE';

/**
 * Dispatches action to login/register a user
 *
 * This worker currently only supports Auth0 and Google OAuth.
 *
 * @param {import('../../constants/auth').LoginRegisterRequest} params
 */
export const loginRegisterRequest = ({ authType, loginParams }) => ({
  type: LOGIN_REGISTER_OATH_REQUEST,
  authType,
  loginParams,
});

/**
 * Login a user
 *
 * @param {import("../../constants/auth").LoginRequest} params
 */
export const loginRequest = ({ authType, loginParams }) => ({
  type: LOGIN_REQUESTING,
  authType,
  loginParams,
});

export const loginSuccess = ({
  user,
  name,
  accessToken,
  accessExp,
  refreshToken,
  refreshExp,
  email,
}) => ({
  type: LOGIN_SUCCESS,
  user,
  name,
  accessToken,
  accessExp,
  refreshToken,
  refreshExp,
  email,
});

export const loginFailure = ({ error }) => ({
  type: LOGIN_FAILURE,
  error,
});

export const logoutRequest = ({ pathname } = {}) => ({
  type: LOGOUT_REQUESTING,
  pathname,
});

export const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS,
});

export const logoutFailure = ({ error }) => ({
  type: LOGOUT_FAILURE,
  error,
});

export const snowflakeLoginRequest = () => ({
  type: SNOWFLAKE_LOGIN_REQUEST,
});

export const snowflakeLoginSuccess = () => ({
  type: SNOWFLAKE_LOGIN_SUCCESS,
});

export const snowflakeLoginFailure = ({ error }) => ({
  type: SNOWFLAKE_LOGIN_FAILURE,
  error,
});

export const logout = () => ({
  type: LOGOUT,
});

export const refreshRequest = () => ({
  type: REFRESH_REQUESTING,
});

export const refreshSuccess = ({ accessToken, accessExp, refreshExp }) => ({
  type: REFRESH_SUCCESS,
  accessToken,
  accessExp,
  refreshExp,
});

export const refreshError = ({ error }) => ({
  type: REFRESH_ERROR,
  error,
});

export const authenticationAlert = ({ retryRequestAction }) => ({
  type: AUTHENTICATION_ALERT,
  retryRequestAction,
});

/**
 * Register a new user
 *
 * @param {import("../../constants/auth").RegisterRequest} params
 */
export const registerRequest = ({
  authType,
  key,
  registerParams,
  defaultHomePage,
  loginOnRegister,
  adAttribution,
}) => ({
  type: REGISTER_REQUESTING,
  authType,
  key,
  registerParams,
  defaultHomePage,
  loginOnRegister,
  adAttribution,
});

export const registerSuccess = () => ({
  type: REGISTER_SUCCESS,
});

export const registerFailure = ({ error }) => ({
  type: REGISTER_FAILURE,
  error,
});

export const otpLinkRequest = ({ email }) => ({
  type: OTP_REQUESTING,
  email,
});

export const otpLinkSuccess = () => ({
  type: OTP_SUCCESS,
});

export const otpLinkFailure = ({ error }) => ({
  type: OTP_FAILURE,
  error,
});

export const emailResetTokenRequest = ({ origin, email, isSignedIn = false }) => ({
  type: EMAIL_RESET_TOKEN_REQUEST,
  origin,
  email,
  isSignedIn,
});

export const emailResetTokenSuccess = () => ({
  type: EMAIL_RESET_TOKEN_SUCCESS,
});

export const emailResetTokenFailure = () => ({
  type: EMAIL_RESET_TOKEN_FAILURE,
});

export const resetPasswordRequest = ({ resetToken, password, confirmationPassword }) => ({
  type: RESET_PASSWORD_REQUEST,
  resetToken,
  password,
  confirmationPassword,
});

export const resetPasswordSuccess = () => ({
  type: RESET_PASSWORD_SUCCESS,
});

export const resetPasswordFailure = () => ({
  type: RESET_PASSWORD_FAILURE,
});

export const verifyPasswordToken = () => ({
  type: VERIFY_PASSWORD_TOKEN_REQUEST,
});

export const resetPasswordFromMenuSuccess = () => ({
  type: RESET_PASSWORD_FROM_MENU_SUCCESS,
});

export const resetPasswordFromMenuFailure = ({ error }) => ({
  type: RESET_PASSWORD_FROM_MENU_FAILURE,
  error,
});

export const sendEmailVerifyRequest = ({
  email,
  redirect,
  clientId,
  scope,
  state,
  redirectUri,
}) => ({
  type: SEND_EMAIL_VERIFY_REQUEST,
  email,
  redirect,
  clientId,
  scope,
  state,
  redirectUri,
});

export const sendEmailVerifyFailure = ({ error }) => ({
  type: SEND_EMAIL_VERIFY_FAILURE,
  error,
});

export const sendEmailVerifySuccess = () => ({
  type: SEND_EMAIL_VERIFY_SUCCESS,
});

export const verifyAccountRequest = ({ email, token }) => ({
  type: VERIFY_ACCOUNT_REQUEST,
  email,
  token,
});

export const verifyAccountFailure = ({ error }) => ({
  type: VERIFY_ACCOUNT_FAILURE,
  error,
});

export const verifyAccountSuccess = () => ({
  type: VERIFY_ACCOUNT_SUCCESS,
});

export const getPermissionsSuccess = ({ permissions, role }) => ({
  type: GET_PERMISSIONS_SUCCESS,
  permissions,
  role,
});

// Tried to put an action that requires authentication, but
// we weren't authenticated.
export const putAuthenticatedFailure = () => ({
  type: PUT_AUTHENTICATED_FAILURE,
});

export const updateProfileRequest = ({ name }) => ({
  type: UPDATE_PROFILE_REQUESTING,
  name,
});

export const updateProfileSuccess = ({ name }) => ({
  type: UPDATE_PROFILE_SUCCESS,
  name,
});

export const updateProfileFailure = ({ error }) => ({
  type: UPDATE_PROFILE_FAILURE,
  error,
});

export const updateProfileFailureAcknowledged = () => ({
  type: UPDATE_PROFILE_FAILURE_ACKNOWLEDGED,
});
