import {
  CLOSE_CHART_BUILDER,
  CLOSE_PIVOT_BUILDER,
  OPEN_CHART_BUILDER,
  OPEN_PIVOT_BUILDER,
} from '../actions/chart_builder.actions';

const initialState = {
  chartBuilderIsOpen: false,
  pivotBuilderIsOpen: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_CHART_BUILDER:
      return {
        ...state,
        chartBuilderIsOpen: true,
      };
    case CLOSE_CHART_BUILDER:
      return {
        ...state,
        chartBuilderIsOpen: false,
      };
    case OPEN_PIVOT_BUILDER:
      return {
        ...state,
        pivotBuilderIsOpen: true,
      };
    case CLOSE_PIVOT_BUILDER:
      return initialState;
    default:
      return state;
  }
};
