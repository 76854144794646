/*
These constants should be synced with the enum in...
${DATACHAT_ROOT}/management_server/web-server/db/models/useraction.go
 */
export const USER_ACTION_LOGIN = 'LOGIN';
export const USER_ACTION_LOGOUT = 'LOGOUT';
export const USER_ACTION_SESSION_START = 'SESSION_START';
export const USER_ACTION_SESSION_END = 'SESSION_END';
export const USER_ACTION_SESSION_JOIN = 'SESSION_JOIN';
export const USER_ACTION_REGISTER = 'USER_REGISTER';
export const USER_ACTION_SESSION_INACTIVE = 'SESSION_INACTIVE';
export const USER_ACTION_RESET_PASSWORD = 'RESET_PASSWORD';
