import DnsIcon from '@mui/icons-material/Dns';
import SettingsIcon from '@mui/icons-material/Settings';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearConnection,
  closeConnectionEditor,
  getConnectionObjectDataRequest,
  openConnectionEditorRequest,
  setEditMode,
} from '../../../../store/actions/connection.actions';
import {
  selectConnectionEditorIsOpen,
  selectConnectionEditorName,
} from '../../../../store/sagas/selectors';
import {
  selectDatabaseBrowserConnectionById,
  selectDatabaseNamspacesLastUpdatedByID,
  selectDatabaseRequestingNamespacesStatusById,
} from '../../../../store/selectors/dbBrowser.selector';
import { listNamespacesRequest } from '../../../../store/slices/dbBrowser.slice';
import { RequestStatus } from '../../../../types/databaseBrowser.types';
import RefreshButton from '../../../RefreshButton';
import TextWithTooltip from '../../../common/TextWithTooltip';
import { StickyWrapperContentProps } from '../../StickyWrapper/StickyWrapper';
import './ConnectionList.scss';
import './DatabaseItem.scss';

const DatabaseItem: React.FC<StickyWrapperContentProps> = ({ current }) => {
  const connectionUUID = current.id ?? '';
  const connectionName = current.name ?? '';
  const dispatch = useDispatch();
  const namespaceLastUpdated = useSelector(selectDatabaseNamspacesLastUpdatedByID(connectionUUID));
  const namespaceStatus = useSelector(selectDatabaseRequestingNamespacesStatusById(connectionUUID));
  const connection = useSelector(selectDatabaseBrowserConnectionById(connectionUUID));
  const connectionEditorIsOpen = useSelector(selectConnectionEditorIsOpen);
  const connectionInEdit = useSelector(selectConnectionEditorName);

  const handleRefreshNamespaces = () => {
    dispatch(listNamespacesRequest({ connectionUUID, refresh: true, page: 0 }));
  };

  const handleSettingsClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    const differentConnectionOpen = connectionEditorIsOpen && connectionInEdit !== connectionName;
    if (differentConnectionOpen || !connectionEditorIsOpen) {
      // open the connection editor or switch to a different connection
      dispatch(clearConnection());
      dispatch(
        openConnectionEditorRequest({ uuid: connectionUUID, object: connection || undefined }),
      );
      dispatch(getConnectionObjectDataRequest({ uuid: connectionUUID }));
      dispatch(setEditMode({ editMode: true }));
    } else if (connectionEditorIsOpen) {
      // close the connection editor
      dispatch(closeConnectionEditor());
    }
  };

  return (
    <>
      <Box className="DatabaseBrowser-ConnectionList-Title-Section">
        <DnsIcon className="ConnectionList-Icon" fontSize="medium" />
        <Box className="ConnectionList-Title">
          <TextWithTooltip resizeDelay={500} text={connectionName} />
        </Box>
      </Box>
      <Box className="DatabaseBrowser-ConnectionList-Title-Section shrinkable">
        <Box className="DatabaseBrowser-ConnectionList-Icon-Container">
          <RefreshButton
            lastUpdated={namespaceLastUpdated}
            status={namespaceStatus || RequestStatus.Unrequested}
            disabled={!connection}
            testId="refresh-schema"
            tooltip="Refresh Database"
            requestingPlaceholder="Fetching Namespaces"
            onClick={handleRefreshNamespaces}
          />
          <Tooltip title="Edit Connection">
            <span>
              <IconButton size="small" onClick={handleSettingsClick}>
                <SettingsIcon className="ConnectionList-Icon" fontSize="medium" />
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      </Box>
    </>
  );
};

export default DatabaseItem;
