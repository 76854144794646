import axios from 'axios';
import qs from 'qs';
import { dcAppServiceEndpoints, endpoints } from '../constants/endpoints';
import { handleDCAppServiceCall } from '../utils/errorHandling/errorHandlers.api';

type PostChatArgs = {
  accessToken: string;
  display?: unknown;
  instruction?: unknown;
  message: string;
  muted: boolean;
  sessionId: string;
  skipParse?: boolean;
  taskId: string;
  utteranceMetadata?: any;
  utteranceTimeout?: number;
};

export const postChat = (args: PostChatArgs) => {
  const {
    message,
    accessToken,
    sessionId: session,
    instruction = null,
    muted,
    display,
    skipParse,
    utteranceMetadata = {},
    utteranceTimeout,
    taskId,
  } = args;

  return axios.post(
    endpoints.chat,
    qs.stringify({
      message,
      instruction,
      muted,
      display,
      skipParse,
      utteranceMetadata: JSON.stringify(utteranceMetadata),
    }),
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      params: {
        session,
        utterance_timeout: utteranceTimeout,
        ...(taskId ? { taskId } : {}), // task is optional and only sent if provided
      },
    },
  );
};

type GetUtteranceKwargs = {
  [key: string]: any | GetUtteranceKwargs;
};

export const getUtterance = (
  accessToken: string,
  sessionId: string,
  skill: string,
  kwargs: GetUtteranceKwargs,
) =>
  handleDCAppServiceCall(() =>
    axios.post(
      dcAppServiceEndpoints.utterance,
      {
        skill,
        kwargs: {
          ...kwargs,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          sessionId,
        },
      },
    ),
  );
