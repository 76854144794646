import {
  BAD_GATEWAY,
  BAD_REQUEST,
  GATEWAY_TIMEOUT,
  INTERNAL_SERVER_ERROR,
  OK,
} from 'http-status-codes';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { getDownload } from '../../api/download.api';
import { exportDataset } from '../../api/session.api';
import { TOAST_ERROR, TOAST_LONG } from '../../constants/toast';
import { authenticate } from '../../utils/authenticate';
import { createAlertChannelRequest } from '../actions/dialog.actions';
import {
  EXPORT_DATASET_REQUEST,
  FILE_DOWNLOAD_REQUEST,
  exportDatasetFailure,
  exportDatasetSuccess,
  fileDownloadFailure,
  fileDownloadRequest,
  fileDownloadSuccess,
} from '../actions/file_download.actions';
import { addToast } from '../actions/toast.actions';
import { selectAccessToken } from './selectors';
import { selectSession } from '../selectors/session.selector';

export function* exportDatasetRequestWorker({ datasetName, version, filename }) {
  try {
    const accessToken = yield select(selectAccessToken);
    const sessionId = yield select(selectSession);
    const params = {
      sessionId,
      datasetName,
      version,
      filename,
    };
    const response = yield call(exportDataset, accessToken, params);
    yield put(exportDatasetSuccess(response.data));
    if (response.status === OK) {
      yield put(fileDownloadRequest({ file: response.data }));
    } else throw Error(response.data);
  } catch (error) {
    yield put(exportDatasetFailure(error.response.data));
    let toastMessage = `Something went wrong when exporting the file. `;
    if (
      (error.response.data.includes('Timeout') &&
        error.response.status === INTERNAL_SERVER_ERROR) ||
      error.response.status === GATEWAY_TIMEOUT ||
      error.response.status === BAD_GATEWAY
    ) {
      toastMessage += 'Connection timed out.';
    } else if (
      error.response.status === INTERNAL_SERVER_ERROR ||
      error.response.status === BAD_REQUEST
    ) {
      toastMessage += `${error.response.data}`;
    }
    yield put(
      addToast({
        toastType: TOAST_ERROR,
        length: TOAST_LONG,
        message: toastMessage,
      }),
    );
  }
}

export function* fileDownloadRequestWorker({ file }) {
  const accessToken = yield select(selectAccessToken);
  const session = yield select(selectSession);
  try {
    const response = yield getDownload(accessToken, session, file);
    const url = window.URL.createObjectURL(new Blob([response.request.response]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', file);
    document.body.appendChild(link);
    link.click();
    link.remove();
    yield put(fileDownloadSuccess());
  } catch (error) {
    yield put(fileDownloadFailure({ error }));

    if (error.response && error.response.status === 404) {
      // TODO: Create alert dialog
      alert('File not found'); // eslint-disable-line no-alert
    } else {
      yield put(createAlertChannelRequest({ error }));
    }
  }
}

export default function* () {
  yield takeEvery(
    FILE_DOWNLOAD_REQUEST,
    authenticate(fileDownloadRequestWorker, fileDownloadFailure),
  );
  yield takeLatest(EXPORT_DATASET_REQUEST, exportDatasetRequestWorker);
}
