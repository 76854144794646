export const loadGoogleTagManagerTrackingScript = () => {
  const gtmScript = document.createElement('script');
  gtmScript.setAttribute('id', 'gtm-script');
  gtmScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-MXTJ6S5');`;
  // note the following nonce need to match the {nonce-xxxx} in config template files
  gtmScript.nonce = 'e75074dcc22c26cb59fd222219597651d2578b96a491f9e6b54b47ddb01b45ca';
  // insert the script tag to the top part of head tag
  document.getElementsByTagName('head')[0].prepend(gtmScript);

  const noscript = document.createElement('noscript');
  const iframe = document.createElement('iframe');
  iframe.src = 'https://www.googletagmanager.com/ns.html?id=GTM-MXTJ6S5';
  iframe.height = '0';
  iframe.width = '0';
  iframe.style.display = 'none';
  iframe.style.visibility = 'hidden';
  noscript.appendChild(iframe);
  // insert the noscript tag to the top part of body tag
  document.getElementsByTagName('body')[0].prepend(noscript);
};
