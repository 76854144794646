import * as json2csv from 'json2csv';
import { push } from 'redux-first-history';
import { call, delay, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { getChartRender } from '../../api/chart.api';
import {
  getPublication,
  getPublicationActionResult,
  postPublicationAction,
} from '../../api/embed.api';
import {
  PublicationActions,
  PublicationActionStatuses,
  PublicationTypes,
} from '../../constants/embed';
import { paths } from '../../constants/paths';
import { Renderers } from '../../types/render.types';
import { createAlertChannelRequest } from '../actions/dialog.actions';
import {
  EXPORT_PREDICTIONS_TO_CSV,
  GET_ACTION_RESULT_REQUEST,
  GET_PUBLICATION_REQUEST,
  GET_REFRESHED_CHART_REQUEST,
  getActionResultError,
  getActionResultFailure,
  getActionResultRequest,
  getActionResultSuccess,
  getPublicationFailure,
  getPublicationSuccess,
  getRefreshedChartFailure,
  getRefreshedChartSuccess,
  OPEN_PRIVATE_EMBED_PAGE,
  POLL_COMPLETE_REFRESHED_CHART,
  pollForRefreshedChartRequest,
  SUBMIT_ACTION_REQUEST,
  submitActionFailure,
  submitActionSuccess,
} from '../actions/embed.actions';
import { selectAccessToken, selectPublicationKey, selectPublicationSecret } from './selectors';
/**
 * Navigates the page to the private embedded page
 */
export function* navigateToPrivateEmbedPage({ pubType, pubId }) {
  yield put(push(paths.privateEmbedView(pubType, pubId)));
}

/**
 * Retrieves a published object from the server
 * (see PublicationTypes in embed.js for supported publication types)
 */
export function* getPublicationWorker(props) {
  const { pubId, pubType, refreshId, apiKey, apiSecret, accessToken } = props;

  let eventChannel = null;
  try {
    // First get the publication to get the dc_chart_id
    const response = yield call(getPublication, {
      pubId,
      pubType,
      refreshId,
      apiKey,
      apiSecret,
      accessToken,
    });

    const { data } = response;

    // For charts with public access (no accessToken), make an additional request to the charting service
    if (pubType === PublicationTypes.CHART && data.dc_chart_id && !accessToken) {
      eventChannel = yield call(getChartRender, {
        dcChartId: data.dc_chart_id,
        apiKey,
        apiSecret,
        publicationId: pubId,
        renderer: Renderers.SPEC,
      });

      while (true) {
        const sseData = yield take(eventChannel);

        if (sseData.data) {
          // Combine the publication data with the chart data
          const enrichedData = {
            ...data,
            content: [
              {
                type: 'viz',
                typeVersion: sseData.data.typeVersion,
                data: sseData.data,
              },
            ],
          };

          yield put(getPublicationSuccess({ pubType, rawData: enrichedData }));
          return;
        }
      }
    } else {
      // For non-chart publications or authenticated access, proceed as normal
      yield put(getPublicationSuccess({ pubType, rawData: data }));
    }
  } catch (error) {
    yield put(createAlertChannelRequest({ error }));
    yield put(getPublicationFailure({ pubType, error: error.response }));
  } finally {
    if (eventChannel) eventChannel.close();
  }
}

/**
 * Makes a request to trigger an action on a publication
 * @param {Number} pubId id of the publication
 * @param {String} actionType type of action to perform on the publication
 * @param {Object} actionInput input for the action
 */
function* submitActionWorker({ pubId, pubType, actionType, actionInput }) {
  try {
    const accessToken = yield select(selectAccessToken);
    const apiKey = yield select(selectPublicationKey);
    const apiSecret = yield select(selectPublicationSecret);
    // Post publication action
    const response = yield call(postPublicationAction, {
      pubId,
      pubType,
      actionType,
      apiKey,
      apiSecret,
      accessToken,
      data: actionInput,
    });
    // Success
    const { data } = response;
    yield put(submitActionSuccess({ actionType, actionId: data.id, data }));
    yield put(getActionResultRequest({ pubId, pubType, actionId: data.id, actionType }));
  } catch (error) {
    yield put(createAlertChannelRequest({ error }));
    yield put(submitActionFailure({ actionType, error }));
  }
}

/**
 * Polls for the result of a specified action
 * @param {Number} pubId id of the publication
 * @param {Number} actionId id of the action
 * @param {String} actionType type of action to perform on the publication
 */
function* getActionResultWorker({ pubId, pubType, actionId, actionType }) {
  try {
    const accessToken = yield select(selectAccessToken);
    const apiKey = yield select(selectPublicationKey);
    const apiSecret = yield select(selectPublicationSecret);
    const response = yield call(getPublicationActionResult, {
      pubId,
      pubType,
      actionId,
      actionType,
      apiKey,
      apiSecret,
      accessToken,
    });
    const { data } = response;
    if (data.status === 'COMPLETE') {
      yield put(getActionResultSuccess({ pubId, actionId, actionType, data }));
    } else if (data.status === 'ERROR') {
      yield put(getActionResultError({ pubId, actionId, actionType, data }));
    } else if (data.status === 'INCOMPLETE') {
      yield delay(1000);
      yield put(getActionResultRequest({ pubId, pubType, actionId, actionType }));
    }
  } catch (error) {
    yield put(createAlertChannelRequest({ error }));
    yield put(getActionResultFailure({ pubId, actionId, actionType, error }));
  }
}

/**
 * Sends a 'refresh' action to the server to spin up a worker to refresh the published chart
 * NOTE: Specific to embedded chart use
 */
function* getRefreshedChartWorker(props) {
  const { pubId, apiKey, apiSecret, accessToken } = props;
  try {
    const response = yield call(postPublicationAction, {
      pubId,
      pubType: PublicationTypes.CHART,
      actionType: PublicationActions.REFRESH,
      apiKey,
      apiSecret,
      accessToken,
    });

    const { data } = response;
    yield put(getRefreshedChartSuccess());
    // If the request was successful, begin polling for the completion of the refresh:
    yield put(
      pollForRefreshedChartRequest({
        pubId,
        actionId: data.id,
        apiKey,
        apiSecret,
        accessToken,
      }),
    );
  } catch (error) {
    yield put(getRefreshedChartFailure({ error }));
  }
}

/**
 * Polls the server for the completion of the chart refresh
 * NOTE: Specific to embedded chart use
 */
export function* pollForCompleteRefreshedChartWorker(props) {
  const { pubId, actionId, apiKey, apiSecret, accessToken } = props;
  let eventChannel = null;
  try {
    const response = yield call(getPublicationActionResult, {
      pubId,
      pubType: PublicationTypes.CHART,
      actionId,
      actionType: PublicationActions.REFRESH,
      apiKey,
      apiSecret,
      accessToken,
    });

    const { status, result } = response.data;
    if (status === PublicationActionStatuses.COMPLETE) {
      // Get the refreshed chart from the charting service
      eventChannel = yield call(getChartRender, {
        dcChartId: result.dc_chart_id, // Use the new chart ID from the refresh
        apiKey,
        apiSecret,
        publicationId: pubId,
        renderer: Renderers.SPEC,
        accessToken,
      });

      while (true) {
        const sseData = yield take(eventChannel);

        if (sseData.data) {
          const chart = {
            publication_id: pubId,
            dc_chart_id: result.dc_chart_id,
            content: [sseData.data],
          };
          yield put(getPublicationSuccess({ pubType: PublicationTypes.CHART, rawData: chart }));
          return;
        }
      }
    } else {
      // Wait 1 second then poll again
      yield delay(1000);
      yield put(
        pollForRefreshedChartRequest({
          pubId,
          actionId,
          pubType: PublicationTypes.CHART,
          apiKey,
          apiSecret,
          accessToken,
        }),
      );
    }
  } catch (error) {
    yield put(getPublicationFailure({ pubType: PublicationTypes.CHART, error: error.response }));
  } finally {
    if (eventChannel) eventChannel.close();
  }
}

/**
 * Formats a Prediction Result's table data into CSV format and triggers a download of the file.
 */
function* exportPredictionsToCsvWorker({ columns, data, modelName }) {
  try {
    // Get list of just the headers
    const colHeaders = columns.map((col) => col.field);
    // Filter the table data (keep only COMPLETE rows and only columns displayed in the tables)
    const filteredData = yield data
      .filter((row) => row.PredictResultStatus === PublicationActionStatuses.COMPLETE)
      .map((row) => {
        const newRow = {};
        Object.entries(row).forEach(([key, value]) => {
          if (colHeaders.includes(key)) newRow[key] = value;
        });
        return newRow;
      });
    // Parse the filtered results into a csv format
    const parser = new json2csv.Parser({ colHeaders });
    const csv = parser.parse(filteredData);
    // Create download link and initiate the csv file download
    const url = window.URL.createObjectURL(new Blob([csv]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${modelName}_Predictions.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    yield put(createAlertChannelRequest({ error }));
  }
}

export default function* () {
  yield takeLatest(OPEN_PRIVATE_EMBED_PAGE, navigateToPrivateEmbedPage);
  yield takeEvery(GET_PUBLICATION_REQUEST, getPublicationWorker);
  yield takeEvery(SUBMIT_ACTION_REQUEST, submitActionWorker);
  yield takeEvery(GET_ACTION_RESULT_REQUEST, getActionResultWorker);
  yield takeEvery(GET_REFRESHED_CHART_REQUEST, getRefreshedChartWorker);
  yield takeEvery(POLL_COMPLETE_REFRESHED_CHART, pollForCompleteRefreshedChartWorker);
  yield takeLatest(EXPORT_PREDICTIONS_TO_CSV, exportPredictionsToCsvWorker);
}
