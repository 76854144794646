import axios from 'axios';
import qs from 'qs';
import { dcAppServiceEndpoints, endpoints } from '../constants/endpoints';
import { handleDCAppServiceCall } from '../utils/errorHandling/errorHandlers.api';

/**
 * A GET HTTP request for retrieving a specific workflow from the server.
 *
 * @param {String} accessToken The access token
 * @param {String} id The database id of the saved workflow
 */
export const getWorkflow = (accessToken, id, preview) =>
  axios.get(endpoints.workflow, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: { workflow_id: id, preview },
  });

export const getWorkflowAPIKey = (id, clientId, secret) =>
  axios.get(endpoints.workflow, {
    params: {
      workflow_id: id,
      client_id: clientId,
      secret,
    },
  });

/**
 * A POST HTTP request for saving a new workflow on the server
 *
 * @param {String} accessToken The access token
 * @param {String} name The name of the new workflow
 * @param {String} currentData The new workflow data contents as a string in the .dcs file format
 * @param {String} appId The id of the app that the new workflow is associated with
 * @param {String} originWorkflowId The id of the workflow that this workflow originated from
 * @param {String} overwrite Whether to overwrite an existing workflow (if applicable)
 */
export const postCreateWorkflow = (
  accessToken,
  { name, data, originWorkflowId, overwrite, appId },
) =>
  axios.post(
    endpoints.workflow,
    qs.stringify({
      name,
      data: typeof data === 'object' ? JSON.stringify(data) : data,
      origin_workflow_id: originWorkflowId,
      overwrite,
      appId,
    }),
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );

/**
 * A PUT HTTP request for updating an existing workflow saved on the server.
 *
 * @param {String} accessToken The access token
 * @param {Number} id The database id of the saved workflow
 * @param {Object} data The updated workflow data content
 *
 */
export const putWorkflow = (accessToken, id, data) => {
  return axios.put(
    endpoints.workflow,
    qs.stringify({
      id,
      data: typeof data === 'object' ? JSON.stringify(data) : data,
    }),
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );
};

/**
 * A POST HTTP request for creating a workflow from an existing workflow shared with the user
 *
 * @param {String} accessToken The access token
 * @param {String} id The database id of the saved workflow to be copied
 * @param {String} name What the copy should be named
 * @param {String} overwrite Whether to overwrite an existing version (if applicable)
 *
 */
export const postCopyWorkflow = (accessToken, id, name, overwrite) =>
  axios.post(
    endpoints.workflowCopy,
    qs.stringify({
      id,
      name,
      overwrite,
    }),
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );

/**
 * A GET HTTP request for retrieving the workflow ID for a chart from its publication ID
 * @param {String} accessToken The access token
 * @param {Number} pubId The publication ID for the chart publication
 * @returns
 */
export const getChartWorkflowID = (accessToken, pubId) =>
  axios.get(`${endpoints.publication}/${pubId}/workflow`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

/**
 * A POST request to restore (and possibly migrate) a workflow's version as the latest version.
 * @param {String} accessToken - used for authentication
 * @param {String} workflowId - primary key of the workflow
 * @param {String} version - version to be restored
 */
export const postRestoreWorkflow = (accessToken, workflowId, version = null) =>
  handleDCAppServiceCall(() =>
    axios.post(dcAppServiceEndpoints.restoreWorkflow, qs.stringify({ workflowId, version }), {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }),
  );
