import { InputNumber as antInputNumber, InputNumberProps } from 'antd';
import styled from 'styled-components';

type IInputNumberProps = InputNumberProps;

const InputNumber = styled(antInputNumber)<IInputNumberProps>`
  width: 100%;
`;

export default InputNumber;
