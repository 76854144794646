import InfoIcon from '@mui/icons-material/InfoOutlined';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * A functional component to render a button UI to open the alert banner
 */
const AlertButton = (props) => {
  const { alertText, messageTypeVersion, setAlertOpen } = props;

  // handle click to toggle status of alert banner
  const handleOnClick = () => {
    setAlertOpen((prevAlertOpen) => !prevAlertOpen);
  };

  return (
    <>
      {alertText && messageTypeVersion === 2 ? (
        <MenuItem key="Notes on chart data" className="Menu-Item-Container" onClick={handleOnClick}>
          <div className="Menu-Item" data-cy="Menu-Item-Alert" data-testid="Menu-Item-Alert">
            <InfoIcon className="Menu-Item-Icon" />
            <span className="Menu-Item-Text">Notes on chart data</span>
          </div>
        </MenuItem>
      ) : null}
    </>
  );
};

AlertButton.propTypes = {
  alertText: PropTypes.string,
  messageTypeVersion: PropTypes.number,
  setAlertOpen: PropTypes.func.isRequired,
};

AlertButton.defaultProps = {
  alertText: undefined,
  messageTypeVersion: undefined,
};

export default AlertButton;
