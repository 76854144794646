import React from 'react';
import { connect } from 'react-redux';
import '../../styles/DataChatLink.scss';

const DEFAULT_URL_TYPE = 'absolute';
type Props = {
  url: string,
  urlType: string,
  port: number,
  children: React.Node,
};

/**
 * Component that can be used for redirecting to custom links from DataChat.
 * This component can be used when the <a> tag does not work.
 * For instance, we'd like to redirect the user to a location
 * with a different port under the same DataChat domain when users spin up Jupyter notebooks.
 * This component is rendered as a span with an on-click handler.
 */
class DataChatRedirectLink extends React.PureComponent<Props> {
  constructor(props) {
    super(props);
    // url - can be absolute or relative
    this.url = this.props.url;
    // type of the URL
    this.urlType = this.props.urlType || DEFAULT_URL_TYPE;

    this.port = this.props.port;
  }

  /*
   * Function that handles redirect when the <span> element is clicked.
   */
  handleClick = (e) => {
    e.stopPropagation(); // avoid highlighting the client message
    let finalURL = this.props.url;
    if (this.props.urlType !== DEFAULT_URL_TYPE) {
      // Get the protocol, hostname and append the new port to the existing URL
      finalURL = this.port
        ? `${window.location.protocol}//${window.location.hostname}:${this.props.port}/${this.props.url}`
        : `${window.location.protocol}//${window.location.hostname}/${this.props.url}`;
    }
    // Open the URL in a new tab.

    window.open(finalURL, '_blank');
  };

  render() {
    const className = `DataChatLink link`;

    return (
      <span
        onClick={this.handleClick}
        className={className}
        title={`Click to navigate to "${this.url}"`}
      >
        {this.props.children}
      </span>
    );
  }
}
const mapStateToProps = () => ({});
export default connect(mapStateToProps)(DataChatRedirectLink);
