import { Input as antInput, GetRef, InputProps } from 'antd';
import styled from 'styled-components';

type IInputProps = InputProps;

const Input = styled(antInput)<IInputProps>``;

export type InputRef = GetRef<typeof Input>;

export default Input;
