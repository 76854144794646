import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  setPublicationCustomization,
  toggleInsightsBoardUnsavedChanges,
} from '../../../store/actions/insights_board.actions';

import { useEditingInfo, useModalPng, useModeBar, usePanMode } from './helpers/visualCustomHooks';

/**
 * A component to provide state for visuals in an IB
 */
const InsightsBoardStateProvider = (props) => {
  const {
    children,
    data,
    setEditingModal,
    isPopOutChart,
    customization,
    tableName,
    messageType,
    messageTypeVersion,
  } = props;
  const dispatch = useDispatch();

  // state to control play/pause slider
  const [sliderPlaying, setSliderPlaying] = useState(false);

  // state to control mode option button for chart
  const [showModeBar, toggleModeBar] = useModeBar();

  // state to control pan mode for chart
  const [isPanMode, togglePanMode] = usePanMode();

  // state to control chart export png
  const [showModalPng, updateModalPng] = useModalPng();

  const datasetName =
    data?.title && !data.title.startsWith('Sample for') ? data?.title : data?.name;

  const tableTitle = tableName || customization?.tableName || datasetName;

  const updateTableTitle = ({ newName, publicationId }) => {
    dispatch(toggleInsightsBoardUnsavedChanges({ toggleValue: true }));
    dispatch(setPublicationCustomization({ publicationId, tableName: newName }));
  };

  // state to control editing functionality
  const {
    chartEditingMode,
    showEditMenu,
    keepChanges,
    toggleChartEditingMode,
    openIBFontMenu,
    toggleIBFontMenu,
  } = useEditingInfo(data, isPopOutChart, messageType, messageTypeVersion, setEditingModal);

  const toggleChartEditingModeAndClosePanMode = useCallback(
    (saveChanges, editMode) => {
      toggleChartEditingMode(saveChanges, editMode);
      togglePanMode(false);
    },
    [toggleChartEditingMode, togglePanMode],
  );

  return (
    <>
      {children({
        sliderPlaying,
        setSliderPlaying,
        showModeBar,
        toggleModeBar,
        isPanMode,
        togglePanMode,
        showModalPng,
        updateModalPng,
        tableTitle,
        updateTableTitle,
        chartEditingMode,
        showEditMenu,
        keepChanges,
        toggleChartEditingModeAndClosePanMode,
        openIBFontMenu,
        toggleIBFontMenu,
      })}
    </>
  );
};

InsightsBoardStateProvider.propTypes = {
  children: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  setEditingModal: PropTypes.func.isRequired,
  isPopOutChart: PropTypes.bool.isRequired,
  customization: PropTypes.object,
  tableName: PropTypes.string,
  messageType: PropTypes.string.isRequired,
  messageTypeVersion: PropTypes.number,
};

InsightsBoardStateProvider.defaultProps = {
  customization: {},
  messageTypeVersion: 0,
  tableName: undefined,
};

const mapStateToProps = (state, props) => ({
  tableName: state.insightsBoard.tableDisplayCustomizations[props.publicationId]?.tableName,
});

export default connect(mapStateToProps, {})(InsightsBoardStateProvider);
