import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { ReactComponent as PopOutIcon } from '../../../../assets/icons/external-link.svg';
import { openPopOutModal } from '../../../../store/actions/popout.actions';

/**
 * A functional component to render a button UI to open visual element in a popout window
 */
const PopOutButton = (props) => {
  // redux dispatch hook
  const dispatch = useDispatch();

  const { objectId, messageType, isPopOutChart, disable, isMenuItem } = props;

  // Handler for clicking the pop out chart button
  const handlePopOutChartButtonClick = () => {
    if (disable) return;
    dispatch(openPopOutModal({ chartObjectId: objectId }));
  };

  // note and annotation are not supported in pop out
  const enablePopOut = messageType !== 'note' && messageType !== 'annotation';

  const headerButton = () => {
    return (
      <div
        className={`ChartHeader-Button${disable ? ' disabled' : ''}`}
        data-cy="ChartHeader-Button-Pop-Out"
        onClick={handlePopOutChartButtonClick}
      >
        <PopOutIcon className="ChartHeader-Icon-Padded" />
      </div>
    );
  };

  const menuItemButton = () => {
    return (
      <MenuItem key="popout" className="Menu-Item-Container" onClick={handlePopOutChartButtonClick}>
        <div className="Menu-Item" data-cy="Menu-Item-PopOut">
          <PopOutIcon className="Menu-Item-Icon" />
          <span className="Menu-Item-Text">View in a larger window</span>
        </div>
      </MenuItem>
    );
  };

  return (
    <>
      {!isPopOutChart && enablePopOut ? (
        <Tooltip key="view" title="View in a larger window">
          {isMenuItem ? menuItemButton() : headerButton()}
        </Tooltip>
      ) : null}
    </>
  );
};

PopOutButton.propTypes = {
  objectId: PropTypes.string,
  isMenuItem: PropTypes.bool,
  messageType: PropTypes.string.isRequired,
  isPopOutChart: PropTypes.bool.isRequired,
  disable: PropTypes.bool,
};

PopOutButton.defaultProps = {
  objectId: undefined,
  isMenuItem: false,
  disable: false,
};

export default PopOutButton;
