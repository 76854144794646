import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EDITABLE_OBJECTS } from '../../../../constants/editor';
import { navigateToWorkflowEditor } from '../../../../store/actions/editor.actions';
import { selectDcChartId } from '../../../../store/sagas/selectors';
import UtterancesPreviewer from '../../../UtterancesPreviewer';

const WorkflowButton = (props) => {
  const {
    clientId,
    hasChartLinked,
    insightsBoardEditMode,
    isPopOutChart,
    messageType,
    publicationId,
    secret,
    workflowId,
  } = props;

  const dispatch = useDispatch();
  const dcChartId = useSelector(selectDcChartId(publicationId));
  const handleWorkflowEditorButtononClick = () => {
    dispatch(navigateToWorkflowEditor({ objectType: EDITABLE_OBJECTS.CHART, objectId: dcChartId }));
  };

  const isPivot = messageType === 'pivot table';

  return (
    <>
      {!isPivot && !isPopOutChart && hasChartLinked ? (
        <div
          className="ChartHeader-Button"
          data-cy="ChartHeader-Button-Workflow"
          onClick={insightsBoardEditMode ? handleWorkflowEditorButtononClick : undefined}
        >
          <UtterancesPreviewer
            workflowId={workflowId}
            anchorId={`${publicationId}-snapshot-button`}
            displayName="Chart's Workflow"
            tag={insightsBoardEditMode ? 'Click to Edit' : undefined}
            clientId={clientId}
            secret={secret}
          >
            {insightsBoardEditMode ? (
              <DescriptionOutlinedIcon className="ChartHeader-Icon-Lg" />
            ) : (
              <VisibilityOutlined className="ChartHeader-Icon-Lg" />
            )}
          </UtterancesPreviewer>
        </div>
      ) : null}
    </>
  );
};

WorkflowButton.propTypes = {
  clientId: PropTypes.string,
  hasChartLinked: PropTypes.bool.isRequired,
  insightsBoardEditMode: PropTypes.bool.isRequired,
  isPopOutChart: PropTypes.bool.isRequired,
  messageType: PropTypes.string.isRequired,
  publicationId: PropTypes.number.isRequired,
  secret: PropTypes.string,
  workflowId: PropTypes.number,
};

WorkflowButton.defaultProps = {
  clientId: null,
  secret: null,
  workflowId: undefined,
};

export default WorkflowButton;
