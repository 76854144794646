import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { connect } from 'react-redux';
import sanitizeHtml from 'sanitize-html';

import memoize from 'lodash/memoize';
import { CONTEXTS } from '../../constants';
import { closeDialog, openAlertDialog } from '../../store/actions/dialog.actions';
import { describeAndSendUtteranceRequest } from '../../store/actions/messages.actions';
import { selectContext } from '../../store/selectors/context.selector';
import { selectVisibleDatasetsByNameVersion } from '../../store/selectors/dataspace.selector';
import DatasetTextboxContent from './DatasetTextboxContent';

type Props = {
  className: string,
  name: string,
  disabled: Boolean,
  version: Number,
  describeAndSendUtteranceRequest: () => mixed,
  context: string,
  datasetTextboxLoadingChange: () => mixed,
  datasets: {},
  contentEditableRef: React.RefObject,
};

class DatasetTextbox extends React.Component<Props> {
  constructor(props) {
    super(props);
    this.contentEditable = React.createRef();
    this.textboxRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.saveName = this.saveName.bind(this);

    this.state = {
      // Sanitize name every time it is set in state to avoid XSS attacks
      name: sanitizeHtml(props.name),
      oldName: sanitizeHtml(props.name),
      version: props.version,
      sendingMessage: false,
      focused: false,
    };
  }

  // Callback to indicate saga request is done
  successSendBenMessage = () => {
    this.setState({ sendingMessage: false });
  };

  handleClickOutside = (event) => {
    // Do nothing if disabled or unmounted
    if (this.isDisabled() || !this.textboxRef) return;
    const clicked = this.textboxRef.current.contains(event.target);
    const { focused } = this.state;
    // If textbox was focused and clicked away, save name
    if (focused && !clicked) {
      this.setState((prevState) => ({ name: prevState.oldName, focused: false }));
    }
  };

  handleDoubleClickOutside = (event) => {
    // Do nothing if disabled or unmounted
    if (this.isDisabled() || !this.textboxRef) return;
    const clicked = this.textboxRef.current.contains(event.target);
    const { focused } = this.state;
    // If the header is double clicked, focus on that element
    if (focused !== clicked) {
      this.setState({ focused: true });
      this.contentEditable.current.focus();
    }
  };

  isDisabled = () => this.isDisabledMemo(this.props.datasets);

  // Is this textbox disabled?  Memoized on datasets
  isDisabledMemo = memoize(
    (datasets) => this.props.disabled || !this.isNameADataset(datasets),
    (datasets) => (this.props.disabled ? '{}' : JSON.stringify(datasets)),
  );

  // Check to see if this dataset name is actually a dataset
  isNameADataset = (datasets) => {
    const { name } = this.state;

    if (!isEmpty(datasets)) {
      return name in datasets;
    }
    return false;
  };

  // Wait until conditionFunction is true
  waitUntil = (conditionFunction) => {
    const poll = (resolve) => {
      if (conditionFunction()) resolve();
      else setTimeout(() => poll(resolve), 400);
    };

    return new Promise(poll);
  };

  // Save the dataset name
  async saveName(newDatasetName) {
    // Update loading flag
    this.props.datasetTextboxLoadingChange(true);

    // The utterances we want to send
    const message = {
      skill: 'NameDataset',
      kwargs: {
        dataset_entity: JSON.stringify({
          dataset_name: this.state.oldName,
          version: this.state.version,
        }),
        name: newDatasetName,
        use_it: true,
      },
    };
    await this.sendUtterances(message);
    this.props.datasetTextboxLoadingChange(false);
  }

  async sendUtterances(message) {
    // Wait until state is set before sending request
    await this.setState({ sendingMessage: true }, () => {
      // Rename the dataset
      this.props.describeAndSendUtteranceRequest({
        message,
        callback: this.successSendBenMessage,
      });
    });

    // Wait for request to be sent and processed
    await this.waitUntil(() => !this.state.sendingMessage && this.props.context === CONTEXTS.REST);
  }

  render() {
    return (
      <DatasetTextboxContent
        className={this.props.className}
        name={this.props.name}
        disabled={this.isDisabled()}
        version={this.props.version}
        saveNameCallback={this.saveName}
        contentEditableRef={this.props.contentEditableRef}
      />
    );
  }
}
const mapStateToProps = (state) => ({
  context: selectContext(state),
  datasets: selectVisibleDatasetsByNameVersion(state),
});

export default connect(mapStateToProps, {
  describeAndSendUtteranceRequest,
  openAlertDialog,
  closeDialog,
})(DatasetTextbox);
