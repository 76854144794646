export const OPEN_VIEW_SLICED_WORKFLOW = 'viewSlicedWorkflow/OPEN_VIEW_SLICED_WORKFLOW';
export const CLOSE_VIEW_SLICED_WORKFLOW = 'viewSlicedWorkflow/CLOSE_VIEW_SLICED_WORKFLOW';
export const RETRIEVE_SLICED_WORKFLOW_DATA_SUCCESS =
  'viewSlicedWorkflow/RETRIEVE_SLICED_WORKFLOW_DATA_SUCCESS';
export const RETRIEVE_SLICED_WORKFLOW_DATA_FAILURE =
  'viewSlicedWorkflow/RETRIEVE_SLICED_WORKFLOW_DATA_FAILURE';

// Action that opens the chart pop out modal with the selected chart
export const openViewSlicedWorkflow = ({ objectType, objectId }) => ({
  type: OPEN_VIEW_SLICED_WORKFLOW,
  objectType,
  objectId,
});

// Action that closes the chart pop out modal
export const closeViewSlicedWorkflow = () => ({
  type: CLOSE_VIEW_SLICED_WORKFLOW,
});

export const retrieveSlicedWorkflowDataSuccess = (slicedWorkflowData) => ({
  type: RETRIEVE_SLICED_WORKFLOW_DATA_SUCCESS,
  slicedWorkflowData,
});

export const retrieveSlicedWorkflowDataFailure = (error) => ({
  type: RETRIEVE_SLICED_WORKFLOW_DATA_FAILURE,
  error,
});
