import PropTypes from 'prop-types';
import React from 'react';
import SessionData from '../../ChartData/SessionData';
import Table from '../../DisplayPanel/Charts/Table/Table';
import '../ChartBuilder.scss';

/**
 * Renders the DataSample dropdown to display a sample view of the dataset
 * @returns {JSX.Element} A table view of the data sample
 */
const DataSample = ({ datasetName, datasetVersion, isInsightsBoard, objectId }) => (
  <div className="CB-Footer-Dataset">
    <SessionData
      datasetName={datasetName}
      datasetVersion={datasetVersion}
      objectId={objectId}
      type="table"
    >
      {(msgContext, error, isFailed, isLoading, rowCount, updateRowCount) => (
        <Table
          data={msgContext.chart.data}
          id={objectId}
          isFailed={isFailed}
          isInsightsBoard={isInsightsBoard}
          isLoading={isLoading}
          rowCount={rowCount}
          updateRowCount={updateRowCount}
        />
      )}
    </SessionData>
  </div>
);

// Set the displayName to be accessed by the container
DataSample.displayName = 'DataSample';

DataSample.propTypes = {
  datasetName: PropTypes.string.isRequired,
  datasetVersion: PropTypes.number.isRequired,
  isInsightsBoard: PropTypes.bool.isRequired,
  objectId: PropTypes.string,
};

DataSample.defaultProps = {
  objectId: undefined,
};

export default DataSample;
