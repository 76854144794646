import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React from 'react';
import { VISUAL_TYPES } from '../../../constants';
import { isDataChatSessionPage } from '../../../constants/paths';
import { isCopyLinkEnabled } from '../../../utils/env';
import ChartBuilderContainer from '../../ChartBuilder/ChartBuilderContainer';
import SessionData from '../../ChartData/SessionData';
import AlertBanner from './AlertBanner';
import AlertButton from './ButtonRow/ButtonColumn/AlertButton';
import ChartEditButton from './ButtonRow/ButtonColumn/ChartEditButton';
import CopyLinkButton from './ButtonRow/ButtonColumn/CopyLinkButton';
import ExportFigureButton from './ButtonRow/ButtonColumn/ExportFigureButton';
import ExportPlotDataButton from './ButtonRow/ButtonColumn/ExportPlotDataButton';
import PanModeButton from './ButtonRow/ButtonColumn/PanModeButton';
import PublishButton from './ButtonRow/ButtonColumn/PublishButton';
import SlicedWorkflowButton from './ButtonRow/ButtonColumn/SlicedWorkflowButton';
import ToolboxButton from './ButtonRow/ButtonColumn/ToolboxButton';
import UseButton from './ButtonRow/ButtonColumn/UseButton';
import WorkspaceButton from './ButtonRow/ButtonColumn/WorkspaceButton';
import ButtonColumnContainer from './ButtonRow/ButtonColumnContainer';
import CancelButton from './ButtonRow/CancelButton';
import CopyStatus from './ButtonRow/CopyStatus';
import PopOutButton from './ButtonRow/PopOutButton';
import ButtonRowContainer from './ButtonRowContainer';
import ChartHeaderLink from './ChartHeaderLink/ChartHeaderLink';
import { shouldOpenChartBuilder } from './helpers/utils';
import SelectBox from './SelectBox';
import TabVisualStateProvider from './TabVisualStateProvider';
import TabsContainer from './TabVisualTabs/TabsContainer';
import VisualContainer from './VisualContainer';
import VisualDisplayContainer from './VisualDisplayContainer';
import VisualDisplayFilter from './VisualDisplayFilter';
import VisualHeaderContainer from './VisualHeaderContainer';

const TabVisual = (props) => {
  const { autoScroll, isPopOutChart, tabVisualContext, selectedCount, setEditingModal } = props;

  const { tabs, tabContents } = tabVisualContext.chart?.data;

  return (
    <TabVisualStateProvider
      tabs={tabs}
      tabContents={tabContents}
      isPopOutChart={isPopOutChart}
      setEditingModal={setEditingModal}
    >
      {(
        selectedTab,
        selectedTabIndex,
        selectedContent,
        changeSelectedTab,
        _, // rowCount
        copyMsg,
        setCopyMsg,
        showModeBar,
        toggleModeBar,
        isPanMode,
        togglePanMode,
        showModalPng,
        updateModalPng,
        alertOpen,
        alertText,
        setAlertOpen,
        openCaptionAlert,
        chartEditingMode,
        showEditMenu,
        keepChanges,
        toggleChartEditingModeAndClosePanMode,
        isPopoutEditable,
      ) => (
        <SessionData
          key={selectedContent.id}
          // Mimic the nesting of a regular message
          message={{ chart: selectedContent }}
          objectId={tabVisualContext.chart.objectId}
          update={selectedContent.update}
        >
          {(chartContext, error, isFailed, isLoading, rowCount, updateRowCount) => (
            <VisualContainer
              objectId={tabVisualContext.chart.objectId}
              contextId={tabVisualContext.id}
              messageType={tabVisualContext.chart.type}
              isPopOutChart={isPopOutChart}
            >
              <VisualHeaderContainer
                messageType={tabVisualContext.chart.type}
                isSelecting={selectedCount > 0}
                userMsgId={tabVisualContext.chart.userMsgId}
                clickToScroll
              >
                <SelectBox
                  objectId={tabVisualContext.chart.objectId}
                  data={tabVisualContext.chart.data}
                  isSelecting={selectedCount > 0}
                  isPopOutChart={isPopOutChart}
                />
                <TabsContainer
                  tabs={tabs}
                  tabContents={tabContents}
                  selectedTab={selectedTab}
                  selectedTabIndex={selectedTabIndex}
                  changeSelectedTab={changeSelectedTab}
                />
                <ButtonRowContainer>
                  <CopyStatus copyMsg={copyMsg} setCopyMsg={setCopyMsg} />
                  <CancelButton
                    showButton={isPanMode}
                    onClick={togglePanMode}
                    tooltipLabel="Leave Pan Mode"
                    dataCy="cancel-pan-mode-button"
                  />
                  {isDataChatSessionPage() && <ChartHeaderLink dcChartId={tabVisualContext.id} />}
                  {!isDataChatSessionPage() && (
                    <PopOutButton
                      objectId={tabVisualContext.id}
                      messageType={tabVisualContext.chart.type}
                      isPopOutChart={isPopOutChart}
                    />
                  )}
                  <ButtonColumnContainer
                    chartEditingMode={chartEditingMode}
                    data={chartContext.chart.data}
                    frames={chartContext.chart.data.frames}
                    isPopOutChart={isPopOutChart}
                    messageType={chartContext.chart.type}
                    messageTypeVersion={chartContext.chart.typeVersion}
                    isStepwise
                  >
                    {isCopyLinkEnabled() && (
                      <CopyLinkButton
                        copyMsg={copyMsg}
                        isDependencyGraph={chartContext.chart.data.isDependencyGraph || false}
                        setCopyMsg={setCopyMsg}
                        objectId={
                          chartContext.chart?.additionalInfo?.dc_chart_id ??
                          chartContext.chart.objectId
                        }
                        messageType={chartContext.chart.type}
                      />
                    )}
                    <ChartEditButton
                      messageType={chartContext.chart.type}
                      data={chartContext.chart.data}
                      isPopoutEditable={isPopoutEditable}
                      isLoading={isLoading}
                      messageTypeVersion={chartContext.chart.typeVersion}
                      chartEditingMode={chartEditingMode}
                      toggleChartEditingMode={toggleChartEditingModeAndClosePanMode}
                    />
                    <ExportFigureButton
                      messageType={chartContext.chart.type}
                      data={chartContext.chart.data}
                      updateModalPng={updateModalPng}
                    />
                    <ExportPlotDataButton
                      messageType={chartContext.chart.type}
                      data={chartContext.chart.data}
                    />
                    <AlertButton
                      alertText={alertText}
                      messageTypeVersion={chartContext.chart.typeVersion}
                      setAlertOpen={setAlertOpen}
                    />
                    <PublishButton
                      objectId={
                        chartContext.chart?.additionalInfo?.dc_chart_id ??
                        chartContext.chart.objectId
                      }
                      messageType={chartContext.chart.type}
                      data={chartContext.chart.data}
                      update={chartContext.chart.update}
                      rowCount={rowCount}
                    />
                    {isDataChatSessionPage() && (
                      <PopOutButton
                        objectId={
                          chartContext.chart?.additionalInfo?.dc_chart_id ??
                          chartContext.chart.objectId
                        }
                        messageType={chartContext.chart.type}
                        isPopOutChart={isPopOutChart}
                        isMenuItem
                      />
                    )}
                    <ToolboxButton
                      messageType={chartContext.chart.type}
                      messageTypeVersion={chartContext.chart.typeVersion}
                      data={chartContext.chart.data}
                      toggleModeBar={toggleModeBar}
                    />
                    <PanModeButton
                      messageType={chartContext.chart.type}
                      messageTypeVersion={chartContext.chart.typeVersion}
                      data={chartContext.chart.data}
                      togglePanMode={togglePanMode}
                    />
                    <WorkspaceButton
                      objectId={
                        chartContext.chart?.additionalInfo?.dc_chart_id ??
                        chartContext.chart.objectId
                      }
                      messageType={chartContext.chart.type}
                    />
                    <UseButton
                      messageType={chartContext.chart.type}
                      data={chartContext.chart.data}
                    />
                    {isDataChatSessionPage() && (
                      <SlicedWorkflowButton
                        data={tabVisualContext.chart.data}
                        dcChartId={tabVisualContext.id}
                        type={VISUAL_TYPES.TABVISUAL}
                        isPopOutChart={isPopOutChart}
                      />
                    )}
                  </ButtonColumnContainer>
                </ButtonRowContainer>
              </VisualHeaderContainer>
              {!isEmpty(chartContext?.chart) ? (
                <>
                  <AlertBanner alertOpen={alertOpen} alertText={alertText} />
                  <VisualDisplayContainer
                    autoScroll={autoScroll}
                    // 'Fit-Aspect-Ratio' is applied so that tables will
                    // render to the same height as charts
                    className="Fit-Aspect-Ratio"
                    clickToScroll
                    contextId={tabVisualContext.id}
                    error={error}
                    isFailed={isFailed}
                    key={`viz-display-container-${
                      chartContext.chart?.additionalInfo?.dc_chart_id ?? chartContext.chart.objectId
                    }-${selectedTab}`}
                    messageType={chartContext.chart.type}
                    messageTypeVersion={chartContext.chart.typeVersion}
                  >
                    <VisualDisplayFilter
                      chart={chartContext.chart}
                      contextId={tabVisualContext.id}
                      showModeBar={showModeBar}
                      isPanMode={isPanMode}
                      isTabVisual
                      toggleModeBar={toggleModeBar}
                      showModalPng={showModalPng}
                      updateModalPng={updateModalPng}
                      openCaptionAlert={openCaptionAlert}
                      rowCount={rowCount}
                      chartEditingMode={chartEditingMode}
                      showEditMenu={showEditMenu}
                      keepChanges={keepChanges}
                      isLoading={isLoading}
                      isFailed={isFailed}
                      updateRowCount={updateRowCount}
                      toggleChartEditingMode={toggleChartEditingModeAndClosePanMode}
                    />
                    {shouldOpenChartBuilder({
                      data: chartContext.chart.data,
                      chartEditingMode,
                      messageType: chartContext.chart.type,
                      messageTypeVersion: chartContext.chart.typeVersion,
                    }) && (
                      <ChartBuilderContainer
                        datasetName={chartContext.chart.data.values.reference.params.dataset}
                        datasetVersion={chartContext.chart.data.values.reference.params.version}
                        dcSpec={chartContext.chart.data}
                        objectId={
                          chartContext.chart?.additionalInfo?.dc_chart_id ??
                          chartContext.chart.objectId
                        }
                        update={chartContext.chart.update}
                        externalName={chartContext.chart.externalName}
                        chartEditingMode
                        toggleChartEditingMode={toggleChartEditingModeAndClosePanMode}
                      />
                    )}
                  </VisualDisplayContainer>
                </>
              ) : null}
            </VisualContainer>
          )}
        </SessionData>
      )}
    </TabVisualStateProvider>
  );
};

TabVisual.propTypes = {
  tabVisualContext: PropTypes.object.isRequired,
  selectedCount: PropTypes.number.isRequired,
  autoScroll: PropTypes.bool.isRequired,
  setEditingModal: PropTypes.func,
  isPopOutChart: PropTypes.bool,
};

TabVisual.defaultProps = {
  isPopOutChart: false,
  setEditingModal: () => {},
};

export default TabVisual;
