import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import Spinner from 'react-spinkit';

/**
 * A functional component to render spinner UI indicating renaming
 */
const RenameIndicator = (props) => {
  const { textboxLoading } = props;
  return (
    textboxLoading && (
      <div className="ChartHeader-RefreshTime">
        <Grid container spacing={1} direction="row-reverse">
          <Grid item className="ChartLoadingIndicator">
            <Spinner
              data-cy="ChartHeader-Button-Rename-Indicator"
              fadeIn="none"
              name="ball-clip-rotate"
            />
          </Grid>

          <Grid item className="ChartLoadingIndicator-Text">
            Processing Request
          </Grid>
        </Grid>
      </div>
    )
  );
};

RenameIndicator.propTypes = {
  textboxLoading: PropTypes.bool,
};

RenameIndicator.defaultProps = {
  textboxLoading: undefined,
};

export default RenameIndicator;
