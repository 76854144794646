import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import '../ChartBuilder.scss';
import Dropdown from '../UtilComponents/Dropdown';
import { defaultMenuDropdownState, iconMapping } from '../utils/constants';
import { getComponentName } from '../utils/manageChart';
import { getCurrentSpec } from '../utils/manageSpec';

/**
 * A functional component to wrap the chart builder's left-side menu
 * Conditionally renders each child component, controlled via the linked Dropdown components
 * @returns {JSX.Element} The chart builder's left-side menu
 */
const MenuDropdownContainer = (props) => {
  const { children, echartsRef, fields, isLoading, renderChart, currChartType, insightsBoardId } =
    props;

  const [dropdowns, setDropdowns] = useState(defaultMenuDropdownState);

  // Close the filter and customize dropdowns when the chart is no longer rendered
  useEffect(() => {
    if (!renderChart) {
      setDropdowns((curr) => ({ ...curr, Customize: false }));
    }
  }, [renderChart]);

  // Decide if we want to show/hide the optional dropdown on CB edit, or chart type swap
  // If there are optional fields with values, then open it. Otherwise hide it
  useEffect(() => {
    // Handle chartType swaps by resetting the dropdowns to their default state
    setDropdowns(defaultMenuDropdownState);

    // Then, check if fields contains any optional inputs & show/hide the optional dropdown
    const { optional } = iconMapping[currChartType];
    const showOptional = Object.keys(fields).some((field) => optional?.includes(field));
    setDropdowns((curr) => ({ ...curr, OptionalFields: showOptional }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currChartType]);

  // Conditions to remove a component from the dropdown list
  const shouldRemove = (componentName) => {
    if (!componentName) return true;
    if (componentName === 'SampleLimit') {
      return insightsBoardId;
    }
    // Don't render Optional dropdowns for single_metric chart type
    if (currChartType === 'single_metric' && componentName === 'OptionalFields') {
      return true;
    }

    return false;
  };

  // Conditions to render the dropdown component
  const shouldRender = (componentName) => {
    switch (componentName) {
      case 'RequiredFields':
        return dropdowns.RequiredFields;
      case 'OptionalFields':
        return dropdowns.OptionalFields;
      case 'SampleLimit':
        return !isLoading && dropdowns.SampleLimit;
      case 'Filter':
        return !isLoading && dropdowns.Filter;
      case 'Customize':
        // Customize is different from the other components, as it renders regardless of dropdowns.Customize
        // status.  We then pass the var as 'showCustomize' to the Customize component to let it decide whether
        // or not to render the input fields. This allows us to access the functions and manipulate text/
        // annotations while the dropdown is 'closed' (input fields are hidden)
        return !isLoading && renderChart && getCurrentSpec(echartsRef);
      default:
        return false;
    }
  };

  // Conditions to disable dropdown components
  const shouldDisable = (componentName) => {
    return componentName === 'Customize' && (!renderChart || isLoading);
  };

  // Toggles a dropdown open/closed
  const toggleDropdown = (componentName) => {
    if (shouldDisable(componentName)) {
      return;
    }

    setDropdowns((curr) => ({ ...curr, [componentName]: !curr[componentName] }));
  };

  /**
   * Attaches a prop to one of the children elements in the dropdown, so that
   * the child element can know if it's opened or not
   * @param {String} componentName The child component's name (ex. 'RequiredFields', 'Filter', etc.)
   * @param {React.ReactElement} child The child component wrapped by this one
   * @returns
   */
  const attachPropsToChild = (componentName, child) => {
    switch (componentName) {
      case 'Customize':
        return React.cloneElement(child, { showCustomize: dropdowns.Customize });
      default:
        return child;
    }
  };

  return children.map((child) => {
    const componentName = getComponentName(child);

    return shouldRemove(componentName) ? null : (
      <React.Fragment key={`${componentName}-Dropdown-Wrapper`}>
        <Dropdown
          componentName={componentName}
          disabled={shouldDisable(componentName)}
          expanded={dropdowns[componentName]}
          fields={fields}
          format="menu"
          isLoading={isLoading}
          key={`${componentName}-Dropdown`}
          onClick={() => toggleDropdown(componentName)}
        />
        {shouldRender(componentName) && attachPropsToChild(componentName, child)}
      </React.Fragment>
    );
  });
};

MenuDropdownContainer.propTypes = {
  // Children components, currently RequiredFields, OptionalFields, Filter, Customize
  children: PropTypes.arrayOf(PropTypes.node),
  // The reference to the translation layer's echart
  echartsRef: PropTypes.object,
  // The chart builder's required & optional field selections, ex. { group: 'SibSp' }
  fields: PropTypes.object.isRequired,
  // The chart's loading status
  isLoading: PropTypes.bool,
  // Should we pass information to the translation layer & render the chart?
  renderChart: PropTypes.bool,
  // The selected chart type, ex. scatter, bubble, bar
  currChartType: PropTypes.string.isRequired,
};

MenuDropdownContainer.defaultProps = {
  echartsRef: { current: null },
  isLoading: false,
  renderChart: false,
};

export default MenuDropdownContainer;
