export const SKILL_LEVELS = ['1', '2', '3'];

export const SETTINGS_VIEW = {
  files: 'files',
  connections: 'connections',
  keys: 'keys',
  sessions: 'sessions',
  notificationLog: 'notificationLog',
  userRoles: 'userRoles',
  llmCredentials: 'llmCredentials',
};

export const FILE_MANAGER_MODE = {
  browse: 'browse',
  upload: 'upload',
};

export const DEFAULT_UTTERANCE_TIMEOUT = 60; // in minutes
