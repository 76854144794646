import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { PropTypes } from 'prop-types';
import React from 'react';
import '../ChartBuilder.scss';

/**
 * Renders the Describe dropdown to display dataset columns & types
 * @returns {JSX.Element} A table view of the describe information
 */
const Describe = (props) => {
  const { columnsTypes } = props;

  if (!columnsTypes) return null;
  return (
    <div className="CB-Footer-Dataset">
      <TableContainer className="CB-Table" component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="CB-Header-Cell" align="left">
                Column
              </TableCell>
              <TableCell className="CB-Header-Cell" align="right">
                Representation
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {columnsTypes.map((row) => (
              <TableRow key={row.name}>
                <TableCell align="left">{row.name}</TableCell>
                <TableCell align="right">{row.type}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

Describe.propTypes = {
  // The column/type information in array of object format, ex. [{ name: 'Age', type: 'Float' }]
  columnsTypes: PropTypes.array.isRequired,
};

// Set the displayName to be accessed by the container
Describe.displayName = 'Describe';

export default Describe;
