import Refresh from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import Popper from '@mui/material/Popper';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { connect } from 'react-redux';

import { selectSessionPreview, selectWorkflowPreview } from '../../store/sagas/selectors';

import {
  getSessionPreviewRequest,
  getWorkflowPreviewAPIKeyRequest,
  getWorkflowPreviewRequest,
} from '../../store/actions/utterances_preview.actions';
import { collapseTaggedJson, containsTaggedJson } from '../../utils/utterances';
import UtterancesList from '../UtterancesList';
import './index.scss';

/**
 * Logic for showing a Popper on hover, and a component for displaying a Workflow's content.
 * Note the element to be hovered must be a child of this Component
 */

type Props = {
  displayName: String, // User-friendly title, full name still in tooltip
  tag: String, // Replaces 'Preview' tag text
  workflowId: Number, // Workflow ID used to fetch contents
  version: Number, // Version of the workflow to fetch contents
  clientId: String,
  secret: String,
  sessionId: Number, // Session ID used to fetch contents
  children: React.Children,
  workflow: [], // Workflow Contents from store
  session: [], // Session Contents from store
  hoveringAnchor: Boolean, // If the anchor for the preview is hovered
  anchorId: String, // Id of anchor for the preview
  refreshText?: String, // Text to show next to the refresh button
  refreshFn?: () => mixed, // Function to call when refreshing
  getWorkflowPreviewRequest: () => mixed, // Dispatch action to get contents
  getWorkflowPreviewAPIKeyRequest: () => mixed,
  getSessionPreviewRequest: () => mixed,
  onClick: () => mixed,
};

class UtterancesPreviewer extends React.Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      hoveringAnchor: false,
      hoveringPreview: false,
    };
  }

  onMouseEnter = (anchor) => {
    this.setHover(anchor, true);
  };

  onMouseLeave = (anchor) => {
    this.setHover(anchor, false);
  };

  getEmptyPlaceHolder = (data) => {
    return data
      ? (this.props.workflowId && 'This workflow is empty') ||
          (this.props.sessionId && 'This session is empty')
      : 'Loading...';
  };

  /**
   * In future, add respective id and utterance data here,
   *  to show the utterances list and name in the utterance preview.
   * Expecting the utteranceData to be in form:
   * @param {Object} utterancesData = {
   *  data: []String (list of utterances in string format),
   *  name: String
   * }
   * @param {Number} id respective id, for example workflowId to preview the workflow utterances,
   *  sessionId to preview the session utterances
   */
  getData = () => {
    const { workflowId, version, sessionId, workflow, session } = this.props;
    let id = null;
    let utterancesData = { data: null, name: '' };
    if (workflowId && workflow) {
      // If provided, display version specified in props, otherwise use the latest version
      const previewVersion = version || workflow.latestVersion;
      id = `${workflowId}${previewVersion && `-${previewVersion}`}`;
      utterancesData = { data: workflow.data[previewVersion], name: workflow.name };
    } else if (sessionId && session) {
      id = sessionId;
      utterancesData = session;
    }
    return { id, utterancesData };
  };

  setHover = (anchor, val) => {
    this.setState(anchor ? { hoveringAnchor: val } : { hoveringPreview: val });
  };

  fetchContents = () => {
    const { workflowId, version, clientId, secret, sessionId, workflow, session } = this.props;
    if ((!workflow && workflowId) || (workflow && version && !(version in workflow.data))) {
      if (clientId || secret) {
        this.props.getWorkflowPreviewAPIKeyRequest({ workflowId, clientId, secret });
      } else {
        this.props.getWorkflowPreviewRequest({ workflowId, latestOnly: version === undefined });
      }
    }
    if (!session && sessionId) {
      this.props.getSessionPreviewRequest({ sessionId });
    }
  };

  render() {
    const {
      displayName,
      tag,
      hoveringAnchor: hoveringAnchorProp,
      children,
      onClick,
      refreshText,
      refreshFn,
    } = this.props;
    if (!children && hoveringAnchorProp === undefined) {
      throw new Error('No children passed, and hovering state not passed.  Preview will not show.');
    }
    const { hoveringAnchor: hoveringAnchorState, hoveringPreview } = this.state;
    const hoveringAnchor = hoveringAnchorProp || hoveringAnchorState;
    const open = hoveringAnchor || hoveringPreview;
    if (open) this.fetchContents();
    const { id, utterancesData } = this.getData();
    const { data, name } = utterancesData;
    const idPre = `workflow-preview-${id}`;
    const arrowId = `${idPre}-arrow`;
    const anchorId = this.props.anchorId || `${idPre}-button`;
    const hasRefresh = refreshText && refreshFn !== undefined;
    return (
      <>
        {children && (
          <SvgIcon
            className="ChartHeader-Icon-Lg"
            id={anchorId}
            onMouseEnter={() => this.onMouseEnter(true)}
            onMouseLeave={() => this.onMouseLeave(true)}
            data-cy="UtterancesPreviewer-Button"
          >
            {children}
          </SvgIcon>
        )}
        <Popper
          open={open}
          anchorEl={() => document.getElementById(anchorId)}
          placement="bottom"
          className={`UtterancesPreviewer${onClick ? ' UtterancesPreviewer-Clickable' : ''}`}
          onClick={(e) => {
            e.stopPropagation();
            if (onClick) onClick();
          }}
          modifiers={[
            {
              name: 'arrow',
              options: {
                element: `#${arrowId}`,
              },
            },
          ]}
          onMouseEnter={() => this.onMouseEnter(false)}
          onMouseLeave={() => this.onMouseLeave(false)}
        >
          <div className="UtterancesPreviewer-Paper">
            <span className="UtterancesPreviewer-Arrow" id={arrowId} />
            <div className="UtterancesPreviewer-Header">
              <Tooltip title={name} placement="top">
                <span>{displayName || name}</span>
              </Tooltip>
              <span className="PreviewTag">
                {hasRefresh ? (
                  <>
                    {refreshText}
                    <IconButton onClick={refreshFn} size="small">
                      <Refresh fontSize="small" />
                    </IconButton>
                  </>
                ) : (
                  tag || 'Preview'
                )}
              </span>
            </div>
            <UtterancesList
              utterances={(data || []).map((line, i) => ({
                id: i + 1,
                data: containsTaggedJson(line) ? collapseTaggedJson(line) : line,
                next: false,
              }))}
              placeholder={this.getEmptyPlaceHolder(data)}
            />
          </div>
        </Popper>
      </>
    );
  }
}

UtterancesPreviewer.defaultProps = {
  refreshText: '',
  refreshFn: undefined,
};

const mapStateToProps = (state, props) => ({
  workflow: selectWorkflowPreview(state, props.workflowId),
  session: selectSessionPreview(state, props.sessionId),
});

export default connect(mapStateToProps, {
  getWorkflowPreviewRequest,
  getSessionPreviewRequest,
  getWorkflowPreviewAPIKeyRequest,
})(UtterancesPreviewer);
