import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EmptyPlaceholderText } from '../../../../constants/databaseBrowser';
import {
  selectFilterTermById,
  selectNamespaceHasFetchedAllTables,
  selectNamespaceRequestingTablesStatus,
  selectNamespaceTables,
} from '../../../../store/selectors/dbBrowser.selector';
import { listNamespaceTablesRequest } from '../../../../store/slices/dbBrowser.slice';
import {
  isStatusRequesting,
  isStatusRequestingMore,
  isStatusUnrequested,
} from '../../../../utils/home_screen';
import TextWithTooltip from '../../../common/TextWithTooltip';
import StatusPlaceholder from '../../../StatusPlaceholder';
import { TABLE_PAGE_SIZE } from '../../constants';
import StickyWrapper, {
  StickyWrapperContentProps,
  StickyWrapperOption,
} from '../../StickyWrapper/StickyWrapper';
import MetadataItem from '../Metadata/MetadataItem';
import TableItem from './TableItem';

const TableList: React.FC<StickyWrapperContentProps> = ({ parents, current }) => {
  const dispatch = useDispatch();

  // Pull out information from props
  const parentConnUUID = parents?.[0]?.id ?? '';
  const namespace = current.name;

  // Redux state
  const isFiltered = useSelector(selectFilterTermById(parentConnUUID));
  const tablesObj = useSelector(selectNamespaceTables(parentConnUUID, namespace));
  const tables = Object.keys(tablesObj);
  const childrenEmpty = tables?.length === 0;
  const hasFetchedAllTables = useSelector(
    selectNamespaceHasFetchedAllTables(parentConnUUID, namespace),
  );

  const requestingTableStatus = useSelector(
    selectNamespaceRequestingTablesStatus(parentConnUUID, namespace),
  );

  // computed state
  const options: StickyWrapperOption[] = tables.map((table) => ({
    id: namespace,
    name: table,
  }));

  const fetchTables = () => {
    // Request the next page of tables
    const nextPage = Math.floor(tables.length / TABLE_PAGE_SIZE);
    dispatch(
      listNamespaceTablesRequest({
        connectionUUID: parentConnUUID,
        namespace,
        page: nextPage,
        refresh: false,
      }),
    );
  };

  React.useEffect(() => {
    if (isStatusUnrequested(requestingTableStatus)) {
      fetchTables();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isRefreshing =
    isStatusRequesting(requestingTableStatus) && !isStatusRequestingMore(requestingTableStatus);

  if (isRefreshing || childrenEmpty) {
    // Return a placeholder if we are still fetching namespaces
    // or if we failed to fetch namespaces
    // or if the database is empty
    return (
      <Box
        className="DatabaseBrowser-TableList-Navigation-Placeholder"
        data-testid="DatabaseBrowser-TableList-Navigation-Placeholder"
      >
        <StatusPlaceholder
          status={requestingTableStatus}
          isEmpty={childrenEmpty}
          emptyText={isFiltered ? EmptyPlaceholderText.Filter : EmptyPlaceholderText.Namespace}
          smallLoader
        />
      </Box>
    );
  }

  return (
    <Box data-testid="TableList">
      <StickyWrapper
        options={options}
        Content={TableItem}
        parents={parents}
        renderChildren={(args: StickyWrapperContentProps) => (
          <MetadataItem parents={[...args.parents, args.current]} current={args.current} />
        )}
      />
      {
        // Add a fetch more button if not all tables have been fetched
        !hasFetchedAllTables ? (
          <Button
            key="fetch-more-tables-button"
            data-testid="fetch-more-tables-button"
            size="large"
            onClick={fetchTables}
            className="DatabaseBrowser-Navigation-FetchMore"
            variant="outlined"
            fullWidth
            sx={{ marginTop: '10px' }}
          >
            <TextWithTooltip text="Load More Tables" />
          </Button>
        ) : null
      }
    </Box>
  );
};

export default TableList;
