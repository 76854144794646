import Share from '@mui/icons-material/Share';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { AskPromptClass } from '../../../../../../constants/dataAssistant';
import { openLinkDialog } from '../../../../../../store/actions/share.actions';

// renders a share button for each answer message
// when a user clicks on the button, it opens a dialog to create a public link
const CreateLinkAction = ({ messages }) => {
  const dispatch = useDispatch();
  // for each message in an response node find the message with ask_prompt === 'answer'
  // this is the message that contains the chart / table
  const dataResult = messages.find((message) => message.ask_prompt === AskPromptClass.ANSWER);
  return (
    <IconButton size="small" onClick={() => dispatch(openLinkDialog(dataResult))}>
      <Share fontSize="inherit" />
    </IconButton>
  );
};

CreateLinkAction.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default CreateLinkAction;
