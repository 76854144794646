import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import mapKeys from 'lodash/mapKeys';

export interface ContextDataset {
  name: string;
  version: number;
}

export interface DataAssistantFeedback {
  app_context: string;
  content: {
    question?: string;
    recipe?: string[];
    vectorId?: string;
  };
  id: number;
  session_id: { String: string; Valid: boolean };
  time: string;
  user_id: number;
  vectorId: string;
  vote: number;
}

export interface CheckContextSuccessPayload {
  exists: boolean;
  datasets: string[];
}

export interface AddToDataSpaceRequestPayload {
  dcDatasetId: string | undefined;
}

export interface AddToChartSpaceRequestPayload {
  dcChartId: string | undefined;
}

export interface SubmitAskFeedbackRequestPayload {
  vectorId: string;
  vote: number;
  metadata: {
    answers?: object;
    ask_recipe?: string[];
    dc_chart_id?: string;
    dc_dataset_id?: string;
    ignore?: boolean;
    promotedId?: string;
    question?: string;
    timestamp?: number;
    vectorId?: string;
  };
}

export interface EditAskFeedbackRequestPayload {
  vectorId: string;
  vote: number;
  feedbackRecord: DataAssistantFeedback;
}

export interface AskQuestionRequestPayload {
  question: string;
}

export interface SendAskMessageRequestPayload {
  question: string;
  datasets: { name: string; version: number }[];
  taskId: string;
}

export interface DataAssistantState {
  feedbackList: { [key: string]: DataAssistantFeedback };
  context: string[];
  error: string | null;
  feedbackError: Error | null;
  askError: string | null;
}

const initialState: DataAssistantState = {
  feedbackList: {},
  context: [],
  error: null,
  feedbackError: null,
  askError: null,
};

const dataAssistantSlice = createSlice({
  name: 'dataAssistant',
  initialState,
  reducers: {
    clearContextRequest: (state) => {
      state.error = null;
    },
    clearContextSuccess: (state) => {
      state.context = [];
    },
    clearContextFailure: (state, { payload }: PayloadAction<{ error: Error }>) => {
      state.error = payload.error.message;
    },
    checkContextRequest: (state) => {
      state.error = null;
    },
    checkContextSuccess: (state, { payload }: PayloadAction<CheckContextSuccessPayload>) => {
      if (payload.exists) {
        state.context = payload.datasets;
      } else {
        state.context = [];
      }
    },
    checkContextFailure: (state, { payload }: PayloadAction<{ error: Error }>) => {
      state.error = payload.error.message;
      state.context = [];
    },
    addToDataSpaceRequest: {
      reducer: (state) => {
        state.error = null;
      },
      prepare: (payload: AddToDataSpaceRequestPayload) => ({ payload }),
    },
    addToDataSpaceSuccess: () => {},
    addToDataSpaceFailure: (state, { payload }: PayloadAction<{ error: Error }>) => {
      state.error = payload.error.message;
    },
    addToChartSpaceRequest: {
      reducer: (state) => {
        state.error = null;
      },
      prepare: (payload: AddToChartSpaceRequestPayload) => ({ payload }),
    },
    addToChartSpaceSuccess: () => {},
    addToChartSpaceFailure: (state, { payload }: PayloadAction<{ error: Error }>) => {
      state.error = payload.error.message;
    },
    queueQuestion: {
      reducer: () => {},
      prepare: (payload: string) => ({ payload }),
    },
    resetDataAssistant: () => initialState,
    getAskFeedbackRequest: {
      reducer: (state) => {
        state.feedbackError = null;
      },
      prepare: (payload: { sessionId: string }) => ({ payload }),
    },
    getAskFeedbackSuccess: (
      state,
      { payload }: PayloadAction<{ feedbackList: DataAssistantFeedback[] }>,
    ) => {
      state.feedbackList = {
        ...state.feedbackList,
        ...mapKeys(payload.feedbackList, 'vectorId'),
      };
    },
    getAskFeedbackFailure: (state, { payload }: PayloadAction<{ error: Error }>) => {
      state.feedbackError = payload.error;
    },
    submitAskFeedbackRequest: {
      reducer: (state) => {
        state.feedbackError = null;
      },
      prepare: (payload: SubmitAskFeedbackRequestPayload) => ({ payload }),
    },
    submitAskFeedbackSuccess: (
      state,
      { payload }: PayloadAction<{ feedbackRecord: DataAssistantFeedback }>,
    ) => {
      state.feedbackList[payload.feedbackRecord.vectorId] = payload.feedbackRecord;
    },
    submitAskFeedbackFailure: (state, { payload }: PayloadAction<{ error: Error }>) => {
      state.feedbackError = payload.error;
    },
    editAskFeedbackRequest: {
      reducer: (state) => {
        state.feedbackError = null;
      },
      prepare: (payload: EditAskFeedbackRequestPayload) => ({ payload }),
    },
    editAskFeedbackSuccess: (
      state,
      { payload }: PayloadAction<{ feedbackRecord: DataAssistantFeedback }>,
    ) => {
      state.feedbackList[payload.feedbackRecord.vectorId] = payload.feedbackRecord;
    },
    editAskFeedbackFailure: (state, { payload }: PayloadAction<{ error: Error }>) => {
      state.feedbackError = payload.error;
    },
    askQuestionRequest: {
      reducer: (state) => {
        state.askError = null;
      },
      prepare: (payload: AskQuestionRequestPayload) => ({
        payload: { question: payload.question },
      }),
    },
    askQuestionSuccess: () => {},
    askQuestionFailure: (state, { payload }: PayloadAction<{ error: Error }>) => {
      state.askError = payload.error.message;
    },
    sendAskMessageRequest: {
      reducer: (state) => {
        state.askError = null;
      },
      prepare: (payload: SendAskMessageRequestPayload) => ({
        payload: {
          question: payload.question,
          datasets: payload.datasets,
          taskId: payload.taskId,
        },
      }),
    },
  },
});

export const {
  clearContextRequest,
  clearContextSuccess,
  clearContextFailure,
  checkContextRequest,
  checkContextSuccess,
  checkContextFailure,
  addToDataSpaceRequest,
  addToDataSpaceSuccess,
  addToDataSpaceFailure,
  addToChartSpaceRequest,
  addToChartSpaceSuccess,
  addToChartSpaceFailure,
  queueQuestion,
  resetDataAssistant,
  getAskFeedbackRequest,
  getAskFeedbackSuccess,
  getAskFeedbackFailure,
  submitAskFeedbackRequest,
  submitAskFeedbackSuccess,
  submitAskFeedbackFailure,
  editAskFeedbackRequest,
  editAskFeedbackSuccess,
  editAskFeedbackFailure,
  askQuestionRequest,
  askQuestionSuccess,
  askQuestionFailure,
  sendAskMessageRequest,
} = dataAssistantSlice.actions;

export default dataAssistantSlice.reducer;
