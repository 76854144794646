// duplicated from worker/dc/core/message_store/message_store.py
export enum NodeTypes {
  NLQ = 'nlq',
  NLQResponse = 'nlq_response',
  GEL = 'gel',
  GELResponse = 'gel_response',
  SessionEvent = 'session_event',
  TopicEvent = 'topic_event',
  Root = 'root',
}

export enum MessageTypes {
  Text = 'text',
  Table = 'table',
  Viz = 'viz',
  Pivot = 'pivot table',
  Annotation = 'annotation',
  Note = 'note',
}

export enum MessageSourceType {
  Client = 'Client',
  Server = 'Server',
}
