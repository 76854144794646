import { isAxiosError } from 'axios';
import { call, getContext, put, select, takeLatest, takeLeading } from 'typed-redux-saga';
import {
  QuestionRecommendationEngagementPayload,
  RecommendationsPayload,
  RecommendationsRegisterEngagementPayload,
} from '../../api/recommendations.api';
import { ReduxSagaContext } from '../../configureStore';
import { API_SERVICES } from '../../constants/api';
import { LLMQueryType } from '../../constants/recommendations';
import {
  selectActiveDatasets,
  selectSelectedDatasetName,
  selectSelectedDatasetVersion,
} from '../selectors/dataspace.selector';
import { selectSession } from '../selectors/session.selector';
import { getDataspaceSuccess } from '../slices/dataspace.slice';
import {
  GetRecommendationsRequestPayload,
  ObjectivesRecommendation,
  QuestionRecommendation,
  RecommendationsLoadingStatus,
  getObjectivesRecommendationsFailure,
  getObjectivesRecommendationsRequest,
  getObjectivesRecommendationsSuccess,
  getQuestionRecommendationsFailure,
  getQuestionRecommendationsRequest,
  getQuestionRecommendationsSuccess,
  registerEngagementWithObjectivesRecommendationFailure,
  registerEngagementWithObjectivesRecommendationRequest,
  registerEngagementWithObjectivesRecommendationSuccess,
  registerEngagementWithQuestionRecommendationFailure,
  registerEngagementWithQuestionRecommendationRequest,
  registerEngagementWithQuestionRecommendationSuccess,
  setObjectivesRecommendationsLoadingStatus,
  setQuestionRecommendationsLoadingStatus,
} from '../slices/recommendations.slice';
import { selectAccessToken, selectUserID } from './selectors';

function mergeRecommendations(
  recommendations: QuestionRecommendation[] | ObjectivesRecommendation[],
  recommendationsType: string[] | undefined,
) {
  if (recommendationsType && recommendationsType.length > 0) {
    return recommendations.map((item, index) => {
      return { ...item, recommendation_type: recommendationsType[index] };
    });
  }
  return recommendations;
}

export function* getQuestionRecommendationsRequestWorker(
  action: {
    payload: GetRecommendationsRequestPayload;
  } = { payload: {} },
) {
  try {
    // get session id from the action payload, if not found, get it from the store
    let sessionId = action.payload?.sessionId;
    if (!sessionId) sessionId = yield* select(selectSession);
    if (!sessionId) throw new Error('Session id not found');

    // request has been queued and ticket received, set the loading status to generating
    yield* put(setQuestionRecommendationsLoadingStatus(RecommendationsLoadingStatus.Generating));

    const recommendationsService = (yield* getContext(
      API_SERVICES.RECOMMENDATIONS,
    )) as ReduxSagaContext[API_SERVICES.RECOMMENDATIONS];

    // Need to assert the types here because these are imported from js files
    const accessToken = yield* select(selectAccessToken);
    const userId = (yield* select(selectUserID)) as number;
    const datasetList = (yield select(selectActiveDatasets)) as {
      name: string;
      numRows: number;
      numColumns: number;
      columnMetadata: { [key: string]: string };
      version: number;
    }[];

    // build array of just name and version
    const datasets = datasetList.map((dataset) => ({
      name: dataset.name,
      version: dataset.version,
    }));

    // build the payload the endpoint expects
    const payload: RecommendationsPayload = {
      query_type: LLMQueryType.recommendQuestions,
      payload: {
        user_id: userId,
        session_id: sessionId,
        datasets,
        // Get cached recommendataions, if available. Otherwise, generate new recommendations.
        no_update: false,
        force_regenerate: false,
      },
    };

    const response = yield* call(
      recommendationsService.getRecommendations,
      accessToken,
      sessionId,
      payload,
    );

    yield* put(
      getQuestionRecommendationsSuccess({
        recommendations: response.data.recommendations,
      }),
    );
  } catch (error) {
    if (error instanceof Error) {
      yield* put(getQuestionRecommendationsFailure({ error }));
    }
  }
}

export function* getObjectivesRecommendationsRequestWorker(action: {
  payload: GetRecommendationsRequestPayload;
}) {
  try {
    // eslint-disable-next-line
    const sessionId = action.payload?.sessionId || (yield* select(selectSession));
    if (!sessionId) {
      throw new Error('Session id not found');
    }

    const refresh = (action.payload?.refresh as boolean) || false;

    // set the loading status
    yield* put(setObjectivesRecommendationsLoadingStatus(RecommendationsLoadingStatus.Generating));

    const recommendationsService = (yield* getContext(
      API_SERVICES.RECOMMENDATIONS,
    )) as ReduxSagaContext[API_SERVICES.RECOMMENDATIONS];

    // Need to assert the types here because these are imported from js files
    const accessToken = yield* select(selectAccessToken);
    const userId = (yield* select(selectUserID)) as number;
    // get the selected dataset. Note: we are only sending one dataset for now
    const datasets = [
      {
        name: yield* select(selectSelectedDatasetName),
        version: yield* select(selectSelectedDatasetVersion),
      },
    ];

    // build the payload the endopint expects
    const payload: RecommendationsPayload = {
      query_type: LLMQueryType.recommendObjectives,
      payload: {
        user_id: userId,
        session_id: sessionId,
        datasets,
        refresh,
      },
    };

    const response = yield* call(
      recommendationsService.getRecommendations,
      accessToken,
      sessionId,
      payload,
    );

    // combine response.data.recommendations and response.data.recommendations_type to one dictionary
    const mergedRecommendations = mergeRecommendations(
      response.data.recommendations,
      response.data.hasOwnProperty('recommendation_types')
        ? (response.data.recommendation_types as string[])
        : undefined,
    );

    yield* put(getObjectivesRecommendationsSuccess({ recommendations: mergedRecommendations }));
  } catch (error) {
    // if error is AxiosError, we can get the response from error.response
    if (isAxiosError(error) && error.response) {
      yield* put(getObjectivesRecommendationsFailure({ error: error.response.data }));
    } else if (error instanceof Error) {
      // for other errors, we can just pass the error object with error.message is empty so that we can show generic error message
      error.message = '';
      yield* put(getObjectivesRecommendationsFailure({ error }));
    }
  }
}

function* objectivesRecommendationRegisterEngagementWorker(action: {
  payload: { recommendation: ObjectivesRecommendation };
}) {
  try {
    const sessionId = yield* select(selectSession);
    if (!sessionId) {
      throw new Error('Session id not found');
    }

    const recommendationsService = (yield* getContext(
      API_SERVICES.RECOMMENDATIONS,
    )) as ReduxSagaContext[API_SERVICES.RECOMMENDATIONS];

    // Need to assert the types here because these are imported from js files
    const accessToken = yield* select(selectAccessToken);
    const userId = (yield* select(selectUserID)) as number;
    // get the selected dataset. Note: we are only sending one dataset for now
    const datasets = [
      {
        name: yield* select(selectSelectedDatasetName),
        version: yield* select(selectSelectedDatasetVersion),
      },
    ];

    const recommendationType = 'objective';
    const records = [
      {
        factors: action.payload.recommendation.factors,
        kpi: action.payload.recommendation.kpi,
        relevance: action.payload.recommendation.relevance,
        title: action.payload.recommendation.title,
        type: action.payload.recommendation.type,
        utterance: action.payload.recommendation.utterance,
      },
    ];

    // build the payload the endopint expects
    const payload: RecommendationsRegisterEngagementPayload = {
      session_id: sessionId,
      user_id: userId,
      recommendation_type: recommendationType,
      datasets,
      records,
    };

    const response = yield* call(
      recommendationsService.recommendationsRegisterEngagement,
      accessToken,
      sessionId,
      userId,
      payload,
    );

    if (response.status === 200) {
      yield* put(registerEngagementWithObjectivesRecommendationSuccess({}));
    } else {
      throw new Error('Error registering engagement with recommendation');
    }
  } catch (error) {
    if (error instanceof Error) {
      yield* put(registerEngagementWithObjectivesRecommendationFailure({}));
    }
  }
}

function* questionRecommendationRegisterEngagementWorker(action: {
  payload: { recommendation: QuestionRecommendation };
}) {
  try {
    const sessionId = yield* select(selectSession);
    if (!sessionId) {
      throw new Error('Session id not found');
    }

    const recommendationsService = (yield* getContext(
      API_SERVICES.RECOMMENDATIONS,
    )) as ReduxSagaContext[API_SERVICES.RECOMMENDATIONS];

    // Need to assert the types here because these are imported from js files
    const accessToken = yield* select(selectAccessToken);
    const userId = (yield* select(selectUserID)) as number;

    const datasetList = (yield select(selectActiveDatasets)) as {
      name: string;
      numRows: number;
      numColumns: number;
      columnMetadata: { [key: string]: string };
      version: number;
    }[];

    const datasets = datasetList.map((dataset) => ({
      name: dataset.name,
      version: dataset.version,
    }));

    const recommendationType = 'question';
    const records = [action.payload.recommendation];

    // build the payload the endopint expects
    const payload: QuestionRecommendationEngagementPayload = {
      session_id: sessionId,
      user_id: userId,
      recommendation_type: recommendationType,
      records,
      datasets,
    };

    const response = yield* call(
      recommendationsService.recommendationsRegisterEngagement,
      accessToken,
      sessionId,
      userId,
      payload,
    );

    if (response.status === 200) {
      yield* put(registerEngagementWithQuestionRecommendationSuccess({}));
    } else {
      throw new Error('Error registering engagement with recommendation');
    }
  } catch (error) {
    if (error instanceof Error) {
      yield* put(registerEngagementWithQuestionRecommendationFailure({}));
    }
  }
}

export function* QuestionRecommendationsSaga() {
  yield* takeLatest(
    [getDataspaceSuccess, getQuestionRecommendationsRequest],
    getQuestionRecommendationsRequestWorker,
  );
}

export function* ObjectivesRecommendationsSaga() {
  yield* takeLatest(getObjectivesRecommendationsRequest, getObjectivesRecommendationsRequestWorker);
}

export function* ObjectiveRecommendationRegisterEngagementSaga() {
  yield* takeLeading(
    registerEngagementWithObjectivesRecommendationRequest,
    objectivesRecommendationRegisterEngagementWorker,
  );
}

export function* QuestionRecommendationRegisterEngagementSaga() {
  yield* takeLeading(
    registerEngagementWithQuestionRecommendationRequest,
    questionRecommendationRegisterEngagementWorker,
  );
}
