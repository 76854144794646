import isEmpty from 'lodash/isEmpty';
import { createSelector } from 'reselect';
import { RootState } from '../../configureStore';

export const selectShowAccessDialog = (state: RootState) => state.accessDialog.show;
export const selectAccessDialogUuid = (state: RootState) => state.accessDialog.uuid;
export const selectAccessDialogData = (state: RootState) => state.accessDialog.data;
export const selectIsAccessDialogLoading = (state: RootState) => state.accessDialog.isLoading;
export const selectAccessDialogIsSaving = (state: RootState) => state.accessDialog.isSaving;
export const selectOrganizationAccessObject = (state: RootState) =>
  state.accessDialog.organizationAccess;
export const selectAccessDialogAccessors = (state: RootState) => state.accessDialog.accessors;
export const selectAccessDialogAccessorsByUuid = createSelector(
  [selectAccessDialogAccessors, (state: RootState, uuid: string) => uuid],
  (accessors, uuid) => accessors[uuid] ?? {},
);

/**
 * Returns true if an object is shared with organization.
 */
export const selectHasOrganizationAccess = createSelector(
  [selectOrganizationAccessObject],
  (organizationAccess) => !isEmpty(organizationAccess),
);
