import produce from 'immer';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getColumnsInUseNames,
  getDatasetInfo,
  shouldUseBackendCompute,
} from 'translate_dc_to_echart';
import { FETCH_ROWS_COUNT } from '../../pages/authenticated/constants';
import { sampleDatasetRequest } from '../../store/actions/dataset.actions';
import { selectSessionDatasetStorage } from '../../store/sagas/selectors';
import { selectSession } from '../../store/selectors/session.selector';
import { SessionDatasetClaim } from '../../types/claim.type';
import usePipelinerDatasetDescription from '../usePipelinerDatasetDescription';
import {
  addDataAndUpdateToChartSpec,
  useDataFromStorage,
  useSessionPipelinerDatasetId,
  useTablePagination,
} from './dataUtils';

/**
 * Insert the row/column data into the message AND
 * Combine the update information into the message's chart spec
 * @param {Object} datasetStorage The key/value pairs of { referenceString: objectData }
 * @param {Function} dispatch The dispatch function
 * @param {Boolean} isEChart Is this an EChart message?
 * @param {Boolean} isFatTable Does the dataset contain a lot of columns?
 * @param {Object} message The original message data
 * @param {String} objectId The id of the chart
 * @param {Object} update The updates to the message data
 * @returns The condensed & completed chart message
 */
const useEChartData = (
  datasetStorage,
  dispatch,
  isEChart,
  isFatTable,
  message,
  objectId,
  update,
) => {
  // Does the message already contain data?
  // This supports backwards compatibility before charts retrieved from dataset references
  const hasMessageData =
    message?.chart?.data?.values?.rows?.length > 0 &&
    message?.chart?.data?.values?.columns?.length > 0;

  // Get the datasetName & datasetVersion from the message
  const { datasetName, datasetVersion } = getDatasetInfo(message.chart.data);

  // Get the pipelinerDatasetId from the list of datasets in the session
  const pipelinerDatasetId = useSessionPipelinerDatasetId({
    datasetName,
    datasetVersion,
  });

  const sessionId = useSelector(selectSession) ?? '';
  const description = usePipelinerDatasetDescription(
    pipelinerDatasetId,
    new SessionDatasetClaim(sessionId),
    { totalRowCount: true },
  );

  const [eChartContext, setEChartContext] = useState(message);
  const stringifiedUpdate = JSON.stringify(update);

  // Detect & reset eChartContext if the fundamental message changes
  // Ex. Switching between tabs in a TabVisual
  useEffect(() => {
    setEChartContext(message);
  }, [message?.chart?.objectId]); // eslint-disable-line

  // Prefer the dataSampleLimit of the update (if any) over the message
  const dataSampleLimit = update?.spec
    ? update.spec.dataSampleLimit ?? null
    : message?.chart?.data?.values?.dataSampleLimit ?? null;

  // Prefer the info from the update (if any) over the message
  const computeSpec = useMemo(
    () => ({
      aggregate: update?.spec?.aggregate ?? message?.chart?.data?.values?.aggregate ?? [],
      bins: update?.spec?.bins ?? message?.chart?.data?.values?.bins ?? [],
      transforms: update?.spec?.transforms ?? message?.chart?.data?.values?.transforms ?? [],
    }),
    [message, update],
  );

  // Did we do aggregation, binning, filtering, etc on the BE?
  const usedCompute = useMemo(
    () =>
      shouldUseBackendCompute({
        computeSpec,
        numRows: dataSampleLimit,
      }),
    [computeSpec, dataSampleLimit],
  );

  // Select the chart data from the reducer state
  const {
    data: chartData,
    error: chartError,
    isFailed: isSampleFailed,
    isLoading: isSampleLoading,
  } = useDataFromStorage({
    dcChartId: isFatTable ? objectId : null,
    computeSpec,
    datasetStorage,
    hasMessageData,
    isTable: false,
    pipelinerDatasetId,
    usedCompute,
  });

  // get total row count
  let totalRowCount: number;

  // if we used compute, the total row count is from the computed data
  if (usedCompute) totalRowCount = chartData?.totalRowCount;
  // else use the description's total row count
  else totalRowCount = description.kind === 'data' ? description.data.totalRowCount ?? 0 : 0;

  // If we have no data
  // If we have data but it's less than the limit
  // If we we have data, but it's not equal to totalRowCount && dataSampleLimit is null
  const rowCount = chartData?.rows?.length ?? 0;
  const needsMoreData =
    !hasMessageData &&
    (isEmpty(chartData) ||
      dataSampleLimit > rowCount ||
      (totalRowCount !== rowCount && dataSampleLimit === null));

  // Send a request to the endpoint for the chart data
  useEffect(() => {
    if (isEChart && needsMoreData && pipelinerDatasetId) {
      dispatch(
        sampleDatasetRequest({
          computeSpec,
          dcChartId: isFatTable ? objectId : null,
          isTable: false,
          numRows: dataSampleLimit ?? undefined,
          pipelinerDatasetId,
          selectedColumns: isFatTable ? getColumnsInUseNames(message, update) : null,
        }),
      );
    }
  }, [
    computeSpec,
    dataSampleLimit,
    dispatch,
    eChartContext?.chart?.data?.values?.dataSampleLimit,
    isEChart,
    isFatTable,
    message,
    needsMoreData,
    objectId,
    pipelinerDatasetId,
    update,
  ]);

  // Add the new data from the reducer to the chart spec
  // Merge the update into the chart spec
  useEffect(() => {
    if (message && isEChart && !needsMoreData && !isSampleFailed && !isSampleLoading) {
      // Use the data from the chartData
      setEChartContext(
        addDataAndUpdateToChartSpec(message, update, dataSampleLimit, totalRowCount, chartData),
      );
    }
    // eslint-disable-next-line
  }, [
    chartData.rows?.length,
    chartData.columns?.length,
    isEChart,
    message,
    needsMoreData,
    stringifiedUpdate,
  ]);

  if (!isEChart) return [];
  return [eChartContext, chartError, isSampleFailed, isSampleLoading];
};

// Note: These types handle their own update information
const useNoteData = (isNote, m) => (isNote ? [m] : []);
const usePivotData = (isPivot, m) => (isPivot ? [m] : []);
const usePlotlyData = (isPlotlyChart, m) => (isPlotlyChart ? [m] : []);
const useRecipeData = (isRecipeDiagram, m) => (isRecipeDiagram ? [m] : []);

const useTableData = (datasetStorage, dispatch, isTable, message) => {
  const { name: datasetName, version: datasetVersion } =
    message.chart.update?.spec?.data ?? message.chart.data ?? {};

  // Initial tableContext is the message without data
  let tableContext = message;

  // Get the pipelinerDatasetId from the list of datasets in the session
  const pipelinerDatasetId = useSessionPipelinerDatasetId({
    datasetName,
    datasetVersion,
  });

  const {
    data: tableData,
    error: tableError,
    isFailed: isTableSampleFailed,
    isLoading: isTableSampleLoading,
  } = useDataFromStorage({
    datasetStorage,
    isTable: true,
    pipelinerDatasetId,
  });

  // If we have no data
  // If we have data but it's less than the minimum, it's not the whole dataset, and it's not forgotten
  const needsMoreData =
    isEmpty(tableData) ||
    (tableData.rows?.length < FETCH_ROWS_COUNT &&
      tableData.rows?.length !== tableData.totalRowCount &&
      !tableData.forgotten);

  // Send a request to the endpoint for the table data
  useEffect(() => {
    if (isTable && needsMoreData && pipelinerDatasetId) {
      dispatch(
        sampleDatasetRequest({
          isTable,
          numRows: FETCH_ROWS_COUNT,
          pipelinerDatasetId,
        }),
      );
    }
  }, [dispatch, isTable, needsMoreData, pipelinerDatasetId]);

  // Add the data from the reducer to the table message
  if (message && isTable) {
    tableContext = produce(message, (draftData) => {
      if (message.chart?.update?.spec?.data) {
        draftData.update.spec.data = {
          ...draftData.update.spec.data,
          columns: tableData.columns,
          forgotten: Boolean(tableData.forgotten),
          rows: tableData.rows,
          tableSampleRowCount: tableData.tableSampleRowCount,
          totalRowCount: tableData.totalRowCount,
        };
      } else {
        draftData.chart.data = {
          ...draftData.chart.data,
          columns: tableData.columns,
          forgotten: Boolean(tableData.forgotten),
          rows: tableData.rows,
          tableSampleRowCount: tableData.tableSampleRowCount,
          totalRowCount: tableData.totalRowCount,
        };
      }
    });
  }

  // Handle for table pagination
  const [rowCount, updateRowCount] = useTablePagination({
    data: tableData,
    dispatch,
    isTable,
    pipelinerDatasetId,
  });

  const forgotten = tableData?.forgotten ?? false;

  if (!isTable) return [];
  return [
    tableContext,
    tableError,
    isTableSampleFailed,
    isTableSampleLoading,
    rowCount,
    updateRowCount,
    forgotten,
  ];
};

const SessionData = (props) => {
  const { children, datasetName, datasetVersion, isFatTable, objectId, type, update } = props;

  const messageIcon = props.message?.icon ?? null;
  const messageType = props.message?.chart?.type ?? type ?? null;
  const messageTypeVersion = props.message?.chart?.typeVersion ?? null;

  // Unify the incoming props into a standardized message object
  const message = useMemo(() => {
    if (!props.message?.chart?.data) {
      // Some types must have data since they don't do any retrieval
      if (messageType === 'note') throw new Error('Notes must include data');

      // Use the datasetName, datasetVersion, and type props
      return { chart: { data: { name: datasetName, version: datasetVersion }, type: messageType } };
    }

    // Use the message prop
    return props.message;
  }, [datasetName, datasetVersion, props.message, messageType]);

  const dispatch = useDispatch();
  const datasetStorage = useSelector(selectSessionDatasetStorage);

  const isEChart =
    messageType === 'viz' && messageTypeVersion === 2 && messageIcon !== 'RecipeDiagram';
  const isNote = messageType === 'note';
  const isPivot = messageType === 'pivot table';
  const isPlotlyChart = messageType === 'viz' && (!messageTypeVersion || messageTypeVersion === 1);
  const isRecipeDiagram =
    messageType === 'viz' && messageTypeVersion === 2 && messageIcon === 'RecipeDiagram';
  const isTable = messageType === 'table';

  const [eChartContext, chartError, isEChartSampleFailed, isEChartSampleLoading] = useEChartData(
    datasetStorage,
    dispatch,
    isEChart,
    isFatTable,
    message,
    objectId,
    update,
  );
  const [noteContext] = useNoteData(isNote, message);
  const [pivotContext] = usePivotData(isPivot, message);
  const [plotlyContext] = usePlotlyData(isPlotlyChart, message);
  const [recipeContext] = useRecipeData(isRecipeDiagram, message);
  const [
    tableContext,
    tableError,
    isTableSampleFailed,
    isTableSampleLoading,
    rowCount,
    updateRowCount,
    forgotten,
  ] = useTableData(datasetStorage, dispatch, isTable, message);

  if (isEChart)
    return <>{children(eChartContext, chartError, isEChartSampleFailed, isEChartSampleLoading)}</>;
  if (isNote) return <>{children(noteContext)}</>;
  if (isPivot) return <>{children(pivotContext)}</>;
  if (isPlotlyChart) return <>{children(plotlyContext)}</>;
  if (isRecipeDiagram) return <>{children(recipeContext)}</>;
  if (isTable)
    return (
      <>
        {children(
          tableContext,
          tableError,
          isTableSampleFailed,
          isTableSampleLoading,
          rowCount,
          updateRowCount,
          forgotten,
        )}
      </>
    );

  // We should never hit this case. All types should be specifically handled
  return <>{children(message)}</>;
};

SessionData.propTypes = {
  children: PropTypes.func.isRequired,
  datasetName: PropTypes.string,
  datasetVersion: PropTypes.number,
  isFatTable: PropTypes.bool,
  message: PropTypes.object,
  objectId: PropTypes.string,
  type: PropTypes.string,
  update: PropTypes.object,
};

SessionData.defaultProps = {
  datasetName: null,
  datasetVersion: null,
  isFatTable: false,
  message: {},
  objectId: undefined,
  type: null,
  update: {},
};

export default SessionData;
