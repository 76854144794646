import axios from 'axios';
import { endpoints } from '../constants/endpoints';

export const sendContactForm = (accessToken, contactForm) =>
  axios.post(endpoints.contact, JSON.stringify(contactForm), {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });
