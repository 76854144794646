/**
 * @fileoverview Contains custom rendering and sorting functions for MaterialTable columns
 */
import { Column } from '@material-table/core';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import format from 'date-fns/format';
import React from 'react';
import { homeObjectIcon } from '../../constants/home_screen';
import {
  HomeObjectActiveNames,
  HomeObjectKeys,
  HomeObjectKeysTypes,
  HomeObjects,
} from '../../utils/homeScreen/types';
import { sortDcObjects, sortDcObjectsByScore } from '../../utils/home_screen';
import NameTypeCell from '../HomeScreenObjectTable/NameTypeCell';
import CatalogNameTypeCell from '../common/DatachatCatalog/NavigationMenu/CatalogNameTypeCell';
import './column_utils.scss';

// Options for sorting MaterialTable columns. There is also a third option 'none' which we don't use
export enum SortOrders {
  ASC = 'asc',
  DESC = 'desc',
}

/**
 * Custom rendering functions for material table columns
 */

export const renderLastActiveFunc = (rowData: HomeObjectKeysTypes): string =>
  format(rowData.lastActive, 'MMM d y K:mm a');

export const renderLastModifiedFunc = (rowData: HomeObjectKeysTypes): string =>
  format(rowData.dateLastModified ?? new Date(), 'MMM d y K:mm a');

export const renderCreatedFunc = (rowData: HomeObjectKeysTypes): string =>
  format(rowData.created, 'MMM d y K:mm a');

export const renderObjectName = (rowData: HomeObjectKeysTypes): React.ReactElement => (
  <NameTypeCell rowData={rowData} />
);

export const renderDatasetSource = (rowData: HomeObjectKeysTypes): React.ReactElement => {
  const { ConnectionFileFk, DataFileFk, WorkflowFileFk } = rowData;
  const objectType = ConnectionFileFk
    ? HomeObjects.CONNECTION
    : DataFileFk
    ? HomeObjects.DATAFILE
    : WorkflowFileFk
    ? HomeObjects.RECIPE
    : '';

  if (objectType) {
    let tooltip = objectType.toLowerCase();
    tooltip = tooltip.charAt(0).toUpperCase() + tooltip.slice(1);
    return homeObjectIcon({ objectType, tooltip });
  }
  return (
    <Box>
      <Divider className="default-icon" />
    </Box>
  );
};

/**
 * Custom sorting functions for material table columns
 */

export const sortByLastActive = (
  currSortOrder: string,
  a: HomeObjectKeysTypes,
  b: HomeObjectKeysTypes,
): number => sortDcObjects(a, b, currSortOrder, HomeObjectKeys.LAST_ACTIVE);

export const sortByObjectName = (
  currSortOrder: string,
  a: HomeObjectKeysTypes,
  b: HomeObjectKeysTypes,
): number => sortDcObjects(a, b, currSortOrder, HomeObjectKeys.NAME);

export const sortByLastModified = (
  currSortOrder: string,
  a: HomeObjectKeysTypes,
  b: HomeObjectKeysTypes,
): number => sortDcObjects(a, b, currSortOrder, HomeObjectKeys.LAST_MODIFIED);

export const sortByCreated = (
  currSortOrder: string,
  a: HomeObjectKeysTypes,
  b: HomeObjectKeysTypes,
): number => sortDcObjects(a, b, currSortOrder, HomeObjectKeys.CREATED);

export const sortByScore = (a: HomeObjectKeysTypes, b: HomeObjectKeysTypes): number =>
  sortDcObjectsByScore(a, b);

/**
 * Get the material table columns for each context
 */

export const getHomeColumns = (
  objectType: string,
  currSortOption: string,
  currentSortOrder: SortOrders,
): Column<HomeObjectKeysTypes>[] => {
  const columns: Column<HomeObjectKeysTypes>[] = [
    {
      title: HomeObjectActiveNames.NAME,
      field: HomeObjectKeys.NAME,
      width: '80%',
      customSort: (a: HomeObjectKeysTypes, b: HomeObjectKeysTypes) =>
        sortByObjectName(currentSortOrder, a, b),
      render: renderObjectName,
      sorting: true,
    },
    {
      title: HomeObjectActiveNames.LAST_ACTIVE,
      field: HomeObjectKeys.LAST_ACTIVE,
      width: '20%',
      defaultSort: currentSortOrder,
      customSort: (a: HomeObjectKeysTypes, b: HomeObjectKeysTypes) =>
        sortByLastActive(currentSortOrder, a, b),
      render: renderLastActiveFunc,
      hidden: currSortOption !== HomeObjectKeys.LAST_ACTIVE,
      sorting: true,
    },
    {
      title: HomeObjectActiveNames.LAST_MODIFIED,
      field: HomeObjectKeys.LAST_MODIFIED,
      width: '20%',
      render: renderLastModifiedFunc,
      customSort: (a: HomeObjectKeysTypes, b: HomeObjectKeysTypes) =>
        sortByLastModified(currentSortOrder, a, b),
      hidden: currSortOption !== HomeObjectKeys.LAST_MODIFIED,
      sorting: true,
    },
    {
      title: HomeObjectActiveNames.CREATED,
      field: HomeObjectKeys.CREATED,
      width: '20%',
      render: renderCreatedFunc,
      customSort: (a: HomeObjectKeysTypes, b: HomeObjectKeysTypes) =>
        sortByCreated(currentSortOrder, a, b),
      hidden: currSortOption !== HomeObjectKeys.CREATED,
      sorting: true,
    },
  ];
  // Add extra columns based on type
  switch (objectType) {
    case HomeObjects.ALL:
    case HomeObjects.DATASET:
      columns.splice(1, 0, {
        title: HomeObjectActiveNames.SOURCE,
        render: renderDatasetSource,
        width: '80px',
        sorting: false,
        cellStyle: { paddingLeft: '30px' },
      });
      break;
    default:
      break;
  }
  return columns;
};

/**
 * Generate the columns in a format that MaterialTable expects
 * @param currSortOrder What are we sorting by? ex. 'asc' | 'desc'
 * @param disableDefaultSort Should we disable default sorting
 * @returns MaterialTable columns
 */
export const getDatasetColumns = (
  currSortOrder: SortOrders,
  disableDefaultSort: boolean,
): Column<HomeObjectKeysTypes>[] => {
  return [
    {
      title: HomeObjectActiveNames.NAME,
      field: HomeObjectKeys.NAME,
      width: 'calc(100% - 170px)',
      customSort: (a: HomeObjectKeysTypes, b: HomeObjectKeysTypes) =>
        sortByObjectName(currSortOrder, a, b),
      render: renderObjectName,
    },
    {
      title: HomeObjectActiveNames.LAST_ACTIVE,
      field: HomeObjectKeys.LAST_ACTIVE,
      width: 170,
      // Disable default sort for lastActive when the user searches so Material table doesn't sort based on lastActive
      defaultSort: disableDefaultSort ? undefined : currSortOrder,
      // Remove customSort when user searches so it doesn't override the score sorting
      customSort: disableDefaultSort
        ? undefined
        : (a: HomeObjectKeysTypes, b: HomeObjectKeysTypes) => sortByLastActive(currSortOrder, a, b),
      render: renderLastActiveFunc,
    },
    // Score column as hidden, to enable auto sorting when user types in search field
    {
      title: 'Score',
      field: 'Score',
      width: 150,
      defaultSort: SortOrders.ASC,
      customSort: (a: HomeObjectKeysTypes, b: HomeObjectKeysTypes) => sortByScore(a, b),
      hidden: true,
      sorting: true,
    },
  ];
};

const renderCatalogObjectname = (rowData: HomeObjectKeysTypes): React.ReactElement => (
  <CatalogNameTypeCell rowData={rowData} />
);

export const getCatalogColumns = (currSortOrder: SortOrders): Column<HomeObjectKeysTypes>[] => [
  {
    title: HomeObjectActiveNames.NAME,
    field: HomeObjectKeys.NAME,
    width: 'calc(100% - 45px)',
    customSort: (a: HomeObjectKeysTypes, b: HomeObjectKeysTypes) =>
      sortByObjectName(currSortOrder, a, b),
    render: renderCatalogObjectname,
  },
];
