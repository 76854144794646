import { eventChannel, EventChannel } from 'redux-saga';
import buildQueryParams from '../utils/api/buildQueryParams.util';

const DEFAULT_TIMEOUT = 3600000; // 1 hour

export interface SSEConfig {
  url: string;
  timeout?: number;
  onMessage?: (event: MessageEvent) => void;
  onError?: (error: Event) => void;
  onOpen?: (event: Event) => void;
}

export class SSEClient {
  protected config: SSEConfig;

  constructor(config: SSEConfig) {
    this.config = config;
  }

  /**
   * Static connection method
   * Accepts SSE configuration, initiates the connection,
   * and returns the event channel.
   */
  static connect<T = any>(config: SSEConfig): EventChannel<NonNullable<T>> {
    const client = new SSEClient(config);
    const connection = client.createSSEConnection();
    return client.createEventChannel(connection);
  }

  /**
   * Static connection method with query params.
   * Accepts a base URL, query parameters object, and optional additional SSE configuration.
   * It builds the full URL with query params and returns the event channel.
   */
  static connectWithQueryParams<T = any>(
    baseUrl: string,
    params: Record<string, any>,
    options?: Partial<Omit<SSEConfig, 'url'>>,
  ): EventChannel<NonNullable<T>> {
    const queryString = buildQueryParams(params).toString();
    const fullUrl = `${baseUrl}?${queryString}`;
    const config: SSEConfig = { url: fullUrl, ...options };
    return SSEClient.connect(config);
  }

  // Create the underlying SSE connection.
  private createSSEConnection(): EventSource {
    return new EventSource(this.config.url, {
      withCredentials: true, // This enables sending cookies/auth headers
    });
  }

  // Create a redux-saga event channel from the given SSE connection.
  createEventChannel<T = any>(connection: EventSource): EventChannel<NonNullable<T>> {
    return eventChannel((emit) => {
      const effectiveTimeout =
        this.config.timeout === undefined ? DEFAULT_TIMEOUT : this.config.timeout;
      const timeoutId: ReturnType<typeof setTimeout> = setTimeout(() => {
        emit(new Error('SSE connection timed out') as unknown as NonNullable<T>);
        connection.close();
      }, effectiveTimeout);

      connection.onmessage = (event: MessageEvent) => {
        // Call the onMessage hook and emit parsed data.
        if (this.config.onMessage) {
          this.config.onMessage(event);
        }
        // Optionally, parse the event.data
        try {
          emit(JSON.parse(event.data));
        } catch (error) {
          emit(event.data);
        }
      };

      connection.onerror = (error: Event) => {
        // Call onError hook if provided.
        if (this.config.onError) {
          this.config.onError(error);
        }
        // Emit an error to the channel.
        emit(new Error('SSE error occurred') as unknown as NonNullable<T>);
      };

      if (this.config.onOpen) {
        connection.onopen = (event: Event) => {
          if (timeoutId) clearTimeout(timeoutId);
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          this.config.onOpen!(event);
        };
      }

      // The subscriber must return an unsubscribe function.
      return () => {
        if (timeoutId) clearTimeout(timeoutId);
        connection.close();
      };
    });
  }
}
