import { createSelector } from 'reselect';
import { RootState } from '../../configureStore';
import { NavigationTabs } from '../../constants/session';
import { selectHasVisibleCharts } from './chartspace.selector';
import { selectHasVisibleDatasets } from './dataspace.selector';

// Session
export const selectSession = (state: RootState) => state.session.session;
export const selectSessionType = (state: RootState) => state.session.sessionType;
export const selectSessionName = (state: RootState) => state.session.sessionName;
export const selectAppId = (state: RootState) => state.session.appId;
export const selectSessionStartTime = (state: RootState) => state.session.startTime;
export const selectSessionCollaborators = (state: RootState) => state.session.collaborators;
export const selectSessionNameSaveFailed = (state: RootState) => state.session.nameSaveFailed;
export const selectDataUseMap = (state: RootState) => state.session.dataUseMap;
export const selectIsEndingSession = (state: RootState) => state.session.isEndingSession;
export const selectAppViewId = (state: RootState) => state.session.appViewId;
export const selectSessionNavigationTabs = (state: RootState) => state.session.navigationTab;
export const selectOpenNavigationTab = (state: RootState) => state.session.openNavigationTab;
export const selectSessionOwner = (state: RootState) => state.session.owner;
export const selectSessionOwnerEmail = createSelector(
  [selectSessionOwner],
  (owner) => owner?.email ?? null,
);
export const selectDetailPanelStatus = (state: RootState) => {
  const sessionId = state.session.session;
  return sessionId ? state.session.detailPanelStatus[sessionId] : false;
};
export const selectCurrentSessionNavigationTab = createSelector(
  [selectSession, selectSessionNavigationTabs],
  (sessionId, navigationTabs) =>
    sessionId !== undefined && navigationTabs[sessionId]
      ? navigationTabs[sessionId]
      : NavigationTabs.DATA_SPACE,
);
export const selectShowPlaceholder = createSelector(
  [selectCurrentSessionNavigationTab, selectHasVisibleDatasets, selectHasVisibleCharts],
  (currentTab, hasVisibleDatasets, hasVisibleCharts) =>
    // If we don't have visible datasets, or we don't have charts
    // and are on that tab, show a placeholder
    currentTab === NavigationTabs.CHART_SPACE ? !hasVisibleCharts : !hasVisibleDatasets,
);
