import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { Row } from 'antd';
import { Col } from '../..';
import { TColors } from '../../theme/colors';

const gridSettings = {
  md: {
    lg: { span: 14 },
    md: { span: 16 },
    sm: { span: 18 },
    xs: { span: 23 },
  },
  lg: {
    xs: { span: 23 },
  },
};

interface IContainerProps {
  background?: keyof TColors;
  children?: React.ReactNode;
  height?: string | number;
  size?: keyof typeof gridSettings;
  $verticalPadding?: number;
  $hasHeader?: boolean;
}

export const StyledContainer = styled(Row)<IContainerProps>`
  max-height: 100vh;
  min-height: 100%;
  overflow-y: scroll;

  ${(props) =>
    props.background &&
    css`
      background-color: ${props.theme?.colors?.[props.background || 'layoutBg']};
    `}

  height: ${(props) => (props.$hasHeader ? 'calc(100vh - 64px)' : '100vh')};

  ${(props) =>
    props.height &&
    css`
      height: ${typeof props.height === 'number' ? `${props.height}px` : props.height || '100%'};
    `}

  ${(props) =>
    props.$verticalPadding !== undefined &&
    css`
      padding-top: ${props.$verticalPadding}px;
      padding-bottom: ${props.$verticalPadding}px;
    `}
`;

const Container: FC<IContainerProps> = ({
  children,
  size = 'lg',
  $verticalPadding = 24,
  ...props
}) => (
  <StyledContainer justify="center" $verticalPadding={$verticalPadding} {...props}>
    <Col {...gridSettings[size]}>{children}</Col>
  </StyledContainer>
);

export default Container;
