import cloneDeep from 'lodash/cloneDeep';
import {
  ANNOTATION_COORDINATE_UPPER,
  MUI_TEXTBOX_PADDING_LEFT,
  MUI_TEXTBOX_PADDING_TOP,
} from './constants';

/**
 * This function will modify some properties of each annotation from presentation spec
 * when users close a text field for an annotation/text, such as "invisible", "interactable"
 * @param {Object} presentation the old presentation spec
 * @param {Boolean} showAnnotations if show annotations
 * @param {Boolean} showTexts if show texts
 * @returns the updated presentation spec after users close a text field for an annotation/text
 */
export const handleCloseTextField = (presentation, showAnnotations, showTexts) => {
  const updatedPresentation = cloneDeep(presentation);
  for (let idx = 0; idx < updatedPresentation.annotations.length; idx++) {
    // if showAnnotations or showTexts
    if (
      (updatedPresentation.annotations[idx].type === 'annotation' && showAnnotations) ||
      (updatedPresentation.annotations[idx].type === 'text' && showTexts)
    ) {
      updatedPresentation.annotations[idx].invisible = false;
      updatedPresentation.annotations[idx].interactable = true;
      if (updatedPresentation.annotations[idx].type === 'annotation') {
        updatedPresentation.annotations[idx].lineInvisible = false;
      }
    }
  }
  return updatedPresentation;
};

/**
 * This function will modify some properties of each annotation from presentation spec
 * when users open a text field for an annotation/text, such as "invisible", "interactable"
 * @param {Object} presentation the old presentation spec
 * @param {Boolean} showAnnotations if show annotations
 * @param {Boolean} showTexts if show texts
 * @returns the updated presentation spec after users open a text field for an annotation/text
 */
export const handleOpenTextField = (presentation, textFieldIndex) => {
  const updatedPresentation = cloneDeep(presentation);
  if (updatedPresentation.annotations[textFieldIndex].type === 'annotation') {
    updatedPresentation.annotations[textFieldIndex].lineInvisible = false;
  }

  updatedPresentation.annotations[textFieldIndex].invisible = true;
  for (let idx = 0; idx < updatedPresentation.annotations.length; idx++) {
    updatedPresentation.annotations[idx].interactable = false;
  }
  return updatedPresentation;
};

/**
 * This function will calculate the left and top value(pixel) of the textfield, and
 * return result as an object
 * @param {Object} ref the echart ref, in order to calculate pixel value by the given coordinate
 * @param {Object} item a annotation in presentation spec
 * @param {Number} width the width of echart
 * @param {Number} height the height of echart
 * @param {Number} menuWidth the width of left side menu(input form)
 * @param {Number} headerHeight the height of header(table title)
 * @param {Number} textHeight the height of text in the annotation
 * @param {Number} textWidth the width of text in the annotation
 * @returns An object which include top and left position value of the textfield
 */
export const getTextFieldPosition = (
  ref,
  item,
  width,
  height,
  menuWidth,
  headerHeight,
  textHeight,
  textWidth,
) => {
  const ecInstance = ref.current?.getEchartsInstance();
  // use ecInstance's width and height if width/height is undefined
  width = width || ecInstance.getWidth();
  height = height || ecInstance.getHeight();

  const top =
    (item.top.substring(0, item.top.indexOf('%')) / 100) * height +
    headerHeight -
    MUI_TEXTBOX_PADDING_TOP;
  const left =
    (item.left.substring(0, item.left.indexOf('%')) / 100) * width +
    menuWidth -
    MUI_TEXTBOX_PADDING_LEFT;
  if (item.coordX && item.coordY && item.gridIndex !== undefined) {
    // convert annotation's position coordinate(item.coordX, item.coordY) to pixel value
    // so that we can use pixel value(item.coordX's pixel, item.coordY's pixel) to position the textfield
    const res = ecInstance.convertToPixel(
      {
        xAxisId: `annotation_cartesian_${item.gridIndex}`,
        yAxisId: `annotation_cartesian_${item.gridIndex}`,
      },
      [item.coordX, item.coordY],
    );
    // if item does locate inside a grid
    if (
      res !== undefined &&
      res[0] >= 0 &&
      res[0] <= ANNOTATION_COORDINATE_UPPER &&
      res[1] >= 0 &&
      res[1] <= ANNOTATION_COORDINATE_UPPER
    ) {
      return {
        top: res[1] - textHeight / 2 + headerHeight - MUI_TEXTBOX_PADDING_TOP,
        left: res[0] - textWidth / 2 + menuWidth - MUI_TEXTBOX_PADDING_LEFT,
      };
    }
    return {
      top,
      left,
    };
  }
  return {
    top,
    left,
  };
};
