import { call, getContext, put, select, takeLatest } from 'redux-saga/effects';
import { API_SERVICES } from '../../constants/api';
import { TOAST_BOTTOM_RIGHT, TOAST_ERROR, TOAST_SHORT } from '../../constants/toast';
import {
  CREATE_PUBLIC_LINK_REQUEST,
  closeLinkDialog,
  createPublicLinkFailure,
  createPublicLinkSuccess,
  openLinkDialog,
} from '../actions/share.actions';
import { addToast } from '../actions/toast.actions';
import { selectSession } from '../selectors/session.selector';
import { selectAccessToken, selectShareLinkDialogOpen } from './selectors';

// saga to create a public link for a datachat item
export function* createPublicLinkRequestWorker({ dcChartId }) {
  try {
    // If the link dialog is already open, don't open it again
    const linkDialogOpen = yield select(selectShareLinkDialogOpen);
    if (!linkDialogOpen) {
      yield put(openLinkDialog());
    }
    const accessToken = yield select(selectAccessToken);
    const destination = 'clipboard';
    if (dcChartId === undefined) {
      throw new Error('Missing required parameters');
    }
    const sessionId = yield select(selectSession);
    const chartService = yield getContext(API_SERVICES.CHART);
    const response = yield call(chartService.shareChart, {
      accessToken,
      sessionId,
      objectId: dcChartId,
      destination,
    });

    if (response && response.data && 'error' in response.data) {
      throw new Error('Error while creating public link for visualization');
    }
    const { link } = response.data;
    const publicLink = `${window.location.protocol}//${window.location.host}${link}`;
    yield put(createPublicLinkSuccess({ publicLink }));
  } catch (error) {
    yield put(createPublicLinkFailure({ error }));
    yield put(closeLinkDialog());
    yield put(
      addToast({
        toastType: TOAST_ERROR,
        length: TOAST_SHORT,
        message: 'Failed to create public link.',
        position: TOAST_BOTTOM_RIGHT,
      }),
    );
  }
}

export default function* () {
  yield takeLatest(CREATE_PUBLIC_LINK_REQUEST, createPublicLinkRequestWorker);
}
