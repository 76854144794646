import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import { PropTypes } from 'prop-types';
import React from 'react';
import { ChartTypes } from 'translate_dc_to_echart';
import { InputType } from '../../../echart-library/types/chartBuilderIO';
import { cleanFields } from '../../../echart-library/utils/chartBuilderIO';
import DataChatAutocompleteInput from '../../common/DataChatAutocompleteInput';
import '../ChartBuilder.scss';
import ChipDropdown from '../UtilComponents/ChipDropdown';
import { iconMapping } from '../utils/constants';
import { autofillInputs } from '../utils/generateFields';
import { renderColumnOption, updateInputSelection } from '../utils/input_utils';
import { convertCamelCase, filterColumns } from '../utils/manageChart';
import AxisConfig from './AxisConfig';
import ComplexColumnProvider from './ComplexColumnProvider';

// Chart types where we allow users to select multiple columns
const MULTI_SELECT_X = [ChartTypes.box, ChartTypes.horizBar];
const MULTI_SELECT_Y = [ChartTypes.line, ChartTypes.stackedArea, ChartTypes.bar];

/**
 * Renders the required input fields that must be filled in order for the chart to render
 * @returns {JSX.Element} The required fields section within the menu
 */
const RequiredFields = (props) => {
  const {
    aggregates,
    applyAxisConfig,
    applyBin,
    bins,
    chooseDataSampleLimit,
    columnsTypes,
    fields,
    isLoadingDataset,
    options,
    transforms,
    updateChart,
  } = props;
  const { type } = options.chartType;
  const { required } = iconMapping[type];

  /**
   * Renders/wraps autocomplete options with tooltips
   * @param {Object} renderOptionProps props to pass to the option ex. key, event handlers, id etc.
   * @param {string} option the text to be displayed for the option
   * @returns the AC option wrapped in a <Tooltip>
   */
  const renderOptionWithTooltip = (renderOptionProps, option, selected, input) => {
    const colType = columnsTypes.find((column) => column.name === option)?.type;
    if (
      (input === InputType.y &&
        (type === ChartTypes.line || type === ChartTypes.stackedArea || type === ChartTypes.bar)) ||
      (input === InputType.x && (type === ChartTypes.box || type === 'horizontal_bar'))
    ) {
      return (
        <Tooltip title={option} key={option} placement="bottom">
          <li {...renderOptionProps} value={option} data-testid={`option-${option}`}>
            <Checkbox value={option} className="CB-Checkbox" checked={selected} />
            {renderColumnOption(option, colType)}
          </li>
        </Tooltip>
      );
    }

    return (
      <Tooltip title={option} key={option} placement="bottom">
        <li {...renderOptionProps} value={option} data-testid={`option-${option}`}>
          {renderColumnOption(option, colType)}
        </li>
      </Tooltip>
    );
  };

  // Gets the className for the row. The last row has a different '-bottom' class
  const getRowClass = (idx) => {
    return required.length - 1 === idx ? 'borderless-row-bottom' : 'borderless-row';
  };

  return (
    <>
      {required.map((input, idx) => {
        let isMultiSelect = false;
        switch (input) {
          case InputType.y:
            isMultiSelect = MULTI_SELECT_Y.includes(type);
            break;
          case InputType.x:
            isMultiSelect = MULTI_SELECT_X.includes(type);
            break;
          default:
            break;
        }

        return (
          <ComplexColumnProvider
            aggregates={aggregates}
            applyBin={applyBin}
            bins={bins}
            chartType={type}
            chooseDataSampleLimit={chooseDataSampleLimit}
            columnsTypes={columnsTypes}
            fields={fields}
            inputName={input}
            key={input}
            updateChart={updateChart}
          >
            {(getComplexColumn, getComplexOptions, handleComplexAction) => (
              <div className={getRowClass(idx)} key={input}>
                <div className="label">
                  {convertCamelCase(input)}
                  <AxisConfig
                    applyAxisConfig={applyAxisConfig}
                    columnNames={fields[input]}
                    columnsTypes={columnsTypes}
                    inputName={input}
                    transforms={transforms}
                  />
                </div>
                <DataChatAutocompleteInput
                  blurOnSelect={!isMultiSelect}
                  data-cy={`${input}-input`}
                  data-testid={`${input}-input`}
                  disableCloseOnSelect={isMultiSelect}
                  isLoading={isLoadingDataset}
                  multiple
                  placeholder="Required"
                  value={fields[input] || []}
                  onChange={(_, newSelection) => {
                    if (newSelection.length === 0) handleComplexAction(null);
                    newSelection = updateInputSelection(newSelection, !isMultiSelect);
                    let updatedFields = cleanFields({ ...fields, [input]: newSelection });

                    // Autofill any inputs as a side-effect of the { [inputName]: newSelectionValue } pair
                    updatedFields = autofillInputs({
                      fields: updatedFields,
                      chartType: type,
                      inputName: input,
                      newSelectionValue: newSelection,
                    });

                    updateChart({ updatedFields });
                  }}
                  options={filterColumns(fields, columnsTypes, input, type)}
                  getOptionLabel={(option) => option}
                  renderOption={(renderOptionProps, option, { selected }) =>
                    renderOptionWithTooltip(renderOptionProps, option, selected, input)
                  }
                  renderTags={(values, getTagProps) =>
                    values.map((chipValue, index) => (
                      <ChipDropdown
                        {...getTagProps({ index })}
                        // Use the underlying column name, not the chip name
                        items={getComplexOptions(fields[input]?.[index])}
                        key={getComplexColumn(chipValue)}
                        selectedItem={getComplexColumn(chipValue)}
                        onChanged={(opt) => handleComplexAction(opt)}
                      />
                    ))
                  }
                />
              </div>
            )}
          </ComplexColumnProvider>
        );
      })}
    </>
  );
};

RequiredFields.propTypes = {
  // Aggregation details for the current chart
  aggregates: PropTypes.array,
  // Applies the new bin details to the chart spec, and callbacks to checkFields
  applyBin: PropTypes.func.isRequired,
  // Applies the new axis min/max to the chart spec
  applyAxisConfig: PropTypes.func.isRequired,
  // Bin details for the current chart
  bins: PropTypes.array,
  // The function to set the data sample limit
  chooseDataSampleLimit: PropTypes.func.isRequired,
  // The column/type information in array of object format, ex. [{ name: 'Age', type: 'Float' }]
  columnsTypes: PropTypes.array.isRequired,
  // The chart builder's required & optional field selections, ex. { group: 'SibSp' }
  fields: PropTypes.object.isRequired,
  // Is the dataset currently loading?
  isLoadingDataset: PropTypes.bool.isRequired,
  // The dataset info & selected chart type
  options: PropTypes.object.isRequired,
  // Specific transforms to apply to the dataset
  transforms: PropTypes.array,
  // Updates the chart spec state
  updateChart: PropTypes.func.isRequired,
};

RequiredFields.defaultProps = {
  aggregates: [],
  bins: [],
  transforms: [],
};

// Set the displayName to be accessed by the container
RequiredFields.displayName = 'RequiredFields';

export default RequiredFields;
