import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { PropTypes } from 'prop-types';
import React from 'react';
import { iconMapping } from '../utils/constants';
import { autofillInputs } from '../utils/generateFields';
import {
  validateAggregate,
  validateBins,
  validateDataSampleLimit,
  validateFields,
  validateTransforms,
} from '../utils/validation';

const ChartTypeSelector = (props) => {
  const {
    aggregates,
    bins,
    chooseDataSampleLimit,
    columnsTypes,
    currChartType,
    fields,
    handleChartType,
    rowCount,
    transforms,
  } = props;

  // Handler for swapping chart types
  const handleTypeSwapWrapper = (newType) => {
    // Update the fields and change the chart type in the chart builder
    let updatedFields = validateFields(fields, newType, columnsTypes);

    // Autofill any necessary fields for the new chart type
    Object.entries(updatedFields).forEach(([input, newSelection]) => {
      updatedFields = autofillInputs({
        fields: updatedFields,
        chartType: newType,
        inputName: input,
        newSelectionValue: newSelection,
      });
    });

    // Preserve or reset the transforms?
    const updatedTransforms = validateTransforms(transforms, updatedFields);

    // Preserve or reset the aggregate?
    const updatedAggregates = validateAggregate(
      aggregates,
      updatedFields,
      columnsTypes,
      rowCount,
      newType,
      currChartType,
    );

    // Preserve or reset the bin?
    const updatedBins = validateBins(bins, updatedFields, columnsTypes, rowCount, newType);

    // Update the dataSampleLimit if necessary
    const updatedDataSampleLimit = validateDataSampleLimit(newType, updatedAggregates);
    chooseDataSampleLimit(updatedDataSampleLimit, false);

    // Update the chart type and the chartSpec state with any changes
    handleChartType(newType, updatedFields, updatedAggregates, updatedBins, updatedTransforms);
  };

  return (
    <>
      {Object.keys(iconMapping).map((type) => (
        <Tooltip title={iconMapping[type].name} key={iconMapping[type].name} placement="top">
          <IconButton
            sx={{ '&:hover': { backgroundColor: 'transparent' } }}
            disableRipple
            onClick={() => handleTypeSwapWrapper(type)}
            data-testid={`Chart-Type-Button-${type}`}
          >
            <div
              className={`Chart-Icon-Button ${
                currChartType === iconMapping[type].type && 'Chart-Icon-Button-Selected'
              }`}
            >
              {iconMapping[type].icon}
            </div>
          </IconButton>
        </Tooltip>
      ))}
    </>
  );
};

export default ChartTypeSelector;

ChartTypeSelector.propTypes = {
  // The chart spec's aggregate info
  aggregates: PropTypes.array,
  // The chart spec's bin info
  bins: PropTypes.array,
  // The function to set the data sample limit
  chooseDataSampleLimit: PropTypes.func.isRequired,
  // Types for each column in the underlying dataset
  columnsTypes: PropTypes.array,
  // Chart type that is currently selected
  currChartType: PropTypes.string,
  // Fields displayed in the chart builder for the selected chart type
  fields: PropTypes.object,
  // Sets the new chart type when the selection changes
  handleChartType: PropTypes.any,
  // The number of rows in the chart builder
  rowCount: PropTypes.number,
  // Specific transforms to apply to the dataset
  transforms: PropTypes.array,
};

ChartTypeSelector.defaultProps = {
  aggregates: [],
  bins: [],
  columnsTypes: [],
  currChartType: '',
  fields: {},
  handleChartType: () => {},
  rowCount: null,
  transforms: [],
};
