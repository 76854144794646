import { ErrorDetails } from '../../types/errorCodes/errorCodes.types';
import { ErrorHandler } from '../../types/errorHandler.types';
import { DatabaseAuthErrorHandler } from './handlers/DatabaseAuthErrorHandler';
import { OAuthErrorHandler } from './handlers/OAuthErrorHandler';

export const messsageErrorHandlers: ErrorHandler[] = [
  new OAuthErrorHandler(),
  new DatabaseAuthErrorHandler(),
];

export const handleMessageError = (error: unknown, errorDetails?: ErrorDetails): void => {
  for (const handler of messsageErrorHandlers) {
    if (handler.canHandleMessageError(error, errorDetails)) {
      handler.handleMessageError(error, errorDetails);
      break;
    }
  }
};
