import CustomStore from 'devextreme/data/custom_store';
import 'devextreme/dist/css/dx.light.css';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import Tooltip from 'devextreme/ui/tooltip';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { useSessionPipelinerDatasetId } from '../../../ChartData/dataUtils';
import { PIVOT_OPERATION_MAP } from '../../../PivotTableBuilder/constant';
import { CustomPivotGrid } from '../../../CustomPivotGrid/CustomPivotGrid';
import './PivotTable.scss';
import { usePivotData } from './usePivotData';
import { MAX_PIVOT_HEIGHT, PIVOT_ROW_CELL_PADDING } from './constants';
import LoadingComponent from '../../../LoadingComponent/LoadingComponent';

/**
 * Reformat the cell values to be displayed in the pivot table after cells are rendered
 * 1. Adds tooltips for columns and rows
 * 2. Truncate the long text to 50 characters
 * 3. Replaces the total text with just 'total'
 * @param {*} e devExtreme cell object
 * @returns
 */
const onCellPreparedHandler = (cell) => {
  // if the pivot table is too large to display, don't do anything
  if ((cell.area === 'row' || cell.area === 'column') && cell.cell.type === 'D') {
    if (cell.cell.text) {
      let { text } = cell.cell;
      if (text.length > 20) {
        text = `${text.substring(0, 20)}...`;
        cell.cellElement.lastChild.innerText = text;
        const container = document.createElement('div');
        cell.cellElement.appendChild(container);
        // eslint-disable-next-line
        new Tooltip(container, {
          target: cell.cellElement,
          visible: false,
          showEvent: 'mouseenter',
          hideEvent: 'mouseleave click',
          contentTemplate: (content) => {
            const label = document.createElement('div');
            label.className = 'tooltip-container';
            label.innerHTML = `<b>${cell.cell.text}</b>`;
            content.appendChild(label);
          },
        });
      }
    }
  }
  if ((cell.area === 'row' || cell.area === 'column') && cell.cell.type === 'T') {
    // Replace the total text with just 't'
    cell.cellElement.lastChild.innerText = 'Total';
  }
};

const PivotTable = ({ tableData, insightsBoardId, height, width, outerHeight, publicationId }) => {
  const [loaded, setLoaded] = useState(false);
  const { name: datasetName, version: datasetVersion, values, id } = tableData;

  const pipelinerDatasetId = useSessionPipelinerDatasetId({
    datasetName,
    datasetVersion,
    pipelinerDatasetId: tableData?.pipeline_id,
  });

  const { fetchSampleData, applyPivotSpec, getMaxDataWidth, isLoading, pivotData, isFailed } =
    usePivotData({
      pipelinerDatasetId,
      insightsBoardId,
      publicationId,
    });

  const fields = useMemo(() => {
    const format =
      PIVOT_OPERATION_MAP[values.pivot_function.toLowerCase()]?.format(values.pivot_column_type) ||
      {};
    return [
      ...values.pivot_columns.map((col) => ({
        dataField: col,
        area: 'column',
        wordWrapEnabled: false,
        customizeText: (cellInfo) => `${cellInfo.value}`,
        width: getMaxDataWidth + PIVOT_ROW_CELL_PADDING, // data length * 10 px + 50 for padding
      })),
      ...values.pivot_rows.map((col) => ({
        dataField: col,
        area: 'row',
        wordWrapEnabled: false,
        customizeText: (cellInfo) => `${cellInfo.value}`,
      })),
      {
        dataField: values.pivot_column,
        area: 'data',
        summaryType: values.pivot_function.toLowerCase(),
        format,
      },
    ];
  }, [values, getMaxDataWidth]);

  const customDataSource = useMemo(
    () =>
      new CustomStore({
        id,
        loadMode: 'processed',
        load: (loadOptions) => {
          if (!pipelinerDatasetId) return Promise.resolve([]);

          if (loadOptions.skip !== undefined) {
            return fetchSampleData();
          }

          if (loadOptions.group || loadOptions.totalSummary) {
            if (!isLoading && !isFailed && !pivotData.rows) {
              return Promise.reject(new Error('Data is not yet fetched. Please wait.'));
            }
            const pivotSpec = {
              ...loadOptions,
              dcSummaryTpe: values.pivot_function,
            };

            return applyPivotSpec(pivotSpec);
          }

          return Promise.resolve([]);
        },
      }),
    [
      id,
      pipelinerDatasetId,
      fetchSampleData,
      isLoading,
      isFailed,
      pivotData.rows,
      values.pivot_function,
      applyPivotSpec,
    ],
  );

  const dataSource = useMemo(
    () =>
      new PivotGridDataSource({
        remoteOperations: true,
        fields,
        store: customDataSource,
        onChanged: () => setLoaded(true),
      }),
    [customDataSource, fields],
  );

  const PivotComponent = useCallback(
    () => (
      <CustomPivotGrid
        dataSource={dataSource}
        height={values.pivot_rows.length > 13 ? MAX_PIVOT_HEIGHT : height}
        width={width}
        onCellPrepared={onCellPreparedHandler}
        scrollingMode="virtual"
        visible={loaded}
      />
    ),
    [dataSource, height, loaded, values.pivot_rows.length, width],
  );

  return (
    <div
      className="Pivot-OuterContainer"
      style={{
        height: !publicationId ? outerHeight : null,
      }}
    >
      <div className="Pivot-Container">
        <PivotComponent />
        {!loaded && <LoadingComponent primaryText="Pivoting into place ..." />}
      </div>
    </div>
  );
};

PivotTable.propTypes = {
  tableData: PropTypes.object.isRequired,
  insightsBoardId: PropTypes.string,
  height: PropTypes.number.isRequired,
  outerHeight: PropTypes.number,
  width: PropTypes.any.isRequired,
  publicationId: PropTypes.number,
};

PivotTable.defaultProps = {
  insightsBoardId: null,
  publicationId: null,
};

export default PivotTable;
