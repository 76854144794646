import cloneDeep from 'lodash/cloneDeep';
import { UTTERANCE_STATE } from '.';
import { CHANGE_TYPES, MAX_CHANGE_DEPTH } from '../../constants/editor';
import {
  addUtteranceHelper,
  bulkAddUtteranceHelper,
  bulkDeleteUtterancesHelper,
  bulkEditUtterancesHelper,
  deleteUtteranceHelper,
  editUtteranceHelper,
  mergeUtterancesHelper,
  reorderUtteranceHelper,
  splitUtteranceHelper,
} from './changes';

/**
 * Helper function to update the changeIndex and changeLog.
 * @param {Object} state - editor reducer state
 * @param {Object} change - object containing all info needed to undo/redo a change
 * @returns object with updated changeIndex and changeLog
 */
const logChangeHelper = (state, change) => {
  const { changeIndex, changeLog } = state;
  // Slice off any changes that were undone
  const updatedChangeLog = changeLog.slice(0, changeIndex);
  // Remove the oldest change once the max depth is reached
  if (updatedChangeLog.length === MAX_CHANGE_DEPTH) updatedChangeLog.shift();
  switch (change.type) {
    case CHANGE_TYPES.ADD:
    case CHANGE_TYPES.EDIT:
    case CHANGE_TYPES.DELETE:
    case CHANGE_TYPES.SPLIT:
    case CHANGE_TYPES.MERGE:
    case CHANGE_TYPES.REORDER:
    case CHANGE_TYPES.BULK_ADD:
    case CHANGE_TYPES.BULK_EDIT:
    case CHANGE_TYPES.BULK_DELETE:
      updatedChangeLog.push(change);
      break;
    default:
      break;
  }
  return { changeIndex: updatedChangeLog.length, changeLog: updatedChangeLog };
};

/**
 * Logs an addition being made to the workflow.
 * @param {Object} state - editor reducer state
 * @param {Number} uttIndex - index of the utterance being added
 * @param {Number} uttKey - key of the utterance being added
 * @returns object with updated changeIndex and changeLog
 */
export const logAddition = (state, uttIndex, uttKey) => {
  return logChangeHelper(state, {
    type: CHANGE_TYPES.ADD,
    uttIndex,
    uttKey,
    undoIndex: uttIndex - 1,
    redoIndex: uttIndex,
  });
};

/**
 * Logs an edit being made to an utterance in the workflow.
 * @param {Object} state - editor reducer state
 * @param {Number} uttKey - key of the utterance being edited
 * @param {String} oldValue - old value of the utterance
 * @param {String} newValue - new value of the utterance
 * @returns object with updated changeIndex and changeLog
 */
export const logEdit = (state, uttKey, oldValue, newValue) => {
  const { utteranceList } = state;
  const uttIndex = utteranceList.indexOf(uttKey);
  return logChangeHelper(state, {
    type: CHANGE_TYPES.EDIT,
    uttKey,
    oldValue,
    newValue,
    undoIndex: uttIndex,
    redoIndex: uttIndex,
  });
};

/**
 * Logs a deletion being made to the workflow.
 * @param {Object} state - editor reducer state
 * @param {Number} uttIndex - index of the utterance being deleted
 * @param {Number} uttKey - key of the utterance being deleted
 * @param {Object} uttObject - object of the deleted utterance
 * @returns object with updated changeIndex and changeLog
 */
export const logDelete = (state, uttIndex, uttKey, uttObject) => {
  return logChangeHelper(state, {
    type: CHANGE_TYPES.DELETE,
    uttIndex,
    uttKey,
    uttObject,
    undoIndex: uttIndex,
    redoIndex: uttIndex > 0 ? uttIndex - 1 : uttIndex,
  });
};

/**
 * Logs a split being made to the workflow.
 * @param {Object} state editor reducer state
 * @param {Object} edit contains info needed for editing an utterance
 * @param {Object} addition contains info needed for adding an utterance to the workflow
 * @returns
 */
export const logSplit = (state, edit, addition) => {
  const { uttIndex } = addition;
  return logChangeHelper(state, {
    type: CHANGE_TYPES.SPLIT,
    edit,
    addition,
    undoIndex: uttIndex - 1,
    redoIndex: uttIndex,
  });
};

/**
 * Logs a merge being made to the workflow.
 * @param {Object} state editor reducer state
 * @param {Object} edit contains info needed for making an edit
 * @param {Object} deletion contains info needed for making a deletion
 * @returns object with updated changeIndex and changeLog
 */
export const logMerge = (state, edit, deletion) => {
  const { uttIndex } = deletion;
  return logChangeHelper(state, {
    type: CHANGE_TYPES.MERGE,
    edit,
    deletion,
    undoIndex: uttIndex,
    redoIndex: uttIndex,
  });
};

/**
 * Logs a reorder of utterances in the workflow.
 * @param {Object} state - editor reducer state
 * @param {Array} oldUtteranceList - old order of utterances
 * @param {Arrau} newUtteranceList - new order of utterances
 * @returns object with updated changeIndex and changeLog
 */
export const logReorder = (state, oldUtteranceList, newUtteranceList, startIndex, endIndex) => {
  return logChangeHelper(state, {
    type: CHANGE_TYPES.REORDER,
    oldUtteranceList,
    newUtteranceList,
    undoIndex: startIndex,
    redoIndex: endIndex,
  });
};

/**
 * Logs a bulk addition of utterances to the workflow.
 * @param {Object} state - editor reducer state
 * @param {Array} insertions - array of objects, each containing info needed for insertion
 * @param {Object} editLog - change object to edit an utterance
 * @returns object with updated changeIndex and changeLog
 */
export const logBulkAddUtterance = (state, insertions, editLog) => {
  return logChangeHelper(state, {
    type: CHANGE_TYPES.BULK_ADD,
    insertions,
    editLog,
    // scroll to line before first added utterance
    undoIndex: editLog
      ? editLog.uttIndex
      : insertions[0].uttIndex > 0
      ? insertions[0].uttIndex - 1
      : 0,
    // scroll to last added utterance
    redoIndex: editLog ? editLog.uttIndex : insertions[insertions.length - 1].uttIndex,
  });
};

/**
 * TODO Logs a bulk deletion of utterances from the workflow.
 * @param {Object} state - editor reducer state
 * @param {Array} deletions - array of objects, each containing info needed for deletions
 * @returns object with updated changeIndex and changeLog
 */
export const logBulkEditUtterance = (state, edits) => {
  const uttIndex = state.utteranceList.indexOf(edits[0].uttKey);
  return logChangeHelper(state, {
    type: CHANGE_TYPES.BULK_EDIT,
    edits,
    // scroll to first edited utterance
    undoIndex: uttIndex,
    redoIndex: uttIndex,
  });
};

/**
 * Logs a bulk deletion of utterances from the workflow.
 * @param {Object} state - editor reducer state
 * @param {Array} deletions - array of objects, each containing info needed for deletions
 * @returns object with updated changeIndex and changeLog
 */
export const logBulkDeleteUtterance = (state, deletions) => {
  return logChangeHelper(state, {
    type: CHANGE_TYPES.BULK_DELETE,
    deletions,
    // scroll to last deleted utterance
    undoIndex: deletions[deletions.length - 1].uttIndex,
    // scroll to line before first deleted utterance
    redoIndex: deletions[0].uttIndex > 0 ? deletions[0].uttIndex - 1 : 0,
  });
};

/**
 * Undoes the last logged change to the workflow.
 * @param {Object} state - editor reducer state
 * @returns object to update the editor reducer state
 */
export const undoLastChange = (state) => {
  let { changeIndex } = state;
  if (changeIndex < 1) return {};
  changeIndex -= 1; // Update the changeIndex
  const change = cloneDeep(state.changeLog[changeIndex]);
  let undoneChanges = {};
  switch (change.type) {
    case CHANGE_TYPES.ADD:
      undoneChanges = deleteUtteranceHelper(state, change.uttIndex, change.uttKey);
      break;
    case CHANGE_TYPES.EDIT:
      undoneChanges = editUtteranceHelper(state, change.uttKey, change.oldValue);
      break;
    case CHANGE_TYPES.DELETE:
      undoneChanges = addUtteranceHelper(state, change.uttIndex, change.uttKey, change.uttObject);
      break;
    case CHANGE_TYPES.SPLIT: {
      const edit = {
        uttKey: change.edit.uttKey,
        newValue: change.edit.oldValue,
      };
      undoneChanges = mergeUtterancesHelper(state, edit, change.addition);
      break;
    }
    case CHANGE_TYPES.MERGE: {
      const edit = {
        uttKey: change.edit.uttKey,
        newValue: change.edit.oldValue,
      };
      undoneChanges = splitUtteranceHelper(state, edit, change.deletion);
      break;
    }
    case CHANGE_TYPES.REORDER:
      undoneChanges = reorderUtteranceHelper(state, change.oldUtteranceList);
      break;
    case CHANGE_TYPES.BULK_ADD: {
      let updatedState = { ...state };
      if (change.editLog) {
        updatedState = {
          ...updatedState,
          ...editUtteranceHelper(updatedState, change.editLog.uttKey, change.editLog.oldValue),
        };
      }
      undoneChanges = bulkDeleteUtterancesHelper(updatedState, change.insertions);
      break;
    }
    case CHANGE_TYPES.BULK_EDIT: {
      // Flip the new and old values
      const edits = change.edits.map((edit) => ({
        ...edit,
        oldValue: edit.newValue,
        newValue: edit.oldValue,
      }));
      undoneChanges = bulkEditUtterancesHelper(state, edits);
      break;
    }
    case CHANGE_TYPES.BULK_DELETE: {
      undoneChanges = bulkAddUtteranceHelper(state, change.deletions);
      break;
    }
    default:
      break;
  }
  return {
    ...state,
    ...undoneChanges,
    changeIndex,
  };
};

/**
 * Undoes the last logged change to the workflow.
 * @param {Object} state - editor reducer state
 * @returns object to update the editor reducer state
 */
export const redoNextChange = (state) => {
  let { changeIndex } = state;
  if (changeIndex === state.changeLog.length) return {};
  const change = cloneDeep(state.changeLog[changeIndex]);
  changeIndex += 1; // Update the changeIndex
  let redoneChanges = {};
  switch (change.type) {
    case CHANGE_TYPES.ADD: {
      // Create utterance object for new utterance
      const newUttObject = UTTERANCE_STATE({ added: true });
      redoneChanges = addUtteranceHelper(state, change.uttIndex, change.uttKey, newUttObject);
      break;
    }
    case CHANGE_TYPES.EDIT:
      redoneChanges = editUtteranceHelper(state, change.uttKey, change.newValue);
      break;
    case CHANGE_TYPES.DELETE:
      redoneChanges = deleteUtteranceHelper(state, change.uttIndex, change.uttKey);
      break;
    case CHANGE_TYPES.SPLIT: {
      redoneChanges = splitUtteranceHelper(state, change.edit, change.addition);
      break;
    }
    case CHANGE_TYPES.MERGE: {
      redoneChanges = mergeUtterancesHelper(state, change.edit, change.deletion);
      break;
    }
    case CHANGE_TYPES.REORDER:
      redoneChanges = reorderUtteranceHelper(state, change.newUtteranceList);
      break;
    case CHANGE_TYPES.BULK_ADD: {
      let updatedState = { ...state };
      if (change.editLog) {
        const { editLog } = change;
        updatedState = {
          ...updatedState,
          ...editUtteranceHelper(updatedState, editLog.uttKey, editLog.newValue),
        };
      }
      redoneChanges = bulkAddUtteranceHelper(updatedState, change.insertions);
      break;
    }
    case CHANGE_TYPES.BULK_EDIT: {
      redoneChanges = bulkEditUtterancesHelper(state, change.edits);
      break;
    }
    case CHANGE_TYPES.BULK_DELETE: {
      redoneChanges = bulkDeleteUtterancesHelper(state, change.deletions);
      break;
    }
    default:
      break;
  }
  return {
    ...state,
    ...redoneChanges,
    changeIndex,
  };
};
