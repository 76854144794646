export const SEND_INTERRUPT_REQUEST = 'interrupt/SEND_INTERRUPT_REQUEST';
export const SEND_INTERRUPT_SUCCESS = 'interrupt/SEND_INTERRUPT_SUCCESS';
export const SEND_INTERRUPT_FAILURE = 'interrupt/SEND_INTERRUPT_FAILURE';
export const SEND_UTTERANCE_TIMEOUT_EXTENSION = 'interrupt/SEND_UTTERANCE_TIMEOUT_EXTENSION';

export const sendInterruptRequest = () => ({
  type: SEND_INTERRUPT_REQUEST,
});

export const sendInterruptSuccess = () => ({
  type: SEND_INTERRUPT_SUCCESS,
});

export const sendInterruptFailure = () => ({
  type: SEND_INTERRUPT_FAILURE,
});

export const sendUtteranceTimeoutExtension = (timeoutExtension) => ({
  type: SEND_UTTERANCE_TIMEOUT_EXTENSION,
  timeoutExtension,
});
