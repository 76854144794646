import parse, { domToReact } from 'html-react-parser';
import React from 'react';
import { connect } from 'react-redux';
import { paths } from '../../../../constants/paths';
import { NavigationTabs } from '../../../../constants/session';
import {
  selectCurrentSessionNavigationTab,
  selectSession,
} from '../../../../store/selectors/session.selector';
import { setCurrentChart } from '../../../../store/slices/chartspace.slice';
import { setCurrentDatasetRequest } from '../../../../store/slices/dataspace.slice';
import { setCurrentNavigationTab } from '../../../../store/slices/session.slice';
import TaggedSpec from '../../../TaggedSpec';
import DataChatLink from '../../../common/DataChatLink';
import DataChatModifyLink from '../../../common/DataChatModifyLink';
import DataChatRedirectLink from '../../../common/DataChatRedirectLink';

type Props = {
  data: mixed,
  expanded: boolean,
  sessionId: string,
  navigationTab: Object,
  setCurrentNavigationTab: () => void,
  setCurrentDatasetRequest: () => void,
  setCurrentChart: () => void,
};
/**
 * Creates a simple HTML text message.
 */
class Text extends React.PureComponent<Props> {
  render() {
    const { data, expanded, navigationTab, sessionId } = this.props;

    const parsedData = parse(data, {
      replace: (node) => {
        const { name, attribs, children } = node;

        switch (name) {
          // Replace `dc-skill-link` tag to `DataChatLink`
          // See https://github.com/DataChatAI/datachat/issues/2569
          case 'dc-skill-link':
            if (window.location.pathname === paths.ask) {
              return node;
            }
            return (
              <DataChatLink
                command={attribs ? attribs.command : null}
                disable={attribs ? attribs.disable : false}
              >
                {domToReact(children)}
              </DataChatLink>
            );
          case 'dc-modify-skill-link':
            return (
              <DataChatModifyLink command={attribs ? attribs.command : null}>
                {domToReact(children)}
              </DataChatModifyLink>
            );
          case 'dc-redirect-link':
            return (
              <DataChatRedirectLink
                url={attribs ? attribs.url : null}
                urlType={attribs ? attribs.url_type : 'absolute'}
                port={attribs && attribs.port !== 'None' ? attribs.port : null}
              >
                {domToReact(children)}
              </DataChatRedirectLink>
            );
          case 'dc-preview-link': {
            // get the attribute info
            const attribInfo = attribs ? attribs?.command?.split(':') : null;
            if (attribInfo && attribInfo.length === 2 && attribInfo?.[0] === 'dc_dataset_id') {
              return (
                <DataChatLink
                  handleClick={() => {
                    if (navigationTab !== NavigationTabs.DATA_SPACE) {
                      this.props.setCurrentNavigationTab({
                        tab: NavigationTabs.DATA_SPACE,
                        sessionId,
                      });
                    }
                    this.props.setCurrentDatasetRequest({ dcDatasetId: attribInfo[1] });
                  }}
                  tooltip="Click to view the dataset"
                >
                  {domToReact(children)}
                </DataChatLink>
              );
            }
            return node;
          }
          case 'dc-tabchart-preview-link': {
            // get the attribute info
            const attribInfo = attribs ? attribs?.command?.split(':') : null;
            if (attribInfo && attribInfo.length === 2 && attribInfo?.[0] === 'dc_chart_id') {
              return (
                <DataChatLink
                  handleClick={() => {
                    this.props.setCurrentChart({ dcChartId: attribInfo[1] });
                  }}
                  tooltip="Click to view the chart"
                >
                  {domToReact(children)}
                </DataChatLink>
              );
            }
            return node;
          }
          // Only the following tags are allowed
          case 'strong': {
            try {
              // try parsing the content of the strong tag
              const joined = children
                .map((c) => c.data)
                .join(' ')
                .trim();
              // check that the tagged content starts with an opening bracket
              if (joined.startsWith('{') || joined.startsWith('[')) {
                const parsed = JSON.parse(joined);
                return <TaggedSpec spec={parsed} />;
              }
              return node;
            } catch (e) {
              // content of strong tag is not json parseable, return original node
              return node;
            }
          }
          case undefined: // name === undefined implies text node
          case 'a':
          case 'br':
          case 'b':
          case 'i':
          case 'img':
          case 's':
          case 'u':
          case 'ul':
          case 'ol':
          case 'li':
          case 'table':
          case 'th':
          case 'tr':
          case 'td':
          case 'thead':
          case 'tbody':
          case 'em':
          case 'span':
          case 'sup':
            return node;

          // Do not display other tags to avoid XSS injection
          default:
            return <></>;
        }
      },
    });

    return <div className={`link-wrapper ${expanded ? ' expanded' : ''}`}>{parsedData}</div>;
  }
}

const mapStateToProps = (state) => ({
  navigationTab: selectCurrentSessionNavigationTab(state),
  sessionId: selectSession(state),
});

export default connect(mapStateToProps, {
  setCurrentDatasetRequest,
  setCurrentNavigationTab,
  setCurrentChart,
})(Text);
