import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React, { useEffect, useRef, useState } from 'react';

interface TextWithTooltipProps {
  text: string | React.ReactHTMLElement<any>;
  className?: string;
  resizeDelay?: number; // delay in ms to wait before checking if text is overflowing
  dataTestid?: string;
  fontSize?: string;
}

const TextWithTooltip: React.FC<TextWithTooltipProps> = ({
  text,
  className,
  resizeDelay,
  dataTestid,
  fontSize,
}) => {
  const textRef = useRef<HTMLDivElement | null>(null);
  const [isTooltipVisible, setTooltipVisible] = useState<boolean>(false);

  const compareSize = () => {
    if (textRef.current?.clientWidth && textRef.current?.scrollWidth) {
      const compare = textRef.current.scrollWidth > textRef.current.clientWidth;
      setTooltipVisible(compare);
    }
  };

  // compare once and add resize listener
  useEffect(() => {
    // This is needed if any css transitions are applied to the surrounding text, we can delay the check until it is finished
    setTimeout(() => compareSize(), resizeDelay);
    window.addEventListener('resize', compareSize);
    return () => {
      window.removeEventListener('resize', compareSize);
    };
  });

  useEffect(() => {
    compareSize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textRef?.current]);

  return (
    <Tooltip className={className} title={isTooltipVisible ? text : ''}>
      <Typography
        style={{
          display: 'block',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize,
        }}
        ref={textRef}
        data-testid={dataTestid || text}
        variant="body1"
      >
        {text}
      </Typography>
    </Tooltip>
  );
};

TextWithTooltip.defaultProps = {
  className: '',
  resizeDelay: 0,
  dataTestid: '',
};

export default TextWithTooltip;
