import { isMobile } from 'react-device-detect';

import { CHAT_PANEL } from '../../constants';
import { checkQsMuted } from '../../utils/messages';
import {
  APPEND_INFORMATIONAL_MESSAGE,
  APPEND_UTTERANCE_HISTORY,
  CLEAR_UTTERANCE_HISTORY,
  CLOSE_CHAT_PANEL,
  ENABLE_CHAT,
  OPEN_CHAT_PANEL,
  SET_CURRENT_UTTERANCE,
  SET_HAS_UNREAD_MESSAGES,
  SET_SHOULD_FLUSH_TEXTBOX,
  SWITCH_CHAT_WINDOW,
  UPDATE_CHAT_PANEL_STYLE,
  UPDATE_CHAT_PANEL_TEXT,
} from '../actions/chat.actions';

export const initialState = {
  app: undefined,
  messagingKey: undefined,
  chatEnabled: true,
  isFullscreen: isMobile,
  apps: [],
  dashboards: [],
  utteranceHistory: [],
  informationalMessages: [],
  inputValue: '',
  currentUtterance: '',
  bottom: CHAT_PANEL.BOTTOM, // Bottom position of the Chat Panel in px
  height: undefined, // Height of the Chat Panel in px
  chatPanelIsOpen: false, // Indicates if the chat panel should be shown.
  hasUnreadMessages: false, // Indicates if there are messages that have not been read.
};

/**
 * @param {Object} state
 * @param {Object} action
 * @returns {Object} new state
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case ENABLE_CHAT:
      return {
        ...state,
        chatEnabled: action.enable,
      };
    case SWITCH_CHAT_WINDOW:
      return {
        ...state,
        isFullscreen: action.toggle ? !state.isFullscreen : action.bottom,
      };
    case APPEND_UTTERANCE_HISTORY:
      if (checkQsMuted(action.utterance)) return state;
      return {
        ...state,
        utteranceHistory: [...state.utteranceHistory, action.utterance],
      };
    case APPEND_INFORMATIONAL_MESSAGE:
      return {
        ...state,
        informationalMessages: [
          ...state.informationalMessages,
          { sessionId: action.sessionId, message: action.message },
        ],
      };
    case CLEAR_UTTERANCE_HISTORY:
      return {
        ...state,
        utteranceHistory: [],
        informationalMessages: [],
      };
    case UPDATE_CHAT_PANEL_STYLE:
      return {
        ...state,
        height: action.height,
        bottom: action.bottom !== undefined ? action.bottom : CHAT_PANEL.BOTTOM,
      };
    case UPDATE_CHAT_PANEL_TEXT:
      return {
        ...state,
        inputValue: action.text,
      };
    case OPEN_CHAT_PANEL:
      return {
        ...state,
        chatPanelIsOpen: true,
      };
    case CLOSE_CHAT_PANEL:
      return {
        ...state,
        chatPanelIsOpen: false,
      };
    case SET_HAS_UNREAD_MESSAGES:
      return {
        ...state,
        hasUnreadMessages: action.hasUnreadMessages,
      };
    case SET_CURRENT_UTTERANCE:
      return {
        ...state,
        currentUtterance: action.text,
      };
    case SET_SHOULD_FLUSH_TEXTBOX:
      return {
        ...state,
        shouldFlushTextbox: action.shouldFlushTextbox,
      };
    default:
      return state;
  }
};
