const SNOWFLAKE_DATABASE = `
  <div>
    <p>
    The name of the Snowflake database you want to connect to. Note that the user must have permission to read from this database.
    </p>
  </div>
`;

const SNOWFLAKE_HOSTNAME =
  '<div><p>The location of the Snowflake database. Do not include snowflake.com in the host name.</p></div>';

const SNOWFLAKE_USERNAME = `
  <div>
    <p>
    The Snowflake user to connect to the database.
    </p>
  </div>
`;

const SNOWFLAKE_PASSWORD = `
  <div>
    <p>
    The password to connect to the database.
    </p>
  </div>
`;

const SNOWFLAKE_WAREHOUSE = `
  <div>
    <p>
    (Optional) The warehouse to use for the Snowflake connection. If not specified, the default warehouse for the user is used.
    </p>
  </div>
`;

const SNOWFLAKE_WORKSPACE = `
  <div>
    <p>
    (Optional) The name of the database that tables and views are written to. If not specified, the database is called “DATACHATWORKSPACE”. This field is ignored if the connection is read-only.
    </p>
  </div>
`;

const SNOWFLAKE_READONLY = `
  <div>
    <p>
    When checked, DataChat does not write to your SnowFlake database. Instead, data is pulled into our internal database, and results are written there. Otherwise, results are written to the DataChat workspace database.
    </p>
  </div>
`;

export const SNOWFLAKE_MAP = {
  databaseName: SNOWFLAKE_DATABASE,
  hostname: SNOWFLAKE_HOSTNAME,
  username: SNOWFLAKE_USERNAME,
  password: SNOWFLAKE_PASSWORD,
  computeWarehouse: SNOWFLAKE_WAREHOUSE,
  datachatWorkspace: SNOWFLAKE_WORKSPACE,
  readOnly: SNOWFLAKE_READONLY,
};

const COCKROACH_DATABASE = `
  <div>
    <p>
    The name of the Cockroach database you want to connect to. Note that the user must have permission to read from this database.
    </p>
  </div>
`;

const COCKROACH_HOSTNAME = `
  <div><p>The location of the Cockroach database. Do not include cockroachlabs.com in the host name.</p></div>
`;

const COCKROACH_PORTNUMBER = `
  <div>
    <p>
    The port number of the Cockroach database.
    </p>
  </div>
`;

const COCKROACH_USERNAME = `
  <div>
    <p>
    The Cockroach user to connect to the database.
    </p>
  </div>
`;

const COCKROACH_PASSWORD = `
  <div>
    <p>
    The password to connect to the database.
    </p>
  </div>
`;

const COCKROACH_READONLY = `
  <div>
    <p>
    When checked, DataChat does not write to your Cockroach database. Instead, data is pulled into our internal database, and results are written there. Otherwise, results are written to the DataChat workspace database.
    </p>
  </div>
`;

export const COCKROACH_MAP = {
  databaseName: COCKROACH_DATABASE,
  hostname: COCKROACH_HOSTNAME,
  portNumber: COCKROACH_PORTNUMBER,
  username: COCKROACH_USERNAME,
  password: COCKROACH_PASSWORD,
  readOnly: COCKROACH_READONLY,
};

const BIGQUERY_PROJECT = `
  <div>
    <p>
    The name of the project you would like to connect to.
    </p>
  </div>
`;

const BIGQUERY_DATASETNAME = `
  <div><p>The name of the BigQuery dataset you want to connect to. Note that the user must have permission to read from this dataset. </p></div>
`;

const BIGQUERY_STAGINGPROJECT = `
  <div>
    <p>
    (Optional) The name of your BigQuery project you want to use to stage your data.
    </p>
  </div>
`;

const BIGQUERY_STAGINGDATASETNAME = `
  <div>
    <p>
    (Optional) The name of the staging dataset from which you would like to stage data. Note that the user must have permission to access this dataset.
    </p>
  </div>
`;

const BIGQUERY_STAGINGCREDENTIALFILE = `
  <div>
    <p>
    (Optional) The authentication file to use to connect to your BigQuery staging dataset.
    </p>
  </div>
`;

const BIGQUERY_READONLY = `
  <div>
    <p>
    When checked, DataChat does not write to your BigQuery database. Instead, data is pulled into our internal database, and results are written there. Otherwise, all results are written directly to your BigQuery database.
    </p>
  </div>
`;

const BIGQUERY_USE_OAUTH = `
  <div>
    <p>
    When checked, DataChat uses OAuth to connect to your BigQuery database. Otherwise, DataChat uses a service account to connect to your BigQuery database.
    </p>
  </div>
`;

export const BIGQUERY_MAP = {
  project: BIGQUERY_PROJECT,
  datasetName: BIGQUERY_DATASETNAME,
  stagingProject: BIGQUERY_STAGINGPROJECT,
  stagingDatasetName: BIGQUERY_STAGINGDATASETNAME,
  stagingCredentialFile: BIGQUERY_STAGINGCREDENTIALFILE,
  useOAuth: BIGQUERY_USE_OAUTH,
  readOnly: BIGQUERY_READONLY,
};

const DATABRICKS_DATABASE = `
  <div>
    <p>
    The name of the Databricks database you want to connect to.
    </p>
  </div>
`;

const DATABRICKS_CATALOG = `
<div>
  <p>
  The name of the Databricks catalog you want to connect to.
  </p>
</div>
`;

const DATABRICKS_HOSTNAME = `
  <div><p>The hostname of the Databricks database.</p></div>
`;

const DATABRICKS_PORTNUMBER = `
  <div>
    <p>
    The port number of the Databricks server.
    </p>
  </div>
`;

const DATABRICKS_HTTPPATH = `
  <div>
    <p>
    The Databricks compute resources URL.
    </p>
  </div>
`;

const DATABRICKS_ACCESSTOKEN = `
  <div>
    <p>
    The personal access token used to connect to your Databricks database.
    </p>
  </div>
`;

const DATABRICKS_READONLY = `
  <div>
    <p>
    When checked, DataChat does not write to your Databricks database. Instead, data is pulled into our internal database, and results are written there. Otherwise, results are written to your Databricks database.
    </p>
  </div>
`;

export const DATABRICKS_MAP = {
  databaseName: DATABRICKS_DATABASE,
  catalog: DATABRICKS_CATALOG,
  hostname: DATABRICKS_HOSTNAME,
  portNumber: DATABRICKS_PORTNUMBER,
  httpPath: DATABRICKS_HTTPPATH,
  accessToken: DATABRICKS_ACCESSTOKEN,
  readOnly: DATABRICKS_READONLY,
};

const MICROSOFTSQLSERVER_DATABASE = `
  <div>
    <p>
    The name of the SQL Server database you want to connect to. Note that the user must have permission to read from this database.
    </p>
  </div>
`;

const MICROSOFTSQLSERVER_HOSTNAME = `
  <div><p>The location of the SQL Server database.</p></div>
`;

const MICROSOFTSQLSERVER_PORTNUMBER = `
  <div>
    <p>
    The port number of the SQL Server database.
    </p>
  </div>
`;

const MICROSOFTSQLSERVER_USERNAME = `
  <div>
    <p>
    The SQL Server user to connect to the database.
    </p>
  </div>
`;

const MICROSOFTSQLSERVER_PASSWORD = `
  <div>
    <p>
    The password to connect to the database.
    </p>
  </div>
`;

const MICROSOFTSQLSERVER_READONLY = `
  <div>
    <p>
    When checked, DataChat does not write to your SQL Server database. Instead, data is pulled into our internal database, and results are written there.
    </p>
  </div>
`;

export const MICROSOFTSQLSERVER_MAP = {
  databaseName: MICROSOFTSQLSERVER_DATABASE,
  hostname: MICROSOFTSQLSERVER_HOSTNAME,
  portNumber: MICROSOFTSQLSERVER_PORTNUMBER,
  username: MICROSOFTSQLSERVER_USERNAME,
  password: MICROSOFTSQLSERVER_PASSWORD,
  readOnly: MICROSOFTSQLSERVER_READONLY,
};

const MYSQL_DATABASE = `
  <div>
    <p>
    The name of the MySQL database you want to connect to. Note that the user must have permission to read from this database.
    </p>
  </div>
`;

const MYSQL_HOSTNAME = `
  <div><p>The location of the MySQL database.</p></div>
`;

const MYSQL_PORTNUMBER = `
  <div>
    <p>
    The port number of the MySQL database.
    </p>
  </div>
`;

const MYSQL_USERNAME = `
  <div>
    <p>
    The MySQL user to connect to the database.
    </p>
  </div>
`;

const MYSQL_PASSWORD = `
  <div>
    <p>
    The password to connect to the database.
    </p>
  </div>
`;

const MYSQL_READONLY = `
  <div>
    <p>
    When checked, DataChat does not write to your MySQL database. Instead, data is pulled into our internal database, and results are written there. Otherwise, results are written to the DataChat workspace database.
    </p>
  </div>
`;

export const MYSQL_MAP = {
  databaseName: MYSQL_DATABASE,
  hostname: MYSQL_HOSTNAME,
  portNumber: MYSQL_PORTNUMBER,
  username: MYSQL_USERNAME,
  password: MYSQL_PASSWORD,
  readOnly: MYSQL_READONLY,
};

const POSTGRESQL_DATABASE = `
  <div>
    <p>
    The name of the PostgreSQL database you want to connect to. Note that the user must have permission to read from this database.
    </p>
  </div>
`;

const POSTGRESQL_HOSTNAME = `
  <div><p>The location of the PostgreSQL database.</p></div>
`;

const POSTGRESQL_PORTNUMBER = `
  <div>
    <p>
    The port number of the PostgreSQL database.
    </p>
  </div>
`;

const POSTGRESQL_USERNAME = `
  <div>
    <p>
    The PostgreSQL user to connect to the database.
    </p>
  </div>
`;

const POSTGRESQL_PASSWORD = `
  <div>
    <p>
    The password to connect to the database.
    </p>
  </div>
`;

const POSTGRESQL_READONLY = `
  <div>
    <p>
    When checked, DataChat does not write to your PostgreSQL database. Instead, data is pulled into our internal database, and results are written there. Otherwise, results are written to the DataChat workspace database.
    </p>
  </div>
`;

export const POSTGRESQL_MAP = {
  databaseName: POSTGRESQL_DATABASE,
  hostname: POSTGRESQL_HOSTNAME,
  portNumber: POSTGRESQL_PORTNUMBER,
  username: POSTGRESQL_USERNAME,
  password: POSTGRESQL_PASSWORD,
  readOnly: POSTGRESQL_READONLY,
};

const PRESTO_URL = `
  <div><p>The URL of the Presto database to connect to.</p></div>
`;

const PRESTO_PORT = `
  <div>
    <p>
    The port number of the Presto database.
    </p>
  </div>
`;

const PRESTO_CATALOG = `
  <div>
    <p>
    The Presto catalog used to connect to your data location.
    </p>
  </div>
`;

const PRESTO_SCHEMA = `
  <div>
    <p>
    The Presto Schema used to organize tables.
    </p>
  </div>
`;

const PRESTO_READONLY = `
  <div>
    <p>
    When checked, DataChat does not write to your Presto database. Instead, data is pulled into our internal database, and results are written there. Otherwise, results are written to the DataChat workspace database.
    </p>
  </div>
`;

export const PRESTO_MAP = {
  url: PRESTO_URL,
  port: PRESTO_PORT,
  catalog: PRESTO_CATALOG,
  schema: PRESTO_SCHEMA,
  readOnly: PRESTO_READONLY,
};

const REDSHIFT_DATABASE = `
  <div>
    <p>
    The name of the Redshift database you want to connect to. Note that the user must have permission to read from this database.
    </p>
  </div>
`;

const REDSHIFT_HOSTNAME = `
  <div><p>The location of the Redshift database.</p></div>
`;

const REDSHIFT_PORTNUMBER = `
  <div>
    <p>
    The port number of the Redshift database.
    </p>
  </div>
`;

const REDSHIFT_USERNAME = `
  <div>
    <p>
    The Redshift user to connect to the database.
    </p>
  </div>
`;

const REDSHIFT_PASSWORD = `
  <div>
    <p>
    The password to connect to the database.
    </p>
  </div>
`;

const REDSHIFT_READONLY = `
  <div>
    <p>
    When checked, DataChat does not write to your Redshift database. Instead, data is pulled into our internal database, and results are written there. Otherwise, results are written to the DataChat workspace database.
    </p>
  </div>
`;

export const REDSHIFT_MAP = {
  databaseName: REDSHIFT_DATABASE,
  hostname: REDSHIFT_HOSTNAME,
  portNumber: REDSHIFT_PORTNUMBER,
  username: REDSHIFT_USERNAME,
  password: REDSHIFT_PASSWORD,
  readOnly: REDSHIFT_READONLY,
};

export const INFO_MAP = {
  SnowFlake: SNOWFLAKE_MAP,
  Cockroach: COCKROACH_MAP,
  BigQuery: BIGQUERY_MAP,
  Databricks: DATABRICKS_MAP,
  MicrosoftSQLServer: MICROSOFTSQLSERVER_MAP,
  MySQL: MYSQL_MAP,
  PostgreSQL: POSTGRESQL_MAP,
  Presto: PRESTO_MAP,
  Redshift: REDSHIFT_MAP,
};
