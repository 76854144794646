import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChartSpec } from 'translate_dc_to_echart';
import { ChartRenderParams, Renderers } from '../../types/render.types';

export interface Product {
  progress: number;
  render: string | ChartSpec | null;
  error?: Error | unknown;
}

type Dimensions = `${number}:${number}`;

export interface PngProducts {
  [dimension: Dimensions]: Product;
}

export interface ChartRenderState {
  [dc_chart_id: string]: {
    [key in Renderers]?: key extends Renderers.PNG ? PngProducts : Product;
  };
}

const initialState: ChartRenderState = {};

function isPngRenderer(renderType: Renderers): renderType is Renderers.PNG {
  return renderType === Renderers.PNG;
}

function updateRenderState(
  state: ChartRenderState,
  dcChartId: string,
  renderType: Renderers,
  update: Partial<Product>,
  width?: number,
  height?: number,
) {
  if (!state[dcChartId]) {
    state[dcChartId] = {};
  }

  const dimension = `${width}:${height}` as Dimensions;

  state[dcChartId] = {
    ...state[dcChartId],
    [renderType]:
      isPngRenderer(renderType) && width && height
        ? {
            ...state[dcChartId][renderType],
            [dimension]: {
              ...state[dcChartId][renderType]?.[dimension],
              ...update,
            },
          }
        : {
            ...state[dcChartId][renderType],
            ...update,
          },
  };
}

const chartRendersSlice = createSlice({
  name: 'chartRenders',
  initialState,
  reducers: {
    getRender: (state, action: PayloadAction<ChartRenderParams>) => {
      const { dcChartId, renderer, width, height } = action.payload;
      updateRenderState(state, dcChartId, renderer, { progress: 0, render: null }, width, height);
    },
    getRenderSuccess: (
      state,
      action: PayloadAction<{
        dcChartId: string;
        renderer: Renderers;
        render: string | ChartSpec;
        width?: number;
        height?: number;
      }>,
    ) => {
      const { dcChartId, renderer, render, width, height } = action.payload;
      updateRenderState(state, dcChartId, renderer, { progress: 100, render }, width, height);
    },
    getRenderFailure: (
      state,
      action: PayloadAction<{
        dcChartId: string;
        renderer: Renderers;
        error: Error | unknown;
        width?: number;
        height?: number;
      }>,
    ) => {
      const { dcChartId, renderer, error, width, height } = action.payload;
      updateRenderState(
        state,
        dcChartId,
        renderer,
        { progress: 0, render: null, error },
        width,
        height,
      );
    },
    updateRenderProgress: (
      state,
      action: PayloadAction<{
        dcChartId: string;
        renderer: Renderers;
        progress: number;
        width?: number;
        height?: number;
      }>,
    ) => {
      const { dcChartId, renderer, progress, width, height } = action.payload;
      updateRenderState(state, dcChartId, renderer, { progress }, width, height);
    },
  },
});

export const { getRender, getRenderSuccess, getRenderFailure, updateRenderProgress } =
  chartRendersSlice.actions;

export default chartRendersSlice.reducer;
