import {
  CREATE_NOTIFICATION_FAILURE,
  GET_ALL_NOTIFICATIONS_FAILURE,
  GET_ALL_NOTIFICATIONS_SUCCESS,
  GET_SEEN_NOTIFICATIONS_FAILURE,
  GET_SEEN_NOTIFICATIONS_SUCCESS,
  GET_UNSEEN_NOTIFICATIONS_FAILURE,
  GET_UNSEEN_NOTIFICATIONS_SUCCESS,
} from '../actions/notification.action';

export const initialState = {
  seenNotifications: undefined,
  unseenNotifications: undefined,
  error: undefined,
};

/**
 * @param {Object} state The current state
 * @param {Object} action The action that was dispatched
 * @returns {Object} The new state
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SEEN_NOTIFICATIONS_SUCCESS:
      return { ...state, seenNotifications: action.seenNotifications };

    case GET_SEEN_NOTIFICATIONS_FAILURE:
      return { ...state, error: action.error };

    case GET_ALL_NOTIFICATIONS_SUCCESS:
      return { ...state, allNotifications: action.allNotifications };

    case GET_ALL_NOTIFICATIONS_FAILURE:
      return { ...state, error: action.error };

    case GET_UNSEEN_NOTIFICATIONS_SUCCESS:
      return { ...state, unseenNotifications: action.unseenNotifications };

    case GET_UNSEEN_NOTIFICATIONS_FAILURE:
      return { ...state, error: action.error };

    case CREATE_NOTIFICATION_FAILURE:
      return { ...state, error: action.error };

    default:
      return state;
  }
};
