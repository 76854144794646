import { RootState } from 'configureStore';
import { createSelector } from 'reselect';
import { NavigationItemStatus } from '../../constants/session';
import { selectDatasets } from './dataspace.selector';
import { buildTabContentsMessage, groupCharts, sortGroupedCharts } from '../../utils/chartspace';
import { VISUAL_TYPES } from '../../constants';
import { ChartFilterKey } from '../slices/chartspace.slice';

export const selectCharts = (state: RootState) => state.chartspace.charts;
export const selectCurrentDcChartId = (state: RootState) => state.chartspace.currentDcChartId;

export const selectChartById = (state: RootState, id: string | undefined) => {
  if (!id) {
    return null;
  }
  return state.chartspace.charts[id] || null;
};

const selectChartListWithParent = createSelector(
  [selectDatasets, selectCharts],
  (datasets, charts) => {
    return Object.values(charts).map((chart) => {
      // add parent dataset name to chart
      const chartWithParent = {
        ...chart,
        parent: datasets[chart.dc_dataset_id]?.name || 'No Dataset',
      };
      const { chart_spec: chartSpec } = chart;
      if (chartSpec.type === VISUAL_TYPES.TABVISUAL && chartSpec.data.tabs) {
        // if the chart is a tab visual, build the tab contents message
        const tabVisualChartSpec = {
          ...chartSpec,
          data: {
            ...chartSpec.data,
            tabContents: buildTabContentsMessage(chartSpec.data.tabs, charts),
          },
        };
        return {
          ...chartWithParent,
          chart_spec: tabVisualChartSpec,
        };
      }
      return chartWithParent;
    });
  },
);

export const selectVisibleCharts = createSelector([selectChartListWithParent], (charts) =>
  charts.filter((chart) => chart.status !== NavigationItemStatus.HIDDEN),
);

export const selectActiveCharts = createSelector([selectChartListWithParent], (charts) => {
  return charts.filter((chart) => chart.status === NavigationItemStatus.ACTIVE);
});

export const selectHasVisibleCharts = createSelector(
  [selectVisibleCharts],
  (visibleCharts) => visibleCharts.length > 0,
);

export const selectIsChartSpaceLoading = (state: RootState) =>
  state.chartspace.loading || !state.chartspace.requested;

export const selectChartListSortParameter = (state: RootState) => state.chartspace.sort;
export const selectChartListGroupByParameter = (state: RootState) => state.chartspace.groupBy;
export const selectChartListFilters = (state: RootState) => state.chartspace.filters;

// filter out charts that do not match the current filters
const selectFilteredCharts = createSelector(
  [selectVisibleCharts, selectChartListFilters],
  (charts, filters) => {
    return charts.filter((chart) => {
      const categories = Object.keys(filters) as ChartFilterKey[];
      return categories.every((category) => {
        // for each filter category
        const filterValues = filters[category];
        if (filterValues.length === 0) {
          // don't filter if there are no filter values for the category
          return true;
        }
        // otherwise check if the chart's category value is in the filter values
        return filterValues.includes(chart[category]);
      });
    });
  },
);

export const selectChartListBySortParameter = createSelector(
  [selectFilteredCharts, selectChartListSortParameter, selectChartListGroupByParameter],
  (chartList, sortParam, groupBy) => {
    const groupedCharts = groupCharts(chartList, groupBy);
    return sortGroupedCharts(groupedCharts, sortParam);
  },
);
