import {
  CLOSE_EXPLORER,
  OPEN_EXPLORER,
  REPOSITION_EXPLORER,
  SET_EXPLORER_POS_X,
  SET_EXPLORER_POS_Y,
} from '../actions/explorer.actions';

export const EXPLORER_WIDTH = 300;
export const EXPLORER_HEIGHT = 380;

const initialState = {
  showExplorer: false, // Indicates if the Explorer should be open.
  posX: window.innerWidth - EXPLORER_WIDTH, // X position of the Explorer
  posY: 0, // Y position of the Explorer
};

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_EXPLORER:
      return {
        ...state,
        showExplorer: true,
      };
    case CLOSE_EXPLORER:
      return {
        ...state,
        showExplorer: false,
      };
    case SET_EXPLORER_POS_X:
      return {
        ...state,
        posX: action.posX,
      };
    case SET_EXPLORER_POS_Y:
      return {
        ...state,
        posY: action.posY,
      };
    case REPOSITION_EXPLORER: {
      return {
        ...state,
        posX: action.xFactor * state.posX,
        posY: action.yFactor * state.posY,
      };
    }
    default:
      return state;
  }
};
