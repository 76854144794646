import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ReactJson from 'react-json-view';
import './TaggedSpec.scss';

const TaggedSpec = (props) => {
  const { spec, title } = props;
  const [anchorEl, setAnchorEl] = useState(undefined);
  const [open, setOpen] = useState(false);

  // open json spec popper
  const onOpen = (event) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  // close json spec popper
  const onClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title="Click to Expand">
        <strong className="TaggedSpec" onClick={onOpen}>
          {'{...}'}
        </strong>
      </Tooltip>
      {spec && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={onClose}
          anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          <div className="SpecViewer">
            <div className="SpecViewer-Header">
              {title}
              <IconButton className="close-button" onClick={onClose} size="medium">
                <CloseIcon />
              </IconButton>
            </div>
            <div className="SpecViewer-Content">
              <ReactJson
                collapsed={2}
                displayDataTypes={false}
                displayObjectSize={false}
                enableClipboard={false}
                iconStyle="circle"
                indentWidth={4}
                name={false}
                quotesOnKeys={false}
                src={spec}
              />
            </div>
          </div>
        </Popover>
      )}
    </>
  );
};

TaggedSpec.propTypes = {
  spec: PropTypes.object.isRequired, // from parent component
  title: PropTypes.string, // parent component
};

TaggedSpec.defaultProps = {
  title: 'Skill Specification',
};

export default TaggedSpec;
