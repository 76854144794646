import { EventChannel } from 'redux-saga';
import {
  all,
  call,
  getContext,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
  takeLeading,
} from 'typed-redux-saga';
import { ReduxSagaContext, RootState } from '../../configureStore';
import { API_SERVICES } from '../../constants/api';
import {
  CANCEL_BUTTON_KEY,
  DISCARD_CHANGES_KEY,
  SAVE_CHANGES_KEY,
  unsavedCatalogChangesAlert,
} from '../../constants/dialog.constants';
import { getErrorToastConfig, getSuccessToastConfig } from '../../constants/toast';
import { DatasetAnnotations } from '../../types/catalog.types';
import { HomeObjectKeys } from '../../utils/homeScreen/types';
import { closeDialog } from '../actions/dialog.actions';
import { addToast } from '../actions/toast.actions';
import {
  selectDatasetEdits,
  selectEditedDatasetAnnotation,
  selectEditedDatasetsIds,
} from '../selectors/catalog.selector';
import { selectObjectByObjectFk } from '../selectors/home_screen.selector';
import { selectSession } from '../selectors/session.selector';
import {
  close,
  closeCatalogRequest,
  discard,
  discardAll,
  gotDatasetAnnotation,
  gotDatasetAnnotationError,
  open,
  save,
  saveAll,
  selectDataset,
} from '../slices/catalog.slice';
import { selectAccessToken } from './selectors';
import { createAlertChannel } from './utils/alert-channels';
import { callAPIWithRetry } from './utils/retry';

export function* getDatasetAnnotation(
  datasetId: string,
  sessionId?: string,
  workspaceUuid?: string,
) {
  // get user's access token
  const accessToken = yield* select(selectAccessToken);

  // get catalog service
  const catalogService = (yield* getContext(
    API_SERVICES.CATALOG,
  )) as ReduxSagaContext[API_SERVICES.CATALOG];

  try {
    // request the annotation
    const response = yield* callAPIWithRetry({
      apiFn: catalogService.getAnnotation,
      args: [
        datasetId,
        accessToken,
        {
          sessionId,
          workspaceUuid,
        },
      ],
    });

    // check if the response is not successful
    if (response.status !== 200) {
      throw new Error(`[${response.status}] Failed to get dataset's annotation`);
    }

    // get the annotation from the response
    const annotation = response.data;

    // store the annotation in the store
    yield put(gotDatasetAnnotation(datasetId, annotation));
  } catch (error: any) {
    // store the error in the store
    yield put(
      gotDatasetAnnotationError({
        datasetId,
        error: error instanceof Error ? error : new Error(error),
      }),
    );
  }
}

export function* saveDatasetAnnotation(
  datasetId: string,
  sessionId?: string,
  workspaceUuid?: string,
) {
  // get edited dataset annotation
  const edited = yield* select(selectEditedDatasetAnnotation, datasetId);

  // get user's access token
  const accessToken = yield* select(selectAccessToken);

  // get catalog service
  const catalogService = (yield* getContext(
    API_SERVICES.CATALOG,
  )) as ReduxSagaContext[API_SERVICES.CATALOG];

  // request the updated annotation
  const response = yield* call(catalogService.putAnnotation, datasetId, edited, accessToken, {
    sessionId,
    workspaceUuid,
  });

  // get the updated annotation from the response
  const saved = response.data;

  // discard edits since they have been saved
  yield put(discard(datasetId));

  // store the updated annotation in the store
  yield put(gotDatasetAnnotation(datasetId, saved));

  // return the updated annotation
  return saved;
}

export function* calculateDatasetClaim(datasetId: string) {
  const sessionId = yield* select(selectSession);
  const datasetObject = yield* select((state: RootState) =>
    selectObjectByObjectFk(state, datasetId),
  );
  return { sessionId, workspaceUuid: datasetObject?.[HomeObjectKeys.UUID] };
}

export function* saveAllDatasetAnnotations() {
  // get edited dataset annotation ids
  const ids = yield* select(selectEditedDatasetsIds);

  // calculate the dataset claim
  const claimParams = yield* all(ids.map((id) => call(calculateDatasetClaim, id)));

  // save each dataset annotation in parallel
  const saved = yield* all(
    ids.map((id, index) =>
      call(
        saveDatasetAnnotation,
        id,
        claimParams[index].sessionId,
        claimParams[index].workspaceUuid,
      ),
    ),
  );

  // return the annotations
  return saved;
}

export function* closeCatalogRequestWorker() {
  // Close the dialog if there are no unsaved changes
  const edits: DatasetAnnotations = yield select(selectDatasetEdits);
  if (Object.keys(edits).length === 0) {
    yield put(close());
    return;
  }

  // create the alert channel with the text and skill buttons
  const alertChannel: EventChannel<any> = yield call(
    createAlertChannel,
    unsavedCatalogChangesAlert(),
  );

  const keyChoice = yield* take(alertChannel);
  // Either submit or discard the changes
  if (keyChoice === SAVE_CHANGES_KEY) {
    yield put(saveAll());
    yield put(close());
  } else if (keyChoice === DISCARD_CHANGES_KEY) {
    yield put(discardAll());
    yield put(close());
  } else if (keyChoice === CANCEL_BUTTON_KEY) {
    /** do nothing */
  }
  yield put(closeDialog());
}

export function* handleSave({ payload: datasetId }: ReturnType<typeof save>) {
  try {
    // calculate the dataset claim
    const { sessionId, workspaceUuid } = yield* call(calculateDatasetClaim, datasetId);

    // save the dataset annotation
    yield call(saveDatasetAnnotation, datasetId, sessionId, workspaceUuid);
  } catch (error) {
    // get the dataset's object
    const datasetObject = yield* select((state: RootState) =>
      selectObjectByObjectFk(state, datasetId),
    );

    // get the dataset's name
    const name = datasetObject?.[HomeObjectKeys.NAME];

    // create the error toast's message
    const message = name ? `Failed to save ${name}'s definition` : 'Failed to save definition';

    // show error toast
    yield put(addToast(getErrorToastConfig(message)));
  }
}

export function* handleSaveAll() {
  try {
    // save all dataset annotations
    yield call(saveAllDatasetAnnotations);

    // show success toast
    yield put(addToast(getSuccessToastConfig('Definitions saved successfully')));
  } catch (error) {
    // show error toast
    yield put(addToast(getErrorToastConfig('Failed to save definitions')));
  }
}

export function* handleSelectDataset({ payload: datasetId }: ReturnType<typeof selectDataset>) {
  const { sessionId, workspaceUuid } = yield* call(calculateDatasetClaim, datasetId);
  yield* call(getDatasetAnnotation, datasetId, sessionId, workspaceUuid);
}

export function* handleOpen({ payload: datasetId }: ReturnType<typeof open>) {
  if (!datasetId) return;
  yield* call(handleSelectDataset, selectDataset(datasetId));
}

export default function* catalogSaga() {
  // close the catalog dialog
  yield* takeLatest(closeCatalogRequest, closeCatalogRequestWorker);

  // fetch an individual dataset annotation
  yield* takeEvery(selectDataset, handleSelectDataset);

  // open the catalog dialog
  yield* takeEvery(open, handleOpen);

  // save an individual dataset annotation
  yield* takeLeading(save, handleSave);

  // save all dataset annotations
  yield* takeLeading(saveAll, handleSaveAll);
}
