import sanitizeHtml from 'sanitize-html';

export const sanitizeFileName = (file) => {
  const sanitizedName = sanitizeHtml(file.name.replace(/\s+/g, '_'), {
    allowedTags: [],
    allowedAttributes: [],
  });
  return sanitizedName || 'invalid-file-name';
};
export default sanitizeFileName;
