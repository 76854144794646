import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../configureStore';
import { selectUserConfig } from '../../../store/sagas/selectors';
import {
  selectDatasetAnnotationErrorMessage,
  selectDatasetAnnotationIsLoading,
  selectMergedColumnAnnotation,
  selectSelectedDatasetId,
} from '../../../store/selectors/catalog.selector';
import { DEFAULT_COLUMN_ANNOTATION, editColumn } from '../../../store/slices/catalog.slice';
import { FeatureTypes } from '../../../types/feature.types';
import { hasFeatureEnabled } from '../../../utils/userconfig_selector';
import './ColumnAnnotation.scss';

const MAX_COLUMN_ANNOTATION_CHAR_COUNT = 1000;

const ColumnAnnotation: React.FC<{
  colName: string;
}> = ({ colName }) => {
  // Fetch the annotation from the store
  const dispatch = useDispatch();
  const datasetId = useSelector(selectSelectedDatasetId);
  const userConfig = useSelector(selectUserConfig);
  const isLoading = useSelector((state: RootState) =>
    selectDatasetAnnotationIsLoading(state, datasetId),
  );
  const error = useSelector((state: RootState) =>
    selectDatasetAnnotationErrorMessage(state, datasetId),
  );
  const hasError = Boolean(error);
  const readableColumnsEnabled = hasFeatureEnabled(
    userConfig,
    FeatureTypes.READABLE_COLUMN_ANNOTATIONS,
  );
  const columnAnnotation =
    useSelector((state: RootState) =>
      selectMergedColumnAnnotation(state, datasetId ?? '', colName),
    ) ?? DEFAULT_COLUMN_ANNOTATION;

  // Local state for the annotation
  const [warning, setWarning] = React.useState<boolean>(false);
  const [showAnnotation, setShowAnnotation] = React.useState<boolean>(
    Boolean(columnAnnotation?.annotation),
  );

  const submitEdit = (annotation?: string, safeToShare?: boolean) =>
    datasetId &&
    dispatch(
      editColumn(
        datasetId,
        colName,
        annotation ?? columnAnnotation.annotation,
        safeToShare ?? columnAnnotation.safeToShare,
      ),
    );

  // Update the local state when the selected dataset changes
  React.useEffect(() => {
    setShowAnnotation(Boolean(columnAnnotation?.annotation));
  }, [datasetId, columnAnnotation]);

  if (isLoading) {
    return (
      <Box className="Column-Annotation-Container" data-testid="loading-skeleton">
        {hasFeatureEnabled(userConfig, FeatureTypes.READABLE_COLUMN_ANNOTATIONS) && (
          <Box className="Column-Annotation-Label-Container">
            <Skeleton variant="text" width="50%" height="1.6rem" />
            <Skeleton variant="circular" width="1rem" height="1rem" />
          </Box>
        )}
        <Box className="Column-Annotation-Label-Container">
          <Skeleton variant="text" width="50%" height="1.6rem" />
          <Skeleton variant="circular" width="1rem" height="1rem" />
        </Box>
        {showAnnotation && <Skeleton variant="rectangular" width="100%" height={87} />}
      </Box>
    );
  }

  if (hasError) {
    return (
      <Box className="Column-Annotation-Container" data-testid="error-message">
        <Typography variant="body1" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box className="Column-Annotation-Container">
      {readableColumnsEnabled ? (
        <Box className="Column-Annotation-Label-Container" data-testid="readable-checkbox">
          <Tooltip
            sx={{ color: 'var(--mui-palette-grey-500)' }}
            title="Coming soon! By selecting this option the Data Assistant is allowed to read
        data from this column. This feature requires sending column data to the LLM."
          >
            <Typography>Readable</Typography>
          </Tooltip>
          <Tooltip
            title={readableColumnsEnabled ? 'This feature is coming soon!' : ''}
            placement="right"
          >
            <Box>
              <Checkbox
                size="small"
                checked={columnAnnotation.safeToShare ?? DEFAULT_COLUMN_ANNOTATION.safeToShare}
                onChange={(e) => submitEdit(columnAnnotation.annotation, e.target.checked)}
                color="primary"
                disabled={isLoading || hasError || !readableColumnsEnabled}
              />
            </Box>
          </Tooltip>
        </Box>
      ) : null}
      <Box className="Column-Annotation-Label-Container">
        <Tooltip title="Provide a description so the Data Assistant can better answer questions related to this column">
          <Typography>Description</Typography>
        </Tooltip>
        <Tooltip title={showAnnotation ? 'Hide Description' : 'Add Description'} placement="right">
          <IconButton size="small" onClick={() => setShowAnnotation(!showAnnotation)}>
            {showAnnotation ? (
              <RemoveCircleOutlineOutlinedIcon />
            ) : (
              <AddCircleOutlineOutlinedIcon />
            )}
          </IconButton>
        </Tooltip>
      </Box>
      <TextField
        value={columnAnnotation.annotation ?? DEFAULT_COLUMN_ANNOTATION.annotation}
        className={`column-annotation${showAnnotation ? '' : ' hidden'}${
          warning ? ' warning' : ''
        }`}
        multiline
        size="small"
        minRows={2}
        maxRows={2}
        fullWidth
        onChange={(e) => {
          if (hasError) return;
          let newValue = e.target.value;
          if (newValue.length > MAX_COLUMN_ANNOTATION_CHAR_COUNT) {
            newValue = newValue.substring(0, MAX_COLUMN_ANNOTATION_CHAR_COUNT);
            setWarning(true);
            setTimeout(() => setWarning(false), 800);
          }
          submitEdit(newValue, columnAnnotation.safeToShare);
        }}
        // Prevents the event from bubbling up to the table cell, which stops the focus
        // from switching to another cell when selecting text in the annotation field
        onKeyDown={(e) => {
          if (e.key === ' ') {
            e.stopPropagation();
          }
        }}
        helperText={`${
          columnAnnotation.annotation?.length ?? 0
        }/${MAX_COLUMN_ANNOTATION_CHAR_COUNT} characters`}
      />
    </Box>
  );
};

export default ColumnAnnotation;
