import { CoralogixRum } from '@coralogix/browser';

export const loadCoralogixRum = (cxEnv, cxVersion) => {
  CoralogixRum.init({
    public_key: 'cxtp_AdVFGas3g6PHd5MGvC2bruRfLVdF1C',
    application: 'datachat-cx-rum',
    version: cxVersion,
    coralogixDomain: 'US2',
    labels: {
      env: cxEnv, // strictly speaking, we don't need this, but this gives us the same filtering experience as Datadog
    },
    sessionRecordingConfig: {
      enable: true,
      autoStartSessionRecording: true, // Automatically records your session when SDK is up.
      recordConsoleEvents: true, // Will record all console events from dev tools. Levels: log, debug, warn, error, info, table etc..
      sessionRecordingSampleRate: 100, // Percentage of overall sessions recording being tracked, defaults to 100% and applied after the overall sessionSampleRate.
    },
  });
};
