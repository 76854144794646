/** The render types that we support in the Charting Service */
export enum Renderers {
  SVG = 'svg',
  PNG = 'png',
  SPEC = 'spec', // Not supported via v0 API
}

/** The content types to set in the header for each renderer */
export enum ContentTypes {
  SVG = 'image/svg+xml',
  PNG = 'image/png',
}

/** The parameters for a charting service render */
export interface ChartRenderParams {
  accessToken?: string;
  apiKey?: string;
  apiSecret?: string;
  dcChartId: string;
  renderer: Renderers;
  width?: number;
  height?: number;
  sessionId?: string;
  insightsBoardId?: number;
  publicationId?: number;
  workspaceUuid?: string;
}
