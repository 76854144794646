import { getDatasetReferenceString } from '../../components/ChartData/dataUtils';
import { COMPUTE_SAMPLE_TRIAL_COUNT, SAMPLE_TRIAL_COUNT } from '../../constants';
import {
  RESET_CHARTS,
  SAMPLE_SESSIONLESS_DATASET_FAILURE,
  SAMPLE_SESSIONLESS_DATASET_REQUEST,
  SAMPLE_SESSIONLESS_DATASET_SUCCESS,
  SAVE_CHART_TAB_ORDER,
  UPDATE_SELECTED_CHART,
} from '../actions/chart.actions';
import { COMPUTE_DATASET_REQUEST } from '../actions/dataset.actions';

const initialState = {
  // persisted order of chart viewer tabs by session id
  persistedTabOrder: {},
  // All dataset reference information
  chartDatasetReferences: {
    // [publicationId]: { snapshotUUID, tableName }
  },
  sessionlessDatasetStorage: {}, // Sessionless chart & table data
  selectedChartName: '', // chart currently displayed in ChartDisplayPanel
  // NOTE: selectedChartVersion is commented out, because we don't currently
  // give the user access to all versions of a chart. Selecting a chart will always
  // display the latest version of the chart.
  // selectedChartVersion: 0, // chart version currently displayed in ChartDisplayPanel
  retrieveChartDatasetReferenceErrors: {}, // errors encountered when retrieving the chart dataset reference
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_CHARTS:
      return initialState;
    case SAVE_CHART_TAB_ORDER: {
      return {
        ...state,
        persistedTabOrder: {
          ...state.persistedTabOrder,
          [action.sessionId]: action.tabOrderList,
        },
      };
    }
    case UPDATE_SELECTED_CHART:
      return {
        ...state,
        selectedChartName: action.chartName,
      };
    case SAMPLE_SESSIONLESS_DATASET_REQUEST:
    case COMPUTE_DATASET_REQUEST: {
      const {
        computeSpec,
        insightsBoardId,
        isTable,
        numRows,
        pipelinerDatasetId,
        publicationId,
        usedCompute,
      } = action;

      // If we're requesting a compute from an IB, we don't want to use this reducer
      if (action.type === COMPUTE_DATASET_REQUEST && !insightsBoardId && !publicationId) {
        return state;
      }

      const referenceString = getDatasetReferenceString({
        computeSpec,
        pipelinerDatasetId,
        usedCompute,
      });

      const currData = state.sessionlessDatasetStorage[referenceString] ?? null;
      const samplingTrialCount = isTable
        ? currData?.tableSamplingTrialCount ?? 0
        : currData?.samplingTrialCount ?? 0;

      // Protect against setting { isSampleLoading: true } when a request isn't forked via takeEachDataset()
      const currRowNum = currData?.rows?.length ?? 0;
      const totalRowNum = currData?.totalRowCount ?? null;
      const hasEnoughRows = currRowNum >= numRows || currRowNum === totalRowNum;

      return {
        ...state,
        sessionlessDatasetStorage: {
          ...state.sessionlessDatasetStorage,
          [referenceString]: {
            ...state.sessionlessDatasetStorage[referenceString],
            ...(isTable
              ? {
                  tableError: null,
                  isTableSampleFailed: false,
                  isTableSampleLoading: !hasEnoughRows,
                  tableSamplingTrialCount: samplingTrialCount + 1,
                  // Set the chart loading statuses to false if we have nothing in the reducer yet
                  ...(!currData && { isSampleFailed: false, isSampleLoading: false }),
                }
              : {
                  chartError: null,
                  isSampleFailed: false,
                  isSampleLoading: !hasEnoughRows,
                  samplingTrialCount: samplingTrialCount + 1,
                  // Set the table loading statuses to false if we have nothing in the reducer yet
                  ...(!currData && { isTableSampleFailed: false, isTableSampleLoading: false }),
                }),
          },
        },
      };
    }
    case SAMPLE_SESSIONLESS_DATASET_SUCCESS: {
      const { computeSpec, isTable, pipelinerDatasetId, result, tableSampleRowCount, usedCompute } =
        action;
      const referenceString = getDatasetReferenceString({
        computeSpec,
        pipelinerDatasetId,
        usedCompute,
      });

      // Total number of rows that we have on the FE
      const existingNumRows =
        state.sessionlessDatasetStorage[referenceString]?.rows?.length ?? null;
      const incomingNumRows = result?.rows?.length ?? 0;

      return {
        ...state,
        sessionlessDatasetStorage: {
          ...state.sessionlessDatasetStorage,
          [referenceString]: {
            ...state.sessionlessDatasetStorage[referenceString],
            // Only insert data if we received more than we currently have
            ...((!existingNumRows || incomingNumRows > existingNumRows) && { ...result }),
            // Update our tableSampleRowCount if it changed, ex. Scrolling on a table
            ...(isTable
              ? {
                  tableError: null,
                  isTableSampleFailed: false,
                  isTableSampleLoading: false,
                  tableSamplingTrialCount: 0,
                  tableSampleRowCount,
                }
              : {
                  chartError: null,
                  isSampleFailed: false,
                  isSampleLoading: false,
                  samplingTrialCount: 0,
                }),
          },
        },
      };
    }
    case SAMPLE_SESSIONLESS_DATASET_FAILURE: {
      const { computeSpec, error, isTable, pipelinerDatasetId, usedCompute } = action;
      const referenceString = getDatasetReferenceString({
        computeSpec,
        pipelinerDatasetId,
        usedCompute,
      });

      const currData = state.sessionlessDatasetStorage[referenceString];
      const samplingTrialCount = isTable
        ? currData?.tableSamplingTrialCount ?? 0
        : currData?.samplingTrialCount ?? 0;
      const keepLoading = usedCompute
        ? samplingTrialCount < COMPUTE_SAMPLE_TRIAL_COUNT
        : samplingTrialCount < SAMPLE_TRIAL_COUNT; // if it is the first trial keep trying

      return {
        ...state,
        sessionlessDatasetStorage: {
          ...state.sessionlessDatasetStorage,
          [referenceString]: {
            ...state.sessionlessDatasetStorage[referenceString],
            ...(isTable
              ? {
                  tableError: !keepLoading ? error : null,
                  isTableSampleFailed: !keepLoading,
                  isTableSampleLoading: keepLoading,
                  tableSamplingTrialCount: keepLoading ? samplingTrialCount : 0,
                }
              : {
                  chartError: !keepLoading ? error : null,
                  isSampleFailed: !keepLoading,
                  isSampleLoading: keepLoading,
                  samplingTrialCount: keepLoading ? samplingTrialCount : 0,
                }),
          },
        },
      };
    }
    default:
      return state;
  }
};
