import React from 'react';
import { AGGREGATE_DOCS_URL, WRANGLE_DOCS_URL } from '../../../constants/external_urls';
import capitalize from '../../../utils/case';

/**
 * A nonsense status code used for the profiler.
 */
export const CUSTOM_PROFILER_STATUS = 123;

/**
 * The title for an unexpected error.
 */
export const UNEXPECTED_ERROR_TITLE = 'Well, that was unexpected.';

/**
 * The description for an unexpected error.
 * @param onRefreshClick The function to call when the user clicks the refresh link.
 */
export const unexpectedErrorDescription = (onRefreshClick: () => void) => (
  <>
    {"To get things working again, let's try doing a page "}
    <span className="link" onClick={onRefreshClick}>
      refresh
    </span>
    .
  </>
);

/**
 * The title for a generic error.
 * @param objectType
 * @returns
 */
export const genericErrorBoundaryTitle = (objectType: string) =>
  `${capitalize(objectType)} failed to load.`;

/**
 * The description for a generic error.
 * @param onRefreshClick The function to call when the user clicks the refresh link.
 */
export const genericErrorBoundaryDescription = (onRefreshClick: () => void) => (
  <>
    {'Try '}
    <span className="link" onClick={onRefreshClick}>
      refreshing
    </span>
    {' the page.'}
  </>
);

/**
 * The title for a timeout (504).
 */
export const TIMEOUT_ERROR_TITLE = 'The request timed out.';

/**
 * The description for a timeout (504).
 */
export const TIMEOUT_ERROR_DESCRIPTION = (
  <>
    {'Try '}
    <a
      href={AGGREGATE_DOCS_URL}
      data-testid="aggregate-docs-link"
      target="_blank"
      rel="noopener noreferrer"
    >
      aggregating
    </a>
    {' or '}
    <a
      href={WRANGLE_DOCS_URL}
      data-testid="wrangle-docs-link"
      target="_blank"
      rel="noopener noreferrer"
    >
      wrangling
    </a>
    {' your data to make it more manageable for visualization.'}
  </>
);

export const EMBED_CHART_UNAUTHORIZED_ERROR_TITLE = 'This chart is not currently available.';
export const EMBED_CHART_UNAUTHORIZED_ERROR_DESCRIPTION =
  'Please ask the owner of the chart for more details.';

export const EMBED_CHART_UNDEFINED_TITLE = 'This chart could not be displayed.';
export const EMBED_CHART_UNDEFINED_DESCRIPTION = 'Please try refreshing the page.';

export const MODEL_PROFILER_OUTDATED_ERROR_TITLE =
  'The selected model is not suited for use with the Profiler';
export const MODEL_PROFILER_OUTDATED_ERROR_DESCRIPTION =
  'To use this tool, try training and publishing the model again.';

export const MODEL_PROFILER_FAILED_PROFILE_TITLE =
  'An unexpected error occurred while profiling the model.';

export const EDITOR_TITLE = 'Workflow failed to load.';
export const EDITOR_DESCRIPTION =
  'The workflow may have been saved improperly. Please try replaying and saving this workflow through a session.';

export const DCPLOTV2_MAX_ROW_LIMIT_ERROR_TITLE = 'Maximum chart row limit exceeded';
export const DCPLOTV2_MAX_ROW_LIMIT_ERROR_DESCRIPTION =
  'Currently charts only support up to 990,000 rows';

export const SHARED_AVA_CONVERSATION_ERROR_TITLE = 'This conversation is not currently available.';
export const SHARED_AVA_CONVERSATION_ERROR_DESCRIPTION =
  'Please ask the owner of the conversation for more details.';

export const LOAD_CARD_TITLE = 'Your suggestions failed to load.';
export const LOAD_CARD_DESCRIPTION = 'Please try refreshing the page.';

export const INSIGHTS_BOARD_ACCESS_CHANGE_ERROR_TITLE =
  'Sorry, you do not have access to this insights board.';
export const INSIGHTS_BOARD_ACCESS_CHANGE_ERROR_DESCRIPTION =
  'You may return to home page by clicking the logo.';
export const genericAccessErrorDescription = (onHomePageClick: () => void) => (
  <>
    {'Please return to the home page by clicking '}
    <span className="link" onClick={onHomePageClick}>
      here
    </span>
    .
  </>
);
