import axios from 'axios';
import qs from 'qs';
import { endpoints } from '../constants/endpoints';

export const listExamples = (accessToken) =>
  axios.get(endpoints.exampleDatasets, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

export const listWorkspace = (accessToken, objType) =>
  axios.get(endpoints.workspaceV2, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      obj_type: objType,
    },
  });

export const listWorkspaceByUuid = (accessToken, uuid) =>
  axios.get(endpoints.workspaceV2, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      uuid,
    },
  });

export const listWorkspaceAccessors = (accessToken, uuid) =>
  axios.get(`${endpoints.workspaceV2}/${uuid}/access`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

export const listOrganizationAccess = (accessToken, uuid) =>
  axios.get(`${endpoints.workspaceV2}/${uuid}/organization_access`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

export const changeWorkspaceAccess = (accessToken, uuid, payload) =>
  axios.put(`${endpoints.workspaceV2}/${uuid}/access`, payload, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

export const editWorkspaceMetadata = (accessToken, uuid, payload) =>
  axios.put(`${endpoints.workspaceV2}/${uuid}/edit`, payload, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

export const deleteWorkspaceObject = (accessToken, uuid) =>
  axios.delete(`${endpoints.workspaceV2}/${uuid}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

export const createWorkspace = (accessToken, name, parentUuid) =>
  axios.post(
    `${endpoints.workspaceV2}/${name}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        dest: parentUuid,
      },
    },
  );

export const moveWorkspaceObject = (accessToken, uuid, payload) =>
  axios.put(`${endpoints.workspaceV2}/${uuid}`, qs.stringify(payload), {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

export const listChartBackingSnapshots = (accessToken) =>
  axios.get(endpoints.workspaceV2Snapshot, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

export const getWorkspaceChildren = (accessToken, uuid) =>
  axios.get(`${endpoints.workspaceV2}/${uuid}/children`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

export const patchWorkspaceDataset = ({ accessToken, objectId, dcDatasetId, sessionId }) => {
  return axios.patch(
    endpoints.patchWorkspaceDataset(objectId),
    {
      dc_dataset_uuid: dcDatasetId,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      params: {
        session: sessionId,
      },
    },
  );
};

export const getWorkspaceDatasetWorkflow = ({ accessToken, objectId }) => {
  return axios.get(endpoints.getWorkspaceDatasetWorkflow(objectId, objectId), {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

/**
 * headWorkspaceNameType checks if the requesting user already owns a workspace
 * object with the given name and type.
 *
 * This endpoint can be used for checking name collisions before requesting
 * workspace object creation.
 *
 * Sends the following request:
 * ```http
 * HEAD /api/workspacev2?name=<name>&type=<type>
 * Authorization: Bearer <access_token>
 * ```
 *
 * @param {string} accessToken user's access token
 * @param {string} name workspace object's name
 * @param {string} type workspace object's type
 * @returns {Promise<axios.AxiosResponse>} 404 if not found, 200 if found
 */
export const headWorkspaceNameType = (accessToken, name, type) =>
  axios.head(endpoints.workspaceV2, {
    headers: { Authorization: `Bearer ${accessToken}` },
    params: { name, type },
  });
