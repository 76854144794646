import { call, getContext, put, select, take, takeLatest } from 'redux-saga/effects';
import { postUtteranceTimeoutExtension } from '../../api/interrupt.api';
import { API_SERVICES } from '../../constants/api';
import { OK_BUTTON_KEY, createInterruptFailedAlert } from '../../constants/dialog.constants';
import { closeDialog, openAlertDialog } from '../actions/dialog.actions';
import {
  SEND_INTERRUPT_FAILURE,
  SEND_INTERRUPT_REQUEST,
  SEND_UTTERANCE_TIMEOUT_EXTENSION,
  sendInterruptFailure,
  sendInterruptSuccess,
} from '../actions/interrupt.actions';
import { selectSession } from '../selectors/session.selector';
import { selectAccessToken } from './selectors';
import { createAlertChannel } from './utils/alert-channels';

export function* sendInterruptWorker() {
  try {
    const accessToken = yield select(selectAccessToken);
    const session = yield select(selectSession);
    const interruptService = yield getContext(API_SERVICES.INTERRUPT);
    yield call(interruptService.postInterrupt, accessToken, session);
    yield put(sendInterruptSuccess());
  } catch (error) {
    yield put(sendInterruptFailure());
  }
}

export function* interruptFailureWorker() {
  const alertChannel = yield call(createAlertChannel, createInterruptFailedAlert());
  const keyChoice = yield take(alertChannel);
  if (keyChoice === OK_BUTTON_KEY) yield put(closeDialog());
}

export function* sendUtteranceTimeoutExtensionWorker(action) {
  const accessToken = yield select(selectAccessToken);
  const session = yield select(selectSession);
  const { timeoutExtension } = action;
  yield call(postUtteranceTimeoutExtension, accessToken, session, timeoutExtension);
  yield put(
    openAlertDialog({
      title: 'Skill Timeout Extended',
      descriptions: [`Your skill execution has been extended by ${timeoutExtension} minutes`],
    }),
  );
}

export default function* () {
  yield takeLatest(SEND_INTERRUPT_REQUEST, sendInterruptWorker);
  yield takeLatest(SEND_INTERRUPT_FAILURE, interruptFailureWorker);
  yield takeLatest(SEND_UTTERANCE_TIMEOUT_EXTENSION, sendUtteranceTimeoutExtensionWorker);
}
