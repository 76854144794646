import Autocomplete, {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  AutocompleteOwnerState,
  AutocompleteRenderGetTagProps,
  AutocompleteRenderOptionState,
} from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { connect } from 'react-redux';

import './index.scss';

type Props = {
  // Applied to the Autocomplete Component
  autoHighlight: boolean,
  blurOnSelect: string | boolean,
  freeSolo: boolean,
  getOptionLabel: () => mixed,
  getOptionDisabled: () => mixed,
  limitTags?: number,
  id: string,
  inputValue: string,
  multiple?: boolean,
  onBlur: () => mixed,
  onChange:
    | ((
        event: React.SyntheticEvent<Element, Event>,
        value: any,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<any> | undefined,
      ) => void)
    | undefined,
  onInputChange:
    | ((
        event: React.SyntheticEvent<Element, Event>,
        value: string,
        reason: AutocompleteInputChangeReason,
      ) => void)
    | undefined,
  onFocus: () => mixed,
  onClick: (event?: React.SyntheticEvent) => mixed,
  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void,
  openOnFocus?: boolean,
  options: any[],
  selectOnFocus: boolean,
  value: any,
  disableClearable: boolean,
  renderOption:
    | ((
        _: React.HTMLAttributes<HTMLLIElement>,
        option: any,
        state: AutocompleteRenderOptionState,
      ) => React.ReactNode)
    | undefined,
  // Applied to the input component
  placeholder: string,
  label: string,
  inlineLabel: string,
  type?: string,
  // Catch all for all input props. To be used with spread operator.
  catchAll: {},
  error: boolean,
  required: boolean,
  disabled: boolean,
  listboxProps: {},
  PopperComponent: boolean,
  renderTags:
    | ((
        value: any[],
        getTagProps: AutocompleteRenderGetTagProps,
        ownerState: AutocompleteOwnerState<any, boolean, boolean, boolean, 'div'>,
      ) => React.ReactNode)
    | undefined,
  tooltip: string,
  open: boolean,
  autoFocus: boolean,
  filterOptions: () => mixed,
  onClose: () => mixed,
  inputStyle: {},
  tooltipPlacement?: string,
  componentsProps: {},
  suggestionError: any,
  isLoading: boolean,
  prompt: string,
  isPartial: boolean,
  clearOnBlur: boolean,
  defaultValue: String,
  isTimeout: boolean,
  timeOutText: string,
  loadingText: string,
  'data-cy': String,
  'data-testid': String,
  disableCloseOnSelect: boolean,
};
/**
 * Wrapper component around material-ui Autocomplete component.
 * For a complete list of Autocomplete props go to https://material-ui.com/api/autocomplete/
 */
const ref = React.createRef();
const timeOutMessage = 'Request timed out.';

class DataChatAutocompleteInput extends React.Component<Props> {
  render() {
    let isTimeout = this.props.isTimeout ? this.props.isTimeout : false;
    if (this.props.suggestionError !== undefined) {
      isTimeout = this.props.suggestionError[this.props.prompt]?.response?.status > 400;
    }

    const timeOutText = this.props.timeOutText
      ? this.props.timeOutText
      : this.props.isPartial
      ? `${timeOutMessage} Please keep typing`
      : `${timeOutMessage} Please click to try again`;

    const loadingText = this.props.loadingText ? this.props.loadingText : 'Loading...';

    const promptText = isTimeout ? timeOutText : this.props.isLoading ? loadingText : 'No Options';

    // Destruct the onClick prop from the input component so we can overwrite it.
    // TODO: destructure all the props
    const { onClick } = this.props;
    return (
      <FormControl
        className="DataChatAutocompleteInput"
        error={this.props.error}
        margin="dense"
        required={this.props.required}
        variant="outlined"
        fullWidth
      >
        <Tooltip
          title={this.props.tooltip ? this.props.tooltip : ''}
          placement={this.props.tooltipPlacement}
        >
          <Autocomplete
            data-cy={this.props['data-cy']}
            data-testid={this.props['data-testid']}
            ref={ref}
            autoHighlight={this.props.autoHighlight}
            disabled={this.props.disabled}
            filterOptions={this.props.filterOptions}
            open={this.props.open}
            onBlur={this.props.onBlur}
            blurOnSelect={this.props.blurOnSelect}
            clearOnBlur={this.props.clearOnBlur}
            disableCloseOnSelect={
              this.props.disableCloseOnSelect !== undefined
                ? this.props.disableCloseOnSelect
                : this.props.multiple
            } // Use disableCloseOnSelect if the prop is defined, otherwise default to true for multi-selects
            freeSolo={this.props.freeSolo}
            getOptionLabel={this.props.getOptionLabel}
            getOptionDisabled={this.props.getOptionDisabled}
            limitTags={this.props.limitTags}
            defaultValue={this.props.defaultValue}
            inputValue={this.props.inputValue}
            noOptionsText={promptText}
            multiple={this.props.multiple}
            onChange={this.props.onChange}
            onInputChange={this.props.onInputChange}
            options={this.props.options}
            openOnFocus={this.props.openOnFocus}
            selectOnFocus={this.props.selectOnFocus}
            value={this.props.value || null}
            ListboxProps={this.props.listboxProps}
            componentsProps={{
              popper: {
                id: 'Multi',
              },
            }}
            ChipProps={{ size: 'small' }}
            renderTags={this.props.renderTags}
            onClose={this.props.onClose}
            renderOption={this.props.renderOption}
            disableClearable={this.props.disableClearable}
            size="small"
            renderInput={(params) => (
              <TextField
                {...params}
                style={this.props.inputStyle}
                error={this.props.error}
                className="DataChatAutocompleteInput-TextField"
                variant="outlined"
                // Hide the placeholder on multi-selects with values selected.
                placeholder={
                  this.props.multiple &&
                  Array.isArray(this.props.value) &&
                  this.props.value.length !== 0
                    ? ''
                    : this.props.placeholder
                }
                label={this.props.inlineLabel}
                type={this.props.type}
                margin="dense"
                onFocus={this.props.onFocus}
                onKeyDown={this.props.onKeyDown}
                autoFocus={this.props.autoFocus}
                // Mui updates has a default onClick, overwrite it with our own.
                InputProps={{ ...params.InputProps, onClick }}
              />
            )}
            {...this.props.catchAll}
          />
        </Tooltip>
      </FormControl>
    );
  }
}

DataChatAutocompleteInput.defaultProps = {
  type: 'text',
  multiple: false,
  limitTags: 3,
  openOnFocus: true,
  tooltipPlacement: 'top-start',
};

const mapStateToProps = (state) => ({
  suggestionError: state.utteranceComposer.suggestionError,
});

export default connect(mapStateToProps, {})(DataChatAutocompleteInput);
