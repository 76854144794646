import { createSelector } from 'reselect';
import { RootState } from '../../configureStore';
import {
  Connections,
  Database,
  Databases,
  Namespace,
  RequestStatus,
} from '../../types/databaseBrowser.types';
import { HomeObjectKeys, HomeObjectKeysTypes } from '../../utils/homeScreen/types';

/* ================================= GENERAL SELECTORS ================================= */
export const selectDatabaseBrowserIsOpen = (state: RootState) => state.dbBrowser.open;
export const selectDatabaseBrowserHideNavigation = (state: RootState): boolean =>
  state.dbBrowser.hideNavigation;
export const selectDatabaseBrowserOpenConnection = (state: RootState): HomeObjectKeysTypes | null =>
  state.dbBrowser.openConnection;
export const selectDatabaseBrowserDatabases = (state: RootState): Databases => {
  return state.dbBrowser.databases;
};
export const selectDatabaseBrowserConnections = (state: RootState): Connections =>
  state.dbBrowser.connections;

/* ================================= CONNECTION SELECTORS ================================= */
export const selectDatabaseBrowserNumConnections = (state: RootState): number => {
  const connections = selectDatabaseBrowserConnections(state) ?? {};
  return Object.keys(connections).length;
};
export const selectDatabaseBrowserConnectionsRequestStatus = (state: RootState): RequestStatus =>
  state.dbBrowser.connectionsRequestStatus;
export const selectDatabaseBrowserConnectionById =
  (connectionId: string) =>
  (state: RootState): HomeObjectKeysTypes | null =>
    state.dbBrowser.connections[connectionId];

/* =================================== DATABASE SELECTORS =================================== */
/** Selects the open DB Browser Database */
export const selectDatabaseBrowserOpenDatabase = createSelector(
  [selectDatabaseBrowserDatabases, selectDatabaseBrowserOpenConnection],
  (databases, connection): Database | null => {
    const connUUID = connection?.[HomeObjectKeys.UUID];
    return connUUID ? databases[connUUID] : null;
  },
);
export const selectDatabaseBrowserDatabaseById =
  (connectionId: string) =>
  (state: RootState): Database | null =>
    state.dbBrowser.databases[connectionId];
/** Selects the namespaces for the current open database */
export const selectDatabaseNamespaces = createSelector(
  [selectDatabaseBrowserOpenDatabase],
  (database): { [namespace: string]: Namespace } => database?.namespaces ?? {},
);
/** Selects the filter term for the current open database */
export const selectDatabaseFilterTerm = createSelector(
  [selectDatabaseBrowserOpenDatabase],
  (database): string => database?.filterTerm ?? '',
);
/** Selects the status of fetching namespaces */
export const selectDatabaseRequestingNamespacesStatus = createSelector(
  [selectDatabaseBrowserOpenDatabase],
  (database): RequestStatus => database?.requestingNamespacesStatus ?? RequestStatus.Unrequested,
);
export const selectDatabaseRequestingNamespacesStatusById =
  (connectionId: string) =>
  (state: RootState): RequestStatus => {
    return (
      state.dbBrowser.databases[connectionId]?.requestingNamespacesStatus ??
      RequestStatus.Unrequested
    );
  };
export const selectDatabaseNamspacesLastUpdatedByID =
  (connectionId: string) => (state: RootState) => {
    return state.dbBrowser.databases[connectionId]?.lastUpdated;
  };
export const selectDatabaseNamspacesErrorByID = (connectionId: string) => (state: RootState) => {
  return state.dbBrowser.databases[connectionId]?.errorMessage;
};
export const selectDatabaseNamespacesListEmpty = (connectionId: string) => (state: RootState) => {
  const namespaces = state.dbBrowser.databases[connectionId]?.namespaces;
  return !namespaces || Object.keys(namespaces).length === 0;
};
export const selectDatabaseListNamespacesError =
  (connectionId: string) =>
  (state: RootState): string | null =>
    state.dbBrowser.databases[connectionId]?.errorMessage || null;
export const selectDatabaseHasFetchedAllNamespaces =
  (connectionId: string) => (state: RootState) => {
    const databaseObj = selectDatabaseBrowserDatabaseById(connectionId)(state);
    if (!databaseObj || !databaseObj.namespaces) {
      return false;
    }
    const { totalNamespaceCount, namespaces } = databaseObj;
    const fetchedNamespaceCount = Object.keys(namespaces).length;
    return totalNamespaceCount <= fetchedNamespaceCount;
  };

/* =================================== NAMESPACE SELECTORS =================================== */
export const selectNamespacesByConnectionId = (connectionId: string) => (state: RootState) => {
  return state.dbBrowser.databases[connectionId]?.namespaces || [];
};
export const selectNamespace = createSelector(
  selectDatabaseNamespaces,
  (_: RootState, namespace: string): string => namespace,
  (namespaces, namespace): Namespace | undefined => namespaces[namespace],
);
export const selectNamespaceRequestingTablesStatus =
  (connectionId: string, namespace: string) =>
  (state: RootState): RequestStatus => {
    return (
      state.dbBrowser.databases[connectionId]?.namespaces?.[namespace]?.requestingTablesStatus ??
      RequestStatus.Unrequested
    );
  };
export const selectNamespaceHasFetchedAllTables =
  (connectionId: string, namespace: string) => (state: RootState) => {
    const namespaceObj = selectNamespacesByConnectionId(connectionId)(state)?.[namespace];
    if (!namespaceObj) {
      return false;
    }
    const { totalTableCount, tables } = namespaceObj;
    const fetchedTableCount = Object.keys(tables).length;
    return totalTableCount <= fetchedTableCount;
  };
export const selectNamespaceTables =
  (connectionId: string, namespace: string) => (state: RootState) => {
    // This is  a list of objects representing namespaces and tables
    const namespaceObj = selectNamespacesByConnectionId(connectionId)(state)?.[namespace];
    return namespaceObj?.tables ?? {};
  };

/* =================================== TABLE METADATA =================================== */
export const selectTable =
  (connectionId: string, namespace: string, table: string) => (state: RootState) => {
    return state.dbBrowser.databases[connectionId]?.namespaces?.[namespace]?.tables?.[table];
  };
export const selectColumnMetadata =
  (connectionId: string, namespace: string, table: string) => (state: RootState) => {
    return selectTable(connectionId, namespace, table)(state)?.columns;
  };
export const selectTableMetadataRequestStatus =
  (connectionId: string, namespace: string, table: string) =>
  (state: RootState): RequestStatus => {
    return (
      selectTable(connectionId, namespace, table)(state)?.metadataStatus ??
      RequestStatus.Unrequested
    );
  };
export const selectTableMetadataError =
  (connectionId: string, namespace: string, table: string) =>
  (state: RootState): string | null =>
    selectTable(connectionId, namespace, table)(state)?.errorMessage;
export const selectTableLastUpdated =
  (connectionId: string, namespace: string, table: string) =>
  (state: RootState): string | null =>
    selectTable(connectionId, namespace, table)(state)?.lastUpdated;

/* =================================== PREVIEW TABLES =================================== */
export const selectSelectedPreview = (connectionId: string) => (state: RootState) => {
  return state.dbBrowser.databases[connectionId]?.selectedPreview;
};
export const selectPreviewTableData =
  (connectionId: string, namespace: string, table: string) => (state: RootState) => {
    return selectTable(connectionId, namespace, table)(state)?.rows;
  };
export const selectPreviewColumnData =
  (connectionId: string, namespace: string, table: string) => (state: RootState) => {
    return selectTable(connectionId, namespace, table)(state)?.columns;
  };
export const selectPreviewTables =
  (connectionId: string, namespace: string) => (state: RootState) => {
    return state.dbBrowser.databases[connectionId]?.selectedPreviewTables?.[namespace] ?? [];
  };

/* =================================== SELECTED TABLES =================================== */
export const selectSelectedTablesByNamespace =
  (connectionId: string, namespace: string) =>
  (state: RootState): string[] => {
    return state.dbBrowser.databases[connectionId]?.selectedTables[namespace] ?? [];
  };
export const selectAllTablesChecked =
  (connectionId: string, namespace: string) => (state: RootState) => {
    const tablesObj = selectNamespaceTables(connectionId, namespace)(state);
    const tables = Object.keys(tablesObj);
    const selectedTables = selectSelectedTablesByNamespace(connectionId, namespace)(state);
    const hasFetchedAllTables = selectNamespaceHasFetchedAllTables(connectionId, namespace)(state);
    if (!hasFetchedAllTables) {
      return false;
    }
    return tables.every((tableName) => selectedTables?.includes(tableName));
  };
export const selectSomeTablesChecked =
  (connectionId: string, namespace: string) => (state: RootState) => {
    const tablesObj = selectNamespaceTables(connectionId, namespace)(state);
    const tables = Object.keys(tablesObj);
    const selectedTables = selectSelectedTablesByNamespace(connectionId, namespace)(state);
    return tables.some((tableName) => selectedTables?.includes(tableName));
  };
export const selectSelectedTables = createSelector(
  [selectDatabaseBrowserOpenDatabase],
  (database): { [namespace: string]: string[] } => database?.selectedTables ?? {},
);
export const selectNumSelectedTables = createSelector(
  selectSelectedTables,
  (selectedTables): number =>
    Object.values(selectedTables).reduce((acc, val) => acc + val.length, 0),
);

/* =================================== FILTERING =================================== */
export const selectFilterTermById =
  (connectionId: string) =>
  (state: RootState): string =>
    state.dbBrowser.databases[connectionId]?.filterTerm ?? '';

/* =================================== DATASET CREATION =================================== */
export const selectFailedDatasetCreation = (state: RootState): string[] =>
  state.dbBrowser.failedDatasetCreation;
export const selectDatasetCreationStatus = (state: RootState): RequestStatus =>
  state.dbBrowser.creatingDatasetRequestStatus;
