export const CHANGE_ACCESS_REQUEST = 'workspacev2/CHANGE_ACCESS_REQUEST';
export const CHANGE_ACCESS_SUCCESS = 'workspacev2/CHANGE_ACCESS_SUCCESS';
export const CHANGE_ACCESS_FAILURE = 'workspacev2/CHANGE_ACCESS_FAILURE';

export const SET_CURRENT_FOLDER_BREADCRUMB = 'workspacev2/SET_CURRENT_FOLDER_BREADCRUMB';
export const SET_CURRENT_FOLDER = 'workspacev2/SET_CURRENT_FOLDER';

export const CREATE_WORKSPACE_OBJECT_REQUEST = 'workspacev2/CREATE_WORKSPACE_OBJECT_REQUEST';
export const CREATE_WORKSPACE_OBJECT_SUCCESS = 'workspacev2/CREATE_WORKSPACE_OBJECT_SUCCESS';
export const CREATE_WORKSPACE_OBJECT_FAILURE = 'workspacev2/CREATE_WORKSPACE_OBJECT_FAILURE';
export const OPEN_MOVE_MENU = 'workspacev2/OPEN_MOVE_MENU';
export const CLOSE_MOVE_MENU = 'workspacev2/CLOSE_MOVE_MENU';
export const MOVE_DC_OBJECT_REQUEST = 'workspacev2/MOVE_DC_OBJECT_REQUEST';
export const MOVE_DC_OBJECT_SUCCESS = 'workspacev2/MOVE_DC_OBJECT_SUCCESS';
export const MOVE_DC_OBJECT_FAILURE = 'workspacev2/MOVE_DC_OBJECT_FAILURE';

/**
  Dispatch an action change the access permissions of the users for an object
  @param {String} uuid uuid of an object
  @param {Object} payload -> {
    "<userId>": {
      "type" : <"Viewer or Editor">
      "visibility" : <"visible" or "hidden">
    }
     @param {String} objectType objectType of the object (optional)
     @param {Object} folder (optional) -> {
      uuid: <uuid of current folder>
      objectName: e.g. "My Work",
      accessType: <'owner', 'viewer','editor','commenter'>,
    }
  }
*/
export const changeAccessRequest = ({ uuid, payload, objectType }) => ({
  type: CHANGE_ACCESS_REQUEST,
  uuid,
  payload,
  objectType,
});

export const changeAccessSuccess = ({ uuid, payload }) => ({
  type: CHANGE_ACCESS_SUCCESS,
  uuid,
  payload,
});

export const changeAccessFailure = ({ uuid, payload }) => ({
  type: CHANGE_ACCESS_FAILURE,
  uuid,
  payload,
});

export const setCurrentFolder = ({ folder }) => ({
  type: SET_CURRENT_FOLDER,
  folder,
});

export const setCurrentFolderBreadcrumb = ({ folderBreadcrumb }) => ({
  type: SET_CURRENT_FOLDER_BREADCRUMB,
  folderBreadcrumb,
});

export const createWorkspaceObjectRequest = ({ name, parentFolder }) => ({
  type: CREATE_WORKSPACE_OBJECT_REQUEST,
  name,
  parentFolder,
});

export const createWorkspaceObjectSuccess = () => ({
  type: CREATE_WORKSPACE_OBJECT_SUCCESS,
});

export const createWorkspaceObjectFailure = ({ error, canceledByUser }) => ({
  type: CREATE_WORKSPACE_OBJECT_FAILURE,
  error,
  canceledByUser,
});

export const openMoveMenu = ({ objects }) => ({
  type: OPEN_MOVE_MENU,
  objects,
});

export const closeMoveMenu = () => ({
  type: CLOSE_MOVE_MENU,
});

export const moveDcObjectRequest = ({ object, destination }) => ({
  type: MOVE_DC_OBJECT_REQUEST,
  object,
  destination,
});

export const moveDcObjectSuccess = () => ({
  type: MOVE_DC_OBJECT_SUCCESS,
});

export const moveDcObjectFailure = ({ error }) => ({
  type: MOVE_DC_OBJECT_FAILURE,
  error,
});
