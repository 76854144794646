import { AxiosResponse } from 'axios';
import { SagaReturnType } from 'redux-saga/effects';
import { call, delay, SagaGenerator, select, take } from 'typed-redux-saga';
import { ErrorHandler } from '../../../types/errorHandler.types';
import { makePercentageBackoff } from '../../../utils/backoff_calculations';
import {
  connectionErrorHandlers,
  handleAPIError,
} from '../../../utils/errorHandling/errorHandlers.api';
import { REFRESH_SUCCESS } from '../../actions/auth.actions';
import { selectAccessToken } from '../selectors';

/**
 * Wrapper function to handle 401 retries with token refresh
 * @param apiCall Function that makes the API call
 * @param args Arguments to pass to the API call
 * @returns Result from the API call
 */
export function* retry401<T, Args extends any[]>(
  apiCall: (token: string, ...args: Args) => T | Promise<T>,
  ...args: Args
): SagaGenerator<SagaReturnType<typeof apiCall>> {
  while (true) {
    try {
      const accessToken = yield* select(selectAccessToken);

      // Check if accessToken is valid
      if (!accessToken) {
        yield* take(REFRESH_SUCCESS);
        yield* delay(200);
        continue;
      }

      return yield* call(apiCall, accessToken, ...args);
    } catch (error) {
      if ((error as { response?: { status: number } }).response?.status === 401) {
        yield* take(REFRESH_SUCCESS);
        yield* delay(200);

        continue;
      }
      throw error;
    }
  }
}

/**
 * Calls an API function with a retry mechanism for specified errors
 *
 * @param apiFn -- Function to call that returns an AxiosResponse
 * @param args -- Arguments to pass to the function
 * @param maxAttempts -- Maximum number of connection attempts to make
 * @param minBackoff -- Minimum backoff time in milliseconds between attempts
 * @param percentForBackoff -- Percentage of the total backoff time to use for the backoff time
 * @param errorHandlers -- List of error handlers to use for determining if a retry is allowed.
 * If the error is not handled by any of these, it will be thrown
 * @returns The result of the API call
 * @throws The error from the API call if it is not a network error, or
 * if if maximum retries have been exceeded
 */

export function* callAPIWithRetry<T, Args extends any[]>({
  apiFn,
  args,
  maxAttempts = 15,
  minBackoff = 400,
  percentForBackoff = 0.3,
  errorHandlers = connectionErrorHandlers,
}: {
  apiFn: (...args: Args) => Promise<AxiosResponse<T>>;
  args: Args;
  maxAttempts?: number;
  minBackoff?: number;
  percentForBackoff?: number;
  errorHandlers?: ErrorHandler[];
}): SagaGenerator<SagaReturnType<typeof apiFn>> {
  const percentageBackoff = makePercentageBackoff(minBackoff, percentForBackoff);
  for (let i = 0; i < maxAttempts; i++) {
    try {
      return yield* call(apiFn, ...args);
    } catch (error) {
      handleAPIError(error, errorHandlers);
      // If handle did not throw an error, retry API call
      yield* delay(percentageBackoff());
      continue;
    }
  }
  throw new Error('Maximum retries exceeded');
}
