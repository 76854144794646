// To move the caret out from a tag in ContentEditable,
// we need to insert a space after the whole string.
export const TAG_ESCAPER = ' ';

export const DIR_VALUE_ENTITY = {
  SIGNAL: 'dirname',
};

export const ELEMENT_ID = {
  REGULAR: 'editable',
  FORM: 'form',
};
export const COLUMN_VALUE_ENTITY = {
  SIGNAL: 'Enter a String Value',
  PROMPT: 'Enter a String Value<VAL><VAL>',
  PROMPT_KEYWORD: [
    'Enter a String Value',
    'Enter a Datetime Value',
    'Enter a Date Value',
    'Enter a Numeric Value',
    'Enter a Boolean Value',
    'dataset.row_number.column',
    'a value or dataset.row_number.column',
  ],
  OPEN_TAG: '<strong>',
  CLOSE_TAG: '</strong>',
  COMPLETE_ANNOTATOR: 'COMPLETE',
  PARTIAL_ANNOTATOR: 'PARTIAL',
  EMPTY_PLACEHOLDER: '<em contenteditable="false">EMPTY</em>',
  EXPAND_TAG: 'Click to Expand',
  NULL_PLACEHOLDER: '<em contenteditable="false">NULL</em>',
  EMPTY_PLACEHOLDER_LENGTH: 'EMPTY'.length,
  SPECIALCHAR_OPEN_TAG: '<strong><em contenteditable="false">',
  SPECIALCHAR_CLOSE_TAG: '</em></strong>',
  PLACEHOLDER_OPEN_TAG: '<em contenteditable="false">',
  // Helper functions

  // remove the tags and return the clean text
  getCleanText: (inputValue) => {
    const cleanInput = inputValue.replace(
      /<strong>|<\/strong>|<em contenteditable="false">|<\/em>/g,
      '',
    );
    return cleanInput;
  },

  getLastElement: () => {
    const elements = document.getElementsByTagName('strong');
    if (elements.length > 0) {
      const lastEl = elements[elements.length - 1];
      return lastEl.outerHTML;
    }
    return '';
  },

  getLastSpecialTag: (inputValue) => {
    const regex = /<strong><em contenteditable="false">[A-Z]+<\/em><\/strong>/g;
    const matched = inputValue.match(regex);
    if (matched.length > 0) {
      return matched[matched.length - 1];
    }
    return '';
  },

  // get the length of a placeholder in italic(last element).
  getPlaceholderLength: (inputValue, first) => {
    // const regex = /<strong><em contenteditable="false">([a-zA-Z]+)<\/em><\/strong>/g
    // const elements = inputValue.match(regex);
    // if (elements.length > 0){
    //   return COLUMN_VALUE_ENTITY
    //          .getCleanText(elements[elements.length-1]).length;
    // }
    const cleanInput = COLUMN_VALUE_ENTITY.getCleanText(inputValue).trim().split(' ');
    if (cleanInput.length > 0) {
      if (first) {
        return cleanInput[0].length;
      }
      return cleanInput[cleanInput.length - 1].length;
    }
    return 0;
  },
  verifySignal: (prompts) => {
    const containKeyword = (prompt) => {
      for (let i = 0; i < COLUMN_VALUE_ENTITY.PROMPT_KEYWORD.length; i++) {
        if (prompt === COLUMN_VALUE_ENTITY.PROMPT_KEYWORD[i]) {
          return true;
        }
      }
      return false;
    };
    for (let i = 0; i < prompts.length; i++) {
      if (containKeyword(prompts[i])) {
        return true;
      }
    }
    return false;
  },
  isPartial: (suggestion) => {
    if (typeof suggestion === 'object') {
      return suggestion.annotation === COLUMN_VALUE_ENTITY.PARTIAL_ANNOTATOR;
    }
    return false;
  },
  isComplete: (suggestion) => {
    return !COLUMN_VALUE_ENTITY.isPartial(suggestion);
  },
};

export const FILE_PATH_ENTITY = {
  SIGNAL: 'filepath',
};

// TODO: unused constant, need be checked in next textbox refactoring step
export const shouldWrapWithTag = (entityType) => entityType === 'VAL' || entityType === 'FILE';
