module.exports = Object.freeze({
  UNALLOWED_DATASETS: ['describe', 'all datasets', 'all foreign keys', 'all primary keys'],
  PLOTS_NOT_DOWNLOADABLE: [
    'geo',
    'geoheatmap',
    'geobubblemap',
    'geochoroplethmap',
    'graph',
    'sunburst',
    'decision_tree',
    'tree',
  ],
  PLOTS_WITHOUT_MODE_BAR: ['treemap', 'geo', 'sunburst'],
  // If changes are made to TYPES_TO_NAMES, please make these changes to the dictionary
  // with the same name found in chart_namespace_manager.py
  TYPES_TO_NAMES: {
    Parcoords: 'Parallel Coordinates',
    Pie: 'Donut',
    Geoheatmap: 'Geo Heatmap',
    Geobubblemap: 'Geo Bubble Map',
    Geochoroplethmap: 'Geo Choropleth Map',
    Decision_tree: 'Decision Tree',
    RecipeDiagram: 'Workflow Graph',
  },
  EDIT_MODE_MENU_WIDTH: 200,
  PLOTS_NOT_COLOR_CHANGEABLE: ['heatmap', 'box'],
  CHART_DESTINATIONS: { Clipboard: 'clipboard', Workplace: 'workplace' },
  EDIT_MODES: { Chart: 'chart', Caption: 'caption', Table: 'table', CloseAll: 'closeAll' },
  CAPTION_CHAR_LIMIT: 1403,
  THIN_TABLE_COL_LIMIT: 8,
  THIN_TABLE_ROW_LIMIT_AVA: 15,
  THICK_TABLE_ROW_LIMIT_AVA: 5,
});
