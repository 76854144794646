import {
  DCColTypes,
  binMethodNames,
  getBinChipTitle,
  skippedBinningInputs,
} from 'translate_dc_to_echart';
import {
  BIN_COUNT_ERROR,
  BIN_FORMATTING_THRESHOLD,
  BOUNDARY_ERROR,
  INVALID_VALUE,
  MAX_BIN_COUNT,
  NUMBER_ERROR,
  POSITIVE_INTEGER_ERROR,
  POSITIVE_NUMBER_ERROR,
} from './constants';

/**
 *
 * This file contains functions affecting the Bin dropdown in the Chart Builder.
 * Translation functions can be found within src/components/DisplayPanel/Charts/dcplotV2/util/bin.js
 *
 */

/** Splits an input string by commas, trims whitespace, and filters out empty string. */
export const splitByComma = (input) => {
  return input
    .split(',')
    .map((val) => val.trim())
    .filter((val) => val);
};

/**
 * Get all of the valid column names from the dataset, filtered by type (numeric)
 * @param {Array} columnsTypes The column/type information from our dataset
 * @param {String} currChartType The selected chart type
 * @param {Object} fields The chart builder's required & optional field selections
 * @returns An array of column names that are valid for binning
 */
export const getBinColumnOptions = (columnsTypes, currChartType, fields) => {
  // Get the invalid/un-binnable fields for the chart type, ex. ['y', 'y-axis']
  const invalidFields = skippedBinningInputs[currChartType];

  // Get the column names for each of the valid fields, if they have been filled by the user
  // Binning is not available for certain fields, based on the chart type
  const validSelectedCols = new Set();
  Object.keys(fields).forEach((field) => {
    if (!invalidFields.includes(field)) {
      // Add the value(s) from the input field to our set
      let val = fields[field];
      val = Array.isArray(val) ? val : [val];
      val.forEach((v) => validSelectedCols.add(v));
    }
  });

  // Get all of the valid column names from the dataset, filtered by type (numeric)
  const allValidCols = columnsTypes
    .filter((col) => col.type === 'Float' || col.type === 'Integer' || col.type === 'Number')
    .map((col) => col.name);

  // Return the current user-selected columns that are valid
  return allValidCols.filter((col) => Array.from(validSelectedCols).includes(col));
};

/**
 * Validate our interval value to be an integer or float
 * @param {String | Number} num The user-inputted interval
 * @returns An error message, or an empty string if no error
 */
export const validateNumber = (num) => (Number.isNaN(Number(num)) ? NUMBER_ERROR : '');

/**
 * Validate our interval value to be a positive integer or float
 * @param {String | Number} num The user-inputted interval
 * @returns An error message, or an empty string if no error
 */
export const validatePositiveNumber = (num) => {
  const testVal = Number(num);

  if (Number.isNaN(testVal) || testVal <= 0) {
    return POSITIVE_NUMBER_ERROR;
  }

  return '';
};

/**
 * Validate our interval value to be a positive integer
 * @param {String | Number} num The user-inputted interval
 * @param {String} method The bin method ex. 'Boundaries'
 * @returns An error message, or an empty string if no error
 */
export const validatePositiveInteger = (num, method) => {
  const testVal = Number(num);

  if (!Number.isInteger(testVal) || testVal <= 1) {
    return POSITIVE_INTEGER_ERROR;
  }
  if (
    testVal > MAX_BIN_COUNT &&
    (method === binMethodNames.percentile || method === binMethodNames.count)
  ) {
    return BIN_COUNT_ERROR;
  }

  // We do not accept intergers with a decimal point (ex. 3.2)
  if (String(num).includes('.')) {
    return INVALID_VALUE;
  }

  return '';
};

/**
 * Validates the user-inputted interval
 * Boundary values have to be split by commas, then tested individually
 * @param {String} testInterval The binInterval value that we're testing
 * @param {String} testMethod The binMethod value that we're testing
 * @returns A string with the error message, or a blank string if there is no error
 */
export const validateInterval = (testInterval, testMethod) => {
  // If our interval is blank, there is no error
  if (testInterval === '' || testInterval === null) return '';

  switch (testMethod) {
    case binMethodNames.boundaries: {
      // Convert string into an array of values
      const testArr = splitByComma(testInterval);

      // Check if the label is empty after splitting
      if (testArr.length === 0) return [BOUNDARY_ERROR];

      // Validate each value
      for (const num2 of testArr) {
        const res = validateNumber(num2);

        // If we find an error, return the first error found
        if (res !== '') return res;
      }

      // Return a blank error if we passed the validation
      return '';
    }
    case binMethodNames.size:
      return validatePositiveNumber(testInterval);
    case binMethodNames.count:
    case binMethodNames.percentile:
      // Validatation for a single value
      return validatePositiveInteger(testInterval, testMethod);
    default:
      return '';
  }
};

/**
 * Checks if the current column is valid with the current chart type and fields
 * @param {String} column The currently selected column in the bin column dropdown
 * @param {Array} currColumnOptions The current column options for our bin column dropdown
 * @returns True or False depending on if the currently selected column is valid
 */
export const isColumnValid = (column, currColumnOptions) => currColumnOptions.includes(column);

/**
 * Generates the bin spec for histograms.
 * @param {string[]} binnedColNames The names of the columns to bin
 * @param {[]} columnsTypes The col names & types from the dataset
 * @param {number} totalRowCount The total number of rows in the dataset
 * @returns Histogram bin definitions for each binnedColName
 */
export const generateHistogramBins = (binnedColNames, columnsTypes, totalRowCount) => {
  if (!binnedColNames || !columnsTypes || !totalRowCount) return []; // Quit early

  // The binning algorithm which matches the behavior of the backend
  const numBins = Math.min(Math.ceil(Math.sqrt(totalRowCount)), MAX_BIN_COUNT);
  const bins = [];

  // Create a bin for each binnedColName
  binnedColNames.forEach((col) => {
    const column = col;
    const interval = numBins;
    const method = binMethodNames.count;
    const title = getBinChipTitle({ column, interval, method });
    const colType = columnsTypes.find((c) => c.name === col).type;
    const type =
      [DCColTypes.FLOAT, DCColTypes.INTEGER].includes(colType) &&
      interval > BIN_FORMATTING_THRESHOLD
        ? DCColTypes.FLOAT
        : DCColTypes.STRING;

    bins.push({ column, interval, method, title, type });
  });

  return bins;
};
