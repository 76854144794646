import { LOADING_MESSAGES } from '../constants/dataAssistant';

// generic shuffle function, used to shuffle loading messages
export const shuffleArray = (array: string[]) => {
  let currentIndex = array.length;
  let randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex > 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
};

// build the sequence for react-type-animation component
// 1. shuffle the loading messages
// 2. use "Generating solution" as the first message
// 3. add a 3 second pause after "Generating solution"
// 4. add a 3 second pause after each message
export const buildLoadingMessageSequence = () => {
  const loadingMessages = shuffleArray(LOADING_MESSAGES);
  const loadingMessageSequence = [500, 'Generating solution...', 4000];
  loadingMessages.forEach((message) => {
    loadingMessageSequence.push(message);
    loadingMessageSequence.push(4000);
  });
  return loadingMessageSequence;
};
