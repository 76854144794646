import Close from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useDispatch } from 'react-redux';
import { CATALOG_TITLE, closeCatalogRequest } from '../../../store/slices/catalog.slice';
import DatachatCatalogContent from './Content/DatachatCatalogContent';
import './DatachatCatalog.scss';

const DatachatCatalog = () => {
  const dispatch = useDispatch();
  const handleClose = () => dispatch(closeCatalogRequest());

  return (
    <Dialog
      className="DatachatCatalog"
      open
      fullWidth
      maxWidth="xl"
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') handleClose();
      }}
    >
      <Box className="DatachatCatalog-Title">
        <Box className="DatachatCatalog-Title-Name-Container">
          <Typography className="DatachatCatalog-Title-Name">{CATALOG_TITLE}</Typography>
        </Box>
        <IconButton
          className="DatachatCatalog-Title-Close"
          data-testid="catalog-close-button"
          onClick={handleClose}
        >
          <Close />
        </IconButton>
      </Box>
      <DatachatCatalogContent />
    </Dialog>
  );
};

export default DatachatCatalog;
