import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BigQueryLogo from '../../assets/logos/google-bigquery-logo.svg';
import SnowflakeLogo from '../../assets/logos/snowflake-logo.svg';
import { OAuthPassthroughState, ProviderConfig } from '../../components/OAuthButtons/oauth.types';
import { authorizeOAuth } from '../../components/OAuthButtons/utils/OAuthUtils';
import { selectIntegrationByType } from '../../store/selectors/integrations.selector';
import {
  selectSnowflakeOAuthAccountName,
  selectSnowflakeOAuthClientID,
} from '../../store/selectors/oauth.selector';
import { disconnectIntegrationRequest } from '../../store/slices/integrations.slice';
import { selectGoogleSSOClientID } from '../../store/typedSelectors';
import { IntegrationProvider } from '../../types/integrations.types';

export const providerConfigs: Record<IntegrationProvider, ProviderConfig> = {
  [IntegrationProvider.BIGQUERY]: {
    name: 'BigQuery',
    Logo: BigQueryLogo,
    clientIdSelector: selectGoogleSSOClientID,
  },
  [IntegrationProvider.SNOWFLAKE]: {
    name: 'Snowflake',
    Logo: SnowflakeLogo,
    clientIdSelector: selectSnowflakeOAuthClientID,
    additionalSelectors: {
      accountName: selectSnowflakeOAuthAccountName,
    },
  },
  // Add more providers here as needed
};

const useOAuthButton = (provider: IntegrationProvider, passThroughState: OAuthPassthroughState) => {
  const dispatch = useDispatch();

  /** Gets's the appropriate selectors, logo, and name for the given provider */
  const config = providerConfigs[provider];
  const { name, Logo, clientIdSelector, additionalSelectors } = config;

  /** the user's bigquery intergations */
  const integration = useSelector(selectIntegrationByType(provider));
  /** If the user is connected to the provider */
  const connected = Boolean(integration);
  /** If the integration is being deleted or not */
  const isDeleting = integration?.deleting;
  /** The redirect URI registered with the oauth provider */
  const redirectUri = `${window.location.origin}/oauth`;
  /** The client id for the given provider */
  const clientId = useSelector(clientIdSelector);

  // Call all selectors individually
  const accountName = useSelector(additionalSelectors?.accountName ?? (() => undefined));

  // Combine additional props
  const additionalProps = React.useMemo(() => {
    const props: Record<string, string> = {};
    if (accountName)
      props.authenticationUri = `https://${accountName}.snowflakecomputing.com/oauth/authorize`;
    return props;
  }, [accountName]);

  /** Triggers the OAuth Flow is the user isn't already connected */
  const handleAuth = React.useCallback(() => {
    if (!connected) {
      authorizeOAuth(provider, clientId, redirectUri, passThroughState, additionalProps);
    }
  }, [connected, provider, clientId, redirectUri, additionalProps, passThroughState]);

  /** Handles revoking the user's current token */
  const handleDisconnect = React.useCallback(() => {
    dispatch(disconnectIntegrationRequest(provider));
  }, [dispatch, provider]);

  return {
    isDeleting,
    connected,
    integration,
    handleAuth,
    handleDisconnect,
    Logo,
    name,
  };
};

export default useOAuthButton;
