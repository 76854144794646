import moment from 'moment';

// Returns the current time corresponding to the format
export const getCurrentTime = (format = '') => {
  return moment().format(format);
};

// Converts the time to utc time corresponding tot he format
export const formatTime = (time, format = '') => {
  return moment(time).format(format);
};

export const formatDate = (date) => {
  return moment(date).format('MM/DD/YYYY');
};

export const formatDateTime = (date) => {
  return moment(date).format('MM/DD/YY hh:mm A');
};
