import {
  SUBMIT_USER_FEEDBACK_FAILURE,
  SUBMIT_USER_FEEDBACK_REQUEST,
  SUBMIT_USER_FEEDBACK_SUCCESS,
} from '../actions/userFeedback.action';

const intialState = {
  requesting: false, // true if a user feedback request is processing
  error: null, // error object
};

export default (state = intialState, action) => {
  switch (action.type) {
    case SUBMIT_USER_FEEDBACK_REQUEST:
      return { ...state, requesting: true };
    case SUBMIT_USER_FEEDBACK_SUCCESS:
      return { ...state, requesting: false };
    case SUBMIT_USER_FEEDBACK_FAILURE:
      return { ...state, requesting: false, error: action.error };

    default:
      return state;
  }
};
