import { createSelector } from 'reselect';
import { RootState } from '../../configureStore';
import { IntegrationProvider } from '../../types/integrations.types';

export const selectShowIntegrationMenu = (state: RootState) => state.integrations.open;
export const selectIntegrations = (state: RootState) => state.integrations.integrations;
export const selectIntegrationByType = (type: IntegrationProvider) => (state: RootState) =>
  state.integrations.integrations[type];
export const selectIntegrationsRequestStatus = (state: RootState) =>
  state.integrations.requestStatus;

export const selectReAuthenticationDialog = (state: RootState) =>
  state.integrations.reAuthenticationDialog;
export const selectReAuthenticationDialogOpen = createSelector(
  [selectReAuthenticationDialog],
  (dialog) => dialog.open,
);
export const selectReAuthenticationDialogProvider = createSelector(
  [selectReAuthenticationDialog],
  (dialog) => dialog.provider,
);
export const selectReAuthenticationDialogDetail = createSelector(
  [selectReAuthenticationDialog],
  (dialog) => dialog.detail,
);
