// All supported generated language types from LLM model
export const GEL_CODE_TYPE = 'GEL';
export const PYTHON_CODE_TYPE = 'Python';
export const SQL_CODE_TYPE = 'SQL';

export const GENERATED_LANGUAGE_TYPES = [GEL_CODE_TYPE, PYTHON_CODE_TYPE, SQL_CODE_TYPE];

// height / width (in ratio of parent container) of ask ava module
export const ASK_AVA_HEIGHT_RATIO = 0.75;
export const ASK_AVA_WIDTH_RATIO = 0.5;

// minimum height / width of ask module
export const ASK_AVA_MIN_WIDTH = '500px';
export const ASK_AVA_MIN_HEIGHT = '50vh';

// confidence scores for generated code
export const ERROR_EXPLAINED = 'ERROR_EXPLAINED';
export const FAILED_SCORE = 'FAILED';

export const ASK_AVA_ALERT_WARNING = 'warning';
export const ASK_AVA_ALERT_ERROR = 'error';

// alert text
export const LOW_CONFIDENCE_TEXT =
  'Low confidence score. Correct the solution below and try rerunning it.';
export const NO_SOLUTION_TEXT = 'Failed to generate a solution. Please rephrase your question.';

// buttons used in AskAvaEditorControls
export const ASK_AVA_BUTTONS = {
  CONTINUE: 'Continue',
  NEXT: 'Next',
  PAUSE: 'Pause',
  STOP: 'Stop',
  COPY: 'Copy',
  CUT: 'Cut',
  SELECT_ALL: 'Select All',
  DESELECT_ALL: 'Deselect All',
  EDIT: 'Edit',
};

export const ASK_AVA_RECIPE_NAME = 'ask_ava_generated_recipe';
// character limit for ask ava input
export const ASK_AVA_CHARACTER_LIMIT = 512;

// share types for ask ava
export const ASK_AVA_SHARE_TYPES = {
  LINK: 'link',
  EMBED: 'embed',
};

// event source constants
export const ASK_AVA_EVENTS = {
  OPEN: 'OPEN',
  MESSAGE: 'MESSAGE',
  CLOSE: 'CLOSE',
};

// custom error class for when a request is in queue,
// but has not been sent for processing
// we can use this to render a cancel button in various cases
export class AvaRequestInQueueError extends Error {
  constructor(message) {
    super(message);
    this.name = 'AvaRequestInQueueError';
  }
}

export const ASK_TASK_TYPES = {
  CODE_GENERATION: 'CODE_GENERATION',
  RECOMMENDATION: 'RECOMMENDATION',
};
