import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getApp } from '../../api/apps.api';
import { authenticate } from '../../utils/authenticate';
import { LOAD_APPS_REQUEST, loadAppsFailure, loadAppsSuccess } from '../actions/apps.actions';
import { createAlertChannelRequest } from '../actions/dialog.actions';
import { selectAccessToken } from './selectors';

/**
 * Makes an API request for the list of apps and handles the response.
 */
export function* loadAppsRequestWorker() {
  try {
    const accessToken = yield select(selectAccessToken);
    const response = yield call(getApp, accessToken);
    const apps = response.data;
    yield put(loadAppsSuccess({ apps }));
  } catch (error) {
    yield put(loadAppsFailure({ error }));
    yield put(createAlertChannelRequest({ error }));
  }
}

/**
 * Listens for the most recent LOAD_APPS_REQUEST action or
 * LOAD_APP_VIEWS_REQUEST action and spins up a worker.
 */
export default function* apiWatcher() {
  yield takeLatest([LOAD_APPS_REQUEST], authenticate(loadAppsRequestWorker, loadAppsFailure));
}
