import { TOAST_EXPORTING, TOAST_UPDATING_DATASET } from '../../constants/toast';
import { UPDATE_DATASET_REQUEST } from '../actions/dataset.actions';
import {
  END_FRONTEND_REPLAY_FAILURE,
  END_FRONTEND_REPLAY_SUCCESS,
  PAUSE_REPLAY,
  SUBMIT_UTTERANCE_FAILURE,
} from '../actions/editor.actions';
import { ADD_TOAST, DISMISS_ALL_TOASTS, DISMISS_TOAST } from '../actions/toast.actions';

// These toast types will only have one toast shown at a time
export const SINGLE_TOAST_TYPES = new Set([TOAST_UPDATING_DATASET]);

export const initialState = {
  toasts: {},
  nextToastId: 0,
  singleToastIds: {},
};

/**
 * Check if new toast already in the list to avoid too many toasts show within 8s
 *
 * @param {*} state current state
 * @param {*} action incoming action
 * @returns true if duplicate other wise false
 */
const checkDuplicateToast = (state, action) => {
  for (const id in state.toasts) {
    if (
      state.toasts[id].toastType === action.toastType &&
      state.toasts[id].message === action.message
    ) {
      return true;
    }
  }
  return false;
};

/**
 * function for filtering toasts, keeping only progress toasts
 * @param {Object} state current state
 * @returns filtered toasts
 */
const filterToasts = (state) => {
  const filteredToasts = {};
  for (const id in state.toasts) {
    if (
      state.toasts[id].toastType === TOAST_EXPORTING ||
      state.toasts[id].toastType === TOAST_UPDATING_DATASET
    ) {
      filteredToasts[id] = { ...state.toasts[id] };
    }
  }
  return filteredToasts;
};

const dismissToast = (state, id) => {
  const newToasts = { ...state.toasts };
  delete newToasts[id];
  for (const [toastType, toastId] of Object.entries(state.singleToastIds)) {
    if (toastId === id) {
      delete state.singleToastIds[toastType];
      break;
    }
  }
  return {
    ...state,
    toasts: newToasts,
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_TOAST:
      if (!checkDuplicateToast(state, action)) {
        const newState = {
          ...state,
          toasts: {
            ...state.toasts,
            [state.nextToastId]: {
              toastType: action.toastType,
              length: action.length,
              message: action.message,
              position: action.position,
            },
          },
          nextToastId: state.nextToastId + 1,
        };
        if (SINGLE_TOAST_TYPES.has(action.toastType)) {
          // Store state.nextToastId (not newState)
          // which is the id of the toast we just added
          newState.singleToastIds[action.toastType] = state.nextToastId;
        }
        return newState;
      }
      return state;
    case UPDATE_DATASET_REQUEST:
    case END_FRONTEND_REPLAY_FAILURE:
    case END_FRONTEND_REPLAY_SUCCESS:
    case PAUSE_REPLAY:
    case SUBMIT_UTTERANCE_FAILURE: {
      const id = state.singleToastIds[TOAST_UPDATING_DATASET];
      // Loose equality to null also covers undefined
      if (id != null) {
        return dismissToast(state, id);
      }
      return state;
    }
    case DISMISS_TOAST:
      return dismissToast(state, action.id);
    case DISMISS_ALL_TOASTS:
      return {
        ...state,
        toasts: filterToasts(state),
      };
    default:
      return state;
  }
};
