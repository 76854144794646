import Stack from '@mui/material/Stack';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectQuestionRecommendations } from '../../../store/selectors/recommendations.selector';
import Recommendation from './Recommendation';
import { queueQuestion } from '../../../store/slices/dataAssistant.slice';
import {
  QuestionRecommendation,
  registerEngagementWithQuestionRecommendationRequest,
} from '../../../store/slices/recommendations.slice';

const RECO_MAX_SHOWN = 3;

const RecommendationList: React.FC = () => {
  const dispatch = useDispatch();
  const recommendations = useSelector(selectQuestionRecommendations);

  const submitRecommendation = (recommendation: QuestionRecommendation) => {
    dispatch(queueQuestion(recommendation.question));
    dispatch(registerEngagementWithQuestionRecommendationRequest({ recommendation }));
  };

  let body;
  if (recommendations && recommendations.length > 0) {
    body = recommendations.slice(0, RECO_MAX_SHOWN).map((rec) => {
      return (
        <Recommendation
          submitRecommendation={submitRecommendation}
          key={rec.question}
          recommendation={rec}
        />
      );
    });
  }

  return (
    <Stack direction="row" sx={{ gap: '8px', flexWrap: 'wrap' }}>
      {body}
    </Stack>
  );
};

export default RecommendationList;
