import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import styled from '@mui/material/styles/styled';
import React from 'react';
import '../../../components/ConnectionEditor/ConnectionEditor.scss';
import InfoTooltip from '../InfoTooltip';
import { getInputInfo } from '../connection';

export const getTFInfoProps = (
  field: { id: string; [key: string]: unknown },
  databaseType: string,
) => {
  let props = {};
  const info = getInputInfo(field, databaseType);
  if (info) {
    props = {
      endAdornment: (
        <InputAdornment position="end">
          <InfoTooltip text={info} />
        </InputAdornment>
      ),
    };
  }
  return props;
};

const CustomTextField = styled((props) => <TextField {...props} />)({
  '& label.Mui-focused': {
    color: 'var(--mui-palette-primary-main)',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '4px',
    '&.Mui-focused fieldset': {
      borderColor: 'var(--mui-palette-primary-main)',
    },
    '.Mui-error': {
      marginBottom: '0',
    },
    ':not(.Mui-error)': {
      marginBottom: '20px',
    },
  },
  '& .MuiInputLabel-asterisk': {
    color: 'var(--mui-palette-error-main)',
  },
});

export default CustomTextField;
