import axios, { AxiosResponse } from 'axios';
import { dcAppServiceEndpoints, endpoints } from '../constants/endpoints';
import { NavigationItemSource, NavigationItemStatus } from '../constants/session';
import { handleDCAppServiceCall } from '../utils/errorHandling/errorHandlers.api';

export type DatasetResponse = {
  dc_dataset_id: string;
  pipeliner_dataset_id: string;
  dc_session_id_fkey: string;
  status: NavigationItemStatus;
  name: string;
  version: number;
  source: NavigationItemSource;
};
export type DataspaceResponse = DatasetResponse[];

export const getDataspace = (
  accessToken: string,
  sessionID: string,
): Promise<AxiosResponse<DataspaceResponse>> =>
  axios.get(endpoints.sessionDatasets(sessionID), {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

export const updateDataset = (
  accessToken: string,
  sessionID: string,
  dcDatasetID: string,
  name?: string,
  status?: NavigationItemStatus,
): Promise<AxiosResponse<DatasetResponse[]>> =>
  axios.patch(
    endpoints.singleSessionDataset(sessionID, dcDatasetID),
    { name, status },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

export const postDataset = (
  accessToken: string,
  sessionID: string,
  pipelinerDatasetID: string,
  workflowID: number,
  name: string,
): Promise<AxiosResponse<DatasetResponse>> =>
  axios.post(
    endpoints.sessionDatasets(sessionID),
    {
      pipeliner_dataset_id: pipelinerDatasetID,
      fk_dc_saved_workflows: workflowID,
      status: 'hidden',
      name,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

/**
 * Requests DC App Service to create a dataset object named `objectName` from
 * an in-session dataset's `name` and `version`. The dataset object will be
 * backed by a trimmed recipe that recreates the in-session dataset.
 *
 * On success, a 200 OK response is returned with an empty body.
 *
 * Sends the following request:
 *
 * ```
 * POST /app/dataset?sessionId=<sessionId>
 * Authorization: Bearer <accessToken>
 *
 * {
 *    "dataset": { "name": <name>, "version": <version> },
 *    "objectName": <objectName>,
 *    "parentUUID": <parentUUID>
 * }
 * ```
 * @param accessToken user's access token
 * @param sessionId  id of the session that the in-sesssion dataset is present in
 * @param name name of the in-session dataset
 * @param version  version of the in-session dataset
 * @param objectName  desired dataset object name
 * @param objectUUID  uuid of the dataset object to update
 * @param parentUUID uuid of the parent folder to create the dataset object in
 */
export const postAppDataset = (
  accessToken: string,
  sessionId: string,
  name: string,
  version: number,
  objectName?: string,
  objectUUID?: string,
  parentUUID?: string,
): Promise<AxiosResponse> =>
  handleDCAppServiceCall(() =>
    axios.post(
      dcAppServiceEndpoints.dataset,
      { dataset: { name, version }, objectName, objectUUID, parentUUID },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
        params: { sessionId },
      },
    ),
  );

export const getCurrentDcDatasetId = (
  accessToken: string,
  sessionId: string,
): Promise<AxiosResponse<string>> =>
  handleDCAppServiceCall(() =>
    axios.get(dcAppServiceEndpoints.currentDcDatasetId, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: { sessionId },
    }),
  );
