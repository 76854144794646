import { push } from 'redux-first-history';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  deleteInsightsBoard,
  deleteInsightsBoardCharts,
  deleteInsightsBoardPublications,
  getInsightsBoardDcChartIDs,
  getInsightsBoardLayout,
  getSnapshotList,
  postAnnotation,
  postInsightsBoard,
  postPublicationCustomizations,
  putAnnotation,
  putInsightsBoard,
  putInsightsBoardItemFont,
} from '../../api/insights_board.api';
import { itemType, publishChartUtterance } from '../../constants/utterance_templates';
import { INSIGHTS_BOARD_ERRORS } from '../../pages/authenticated/InsightsBoardPage/constants';
import { createAlertChannelRequest } from '../actions/dialog.actions';
import {
  CREATE_ANNOTATION_REQUEST,
  CREATE_NEW_INSIGHTS_BOARD_REQUEST,
  DELETE_INSIGHTS_BOARD_PUBLICATIONS_FAILURE,
  DELETE_INSIGHTS_BOARD_PUBLICATIONS_REQUEST,
  EDIT_ANNOTATION_REQUEST,
  GET_INSIGHTS_BOARD_LAYOUT_REQUEST,
  GET_INSIGHTS_BOARD_PUBLICATION_LIBRARY_FAILURE,
  GET_SNAPSHOTS_REQUEST,
  PUBLISH_TO_INSIGHTS_BOARD_REQUEST,
  SAVE_PUBLICATION_CUSTOMIZATIONS_REQUEST,
  UPDATE_INSIGHTS_BOARD_ITEM_FONT_REQUEST,
  UPDATE_INSIGHTS_BOARD_REQUEST,
  closePublishMenu,
  createAnnotationFailure,
  createAnnotationSuccess,
  createNewInsightsBoardSuccess,
  deleteInsightsBoardPublicationsFailure,
  deleteInsightsBoardPublicationsSuccess,
  editAnnotationFailure,
  editAnnotationSuccess,
  getInsightsBoardLayoutFailure,
  getInsightsBoardLayoutSuccess,
  getSnapshotsFailure,
  getSnapshotsSuccess,
  publishToInsightsBoardRequest,
  savePublicationCustomizationsFailure,
  savePublicationCustomizationsSuccess,
  updateInsightsBoardFailure,
  updateInsightsBoardItemFontFailure,
  updateInsightsBoardItemFontSuccess,
  updateInsightsBoardSuccess,
} from '../actions/insights_board.actions';
import { sendMessageRequest } from '../actions/messages.actions';
import { deleteTableObjects } from '../actions/tableObject.actions';

import { paths } from '../../constants/paths';

import { selectSession } from '../selectors/session.selector';
import { selectAccessToken, selectInsightsBoardCustomizations } from './selectors';
import {
  duplicateNameErrorWorker,
  forbiddenRoleErrorWorker,
  generalInsightsBoardErrorWorker,
} from './utils/alert-channels';

// isFromHeader is an indicator for if the update request is from Header(i.e. changing IB name)
export function* updateInsightsBoardRequestWorker({ id, payload, isFromHeader }) {
  try {
    const accessToken = yield select(selectAccessToken);
    yield call(putInsightsBoard, accessToken, id, payload);
    yield put(updateInsightsBoardSuccess(payload, isFromHeader));
  } catch (error) {
    yield put(updateInsightsBoardFailure(error));
    if (error.response.status === 409) {
      yield* duplicateNameErrorWorker();
    }
  }
}

export function* publishToInsightsBoardRequestWorker({ ibName }) {
  const selectPublishChartType = (state) => state.insightsBoard.chartType;
  const selectPublishObjId = (state) => state.insightsBoard.objectId;
  const selectPublishDatasetName = (state) => state.insightsBoard.datasetName;
  const selectPublishDatasetVersion = (state) => state.insightsBoard.datasetVersion;
  const chartType = yield select(selectPublishChartType);
  const objId = yield select(selectPublishObjId);
  const datasetName = yield select(selectPublishDatasetName);
  const datasetVersion = yield select(selectPublishDatasetVersion);
  const sessionID = yield select(selectSession);

  yield put(
    sendMessageRequest({
      message: publishChartUtterance(
        itemType(chartType).toLowerCase(),
        objId,
        datasetName,
        datasetVersion,
        ibName,
      ),
      sessionID,
    }),
  );
  yield put(closePublishMenu());
}

export function* createNewInsightsBoardRequestWorker({ name, shouldGoToPage, shouldPublishItem }) {
  try {
    const accessToken = yield select(selectAccessToken);
    const insightsBoard = yield call(postInsightsBoard, accessToken, { name, layout_items: [] });
    yield put(createNewInsightsBoardSuccess());

    if (shouldPublishItem) {
      yield put(publishToInsightsBoardRequest({ ibName: name }));
    }

    // navigate to view page if shouldGoToPage is true
    if (shouldGoToPage) {
      const {
        data: { id },
      } = insightsBoard;
      const viewUrl = paths.insightsBoard(id);
      yield put(push(viewUrl)); // enable when new dashboard page is ready
      //
      // NOTE / TODO: We should be storing the newly created IB in the redux store
      // after creating it. the else statement is commented out as a bug fix,
      // but this code should be refactored so the redux state is up to date.
      // } else {
      //   const currentInsightsBoards = yield select(
      //     selectHomeScreenObjects,
      //     HOME_OBJECTS.INSIGHTS_BOARD,
      //   );
      //   yield put(
      //     getHomeScreenObjectsSuccess({
      //       data: [insightsBoard.data, ...currentInsightsBoards],
      //       objectType: HOME_OBJECTS.INSIGHTS_BOARD,
      //     }),
      //   );
      // }
    }
  } catch (error) {
    if (error.response.status === 409) {
      yield* duplicateNameErrorWorker();
    } else if (error.response.status === 403) {
      yield* forbiddenRoleErrorWorker();
    } else {
      yield put(createAlertChannelRequest({ error }));
    }
  }
}

/**
 * Helper function that attempts to delete insights board from database
 *
 * @param {String} id ID of insights board
 * @param {String} name Name of the insights board
 * @param {Number} owner Owner of the insights board
 */
export function* deleteInsightsBoardRequestHelper({ id, name }) {
  const accessToken = yield select(selectAccessToken);
  // have to get chart ids before insights board is deleted
  const { data } = yield call(getInsightsBoardDcChartIDs, accessToken, id);
  yield put(deleteTableObjects(name)); // Remove cached table formatting state
  yield call(deleteInsightsBoard, accessToken, id);
  deleteInsightsBoardCharts(accessToken, data);
}

/**
 * Sends a request to the server to update a particular
 * insights board publication.
 */
export function* updateInsightsBoardItemFontRequestWorker({
  insightsBoardId,
  publicationId,
  newFontScaling,
}) {
  try {
    const accessToken = yield select(selectAccessToken);
    yield call(putInsightsBoardItemFont, accessToken, {
      insightsBoardId,
      publicationId,
      newFontScaling,
    });
    yield put(updateInsightsBoardItemFontSuccess());
  } catch (error) {
    yield put(updateInsightsBoardItemFontFailure({ error }));
    yield put(createAlertChannelRequest({ error }));
  }
}

// Get the layout for an insights board
export function* getInsightsBoardLayoutWorker({ insightsBoardId }) {
  try {
    const accessToken = yield select(selectAccessToken);
    const { data } = yield call(getInsightsBoardLayout, accessToken, insightsBoardId);
    yield put(getInsightsBoardLayoutSuccess({ insightsBoardLayout: data }));
  } catch (error) {
    yield put(getInsightsBoardLayoutFailure({ error }));
    yield put(createAlertChannelRequest({ error }));
  }
}

// Get all snapshots for a user
export function* getSnapshotsWorker() {
  try {
    const accessToken = yield select(selectAccessToken);
    const { data } = yield call(getSnapshotList, accessToken);
    yield put(getSnapshotsSuccess({ snapshots: data }));
  } catch (error) {
    yield put(getSnapshotsFailure({ error }));
    yield put(createAlertChannelRequest({ error }));
  }
}

// Delete the requested publications, then get the updated layout
export function* deleteInsightsBoardPublicationsWorker({
  insightsBoardId,
  publicationIdList,
  callback,
}) {
  try {
    const accessToken = yield select(selectAccessToken);
    yield call(deleteInsightsBoardPublications, accessToken, insightsBoardId, publicationIdList);
    const {
      name,
      layout,
      layout_items: layoutItems, // rename from snake to camel case
    } = (yield call(getInsightsBoardLayout, accessToken, insightsBoardId)).data;
    // update the insightsBoard with the new layout
    yield call(putInsightsBoard, accessToken, insightsBoardId, { name, layout, layoutItems });
    if (callback) callback();
    yield put(deleteInsightsBoardPublicationsSuccess({ updatedLayout: { name, layoutItems } }));
  } catch (error) {
    yield put(deleteInsightsBoardPublicationsFailure({ error }));
    yield put(createAlertChannelRequest({ error }));
  }
}

export function* createAnnotationWorker({ insightsBoardId, text, color, callback }) {
  try {
    const accessToken = yield select(selectAccessToken);
    yield call(postAnnotation, accessToken, insightsBoardId, text, color);

    yield put(createAnnotationSuccess());
    if (callback) callback();
  } catch (error) {
    yield put(createAnnotationFailure({ error }));
  }
}

export function* editAnnotationWorker({ insightsBoardId, publicationId, text, color, callback }) {
  try {
    const accessToken = yield select(selectAccessToken);
    yield call(putAnnotation, accessToken, insightsBoardId, publicationId, text, color);

    yield put(editAnnotationSuccess());
    if (callback) callback();
  } catch (error) {
    yield put(editAnnotationFailure({ error }));
  }
}

export function* savePublicationCustomizationsWorker({ insightsBoardId, callback }) {
  try {
    const accessToken = yield select(selectAccessToken);
    const customizations = yield select(selectInsightsBoardCustomizations);

    yield call(postPublicationCustomizations, accessToken, insightsBoardId, customizations);

    yield put(savePublicationCustomizationsSuccess());
    if (callback) callback();
  } catch (error) {
    yield put(savePublicationCustomizationsFailure({ error }));
  }
}

// Error handler for getting IB publication menu content. Opens a dialog
export function* getIBPublicationLibraryFailureWorker({ error }) {
  yield call(generalInsightsBoardErrorWorker, error, INSIGHTS_BOARD_ERRORS.GET_IB_PUB_LIBRARY);
}

// Error handler for deleting IB publications from within an IB. Opens a dialog
export function* deleteIBPublicationsFailureWorker({ error }) {
  yield call(generalInsightsBoardErrorWorker, error, INSIGHTS_BOARD_ERRORS.DELETE_IB_PUBS);
}

export default function* apiWatcher() {
  yield takeLatest(CREATE_NEW_INSIGHTS_BOARD_REQUEST, createNewInsightsBoardRequestWorker);
  yield takeLatest(PUBLISH_TO_INSIGHTS_BOARD_REQUEST, publishToInsightsBoardRequestWorker);
  yield takeEvery(UPDATE_INSIGHTS_BOARD_REQUEST, updateInsightsBoardRequestWorker);
  yield takeLatest(GET_INSIGHTS_BOARD_LAYOUT_REQUEST, getInsightsBoardLayoutWorker);
  yield takeLatest(
    UPDATE_INSIGHTS_BOARD_ITEM_FONT_REQUEST,
    updateInsightsBoardItemFontRequestWorker,
  );
  yield takeLatest(GET_SNAPSHOTS_REQUEST, getSnapshotsWorker);
  yield takeLatest(
    DELETE_INSIGHTS_BOARD_PUBLICATIONS_REQUEST,
    deleteInsightsBoardPublicationsWorker,
  );
  yield takeLatest(CREATE_ANNOTATION_REQUEST, createAnnotationWorker);
  yield takeLatest(EDIT_ANNOTATION_REQUEST, editAnnotationWorker);
  yield takeLatest(SAVE_PUBLICATION_CUSTOMIZATIONS_REQUEST, savePublicationCustomizationsWorker);
  yield takeLatest(
    GET_INSIGHTS_BOARD_PUBLICATION_LIBRARY_FAILURE,
    getIBPublicationLibraryFailureWorker,
  );
  yield takeLatest(DELETE_INSIGHTS_BOARD_PUBLICATIONS_FAILURE, deleteIBPublicationsFailureWorker);
}
