import isEmpty from 'lodash/isEmpty';
import { createSelector } from 'reselect';
import { RootState } from '../../configureStore';
import { VISUAL_TYPES } from '../../constants';
import { Message, Node } from '../slices/nodes.slice';

export const selectNodes = (state: RootState) => state.nodes.nodes;
export const selectCurrentNodeId = (state: RootState) => state.nodes.currentNodeId;
export const selectCurrentNodeOffset = (state: RootState) => state.nodes.currentNodeOffset;
export const selectCurrentThread = (state: RootState) => state.nodes.currentThread;

export const selectCurrentNodes = createSelector(
  [selectNodes, selectCurrentThread],
  (nodes, currentThread) => {
    if (currentThread.length === 0 || isEmpty(nodes)) {
      return [];
    }
    const nodesFromThread: Node[] = [];
    currentThread.forEach((nodeId) => {
      if (nodes[nodeId]) nodesFromThread.push(nodes[nodeId]);
    });
    return nodesFromThread;
  },
);

/**
 * Selects all of the messages from the current nodes
 * that are of type viz, table, pivot, or tabvisual
 */
export const selectCurrentNodeMessages = createSelector([selectCurrentNodes], (currentNodes) => {
  // Exclude text & note messages from the response
  const filteredTypes = [
    VISUAL_TYPES.TABLE,
    VISUAL_TYPES.VIZ,
    VISUAL_TYPES.PIVOT,
    VISUAL_TYPES.TABVISUAL,
  ];

  return currentNodes
    .map((node) => {
      const messages = node.messages.filter((m: Message) => filteredTypes.includes(m.type));
      const tabvisualMessages = node.messages.filter(
        (m: Message) => m.type === VISUAL_TYPES.TABVISUAL,
      );

      // Extract and flatten tab_contents from tabvisual messages
      const tabContents = tabvisualMessages.map((m) => m.data.tab_contents).flat();
      const tabContentMessages = tabContents.reduce(
        (acc, tabContent) => [...acc, ...Object.values(tabContent)],
        [],
      );

      return [...messages, ...tabContentMessages];
    })
    .flat();
});

export const selectMessageByObjectId = createSelector(
  [selectCurrentNodeMessages, (_state: RootState, objectId: string) => objectId],
  (messages: Message[], objectId: string) => {
    return messages.find(
      (message: Message) =>
        `${message.additional_info?.dc_chart_id}` === objectId ||
        `${message.additional_info?.dc_dataset_id}` === objectId ||
        `${message.object_id}` === objectId,
    );
  },
);

export const selectNode = createSelector(
  [selectNodes, (_state: RootState, nodeId: string) => nodeId],
  (nodes, nodeId) => nodes[nodeId],
);

export const selectNodeHasMessages = createSelector([selectNode], (node) => {
  return node?.messages?.length > 0;
});
