/* eslint-disable react/jsx-boolean-value */
import Grid from '@mui/material/Grid';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { FormikProps } from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';
import { OAuthContextType } from '../../../components/OAuthButtons/oauth.types';
import { selectIsEditMode, selectUserConfig } from '../../../store/sagas/selectors';
import { selectGoogleClientID } from '../../../store/selectors/config.selector';
import {
  selectSnowflakeOAuthAccountName,
  selectSnowflakeOAuthClientID,
} from '../../../store/selectors/oauth.selector';
import { FeatureTypes } from '../../../types/feature.types';
import { IntegrationProvider } from '../../../types/integrations.types';
import useOAuthButton from '../../hooks/useOAuthButton.hook';
import { capitalizeFirstLetter } from '../../string';
import { hasFeatureEnabled } from '../../userconfig_selector';

export type OAuthToggleField = {
  id: string;
  type: string;
  provider: IntegrationProvider;
};

const OAuthToggle: React.FC<{
  field: OAuthToggleField;
  formik: Partial<FormikProps<any>>;
}> = ({ field, formik }) => {
  const { provider } = field;
  const isEdit = useSelector(selectIsEditMode);
  const { connected, handleAuth, name } = useOAuthButton(provider, {
    context: OAuthContextType.DB_BROWSER,
    databaseProvider: provider,
    closeOnRedirect: true,
  });

  const { values, setFieldValue } = formik;
  const value = values[field.id] ?? false;
  const userConfig = useSelector(selectUserConfig);

  // Get OAuth configuration values
  const googleClientID = useSelector(selectGoogleClientID);
  const snowflakeClientID = useSelector(selectSnowflakeOAuthClientID);
  const snowflakeAccountName = useSelector(selectSnowflakeOAuthAccountName);

  // Check if OAuth is properly configured
  const isOAuthConfigured =
    provider === IntegrationProvider.BIGQUERY
      ? googleClientID.isSet
      : snowflakeClientID && snowflakeAccountName;

  const handleChange = (newValue: boolean | null) => {
    if (!setFieldValue) return;
    // Prevent null values, maintain current value if null is received
    const valueToSet = newValue === null ? value : newValue;
    setFieldValue(field.id, valueToSet);
    if (!connected && valueToSet) {
      handleAuth();
    }
  };

  // String to display in the toggle button
  const capitalizedProvider = capitalizeFirstLetter(name);
  const useOAuthString = connected
    ? `Use ${capitalizedProvider} OAuth`
    : `Connect to ${capitalizedProvider}`;

  // Hide toggle if feature is disabled
  if (!hasFeatureEnabled(userConfig, FeatureTypes.DatabaseOAuth)) {
    return null;
  }

  // Hide toggle if OAuth is not configured and:
  // 1. This is a new connection creation, or
  // 2. This is editing an existing connection and service account is selected
  if (!isOAuthConfigured && (!isEdit || !value)) {
    return null;
  }

  return (
    <Grid key={field.id} item xs={12} sm={6}>
      <ToggleButtonGroup
        color="primary"
        value={value}
        exclusive
        fullWidth
        onChange={(_, val) => handleChange(val)}
        sx={{ height: 56 }}
      >
        <ToggleButton key="service-account" value={false}>
          Service Account
        </ToggleButton>
        <ToggleButton key="oauth" value={true}>
          {useOAuthString}
        </ToggleButton>
      </ToggleButtonGroup>
    </Grid>
  );
};

export default OAuthToggle;
