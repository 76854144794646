/**
 * This action determines whether the server is waiting
 * for a response from the user
 */

export const SERVER_WAITING = 'serverStatus/SERVER_WAITING';
export const SERVER_IDLE = 'serverStatus/SERVER_IDLE';

export const serverIsWaiting = () => ({
  type: SERVER_WAITING,
});

export const serverIsIdle = () => ({
  type: SERVER_IDLE,
});
