import {
  DRILL_THROUGH_FAILURE,
  DRILL_THROUGH_SUCCESS,
  EXIT_DRILL_THROUGH,
  TRIGGER_DRILL_THROUGH,
} from '../actions/drill_through.actions';

export const initialState = {
  isDrillingThrough: false,
  drillThroughSpec: '',
};

/**
 * @param {Object} state
 * @param {Object} action
 * @returns {Object} new state
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case TRIGGER_DRILL_THROUGH:
      return {
        ...state,
        isDrillingThrough: true,
        drillThroughSpec: action.drillThroughSpec,
      };
    case EXIT_DRILL_THROUGH:
    case DRILL_THROUGH_FAILURE:
      return {
        ...state,
        isDrillingThrough: false,
        drillThroughSpec: '',
      };
    case DRILL_THROUGH_SUCCESS:
      return state;
    default:
      return state;
  }
};
