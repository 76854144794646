// This file contains constants used in the ChartBuilder component
import React from 'react';
import {
  AggregateExpressions,
  ChartTypes,
  aggregateExpressionsDisplay,
  binMethodNames,
} from 'translate_dc_to_echart';
import { InputType } from '../../../echart-library/types/chartBuilderIO';
import Icon from '../../ChatPanel/ChatContext/BubbleComponent/Icon';

// Bin methods in array format
export const binMethodsArr = [
  binMethodNames.percentile,
  binMethodNames.count,
  binMethodNames.size,
  binMethodNames.boundaries,
];

// Standard title for the histogram y-axis which can be overridden by the user
export const HISTOGRAM_Y_AXIS_TITLE = 'Frequency';
// The maximum number of bins that can be displayed in a histogram
export const MAX_BIN_COUNT = 500;
/**
 * Under this threshold, we will change numeric bins to strings.
 * Similar functionality is called LOW_CARDINALITY_THRESHOLD on the backend,
 * But we don't have a good way to get the cardinality of a column in the chart builder.
 * So we just validate that the number of bins is less than the threshold
 */
export const BIN_FORMATTING_THRESHOLD = 10;
// Bin methods where the binning interval is the same as the output bin count
export const BIN_METHODS_TESTABLE_BY_INTERVAL = [binMethodNames.count, binMethodNames.percentile];

/**
 * Returns the chart builder's aggregated inputs for each chart type
 * @param {String} chartType The type of chart
 * @returns {Array} The list of aggregated inputs
 */
export const getAggregatedInputs = (chartType) => {
  switch (chartType) {
    case ChartTypes.scatter:
    case ChartTypes.line:
    case ChartTypes.stackedBar:
    case ChartTypes.bubble:
    case ChartTypes.stackedArea:
      return [InputType.y];
    case ChartTypes.bar:
      return [InputType.y, InputType.overlay];
    case ChartTypes.horizBar:
      return [InputType.x, InputType.overlay];
    case ChartTypes.heatmap:
      return [InputType.density];
    case ChartTypes.donut:
      return [InputType.sliceSize];
    case ChartTypes.singleMetric:
      return [InputType.column];
    case ChartTypes.histogram:
      return [InputType.y];
    default:
      return [];
  }
};

/**
 * Gets the columns that exist in the aggregated field inputs
 * @param {String} chartType Type of the chart
 * @param {Object} fields The input fields key/value pairs from the chart builder
 * @returns {Array} The aggregated column names
 */
export const getAggregatedColumns = (chartType, fields) => {
  const aggregatedInputs = getAggregatedInputs(chartType);
  const aggregatedColumns = new Set();

  // Iterate through the input fields, adding the values (columns) from
  // each aggregated input
  for (const [inputName, colNames] of Object.entries(fields)) {
    if (aggregatedInputs.includes(inputName)) {
      colNames.forEach(aggregatedColumns.add, aggregatedColumns);
    }
  }

  return Array.from(aggregatedColumns);
};

/**
 * Gets the binned inputs for the chart type
 * @param {String} chartType The type of chart
 * @returns {Array} The list of binned inputs
 */
export const getBinnedInputs = (chartType) => {
  switch (chartType) {
    case ChartTypes.scatter:
    case ChartTypes.line:
    case ChartTypes.stackedArea:
    case ChartTypes.bar:
      return [InputType.x, InputType.group, InputType.subplot, InputType.slider];
    case ChartTypes.stackedBar:
      return [
        InputType.x,
        InputType.group,
        InputType.subplot,
        InputType.slider,
        InputType.partition,
      ];
    case ChartTypes.bubble:
      return [InputType.x, InputType.subplot, InputType.slider, InputType.color];
    case ChartTypes.horizBar:
      return [InputType.y, InputType.group, InputType.subplot, InputType.slider];
    case ChartTypes.heatmap:
      return [InputType.x, InputType.y, InputType.subplot, InputType.slider];
    case ChartTypes.donut:
      return [InputType.split, InputType.subplot, InputType.slider];
    case ChartTypes.violin:
    case ChartTypes.box:
    case ChartTypes.histogram:
      return [InputType.x, InputType.subplot, InputType.slider];
    case ChartTypes.singleMetric:
    default:
      return [];
  }
};

// The input placeholder for each CB Bin interval type, based on the corresponding method
export const intervalPlaceholderByMethod = {
  Percentile: 'Number of intervals',
  'Width by Interval Count': 'Number of intervals',
  'Width by Interval Size': 'Interval size',
  Boundaries: 'Boundary values separated by commas',
  default: 'Number of intervals',
  '': 'Number of intervals',
  null: 'Number of intervals',
};

// Default menu dropdown opened/closed state
export const defaultMenuDropdownState = {
  RequiredFields: true,
  OptionalFields: false,
  SampleLimit: false,
  Bin: false,
  Filter: false,
  Customize: false,
};

// Default footer dropdown opened/closed state
export const defaultFooterDropdownState = {
  DataSample: false,
  Describe: false,
};

// User-facing dropdown titles for each component in the chart builder menu
export const dropdownTitles = {
  RequiredFields: 'Required Fields',
  OptionalFields: 'Optional Fields',
  SampleLimit: 'Dataset Sample',
  Bin: 'Bin',
  Filter: 'Filter',
  Customize: 'Customize',
  DataSample: 'Data Sample',
  Describe: 'Describe',
};

/**
 * Chart types that support aggregation
 */
export const supportAggregateChartTypes = [
  ChartTypes.scatter,
  ChartTypes.bar,
  ChartTypes.bubble,
  ChartTypes.line,
  ChartTypes.horizBar,
  ChartTypes.heatmap,
  ChartTypes.stackedArea,
  ChartTypes.stackedBar,
  ChartTypes.donut,
  ChartTypes.singleMetric,
];

/**
 * General information for mapping the chart information for each chart type.
 * Serves as a source of truth for the chart types that are supported in the chart builder.
 */
export const iconMapping = {
  [ChartTypes.bar]: {
    icon: <Icon iconType="bar" />,
    name: 'Bar Chart',
    type: ChartTypes.bar,
    description:
      'Presents categorical data with rectangular bars. The length of each bar represents the value and can be differentiated by color.',
    required: [InputType.x, InputType.y],
    optional: [InputType.overlay, InputType.group, InputType.subplot, InputType.slider],
  },
  [ChartTypes.stackedBar]: {
    icon: <Icon iconType="stacked_bar" />,
    name: 'Stacked Bar Chart',
    type: ChartTypes.stackedBar,
    description: 'Short description of chart type',
    required: [InputType.x, InputType.y, InputType.partition],
    optional: [InputType.subplot, InputType.slider],
  },
  [ChartTypes.scatter]: {
    icon: <Icon iconType="scatter" />,
    name: 'Scatter Chart',
    type: ChartTypes.scatter,
    description: 'Short description of chart type',
    required: [InputType.x, InputType.y],
    optional: [InputType.group, InputType.subplot, InputType.slider, InputType.label],
  },
  [ChartTypes.bubble]: {
    icon: <Icon iconType="bubble" />,
    name: 'Bubble Chart',
    type: ChartTypes.bubble,
    description: 'Short description of chart type',
    required: [InputType.x, InputType.y, InputType.bubbleSize],
    optional: [InputType.color, InputType.subplot, InputType.slider, InputType.label],
  },
  [ChartTypes.line]: {
    icon: <Icon iconType="line" />,
    name: 'Line Chart',
    type: ChartTypes.line,
    description: 'Short description of chart type',
    required: [InputType.x, InputType.y],
    optional: [InputType.group, InputType.subplot, InputType.slider, InputType.label],
  },
  [ChartTypes.donut]: {
    icon: <Icon iconType="pie" />,
    name: 'Donut Chart',
    type: ChartTypes.donut,
    required: [InputType.split, InputType.sliceSize],
    optional: [InputType.subplot, InputType.slider],
  },
  [ChartTypes.histogram]: {
    icon: <Icon iconType="histogram" />,
    name: 'Histogram',
    type: ChartTypes.histogram,
    description: 'Short description of chart type',
    required: [InputType.x],
    optional: [InputType.subplot, InputType.slider],
  },
  [ChartTypes.violin]: {
    icon: <Icon iconType="violin" />,
    name: 'Violin Chart',
    type: ChartTypes.violin,
    description: 'Short description of chart type',
    required: [InputType.x, InputType.y],
    optional: [InputType.split, InputType.subplot, InputType.slider],
  },
  [ChartTypes.box]: {
    icon: <Icon iconType="box" />,
    name: 'Box Chart',
    type: ChartTypes.box,
    description: 'Short description of chart type',
    required: [InputType.x, InputType.y],
    optional: [InputType.subplot, InputType.slider],
  },
  [ChartTypes.horizBar]: {
    icon: <Icon iconType="horizontal_bar" />,
    name: 'Horizontal Bar Chart',
    type: ChartTypes.horizBar,
    description: 'Short description of chart type',
    required: [InputType.x, InputType.y],
    optional: [InputType.overlay, InputType.group, InputType.subplot, InputType.slider],
  },
  [ChartTypes.heatmap]: {
    icon: <Icon iconType="heatmap" />,
    name: 'Heatmap Chart',
    type: ChartTypes.heatmap,
    description: 'Short description of chart type',
    required: [InputType.x, InputType.y, InputType.density],
    optional: [InputType.subplot, InputType.slider],
  },
  [ChartTypes.stackedArea]: {
    icon: <Icon iconType="stack" />,
    name: 'Stacked Area Chart',
    type: ChartTypes.stackedArea,
    description: 'Short description of chart type',
    required: [InputType.x, InputType.y],
    optional: [InputType.group, InputType.subplot, InputType.slider],
  },
  // [ChartTypes.ridgeline]: {
  //   icon: <Icon iconType="ridgeline" />,
  //   name: 'Ridgeline Chart',
  //   type: ChartTypes.ridgeline,
  //   description: 'Short description of chart type',
  //   required: [InputType.x, InputType.y],
  //   optional: [InputType.split, InputType.subplot, InputType.slider],
  // },
  [ChartTypes.singleMetric]: {
    icon: <Icon iconType="single_metric" />,
    name: 'Single Metric Chart',
    type: ChartTypes.singleMetric,
    description: 'Short description of chart type',
    required: [InputType.column],
  },
  /*
  [ChartTypes.barLine]: {
    icon: <Icon iconType="bar_line" />,
    name: 'Bar-Line Chart',
    type: ChartTypes.barLine,
    description: 'Short description of chart type',
    required: [InputType.x, 'y-bar', 'y-line'],
    optional: [InputType.group, InputType.subplot, InputType.slider],
  },
  [ChartTypes.scatterLine]: {
    icon: <Icon iconType="scatter_line" />,
    name: 'Scatter-Line Chart',
    type: ChartTypes.scatterLine,
    description: 'Short description of chart type',
    required: [InputType.x, 'y-line', 'y-scatter'],
    optional: [InputType.group, InputType.subplot, InputType.slider, InputType.label],
  },
  [ChartTypes.bubbleLine]: {
    icon: <Icon iconType="bubble_line" />,
    name: 'Bubble-Line Chart',
    type: ChartTypes.bubbleLine,
    description: 'Short description of chart type',
    required: [InputType.x, 'y-line', 'y-bubble', InputType.group],
    optional: ['size', InputType.slider],
  },
  /*
  geo: {
    icon: <Icon iconType="geo" />,
    name: 'Geo Chart',
    type: 'geo',
    description: 'Short description of chart type',
    required: '',
    optional: '',
  },
  tree: {
    icon: <Icon iconType="tree" />,
    name: 'Tree Chart',
    description: 'Short description of chart type',
    required: '',
    optional: '',
  },
  */
};

// TODO: Use DCColTypes instead and remove this
export const COLUMN_TYPES = {
  BOOLEAN: 'boolean',
  DATE: 'date',
  FLOAT: 'float',
  INTEGER: 'integer',
  INTERVAL: 'interval',
  NUMBER: 'number',
  STRING: 'string',
  TIME: 'time',
  TIMESTAMP: 'timestamp',
  JSON: 'json',
};

export const OTHER_TYPES = {
  NUMERIC: 'float',
  EXPRESSION: 'expression',
};

/**
 * Get the predicates (keys) and how many inputs they expect (values)
 * @param {string} columnType type of the current column
 * @returns {Object} key-value pairs with predicate: number of inputs
 */
export const getFilterPredicateValueCountMap = (columnType) => {
  columnType = columnType?.toLowerCase();

  const common = {
    'is equal to': 1,
    'is not equal to': 1,
    'is null': 0,
    'is not null': 0,
  };

  switch (columnType) {
    case COLUMN_TYPES.BOOLEAN:
      return { ...common };
    case COLUMN_TYPES.FLOAT:
    case COLUMN_TYPES.INTEGER:
    case COLUMN_TYPES.NUMBER:
      return {
        ...common,
        'is between the values': 2,
        'is not between the values': 2,
        'is greater than': 1,
        'is greater than or equal to': 1,
        'is less than': 1,
        'is less than or equal to': 1,
      };
    case COLUMN_TYPES.STRING:
      return {
        ...common,
        contains: 1,
        'does not contain': 1,
        'does not end with': 1,
        'does not start with': 1,
        'ends with': 1,
        // 'is not one of': 1, // FIXME - var length list
        // 'is one of': 1,
        'matches the pattern': 1,
        // FIXME: with the var length list above, we can accommodate the DC-English style RegEx (see below)
        // 'matches a pattern containing': 1,
        // 'matches a pattern not starting with': 1,
        // 'matches a pattern starting with': 1,
        'starts with': 1,
      };
    default:
      // FIXME DATES
      // return {
      //   'is between the dates': null,
      //   'is not between the dates': null,
      //   'is after': null,
      //   'is before': null,
      //   'is null': null,
      //   'is not null': null,
      //   'is in the last': null,
      //   'is not in the last': null,
      //   'is equal to': null,
      //   'is not equal to': null,
      //   'is on or after': null,
      //   'is on or before': null,
      // }
      return {};
  }
};

/**
 * Get the predicates (keys) and if the values should use autocomplete
 * @param {string} columnType type of the current column
 * @returns {Object} key-value pairs with predicate: should use autocomplete
 */
export const shouldExprUseACIfPossible = (columnType) => {
  columnType = columnType?.toLowerCase();

  const common = {
    'is equal to': true,
    'is not equal to': true,
    'is null': false,
    'is not null': false,
  };

  switch (columnType) {
    case COLUMN_TYPES.BOOLEAN:
      return { ...common };
    case COLUMN_TYPES.FLOAT:
    case COLUMN_TYPES.INTEGER:
    case COLUMN_TYPES.NUMBER:
      return {
        ...common,
        'is between the values': true,
        'is not between the values': true,
        'is greater than': true,
        'is greater than or equal to': true,
        'is less than': true,
        'is less than or equal to': true,
      };
    case COLUMN_TYPES.STRING:
      return {
        ...common,
        contains: false,
        'does not contain': false,
        'does not end with': false,
        'does not start with': false,
        'ends with': false,
        // 'is not one of': 1, // FIXME - var length list
        // 'is one of': 1,
        'matches the pattern': false,
        // FIXME: with the var length list above, we can accommodate the DC-English style RegEx (see below)
        // 'matches a pattern containing': false,
        // 'matches a pattern not starting with': false,
        // 'matches a pattern starting with': false,
        'starts with': false,
      };
    case COLUMN_TYPES.DATE:
      // FIXME: Not used yet, dates not implemented
      // Dates use a date picker so no AC necessary
      return {
        ...common,
        'is between the dates': false,
        'is not between the dates': false,
        'is after': false,
        'is before': false,
        'is in the last': false,
        'is not in the last': false,
        'is on or after': false,
        'is on or before': false,
      };
    default:
      return {};
  }
};

/**
 * Filter types that are not supported in the chart builder
 */
export const disallowedFilterTypes = [COLUMN_TYPES.DATE, COLUMN_TYPES.TIME, COLUMN_TYPES.TIMESTAMP];

/**
 * Function for generating the error label for font size input field in chart builder
 * @param {number} lo lower font size bound
 * @param {number} hi higher font size bound
 * @returns {string} the error label
 */
export const fontSizeInputErrorMessage = (lo = 8, hi = 32) => {
  return `must be between ${lo}~${hi}`;
};

/**
 * Computes the groupBy columns for a given chart type
 * @param {string} chartType the type of chart
 * @returns {Array} the list of groupBy columns
 */
export const aggregateGroupBy = (chartType) => {
  switch (chartType) {
    case ChartTypes.scatter:
    case ChartTypes.line:
    case ChartTypes.stackedArea:
    case ChartTypes.bar:
      return [InputType.x, InputType.group, InputType.subplot, InputType.slider];
    case ChartTypes.stackedBar:
      return [
        InputType.x,
        InputType.group,
        InputType.subplot,
        InputType.slider,
        InputType.partition,
      ];
    case ChartTypes.bubble:
      return [
        InputType.x,
        InputType.subplot,
        InputType.slider,
        InputType.color,
        InputType.bubbleSize,
      ];
    case ChartTypes.horizBar:
      return [InputType.y, InputType.group, InputType.subplot, InputType.slider];
    case ChartTypes.heatmap:
      return [InputType.x, InputType.y, InputType.subplot, InputType.slider];
    case ChartTypes.donut:
      return [InputType.split, InputType.subplot, InputType.slider];
    case ChartTypes.histogram:
      return [InputType.x, InputType.subplot, InputType.slider];
    case ChartTypes.singleMetric:
    default:
      return [];
  }
};

// Convert the display names back to the aggregate expressions
export const aggregateDisplayToExpressions = Object.entries(aggregateExpressionsDisplay).reduce(
  (acc, [key, value]) => {
    acc[value] = key;
    return acc;
  },
  {},
);

// Shorthand aggregate names for display within the chart builder inputs
export const shorthandAggregates = {
  [AggregateExpressions.none]: 'NONE',
  [AggregateExpressions.min]: 'MIN',
  [AggregateExpressions.max]: 'MAX',
  [AggregateExpressions.average]: 'AVG',
  [AggregateExpressions.total]: 'TOT',
  [AggregateExpressions.count]: 'CT',
  [AggregateExpressions.countOfRecords]: 'CT_RECORDS',
};

// Types that we consider numeric
export const numericColumnTypes = [COLUMN_TYPES.FLOAT, COLUMN_TYPES.INTEGER, COLUMN_TYPES.NUMBER];
// Types that we consider non-numeric
export const nonNumericColumnTypes = [
  COLUMN_TYPES.BOOLEAN,
  COLUMN_TYPES.DATE,
  COLUMN_TYPES.INTERVAL,
  COLUMN_TYPES.STRING,
  COLUMN_TYPES.TIME,
  COLUMN_TYPES.TIMESTAMP,
];

/**
 * Determines the default aggregate for a given chart type
 * @param {String} chartType The type of chart
 * @param {Object} fields The input:value pairs from the chart builder menu
 * @param {String} columnType The type of the aggregated column
 * @param {Number} rowCount The number of rows in the chart builder
 * @returns {String} The default aggregate for that chart type
 */
export const defaultAggregate = (chartType, fields, columnsTypes, rowCount) => {
  // If our dataset has only 1 row, there is nothing to aggregate
  if (rowCount === 1) return AggregateExpressions.none;

  // If we have input fields and columnType information, try to determine
  // if every aggregated column is numeric.
  // This allows us to more intelligently select the default aggregate.
  let isNumeric;
  if (fields && columnsTypes) {
    const aggregatedColumns = getAggregatedColumns(chartType, fields);
    isNumeric = aggregatedColumns.every((name) =>
      numericColumnTypes.includes(
        columnsTypes.find((col) => col.name === name)?.type?.toLowerCase(),
      ),
    );
  }

  switch (chartType) {
    case ChartTypes.heatmap:
    case ChartTypes.line:
    case ChartTypes.bar:
    case ChartTypes.stackedBar:
    case ChartTypes.horizBar:
      return AggregateExpressions.average;
    case ChartTypes.stackedArea:
      return AggregateExpressions.total;
    case ChartTypes.donut:
    case ChartTypes.histogram:
      return AggregateExpressions.countOfRecords;
    case ChartTypes.scatter:
    case ChartTypes.bubble:
    case ChartTypes.singleMetric:
      return isNumeric ? AggregateExpressions.average : AggregateExpressions.count;
    case ChartTypes.violin:
    case ChartTypes.box:
      return AggregateExpressions.none;
    default:
      return AggregateExpressions.none;
  }
};

/**
 * Aggregate suggestions when the dependent column is numeric
 */
export const numericColumnAggregates = [
  AggregateExpressions.none,
  AggregateExpressions.min,
  AggregateExpressions.max,
  AggregateExpressions.average,
  AggregateExpressions.total,
  AggregateExpressions.count,
  AggregateExpressions.countOfRecords,
];

/**
 * Aggregate suggestions when dependent column is non-numeric
 */
export const nonNumericColumnAggregates = [
  AggregateExpressions.none,
  AggregateExpressions.count,
  AggregateExpressions.countOfRecords,
];

// Aggregate options when the chart type is a histogram
export const histogramAggregates = [AggregateExpressions.countOfRecords];

/**
 * Chart types which are aggregated by the aggregate spec instead of the translation layer
 */
export const manualAggregateByDefaultChartTypes = ['line', 'heatmap'];

// Warn the user that the dataset may take a while to load when we have too many cells
export const CHART_BUILDER_SAMPLE_LIMIT = 5000;
export const SAMPLE_LIMITS_CHART_BUILDER = [5000, 10000, 15000, 25000, 50000];

// Offset x/y in order to position the floating textbox over the text/annotation that is being edited
export const OFFSET_TEXTBOX_VERTICAL = 60;
export const OFFSET_TEXTBOX_HORIZONTAL = 14;

// mui textfield padding top/bottom and padding left/right
export const MUI_TEXTBOX_PADDING_TOP = 12;
export const MUI_TEXTBOX_PADDING_LEFT = 13;

/**
 * annotation coordinate upper boundary(i.e. option.axis.max)
 */
export const ANNOTATION_COORDINATE_UPPER = 1000;

/**
 * the constant factor for converting pixel to point
 */
export const PIXEL_TO_POINT_FACTOR = 0.75;

/**
 * textbox width offset when editing a text or annotation
 */
export const ANNOTATION_TEXTBOX_OFFSET = 55;

export const SAMPLING_ALL_VALUES_PHRASE = 'All Values';

export const AXIS_MIN_EXPRESSION = 'is greater than or equal to';

export const AXIS_MAX_EXPRESSION = 'is less than or equal to';

export const AXIS_RANGE_EXPRESSION = 'is between the values';

// Message to display when the chart builder's data loading is complete
export const LOADING_COMPLETE_MESSAGE = 'Complete the required fields to plot your chart.';

// Error messages for the chart builder's binning inputs
export const NUMBER_ERROR = 'Must be a number';
export const POSITIVE_NUMBER_ERROR = 'Must be a positive number';
export const POSITIVE_INTEGER_ERROR = 'Must be a positive integer greater than 1';
export const BOUNDARY_ERROR = 'Invalid boundaries';
export const INVALID_VALUE = 'Invalid value';
export const BIN_COUNT_ERROR = `Must be less than or equal to ${MAX_BIN_COUNT}`;
