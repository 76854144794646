import { SeriesGroupKeys, SeriesMarkKeys } from 'translate_dc_to_echart';
import ChartModules from '../modules/ChartModules';
import { InputType } from '../types/chartBuilderIO';
import {
  disallowWithMultiXAxis,
  isNumeric,
  maximumOfOneColumnWithGroup,
  notDuplicatedWithOverlay,
  notDuplicatedWithXAxis,
  notDuplicatedWithYAxis,
} from '../utils/columnSelection';
import ChartType from './ChartType';

export default class HorizontalBar extends ChartType {
  static fieldGenMapping = {
    mark: {
      [SeriesMarkKeys.x]: InputType.x,
      [SeriesMarkKeys.y]: InputType.y,
      [SeriesMarkKeys.overlay]: InputType.overlay,
      [SeriesMarkKeys.color]: InputType.group,
    },
    group: {
      [SeriesGroupKeys.subplot]: InputType.subplot,
      [SeriesGroupKeys.slider]: InputType.slider,
    },
  };

  static columnFilters = {
    [InputType.x]: [
      notDuplicatedWithYAxis,
      notDuplicatedWithOverlay,
      maximumOfOneColumnWithGroup,
      isNumeric,
    ],
    [InputType.y]: [notDuplicatedWithXAxis, notDuplicatedWithOverlay],
    [InputType.overlay]: [disallowWithMultiXAxis, notDuplicatedWithXAxis, notDuplicatedWithYAxis],
    [InputType.group]: [disallowWithMultiXAxis],
  };

  constructor() {
    super(new ChartModules(HorizontalBar.columnFilters, HorizontalBar.fieldGenMapping));
  }
}
