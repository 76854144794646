import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { SvgIconProps } from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import './ActionIndicator.scss';

export type HoverAction = {
  icon: React.ComponentType<SvgIconProps>;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  tooltip: string;
  primary?: boolean;
};

interface ActionIndiatorProps {
  actions?: HoverAction[];
}

const ActionIndicator: React.FC<ActionIndiatorProps> = ({ actions = [] }) => {
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <Box
      onMouseLeave={() => setIsHovered(false)}
      onMouseEnter={() => setIsHovered(true)}
      className="CellActionIndiator"
      data-testid="CellActionIndiator"
    >
      {isHovered ? (
        actions.map((action) => (
          <Tooltip
            className="CellActionIndiator-Button"
            title={action.tooltip}
            key={action.tooltip}
          >
            <IconButton
              size="small"
              data-testid={action.tooltip}
              key={action.tooltip}
              onClick={action.onClick}
            >
              <action.icon className={action.primary ? 'primary' : ''} />
            </IconButton>
          </Tooltip>
        ))
      ) : (
        <Box className="CellActionIndiator-Indicator" />
      )}
    </Box>
  );
};

export default ActionIndicator;
