import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { scrollToUserMessage } from '../../../utils/utterances';

/**
 *  A general container component to wrap header for visuals
 */
const VisualHeaderContainer = (props) => {
  const {
    children,
    messageType,
    isSelecting,
    isInsightsBoard,
    isDragInProgress,
    chartEditingMode,
    clickToScroll,
    userMsgId,
  } = props;
  const [showHeader, setShowHeader] = useState(true);

  // Get the 'showHeader' queryParam. If it doesn't exist, then set it to true
  // It should only be explicitly false in the 'Copy link to clipboard' window
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const queryShowHeader = queryParams.get('showHeader');
    setShowHeader(queryShowHeader === null);
  }, []);

  /**
   * Returns the appropriate className for the chart header
   */
  const chartHeaderClassName = `ChartHeader${
    messageType === 'note' ||
    messageType === 'annotation' ||
    (messageType === 'table' && isInsightsBoard) ||
    (messageType === 'viz' && isInsightsBoard)
      ? ' note-header'
      : ''
  }${
    isInsightsBoard
      ? isDragInProgress
        ? ' InsightsBoard-Chart-Header-Dragging'
        : ' InsightsBoard-Chart-Header'
      : ''
  }${
    !isSelecting &&
    !chartEditingMode &&
    (messageType === 'note' || (isInsightsBoard && messageType === 'annotation'))
      ? ' hidden'
      : ''
  }`;

  /**
   * Scrolls to the corresponding user message bubble in the Chat Panel.
   */
  const linkToUserMsg = () => {
    if (clickToScroll) scrollToUserMessage(userMsgId - 1);
  };

  return showHeader ? (
    <div className={chartHeaderClassName} onClick={linkToUserMsg}>
      {children}
    </div>
  ) : null;
};

VisualHeaderContainer.propTypes = {
  children: PropTypes.node.isRequired,
  messageType: PropTypes.string.isRequired,
  isSelecting: PropTypes.bool,
  isInsightsBoard: PropTypes.bool,
  isDragInProgress: PropTypes.bool,
  chartEditingMode: PropTypes.bool,
  clickToScroll: PropTypes.bool,
  userMsgId: PropTypes.number,
};

VisualHeaderContainer.defaultProps = {
  isSelecting: false,
  isInsightsBoard: false,
  isDragInProgress: false,
  chartEditingMode: false,
  clickToScroll: false,
  userMsgId: 1,
};

export default VisualHeaderContainer;
