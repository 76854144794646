import FilePondPluginFileRename from 'filepond-plugin-file-rename';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import React from 'react';
import { FilePond, registerPlugin } from 'react-filepond';
import { connect } from 'react-redux';
import { closeLoadCard } from '../../store/slices/loadCard.slice';

import 'filepond/dist/filepond.min.css';

import { closeDialog } from '../../store/actions/dialog.actions';
import { autoOpenBrowseFiles } from '../../store/actions/file_manager.actions';
import {
  closeFileModal,
  fileUploadCancel,
  fileUploadFailure,
  fileUploadRequest,
  loadImmediatelyAlert,
} from '../../store/actions/upload.actions';
import { updateSingleSelectData } from '../../store/actions/utterance_composer.actions';
import '../../styles/UploadButton.scss';
import getFileValidateTypeDetectType from '../../utils/get_file_validate_type_detect_type';
import sanitizeFileName from '../../utils/sanitize_file_name';

registerPlugin(
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize,
  FilePondPluginFileRename,
);

type Props = {
  openBrowseFiles: Boolean,
  autoOpenBrowseFiles: () => mixed,
  fileUploadRequest: () => mixed,
  fileUploadFailure: () => mixed,
  fileUploadCancel: () => mixed,
  closeLoadCard: () => mixed,
  closeFileModal: () => mixed,
  acceptedFileTypes?: string[],
};

/**
 * This button allows files to be dragged and dropped to be uploaded
 * to the server.
 */
class UploadButton extends React.Component<Props> {
  constructor(props) {
    super(props);
    this.filePondRef = React.createRef();
  }

  componentWillUnmount = () => {
    this.props.fileUploadCancel({ error: 'File upload cancelled' });
  };

  onInit = () => {
    // if we came from Load Form, then
    if (this.props.openBrowseFiles) {
      document.getElementsByClassName('filepond--label-action')[0].click();
    }
    this.props.autoOpenBrowseFiles(false);
  };

  handleAddFileStart = (file) => {
    this.props.fileUploadRequest({ file, filePond: this.filePondRef.current });
  };

  handleError = (error, file) => {
    this.props.fileUploadFailure({ file, error });
  };

  handleProcessFiles = () => {
    this.props.closeLoadCard();
    this.props.closeFileModal();
  };

  render() {
    return (
      <FilePond
        ref={this.filePondRef}
        allowMultiple
        className="filepond--root"
        onaddfilestart={this.handleAddFileStart}
        onerror={this.handleError}
        oninit={() => this.onInit()}
        onprocessfiles={this.handleProcessFiles}
        onupdatefiles={this.handleUpdateFiles}
        instantUpload={false}
        allowRevert={false}
        allowReplace={false}
        acceptedFileTypes={this.props.acceptedFileTypes}
        maxFileSize={50000000000} // 50GB
        labelTapToCancel="" // No support for Tap to Cancel
        labelTapToUndo=""
        fileValidateTypeDetectType={getFileValidateTypeDetectType}
        fileRenameFunction={sanitizeFileName}
        allowFileEncode={false}
        credits={false}
      />
    );
  }
}
UploadButton.defaultProps = {
  acceptedFileTypes: ['.csv', '.xlsx'],
};

const mapStateToProps = (state) => ({
  openBrowseFiles: state.fileManager.openBrowseFiles,
});

export default connect(mapStateToProps, {
  autoOpenBrowseFiles,
  fileUploadRequest,
  fileUploadFailure,
  fileUploadCancel,
  loadImmediatelyAlert,
  closeDialog,
  updateSingleSelectData,
  closeLoadCard,
  closeFileModal,
})(UploadButton);
