import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { getModelNames, getPublishedModel } from '../../api/model.api';
import { GET_APIKEYS_SUCCESS, getApikeysRequest } from '../actions/apikeys.action';
import {
  GET_PUBLISHED_MODEL_DATA_REQUEST,
  GET_SESSION_MODELS_REQUEST,
  OPEN_MODEL_MENU,
  getPublishedModelDataFailure,
  getPublishedModelDataRequest,
  getPublishedModelDataSuccess,
  getSessionModelFailure,
  getSessionModelsSuccess,
} from '../actions/model.actions';
import { selectSession } from '../selectors/session.selector';
import { selectAccessToken, selectShowModelProfiler } from './selectors';
import { callAPIWithRetry } from './utils/retry';

/**
 * Retrieve all api keys once opening the profiler
 */
function* openModelProfilerWorker() {
  yield put(getApikeysRequest());
}

const extractModelName = (spec) => {
  return { modelName: JSON.parse(spec.model_name) };
};

/**
 * Attempts to retrieve a published model's spec.
 * @param {Object} publication - ID's related to a given model's publication
 */
function* getPublishedModelWorker({ publicationId, apiKey, apiSecret }) {
  try {
    const accessToken = yield select(selectAccessToken);
    const response = yield call(getPublishedModel, {
      publicationId,
      apiKey,
      apiSecret,
      accessToken,
    });
    const { data } = response;
    yield put(
      getPublishedModelDataSuccess({
        publicationId: data.publication_id,
        model: extractModelName(data.content),
      }),
    );
  } catch (error) {
    yield put(getPublishedModelDataFailure({ error }));
  }
}

/**
 * Fetches the model specs for each published model for each of the user's keys
 *
 * The specs for each published model are cached on the frontend.
 */
function* fetchPublicationsFromApiKeysWorker({ keys }) {
  const showModelProfiler = yield select(selectShowModelProfiler);
  if (showModelProfiler) {
    // Request the model spec for each published model of each api key
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      for (let j = 0; j < key.publications?.length; j++) {
        const publication = key.publications[j];
        if (publication.type === 'model') {
          yield put(
            getPublishedModelDataRequest({
              publicationId: publication.id,
              apiName: key.name,
              apiKey: key.client_id,
              apiSecret: key.secret,
              requireToken: key.require_token,
            }),
          );
        }
      }
    }
  }
}

function* getSessionModelsWorker() {
  try {
    const accessToken = yield select(selectAccessToken);
    const sessionId = yield select(selectSession);

    if (sessionId) {
      const modelResponse = yield callAPIWithRetry({
        apiFn: getModelNames,
        args: [accessToken, sessionId],
      });
      const models = modelResponse.data;

      yield put(getSessionModelsSuccess({ models }));
    }
  } catch (error) {
    yield put(getSessionModelFailure({ error }));
  }
}

export default function* () {
  yield takeEvery(OPEN_MODEL_MENU, openModelProfilerWorker);
  yield takeEvery(GET_PUBLISHED_MODEL_DATA_REQUEST, getPublishedModelWorker);
  yield takeLatest(GET_APIKEYS_SUCCESS, fetchPublicationsFromApiKeysWorker);
  yield takeLatest(GET_SESSION_MODELS_REQUEST, getSessionModelsWorker);
}
