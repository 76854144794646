import Tooltip from '@mui/material/Tooltip';
import { XAXisComponentOption, YAXisComponentOption } from 'echarts';
import React from 'react';
import { Column, DCColTypes, NUMERIC_COL_TYPES, Row, getMinMax } from 'translate_dc_to_echart';
import { KEYBOARD_KEYS } from '../../../../constants';
import DataChatAutocompleteInput from '../../../common/DataChatAutocompleteInput';
import { Line, Lines, MarkLine } from '../../types/ChartBuilder';
import { getUniqueValuesByLocator } from '../../utils/columns';
import {
  ERROR_MESSAGE_BOUNDS,
  ERROR_MESSAGE_EMPTY,
  ERROR_MESSAGE_NUMBER,
  ERROR_MESSAGE_POINTS,
  LINE_COLOR,
  LINE_END_SYMBOL,
  LINE_TYPE,
  LineOptions,
  LineOptionsDisplay,
  LineOptionsMap,
} from './constants';

/**
 * Creates the markLine object for the Chart Builder's fields & chart spec series
 * @param {Array} lines The line data
 * @returns The markLine object to be added to the chart spec
 */
export const constructMarkLine = (lines: Lines): MarkLine => ({
  data: lines ?? [],
  lineStyle: { color: LINE_COLOR, type: LINE_TYPE },
  silent: true, // Prevents interactions
  symbol: LINE_END_SYMBOL,
});

/**
 * Get the axis bound from the ECharts axisOption.
 * Prefer the min/max from the ECharts spec if one is defined.
 * @param axisOption The axis component option from the ECharts spec
 * @param tempLimit The fallback limit from the raw rowData. This can only be used
 * if we're not performing FE computations.
 * @param limitType 'min' or 'max'
 * @returns The value for the min or max axis limit.
 */
export const getAxisLimit = (
  axisOption:
    | XAXisComponentOption
    | XAXisComponentOption[]
    | YAXisComponentOption
    | YAXisComponentOption[]
    | undefined,
  tempLimit: string | number,
  limitType: 'min' | 'max',
): number =>
  Number(
    Array.isArray(axisOption)
      ? axisOption?.[0]?.[limitType] ?? tempLimit
      : axisOption?.[limitType] ?? tempLimit,
  );

/**
 * Gets the title of the chip by parsing the available data in the main Lines input field.
 * @param chipValue The value of the chip in the main Lines input field.
 * @returns The title of the chip.
 */
export const getChipTitle = (chipValue: Line): string => {
  if (!chipValue) return '';
  if (Array.isArray(chipValue)) return LineOptionsDisplay[LineOptionsMap[LineOptions.COORD]];
  if (chipValue.type) return LineOptionsDisplay[chipValue.type];
  return chipValue.yAxis !== undefined
    ? LineOptionsDisplay.horizontal
    : chipValue.xAxis !== undefined
    ? LineOptionsDisplay.vertical
    : '';
};

/**
 * Handle key presses while interacting with the main Lines input field.
 * @param e The key press event
 */
const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
  if (e.key === KEYBOARD_KEYS.BACKSPACE) {
    e.stopPropagation();
  }
};

/** Helper function to create DataChatAutocompleteInput components */
export const createAutocompleteInput = (
  key: string,
  placeholder: string,
  value: string,
  onInputChange: (_: any, newValue: string) => void,
  options: string[] | number[],
  freeSolo: boolean,
  error: string,
) => (
  <DataChatAutocompleteInput
    key={key}
    data-testid={key}
    listboxProps={{ style: { maxHeight: '10rem' } }}
    placeholder={placeholder}
    value={value}
    onInputChange={onInputChange}
    options={freeSolo ? [] : options}
    onKeyDown={handleKeyDown}
    freeSolo={freeSolo}
    error={Boolean(error)}
    inlineLabel={error || placeholder}
  />
);

/**
 * Get the axis information for the given columns.
 * Assumes that all columns in the axis are of the same type.
 * @param {String | String[]} axisCols The columns that constitute the axis
 * @param {Columns} columns The columns from the dcSpec
 * @param {Rows} rows The rows from the dcSpec
 * @returns [axisType, axisIsNumeric, axisValues, axisMin, axisMax]
 */
export const getAxisInfo = (
  axisCols: string[] | string,
  columns: Column[],
  rows: Row[],
): [DCColTypes, boolean, number[] | string[], string | number, string | number] => {
  const arrAxisCols = Array.isArray(axisCols) ? axisCols : [axisCols];

  // Output defaults
  let axisType = DCColTypes.INTEGER;
  let axisIsNumeric = true;
  const axisValuesSet = new Set();

  // Get the information for each column in arrAxisCols
  for (const colName of arrAxisCols) {
    const columnIndex = columns.findIndex((c) => c.name === colName);
    const column = columns[columnIndex];

    // Skip if column not found
    if (!column || columnIndex === -1) continue;

    // Assuming all columns have the same type, so set these once
    if (axisType === DCColTypes.INTEGER) {
      axisType = column.type;
      axisIsNumeric = NUMERIC_COL_TYPES.includes(axisType);
    }

    const uniqueValues = getUniqueValuesByLocator(columnIndex, axisType, rows);
    uniqueValues.forEach((value) => {
      if (value !== 'null' && value != null) axisValuesSet.add(value);
    });
  }

  const axisValues = Array.from(axisValuesSet) as number[] | string[];
  const { min: axisMin, max: axisMax } = getMinMax(axisValues, axisType);
  const min = axisIsNumeric ? Number(axisMin) : axisMin;
  const max = axisIsNumeric ? Number(axisMax) : axisMax;
  return [axisType, axisIsNumeric, axisValues, min, max];
};

/**
 * Renders/wraps autocomplete options with tooltips
 * @param renderOptionProps props to pass to the option ex. key, event handlers, id etc.
 * @param option the text to be displayed for the option
 * @returns the AC option wrapped in a <Tooltip>
 */
export const renderOptionWithTooltip = (
  renderOptionProps: React.HTMLAttributes<HTMLLIElement>,
  option: string,
) => (
  <Tooltip title={option} key={option} placement="bottom">
    <li {...renderOptionProps}>
      <span>{option}</span>
    </li>
  </Tooltip>
);

/**
 * Get an error message (or empty string if none) for the given userInput.
 * For isFreeSolo, checks if the input is a number and within the axis bounds.
 * For !isFreeSolo, checks if the input is a valid selection for the given data points.
 */
export const validateUserInput = (
  isFreeSolo: boolean,
  min: number,
  max: number,
  rowValues: any[],
  userInput?: string,
):
  | '' // No error
  | typeof ERROR_MESSAGE_NUMBER
  | typeof ERROR_MESSAGE_BOUNDS
  | typeof ERROR_MESSAGE_POINTS
  | typeof ERROR_MESSAGE_EMPTY => {
  if (!userInput) return ERROR_MESSAGE_EMPTY;
  if (isFreeSolo) {
    // Invalid number value
    if (Number.isNaN(Number(userInput))) return ERROR_MESSAGE_NUMBER;
    // Out of axis bounds
    if (Number(userInput) < min || Number(userInput) > max) return ERROR_MESSAGE_BOUNDS;
  } else if (!isFreeSolo && !rowValues.includes(userInput)) {
    // Invalid selection for the given data points
    return ERROR_MESSAGE_POINTS;
  }
  return '';
};
