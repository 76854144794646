/**
 * @fileoverview Renders a single row for the row component of MaterialTable
 */
import { MTableBodyRow } from '@material-table/core';
import React, { useContext } from 'react';
import { SelectedRowContext } from './GenericMaterialTable';
import { WithProperties } from './constants';

interface GenericMaterialTableRowProps<T extends WithProperties> {
  handleRowClick: (object: T, event: React.MouseEvent<HTMLElement>) => void;
  handleRowDblClick: (object: T, event: React.MouseEvent<HTMLElement>) => void;
  rowProps: { data: T };
}

const GenericMaterialTableRow = <T extends WithProperties>({
  handleRowClick,
  handleRowDblClick,
  rowProps,
}: GenericMaterialTableRowProps<T>): React.ReactElement => {
  const { selectedRows } = useContext(SelectedRowContext);
  const selected = selectedRows.some((obj) => obj.id === rowProps.data.id);
  return (
    <MTableBodyRow
      {...rowProps}
      className={selected ? 'GenericMaterialTable-Row-Selected' : 'GenericMaterialTable-Row'}
      data-testid={selected ? 'GenericMaterialTable-Row-Selected' : 'GenericMaterialTable-Row'}
      onMouseDown={(e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        if (e.target instanceof HTMLElement) handleRowClick(rowProps.data, e);
      }}
      onRowDoubleClick={(e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        if (e.target instanceof HTMLElement) handleRowDblClick(rowProps.data, e);
      }}
    />
  );
};

export default GenericMaterialTableRow;
