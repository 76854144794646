import { getVersionStatus, unpackWorkflowFile } from '../../../utils/workflow';

export const getWorkflowData = (data) => {
  // Extract the workflow data from the response
  const workflowData = data.workflow;
  const workflowVersions = data.versions;
  // determine the replay/copy-paste status of the workflow
  workflowVersions.forEach((version) => {
    const status = getVersionStatus(version.dcw_data);
    version.status = status;
  });
  // Versions are ordered, so the first one will be the current version
  const current = data.versions[0];

  // Split the header and workflow
  const { header, content } = unpackWorkflowFile(current.dcw_data);

  const name = workflowData.name.String;

  return { workflowData, workflowVersions, header, content, name };
};
