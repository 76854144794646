export type OTPParams = {
  name?: string;
  email?: string;
  otpToken?: string;
};

export type OAuthParams = {
  idToken: string; // JWT token returned from service provider
  authCode?: string; // Authorization code (optional) used to get an access and refresh token
};

export type Auth0Params = {
  accessToken: string;
};

export type PasswordAuthParams = {
  email: string;
  password: string;

  // Only defined for registration request
  name?: string;
  confirmationPassword?: string;
};

export type AuthParams = OAuthParams | Auth0Params | PasswordAuthParams | OTPParams;

export interface AdAttributionType {
  utmSource: string;
  utmMedium: string;
  utmTerm: string;
  utmContent: string;
  utmCampaign: string;
  obilityId: string;
  obilityOffer: string;
}
// Copied from authtype.xo.go::UnmarshalText
export enum AuthType {
  PASSWORD = 'password',
  GOOGLE = 'google',
  SAML = 'saml',
  IAP = 'iap',
  AUTH0 = 'auth0',
  OTP = 'otp',
}

export type LoginRegisterRequest = {
  authType: AuthType;
  loginParams: AuthParams;
  adAttribution?: AdAttributionType;
};

export type LoginRequest = {
  authType: AuthType;
  loginParams: AuthParams;
};

export type RegisterRequest = {
  authType: AuthType;
  key?: string;
  registerParams?: AuthParams;
  defaultHomePage?: string;
  loginOnRegister?: boolean;
  adAttribution?: AdAttributionType;
};
