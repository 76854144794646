import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import React from 'react';
import { ReactComponent as CancelIcon } from '../../../../assets/icons/x-circle.svg';

const CancelButton = (props) => {
  const { showButton, onClick, tooltipLabel, dataCy, className } = props;

  return showButton ? (
    <Tooltip key="cancel" title={tooltipLabel}>
      <div
        className="ChartHeader-Button"
        data-cy={dataCy}
        onClick={(event) => {
          // avoid click bubbling to parent to trigger the link option
          event.stopPropagation();
          onClick();
        }}
      >
        <CancelIcon className={className} />
      </div>
    </Tooltip>
  ) : null;
};

CancelButton.propTypes = {
  showButton: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  tooltipLabel: PropTypes.string.isRequired,
  dataCy: PropTypes.string,
  className: PropTypes.string,
};

CancelButton.defaultProps = {
  dataCy: 'ChartHeader-Button-Cancel',
  className: 'ChartHeader-Icon-Lg',
};

export default CancelButton;
