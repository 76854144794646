import { SeriesMarkKeys } from 'translate_dc_to_echart';
import ChartModules from '../modules/ChartModules';
import { InputType } from '../types/chartBuilderIO';
import ChartType from './ChartType';

export default class SingleMetric extends ChartType {
  static fieldGenMapping = {
    mark: {
      [SeriesMarkKeys.column]: InputType.column,
    },
    group: {},
  };

  static columnFilters = {};

  constructor() {
    super(new ChartModules(SingleMetric.columnFilters, SingleMetric.fieldGenMapping));
  }
}
