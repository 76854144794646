// Constants for deployment configurations
export const DEPLOYMENT_MODE = {
  DEFAULT: 'default',
  AMI: 'ami',
};

export const STORAGE = {
  SESSION: 'session',
  LOCAL: 'local',
};
