import DeleteIcon from '@mui/icons-material/Delete';
import ErrorIcon from '@mui/icons-material/Error';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedDatasetId } from '../../../../store/selectors/catalog.selector';
import { deleteColumn } from '../../../../store/slices/catalog.slice';
import './CatalogEditPanel.scss';

const DeleteColumnButton: React.FC<{ column: string }> = ({ column }) => {
  const dispatch = useDispatch();
  const selectedDatasetId = useSelector(selectSelectedDatasetId);
  const [hovered, setHovered] = React.useState<boolean>(false);

  const handleMouseEnter = () => setHovered(true);
  const handleMouseLeave = () => setHovered(false);

  return (
    <Tooltip title="This column no longer exists in the dataset">
      <div
        className="delete-column-wrapper"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{ display: 'inline-block' }} // Ensures it doesn't take unnecessary space
      >
        <IconButton
          data-testid="delete-column-button"
          size="small"
          color="error"
          onClick={() => {
            if (!selectedDatasetId) return;
            dispatch(deleteColumn({ datasetId: selectedDatasetId, column }));
          }}
        >
          {hovered ? (
            <DeleteIcon data-testid="delete-icon" sx={{ fontSize: '16px' }} />
          ) : (
            <ErrorIcon data-testid="error-icon" sx={{ fontSize: '16px' }} />
          )}
        </IconButton>
      </div>
    </Tooltip>
  );
};
export default DeleteColumnButton;
