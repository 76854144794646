import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { ABBREVIATION, ColumnStatKey, STAT_TOOLTIP } from './constant';
import { getFormattedStat } from './utils';

const IndividualColumnStat: React.FC<{
  columnStat: { [columnStatKey: string]: any };
  colType: string;
  columnStatKey: ColumnStatKey;
  index: number;
}> = ({ columnStat, colType, columnStatKey, index }) => {
  // if the columnStatKey is an empty string, return an empty row
  if (columnStatKey === ColumnStatKey.Placeholder) {
    return (
      <tr key={columnStatKey.concat(`${index}`)}>
        <td aria-label="empty-line">&nbsp;</td>
      </tr>
    );
  }

  const { value, tooltipValue, valueClassName } = getFormattedStat(
    columnStat,
    columnStatKey,
    colType,
  );
  return (
    <tr key={columnStatKey} className={columnStatKey} data-testid={`${columnStatKey}-container`}>
      <Tooltip placement="right" title={STAT_TOOLTIP[columnStatKey] || columnStatKey}>
        <td className="title" data-testid={`${columnStatKey}-title`}>
          {ABBREVIATION[columnStatKey] || columnStatKey}
        </td>
      </Tooltip>
      <Tooltip placement="right" title={tooltipValue}>
        <td className={valueClassName} data-testid={`${columnStatKey}-value`}>
          {value}
        </td>
      </Tooltip>
    </tr>
  );
};

export default IndividualColumnStat;
