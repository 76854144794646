import * as Yup from 'yup';
import { IntegrationProvider } from '../../types/integrations.types';
import {
  DATABASE_CONNECTION_FIELDS,
  FORM_INPUT_TYPES,
  YUP_VALIDATION_SCHEMAS,
} from '../../utils/connection/connection';
import { createDatabaseType, hideIfTrue } from './__helpers__';

const NAME = 'SnowFlake';
const SnowflakeConnectionFields = {
  hostname: 'hostname',
  username: 'username',
  password: 'password',
  databaseName: 'databaseName',
  computeWarehouse: 'computeWarehouse',
  datachatWorkspace: 'datachatWorkspace',
  datasets: 'datasets',
  readOnly: 'readOnly',
  useOAuth: 'useOAuth',
};

/* Note: The following lines have been lint disabled as we cannot use
          Yup's this context with the ES6 arrow syntax
*/
// eslint-disable-next-line func-names
Yup.addMethod(Yup.string, 'verify_hostname', function (errorMessage) {
  // eslint-disable-next-line func-names
  return this.test(`test-hostname`, errorMessage, function (value) {
    const { path, createError } = this;
    return (
      !value?.includes('snowflakecomputing.com') || createError({ path, message: errorMessage })
    );
  });
});

export default {
  meta: {
    name: NAME,
  },
  body: {
    validationSchema: Yup.object().shape({
      [SnowflakeConnectionFields.databaseName]: Yup.string().required('Required'),
      [SnowflakeConnectionFields.computeWarehouse]: Yup.string().optional(),
      [SnowflakeConnectionFields.datachatWorkspace]: Yup.string().optional(),
      [SnowflakeConnectionFields.useOAuth]: Yup.boolean().required('Required'),
      [SnowflakeConnectionFields.hostname]: Yup.string()
        .verify_hostname('Remove snowflakecomputing.com from hostname')
        .required('Required'),
      [SnowflakeConnectionFields.username]: Yup.string().when(SnowflakeConnectionFields.useOAuth, {
        is: false,
        then: Yup.string().required('Required'),
      }),
      [SnowflakeConnectionFields.password]: Yup.string().when(SnowflakeConnectionFields.useOAuth, {
        is: false,
        then: Yup.string().required('Required'),
      }),
      [SnowflakeConnectionFields.datasets]: YUP_VALIDATION_SCHEMAS.DATASETS,
      [SnowflakeConnectionFields.readOnly]: Yup.boolean().required('Required'),
    }),
    fields: [
      { ...createDatabaseType, value: NAME },
      {
        id: SnowflakeConnectionFields.hostname,
        label: 'Hostname',
        value: '',
        type: FORM_INPUT_TYPES.TEXT,
      },
      {
        id: SnowflakeConnectionFields.useOAuth,
        label: 'Use OAuth',
        value: false,
        isRequired: true,
        provider: IntegrationProvider.SNOWFLAKE,
        type: FORM_INPUT_TYPES.OAuthToggle,
      },
      {
        id: SnowflakeConnectionFields.username,
        label: 'Username',
        value: '',
        isRequired: true,
        type: FORM_INPUT_TYPES.TEXT,
        hideIfFn: (values) => hideIfTrue(values, SnowflakeConnectionFields.useOAuth),
      },
      {
        id: SnowflakeConnectionFields.password,
        label: 'Password',
        value: '',
        isRequired: true,
        autocomplete: 'new-password',
        type: FORM_INPUT_TYPES.PASSWORD,
        hideIfFn: (values) => hideIfTrue(values, SnowflakeConnectionFields.useOAuth),
      },
      {
        id: SnowflakeConnectionFields.databaseName,
        label: 'Database',
        value: '',
        type: FORM_INPUT_TYPES.TEXT,
      },
      {
        id: SnowflakeConnectionFields.computeWarehouse,
        label: 'Compute Warehouse',
        value: '',
        isRequired: false,
        type: FORM_INPUT_TYPES.TEXT,
      },
      {
        id: SnowflakeConnectionFields.datachatWorkspace,
        label: 'DataChat Workspace',
        value: '',
        isRequired: false,
        type: FORM_INPUT_TYPES.TEXT,
      },
      DATABASE_CONNECTION_FIELDS.DATASETS,
      {
        id: SnowflakeConnectionFields.readOnly,
        label: 'Read-Only',
        value: false,
        isRequired: true,
        type: FORM_INPUT_TYPES.CHECKBOX,
      },
    ],
    // initFields: set of fields which must be filled before options are retrieved
    initFields: new Set([
      SnowflakeConnectionFields.hostname,
      SnowflakeConnectionFields.username,
      SnowflakeConnectionFields.password,
      SnowflakeConnectionFields.useOAuth,
    ]),
    // optionFields: list of fields for which options should be retrieved
    optionFields: [
      SnowflakeConnectionFields.databaseName,
      SnowflakeConnectionFields.computeWarehouse,
      SnowflakeConnectionFields.datachatWorkspace,
    ],
  },
};
