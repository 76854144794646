import {
  CLOSE_VIEW_SLICED_WORKFLOW,
  OPEN_VIEW_SLICED_WORKFLOW,
  RETRIEVE_SLICED_WORKFLOW_DATA_FAILURE,
  RETRIEVE_SLICED_WORKFLOW_DATA_SUCCESS,
} from '../actions/viewSlicedWorkflow.action';

const initialState = {
  isSlicedWorkflowPopoutOpen: false, // Indicates if the popout for dependency graph is open
  isSlicedWorkflowDataLoading: false,
  slicedWorkflowData: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_VIEW_SLICED_WORKFLOW:
      return { ...state, isSlicedWorkflowPopoutOpen: true, isSlicedWorkflowDataLoading: true };
    case CLOSE_VIEW_SLICED_WORKFLOW:
      return { ...state, isSlicedWorkflowPopoutOpen: false, slicedWorkflowData: null };
    case RETRIEVE_SLICED_WORKFLOW_DATA_SUCCESS:
      return {
        ...state,
        slicedWorkflowData: action.slicedWorkflowData,
        isSlicedWorkflowDataLoading: false,
      };
    case RETRIEVE_SLICED_WORKFLOW_DATA_FAILURE:
      // error will be handled later when rendering the popout chart
      return {
        ...state,
        isSlicedWorkflowPopoutOpen: false,
        isSlicedWorkflowDataLoading: false,
      };
    default:
      return state;
  }
};
