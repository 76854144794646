import { FieldGenMapping } from '../types/chartBuilderIO';
import { ColumnFilters } from '../types/columnSelection';
import ChartBuilderIO from './chartBuilder/ChartBuilderIO';
import ColumnSelection from './chartBuilder/ColumnSelection';

/**
 * Modules that expose utilities for chart building, editing, and translation.
 * The per-chart-type behaviors are specified on the chart type class & injected into this class.
 */
export default class ChartModules {
  /** Chart Builder: Column selection utilities */
  public ColumnSelection: ColumnSelection;

  /** Chart Builder: Input & Output utilities */
  public ChartBuilderIO: ChartBuilderIO;

  constructor(columnFilters: Partial<ColumnFilters>, fieldGenMapping: FieldGenMapping) {
    this.ColumnSelection = new ColumnSelection(columnFilters);
    this.ChartBuilderIO = new ChartBuilderIO(fieldGenMapping);
  }
}
