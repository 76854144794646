import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import { formatRelative } from 'date-fns';
import isBoolean from 'lodash/isBoolean';
import isString from 'lodash/isString';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import sanitizeHtml from 'sanitize-html';
import { type Message, type Node } from '../../../../../store/slices/nodes.slice';
import { capitalizeFirstLetter } from '../../../../../utils/string';

import {
  CLASS,
  CONTACT_FORM_DEFAULTS,
  CONTACT_FORM_ERROR_MESSAGE_TEMPLATE,
  REPLAY_MESSAGES,
} from '../../../../../constants';
import { NodeTypes } from '../../../../../constants/nodes';
import { openContactForm } from '../../../../../store/actions/contact_form.actions';
import { selectSession } from '../../../../../store/selectors/session.selector';
import Text from '../../../../ChatPanel/ChatContext/BubbleComponent/Text';
import './GeneralResponse.scss';

interface GeneralResponseProps {
  id: string;
  messages: Message[];
  nodeType: string;
  divider?: boolean;
  timestamp?: number;
  childNode: Node | null;
  expanded?: boolean;
}

const GeneralResponse: FC<GeneralResponseProps> = ({
  id,
  messages,
  divider = false,
  timestamp,
  childNode,
  nodeType,
  expanded = false,
}) => {
  const dispatch = useDispatch();
  const sessionId = useSelector(selectSession);

  const [collapsed, setCollapsed] = useState<boolean | null>(null);
  const open = collapsed === null ? expanded : !collapsed;

  const skillFailed = useMemo(() => {
    if (
      // the execution_successful key exists in the metadata
      isBoolean(childNode?.metadata.execution_successful) &&
      // the value of the execution_successful key is false
      childNode?.metadata.execution_successful === false
    ) {
      return true;
    }
    return false;
  }, [childNode?.metadata.execution_successful]);

  const infoMessages = useMemo(() => {
    // dont render if
    if (
      // there is no child node
      !childNode ||
      // the child node has no messages
      childNode?.messages?.length === 0 ||
      // the child node is not a GELResponse
      childNode?.node_type !== NodeTypes.GELResponse ||
      // the gel response has not completed execution
      !isBoolean(childNode?.metadata.execution_successful)
    ) {
      return [];
    }
    // get all the message types we want to render
    const informationalMessages: string[] = [];
    const failureMessages: string[] = [];
    const successMessages: string[] = [];
    childNode.messages.forEach((message) => {
      if (isString(message.data) && message.data.length > 0) {
        switch (message.class) {
          case CLASS.INFORMATIONAL:
            informationalMessages.push(message.data);
            break;
          case CLASS.FAILURE:
            failureMessages.push(message.data);
            break;
          case CLASS.SUCCESS:
            successMessages.push(message.data);
            break;
          default:
            break;
        }
      }
    });

    // rener the messages in priority order
    // failure > success > informational
    const messagesToRender = informationalMessages.concat(successMessages, failureMessages);
    return messagesToRender;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childNode?.messages?.length, childNode?.metadata.execution_successful]);

  const getIcon = () => {
    // if there is no child node or if the child node is not a GEL response, return no icon
    if (!childNode || childNode?.node_type !== NodeTypes.GELResponse) return null;

    /** The icon to display next to this general response.  */
    let icon: JSX.Element | null = null;

    // get the child node's "execution_successful" property from its metadata
    const success = childNode.metadata.execution_successful;

    // if the child node has children and we don't know if the execution was successful or not, return no icon
    if (childNode?.children?.length > 0 && success === undefined) return null;

    // if success is defined, set icon based of its value
    if (success !== undefined) {
      // set icon based on success value
      icon = success ? <Check className="success" /> : <Close className="error" />;

      // return icon
      return <div className="GeneralResponse-Icon start">{icon}</div>;
    }

    // ! success is undefined, check if the child node is replaying

    // get whether the child node has a `"data": "Replay started."` message
    const isReplaying = childNode.messages.some((msg) => msg.data === REPLAY_MESSAGES.START);

    // if child node is replaying, return loading spinner
    if (isReplaying)
      return (
        <div className="GeneralResponse-Icon start">
          <CircularProgress size="100%" />
        </div>
      );

    // ! we're not replaying, set icon based on if child has a "class": "ready" message

    // get whether the child node has a `"class": "ready"` message
    const hasReadyMessage = childNode.messages.some((msg) => msg.class === CLASS.READY);

    // if we have a ready message, but don't know the execution status assume it failed
    if (hasReadyMessage && success === undefined) icon = <Close className="error" />;

    // if the child node doesn't have a ready message, set the icon to a loading spinner
    if (!hasReadyMessage) icon = <CircularProgress size="100%" />;

    // return icon
    return <div className="GeneralResponse-Icon start">{icon}</div>;
  };

  const renderUpdateMessages = useCallback(() => {
    // dont render if
    if (
      // there is no child node
      !childNode ||
      // the child node has no messages
      childNode?.messages?.length === 0 ||
      // the child node is not a GELResponse
      childNode?.node_type !== NodeTypes.GELResponse ||
      // the gel response has completed execution
      isBoolean(childNode?.metadata.execution_successful) ||
      // the gel response hasn't "completed" execution and has children (worker crashed)
      (childNode?.metadata.execution_successful === undefined && childNode?.children.length > 0)
    ) {
      return null;
    }
    // get the update messages
    const updateMessages: string[] = [];
    childNode.messages.forEach((message) => {
      if (message.class === CLASS.UPDATE && isString(message.data) && message.data.length > 0) {
        updateMessages.push(message.data);
      }
    });
    // render the update messages
    return updateMessages.length > 0 ? (
      <div className="GeneralResponse-Update">{updateMessages[updateMessages.length - 1]}</div>
    ) : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childNode?.messages?.length, childNode?.metadata.execution_successful]);

  const handleReportErrorClick = () => {
    dispatch(
      openContactForm({
        subject: CONTACT_FORM_DEFAULTS.ERROR_SUBJECT,
        content: CONTACT_FORM_ERROR_MESSAGE_TEMPLATE(
          messages?.[0]?.data || 'unknown',
          sanitizeHtml(infoMessages.map((msg) => msg).join('\n'), {
            allowedTags: [],
          }),
          sessionId,
          `Node:${id}`,
        ),
        messageType: CONTACT_FORM_DEFAULTS.SUBJECT_BUG_REPORT,
      }),
    );
  };

  const leftAlign: boolean = nodeType === NodeTypes.GEL;

  const renderMessages = () => {
    return (
      <>
        {timestamp && (
          <Typography
            className="GeneralResponse-Text center"
            variant="caption"
            data-testid="general-response-date"
          >
            {capitalizeFirstLetter(formatRelative(new Date(timestamp), new Date()))}
          </Typography>
        )}
        {messages.map((message, i) =>
          isString(message?.data) ? (
            // message indices are static, so they (along with the node's id) can be trusted as a unique key
            // eslint-disable-next-line react/no-array-index-key
            <Box key={`${i}-${id}`} className="GeneralResponse-Text-Container">
              {getIcon()}
              <Typography
                className={classNames('GeneralResponse-Text', {
                  left: leftAlign,
                  center: !leftAlign,
                })}
                variant="caption"
                component="span"
              >
                <Text data={message.data} expanded={false} />
                {renderUpdateMessages()}
              </Typography>
              {infoMessages.length > 0 && (
                <>
                  <span className="GeneralResponse-Text-UnderlineFill" />
                  <div
                    className={classNames('GeneralResponse-Icon end', { open })}
                    onClick={() => setCollapsed((oldValue) => !oldValue)}
                  >
                    {open ? <ArrowDropUp className="info" /> : <ArrowDropDown className="info" />}
                  </div>
                </>
              )}
            </Box>
          ) : null,
        )}
        <Collapse in={open}>
          <div className="GeneralResponse-Info">
            {infoMessages.map((m) => (
              <Typography key={m} className="GeneralResponse-Info-Item" variant="caption">
                <strong>&#x2022;</strong>
                <Text data={m} expanded={false} />
              </Typography>
            ))}
            {skillFailed && (
              <Link variant="caption" color="error" onClick={handleReportErrorClick}>
                Report Failure
              </Link>
            )}
          </div>
        </Collapse>
      </>
    );
  };

  return (
    <div id={id} data-testid={id} className="GeneralResponse">
      {divider ? <Divider sx={{ width: '100%' }}>{renderMessages()}</Divider> : renderMessages()}
    </div>
  );
};

export default GeneralResponse;
