export enum LLMQueryType {
  recommendQuestions = 'recommend_questions',
  recommendObjectives = 'recommend_objectives',
}

export enum ObjectivesRecommendationSkillType {
  visualize_ml = 'visualize_ml',
  pivot = 'pivot',
  visualize = 'chart',
}

export enum ObjectivesRecommendationType {
  trending = 'trending',
  clicked = 'history_engaged',
}
