export const DRILL_THROUGH_REQUEST = 'drill_through/DRILL_THROUGH_REQUEST';
export const DRILL_THROUGH_SUCCESS = 'drill_through/DRILL_THROUGH_SUCCESS';
export const DRILL_THROUGH_FAILURE = 'drill_through/DRILL_THROUGH_FAILURE';

export const TRIGGER_DRILL_THROUGH = 'drill_through/TRIGGER_DRILL_THROUGH';
export const EXIT_DRILL_THROUGH = 'drill_through/EXIT_DRILL_THROUGH';

/**
  Dispatch action when the drill through button is clicked in an IB chart header
  @param {Object} object Object representing the snapshot object to be opened
  @param {Object} drillThroughSpec Chart spec to use when loading the new session
*/
export const drillThroughRequest = ({ object, drillThroughSpec }) => ({
  type: DRILL_THROUGH_REQUEST,
  object,
  drillThroughSpec,
});

// Set states after initiating the request
export const triggerDrillThrough = ({ drillThroughSpec }) => ({
  type: TRIGGER_DRILL_THROUGH,
  drillThroughSpec,
});

// Clear the drill through state after we've processed the trigger
export const exitDrillThrough = () => ({
  type: EXIT_DRILL_THROUGH,
});

export const drillThroughSuccess = () => ({
  type: DRILL_THROUGH_SUCCESS,
});

export const drillThroughFailure = ({ object, error }) => ({
  type: DRILL_THROUGH_FAILURE,
  object,
  error,
});
