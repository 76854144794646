import MenuItem from '@mui/material/MenuItem';
import isString from 'lodash/isString';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import { ReactComponent as ExportPlotDataIcon } from '../../../../../assets/icons/download_plot_data.svg';
import { isWorkflowEditorPage } from '../../../../../constants/paths';
import { showReplayDisableToast } from '../../../../../constants/toast';
import { exportDatasetRequest } from '../../../../../store/actions/file_download.actions';
import { addToast } from '../../../../../store/actions/toast.actions';
import { selectCurrentLocation } from '../../../../../store/sagas/selectors';
import { selectIsStepwise } from '../../../../../store/selectors/context.selector';
import { PLOTS_NOT_DOWNLOADABLE } from '../../../constants';

/**
 * A functional component to render a button UI to export the underlying data for a plot
 */
const ExportPlotDataButton = (props) => {
  // redux dispatch hook
  const dispatch = useDispatch();

  const { isReplaying, isStepwise, isWorkflowEditor, sessionType, messageType, data } = props;

  // Download the dataset associated with a plot
  const handleExportPlotDataButtonClick = () => {
    // show toast during not stepwise replay
    if (isReplaying && !isStepwise) {
      showReplayDisableToast(addToast, 'download data', dispatch);
      return;
    }

    // Gather exportedName, datasetName, and datasetVersion from different
    // locations within data objects depending on the typeVersion
    let datasetNameSplit;
    let dsName;
    let dsVersion;
    if (data.typeVersion === 2) {
      datasetNameSplit = isString(data.plot.presentation.title.text)
        ? data.plot.presentation.title.text
        : data.plot.presentation.title.text[0];
      dsName = data.values.reference.params.dataset;
      dsVersion = data.values.reference.params.version;
    } else {
      datasetNameSplit = isString(data.layout.title) ? data.layout.title : data.layout.title.text;
      dsName = data.dataset.name;
      dsVersion = data.dataset.version;
    }
    // Parse the exportedName from the chart title
    datasetNameSplit = datasetNameSplit.split(/[^a-zA-Z0-9]/);
    datasetNameSplit = datasetNameSplit.map(
      (string) => string.charAt(0).toUpperCase() + string.slice(1),
    );
    const exportedName = datasetNameSplit.join('');

    dispatch(
      exportDatasetRequest({
        datasetName: dsName,
        version: dsVersion,
        filename: exportedName,
      }),
    );
  };

  // Returns true if the export plot data button should be shown, otherwise, returns false.
  const showExportPlotDataButton = useCallback(
    () => {
      if (data && sessionType && messageType === 'viz' && !isWorkflowEditor) {
        // Don't show button if plot doesn't render or if plotly chart isn't downloadable
        return data.typeVersion === 2
          ? data.plot.series && data.values.reference && !data.isDependencyGraph
          : data.chartType && data.dataset && !PLOTS_NOT_DOWNLOADABLE.includes(data.chartType);
      }
      return false;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, isWorkflowEditor],
  );

  return (
    <>
      {showExportPlotDataButton() ? (
        <MenuItem
          key="Export the chart's data"
          className="Menu-Item-Container"
          onClick={handleExportPlotDataButtonClick}
        >
          <div
            className={`${isReplaying && !isStepwise ? 'Menu-Item Replaying' : 'Menu-Item'}`}
            data-cy="Menu-Item-Export-Plot"
          >
            <ExportPlotDataIcon className="Menu-Item-Icon" />
            <span className="Menu-Item-Text">Export the chart&rsquo;s data</span>
          </div>
        </MenuItem>
      ) : null}
    </>
  );
};

ExportPlotDataButton.propTypes = {
  isReplaying: PropTypes.bool.isRequired,
  isStepwise: PropTypes.bool.isRequired,
  isWorkflowEditor: PropTypes.bool.isRequired,
  sessionType: PropTypes.string.isRequired,
  messageType: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sessionType: state.session.sessionType,
  isStepwise: selectIsStepwise(state),
  isReplaying: state.context.isReplaying,
  isWorkflowEditor: isWorkflowEditorPage(selectCurrentLocation(state)),
});

export default connect(mapStateToProps, {})(ExportPlotDataButton);
