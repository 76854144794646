import ActionButton from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useState } from 'react';
import { GiBroom } from 'react-icons/gi';
import { useDispatch, useSelector } from 'react-redux';
import { CONTEXTS } from '../../../constants';
import { selectContext } from '../../../store/selectors/context.selector';
import { selectDataAssistantContext } from '../../../store/selectors/dataAssistant.selector';
import { clearContextRequest } from '../../../store/slices/dataAssistant.slice';
import './SweepButton.scss';

const SweepButton = () => {
  const dispatch = useDispatch();
  const contextDatasets = useSelector(selectDataAssistantContext);
  const context = useSelector(selectContext);
  const contextExists = contextDatasets.length > 0;

  /**
   * The conditions to disable the button are:
   *   - There is no context
   *   - The context is not REST
   *   - The user just clicked the button (setIsDisabled(true))
   */
  const disabledCondition = !contextExists || context !== CONTEXTS.REST;
  const [isDisabled, setIsDisabled] = useState(disabledCondition);

  // Update the disabled state when the context changes
  useEffect(() => {
    setIsDisabled(disabledCondition);
  }, [disabledCondition]);

  return (
    <Tooltip title={contextExists ? 'Clear your topic' : 'Start a topic by asking a question'}>
      <span>
        <ActionButton
          aria-label="Data Assistant Sweep Button"
          data-testid="DataAssistantSweepButton"
          disabled={isDisabled}
          color="primary"
          className="SweepButton"
          onClick={() => {
            setIsDisabled(true);
            dispatch(clearContextRequest());
          }}
        >
          <GiBroom />
        </ActionButton>
      </span>
    </Tooltip>
  );
};

export default SweepButton;
