import useTheme from '@mui/material/styles/useTheme';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CONTEXTS } from '../../../../constants';
import { scrollToConversationBottom } from '../../../../constants/dataAssistant';
import { NodeTypes } from '../../../../constants/nodes';
import { selectContext } from '../../../../store/selectors/context.selector';
import { ScreenSizeContext } from '../../../DataAssistant/DataAssistant';
import RecommendationList from '../../../DataAssistant/Recommendations/RecommendationList';
import AvaResponse from './AvaResponse';
import './Conversation.scss';
import GeneralResponse from './GeneralResponse/GeneralResponse';
import UserQuestion from './UserQuestion';

/**
 * Renders the conversation for Ava.
 */
const Conversation = React.forwardRef(
  ({ isPublic = false, responseBgColor = undefined, items }, scrollRef) => {
    const context = useSelector(selectContext);
    const theme = useTheme();
    const smallScreenSize = useContext(ScreenSizeContext);

    // If no background color is provided, use the default paper color
    const bgColor = responseBgColor || theme.palette.background.paper;

    /**
     * Scroll to the bottom of the conversation when:
     *   - The tab changes
     *   - The conversation length changes
     *   - The conversation is first rendered
     */
    useEffect(() => {
      scrollToConversationBottom(scrollRef);
    }, [items.length, scrollRef]);

    // Track the last node in the message store
    const lastIndex = items.length - 1;
    const lastNode = items[lastIndex];

    // Incrementally tracks the last NLQ node as each node is rendered
    let lastNQLNode = null;

    return (
      <div
        id="ava-conversation"
        className={classNames('Conversation', { SmallScreenSize: smallScreenSize })}
      >
        {items.map((node, index) => {
          // we guarantee ordering using current thread
          const childNode = items?.[index + 1] || null;

          /** @type {Array} */
          const children = node.children ?? [];

          /** Whether this node's children includes the last node. */
          const parentsLastNode = children.includes(lastNode.node_id);

          if (node.metadata.muted) return null;
          switch (node.node_type) {
            case NodeTypes.TopicEvent:
              return (
                <GeneralResponse
                  id={node.node_id}
                  key={node.node_id}
                  nodeType={node.node_type}
                  messages={node.messages}
                  divider
                  timestamp={node.metadata.timestamp}
                  childNode={childNode}
                />
              );
            case NodeTypes.GEL:
            case NodeTypes.SessionEvent: {
              return (
                <GeneralResponse
                  id={node.node_id}
                  key={node.node_id}
                  nodeType={node.node_type}
                  messages={node.messages}
                  childNode={childNode}
                  expanded={parentsLastNode} // expand last node's parent
                />
              );
            }
            case NodeTypes.NLQ: {
              lastNQLNode = node;
              return (
                <UserQuestion
                  id={node.node_id}
                  key={node.node_id}
                  messages={node.messages}
                  isPublic={isPublic}
                  userId={node.metadata?.user_id}
                />
              );
            }
            case NodeTypes.NLQResponse:
              return (
                <AvaResponse
                  id={node.node_id}
                  key={node.node_id}
                  messages={node.messages}
                  metadata={node.metadata}
                  questionNode={lastNQLNode}
                  isLastNode={index === lastIndex}
                  isPublic={isPublic}
                  bgColor={bgColor}
                />
              );
            default:
              return null;
          }
        })}
        {/* Push everything after this to the bottom */}
        <div style={{ flexGrow: 1 }}></div>
        {context === CONTEXTS.REST && <RecommendationList />}
        {/* Reference to the bottom of the chart.
          This seems necessary to be able to snap to bottom of the scroll window */}
        <div
          className="Conversation-ScrollReference"
          key="bottom-scroll-reference"
          style={{ marginBottom: 0 }}
        />
      </div>
    );
  },
);

Conversation.propTypes = {
  items: PropTypes.array.isRequired,
  isPublic: PropTypes.bool,
  responseBgColor: PropTypes.string,
};

Conversation.displayName = 'Conversation';
export default Conversation;
