export const GET_INVOICES_REQUEST = 'invoice/GET_INVOICES_REQUEST';
export const GET_INVOICES_SUCCESS = 'invoice/GET_INVOICES_SUCCESS';
export const GET_INVOICES_FAILURE = 'invoice/GET_INVOICES_FAILURE';

export const GET_UPCOMING_INVOICE_REQUEST = 'invoice/GET_UPCOMING_INVOICE_REQUEST';
export const GET_UPCOMING_INVOICE_SUCCESS = 'invoice/GET_UPCOMING_INVOICE_SUCCESS';
export const GET_UPCOMING_INVOICE_FAILURE = 'invoice/GET_UPCOMING_INVOICE_FAILURE';

// action creators to get invoices
export const getInvoicesRequest = () => ({
  type: GET_INVOICES_REQUEST,
});

export const getInvoicesSuccess = ({ data }) => ({
  type: GET_INVOICES_SUCCESS,
  data,
});

export const getInvoicesFailure = ({ error }) => ({
  type: GET_INVOICES_FAILURE,
  error,
});

// action creators to get upcoming invoice for specific subscription
export const getUpcomingInvoiceRequest = () => ({
  type: GET_UPCOMING_INVOICE_REQUEST,
});

export const getUpcomingInvoiceSuccess = ({ data }) => ({
  type: GET_UPCOMING_INVOICE_SUCCESS,
  data,
});

export const getUpcomingInvoiceFailure = ({ error }) => ({
  type: GET_UPCOMING_INVOICE_FAILURE,
  error,
});
