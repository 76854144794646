import moment from 'moment';
import { call, getContext, put, select, take, takeLatest } from 'redux-saga/effects';
import { API_SERVICES } from '../../constants/api';
import { OK_BUTTON_KEY, createLicenseWarningAlert } from '../../constants/dialog.constants';
import { isNetworkError } from '../../constants/network_error';
import { authenticate } from '../../utils/authenticate';
import { closeDialog, createAlertChannelRequest } from '../actions/dialog.actions';
import {
  CHECK_LICENSE_EXPIRY_REQUEST,
  checkLicenseExpiryFailure,
  checkLicenseExpirySuccess,
  saveLicenseTimestampToLS,
} from '../actions/license.actions';
import { selectAccessToken } from './selectors';
import { createAlertChannel } from './utils/alert-channels';

/**
 * Makes an API request for checking license expiry and handles the response
 */
export function* checkLicenseExpiryWorker() {
  try {
    // Display license warning if last seen >= 1 day
    const accessToken = yield select(selectAccessToken);
    const licenseService = yield getContext(API_SERVICES.LICENSE);
    const { data } = yield call(licenseService.checkExpiry, accessToken);
    yield put(checkLicenseExpirySuccess());
    if (data) {
      // Show warning if license will expire soon.
      const alertChannel = yield call(createAlertChannel, createLicenseWarningAlert(data));
      const keyChoice = yield take(alertChannel);
      // User picked OK. Close the pop up and save timeStamp to LS
      if (keyChoice === OK_BUTTON_KEY) yield put(closeDialog());
      yield put(saveLicenseTimestampToLS({ timeStamp: moment().format() }));
    }
  } catch (error) {
    yield put(checkLicenseExpiryFailure({ error }));
    if (!isNetworkError(error)) {
      yield put(createAlertChannelRequest({ error }));
    }
  }
}

/**
 * Listens for the most recent CHECK_LICENSE_EXPIRY_REQUEST action and spins up a worker.
 */
export default function* () {
  yield takeLatest(
    CHECK_LICENSE_EXPIRY_REQUEST,
    authenticate(checkLicenseExpiryWorker, checkLicenseExpiryFailure),
  );
}
