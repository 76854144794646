import { Dispatch } from 'redux';

/**
 * Map home object keys to names that are more user-friendly
 * Should only include keys that are contained within HomeObjectKeys
 */
export enum HomeObjectActiveNames {
  ID = 'Id',
  NAME = 'Name',
  TYPE = 'Object Type',
  OWNER_NAME = 'Owner Name',
  IS_SHARED = 'Shared',
  CREATED = 'Created',
  LAST_ACTIVE = 'Last Active',
  LAST_MODIFIED = 'Last Modified',
  SOURCE = 'Source',
}

export enum HomeObjectKeys {
  ACCESS_TYPE = 'accessType',
  APP_ID = 'appId',
  CONNECTION_FK = 'ConnectionFileFk',
  CREATED = 'created',
  DATAFILE_FK = 'DataFileFk',
  EXAMPLE_FK = 'exampleFk',
  HAS_INDEPENDENT_ACCESS = 'hasIndependentAccess',
  ID = 'objectId',
  IS_SHARED = 'isShared',
  LAST_ACTIVE = 'lastActive',
  LAST_MODIFIED = 'dateLastModified',
  NAME = 'objectName',
  OBJECT_FK = 'ObjectFk',
  OWNER_EMAIL = 'ownerEmail',
  OWNER_ID = 'ownerId',
  OWNER_NAME = 'ownerName',
  OWNER = 'Owner',
  PARENT_ID = 'Parent',
  READ_ONLY = 'readOnly',
  TABLE_ID = 'id',
  TYPE = 'objectType',
  UUID = 'uuid',
  VISIBILITY = 'visibility',
  WORKFLOW_FK = 'WorkflowFileFk',
  SESSION_ID = 'sessionId',
  PIPELINER_DATASET_ID = 'PipelinerDatasetID',
}

export type HomeObjectKeysTypes = {
  [HomeObjectKeys.ACCESS_TYPE]?: string; // 'viewer' | 'editor' | 'owner';
  [HomeObjectKeys.APP_ID]?: string;
  [HomeObjectKeys.CONNECTION_FK]?: string;
  [HomeObjectKeys.CREATED]: Date;
  [HomeObjectKeys.DATAFILE_FK]?: string;
  [HomeObjectKeys.EXAMPLE_FK]?: boolean;
  [HomeObjectKeys.HAS_INDEPENDENT_ACCESS]: boolean; // Will be true for object owner or if the object is shared independently with other user. False if the object is shared with organization.
  [HomeObjectKeys.ID]: string;
  [HomeObjectKeys.IS_SHARED]: boolean;
  [HomeObjectKeys.LAST_ACTIVE]: Date;
  [HomeObjectKeys.LAST_MODIFIED]?: Date;
  [HomeObjectKeys.NAME]: string;
  [HomeObjectKeys.OBJECT_FK]?: string;
  [HomeObjectKeys.OWNER_EMAIL]?: string;
  [HomeObjectKeys.OWNER_ID]: number;
  [HomeObjectKeys.OWNER]: number;
  [HomeObjectKeys.OWNER_NAME]: string;
  [HomeObjectKeys.PARENT_ID]?: string;
  [HomeObjectKeys.READ_ONLY]?: boolean;
  [HomeObjectKeys.TABLE_ID]?: string; // For material table
  [HomeObjectKeys.TYPE]: HomeObjects;
  [HomeObjectKeys.UUID]: string;
  [HomeObjectKeys.VISIBILITY]: string;
  [HomeObjectKeys.WORKFLOW_FK]?: string;
  [HomeObjectKeys.SESSION_ID]?: string;
  [HomeObjectKeys.PIPELINER_DATASET_ID]?: string;
};

/**
 * Enum of home screen object types.  String value should
 * be presentable as the type of an object.  These types
 * also map to tabs on the home screen.
 *
 * If changing value of these variables, also change the corresponding
 * variable in dcobject.go (WA_{objectType})
 */
export enum HomeObjects {
  SESSION = 'Session',
  RECIPE = 'Workflow',
  INSIGHTS_BOARD = 'Insights Board',
  SNAPSHOT = 'Snapshot',
  CONNECTION = 'Database',
  ALL = 'My Work',
  FOLDER = 'Folder',
  SEARCH = 'Search',
  QUERY = 'Query',
  DATAFILE = 'File',
  ASK_SESSION = 'Conversation',
  DATASET = 'Dataset',
}

export type HomeObjectAction = (rows: HomeObjectKeysTypes[]) => HomeObjectActionResult;
export type HomeObjectActionResult = {
  key: string;
  tooltip: string;
  onClick: (event: any) => void;
  disabled?: boolean;
  iconProps?: any;
  /* If provided, a beta chip will be rendered next to the option */
  betaText?: string;
};

export type HSOTRowClickParams = {
  e: React.MouseEvent;
  rowData: HomeObjectKeysTypes;
  isSelected?: boolean;
  rowEl?: HTMLElement | null;
  passedSelectedRows: HomeObjectKeysTypes[];
};

export type CreateRowClickHandlerArgs = {
  deletingObjects: string[];
  selectedRows: HomeObjectKeysTypes[];
  setSelectedObjects: (rows: HomeObjectKeysTypes[]) => void;
  dispatch: Dispatch<any>;
  objectType: string;
  data: HomeObjectKeysTypes[];
  lastNonShiftClickedRef: React.MutableRefObject<HTMLElement | null | undefined>;
  lastShiftClickedRef: React.MutableRefObject<HTMLElement | null | undefined>;
};

export type CreateRowClickHandler = (
  createRowClickHandlerArgs: CreateRowClickHandlerArgs,
) => (args: HSOTRowClickParams) => void;

export type CreateContextHandlerArgs = {
  setSelectedObjects: (rows: HomeObjectKeysTypes[]) => void;
  deletingObjects: string[];
  dispatch: Dispatch<any>;
};
export type CreateContextClickHandler = (
  createContextClickHandlerArgs: CreateContextHandlerArgs,
) => (args: HSOTRowClickParams) => void;

export type SelectionBoxParams = {
  showBox?: boolean;
  left: number;
  top: number;
  width: number;
  height: number;
};
