import { NavigationItemStatus } from '../constants/session';
import { type Dataset } from '../store/slices/dataspace.slice';

export const sortNamesByActive = (names: string[], datasets: Dataset[]): string[] => {
  const activeNames = new Set(
    datasets
      .filter((dataset) => dataset.status === NavigationItemStatus.ACTIVE)
      .map((dataset) => dataset.name),
  );
  return names.sort((a, b) => {
    if (activeNames.has(a) && !activeNames.has(b)) {
      return -1;
    }
    if (activeNames.has(b) && !activeNames.has(a)) {
      return 1;
    }
    return 0;
  });
};
