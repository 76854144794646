import {
  GET_EXAMPLES_FAILURE,
  GET_EXAMPLES_REQUEST,
  GET_EXAMPLES_SUCCESS,
  SELECT_EXAMPLE_SUCCESS,
} from '../actions/examples.action';

const initialState = {
  // The example information used to populate the Ava example cards
  examples: [],
  // Are we waiting for the examples retrieval?
  isLoadingExamples: false,
  // If an example was clicked, the file name of the example
  selectedExample: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // Succeed in getting all of the examples
    case GET_EXAMPLES_SUCCESS:
      return {
        ...state,
        isLoadingExamples: false,
        examples: action.examples,
      };
    // Fail to get all of the examples
    case GET_EXAMPLES_FAILURE:
      return {
        ...state,
        isLoadingExamples: false,
      };
    // Request to get all of the examples
    case GET_EXAMPLES_REQUEST:
      return {
        ...state,
        isLoadingExamples: true,
      };
    // Select one of the examples
    case SELECT_EXAMPLE_SUCCESS:
      return {
        ...state,
        selectedExample: action.selectedExample,
      };
    default:
      return state;
  }
};
