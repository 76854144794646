import cloneDeep from 'lodash/cloneDeep';
import { getConcatKey } from '../../store/reducers/utterance_composer.reducer';
import { CORE_REQUIRED_PAGES, getComposedUtteranceFinal, getInitialInputValue } from './constants';
import {
  EntityState,
  FormGroup,
  PageState,
  PathState,
  UtteranceComposerInput,
  UtteranceComposerPageType,
  UtteranceComposerRecipe,
} from './UtteranceComposer.types';

/**
 * Get the path of pages to display on the form
 * ex) ['Bar', 'Option', 'EachOf']
 */
export const getFormPath = (pathState: PathState, recipe: UtteranceComposerRecipe): string[] => {
  const { pages, startingPage } = recipe;
  const path: string[] = [];
  let currentPage: UtteranceComposerPageType | undefined = pages[startingPage];
  while (currentPage && !path.includes(currentPage.id)) {
    // PUSH THE CURRENT PAGE TO THE PATH
    path.push(currentPage.id);

    if (!currentPage.page_navigator) {
      // CASE 1: PAGE IS NOT A PAGE NAVIGATOR (continue)
      currentPage = currentPage.next ? pages[currentPage.next] : undefined;
      continue;
    } else {
      // CASE 2: PAGE IS A PAGE NAVIGATOR
      const next = pathState[currentPage.id];
      // makes sure that the next page is selected and that it exists in the current skill
      if (next && Object.keys(pages).includes(next)) {
        currentPage = pages[next];
      } else {
        // if there is no next page, assume we hit the end of the form
        break;
      }
    }
  }
  return path;
};

/** This is the tooltip text we show on a disabled submit button */
export const getNotificationText = (
  /** Whether or not the form is complete  */
  completed: boolean,
  /** Whether or not all "core" pages of a form are complete. these are pages that are required
   * even if they're hidden */
  coreCompleted: boolean,
  /** if the context is not equal to RESTING */
  nonrestingContext: boolean,
  /** utterance composer forms are composed of different branching "pages".
   * This is the current path the user has taken through the form */
  path: string[],
  /** All pages in the form */
  pages: Record<string, UtteranceComposerPageType>,
) => {
  let notificationText = '';
  if (!completed && !coreCompleted) {
    notificationText = 'Please fill in the required fields';
  } else if (!completed && coreCompleted) {
    notificationText = `Fill in the required field for "${
      pages[path[path.length - 1]].label
    }", or unselect the "${pages[path[path.length - 1]].label}" option.`;
  } else if (nonrestingContext) {
    notificationText = 'Please wait for the current skill to finish.';
  }
  return notificationText;
};

/**
 * get the path of pages for utterance composer
 * This is required since state.path is only for display.
 * We need to create utterance from hidden pages as well.
 */
export const getUtterancePath = (
  path: string[],
  pages: Record<string, UtteranceComposerPageType>,
  pageState: PageState,
  defaultOrder: string[],
) => {
  const newPath = [...path];
  // Iterate through each page in the path
  for (const page of path) {
    // If the page is a page navigator and not marked as single, add the nested pages to the path
    if (pages[page].page_navigator && !pages[page].single) {
      for (const next of pages[page]?.pages ?? []) {
        // Only insert the nested page if:
        // 1. It's not already in the path AND the page is complete
        // 2. The page is marked as insert in the recipe
        if ((!newPath.includes(next) && pageState[next]) || pages[next].insert) {
          newPath.push(next);
        }
      }
    }
  }
  const sortedPath = [];
  // sort the path based on the default order
  for (const page of defaultOrder) {
    if (newPath.includes(page)) {
      sortedPath.push(page);
    }
  }
  return sortedPath;
};

/**
 * Checks if all the required pages are completed to enable submit button
 * @param pageState
 * @param path
 * @param pages
 * @returns {boolean}
 */
export const getSubmitDisabled = (
  pageState: PageState,
  path: string[],
  pages: Record<string, UtteranceComposerPageType>,
) => {
  let disabled = false;
  let p;
  for (p of path) {
    if (!pageState[p] && !pages[p].page_navigator) {
      disabled = true;
    }
  }
  // in case the last page is page_navigator, make sure that next page is not required
  if (p && !disabled && pages[p].page_navigator) {
    const nextPages = pages[p].pages ?? [];
    nextPages.forEach((next: string) => {
      if (pages[next].required) {
        disabled = true;
      }
    });
  }
  return disabled;
};

/**
 * Checks if all the core required pages are completed.
 * If all core required pages are completed, then we will later
 * show a different error if the user tries to submit the utterance.
 *
 * Core required pages are the ones we always must fill out regardless of
 * any "additional options" we click
 *
 * @param pageState
 * @param path
 * @param pages
 * @returns {boolean} true if we should show a specific error message related
 * to the user having optional fields open but trying to click "Submit" for the form
 */
export const getCoreFormsDisabled = (
  pageState: PageState,
  path: string[],
  pages: Record<string, UtteranceComposerPageType>,
) => {
  let coreDisabled = true;
  let p;
  for (p of path) {
    if (CORE_REQUIRED_PAGES.includes(p) && pageState[p]) {
      coreDisabled = false;
    }
  }

  // if any page is page_navigator, make sure that next page is not required
  let i;
  let j;
  for (i of path) {
    if (!coreDisabled && pages[i].page_navigator) {
      // for j in pages[i].pages, if pages[j].required, set coreDisabled to true
      // this will set coreDisabled to true if there are required entries after page_navigator
      for (j of pages[i].pages ?? []) {
        if (pages[j].required) {
          coreDisabled = true;
        }
      }
    }
  }
  return coreDisabled;
};

/**
 * Computes whether each page is complete or not
 */
export const getPageState = (
  recipe: UtteranceComposerRecipe,
  entityState: EntityState,
): PageState => {
  const pageState: PageState = {};
  const { pages } = recipe;
  Object.entries(pages).forEach(([pageId, page]: [string, UtteranceComposerPageType]) => {
    if (page.page_navigator) {
      // if the page is a page navigator, we assume it's always complete
      pageState[pageId] = true;
    } else {
      // flatten arrays of inputs
      const inputs: UtteranceComposerInput[][] = cloneDeep(page.inputs);
      // check if all append inputs are completed
      const appendInputs: UtteranceComposerInput[][] | undefined = page?.repeatIndex
        ? page.appendInputs?.[page.repeatIndex]
        : undefined;
      if (appendInputs) {
        inputs.splice(page?.repeatIndex ?? 0 + 1, 0, ...appendInputs);
      }

      // check if all required inputs are completed
      const allInputs = ([] as UtteranceComposerInput[]).concat(...inputs);
      const completed = !allInputs.some(
        (input) => !input.optional && !entityState[input.id]?.completed,
      );

      // store the state of the page
      pageState[pageId] = completed;
    }
  });
  return pageState;
};

/** Initializes a new input in the utterance composer framework */
const initializeNewInput = (entityState: EntityState, newInput: { [key: string]: any }) => {
  entityState[newInput.id] = {
    value: newInput.default_value ? newInput.init_value : getInitialInputValue(newInput.input_type),
    completed: newInput.default_value !== undefined,
    coreCompleted: newInput.default_value !== undefined,
    optional: !!newInput.optional,
  };
};

/**
 * Updates a specific row in the form when a user modified an input
 * @param inputRow
 * @param page
 * @param modifiedInput
 * @param modifiedInputValue
 * @param entityState // This must be a deep clone of the entityState to avoid side effects
 */
const getUpdatedRow = (
  inputRow: UtteranceComposerInput[],
  page: UtteranceComposerPageType,
  modifiedInput: UtteranceComposerInput,
  modifiedInputValue: string,
  entityState: EntityState, //
) => {
  const updatedRow = [];
  const { id, concatInputOnSuggestion, addedRowIndex } = modifiedInput;
  const { defaultKeys = [], conditionalInputs = {} } = page;

  // for each input in the row, we need to check if the modified input has any conditional inputs
  for (let i = 0; i < inputRow.length; i++) {
    const singleInput = inputRow[i]; // the current input we are looking at
    updatedRow.push(singleInput); // add the input to the new list of inputs
    if (id === singleInput.id && concatInputOnSuggestion) {
      if (modifiedInputValue?.length === 0) break;

      // get the key that we will use to find the conditional inputs
      const concatKey: string = getConcatKey(modifiedInput, modifiedInputValue);

      // We get the next input it if exists in the list of conditional inputs
      let nextInputs =
        page?.conditionalInputs?.[concatKey] !== undefined
          ? cloneDeep(conditionalInputs[concatKey])
          : null;

      // If the next input does not exist in the list of conditional inputs, we check if it is a default key
      if (nextInputs === null && defaultKeys.includes(concatKey)) {
        nextInputs = cloneDeep(conditionalInputs.default);
      }

      // If the list of conditional inputs exists, we add them to the new list of inputs one by one
      if (nextInputs !== null) {
        for (const nextInput of nextInputs) {
          nextInput.concat = true; // mark the input as concatenated
          // if it is a added row, make sure that input id is unique by appending the row index
          if (addedRowIndex !== undefined) {
            nextInput.id = `${nextInput.id}-${addedRowIndex}-${page.id}`;
            nextInput.addedRowIndex = addedRowIndex;
          }
          updatedRow.push(nextInput);
          // We initialize the value of the new input in the entityState
          initializeNewInput(entityState, nextInput);
        }
      }
      break;
    }
  }
  return updatedRow;
};

/**
 * When we update an input, we need to check if the input has any conditional inputs and insert
 * them in the formGroup and entityState.
 *
 * @param formGroup - contains the structure of the form by skill
 * @param pageState - contains the "completed" state of each page
 * @param entityState - contains the values of all inputs in the form
 * @param input - The input that was modified
 * @param value - The new value of the input
 * @param currentSkill - The current skill we are working on
 * @param currentPageID - The current page we are working on
 * @param outerIndex - the "row" of the page that we are working on
 */
export const concatInput = (
  formGroup: FormGroup,
  pageState: Record<string, PageState>,
  entityState: EntityState,
  input: UtteranceComposerInput,
  value: string,
  currentSkill: string,
  currentPageID: string,
  outerIndex: number,
): { entityState: EntityState; formGroup: FormGroup; pageState: Record<string, PageState> } => {
  // clone the formGroup, pageState, and entityState so we can freely modify them without side effects
  const newFormGroup = cloneDeep(formGroup);
  const newPageState = cloneDeep(pageState);
  const newEntityState = cloneDeep(entityState);
  const { pages } = newFormGroup[currentSkill];

  // get the page that the modified input belongs to
  let page = pages[currentPageID];

  // get the list of pages that share the same inputs as the target page
  const pageList = page.shareWith ? [page.id, ...page.shareWith] : [page.id];

  // for each page in the list, we need to check if the modified input has any conditional inputs
  for (const pageName of pageList) {
    page = pages[pageName];
    const { repeatIndex = 0 } = page;

    // Get any inputs that are "appended" in the page
    const appendInputs = page.appendInputs?.[repeatIndex] ? page.appendInputs[repeatIndex] : [];

    // get the inputs that are in the page
    const groupedInputs = input.addedRowIndex === undefined ? page.inputs : appendInputs;

    // get the inputs that are in the same "row" as the modified input
    const inputs = groupedInputs[outerIndex];

    /** this will track the new list of inputs */
    const newRow = getUpdatedRow(inputs, page, input, value, newEntityState);

    // update the list of inputs in the page
    if (input.addedRowIndex === undefined) {
      page.inputs[outerIndex] = newRow;
    } else if (page.appendInputs?.[repeatIndex]) {
      page.appendInputs[repeatIndex][outerIndex] = newRow;
    }
    // reset the pageState for the corresponding page to false since the page is no longer complete
    newPageState[currentSkill][currentPageID] = false;
  }

  return {
    formGroup: newFormGroup,
    entityState: newEntityState,
    pageState: newPageState,
  };
};

/**
 * This function is called to update the state of the utterance composer whenever a user:
 * 1. Changes an input
 * 2. Clicks on a page navigator
 * 3. Switches to a different skill
 *
 * @param entityState - contains the values of all inputs in the form
 * @param newPageState - contains the structure of the form by skill
 * @param formGroup - contains the values of all inputs in the form
 * @param skill - The current skill we are working on
 * @param newPathState - The current path we are working on
 * @param newValue - The new value of the input if the user changed an input
 * @returns
 */
export const getUpdatedState = (
  entityState: EntityState,
  pageState: Record<string, PageState>,
  formGroup: FormGroup,
  skill: string,
  newPathState: PathState,
  newValue: { entity: string; value: any; [key: string]: any } | null = null,
) => {
  let concatenationUpdates;
  if (newValue?.input?.concatInputOnSuggestion) {
    concatenationUpdates = concatInput(
      formGroup,
      pageState,
      entityState,
      newValue.input,
      newValue.value,
      skill,
      newValue.currentPageKey,
      newValue.outerIndex,
    );
  }

  // use the concatenation updates if they exist, otherwise use the current state
  const {
    pageState: newPageState,
    entityState: newEntityState,
    formGroup: newFormGroup,
  } = concatenationUpdates ?? { pageState, entityState, formGroup };

  // destructure the current recipe
  const currentRecipe = newFormGroup[skill];
  const { pages, defaultOrder = [] } = currentRecipe;

  // Get the updated path and utterance
  const currentPath = getFormPath(newPathState, currentRecipe);
  const currentUtterancePath = getUtterancePath(
    currentPath,
    pages,
    newPageState[skill],
    defaultOrder,
  );

  // Construct the new utterance
  const newUtterance = getComposedUtteranceFinal({
    defaultOrder: currentUtterancePath,
    currentPageValues: newEntityState,
    pages,
  });

  // Check if the form is completed
  const newCompleted = !getSubmitDisabled(newPageState[skill], currentPath, pages);
  const newCoreCompleted = !getCoreFormsDisabled(newPageState[skill], currentPath, pages);
  return {
    utterance: newUtterance,
    completed: newCompleted,
    entityState: newEntityState,
    pageState: newPageState,
    formGroup: newFormGroup,
    coreCompleted: newCoreCompleted,
  };
};
