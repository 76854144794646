import { CLOSE_GENERIC_MENU, OPEN_GENERIC_MENU } from '../actions/menus.actions';

const initialState = {
  openMenuID: '',
  xPos: 0,
  yPos: 0,
  options: [],
};

// Reducer to execute menu actions
export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_GENERIC_MENU:
      return {
        ...state,
        openMenuID: action.openMenuID,
        xPos: action.xPos,
        yPos: action.yPos,
        options: action.options,
      };
    case CLOSE_GENERIC_MENU:
      return initialState;
    default:
      return state;
  }
};
