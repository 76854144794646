import InfoIcon from '@mui/icons-material/InfoOutlined';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import styled from '@mui/material/styles/styled';
import React from 'react';
import './index.scss';

type Props = {
  content: String,
  placement: String, // Default placement is bottom
  tooltipWidth: Number,
};
class InfoButton extends React.Component<Props> {
  render() {
    const { content, placement, tooltipWidth } = this.props;

    // Adds a custom maxWidth property to the tooltip. Uses default <Tooltip /> maxWidth if not provided.
    const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
      <Tooltip {...props} classes={{ popper: className }} />
    ))({
      [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: tooltipWidth || 300,
      },
    });

    return (
      <CustomWidthTooltip title={content} placement={placement || 'bottom'}>
        <InfoIcon fontSize="small" className="small_svg_icons" />
      </CustomWidthTooltip>
    );
  }
}

export default InfoButton;
