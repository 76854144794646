import { createSelector } from 'reselect';
import { RootState } from '../../configureStore';
import {
  ColumnAnnotations,
  DatasetAnnotation,
  DatasetAnnotations,
  EditedDatasetAnnotations,
} from '../../types/catalog.types';
import merge from '../../utils/merge';
import {
  DEFAULT_DATASET_ANNOTATION,
  filterDeletedColumns,
  getAllSafeToShare,
  getMergedColumnAnnotations,
} from '../slices/catalog.slice';

export const selectIsCatalogOpen = (state: RootState) => state.catalog.open;
export const selectSelectedDatasetId = (state: RootState) => state.catalog.selectedDatasetId;
export const selectEditedDatasetsIds = (state: RootState) => [
  ...new Set([
    ...Object.keys(state.catalog.edits),
    ...Object.keys(state.catalog.delete).filter((id) => state.catalog.delete[id].length > 0),
  ]),
];
export const selectDatasetEdits = (state: RootState) => state.catalog.edits;
export const selectHasDatasetEdits = createSelector(
  selectEditedDatasetsIds,
  (ids) => ids.length > 0,
);
export const selectDatasetAnnotations = (state: RootState) => state.catalog.datasetAnnotations;
export const selectDeleted = (state: RootState) => state.catalog.delete;

const selectDatasetAnnotationById = createSelector(
  selectDatasetAnnotations,
  (_: RootState, id: string | null) => id,
  (annotations, id) => (id !== null ? annotations[id] : null),
);

const selectDatasetAnnotationKind = createSelector(
  selectDatasetAnnotationById,
  (annotation) => annotation?.kind ?? 'loading',
);

export const selectDatasetAnnotationHasData = createSelector(
  selectDatasetAnnotationKind,
  (kind) => kind === 'data',
);

export const selectDatasetAnnotationIsLoading = createSelector(
  selectDatasetAnnotationKind,
  (kind) => kind === 'loading',
);

export const selectDatasetAnnotationError = createSelector(
  selectDatasetAnnotationById,
  (annotation) => {
    return annotation?.kind === 'error' ? annotation.error : null;
  },
);

export const selectDatasetAnnotationErrorMessage = createSelector(
  selectDatasetAnnotationError,
  (error) => error?.message,
);

/**
 * Select a dataset's edited annotation. This is the merged annotation of its
 * original annotation and any unsaved edits.
 */
export const selectEditedDatasetAnnotation = createSelector(
  selectDatasetAnnotations,
  selectDatasetEdits,
  selectDeleted,
  (_: RootState, id: string) => id,
  (
    annotations: DatasetAnnotations,
    edits: EditedDatasetAnnotations,
    deleted: Record<string, string[]>,
    id: string,
  ) => {
    const editData = edits[id] ?? {};
    const annoationData =
      annotations[id]?.kind === 'data' ? annotations[id]?.data : DEFAULT_DATASET_ANNOTATION;

    // remove the deleted columns from the original annotation
    const filteredAnnotation = {
      ...annoationData,
      columns: filterDeletedColumns(
        (annoationData?.columns ?? {}) as ColumnAnnotations,
        deleted[id] ?? [],
      ),
    };

    return merge<Partial<DatasetAnnotation>>(filteredAnnotation, editData ?? {});
  },
);

export const selectColumnAnnotationEdits = createSelector(
  selectDatasetEdits,
  (_: RootState, datasetId: string, column: string) => ({ datasetId, column }),
  (edits: EditedDatasetAnnotations, { datasetId, column }) => edits[datasetId]?.columns?.[column],
);

export const selectMergedColumnAnnotation = createSelector(
  selectDatasetAnnotations,
  selectDatasetEdits,
  selectDeleted,
  (_: RootState, id: string, column: string) => ({ id, column }),
  (
    annotations: DatasetAnnotations,
    edits: EditedDatasetAnnotations,
    deleted: Record<string, string[]>,
    { id, column },
  ) => {
    const annoationData =
      annotations[id]?.kind === 'data' ? annotations[id]?.data : ({} as DatasetAnnotation);
    const filteredAnnotation = {
      ...annotations[id],
      columns: filterDeletedColumns(annoationData.columns ?? {}, deleted[id] ?? []),
    };
    const annotation = merge(filteredAnnotation, edits[id] ?? {});
    return annotation?.columns?.[column];
  },
);

export const selectAnnotationColumnNames = createSelector(
  selectDatasetAnnotations,
  selectDatasetEdits,
  selectDeleted,
  (_: RootState, id: string) => id,
  (annotations, edits, deleted, id) => {
    const annotationData =
      annotations[id]?.kind === 'data' ? annotations[id]?.data : ({} as DatasetAnnotation);
    const editData = edits[id] ?? ({} as DatasetAnnotation);
    const mergedAnnotations = merge(annotationData, editData);
    let columns = mergedAnnotations?.columns ?? {};
    columns = filterDeletedColumns(columns, deleted[id] ?? []);
    return Object.keys(columns);
  },
);

export const selectAllSafeToShare = createSelector(
  selectDatasetAnnotations,
  selectDatasetEdits,
  selectDeleted,
  (_: RootState, id: string, allColumns: string[]) => ({
    id,
    allColumns,
  }),
  (annotations, edits, deleted, { id, allColumns }) => {
    const annotationData =
      annotations[id]?.kind === 'data' ? annotations[id]?.data : ({} as DatasetAnnotation);
    const columnAnnotations = getMergedColumnAnnotations(
      annotationData,
      edits[id] ?? {},
      deleted[id] ?? [],
    );
    return getAllSafeToShare(columnAnnotations, allColumns);
  },
);
