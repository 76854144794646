import { Typography as antTypography } from 'antd';
import { LinkProps } from 'antd/es/typography/Link';
import { TextProps } from 'antd/es/typography/Text';
import { TitleProps } from 'antd/es/typography/Title';
import styled, { css } from 'styled-components';
import { TColors } from '../../theme/colors';

interface ITypographyTitleProps extends TitleProps {
  color?: keyof TColors;
  align?: 'left' | 'right' | 'center' | 'justify' | 'start' | 'end';
  // add new props here
}

const Title = styled(antTypography.Title)<ITypographyTitleProps>`
  margin-top: 0px;

  ${(props) =>
    props?.color &&
    css`
      color: ${props.theme.colors[props.color]} !important;
    `}

  ${(props) =>
    props?.align &&
    css`
      text-align: ${props.align};
    `}
`;

interface ITypographyTextProps extends TextProps {
  color?: keyof TColors;
  align?: 'left' | 'right' | 'center' | 'justify' | 'start' | 'end';
  // add new props here
}

const Text = styled(antTypography.Text)<ITypographyTextProps>``;

interface ITypographyLinkProps extends LinkProps {
  color?: keyof TColors;
  align?: 'left' | 'right' | 'center' | 'justify' | 'start' | 'end';
  // add new props here
}

const Link = styled(antTypography.Link)<ITypographyLinkProps>``;

export { Title, Text, Link };
