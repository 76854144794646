import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  SEND_CONTACT_FORM_REQUEST,
  closeContactForm,
  sendContactFormFailure,
  sendContactFormSuccess,
} from '../actions/contact_form.actions';

import { sendContactForm } from '../../api/contact_form.api';
import { TOAST_ERROR, TOAST_SHORT, TOAST_SUCCESS } from '../../constants/toast';
import { createAlertChannelRequest } from '../actions/dialog.actions';
import { addToast } from '../actions/toast.actions';
import { selectAccessToken } from './selectors';

/**
 * Posts a new notification to the server
 */
export function* sendContactFormWorker({ contactForm }) {
  try {
    const accessToken = yield select(selectAccessToken);
    yield call(sendContactForm, accessToken, contactForm);
    yield put(sendContactFormSuccess());
    yield put(closeContactForm());
    yield put(
      addToast({
        toastType: TOAST_SUCCESS,
        length: TOAST_SHORT,
        message: 'Message sent!',
      }),
    );
  } catch (error) {
    yield put(sendContactFormFailure({ error }));
    yield put(createAlertChannelRequest({ error }));
    yield put(
      addToast({
        toastType: TOAST_ERROR,
        length: TOAST_SHORT,
        message: 'Message failed.',
      }),
    );
  }
}

/**
 * Listens for the most recent notification related
 * actions from the client.
 */
export default function* apiWatcher() {
  yield takeLatest(SEND_CONTACT_FORM_REQUEST, sendContactFormWorker);
}
