import StopIcon from '@mui/icons-material/Stop';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { CancelCurrentTask } from '../../../store/slices/task.slice';
import './CancelButton.scss';

/** The Data Assistant's cancel button. */
const CancelButton = () => {
  const dispatch = useDispatch();

  /**
   * Have we processed a cancellation since the component was rendered?
   * This will be reset when the component is unmounted & remounted
   */
  const [hasCancelled, setHasCancelled] = useState(false);

  return (
    <Tooltip title="Cancel">
      <span>
        <Fab
          aria-label="Data Assistant Cancel Button"
          data-testid="CancelButton"
          disabled={hasCancelled}
          color="primary"
          className="CancelButton"
          onClick={() => {
            setHasCancelled(true);
            dispatch(CancelCurrentTask());
          }}
        >
          <StopIcon />
        </Fab>
      </span>
    </Tooltip>
  );
};

export default CancelButton;
