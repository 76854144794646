/**
 * This file manages the state of the polling mechanism
 */
import { RESTART_POLLING_MECHANISM, STOP_POLLING_MECHANISM } from '../actions/poll.actions';

const initialState = {
  isPolling: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RESTART_POLLING_MECHANISM:
      return { ...state, isPolling: true };
    case STOP_POLLING_MECHANISM:
      return initialState;
    default:
      return state;
  }
};
