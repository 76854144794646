import Person from '@mui/icons-material/Person';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { selectUserID } from '../../../../store/sagas/selectors';
import { selectSessionCollaborators } from '../../../../store/selectors/session.selector';
import { ScreenSizeContext } from '../../../DataAssistant/DataAssistant';
import AvatarWrap from '../../../Header/AvatarWrap';
import './Conversation.scss';
import TextMessage from './Messages/TextMessage';

/**
 * UserQuestion renders the user's question.
 */
const UserQuestion = ({ id, messages, isPublic, userId }) => {
  const loggedInUserId = useSelector(selectUserID);
  const sessionCollaborators = useSelector(selectSessionCollaborators);

  // Use userId from node's metadata if available, otherwise use logged in user's id.
  const effectiveUserId = userId || loggedInUserId;

  /**
   * Initialize user details for collaborator avatars.
   * Otherwise, set empty and render a generic profile icon.
   */
  const { id: organizationMemberId = '', email: organizationMemberEmail = '' } =
    sessionCollaborators?.find((c) => c.id === effectiveUserId) ?? {};

  const isSmallScreen = useContext(ScreenSizeContext);
  return (
    <div
      id={id}
      data-testid={id}
      className={classNames('ConversationNode', 'UserQuestion', { SmallScreenSize: isSmallScreen })}
    >
      <div className={classNames('ConversationNode-ChatIcon', { SmallScreenSize: isSmallScreen })}>
        {/* Only render the avatar if the user is logged in and is a member of organization.
        otherwise render a generic icon. */}
        {!isPublic && organizationMemberId ? (
          <AvatarWrap
            userId={organizationMemberId}
            name={organizationMemberEmail}
            height="inherit"
            width="inherit"
            fontSize={isSmallScreen ? 0.8 : 1.25}
            showDefaultColor
          />
        ) : (
          <Person sx={{ color: 'action.active' }} />
        )}
      </div>
      <div
        className={classNames('ConversationNode-ChatBubble', { SmallScreenSize: isSmallScreen })}
      >
        {messages.map((msg, i) => (
          <TextMessage
            // Okay to use index as key here because the messages are static.
            // eslint-disable-next-line react/no-array-index-key
            key={`${id}-${i}-user-question`}
            data={msg?.display}
            id={`${id}-user-question`}
          />
        ))}
      </div>
    </div>
  );
};

UserQuestion.propTypes = {
  id: PropTypes.string.isRequired,
  // Question nodes are a list of messages just like Response nodes,
  // even though only one message is expected in a question node.
  // This is done for consistency in handling nodes.
  messages: PropTypes.array.isRequired,
  isPublic: PropTypes.bool,
  userId: PropTypes.number,
};

UserQuestion.defaultProps = {
  isPublic: false,
};

export default UserQuestion;
