import { AxiosResponse } from 'axios';
import { call, delay } from 'redux-saga/effects';
import { makePercentageBackoff } from '../../../utils/backoff_calculations';
import { callAPIWithRetry } from './retry';

type PollingFnArgs = [accessToken: string, signal: AbortSignal, args: object];
type CallWithPollingParams<T = any> = {
  accessToken: string;
  // The API (function) to call
  fn: (accessToken: string, signal: AbortSignal, args: any) => Promise<AxiosResponse<T>>;
  // The abort signal for request cancellation
  signal: AbortSignal;
  // The arguments to pass to the function (fn)
  args: object;
  retry?: boolean;
};

type CallWithPollingResponse<T = any> = Generator<object, AxiosResponse<T>, AxiosResponse<T>>;

function* callConditionalRetry<T, Args extends PollingFnArgs>(
  retry: boolean,
  fn: (...args: Args) => Promise<AxiosResponse<T>>,
  ...args: Args
): Generator<object, AxiosResponse<T>, AxiosResponse<T>> {
  return yield retry ? call(callAPIWithRetry, { apiFn: fn, args }) : call(fn, ...args);
}

/**
 * Calls an API (fn). Polls if the response is 202.
 */
export function* callWithPolling<T = any>(
  params: CallWithPollingParams<T>,
): CallWithPollingResponse<T> {
  const { accessToken, fn, signal, args, retry = false } = params;
  const newArgs: PollingFnArgs = [accessToken, signal, args];

  let response = yield* callConditionalRetry(retry, fn, ...newArgs);

  const percentageBackoff = makePercentageBackoff();
  while (response.status === 202) {
    yield delay(percentageBackoff());
    response = yield* callConditionalRetry(retry, fn, ...newArgs);
  }

  return response;
}
