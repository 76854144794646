import cloneDeep from 'lodash/cloneDeep';
import { call, getContext, put, select, takeLatest } from 'redux-saga/effects';
import { API_SERVICES } from '../../constants/api';
import { INSIGHTS_BOARD_ERRORS } from '../../pages/authenticated/InsightsBoardPage/constants';
import {
  GET_CROSS_FILTERS_FAILURE,
  GET_CROSS_FILTERS_REQUEST,
  SAVE_CROSS_FILTERS_FAILURE,
  SAVE_CROSS_FILTERS_REQUEST,
  getCrossFiltersFailure,
  getCrossFiltersSuccess,
  saveCrossFiltersFailure,
  saveCrossFiltersSuccess,
} from '../actions/crossFilter.actions';
import {
  selectAccessToken,
  selectDeletedCrossFilterGroups,
  selectDeletedCrossFilters,
  selectUpdatedCrossFilterGroups,
} from './selectors';
import { generalInsightsBoardErrorWorker } from './utils/alert-channels';

/**
 * Convert the cross_filters call to a format that the API expects
 * Change key crossFilters to cross_filters and stringify the transform
 * @param {Object} crossFilterGroups
 */
export const formatCrossFiltersCall = (crossFilterGroups) => {
  const updatedCrossFilterGroups = cloneDeep(crossFilterGroups);

  Object.entries(updatedCrossFilterGroups).forEach(([groupId, group]) => {
    updatedCrossFilterGroups[groupId].cross_filters = group.crossFilters.map((f) => ({
      ...f,
      transform: JSON.stringify(f.transform),
    }));
    delete updatedCrossFilterGroups[groupId].crossFilters;
  });

  return updatedCrossFilterGroups;
};

/**
 * Convert the cross_filters response to a format that the FE can use
 * Change key cross_filters to crossFilters and parse the transform JSON
 * @param {Object} data The response from the API
 */
export const formatCrossFiltersResponse = (data) => {
  const updatedCrossFilterGroups = {};

  Object.entries(data).forEach(([groupId, group]) => {
    updatedCrossFilterGroups[groupId] = {
      ...group,
      crossFilters: group.cross_filters.map((f) => ({ ...f, transform: JSON.parse(f.transform) })),
    };
    delete updatedCrossFilterGroups[groupId].cross_filters;
  });

  return updatedCrossFilterGroups;
};

/**
 * Retrieve the cross filters from the BE
 */
export function* getCrossFiltersWorker({ insightsBoardId }) {
  try {
    const accessToken = yield select(selectAccessToken);
    const insightsBoardService = yield getContext(API_SERVICES.INSIGHTS_BOARD);
    const response = yield call(
      insightsBoardService.getInsightsBoardCrossFilters,
      accessToken,
      insightsBoardId,
    );
    yield put(
      getCrossFiltersSuccess({ crossFilterGroups: formatCrossFiltersResponse(response.data) }),
    );
  } catch (error) {
    yield put(getCrossFiltersFailure({ error }));
  }
}

/**
 * Save the new cross filters to the BE in a specific format
 * Updates the state of the cross filters with the response
 */
export function* saveCrossFiltersWorker({ insightsBoardId }) {
  try {
    const accessToken = yield select(selectAccessToken);
    const crossFilterGroups = yield select(selectUpdatedCrossFilterGroups);

    const crossFilterUpdates = {
      // The cross filters that were changed since the last interaction with the API
      cross_filter_groups: formatCrossFiltersCall(crossFilterGroups),
      // The cross filters that were deleted since the last interaction with the API
      cross_filters_to_remove: yield select(selectDeletedCrossFilters),
      // The groups that were deleted since the last interaction with the API
      groups_to_remove: yield select(selectDeletedCrossFilterGroups),
    };

    const insightsBoardService = yield getContext(API_SERVICES.INSIGHTS_BOARD);
    const response = yield call(
      insightsBoardService.saveInsightsBoardCrossFilters,
      accessToken,
      insightsBoardId,
      crossFilterUpdates,
    );
    yield put(
      saveCrossFiltersSuccess({ crossFilterGroups: formatCrossFiltersResponse(response.data) }),
    );
  } catch (error) {
    yield put(saveCrossFiltersFailure({ error }));
  }
}

// Error handler for getting cross filters. Opens a dialog
export function* getCrossFiltersFailureWorker({ error }) {
  yield call(generalInsightsBoardErrorWorker, error, INSIGHTS_BOARD_ERRORS.GET_CROSS_FILTERS);
}

// Error handler for saving cross filters. Opens a dialog
export function* saveCrossFiltersFailureWorker({ error }) {
  yield call(generalInsightsBoardErrorWorker, error, INSIGHTS_BOARD_ERRORS.SAVE_CROSS_FILTERS);
}

export default function* () {
  yield takeLatest(GET_CROSS_FILTERS_REQUEST, getCrossFiltersWorker);
  yield takeLatest(SAVE_CROSS_FILTERS_REQUEST, saveCrossFiltersWorker);
  yield takeLatest(GET_CROSS_FILTERS_FAILURE, getCrossFiltersFailureWorker);
  yield takeLatest(SAVE_CROSS_FILTERS_FAILURE, saveCrossFiltersFailureWorker);
}
