import {
  AggregateExpressions,
  aggregateExpressionsDisplay,
  ChartTypes,
  getBinChipTitle,
  replaceValInObj,
} from 'translate_dc_to_echart';
import { getAggregatedInputs, getBinnedInputs, HISTOGRAM_Y_AXIS_TITLE } from './constants';

/**
 * Updated any column names with the bin title if they are binned
 * @param {Object} mark The series.mark chartSpec information
 * @param {Object} group The series.group chartSpec information
 * @param {Array} bins The chartSpec's binning information
 * @param {String} chartType The type of the chart, ex. 'scatter'
 * @param {Array} targetedKeys Keys in mark/group whose columns we would like
 * to update with the new bin title (if any)
 * @returns {Array} Updated mark and group with the bin titles
 */
const updateColumnNamesWithBins = (mark, group, bins, chartType, targetedKeys) => {
  // For each mark/group key that we're binning, update the column name with the bin title
  for (const key of targetedKeys) {
    const binnedMarkColumns = mark[key] || [];
    const binnedGroupColumns = group[key] || [];

    for (const bin of bins) {
      const colName = bin.column;
      const binName = getBinChipTitle(bin);

      if (binnedMarkColumns.includes(colName)) {
        mark = replaceValInObj(mark, colName, binName);
      }

      if (binnedGroupColumns.includes(colName)) {
        group = replaceValInObj(group, colName, binName);
      }
    }
  }

  return [mark, group];
};

/**
 * Generates the title for a scatter chart
 * @param {string} xText x-axis text
 * @param {string} yText y-axis text
 * @param {string} group group key of the series
 * @param {string} mark mark key of the series
 * @returns {string} chart title
 */
const generateScatterTitle = (xText, yText, mark, group, bins) => {
  [mark, group] = updateColumnNamesWithBins(mark, group, bins, ChartTypes.scatter, [
    'color',
    'subplot',
    'label',
  ]);

  let title = '';
  title += `${yText} vs. ` || '';
  title += xText || '';
  title += mark.color && mark.shape ? `, clustured using: ${mark.color}` : '';
  title += group.subplot ? `, subplotted using: ${group.subplot}` : '';
  title += mark.label ? `, labeled using: ${mark.label}` : '';
  return title;
};

/**
 * Generates the title for a bubble chart
 * @param {string} xText x-axis text
 * @param {string} yText y-axis text
 * @param {string} mark mark key of the series
 * @returns {string} chart title
 */
const generateBubbleTitle = (xText, yText, mark, group, bins) => {
  [mark, group] = updateColumnNamesWithBins(mark, group, bins, ChartTypes.bubble, [
    'color',
    'label',
  ]);

  let title = '';
  title += `${yText} vs. ` || '';
  title += xText || '';
  title += mark.size ? `, sized using: ${mark.size}` : '';
  title += mark.color ? `, colored using: ${mark.color}` : '';
  title += mark.label ? `, labeled using: ${mark.label}` : '';
  return title;
};

/**
 * Generates the title for a donut chart
 * @param {string} xText x-axis/column text
 * @param {Object} group group key of the series
 * @param {string} aggregateExpression aggregate expression applied
 * @returns {string} chart title
 */
const generateDonutTitle = (xText, mark, group, aggregateExpression, bins) => {
  [mark, group] = updateColumnNamesWithBins(mark, group, bins, ChartTypes.donut, [
    'subplot',
    'slider',
  ]);

  let title = '';
  switch (aggregateExpression) {
    case AggregateExpressions.none:
      title += xText;
      break;
    case AggregateExpressions.count:
      title += `Count of ${xText}`;
      break;
    default:
      // When no aggregate is specified, donuts always aggregate by count of records
      title +=
        aggregateExpression === AggregateExpressions.countOfRecords
          ? aggregateExpressionsDisplay[aggregateExpression]
          : `${aggregateExpressionsDisplay[aggregateExpression]} ${xText}`;
      break;
  }

  // Conditionally grab our bin title, from the group.color or mark.x
  const splitByCol = group.color || mark.x;
  const binTitle = bins?.find((bin) => bin.column === splitByCol)?.title;
  const splitByTitle = binTitle || splitByCol;

  title += ` split by ${splitByTitle}`;
  title += group.subplot ? ` for each ${group.subplot}` : '';
  title += group.slider ? ` sliding by ${group.slider}` : '';
  return title;
};

/**
 * Generates the title for a heatmap
 * @param {string} xText x-axis text
 * @param {string} yText y-axis text
 * @param {Object} mark mark key of the series
 * @param {string} aggregateExpression aggregate expression applied
 * @returns {string} chart title
 */
const generateHeatmapTitle = (xText, yText, mark, group, aggregateExpression, bins) => {
  [mark, group] = updateColumnNamesWithBins(mark, group, bins, ChartTypes.heatmap, []);

  let title = '';
  title += aggregateExpression ? `${aggregateExpression} ` : '';
  title += mark.density ? `${mark.density} for each ` : '';
  title += xText ? `${xText}, ` : '';
  title += yText || '';
  return title;
};

/**
 * Generates the title for a line chart
 * @param {string} xText x-axis text
 * @param {string} yText y-axis text
 * @param {Object} mark mark key of the series
 * @returns {string} chart title
 */
const generateLineTitle = (xText, yText, mark, group, bins) => {
  [mark, group] = updateColumnNamesWithBins(mark, group, bins, ChartTypes.line, ['color', 'label']);

  let title = '';
  title += `${yText} vs. ` || '';
  title += xText || '';
  title += mark.label ? `, labeled using: ${mark.label}` : '';
  title += mark.shape && mark.color ? `, for each: ${mark.color}` : '';
  return title;
};

/**
 * Generates the title for a stacked area chart
 * @param {string} xText x-axis text
 * @param {string} yText y-axis text
 * @param {Object} mark mark key of the series
 * @returns {string} chart title
 */
const generateStackedAreaTitle = (xText, yText, mark, group, bins) => {
  [mark, group] = updateColumnNamesWithBins(mark, group, bins, ChartTypes.stackedArea, [
    'color',
    'label',
  ]);

  let title = '';
  title += `${yText} vs. ` || '';
  title += xText || '';
  title += mark.label ? `, labeled using: ${mark.label}` : '';
  title += mark.shape && mark.color ? `, for each: ${mark.color}` : '';
  return title;
};

/**
 * Generates the title for a box plot
 * @param {string} xText x-axis text
 * @param {string} yText y-axis text
 * @param {Object} mark mark key of the series
 * @returns {string} chart title
 */
const generateBoxplotTitle = (xText, yText, mark, group, bins) => {
  [mark, group] = updateColumnNamesWithBins(mark, group, bins, ChartTypes.box, ['color']);

  let title = '';
  title += `${yText} vs. ` || '';
  title += xText || '';
  title += mark.color ? `, ${mark.color}` : '';
  return title;
};

/**
 * Generates the title for a violin chart
 * @param {string} xText x-axis text
 * @param {string} yText y-axis text
 * @param {Object} group group key of the series
 * @returns {string} chart title
 */
const generateViolinTitle = (xText, yText, mark, group, bins) => {
  [mark, group] = updateColumnNamesWithBins(mark, group, bins, ChartTypes.violin, []);

  let title = '';
  title += `${yText} vs. ` || '';
  title += xText || '';
  title += group.split ? `, splitting on ${group.split}` : '';
  return title;
};

/**
 * Generates the title for a ridgeline chart
 * @param {string} xText x-axis text
 * @param {string} yText y-axis text
 * @param {Object} group group key of the series
 * @returns {string} chart title
 */
const generateRidgelineTitle = (xText, yText, mark, group, bins) => {
  [mark, group] = updateColumnNamesWithBins(mark, group, bins, ChartTypes.ridgeline, []);

  let title = '';
  title += `${yText} vs. ` || '';
  title += xText || '';
  title += group.split ? `, splitting on ${group.split}` : '';
  return title;
};

/**
 * Generates the title for a bar chart
 * @param {string} xText x-axis text
 * @param {string} yText y-axis text
 * @param {Object} mark mark key of the series
 * @returns {string} chart title
 */
const generateBarTitle = (xText, yText, mark, group, bins) => {
  [mark, group] = updateColumnNamesWithBins(mark, group, bins, ChartTypes.bar, ['color']);

  let title = '';
  title += yText || '';
  title += mark.overlay ? `, with ${mark.overlay} overlayed,` : '';
  title += ' vs. ';
  title += xText || '';
  title += mark.color ? `, for each: ${mark.color}` : '';
  return title;
};

/**
 * Generates the title for a histogram
 * @param {string} xText x-axis text
 * @returns {string} chart title
 */
const generateHistogramTitle = (xText) => `${HISTOGRAM_Y_AXIS_TITLE} of ${xText}`;

/**
 * Generates the title for a bar chart
 * @param {string} xText x-axis text
 * @param {string} yText y-axis text
 * @param {Object} mark mark key of the series
 * @returns {string} chart title
 */
const generateHorizontalBarTitle = (xText, yText, mark, group, bins) => {
  [mark, group] = updateColumnNamesWithBins(mark, group, bins, ChartTypes.horizBar, ['color']);

  let title = '';
  title += yText || '';
  title += mark.overlay ? `, with ${mark.overlay} overlayed,` : '';
  title += ' vs. ';
  title += xText || '';
  title += mark.color ? `, for each: ${mark.color}` : '';
  return title;
};

/**
 * Generates the title for a stacked bar chart
 * @param {string} xText x-axis text
 * @param {string} yText y-axis text
 * @param {Object} mark mark key of the series
 * @returns {string} chart title
 */
const generateStackedBarTitle = (xText, yText, mark, group, bins) => {
  [mark, group] = updateColumnNamesWithBins(mark, group, bins, ChartTypes.stackedBar, ['color']);

  let title = '';
  title += yText || '';
  title += mark.color ? `, with ${mark.color} stacked,` : '';
  title += ' vs. ';
  title += xText || '';
  return title;
};

/**
 * Generates the title for a single metric chart
 * @returns {string} chart title
 */
const generateSingleMetricTitle = (mark, aggregateExpression) => {
  let title = '';
  title += aggregateExpression !== 'None' ? `${aggregateExpression} ` : '';
  title += aggregateExpression === 'Count' ? 'of ' : '';
  title += mark.column || '';
  return title;
};

/**
 * Generates the chart title for a given chart type
 * @param {String} xText x-axis text
 * @param {String} yText y-axis text
 * @param {Object} group group key of the series
 * @param {Object} mark mark key of the series
 * @param {Object} fields key/value pairs for fields in the chart builder
 * @param {String} type chart type
 * @param {String} aggregateExpression aggregate expression applied
 * @param {Array} bins The chart spec's bins
 * @returns {string} chart title
 */
export const generateTitle = (
  xText,
  yText,
  group,
  mark,
  fields,
  type,
  aggregateExpression,
  bins,
) => {
  switch (type) {
    case ChartTypes.scatter:
      return generateScatterTitle(xText, yText, mark, group, bins);
    case ChartTypes.bubble:
      return generateBubbleTitle(xText, yText, mark, group, bins);
    case ChartTypes.donut:
      return generateDonutTitle(xText, mark, group, aggregateExpression, bins);
    case ChartTypes.heatmap:
      return generateHeatmapTitle(xText, yText, mark, group, aggregateExpression, bins);
    case ChartTypes.line:
      return generateLineTitle(xText, yText, mark, group, bins);
    case ChartTypes.stackedArea:
      return generateStackedAreaTitle(xText, yText, mark, group, bins);
    case ChartTypes.box:
      return generateBoxplotTitle(xText, yText, mark, group, bins);
    case ChartTypes.violin:
      return generateViolinTitle(xText, yText, mark, group, bins);
    case ChartTypes.ridgeline:
      return generateRidgelineTitle(xText, yText, mark, group, bins);
    case ChartTypes.bar:
      return generateBarTitle(xText, yText, mark, group, bins);
    case ChartTypes.horizBar:
      return generateHorizontalBarTitle(xText, yText, mark, group, bins);
    case ChartTypes.stackedBar:
      return generateStackedBarTitle(xText, yText, mark, group, bins);
    case ChartTypes.singleMetric:
      return generateSingleMetricTitle(mark, aggregateExpression);
    case ChartTypes.histogram:
      return generateHistogramTitle(xText);
    default:
      return '';
  }
};

/**
 * Generate axis titles by parsing all objects in the series array
 * @param {String} axis 'x-axis', 'y-axis', or 'overlay'
 * @param {Object[]} series Created by generateSeries
 * @param {String} chartType Ex. 'scatter', 'donut', etc.
 * @param {Array} aggregateInputs The inputs that we aggregate for the chart type
 * @param {String} expression One of the AggregateExpressions
 * @param {Array} binInputs The inputs that we bin for the chart type
 * @param {Array} bins The array of bins from the chart spec
 * @returns {String} The axis title
 */
export const generateAxisTitle = (axis, series, chartType, expression, bins) => {
  const axisSet = new Set();
  const axisShorthand = axis.split('-')[0];

  const aggregateInputs = getAggregatedInputs(chartType);
  const binInputs = getBinnedInputs(chartType);

  for (const value of series) {
    // Continue generating an axis title for an overlay but it's not in the series
    if (axis === 'overlay' && !value.mark.overlay) continue;

    // Static y-axis name and raw x-axis column name for histograms
    if (chartType === ChartTypes.histogram) {
      if (axis === 'y-axis') return HISTOGRAM_Y_AXIS_TITLE;
      if (axis === 'x-axis') return value.mark.x;
    }

    if (aggregateInputs.includes(axis) && expression !== undefined && expression !== 'None') {
      const axisTitlePart =
        expression === AggregateExpressions.countOfRecords
          ? aggregateExpressionsDisplay[expression]
          : `${aggregateExpressionsDisplay[expression]} ${value.mark[axisShorthand]}`;
      axisSet.add(axisTitlePart);
    } else if (
      bins &&
      bins.length > 0 &&
      bins.some((b) => b.column === value.mark[axisShorthand]) &&
      binInputs.includes(axis) &&
      (axis !== 'x-axis' || chartType !== 'donut')
    ) {
      const axisBin = bins.find((b) => b.column === value.mark[axisShorthand]);
      axisSet.add(getBinChipTitle(axisBin));
    } else {
      axisSet.add(value.mark[axisShorthand]);
    }
  }

  return Array.from(axisSet).join(', ');
};
