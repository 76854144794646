import { Menu as antMenu, MenuProps } from 'antd';
import styled from 'styled-components';

type IMenuProps = MenuProps;

const Menu = styled(antMenu)<IMenuProps>``;

export type MenuItem = Required<MenuProps>['items'][number];
export type { MenuProps } from 'antd';

export default Menu;
