import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import { ReactComponent as ExportMultipleFigureIcon } from '../../../../../assets/icons/download_multi.svg';
import { isWorkflowEditorPage } from '../../../../../constants/paths';
import { TOAST_ERROR, TOAST_SHORT, showReplayDisableToast } from '../../../../../constants/toast';
import { requestBulkExport } from '../../../../../store/actions/bulk_charts_export.action';
import { addToast } from '../../../../../store/actions/toast.actions';
import {
  selectCurrentLocation,
  selectIsExportingRequestInitialized,
} from '../../../../../store/sagas/selectors';
import { selectIsStepwise } from '../../../../../store/selectors/context.selector';

/**
 * A functional component to render a button UI to export bulk echart(i.e. chart with slider)
 */
const ExportBulkFigureButton = (props) => {
  // redux dispatch hook
  const dispatch = useDispatch();

  const {
    isReplaying,
    isStepwise,
    isWorkflowEditor,
    sessionType,
    messageType,
    data,
    isExportingRequestInitialized,
    objectId,
    isPopOutChart,
  } = props;

  // download the table or chart
  const handleExportButtonClick = () => {
    // show toast during not stepwise replaying
    if (isReplaying && !isStepwise) {
      showReplayDisableToast(addToast, 'export', dispatch);
      return;
    }
    if (isExportingRequestInitialized) {
      dispatch(
        addToast({
          toastType: TOAST_ERROR,
          length: TOAST_SHORT,
          message: 'Another exporting request is being processed!',
        }),
      );
      return;
    }
    dispatch(
      requestBulkExport({
        exportObjectID: objectId,
        isExportedFromPopOutChart: isPopOutChart,
        isExportedFromChartBuilder: false,
      }),
    );
  };

  // helper function for checking each series has a slider
  const chartHasSlider = useCallback(() => {
    for (const series of data.plot.series) {
      if (!series?.group?.slider) {
        return false;
      }
    }
    return true;
  }, [data]);

  // Returns true if the export button should be shown, otherwise, returns false.
  const showExportFigureButton = useCallback(
    () => {
      // Disable export for describe tables
      if (data?.title === `${data?.name}_Describe`) {
        return false;
      }

      if (data && messageType && sessionType && !isWorkflowEditor) {
        switch (messageType) {
          case 'viz':
            // only for echart and chart has slider
            return (
              data.typeVersion === 2 &&
              data.plot.series &&
              data.values.reference &&
              !data.isDependencyGraph &&
              chartHasSlider()
            );
          default:
            return false;
        }
      } else {
        return false;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, isWorkflowEditor],
  );

  return (
    <>
      {showExportFigureButton() ? (
        <MenuItem
          key="Export bulk chart"
          className="Menu-Item-Container"
          onClick={handleExportButtonClick}
        >
          <div
            className={`${isReplaying && !isStepwise ? 'Menu-Item Replaying' : 'Menu-Item'}`}
            data-cy="Menu-Item-Export-Figure"
          >
            <ExportMultipleFigureIcon className="Menu-Item-Icon" />
            <span className="Menu-Item-Text">Export all slides</span>
          </div>
        </MenuItem>
      ) : null}
    </>
  );
};

ExportBulkFigureButton.propTypes = {
  isReplaying: PropTypes.bool.isRequired,
  isStepwise: PropTypes.bool.isRequired,
  isWorkflowEditor: PropTypes.bool.isRequired,
  sessionType: PropTypes.string.isRequired,
  messageType: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  objectId: PropTypes.string.isRequired,
  isExportingRequestInitialized: PropTypes.bool.isRequired,
  isPopOutChart: PropTypes.bool,
};

ExportBulkFigureButton.defaultProps = {
  isPopOutChart: false,
};

const mapStateToProps = (state) => ({
  sessionType: state.session.sessionType,
  isStepwise: selectIsStepwise(state),
  isReplaying: state.context.isReplaying,
  isWorkflowEditor: isWorkflowEditorPage(selectCurrentLocation(state)),
  isExportingRequestInitialized: selectIsExportingRequestInitialized(state),
});

export default connect(mapStateToProps, {})(ExportBulkFigureButton);
