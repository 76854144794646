export const GET_PUBLIC_CONVERSATION_REQUEST = 'avaPublic/GET_PUBLIC_CONVERSATION_REQUEST';
export const GET_PUBLIC_CONVERSATION_SUCCESS = 'avaPublic/GET_PUBLIC_CONVERSATION_SUCCESS';
export const GET_PUBLIC_CONVERSATION_FAILURE = 'avaPublic/GET_PUBLIC_CONVERSATION_FAILURE';

export const getPublicConversationRequest = ({ sessionId }) => ({
  type: GET_PUBLIC_CONVERSATION_REQUEST,
  sessionId,
});

export const getPublicConversationSuccess = () => ({
  type: GET_PUBLIC_CONVERSATION_SUCCESS,
});

export const getPublicConversationFailure = () => ({
  type: GET_PUBLIC_CONVERSATION_FAILURE,
});
