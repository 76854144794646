import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import React from 'react';
import '../ChartBuilder.scss';

/**
 * Renders arrows for all dropdown toggles
 * @returns {JSX.Element} The icon for up/down arrows
 */
const DropdownArrow = (props) => {
  const { componentName, disabled, expanded, format, onClick } = props;

  return (
    <IconButton
      sx={{ alignSelf: 'center' }}
      data-cy={`${componentName}-Drop${expanded ? 'Up' : 'Down'}Arrow`}
      disabled={disabled}
      // Only add the onClick to the footer arrows. Menu accepts clicks on the whole row instead
      onClick={() => (format === 'footer' ? onClick() : null)}
    >
      {expanded && !disabled ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
    </IconButton>
  );
};

DropdownArrow.propTypes = {
  // The dropdown component that we're rendering, ex. 'Describe', 'RequiredFields', 'Filter', etc.
  componentName: PropTypes.string.isRequired,
  // Should the arrow be disabled?
  disabled: PropTypes.bool,
  // Is the dropdown currently expanded?
  expanded: PropTypes.bool.isRequired,
  // The format of the dropdown. This tells us which styles to apply and where to add the onClick
  format: PropTypes.string.isRequired,
  // The onClick function to attach to the arrow
  onClick: PropTypes.func,
};

DropdownArrow.defaultProps = {
  disabled: false,
  onClick: () => {},
};

export default DropdownArrow;
