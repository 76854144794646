import {
  BULK_EXPORT_CANCEL_CURRENT_EXPORTING,
  BULK_EXPORT_CONSUME_CHARTSPEC_QUEUE,
  BULK_EXPORT_LOAD_SPEC,
  BULK_EXPORT_REPORT_PROGRESS,
  BULK_EXPORT_REQUEST,
} from '../actions/bulk_charts_export.action';

export const initialState = {
  isExportingRequestInitialized: false,
  exportObjectID: undefined,
  isExportedFromPopOutChart: false,
  isExportedFromChartBuilder: false,
  progress: 0,
  chartSpecQueue: [],
};

/**
 * @param {Object} state
 * @param {Object} action
 * @returns {Object} new state
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case BULK_EXPORT_REQUEST:
      return {
        ...state,
        isExportingRequestInitialized: true,
        exportObjectID: action.exportObjectID,
        isExportedFromPopOutChart: action.isExportedFromPopOutChart,
        isExportedFromChartBuilder: action.isExportedFromChartBuilder,
      };
    case BULK_EXPORT_LOAD_SPEC:
      return {
        ...state,
        chartSpecQueue: [...state.chartSpecQueue, action.chartSpec],
        isExportingRequestInitialized: false,
        exportObjectID: undefined,
        isExportedFromPopOutChart: false,
        isExportedFromChartBuilder: false,
      };
    case BULK_EXPORT_CONSUME_CHARTSPEC_QUEUE:
      return {
        ...state,
        chartSpecQueue: state.chartSpecQueue.slice(1), // remove the chart spec that is being processed
        progress: 0,
      };
    case BULK_EXPORT_CANCEL_CURRENT_EXPORTING:
      return {
        ...state,
        isExportingRequestInitialized: false,
        exportObjectID: undefined,
        isExportedFromPopOutChart: false,
        isExportedFromChartBuilder: false,
        chartSpecQueue: state.chartSpecQueue.slice(1),
        progress: 0,
      };
    case BULK_EXPORT_REPORT_PROGRESS:
      return {
        ...state,
        progress: action.progress,
      };

    default:
      return state;
  }
};
