export enum ColumnStatKey {
  Max = 'Max',
  Min = 'Min',
  Mean = 'Mean',
  ApproxUnique = 'Approx Unique',
  Null = 'Null',
  Placeholder = '',
}

// the empty string is to add empty rows in the table for alignment
export const NUMERIC_STATS = [
  ColumnStatKey.Max,
  ColumnStatKey.Min,
  ColumnStatKey.Mean,
  ColumnStatKey.Null,
];
export const STR_STATS = [
  ColumnStatKey.Placeholder,
  ColumnStatKey.ApproxUnique,
  ColumnStatKey.Placeholder,
  ColumnStatKey.Null,
];
export const DATE_STATS = [
  ColumnStatKey.Max,
  ColumnStatKey.Min,
  ColumnStatKey.ApproxUnique,
  ColumnStatKey.Null,
];
export const BOOL_STATS = [
  ColumnStatKey.Placeholder,
  ColumnStatKey.ApproxUnique,
  ColumnStatKey.Placeholder,
  ColumnStatKey.Null,
];
export const DEFAULT_STATS = [
  ColumnStatKey.Placeholder,
  ColumnStatKey.ApproxUnique,
  ColumnStatKey.Placeholder,
  ColumnStatKey.Null,
];

export const ABBREVIATION: { [key in ColumnStatKey]: string } = {
  [ColumnStatKey.ApproxUnique]: 'Uniq (~)',
  [ColumnStatKey.Max]: ColumnStatKey.Max,
  [ColumnStatKey.Min]: ColumnStatKey.Min,
  [ColumnStatKey.Mean]: ColumnStatKey.Mean,
  [ColumnStatKey.Null]: ColumnStatKey.Null,
  [ColumnStatKey.Placeholder]: '',
};

export const STAT_TOOLTIP: { [key in ColumnStatKey]: string } = {
  [ColumnStatKey.Max]: 'Maximum value',
  [ColumnStatKey.Min]: 'Minimum value',
  [ColumnStatKey.Mean]: 'Average value',
  [ColumnStatKey.ApproxUnique]: 'Approx. count of unique values',
  [ColumnStatKey.Null]: 'Percentage of null values',
  [ColumnStatKey.Placeholder]: '',
};

export const STAT_UNAVAILABLE = 'N/A';

// determine whether we should warn the user with different styles of null values
// unit: percentage
export const NULL_THRESHOLD = {
  MEDIUM: 20,
  HIGH: 50,
};

// determine the class name for the values in the column statistics
export enum ValueClass {
  VALUE = 'value',
  WARN_HIGH = 'value warn high',
  WARN_MEDIUM = 'value warn medium',
}
