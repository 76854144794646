import { CLOSE_POP_OUT_MODAL, OPEN_POP_OUT_MODAL } from '../actions/popout.actions';

const initialState = {
  isPopOutModalOpen: false, // Indicates if the chart pop out modal is open
  selectedChart: '',
  isPopoutEditable: true, // Indicates if the chart pop out modal is opened from the data assistant
};

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_POP_OUT_MODAL:
      return {
        ...state,
        isPopOutModalOpen: true,
        selectedChart: action.chartObjectId,
        isPopoutEditable: action.isPopoutEditable,
      };
    case CLOSE_POP_OUT_MODAL:
      return {
        ...state,
        isPopOutModalOpen: false,
        selectedChart: null,
        isPopoutEditable: true,
      };
    default:
      return state;
  }
};
