/* eslint-disable */
import BarChartRounded from '@mui/icons-material/BarChartRounded';
import NotInterested from '@mui/icons-material/NotInterested';
import Refresh from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EDITABLE_OBJECTS } from '../../constants/editor';
import { HOME_OBJECTS, homeObjectIcon } from '../../constants/home_screen';
import { WORKSPACE_VISIBILITY } from '../../constants/workspace';
import { navigateToWorkflowEditor } from '../../store/actions/editor.actions';
import {
  selectAppsById,
  selectChartBackingSnapshotUUIDList,
  selectDeletingObjects,
} from '../../store/sagas/selectors';
import { getFileExtension } from '../../utils/home_screen';
import { HomeObjectKeys, HomeObjectKeysTypes } from '../../utils/homeScreen/types';
import { convertToCalenderDate } from '../../utils/time';
import DemoIndicator from '../DemoIndicator';
import SharedIndicator from '../SharedIndicator';
import ActionIndicator, { HoverAction } from './ActionIndicator';
import './HomeScreenObjectTable.scss';

interface NameTypeCellProps {
  rowData: HomeObjectKeysTypes;
  hoverActions?: HoverAction[];
  showIndicator?: boolean;
}

const NameTypeCell: React.FC<NameTypeCellProps> = ({
  rowData,
  hoverActions,
  showIndicator = false,
}) => {
  const dispatch = useDispatch();
  const objectType = rowData[HomeObjectKeys.TYPE];
  const objectName = rowData[HomeObjectKeys.NAME];

  // TODO: Remove dependence on redux
  const appsById = useSelector(selectAppsById) as Record<string, string>;
  const snapshotsWithCharts = useSelector(selectChartBackingSnapshotUUIDList) as string[];
  const deletingObjects = useSelector(selectDeletingObjects) as string[];

  const isWorkflowBasedDataset =
    rowData[HomeObjectKeys.TYPE] === HOME_OBJECTS.DATASET &&
    Boolean(rowData[HomeObjectKeys.WORKFLOW_FK]) &&
    !rowData[HomeObjectKeys.DATAFILE_FK];

  let workflowRefreshDateTooltip = '';
  if (isWorkflowBasedDataset) {
    const refreshDateString = rowData[HomeObjectKeys.LAST_MODIFIED] ?? '';
    const refreshDate = refreshDateString ? convertToCalenderDate(refreshDateString) : '';
    workflowRefreshDateTooltip = refreshDate ? `Last Refreshed: ${refreshDate}` : '';
  }

  const handleRefreshDataset = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    e.bubbles = false;
    dispatch(
      navigateToWorkflowEditor({
        objectType: EDITABLE_OBJECTS.DATASET_OBJECT,
        objectId: rowData[HomeObjectKeys.UUID],
        autoSave: true,
      }),
    );
  };
  return (
    <div className="NameTypeCell">
      {homeObjectIcon({
        objectType,
        props: { className: 'NameTypeCell-Type' },
        app: rowData[HomeObjectKeys.APP_ID] ? appsById[rowData[HomeObjectKeys.APP_ID]] : {},
        textOverlay:
          objectType === HOME_OBJECTS.DATAFILE ? (getFileExtension(objectName) as string) : '',
        processing: rowData[HomeObjectKeys.UUID]
          ? deletingObjects.includes(rowData[HomeObjectKeys.UUID])
          : false,
      })}
      <span className="NameTypeCell-Name">{rowData.objectName}</span>
      <SharedIndicator rowData={rowData} />
      {isWorkflowBasedDataset && (
        <Tooltip title={workflowRefreshDateTooltip} placement="right">
          <IconButton
            data-testid="refresh-dataset"
            onClick={handleRefreshDataset}
            onMouseDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            size="small"
          >
            <Refresh fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
      {rowData[HomeObjectKeys.UUID] &&
        snapshotsWithCharts.includes(rowData[HomeObjectKeys.UUID]) && (
          <Tooltip title="This snapshot is used by existing charts. Changing it will affect them.">
            <BarChartRounded className="NameTypeCell-Icon" fontSize="medium" />
          </Tooltip>
        )}
      {rowData[HomeObjectKeys.VISIBILITY] === WORKSPACE_VISIBILITY.HIDDEN && (
        <Tooltip title="Hidden">
          <NotInterested className="NameTypeCell-VisibilityOff" fontSize="small" />
        </Tooltip>
      )}
      <DemoIndicator rowData={rowData} />
      {showIndicator && <ActionIndicator actions={hoverActions} />}
    </div>
  );
};

export default NameTypeCell;
