import moment from 'moment';
import { put, race, select, take } from 'redux-saga/effects';
import { getFromLS } from '../../../utils/local_storage';
import { LOAD_APPS_FAILURE, LOAD_APPS_SUCCESS, loadAppsRequest } from '../../actions/apps.actions';
import { checkLicenseExpiryRequest } from '../../actions/license.actions';
import { getUserConfigRequest, getUserRecordRequest } from '../../actions/settings.actions';
import {
  selectHasRequestedApps,
  selectHasRequestedUserConfig,
  selectHasRequestedUserRecord,
  selectIsCheckingLicenseExpiry,
} from '../selectors';

export function* loadUserLevelRequests() {
  const hasRequestedUserConfig = yield select(selectHasRequestedUserConfig);
  const hasRequestedUserRecord = yield select(selectHasRequestedUserRecord);
  const hasRequestedApps = yield select(selectHasRequestedApps);

  const isCheckingLicenseExpiry = yield select(selectIsCheckingLicenseExpiry);

  if (!hasRequestedUserConfig) {
    yield put(getUserConfigRequest());
  }
  if (!hasRequestedUserRecord) yield put(getUserRecordRequest());
  if (!hasRequestedApps) {
    yield put(loadAppsRequest());
    yield race([take(LOAD_APPS_SUCCESS), take(LOAD_APPS_FAILURE)]);
  }
  if (!isCheckingLicenseExpiry) {
    const licenseTimestamp = getFromLS('licenseTimestamp');
    if (!licenseTimestamp || moment().diff(moment(licenseTimestamp), 'days') >= 1) {
      yield put(checkLicenseExpiryRequest());
    }
  }
}
