import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Spinner from 'react-spinkit';

import '../../styles/Loader.scss';

/**
 * Simple animated loader. This takes up some CPU resources.
 */
const Loader = ({ className }) => {
  const classList = classNames('LoadingScreen', className);
  return (
    <div className={classList} data-testid="Loader">
      <Spinner name="ball-triangle-path" fadeIn="none" />
    </div>
  );
};

Loader.propTypes = {
  className: PropTypes.string,
};

Loader.defaultProps = {
  className: '',
};

export default Loader;
