import axios from 'axios';
import { dcAppServiceEndpoints, endpoints } from '../constants/endpoints';
import { handleDCAppServiceCall } from '../utils/errorHandling/errorHandlers.api';

/**
 * Retrieves a published model's spec
 */
export const getPublishedModel = ({ publicationId, apiKey, apiSecret, accessToken }) => {
  if (accessToken) {
    return axios.get(`${endpoints.embedModel}/${publicationId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  }
  return axios.get(`${endpoints.embedChart}/${publicationId}`, {
    params: {
      client_id: apiKey,
      secret: apiSecret,
    },
  });
};

/**
 * Spins up a worker to perform an action on a published model.
 * @param pubId id of the publication
 * @param actionType type of action to run on the publication (see PUBLICATION_ACTIONS in embed.js)
 * @param data (optional) Input for running an action. Defaults to an empty javascript object.
 */
export const postModelAction = ({
  pubId,
  actionType,
  data = {},
  apiKey,
  apiSecret,
  accessToken,
}) => {
  if (accessToken) {
    // User is logged in
    return axios.post(`${endpoints.embedModel}/${pubId}/${actionType}`, JSON.stringify(data), {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  }
  // User is viewing a shareable link
  return axios.post(`${endpoints.embedModel}/${pubId}/${actionType}`, JSON.stringify(data), {
    params: {
      client_id: apiKey,
      secret: apiSecret,
    },
  });
};

/**
 * Retrieves the result of a publication action.
 * @param pubId id of the published model
 * @param actionId id of the action performed on the model
 * @param actionType type of action to run on the publication (see PUBLICATION_ACTIONS in embed.js)
 * @param data (optional) Input for running an action. Defaults to an empty javascript object.
 */
export const getModelActionResult = ({
  pubId,
  actionId,
  actionType,
  apiKey,
  apiSecret,
  accessToken,
}) => {
  if (accessToken) {
    // User is logged in
    return axios.get(`${endpoints.embedModel}/${pubId}/${actionType}/${actionId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  }
  // User is viewing a shareable link
  return axios.get(`${endpoints.embedModel}/${pubId}/${actionType}/${actionId}`, {
    params: {
      client_id: apiKey,
      secret: apiSecret,
    },
  });
};

/**
 * Retrieves the list of accessible models within a session.
 * @param accessToken access token for the logged in user
 * @param sessionId the session to query for
 */
export const getModelNames = (accessToken, sessionId) =>
  handleDCAppServiceCall(() =>
    axios.get(dcAppServiceEndpoints.modelNames, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: { sessionId },
    }),
  );
