import {
  GET_APIKEYS_FAILURE,
  GET_APIKEYS_REQUEST,
  GET_APIKEYS_SUCCESS,
  UPDATE_APIKEY_FAILURE,
  UPDATE_APIKEY_REQUEST,
  UPDATE_APIKEY_SUCCESS,
} from '../actions/apikeys.action';

// export const createKey = (props) => ({
//   client_id: 1,
//   name: 'test',
//   user_id: 1,
//   require_token: false,
//   secret: 'abc',
//   publications: [
//     {
//       id: 1,
//       type: 'chart',
//       permissions: 'ALL',
//     },
//   ],
// });

export const initialState = {
  keys: [], // List of api key
  error: undefined, // Error
  isRequesting: false, // Indicates that the request is awaiting a response.
  isBlocked: false, // KeyList is blocked waiting for response from update request
};

/**
 * @param {Object} state
 * @param {Object} action
 * @returns {Object} new state
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_APIKEYS_REQUEST:
      return {
        ...state,
        error: undefined,
        isRequesting: true,
      };
    case GET_APIKEYS_SUCCESS:
      return {
        ...state,
        keys: action.keys,
        isRequesting: false,
      };
    case GET_APIKEYS_FAILURE:
      return {
        ...state,
        error: action.error,
        isRequesting: false,
      };
    case UPDATE_APIKEY_REQUEST:
      return {
        ...state,
        error: undefined,
        isBlocked: true,
      };
    case UPDATE_APIKEY_SUCCESS:
      return {
        ...state,
        isBlocked: false,
      };
    case UPDATE_APIKEY_FAILURE:
      return {
        ...state,
        error: action.error,
        isBlocked: false,
      };
    default:
      return state;
  }
};
