export const NEW_SNACKBAR = 'snackbar/NEW_SNACKBAR';
export const CLOSE_SNACKBAR = 'snackbar/CLOSE_SNACKBAR';

let snackbarId = 0;

const generateSnackbarId = () => {
  snackbarId += 1;
  return snackbarId;
};
/**
 * Opens a new snackbar.
 *
 * @param {Object} params
 * @param {string} message
 * @param {Array} buttons an array of objects of type { label: string, onClick: Function }
 */
export const newSnackbar = ({ message, buttons = [] }) => ({
  type: NEW_SNACKBAR,
  id: generateSnackbarId(),
  message,
  buttons,
});

/**
 * Closes a snackbar of a particular id.
 * @param {Object} param0
 * @param {Number} id
 */
export const closeSnackbar = ({ id }) => ({
  type: CLOSE_SNACKBAR,
  id,
});
