// Open connection editor actions
export const EDIT_SNAPSHOT_REQUEST = 'snapshot/EDIT_SNAPSHOT_REQUEST';
export const EDIT_SNAPSHOT_SUCCESS = 'snapshot/EDIT_SNAPSHOT_SUCCESS';
export const EDIT_SNAPSHOT_FAILURE = 'snapshot/EDIT_SNAPSHOT_FAILURE';

// Open connection editor actions
export const REFRESH_SNAPSHOT_REQUEST = 'snapshot/REFRESH_SNAPSHOT_REQUEST';
export const REFRESH_SNAPSHOT_SUCCESS = 'snapshot/REFRESH_SNAPSHOT_SUCCESS';
export const REFRESH_SNAPSHOT_FAILURE = 'snapshot/REFRESH_SNAPSHOT_FAILURE';

/**
  Dispatch an action open snapshot workflow editor
  @param {String} id // Id of the snapshot object
*/
export const editSnapshotRequest = ({ id }) => ({
  type: EDIT_SNAPSHOT_REQUEST,
  id,
});

export const editSnapshotSuccess = ({ id }) => ({
  type: EDIT_SNAPSHOT_SUCCESS,
  id,
});

export const editSnapshotFailure = ({ id, error }) => ({
  type: EDIT_SNAPSHOT_FAILURE,
  id,
  error,
});

/**
  Dispatch an action to refresh a snapshot
  @param {Object} object // Snapshot Object
*/
export const refreshSnapshotRequest = ({ object }) => ({
  type: REFRESH_SNAPSHOT_REQUEST,
  object,
});

export const refreshSnapshotSuccess = ({ object }) => ({
  type: REFRESH_SNAPSHOT_SUCCESS,
  object,
});

export const refreshSnapshotFailure = ({ object, error }) => ({
  type: REFRESH_SNAPSHOT_FAILURE,
  object,
  error,
});
