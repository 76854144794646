import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import { useSelector } from 'react-redux';
import { AuthType } from '../constants/auth';
import { selectAuthBackendName } from '../store/sagas/selectors';
import { selectAuth0ClientId, selectAuth0Domain } from '../store/selectors/config.selector';

/**
 * This component wraps the Auth0Provider, so that we can dynamically fetch the clientId and domain.
 * This is necessary because we don't have these environment variables directly available at build
 * time. We need to fetch them from the Redux store.
 *
 * This component also exposes the logout function from Auth0Provider, so that we can call it from
 * anywhere in the application (i.e. from a Redux Saga)
 *
 * If the clientId or domain is not set, this component will just return the children since we may
 * not have the Auth0 environment variables set in every deployment.
 *
 * @param children The children components that will be wrapped by the Auth0Provider
 */
const Auth0ProviderWithRedux: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // Select Environment Variables from Redux Store
  const authBackendName = useSelector(selectAuthBackendName);
  const isAuth0 = authBackendName.includes(AuthType.AUTH0);
  const clientId = useSelector(selectAuth0ClientId);
  const domain = useSelector(selectAuth0Domain);
  const auth0Ready = clientId && domain && isAuth0;
  const redirectUri = `${window.location.origin}/web/login`;

  if (!auth0Ready) {
    return <>{children}</>;
  }

  return (
    <Auth0Provider
      clientId={clientId}
      domain={domain}
      authorizationParams={{ redirect_uri: redirectUri }}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithRedux;
