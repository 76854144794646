import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { selectIsStepwise } from '../../../../../store/selectors/context.selector';
import { PLOTS_WITHOUT_MODE_BAR } from '../../../constants';

/**
 * A functional component to render a button UI to show more options for a chart
 */
const ToolboxButton = (props) => {
  const { messageType, messageTypeVersion, data, toggleModeBar, isReplaying, isStepwise } = props;

  const handleOnClick = (event) => {
    // avoid click bubbling to parent to trigger the link option
    event.stopPropagation();
    toggleModeBar();
  };

  return (
    <>
      {!data.isDependencyGraph &&
      messageType === 'viz' &&
      !PLOTS_WITHOUT_MODE_BAR.includes(data.chartType) &&
      messageTypeVersion !== 2 ? (
        <MenuItem key="toolbox" className="Menu-Item-Container" onClick={handleOnClick}>
          <div
            className={`${isReplaying && !isStepwise ? 'Menu-Item Replaying' : 'Menu-Item'}`}
            data-cy="Menu-Item-Toolbox"
          >
            <BuildCircleOutlinedIcon className="Menu-Item-Icon" />
            <span className="Menu-Item-Text">Toolbox</span>
          </div>
        </MenuItem>
      ) : null}
    </>
  );
};

ToolboxButton.propTypes = {
  // Are we currently replaying a WF?
  isReplaying: PropTypes.bool.isRequired,
  // Are we replaying stepwise?
  isStepwise: PropTypes.bool.isRequired,
  messageType: PropTypes.string.isRequired,
  messageTypeVersion: PropTypes.number,
  data: PropTypes.object.isRequired,
  toggleModeBar: PropTypes.func.isRequired,
};

ToolboxButton.defaultProps = {
  messageTypeVersion: null,
};

const mapStateToProps = (state) => ({
  isReplaying: state.context.isReplaying,
  isStepwise: selectIsStepwise(state),
});

export default connect(mapStateToProps, {})(ToolboxButton);
