import { cancel, fork, put, race, select, take } from 'redux-saga/effects';
import { CHAT_PANEL } from '../../constants';
import {
  OPEN_CHAT_PANEL_CONTEXTUALLY,
  OPEN_CHAT_PANEL_FULLY,
  openChatPanel,
  updateChatPanelStyle,
} from '../actions/chat.actions';
import { selectMessages } from './selectors';
import { getChatPanelBottomPosition, getContextualChatPanelHeight } from './utils/chat_panel';

/**
 * Updates the state to show the chat panel contextually.
 */
function* openChatPanelContextuallyWorker() {
  const bottom = getChatPanelBottomPosition();

  const contexts = yield select(selectMessages);
  const height = getContextualChatPanelHeight(contexts);
  yield put(updateChatPanelStyle({ bottom, height }));
  yield put(openChatPanel());
}

/**
 * Updates the state to show the chat panel fully.
 */
function* openChatPanelFullyWorker() {
  const bottom = getChatPanelBottomPosition();
  yield put(updateChatPanelStyle({ bottom, height: CHAT_PANEL.HEIGHT }));
  yield put(openChatPanel());
}

/**
 * Listens for the first action that shows a chat panel fully or contextually.
 * Always cancels the previous task when a new one arrives.
 */
export default function* () {
  while (true) {
    let lastTask;
    const { full, contextual } = yield race({
      contextual: take(OPEN_CHAT_PANEL_CONTEXTUALLY),
      full: take(OPEN_CHAT_PANEL_FULLY),
    });
    if (lastTask) yield cancel(lastTask);
    if (contextual) lastTask = yield fork(openChatPanelContextuallyWorker);
    if (full) lastTask = yield fork(openChatPanelFullyWorker);
  }
}
