import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectReAuthenticationDialogDetail,
  selectReAuthenticationDialogProvider,
} from '../../store/selectors/integrations.selector';
import { selectSession } from '../../store/selectors/session.selector';
import { closeReAuthenticationDialog } from '../../store/slices/integrations.slice';
import { IntegrationProvider } from '../../types/integrations.types';
import OAuthButton from '../OAuthButtons/OAuthButton';
import { OAuthContextType, OAuthPassthroughState } from '../OAuthButtons/oauth.types';
import './ReAuthenticationDialog.scss';
import { capitalizeFirstLetter } from '../../utils/string';

export const getAuthDialogPassthroughState = (
  provider: IntegrationProvider | undefined,
  sessionId: string | undefined,
) => {
  // if we don't have a provider, we cannot construct a passthrough state
  if (!provider) return undefined;

  // default to NONE context
  const state: OAuthPassthroughState = {
    context: OAuthContextType.NONE, // Specifies that the OAuth flow was not initiated from a specific context
    databaseProvider: provider,
  };

  // if we have a sessionId, we know the context is a session
  if (sessionId) {
    state.context = OAuthContextType.SESSION;
    state.contextDetails = { sessionId };
  }

  return state;
};

const ReAuthenticationDialog = () => {
  const dispatch = useDispatch();
  const provider = useSelector(selectReAuthenticationDialogProvider);
  const detail = useSelector(selectReAuthenticationDialogDetail);
  const sessionId = useSelector(selectSession);

  const close = React.useCallback(() => dispatch(closeReAuthenticationDialog()), [dispatch]);

  React.useEffect(() => {
    if (!provider) {
      close();
    }
  }, [provider, close]);

  const passthroughState: OAuthPassthroughState | undefined = React.useMemo(
    () => getAuthDialogPassthroughState(provider, sessionId),
    [provider, sessionId],
  );

  return (
    <Dialog className="re-authentication-dialog" open={Boolean(provider)}>
      <DialogTitle
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography className="dialog-title">Missing Credentials</Typography>
        <IconButton data-testid="close-buttion" onClick={close}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText className="dialog-content">
          {detail ??
            `Your ${capitalizeFirstLetter(
              provider ?? '',
            )} credentials don't exist or have expired. Please authenticate to continue.`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {passthroughState && <OAuthButton passthroughState={passthroughState} />}
      </DialogActions>
    </Dialog>
  );
};

export default ReAuthenticationDialog;
