import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import isEqual from 'lodash/isEqual';
import { NavigationTabs, UNNAMED_SESSION } from '../../constants/session';

// Types of sessions
export const SESSION_TYPES = {
  // Able to reuse a previous active session
  CHAT: 'chat',
  ASK: 'ask',
  // Does not support session reuse
  DASHBOARD: 'dashboard',
  WORKFLOW_EDITOR: 'workflow_editor',
  INSIGHTS_BOARD: 'insight_board',
  CHART_REFRESH: 'chart_refresh',
  API: 'api',
};

export interface SessionOwner {
  id: number | undefined;
  email: string | undefined;
  name: { String: string; Valid: boolean } | undefined;
}

export interface SessionCollaborator {
  id: number;
  email: string;
}

export interface DetailPanelStatus {
  [key: string]: boolean;
}

export interface SessionState {
  sessionName: string;
  nameSaveFailed: boolean;
  isNameSystemGenerated: boolean;
  session: string | undefined;
  sessionType: string | undefined;
  startTime: number | undefined;
  error: unknown | undefined;
  isRequesting: boolean;
  localDependencyGraph: unknown | undefined;
  collaborators: SessionCollaborator[] | undefined;
  dataUseMap: { [key: string]: number };
  isEndingSession: boolean;
  owner: SessionOwner;
  navigationTab: { [key: string]: NavigationTabs };
  detailPanelStatus: DetailPanelStatus;
  openNavigationTab: NavigationTabs;
}

export interface OnAppClickPayload {
  /** Whether or not to open the load card on session open */
  showLoadCard?: boolean;
  /** The type of session */
  sessionType?: string;
  /** object id and type are used to look up the name of the object to create a default name for
   *  the session */
  objectId?: string;
  /** object id and type are used to look up the name of the object to create a default name for
   *  the session */
  objectType?: string;
}

export interface OnAppClickSuccessPayload {
  data: { sessionId: string };
  requestAction: OnAppClickPayload;
}

export interface OnAppClickFailureAction {
  error: Error;
  requestAction: OnAppClickPayload;
}

export interface OnSessionClickPayload {
  sessionId: string;
  sessionType?: string;
}

export interface InitializeSessionPayload {
  session: string;
  appId?: number;
}

interface StartSessionSuccessPayload {
  sessionType: string;
  startTime: number;
}

interface SaveSessionNameRequestPayload {
  sessionId: string;
  sessionName: string;
  isNameSystemGenerated: boolean;
}

interface SetSessionUsageInfoPayload {
  sessionId: string;
  dataUsage: number;
}

export interface SetNavigationTabPayload {
  sessionId: string | undefined;
  tab: NavigationTabs;
}

interface GetSessionNameSuccessPayload {
  sessionName: string;
  sessionData: object;
}

// Each session is tied to a sessionId and appId.
export const initialState: SessionState = {
  sessionName: UNNAMED_SESSION,
  nameSaveFailed: false, // Indicates that name saving failed and needs to be acknowledged
  isNameSystemGenerated: false, // Indicates if the name was generated by the system
  session: undefined, // Indicates the current session ID
  sessionType: undefined, // Indicates the session type
  startTime: undefined, // Indicates the time at which the session began.
  error: undefined, // Error
  isRequesting: false, // Indicates that the API request is waiting for a response.
  localDependencyGraph: undefined, // local dependency graph for stepwise replay
  collaborators: undefined, // user ids and emails of the collaborators
  dataUseMap: {}, // object containing the session's data usage and perceived data limit
  isEndingSession: false,
  owner: {
    id: undefined,
    email: undefined,
    name: undefined,
  },
  navigationTab: {},
  detailPanelStatus: {},
  openNavigationTab: NavigationTabs.NO_TAB_SELECTED,
};

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    /**
     * Detects when the user clicks on an application in the Home page.
     *
     * @param {String} appId The app id of the session to be started.
     * @param {String} sessionType The type of session being to be started.
     * @param {String} target The target route for the session
     * @param {String} appName The name of the app for the session.
     * @param {String} config The config of the app.
     * @param {Integer} objectId id of the object.
     * @param {Integer} objectType type of the object.
     *   Note: ObjectId and ObjectType only used to inherit
     *         the name of the object when session is created for this object.
     *         Both ObjectId and ObjectType should be passed to inherit the name of the object.
     * @param {String} prefix name to be appended to the start of the session name
     */
    onAppClick: {
      reducer: () => {},
      prepare: (payload: OnAppClickPayload) => {
        // set defaults
        if (payload.sessionType === undefined) payload.sessionType = SESSION_TYPES.CHAT;
        return { payload };
      },
    },
    /**
     * Action for running side-effects after successfully creating a session
     * @param {Object} data - response data from server
     * @param {Object} requestAction - onAppClick action
     */
    onAppClickSuccess: {
      reducer: () => {},
      prepare: (payload: OnAppClickSuccessPayload) => ({ payload }),
    },
    /**
     * Action for running side-effects after failing to create a session
     * @param {Object} error - error object
     * @param {Object} requestAction - onAppClick action
     */
    onAppClickFailure: {
      reducer: () => {},
      prepare: (payload: OnAppClickFailureAction) => {
        return { payload };
      },
    },
    /**
     * Detects when the user clicks on a session.
     *
     * @param {String} sessionId The session id
     * @param {String} appId The app id
     * @param {String} appViewId The app view id
     * @param {String} sessionType The session type
     */
    onSessionClick: {
      reducer: () => {},
      prepare: (payload: OnSessionClickPayload) => {
        // set defaults
        return { payload };
      },
    },
    /**
     * Clear the current session.
     * This action is invoked when the user presses the back button or goes to the homepage
     * by clicking on the DataChat icon.
     */
    resetSession(state) {
      state.appId = undefined;
      state.session = undefined;
      state.sessionType = undefined;
      state.localDependencyGraph = undefined;
      state.collaborators = undefined;
    },
    resetSessionId(state) {
      state.session = undefined;
    },
    /**
     * Initializes the current session by setting the session id.
     *
     * @param {String} session The session id of the current session.
     * @param {String} appId The app id of the current session.
     */
    initializeSession(state, { payload }: PayloadAction<InitializeSessionPayload>) {
      state.session = payload.session;
      state.appId = payload.appId;
      if (state.navigationTab[payload.session] === undefined) {
        state.navigationTab[payload.session] = NavigationTabs.DATA_SPACE;
      }
      if (state.detailPanelStatus[payload.session] === undefined) {
        state.detailPanelStatus[payload.session] = true;
      }
    },
    /**
     * Set the status of DetailPanel component
     *
     * @param {Boolean} isDetailPanelOpen
     */
    setDetailPanelState(state, { payload }: PayloadAction<boolean>) {
      if (state.session) {
        state.detailPanelStatus[state.session] = payload;
      }
    },
    /**
     * Set the status of the DetailPanel component to open.
     * This should be open when we submit skills because the cancel button is there.
     */
    setDetailPanelOpen(state) {
      if (state.session) {
        state.detailPanelStatus[state.session] = true;
      }
    },
    /**
     * Indicates that a start session request was successfully received.
     *
     * @param {String} sessionType The session id of the current session.
     * @param {String} startTime The time that the current session was started at.
     */
    startSessionSuccess(state, { payload }: PayloadAction<StartSessionSuccessPayload>) {
      state.sessionType = payload.sessionType;
      state.isRequesting = false;
      state.startTime = payload.startTime;
    },
    /**
     * Close a session
     * If sessionId is provided, close the specified session
     * Otherwise, close the last active session (the one whose id is kept in redux store)
     * @param {*} sessionId
     */
    exitSessionRequest: {
      reducer: (state) => {
        state.error = undefined;
        state.isRequesting = true;
      },
      prepare: (payload: string | undefined) => {
        return { payload };
      },
    },
    /**
     * Try to close a session, triggering checks for closing other things
     * If sessionId is provided, close the specified session
     * Otherwise, request to close the last active session (the one whose id is kept in redux store)
     * @param {*} sessionId
     */
    tryExitSessionRequest: {
      reducer: () => {},
      prepare: (payload: string | undefined) => {
        return { payload };
      },
    },
    exitSessionSuccess(state, { payload }: PayloadAction<string>) {
      delete state.navigationTab[payload];
      delete state.detailPanelStatus[payload];
      state.isRequesting = false;
      state.appId = undefined;
      state.appViewId = undefined;
      state.session = undefined;
      state.sessionType = undefined;
    },
    exitSessionFailure(state, { payload }: PayloadAction<{ error: Error }>) {
      state.error = payload.error;
      state.isRequesting = false;
    },
    isEndingSession(state, { payload }: PayloadAction<{ isEnding: boolean }>) {
      state.isEndingSession = payload.isEnding;
    },
    setSessionAppView(state, { payload }: PayloadAction<{ appViewId: number }>) {
      state.appViewId = payload.appViewId;
    },
    updateSessionAppViewFailure(state, { payload }: PayloadAction<{ error: Error }>) {
      state.error = payload.error;
    },
    /**
     * Update local dependency graph
     */
    setLocalDependencyGraph(state, { payload }: PayloadAction<unknown | undefined>) {
      state.localDependencyGraph = payload;
    },
    /**
     * Update collaborators of the session
     * @param {Array[Object]} collaborators user ids and emails of the collaborators
     */
    setSessionCollaborators(state, { payload }: PayloadAction<SessionCollaborator[] | undefined>) {
      if (!isEqual(state.collaborators, payload)) {
        state.collaborators = payload;
      }
    },
    /**
     * Retrieve the notifications of the session when the collaborators
     * leave the session.
     */
    receiveSessionNotificationsRequest() {},
    receiveSessionNotificationsFailure: {
      reducer: () => {},
      prepare: (payload: { error: Error }) => {
        return { payload };
      },
    },
    // Save a session name for a particular session
    saveSessionNameRequest: {
      reducer: () => {},
      prepare: (payload: SaveSessionNameRequestPayload) => {
        // set defaults
        if (payload.isNameSystemGenerated === undefined) payload.isNameSystemGenerated = false;
        return { payload };
      },
    },
    saveSessionNameFailure: {
      reducer: (state) => {
        state.nameSaveFailed = true;
        state.isNameSystemGenerated = false;
      },
      prepare: (payload: { error: Error }) => {
        return { payload };
      },
    },
    saveSessionNameFailureAcknowledged(state) {
      state.nameSaveFailed = false;
    },
    saveSessionNameSuccess(state, { payload }: PayloadAction<{ sessionName: string }>) {
      state.sessionName = payload.sessionName;
      state.isNameSystemGenerated = false;
    },
    setDataUsage(state, { payload }: PayloadAction<SetSessionUsageInfoPayload>) {
      state.dataUseMap = {
        ...state.dataUseMap,
        [payload.sessionId]: payload.dataUsage,
      };
    },
    /**
     * Get and set session name corresponding to sessionId if name exists
     * @param {String} sessionId id of the session
     */
    getSessionNameRequest: {
      reducer: () => {},
      prepare: (payload: { sessionId: string }) => {
        return { payload };
      },
    },
    /**
     * Dispatch a session name success action
     * @param {String} sessionName name of the session
     */
    getSessionNameSuccess(state, { payload }: PayloadAction<GetSessionNameSuccessPayload>) {
      state.sessionName = payload.sessionName;
    },
    getSessionNameFailure: {
      reducer: () => {},
      prepare: (payload: { error: Error }) => {
        return { payload };
      },
    },
    /**
     * Dispatch a session owner success action
     * @param {String} sessionOwner owner of the session
     * @param {String} sessionOwnerEmail email of the session owner
     * @param {String} sessionOwnerName name of the session owner
     */
    setSessionInfo(state, { payload }: PayloadAction<{ owner: SessionOwner }>) {
      state.owner = payload.owner;
    },
    /**
     * Dispatch a session name failure action
     */
    setDefaultSessionName(state) {
      state.sessionName = UNNAMED_SESSION;
    },
    setCurrentNavigationTab(state, { payload }: PayloadAction<SetNavigationTabPayload>) {
      if (payload.sessionId === undefined) return;
      state.navigationTab[payload.sessionId] = payload.tab;
    },
    setOpenNavigationTab(state, { payload }: PayloadAction<NavigationTabs>) {
      state.openNavigationTab = payload;
    },
  },
});

export const {
  onAppClick,
  onAppClickSuccess,
  onAppClickFailure,
  onSessionClick,
  setDetailPanelState,
  setDetailPanelOpen,
  resetSession,
  resetSessionId,
  initializeSession,
  startSessionSuccess,
  exitSessionRequest,
  tryExitSessionRequest,
  exitSessionSuccess,
  exitSessionFailure,
  isEndingSession,
  setSessionAppView,
  updateSessionAppViewFailure,
  setLocalDependencyGraph,
  setSessionCollaborators,
  receiveSessionNotificationsRequest,
  receiveSessionNotificationsFailure,
  setDefaultSessionName,
  saveSessionNameRequest,
  saveSessionNameFailure,
  saveSessionNameFailureAcknowledged,
  saveSessionNameSuccess,
  getSessionNameRequest,
  getSessionNameSuccess,
  getSessionNameFailure,
  setDataUsage,
  setSessionInfo,
  setCurrentNavigationTab,
  setOpenNavigationTab,
} = sessionSlice.actions;

export default sessionSlice.reducer;
