import { put, takeEvery } from 'redux-saga/effects';
import { getQualifiedObjectName } from '../../utils/home_screen';
import {
  DRILL_THROUGH_REQUEST,
  drillThroughFailure,
  drillThroughSuccess,
  triggerDrillThrough,
} from '../actions/drill_through.actions';
import { triggerInitialUtterance } from '../slices/initial_utterance.slice';
import { onAppClick } from '../slices/session.slice';

export function* drillThroughRequestWorker({ object, drillThroughSpec }) {
  const { objectType } = object;

  try {
    const loadSnapshotUtterance = `Load data from the snapshot <strong>${getQualifiedObjectName(
      object,
    )}</strong>`;

    yield put(triggerInitialUtterance({ message: loadSnapshotUtterance }));
    yield put(triggerDrillThrough({ drillThroughSpec }));
    yield put(onAppClick({ objectType }));

    return yield put(drillThroughSuccess());
  } catch (error) {
    return yield put(drillThroughFailure({ object, error }));
  }
}

export default function* () {
  yield takeEvery(DRILL_THROUGH_REQUEST, drillThroughRequestWorker);
}
