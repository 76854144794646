import { WARNED_SLUGGISHNESS } from '../actions/dcplot.actions';

export const initialState = {
  hasShownSluggishWarning: false,
  showSluggishWarningNextSession: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case WARNED_SLUGGISHNESS:
      return {
        ...state,
        hasShownSluggishWarning: true,
      };
    default:
      return state;
  }
};
