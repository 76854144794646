import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import ExpandMoreOutlined from '@mui/icons-material/ExpandMoreOutlined';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { FieldArray, FormikProps, getIn } from 'formik';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import '../../../components/ConnectionEditor/ConnectionEditor.scss';
import { DATASETS_INFO, FORM_GRID } from '../../../constants/connection';
import InfoTooltip from '../InfoTooltip';
import CustomTextField from './CustomTextField';

const QueryInput: React.FC<{
  field: { id: string; type: string };
  formik: FormikProps<any>;
}> = ({ field, formik }) => {
  const { values, handleBlur, handleChange, touched, errors } = formik;

  return (
    <Grid key={field.id} item xs={12} className={`${FORM_GRID}-Accordion`}>
      <Accordion data-cy="ce-datasets-accordion" elevation={2}>
        <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
          <Stack direction="row">
            <Typography>Custom Queries</Typography>
            <InfoTooltip text={DATASETS_INFO} />
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <FieldArray
            name="datasets"
            render={({ push, remove }) => (
              <Stack spacing={2}>
                {values?.datasets?.length > 0 ? (
                  <>
                    {values?.datasets?.map(
                      (
                        dataset: { id: string; datasetName: string; query: string },
                        index: number,
                      ) => {
                        const datasetName = `datasets[${index}].datasetName`;
                        const touchedDatasetName = getIn(touched, datasetName);
                        const errorDatasetName = getIn(errors, datasetName);

                        const query = `datasets[${index}].query`;
                        const touchedQuery = getIn(touched, query);
                        const errorQuery = getIn(errors, query);

                        return (
                          <Stack
                            key={`${dataset.id}-Stack`}
                            className={`${FORM_GRID}-Accordion-FieldArrayStack`}
                            direction="row"
                            spacing={1}
                          >
                            <Grid
                              className={`${FORM_GRID}-DatasetsGrid`}
                              key={`${dataset.id}-Grid`}
                              container
                              spacing={2}
                            >
                              <Grid key={`${dataset.id}-Grid-datasetName`} item xs={12} sm={4}>
                                <CustomTextField
                                  key={`${dataset.id}-datasetName`}
                                  fullWidth
                                  variant="outlined"
                                  required
                                  label="Dataset Name"
                                  name={datasetName}
                                  type={field.type}
                                  value={dataset.datasetName}
                                  autoComplete="off"
                                  error={
                                    touchedDatasetName && Boolean(datasetName && errorDatasetName)
                                  }
                                  helperText={
                                    touchedDatasetName && errorDatasetName ? errorDatasetName : ''
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </Grid>
                              <Grid key={`${dataset.id}-Grid-query`} item xs={12} sm={8}>
                                <CustomTextField
                                  key={`${dataset.id}-query`}
                                  fullWidth
                                  variant="outlined"
                                  multiline
                                  maxRows={6}
                                  required
                                  label="Query"
                                  name={query}
                                  type={field.type}
                                  value={dataset.query}
                                  autoComplete="off"
                                  error={touchedQuery && Boolean(query && errorQuery)}
                                  helperText={touchedQuery && errorQuery ? errorQuery : ''}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </Grid>
                            </Grid>
                            <IconButton
                              key={`${dataset.id}-DeleteBtn`}
                              className={`${FORM_GRID}-Accordion-Delete-Button`}
                              onClick={() => remove(index)}
                              disableRipple
                            >
                              <DeleteOutline />
                            </IconButton>
                          </Stack>
                        );
                      },
                    )}
                  </>
                ) : (
                  <Box className={`${FORM_GRID}-Accordion-Typography`} sx={{ typography: 'body1' }}>
                    No custom queries have been added for this connection. All tables in the
                    database will be loaded when this connection is used. Use the Add button below
                    to add SQL queries to load custom datasets or specific tables.
                  </Box>
                )}
                <Box
                  className={`${FORM_GRID}-Accordion-Button`}
                  onClick={() =>
                    push({
                      id: uuidv4(),
                      datasetName: '',
                      query: '',
                    })
                  }
                >
                  <AddCircleOutlineIcon />
                  <Typography>Add Query</Typography>
                </Box>
              </Stack>
            )}
          />
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default QueryInput;
