import { Series, SeriesGroupKeys, SeriesMarkKeys } from 'translate_dc_to_echart';
import ChartModules from '../modules/ChartModules';
import { InputType } from '../types/chartBuilderIO';
import ChartType from './ChartType';

export default class Donut extends ChartType {
  static fieldGenMapping = {
    mark: {
      // Special conditional case for donut charts
      [SeriesMarkKeys.x]: (group: Series['group']) =>
        group?.color ? InputType.sliceSize : InputType.split,
    },
    group: {
      [SeriesGroupKeys.color]: InputType.split,
      [SeriesGroupKeys.subplot]: InputType.subplot,
      [SeriesGroupKeys.slider]: InputType.slider,
    },
  };

  static columnFilters = {};

  constructor() {
    super(new ChartModules(Donut.columnFilters, Donut.fieldGenMapping));
  }
}
