import axios, { AxiosResponse, CancelTokenSource } from 'axios';
import { handleDCAppServiceCall } from '../utils/errorHandling/errorHandlers.api';

import { NAMESPACE_PAGE_SIZE, TABLE_PAGE_SIZE } from '../components/DatabaseBrowser/constants';
import { dcAppServiceEndpoints } from '../constants/endpoints';
import { BrowserColumn } from '../types/databaseBrowser.types';

export type ListNamespacesResponse = {
  namespaces: string[];
  totalNamespaceCount: number;
  time: string;
};

export const listNamespaces = (
  accessToken: string,
  connectionUUID: string,
  page: number,
  refresh: boolean,
): Promise<AxiosResponse<ListNamespacesResponse>> => {
  const offset = page * NAMESPACE_PAGE_SIZE;
  return handleDCAppServiceCall(() =>
    axios.get(dcAppServiceEndpoints.listNamespaces(connectionUUID), {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        offset,
        limit: NAMESPACE_PAGE_SIZE,
        refresh,
      },
    }),
  );
};

export const createCancelToken = (): CancelTokenSource => {
  return axios.CancelToken.source();
};

export type ListNamespaceTablesResponse = {
  tables: string[];
  totalTableCount: number;
  time: string;
};

export const listNamespaceTables = (
  accessToken: string,
  connectionUUID: string,
  namespace: string,
  filter: string,
  page: number,
  refresh: boolean,
  cancelToken: CancelTokenSource,
): Promise<AxiosResponse<ListNamespaceTablesResponse>> => {
  const offset = page * TABLE_PAGE_SIZE;
  return handleDCAppServiceCall(() =>
    axios.get(dcAppServiceEndpoints.listNamespaceTables(connectionUUID, namespace), {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        offset,
        limit: TABLE_PAGE_SIZE,
        refresh,
        filter,
      },
      cancelToken: cancelToken.token,
    }),
  );
};

export type GetTableMetadataResponse = {
  rows: any[][];
  columns: BrowserColumn[];
  specVersion: string;
  time: string;
};

export const getBrowserTableMetadata = (
  accessToken: string,
  connectionUUID: string,
  namespace: string,
  table: string,
  refresh: boolean,
  limit = 5,
): Promise<AxiosResponse<GetTableMetadataResponse>> => {
  return handleDCAppServiceCall(() =>
    axios.get(dcAppServiceEndpoints.getDatabaseTableMetadata(connectionUUID, namespace, table), {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        limit,
        refresh,
      },
    }),
  );
};
