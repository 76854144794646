import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { isWorkflowEditorPage } from '../../../constants/paths';
import { selectCurrentLocation } from '../../../store/sagas/selectors';
import '../../../styles/Chart.scss';

/**
 * A general container component to wrap all the visual elements
 */
const VisualContainer = (props) => {
  const {
    children,
    isWorkflowEditor,
    isInsightsBoard,
    isPopOutChart,
    inChartBuilder,
    messageType,
    contextId,
    objectId,
    chartStatuses,
    forgotten,
  } = props;

  const fitContent =
    (messageType === 'table' || messageType === 'note' || messageType === 'annotation') &&
    !isPopOutChart &&
    !isInsightsBoard &&
    !isWorkflowEditor &&
    !inChartBuilder;

  // from the chartStatuses array, find the index of the item whose objectId
  // matches the objectId we receive from props
  const index = chartStatuses.findIndex((item) => item.objectId === objectId);
  const selected = chartStatuses[index] && chartStatuses[index].selected;

  const className = useMemo(
    () =>
      `ChartContainer${forgotten ? ' expanded' : fitContent ? ' fit-content' : ''}${
        selected ? ' selected' : ''
      }`,
    [fitContent, selected, forgotten],
  );

  return (
    <div className={className} id={`chartContainer-${contextId}`}>
      {children}
    </div>
  );
};

VisualContainer.propTypes = {
  children: PropTypes.array.isRequired,
  isWorkflowEditor: PropTypes.bool.isRequired,
  isInsightsBoard: PropTypes.bool,
  isPopOutChart: PropTypes.bool,
  inChartBuilder: PropTypes.bool,
  messageType: PropTypes.string.isRequired,
  contextId: PropTypes.string.isRequired,
  objectId: PropTypes.string,
  chartStatuses: PropTypes.array.isRequired,
  forgotten: PropTypes.bool,
};

VisualContainer.defaultProps = {
  isInsightsBoard: false,
  isPopOutChart: false,
  inChartBuilder: false,
  objectId: undefined,
  forgotten: false,
};

const mapStateToProps = (state) => ({
  isWorkflowEditor: isWorkflowEditorPage(selectCurrentLocation(state)),
  chartStatuses: state.chartSelection.chartStatuses,
});

export default connect(mapStateToProps, {})(VisualContainer);
