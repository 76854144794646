import { ChartTypes } from 'translate_dc_to_echart';
import { iconMapping } from '../../../ChartBuilder/utils/constants';

/**
 * A helper function to decide if the editing chart should open in the builder
 * If we're editing the caption or have a Plotly chart, return false
 *
 * @param {Object} data - The chart spec object
 * @param {Boolean} chartEditingMode - Whether the chart is in editing mode
 * @param {String} messageType - The message type
 * @param {Number} messageTypeVersion - The message version
 * @returns true or false
 */
export const shouldOpenChartBuilder = ({
  data,
  chartEditingMode,
  messageType,
  messageTypeVersion,
}) =>
  messageType === 'viz' &&
  messageTypeVersion === 2 &&
  Object.keys(iconMapping).includes(data.plot.series[0].type) &&
  chartEditingMode;

/**
 * Determines whether the current visual can be edited
 * @param {Boolean} chartEditingMode
 * @param {Object} data
 * @param {Boolean} isInsightsBoard
 * @param {Boolean} isInsightsBoardCreator
 * @param {Boolean} isWorkflowEditor
 * @param {String} messageType
 */
export const isVisualEditable = ({
  data,
  isInsightsBoard = false,
  isInsightsBoardCreator = false,
  isWorkflowEditor = false,
  isPopoutEditable = false,
  messageType,
}) => {
  if (!isPopoutEditable) return false;

  // Don't support editing for histograms yet
  if (data?.chartType === ChartTypes.histogram) return false;

  return (
    ((messageType === 'viz' || (messageType === 'note' && !isInsightsBoard)) &&
      !isWorkflowEditor &&
      !data.isDependencyGraph &&
      !(messageType === 'viz' && (data.chartType === 'graph' || data.chartType === 'sunburst'))) ||
    (messageType === 'annotation' && isInsightsBoardCreator)
  );
};
