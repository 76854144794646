import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import React from 'react';
import { isStatusFailed, isStatusSuccess } from '../../utils/home_screen';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import Loader from '../common/Loader';

interface StatusPlaceholderProps {
  status?: string;
  alertTextProps?: object;
  isEmpty?: boolean;
  emptyText?: string;
  smallLoader?: boolean;
  errorText?: string;
  docLink?: string;
}

/**
 * StatusPlaceholder renders an error message or loading indicator if needed,
 * otherwise null.  This based on matching status to REQUEST_STATUS.
 * For example:
 * {!isStatusSuccess(status)? <StatusPlaceholder status={status} ... /> : <Content /> }
 */
const StatusPlaceholder: React.FC<StatusPlaceholderProps> = ({
  status,
  alertTextProps,
  isEmpty,
  emptyText,
  smallLoader,
  errorText,
  docLink,
}) => {
  const showEmpty = isStatusSuccess(status) && isEmpty;
  return (
    <Box className="StatusPlaceholder">
      {isStatusFailed(status) || showEmpty ? (
        <Alert
          className="StatusPlaceholder-Alert"
          severity={showEmpty ? 'info' : 'error'}
          variant="filled"
        >
          <ErrorMessage
            alertTextProps={alertTextProps}
            errorMessage={(showEmpty ? emptyText : errorText) || ''}
            docLink={docLink || ''}
          />
        </Alert>
      ) : smallLoader ? (
        <LinearProgress className="StatusPlaceholder-Loader" />
      ) : (
        <Loader className="StatusPlaceholder-Loader" />
      )}
    </Box>
  );
};

StatusPlaceholder.defaultProps = {
  alertTextProps: { variant: 'body1' },
  status: undefined,
  isEmpty: false,
  emptyText: '',
  smallLoader: false,
  errorText: 'An error occurred. Please try again later.',
  docLink: '',
};

export default StatusPlaceholder;
