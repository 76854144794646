import moment from 'moment';

/**
 * Checks if a given string is a date
 * @param {string} s string to check
 * @returns {boolean} true if the given string is a date
 */
const isDateString = (s) => {
  return moment(new Date(s)).isValid();
};

/**
 * Gets the unique row values in the column for a given column index
 * @param {Integer | String} locator index or columnName of the column to locate the row data
 * @param {String} columnType The type of the selected column
 * @param {Array} rows Dataset data
 * @returns {Array} unique row values for the column
 */
export const getUniqueValuesByLocator = (locator, columnType, rows) => {
  if (locator === undefined || locator < 0) return [];

  const valueSet = new Set();
  // autocomplete wants strings, and we cast them in translation anyway
  rows.forEach((e) => {
    const newValue = e[locator] !== null ? e[locator]?.toString() : 'null';
    valueSet.add(newValue);
  });

  const valueArr = Array.from(valueSet);

  // TODO - sort dates too
  if (columnType === 'string') {
    if (isDateString(valueArr[0])) {
      return valueArr.sort((a, b) => new Date(a) - new Date(b));
    }
    return valueArr.sort().filter((item) => item);
  }
  return valueArr.sort((a, b) => a - b).filter((item) => item);
};
