export const SCHEMA_TYPES = {
  FOLDER: 'folder',
  TABLE: 'table',
};

export enum EmptyPlaceholderText {
  Connections = 'There are no connections.',
  Database = 'This Database is Empty.',
  Namespace = 'This Namespace is Empty.',
  Filter = 'This Namespace has no matching tables.',
  TableMetadata = 'This table has no columns.',
}

export enum DefaultErrorMessage {
  Database = 'Failed to fetch connections.',
  Namespace = 'Failed to fetch namespaces.',
  Tables = 'Failed to fetch tables.',
  TableMetadata = 'Failed to fetch metadata.',
}
