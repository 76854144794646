import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { REQUEST_STATUS } from '../../../constants/home_screen';
import { selectConnectionEditorIsOpen } from '../../../store/sagas/selectors';
import {
  selectDatabaseBrowserHideNavigation,
  selectDatabaseBrowserNumConnections,
  selectDatasetCreationStatus,
  selectNumSelectedTables,
} from '../../../store/selectors/dbBrowser.selector';
import { selectSession } from '../../../store/selectors/session.selector';
import { createDatasetsRequest } from '../../../store/slices/dbBrowser.slice';
import { closeLoadCard } from '../../../store/slices/loadCard.slice';
import DropDownButton from '../../common/DropDownButton';
import DatabastBrowserConnectionDetails from '../ConnectionDetails/DatabaseBrowserConnectionDetails';
import ConnectionList from '../Navigation/Databases/ConnectionList';
import DatabaseBrowserPreview from '../Preview/DatabaseBrowserPreview';
import './DatabaseBrowserContent.scss';

// This is separate from the dialog for reuse
const DatabaseBrowserContent = () => {
  const connectionEditorIsOpen = useSelector(selectConnectionEditorIsOpen);
  const numSelectedTables = useSelector(selectNumSelectedTables);
  const hideNavigation = useSelector(selectDatabaseBrowserHideNavigation);
  const numConnections = useSelector(selectDatabaseBrowserNumConnections);
  const creatingDatasetRequestStatus = useSelector(selectDatasetCreationStatus);
  const sessionId = useSelector(selectSession);
  const dispatch = useDispatch();
  return (
    <DialogContent className="DatabaseBrowser-Content" data-testid="databasebrowser-content">
      <Box
        className={`NavigationCollapse${
          numConnections === 0 || hideNavigation ? ' hidden' : ' active'
        }`}
      >
        <ConnectionList />
        <DialogActions sx={{ zIndex: 11 }}>
          <Box sx={{ display: 'flex' }}>
            <Chip
              data-testid="num-selected-tables"
              label={numSelectedTables}
              color="primary"
              size="small"
            />
            <Typography sx={{ marginRight: '1rem', marginLeft: '0.5rem' }}>
              table{numSelectedTables === 1 ? '' : 's'} selected
            </Typography>
          </Box>

          {sessionId ? (
            <DropDownButton
              disabled={numSelectedTables === 0}
              variant="contained"
              onClick={() => {
                dispatch(closeLoadCard());
                dispatch(createDatasetsRequest({ openInSession: true }));
              }}
              label="Import and Load"
              loading={creatingDatasetRequestStatus === REQUEST_STATUS.REQUESTED}
            />
          ) : (
            <DropDownButton
              disabled={numSelectedTables === 0}
              variant="contained"
              onClick={() => dispatch(createDatasetsRequest({ openInSession: false }))}
              label="Import"
              loading={creatingDatasetRequestStatus === REQUEST_STATUS.REQUESTED}
              subOptions={[
                {
                  label: 'Import and Load',
                  onClick: () => dispatch(createDatasetsRequest({ openInSession: true })),
                },
              ]}
            />
          )}
        </DialogActions>
      </Box>
      <Box
        className={`PanelGroup PreviewContainer ${
          !connectionEditorIsOpen && numConnections !== 0 ? 'active' : 'hidden'
        } `}
      >
        <DatabaseBrowserPreview />
      </Box>
      <Box
        className={`PanelGroup DetailsPanel ${
          connectionEditorIsOpen || numConnections === 0 ? 'active' : 'hidden'
        }`}
      >
        <DatabastBrowserConnectionDetails />
      </Box>
    </DialogContent>
  );
};

export default DatabaseBrowserContent;
