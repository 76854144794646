import isEmpty from 'lodash/isEmpty';
import { CATALOG_TITLE } from '../store/slices/catalog.slice';
import { SESSION_TYPES } from '../store/slices/session.slice';
import { formatBytes } from '../utils/format_bytes';

export const APP_FAILED_TO_START_ALERT = 'APP_FAILED_TO_START_ALERT';
export const APP_FAILED_TO_START_TITLE = 'Service unavailable';
export const getAppFailedToStartDescription = (appName) =>
  `${appName} is currently unavailable. Please try again later. If the issue persists, please contact info@datachat.ai.`;

export const GOODBYE_PROMPT_ALERT = 'GOODBYE_PROMPT_ALERT';
export const GOODBYE_PROMPT_TITLE = 'Goodbye';
export const GOODBYE_PROMPT_DESCRIPTION = 'Are you sure you want to end your session?';

export const TASK_FAILED_ALERT = 'TASK_FAILED_ALERT';
export const TASK_FAILED_TITLE = 'Uh-oh!';
export const TASK_FAILED_DESCRIPTION =
  'Something went wrong with your last request. Please try again later';

export const SESSION_WORKING_ALERT = 'WARN_END_SESSION_ALERT';
export const SESSION_WORKING_TITLE = 'Session is Still Working';
export const SESSION_WORKING_DESCRIPTION =
  'Session is still working on your last step. Are you sure you want to end your session?';

export const CONNECTION_UPLOAD_ALERT = 'CONNECTION_UPLOAD_ALERT';
export const CONNECTION_UPLOAD_TITLE = 'Connection files can not be uploaded';
export const CONNECTION_UPLOAD_DESCRIPTION =
  'Connection files can be created under the Connections section on the home screen.';

export const GOODBYE_TRANSITION_ALERT = 'GOODBYE_TRANSITION_ALERT';
export const GOODBYE_TRANSITION_TITLE = 'Thanks for using DataChat!';
export const GOODBYE_TRANSITION_DESCRIPTION = 'Taking you to the home screen';

export const SESSION_CONCURRENCY_LIMIT_ALERT = 'SESSION_CONCURRENCY_LIMIT_ALERT';
export const SESSION_CONCURRENCY_LIMIT_TITLE = 'Too many sessions!';

const UNSAVED_DATASET_CHANGES_ALERT = 'UNSAVED_DATASET_CHANGES_ALERT';
const UNSAVED_DATASET_CHANGES_TITLE = 'Unsaved Changes';
const UNSAVED_DATASET_CHANGES_DESCRIPTION =
  "We've noticed you have unfinished changes in progress. Please complete or discard these changes before continuing with this operation.";
export const RENAME_COLUMNS_KEY = 'RENAME_COLUMNS';
export const RENAME_COLUMNS_LABEL = 'Rename Columns';
export const DISCARD_CHANGES_KEY = 'DISCARD_CHANGES';
export const DISCARD_CHANGES_LABEL = 'Discard Changes';

export const ASK_CONVERSATION_LIMIT_ALERT = 'ASK_CONVERSATION_LIMIT_ALERT';
export const ASK_CONVERSATION_LIMIT_TITLE = 'Limit Reached';
export const ASK_CONVERSATION_LIMIT_DESCRIPTION = [
  'You have too many sessions or conversations open.',
  'Close one of them, and try again.',
];

export const UNSAVED_CATALOG_CHANGES_ALERT = 'UNSAVED_CATALOG_CHANGES_ALERT';
export const UNSAVED_CATALOG_CHANGES_TITLE = 'Unsaved Changes';
export const UNSAVED_CATALOG_CHANGES_DESCRIPTION = `You have unsaved changes in the ${CATALOG_TITLE}.`;
export const SAVE_CHANGES_KEY = 'SAVE_CHANGES';
export const SAVE_CHANGES_LABEL = 'Save Changes';

const SESSION_NAME = {};
SESSION_NAME[SESSION_TYPES.CHAT] = 'Chat Session';
SESSION_NAME[SESSION_TYPES.DASHBOARD] = 'Insight Board Session';
SESSION_NAME[SESSION_TYPES.WORKFLOW_EDITOR] = 'Testing Session';
SESSION_NAME[SESSION_TYPES.CHART_REFRESH] = 'Chart Refresh Process';
SESSION_NAME[SESSION_TYPES.API] = 'API session';

export const addArticle = (phrase) => {
  if ('aeiouAEIOU'.includes(phrase?.[0])) return `an ${phrase}`;
  return `a ${phrase}`;
};

/**
 * Returns alert description for session limit dialog
 * @param {String} newSesionType - type of session being created
 * @param {Boolean} isHome - true if session creation triggered on home page, false otherwise
 * @returns {Array} alert description
 */
export const getSessionConcurrencyLimitDescription = (newSessionType, isHome) => {
  // direction message is slightly different if on home screen
  const direction = isHome
    ? 'Please close one of the old sessions, and try again.'
    : 'Please close one of the old sessions from the home screen, and try again.';
  return [
    'You already have the maximum number of sessions open, ' +
      `but are trying to open ${addArticle(SESSION_NAME[newSessionType])}.`,
    direction,
  ];
};

export const ACTIVE_DASHBOARD_FORBIDDEN_ALERT = 'ACTIVE_DASHBOARD_FORBIDDEN_ALERT';
export const ACTIVE_DASHBOARD_FORBIDDEN_TITLE =
  'Opening a running insights board session is not yet supported.';
export const ACTIVE_DASHBOARD_FORBIDDEN_DESCRIPTION =
  'Please open the insights board from the insights board menu instead.';

export const RELOAD_FORBIDDEN_ALERT = 'RELOAD_FORBIDDEN_ALERT';
export const RELOAD_FORBIDDEN_TITLE = 'Cannot reload the session';
export const RELOAD_FORBIDDEN_DESCRIPTION = 'The session has to be a normal chat session';

export const SESSION_NAME_EXISTS_ALERT = 'SESSION_NAME_EXISTS_ALERT';
export const SESSION_NAME_EXISTS_TITLE = 'Session name exists';
export const SESSION_NAME_EXISTS_DESCRIPTION = 'A session with this name already exists.';

export const RELOAD_SESSION_NOT_FOUND_ALERT = 'RELOAD_SESSION_NOT_FOUND_ALERT';
export const RELOAD_SESSION_NOT_FOUND_TITLE = 'Cannot reload the session';
export const RELOAD_SESSION_NOT_FOUND_DESCRIPTION = 'The session seems to be lost';

export const SWITCH_APPS_ALERT = 'SWITCH_APPS_ALERT';
export const SWITCH_APPS_TITLE = 'Switching Apps';

export const RELOAD_APPS_ALERT = 'RELOAD_APPS_ALERT';
export const RELOAD_APPS_TITLE = 'Workflow exists';
export const RELOAD_APPS_DESCRIPTION = 'Would you like to reload the workflow?';

export const SWITCH_DASHBOARDS_ALERT = 'SWITCH_DASHBOARDS_ALERT';
export const SWITCH_DASHBOARDS_TITLE = 'Switching Dashboards';

export const DASHBOARD_INVALID_SESSION_ALERT = 'DASHBOARD_INVALID_SESSION_ALERT';
export const DASHBOARD_INVALID_SESSION_TITLE = 'Insights board generation failed';

export const CHAT_TO_DASHBOARD_ALERT = 'CHAT_TO_DASHBOARD_ALERT';
export const CHAT_TO_DASHBOARD_TITLE = 'Chat session is active';

export const DASHBOARD_TO_CHAT_ALERT = 'DASHBOARD_TO_CHAT_ALERT';
export const DASHBOARD_TO_CHAT_TITLE = 'Dashboard session is active';

export const GET_DASHBOARD_NAME_ALERT = 'GET_DASHBOARD_NAME_ALERT';
export const GET_DASHBOARD_NAME_TITLE = 'Create Dashboard';

export const DASHBOARD_DELETE_ALERT = 'DASHBOARD_DELETE_ALERT';
export const DASHBOARD_DELETE_TITLE = 'Delete Confirmation';
export const getDeleteDashboardDescription = (dashboardName, sessionName, appName) =>
  `'${dashboardName}' powered by '${sessionName}' (${appName}) will be deleted permanently.`;

export const INSIGHTS_BOARD_DELETE_ALERT = 'INSIGHTS_BOARD_DELETE_ALERT';
export const INSIGHTS_BOARD_DELETE_TITLE = 'Delete Confirmation';

export const MULTI_OBJECT_DELETE_ALERT = 'MULTI_OBJECT_DELETE_ALERT';
export const MULTI_OBJECT_DELETE_TITLE = 'Delete Confirmation';
export const getDeleteMultiObjectDescription = (insightWarning = false, sharedWarning = false) => [
  `Are you sure you want to permanently delete the selected object(s)? ${
    insightWarning ? 'Deleting insights boards will also delete the related charts.' : ''
  } ${sharedWarning ? 'Deleting shared objects will remove access for all users.' : ''}`,
];

export const INSIGHTS_BOARD_DELETE_CHARTS_ALERT = 'INSIGHTS_BOARD_DELETE_CHARTS_ALERT';
export const INSIGHTS_BOARD_DELETE_CHARTS_TITLE = 'Delete Charts';
export const INSIGHTS_BOARD_DELETE_CHARTS_DESCRIPTION =
  "Would you like to permanently delete this insights board's publications?";

export const INSIGHTS_BOARD_VARIABLE_CREATE_ERROR = 'Error creating a variable.';
export const INSIGHTS_BOARD_VARIABLE_EDIT_ERROR = 'Error editing a variable.';
export const INSIGHTS_BOARD_VARIABLE_ERROR_ALERT = 'INSIGHTS_BOARD_VARIABLE_ALERT';

export const DASHBOARD_SHARED_DELETE_ALERT = 'DASHBOARD_SHARED_DELETE_ALERT';
export const DASHBOARD_SHARED_DELETE_TITLE = 'Delete Confirmation';
export const getDeleteDashboardSharedDescription = (dashboardName, sessionName, appName) =>
  `'${dashboardName}' powered by '${sessionName}' (${appName}) is currently shared with other users. Deleting this will remove access for everybody.`;

export const getDeleteObjectSharedDescription = (name) =>
  `${name} is currently shared with other users. Deleting this will remove access for everybody.`;
export const getDeleteObjectDescription = (name, fromLocation = 'permanently') =>
  `${name} will be deleted ${fromLocation}.`;
export const getDeleteChartBackingSnapshotDescription = (name) =>
  `Deleting ${name} will also delete the related charts.`;
export const OBJECT_SHARED_DELETE_ALERT = 'OBJECT_SHARED_DELETE_ALERT';
export const OBJECT_DELETE_ALERT = 'OBJECT_DELETE_ALERT';
export const OBJECT_DELETE_TITLE = 'Delete Confirmation';
export const OBJECT_EXISTS_ALERT = 'OBJECT_EXISTS_ALERT';

export const INSIGHTS_BOARD_DUPLICATE_NAME_ALERT = 'INSIGHTS_BOARD_DUPLICATE_NAME_ALERT';
export const INSIGHTS_BOARD_DUPLICATE_NAME = 'Duplicate Name';

export const INSIGHTS_BOARD_INVALID_FILE_NAME =
  'Please enter a name with no punctuation or special characters except for underscores (_).';

export const NETWORK_ERROR_ALERT = 'NETWORK_ERROR_ALERT';
export const NETWORK_ERROR_TITLE = 'Connection lost';
export const NETWORK_ERROR_DESCRIPTION = 'No connection to the server';

export const GENERAL_ERROR_ALERT = 'GENERAL_ERROR_ALERT';
export const GENERAL_ERROR_TITLE = 'DataChat encountered a technical issue.';
export const GENERAL_ERROR_DESCRIPTION =
  'DataChat encountered a technical issue. Please consider refreshing the application.';

export const GENERAL_AVA_ERROR_ALERT = 'GENERAL_AVA_ERROR_ALERT';

export const GENERAL_IB_ERROR_ALERT = 'GENERAL_IB_ERROR_ALERT';

export const UNAUTHENTICATED_ERROR_ALERT = 'UNAUTHENTICATED_ERROR_ALERT';
export const UNAUTHENTICATED_ERROR_DESCRIPTION_TITLE = 'Not Authenticated';
export const UNAUTHENTICATED_ERROR_DESCRIPTION = 'Looks like some credentials have expired.';

export const FORGET_DATASET_ALERT = 'FORGET_DATASET_ALERT';
export const FORGET_DATASET_DESCRIPTION_TITLE = 'Are you sure?';
export const FORGET_DATASET_DESCRIPTION = 'Are you sure you want to forget this dataset?';

export const FORBIDDEN_ROLE_ERROR_ALERT = 'FORBIDDEN_ROLE_ERROR_ALERT';
export const FORBIDDEN_ROLE_ERROR_TITLE = 'Access Forbidden';
export const FORBIDDEN_ROLE_ERROR_DESCRIPTION =
  'You do not have access to this feature with your current subscription level. Upgrade your DataChat subscription to gain access.';

export const FILE_STORAGE_LIMIT_ALERT = 'FILE_STORAGE_LIMIT_ALERT';
export const FILE_STORAGE_LIMIT_TITLE = 'File Storage Limit Reached';
export const UPGRADE_PROMPT = 'Press "Upgrade" to upgrade your subscription.';

export const FORBIDDEN_ERROR_ALERT = 'FORBIDDEN_ERROR_ALERT';
export const FORBIDDEN_ERROR_TITLE = 'Access Denied';
export const FORBIDDEN_ERROR_DESCRIPTIONS = [
  'You do not have permission to perform this action.',
  'Please contact the session owner to grant you access.',
];

export const UNEXPECTED_ERROR_ALERT = 'UNEXPECTED_ERROR_ALERT';
export const UNEXPECTED_ERROR_TITLE = 'An unexpected error occurred.';

export const FILE_INVALID_ALERT = 'FILE_INVALID_ALERT';
export const FILE_INVALID_TITLE = 'Invalid File Format';
export const FILE_INVALID_DESCRIPTION = 'It is likely that the file is corrupted. ';

export const FILE_INVALID_NAME_ALERT = 'FILE_INVALID_NAME_ALERT';
export const getFileInvalidNameDescription = (filename) =>
  `The file name${
    filename ? ` "${filename}"` : ''
  } is too short or contains invalid characters. Please rename the file and try again.`;

export const FILE_BAD_STATUS_REQUEST_ALERT = 'FILE_BAD_STATUS_ALERT';
export const FILE_BAD_STATUS_REQUEST_TITLE = 'Bad Status Request';
export const FILE_BAD_STATUS_REQUEST_DESCRIPTION = 'The file name or type is invalid.';

export const FILE_UPLOAD_FAILED_ALERT = 'FILE_UPLOAD_FAILED_ALERT';
export const FILE_UPLOAD_FAILED_TITLE = 'File Upload Failed';
export const FILE_UPLOAD_FAILED_DESCRIPTION =
  'An unexpected error occurred while uploading the file.';

export const FILE_ALREADY_EXISTS_ALERT = 'FILE_ALREADY_EXISTS_ALERT';
export const FILE_ALREADY_EXISTS_TITLE = 'already exists';
export const FILE_ALREADY_EXISTS_DESCRIPTION = 'Would you like to overwrite the existing file?';

export const RECIPE_ALREADY_EXISTS_ALERT = 'RECIPE_ALREADY_EXISTS_ALERT';
export const RECIPE_ALREADY_EXISTS_TITLE = 'already exists';
export const RECIPE_ALREADY_EXISTS_DESCRIPTION =
  'This workflow already exists. A new version will be created if you save this workflow. Would you like to continue?';

export const WORKFLOW_INVALID_FILE_NAME =
  'Please enter a name with no punctuation, spaces, or special characters except for underscores (_).';

export const CONNECTION_INVALID_FILE_NAME =
  'Please enter a name with no punctuation, spaces, or special characters except for underscores (_).';

export const FILES_ALREADY_EXIST_ALERT = 'FILES_ALREADY_EXIST_ALERT';
export const FILES_ALREADY_EXIST_TITLE = 'Multiple files already exist';
export const FILES_ALREADY_EXIST_DESCRIPTION = 'Would you like to overwrite the following files?';

export const DATASET_ALREADY_EXISTS_ALERT = 'DATASET_ALREADY_EXISTS_ALERT';
export const DATASET_ALREADY_EXISTS_TITLE = 'already exists';
export const DATASET_ALREADY_EXISTS_DESCRIPTION =
  'Would you like to overwrite the existing dataset?';

export const DATASETS_ALREADY_EXIST_ALERT = 'DATASETS_ALREADY_EXIST_ALERT';
export const DATASETS_ALREADY_EXIST_TITLE = 'Multiple datasets already exist';
export const DATASETS_ALREADY_EXIST_DESCRIPTION =
  'Would you like to overwrite the following datasets?';

export const EMAIL_RESET_TOKEN_REQUEST_ALERT = 'EMAIL_RESET_TOKEN_SUCCESS_ALERT';
export const EMAIL_RESET_TOKEN_REQUEST_TITLE = 'Request received!';
export const EMAIL_RESET_TOKEN_REQUEST_DESCRIPTION =
  'If we have an account matching your email address, you will receive an email with a link to reset your password.';
export const EMAIL_RESET_TOKEN_REQUEST_DESCRIPTION_SIGNED_IN =
  'You will receive an email with a link to reset your password.';

export const OTP_ACCOUNT_CREATED_ALERT = 'OTP_ACCOUNT_CREATED_ALERT';
export const OTP_ACCOUNT_CREATED_TITLE = 'Account Created & Email Sent!';
export const OTP_ACCOUNT_CREATED_DESCRIPTION =
  'Your account is successfully created, you will receive an email with a link to login into your account.';

export const OTP_EMAIL_SENT_ALERT = 'OTP_EMAIL_SENT_ALERT';
export const OTP_EMAIL_SENT_TITLE = 'Email Sent!';
export const OTP_EMAIL_SENT_DESCRIPTION =
  'If we have an account matching your email address, you will receive an email with a link to login into your account.';

export const RESET_PASSWORD_SUCCESS_ALERT = 'RESET_PASSWORD_SUCCESS_ALERT';
export const RESET_PASSWORD_SUCCESS_TITLE = 'Woohoo!';
export const RESET_PASSWORD_SUCCESS_DESCRIPTION = 'Your password has been successfully reset.';
export const PROMPT_FILE_DELETION_ALERT = 'CONFIRM_FILE_DELETION_ALERT';
export const PROMPT_FILE_DELETION_TITLE = 'Removing files';

export const PASSWORD_TOKEN_EXPIRED_ALERT = 'PASSWORD_TOKEN_EXPIRED_ALERT';
export const PASSWORD_TOKEN_EXPIRED_TITLE = 'Sorry! The reset link has expired!';
export const PASSWORD_TOKEN_EXPIRED_DESCRIPTION =
  'For security reasons, the password reset link has expired. If you still need to reset your password, you can request a new reset email.';

export const PROMPT_LOGOUT_AFTER_PASSWORD_RESET_ALERT = 'PROMPT_LOGOUT_AFTER_PASSWORD_RESET_ALERT';
export const PROMPT_LOGOUT_AFTER_PASSWORD_RESET_TITLE = 'Signing out';
export const PROMPT_LOGOUT_AFTER_PASSWORD_RESET_DESCRIPTION =
  'You will be logged out now. Please login with your new password.';

export const SEND_VERIFY_EMAIL_SUCCESS_ALERT = 'SEND_VERIFY_EMAIL_SUCCESS_ALERT';
export const SEND_VERIFY_EMAIL_SUCCESS_TITLE = 'Keep your eyes peeled!';
export const SEND_VERIFY_EMAIL_SUCCESS_DESCRIPTION =
  'A verification email has successfully been sent to ';

export const SEND_VERIFY_EMAIL_FAILURE_ALERT = 'SEND_VERIFY_EMAIL_FAILURE_ALERT';
export const SEND_VERIFY_EMAIL_FAILURE_TITLE = 'Oops...';

export const VERIFY_ACCOUNT_SUCCESS_ALERT = 'VERIFY_ACCOUNT_SUCCESS_ALERT';
export const VERIFY_ACCOUNT_SUCCESS_TITLE = 'Woohoo!';
export const VERIFY_ACCOUNT_SUCCESS_DESCRIPTION =
  ' has been successfully verified. You should now be able to sign in.';

export const LEAVE_SESSION_COLLABORATION_ALERT = 'LEAVE_SESSION_COLLABORATION_ALERT';
export const LEAVE_SESSION_COLLABORATION_TITLE = 'Leave Session Collaboration';
export const LEAVE_SESSION_COLLABORATION_DESCRIPTION =
  'You will no longer have access to the session';

export const VERIFY_ACCOUNT_FAILURE_ALERT = 'VERIFY_ACCOUNT_SUCCESS_ALERT';
export const VERIFY_ACCOUNT_FAILURE_TITLE = 'Oops...';

export const UNVERIFIED_ACCOUNT_ALERT = 'UNVERIFIED_ACCOUNT_ALERT';
export const UNVERIFIED_ACCOUNT_TITLE = 'Oops...';
export const UNVERIFIED_ACCOUNT_DESCRIPTION =
  "Your account's email isn't verified. Please verify your email before signing in. " +
  'If you need a new account verification email, please click Resend. ' +
  'Otherwise, clicking OK will take you back to the login page.';

export const ALREADY_LOGGED_IN_ALERT = 'ALREADY_LOGGED_IN_ALERT';
export const ALREADY_LOGGED_IN_TITLE = 'You are already logged in.';
export const ALREADY_LOGGED_IN_DESCRIPTION = 'What would you like to do?';

export const NOT_FOUND_ALERT = 'NOT_FOUND_ALERT';
export const NOT_FOUND_TITLE = "Oops, you're not supposed to be here.";
export const NOT_FOUND_DESCRIPTION =
  'The link you followed may be broken, or the page may not exist.';
export const ENDPOINT_NOT_FOUND_ALERT = 'ENDPOINT_NOT_FOUND_ALERT';
export const ENDPOINT_NOT_FOUND_TITLE = 'Not Found';
export const ENDPOINT_NOT_FOUND_DESCRIPTIONS = [
  'The requested resource could not be found.',
  'Please check the URL and try again.',
];
export const NOT_FOUND_BUTTON = 'Return to ';
export const NOT_FOUND_BUTTON_LABEL_AUTHENTICATED = 'home';
export const NOT_FOUND_BUTTON_LABEL_NOT_AUTHENTICATED = 'login';

export const REGISTER_ERROR_ALERT = 'REGISTER_ERROR_ALERT';
export const LOGIN_ERROR_ALERT = 'LOGIN_ERROR_ALERT';

export const BAD_REQUEST_ERROR_TITLE = 'Invalid Request';
export const BAD_REQUEST_ERROR_DESCRIPTIONS = [
  'The request was malformed or invalid.',
  'Please check your input and try again.',
];

export const AUTHENTICATION_ERROR_TITLE = 'Unauthorized';
export const AUTHENTICATION_ERROR_DESCRIPTIONS = [
  'We were unable to confirm your identity for this action.',
  'Please log in and try again.',
];

export const OTP_EXPIRED_ERROR_TITLE = 'Used or Expired link';
export const OTP_EXPIRED_ERROR_DESCRIPTION =
  'The link has been used or already expired, please request a new login link.';

export const DOMAIN_NOT_WHITELISTED_TITLE = 'Invalid email';
export const DOMAIN_NOT_WHITELISTED_DESCRIPTION =
  "Email does not belong to a domain that's allowed. Please enter an email associated with an allowed domain. " +
  'If this issue persists, please contact info@datachat.ai.';

export const FORBIDDEN_REGISTER_ERROR_TITLE = 'Invalid email';
export const FORBIDDEN_REGISTER_ERROR_DESCRIPTION =
  'An account with this email already exists. Please try signing in instead.';

export const NAME_TOO_SHORT_ERROR_TITLE = 'Invalid name';
export const NAME_TOO_SHORT_ERROR_DESCRIPTION = 'Name cannot be empty';

export const NAME_TOO_LONG_ERROR_TITLE = 'Invalid name';
export const NAME_TOO_LONG_ERROR_DESCRIPTION = 'Name must be less than 150 characters';

export const NO_USER_REGISTRATION_TITLE = 'Sign up Requires Approval';
export const NO_USER_REGISTRATION_DESCRIPTION =
  'Your account was not created. Users for your organization must be approved by an administrator ' +
  'with management access in DataChat. Please request access from an administrator.';

export const PASSWORD_TOO_WEAK_TITLE = 'Weak Password';
export const PASSWORD_TOO_WEAK_DESCRIPTION =
  'The entered password is not strong enough.  Please enter a stronger password.';

export const EXCEED_USER_LIMIT_TITLE = 'Exceed User Limit';
export const EXCEED_USER_LIMIT_DESCRIPTION =
  'This organization does not have any room for new users.';

export const MAX_USERS_REGISTER_ERROR_TITLE = 'User Limit Reached';
export const MAX_USERS_REGISTER_ERROR_DESCRIPTION =
  'You have reached the maximum allowed users as per your license. Please contact DataChat if you would like to increase your limit.';

export const INCORRECT_CURRENT_PASSWORD_ERROR_TITLE = 'Incorrect Current Password';
export const INCORRECT_CURRENT_PASSWORD_ERROR_DESCRIPTION =
  'The current password that you have entered is incorrect. Please verify and try again.';

export const UNAUTHORIZED_RESET_ERROR_TITLE = 'Invalid Token';
export const UNAUTHORIZED_RESET_ERROR_DESCRIPTION =
  'It seems your reset token in invalid. Please verify that you have entered the exact link as sent in your email';

export const PASSWORD_MISMATCH_TITLE = 'Passwords do not match';
export const PASSWORD_MISMATCH_DESCRIPTION = 'Please try again.';

export const LICENSE_WARNING_ALERT = 'LICENSE_WARNING_ALERT';
export const LICENSE_WARNING_TITLE = 'Warning';

export const BROWSER_TYPE_ALERT = 'BROWSER_TYPE_ALERT';
export const BROWSER_TYPE_TITLE = 'Browser Type Warning';
export const BROWSER_TYPE_DESCRIPTION =
  'Your browser is not officially supported by DataChat. Please use Chrome to avoid issues.';

export const SETTINGS_UPLOAD_CLOSE_WARNING_ALERT = `SETTINGS_UPLOAD_CLOSE_WARNING`;
export const SETTINGS_UPLOAD_CLOSE_WARNING_TITLE = `File Upload in Progress`;
export const SETTINGS_UPLOAD_CLOSE_WARNING_DESCRIPTION_SINGLE = `Closing this menu will also stop uploading the file. Do you want to continue?`;
export const SETTINGS_UPLOAD_CLOSE_WARNING_DESCRIPTION_MULTIPLE = `Closing this menu will also stop uploading the files. Do you want to continue?`;

export const LOAD_FILES_IN_SESSION_ALERT = 'LOAD_FILES_IN_SESSION_ALERT';

export const SESSION_DATASET_BUILDER_CLOSE_WARNING_ALERT = `SESSION_DATASET_BUILDER_CLOSE_WARNING`;
export const SESSION_DATASET_BUILDER_CLOSE_WARNING_TITLE = `Unsaved dataset will be lost`;
export const SESSION_DATASET_BUILDER_CLOSE_WARNING_DESCRIPTION = `Closing this session will also close the dataset builder, losing any unsaved data. Do you want to continue?`;
export const SESSION_DATASET_BUILDER_LEAVE_WARNING_DESCRIPTION = `Leaving this session will also close the dataset builder, losing any unsaved data. Do you want to continue?`;

export const EDIT_EXISTING_DB_ALERT = 'EDIT_EXISTING_DB_ALERT';
export const EDIT_EXISTING_DB_TITLE = 'Modifying a database connection file';
export const EDIT_EXISTING_DB_DESCRIPTION = 'Would you like to continue?';
export const RENAME_EXISTING_DB_ALERT = 'RENAME_EXISTING_DB_ALERT';
export const RENAME_EXISTING_DB_TITLE = 'Renaming a database connection file';
export const RENAME_EXISTING_DB_DESCRIPTION =
  'Renaming a database connection will remove access for all users. Would you like to continue?';

export const MOVE_DC_OBJECT_TO_SHARED_ALERT = 'MOVE_DC_OBJECT_TO_SHARED_ALERT';
export const MOVE_DC_OBJECT_TO_SHARED_TITLE = 'Change object permissions?';

export const PROFILE_PICTURE_SIZE_EXCEEDED_ALERT = `PROFILE_PICTURE_SIZE_EXCEEDED_WARNING`;
export const PROFILE_PICTURE_SIZE_EXCEEDED_TITLE = 'Profile Picture Size Exceeded';
export const PROFILE_PICTURE_SIZE_EXCEEDED_DESCRIPTION =
  'The profile picture size should be less than 10MB';

export const QUERY_OBJECT_DELETE_TITLE = 'Delete Query Object';
export const QUERY_OBJECT_DELETE_ALERT = 'QUERY_OBJECT_DELETE_ALERT';
// Keys
export const SINGLE_TEXT_INPUT = 'SINGLE_TEXT_INPUT';
export const SAVE_UNNAMED_SESSION = 'SAVE_UNNAMED_SESSION';
export const SAVE_AS_CONNECTION = 'SAVE_AS_CONNECTION';
export const OVERWRITE_WORKFLOW = 'OVERWRITE_WORKFLOW';
export const TRY_AGAIN_BUTTON_KEY = 'TRY_AGAIN';
export const CONFIRM_BUTTON_KEY = 'CONFIRM';
export const CANCEL_BUTTON_KEY = 'CANCEL';
export const RETURN_TO_LOGIN_BUTTON_KEY = 'RETURN_TO_LOGIN';
export const RETURN_TO_HOME_BUTTON_KEY = 'RETURN_TO_HOME';
export const YES_BUTTON_KEY = 'YES';
export const NO_BUTTON_KEY = 'NO';
export const LOGOUT_BUTTON_KEY = 'LOGOUT';
export const HOME_BUTTON_KEY = 'HOME';
export const OK_BUTTON_KEY = 'OK';
export const RECONNECT_KEY = 'RECONNECT';
export const GENERATE_DASHBOARD_BUTTON_KEY = 'GENERATE_DASHBOARD';
export const TERMINATE_AND_EXIT_KEY = 'TERMINATE_AND_EXIT';
export const RESEND_EMAIL_BUTTON_KEY = 'RESEND_EMAIL';
export const RELOAD_BUTTON_KEY = 'RELOAD_BUTTON';
export const USE_NEW_SESSION_KEY = 'USE_NEW_SESSION_KEY';
export const OVERWRITE_KEY = 'OVERWRITE_KEY';
export const OVERWRITE_SELECT_KEY = 'OVERWRITE_SELECT_KEY';
export const SUBMIT_BUTTON_KEY = 'CREATE_SUBMIT_KEY';
export const LOAD_KEY = 'LOAD_KEY';
export const REPORT_BUTTON_KEY = 'REPORT_BUTTON_KEY';

// Labels
export const TRY_AGAIN_LABEL = 'Try Again';
export const CONFIRM_BUTTON_LABEL = 'Confirm';
export const CANCEL_BUTTON_LABEL = 'Cancel';
export const RETURN_TO_LOGIN_LABEL = 'Return to Login';
export const RETURN_TO_HOME_LABEL = 'Return Home';
export const YES_BUTTON_LABEL = 'Yes';
export const NO_BUTTON_LABEL = 'No';
export const GO_TO_HOME_LABEL = 'Go to Home';
export const LOGOUT_LABEL = 'Sign out';
export const OK_BUTTON_LABEL = 'OK';
export const RECONNECT_LABEL = 'Reconnect';
export const GENERATE_DASHBOARD_LABEL = 'Generate';
export const TERMINATE_AND_EXIT_LABEL = 'Exit Session';
export const RESEND_EMAIL_BUTTON_LABEL = 'Resend';
export const RELOAD_BUTTON_LABEL = 'Reload';
export const USE_NEW_SESSION_LABEL = 'Start new session';
export const OVERWRITE_SELECT_LABEL = 'Overwrite Selected';
export const REPORT_BUTTON_LABEL = 'Report Error';
export const OVERWRITE_LABEL = 'Overwrite';
export const SUBMIT_BUTTON_LABEL = 'Submit';

// Text Field Constants
export const INVALID_INPUT = 'Invalid Input';

// dataset naming contraints
export const INVALID_DATASET_NAMES = ['dataset', 'name'];
export const INVALID_DATASET_NAME_TITLE = 'Error renaming dataset';
export const INVALID_DATASET_NAME_SPACES = 'Dataset names cannot contain spaces.';
export const INVALID_DATASET_NAME_RESERVED = `Datasets cannot be named any of the following: ${INVALID_DATASET_NAMES.join(
  ', ',
)}.`;
export const INVALID_DATASET_NAME_EMPTY = 'Dataset name cannot be empty.';
export const EXPRESSION_NAME_ALERT =
  'Please enter a name with no punctuation or special characters except for dashes (-) and underscores (_).';
export const DATE_RANGE_ALERT = 'Please enter a date in mm-dd-yyyy form';
export const NUMERICAL_RANGE_ALERT = 'Please enter only a numerical value';
export const INTEGER_ALERT = 'Please enter only an integer value';
export const CHARTLET_NAME_ALERT =
  'Please enter a name with no punctuation or special characters except for dashes (-) and underscores (_).';
export const COLUMN_NAME_ALERT = 'Please use only alphanumeric characters.';
export const COLUMN_NAME_ALERT_WITH_COMMAS = 'Please use alphanumeric words separated by commas.';
export const NUMERIC_ALERT_WITH_COMMAS = 'Please enter numbers separated by commas.';
export const ALPHANUMERIC_INPUT_ALERT = 'Please use only alphanumeric characters.';
export const PERCENTAGE_OUT_OF_RANGE = 'Please enter a number between 1 and 100';

export const RETURN_TO_HOME_FROM_SESSION_TITLE = 'Leaving session';
export const RETURN_TO_HOME_FROM_SESSION = 'RETURN_TO_HOME_FROM_SESSION';
export const RETURN_TO_HOME_FROM_SESSION_DESCRIPTION =
  'Are you sure you want to leave your session?';

export const INTERRUPT_FAILED_ALERT = 'INTERRUPT_FAILED_ALERT';
export const INTERRUPT_FAILED_TITLE = 'Interrupt failed';
export const INTERRUPT_FAILED_DESCRIPTION =
  'Interrupting that skill failed. We may be experiencing a high volume of traffic. ' +
  'You can either wait for your utterance to be submitted ' +
  'or end the session from the home screen.';

// General error message
export const GENERAL_ERROR_MESSAGE = 'Something went wrong, please try again.';

// Descriptions
export const getSwitchAppsDescription = (appName, targetAppName) =>
  `The app ${appName} is currently running. Would you like to end it and start ${targetAppName}?`;
export const getSwitchDashboardsDescription = (
  currentSessionName,
  currentAppName,
  targetSessionName,
  targetAppName,
) =>
  `The dashboard '${currentSessionName}' (${currentAppName}) is currently active. Would you like to end it and start another using '${targetSessionName}' (${targetAppName})?`;
export const getChatToDashboardAlert = () =>
  'A chat session is active. Would you like to end it and start the insights board?';
export const getDashboardToChatDescription = (currentSessionName, currentAppName, targetAppName) =>
  `The dashboard '${currentSessionName}' (${currentAppName}) is currently active. Would you like to end it and start ${targetAppName}?`;
export const getDashboardNameAlertDescription = (appName) =>
  `Creating a dashboard will end your current session in ${appName}.`;
export const promptFileDeletionDescription = (numFiles) => {
  const suffix = numFiles === 1 ? `this ${numFiles} file` : `all ${numFiles} files`;
  return `Are you sure you want to remove ${suffix}?`;
};
export const getGoodbyeTransitionDescription = () => {
  // const keystrokes = numKeystrokes === 1 ? 'keystroke' : 'keystrokes';
  // const minutes = sessionDuration === 1 ? 'minute' : 'minutes';

  return `See you back soon!`;
  // We currently track duration and keystrokes on the front-end, so this tracking will be incorrect when reloading a
  // session in another tab.  For now we will skip the tracking metrics.
  // if (typeof numKeystrokes !== 'undefined') {
  //   return `This session took ${numKeystrokes} ${keystrokes} and ${sessionDuration} ${minutes}. See you back soon!`;
  // }
  // return `This session took ${sessionDuration} ${minutes}. See you back soon!`;
};

// Alert creators for passing into the OPEN_ALERT_DIALOG action

export const uploadConnectionFailedAlert = () => ({
  title: CONNECTION_UPLOAD_TITLE,
  descriptions: [CONNECTION_UPLOAD_DESCRIPTION],
  buttons: [
    {
      key: OK_BUTTON_KEY,
      label: OK_BUTTON_LABEL,
    },
  ],
  dialogType: CONNECTION_UPLOAD_ALERT,
});

// A pop up that informs the user that the app failed to start
export const createAppFailedToStartAlert = (appName) => ({
  title: APP_FAILED_TO_START_TITLE,
  descriptions: [getAppFailedToStartDescription(appName)],
  buttons: [
    {
      key: OK_BUTTON_KEY,
      label: OK_BUTTON_LABEL,
    },
  ],
  dialogType: APP_FAILED_TO_START_ALERT,
});

// A pop up that asks the user if they are sure that they want to exit the session.
export const goodbyePromptAlert = () => ({
  title: GOODBYE_PROMPT_TITLE,
  descriptions: [GOODBYE_PROMPT_DESCRIPTION],
  buttons: [
    {
      key: CONFIRM_BUTTON_KEY,
      label: CONFIRM_BUTTON_LABEL,
    },
    {
      key: CANCEL_BUTTON_KEY,
      label: CANCEL_BUTTON_LABEL,
    },
  ],
  dialogType: GOODBYE_PROMPT_ALERT,
});

// A pop up that warns the user that their conversation with Ava is incomplete.
export const avaWorkingAlert = () => ({
  title: SESSION_WORKING_TITLE,
  descriptions: [SESSION_WORKING_DESCRIPTION],
  buttons: [
    {
      key: CONFIRM_BUTTON_KEY,
      label: CONFIRM_BUTTON_LABEL,
    },
    {
      key: CANCEL_BUTTON_KEY,
      label: CANCEL_BUTTON_LABEL,
    },
  ],
  dialogType: SESSION_WORKING_ALERT,
});

// A pop up that informs the user that he or she is being redirected to the home screen.
export const goodbyeTransitionAlert = (numKeystrokes, sessionDuration) => ({
  title: GOODBYE_TRANSITION_TITLE,
  descriptions: [getGoodbyeTransitionDescription(numKeystrokes, sessionDuration)],
  dialogType: GOODBYE_TRANSITION_ALERT,
});

/**
 * Returns config for session limit alert dialog
 * @param {String} newSesionType - type of session being created
 * @param {Boolean} isHome - true if session creation triggered on home page, false otherwise
 * @returns {Object} config to create session limit alert
 */
export const createSessionConcurrencyLimitAlert = (newSesionType, isHome) => ({
  title: SESSION_CONCURRENCY_LIMIT_TITLE,
  descriptions: getSessionConcurrencyLimitDescription(newSesionType, isHome),
  dialogType: SESSION_CONCURRENCY_LIMIT_ALERT,
  buttons: [
    {
      key: OK_BUTTON_KEY,
      label: OK_BUTTON_LABEL,
    },
  ],
});

export const createAskConversationLimitAlert = () => ({
  title: ASK_CONVERSATION_LIMIT_TITLE,
  descriptions: ASK_CONVERSATION_LIMIT_DESCRIPTION,
  dialogType: ASK_CONVERSATION_LIMIT_ALERT,
  buttons: [
    {
      key: OK_BUTTON_KEY,
      label: OK_BUTTON_LABEL,
    },
  ],
});

export const createActiveDashboardSessionForbiddenAlert = () => ({
  title: ACTIVE_DASHBOARD_FORBIDDEN_TITLE,
  descriptions: [ACTIVE_DASHBOARD_FORBIDDEN_DESCRIPTION],
  buttons: [
    {
      key: OK_BUTTON_KEY,
      label: OK_BUTTON_LABEL,
    },
  ],
  dialogType: ACTIVE_DASHBOARD_FORBIDDEN_ALERT,
});

export const createReloadSessionForbiddenAlert = () => ({
  title: RELOAD_FORBIDDEN_TITLE,
  descriptions: [RELOAD_FORBIDDEN_DESCRIPTION],
  buttons: [
    {
      key: OK_BUTTON_KEY,
      label: OK_BUTTON_LABEL,
    },
  ],
  dialogType: RELOAD_FORBIDDEN_ALERT,
});

export const createSessionNameAlreadyExistsAlert = () => ({
  title: SESSION_NAME_EXISTS_TITLE,
  descriptions: [SESSION_NAME_EXISTS_DESCRIPTION],
  buttons: [
    {
      key: OK_BUTTON_KEY,
      label: OK_BUTTON_LABEL,
    },
  ],
  dialogType: SESSION_NAME_EXISTS_ALERT,
});

export const createReloadSessionNotFoundAlert = () => ({
  title: RELOAD_SESSION_NOT_FOUND_TITLE,
  descriptions: [RELOAD_SESSION_NOT_FOUND_DESCRIPTION],
  buttons: [
    {
      key: OK_BUTTON_KEY,
      label: OK_BUTTON_LABEL,
    },
  ],
  dialogType: RELOAD_SESSION_NOT_FOUND_ALERT,
});

// A pop up before switching apps.
export const createSwitchAppsAlert = (appName, targetAppName) => ({
  title: SWITCH_APPS_TITLE,
  descriptions: [getSwitchAppsDescription(appName, targetAppName)],
  buttons: [
    {
      key: YES_BUTTON_KEY,
      label: YES_BUTTON_LABEL,
    },
    {
      key: NO_BUTTON_KEY,
      label: NO_BUTTON_LABEL,
    },
  ],
  dialogType: SWITCH_APPS_ALERT,
});

export const createReloadSessionAlert = () => ({
  title: RELOAD_APPS_TITLE,
  descriptions: [RELOAD_APPS_DESCRIPTION],
  buttons: [
    {
      key: RELOAD_BUTTON_KEY,
      label: RELOAD_BUTTON_LABEL,
    },
    {
      key: USE_NEW_SESSION_KEY,
      label: USE_NEW_SESSION_LABEL,
    },
  ],
});

export const createSwitchDashboardsAlert = (
  currentSessionName,
  currentAppName,
  targetSessionName,
  targetAppName,
) => ({
  title: SWITCH_DASHBOARDS_TITLE,
  descriptions: [
    getSwitchDashboardsDescription(
      currentSessionName,
      currentAppName,
      targetSessionName,
      targetAppName,
    ),
  ],
  buttons: [
    {
      key: YES_BUTTON_KEY,
      label: YES_BUTTON_LABEL,
    },
    {
      key: NO_BUTTON_KEY,
      label: NO_BUTTON_LABEL,
    },
  ],
  dialogType: SWITCH_DASHBOARDS_ALERT,
});

export const createSwitchFromChatToDashboardAlert = () => ({
  title: CHAT_TO_DASHBOARD_TITLE,
  descriptions: [getChatToDashboardAlert()],
  buttons: [
    {
      key: YES_BUTTON_KEY,
      label: YES_BUTTON_LABEL,
    },
    {
      key: NO_BUTTON_KEY,
      label: NO_BUTTON_LABEL,
    },
  ],
  dialogType: CHAT_TO_DASHBOARD_ALERT,
});

export const createSwitchFromDashboardToChatAlert = (
  currentSessionName,
  currentAppName,
  targetAppName,
) => ({
  title: DASHBOARD_TO_CHAT_TITLE,
  descriptions: [getDashboardToChatDescription(currentSessionName, currentAppName, targetAppName)],
  buttons: [
    {
      key: YES_BUTTON_KEY,
      label: YES_BUTTON_LABEL,
    },
    {
      key: NO_BUTTON_KEY,
      label: NO_BUTTON_LABEL,
    },
  ],
  dialogType: DASHBOARD_TO_CHAT_ALERT,
});

// A pop up that asks for user confirmation to delete a dashboard
export const confirmDeleteDashboardAlert = (dashboardName, sessionName, appName) => ({
  title: DASHBOARD_DELETE_TITLE,
  descriptions: [
    getDeleteDashboardDescription(dashboardName, sessionName, appName),
    'Are you sure you wish to continue?',
  ],
  buttons: [
    {
      key: CONFIRM_BUTTON_KEY,
      label: CONFIRM_BUTTON_LABEL,
    },
    {
      key: CANCEL_BUTTON_KEY,
      label: CANCEL_BUTTON_LABEL,
    },
  ],
  dialogType: DASHBOARD_DELETE_ALERT,
});

// A pop up that asks for user confirmation to delete a shared dashboard
export const confirmDeleteDashboardSharedAlert = (dashboardName, sessionName, appName) => ({
  title: DASHBOARD_SHARED_DELETE_TITLE,
  descriptions: [
    getDeleteDashboardSharedDescription(dashboardName, sessionName, appName),
    'Are you sure you wish to continue?',
  ],
  buttons: [
    {
      key: CONFIRM_BUTTON_KEY,
      label: CONFIRM_BUTTON_LABEL,
    },
    {
      key: CANCEL_BUTTON_KEY,
      label: CANCEL_BUTTON_LABEL,
    },
  ],
  dialogType: DASHBOARD_SHARED_DELETE_ALERT,
});

export const confirmDeleteMultiObjectAlert = (insightWarning = false, sharedWarning = false) => ({
  title: MULTI_OBJECT_DELETE_TITLE,
  dialogType: MULTI_OBJECT_DELETE_ALERT,
  buttons: [
    {
      key: CONFIRM_BUTTON_KEY,
      label: CONFIRM_BUTTON_LABEL,
    },
    {
      key: CANCEL_BUTTON_KEY,
      label: CANCEL_BUTTON_LABEL,
    },
  ],
  descriptions: getDeleteMultiObjectDescription(insightWarning, sharedWarning),
});

// A pop up that shares with user why they can't delete an insights board they didn't create
export const errorDeleteInsightsBoardAlert = () => ({
  title: INSIGHTS_BOARD_DELETE_TITLE,
  descriptions: ['Only the creator of the insights board may delete it.'],
  dialogType: INSIGHTS_BOARD_DELETE_ALERT,
});

export const errorVariableSubmissionAlert = () => ({
  title: INSIGHTS_BOARD_VARIABLE_CREATE_ERROR,
  descriptions: ['There was an error submitting the variable.'],
  dialogType: INSIGHTS_BOARD_VARIABLE_ERROR_ALERT,
});

export const errorVariableEditingAlert = () => ({
  title: INSIGHTS_BOARD_VARIABLE_EDIT_ERROR,
  descriptions: ['There was an error editing the variable.'],
  dialogType: INSIGHTS_BOARD_VARIABLE_ERROR_ALERT,
});

// A pop up that tells user that the name they picked for an insights board already exists
export const createDuplicateNameErrorAlert = () => ({
  title: INSIGHTS_BOARD_DUPLICATE_NAME,
  descriptions: ['An insights board with this name already exists. Please choose another name.'],
  dialogType: INSIGHTS_BOARD_DUPLICATE_NAME_ALERT,
});

// A pop up that informs the user that dashboard generation has failed
export const invalidDashboardAlert = (description) => ({
  title: DASHBOARD_INVALID_SESSION_TITLE,
  descriptions: [description],
  buttons: [
    {
      key: OK_BUTTON_KEY,
      label: OK_BUTTON_LABEL,
    },
  ],
  dialogType: DASHBOARD_INVALID_SESSION_ALERT,
});

// A pop up that warns user of session termination and gets dashboard name.
export const dashboardNameAlert = (appName, props) => ({
  title: GET_DASHBOARD_NAME_TITLE,
  descriptions: [getDashboardNameAlertDescription(appName)],
  inputs: [
    {
      type: 'TextField',
      key: 'dashboard-name',
      props,
    },
  ],
  buttons: [
    {
      key: CANCEL_BUTTON_KEY,
      label: CANCEL_BUTTON_LABEL,
    },
    {
      key: GENERATE_DASHBOARD_BUTTON_KEY,
      label: GENERATE_DASHBOARD_LABEL,
    },
  ],
  dialogType: GET_DASHBOARD_NAME_ALERT,
});

export const createNetworkErrorAlert = () => ({
  title: NETWORK_ERROR_TITLE,
  descriptions: [NETWORK_ERROR_DESCRIPTION],
  buttons: [
    {
      key: RECONNECT_KEY,
      label: RECONNECT_LABEL,
    },
    {
      key: TERMINATE_AND_EXIT_KEY,
      label: TERMINATE_AND_EXIT_LABEL,
    },
  ],
  dialogType: NETWORK_ERROR_ALERT,
});

export const createRegisterErrorAlert = (title, description) => ({
  title,
  descriptions: [description],
  buttons: [
    {
      key: OK_BUTTON_KEY,
      label: OK_BUTTON_LABEL,
    },
  ],
  dialogType: REGISTER_ERROR_ALERT,
});

export const createLoginErrorAlert = (title, description) => ({
  title,
  descriptions: [description],
  buttons: [
    {
      key: OK_BUTTON_KEY,
      label: OK_BUTTON_LABEL,
    },
  ],
  dialogType: LOGIN_ERROR_ALERT,
});

export const createUnauthenticatedErrorAlert = () => ({
  title: UNAUTHENTICATED_ERROR_DESCRIPTION_TITLE,
  descriptions: [UNAUTHENTICATED_ERROR_DESCRIPTION],
  buttons: [
    {
      key: RETURN_TO_LOGIN_BUTTON_KEY,
      label: RETURN_TO_LOGIN_LABEL,
    },
  ],
  dialogType: UNAUTHENTICATED_ERROR_ALERT,
});

export const createForgetDatasetAlert = () => ({
  title: FORGET_DATASET_DESCRIPTION_TITLE,
  descriptions: [FORGET_DATASET_DESCRIPTION],
  buttons: [
    {
      key: YES_BUTTON_KEY,
      label: YES_BUTTON_LABEL,
    },
    {
      key: CANCEL_BUTTON_KEY,
      label: CANCEL_BUTTON_LABEL,
    },
  ],
  dialogType: FORGET_DATASET_ALERT,
});

// TODO: Make this point to the subscription page once the subscriptions are ready
export const createForbiddenRoleErrorAlert = () => ({
  title: FORBIDDEN_ROLE_ERROR_TITLE,
  descriptions: [FORBIDDEN_ROLE_ERROR_DESCRIPTION],
  buttons: [
    {
      key: OK_BUTTON_KEY,
      label: OK_BUTTON_LABEL,
    },
  ],
  dialogType: FORBIDDEN_ROLE_ERROR_ALERT,
  showCloseButton: true,
});

export const uploadFileFailedAlert = () => ({
  title: FILE_UPLOAD_FAILED_TITLE,
  descriptions: [FILE_UPLOAD_FAILED_DESCRIPTION],
  buttons: [
    {
      key: OK_BUTTON_KEY,
      label: OK_BUTTON_LABEL,
    },
  ],
  dialogType: FILE_UPLOAD_FAILED_ALERT,
});

export const createFileStorageLimitReachedAlert = (limit) => ({
  title: FILE_STORAGE_LIMIT_TITLE,
  // multiply by 1024^2 since limit is in MB
  descriptions: [
    limit
      ? `You have reached your file storage limit of ${formatBytes(limit * 1024 * 1024)}.` // ${UPGRADE_PROMPT}`
      : `You have reached your file storage limit.`,
  ], // ${UPGRADE_PROMPT}`],
  buttons: [
    {
      key: OK_BUTTON_KEY,
      label: OK_BUTTON_LABEL,
    }, // TODO: Add upgrade button which links to a subscription page
  ],
  dialogType: FILE_STORAGE_LIMIT_ALERT,
});

export const createForbiddenErrorAlert = () => ({
  title: FORBIDDEN_ERROR_TITLE,
  buttons: [
    {
      key: RETURN_TO_HOME_BUTTON_KEY,
      label: RETURN_TO_HOME_LABEL,
    },
  ],
  dialogType: FORBIDDEN_ERROR_ALERT,
});

export const createGeneralErrorAlert = (error) => {
  const deeplyNestedDataExists =
    error && error.response && error.response.status && error.response.statusText;

  let description = [];
  if (error && error.response && error.response.data && !isEmpty(error.response.data)) {
    // Request Error description
    description = [error.response.data];
  } else if (error && error.message && !isEmpty(error.message)) {
    // General error description
    description = [error.message];
  }
  return {
    title: deeplyNestedDataExists
      ? `${error.response.status}: ${error.response.statusText}`
      : GENERAL_ERROR_TITLE,
    descriptions: [GENERAL_ERROR_DESCRIPTION],
    collapsedDescriptions: description || undefined,
    buttons: [
      {
        key: REPORT_BUTTON_KEY,
        label: REPORT_BUTTON_LABEL,
      },
      {
        key: OK_BUTTON_KEY,
        label: OK_BUTTON_LABEL,
      },
    ],
    dialogType: GENERAL_ERROR_ALERT,
  };
};

export const createGeneralAvaErrorAlert = (title) => {
  return {
    title,
    descriptions: [GENERAL_ERROR_DESCRIPTION],
    buttons: [
      {
        key: REPORT_BUTTON_KEY,
        label: REPORT_BUTTON_LABEL,
      },
      {
        key: OK_BUTTON_KEY,
        label: OK_BUTTON_LABEL,
      },
    ],
    dialogType: GENERAL_AVA_ERROR_ALERT,
  };
};

export const createGeneralInsightsBoardErrorAlert = (title) => {
  return {
    title,
    descriptions: [GENERAL_ERROR_DESCRIPTION],
    buttons: [
      {
        key: REPORT_BUTTON_KEY,
        label: REPORT_BUTTON_LABEL,
      },
      {
        key: OK_BUTTON_KEY,
        label: OK_BUTTON_LABEL,
      },
    ],
    dialogType: GENERAL_IB_ERROR_ALERT,
  };
};

/**
 * Create an alert that notifies the user that their file contains invalid characters
 * Invalid characters are non-ISO-8859-1 characters
 * @param {String} filename The name of the file that contains invalid characters
 */
export const createFileInvalidNameAlert = (filename) => ({
  title: FILE_UPLOAD_FAILED_TITLE,
  descriptions: [getFileInvalidNameDescription(filename)],
  buttons: [
    {
      key: OK_BUTTON_KEY,
      label: OK_BUTTON_LABEL,
    },
  ],
  dialogType: FILE_INVALID_NAME_ALERT,
});

export const createFileInvalidAlert = () => ({
  title: FILE_INVALID_TITLE,
  descriptions: [FILE_INVALID_DESCRIPTION],
  buttons: [
    {
      key: OK_BUTTON_KEY,
      label: OK_BUTTON_LABEL,
    },
  ],
  dialogType: FILE_INVALID_ALERT,
});

export const createFileBadStatusRequestAlert = (error) => ({
  title: FILE_BAD_STATUS_REQUEST_TITLE,
  descriptions: [error],
  buttons: [
    {
      key: OK_BUTTON_KEY,
      label: OK_BUTTON_LABEL,
    },
  ],
  dialogType: FILE_BAD_STATUS_REQUEST_ALERT,
});

export const createRecipeAlreadyExistsAlert = (name) => ({
  title: `${name} ${RECIPE_ALREADY_EXISTS_TITLE}`,
  descriptions: [RECIPE_ALREADY_EXISTS_DESCRIPTION],
  buttons: [
    {
      key: YES_BUTTON_KEY,
      label: YES_BUTTON_LABEL,
    },
    {
      key: NO_BUTTON_KEY,
      label: NO_BUTTON_LABEL,
    },
  ],
  dialogType: RECIPE_ALREADY_EXISTS_ALERT,
});

export const createDatasetAlreadyExistAlert = (datasetName) => ({
  title: `${datasetName} ${DATASET_ALREADY_EXISTS_TITLE}`,
  descriptions: [DATASET_ALREADY_EXISTS_DESCRIPTION],
  buttons: [
    {
      key: YES_BUTTON_KEY,
      label: YES_BUTTON_LABEL,
      primary: true,
    },
    {
      key: NO_BUTTON_KEY,
      label: NO_BUTTON_LABEL,
    },
  ],
  dialogType: FILE_ALREADY_EXISTS_ALERT,
});

/**
 * Creates an alert to prompt the user on overwriting files.
 * @param {Array of Objects} datasets
 */
export const createDatasetsAlreadyExistAlert = (datasets) => ({
  title: DATASETS_ALREADY_EXIST_TITLE,
  descriptions: [DATASETS_ALREADY_EXIST_DESCRIPTION],
  inputs: datasets.map((dataset) => {
    return {
      key: dataset.Name,
      type: 'Checkbox',
      checked: false,
    };
  }),
  buttons: [
    {
      key: OVERWRITE_KEY,
      label: OVERWRITE_LABEL,
      primary: true,
    },
    {
      key: NO_BUTTON_KEY,
      label: NO_BUTTON_LABEL,
    },
  ],
  dialogType: DATASETS_ALREADY_EXIST_ALERT,
});

export const createFileAlreadyExistsAlert = (filename) => ({
  title: `${filename} ${FILE_ALREADY_EXISTS_TITLE}`,
  descriptions: filename.endsWith('.dcw')
    ? [RECIPE_ALREADY_EXISTS_DESCRIPTION]
    : [FILE_ALREADY_EXISTS_DESCRIPTION],
  buttons: [
    {
      key: YES_BUTTON_KEY,
      label: YES_BUTTON_LABEL,
      primary: true,
    },
    {
      key: NO_BUTTON_KEY,
      label: NO_BUTTON_LABEL,
    },
  ],
  dialogType: FILE_ALREADY_EXISTS_ALERT,
});

/**
 * Creates an alert to prompt the user on overwriting files.
 * @param {Array of Objects} files
 */
export const createFilesAlreadyExistAlert = (files) => ({
  title: FILES_ALREADY_EXIST_TITLE,
  descriptions: [FILES_ALREADY_EXIST_DESCRIPTION],
  inputs: files.map((file) => {
    return {
      key: file.filename,
      type: 'Checkbox',
      checked: false,
    };
  }),
  buttons: [
    {
      key: OVERWRITE_KEY,
      label: OVERWRITE_LABEL,
      primary: true,
    },
    {
      key: CANCEL_BUTTON_KEY,
      label: CANCEL_BUTTON_LABEL,
    },
  ],
  dialogType: FILES_ALREADY_EXIST_ALERT,
});

/**
 * Creates an alert that prompts the user on deleting files.
 *
 * @param {Integer} numFiles Number of files to be deleted
 */
export const createPromptFileDeletionAlert = (numFiles, description = undefined) => ({
  title: PROMPT_FILE_DELETION_TITLE,
  // description will have a min-length of 3
  descriptions: description.length >= 3 ? description : [promptFileDeletionDescription(numFiles)],
  buttons: [
    {
      key: YES_BUTTON_KEY,
      label: YES_BUTTON_LABEL,
    },
    {
      key: NO_BUTTON_KEY,
      label: NO_BUTTON_LABEL,
    },
  ],
  dialogType: PROMPT_FILE_DELETION_ALERT,
});

export const createAlreadyLoggedInAlert = () => ({
  title: ALREADY_LOGGED_IN_TITLE,
  descriptions: [ALREADY_LOGGED_IN_DESCRIPTION],
  buttons: [
    {
      key: HOME_BUTTON_KEY,
      label: GO_TO_HOME_LABEL,
    },
    {
      key: LOGOUT_BUTTON_KEY,
      label: LOGOUT_LABEL,
    },
  ],
  dialogType: ALREADY_LOGGED_IN_ALERT,
});

/**
 *
 * @param {boolean} isSignedIn - Tells us if the user is requseting to reset their password after
 * signing in
 * @returns
 */
export const createResetEmailSentAlert = (isSignedIn) => ({
  title: EMAIL_RESET_TOKEN_REQUEST_TITLE,
  descriptions: [
    isSignedIn
      ? EMAIL_RESET_TOKEN_REQUEST_DESCRIPTION_SIGNED_IN
      : EMAIL_RESET_TOKEN_REQUEST_DESCRIPTION,
  ],
  buttons: [
    {
      key: OK_BUTTON_KEY,
      label: OK_BUTTON_LABEL,
    },
  ],
  dialogType: EMAIL_RESET_TOKEN_REQUEST_ALERT,
});

export const createOTPAccountCreatedAlert = () => ({
  title: OTP_ACCOUNT_CREATED_TITLE,
  descriptions: [OTP_ACCOUNT_CREATED_DESCRIPTION],
  buttons: [
    {
      key: OK_BUTTON_KEY,
      label: OK_BUTTON_LABEL,
    },
  ],
  dialogType: OTP_ACCOUNT_CREATED_ALERT,
});

export const unsavedDatasetChangesAlert = () => ({
  id: UNSAVED_DATASET_CHANGES_ALERT,
  dialogType: UNSAVED_DATASET_CHANGES_ALERT,
  title: UNSAVED_DATASET_CHANGES_TITLE,
  descriptions: [UNSAVED_DATASET_CHANGES_DESCRIPTION],
  buttons: [
    {
      key: DISCARD_CHANGES_KEY,
      label: DISCARD_CHANGES_LABEL,
    },
    {
      key: SAVE_CHANGES_KEY,
      label: SAVE_CHANGES_LABEL,
      primary: true,
    },
  ],
});

export const unsavedCatalogChangesAlert = () => ({
  id: UNSAVED_CATALOG_CHANGES_ALERT,
  dialogType: UNSAVED_CATALOG_CHANGES_ALERT,
  title: UNSAVED_CATALOG_CHANGES_TITLE,
  descriptions: [UNSAVED_CATALOG_CHANGES_DESCRIPTION],
  showCloseButton: true,
  buttons: [
    {
      key: DISCARD_CHANGES_KEY,
      label: DISCARD_CHANGES_LABEL,
    },
    {
      key: SAVE_CHANGES_KEY,
      label: SAVE_CHANGES_LABEL,
      primary: true,
    },
  ],
});

export const createTaskFailedAlert = () => ({
  id: TASK_FAILED_ALERT,
  dialogType: TASK_FAILED_ALERT,
  title: TASK_FAILED_TITLE,
  descriptions: [TASK_FAILED_DESCRIPTION],
  buttons: [
    {
      key: REPORT_BUTTON_KEY,
      label: REPORT_BUTTON_LABEL,
    },
    {
      key: OK_BUTTON_KEY,
      label: OK_BUTTON_LABEL,
      primary: true,
    },
  ],
});

export const createOTPEmailSentAlert = () => ({
  title: OTP_EMAIL_SENT_TITLE,
  descriptions: [OTP_EMAIL_SENT_DESCRIPTION],
  buttons: [
    {
      key: OK_BUTTON_KEY,
      label: OK_BUTTON_LABEL,
    },
  ],
  dialogType: OTP_EMAIL_SENT_ALERT,
});

export const createPasswordResetAlert = () => ({
  title: RESET_PASSWORD_SUCCESS_TITLE,
  descriptions: [RESET_PASSWORD_SUCCESS_DESCRIPTION],
  buttons: [
    {
      key: OK_BUTTON_KEY,
      label: OK_BUTTON_LABEL,
    },
  ],
  dialogType: RESET_PASSWORD_SUCCESS_ALERT,
});

export const createPasswordTokenExpiredAlert = () => ({
  title: PASSWORD_TOKEN_EXPIRED_TITLE,
  descriptions: [PASSWORD_TOKEN_EXPIRED_DESCRIPTION],
  buttons: [
    {
      key: OK_BUTTON_KEY,
      label: OK_BUTTON_LABEL,
    },
  ],
  dialogType: PASSWORD_TOKEN_EXPIRED_ALERT,
});

export const createResetPasswordFromMenuAlert = () => ({
  title: PROMPT_LOGOUT_AFTER_PASSWORD_RESET_TITLE,
  descriptions: [PROMPT_LOGOUT_AFTER_PASSWORD_RESET_DESCRIPTION],
  buttons: [
    {
      key: OK_BUTTON_KEY,
      label: OK_BUTTON_LABEL,
    },
  ],
  dialogType: PROMPT_LOGOUT_AFTER_PASSWORD_RESET_ALERT,
});

export const createLicenseWarningAlert = (description) => ({
  title: LICENSE_WARNING_TITLE,
  descriptions: [description],
  buttons: [
    {
      key: OK_BUTTON_KEY,
      label: OK_BUTTON_LABEL,
    },
  ],
  dialogType: LICENSE_WARNING_ALERT,
});

export const createBrowserTypeAlert = () => ({
  title: BROWSER_TYPE_TITLE,
  descriptions: [BROWSER_TYPE_DESCRIPTION],
  buttons: [
    {
      key: OK_BUTTON_KEY,
      label: OK_BUTTON_LABEL,
    },
  ],
  dialogType: BROWSER_TYPE_DESCRIPTION,
});

export const createSendVerifyEmailSuccessAlert = (email) => ({
  title: SEND_VERIFY_EMAIL_SUCCESS_TITLE,
  descriptions: [SEND_VERIFY_EMAIL_SUCCESS_DESCRIPTION + email],
  buttons: [
    {
      key: OK_BUTTON_KEY,
      label: OK_BUTTON_LABEL,
    },
  ],
  dialogType: SEND_VERIFY_EMAIL_SUCCESS_ALERT,
});

export const createSendVerifyEmailFailureAlert = (description) => ({
  title: SEND_VERIFY_EMAIL_FAILURE_TITLE,
  descriptions: [description],
  buttons: [
    {
      key: OK_BUTTON_KEY,
      label: OK_BUTTON_LABEL,
    },
  ],
  dialogType: SEND_VERIFY_EMAIL_FAILURE_ALERT,
});

export const createVerifyAccountSuccessAlert = (email) => ({
  title: VERIFY_ACCOUNT_SUCCESS_TITLE,
  descriptions: [email + VERIFY_ACCOUNT_SUCCESS_DESCRIPTION],
  buttons: [
    {
      key: OK_BUTTON_KEY,
      label: OK_BUTTON_LABEL,
    },
  ],
  dialogType: VERIFY_ACCOUNT_SUCCESS_ALERT,
});

export const createLeaveSessionCollaborationAlert = () => ({
  id: LEAVE_SESSION_COLLABORATION_ALERT,
  title: LEAVE_SESSION_COLLABORATION_TITLE,
  descriptions: [LEAVE_SESSION_COLLABORATION_DESCRIPTION],
  buttons: [
    {
      key: CANCEL_BUTTON_KEY,
      label: CANCEL_BUTTON_LABEL,
      primary: true,
    },
    {
      key: CONFIRM_BUTTON_KEY,
      label: CONFIRM_BUTTON_LABEL,
    },
  ],
  dialogType: VERIFY_ACCOUNT_SUCCESS_ALERT,
});

export const createVerifyAccountFailureAlert = (description) => ({
  title: VERIFY_ACCOUNT_FAILURE_TITLE,
  descriptions: [description],
  buttons: [
    {
      key: RESEND_EMAIL_BUTTON_KEY,
      label: RESEND_EMAIL_BUTTON_LABEL,
    },
    {
      key: OK_BUTTON_KEY,
      label: OK_BUTTON_LABEL,
    },
  ],
  dialogType: VERIFY_ACCOUNT_FAILURE_ALERT,
});

export const createUnverifiedAccountAlert = () => ({
  title: UNVERIFIED_ACCOUNT_TITLE,
  descriptions: [UNVERIFIED_ACCOUNT_DESCRIPTION],
  buttons: [
    {
      key: RESEND_EMAIL_BUTTON_KEY,
      label: RESEND_EMAIL_BUTTON_LABEL,
    },
    {
      key: OK_BUTTON_KEY,
      label: OK_BUTTON_LABEL,
    },
  ],
  dialogType: UNVERIFIED_ACCOUNT_ALERT,
});

// A pop up that confirms closing the settings menu while uploading
export const closeSettingsWhileUploadingAlert = (count) => ({
  title: SETTINGS_UPLOAD_CLOSE_WARNING_TITLE,
  descriptions: [
    count > 1
      ? SETTINGS_UPLOAD_CLOSE_WARNING_DESCRIPTION_MULTIPLE
      : SETTINGS_UPLOAD_CLOSE_WARNING_DESCRIPTION_SINGLE,
  ],
  buttons: [
    {
      key: YES_BUTTON_KEY,
      label: YES_BUTTON_KEY,
    },
    {
      key: NO_BUTTON_KEY,
      label: NO_BUTTON_KEY,
    },
  ],
  dialogType: SETTINGS_UPLOAD_CLOSE_WARNING_ALERT,
});

// A pop up that confirms closing a session while the dataset builder is open
export const closeSessionWhileDatasetBuilderOpenAlert = (isClosing) => ({
  title: SESSION_DATASET_BUILDER_CLOSE_WARNING_TITLE,
  descriptions: [
    isClosing
      ? SESSION_DATASET_BUILDER_CLOSE_WARNING_DESCRIPTION
      : SESSION_DATASET_BUILDER_LEAVE_WARNING_DESCRIPTION,
  ],
  buttons: [
    {
      key: YES_BUTTON_KEY,
      label: YES_BUTTON_KEY,
    },
    {
      key: NO_BUTTON_KEY,
      label: NO_BUTTON_KEY,
    },
  ],
  dialogType: SESSION_DATASET_BUILDER_CLOSE_WARNING_ALERT,
});

// a pop up alerting the user that renaming db connection will delete
// for all users with access
export const renameExistingDatabaseAlert = () => ({
  title: RENAME_EXISTING_DB_TITLE,
  descriptions: [RENAME_EXISTING_DB_DESCRIPTION],
  buttons: [
    {
      key: YES_BUTTON_KEY,
      label: YES_BUTTON_LABEL,
    },
    {
      key: NO_BUTTON_KEY,
      label: NO_BUTTON_LABEL,
    },
  ],
  dialogType: RENAME_EXISTING_DB_ALERT,
});

// A pop up confirming the user is sure they want to edit DB connection file.
export const editExistingDatabaseAlert = () => ({
  title: EDIT_EXISTING_DB_TITLE,
  descriptions: [EDIT_EXISTING_DB_DESCRIPTION],
  buttons: [
    {
      key: YES_BUTTON_KEY,
      label: YES_BUTTON_LABEL,
    },
    {
      key: NO_BUTTON_KEY,
      label: NO_BUTTON_LABEL,
    },
  ],
  dialogType: EDIT_EXISTING_DB_ALERT,
});

// A pop up that asks for user confirmation to delete a shared object
export const confirmDeleteObjectSharedAlert = (name, addMessage) => ({
  title: OBJECT_DELETE_TITLE,
  descriptions: [
    getDeleteObjectSharedDescription(name),
    'Are you sure you wish to continue?',
    addMessage,
  ],
  buttons: [
    {
      key: CONFIRM_BUTTON_KEY,
      label: CONFIRM_BUTTON_LABEL,
    },
    {
      key: CANCEL_BUTTON_KEY,
      label: CANCEL_BUTTON_LABEL,
    },
  ],
  dialogType: OBJECT_DELETE_ALERT,
});

// A pop up that asks for user confirmation to delete an Object
export const confirmDeleteObjectAlert = (name, addMessage, fromLocation) => {
  const descriptions = [getDeleteObjectDescription(name, fromLocation ?? 'permanently')];
  if (addMessage !== undefined) {
    descriptions.push(addMessage);
  }
  descriptions.push('Are you sure you wish to continue?');
  return {
    id: 'confirmDeleteObjectAlert',
    title: OBJECT_DELETE_TITLE,
    descriptions,
    buttons: [
      {
        key: CONFIRM_BUTTON_KEY,
        label: CONFIRM_BUTTON_LABEL,
      },
      {
        key: CANCEL_BUTTON_KEY,
        label: CANCEL_BUTTON_LABEL,
      },
    ],
    dialogType: OBJECT_DELETE_ALERT,
  };
};

// A pop up that asks for user confirmation to delete an chart backing snapshot
export const confirmDeleteChartBackingSnapshot = (name, addMessage) => ({
  title: OBJECT_DELETE_TITLE,
  descriptions: [
    getDeleteChartBackingSnapshotDescription(name),
    'Are you sure you wish to continue?',
    addMessage,
  ],
  buttons: [
    {
      key: CONFIRM_BUTTON_KEY,
      label: CONFIRM_BUTTON_LABEL,
    },
    {
      key: CANCEL_BUTTON_KEY,
      label: CANCEL_BUTTON_LABEL,
    },
  ],
  dialogType: OBJECT_DELETE_ALERT,
});

export const renameObjectAlreadyExistsAlert = (type, name) => ({
  title: `${type} Name Exists`,
  descriptions: [`A ${type} with the name ${name} already exists.`],
  buttons: [
    {
      key: OK_BUTTON_KEY,
      label: OK_BUTTON_LABEL,
    },
  ],
  dialogType: OBJECT_EXISTS_ALERT,
});

export const moveDcObjectToSharedFolderAlert = (objectName, destinationName) => ({
  title: MOVE_DC_OBJECT_TO_SHARED_TITLE,
  descriptions: [
    `Users with access to ${destinationName} will now have access to ${objectName}.`,
    `Are you sure you wish to continue?`,
  ],
  buttons: [
    {
      key: CONFIRM_BUTTON_KEY,
      label: CONFIRM_BUTTON_LABEL,
    },
    {
      key: CANCEL_BUTTON_KEY,
      label: CANCEL_BUTTON_LABEL,
    },
  ],
  dialogType: MOVE_DC_OBJECT_TO_SHARED_ALERT,
});

export const profilePictureSizeExceededAlert = () => ({
  title: PROFILE_PICTURE_SIZE_EXCEEDED_TITLE,
  descriptions: [PROFILE_PICTURE_SIZE_EXCEEDED_DESCRIPTION],
  buttons: [
    {
      key: OK_BUTTON_KEY,
      label: OK_BUTTON_LABEL,
    },
  ],
  dialogType: PROFILE_PICTURE_SIZE_EXCEEDED_ALERT,
});

export const errorDeleteQueryObjectAlert = () => ({
  title: QUERY_OBJECT_DELETE_TITLE,
  descriptions: ['Only the creator of the query object may delete it.'],
  dialogType: QUERY_OBJECT_DELETE_ALERT,
});

export const deleteInsightsBoardChartsAlert = () => ({
  title: INSIGHTS_BOARD_DELETE_CHARTS_TITLE,
  descriptions: [INSIGHTS_BOARD_DELETE_CHARTS_DESCRIPTION],
  buttons: [
    {
      key: YES_BUTTON_KEY,
      label: YES_BUTTON_LABEL,
    },
    {
      key: NO_BUTTON_KEY,
      label: NO_BUTTON_LABEL,
    },
  ],
  dialogType: INSIGHTS_BOARD_DELETE_CHARTS_ALERT,
});

// A pop up before switching apps.
export const createReturnToHomeFromSessionAlert = () => ({
  title: RETURN_TO_HOME_FROM_SESSION_TITLE,
  descriptions: [RETURN_TO_HOME_FROM_SESSION_DESCRIPTION],
  buttons: [
    {
      key: YES_BUTTON_KEY,
      label: YES_BUTTON_LABEL,
    },
    {
      key: NO_BUTTON_KEY,
      label: NO_BUTTON_LABEL,
    },
  ],
  dialogType: RETURN_TO_HOME_FROM_SESSION,
});

export const createInterruptFailedAlert = () => ({
  title: INTERRUPT_FAILED_TITLE,
  descriptions: [INTERRUPT_FAILED_DESCRIPTION],
  buttons: [
    {
      key: OK_BUTTON_KEY,
      label: OK_BUTTON_LABEL,
    },
  ],
  dialogType: INTERRUPT_FAILED_ALERT,
});
