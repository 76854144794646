import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

/* eslint-disable no-console */
/**
 * This utils file provides functions to save, get, and remove from
 * local storage. Values in local storage is always a string.
 * The most convenient means of saving and retrieving from
 * local storage is to use functions JSON.stringify and JSON.parse.
 * Both functions work on booleans, numbers, arrays, and objects
 * but not for strings.
 *
 * Thus, in this implementation we utilize these built-in functions
 * for saving and retrieving values from local storage unless
 * the values are actually strings themselves.
 *
 * NOTE: The caller does not require to stringify/parse the values
 * and assume they be stringified and parsed here.
 */

// Provided a key, this func will attempt to read its value in localstorage
// and return the value else it will return undefined
/* eslint-disable consistent-return */

export class LocalStorageSecurityError extends Error {
  constructor(props) {
    super(props);
    this.name = 'LocalStorageSecurityError';
  }
}

let warnedUserLocalStorageIsNotAvailable = false;

const warnUserLocalStorageIsNotAvailable = () => {
  warnedUserLocalStorageIsNotAvailable = true;
  confirmAlert({
    title: 'Cannot access local storage.',
    message: 'Please enable cookies and local storage.',
    buttons: [{ label: 'dismiss' }],
  });
};

export function getFromLS(key) {
  let value;
  try {
    value = localStorage.getItem(key);
  } catch (err) {
    if (err instanceof DOMException && err.name === 'SecurityError') {
      if (!warnedUserLocalStorageIsNotAvailable) {
        warnUserLocalStorageIsNotAvailable();
      }
    } else {
      throw err;
    }
  }

  try {
    // JSON, number, boolean,
    return JSON.parse(value);
  } catch (err) {
    if (err instanceof SyntaxError) {
      // string
      return value;
    } else if (err instanceof DOMException && err.name === 'SecurityError') {
      if (!warnedUserLocalStorageIsNotAvailable) {
        warnUserLocalStorageIsNotAvailable();
      }
    } else {
      throw err;
    }
  }
}
/* eslint-enable consistent-return */

// Saves any key-value pair to local storage overwriting the
// existing key-value pair
export function saveToLS(key, value) {
  try {
    if (typeof value === 'string') {
      // already a string
      localStorage.setItem(key, value);
    } else {
      // need to stringify JSON, number, or boolean
      localStorage.setItem(key, JSON.stringify(value));
    }
  } catch (err) {
    if (err instanceof DOMException && err.name === 'SecurityError') {
      if (!warnedUserLocalStorageIsNotAvailable) {
        warnUserLocalStorageIsNotAvailable();
      }
    } else {
      throw err;
    }
  }
}

// Removes a key-value pair from local storage
export function removeFromLS(key) {
  try {
    localStorage.removeItem(key);
  } catch (err) {
    if (err instanceof DOMException && err.name === 'SecurityError') {
      if (!warnedUserLocalStorageIsNotAvailable) {
        warnUserLocalStorageIsNotAvailable();
      }
    } else {
      throw err;
    }
  }
}

// Checks if key is in local storage
export function existsInLS(key) {
  try {
    return key in localStorage;
  } catch (err) {
    if (err instanceof DOMException && err.name === 'SecurityError') {
      if (!warnedUserLocalStorageIsNotAvailable) {
        warnUserLocalStorageIsNotAvailable();
      }
      return false;
    }
    throw err;
  }
}
