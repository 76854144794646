// Max value accepted for the param maximumFractionDigits in toLocaleString
// documention: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString#specifications
export const MAX_FRACTION_DIGITS = 20;

// minimum value where Number(string) does not represent a number with scientific
export const SCIENTIFIC_CONVERTION_THRESHOLD = 0.000001;

// List of column types
export const COLUMN_TYPES = {
  DATE: 'Date',
  BOOLEAN: 'Boolean',
  TIMESTAMP: 'Timestamp',
  UNDEFINED: 'Undefined',
  INTEGER: 'Integer',
  FLOAT: 'Float',
  STRING: 'String',
};

export const DISPLAY_TYPES = {
  DOLLAR_SIGN: 'DollarSign',
  PERCENT_SIGN: 'PercentSign',
  ZIP_CODE: 'ZipCode',
  NONE: '-',
};

export const VALUE_FORMATTERS = {
  NUMBER: 'number',
  NUMBER_HIGH_PRECISION: 'numberHighPrecision',
  COMMA_SEPARATED: 'commaSeparated',
  COMMA_SEPARATED_HIGH_PRECISION: 'commaSeparatedHighPrecision',
  SCIENTIFIC: 'scientific',
  SCIENTIFIC_HIGH_PRECISION: 'scientificHighPrecision',
};

export const FORMATTING_REGEX = {
  DOLLAR: /^\$([0-9]*.{0,1}[0-9]*)$/,
  PERCENT: /^[0-9]*(.){0,1}[0-9]*%$/,
  SCIENTIFIC: /^[0-9]*.{0,1}[0-9]*(e|E)(\+|-){0,1}[0-9]*$/,
  COMMA: /,\s?/g,
};

export const FALSY_BOOL_VALUES = [0, false, 'false'];
export const TRUTHY_BOOL_VALUES = [1, true, 'true'];
