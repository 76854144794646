import { AxiosError } from 'axios';
import { GATEWAY_TIMEOUT, INTERNAL_SERVER_ERROR, UNAUTHORIZED } from 'http-status-codes';
import {
  CUSTOM_PROFILER_STATUS,
  EDITOR_DESCRIPTION,
  EDITOR_TITLE,
  EMBED_CHART_UNAUTHORIZED_ERROR_DESCRIPTION,
  EMBED_CHART_UNAUTHORIZED_ERROR_TITLE,
  EMBED_CHART_UNDEFINED_DESCRIPTION,
  EMBED_CHART_UNDEFINED_TITLE,
  genericAccessErrorDescription,
  genericErrorBoundaryDescription,
  genericErrorBoundaryTitle,
  INSIGHTS_BOARD_ACCESS_CHANGE_ERROR_TITLE,
  LOAD_CARD_DESCRIPTION,
  LOAD_CARD_TITLE,
  MODEL_PROFILER_FAILED_PROFILE_TITLE,
  MODEL_PROFILER_OUTDATED_ERROR_DESCRIPTION,
  MODEL_PROFILER_OUTDATED_ERROR_TITLE,
  SHARED_AVA_CONVERSATION_ERROR_DESCRIPTION,
  SHARED_AVA_CONVERSATION_ERROR_TITLE,
  TIMEOUT_ERROR_DESCRIPTION,
  TIMEOUT_ERROR_TITLE,
  UNEXPECTED_ERROR_TITLE,
  unexpectedErrorDescription,
} from './constants';

/**
 * Gets the error title.
 * @param type The type of the error (what functionality it wraps)
 * @param error Error information (if available)
 * @returns The error title to display
 */
export const getTitle = (type: string, error?: AxiosError | Error | null): string => {
  // Check if the error is an AxiosError (will have a status code)
  const isAxiosError = error && error instanceof AxiosError;
  const status = !isAxiosError ? null : error.status ?? error.response?.status;

  switch (type) {
    case 'load_card':
      return LOAD_CARD_TITLE;
    case 'editor':
      return EDITOR_TITLE;
    case 'embed_chart':
      if (status === UNAUTHORIZED) return EMBED_CHART_UNAUTHORIZED_ERROR_TITLE;
      else if (status === INTERNAL_SERVER_ERROR) return EMBED_CHART_UNDEFINED_TITLE;
      return genericErrorBoundaryTitle('Chart');
    case 'visual':
    case 'echart':
    case 'chart':
    case 'plotly':
      if (status === GATEWAY_TIMEOUT) return TIMEOUT_ERROR_TITLE;
      return genericErrorBoundaryTitle('Chart');
    case 'app':
    case 'accessChangeError':
      return INSIGHTS_BOARD_ACCESS_CHANGE_ERROR_TITLE;
    case 'profiler':
      if (status === CUSTOM_PROFILER_STATUS) {
        return MODEL_PROFILER_OUTDATED_ERROR_TITLE;
      }
      return MODEL_PROFILER_FAILED_PROFILE_TITLE;
    case 'ava_conversation':
      return SHARED_AVA_CONVERSATION_ERROR_TITLE;
    default:
      return UNEXPECTED_ERROR_TITLE;
  }
};

/**
 * Gets the error description.
 * @param type The type of the error (what functionality it wraps)
 * @param onHomePageClick Callback to navigate to the home page
 * @param onRefreshClick Callback to refresh the page
 * @param error Error information (if available)
 * @returns The error description to display
 */
export const getDescription = (
  type: string,
  onHomePageClick: () => void,
  onRefreshClick: () => void,
  error?: AxiosError | Error | string | null,
): string | JSX.Element => {
  // Check if the error is an AxiosError (will have a status code)
  const isAxiosError = error && error instanceof AxiosError;
  const status = !isAxiosError ? null : error.status ?? error.response?.status;

  switch (type) {
    case 'load_card':
      return LOAD_CARD_DESCRIPTION;
    case 'editor':
      return EDITOR_DESCRIPTION;
    case 'embed_chart':
      if (status === UNAUTHORIZED) return EMBED_CHART_UNAUTHORIZED_ERROR_DESCRIPTION;
      else if (status === INTERNAL_SERVER_ERROR) return EMBED_CHART_UNDEFINED_DESCRIPTION;
      return genericErrorBoundaryDescription(onRefreshClick);
    case 'profiler':
      if (status === CUSTOM_PROFILER_STATUS) {
        return MODEL_PROFILER_OUTDATED_ERROR_DESCRIPTION;
      }
      if (typeof error === 'string') {
        return error;
      }
      return genericErrorBoundaryDescription(onRefreshClick);
    case 'visual':
    case 'echart':
    case 'chart':
    case 'plotly':
      if (status === GATEWAY_TIMEOUT) return TIMEOUT_ERROR_DESCRIPTION;
      return genericErrorBoundaryDescription(onRefreshClick);
    case 'app':
    case 'accessChangeError':
      return genericAccessErrorDescription(onHomePageClick);
    case 'ava_conversation':
      return SHARED_AVA_CONVERSATION_ERROR_DESCRIPTION;
    default:
      return unexpectedErrorDescription(onRefreshClick);
  }
};
