import { LOAD_APPS_FAILURE, LOAD_APPS_REQUEST, LOAD_APPS_SUCCESS } from '../actions/apps.actions';

export const initialState = {
  apps: {
    // List of apps
    byId: {},
    byName: {},
  },
  error: undefined, // Error
  isRequesting: false, // Indicates that the request is awaiting a response.
  hasRequestedApps: false, // Indicates if the client has already requested for apps from the server.
};

/**
 * @param {Object} state
 * @param {Object} action
 * @returns {Object} new state
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_APPS_REQUEST:
      return {
        ...state,
        hasRequestedApps: true,
        error: undefined,
        isRequesting: true,
      };
    case LOAD_APPS_SUCCESS:
      return {
        ...state,
        apps: {
          byId: action.apps.reduce(
            (acc, app) => ({
              ...acc,
              [app.id]: app,
            }),
            {},
          ),
        },
        isRequesting: false,
      };
    case LOAD_APPS_FAILURE:
      return {
        ...state,
        error: action.error,
        isRequesting: false,
        hasRequestedApps: false,
      };
    default:
      return state;
  }
};
