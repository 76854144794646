/* eslint-disable class-methods-use-this */
import { EnhancedStore } from '@reduxjs/toolkit';
import { openReAuthenticationDialog } from '../../../store/slices/integrations.slice';
import { ErrorDetails } from '../../../types/errorCodes/errorCodes.types';
import { INTEGRATION_PROVIDERS, IntegrationProvider } from '../../../types/integrations.types';
import { isOAuthErrorCode } from '../pipelinerErrorHandling';
import { BaseErrorHandler } from './BaseErrorHandler';

// inject store so we can access redux from this file
// ----------------------------------------------------
let store: EnhancedStore;
export const injectStoreToOAuthErrorHandlers = (_store: EnhancedStore) => {
  store = _store;
};
// ----------------------------------------------------

export class OAuthErrorHandler extends BaseErrorHandler {
  canHandle(errorDetails?: ErrorDetails): boolean {
    return isOAuthErrorCode(errorDetails?.error_code);
  }

  handle(errorDetails?: ErrorDetails): void {
    const detail = errorDetails?.detail ?? '';
    const provider = errorDetails?.provider as IntegrationProvider;

    if (INTEGRATION_PROVIDERS.includes(provider)) {
      store.dispatch(openReAuthenticationDialog({ provider, detail }));
    }
  }
}
