import React, { forwardRef } from 'react';
// import ReactECharts from 'echarts-for-react';

// See: https://github.com/hustcc/echarts-for-react#usage
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import 'echarts/lib/component/grid';

// Import charts, all with Chart suffix
import {
  BarChart,
  BoxplotChart,
  CandlestickChart,
  CustomChart,
  EffectScatterChart,
  FunnelChart,
  GaugeChart,
  GraphChart,
  HeatmapChart,
  LineChart,
  LinesChart,
  MapChart,
  ParallelChart,
  PictorialBarChart,
  PieChart,
  RadarChart,
  SankeyChart,
  ScatterChart,
  SunburstChart,
  ThemeRiverChart,
  TreeChart,
  TreemapChart,
} from 'echarts/charts';

// import components, all suffixed with Component
import {
  AriaComponent,
  AxisPointerComponent,
  BrushComponent,
  CalendarComponent,
  DataZoomComponent,
  DataZoomInsideComponent,
  DataZoomSliderComponent,
  DatasetComponent,
  GeoComponent,
  GraphicComponent,
  GridComponent,
  GridSimpleComponent,
  LegendComponent,
  LegendPlainComponent,
  LegendScrollComponent,
  MarkAreaComponent,
  MarkLineComponent,
  MarkPointComponent,
  ParallelComponent,
  PolarComponent,
  RadarComponent,
  SingleAxisComponent,
  TimelineComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  TransformComponent,
  VisualMapComponent,
  VisualMapContinuousComponent,
  VisualMapPiecewiseComponent,
} from 'echarts/components';

import { aggregate } from 'echarts-simple-transform';
import { CanvasRenderer } from 'echarts/renderers';

import PropTypes from 'prop-types';
import { registerDCTransforms } from 'translate_dc_to_echart';

import './styles/DCEChart.scss';

export const DCEChart = forwardRef(({ data, theme, metadata }, ref) => {
  echarts.use([
    // register charts
    LineChart,
    BarChart,
    PieChart,
    ScatterChart,
    BoxplotChart,
    LinesChart,
    HeatmapChart,
    CustomChart,
    RadarChart,
    MapChart,
    TreeChart,
    TreemapChart,
    GraphChart,
    GaugeChart,
    FunnelChart,
    ParallelChart,
    SankeyChart,
    CandlestickChart,
    EffectScatterChart,
    PictorialBarChart,
    ThemeRiverChart,
    SunburstChart,

    // register components
    GridSimpleComponent,
    GridComponent,
    PolarComponent,
    RadarComponent,
    GeoComponent,
    SingleAxisComponent,
    ParallelComponent,
    CalendarComponent,
    GraphicComponent,
    ToolboxComponent,
    TooltipComponent,
    AxisPointerComponent,
    BrushComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    MarkAreaComponent,
    LegendComponent,
    LegendScrollComponent,
    LegendPlainComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
    AriaComponent,
    TransformComponent,
    DatasetComponent,

    // register renderer
    CanvasRenderer,
  ]);

  echarts.registerTransform(aggregate);
  registerDCTransforms(echarts, metadata);

  echarts.registerTheme('_dcUserTheme', theme);

  return (
    <ReactEChartsCore
      echarts={echarts}
      option={data}
      ref={ref}
      className="datachat-echarts"
      theme="_dcUserTheme"
      style={{ height: 0 }} // Without setting this property, the min height is locked to 300px
    />
  );
});

DCEChart.propTypes = {
  data: PropTypes.object,
  theme: PropTypes.object,
  metadata: PropTypes.object,
};

DCEChart.defaultProps = {
  data: {},
  metadata: {},
  theme: {},
};
