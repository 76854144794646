import Button from '@mui/material/Button';
import { AxiosError } from 'axios';
import { format } from 'date-fns';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'redux-first-history';
import {
  CONTACT_FORM_DEFAULTS,
  CONTACT_FORM_ERROR_DETAILS,
  CONTACT_FORM_GENERAL_ERROR_MESSAGE_TEMPLATE,
} from '../../../constants';
import { paths } from '../../../constants/paths';
import { openContactForm } from '../../../store/actions/contact_form.actions';
import { closeDialog } from '../../../store/actions/dialog.actions';
import { selectSession } from '../../../store/selectors/session.selector';
import './ErrorBoundary.scss';
import { getDescription, getTitle } from './utils';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      caughtError: props.error ? { error: props.error } : null,
      hasError: props.hasError,
    };
    this.errorBoundaryRef = React.createRef();
  }

  componentDidMount = () => {
    if (this.state.hasError) this.scrollIntoView();
  };

  componentDidUpdate = (prevProps, prevState) => {
    // Updates component state when parents pass errors. (Children handled in getDerivedStateFromError)
    if (!isEqual(prevProps, this.props)) {
      this.setState({
        caughtError: this.props.error ? { error: this.props.error } : null,
        hasError: this.props.hasError,
      });
    }

    // Scroll the component into view if the error state changes (from parent or children).
    if (!isEqual(prevState, this.state) && this.state.hasError) {
      this.scrollIntoView();
    }
  };

  /**
   * Updates component state when children throw errors. (Parents handled in componentDidUpdate)
   * @param {Error} error
   * @param {React.ErrorInfo} errorInfo
   * @returns {Object} Updated state
   */
  static getDerivedStateFromError(error, errorInfo) {
    return {
      caughtError: { error, errorInfo },
      hasError: true,
    };
  }

  /**
   * Log errors to the console.
   * @param {Error} error
   * @param {React.ErrorInfo} errorInfo
   * @returns {void}
   */
  componentDidCatch(error, errorInfo) {
    /* eslint-disable-next-line no-console */
    console.error(`Encountered Error: ${error}. ErrorInfo: ${errorInfo}`);
  }

  /**
   * Scrolls to the error boundary upon render.
   * @returns {void}
   */
  scrollIntoView = () => this.errorBoundaryRef.current.scrollIntoView(true);

  /**
   * Refreshes the page
   */
  onRefreshClick = () => {
    window.location.reload();
  };

  /**
   * Routes to the home page
   */
  onHomePageClick = () => {
    this.props.pushHistory(paths.index);
  };

  /**
   * Opens the report modal
   */
  onReportClick = () => {
    const { msgContext, session } = this.props;
    const { objectId, type: chartType } = msgContext?.chart ?? {};

    const { error = {} } = this.state.caughtError ?? {};
    const { message, stack } = error;

    // Creating the hidden content
    const pathName = window.location.pathname;

    // Creating visible content
    const timeStamp = format(Date.now(), 'MMM d y K:mm a');

    // Format Error Details
    let technicalDetails = '';

    if (objectId) technicalDetails += `\nObjectID: ${objectId}\n`;
    if (chartType) technicalDetails += `\nChart Type: ${chartType}\n`;
    if (message) technicalDetails += `\nError Details: ${message}\n`;
    if (stack) technicalDetails += `\nError Stack: ${stack}\n`;

    const content = {
      subject: CONTACT_FORM_DEFAULTS.GENERAL_ERROR_SUBJECT,
      content: CONTACT_FORM_GENERAL_ERROR_MESSAGE_TEMPLATE(timeStamp, message),
      messageType: CONTACT_FORM_DEFAULTS.SUBJECT_BUG_REPORT,
      hiddenDetails: CONTACT_FORM_ERROR_DETAILS(error, session, pathName) + technicalDetails,
    };

    // Opening the contact form
    this.props.openContactForm(content);
    this.props.closeDialog();
  };

  render() {
    const { showButton, type } = this.props;
    const { caughtError, hasError } = this.state;
    const { error = {} } = caughtError ?? {};

    // Render children if no error
    if (!hasError) return this.props.children;

    // Render error message
    return (
      <div
        className={`ErrorBoundary${type ? ` ${type}` : ''}`}
        data-testid="error-boundary"
        ref={this.errorBoundaryRef}
      >
        <div className="error-container">
          <h4 className="error-title">{getTitle(type, error)}</h4>
          <h4 className="error-description">
            {getDescription(type, this.onHomePageClick, this.onRefreshClick, error)}
          </h4>
          {showButton && (
            <Button
              className="ReportButton"
              data-testid="report-error-button"
              variant="outlined"
              onClick={this.onReportClick}
            >
              Report Error
            </Button>
          )}
        </div>
      </div>
    );
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
  hasError: PropTypes.bool,
  error: PropTypes.oneOfType([
    PropTypes.instanceOf(AxiosError),
    PropTypes.instanceOf(Error),
    PropTypes.oneOf([null]),
  ]),
  showButton: PropTypes.bool,
  session: PropTypes.string,
  msgContext: PropTypes.shape({
    chart: PropTypes.shape({
      objectId: PropTypes.string,
      type: PropTypes.string,
    }),
  }),
  pushHistory: PropTypes.func.isRequired,
  openContactForm: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired,
};

ErrorBoundary.defaultProps = {
  children: null,
  hasError: false,
  error: null,
  msgContext: null,
  session: undefined,
  showButton: true,
  type: null,
};

const mapStateToProps = (state) => ({ session: selectSession(state) });

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      openContactForm,
      closeDialog,
      pushHistory: (path) => push(path),
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);
