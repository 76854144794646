import Fuse from 'fuse.js';
import {
  DEFAULT_FILTER_OPTION,
  FUSE_OPTIONS,
  HOME_OBJECTS,
  HOME_OBJECT_KEYS,
  REQUEST_STATUS,
} from '../../constants/home_screen';
import { HomeObjectKeys } from '../../utils/homeScreen/types';
import { unUnifyObjects, unifyAllObjects } from '../../utils/search';
import {
  CLEAR_HOME_SCREEN_OBJECTS,
  CLEAR_OBJECT_FILTERS,
  CLEAR_SEARCH,
  CLOSE_CONTEXT_MENU,
  DELETE_OBJECTS_FAILURE,
  DELETE_OBJECTS_REQUEST,
  DELETE_OBJECT_FAILURE,
  DELETE_OBJECT_REQUEST,
  DELETE_OBJECT_SUCCESS,
  DESELECT_OBJECT,
  DIALOG_INPUT_CHANGE,
  GET_CHART_RELATED_SNAPSHOT_UUIDS_SUCCESS,
  GET_HOME_SCREEN_OBJECTS_FAILURE,
  GET_HOME_SCREEN_OBJECTS_REQUEST,
  GET_HOME_SCREEN_OBJECTS_SUCCESS,
  SEARCH_FAILURE,
  SEARCH_REQUEST,
  SEARCH_SUCCESS,
  SET_CONTEXT_MENU_ANCHOR,
  SET_HIDE_HIDDEN_OBJECTS,
  SET_OBJECT_FILTERS,
  SET_SEARCH_LOADING,
  SET_SELECTED_FEATURE_CARDS,
  SET_SELECTED_OBJECT_OPTIONS,
  SET_SELECTED_ROWS,
  SET_SHOW_HIDDEN_OBJECTS,
  SET_TAB,
  SUBMIT_SEARCH,
} from '../actions/home_screen.actions';

export const initialState = {
  objects: {
    [HOME_OBJECTS.SESSION]: {}, // Stores the information of existing sessions.
    [HOME_OBJECTS.RECIPE]: {}, // Stores the information of existing workflows.
    [HOME_OBJECTS.INSIGHTS_BOARD]: {}, // Stores the information of existing insights boards.
    [HOME_OBJECTS.SNAPSHOT]: {}, // TODO: Add actions for Snapshots
    [HOME_OBJECTS.CONNECTION]: {},
    [HOME_OBJECTS.FOLDER]: {},
    [HOME_OBJECTS.QUERY]: {},
    [HOME_OBJECTS.DATAFILE]: {},
    [HOME_OBJECTS.DATASET]: {},
  },
  // Tab that is currently selected by the user.
  selectedTab: HOME_OBJECTS.ALL, // Initially set to My Work Tab
  objectRequestStatus: {
    [HOME_OBJECTS.SESSION]: REQUEST_STATUS.UNREQUESTED,
    [HOME_OBJECTS.RECIPE]: REQUEST_STATUS.UNREQUESTED,
    [HOME_OBJECTS.INSIGHTS_BOARD]: REQUEST_STATUS.UNREQUESTED,
    [HOME_OBJECTS.SNAPSHOT]: REQUEST_STATUS.UNREQUESTED,
    [HOME_OBJECTS.CONNECTION]: REQUEST_STATUS.UNREQUESTED,
    [HOME_OBJECTS.FOLDER]: REQUEST_STATUS.UNREQUESTED,
    [HOME_OBJECTS.QUERY]: REQUEST_STATUS.UNREQUESTED,
    [HOME_OBJECTS.DATAFILE]: REQUEST_STATUS.UNREQUESTED,
  },
  objectDeleteStatus: {
    [HOME_OBJECTS.SESSION]: REQUEST_STATUS.UNREQUESTED,
    [HOME_OBJECTS.RECIPE]: REQUEST_STATUS.UNREQUESTED,
    [HOME_OBJECTS.INSIGHTS_BOARD]: REQUEST_STATUS.UNREQUESTED,
    [HOME_OBJECTS.SNAPSHOT]: REQUEST_STATUS.UNREQUESTED,
    [HOME_OBJECTS.CONNECTION]: REQUEST_STATUS.UNREQUESTED,
    [HOME_OBJECTS.FOLDER]: REQUEST_STATUS.UNREQUESTED,
    [HOME_OBJECTS.QUERY]: REQUEST_STATUS.UNREQUESTED,
    [HOME_OBJECTS.DATAFILE]: REQUEST_STATUS.UNREQUESTED,
  },
  selectedRows: [], // list of HomeObjectKeysTypes of selected rows
  selectedFeatureCard: null, // selected Feature Card of type HomeObjectKeysTypes
  contextMenuAnchor: null, // Anchor for where the context menu should appear.
  deletingObjects: [], // The list of ids of the objects that are being deleted.
  isLoadingSearch: false, // Boolean to show loading screen if the search results are not loaded.
  isSearchSubmitted: false, // Boolean to check if the search submitted by the user.
  searchValue: '', // Stores the search value of the search bar after users hit the enter or submit key.
  tempSearchValue: '', // Stores the search value of the search bar while the user is typing.
  tempFilteredResults: [], // Stores the results of the drop-down search.
  filteredResults: {}, // Final search results when the user hits the enter key
  fuse: null, // Initializes the Fuse object with the current home screen objects.
  dialogValue: '', // The dialog value for the alert dialog box for the home screen
  showHiddenObjects: false,
  selectedObject: {
    objectData: null, // The object the user has selected on the homescreen.
    contextMenuAnchor: null, // Anchor for where the context menu should appear.
    actionType: '', // This is used show different object actions on the conext menu. Action types can be "specificActions" or "allActions"
  },
  searchFilters: {
    [HOME_OBJECT_KEYS.TYPE]: DEFAULT_FILTER_OPTION, // objectType filter for temp results in search bar
    [HOME_OBJECT_KEYS.OWNER_ID]: DEFAULT_FILTER_OPTION,
    [HOME_OBJECT_KEYS.LAST_ACTIVE]: DEFAULT_FILTER_OPTION,
    [HOME_OBJECT_KEYS.VISIBILITY]: DEFAULT_FILTER_OPTION,
  },
  selectedFilters: {
    [HOME_OBJECT_KEYS.OWNER_ID]: DEFAULT_FILTER_OPTION,
    [HOME_OBJECT_KEYS.LAST_ACTIVE]: DEFAULT_FILTER_OPTION,
    [HOME_OBJECT_KEYS.VISIBILITY]: DEFAULT_FILTER_OPTION,
  },
  snapshotsWithCharts: [],
};

const clearSearchState = (state) => {
  return {
    ...state,
    isLoadingSearch: false,
    isSearchSubmitted: false,
    searchValue: '',
    tempSearchValue: '',
    tempFilteredResults: [],
    filteredResults: {},
    searchFilters: initialState.searchFilters,
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_HOME_SCREEN_OBJECTS_REQUEST: {
      const { objectType } = action;
      if (objectType !== HOME_OBJECTS.ALL) {
        const requestStatus = action.refreshing ? REQUEST_STATUS.REFRESH : REQUEST_STATUS.REQUESTED;
        return {
          ...state,
          objectRequestStatus: {
            ...state.objectRequestStatus,
            [action.objectType]: requestStatus,
          },
        };
      }
      return state;
    }
    case GET_HOME_SCREEN_OBJECTS_SUCCESS: {
      const newState = {
        ...state,
        objectRequestStatus: {
          ...state.objectRequestStatus,
          [action.objectType]: REQUEST_STATUS.SUCCESS,
        },
        objects: {
          ...state.objects,
          [action.objectType]: action.data,
        },
      };
      // if there is a selected Object and it doesn't exist in the object store, deselet it.
      const selectedObjectUuid = state.selectedObject.objectData?.[HOME_OBJECT_KEYS.UUID];
      const selectedObjectType = state.selectedObject.objectData?.[HOME_OBJECT_KEYS.TYPE];
      if (!newState.objects?.[selectedObjectType]?.[selectedObjectUuid]) {
        newState.selectedObject = initialState.selectedObject;
      }
      // Please refer to https://fusejs.io/ for more info
      newState.fuse = new Fuse(unifyAllObjects(newState.objects), FUSE_OPTIONS);
      return newState;
    }
    case GET_HOME_SCREEN_OBJECTS_FAILURE:
      return {
        ...state,
        objectRequestStatus: {
          ...state.objectRequestStatus,
          [action.objectType]: REQUEST_STATUS.FAILURE,
        },
      };
    case CLEAR_HOME_SCREEN_OBJECTS:
      return {
        ...state,
        objectRequestStatus: initialState.objectRequestStatus,
        objects: initialState.objects,
      };
    case SET_TAB:
      return {
        ...state,
        selectedTab: action.tab,
      };
    case DIALOG_INPUT_CHANGE:
      return {
        ...state,
        dialogValue: action.value,
      };
    case SEARCH_REQUEST: {
      return {
        ...state,
        isLoadingSearch: true,
        tempSearchValue: action.value,
      };
    }
    case SEARCH_SUCCESS:
      return {
        ...state,
        tempFilteredResults: action.data,
        isLoadingSearch: false,
      };
    case SEARCH_FAILURE:
      return {
        ...state,
        isLoadingSearch: false,
      };
    case SUBMIT_SEARCH: {
      const filteredResults = unUnifyObjects(state.tempFilteredResults);
      return {
        ...state,
        searchValue: action?.refreshResults ? state.searchValue : state.tempSearchValue.trim(),
        isSearchSubmitted: true,
        filteredResults,
      };
    }
    case SET_SEARCH_LOADING:
      return {
        ...state,
        isLoadingSearch: action.isLoading,
      };
    case CLEAR_SEARCH:
      return clearSearchState(state);
    case DELETE_OBJECT_REQUEST: {
      let deletingObjects = [...state.deletingObjects];
      if (
        !state.deletingObjects.some(
          (object) => object[HomeObjectKeys.UUID] === action.object[HomeObjectKeys.UUID],
        )
      ) {
        deletingObjects = [...state.deletingObjects, action.object[HOME_OBJECT_KEYS.UUID]];
      }

      return {
        ...state,
        objectDeleteStatus: {
          ...state.objectDeleteStatus,
          [action.object.objectType]: REQUEST_STATUS.REQUESTED,
        },
        deletingObjects,
      };
    }
    case DELETE_OBJECT_SUCCESS: {
      // remove the object id from the deletingObjects array
      const deletingObjects = [...state.deletingObjects].filter(
        (object) => object !== action.object[HOME_OBJECT_KEYS.UUID],
      );
      const newState = {
        ...state,
        objectDeleteStatus: {
          ...state.objectDeleteStatus,
          [action.object.objectType]: REQUEST_STATUS.SUCCESS,
        },
        deletingObjects,
      };
      // If we deleted the object the user selected, deselect it
      if (
        action.object[HOME_OBJECT_KEYS.UUID] ===
        state.selectedObject?.objectData?.[HOME_OBJECT_KEYS.UUID]
      ) {
        newState.selectedObject = initialState.selectedObject;
      }
      return newState;
    }
    case DELETE_OBJECT_FAILURE: {
      // remove the object id from the deletingObjects array
      const deletingObjects = [...state.deletingObjects].filter(
        (object) => object !== action.object[HOME_OBJECT_KEYS.UUID],
      );
      return {
        ...state,
        objectDeleteStatus: {
          ...state.objectDeleteStatus,
          [action.object.objectType]: REQUEST_STATUS.FAILURE,
        },
        deletingObjects,
      };
    }
    case DELETE_OBJECTS_REQUEST: {
      let updatedDeletingObjects = [
        ...state.deletingObjects,
        ...action.objects.map((o) => o[HomeObjectKeys.UUID]),
      ];
      // remove duplicates
      updatedDeletingObjects = [...new Set(updatedDeletingObjects)];
      return {
        ...state,
        deletingObjects: updatedDeletingObjects,
      };
    }
    case DELETE_OBJECTS_FAILURE: {
      // Remove the cancelled and failed objects from the deleting objects array
      const failedObjectsUUIDList = Object.values(action.failedObjects).map(
        (failedObj) => failedObj.uuid,
      );
      const updatedDeletingObjects = state.deletingObjects.filter(
        (object) =>
          !action.cancelledObjects.some((cancelObj) => cancelObj[HomeObjectKeys.UUID] === object) &&
          !failedObjectsUUIDList.some((failedUUID) => failedUUID === object),
      );
      return { ...state, deletingObjects: updatedDeletingObjects };
    }
    case DESELECT_OBJECT:
      return {
        ...state,
        selectedObject: initialState.selectedObject,
      };
    case SET_SHOW_HIDDEN_OBJECTS:
      return {
        ...state,
        showHiddenObjects: true,
      };
    case SET_HIDE_HIDDEN_OBJECTS:
      return {
        ...state,
        showHiddenObjects: false,
      };
    case SET_SELECTED_OBJECT_OPTIONS:
      return {
        ...state,
        selectedObject: {
          ...state.selectedObject,
          objectData: action?.objectData || state.selectedObject.objectData,
          contextMenuAnchor: action?.contextMenuAnchor || state.selectedObject.contextMenuAnchor,
          actionType: action?.actionType || state.selectedObject.actionType,
        },
      };
    case SET_SELECTED_ROWS:
      return {
        ...state,
        selectedRows: action.selectedRows,
      };
    case SET_SELECTED_FEATURE_CARDS:
      return {
        ...state,
        selectedFeatureCard: action.selectedFeatureCard,
      };
    case SET_CONTEXT_MENU_ANCHOR:
      return {
        ...state,
        contextMenuAnchor: action.contextMenuAnchor,
      };
    case GET_CHART_RELATED_SNAPSHOT_UUIDS_SUCCESS:
      return {
        ...state,
        snapshotsWithCharts: action.uuidList,
      };

    case CLOSE_CONTEXT_MENU:
      return {
        ...state,
        contextMenuAnchor: initialState.contextMenuAnchor,
      };

    case SET_OBJECT_FILTERS: {
      // User will be able to set search filters on search bar in which selectedTab would not be search tab
      // when it is initial search. Due to this we pass onSearchBar true to detect this case.
      const filtersName =
        state.selectedTab === HOME_OBJECTS.SEARCH || action.onSearchBar
          ? 'searchFilters'
          : 'selectedFilters';
      return {
        ...state,
        [filtersName]: {
          ...state[filtersName],
          ...action.filtersObject,
        },
      };
    }
    case CLEAR_OBJECT_FILTERS: {
      if (state.selectedTab === HOME_OBJECTS.SEARCH) {
        return {
          ...state,
          searchFilters: initialState.searchFilters,
        };
      }
      return {
        ...state,
        selectedFilters: initialState.selectedFilters,
      };
    }
    default:
      return state;
  }
};
