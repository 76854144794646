import { put, takeEvery } from 'redux-saga/effects';
import { addChartStatus, clearChartStatuses } from '../actions/chart_selection.actions';
import { CLEAR_SCREEN, RECEIVE_MESSAGES_SUCCESS } from '../actions/messages.actions';
import { resetSession } from '../slices/session.slice';

/**
 * Watches for RECEIVE_MESSAGES_SUCCESS actions in order to add the chart object
 * metadata to the chartStatuses array.
 */
function* chartSelectionWorker(action) {
  if (action.type === RECEIVE_MESSAGES_SUCCESS) {
    if (action.messages !== undefined) {
      for (let index = 0; index < action.messages.length; index++) {
        const msg = action.messages[index].message;
        if (msg.type !== 'text' && msg.type !== 'collapsible' && msg.type !== 'file') {
          // Messages types that are not 'text', 'collapsible', or 'file' are contain chart objects
          // This follows the same logic for determining chart messages as in messages.saga.js
          // If changes to this logic should be done in both places to keep in sync
          const newElement = {
            objectId: msg.objectId,
            chartType: msg.type,
            selectable: msg.objectId !== undefined && !msg.data?.isDependencyGraph,
            dataset: msg?.data?.name,
            version: msg?.data?.version,
          };
          yield put(addChartStatus(newElement));
        }
      }
    }
  } else if (action.type === CLEAR_SCREEN || action.type === resetSession.type) {
    yield put(clearChartStatuses());
  }
}

// Spins up the saga watchers
export default function* () {
  yield takeEvery(
    [RECEIVE_MESSAGES_SUCCESS, CLEAR_SCREEN, resetSession.type],
    chartSelectionWorker,
  );
}
