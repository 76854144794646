import {
  GET_USERS_IN_ORGANIZATION_FAILURE,
  GET_USERS_IN_ORGANIZATION_REQUEST,
  GET_USERS_IN_ORGANIZATION_SUCCESS,
} from '../actions/organization.action';

const initialState = {
  isLoadingOrganizationUsers: false,
  usersInOrganization: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS_IN_ORGANIZATION_REQUEST:
      return {
        ...state,
        isLoadingOrganizationUsers: true,
      };

    case GET_USERS_IN_ORGANIZATION_SUCCESS:
      return {
        ...state,
        isLoadingOrganizationUsers: false,
        usersInOrganization: {
          ...state.usersInOrganization,
          [action.id]: action.data,
        },
      };

    case GET_USERS_IN_ORGANIZATION_FAILURE:
      return {
        ...state,
        isLoadingOrganizationUsers: false,
      };

    default:
      return state;
  }
};
