export type TBreakPoints = {
  xs: number;
  sm: number;
  md: number;
  lg: number;
  tablet: number;
  xl: number;
  xxl: number;
};

const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  tablet: 1024,
  xl: 1200,
  xxl: 1600,
};

export default breakpoints;
