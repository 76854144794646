import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import cloneDeep from 'lodash/cloneDeep';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import React from 'react';
import { connect } from 'react-redux';
import {
  ChartSpec,
  DOMUtils,
  QUANTITATIVE_COLOR_BAR_THRESHOLD,
  SINGLE_METRIC_COLOR,
  getTextFont,
  isColumnNumeric,
} from 'translate_dc_to_echart';
import ColorEditor from '../../../chart-library/src/PlotlyComponents/ColorEditor';
import { closeDialog, openAlertDialog } from '../../../store/actions/dialog.actions';
import theme from '../../../utils/material_table';
import '../ChartBuilder.scss';
import {
  ANNOTATION_TEXTBOX_OFFSET,
  PIXEL_TO_POINT_FACTOR,
  fontSizeInputErrorMessage,
} from '../utils/constants';
import { getTextFieldPosition } from '../utils/manageCBAnnotations';
import { getCenterPointPixelValues, getCurrentSpec } from '../utils/manageSpec';
import LinesField from './LinesField/LinesField';

const AUTOSCALABLE_CHART_TYPES = [
  'scatter',
  'bar',
  'stacked_bar',
  'bubble_chart',
  'line',
  'violin',
  'boxplot',
  'horizontal_bar',
  'heatmap',
  'stacked_area',
  'ridgeline',
  'histogram',
  // 'donut',
  // 'single_metric',
];

const AXIS_LABELED_CHART_TYPES = [
  'scatter',
  'bar',
  'stacked_bar',
  'bubble_chart',
  'line',
  'violin',
  'boxplot',
  'horizontal_bar',
  'heatmap',
  'stacked_area',
  'ridgeline',
  'histogram',
  // 'donut',
  // 'single_metric',
];

const TITLED_CHART_TYPES = [
  'scatter',
  'bar',
  'stacked_bar',
  'bubble_chart',
  'line',
  'violin',
  'boxplot',
  'horizontal_bar',
  'heatmap',
  'stacked_area',
  'ridgeline',
  'donut',
  'histogram',
  // 'single_metric',
];

const COLORABLE_CHART_TYPES = [
  'scatter',
  'bar',
  'stacked_bar',
  'bubble_chart',
  'line',
  // 'violin',
  'boxplot',
  'horizontal_bar',
  // 'heatmap',
  'stacked_area',
  // 'ridgeline',
  // 'donut',
  'single_metric',
  'histogram',
];

const UNSUPPORTED_LINE_TYPES = ['heatmap', 'ridgeline', 'donut', 'single_metric'];

type Props = {
  showCustomize: Boolean,
  width: Number, // Chart's width determined by echarts getWidth()
  height: Number, // Chart's height determined by echarts getHeight()
  presentation: {
    title: {
      text: String, // Chart title text
      textStyle: {
        fontSize: Number, // Chart title font size
      },
    }, // Chart title
    xaxis: Object, // X-axis title and styles
    yaxis: Object, // Y-axis title and styles
    annotations: Array, // Annotations added to the chart
    colorOverride: Array, // Colors to override default values with
    overlayAxis: Object, // Overlay axis title and styles
    shouldAutoscale: Boolean, // Flag that indicates if the chart should be autoscaled
  },
  series: [], // Info about the chart type, columns to use for axes, group, slider or subplot
  menuElement: Element, // Reference to the chart builder menu
  headerElement: Element, // Reference to the chart builder header(i.e. dataset title)
  echartsRef: { current: Object }, // Reference to the current echarts instance
  caption: String, // Chart caption
  options: { chartType: { type: String } }, // Data required for plotting in the chart builder
  customUpdate: Boolean, // Flag that indicates if the translation layer needs to be retriggered to apply chart updates
  showTextField: Boolean, // Flag that decides if textbox should be rendered for annotation/text
  textFieldIndex: Number, // Annotation/text index when rendering textbox for annotation/text
  updateCaption: () => mixed, // Updates the chart caption
  closeDialog: () => mixed, // Closes the opened dialog if any
  openAlertDialog: () => mixed, // Opens a new alert dialog with the given title, description and buttons
  updateFromCustomize: () => mixed, // Updates the presentation state in the chart builder with changes from <Customize />
  updateAnnotationTextField: () => mixed, // Updates the visibility and interactibility of each annotation/text for a chart in the chart builder
  isLoadingDataset: Boolean, // Is the dataset currently loading?
  updateChart: () => mixed, // Updates the markline state in the chart builder with changes from <Customize />
  chartSpec: ChartSpec,
  fields: {},
};

class Customize extends React.Component<Props> {
  constructor(props) {
    super(props);
    this.ref = React.createRef(null);
    this.debounce = { timeout: 0, options: {} };
    const currentSpec = getCurrentSpec(props.echartsRef);
    const columnData = this.getColumnData(currentSpec);
    const isNumericColoring = isColumnNumeric(columnData, (x) => parseInt(x, 10));

    this.state = {
      currentSpec,
      presentation: cloneDeep(props.presentation),
      caption: props.caption,
      annotations: cloneDeep(props.presentation.annotations) || [],
      annotationsFontSize:
        props.presentation?.annotations?.length > 0 && props.presentation?.annotations[0]?.fontSize
          ? props.presentation?.annotations[0]?.fontSize
          : 16,
      validAnnotationsFontSize:
        props.presentation?.annotations?.length > 0 && props.presentation?.annotations[0]?.fontSize
          ? props.presentation?.annotations[0]?.fontSize
          : 16,
      // determine the current showTexts/showAnnotations state based on current spec
      showTexts:
        props.presentation?.annotations?.length === 0 ||
        (props.presentation?.annotations?.length > 0 &&
          props.presentation?.annotations
            .filter((ele) => ele.type === 'text')
            .every((text) => !text.invisible)),
      showAnnotations:
        props.presentation?.annotations?.length === 0 ||
        (props.presentation?.annotations?.length > 0 &&
          props.presentation?.annotations
            .filter((ele) => ele.type === 'annotation')
            .every((annotation) => !annotation.invisible)),
      allFontSize: 0,
      validAllFontSize: 0,
      colorData: [],
      title: props.presentation.title || currentSpec.title[0],
      // latest valid title font size
      validTitleFontSize:
        props.presentation.title.textStyle.fontSize || currentSpec.title[0].textStyle.fontSize,
      xaxis: props.presentation.xaxis || currentSpec.xAxis[0],
      validXaxisLabelFontSize:
        props.presentation.xaxis.axisLabel.fontSize || currentSpec.xAxis[0]?.axisLabel.fontSize,
      validXaxisTitleFontSize:
        props.presentation.xaxis.nameTextStyle.fontSize ||
        currentSpec.xAxis[0]?.nameTextStyle.fontSize,

      yaxis: props.presentation.yaxis || currentSpec.yAxis[0],
      overlayAxis:
        props.presentation.overlayAxis ||
        (currentSpec.yAxis.filter((item) => item._overlayAxis)[0]
          ? { text: currentSpec.yAxis.filter((item) => item._overlayAxis)[0].name }
          : null) ||
        (currentSpec.xAxis.filter((item) => item._overlayAxis)[0]
          ? { text: currentSpec.xAxis.filter((item) => item._overlayAxis)[0].name }
          : null),
      validYaxisLabelFontSize:
        props.presentation.yaxis.axisLabel.fontSize || currentSpec.yAxis[0]?.axisLabel.fontSize,
      validYaxisTitleFontSize:
        props.presentation.yaxis.nameTextStyle.fontSize ||
        currentSpec.yAxis[0]?.nameTextStyle.fontSize,
      columnData,
      isNumericColoring,
      classes: {
        dropdownPopup: {
          fontSize: '14px',
          fontFamily: 'Arial, Helvetica, sans-serif',
        },
      },
      shouldAutoscale: props.presentation.shouldAutoscale || false,
    };
  }

  componentDidMount = () => {
    // Get width of CB-menu for computing renderTextField position
    // also get header's height since table title may have multiple
    // rows when windows size is small
    this.updateState({
      menuWidth: this.props.menuElement.clientWidth,
      headerHeight: this.props.headerElement.clientHeight,
    });

    // Load in the presentation prop
    this.loadPresentation();
  };

  componentDidUpdate = (prevProps) => {
    // Set the both new menu width and header height
    // when the width changes (for textField placement)
    if (prevProps.width !== this.props.width) {
      this.updateState({
        menuWidth: this.props.menuElement.clientWidth,
        headerHeight: this.props.headerElement.clientHeight,
      });
    }

    // Reload annotations & reapply to echart after changing other presentation keys
    // echartsRef is null while updating. Poll for the update for a maximum 2s
    if (
      (prevProps.customUpdate && !this.props.customUpdate) ||
      this.props.width !== prevProps.width ||
      this.props.height !== prevProps.height
    ) {
      let loops = 0;
      const iid = setInterval(() => {
        if (
          this.props.echartsRef &&
          this.props.echartsRef.current &&
          this.props.echartsRef.current.props.option &&
          Object.keys(this.props.echartsRef.current.props.option).length > 0
        ) {
          const currentSpec = getCurrentSpec(this.props.echartsRef);
          this.setState({ currentSpec }, () => this.loadPresentation());
          clearInterval(iid);
        } else if (loops > 20) {
          // Failure condition. Use old spec (Reverts to old font size, color, or annotation)
          clearInterval(iid);
        }
        loops++;
      }, 100);
    }

    if (
      !isEqual(prevProps.presentation, this.props.presentation) ||
      prevProps.showTextField !== this.props.showTextField
    ) {
      this.updatePresentation();
    }
  };

  /**
   * Handles the onBlur event triggered when users lose focus from
   * the chart title font size input field. If the input value is invalid, reset it
   * to the latest valid value, otherwise keep it
   * @returns {void}
   */
  onBlurTitleFontSize = () => {
    const { title, validTitleFontSize } = this.state;
    const isValidFontSize = this.checkBoundary(title.textStyle.fontSize);
    if (isValidFontSize) {
      return;
    }
    title.textStyle.fontSize = validTitleFontSize;
    this.setState({ title });
  };

  /**
   * Handles the onBlur event triggered when users lose focus from
   * the annotation font size input field. If the input value is invalid, reset it
   * to the latest valid value, otherwise keep it
   * @returns {void}
   */
  onBlurAnnotationsFontSize = () => {
    const { annotationsFontSize, validAnnotationsFontSize } = this.state;
    const isValidFontSize = this.checkBoundary(annotationsFontSize);
    if (isValidFontSize) {
      return;
    }
    this.setState({ annotationsFontSize: validAnnotationsFontSize });
  };

  /**
   * Handles the onBlur event triggered when users lose focus from
   * the y-axis label font size input field. If the input value is invalid, reset it
   * to the latest valid value, otherwise keep it
   * @returns {void}
   */
  onBlurYAxesLabelFontSize = () => {
    const { yaxis, validYaxisLabelFontSize } = this.state;
    const isValidFontSize = this.checkBoundary(yaxis.axisLabel.fontSize);
    if (isValidFontSize) {
      return;
    }
    yaxis.axisLabel.fontSize = validYaxisLabelFontSize;
    this.setState({ yaxis });
  };

  /**
   * Handles the onBlur event triggered when users lose focus from
   * the y-axis title title font size input field. If the input value is invalid, reset it
   * to the latest valid value, otherwise keep it
   * @returns {void}
   */
  onBlurYAxesTitleFontSize = () => {
    const { yaxis, validYaxisTitleFontSize } = this.state;
    const isValidFontSize = this.checkBoundary(yaxis.nameTextStyle.fontSize);
    if (isValidFontSize) {
      return;
    }
    yaxis.nameTextStyle.fontSize = validYaxisTitleFontSize;
    this.setState({ yaxis });
  };

  /**
   * Handles the onBlur event triggered when users lose focus from
   * the x-axis label font size input field. If the input value is invalid, reset it
   * to the latest valid value, otherwise keep it
   * @returns {void}
   */
  onBlurXAxisLabelFontSize = () => {
    const { xaxis, validXaxisLabelFontSize } = this.state;
    const isValidFontSize = this.checkBoundary(xaxis.axisLabel.fontSize);
    if (isValidFontSize) {
      return;
    }
    xaxis.axisLabel.fontSize = validXaxisLabelFontSize;
    this.setState({ xaxis });
  };

  /**
   * Handles the onBlur event triggered when users lose focus from
   * the x-axis title font size input field. If the input value is invalid, reset it
   * to the latest valid value, otherwise keep it
   * @returns {void}
   */
  onBlurXAxisTitleFontSize = () => {
    const { xaxis, validXaxisTitleFontSize } = this.state;
    const isValidFontSize = this.checkBoundary(xaxis.nameTextStyle.fontSize);
    if (isValidFontSize) {
      return;
    }
    xaxis.nameTextStyle.fontSize = validXaxisTitleFontSize;
    this.setState({ xaxis });
  };

  /**
   * Handles the onBlur event triggered when users lose focus from
   * the all font size input field. If the input value is invalid, reset it
   * to the latest valid value, otherwise keep it
   * @returns {void}
   */
  onBlurAllFontSize = () => {
    const { allFontSize, validAllFontSize } = this.state;
    const isValidFontSize = this.checkAllFontSizeBoundary(allFontSize);
    if (isValidFontSize) {
      return;
    }
    this.setState({ allFontSize: validAllFontSize });
  };

  /**
   * Toggles the shouldAutoscale flag on change
   */
  toggleAutoscale = () => {
    this.setState(
      (prev) => ({ shouldAutoscale: !prev.shouldAutoscale }),
      () => {
        this.debounceTimer('shouldAutoscale');
      },
    );
  };

  /**
   * Gets the caption added to the chart
   * @returns {string} caption
   */
  getCaption = () => this.state.caption;

  /**
   * Updates caption value in this state, and debounces updating caption value for the chart which
   * triggers re-translation
   * @param {string} caption new value of caption
   */
  setCaption = (caption) =>
    this.setState({ caption }, () => this.debounceTimer('caption', 750, caption));

  /**
   * Gets the current chart title
   * @returns {string} chart title
   */
  getTitle = () => this.state.title.text;

  /**
   * Gets the font size of the current chart title
   * @returns {number} chart title font size
   */
  getTitleFontSize = () => this.state.title.textStyle.fontSize;

  /**
   * Sets the chart title on change
   * @param {string} newTitle new chart title
   * @returns {void}
   */
  setTitle = (newTitle) => {
    this.setState(
      (prevState) => ({
        title: { ...prevState.title, text: newTitle },
      }),
      () => {
        this.debounceTimer('title', 750);
      },
    );
  };

  /**
   * Sets the font size of the chart title on change
   * @param {number} newFontSize new font size
   * @returns {void}
   */
  setTitleFontSize = (newFontSize) => {
    const { title } = this.state;
    const isValidFontSize = this.checkBoundary(newFontSize);
    title.textStyle.fontSize = newFontSize;
    if (isValidFontSize) {
      this.setState({ validTitleFontSize: newFontSize }, () => {
        this.debounceTimer('title');
      });
    } else {
      this.setState({ title });
    }
  };

  /**
   * Gets the current x-axis title.
   * Use .text if getting from the presentation, .name if getting from echarts option
   * @returns {string} x-axis title
   */
  getXAxisTitle = () => this.state.xaxis.text || this.state.xaxis.name;

  /**
   * Gets the current x-axis title.
   * Use .text if getting from the presentation, .name if getting from echarts option
   * @returns {string} x-axis title
   */
  getXAxisTicksPrefix = () => this.state.xaxis?.axisLabel?.prefix || '';

  /**
   * Gets the current x-axis title.
   * Use .text if getting from the presentation, .name if getting from echarts option
   * @returns {string} x-axis title
   */
  getXAxisTicksSuffix = () => this.state.xaxis?.axisLabel?.suffix || '';

  /**
   * Gets the font size of the x-axis title
   * @returns {number} x-axis title font size
   */
  getXAxisTitleFontSize = () => this.state.xaxis.nameTextStyle.fontSize;

  /**
   * Gets the font size of the x-axis labels
   * @returns {number} x-axis label font size
   */
  getXAxisLabelFontSize = () => this.state.xaxis.axisLabel.fontSize;

  /**
   * Sets the value of the x-axis title on change
   * @param {string} newXAxisTitle new x-axis title
   * @returns {void}
   */
  setXAxisTitle = (newXAxisTitle) => {
    this.setState(
      (prevState) => ({ xaxis: { ...prevState.xaxis, text: newXAxisTitle } }),
      () => {
        this.debounceTimer('xaxis', 750);
      },
    );
  };

  /**
   * Sets the value of the x-axis Tick Prefix on change
   * @param {string} newXAxisTickPrefix new x-axis Tick Prefix
   * @returns {void}
   */
  setXAxisTickPrefix = (newXAxisTickPrefix) => {
    this.setState(
      (prevState) => ({
        xaxis: {
          ...prevState.xaxis,
          axisLabel: { ...prevState.xaxis.axisLabel, prefix: newXAxisTickPrefix },
        },
      }),
      () => {
        this.debounceTimer('xaxis', 50);
      },
    );
  };

  /**
   * Sets the value of the x-axis Tick Suffix on change
   * @param {string} newXAxisTickSuffix new x-axis Tick Suffix
   * @returns {void}
   */
  setXAxisTickSuffix = (newXAxisTickSuffix) => {
    this.setState(
      (prevState) => ({
        xaxis: {
          ...prevState.xaxis,
          axisLabel: { ...prevState.xaxis.axisLabel, suffix: newXAxisTickSuffix },
        },
      }),
      () => {
        this.debounceTimer('xaxis', 50);
      },
    );
  };

  /**
   * Sets the value of the x-axis title font size on change
   * @param {string} newFontSize x-axis title font size
   * @returns {void}
   */
  setXAxisTitleFontSize = (newFontSize) => {
    const { xaxis } = this.state;
    const isValidFontSize = this.checkBoundary(newFontSize);
    xaxis.nameTextStyle.fontSize = newFontSize;
    if (isValidFontSize) {
      this.setState({ validXaxisTitleFontSize: newFontSize }, () => {
        this.debounceTimer('xaxis');
      });
    } else {
      this.setState({ xaxis });
    }
  };

  /**
   * Sets the value of the x-axis label font size on change
   * @param {string} newFontSize x-axis label font size
   * @returns {void}
   */
  setXAxisLabelFontSize = (newFontSize) => {
    const { xaxis } = this.state;
    const isValidFontSize = this.checkBoundary(newFontSize);
    xaxis.axisLabel.fontSize = newFontSize;
    if (isValidFontSize) {
      this.setState({ validXaxisLabelFontSize: newFontSize }, () => {
        this.debounceTimer('xaxis');
      });
    } else {
      this.setState({ xaxis });
    }
  };

  /**
   * Gets the current y-axis title.
   * Use .text if getting from the presentation, .name if getting from echarts option
   * @returns {string} y-axis title
   */
  getYAxisTitle = () => this.state.yaxis.text || this.state.yaxis.name;

  /**
   * Gets the current x-axis title.
   * Use .text if getting from the presentation, .name if getting from echarts option
   * @returns {string} x-axis title
   */
  getYAxisTicksPrefix = () => this.state.yaxis?.axisLabel?.prefix || '';

  /**
   * Gets the current x-axis title.
   * Use .text if getting from the presentation, .name if getting from echarts option
   * @returns {string} x-axis title
   */
  getYAxisTicksSuffix = () => this.state.yaxis?.axisLabel?.suffix || '';

  /**
   * Gets the font size of the y-axis title
   * @returns {number} y-axis title font size
   */
  getYAxesTitleFontSize = () => this.state.yaxis.nameTextStyle.fontSize;

  /**
   * Gets the font size of the y-axis labels
   * @returns {number} y-axis label font size
   */
  getYAxesLabelFontSize = () => this.state.yaxis.axisLabel.fontSize;

  /**
   * Sets the value of the y-axis title on change
   * @param {string} newYAxisTitle new y-axis title
   * @returns {void}
   */
  setYAxisTitle = (newYAxisTitle) => {
    this.setState(
      (prevState) => ({ yaxis: { ...prevState.yaxis, text: newYAxisTitle } }),
      () => {
        this.debounceTimer('yaxis', 750);
      },
    );
  };

  /**
   * Sets the value of the y-axis Tick Prefix on change
   * @param {string} newYAxisTickPrefix new x-axis Tick Prefix
   * @returns {void}
   */
  setYAxisTickPrefix = (newYAxisTickPrefix) => {
    this.setState(
      (prevState) => ({
        yaxis: {
          ...prevState.yaxis,
          axisLabel: { ...prevState.yaxis.axisLabel, prefix: newYAxisTickPrefix },
        },
      }),
      () => {
        this.debounceTimer('yaxis', 50);
      },
    );
  };

  /**
   * Sets the value of the y-axis Tick Suffix on change
   * @param {string} newYAxisTickSuffix new x-axis Tick Suffix
   * @returns {void}
   */
  setYAxisTickSuffix = (newYAxisTickSuffix) => {
    this.setState(
      (prevState) => ({
        yaxis: {
          ...prevState.yaxis,
          axisLabel: { ...prevState.yaxis.axisLabel, suffix: newYAxisTickSuffix },
        },
      }),
      () => {
        this.debounceTimer('yaxis', 50);
      },
    );
  };

  /**
   * Sets the value of the y-axis title font size on change
   * @param {string} newFontSize y-axis title font size
   * @returns {void}
   */
  setYAxesTitleFontSize = (newFontSize) => {
    const { yaxis } = this.state;
    const isValidFontSize = this.checkBoundary(newFontSize);
    yaxis.nameTextStyle.fontSize = newFontSize;
    if (isValidFontSize) {
      this.setState({ validYaxisTitleFontSize: newFontSize }, () => {
        this.debounceTimer('yaxis');
      });
    } else {
      this.setState({ yaxis });
    }
  };

  /**
   * Sets the value of the y-axis label font size on change
   * @param {string} newFontSize y-axis label font size
   * @returns {void}
   */
  setYAxesLabelFontSize = (newFontSize) => {
    const { yaxis } = this.state;
    const isValidFontSize = this.checkBoundary(newFontSize);
    yaxis.axisLabel.fontSize = newFontSize;
    if (isValidFontSize) {
      this.setState({ validYaxisLabelFontSize: newFontSize }, () => {
        this.debounceTimer('yaxis');
      });
    } else {
      this.setState({ yaxis });
    }
  };

  /**
   * Gets the current overlay axis title.
   * Use .text if getting from the presentation, .name if getting from echarts option
   * @returns {string} overlay axis title
   */
  getOverlayAxisTitle = () => this.state.overlayAxis?.text || this.state.overlayAxis?.name;

  /**
   * Sets the value of the overlay axis title on change
   * @param {string} newOverlayAxisTitle new overlay axis title
   * @returns {void}
   */
  setOverlayAxisTitle = (newOverlayAxisTitle) => {
    this.setState(
      (prevState) => ({
        overlayAxis: { ...prevState.overlayAxis, text: newOverlayAxisTitle },
      }),
      () => {
        this.debounceTimer('overlayAxis', 750);
      },
    );
  };

  /**
   * Gets the font size of the annotations added to the chart
   * @returns {number} annotation font size
   */
  getAnnotationsFontSize = () => this.state.annotationsFontSize;

  /**
   * Sets the value of the annotation font size on change
   * @param {string} newFontSize annotation font size
   * @returns {void}
   */
  setAnnotationsFontSize = (newFontSize) => {
    const { presentation, annotations } = this.state;
    const isValidFontSize = this.checkBoundary(newFontSize);
    if (isValidFontSize) {
      for (const item of presentation.annotations) {
        item.fontSize = newFontSize;
      }
      for (const annotation of annotations) {
        annotation.fontSize = newFontSize;
      }
      this.setState(
        {
          presentation,
          annotations,
          annotationsFontSize: newFontSize,
          validAnnotationsFontSize: newFontSize,
        },
        () => {
          this.debounceTimer('annotations');
        },
      );
    } else {
      this.setState({ annotationsFontSize: newFontSize });
    }
  };

  /**
   * Calculates the lower bound for all customizable font sizes in the chart builder
   * @returns {number} lower bound
   */
  getLowerBoundOfAllFontSize = () => {
    return (
      8 -
      Math.min(
        parseInt(this.getXAxisTitleFontSize(), 10),
        parseInt(this.getXAxisLabelFontSize(), 10),
        parseInt(this.getYAxesTitleFontSize(), 10),
        parseInt(this.getYAxesLabelFontSize(), 10),
        parseInt(this.getTitleFontSize(), 10),
      ) +
      parseInt(this.state.validAllFontSize, 10)
    );
  };

  /**
   * Calculates the upper bound for all customizable font sizes in the chart builder
   * @returns {number} upper bound
   */
  getHigherBoundOfAllFontSize = () => {
    return (
      32 -
      Math.max(
        parseInt(this.getXAxisTitleFontSize(), 10),
        parseInt(this.getXAxisLabelFontSize(), 10),
        parseInt(this.getYAxesTitleFontSize(), 10),
        parseInt(this.getYAxesLabelFontSize(), 10),
        parseInt(this.getTitleFontSize(), 10),
      ) +
      parseInt(this.state.validAllFontSize, 10)
    );
  };

  /**
   * Gets the value of the all font size input field
   * @returns {number} font size
   */
  getAllFontSize = () => this.state.allFontSize;

  /**
   * Sets the value of all font sizes on change
   * @param {number} newFontSize new font size
   * @returns {void}
   */
  setAllFontSize = (newFontSize) => {
    const increment = newFontSize - this.state.validAllFontSize;
    this.setState({ allFontSize: newFontSize });

    if (
      newFontSize !== '' &&
      this.checkBoundary(parseInt(this.getXAxisTitleFontSize(), 10) + increment) &&
      this.checkBoundary(parseInt(this.getXAxisLabelFontSize(), 10) + increment) &&
      this.checkBoundary(parseInt(this.getYAxesTitleFontSize(), 10) + increment) &&
      this.checkBoundary(parseInt(this.getYAxesLabelFontSize(), 10) + increment) &&
      this.checkBoundary(parseInt(this.getTitleFontSize(), 10) + increment)
    ) {
      this.setXAxisTitleFontSize(parseInt(this.getXAxisTitleFontSize(), 10) + increment);
      this.setXAxisLabelFontSize(parseInt(this.getXAxisLabelFontSize(), 10) + increment);
      this.setYAxesTitleFontSize(parseInt(this.getYAxesTitleFontSize(), 10) + increment);
      this.setYAxesLabelFontSize(parseInt(this.getYAxesLabelFontSize(), 10) + increment);
      this.setTitleFontSize(parseInt(this.getTitleFontSize(), 10) + increment);
      this.setState({ validAllFontSize: newFontSize });
    }
  };

  /**
   * Retrieves the chart's color data from props (saved color data) or ECharts library (currentSpec)
   * @returns {Array} The current chart's color data
   */
  getColorData = () => {
    const { presentation, options } = this.props;
    const { currentSpec, columnData } = this.state;
    const chartType = options.chartType.type;
    let colorData = [];

    // Get our color data. Always prefer using the prop colors,
    // but fall-back to other methods for getting the colors
    if (presentation.colorOverride?.length > 0) {
      // Get colorData from props if opening with saved colors
      colorData = presentation.colorOverride;
    } else if (chartType === 'single_metric') {
      // If no prop colors, use the default color for the chart type
      // This only works for chart types where default colors never vary by column data
      return [SINGLE_METRIC_COLOR];
    } else if (
      currentSpec.series.every(
        (series) =>
          (series.color && !isArray(series.color)) ||
          (series.itemStyle?.color && !isArray(series.itemStyle?.color)),
      )
    ) {
      // Otherwise, we can get colorData from the spec if every series has a color
      const collectedSeriesColor = new Set();
      currentSpec.series
        .filter((series) => !series.isShadow)
        .filter((series) => series.name !== null)
        .forEach((series) => {
          if (!collectedSeriesColor.has(series.name)) {
            colorData.push(
              series.color && isArray(series.color) ? series.itemStyle.color : series.color,
            );
            collectedSeriesColor.add(series.name);
          }
        });
    } else if (columnData.length < QUANTITATIVE_COLOR_BAR_THRESHOLD) {
      // Finally, get colorData from the color option if not saved in each series
      colorData = currentSpec.color.slice(0, columnData.length);
    }

    // Sometimes we have more series than columnData (because duplicates, ex. boxplots)
    colorData = colorData.slice(0, columnData.length);
    // Protect against null values by replacing with white
    colorData = colorData.map((c) => (c === null ? '#fff' : c));
    // Protect against grabbing undefined values which crash the page
    return colorData.includes(undefined) ? [] : colorData;
  };

  /**
   * Retrieves column data from the chart spec
   * @param {Object} spec current chart spec
   * @returns {Array} column data
   */
  getColumnData = (spec) => {
    const chartType = this.props.options.chartType.type;
    let columnData = [];
    if (!spec) return columnData;

    switch (chartType) {
      case 'single_metric': {
        const singleMetricValue =
          spec.graphic?.[0].elements?.find((ele) => ele.id === 'SingleMetricValue')?.style?.text ||
          undefined;
        columnData = [singleMetricValue];
        break;
      }
      default: {
        const seriesData = spec.series
          .filter((series) => !series.isShadow)
          .filter((series) => series.name !== null)
          .map((series) => series.name);
        columnData = [...new Set(seriesData)];
        break;
      }
    }

    return columnData;
  };

  /**
   * Sets the new color when the user selection changes
   * @param {Array} newColorData new color
   * @returns {Promise}
   */
  setColorData = (newColorData) => {
    if (newColorData) {
      return new Promise((resolve) => {
        // Add a 0.2 second delay, so that users can click & drag color selector
        // without re-translating for every point grabbed
        this.debounceTimer('colorOverride', 200, newColorData);
        resolve();
      });
    }
    return new Promise();
  };

  /**
   * Updates the state and debounce options when presentation items change
   */
  updatePresentation = () => {
    const newPresentation = cloneDeep(this.props.presentation);
    const newAnnotations = newPresentation.annotations || [];
    this.setState(
      (prevState) => {
        return {
          ...prevState,
          presentation: newPresentation,
          annotations: newAnnotations,
        };
      },
      () => {
        // also update the debounce's option from parent(chart builder)
        this.debounce.options.annotations = this.state.annotations;
      },
    );
  };

  /**
   * Wrapper function for updating the component state
   * @param {Object} state New component state
   */
  updateState = (state) => {
    this.setState(state);
  };

  /**
   * Use debouncing to batch-submit the customize changes to the translation layer
   * @param {String} option Property that we're altering (ex. 'title', 'xaxis', 'colorOverride')
   * @param {Number} delay The timeout duration in milliseconds
   * @param {Any} overrideValue The value to override the value held in state for the given option
   */
  debounceTimer = (option, delay = 0, overrideValue, callback) => {
    // Clear any previous timeout
    if (this.debounce.timeout) clearTimeout(this.debounce.timeout);

    // Add the new key/value to our options (ex. title: this.state.title)
    // This allows users to rapid-fire edit multiple fields without losing changes
    // to the previously-edited fields, if the timeout still hasn't triggered the update
    this.debounce.options[option] = overrideValue || this.state[option];

    this.debounce.timeout = setTimeout(() => {
      if (this.debounce.options.caption !== undefined) {
        /**
         * Use `this.props.updateCaption` instead of `this.props.updateFromCustomize` because
         * captions are stored in the `Chart` state and not the echart option
         */
        this.props.updateCaption(this.debounce.options.caption);
        delete this.debounce.options.caption;
      }
      this.props.updateFromCustomize(this.debounce.options, callback);
    }, delay);
  };

  /**
   * Adds a new text or annotation
   * @param {Boolean} option True for annotations, False for texts
   */
  addAnnotation = (option) => {
    const { presentation, showAnnotations, showTexts } = this.state;
    const fontSize = this.getAnnotationsFontSize();
    const newText = 'Click to add text';
    const numItems = presentation.annotations.length;
    const visible = option ? showAnnotations : showTexts;
    this.setState(
      (prev) => ({
        annotations: [
          ...prev.annotations,
          {
            type: option ? 'annotation' : 'text',
            text: newText,
            fontSize,
            // Offset the position so that the text/annotations don't stack directly on top of eachother
            left: `${15 + numItems}%`,
            top: `${10 + numItems}%`,
            linex: option ? 50 : null,
            liney: option ? 50 : null,
            width: this.props.width,
            height: this.props.height,
            invisible: option ? !showAnnotations : !showTexts,
            interactable: visible && !this.props.showTextField,
            lineInvisible: option ? !showAnnotations : null,
          },
        ],
        presentation: {
          ...presentation,
          annotations: [
            ...prev.presentation.annotations,
            {
              type: option ? 'annotation' : 'text',
              text: newText,
              fontSize,
              // Offset the position so that the text/annotations don't stack directly on top of eachother
              left: `${15 + numItems}%`,
              top: `${10 + numItems}%`,
              width: this.props.width,
              height: this.props.height,
              linex: option ? 50 : null,
              liney: option ? 50 : null,
              invisible: option ? !showAnnotations : !showTexts,
              interactable: visible && !this.props.showTextField,
              lineInvisible: option ? !showAnnotations : null,
            },
          ],
        },
      }),
      () => {
        if (option && !showAnnotations) {
          this.toggleShowAnnotations();
        } else if (!option && !showTexts) {
          this.toggleShowTexts();
        } else {
          this.debounceTimer('annotations');
        }
      },
    );
  };

  /**
   * Toggles the visibility of annotations added to the chart
   */
  toggleShowAnnotations = () => {
    const { presentation, showAnnotations, annotations } = this.state;
    presentation.annotations.forEach((item) => {
      if (item.type !== 'text') {
        item.invisible = showAnnotations;
      }
    });
    annotations.forEach((item) => {
      if (item.type !== 'text') {
        item.invisible = showAnnotations;
        item.interactable = !showAnnotations;
        item.lineInvisible = showAnnotations;
      }
    });
    this.setState(
      (prev) => ({
        annotations,
        showAnnotations: !prev.showAnnotations,
        presentation,
      }),
      () => {
        this.debounceTimer('annotations');
      },
    );
  };

  /**
   * Toggles the visibility of text added to the chart
   */
  toggleShowTexts = () => {
    const { presentation, showTexts, annotations } = this.state;
    presentation.annotations.forEach((item) => {
      if (item.type === 'text') {
        item.invisible = showTexts;
      }
    });
    annotations.forEach((item) => {
      if (item.type === 'text') {
        item.invisible = showTexts;
        item.interactable = !showTexts;
      }
    });
    this.setState(
      (prev) => ({
        annotations,
        showTexts: !prev.showTexts,
        presentation,
      }),
      () => {
        // update annotations to chart builder finally
        this.debounceTimer('annotations');
      },
    );
  };

  /**
   * Should we disable the trash icon for the texts or annotations?
   * @param {String} type 'text' or 'annotation'
   * @returns True if count === 0, False if count !== 0
   */
  shouldDisableTrashIcon = (type) =>
    this.state.presentation.annotations.filter((item) => item.type === type).length === 0;

  /**
   * Delete all texts or annotations. Open alert dialog to confirm
   * @param {String} type Type to delete. Either 'text' or 'annotation'
   */
  handleBatchDelete = (type) => {
    const { presentation, annotations } = this.state;

    this.props.openAlertDialog({
      title: 'Are you sure?',
      descriptions: [`Deleting all ${type}s cannot be undone.`],
      buttons: [
        {
          label: 'Yes',
          key: 'yes',
          onClick: () => {
            presentation.annotations = presentation.annotations.filter(
              (item) => item.type !== type,
            );
            const updatedAnnotations = annotations.filter((item) => item.type !== type);
            this.setState({ presentation, annotations: updatedAnnotations }, () => {
              this.debounceTimer('annotations');
              this.props.closeDialog();
            });
          },
        },
        {
          label: 'Cancel',
          key: 'cancel',
          onClick: () => this.props.closeDialog(),
        },
      ],
    });
  };

  /**
   * Handle the text & annotation input changes, then hide the input field and update the display
   * @param {String} newText The newly inputted text
   * @param {Boolean} submit True if the 'Enter' key was pressed
   */
  handleInputChange = (newText, submit) => {
    if (submit) {
      this.debounceTimer('annotations', 0, undefined, () => {
        this.props.updateAnnotationTextField(
          false,
          null,
          this.state.showAnnotations,
          this.state.showTexts,
        );
      });
    } else {
      const { presentation, annotations } = this.state;
      const { textFieldIndex } = this.props;
      const annotation = presentation.annotations[textFieldIndex];
      const oldText = presentation.annotations[textFieldIndex].text;
      presentation.annotations[textFieldIndex].text = newText;
      annotations[textFieldIndex].text = newText;
      presentation.annotations[textFieldIndex].invisible = !submit;
      annotations[textFieldIndex].invisible = !submit;
      const textFont = getTextFont(annotation.fontSize * PIXEL_TO_POINT_FACTOR, 'Microsoft YaHei');
      // calculate new text width and height
      const textWidth = DOMUtils.getTextParams(newText, textFont, 'width');
      const oldTextWidth = DOMUtils.getTextParams(oldText, textFont, 'width');
      const textHeight = DOMUtils.getTextParams(newText, textFont, 'height');
      if (annotation.coordX && annotation.coordY) {
        // if the edited annotation has coordinate, we need to update the left and top
        // properties based on the new text
        const xy = getCenterPointPixelValues(this.props.echartsRef, annotation);
        const x = xy[0] - textWidth / 2;
        const y = xy[1] - textHeight / 2;

        const currentLeft = `${(x / this.props.width).toFixed(5) * 100}%`;
        const currentTop = `${(y / this.props.height).toFixed(5) * 100}%`;
        presentation.annotations[textFieldIndex].left = currentLeft;
        presentation.annotations[textFieldIndex].top = currentTop;
        annotations[textFieldIndex].left = currentLeft;
        annotations[textFieldIndex].top = currentTop;
      } else {
        // annotation is not in a grid, update the left based on
        // old text and new text so that text can always be centered
        const currentLeft =
          annotations[textFieldIndex].left.substring(
            0,
            annotations[textFieldIndex].left.indexOf('%'),
          ) / 100;
        const widthDistance = (oldTextWidth - textWidth) / 2;
        const updatedLeft = (currentLeft + widthDistance / this.props.width) * 100;
        presentation.annotations[textFieldIndex].left = `${updatedLeft}%`;
        annotations[textFieldIndex].left = `${updatedLeft}%`;
      }
      this.setState({ presentation, annotations });
    }
  };

  // Handle clicking outside of the text/annotation TextField region
  handleTextClickAway = () => {
    // submit the new text
    this.debounceTimer('annotations', 0, undefined, () => {
      this.props.updateAnnotationTextField(
        false,
        null,
        this.state.showAnnotations,
        this.state.showTexts,
      );
    });
  };

  /**
   * Handles clicking the 'X' button in the text/annotation TextField
   */
  handleTextClose = () => {
    const { presentation, annotations } = this.state;
    const { textFieldIndex } = this.props;
    presentation.annotations.splice(textFieldIndex, 1);
    annotations.splice(textFieldIndex, 1);
    this.setState({ presentation, annotations }, () => {
      this.debounceTimer('annotations', 0, undefined, () => {
        this.props.updateAnnotationTextField(
          false,
          null,
          this.state.showAnnotations,
          this.state.showTexts,
        );
      });
    });
  };

  /**
   * Translates and applies annotations to spec from the existing presentation prop
   */
  loadPresentation = () => {
    const { presentation } = this.state;
    const colorData = this.getColorData();

    // Filter out dummy annotations
    const annotations = presentation.annotations.filter(
      (item) => item.x !== 'DataValue' && item.y !== 'DataValue',
    );

    // Convert our existing annotations from percentages to pixels
    if (annotations.length > 0) {
      this.setState((prevState) => ({
        ...prevState,
        annotations,
      }));
    } else {
      presentation.annotations = [];
      this.setState((prevState) => ({
        ...prevState,
        annotations: [],
      }));
    }

    // Set base presentation state after cleanup & conversion
    this.setState({ presentation, colorData });
  };

  /**
   * Checks if a given font size falls within the lower and upper bounds.
   * If not, the font size is set to the corresponding bound value.
   * @param {number} newFontSize font size to be checked
   * @returns {number} updated font size
   */
  checkBounds = (newFontSize) => {
    if (newFontSize < 8) {
      return 8;
    } else if (newFontSize > 32) {
      return 32;
    }
    return newFontSize;
  };

  /**
   * Checks if a given font size falls within the lower and upper bounds
   * @param {number} newFontSize font size to be checked
   * @returns {boolean} check pass/fail status
   */
  checkBoundary = (newFontSize) => {
    if (newFontSize < 8 || newFontSize > 32) {
      return false;
    }
    return true;
  };

  /**
   * Checks if a given font size for all fonts falls within the lower and upper bounds
   * @param {number} newFontSize font size to be checked
   * @returns {boolean} check pass/fail status
   */
  checkAllFontSizeBoundary = (newFontSize) => {
    if (newFontSize === '') {
      return false;
    }
    const { validAllFontSize } = this.state;
    const increment = newFontSize - validAllFontSize;
    if (
      this.checkBoundary(parseInt(this.state.validXaxisTitleFontSize, 10) + increment) &&
      this.checkBoundary(parseInt(this.state.validXaxisLabelFontSize, 10) + increment) &&
      this.checkBoundary(parseInt(this.state.validYaxisTitleFontSize, 10) + increment) &&
      this.checkBoundary(parseInt(this.state.validYaxisLabelFontSize, 10) + increment) &&
      this.checkBoundary(parseInt(this.state.validTitleFontSize, 10) + increment)
    ) {
      return true;
    }
    return false;
  };

  render() {
    const { colorData, columnData, classes, presentation } = this.state;
    const { showCustomize, textFieldIndex, showTextField } = this.props;

    /**
     * Renders the TextField for adding annotations and text
     * @returns {ReactElement} the React element to display
     */
    const renderTextField = () => {
      if (presentation.annotations[textFieldIndex] === undefined) return null;
      const textFont = getTextFont(
        presentation.annotations[textFieldIndex].fontSize * PIXEL_TO_POINT_FACTOR,
        'Microsoft YaHei',
      );
      const textWidth = DOMUtils.getTextParams(
        presentation.annotations[textFieldIndex].text,
        textFont,
        'width',
      );
      const textHeight = DOMUtils.getTextParams(
        presentation.annotations[textFieldIndex].text,
        textFont,
        'height',
      );

      // get textfield's left and top in pixel
      const textFieldPosition = getTextFieldPosition(
        this.props.echartsRef,
        presentation.annotations[textFieldIndex],
        this.props.width,
        this.props.height,
        this.state.menuWidth,
        this.state.headerHeight,
        textHeight,
        textWidth,
      );
      return (
        <ClickAwayListener touchEvent="onTouchStart" onClickAway={() => this.handleTextClickAway()}>
          <TextField
            autoFocus
            hiddenLabel
            variant="outlined"
            size="small"
            InputProps={{
              style: {
                // Get the same font size as currently displayed
                fontSize: `${presentation.annotations[textFieldIndex].fontSize}px`,
                fontFamily: 'Microsoft YaHei',
                width: `${textWidth + ANNOTATION_TEXTBOX_OFFSET}px`,
                padding: 0,
              },
              endAdornment: (
                <Tooltip title="Delete" placement="right">
                  <IconButton fontSize="small" onClick={() => this.handleTextClose()}>
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              ),
            }}
            style={{
              position: 'absolute',
              zIndex: '101',
              // Position the TextField over the existing text.
              // Extra +/- seems to be loosely based on header/footer height & left/right padding?
              top: textFieldPosition.top,
              left: textFieldPosition.left,
              width: `${textWidth + ANNOTATION_TEXTBOX_OFFSET}px`,
            }}
            value={presentation.annotations[textFieldIndex].text}
            onKeyDown={(e) =>
              e.key === 'Enter' ? this.handleInputChange(e.target.value, true) : null
            }
            onChange={(e) => {
              this.handleInputChange(e.target.value, false);
            }}
          />
        </ClickAwayListener>
      );
    };

    /**
     * Renders the input fields in the Customize dropdown
     * @returns {ReactFragment} Fragment wrapping the input fields to display
     */
    const renderInputFields = () => (
      <>
        {AUTOSCALABLE_CHART_TYPES.includes(this.props.options.chartType.type) && (
          <>
            <div className="borderless-row customize-header">
              <div className="customize-label">Axes</div>
            </div>
            <div className="borderless-row">
              <FormControlLabel
                sx={{
                  minWidth: 'calc(50% - 29px)',
                  marginLeft: 0,
                  justifyContent: 'space-between',
                }}
                label="Autoscale"
                labelPlacement="start"
                control={
                  <Switch
                    checked={this.state.shouldAutoscale}
                    onChange={this.toggleAutoscale}
                    name="autoscale-switch"
                    data-cy="CB-Autoscale-Switch"
                    data-testid="CB-Autoscale-Switch"
                  />
                }
              />
            </div>
          </>
        )}
        <div className="borderless-row customize-header">
          <div className="customize-label">Labels</div>
        </div>
        {TITLED_CHART_TYPES.includes(this.props.options.chartType.type) && (
          <div className="borderless-row">
            <div className="customize-label">Chart Title</div>
            <TextField
              data-cy="CB-Chart-Title"
              data-testid="CB-Chart-Title"
              sx={{ width: '100%' }}
              variant="outlined"
              size="small"
              value={this.getTitle()}
              onChange={(e) => {
                this.setTitle(e.target.value);
              }}
            />
          </div>
        )}
        {AXIS_LABELED_CHART_TYPES.includes(this.props.options.chartType.type) && (
          <>
            <div className="borderless-row">
              <div className="customize-label">X-Axis</div>
              <TextField
                data-cy="CB-X-Axis"
                data-testid="CB-X-Axis"
                sx={{ width: '100%' }}
                variant="outlined"
                size="small"
                value={this.getXAxisTitle()}
                onChange={(e) => {
                  this.setXAxisTitle(e.target.value);
                }}
              />
            </div>
            <div className="borderless-row">
              <div className="customize-label">X-Axis Ticks Prefix</div>
              <TextField
                data-testid="CB-X-Axis-Prefix"
                sx={{ width: '100%' }}
                variant="outlined"
                size="small"
                value={this.getXAxisTicksPrefix()}
                onChange={(e) => {
                  this.setXAxisTickPrefix(e.target.value);
                }}
              />
            </div>
            <div className="borderless-row">
              <div className="customize-label">X-Axis Ticks Suffix</div>
              <TextField
                data-testid="CB-X-Axis-Suffix"
                sx={{ width: '100%' }}
                variant="outlined"
                size="small"
                value={this.getXAxisTicksSuffix()}
                onChange={(e) => {
                  this.setXAxisTickSuffix(e.target.value);
                }}
              />
            </div>
            <div className="borderless-row">
              <div className="customize-label">Y-Axis</div>
              <TextField
                data-cy="CB-Y-Axis"
                data-testid="CB-Y-Axis"
                sx={{ width: '100%' }}
                variant="outlined"
                size="small"
                value={this.getYAxisTitle()}
                onChange={(e) => {
                  this.setYAxisTitle(e.target.value);
                }}
              />
            </div>
            <div className="borderless-row">
              <div className="customize-label">Y-Axis Ticks Prefix</div>
              <TextField
                data-testid="CB-Y-Axis-Prefix"
                sx={{ width: '100%' }}
                variant="outlined"
                size="small"
                value={this.getYAxisTicksPrefix()}
                onChange={(e) => {
                  this.setYAxisTickPrefix(e.target.value);
                }}
              />
            </div>
            <div className="borderless-row">
              <div className="customize-label">Y-Axis Ticks Suffix</div>
              <TextField
                data-testid="CB-Y-Axis-Suffix"
                sx={{ width: '100%' }}
                variant="outlined"
                size="small"
                value={this.getYAxisTicksSuffix()}
                onChange={(e) => {
                  this.setYAxisTickSuffix(e.target.value);
                }}
              />
            </div>
            {this.props.series && this.props.series[0] && this.props.series[0]?.mark?.overlay && (
              <div className="borderless-row">
                <div className="customize-label">Overlay</div>
                <TextField
                  data-cy="CB-Overlay-Axis"
                  data-testid="CB-Overlay-Axis"
                  sx={{ width: '100%' }}
                  variant="outlined"
                  size="small"
                  value={this.getOverlayAxisTitle()}
                  onChange={(e) => {
                    this.setOverlayAxisTitle(e.target.value);
                  }}
                />
              </div>
            )}
          </>
        )}
        <div className="borderless-row">
          <div className="customize-label">Caption</div>
          <TextField
            data-cy="CB-Caption"
            data-testid="CB-Caption"
            sx={{ width: '100%' }}
            variant="outlined"
            size="small"
            value={this.getCaption()}
            onChange={(e) => this.setCaption(e.target.value)}
          />
        </div>
        {(TITLED_CHART_TYPES.includes(this.props.options.chartType.type) ||
          AXIS_LABELED_CHART_TYPES.includes(this.props.options.chartType.type)) && (
          <>
            <div className="borderless-row customize-header">
              <div className="customize-label">Font Sizes</div>
              <span />
            </div>
            <div className="borderless-row">
              <div className="customize-label">All Sizes</div>
              <TextField
                data-cy="CB-All-Sizes"
                data-testid="CB-All-Sizes"
                error={!this.checkAllFontSizeBoundary(this.getAllFontSize())}
                label={
                  this.checkAllFontSizeBoundary(this.getAllFontSize())
                    ? ''
                    : fontSizeInputErrorMessage(
                        this.getLowerBoundOfAllFontSize(),
                        this.getHigherBoundOfAllFontSize(),
                      )
                }
                type="number"
                sx={{ width: '100%' }}
                variant="outlined"
                value={this.state.allFontSize}
                onChange={(e) => {
                  this.setAllFontSize(e.target.value);
                }}
                inputProps={{
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                  min: this.getLowerBoundOfAllFontSize(),
                  max: this.getHigherBoundOfAllFontSize(),
                }}
                InputLabelProps={{ style: { fontSize: 14 } }}
                onBlur={() => {
                  this.onBlurAllFontSize();
                }}
              />
            </div>
          </>
        )}
        {TITLED_CHART_TYPES.includes(this.props.options.chartType.type) && (
          <div className="borderless-row">
            <div className="customize-label">Chart Title</div>
            <TextField
              data-cy="CB-Chart-Title-Font-Size"
              data-testid="CB-Chart-Title-Font-Size"
              error={!this.checkBoundary(this.getTitleFontSize())}
              label={this.checkBoundary(this.getTitleFontSize()) ? '' : fontSizeInputErrorMessage()}
              type="number"
              sx={{ width: '100%' }}
              variant="outlined"
              value={this.getTitleFontSize()}
              onChange={(e) => {
                this.setTitleFontSize(e.target.value);
              }}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 8, max: 32 }}
              InputLabelProps={{ style: { fontSize: 14 } }}
              onBlur={() => {
                this.onBlurTitleFontSize();
              }}
            />
          </div>
        )}
        {AXIS_LABELED_CHART_TYPES.includes(this.props.options.chartType.type) && (
          <>
            <div className="borderless-row">
              <div className="customize-label">X-Axis Title</div>
              <TextField
                data-cy="CB-X-Axis-Title"
                data-testid="CB-X-Axis-Title"
                error={!this.checkBoundary(this.getXAxisTitleFontSize())}
                label={
                  this.checkBoundary(this.getXAxisTitleFontSize())
                    ? ''
                    : fontSizeInputErrorMessage()
                }
                type="number"
                sx={{ width: '100%' }}
                variant="outlined"
                value={this.getXAxisTitleFontSize()}
                onChange={(e) => {
                  this.setXAxisTitleFontSize(e.target.value);
                }}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 8, max: 32 }}
                InputLabelProps={{ style: { fontSize: 14 } }}
                onBlur={() => {
                  this.onBlurXAxisTitleFontSize();
                }}
              />
            </div>
            <div className="borderless-row">
              <div className="customize-label">X-Axis Labels</div>
              <TextField
                data-cy="CB-X-Axis-Labels"
                data-testid="CB-X-Axis-Labels"
                error={!this.checkBoundary(this.getXAxisLabelFontSize())}
                label={
                  this.checkBoundary(this.getXAxisLabelFontSize())
                    ? ''
                    : fontSizeInputErrorMessage()
                }
                type="number"
                sx={{ width: '100%' }}
                variant="outlined"
                value={this.getXAxisLabelFontSize()}
                onChange={(e) => {
                  this.setXAxisLabelFontSize(e.target.value);
                }}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 8, max: 32 }}
                InputLabelProps={{ style: { fontSize: 14 } }}
                onBlur={() => {
                  this.onBlurXAxisLabelFontSize();
                }}
              />
            </div>
            <div className="borderless-row">
              <div className="customize-label">
                {this.state.currentSpec.yAxis.length > 1 ? 'Y-Axes Titles' : 'Y-Axis Title'}
              </div>
              <TextField
                data-cy="CB-Y-Axis-Title"
                data-testid="CB-Y-Axis-Title"
                error={!this.checkBoundary(this.getYAxesTitleFontSize())}
                label={
                  this.checkBoundary(this.getYAxesTitleFontSize())
                    ? ''
                    : fontSizeInputErrorMessage()
                }
                type="number"
                sx={{ width: '100%' }}
                variant="outlined"
                value={this.getYAxesTitleFontSize()}
                onChange={(e) => {
                  this.setYAxesTitleFontSize(e.target.value);
                }}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 8, max: 32 }}
                InputLabelProps={{ style: { fontSize: 14 } }}
                onBlur={() => {
                  this.onBlurYAxesTitleFontSize();
                }}
              />
            </div>
            <div className="borderless-row">
              <div className="customize-label">
                {this.state.currentSpec.yAxis.length > 1 ? 'Y-Axes Labels' : 'Y-Axis Labels'}
              </div>
              <TextField
                data-cy="CB-Y-Axis-Labels"
                data-testid="CB-Y-Axis-Labels"
                error={!this.checkBoundary(this.getYAxesLabelFontSize())}
                label={
                  this.checkBoundary(this.getYAxesLabelFontSize())
                    ? ''
                    : fontSizeInputErrorMessage()
                }
                type="number"
                sx={{ width: '100%' }}
                variant="outlined"
                value={this.getYAxesLabelFontSize()}
                onChange={(e) => {
                  this.setYAxesLabelFontSize(e.target.value);
                }}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 8, max: 32 }}
                InputLabelProps={{ style: { fontSize: 14 } }}
                onBlur={() => {
                  this.onBlurYAxesLabelFontSize();
                }}
              />
            </div>
          </>
        )}
        {COLORABLE_CHART_TYPES.includes(this.props.options.chartType.type) &&
        /* Don't display the colorEditor when we have continuous coloring */
        !(
          this.state.columnData.length >= QUANTITATIVE_COLOR_BAR_THRESHOLD &&
          this.state.isNumericColoring
        ) ? (
          <>
            <div className="borderless-row customize-header">
              <div className="customize-label">Plot</div>
            </div>
            <div className="borderless-row">
              <div className="label">Color</div>
              <ColorEditor
                dataCy="CB-Color"
                tooltip="Column Color"
                recolor={this.setColorData}
                colorData={colorData}
                columnData={columnData}
                classes={classes}
              />
            </div>
          </>
        ) : null}
        <div className="borderless-row customize-header">
          <div className="customize-label">Annotations</div>
          <span />
        </div>
        {!UNSUPPORTED_LINE_TYPES.includes(this.props.options.chartType.type) && (
          <LinesField
            chartSpec={this.props.chartSpec}
            echartsRef={this.props.echartsRef}
            fields={this.props.fields}
            isLoadingDataset={this.props.isLoadingDataset}
            updateChart={this.props.updateChart}
            chartType={this.props.options.chartType.type}
          />
        )}
        <div className="borderless-row">
          <div className="customize-label">Font Size</div>
          <TextField
            data-cy="CB-Font-Size"
            data-testid="CB-Font-Size"
            error={!this.checkBoundary(this.getAnnotationsFontSize())}
            label={
              this.checkBoundary(this.getAnnotationsFontSize()) ? '' : fontSizeInputErrorMessage()
            }
            type="number"
            sx={{ width: '100%' }}
            variant="outlined"
            value={this.getAnnotationsFontSize()}
            onChange={(e) => {
              this.setAnnotationsFontSize(e.target.value);
            }}
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*',
              min: 8,
              max: 32,
            }}
            InputLabelProps={{ style: { fontSize: 14 } }}
            onBlur={() => {
              this.onBlurAnnotationsFontSize();
            }}
          />
        </div>
        <div className="borderless-row">
          <FormControlLabel
            sx={{
              minWidth: 'calc(50% - 29px)',
              marginLeft: 0,
              justifyContent: 'space-between',
            }}
            label="Texts"
            labelPlacement="start"
            control={
              <Switch
                checked={this.state.showTexts}
                onChange={this.toggleShowTexts}
                name="text-switch"
              />
            }
          />
          <Tooltip title="Delete all texts">
            <span>
              <IconButton
                onClick={
                  this.shouldDisableTrashIcon('text')
                    ? undefined
                    : () => this.handleBatchDelete('text')
                }
                disabled={this.shouldDisableTrashIcon('text')}
              >
                <DeleteOutlineIcon
                  style={{
                    fill: this.shouldDisableTrashIcon('text')
                      ? '#B9B9B9'
                      : theme.palette.primary.main,
                  }}
                />
              </IconButton>
            </span>
          </Tooltip>
          <Button
            data-cy="CB-Add-Text"
            data-testid="CB-Add-Text"
            className="annotations-button"
            size="small"
            onClick={() => this.addAnnotation(false)}
          >
            Add Text
          </Button>
        </div>
        <div className="borderless-row-bottom">
          <FormControlLabel
            sx={{
              minWidth: 'calc(50% - 29px)',
              marginLeft: 0,
              justifyContent: 'space-between',
            }}
            label="Annotations"
            labelPlacement="start"
            control={
              <Switch
                checked={this.state.showAnnotations}
                onChange={this.toggleShowAnnotations}
                name="annotations-switch"
              />
            }
          />
          <Tooltip title="Delete all annotations">
            <span>
              <IconButton
                onClick={
                  this.shouldDisableTrashIcon('annotation')
                    ? undefined
                    : () => this.handleBatchDelete('annotation')
                }
                disabled={this.shouldDisableTrashIcon('annotation')}
              >
                <DeleteOutlineIcon
                  style={{
                    fill: this.shouldDisableTrashIcon('annotation')
                      ? '#B9B9B9'
                      : theme.palette.primary.main,
                  }}
                />
              </IconButton>
            </span>
          </Tooltip>
          <Button
            data-cy="CB-Add-Annotation"
            data-testid="CB-Add-Annotation"
            className="annotations-button"
            size="small"
            onClick={() => this.addAnnotation(true)}
          >
            Add Annotation
          </Button>
        </div>
      </>
    );
    return (
      <>
        {/* Always render the text field when clicking text & annotations */}
        {showTextField && renderTextField()}
        {/* Only render the input fields when the customize dropdown is clicked */}
        {showCustomize && renderInputFields()}
      </>
    );
  }
}

// Set the displayName to be accessed by the container
Customize.displayName = 'Customize';

const mapStateToProps = () => ({});
export default connect(mapStateToProps, {
  closeDialog,
  openAlertDialog,
})(Customize);
