import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { AVA_IDS } from '../../../../../constants/dataAssistant';
import { selectDatasetById } from '../../../../../store/selectors/dataspace.selector';
import SessionData from '../../../../ChartData/SessionData';
import Table from '../../../../DisplayPanel/Charts/Table/Table';
import { AVA_TABLE_EXPANSION_LIMIT } from '../../../../DisplayPanel/Charts/Table/constants';
import './TableMessage.scss';

const TableMessage = ({ id, inView = true, objectId, message }) => {
  // Use dc_dataset_id (objectId) to get the pipeliner ID and pass it to the table component
  const dataset = useSelector((state) => selectDatasetById(state, objectId));

  return (
    <div className="TableMessage" data-testid={AVA_IDS.MSG_TABLE_WRAPPER(id)}>
      <SessionData key={id} message={message} objectId={objectId}>
        {(msgContext, error, isFailed, isLoading, rowCount, updateRowCount) => (
          <Table
            data={msgContext.chart.data}
            id={id}
            inView={inView}
            isFailed={isFailed}
            isInsightsBoard={false}
            isLoading={isLoading}
            noRowsText="The answer to your question appears to be an empty dataset."
            pageSizeOptions={[AVA_TABLE_EXPANSION_LIMIT]}
            rowCount={rowCount}
            updateRowCount={updateRowCount}
            pipelinerDatasetId={dataset?.pipeliner_dataset_id}
          />
        )}
      </SessionData>
    </div>
  );
};

TableMessage.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  inView: PropTypes.bool,
  message: PropTypes.object.isRequired,
  objectId: PropTypes.string.isRequired,
};

export default TableMessage;
