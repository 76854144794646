import {
  CREATE_CROSS_FILTER,
  CREATE_CROSS_FILTER_GROUP,
  DELETE_CROSS_FILTER,
  DELETE_CROSS_FILTER_GROUP,
  GET_CROSS_FILTERS_FAILURE,
  GET_CROSS_FILTERS_REQUEST,
  GET_CROSS_FILTERS_SUCCESS,
  SAVE_CROSS_FILTERS_FAILURE,
  SAVE_CROSS_FILTERS_REQUEST,
  SAVE_CROSS_FILTERS_SUCCESS,
  TOGGLE_CROSS_FILTER_GROUP,
  UPDATE_CROSS_FILTER,
  UPDATE_CROSS_FILTER_GROUP,
} from '../actions/crossFilter.actions';

export const initialState = {
  // Are we waiting for a response from the server?
  isLoading: false,
  // Cross filter groups from the last interaction with the API
  oldCrossFilterGroups: {},
  // Current state of the cross filter groups
  crossFilterGroups: {},
  // The IDs of all the deleted cross filters since the last interaction w/ API
  deletedCrossFilters: [],
  // The groupIDs of all the deleted cross filter groups since the last interaction w/ API
  deletedCrossFilterGroups: [],
};

/**
 * Deletes crossFilters with the given index from the given filters array
 * @param {{}Array} filters The filters to delete from
 * @param {Integer} index The index of the filter to delete. If null, all filters will be deleted.
 * @returns {[]Array} Updated crossFilters and the deleted filter IDs -
 * For the filters that were stored on BE
 */
const deleteFilters = (filters, index = null) => {
  if (!filters || filters.length === 0) return [[], []];

  // Delete all of the filters
  if (index === null) return [[], filters.map((f) => f.id)];

  // Delete a single filter by index within the crossFilter array of the group
  const updatedFilters = [...filters];
  const { id } = updatedFilters.splice(index, 1)[0];
  return [updatedFilters, [id]];
};

export default (state = initialState, action) => {
  switch (action.type) {
    // We cannot toggle cross filters within a group on/off individually
    case TOGGLE_CROSS_FILTER_GROUP: {
      const { groupId } = action;
      return {
        ...state,
        crossFilterGroups: {
          ...state.crossFilterGroups,
          [groupId]: {
            ...state.crossFilterGroups[groupId],
            enabled: !state.crossFilterGroups[groupId].enabled,
          },
        },
      };
    }
    case CREATE_CROSS_FILTER: {
      const { column, columnType, expression, groupId, value } = action;
      return {
        ...state,
        crossFilterGroups: {
          ...state.crossFilterGroups,
          [groupId]: {
            ...state.crossFilterGroups[groupId],
            crossFilters: [
              ...state.crossFilterGroups[groupId].crossFilters,
              { transform: { column, columnType, expression, type: 'filter', value } },
            ],
          },
        },
      };
    }
    case CREATE_CROSS_FILTER_GROUP: {
      const { crossFilters, enabled, name } = action;

      // Generate a new, unique UUID for the new cross filter groupId
      let groupId = crypto.randomUUID();
      while (state.crossFilterGroups[groupId] || state.oldCrossFilterGroups[groupId]) {
        groupId = crypto.randomUUID();
      }

      return {
        ...state,
        crossFilterGroups: {
          ...state.crossFilterGroups,
          [groupId]: {
            // The format of each crossFilters should match the format specified in CREATE_CROSS_FILTER
            // Add the type property to each cross filter
            crossFilters: crossFilters.map((f) => ({
              ...f,
              transform: { ...f.transform, type: 'filter' },
            })),
            enabled,
            // Publications to add an exclusion for in dc_cross_filter_group_scope.
            // Existing entries will not be added again.
            excluded_publications: [],
            // Publications to remove the exclusion for in dc_cross_filter_group_scope.
            // IDs that are in excluded_publications will be ignored.
            // These are specified explicitly to avoid conflicts between multiple editors.
            included_publications: [],
            name,
          },
        },
      };
    }
    case UPDATE_CROSS_FILTER: {
      const { column, columnType, expression, groupId, index, value } = action;

      // Update the filter with the properties that were provided
      const updatedFilters = [...state.crossFilterGroups[groupId].crossFilters];
      updatedFilters[index] = {
        ...updatedFilters[index],
        transform: {
          ...updatedFilters[index].transform,
          ...(column && { column }),
          ...(columnType && { columnType }),
          ...(expression && { expression }),
          ...(value && { value }),
        },
      };

      return {
        ...state,
        crossFilterGroups: {
          ...state.crossFilterGroups,
          [groupId]: {
            ...state.crossFilterGroups[groupId],
            crossFilters: updatedFilters,
          },
        },
      };
    }
    case UPDATE_CROSS_FILTER_GROUP: {
      const { excludedPublications, groupId, includedPublications, name } = action;
      return {
        ...state,
        crossFilterGroups: {
          ...state.crossFilterGroups,
          [groupId]: {
            ...state.crossFilterGroups[groupId],
            ...(excludedPublications && { excluded_publications: excludedPublications }),
            ...(includedPublications && { included_publications: includedPublications }),
            ...(name && { name }),
          },
        },
      };
    }
    case DELETE_CROSS_FILTER: {
      const { groupId, index } = action;
      const [updatedFilters, deletedFilterIDs] = deleteFilters(
        state.crossFilterGroups[groupId].crossFilters,
        index,
      );
      return {
        ...state,
        crossFilterGroups: {
          ...state.crossFilterGroups,
          [groupId]: {
            ...state.crossFilterGroups[groupId],
            crossFilters: updatedFilters,
          },
        },
        ...(deletedFilterIDs.length > 0 && {
          deletedCrossFilters: [...state.deletedCrossFilters, ...deletedFilterIDs],
        }),
      };
    }
    case DELETE_CROSS_FILTER_GROUP: {
      const { groupId } = action;

      // Delete the filters within the cross filter group
      const [, deletedFilterIDs] = deleteFilters(state.crossFilterGroups[groupId].crossFilters);

      // Was this group stored on the BE?
      const wasGroupStored = state.oldCrossFilterGroups[groupId];

      // Delete the group itself
      const updatedCrossFilterGroups = { ...state.crossFilterGroups };
      delete updatedCrossFilterGroups[groupId];

      return {
        ...state,
        crossFilterGroups: updatedCrossFilterGroups,
        ...(wasGroupStored && {
          deletedCrossFilterGroups: [...state.deletedCrossFilterGroups, Number(groupId)],
        }),
        ...(deletedFilterIDs.length > 0 && {
          deletedCrossFilters: [...state.deletedCrossFilters, ...deletedFilterIDs],
        }),
      };
    }
    case GET_CROSS_FILTERS_REQUEST:
      return {
        ...state,
        isLoading: true,
        oldCrossFilterGroups: {},
        crossFilterGroups: {},
        deletedCrossFilters: [],
        deletedCrossFilterGroups: [],
      };
    case GET_CROSS_FILTERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        oldCrossFilterGroups: action.crossFilterGroups,
        crossFilterGroups: action.crossFilterGroups,
      };
    case GET_CROSS_FILTERS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case SAVE_CROSS_FILTERS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case SAVE_CROSS_FILTERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        oldCrossFilterGroups: action.crossFilterGroups,
        crossFilterGroups: action.crossFilterGroups,
        deletedCrossFilters: [],
        deletedCrossFilterGroups: [],
      };
    case SAVE_CROSS_FILTERS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
