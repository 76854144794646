import Button from '@mui/material/Button';
import React from 'react';
import DataChatAutocompleteInput from '../../common/DataChatAutocompleteInput';
import '../ChartBuilder.scss';
import { SAMPLE_LIMITS_CHART_BUILDER } from '../utils/constants';

type Props = {
  chooseDataSampleLimit: () => mixed, // set the amount of rows
  dataSampleLimit: Number, // Amount of rows to sample that has been set in the container
  options: {
    datasetName: String, // Name of the underlying dataset
    datasetVersion: Number, // Version of the underlying dataset
    chartType: { type: String, name: String }, // Type of the chart being plotted
  }, // Data required for plotting in the chart builder
  totalRowCount: Number, // Amount of rows in the dataset
};

const formatDataSampleLimit = (dataSampleLimit) =>
  dataSampleLimit
    ? { label: dataSampleLimit.toLocaleString(), value: dataSampleLimit }
    : { label: 'All Rows', value: null };

class SampleLimit extends React.Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      dataSampleLimit: formatDataSampleLimit(props.dataSampleLimit),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dataSampleLimit !== this.props.dataSampleLimit) {
      this.setState({
        dataSampleLimit: formatDataSampleLimit(this.props.dataSampleLimit),
      });
    }
  }

  render() {
    const { totalRowCount } = this.props;
    const limitSuggestions = SAMPLE_LIMITS_CHART_BUILDER.filter((item) => item < totalRowCount).map(
      (item) => ({ value: item, label: item.toLocaleString() }),
    );

    if (limitSuggestions) limitSuggestions.push({ value: null, label: 'All Rows' });

    const displayValue = this.state.dataSampleLimit?.label
      ? this.state.dataSampleLimit.label.toLocaleString()
      : '';
    return (
      <>
        <div className="borderless-row">
          <div className="label">Row Limit</div>
          <DataChatAutocompleteInput
            key="sample_limit_field"
            placeholder="Select a sample limit"
            options={limitSuggestions}
            value={displayValue}
            inputValue={displayValue}
            listboxProps={{ style: { maxHeight: '10rem' } }}
            renderOption={(props, option) => (
              <li {...props}>
                <span>{option.label.toLocaleString()}</span>
              </li>
            )}
            freeSolo
            onChange={(_, newSelection) => {
              this.setState({ dataSampleLimit: newSelection });
            }}
            onKeyDown={(event) => {
              if (event.key === 'Backspace') {
                //  Remove value if freesolo is active
                if (this.state.dataSampleLimit?.label === 'All Rows') {
                  this.setState({ dataSampleLimit: undefined });
                }
              }
            }}
            onInputChange={(_, newSelection) => {
              //  Check if an integer
              const parsedInt = parseInt(newSelection.replaceAll(',', ''), 10);
              if (!Number.isNaN(parsedInt) && parsedInt > 0) {
                this.setState({
                  dataSampleLimit: { label: parsedInt.toLocaleString(), value: parsedInt },
                });
              }
            }}
            data-cy="sampleLimit-input"
            data-testid="sampleLimit-input"
          />
        </div>
        <div className="borderless-row">
          <span />
          {/* Pushes this button to the right */}
          <Button
            className="Chart-Builder-Submit-Button"
            color="primary"
            variant="outlined"
            size="small"
            onClick={() =>
              this.props.chooseDataSampleLimit(this.state.dataSampleLimit?.value || null, true)
            }
          >
            Apply
          </Button>
        </div>
        <span />
      </>
    );
  }
}

// Set the displayName to be accessed by the container
SampleLimit.displayName = 'SampleLimit';

export default SampleLimit;
