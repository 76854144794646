import styled, { css } from 'styled-components';

interface IGutter {
  size?: number;
  direction?: 'horizontal' | 'vertical';
}

const Gutter = styled('div')<IGutter>`
  ${(props) => css`
    ${props.direction === 'horizontal' ? 'width' : 'height'} : ${(props.size || 0) * 8}px;
  `}

  ${(props) =>
    props.direction === 'horizontal' &&
    css`
      display: inline-block;
    `}
`;

export default Gutter;
