import { REHYDRATE } from 'redux-persist';
import { all, call, cancel, race, select, spawn, take } from 'redux-saga/effects';

import { LOGIN_SUCCESS, LOGOUT_SUCCESS, REGISTER_SUCCESS } from '../actions/auth.actions';
import pipelinerDatasetsSaga from '../slices/pipelinerDatasets/sagas';
import accessDialogSaga from './accessDialog.saga';
import apikeysSaga from './apikeys.saga';
import appsSaga from './apps.saga';
import askAvaSaga from './askAva.saga';
import authSaga from './auth.saga';
import avaPublicSaga from './avaPublic.saga';
import browserTypeSaga from './browser_type.saga';
import catalogSaga from './catalog.saga';
import chartSaga from './chart.saga';
import chartSelectionToolbarSaga from './chart_selection.saga';
import chartRendersSaga from './chartRenders.saga';
import chartspaceSaga from './chartspace.saga';
import chatSaga from './chat.saga';
import computeSaga from './computeDataset.saga';
import configSaga from './config.saga';
import connectionSaga from './connection.saga';
import contactFormSaga from './contact_form.saga';
import contextSaga from './context.saga';
import credentialsSaga from './credentials.saga';
import crossFilterSaga from './crossFilter.saga';
import dataAssistantSaga from './dataAssistant.saga';
import benSaga from './dataset.saga';
import datasetCreatorSaga from './datasetCreator.saga';
import dataspaceSaga from './dataspace.saga';
import dbBrowserSaga from './dbBrowser.saga';
import dialogSaga from './dialog.saga';
import drillThroughSaga from './drill_through.saga';
import editorSaga from './editor.saga';
import embedSaga from './embed.saga';
import examplesSaga from './examples.saga';
import expressionsSaga from './expressions.saga';
import fileDownloadSaga from './file_download.saga';
import fileManagerSaga from './file_manager.saga';
import graphModeSaga from './graphMode.saga';
import headerSaga from './header.saga';
import homeScreenSaga from './home_screen.saga';
import initialUtteranceSaga from './initial_utterance.saga';
import insightsBoardSaga from './insights_board.saga';
import integrationsSaga from './integrations.saga';
import interruptSaga from './interrupt.saga';
import invoiceSaga from './invoice.saga';
import licenseSaga from './license.saga';
import loadCardSaga from './loadCard.saga';
import messagesSaga from './messages.saga';
import modelSaga from './model.saga';
import monitorSaga from './monitor.saga';
import nodesSaga from './nodes.saga';
import notificationSaga from './notification.saga';
import oauthSaga from './oauth.saga';
import organizationSaga from './organization.saga';
import pollSaga from './poll.saga';
import typedProfilePicturesSaga from './profilePictures.saga';
import {
  ObjectiveRecommendationRegisterEngagementSaga,
  ObjectivesRecommendationsSaga,
  QuestionRecommendationRegisterEngagementSaga,
  QuestionRecommendationsSaga,
} from './recommendations.saga';
import routesSaga from './routes.saga';
import { selectIsAuthenticated } from './selectors';
import sessionSaga from './session.saga';
import settingsSaga from './settings.saga';
import shareSaga from './share.saga';
import subscriptionCheckFreeTrial from './subscriptionCheckFreeTrial.saga';
import subscriptionsSaga from './subscriptions.saga';
import subscriptionTiersSaga from './subscriptionTiers.saga';
import suggestionsSaga from './suggestions.saga';
import taskSaga from './task.saga';
import uploadSaga from './upload.saga';
import userFeedbackSaga from './userFeedback.saga';
import utteranceSaga from './utterance.saga';
import utteranceComposerSaga from './utterance_composer.saga';
import utterancesPreviewSaga from './utterances_preview.saga';
import viewLineageSaga from './viewSlicedWorkflow.saga';
import windowSaga from './window.saga';
import workspacev2Saga from './workspacev2.saga';

/**
 * Spawns a list of saga. errorHandler objects using Redux Saga's spawn API.
 * If a spawned saga throws an error, the errorHandler is executed and the saga
 * is retried.
 */
const spawnSagasList = (sagasList) =>
  sagasList.map(({ saga, errorHandler }) =>
    spawn(function* s() {
      while (true) {
        try {
          yield call(saga);
          break;
        } catch (e) {
          yield* errorHandler(e);
        }
      }
    }),
  );

// eslint-disable-next-line require-yield
function* genericSagaErrorHandler(e) {
  /* eslint-disable-next-line no-console */
  console.log(e);
}

export default function* rootSaga() {
  // Sagas that will run for the entire duration of the application's life.
  const mainSagasList = [
    { saga: authSaga, errorHandler: genericSagaErrorHandler },
    { saga: routesSaga, errorHandler: genericSagaErrorHandler },
    { saga: embedSaga, errorHandler: genericSagaErrorHandler },
    { saga: chartSaga, errorHandler: genericSagaErrorHandler },
    { saga: computeSaga, errorHandler: genericSagaErrorHandler },
    { saga: modelSaga, errorHandler: genericSagaErrorHandler },
    { saga: browserTypeSaga, errorHandler: genericSagaErrorHandler },
    { saga: configSaga, errorHandler: genericSagaErrorHandler },
    { saga: utterancesPreviewSaga, errorHandler: genericSagaErrorHandler },
    { saga: contactFormSaga, errorHandler: genericSagaErrorHandler },
    { saga: subscriptionTiersSaga, errorHandler: genericSagaErrorHandler },
    { saga: avaPublicSaga, errorHandler: genericSagaErrorHandler },
  ];
  yield all(spawnSagasList(mainSagasList));

  // Sagas that will only start when the user is logged in.
  const loggedInSagasList = [
    { saga: accessDialogSaga, errorHandler: genericSagaErrorHandler },
    { saga: appsSaga, errorHandler: genericSagaErrorHandler },
    { saga: chartRendersSaga, errorHandler: genericSagaErrorHandler },
    { saga: chatSaga, errorHandler: genericSagaErrorHandler },
    { saga: catalogSaga, errorHandler: genericSagaErrorHandler },
    { saga: contextSaga, errorHandler: genericSagaErrorHandler },
    { saga: examplesSaga, errorHandler: genericSagaErrorHandler },
    { saga: fileDownloadSaga, errorHandler: genericSagaErrorHandler },
    { saga: fileManagerSaga, errorHandler: genericSagaErrorHandler },
    { saga: headerSaga, errorHandler: genericSagaErrorHandler },
    { saga: licenseSaga, errorHandler: genericSagaErrorHandler },
    { saga: loadCardSaga, errorHandler: genericSagaErrorHandler },
    { saga: messagesSaga, errorHandler: genericSagaErrorHandler },
    { saga: pollSaga, errorHandler: genericSagaErrorHandler },
    { saga: suggestionsSaga, errorHandler: genericSagaErrorHandler },
    { saga: sessionSaga, errorHandler: genericSagaErrorHandler },
    { saga: settingsSaga, errorHandler: genericSagaErrorHandler },
    { saga: uploadSaga, errorHandler: genericSagaErrorHandler },
    { saga: utteranceSaga, errorHandler: genericSagaErrorHandler },
    { saga: monitorSaga, errorHandler: genericSagaErrorHandler },
    { saga: editorSaga, errorHandler: genericSagaErrorHandler },
    { saga: apikeysSaga, errorHandler: genericSagaErrorHandler },
    { saga: interruptSaga, errorHandler: genericSagaErrorHandler },
    { saga: insightsBoardSaga, errorHandler: genericSagaErrorHandler },
    { saga: chartSelectionToolbarSaga, errorHandler: genericSagaErrorHandler },
    { saga: windowSaga, errorHandler: genericSagaErrorHandler },
    { saga: utteranceComposerSaga, errorHandler: genericSagaErrorHandler },
    { saga: benSaga, errorHandler: genericSagaErrorHandler },
    { saga: notificationSaga, errorHandler: genericSagaErrorHandler },
    { saga: organizationSaga, errorHandler: genericSagaErrorHandler },
    { saga: subscriptionsSaga, errorHandler: genericSagaErrorHandler },
    { saga: subscriptionCheckFreeTrial, errorHandler: genericSagaErrorHandler },
    { saga: homeScreenSaga, errorHandler: genericSagaErrorHandler },
    { saga: typedProfilePicturesSaga, errorHandler: genericSagaErrorHandler },
    { saga: initialUtteranceSaga, errorHandler: genericSagaErrorHandler },
    { saga: workspacev2Saga, errorHandler: genericSagaErrorHandler },
    { saga: connectionSaga, errorHandler: genericSagaErrorHandler },
    { saga: viewLineageSaga, errorHandler: genericSagaErrorHandler },
    { saga: drillThroughSaga, errorHandler: genericSagaErrorHandler },
    { saga: expressionsSaga, errorHandler: genericSagaErrorHandler },
    { saga: graphModeSaga, errorHandler: genericSagaErrorHandler },
    { saga: invoiceSaga, errorHandler: genericSagaErrorHandler },
    { saga: dialogSaga, errorHandler: genericSagaErrorHandler },
    { saga: askAvaSaga, errorHandler: genericSagaErrorHandler },
    { saga: dbBrowserSaga, errorHandler: genericSagaErrorHandler },
    { saga: userFeedbackSaga, errorHandler: genericSagaErrorHandler },
    { saga: credentialsSaga, errorHandler: genericSagaErrorHandler },
    { saga: shareSaga, errorHandler: genericSagaErrorHandler },
    { saga: crossFilterSaga, errorHandler: genericSagaErrorHandler },
    { saga: datasetCreatorSaga, errorHandler: genericSagaErrorHandler },
    { saga: dataspaceSaga, errorHandler: genericSagaErrorHandler },
    { saga: chartspaceSaga, errorHandler: genericSagaErrorHandler },
    { saga: QuestionRecommendationsSaga, errorHandler: genericSagaErrorHandler },
    { saga: ObjectivesRecommendationsSaga, errorHandler: genericSagaErrorHandler },
    { saga: ObjectiveRecommendationRegisterEngagementSaga, errorHandler: genericSagaErrorHandler },
    { saga: QuestionRecommendationRegisterEngagementSaga, errorHandler: genericSagaErrorHandler },
    { saga: nodesSaga, errorHandler: genericSagaErrorHandler },
    { saga: dataAssistantSaga, errorHandler: genericSagaErrorHandler },
    { saga: taskSaga, errorHandler: genericSagaErrorHandler },
    { saga: integrationsSaga, errorHandler: genericSagaErrorHandler },
    { saga: oauthSaga, errorHandler: genericSagaErrorHandler },
    { saga: pipelinerDatasetsSaga, errorHandler: genericSagaErrorHandler },
  ];

  // Check whether user is already logged in.
  yield take(REHYDRATE);
  const isAuthenticated = yield select(selectIsAuthenticated);
  if (!isAuthenticated) {
    yield race([take(LOGIN_SUCCESS), take(REGISTER_SUCCESS)]);
  }

  while (true) {
    const loggedInTasks = yield all(spawnSagasList(loggedInSagasList));

    // Cancel all tasks on logout for teardown.
    yield take(LOGOUT_SUCCESS);
    yield cancel(loggedInTasks);
    yield take(LOGIN_SUCCESS);
  }
}
