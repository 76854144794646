import { createSlice } from '@reduxjs/toolkit';
import {
  GetProfilePicturesSuccessPayload,
  GetUserProfilePicturesFailurePayload,
  GetUserProfilePicturesRequestPayload,
  ImagesByUserId,
  UploadProfilePictureFailurePayload,
  UploadProfilePictureRequestPayload,
} from '../../types/profilePictures.types';

type ProfilePicturesState = {
  /** Stores users' profile pictures. */
  profilePictures: ImagesByUserId;
  /** Are the profile pictures loading? */
  isLoading: boolean;
  /** Error information surrounding profile picture upload & retrieval. */
  error: any;
};

const initialState: ProfilePicturesState = {
  profilePictures: {},
  isLoading: false,
  error: null,
};

const profilePicturesSlice = createSlice({
  name: 'profilePictures',
  initialState,
  reducers: {
    /** Action to fetch profile pictures for users in the current user's organization. */
    getOrganizationProfilePicturesRequest: (state: ProfilePicturesState) => {
      state.isLoading = true;
    },

    /** Action to fetch profile pictures for the listed userIds. */
    getUserProfilePicturesRequest: {
      prepare: (payload: GetUserProfilePicturesRequestPayload) => ({ payload }),
      reducer: (state: ProfilePicturesState) => {
        state.isLoading = true;
      },
    },

    /** Action to signal success & store the retrieved profile pictures. */
    getProfilePicturesSuccess: (
      state: ProfilePicturesState,
      { payload }: GetProfilePicturesSuccessPayload,
    ) => {
      const { imagesByUserId } = payload;
      state.profilePictures = {
        ...state.profilePictures,
        ...imagesByUserId,
      };
      state.isLoading = false;
    },

    /** Action to signal failure when retrieving profile pictures. */
    getProfilePicturesFailure: (
      state: ProfilePicturesState,
      { payload }: GetUserProfilePicturesFailurePayload,
    ) => {
      const { error } = payload;
      state.error = error;
      state.isLoading = false;
    },

    /** Action to upload a profile picture. */
    uploadProfilePictureRequest: {
      prepare: (payload: UploadProfilePictureRequestPayload) => ({ payload }),
      reducer: () => {},
    },

    /** Action to signal upload success. */
    uploadProfilePictureSuccess: {
      prepare: () => ({ payload: {} }),
      reducer: () => {},
    },

    /** Action to signal upload failure. */
    uploadProfilePictureFailure: (
      state: ProfilePicturesState,
      { payload }: UploadProfilePictureFailurePayload,
    ) => {
      const { error } = payload;
      state.error = error;
    },
  },
});

export const {
  getOrganizationProfilePicturesRequest,
  getUserProfilePicturesRequest,
  getProfilePicturesSuccess,
  getProfilePicturesFailure,
  uploadProfilePictureRequest,
  uploadProfilePictureSuccess,
  uploadProfilePictureFailure,
} = profilePicturesSlice.actions;

export default profilePicturesSlice.reducer;
