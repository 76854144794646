import { generateFilterText } from '../../../../components/ChartBuilder/Menu/Filter';
import { getDatasetReferenceString } from '../../../../components/ChartData/dataUtils';

// States that the cross filter modal can be in
export const MODAL_INPUT_MODES = {
  ADD: 'addFilter', // Open <Filter /> to add
  EDIT: 'editFilter', // Open <Filter /> to edit
  SCOPE: 'scopeFilter', // Open <CrossFilterScoping />
  NULL: null, // Nothing is opened in right-side of modal
};

// Get the column data from the given publication
export const getColumns = (pub) =>
  pub.chart.data.values?.columns || pub.chart.data.schema?.fields || [];

/**
 * Convert array of columns to dict of format { columnName: columnType }
 * This function assumes that column names are unique
 * @param {Array} columns Array of column objects
 * @returns {Object} Dict of { columnName: columnType }
 */
export const columnsToDict = (columns) =>
  columns.reduce((acc, c) => ({ ...acc, [c.name]: c.type }), {});

/**
 * Get all of the filters that can apply to the underlying dataset
 * Validity is checked by ensuring the filter column name & type match
 * the given column name & type information from the underlying dataset
 * @param {Object} columnsDict Column/type information from the dataset
 * In format { columnName: columnType }
 * @param {{}Array} crossFilterGroupVals Specified cross filter groups to check
 * @returns {{}Array} Valid filter transforms for the given groups
 */
const getValidFilters = (columnsDict = {}, crossFilterGroupVals = []) =>
  crossFilterGroupVals.reduce((acc, group) => {
    const validFilters = group.crossFilters
      .filter((f) => columnsDict[f.transform.column] === f.transform.columnType)
      .map((f) => f.transform);
    return [...acc, ...validFilters];
  }, []);

/**
 * Get the enabled & valid cross filter groups for the specified publication
 * Validity is checked by ensuring that at least one filter within the group
 * can be applied to the underlying dataset
 * @param {Object} columnsDict Column/type information from the dataset
 * In format { columnName: columnType }
 * @param {{}Array} crossFilterGroups Specified cross filter groups
 * @param {Number} publicationId Current publication ID
 */
const getValidFilterGroupsByPub = (columnsDict, crossFilterGroups, publicationId) => {
  const res = Object.entries(crossFilterGroups).filter(([, group]) => {
    // Is the filter group enabled?
    if (!group.enabled) return false;
    // Is the filter group scoped for the current publication?
    if (
      group.excluded_publications?.includes(publicationId) &&
      !group.included_publications?.includes(publicationId)
    ) {
      return false;
    }
    // For the current publication, do we have at least one valid filter within the group? (with type-checking)
    return group.crossFilters?.some(
      (f) => columnsDict[f.transform.column] === f.transform.columnType,
    );
  });

  // { group objects key/value pairs }
  return res.reduce((acc, g) => ({ ...acc, [g[0]]: g[1] }), {});
};

/**
 * Get the enabled & valid cross filters for the specified publication
 * @param {{}Array} columns Column/type information from the dataset
 * @param {{}Array} crossFilterGroups Specified cross filter groups
 * @param {Number} publicationId Current publication ID
 */
export const getValidFiltersByPub = ({ columns = [], crossFilterGroups, publicationId }) => {
  // Convert the array of columns to a dict for algorithm performance
  const columnsDict = columnsToDict(columns);

  // Get the groups that are applied to the current publication
  const validFilterGroups = getValidFilterGroupsByPub(
    columnsDict,
    crossFilterGroups,
    publicationId,
  );

  // Get the filters that are applied to the current publication
  const validFilters = getValidFilters(columnsDict, Object.values(validFilterGroups));

  return { validFilterGroups, validFilters };
};

/**
 * Get all of the publications that are affected by the filter group
 * To get publications that are both scoped and enabled, use getScopedPublications as well
 * @param {Object} crossFilterGroup
 * @param {Array} publications
 */
export const filterEnabledPubs = (crossFilterGroup, publications) =>
  publications.filter(
    (p) =>
      !(
        crossFilterGroup.excluded_publications?.includes(p.publication_id) &&
        !crossFilterGroup.included_publications?.includes(p.publication_id)
      ),
  );

/**
 * Get all of the publications that could potentially be affected by the filter group
 * @param {Object} crossFilterGroup The filter group to check against
 * @param {Array} publications The list of publications to check
 * @returns {Array} The list of publications that could be affected by the filter group
 */
export const filterScopedPubs = (crossFilterGroup, publications) =>
  publications.filter(
    (p) =>
      getValidFilters(columnsToDict(getColumns(p)), [crossFilterGroup]).length > 0 &&
      p.chart.type === 'viz', // ToDo: Remove this when table cross filtering is supported
  );

/**
 * Get the publications that are affected by the filter group
 * @param {Object} crossFilterGroup The filter group to check against
 * @param {Array} publications The list of publications to check
 * @returns {Array} The list of publications that are affected by the filter group
 */
const filterAffectedPubs = (crossFilterGroup, publications) => {
  let res = filterScopedPubs(crossFilterGroup, publications);
  res = filterEnabledPubs(crossFilterGroup, res);
  return res;
};

/**
 * Get the count of publications that are affected by the filter group
 * @param {Object} crossFilterGroup The filter group to check against
 * @param {Array} publications The list of publications to check
 * @returns {Integer} The count of publications that are affected by the filter group
 */
export const getNumAffectedPubs = (crossFilterGroup, publications) =>
  filterAffectedPubs(crossFilterGroup, publications).length;

/**
 * Get the filter names for display in the tooltip
 * Or an error message if there are no filters
 * @param {String} chartType The type of the current chart
 * @param {{}Array} filters The array of filter transform information
 * @param {Boolean} invalidObject Whether or not the object is invalid
 * @param {String} messageType The type of the message (viz, table, note, etc.)
 * @returns {String[]} The cross filter indicator tooltip
 */
export const getIndicatorTooltip = ({ chartType, filters, invalidObject, messageType }) => {
  // Generate an error message for the chart
  if (invalidObject) {
    if (messageType === 'table') return 'Cross filtering is not yet supported for tables';
    if (!chartType) return 'Cross filters could not be applied to this chart';

    const res = `Cross filtering is not yet supported for ${chartType}s`;
    return res.charAt(0).toUpperCase() + res.slice(1);
  }

  // Return the cross filter names
  return filters
    .map((f) => generateFilterText({ column: f.column, expression: f.expression, value: f.value }))
    .join('\n');
};

/**
 * Get the user-facing names from the given publication info
 * @param {{}Array} publications The list of publication objects
 * @returns Array of user-facing publication names
 */
export const getPublicationNames = (publications) =>
  publications.map((p) => {
    const pubType = p.chart.type;
    switch (pubType) {
      case 'viz':
        return p.chart.data.plot?.presentation?.title?.text || '';
      case 'table':
        return `${p.customization?.tableName || p.chart.data?.title || p.chart.data?.name} ${
          p.chart.data?.version
        }`;
      default:
        return pubType;
    }
  });

/**
 * Add the table columns to the publication's data
 * Table publication types do not include column info, so we get it from redux instead
 * @param {Array} publicationData The publication data to update
 * @param {Object} tableData Data retrieved and stored in redux
 * @returns Updated publications containing the column information
 */
export const addTableColumnsToPublicationData = (publicationData, tableData = {}) =>
  publicationData.map((pub) => {
    if (pub.chart?.type !== 'table') return pub;

    const pipelinerDatasetId = pub.pipelineId?.isValid ? pub.pipelineId.String : null;
    const datasetReferenceString = getDatasetReferenceString({ pipelinerDatasetId });

    const { columns, rows } = tableData[datasetReferenceString] || {};
    return {
      ...pub,
      chart: {
        ...pub.chart,
        data: {
          ...pub.chart.data,
          schema: {
            columns,
            rows,
          },
        },
      },
    };
  });
