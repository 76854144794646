export const LOAD_APPS_REQUEST = 'apps/LOAD_APPS_REQUEST';
export const LOAD_APPS_SUCCESS = 'apps/LOAD_APPS_SUCCESS';
export const LOAD_APPS_FAILURE = 'apps/LOAD_APPS_FAILURE';

export const loadAppsRequest = () => ({
  type: LOAD_APPS_REQUEST,
});

/**
 * Indicates that the API call to the server is successful.
 *
 * @param {Array of Objects} param0 An array of objects containing app id, name and icon
 */
export const loadAppsSuccess = ({ apps }) => ({
  type: LOAD_APPS_SUCCESS,
  apps,
});

export const loadAppsFailure = ({ error }) => ({
  type: LOAD_APPS_FAILURE,
  error,
});
