import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { call, getContext, put, select, takeEvery, takeLatest } from 'typed-redux-saga';
import { ReduxSagaContext } from '../../configureStore';
import { API_SERVICES } from '../../constants/api';
import { IntegrationProvider } from '../../types/integrations.types';
import { selectExternalOAuthClientByProvider } from '../selectors/oauth.selector';
import { openDatabaseBrowser } from '../slices/dbBrowser.slice';
import {
  getIntegrationsRequest,
  openIntegrationsMenu,
  openReAuthenticationDialog,
} from '../slices/integrations.slice';
import {
  approveOAuthConsentFailure,
  approveOAuthConsentRequest,
  approveOAuthConsentSuccess,
  getExternalOAuthClientsFailure,
  getExternalOAuthClientsRequest,
  getExternalOAuthClientsSuccess,
  getOAuthClientFailure,
  getOAuthClientRequest,
  getOAuthClientSuccess,
} from '../slices/oauth.slice';
import { selectAccessToken } from './selectors';

export function* getOAuthClientSaga(
  action: PayloadAction<{ clientId: string; redirectUri: string }>,
) {
  try {
    const oauthService = (yield* getContext(
      API_SERVICES.OAUTH,
    )) as ReduxSagaContext[API_SERVICES.OAUTH];

    const accessToken = yield* select(selectAccessToken);
    const response = yield* call(
      oauthService.getOAuthClient,
      accessToken,
      action.payload.clientId,
      action.payload.redirectUri,
    );
    yield put(getOAuthClientSuccess({ client: response.data }));
  } catch (error) {
    if (error instanceof Error) {
      yield put(getOAuthClientFailure({ error: error.message }));
    } else {
      yield put(getOAuthClientFailure({ error: 'An unknown error occurred' }));
    }
  }
}

export function* approveOAuthConsentSaga(
  action: PayloadAction<{ clientId: string; redirectUri: string; state?: string }>,
) {
  try {
    const oauthService = (yield* getContext(
      API_SERVICES.OAUTH,
    )) as ReduxSagaContext[API_SERVICES.OAUTH];

    const accessToken = yield* select(selectAccessToken);
    const redirectURL = yield* call(
      oauthService.approveOAuthConsent,
      accessToken,
      action.payload.clientId,
      action.payload.redirectUri,
      action.payload.state,
    );

    // Dispatch success action with redirect URL
    yield put(
      approveOAuthConsentSuccess({
        redirectURL,
      }),
    );
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      yield put(
        approveOAuthConsentFailure({
          error: error.response.data ?? 'Failed to approve consent',
        }),
      );
    } else {
      yield put(
        approveOAuthConsentFailure({
          error: 'Failed to approve consent',
        }),
      );
    }
  }
}

export function* getExternalOAuthClientsSaga(
  action: PayloadAction<{ provider: IntegrationProvider }>,
) {
  try {
    const oauthService = (yield* getContext(
      API_SERVICES.OAUTH,
    )) as ReduxSagaContext[API_SERVICES.OAUTH];

    const accessToken = yield* select(selectAccessToken);
    const response = yield* call(
      oauthService.getExternalOAuthClientsByProvider,
      accessToken,
      action.payload.provider,
    );
    yield put(
      getExternalOAuthClientsSuccess({ provider: action.payload.provider, clients: response.data }),
    );
  } catch (error) {
    const message = error instanceof Error ? error.message : 'An unknown error occurred';
    yield put(
      getExternalOAuthClientsFailure({ provider: action.payload.provider, error: message }),
    );
  }
}

/** Fetches external clients for each provider and integrations */
export function* fetchAllExternalOAuthClientsAndIntegrationsSaga() {
  yield* put(getIntegrationsRequest());
  for (const provider of Object.values(IntegrationProvider)) {
    const externalOAuthClient = yield* select(selectExternalOAuthClientByProvider, provider);
    if (externalOAuthClient) continue;
    yield* put(getExternalOAuthClientsRequest({ provider }));
  }
}

export default function* watchOAuth() {
  yield* takeLatest(getOAuthClientRequest.type, getOAuthClientSaga);
  yield* takeEvery(approveOAuthConsentRequest.type, approveOAuthConsentSaga);
  yield* takeEvery(getExternalOAuthClientsRequest.type, getExternalOAuthClientsSaga);
  yield* takeLatest(
    [openIntegrationsMenu.type, openDatabaseBrowser.type, openReAuthenticationDialog.type],
    fetchAllExternalOAuthClientsAndIntegrationsSaga,
  );
}
