import {
  GO_TO_INDEX_FAILURE,
  GO_TO_INDEX_REQUEST,
  GO_TO_INDEX_SUCCESS,
  TRY_GO_TO_INDEX_REQUEST,
} from '../actions/header.actions';

export const initialState = {
  isRequesting: false, // Indicates if API request is waiting for a response
  error: undefined, // Error
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GO_TO_INDEX_REQUEST:
      return { ...state, isRequesting: true };
    case GO_TO_INDEX_SUCCESS:
      return { ...state, isRequesting: false };
    case GO_TO_INDEX_FAILURE:
      return { ...state, isRequesting: false, error: action.error };
    case TRY_GO_TO_INDEX_REQUEST:
    default:
      return state;
  }
};
