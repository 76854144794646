import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../configureStore';
import { sampleDatasetRequest } from '../../../../store/actions/dataset.actions';
import { selectSelectedDatasetId } from '../../../../store/selectors/catalog.selector';
import { selectObjectByObjectFk } from '../../../../store/selectors/home_screen.selector';
import { HomeObjectKeys } from '../../../../utils/homeScreen/types';
import './CatalogEditPanel.scss';
import ColumnAnnotationEditor from './ColumnAnnotationEditor';
import DatasetAnnotationEditor from './EditDatasetAnnotation';

export const MAX_DESCRIPTION_CHAR_COUNT: number = 1500;

const CatalogEditPanel = () => {
  const dispatch = useDispatch();
  const selectedDatasetId = useSelector(selectSelectedDatasetId);
  const datasetObject = useSelector((state: RootState) =>
    selectObjectByObjectFk(state, selectedDatasetId ?? ''),
  );
  const pipelinerDatasetId = datasetObject?.[HomeObjectKeys.PIPELINER_DATASET_ID];
  const dcObjectId = datasetObject?.[HomeObjectKeys.UUID];

  useEffect(() => {
    if (pipelinerDatasetId && dcObjectId) {
      dispatch(
        sampleDatasetRequest({
          pipelinerDatasetId,
          numRows: 10,
          dcObjectId,
        }),
      );
    }
  }, [dispatch, pipelinerDatasetId, dcObjectId]);

  if (!selectedDatasetId) {
    return (
      <Box className="CatalogEditPanel">
        <Box className="CatalogEditPanel-Placeholder">
          <Typography variant="h6" fontStyle="italic" color="var(--mui-palette-text-medium);">
            Please Select a Dataset
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box className="CatalogEditPanel">
      <DatasetAnnotationEditor datasetId={selectedDatasetId} />
      <ColumnAnnotationEditor datasetId={selectedDatasetId} />
    </Box>
  );
};

export default CatalogEditPanel;
