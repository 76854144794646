import React, { useMemo } from 'react';
import { BrowserColumn } from '../../../types/databaseBrowser.types';
import Table from '../../DisplayPanel/Charts/Table/Table';

// renders a custom material table that shows
// - table name
// - table type
// - sample rows
interface DatabasePreviewTableProps {
  tableId: string;
  rows: any[][];
  columns: BrowserColumn[];
}

const DatabasePreviewTable: React.FC<DatabasePreviewTableProps> = ({ tableId, rows, columns }) => {
  // transform list of list into list of objects
  const data = useMemo(
    () =>
      rows.map((row) =>
        columns.reduce<{ [key: string]: any }>((acc, col, idx) => {
          acc[col.name] = row[idx];
          return acc;
        }, {}),
      ),
    [rows, columns],
  );

  return (
    <Table
      id={`database-preview-table-${tableId}`}
      data={{
        data,
        title: 'Database Preview',
        schema: {
          fields: columns,
        },
      }}
      rowCount={data.length}
    />
  );
};

export default DatabasePreviewTable;
