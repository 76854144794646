export const TRY_GO_TO_INDEX_REQUEST = 'header/TRY_GO_TO_INDEX_REQUEST';
export const GO_TO_INDEX_REQUEST = 'header/GO_TO_INDEX_REQUEST';
export const GO_TO_INDEX_SUCCESS = 'header/GO_TO_INDEX_SUCCESS';
export const GO_TO_INDEX_FAILURE = 'header/GO_TO_INDEX_FAILURE';

export const tryGoToIndexRequest = ({ isAuthenticated }) => ({
  type: TRY_GO_TO_INDEX_REQUEST,
  isAuthenticated,
});

export const goToIndexRequest = ({ isAuthenticated }) => ({
  type: GO_TO_INDEX_REQUEST,
  isAuthenticated,
});

export const goToIndexSuccess = () => ({
  type: GO_TO_INDEX_SUCCESS,
});

export const goToIndexFailure = ({ error }) => ({
  type: GO_TO_INDEX_FAILURE,
  error,
});
