import { ChartTypes } from 'translate_dc_to_echart';

/**
 * Autofill any inputs as a side-effect of the current input selection which is the
 * { [inputName]: newSelectionValue } pair and is already inserted into the fields object
 * @param {string} chartType The type of chart
 * @param {object} fields The key/value pairs for the chart builder inputs
 * @param {string} inputName The name of the input that was just selected
 * @param {array} newSelectionValue The newly selected value(s) in the input
 * @returns {object} The updated fields object
 */
export const autofillInputs = ({ chartType, fields, inputName, newSelectionValue }) => {
  // { [inputName]: columnName(s) } pairs to be added to the fields object
  const sideEffects = {};

  // Autofill Slice Size input when selecting Split for donut charts
  if (chartType === ChartTypes.donut && inputName === 'split' && !fields.sliceSize) {
    sideEffects.sliceSize = newSelectionValue;
  }

  // Autofill y-axis input when selecting x-axis for histograms
  // (y-axis input is hidden from the user)
  if (chartType === ChartTypes.histogram && inputName === 'x-axis') {
    sideEffects['y-axis'] = newSelectionValue;
  }

  return { ...fields, ...sideEffects };
};
