import PropTypes from 'prop-types';
import React from 'react';
import DataChatOutlinedInput from '../../common/DataChatOutlinedInput';

/**
 * A functional component to render the footer (caption) for visuals
 */
const VisualCaption = (props) => {
  const { messageType, update, isInsightsBoard } = props;

  // get caption content from data
  const getCaption = () => {
    switch (messageType) {
      case 'viz':
        return update?.spec?.caption || update?.caption;
      case 'table':
        return update?.spec?.caption;
      default:
        return undefined;
    }
  };

  return (
    <>
      {getCaption() && (
        <div
          className={`ChartFooter${isInsightsBoard ? ' ib-formatting' : ''}`}
          data-cy="Visual-Caption"
        >
          <button aria-label="Visual-Caption">
            <DataChatOutlinedInput multiline value={getCaption()} disabled />
          </button>
        </div>
      )}
    </>
  );
};

VisualCaption.propTypes = {
  messageType: PropTypes.string.isRequired,
  update: PropTypes.object,
  isInsightsBoard: PropTypes.bool,
};

VisualCaption.defaultProps = {
  update: null,
  isInsightsBoard: false,
};

export default VisualCaption;
