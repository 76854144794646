import axios, { AxiosResponse } from 'axios';
import { nl2codeEndpoints } from '../constants/endpoints';
import { LLMQueryType } from '../constants/recommendations';
import {
  ObjectivesRecommendation,
  QuestionRecommendation,
} from '../store/slices/recommendations.slice';

export interface RecommendationsPayload {
  query_type: LLMQueryType;
  payload: {
    user_id: number;
    session_id: string;
    datasets: {
      name: string;
      version: number;
    }[];
    refresh?: boolean;
    no_update?: boolean;
    force_regenerate?: boolean;
  };
}

export interface RecommendationsResponse {
  error_code: string;
  message: string;
  recommendations: QuestionRecommendation[] | ObjectivesRecommendation[];
  status: number;
  recommendation_types?: string[];
}

export interface RecommendationsRegisterEngagementPayload {
  session_id: string;
  user_id: number;
  recommendation_type: string;
  datasets: {
    name: string;
    version: number;
  }[];
  records: {
    factors: string[];
    kpi: string;
    relevance: number;
    title: string;
    type: string;
    utterance: string;
  }[];
}
export interface QuestionRecommendationEngagementPayload {
  session_id: string;
  user_id: number;
  recommendation_type: string;
  records: QuestionRecommendation[];
  datasets: {
    name: string;
    version: number;
  }[];
}

export interface RecommendationsRegisterEngagementResponse {
  error_code: string;
  message: string;
  status: number;
}

export const getRecommendations = (
  accessToken: string,
  sessionId: string,
  payload: RecommendationsPayload,
): Promise<AxiosResponse<RecommendationsResponse>> => {
  if (payload.query_type === LLMQueryType.recommendObjectives) {
    const params = {
      sessionId: payload.payload.session_id,
      user_id: payload.payload.user_id,
      payload: JSON.stringify(payload.payload),
      query_type: payload.query_type,
      refresh: payload.payload.refresh,
    };
    return axios.get(nl2codeEndpoints.queryLlm, {
      headers: { Authorization: `Bearer ${accessToken}` },
      params,
    });
  }

  return axios.post(nl2codeEndpoints.queryLlm, payload, {
    headers: { Authorization: `Bearer ${accessToken}` },
    params: {
      sessionId,
    },
  });
};

export const recommendationsRegisterEngagement = (
  accessToken: string,
  sessionId: string,
  userId: number,
  payload: RecommendationsRegisterEngagementPayload | QuestionRecommendationEngagementPayload,
): Promise<AxiosResponse<RecommendationsRegisterEngagementResponse>> => {
  return axios.post(nl2codeEndpoints.registerEngagement, payload, {
    headers: { Authorization: `Bearer ${accessToken}` },
    params: {
      sessionId,
      user_id: userId,
    },
  });
};
