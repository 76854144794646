import Box from '@mui/material/Box';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedDatasetId } from '../../../../store/selectors/catalog.selector';
import { selectDataset } from '../../../../store/slices/catalog.slice';
import {
  HomeObjectKeys,
  HomeObjectKeysTypes,
  HomeObjects,
} from '../../../../utils/homeScreen/types';
import { getCatalogColumns, SortOrders } from '../../../GenericMaterialTable/column_utils';
import GenericMaterialTable from '../../../GenericMaterialTable/GenericMaterialTable';
import { useSearchLoadObjects } from '../../../LoadCard/load_card_hooks';
import SearchBar from '../../../SearchBar/SearchBar';
import './CatalogNavigationMenu.scss';

const CatalogNavigationMenu = () => {
  const dispatch = useDispatch();
  const selectedDatasetId = useSelector(selectSelectedDatasetId);
  const { searchedObjects, handleSearch } = useSearchLoadObjects([
    HomeObjects.DATASET,
    HomeObjects.FOLDER,
  ]);
  const handleGetColumns = React.useCallback((order: SortOrders) => getCatalogColumns(order), []);
  const handleRowClick = React.useCallback(
    (row: HomeObjectKeysTypes, e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      if (row[HomeObjectKeys.TYPE] !== HomeObjects.DATASET) return;
      if (!row[HomeObjectKeys.OBJECT_FK]) return;
      dispatch(selectDataset(row[HomeObjectKeys.OBJECT_FK]));
    },
    [dispatch],
  );

  const selectedRows = selectedDatasetId ? [{ id: `Dataset-${selectedDatasetId}` }] : [];
  return (
    <Box className="CatalogNavigationMenu-Container">
      <SearchBar
        className="SearchBar"
        handleSearchSubmit={handleSearch}
        handleSearchChange={handleSearch}
      />
      <Box className="CatalogNavigationMenu-Table scroll">
        <GenericMaterialTable
          childProperty="Parent"
          getColumns={handleGetColumns}
          handleRowClick={handleRowClick}
          handleRowDblClick={() => {}}
          parentProperty="uuid"
          rowData={searchedObjects}
          selectedRows={selectedRows}
          maxBodyHeight="100%"
          minBodyHeight="100%"
        />
      </Box>
    </Box>
  );
};

export default CatalogNavigationMenu;
