import { getContext, put, select, takeEvery } from 'redux-saga/effects';
import { API_SERVICES } from '../../constants/api';
import {
  GET_USERS_IN_ORGANIZATION_REQUEST,
  getUsersInOrganizationFailure,
  getUsersInOrganizationSuccess,
} from '../actions/organization.action';
import {
  GET_USER_RECORD_FAILURE,
  GET_USER_RECORD_SUCCESS,
  getUserRecordRequest,
} from '../actions/settings.actions';
import { raceGenerator } from './home_screen.saga';
import { selectOrganizationID, selectOrganizationName } from './selectors';
import { retry401 } from './utils/retry';

// Returns the users organization id and name.
export function* getDefaultOrganizationDetails() {
  let organizationId = yield select(selectOrganizationID);
  let organizationName = yield select(selectOrganizationName);

  if (organizationId === 0 || !organizationName) {
    yield put(getUserRecordRequest());
    yield raceGenerator(GET_USER_RECORD_SUCCESS, GET_USER_RECORD_FAILURE);
    organizationId = yield select(selectOrganizationID);
    organizationName = yield select(selectOrganizationName);
  }
  return { organizationId, organizationName };
}

/**
  Dispatch action to fetch users in organization corresponding to the organization id.
*/
export function* getUsersInOrganizationRequestWorker() {
  try {
    const { organizationId } = yield getDefaultOrganizationDetails();

    const insightsBoardService = yield getContext(API_SERVICES.INSIGHTS_BOARD);
    const getUsersInOrganization =
      insightsBoardService.getUsersInOrganization.bind(insightsBoardService);

    const response = yield retry401(getUsersInOrganization, organizationId);

    yield put(getUsersInOrganizationSuccess({ id: organizationId, data: response.data }));
  } catch (error) {
    yield put(getUsersInOrganizationFailure({ error }));
  }
}

// Add logging to root saga
export default function* organizationSaga() {
  yield takeEvery(GET_USERS_IN_ORGANIZATION_REQUEST, getUsersInOrganizationRequestWorker);
}
