import Fuse from 'fuse.js';
import { FUSE_OPTIONS } from '../../constants/home_screen';
import { HomeObjectKeys, HomeObjectKeysTypes, HomeObjects } from '../../utils/homeScreen/types';

// Extend the HomeObjectKeysTypes to include the tableData property from GenericMaterialTable
type HomeObjectKeysTypesWithTableData = HomeObjectKeysTypes & {
  tableData?: Record<string, HomeObjectKeysTypes[]>;
};

/**
 * Filter home objects by parameters:
 *   keepObjectTypes are the object types to keep in the result
 *   keepObjectText is the text to filter the object names by (fuzzy search)
 */
export const filterObjects = ({
  keepObjectText,
  keepObjectTypes = Object.values(HomeObjects),
  objects,
}: {
  keepObjectText?: string | null;
  keepObjectTypes?: string[];
  objects: HomeObjectKeysTypes[];
}): HomeObjectKeysTypes[] => {
  const typeFilteredObjects = objects.filter((obj) =>
    keepObjectTypes.includes(obj[HomeObjectKeys.TYPE]),
  );

  if (!keepObjectText) return typeFilteredObjects;

  const fuse = new Fuse(typeFilteredObjects, FUSE_OPTIONS);
  return fuse.search(keepObjectText).map((result) => {
    return { ...result.item, score: result.score };
  });
};

/**
 * Get the relationships from the selected object recursively
 * Supports getting the children or grandparents of the object
 *
 * tableData is a property that is added by MaterialTable to indicate nesting
 * We depend on this property when available, but otherwise we can use allFolders to find ancestors
 */
export const getRelationsRecursively = ({
  allFolders, // Folder-nesting that is indicative of parent-child relationships
  allObjects, // A list of all objects in the table which may or may not include relationship information
  object, // The object of interest
  relation, // The relationship to recurse on (children or grandparents)
}: {
  allFolders: Record<string, HomeObjectKeysTypesWithTableData[]>;
  allObjects: HomeObjectKeysTypesWithTableData[];
  object: HomeObjectKeysTypesWithTableData;
  relation: 'children' | 'grandparents';
}): HomeObjectKeysTypes[] => {
  let result: HomeObjectKeysTypes[] = [];
  if (relation === 'children' && object.tableData?.childRows) {
    object.tableData.childRows.forEach((child) => {
      result = [
        ...result,
        child,
        ...getRelationsRecursively({ allFolders, allObjects, object: child, relation: 'children' }),
      ];
    });
  } else if (relation === 'children' && allFolders[object[HomeObjectKeys.UUID]]) {
    allFolders[object[HomeObjectKeys.UUID]].forEach((child) => {
      result = [
        ...result,
        child,
        ...getRelationsRecursively({ allFolders, allObjects, object: child, relation: 'children' }),
      ];
    });
  } else if (
    relation === 'grandparents' &&
    'Parent' in object &&
    object[HomeObjectKeys.PARENT_ID]
  ) {
    const parentStr = object[HomeObjectKeys.PARENT_ID];
    const parentObj = allObjects.find((o) => o.uuid === parentStr);
    if (parentObj) {
      result = [
        parentObj,
        ...getRelationsRecursively({
          allFolders,
          allObjects,
          object: parentObj,
          relation: 'grandparents',
        }),
      ];
    }
  } else if (relation === 'grandparents' && object[HomeObjectKeys.PARENT_ID]) {
    const parentObj = allObjects.find(
      (obj) => obj[HomeObjectKeys.UUID] === object[HomeObjectKeys.PARENT_ID],
    );
    if (parentObj) {
      result = [
        parentObj,
        ...getRelationsRecursively({
          allFolders,
          allObjects,
          object: parentObj,
          relation: 'grandparents',
        }),
      ];
    }
  }
  return result;
};

/**
 * Filter objects by object types
 *
 * @param folderObjects - a dictionary mapping folder id to children objects
 * @param objects - a list of valid object types
 * @returns a flat list of objects that match the object types
 */
export const filterObjectsByType = (
  folderObjects: Record<string, HomeObjectKeysTypes[]>,
  objectTypes: string[],
): HomeObjectKeysTypes[] =>
  Object.entries(folderObjects).reduce((acc, [, objects]) => {
    const filteredFolderObjects = filterObjects({
      keepObjectTypes: objectTypes,
      objects,
    });
    return [...acc, ...filteredFolderObjects];
  }, [] as HomeObjectKeysTypes[]);

/**
 * Searches the list of given objects based on the search text
 * @param searchText - The text that we are looking for
 * @param folderObjects - a dictionary mapping folder id to children objects
 * @param objects - a flat list of all objects
 * @returns A filtered list of objects that match the search text
 */
export const searchObjects: (
  searchText: string,
  folderObjects: Record<string, HomeObjectKeysTypesWithTableData[]>,
  objects: HomeObjectKeysTypes[],
) => HomeObjectKeysTypes[] = (searchText, folderObjects, objects) => {
  const filteredObjects = filterObjects({
    keepObjectText: searchText,
    objects,
  });

  // If the search text is empty, return
  if (!searchText) return filteredObjects;

  // If the search text is non-empty, get the ancestors of the matched objects
  const ancestors = filteredObjects
    .map((object) => {
      const childrenObjects = getRelationsRecursively({
        allFolders: folderObjects,
        allObjects: objects,
        object,
        relation: 'children',
      });

      const grandparentObjects = getRelationsRecursively({
        allFolders: folderObjects,
        allObjects: objects,
        object,
        relation: 'grandparents',
      });

      return [object, ...childrenObjects, ...grandparentObjects];
    })
    .flat();

  // Remove duplicates
  return ancestors.filter(
    (value, index, self) => self.findIndex((t) => t.id === value.id) === index,
  );
};
