import axios from 'axios';
import { endpoints } from '../constants/endpoints';

// get all llm credentials
export const getLlmCredentials = (accessToken) =>
  axios.get(endpoints.llmConnection, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

// create an llm credential
export const createLlmCredential = (accessToken, payload) =>
  axios.post(endpoints.llmConnection, payload, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

// delete an llm credential by its id
export const deleteLlmCredential = (accessToken, connectionId) =>
  axios.delete(`${endpoints.llmConnection}/${connectionId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

// Check if a connection name exists
export const checkLlmCredentialExists = (accessToken, connectionName) =>
  axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
      connectionName,
    },
    method: 'HEAD',
    url: endpoints.llmConnection,
  });

// Get a llm credential by its id
export const getLlmCredentialById = (accessToken, connectionId) =>
  axios.get(`${endpoints.llmConnection}/${connectionId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

// set the default credential
export const setDefaultLlmCredential = (accessToken, connectionId) =>
  axios.post(
    `${endpoints.llmConnection}/default/${connectionId}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

// remove the default credential
export const removeDefaultLlmCredential = (accessToken, connectionId) =>
  axios.delete(`${endpoints.llmConnection}/default/${connectionId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
