import axios, { AxiosResponse } from 'axios';
import { EventChannel } from 'redux-saga';
import { Aggregate, Bin, ChartSpec, Presentation, Series, Transform } from 'translate_dc_to_echart';
import { chartingServiceEndpoints, dcAppServiceEndpoints, endpoints } from '../constants/endpoints';
import { ChartRenderParams } from '../types/render.types';
import { handleDCAppServiceCall } from '../utils/errorHandling/errorHandlers.api';
import { SSEClient } from './SSEClient';

interface ShareChartParams {
  accessToken: string;
  sessionId: string;
  objectId: string;
  destination: string;
}

interface GetChartWorkflowParams {
  accessToken: string;
  dcChartId: string;
}

interface ModifyChartParams {
  accessToken: string;
  aggregate: Aggregate;
  bins: Bin[];
  caption: string;
  dataSampleLimit: number;
  insightsBoardId: string;
  presentation: Presentation;
  publicationId: number;
  series: Series;
  transforms: Transform[];
}

interface PatchChartParams {
  accessToken: string;
  dcChartId: string;
  dcDatasetId: string;
  sessionId: string;
}

export interface GetChartResponse {
  type: string;
  typeVersion: number;
  render: ChartSpec;
  progress: number;
}

export const shareChart = async ({
  accessToken,
  sessionId,
  objectId,
  destination,
}: ShareChartParams): Promise<AxiosResponse<any>> => {
  return handleDCAppServiceCall(() =>
    axios.post(
      dcAppServiceEndpoints.shareChart,
      { destination },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        params: {
          sessionId,
          objectId,
        },
      },
    ),
  );
};

export const getChartWorkflow = async ({
  accessToken,
  dcChartId,
}: GetChartWorkflowParams): Promise<AxiosResponse<any>> => {
  return axios.get(endpoints.getChartWorkflow(dcChartId), {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });
};

export const modifyChart = async ({
  accessToken,
  aggregate,
  bins,
  caption,
  dataSampleLimit,
  insightsBoardId,
  presentation,
  publicationId,
  series,
  transforms,
}: ModifyChartParams): Promise<AxiosResponse<any>> => {
  return axios.post(
    endpoints.modifyChart(publicationId),
    JSON.stringify({
      aggregate,
      bins,
      caption,
      dataSampleLimit,
      insightsBoardId,
      presentation,
      series,
      transforms,
    }),
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    },
  );
};

export const patchChart = async ({
  accessToken,
  dcChartId,
  dcDatasetId,
  sessionId,
}: PatchChartParams): Promise<AxiosResponse<any>> => {
  return axios.patch(
    endpoints.chartById(dcChartId),
    {
      dc_dataset_id: dcDatasetId,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      params: {
        session: sessionId,
      },
    },
  );
};

export interface ChartRenderSSEMessage {
  message: string;
  progress?: number;
  data?: ChartSpec;
}

/**
 * Fetch a chart from the charting service using dc_chart_id
 */
export function getChartRender(params: ChartRenderParams): EventChannel<ChartRenderSSEMessage> {
  const url = chartingServiceEndpoints.chartImage(params.dcChartId);

  // Create an object of query parameters
  const queryObject = {
    client_id: params.apiKey,
    secret: params.apiSecret,
    renderer: params.renderer,
    publicationId: params.publicationId,
    sessionId: params.sessionId,
    workspaceUuid: params.workspaceUuid,
    insightsBoardId: params.insightsBoardId,
    width: params.width,
    height: params.height,
  };

  return SSEClient.connectWithQueryParams(url, queryObject);
}

export function putChartRender(params: ChartRenderParams): Promise<AxiosResponse> {
  const url = chartingServiceEndpoints.chartImage(params.dcChartId);
  return axios.put(url, {
    headers: {
      ...(params.accessToken && { Authorization: `Bearer ${params.accessToken}` }),
    },
    params: {
      client_id: params.apiKey,
      secret: params.apiSecret,
      renderer: params.renderer,
      publicationId: params.publicationId,
      sessionId: params.sessionId,
      workspaceUuid: params.workspaceUuid,
      insightsBoardId: params.insightsBoardId,
      width: params.width,
      height: params.height,
    },
  });
}
