import isEmpty from 'lodash/isEmpty';
import mapKeys from 'lodash/mapKeys';
import moment from 'moment';
import { cancel, fork, take } from 'redux-saga/effects';
import { KEYBOARD_KEYS } from '../constants';
import { DATE_FORMATS } from '../constants/date';
import {
  SAVE_UNNAMED_SESSION,
  SUBMIT_BUTTON_KEY,
  WORKFLOW_INVALID_FILE_NAME,
} from '../constants/dialog.constants';
import {
  ACTION_TYPES,
  DEFAULT_FILTER_OPTION,
  HOME_OBJECTS,
  HOME_OBJECT_ACTIVE_NAMES,
  HOME_OBJECT_KEYS,
  LAST_ACTIVE_FILTER_OPTIONS,
  NUMBER_OF_ACTIONS_IN_HEADER,
  REQUEST_STATUS,
  STRING_CONSTANTS,
} from '../constants/home_screen';
import { VALID_WORKFLOW_NAME } from '../constants/name_validation';
import { paths } from '../constants/paths';
import { ROOT_FOLDER, WORKSPACE_ACCESS_TYPES, WORKSPACE_VISIBILITY } from '../constants/workspace';
import { openAlertDialog } from '../store/actions/dialog.actions';
import { dialogInputChange, setContextMenuAnchor } from '../store/actions/home_screen.actions';
import {
  selectDefaultHomeObjects,
  selectSearchFilters,
  selectSelectedFilters,
  selectSelectedTab,
} from '../store/sagas/selectors';
import { RequestStatus } from '../types/databaseBrowser.types';
import { formatTime, getCurrentTime } from './date';
import { HomeObjectActions, ObjectActionsByType } from './homeScreen/homeScreenActions';

// inject store so we can access redux from this file
// ----------------------------------------------------
let store;
export const injectStoreToHomeScreenUtils = (_store) => {
  store = _store;
};
// ----------------------------------------------------

export const openDialog = ({ title, placeholder, clickEvent, objectName, closeEvent }) => {
  store.dispatch(dialogInputChange({ value: objectName }));
  const clearDialogValue = () => store.dispatch(dialogInputChange({ value: '' }));
  const closeCurrentDialog = () => {
    closeEvent();
    clearDialogValue();
  };
  const buttons = [
    { label: 'Cancel', key: 'create-cancel', onClick: () => closeCurrentDialog() },
    {
      label: 'Submit',
      key: SUBMIT_BUTTON_KEY,
      onClick: () => clickEvent(),
      props: {
        disabled: () => {
          const state = store.getState();
          return state.homeScreen.dialogValue === objectName;
        },
      },
    },
  ];
  store.dispatch(
    openAlertDialog({
      title,
      dialogType: SAVE_UNNAMED_SESSION,
      dialogProps: {
        onKeyDown: (event) => {
          switch (event.key) {
            case KEYBOARD_KEYS.ENTER:
              /*
              buttons[i].key will eventually be set to that HTML button's data-cy attribute, and we
              use that fact to perform the next check.

              The DOM would look something like this:
              <button ... data-cy={buttons[i].key} />
              */
              if (
                event.target.localName === 'button' &&
                buttons.some((btn) => event.target.attributes['data-cy']?.value === btn.key)
              )
                break;

              clickEvent();
              break;
            case KEYBOARD_KEYS.ESC:
              closeCurrentDialog();
              break;
            default:
              break;
          }
        },
        fullWidth: true,
        maxWidth: 'xs',
      },
      inputs: [
        {
          type: 'TextField',
          key: 'workflow_name_validation',
          inputValidationContext: {
            regexValidationPattern: VALID_WORKFLOW_NAME,
            errorMessage: WORKFLOW_INVALID_FILE_NAME,
          },
          props: {
            placeholder,
            defaultValue: objectName,
            autoFocus: true,
            required: true,
            fullWidth: true,
            margin: 'normal',
          },
          onChange: (e) => store.dispatch(dialogInputChange({ value: e.target.value })),
        },
      ],
      buttons,
    }),
  );
};

export const sortDcObjects = (a, b, sortOrder, sortVariable) => {
  const orderMultiplier = sortOrder === 'desc' ? -1 : 1;
  if (
    a[HOME_OBJECT_KEYS.TYPE] === HOME_OBJECTS.FOLDER &&
    b[HOME_OBJECT_KEYS.TYPE] !== HOME_OBJECTS.FOLDER
  ) {
    return -1 * orderMultiplier;
  } else if (
    a[HOME_OBJECT_KEYS.TYPE] !== HOME_OBJECTS.FOLDER &&
    b[HOME_OBJECT_KEYS.TYPE] === HOME_OBJECTS.FOLDER
  ) {
    return 1 * orderMultiplier;
  }
  const varA =
    typeof a[sortVariable] === 'string' ? a[sortVariable].toLowerCase() : a[sortVariable];
  const varB =
    typeof b[sortVariable] === 'string' ? b[sortVariable].toLowerCase() : b[sortVariable];
  return varA < varB ? -1 : 1;
};

export const sortDcObjectsByScore = (a, b) => {
  return a.score > b.score ? 1 : -1;
};

export const getAllHomeObjectActions = () => {
  return Object.values(HomeObjectActions);
};

/** used to translate REQUEST_STATUS to the typescript enum RequestStatus */
const translateRequestStatus = (oldRequestStatus) => {
  const isTypescriptEnum = Object.values(RequestStatus).includes(oldRequestStatus);
  if (isTypescriptEnum) return oldRequestStatus;
  switch (oldRequestStatus) {
    case REQUEST_STATUS.REFRESH:
      return RequestStatus.Refresh;
    case REQUEST_STATUS.REQUESTED:
      return RequestStatus.Requesting;
    case REQUEST_STATUS.SUCCESS:
      return RequestStatus.Success;
    case REQUEST_STATUS.FAILURE:
      return RequestStatus.Failure;
    default:
      return RequestStatus.Unrequested;
  }
};

// Refresh indicates we failed to fetch data
export const isStatusFailed = (status) => translateRequestStatus(status) === RequestStatus.Failure;

// Refresh indicates we have requested (and e.g, should not re-request)
export const isStatusRequesting = (status) => {
  const statusToCheck = translateRequestStatus(status);
  return (
    statusToCheck === RequestStatus.Requesting || statusToCheck === RequestStatus.RequestingMore
  );
};

export const isStatusRefreshing = (status) =>
  translateRequestStatus(status) === RequestStatus.Refresh;

export const isStatusRequestingMore = (status) => {
  const statusToCheck = translateRequestStatus(status);
  return statusToCheck === RequestStatus.RequestingMore;
};

// Refresh indicates we have not requested
export const isStatusUnrequested = (status) =>
  translateRequestStatus(status) === RequestStatus.Unrequested;

// Refresh indicates we have data but we're requesting again
export const isStatusSuccess = (status) => translateRequestStatus(status) === RequestStatus.Success;

// Check objectRequestStatus for all success
export const areAllObjectsRefreshingOrReceived = (objectRequestStatus) => {
  const state = store.getState();
  const defaultHomeObjects = selectDefaultHomeObjects(state);
  return Array.from(defaultHomeObjects).every((objectType) => {
    const status = objectRequestStatus[objectType];
    return isStatusSuccess(status) || isStatusRefreshing(status);
  });
};

// Returns the name of the key wrt to objectType
export const getHomeObjectKeyName = (key, objectType) => {
  /* If objectType is workflow or insights boards, then the last active name should be last opened
     since we considered last open in insights boards and workflows as last active.
  */
  if (objectType === HOME_OBJECTS.RECIPE || objectType === HOME_OBJECTS.INSIGHTS_BOARD) {
    if (key === HOME_OBJECT_KEYS.LAST_ACTIVE) {
      return STRING_CONSTANTS.LAST_OPENED;
    }
  }
  return HOME_OBJECT_ACTIVE_NAMES[key];
};

// Returns the Home Object Type based on table rowData
export const getRowType = (rowData) => {
  if (!rowData) {
    return '';
  }
  const { [HOME_OBJECT_KEYS.TYPE]: type } = rowData;
  return type;
};

// this custom take function will only cancel a task if there is already
// one forked with the same objec type.
// https://redux-saga.js.org/docs/api/#takelatestpattern-saga-args
export const takeLatestByObjectType = (patternOrChannel, saga, ...args) =>
  fork(function* handleTaskByObjectType() {
    // hold a reference to each forked saga identified by the type property
    const lastTasks = {};

    while (true) {
      const action = yield take(patternOrChannel);

      // if there is a forked saga running with the same object type, cancel it.
      if (lastTasks[action.objectType]) {
        yield cancel(lastTasks[action.objectType]);
      }

      lastTasks[action.objectType] = yield fork(saga, ...args.concat(action));
    }
  });

// Get object name, adding email if shared with the user
export const getQualifiedObjectName = (object) =>
  `${object[HOME_OBJECT_KEYS.NAME]}${
    object[HOME_OBJECT_KEYS.IS_SHARED] ? `[${object[HOME_OBJECT_KEYS.OWNER_EMAIL]}]` : ''
  }`;

export const getRowId = (object, tableObjectType) => {
  const { [HOME_OBJECT_KEYS.TYPE]: objectType, [HOME_OBJECT_KEYS.ID]: objectId } = object;
  return `hs-objects-${tableObjectType}-${objectType}-${objectId}`;
};

export const getOpenActionToolTip = (type) => {
  switch (type) {
    case HOME_OBJECTS.CONNECTION:
      return `Load ${type}`;
    default:
      return `Open ${type}`;
  }
};

export const isHomeScreen = () => {
  const currentLocation = window.location.pathname;
  // Added this because search bar was not showing up on /web?dev_mode=true
  const devWebpath = '/web';
  const validPaths = [paths.home, paths.homeScreenTabs, devWebpath];
  const isHome = validPaths.some((path) => currentLocation === path);
  return isHome;
};

/**
 * returns the breadcrumb (folder tree) for folders opened from
 * anywhere in the homescreen
 * @param {Array} folders list of all folders user has access to
 * @param {Object} currentFolder folder the user is trying to open
 * @param {Array} currentBreadcrumb the current breadcrumb from redux
 * @returns
 */

export const getFolderBreadcrumb = (folders, currentFolder, currentBreadcrumb) => {
  // get the uuid and the parent of the folder
  const { [HOME_OBJECT_KEYS.UUID]: uuid, Parent: parent } = currentFolder;
  // if the current folder is the root folder
  if (currentFolder === ROOT_FOLDER) {
    return [ROOT_FOLDER];
  }
  // parent of current folder is undefined (ROOT_FOLDER)
  if (parent === undefined) {
    return [ROOT_FOLDER, currentFolder];
  }
  // initialize a new Breadcrumb
  let newBreadcrumb = [...currentBreadcrumb];
  // create a list for all uuids in breadcrumb
  const uuidList = newBreadcrumb.map((f) => f[HOME_OBJECT_KEYS.UUID]);
  // position of current folder uuid in list
  const uuidIndex = uuidList.indexOf(uuid);
  // position of current folder parent in list
  const parentIndex = uuidList.indexOf(parent);
  //
  if (uuidIndex < 0 && parentIndex < 0) {
    // case: neither the current folder or its parent are in the list
    // init a new folder stack
    newBreadcrumb = [currentFolder];
    // take the list of all current folders and convert to object with
    // uuids as the keys
    const folderObjects = mapKeys(folders, HOME_OBJECT_KEYS.UUID);
    // create breadcrumb
    while (newBreadcrumb[0].Parent) {
      // in the case of shared folders, nested in unshared folders
      // (folders parent is not in the folder list)
      if (folderObjects[newBreadcrumb[0].Parent] === undefined) break;
      newBreadcrumb.unshift(folderObjects[newBreadcrumb[0].Parent]);
    }
    // unshift the Root folder to the stack
    newBreadcrumb.unshift(ROOT_FOLDER);
    return newBreadcrumb;
  } else if (uuidIndex < 0 && parentIndex >= 0) {
    // case: the current folder's parent is in list, but not current folder
    newBreadcrumb.push(currentFolder);
    return newBreadcrumb;
  }
  // case: both current folder and parent are in list
  return newBreadcrumb.slice(0, uuidIndex + 1);
};

/**
 * Filters home screen objects based on visibility.
 * @param {Array[Object]} objectData // list of dc objects
 * @param {Boolean} showHiddenObjects // indicates whether hidden objects should be displayed
 * This function is used to filter down the home screen objects by
 *  visibility in My Work tab and rest of the tabs.
 */
export const filterHiddenDcObjects = (objectData, showHiddenObjects) => {
  return showHiddenObjects
    ? objectData
    : objectData?.filter(
        (obj) => obj[HOME_OBJECT_KEYS.VISIBILITY] === WORKSPACE_VISIBILITY.VISIBLE,
      );
};

// Object Table Actions

/**
 * returns the correct actions to display in the context menu, depending,
 * on the selectObject properies.
 * if the user clicks the "More options" button in the table header,
 * the ACTION_TYPE will be SPECIFIC_ACTIONS
 * if the user right clicks a featured card or homescreenObjectTable row, return all actions.
 * @param {String} objectType // Type of the object.
 * @param {String} actionType // Action type which is altered to display specific actions or all actions on the context menu.
 */
export const getObjectActions = (objectType, actionType) => {
  const state = store.getState();
  const selectedTab = selectSelectedTab(state);
  const allActions = [...ObjectActionsByType[objectType]];
  if (
    selectedTab === HOME_OBJECTS.ALL &&
    actionType !== ACTION_TYPES.FEATURED_ACTIONS &&
    objectType !== HOME_OBJECTS.SESSION
  )
    allActions.push(HomeObjectActions.MOVE);
  if (actionType === ACTION_TYPES.SPECIFIC_ACTIONS) {
    return allActions.slice(1 + NUMBER_OF_ACTIONS_IN_HEADER);
  }
  return allActions.slice(1);
};

export const openObjectContextMenu = (e) => {
  e.preventDefault();
  store.dispatch(
    setContextMenuAnchor({
      contextMenuAnchor: {
        left: e.clientX,
        top: e.clientY,
      },
    }),
  );
};

// Gets the object filter value.
// The filter value switches between the filters of search tab and rest of the tabs.
// My Work tab does not have filters.
/**
 * Returns the filter value corresponding to the filter type and selected tab.
 * @param {String} filterType // Filter type.
 */
export const getObjectFilterValue = (filterType) => {
  const state = store.getState();
  const selectedTab = selectSelectedTab(state);

  const filterObject =
    selectedTab === HOME_OBJECTS.SEARCH ? selectSearchFilters(state) : selectSelectedFilters(state);

  return filterObject?.[filterType];
};

// Checks if altest one of the filter property has a non empty value apart from DEFAULT_FILTER_OPTION
// By Default the Visibility Filters is set to Hide Hidden Objects
export const isNotEmptyFilters = (filtersObject) => {
  if (!isEmpty(filtersObject)) {
    return Object.values(filtersObject).some(
      (filter) => filter && filter !== DEFAULT_FILTER_OPTION,
    );
  }
  return false;
};

// Checks if filters are ON, on the corresponding selected tab.
export const areFiltersOn = (selectedTab) => {
  const state = store.getState();
  switch (selectedTab) {
    case HOME_OBJECTS.SEARCH: {
      const searchFilters = selectSearchFilters(state);
      return isNotEmptyFilters(searchFilters);
    }
    case HOME_OBJECTS.ALL: {
      return false;
    }
    default: {
      const selectedFilters = selectSelectedFilters(state);
      return isNotEmptyFilters(selectedFilters);
    }
  }
};

// Returns past or present date appropriate corresponding to the LAST_ACTIVE_FILTER_OPTIONS from constants/home_screen.js
export const getFilteredEndTime = (filterValue) => {
  switch (filterValue) {
    case LAST_ACTIVE_FILTER_OPTIONS.LAST_YEAR:
      return moment().subtract(1, 'year').endOf('year').format(DATE_FORMATS.YYYYMMDD);
    default:
      return getCurrentTime(DATE_FORMATS.YYYYMMDD);
  }
};

// Returns past or present date appropriate corresponding to the LAST_ACTIVE_FILTER_OPTIONS from constants/home_screen.js
export const getFilteredStartTime = (filterValue) => {
  switch (filterValue) {
    case LAST_ACTIVE_FILTER_OPTIONS.LAST_7_DAYS:
      return moment().subtract(7, 'days').format(DATE_FORMATS.YYYYMMDD);
    case LAST_ACTIVE_FILTER_OPTIONS.LAST_30_DAYS:
      return moment().subtract(30, 'days').format(DATE_FORMATS.YYYYMMDD);
    case LAST_ACTIVE_FILTER_OPTIONS.THIS_YEAR:
      return moment().startOf('year').format(DATE_FORMATS.YYYYMMDD);
    case LAST_ACTIVE_FILTER_OPTIONS.LAST_YEAR:
      return moment().subtract(1, 'year').startOf('year').format(DATE_FORMATS.YYYYMMDD);
    default:
      return getCurrentTime(DATE_FORMATS.YYYYMMDD);
  }
};

/**
 * Compares if the date given is between start time and end time (start and end time are included)
 * @param {*} date // String which is used to determine the set of actions rendered
 * @param {*} startTime // Start time
 * @param {*} endTime // End time
 */
const checkIfTimeIsBetweenTwoTimes = (time, startTime, endTime) => {
  return moment(time).isBetween(startTime, endTime, undefined, '[]'); // Start Date and End Date are included in the comparision
};

/**
 * Returns the filtered list of objects by applying the filters.
 * @param {Array[Objects]} objects // Array of specific home screen objects
 * @param {Object} filtersObject // Filters object which can be selectedFilters or searchFilters from home screen reducer.
 * Run filters should always filter the hidden objects since the default visibility is set
 * to hide hidden objects.
 */
export const runFilters = (objects, filtersObject) => {
  // Filter according to workspace visibility filter
  // Had to filter here first because the default case for visibility is to hide hidden objects.
  let results =
    filtersObject?.[HOME_OBJECT_KEYS.VISIBILITY] === DEFAULT_FILTER_OPTION
      ? filterHiddenDcObjects(objects, false)
      : objects;

  // Check if filters are on (except visibility filter which is handled above)
  // Had to do this because, this filter function doesn't consider the case where visibility has default value,
  // which is hide hidden dc objects.
  if (isNotEmptyFilters(filtersObject)) {
    // If Last Active filter is active fetch the start and endtime for time comparision.
    let startTime = '';
    let endTime = '';
    if (
      filtersObject?.[HOME_OBJECT_KEYS.LAST_ACTIVE] &&
      filtersObject?.[HOME_OBJECT_KEYS.LAST_ACTIVE] !== DEFAULT_FILTER_OPTION
    ) {
      startTime = getFilteredStartTime(filtersObject[HOME_OBJECT_KEYS.LAST_ACTIVE]);
      endTime = getFilteredEndTime(filtersObject[HOME_OBJECT_KEYS.LAST_ACTIVE]);
    }

    if (!isEmpty(results)) {
      // Perform Filter Operation
      results = results.filter((object) => {
        // Used every to consider all the filter value types
        return Object.entries(filtersObject).every(([filterType, filterValue]) => {
          // If filter has value and filter is not the default filter and filter is not visibility filter
          // We are ruling out visibility filter here because we already had filtered objects based on visibility filter above
          if (
            filterValue &&
            filterValue !== DEFAULT_FILTER_OPTION &&
            filterType !== HOME_OBJECT_KEYS.VISIBILITY
          ) {
            if (filterType === HOME_OBJECT_KEYS.LAST_ACTIVE) {
              return checkIfTimeIsBetweenTwoTimes(
                formatTime(object[HOME_OBJECT_KEYS.LAST_ACTIVE], DATE_FORMATS.YYYYMMDD),
                startTime,
                endTime,
              );
            }
            return object[filterType].toString() === filterValue.toString();
          }
          // Returns true if the filter is the default filter or it is the visibility filter.
          return true;
        });
      });
    }
  }
  return results || [];
};

/**
 * takes the search value and search filters and returns the search
 * param string
 * @param {string} searchValue
 * @param {{[key: string]: string}} searchFilters
 * @returns a search params string
 */
export const getSearchParams = (searchValue, searchFilters) => {
  const searchParams = searchValue ? { term: searchValue } : {};
  Object.keys(searchFilters).forEach((filter) => {
    // Check if filter exists and filter is not a default filter
    if (searchFilters[filter] && searchFilters[filter] !== DEFAULT_FILTER_OPTION) {
      searchParams[filter] = searchFilters[filter];
    }
  });
  const searchParamsString = new URLSearchParams(searchParams).toString();
  return searchParamsString;
};

/**
 * Replace %20 or + with a space.
 * @param {String | null} value
 * @returns {String | undefined}
 */
export const decodeUrlSearchParam = (value) => {
  return value?.replaceAll(/(%20|[+])/g, ' ');
};

/**
 * Takes in the query parameter (object) and replaces + with " ".
 * @param {URLSearchParams} urlParams
 * @returns {{[key: string]: string}}
 */
export const getSearchFiltersFromURLSearchParams = (urlParams) => {
  const state = store.getState();
  const searchFiltersObject = selectSearchFilters(state);
  const cleanQuery = { ...searchFiltersObject };
  Object.keys(urlParams).forEach((key) => {
    if (key !== 'term' && key !== 'tab') {
      cleanQuery[key] = decodeUrlSearchParam(urlParams.get(key));
    }
  });
  return cleanQuery;
};

export const getFileExtension = (fileName) => {
  return fileName.split('.').at(-1);
};

export const convertDcObjectToHomeScreenObject = (dcObject, objectType) => {
  return {
    [HOME_OBJECT_KEYS.ID]: dcObject.ObjectFk,
    [HOME_OBJECT_KEYS.TABLE_ID]: `${objectType}-${dcObject.ObjectFk}`,
    [HOME_OBJECT_KEYS.UUID]: dcObject.Uuid,
    [HOME_OBJECT_KEYS.ACCESS_TYPE]: dcObject.AccessType,
    [HOME_OBJECT_KEYS.NAME]: dcObject.Name,
    [HOME_OBJECT_KEYS.TYPE]: objectType,
    [HOME_OBJECT_KEYS.OWNER_NAME]: dcObject.OwnerName,
    [HOME_OBJECT_KEYS.OWNER_EMAIL]: dcObject.OwnerEmail,
    [HOME_OBJECT_KEYS.OWNER_ID]: dcObject.Owner,
    [HOME_OBJECT_KEYS.IS_SHARED]: dcObject.AccessType !== WORKSPACE_ACCESS_TYPES.OWNER,
    [HOME_OBJECT_KEYS.CREATED]: new Date(dcObject.CreateInstant),
    [HOME_OBJECT_KEYS.LAST_ACTIVE]: new Date(dcObject.AccessInstant),
    [HOME_OBJECT_KEYS.LAST_MODIFIED]: new Date(dcObject.UpdateInstant),
    [HOME_OBJECT_KEYS.VISIBILITY]: dcObject.Visibility,
    [HOME_OBJECT_KEYS.HAS_INDEPENDENT_ACCESS]: dcObject.HasIndependentAccess,
    [HOME_OBJECT_KEYS.READ_ONLY]: dcObject.ReadOnly,
    [HOME_OBJECT_KEYS.EXAMPLE_FK]: !!dcObject.ExampleFK,
  };
};
