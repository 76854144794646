import { HTMLElement, parse, TextNode } from 'node-html-parser';

interface SkillButton {
  label: string;
  disabled: boolean;
  key: string;
}

export interface MessageObject {
  text: string;
  skillButtons: SkillButton[];
}

const parseElement = (
  element: HTMLElement | TextNode | Node,
  textMessage: string = '',
  skillButtons: SkillButton[] = [],
): {
  textMessage: string;
  skillButtons: SkillButton[];
} => {
  if (element instanceof HTMLElement) {
    // if the element is an HTMLElement, we switch over the
    // rawTagName to see if it's a skill link or modify skill link
    switch (element.rawTagName) {
      case 'dc-modify-skill-link':
      case 'dc-skill-link':
        // if it's a skill link, we add the button to the skillButtons array
        // and return the updated skillButtons array
        skillButtons.push({
          label: element.textContent || '',
          disabled: false,
          key: element.getAttribute('command') || '',
        });
        return { textMessage, skillButtons };
      default:
        break;
    }
  }
  if (element.childNodes.length > 0) {
    // if the element has child nodes, we recursively call this function
    element.childNodes.forEach((child) => {
      ({ textMessage } = parseElement(child, textMessage, skillButtons));
    });
  }
  if (element instanceof TextNode && element.childNodes.length === 0) {
    // if the element is a text node and has no child nodes, we add the text to the textMessage
    textMessage += element.textContent;
  }
  // return the textMessage and skillButtons
  return { textMessage, skillButtons };
};

export const getMessageObjectFromText = (message: string): MessageObject => {
  const parsedMessage = parse(message);
  const { textMessage, skillButtons } = parseElement(parsedMessage);
  return { text: textMessage.trim(), skillButtons };
};
