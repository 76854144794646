import PropTypes from 'prop-types';
import React from 'react';

// A functional component to render a line break conditionally
// after the specified buttons in the 'More options' dropdown
const LineBreak = (props) => {
  const { isInsightsBoard, isPopOutChart, messageType, prevComponentName } = props;

  const shouldInsertLineBreak = () => {
    // Should we insert the line break after the popOutButton?
    if (prevComponentName === 'SliderButton') {
      return messageType === 'viz' && isInsightsBoard && !isPopOutChart;
    }

    // Return false if we're not inserting a line break at the current position
    return false;
  };

  return shouldInsertLineBreak() && <hr style={{ margin: '5px 0' }} />;
};

LineBreak.propTypes = {
  // Are we on an IB?
  isInsightsBoard: PropTypes.bool,
  // Is this object being viewed in a larger window/pop out modal?
  isPopOutChart: PropTypes.bool,
  // The type of the object (viz, table, note, etc.)
  messageType: PropTypes.string.isRequired,
  // The name of the component before the line break
  prevComponentName: PropTypes.string,
};

LineBreak.defaultProps = {
  isInsightsBoard: false,
  isPopOutChart: false,
  prevComponentName: '',
};

export default LineBreak;
