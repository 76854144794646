import {
  CLEAR_UTTERANCES_PREVIEW,
  GET_SESSION_PREVIEW_SUCCESS,
  GET_WORKFLOW_PREVIEW_SUCCESS,
} from '../actions/utterances_preview.actions';

export const initialState = {
  workflows: {},
  sessions: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_WORKFLOW_PREVIEW_SUCCESS:
      return {
        ...state,
        workflows: {
          ...state.workflows,
          [action.workflowId]: {
            data: action.data,
            name: action.name,
            latestVersion: action.latestVersion,
          },
        },
      };
    case GET_SESSION_PREVIEW_SUCCESS:
      return {
        ...state,
        sessions: {
          ...state.sessions,
          [action.sessionId]: { data: action.userMessages, name: action.sessionName },
        },
      };
    case CLEAR_UTTERANCES_PREVIEW:
      return initialState;
    default:
      return state;
  }
};
