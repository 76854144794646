import axios from 'axios';

import { PublicationActions, PublicationTypes } from '../constants/embed';
import { endpoints } from '../constants/endpoints';

interface PublicationParams {
  pubId: string | number;
  pubType: PublicationTypes;
  refreshId?: number;
  apiKey?: string;
  apiSecret?: string;
  accessToken?: string;
}

interface PublicationActionParams extends PublicationParams {
  actionType: PublicationActions;
  data?: Record<string, unknown>;
}

interface PublicationActionResultParams extends Omit<PublicationParams, 'refreshId'> {
  actionId: string;
  actionType: PublicationActions;
}

/**
 * Fetch a publication from the server
 * @param pubId - The ID of the publication
 * @param pubType - The type of the publication (see PublicationTypes in embed.js)
 * @param refreshId - The ID of the refresh NOTE: Specific to embedded chart usage
 * @param apiKey - The API key
 * @param apiSecret - The API secret
 * @param accessToken - The access token
 * @returns The publication
 */
export const getPublication = ({
  pubId,
  pubType,
  refreshId,
  apiKey,
  apiSecret,
  accessToken,
}: PublicationParams) => {
  let url = `${endpoints.embed}/${pubType}/${pubId}`;
  if (refreshId !== undefined) url += `?refresh_id=${refreshId}`;

  if (apiKey && apiSecret) {
    // User is viewing a shareable link (this should be the default)
    return axios.get(url, {
      params: {
        client_id: apiKey,
        secret: apiSecret,
      },
    });
  }

  // User is logged in
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};

/**
 * Spins up a worker to perform an action on a publication.
 * @param pubId - The ID of the publication
 * @param pubType - The type of the publication (see PublicationTypes in embed.js)
 * @param actionType - The type of the action to run on the publication (see PublicationActionTypes
 * in embed.js)
 * @param data (optional) - Input for running an action. Defaults to an empty javascript object.
 * @param apiKey - The API key
 * @param apiSecret - The API secret
 * @param accessToken - The access token
 * @returns The result of the action
 */
export const postPublicationAction = ({
  pubId,
  pubType,
  actionType,
  data = {},
  apiKey,
  apiSecret,
  accessToken,
}: PublicationActionParams) => {
  if (apiKey && apiSecret) {
    // User is viewing a shareable link (this should be the default)
    return axios.post(`${endpoints.embed}/${pubType}/${pubId}/${actionType}`, data, {
      params: {
        client_id: apiKey,
        secret: apiSecret,
      },
    });
  }

  // User is logged in
  return axios.post(`${endpoints.embed}/${pubType}/${pubId}/${actionType}`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};

/**
 * Retrieves the result of a publication action.
 * @param pubId - The ID of the publication
 * @param pubType - The type of the publication (see PublicationTypes in embed.js)
 * @param actionId - The ID of the action
 * @param actionType - The type of the action to run on the publication (see PublicationActionTypes
 * in embed.js)
 * @param apiKey - The API key
 * @param apiSecret - The API secret
 * @param accessToken - The access token
 * @returns The result of the action
 */
export const getPublicationActionResult = ({
  pubId,
  pubType,
  actionId,
  actionType,
  apiKey,
  apiSecret,
  accessToken,
}: PublicationActionResultParams) => {
  if (apiKey && apiSecret) {
    // User is viewing a shareable link (this should be the default)
    return axios.get(`${endpoints.embed}/${pubType}/${pubId}/${actionType}/${actionId}`, {
      params: {
        client_id: apiKey,
        secret: apiSecret,
      },
    });
  }

  // User is logged in
  return axios.get(`${endpoints.embed}/${pubType}/${pubId}/${actionType}/${actionId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};
