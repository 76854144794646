import axios from 'axios';
import { dcAppServiceEndpoints } from '../constants/endpoints';
import { handleDCAppServiceCall } from '../utils/errorHandling/errorHandlers.api';

export const getAllDefinedExpressions = (accessToken, sessionId, datasets) =>
  handleDCAppServiceCall(() =>
    axios.get(dcAppServiceEndpoints.allDefinedExpressions, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        sessionId,
        datasets,
      },
    }),
  );

export const getDefinedExpressions = (accessToken, sessionId, dataset) =>
  handleDCAppServiceCall(() =>
    axios.get(dcAppServiceEndpoints.definedExpressions, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        sessionId,
        dataset,
      },
    }),
  );

export const getSuggestedExpressions = (accessToken, sessionId, dataset) =>
  handleDCAppServiceCall(() =>
    axios.get(dcAppServiceEndpoints.suggestedExpressions, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        sessionId,
        dataset,
      },
    }),
  );

export const getTemporalExpressionSuggestions = (accessToken, sessionId, partialExpr) =>
  handleDCAppServiceCall(() =>
    axios.get(dcAppServiceEndpoints.temporalExpressionSuggestions, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: { sessionId, partialExpr },
    }),
  );

export const isValidTemporalExpression = (accessToken, sessionId, expr) =>
  handleDCAppServiceCall(() =>
    axios.get(dcAppServiceEndpoints.isValidTemporalExpression, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: { sessionId, expr },
    }),
  );
