import axios from 'axios';
import qs from 'qs';
import { endpoints } from '../constants/endpoints';

/**
 * A GET HTTP request for retrieving the user's file directory.
 *
 * @param {String} accessToken The access token
 */
export const getFiles = (accessToken) =>
  axios.get(endpoints.file, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

/**
 * A DELETE HTTP request for deleting a list of files specified by the user.
 *
 * @param {String} accessToken The access token
 * @param {Array} files List of strings containing the file names to be deleted
 * @param {Boolean} check Check if files have been shared with other users
 */
export const deleteFiles = (accessToken, files, check = false) =>
  axios.delete(endpoints.file, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: { data: files, check },
    // Override default behavior if check check is true.
    validateStatus: (status) =>
      check ? (status >= 200 && status < 300) || status === 409 : status >= 200 && status < 300,
    // Produces query string in the form: data=file0&data=file1&...&data=fileN
    paramsSerializer: { serialize: (params) => qs.stringify(params, { indices: false }) },
  });

/**
 * A POST HTTP request for uploading profile picture to the DataChat file system server
 *
 * @param {String} accessToken // The access token
 * @param {File} file // Profile Picture File
 */
export const uploadProfilePicture = (accessToken, file) => {
  const formData = new FormData();
  formData.append('profile_picture', file);

  return axios.post(endpoints.profilePicture, formData, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'multipart/form-data',
    },
  });
};

/**
 * A GET HTTP request for retreving the profile picture uuids
 *  for the entire organization of the current user.
 *
 * @param {string} accessToken The user's access token
 * @returns {import('../types/file_manager.types').GetProfilePictureFileNamesResponse}
 */
export const getOrganizationProfilePictureFileNames = (accessToken) =>
  axios.get(`${endpoints.profilePictures}/organization`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });

/**
 * A GET HTTP request for retreving the userId, profile picture filename pairs.
 *
 * @param {string} accessToken The user's access token
 * @param {number[]} userIds List of user ids to get profile pictures for
 * @returns {import('../types/file_manager.types').GetProfilePictureFileNamesResponse}
 */
export const getUserProfilePictureFileNames = (accessToken, userIds) =>
  axios.get(`${endpoints.profilePictures}/users`, {
    headers: { Authorization: `Bearer ${accessToken}` },
    params: { user_ids: JSON.stringify(userIds) },
  });

export const deleteFileById = (accessToken, fileId) =>
  axios.delete(`${endpoints.file}/${fileId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
