import { REDUCER_METRICS_NAMES } from '../../constants/subscriptions';
import {
  ADD_PAYMENT_METHOD_FAILURE,
  ADD_PAYMENT_METHOD_REQUEST,
  ADD_PAYMENT_METHOD_SUCCESS,
  CANCEL_SUBSCRIPTION_FAILURE,
  CANCEL_SUBSCRIPTION_REQUEST,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CLOSE_ADD_PAYMENT_DIALOG,
  DELETE_OBJECT_FROM_USAGE_STATS_FAILURE,
  DELETE_OBJECT_FROM_USAGE_STATS_REQUEST,
  DELETE_OBJECT_FROM_USAGE_STATS_SUCCESS,
  DISABLE_PAYMENT_POPUP_FAILURE,
  DISABLE_PAYMENT_POPUP_REQUEST,
  DISABLE_PAYMENT_POPUP_SUCCESS,
  ENTER_STRIPE,
  EXIT_CHOOSE_SUBSCRIPTION,
  EXIT_STRIPE,
  GET_CURRENT_SUBSCRIPTION_FAILURE,
  GET_CURRENT_SUBSCRIPTION_REQUEST,
  GET_CURRENT_SUBSCRIPTION_SUCCESS,
  GET_PAYMENT_METHODS_FAILURE,
  GET_PAYMENT_METHODS_REQUEST,
  GET_PAYMENT_METHODS_SUCCESS,
  GET_SUBSCRIPTIONS_FAILURE,
  GET_SUBSCRIPTIONS_REQUEST,
  GET_SUBSCRIPTIONS_SUCCESS,
  GET_SUBSCRIPTION_CUSTOMER_ID_FAILURE,
  GET_SUBSCRIPTION_CUSTOMER_ID_REQUEST,
  GET_SUBSCRIPTION_CUSTOMER_ID_SUCCESS,
  GET_SUBSCRIPTION_PRICE_FAILURE,
  GET_SUBSCRIPTION_PRICE_REQUEST,
  GET_SUBSCRIPTION_PRICE_SUCCESS,
  GET_USAGE_STATS_FAILURE,
  GET_USAGE_STATS_REQUEST,
  GET_USAGE_STATS_SUCCESS,
  OPEN_ADD_PAYMENT_DIALOG,
  OPEN_CHOOSE_SUBSCRIPTION,
  SET_SELECTED_PAYMENT_METHOD,
  SET_SUBSCRIPTION_VARS,
  SET_USAGE_STATS_LOADING_STATUS,
  START_FREE_TIER_FAILURE,
  START_FREE_TIER_REQUEST,
  START_FREE_TIER_SUCCESS,
  SUBMIT_PAYMENT_FAILURE,
  SUBMIT_PAYMENT_REQUEST,
  SUBMIT_PAYMENT_SUCCESS,
  UPDATE_SUBSCRIPTION_FAILURE,
  UPDATE_SUBSCRIPTION_REQUEST,
  UPDATE_SUBSCRIPTION_SUCCESS,
} from '../actions/subscriptions.actions';

export const initialState = {
  disablePaymentOptionsPopup: false,
  isRequesting: false,
  isRequestingSubscriptions: false,
  isCancellingSubscription: false, // Boolean that is true when api is processing cancel request
  isRequestingPrice: false,
  error: undefined,
  inStripe: false,
  chooseSubscription: false,
  subscriptions: null,
  productPrice: 0,
  productName: '',
  trialValid: false,
  yearly: false,
  details: null, // the features / details of the selected subscription
  isUsageStatsLoading: false,
  usageStats: {
    sessionLimitUsageStats: {},
    cellCountUsageStats: {},
    nl2CodeUsageStats: {},
  },
  subscriptionCustomerId: '',
  selectedPaymentMethod: null, // the currently selected payment method - becomes default after sub update
  amountDue: null, // the estimated amount due for a subscription change
  subscriptionProcessing: false, // boolean indicating whether a user is updating / creating a subscription.
  paymentDialogOpen: false, // controls visibility of payment dialog
  paymentMethodProcessing: false, // boolean indicating whether a user is adding payment method
  paymentMethods: null, // object containing a user's payment methods
  currentSubscription: null, // the users current subscriptions
  isRequestingCurrentSubscription: false, // boolean indicating whether we are requesting the current subscription
  currentSubscriptionError: null, // error from getting current subscription
  isExempt: false, // whether the user is exempted or not
  isExemptRequesting: false, // whether we are requesting the exempt status
  isExemptError: undefined, // error from getting exempt status
  // Whether we have attempted to start a free trial.  Kept track of to avoid requesting multiple times
  freeTierAttempted: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DISABLE_PAYMENT_POPUP_REQUEST:
      return {
        ...state,
        isRequesting: true,
      };
    case DISABLE_PAYMENT_POPUP_SUCCESS:
      return {
        ...state,
        disablePaymentOptionsPopup: action.disablePaymentOptionsPopup,
        isRequesting: false,
        error: undefined,
      };
    case DISABLE_PAYMENT_POPUP_FAILURE:
      return {
        ...state,
        error: action.error,
        isRequesting: false,
      };
    case ENTER_STRIPE:
      return {
        ...state,
        inStripe: true,
      };
    case EXIT_STRIPE:
      return {
        ...state,
        inStripe: false,
      };
    case OPEN_CHOOSE_SUBSCRIPTION:
      return {
        ...state,
        chooseSubscription: true,
      };
    case EXIT_CHOOSE_SUBSCRIPTION:
      return {
        ...state,
        chooseSubscription: false,
      };
    case SET_SUBSCRIPTION_VARS:
      return {
        ...state,
        productPrice: action.productPrice,
        productName: action.productName,
        trialValid: action.trialValid,
        yearly: action.yearly,
        details: action.details,
      };
    case GET_SUBSCRIPTIONS_REQUEST:
      return {
        ...state,
        isRequestingSubscriptions: true,
      };
    case GET_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        subscriptions: action.subscriptions?.subscriptions,
        isRequestingSubscriptions: false,
        error: undefined,
      };
    case GET_SUBSCRIPTIONS_FAILURE:
      return {
        ...state,
        error: action.error,
        isRequestingSubscriptions: false,
      };

    case GET_USAGE_STATS_REQUEST: {
      return {
        ...state,
        isUsageStatsLoading: true,
      };
    }

    case GET_USAGE_STATS_FAILURE: {
      return {
        ...state,
        isUsageStatsLoading: false,
      };
    }

    case SET_USAGE_STATS_LOADING_STATUS: {
      return {
        ...state,
        isUsageStatsLoading: action.status,
      };
    }

    case GET_USAGE_STATS_SUCCESS: {
      const usageMetric = REDUCER_METRICS_NAMES[action.metric];
      return {
        ...state,
        usageStats: {
          ...state.usageStats,
          [usageMetric]: action.usageData,
        },
      };
    }

    case DELETE_OBJECT_FROM_USAGE_STATS_REQUEST: {
      return {
        ...state,
        isUsageStatsLoading: true,
      };
    }

    case DELETE_OBJECT_FROM_USAGE_STATS_SUCCESS: {
      return {
        ...state,
        isUsageStatsLoading: false,
      };
    }

    case DELETE_OBJECT_FROM_USAGE_STATS_FAILURE: {
      return {
        ...state,
        isUsageStatsLoading: false,
      };
    }

    case GET_SUBSCRIPTION_CUSTOMER_ID_REQUEST:
      return {
        ...state,
        isExemptRequesting: true,
        isExemptError: undefined,
      };

    case GET_SUBSCRIPTION_CUSTOMER_ID_SUCCESS:
      return {
        ...state,
        subscriptionCustomerId: action.customerId,
        isExempt: action.isExempt,
        isExemptRequesting: false,
      };

    case GET_SUBSCRIPTION_CUSTOMER_ID_FAILURE:
      return {
        ...state,
        isExemptRequesting: false,
        isExemptError: action.error,
      };

    case GET_CURRENT_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        isRequestingCurrentSubscription: true,
      };

    case GET_CURRENT_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        currentSubscription: action.subscription,
        currentSubscriptionError: null,
        isRequestingCurrentSubscription: false,
      };

    case GET_CURRENT_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        currentSubscriptionError: action.error,
        isRequestingCurrentSubscription: false,
      };

    case UPDATE_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        subscriptionProcessing: true,
      };

    case UPDATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscriptionProcessing: false,
      };

    case UPDATE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        error: action.error,
        subscriptionProcessing: false,
      };

    case GET_SUBSCRIPTION_PRICE_REQUEST:
      return {
        ...state,
        isRequestingPrice: true,
      };

    case GET_SUBSCRIPTION_PRICE_SUCCESS:
      return {
        ...state,
        amountDue: action.amountDue,
        isRequestingPrice: false,
      };

    case GET_SUBSCRIPTION_PRICE_FAILURE:
      return {
        ...state,
        error: action.error,
        isRequestingPrice: false,
      };

    case SUBMIT_PAYMENT_REQUEST:
      return {
        ...state,
        subscriptionProcessing: true,
      };

    case SUBMIT_PAYMENT_SUCCESS:
      return {
        ...state,
        subscriptionProcessing: false,
      };

    case SUBMIT_PAYMENT_FAILURE:
      return {
        ...state,
        subscriptionProcessing: false,
        error: action.error,
      };

    case START_FREE_TIER_REQUEST:
      return {
        ...state,
        subscriptionProcessing: true,
        freeTierAttempted: true,
      };

    case START_FREE_TIER_SUCCESS:
      return {
        ...state,
        subscriptionProcessing: false,
        disablePaymentOptionsPopup: true,
      };

    case START_FREE_TIER_FAILURE:
      return {
        ...state,
        subscriptionProcessing: false,
        error: action.error,
      };

    case OPEN_ADD_PAYMENT_DIALOG:
      return {
        ...state,
        paymentDialogOpen: true,
      };

    case CLOSE_ADD_PAYMENT_DIALOG:
      return {
        ...state,
        paymentDialogOpen: false,
      };

    case ADD_PAYMENT_METHOD_REQUEST:
      return {
        ...state,
        paymentMethodProcessing: true,
      };

    case ADD_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        paymentMethodProcessing: false,
        paymentMethods: {
          ...state.paymentMethods,
          [action.paymentMethod.id]: action.paymentMethod,
        },
      };

    case ADD_PAYMENT_METHOD_FAILURE:
      return {
        ...state,
        error: action.error,
        paymentMethodProcessing: false,
      };

    case GET_PAYMENT_METHODS_REQUEST:
      return {
        ...state,
        paymentMethodProcessing: true,
      };

    case GET_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        paymentMethods: action.paymentMethods,
        paymentMethodProcessing: false,
      };

    case GET_PAYMENT_METHODS_FAILURE:
      return {
        ...state,
        error: action.error,
        paymentMethodProcessing: false,
      };

    case SET_SELECTED_PAYMENT_METHOD:
      return {
        ...state,
        selectedPaymentMethod: action.paymentMethodId,
      };

    case CANCEL_SUBSCRIPTION_REQUEST:
      return { ...state, isCancellingSubscription: true };

    case CANCEL_SUBSCRIPTION_SUCCESS:
      return { ...state, isCancellingSubscription: false };

    case CANCEL_SUBSCRIPTION_FAILURE:
      return { ...state, isCancellingSubscription: false, error: action.error };

    default:
      return state;
  }
};
