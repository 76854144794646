import Typography from '@mui/material/Typography';
import React from 'react';

export const URL_SYMBOL_START = '<a>';
export const URL_SYMBOL_END = '</a>';
const DEFAULT_ERROR_MESSAGE = 'There was an error processing your request. Please try again later.';

interface ErrorMessageProps {
  errorMessage: string; // error must contain URL_SYMBOL_START and URL_SYMBOL_END to be replaced with a link
  docLink?: string;
  alertTextProps?: object;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ errorMessage, docLink, alertTextProps }) => {
  if (!errorMessage) {
    // Note: we should never reach this case, but if we don't provide an error message we will show this default message
    return <Typography data-testid="ErrorMessageText">{DEFAULT_ERROR_MESSAGE}</Typography>;
  }
  const [startMessage, rest] = errorMessage.split(URL_SYMBOL_START);
  const [urlString, endMessage] = rest ? rest.split(URL_SYMBOL_END) : ['', ''];

  // Return the error message with the link
  if (
    startMessage.length > 0 &&
    rest?.length > 0 &&
    rest.includes(URL_SYMBOL_END) &&
    urlString?.length > 0
  ) {
    return (
      <Typography data-testid="ErrorMessageText" {...alertTextProps}>
        {startMessage}
        <a style={{ color: 'inherit' }} href={docLink} target="_blank" rel="noopener noreferrer">
          {urlString}
        </a>
        {endMessage}
      </Typography>
    );
  }

  return <Typography data-testid="ErrorMessageText">{errorMessage}</Typography>;
};

ErrorMessage.defaultProps = {
  docLink: '',
  alertTextProps: {},
};

export default ErrorMessage;
