import { useCallback, useEffect, useState } from 'react';
import { DEFAULT_CLEANING_ALERT } from 'translate_dc_to_echart';
import { EDIT_MODES } from '../../constants';
import { ALERT_TIMEOUT } from './constants';
import { shouldOpenChartBuilder } from './utils';

/**
 * NOTE: We must use memoization/useCallback() for functions which we create and
 * return in our custom hooks. If not, we will create new function references
 * when mousing-over the chart, which triggers unwanted updates in DCPlotV2
 * Refer to https://github.com/DataChatAI/datachat/issues/33229
 */

/**
 * A custom hook used for alert info logic in SessionStateProvider and IBStateProvider
 *
 * @returns alert state and state mutate functions
 */
export const useAlertInfo = () => {
  // if alert banner is open
  const [alertOpen, setAlertOpen] = useState(false);
  // alert banner text
  const [alertText, setAlertText] = useState(null);
  // If the update has new information for the user
  const [freshUpdate, setFreshUpdate] = useState(false);

  // Wait 6s, then close the alert popup (can be reopened with alertButton)
  useEffect(() => {
    let timer;

    if (alertText && alertText !== DEFAULT_CLEANING_ALERT && freshUpdate) {
      timer = setTimeout(() => {
        setFreshUpdate(false);
        setAlertOpen(false);
      }, ALERT_TIMEOUT);
    }

    return () => clearTimeout(timer);
  }, [freshUpdate, alertText]);

  /**
   * Set our clean data prompt and conditionally show the banner
   * @param {String} prompt The cleaning information
   * @param {Boolean} isFreshUpdate Did we just save an edit?
   */
  const openCaptionAlert = useCallback((prompt, isFreshUpdate) => {
    setAlertText(prompt);

    // Decide if we want to show the alert banner after translation
    if (prompt && prompt !== DEFAULT_CLEANING_ALERT && isFreshUpdate) {
      setAlertOpen(true);
      setFreshUpdate(true);
    }

    return new Promise((resolve) => {
      resolve(prompt);
    });
  }, []);
  return [alertOpen, alertText, openCaptionAlert, setAlertOpen];
};

/**
 * A custom hook used for edit info logic in SessionStateProvider and IBStateProvider
 *
 * @param {*} data the data passed for visual elements
 * @param {*} isPopOutChart if it is in PopOut window
 * @param {String} messageType the type of message ('viz' for charts)
 * @param {Number} messageTypeVersion the version of message (2 for ECharts)
 * @param {*} setEditingModal
 * @returns edit state and state mutate functions
 */
export const useEditingInfo = (
  data,
  isPopOutChart,
  messageType,
  messageTypeVersion,
  setEditingModal,
  update,
) => {
  const [chartEditingMode, setChartEditingMode] = useState(false);
  const [showEditMenu, setShowEditMenu] = useState(false);
  const [keepChanges, setKeepChanges] = useState(false);
  const [openIBFontMenu, setOpenIBFontMenu] = useState(false);

  /**
   * Toggles the value of chartEditingMode
   * @param {boolean} keepChanges if true edits are saved, if false edits are canceled
   * If keepChanges parameter is left undefined, keepChanges is reset to false.
   *
   * Edit mode specifies which part of the chart the
   * user wants to edit (chart itself or the caption).
   * If the type is 'CloseAll', all of the editable menus will close.
   *
   */
  const toggleChartEditingMode = useCallback(
    (saveChanges, editMode) => {
      if (isPopOutChart) {
        // Toggling edit mode, so it should be false if we're about to enter it
        // Tell our popOutModal if we're editing the chartBuilder
        setEditingModal(
          shouldOpenChartBuilder({
            data,
            chartEditingMode: !chartEditingMode,
            messageType,
            messageTypeVersion,
          }),
        );
      }

      setKeepChanges(saveChanges);
      setChartEditingMode((prevMode) => !prevMode);

      // Show edit menu or show caption footer
      // If CloseAll is specified, the edit menus will close.
      setShowEditMenu(editMode === EDIT_MODES.Chart);
    },
    // We must include update here so that edit actions propagate
    // our changes to the translation layer
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, update, isPopOutChart, setEditingModal, chartEditingMode],
  );

  // for plotly chart in IB font menu
  const toggleIBFontMenu = useCallback((saveFontChanges) => {
    setKeepChanges(saveFontChanges);
    setOpenIBFontMenu((prevOpen) => !prevOpen);
  }, []);

  // resets all editing variables to their initial state
  const resetEditingMenu = () => {
    setChartEditingMode(false);
    setShowEditMenu(false);
    setKeepChanges(false);
    setOpenIBFontMenu(false);
  };

  return {
    chartEditingMode,
    showEditMenu,
    keepChanges,
    resetEditingMenu,
    toggleChartEditingMode,
    openIBFontMenu,
    toggleIBFontMenu,
  };
};

/**
 * A custom hook used for export as png logic in SessionStateProvider and IBStateProvider
 *
 * @returns
 */
export const useModalPng = () => {
  const [showModalPng, setModalPng] = useState(false);

  const updateModalPng = useCallback(() => {
    setModalPng((prevModalPng) => !prevModalPng);
  }, []);

  return [showModalPng, updateModalPng, setModalPng];
};

/**
 * A custom hook used for more option logic in SessionStateProvider and IBStateProvider
 *
 * @returns
 */
export const useModeBar = () => {
  const [showModeBar, setShowModeBar] = useState(false);

  // Toggle the mode bar state
  const toggleModeBar = useCallback(() => {
    setShowModeBar((prevShowModeBar) => !prevShowModeBar);
  }, []);

  return [showModeBar, toggleModeBar];
};

/**
 * A custom hook used for pan mode logic in SessionStateProvider and IBStateProvider
 *
 * @returns
 */
export const usePanMode = () => {
  const [isPanMode, setPanMode] = useState(false);

  // Toggle the mode bar state
  const togglePanMode = useCallback((panMode) => {
    if (panMode !== undefined) setPanMode(panMode);
    else setPanMode((prevPanMode) => !prevPanMode);
  }, []);

  return [isPanMode, togglePanMode];
};

/**
 * A custom hook for the state of the TabVisual tabs state.
 * @param {Array} tabs visual order of tabs
 * @param {Object} tabContents mapping of tab label to tab content (chart, table, etc.)
 */
export const useTabVisualState = (tabs, tabContents) => {
  const initialTab = tabs[0].content[0];
  const [selectedTab, setSelectedTab] = useState(initialTab);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const selectedContent = tabContents[selectedTab];

  return [selectedTab, setSelectedTab, selectedTabIndex, setSelectedTabIndex, selectedContent];
};
