import { HOME_OBJECTS } from './home_screen';

export const TOAST_SHORT = 4000;
export const TOAST_MEDIUM = 6000;
export const TOAST_LONG = 8000;
export const TOAST_INDEFINITE = Infinity;

export const TOAST_SUCCESS = 'success';
export const TOAST_INFO = 'info';
export const TOAST_WARNING = 'warning';
export const TOAST_ERROR = 'error';
export const TOAST_WORKING = 'working';
export const TOAST_EXPORTING = 'exporting';
export const TOAST_UPDATING_DATASET = 'updating_dataset';

export const TOAST_TOP_LEFT = 'TopLeft';
export const TOAST_BOTTOM_RIGHT = 'BottomRight';
export const TOAST_TOP_MIDDLE = 'TopMiddle';
export const TOAST_BOTTOM_LEFT = 'BottomLeft';

export const UPDATING_DATASET_TOAST = {
  toastType: TOAST_UPDATING_DATASET,
  length: TOAST_INDEFINITE,
  message: `Replaying ${HOME_OBJECTS.RECIPE} to update ${HOME_OBJECTS.DATASET}`,
};

/**
 * Gets the default toast configuration
 * @param {string} message
 * @param {TOAST_SUCCESS | TOAST_INFO | TOAST_WARNING |
 * TOAST_ERROR | TOAST_WORKING | TOAST_EXPORTING | TOAST_UPDATING_DATASET} [type]
 * @param {number} [length]
 * @param {string} [position]
 */
export const getToastConfig = (
  message,
  type = TOAST_INFO,
  length = TOAST_MEDIUM,
  position = TOAST_BOTTOM_RIGHT,
) => ({
  message,
  toastType: type,
  length,
  position,
});

export const getSuccessToastConfig = (message) => getToastConfig(message, TOAST_SUCCESS);
export const getErrorToastConfig = (message) => getToastConfig(message, TOAST_ERROR);
export const getWarningToastConfig = (message) => getToastConfig(message, TOAST_WARNING);
export const getInfoToastConfig = (message) => getToastConfig(message, TOAST_INFO);

/**
 * A method to show toast for disabled buttons while replaying for NOT using dispatch hooks
 * @param {*} addToast reference to addToast function call
 * @param {*} msg a customized message based on which button is disabled
 * @param {*} dispatch redux diaptch hook function, default is null
 */
export const showReplayDisableToast = (addToast, msg, dispatch = null) => {
  if (dispatch) {
    dispatch(
      addToast({
        toastType: TOAST_WARNING,
        length: TOAST_LONG,
        message: `Please wait for DataChat to finish, and then try to ${msg} again.`,
      }),
    );
  } else {
    addToast({
      toastType: TOAST_WARNING,
      length: TOAST_LONG,
      message: `Please wait for DataChat to finish, and then try to ${msg} again.`,
    });
  }
};

/**
 * A method to show toast for dataset switch in textbox in grid mode
 * @param {*} addToast reference to addToast function call
 */
export const showDatasetSwitchToast = (addToast) => {
  addToast({
    toastType: TOAST_WARNING,
    length: TOAST_SHORT,
    message: `Please wait for the dataset to switch, and then try to type again.`,
  });
};

/**
 * A method to show toast when users click x to forget datset in grid mode
 * @param {*} addToast reference to addToast function call
 */
export const showForgetDatasetToast = (addToast) => {
  addToast({
    toastType: TOAST_WARNING,
    length: TOAST_LONG,
    message: `Forget dataset may clear relevant charts`,
  });
};
