import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import isString from 'lodash/isString';
import omit from 'lodash/omit';
import { SESSION_ROOT_NODE } from '../../constants';
import { NodeTypes } from '../../constants/nodes';
import { DatachatErrorCode } from '../../types/errorCodes/errorCodes.types';
import { exitSessionSuccess, resetSession, startSessionSuccess } from './session.slice';

export interface TableMessageData {
  name: string;
  title: string;
  totalColumnCount: number;
  totalRowCount: number;
  version: number;
}

export interface Message {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: string | TableMessageData | any;
  src_type: string;
  type: string;
  class?: string;
  collapse?: boolean;
  skill_event?: string;
  data_usage?: number;
  importance?: number;
  workflowContentDelta?: [];
  object_id?: string;
  version?: number;
  from_user?: string;
  external_name?: string;
  // need to investigate what could be contained in additional_info
  additional_info?: { [key: string]: string | number | boolean | null | undefined };
  exit_code?: string;
  display?: string;
  ask_prompt?: string;
  ask_recipe?: string;
  summary_response?: string;
  question?: string;
  vector_id?: string;
  error_code?: DatachatErrorCode;
}

export interface Node {
  legacy?: boolean;
  node_id: string;
  node_type: string;
  parent: string | null;
  children: string[];
  messages: Message[];
  // need to investigate what could be contained in metadata
  metadata: { [key: string]: string | boolean | number | null | undefined };
}

export interface NodesState {
  currentNodeId: string;
  currentNodeOffset: number;
  nodes: { [nodeId: string]: Node };
  currentThread: string[];
  error: string | null;
}

export interface GetNodesRequestPayload {
  sessionId?: string;
  nodeId?: string;
  offset?: number;
  refreshFromNode?: boolean;
}

export interface GetNodesSuccessPayload {
  currentNodeId: string;
  currentNodeOffset: number;
  messages: Message[];
  currentThread: string[];
  nodes: { [nodeId: string]: Node };
  refreshFromNode?: boolean;
}

export interface InsertMessageRequestPayload {
  parent?: string;
  type: NodeTypes;
  message?: Message;
  metadata?: { [key: string]: string | number | boolean | null };
}

const initialState: NodesState = {
  nodes: {},
  currentNodeId: SESSION_ROOT_NODE,
  currentNodeOffset: 0,
  currentThread: [SESSION_ROOT_NODE],
  error: null,
};

const nodesSlice = createSlice({
  name: 'nodes',
  initialState,
  reducers: {
    getNodesRequest: {
      reducer: (state) => {
        state.error = null;
      },
      prepare: (payload: GetNodesRequestPayload = { refreshFromNode: false }) => {
        return { payload };
      },
    },
    getNodesSuccess: (state, { payload }: PayloadAction<GetNodesSuccessPayload>) => {
      Object.values(payload.nodes).forEach((node) => {
        if (state.nodes[node.node_id] && node.messages.length > 0 && !payload.refreshFromNode) {
          // if the node already exists, update it
          // update the node metadata
          state.nodes[node.node_id] = {
            ...state.nodes[node.node_id],
            ...omit(node, 'messages', 'metadata'),
            metadata: { ...state.nodes[node.node_id].metadata, ...node.metadata },
          };
          // append to messages
          state.nodes[node.node_id].messages = [
            ...state.nodes[node.node_id].messages,
            ...node.messages,
          ];
        } else if (!state.nodes?.[node.node_id] || payload.refreshFromNode) {
          // if the node doesn't exist, or we are refreshing the entire node.
          state.nodes[node.node_id] = node;
        }
        // compile additional info from messages into metadata
        if (node.messages.length > 0) {
          node.messages.forEach((message) => {
            if (message.additional_info) {
              state.nodes[node.node_id].metadata = {
                ...state.nodes[node.node_id].metadata,
                ...message.additional_info,
              };
            }
          });
        }
      });
      // update the current node id and offset
      if (state.currentNodeId !== payload.currentNodeId) {
        state.currentNodeId = payload.currentNodeId;
      }
      if (state.currentNodeOffset !== payload.currentNodeOffset) {
        state.currentNodeOffset = payload.currentNodeOffset;
      }
      // update the current thread
      const threadsEqual = state.currentThread.every((nodeId, index) => {
        return (
          state.currentThread.length === payload.currentThread.length &&
          nodeId === payload.currentThread[index]
        );
      });
      if (!threadsEqual) {
        state.currentThread = payload.currentThread;
      }
    },
    getNodesFailure: (state, { payload }: PayloadAction<{ error: Error }>) => {
      state.error = payload.error.message;
    },
    insertMessagesRequest: {
      reducer: () => {},
      prepare: (payload: InsertMessageRequestPayload[]) => {
        // trim any messages we send
        const trimmedPayload = payload.map((msgRequest) => {
          if (msgRequest.message?.data && isString(msgRequest.message.data)) {
            return {
              ...msgRequest,
              message: { ...msgRequest.message, data: msgRequest.message.data.trim() },
            };
          }
          return msgRequest;
        });
        return { payload: trimmedPayload };
      },
    },
    /** Messages have been successfully inserted, their node ids are in the payload. */
    insertMessagesSuccess: {
      prepare: (nodeIds: string[]) => ({ payload: nodeIds }),
      reducer: () => {},
    },
    insertMessagesFailure: (state, { payload }: PayloadAction<{ error: Error }>) => {
      state.error = payload.error.message;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetSession, () => initialState);
    builder.addCase(exitSessionSuccess, () => initialState);
    builder.addCase(startSessionSuccess, () => initialState);
  },
});

export const {
  getNodesRequest,
  getNodesSuccess,
  getNodesFailure,
  insertMessagesRequest,
  insertMessagesSuccess,
  insertMessagesFailure,
} = nodesSlice.actions;

export default nodesSlice.reducer;
