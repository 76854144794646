// create a slice for the initial utterance

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Utterance {
  message: string;
  utteranceMetadata: object;
}
export interface InitialUtteranceState {
  isInitialUtterance: boolean;
  utterance: Utterance;
}

const initialState: InitialUtteranceState = {
  isInitialUtterance: false,
  utterance: { message: '', utteranceMetadata: {} },
};

export const initialUtteranceSlice = createSlice({
  name: 'initialUtterance',
  initialState,
  reducers: {
    triggerInitialUtterance: (state, action: PayloadAction<Utterance>) => {
      state.isInitialUtterance = true;
      state.utterance = action.payload;
    },
    executeInitialUtterance: () => {},
    exitInitialUtterance: (state) => {
      state.isInitialUtterance = false;
    },
  },
});

export const { exitInitialUtterance, triggerInitialUtterance, executeInitialUtterance } =
  initialUtteranceSlice.actions;

export default initialUtteranceSlice.reducer;
