import Close from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useDispatch } from 'react-redux';
import { closeIntegrationsMenu } from '../../store/slices/integrations.slice';
import { IntegrationProvider } from '../../types/integrations.types';
import OAuthButton from '../OAuthButtons/OAuthButton';
import { OAuthContextType } from '../OAuthButtons/oauth.types';
import './IntegrationsMenu.scss';

const IntegrationsMenu = () => {
  const dispatch = useDispatch();

  const handleClose = () => dispatch(closeIntegrationsMenu());

  return (
    <Dialog
      className="IntegrationsMenu-Dialog"
      fullWidth
      open
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') handleClose();
      }}
    >
      <Box className="IntegrationsMenu-Title">
        <Typography className="IntegrationsMenu-Title-Name" data-testid="title">
          My Integrations
        </Typography>
        <IconButton
          className="IntegrationsMenu-Title-Close"
          data-testid="close-button"
          onClick={handleClose}
        >
          <Close />
        </IconButton>
      </Box>
      <Box className="IntegrationsMenu-Body">
        <OAuthButton
          passthroughState={{
            context: OAuthContextType.INTEGRATIONS_MENU,
            databaseProvider: IntegrationProvider.BIGQUERY,
          }}
        />
        <OAuthButton
          passthroughState={{
            context: OAuthContextType.INTEGRATIONS_MENU,
            databaseProvider: IntegrationProvider.SNOWFLAKE,
          }}
        />
      </Box>
    </Dialog>
  );
};

export default IntegrationsMenu;
