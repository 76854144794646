import { call, put, select, takeLatest } from 'redux-saga/effects';

import { deleteApiKey, getApiKeys, updateApiKey } from '../../api/apikey.api';
import { authenticate } from '../../utils/authenticate';
import {
  DELETE_APIKEYS_REQUEST,
  GET_APIKEYS_REQUEST,
  UPDATE_APIKEY_REQUEST,
  deleteApikeysFailure,
  deleteApikeysSuccess,
  getApikeysFailure,
  getApikeysRequest,
  getApikeysSuccess,
  updateApikeyFailure,
  updateApikeySuccess,
} from '../actions/apikeys.action';
import { createAlertChannelRequest } from '../actions/dialog.actions';
import { selectAccessToken } from './selectors';

/**
 * Makes an API request for the list of apps and handles the response.
 */
export function* getApikeysRequestWorker() {
  try {
    const accessToken = yield select(selectAccessToken);
    const response = yield call(getApiKeys, accessToken);
    const { data } = response;
    yield put(getApikeysSuccess({ keys: data }));
  } catch (error) {
    yield put(getApikeysFailure({ error }));

    yield put(createAlertChannelRequest({ error }));
  }
}

export function* deleteApikeysRequestWorker({ id }) {
  try {
    const accessToken = yield select(selectAccessToken);
    yield call(deleteApiKey, accessToken, id);
    yield put(deleteApikeysSuccess());
    // Refresh the list
    yield put(getApikeysRequest());
  } catch (error) {
    yield put(deleteApikeysFailure({ error }));

    yield put(createAlertChannelRequest({ error }));
  }
}

export function* updateApikeyRequestWorker({ id, key }) {
  try {
    const accessToken = yield select(selectAccessToken);
    yield call(updateApiKey, accessToken, id, key);
    yield put(updateApikeySuccess());
  } catch (error) {
    yield put(updateApikeyFailure({ error }));

    yield put(createAlertChannelRequest({ error }));
  }
}

/**
 * Listens for the most recent DELETE_APIKEYS_REQUEST action and spins up a worker.
 */
export default function* apiWatcher() {
  yield takeLatest(GET_APIKEYS_REQUEST, authenticate(getApikeysRequestWorker, getApikeysFailure));
  yield takeLatest(
    DELETE_APIKEYS_REQUEST,
    authenticate(deleteApikeysRequestWorker, deleteApikeysFailure),
  );
  yield takeLatest(
    UPDATE_APIKEY_REQUEST,
    authenticate(updateApikeyRequestWorker, updateApikeyFailure),
  );
}
