import Close from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectDatabaseBrowserOpenConnection,
  selectDatabaseFilterTerm,
} from '../../../store/selectors/dbBrowser.selector';
import { setDatabaseFilterTerm } from '../../../store/slices/dbBrowser.slice';
import { HomeObjectKeys } from '../../../utils/homeScreen/types';

// filter input component to filter db tree results
const DatabaseBrowserSearch = () => {
  const dispatch = useDispatch();
  const filterTerm = useSelector(selectDatabaseFilterTerm);
  const currentConnection = useSelector(selectDatabaseBrowserOpenConnection);
  const connectionUUID = currentConnection ? currentConnection[HomeObjectKeys.UUID] : '';

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e && currentConnection)
      dispatch(
        setDatabaseFilterTerm({
          connectionUUID,
          filterTerm: e.target.value,
        }),
      );
  };

  const handleClearFilter = () =>
    currentConnection && dispatch(setDatabaseFilterTerm({ connectionUUID, filterTerm: '' }));

  return (
    <TextField
      className="DatabaseBrowser-Navigation-Header-Search"
      size="small"
      label="Filter"
      value={filterTerm}
      onChange={handleFilterChange}
      fullWidth
      disabled={!currentConnection}
      InputProps={{
        endAdornment: filterTerm !== '' && (
          <IconButton onClick={() => handleClearFilter()}>
            <Close />
          </IconButton>
        ),
      }}
    />
  );
};

export default DatabaseBrowserSearch;
