export const GET_APIKEYS_REQUEST = 'apikeys/GET_APIKEYS_REQUEST';
export const GET_APIKEYS_SUCCESS = 'apikeys/GET_APIKEYS_SUCCESS';
export const GET_APIKEYS_FAILURE = 'apikeys/GET_APIKEYS_FAILURE';

export const DELETE_APIKEYS_REQUEST = 'apikeys/DELETE_APIKEYS_REQUEST';
export const DELETE_APIKEYS_SUCCESS = 'apikeys/DELETE_APIKEYS_SUCCESS';
export const DELETE_APIKEYS_FAILURE = 'apikeys/DELETE_APIKEYS_FAILURE';

export const UPDATE_APIKEY_REQUEST = 'apikeys/UPDATE_APIKEY_REQUEST';
export const UPDATE_APIKEY_SUCCESS = 'apikeys/UPDATE_APIKEY_SUCCESS';
export const UPDATE_APIKEY_FAILURE = 'apikeys/UPDATE_APIKEY_FAILURE';

export const getApikeysRequest = () => ({
  type: GET_APIKEYS_REQUEST,
});

/**
 * Indicates that the API call to the server is successful.
 */
export const getApikeysSuccess = ({ keys }) => ({
  type: GET_APIKEYS_SUCCESS,
  keys,
});

export const getApikeysFailure = ({ error }) => ({
  type: GET_APIKEYS_FAILURE,
  error,
});

export const deleteApikeysRequest = ({ id }) => ({
  type: DELETE_APIKEYS_REQUEST,
  id,
});

export const deleteApikeysSuccess = () => ({
  type: DELETE_APIKEYS_SUCCESS,
});

export const deleteApikeysFailure = ({ error }) => ({
  type: DELETE_APIKEYS_FAILURE,
  error,
});

export const updateApikeyRequest = ({ id, key }) => ({
  type: UPDATE_APIKEY_REQUEST,
  id,
  key,
});

export const updateApikeySuccess = () => ({
  type: UPDATE_APIKEY_SUCCESS,
});

export const updateApikeyFailure = ({ error }) => ({
  type: UPDATE_APIKEY_FAILURE,
  error,
});
