import PropTypes from 'prop-types';
import React, { memo, useContext } from 'react';
import { CLASS, VISUAL_TYPES } from '../../../../constants';
import { ScreenSizeContext } from '../../../DataAssistant/DataAssistant';
import PivotTable from '../../../DisplayPanel/Charts/PivotTable/PivotTable';
import {
  MAX_PIVOT_HEIGHT,
  PIVOT_ROW_HEIGHT,
} from '../../../DisplayPanel/Charts/PivotTable/constants';
import CarouselMessage from './Messages/CarouselMessage';
import ChartMessage from './Messages/ChartMessage';
import TableMessage from './Messages/TableMessage';
import TextMessage from './Messages/TextMessage';

const AvaResponseMessages = memo(
  ({ nodeId, messages, isLastNode, nodeMetadata, inView, setSelectedChart, objectId }) => {
    // only show the typing effect when
    // 1. the AvaResponse is the last node in the conversation
    // 2. the last message is less than 10 seconds old
    const showTypeEffect = isLastNode && Date.now() / 1000 - nodeMetadata?.timestamp < 10;
    const isSmallScreen = useContext(ScreenSizeContext);

    if ((!messages || messages.length === 0) && nodeMetadata.execution_successful === false) {
      // If there are no messages to display, and the execution was not successful, show an error message
      return (
        <TextMessage
          data="An error occurred while processing your request."
          id={`${nodeId}-error`}
          key={`${isSmallScreen ? 'DA-' : ''}${nodeId}-error`}
          promptClass={CLASS.ERROR}
          showTypeEffect={showTypeEffect}
        />
      );
    }

    return messages.map((message, index) => {
      const isLast = index === messages.length - 1;
      const messageId = `${nodeId}-${index}`;
      switch (message.type) {
        case VISUAL_TYPES.TEXT:
          if (message.class === CLASS.UPDATE && !isLast) {
            // Hide update messages that are not the last message
            return null;
          }
          return (
            <TextMessage
              code={message.additionalInfo?.code}
              data={message.data}
              id={messageId}
              key={`${isSmallScreen ? 'DA-' : ''}${messageId}`}
              promptClass={message.class}
              showTypeEffect={showTypeEffect}
            />
          );
        case VISUAL_TYPES.VIZ:
          return (
            <ChartMessage
              externalName={message.external_name}
              id={messageId}
              key={`${isSmallScreen ? 'DA-' : ''}${objectId}`}
              message={{ chart: message }}
              objectId={objectId}
              inView={inView}
              update={message.update}
            />
          );
        case VISUAL_TYPES.TABLE:
          return (
            <TableMessage
              id={messageId}
              inView={inView}
              key={`${isSmallScreen ? 'DA-' : ''}${messageId}`}
              message={{ chart: message }}
              objectId={objectId}
            />
          );
        case VISUAL_TYPES.PIVOT: {
          const { dimensions } = message.data.values;
          let height = (dimensions[0] + 2) * PIVOT_ROW_HEIGHT;
          height = height > MAX_PIVOT_HEIGHT ? MAX_PIVOT_HEIGHT : height;
          return (
            <div className="AvaPivotWrapper" key={`${isSmallScreen ? 'DA-' : ''}${messageId}`}>
              <PivotTable
                width="100%"
                height={height}
                outerHeight={height}
                isPopOutChart={false}
                tableData={message.data}
                objectId={objectId}
              />
            </div>
          );
        }
        case VISUAL_TYPES.TABVISUAL: {
          const tabvisualSummary = nodeMetadata?.tabvisual_summary || {};
          // charts is an array of [chartName, chartData]
          // we need the name so we can track the chart name when a user navigates the carousel
          // Chart name will be used to provide the chart recipe to the validation panel
          const charts = Object.entries(message.data?.tab_contents || {});
          switch (charts.length) {
            case 0:
              // no charts, so don't render anything
              return null;
            case 1: {
              // single chart, so just render it as a regular chart
              const loneChart = charts[0][1];
              const loneChartId = `${nodeId}-lone-chart`;
              const loneChartObjectId = loneChart.additional_info?.dc_chart_id || objectId;

              return (
                <ChartMessage
                  externalName={loneChart.external_name}
                  id={loneChartId}
                  key={`${isSmallScreen ? 'DA-' : ''}${loneChartId}-${loneChartObjectId}`}
                  message={{ chart: loneChart }}
                  objectId={loneChartObjectId}
                  inView={inView}
                  update={message.update}
                  parentObjectId={objectId}
                />
              );
            }
            default:
              // multiple charts, render them as a carousel
              return (
                <CarouselMessage
                  setSelectedChart={setSelectedChart}
                  charts={charts}
                  tabvisualSummary={tabvisualSummary}
                  id={nodeId}
                  key={`${isSmallScreen ? 'DA-' : ''}${objectId}`}
                  objectId={objectId}
                  inView={inView}
                  showTypeEffect={showTypeEffect}
                />
              );
          }
        }
        default:
          return null;
      }
    });
  },
  (prevProps, nextProps) => {
    return (
      prevProps.nodeId === nextProps.nodeId &&
      prevProps.messages.length === nextProps.messages.length &&
      prevProps.inView === nextProps.inView &&
      prevProps.isLastNode === nextProps.isLastNode &&
      prevProps.nodeMetadata === nextProps.nodeMetadata
    );
  },
);

AvaResponseMessages.propTypes = {
  nodeId: PropTypes.string.isRequired,
  messages: PropTypes.array.isRequired,
  isLastNode: PropTypes.bool.isRequired,
  nodeMetadata: PropTypes.object.isRequired,
  inView: PropTypes.bool.isRequired,
  setSelectedChart: PropTypes.func.isRequired,
  objectId: PropTypes.string,
};

AvaResponseMessages.defaultProps = {
  objectId: '',
};

export default AvaResponseMessages;
