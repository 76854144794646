import { CLOSE_SNACKBAR, NEW_SNACKBAR } from '../actions/snackbars.actions';

export const initialState = {
  snackbars: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case NEW_SNACKBAR:
      return {
        ...state,
        snackbars: [
          ...state.snackbars,
          {
            id: action.id,
            message: action.message,
            buttons: action.buttons,
          },
        ],
      };
    case CLOSE_SNACKBAR:
      return {
        ...state,
        snackbars: state.snackbars.filter((snackbar) => snackbar.id !== action.id),
      };
    default:
      return state;
  }
};
