/**
 * Converts scientific notation to number notation
 *
 * @param {string} value - Expects a string representation of a number in scientific notation.
 *                        If type is number, then precision will we limited to 20 decimal points
 * @return {string} value in plain number notation (no commas)
 */
export const scientificToNumber = (value: string) => {
  const number = Number(value);
  const [num, power] = value.toString().split('e');
  const [, digits] = num.split('.');

  if (!Number(power)) {
    return number;
  } else if (Number(power) < 0) {
    const numDecimals = Math.min(Math.abs(Number(power)) + (digits ? digits.length : 0), 100);
    return number.toFixed(numDecimals);
  }
  return number.toLocaleString('fullwide', { useGrouping: false });
};
