import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { ReactComponent as UseDatasetIcon } from '../../../../../assets/icons/use_crane.svg';
import { isDataChatSessionPage, isWorkflowEditorPage } from '../../../../../constants/paths';
import { showReplayDisableToast } from '../../../../../constants/toast';
import { utteranceUseDataset } from '../../../../../constants/utterance_templates';
import { retrieveForgottenDataset } from '../../../../../store/actions/dataset.actions';
import { sendMessageRequest } from '../../../../../store/actions/messages.actions';
import { addToast } from '../../../../../store/actions/toast.actions';
import { selectCurrentLocation } from '../../../../../store/sagas/selectors';
import { selectIsStepwise } from '../../../../../store/selectors/context.selector';
import { PLOTS_NOT_DOWNLOADABLE, UNALLOWED_DATASETS } from '../../../constants';

/**
 * A functional component to render button UI to use dataset
 */
const UseButton = (props) => {
  // redux dispatch hook
  const dispatch = useDispatch();

  const {
    sessionType,
    isReplaying,
    isStepwise,
    isWorkflowEditor,
    messageType,
    data,
    sessionID,
    isDropDownButton,
  } = props;

  // Sets the current dataset to the one associated with the clicked table/chart
  const handleUseDatasetButtonClick = () => {
    if (isReplaying && !isStepwise) {
      showReplayDisableToast(addToast, 'use dataset', dispatch);
      return;
    }
    if ((messageType === 'table' || messageType === 'pivot table') && data.name) {
      dispatch(
        sendMessageRequest({
          message: utteranceUseDataset(data.name, data.version),
          sessionID,
        }),
      );
    } else if (messageType === 'viz') {
      const [dsName, dsVersion] =
        data.dataset?.name && data.dataset?.version
          ? // typeVersion = 1, or use dataset is intended to be different than reference dataset
            [data.dataset.name, data.dataset.version]
          : // typeVersion = 2
            [data.values.reference.params.dataset, data.values.reference.params.version];
      dispatch(
        sendMessageRequest({
          message: utteranceUseDataset(dsName, dsVersion),
          sessionID,
        }),
      );
    }
  };

  const handleUseForgottenDatasetButtonClick = () => {
    dispatch(
      retrieveForgottenDataset({
        datasetName: data.name,
        version: data.version,
        sendUseUtternace: true,
      }),
    );
  };

  /**
   * Returns true if the use button should be shown, otherwise, returns false.
   */
  const showUseButton = () => {
    if (isDataChatSessionPage()) return false;
    if (data && messageType && sessionType && !isWorkflowEditor) {
      switch (messageType) {
        case 'viz':
          // Don't show button if plot doesn't render or if plotly chart isn't downloadable
          return data.typeVersion === 2
            ? data.plot.series && data.values.reference && !data.isDependencyGraph
            : data.chartType && data.dataset && !PLOTS_NOT_DOWNLOADABLE.includes(data.chartType);
        case 'table':
        case 'pivot table':
          return (
            data.name &&
            data.version !== undefined &&
            !UNALLOWED_DATASETS.includes(data.name.toLowerCase())
          );
        default:
          return false;
      }
    } else {
      return false;
    }
  };

  return (
    <>
      {showUseButton() ? (
        isDropDownButton ? (
          <MenuItem key="use" className="Menu-Item-Container" onClick={handleUseDatasetButtonClick}>
            <div
              className={`${isReplaying && !isStepwise ? 'Menu-Item Replaying' : 'Menu-Item'}`}
              data-cy="Menu-Item-Use"
            >
              <UseDatasetIcon className="Menu-Item-Icon" />
              <span className="Menu-Item-Text">
                {`Use ${messageType === 'viz' ? 'the associated' : 'this'} dataset`}
              </span>
            </div>
          </MenuItem>
        ) : (
          <Tooltip key="view" title="Retrieve this dataset">
            <div className="ChartHeader-Button" onClick={handleUseForgottenDatasetButtonClick}>
              <UseDatasetIcon className="ChartHeader-Icon-Padded" />
            </div>
          </Tooltip>
        )
      ) : null}
    </>
  );
};

UseButton.propTypes = {
  sessionType: PropTypes.string.isRequired,
  isReplaying: PropTypes.bool.isRequired,
  isStepwise: PropTypes.bool.isRequired,
  isWorkflowEditor: PropTypes.bool.isRequired,
  messageType: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  sessionID: PropTypes.string.isRequired,
  isDropDownButton: PropTypes.bool,
};

UseButton.defaultProps = {
  isDropDownButton: true,
};

const mapStateToProps = (state) => ({
  sessionType: state.session.sessionType,
  isStepwise: selectIsStepwise(state),
  isReplaying: state.context.isReplaying,
  isWorkflowEditor: isWorkflowEditorPage(selectCurrentLocation(state)),
  sessionID: state.session.session,
});

export default connect(mapStateToProps, {})(UseButton);
