import { Buffer } from 'buffer';
import { CallEffect, GetContextEffect, SelectEffect } from 'redux-saga/effects';
import { call, getContext, put, select, take, takeEvery, takeLatest } from 'typed-redux-saga';
import { ReduxSagaContext } from '../../configureStore';
import { API_SERVICES } from '../../constants/api';
import { OK_BUTTON_KEY, profilePictureSizeExceededAlert } from '../../constants/dialog.constants';
import { PROFILE_PICTURE_SIZE } from '../../constants/profilePictures';
import { GetProfilePictureResponse } from '../../types/download.types';
import { GetProfilePictureFileNamesResponse } from '../../types/file_manager.types';
import { FileNamesByUserId, ImagesByUserId } from '../../types/profilePictures.types';
import { closeDialog } from '../actions/dialog.actions';
import { selectUserIdsWithProfilePictures } from '../selectors/profilePictures.selector';
import {
  getOrganizationProfilePicturesRequest,
  getProfilePicturesFailure,
  getProfilePicturesSuccess,
  getUserProfilePicturesRequest,
  uploadProfilePictureFailure,
  uploadProfilePictureRequest,
  uploadProfilePictureSuccess,
} from '../slices/profilePictures.slice';
import { selectAccessToken, selectUserID } from './selectors';
import { createAlertChannel } from './utils/alert-channels';
import { retry401 } from './utils/retry';

/**
 * Format an API response to a base64 image url.
 * @param response Response from the getProfilePicture API.
 * @returns Base64 image url.
 */
export const formatToBase64ImageUrl = (response: GetProfilePictureResponse) =>
  `data:${response.headers['content-type']};base64,${Buffer.from(response.data).toString(
    'base64',
  )}`;

/**
 * Uploads a profile picture to the DataChat server for the current user.
 * @param file Profile picture image file.
 */
export function* uploadProfilePictureRequestWorker({
  payload,
}: ReturnType<typeof uploadProfilePictureRequest>) {
  const { file } = payload;
  const accessToken = yield* select(selectAccessToken);
  const userId = yield* select(selectUserID);

  // Check if file size is less than 10 MB
  if (file.size > PROFILE_PICTURE_SIZE) {
    // Alert the user that the profile picture size is greater than 10MB
    const alertChannel = yield* createAlertChannel(profilePictureSizeExceededAlert());
    const keyChoice = yield* take(alertChannel);

    // User clicked Ok. Close the pop up.
    if (keyChoice === OK_BUTTON_KEY) yield put(closeDialog());
    yield* put(uploadProfilePictureFailure({ error: new Error('File size exceeded') }));
    return;
  }

  try {
    const fileManagerService = (yield* getContext(
      API_SERVICES.FILE_MANAGER,
    )) as ReduxSagaContext[API_SERVICES.FILE_MANAGER];
    yield* call(fileManagerService.uploadProfilePicture, accessToken, file);

    // Fetch the new profile picture for the user
    yield put(getUserProfilePicturesRequest({ userIds: [userId], overwrite: true }));
    yield* put(uploadProfilePictureSuccess());
  } catch (error) {
    yield* put(uploadProfilePictureFailure({ error }));
  }
}

/**
 * Helper to get the Base64 image urls from the profile picture file names.
 * @param data filenames by userId.
 * @returns Base64 image urls by userId.
 */
export function* downloadProfilePicturesHelper({
  data,
}: {
  data: FileNamesByUserId;
}): Generator<
  SelectEffect | GetContextEffect | CallEffect<GetProfilePictureResponse>,
  ImagesByUserId,
  string | ReduxSagaContext[API_SERVICES.DOWNLOAD]
> {
  const accessToken = yield* select(selectAccessToken);
  const downloadService = (yield* getContext(
    API_SERVICES.DOWNLOAD,
  )) as ReduxSagaContext[API_SERVICES.DOWNLOAD];

  const ret: ImagesByUserId = {};

  for (const [userId, filename] of Object.entries(data)) {
    if (filename) {
      const res = yield* call(downloadService.getProfilePicture, accessToken, filename);
      ret[Number(userId)] = formatToBase64ImageUrl(res);
    }
  }

  return ret;
}

/**
 * Helper to call the Management Service for the organization's profile picture file names.
 * @returns Profile picture file names by userId for the organization.
 */
export function* getOrganizationProfilePictureFileNamesHelper(): Generator<
  SelectEffect | GetContextEffect | CallEffect<GetProfilePictureFileNamesResponse>,
  GetProfilePictureFileNamesResponse,
  string | ReduxSagaContext[API_SERVICES.FILE_MANAGER]
> {
  const accessToken = yield* select(selectAccessToken);
  const fileManagerService = (yield* getContext(
    API_SERVICES.FILE_MANAGER,
  )) as ReduxSagaContext[API_SERVICES.FILE_MANAGER];

  return yield* call(fileManagerService.getOrganizationProfilePictureFileNames, accessToken);
}

/**
 * Worker to fetch all profile pictures for the current user's organization.
 */
export function* getOrganizationProfilePicturesRequestWorker() {
  const idsWithPictures = yield* select(selectUserIdsWithProfilePictures);

  try {
    // Call the management service for the profile picture download urls
    const fileNamesByUserId = yield* call(getOrganizationProfilePictureFileNamesHelper);

    // Filter out the user ids that already have profile pictures
    idsWithPictures.forEach((id) => delete fileNamesByUserId.data[id]);
    if (Object.keys(fileNamesByUserId.data).length === 0) {
      yield put(
        getProfilePicturesFailure({ error: new Error('No new profile pictures to fetch') }),
      );
      return;
    }

    // Get the Base64 images for the profile pictures
    const imagesByUserId = yield* call(downloadProfilePicturesHelper, fileNamesByUserId);

    // Call for success with the image data
    yield put(getProfilePicturesSuccess({ imagesByUserId }));
  } catch (error) {
    yield put(getProfilePicturesFailure({ error }));
  }
}

/**
 * Helper to call the Management Service for the users' profile picture file names.
 * @returns Profile picture file names by userId.
 */
export function* getUserProfilePictureFileNamesHelper(userIds: number[]) {
  const fileManagerService = (yield* getContext(
    API_SERVICES.FILE_MANAGER,
  )) as ReduxSagaContext[API_SERVICES.FILE_MANAGER];

  return yield* retry401(fileManagerService.getUserProfilePictureFileNames, userIds);
}

/**
 * Worker to fetch profile pictures for the specified user ids.
 * @param userIds User ids to fetch profile pictures for.
 */
export function* getUserProfilePicturesRequestWorker({
  payload,
}: ReturnType<typeof getUserProfilePicturesRequest>) {
  const { overwrite = false, userIds = [] } = payload;

  const currUserId = yield* select(selectUserID);
  const idsWithPictures = yield* select(selectUserIdsWithProfilePictures);

  // Filter out the user ids that already have profile pictures if we're not overwriting
  let ids = Array.from(new Set([currUserId, ...userIds]));
  ids = overwrite ? ids : ids.filter((id) => !idsWithPictures.includes(id));
  if (ids.length === 0) {
    yield put(getProfilePicturesFailure({ error: new Error('No new profile pictures to fetch') }));
    return;
  }

  try {
    // Call the management service for the profile picture file names
    const fileNamesByUserId = yield* call(getUserProfilePictureFileNamesHelper, ids);

    // Download the Base64 images for the profile pictures
    const imagesByUserId = yield* call(downloadProfilePicturesHelper, fileNamesByUserId);

    // Call for success with final formatted image data
    yield put(getProfilePicturesSuccess({ imagesByUserId }));
  } catch (error) {
    yield put(getProfilePicturesFailure({ error }));
  }
}

export default function* () {
  yield* takeLatest(
    getOrganizationProfilePicturesRequest.type,
    getOrganizationProfilePicturesRequestWorker,
  );
  yield* takeEvery(getUserProfilePicturesRequest.type, getUserProfilePicturesRequestWorker);
  yield* takeEvery(uploadProfilePictureRequest.type, uploadProfilePictureRequestWorker);
}
