export const ADD_CHART_STATUS = 'chart_selection/ADD_CHART_STATUS';
export const CLEAR_CHART_STATUSES = 'chart_selection/CLEAR_CHART_STATUSES';
export const HANDLE_SELECT_CHART = 'chart_selection/HANDLE_SELECT_CHART';
export const SELECT_ALL_CHARTS = 'chart_selection/SELECT_ALL_CHARTS';
export const UNSELECT_ALL_CHARTS = 'chart_selection/UNSELECT_ALL_CHARTS';
export const HANDLE_SELECT_DATASET = 'chart_selection/HANDLE_SELECT_DATASET';
export const ADD_DATASET_TO_LIST = 'chart_selection/ADD_DATASET_TO_LIST';

/**
 * Adds the metadata for a chart object to the chartStatuses array
 * @param {object} metadata related to selecting and saving chart objects
 */
export const addChartStatus = ({ objectId, chartType, selectable, dataset, version }) => ({
  type: ADD_CHART_STATUS,
  objectId,
  chartType,
  selectable,
  dataset,
  version,
});

export const addDatasetToList = ({ name, version }) => ({
  type: ADD_DATASET_TO_LIST,
  name,
  version,
});

/**
 * Resets state
 */
export const clearChartStatuses = () => ({
  type: CLEAR_CHART_STATUSES,
});

/**
 * Sets the selected status for the chart at the given index
 * @param {number} index within the chartStatuses array
 * @param {boolean} selected true if selected, false otherwise
 */
export const handleSelectChart = (index, selected) => ({
  type: HANDLE_SELECT_CHART,
  index,
  selected,
});

export const handleSelectDataset = (name, version, selected) => ({
  type: HANDLE_SELECT_DATASET,
  name,
  version,
  selected,
});

/**
 * Sets the selected status for all selectable chart objects to true
 */
export const selectAllCharts = () => ({ type: SELECT_ALL_CHARTS });

/**
 * Sets the selected status for all selectable chart objects to false
 */
export const unselectAllCharts = () => ({ type: UNSELECT_ALL_CHARTS });
