import { TOAST_BOTTOM_RIGHT } from '../../constants/toast';

export const ADD_TOAST = 'toast/ADD_TOAST';
export const DISMISS_TOAST = 'toast/DISMISS_TOAST';
export const DISMISS_ALL_TOASTS = 'toast/DISMISS_ALL_TOASTS';

/**
 * Adds a new toast notification.
 *
 * @param {object} o
 * @param {string} [o.toastType]
 * @param {number} [o.length]
 * @param {string} [o.message]
 * @param {string} [o.position]
 */
export const addToast = ({ toastType, length, message, position = TOAST_BOTTOM_RIGHT }) => ({
  type: ADD_TOAST,
  toastType,
  length,
  message,
  position,
});

/**
 * Dismisses a toast of a particular id.
 * @param {Object} param0
 * @param {Number} id
 */
export const dismissToast = ({ id }) => ({
  type: DISMISS_TOAST,
  id,
});

/**
 * Dismisses all toasts
 */
export const dismissAllToasts = () => ({
  type: DISMISS_ALL_TOASTS,
});
