import { PropTypes } from 'prop-types';
import React from 'react';
import { aggregateExpressionsDisplay, getBinChipTitle } from 'translate_dc_to_echart';
import '../ChartBuilder.scss';
import {
  aggregateDisplayToExpressions,
  getAggregatedInputs,
  getBinnedInputs,
  shorthandAggregates,
} from '../utils/constants';
import { constructAggregate, filterAggregate } from '../utils/manageAggregates';
import { validateDataSampleLimit } from '../utils/validation';
import Bin from './Bin';

/**
 * A provider component that wraps the chartBuilder input fields and supports complex column actions
 * @returns {JSX.Element} The complex column provider component
 */
const ComplexColumnProvider = (props) => {
  const {
    aggregates,
    applyBin,
    bins,
    chartType,
    children,
    chooseDataSampleLimit,
    columnsTypes,
    fields,
    inputName,
    updateChart,
  } = props;

  /**
   * Reset the current input field by undoing any complex column actions on the
   * column(s) that are currently selected in this input
   */
  const resetComplexColumn = () => {
    let columnsToReset = fields[inputName]; // Columns in the current input
    let updatedAggregates = [...aggregates];
    let updatedBins = [...bins];

    // If bin applies to more than one input, then we shouldn't remove the bin
    // Get all duplicate selected columns, then remove them from columnsToReset
    const allSelectedColumns = Object.values(fields).flat();
    const findDuplicates = (arr) => arr.filter((item, index) => arr.indexOf(item) !== index);
    const duplicateCols = findDuplicates(allSelectedColumns);
    columnsToReset = columnsToReset.filter((col) => !duplicateCols.includes(col));

    // Remove the aggregates and bins that include the columns in columnsToReset
    for (const colName of columnsToReset) {
      updatedBins = updatedBins.filter((bin) => bin.column !== colName);
      updatedAggregates = updatedAggregates.filter((agg) => !agg.columns.includes(colName));
    }

    // Update the dataSampleLimit if necessary when aggregate or bins change
    const updatedDataSampleLimit = validateDataSampleLimit(chartType, updatedAggregates);
    chooseDataSampleLimit(updatedDataSampleLimit, false);

    updateChart({ aggregates: updatedAggregates, bins: updatedBins });
  };

  /**
   * Get the complex column options that we can apply to the current column
   * @param {String} columnName The name of the column
   * @returns {Array} The options that we can apply
   */
  const getComplexOptions = (columnName) => {
    if (!columnName || !fields[inputName]) return [];

    const complexOptions = [];
    const colType = columnsTypes.find((column) => column.name === columnName)?.type;

    // Get the list of inputs that we aggregate and bin
    const aggregatedInputs = getAggregatedInputs(chartType);
    const binnedInputs = getBinnedInputs(chartType);

    // Check if we can aggregate the current input
    if (aggregatedInputs.includes(inputName)) {
      const aggregateOptions = filterAggregate(chartType, colType).map(
        (agg) => aggregateExpressionsDisplay[agg],
      );
      complexOptions.push(...aggregateOptions);
    }

    // Check if we can bin the current input
    if (binnedInputs.includes(inputName) && ['Integer', 'Float', 'Number'].includes(colType)) {
      complexOptions.push(
        <Bin
          bins={bins}
          column={columnName}
          columnsTypes={columnsTypes}
          currChartType={chartType}
          fields={fields}
          key="Bin"
        />,
      );
    }

    // Return our filtered options
    return complexOptions;
  };

  /**
   * Get the complex column name(s) based on the chart type, fields, aggregates, and bins
   * @param {String} columnName The original name of the column
   * @returns {String} The new column name(s), ex. 'MIN(Fare)', 'AgePer10'
   */
  const getComplexColumn = (columnName) => {
    const aggregate = aggregates.find((agg) => agg.columns.includes(columnName));
    const bin = bins.find((b) => columnName === b.column);

    // Update the column name if it's aggregated
    if (aggregate) {
      const { expression } = aggregate;
      const aggregatedInputs = getAggregatedInputs(chartType);

      if (aggregatedInputs.includes(inputName) && expression !== 'None') {
        const shorthand = shorthandAggregates[expression]; // Get the shorthand version
        return `${shorthand}(${columnName})`; // Replace the column name with the new one
      }
    }

    // Update the column name if it's binned
    if (bin) {
      const binnedInputs = getBinnedInputs(chartType);

      if (binnedInputs.includes(inputName)) {
        return getBinChipTitle(bin);
      }
    }

    // If not binned or aggregated, return the unmodified column name
    return columnName;
  };

  /**
   * Wrapper function for processing a complex column action or form submission
   * @param {String|Object|null} action The selected complex action, ex. 'Average', { ...binInfo }
   */
  const handleComplexAction = (action) => {
    // Did we clear the input?
    if (action === null) {
      resetComplexColumn();
      return;
    }

    // For the purpose of passing to validation
    let updatedAggregates = [];

    // Are we handling an aggregate action?
    const aggregateExpression = aggregateDisplayToExpressions[action];
    const processingAggregate = Boolean(aggregateExpression);
    if (processingAggregate) {
      updatedAggregates = constructAggregate(chartType, aggregateExpression, fields);
    }

    // Update the dataSampleLimit if necessary when aggregate or bins change
    // Choose dataSampleLimit before updating the chart spec
    const updatedDataSampleLimit = validateDataSampleLimit(
      chartType,
      processingAggregate ? updatedAggregates : aggregates,
    );
    chooseDataSampleLimit(updatedDataSampleLimit, false);

    if (processingAggregate) {
      updateChart({ aggregates: updatedAggregates });
    }

    // Are we handling a bin action?
    // We send null newBin when clearing a bin, so check for the oldBinTitle
    const processingBin = action?.oldBinTitle || action?.newBin;
    if (processingBin) {
      applyBin(action.newBin, action.oldBinTitle);
    }
  };

  return <>{children(getComplexColumn, getComplexOptions, handleComplexAction)}</>;
};

ComplexColumnProvider.propTypes = {
  // Aggregation details for the current chart
  aggregates: PropTypes.array.isRequired,
  // Applies the new bin details to the chart spec, and callbacks to checkFields
  applyBin: PropTypes.func.isRequired,
  // Bin details for the current chart
  bins: PropTypes.array.isRequired,
  // The type of chart, ex. 'bar'
  chartType: PropTypes.string.isRequired,
  // The children of this component
  children: PropTypes.func.isRequired,
  // The function to set the data sample limit
  chooseDataSampleLimit: PropTypes.func.isRequired,
  // The column/type information in array of object format, ex. [{ name: 'Age', type: 'Float' }]
  columnsTypes: PropTypes.array.isRequired,
  // The list of fields selected in the chart builder
  fields: PropTypes.object.isRequired,
  // The name of the current input, ex. 'x-axis', 'group'
  inputName: PropTypes.string.isRequired,
  // The function to update the chart spec
  updateChart: PropTypes.func.isRequired,
};

export default ComplexColumnProvider;
