import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectSessionDatasetStorage } from '../../../store/sagas/selectors';
import {
  selectSelectedDatasetName,
  selectSelectedDatasetVersion,
} from '../../../store/selectors/dataspace.selector';
import { useDataFromStorage, useSessionPipelinerDatasetId } from '../../ChartData/dataUtils';
import './ColumnStat.scss';
import IndividualColumnStat from './IndividualColumnStat';
import { getKeys } from './utils';

// A component to display the column stat for the given column
// It is dependent on the representation from the BE
const ColumnStat: React.FC<{
  colName: string;
  colType: string;
}> = ({ colName, colType }) => {
  const datasetStorage: { [datasetId: string]: object } = useSelector(selectSessionDatasetStorage);
  const selectedDatasetName = useSelector(selectSelectedDatasetName);
  const selectedDatasetVersion = useSelector(selectSelectedDatasetVersion);

  const pipelinerDatasetId = useSessionPipelinerDatasetId({
    datasetName: selectedDatasetName,
    datasetVersion: selectedDatasetVersion,
  });

  const { data: tableData } = useDataFromStorage({
    datasetStorage,
    isTable: false,
    pipelinerDatasetId,
  });

  const columnStat = tableData?.columnStat?.[colName] ?? {};

  return (
    <div className="Column-Statistics">
      <table>
        <tbody>
          <Tooltip key="col-type" placement="right" title={colType}>
            <tr className="col-type">
              <td className="title">Type</td>
              <td className="value">{colType}</td>
            </tr>
          </Tooltip>
          {getKeys(colType).map((key, idx) => (
            <IndividualColumnStat
              key={key || `empty-${idx}`}
              columnStatKey={key}
              columnStat={columnStat}
              index={idx}
              colType={colType}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ColumnStat;
