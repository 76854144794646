import { DefaultTheme } from 'styled-components';
import colors, { TColors } from './colors';
import typography, { TTypography } from './typography';
import breakpoints, { TBreakPoints } from './breakpoints';

export interface IThemeVariables {
  colors: TColors;
  typography: TTypography;
  breakpoints: TBreakPoints;
}

const themeVariables: DefaultTheme = {
  colors,
  breakpoints,
  typography,
};

export { default as ThemeProvider } from './context/ThemeProvider';

export default themeVariables;
