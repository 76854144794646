import CameraAlt from '@mui/icons-material/CameraAlt';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { connect } from 'react-redux';
import {
  selectIsProfilePictureLoading,
  selectProfilePictureByUserId,
} from '../../store/selectors/profilePictures.selector';
import { uploadProfilePictureRequest } from '../../store/slices/profilePictures.slice';
import { ORIENTATIONS, fontSizeToRem } from './constants';

import '../../styles/ProfilePicture.scss';

type Props = {
  name: string,
  showDefaultColor: boolean,
  showTooltip: boolean,
  height: number,
  width: number,
  fontSize: number,
  orientation: typeof ORIENTATIONS.HORIZ | typeof ORIENTATIONS.VERT,
  enableEdit: boolean,
  onClick: () => mixed,
  isProfilePictureLoading: boolean,
  /** Base64 image url */
  profilePictureData: string,
  uploadProfilePictureRequest: () => mixed,
};

class AvatarWrap extends React.Component<Props> {
  constructor(props) {
    super(props);
    this.fileRef = React.createRef();
  }

  getUploadProPicIcon = () => {
    return (
      <div className="Avatar-UploadIcon">
        <IconButton
          disableFocusRipple
          disableRipple
          disableTouchRipple
          className="Avatar-UploadIcon-IconButton"
          onClick={() => this.fileRef.current.click()}
        >
          <CameraAlt className="Avatar-UploadIcon-IconButton-Icon" />
        </IconButton>
        <input
          ref={this.fileRef}
          onChange={this.handleFileChange}
          onClick={(event) => {
            event.target.value = null;
          }}
          multiple={false}
          type="file"
          accept="image/png, image/jpeg, image/jpg"
          hidden
        />
      </div>
    );
  };

  getAvatar = () => {
    const {
      name,
      showDefaultColor,
      height,
      width,
      fontSize,
      enableEdit,
      orientation,
      onClick,
      isProfilePictureLoading,
      profilePictureData,
    } = this.props;

    // New profile picture flow requires a bearer token
    return (
      <span className="Avatar" data-testid="Avatar">
        <Avatar
          style={{
            height,
            width,
            fontSize: fontSizeToRem(fontSize),
            // use mui theme default color if showDefaultColor is true
            ...(!showDefaultColor ? { backgroundColor: this.stringToColor(name) } : {}),
            // If there's a click action, show a pointer cursor on hover
            ...(onClick ? { cursor: 'pointer' } : {}),
          }}
          className={orientation}
          src={isProfilePictureLoading ? '' : profilePictureData}
          onClick={this.props.onClick}
        >
          {name.charAt(0).toUpperCase()}
        </Avatar>
        {enableEdit && this.getUploadProPicIcon()}
      </span>
    );
  };

  handleFileChange = (e) => {
    const [file] = e.target.files;
    this.props.uploadProfilePictureRequest({ file });
  };

  stringToColor = (string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    // get a value ranging from 0 to 360 from the hash
    const hue = hash % 360;

    return `hsl(${hue}, 55%, 45%)`;
  };

  render() {
    const { name, showTooltip } = this.props;

    return showTooltip ? <Tooltip title={name}>{this.getAvatar()}</Tooltip> : this.getAvatar();
  }
}

const mapStateToProps = (state, props) => ({
  isProfilePictureLoading: selectIsProfilePictureLoading(state),
  profilePictureData: selectProfilePictureByUserId(state, props.userId),
});
export default connect(mapStateToProps, {
  uploadProfilePictureRequest,
})(AvatarWrap);
