import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { VISUAL_TYPES } from '../../../constants';
import { selectIsPopOutEditable } from '../../../store/typedSelectors';
import {
  useAlertInfo,
  useEditingInfo,
  useModalPng,
  useModeBar,
  usePanMode,
  useTabVisualState,
} from './helpers/visualCustomHooks';

/**
 * A component to provide state for the TabVisual component.
 */
const TabVisualStateProvider = (props) => {
  const { children, tabs, tabContents, isPopOutChart, setEditingModal } = props;

  /**
   * General state variables.
   * These are independent of the selected visual.
   */

  // state to control which tab is selected
  const [selectedTab, setSelectedTab, selectedTabIndex, setSelectedTabIndex, selectedContent] =
    useTabVisualState(tabs, tabContents);

  /**
   * Helper function to return the total row count of a table.
   */
  const getRowCount = (tabContent) => {
    let initRowCount = 0;
    if (tabContent.type === VISUAL_TYPES.TABLE) {
      initRowCount = tabContent.data.totalRowCount;
    }
    return initRowCount;
  };

  /**
   * Visual-specific state variables.
   * These are specific to the selected visual and should be reset when the selected visual changes.
   */

  // state to control mode option button for chart
  const [showModeBar, toggleModeBar] = useModeBar();

  const [isPanMode, togglePanMode] = usePanMode();

  // state to control chart export png
  const [showModalPng, updateModalPng, setModalPng] = useModalPng();

  // state to control how many rows to show in a table
  const initRowCount = getRowCount(selectedContent);
  const [rowCount, setRowCount] = useState(initRowCount);

  // state to control alert info for echarts
  const [alertOpen, alertText, openCaptionAlert, setAlertOpen] = useAlertInfo();

  // state to control the copy message
  const [copyMsg, setCopyMsg] = useState('Copy link to clipboard');

  // state to control editing functionality
  const { chartEditingMode, showEditMenu, keepChanges, resetEditingMenu, toggleChartEditingMode } =
    useEditingInfo(
      selectedContent.data,
      isPopOutChart,
      selectedContent.type,
      selectedContent.typeVersion,
      setEditingModal,
      selectedContent.update,
    );

  const isPopoutEditable = useSelector(selectIsPopOutEditable);

  /**
   * Sets the selected tab and resets state variables.
   * @param {String} newSelectedTab label of the new selected tab
   * @param {Number} newSelectedIndex index into the tab list of the new selected tab
   */
  const changeSelectedTab = (event, newSelectedTab, newSelectedIndex) => {
    if (selectedTab !== newSelectedTab) {
      // only update state if tab has actually changed
      // update the selected tab, tab index, and selected content
      setSelectedTab(newSelectedTab);
      setSelectedTabIndex(newSelectedIndex);
      const newSelectedContent = tabContents[newSelectedTab];
      if (showModeBar) {
        toggleModeBar();
      }
      setModalPng(false);
      const newTotalRowCount = getRowCount(newSelectedContent);
      setRowCount(newTotalRowCount);
      setAlertOpen(false);
      resetEditingMenu();
    }
  };

  const toggleChartEditingModeAndClosePanMode = (saveChanges, editMode) => {
    toggleChartEditingMode(saveChanges, editMode);
    togglePanMode(false);
  };

  return (
    <>
      {children(
        selectedTab,
        selectedTabIndex,
        selectedContent,
        changeSelectedTab,
        rowCount,
        copyMsg,
        setCopyMsg,
        showModeBar,
        toggleModeBar,
        isPanMode,
        togglePanMode,
        showModalPng,
        updateModalPng,
        alertOpen,
        alertText,
        setAlertOpen,
        openCaptionAlert,
        chartEditingMode,
        showEditMenu,
        keepChanges,
        toggleChartEditingModeAndClosePanMode,
        isPopoutEditable,
      )}
    </>
  );
};

TabVisualStateProvider.propTypes = {
  children: PropTypes.func.isRequired,
  tabs: PropTypes.array.isRequired,
  tabContents: PropTypes.object.isRequired,
  isPopOutChart: PropTypes.bool.isRequired,
  setEditingModal: PropTypes.func.isRequired,
};

export default TabVisualStateProvider;
