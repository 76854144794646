import { call, put, select, takeLatest } from 'redux-saga/effects';
import { checkForFreeTrial } from '../../api/subscriptionCheckFreeTrial.api';
import {
  CHECK_FOR_FREE_TRIAL_REQUEST,
  canUseFreeTrialFailure,
  canUseFreeTrialSuccess,
} from '../actions/subscriptionCheckFreeTrial.actions';
import { selectAccessToken } from './selectors';

function* canUseFreeTrialRequestWorker() {
  try {
    const accessToken = yield select(selectAccessToken);
    const canUseFreeTrial = yield call(checkForFreeTrial, accessToken);

    yield put(
      canUseFreeTrialSuccess({
        canUseFreeTrial: canUseFreeTrial.data,
      }),
    );
  } catch (err) {
    yield put(canUseFreeTrialFailure(err));
  }
}

export default function* () {
  yield takeLatest(CHECK_FOR_FREE_TRIAL_REQUEST, canUseFreeTrialRequestWorker);
}
