export const GET_EXAMPLES_REQUEST = 'examples/GET_EXAMPLES_REQUEST';
export const GET_EXAMPLES_SUCCESS = 'examples/GET_EXAMPLES_SUCCESS';
export const GET_EXAMPLES_FAILURE = 'examples/GET_EXAMPLES_FAILURE';

export const SELECT_EXAMPLE_SUCCESS = 'examples/SELECT_EXAMPLE_SUCCESS';

// Get all of the example information, ex. Ava example cards
export const getExamples = () => ({
  type: GET_EXAMPLES_REQUEST,
});

// Getting all of the example information succeeded
export const getExamplesSuccess = ({ examples }) => ({
  type: GET_EXAMPLES_SUCCESS,
  examples,
});

// Getting all of the example information failed
export const getExamplesFailure = ({ error }) => ({
  type: GET_EXAMPLES_FAILURE,
  error,
});

// Select one of the examples
export const selectExampleSuccess = ({ selectedExample }) => ({
  type: SELECT_EXAMPLE_SUCCESS,
  selectedExample,
});
