import { call, put, select, takeEvery } from 'redux-saga/effects';
import { getInvoices, getUpcomingInvoice } from '../../api/subscriptions.api';
import {
  GET_INVOICES_REQUEST,
  GET_UPCOMING_INVOICE_REQUEST,
  getInvoicesFailure,
  getInvoicesSuccess,
  getUpcomingInvoiceFailure,
  getUpcomingInvoiceSuccess,
} from '../actions/invoice.actions';
import { selectAccessToken } from './selectors';

// Fetch the users invoices
function* getInvoicesRequestWorker() {
  try {
    const accessToken = yield select(selectAccessToken);
    const { data } = yield call(getInvoices, accessToken);
    yield put(getInvoicesSuccess({ data: data.invoices }));
  } catch (error) {
    yield put(getInvoicesFailure({ error }));
  }
}

// Fetch the user upcoming invoice for their current subscription
function* getUpcomingInvoiceRequestWorker() {
  try {
    const accessToken = yield select(selectAccessToken);
    const { data } = yield call(getUpcomingInvoice, accessToken);
    yield put(getUpcomingInvoiceSuccess({ data: data.invoice }));
  } catch (error) {
    yield put(getUpcomingInvoiceFailure({ error }));
  }
}

export default function* () {
  yield takeEvery(GET_INVOICES_REQUEST, getInvoicesRequestWorker);
  yield takeEvery(GET_UPCOMING_INVOICE_REQUEST, getUpcomingInvoiceRequestWorker);
}
