import { createSlice } from '@reduxjs/toolkit';

import {
  AddPreviewTablePayload,
  BrowserTable,
  ConnectionListSuccessPayload,
  CreateDatasetsCompletePayload,
  Database,
  DatabaseBrowserState,
  GetBrowserTableMetadataFailurePayload,
  GetBrowserTableMetadataRequestPayload,
  GetBrowserTableMetadataSuccessPayload,
  ListNamespacesFailurePayload,
  ListNamespacesRequestPayload,
  ListNamespacesSuccessPayload,
  ListNamespaceTablesFailurePayload,
  ListNamespaceTablesRequestPayload,
  ListNamespaceTablesSuccessPayload,
  ModifySelectedFolderPayload,
  ModifySelectedTablePayload,
  Namespace,
  OpenDatabaseBrowserPayload,
  RemovePreviewTablePayload,
  RequestStatus,
  SelectPreviewTablePayload,
  SetConnectionPayload,
  SetDatabaseFilterTermPayload,
} from '../../types/databaseBrowser.types';
import { HomeObjectKeys } from '../../utils/homeScreen/types';

export const defaultTable: BrowserTable = {
  name: '',
  columns: null,
  rows: null,
  metadataStatus: RequestStatus.Unrequested,
  lastUpdated: null,
  errorMessage: null,
};

export const defaultNamespace: Namespace = {
  tables: {},
  requestingTablesStatus: RequestStatus.Unrequested,
  totalTableCount: 0,
  lastUpdated: null,
  errorMessage: null,
};

export const defaultDatabase: Database = {
  namespaces: {},
  selectedTables: {},
  selectedPreviewTables: {},
  selectedPreview: null,
  requestingNamespacesStatus: RequestStatus.Unrequested,
  totalNamespaceCount: 0,
  lastUpdated: null,
  errorMessage: null,
  filterTerm: '',
};

export const initialState: DatabaseBrowserState = {
  open: false,
  openConnection: null,
  databases: {},
  connections: {},
  connectionsRequestStatus: RequestStatus.Unrequested,
  creatingDatasetRequestStatus: RequestStatus.Unrequested,
  failedDatasetCreation: [],
  hideNavigation: false,
};

const dbBrowserSlice = createSlice({
  name: 'dbBrowser',
  initialState,
  reducers: {
    openDatabaseBrowser: (state: DatabaseBrowserState, { payload }: OpenDatabaseBrowserPayload) => {
      const { hideNavigation, connection } = payload;

      state.databases = initialState.databases;
      state.connections = initialState.connections;
      state.connectionsRequestStatus = initialState.connectionsRequestStatus;
      state.creatingDatasetRequestStatus = initialState.creatingDatasetRequestStatus;
      state.failedDatasetCreation = initialState.failedDatasetCreation;

      state.open = true;
      state.hideNavigation = Boolean(hideNavigation);
      state.openConnection = connection;
    },
    closeDatabaseBrowser: (state: DatabaseBrowserState) => {
      state.databases = initialState.databases;
      state.connections = initialState.connections;
      state.connectionsRequestStatus = initialState.connectionsRequestStatus;
      state.creatingDatasetRequestStatus = initialState.creatingDatasetRequestStatus;
      state.failedDatasetCreation = initialState.failedDatasetCreation;

      state.open = false;
      state.hideNavigation = initialState.hideNavigation;
      state.openConnection = initialState.openConnection;
    },

    setConnection: (state: DatabaseBrowserState, { payload }: SetConnectionPayload) => {
      const { connection } = payload;
      if (!connection) state.openConnection = null;
      else {
        const connUUID = connection[HomeObjectKeys.UUID];
        const prevConnData: Database = { ...state.databases[connUUID] };
        const newConnData: Database =
          Object.keys(prevConnData).length > 0 ? prevConnData : { ...defaultDatabase };

        state.openConnection = connection;
        state.databases = {
          ...state.databases,
          [connUUID]: newConnData,
        };
      }
    },

    connectionListRequest: (state: DatabaseBrowserState) => {
      state.connectionsRequestStatus = RequestStatus.Requesting;
    },
    connectionListSuccess: (
      state: DatabaseBrowserState,
      { payload }: ConnectionListSuccessPayload,
    ) => {
      const { connections } = payload;
      state.connectionsRequestStatus = RequestStatus.Success;
      // Store data
      Object.keys(connections).forEach((connUUID: string) => {
        // Populate each database with default data
        state.databases[connUUID] = state.databases[connUUID] || { ...defaultDatabase };
        // Populate the connections with the connection data
        if (connections[connUUID]) state.connections[connUUID] = connections[connUUID];
      });
    },

    connectionListFailure: {
      reducer: (state: DatabaseBrowserState) => {
        state.connections = {};
        state.connectionsRequestStatus = RequestStatus.Failure;
      },
      prepare: (error: string) => {
        return { payload: { error } };
      },
    },

    createDatasetsRequest: {
      reducer: (state: DatabaseBrowserState) => {
        state.creatingDatasetRequestStatus = RequestStatus.Requesting;
        state.failedDatasetCreation = [];
      },
      prepare: ({ openInSession }: { openInSession: boolean }) => ({
        payload: { openInSession },
      }),
    },
    createDatasetsComplete: (
      state: DatabaseBrowserState,
      { payload }: CreateDatasetsCompletePayload,
    ) => {
      const { failedDatasets } = payload;
      state.failedDatasetCreation = failedDatasets;
      state.creatingDatasetRequestStatus =
        failedDatasets.length > 0 ? RequestStatus.Failure : RequestStatus.Success;
    },
    createDatasetsCancel: (state: DatabaseBrowserState) => {
      state.creatingDatasetRequestStatus = RequestStatus.Success;
      state.failedDatasetCreation = [];
    },

    modifySelectedTable: (state: DatabaseBrowserState, { payload }: ModifySelectedTablePayload) => {
      const { namespace, table } = payload;
      const openConnectionUUID = state.openConnection?.[HomeObjectKeys.UUID];
      // If there is no open connection, we cannot select a table
      if (!openConnectionUUID) return;

      const prevSelectedNamespaceTables =
        state.databases[openConnectionUUID].selectedTables[namespace];
      if (prevSelectedNamespaceTables === undefined) {
        // No tables have been selected in this namespace yet
        state.databases[openConnectionUUID].selectedTables[namespace] = [table];
      } else if (prevSelectedNamespaceTables.includes(table)) {
        // The table is already selected, so deselect it
        state.databases[openConnectionUUID].selectedTables[namespace] =
          prevSelectedNamespaceTables.filter((selectedTable) => selectedTable !== table);
      } else {
        // The table is not selected, so select it
        state.databases[openConnectionUUID].selectedTables[namespace] = [
          ...prevSelectedNamespaceTables,
          table,
        ];
      }
    },
    modifySelectedFolder: (
      state: DatabaseBrowserState,
      { payload }: ModifySelectedFolderPayload,
    ) => {
      const { namespace } = payload;
      const openConnectionUUID = state.openConnection?.[HomeObjectKeys.UUID];
      // If there is no open connection, we cannot select a table
      if (!openConnectionUUID) return;

      // If we have not fetched all of the tables in the namespace, we cannot select all of them
      const namespaceTables = state.databases[openConnectionUUID].namespaces?.[namespace]?.tables;
      const numFetchedTables = Object.keys(namespaceTables ?? {}).length;
      const totalTableCount =
        state.databases[openConnectionUUID].namespaces?.[namespace]?.totalTableCount ?? 0;
      if (!totalTableCount || !numFetchedTables) return;

      const selectedNamespaceTables = state.databases[openConnectionUUID].selectedTables[namespace];
      const numSelectedTables = selectedNamespaceTables?.length ?? 0;

      if (numSelectedTables === 0) {
        // No tables are selected, so select all
        state.databases[openConnectionUUID].selectedTables[namespace] =
          Object.keys(namespaceTables);
      } else {
        // Not all tables are selected, so deselect all
        state.databases[openConnectionUUID].selectedTables[namespace] = [];
      }
    },
    tryModifySelectedEntry: {
      prepare: (namespace: string, table?: string) => ({
        payload: {
          namespace,
          table,
        },
      }),
      reducer: () => {},
    },

    addPreviewTable: (state: DatabaseBrowserState, { payload }: AddPreviewTablePayload) => {
      // If there is no open connection, we cannot select a table
      const { namespace, table } = payload;
      const openConnectionUUID = state.openConnection?.[HomeObjectKeys.UUID];
      if (!openConnectionUUID) return;

      // Add the table to the list of selected preview tables
      const prevPreviewTables =
        state.databases[openConnectionUUID].selectedPreviewTables[namespace];
      // check if the table is already added
      if (!prevPreviewTables?.includes(table)) {
        const newPreviewTables = prevPreviewTables ? [...prevPreviewTables, table] : [table];
        state.databases[openConnectionUUID].selectedPreviewTables[namespace] = newPreviewTables;
      }

      // Set the selected preview table to the new table
      state.databases[openConnectionUUID].selectedPreview = { namespace, table };
    },
    refreshPreviewTable: {
      prepare: (namespace: string, table: string) => ({
        payload: {
          namespace,
          table,
        },
      }),
      reducer: () => {},
    },
    removePreviewTable: (state: DatabaseBrowserState, { payload }: RemovePreviewTablePayload) => {
      // If there is no open connection, we cannot deselect a table
      const { namespace, table } = payload;
      const openConnectionUUID = state.openConnection?.[HomeObjectKeys.UUID];
      if (!openConnectionUUID) return;

      // Remove the table from the list of selected preview tables
      const prevPreviewTables =
        state.databases[openConnectionUUID].selectedPreviewTables[namespace] ?? [];
      if (!prevPreviewTables) return;
      const newPreviewTables = prevPreviewTables?.filter((previewTable) => previewTable !== table);
      state.databases[openConnectionUUID].selectedPreviewTables[namespace] = newPreviewTables;

      // Set the selected preview table to the first table in the list of selected preview tables
      const newSelectedPreviewTable = newPreviewTables[0];
      state.databases[openConnectionUUID].selectedPreview = newSelectedPreviewTable
        ? { namespace, table: newSelectedPreviewTable }
        : null;
    },
    selectPreviewTable: (state: DatabaseBrowserState, { payload }: SelectPreviewTablePayload) => {
      const { namespace, table } = payload;
      const openConnectionUUID = state.openConnection?.[HomeObjectKeys.UUID];
      if (!openConnectionUUID) return;
      state.databases[openConnectionUUID].selectedPreview = { namespace, table };
    },

    listNamespacesRequest: (
      state: DatabaseBrowserState,
      { payload }: ListNamespacesRequestPayload,
    ) => {
      const { connectionUUID } = payload;
      state.databases[connectionUUID] = {
        ...state.databases[connectionUUID],
        requestingNamespacesStatus: RequestStatus.Requesting,
        errorMessage: null,
      };
    },
    listNamespacesSuccess: (
      state: DatabaseBrowserState,
      { payload }: ListNamespacesSuccessPayload,
    ) => {
      const { connectionUUID, namespaces, lastUpdated, refresh, totalNamespaceCount } = payload;
      // If we are refreshing the namespaces, clear the existing namespaces and preview tables
      if (refresh) {
        state.databases[connectionUUID] = {
          ...state.databases[connectionUUID],
          namespaces: {},
          selectedPreviewTables: {},
          selectedPreview: null,
        };
      }

      // Add the namespaces to the database
      namespaces.forEach((namespace) => {
        state.databases[connectionUUID].namespaces[namespace] = { ...defaultNamespace };
      });

      // Update the status of the request
      state.databases[connectionUUID] = {
        ...state.databases[connectionUUID],
        requestingNamespacesStatus: RequestStatus.Success,
        totalNamespaceCount,
        lastUpdated:
          refresh || !state.databases[connectionUUID]?.lastUpdated
            ? lastUpdated
            : state.databases[connectionUUID].lastUpdated,
        errorMessage: null,
      };
    },
    listNamespacesFailure: (
      state: DatabaseBrowserState,
      { payload }: ListNamespacesFailurePayload,
    ) => {
      const { connectionUUID, error } = payload;
      state.databases[connectionUUID] = {
        ...state.databases[connectionUUID],
        requestingNamespacesStatus: RequestStatus.Failure,
        errorMessage: error,
      };
    },

    listNamespaceTablesRequest: (
      state: DatabaseBrowserState,
      { payload }: ListNamespaceTablesRequestPayload,
    ) => {
      const { connectionUUID, namespace, page } = payload;
      state.databases[connectionUUID].namespaces[namespace] = {
        ...state.databases[connectionUUID].namespaces[namespace],
        requestingTablesStatus:
          page === 0 ? RequestStatus.Requesting : RequestStatus.RequestingMore,
        errorMessage: null,
      };
    },
    listNamespaceTablesSuccess: (
      state: DatabaseBrowserState,
      { payload }: ListNamespaceTablesSuccessPayload,
    ) => {
      const { connectionUUID, namespace, tables, lastUpdated, refresh, totalTableCount } = payload;
      // Construct what the new tables object should look like
      const previousTables = { ...state.databases[connectionUUID].namespaces[namespace].tables };

      let newTablesObject: { [tableName: string]: BrowserTable } = {
        ...(refresh ? {} : previousTables),
      };
      tables.forEach((table) => {
        // use existing data if present
        const existingTable = {
          ...(previousTables?.[table] || defaultTable),
        };
        newTablesObject = {
          ...newTablesObject,
          [table]: {
            ...existingTable,
            name: table,
          },
        };
      });

      // Update the tables in the namespace
      state.databases[connectionUUID].namespaces[namespace].tables = newTablesObject;

      // Update the status of the request
      state.databases[connectionUUID].namespaces[namespace] = {
        ...state.databases[connectionUUID].namespaces[namespace],
        requestingTablesStatus: RequestStatus.Success,
        totalTableCount,
        errorMessage: null,
        lastUpdated:
          refresh || !state.databases[connectionUUID]?.namespaces?.[namespace]?.lastUpdated
            ? lastUpdated
            : state.databases[connectionUUID]?.namespaces?.[namespace]?.lastUpdated,
      };

      // remove any preview tables that are no longer in the namespace
      const previewTables = state.databases[connectionUUID].selectedPreviewTables[namespace];
      if (previewTables) {
        const newPreviewTables = previewTables.filter((previewTable) =>
          tables.includes(previewTable),
        );
        state.databases[connectionUUID].selectedPreviewTables[namespace] = newPreviewTables;
      }

      // If the selected preview table is no longer in the namespace, set it to a different value
      const { selectedPreview } = state.databases[connectionUUID];
      if (
        selectedPreview &&
        selectedPreview.namespace === namespace &&
        !tables.includes(selectedPreview.table)
      ) {
        // Find a new table to preview from PreviewTables
        const allPreviewTables = state.databases[connectionUUID]?.selectedPreviewTables || {};
        let newPreview: { namespace: string; table: string } | null = null;
        // Search the preview table list for any table in the database, stop searching after the first one
        for (let i = 0; i < Object.keys(allPreviewTables).length; i++) {
          const ns = Object.keys(allPreviewTables)[i];
          if (allPreviewTables[ns]?.length > 0) {
            newPreview = {
              namespace,
              table: allPreviewTables[ns][0],
            };
            break;
          }
        }
        // set the new selected preview table
        state.databases[connectionUUID].selectedPreview = newPreview;
      }
    },
    listNamespaceTablesFailure: (
      state: DatabaseBrowserState,
      { payload }: ListNamespaceTablesFailurePayload,
    ) => {
      const { connectionUUID, namespace, error } = payload;
      state.databases[connectionUUID].namespaces[namespace] = {
        ...state.databases[connectionUUID].namespaces[namespace],
        requestingTablesStatus: RequestStatus.Failure,
        errorMessage: error,
      };
    },
    listNamespaceTablesCancelled: () => {},

    getBrowserTableMetadataRequest: (
      state: DatabaseBrowserState,
      { payload }: GetBrowserTableMetadataRequestPayload,
    ) => {
      const { connectionUUID, namespace, table } = payload;
      state.databases[connectionUUID].namespaces[namespace].tables[table] = {
        ...state.databases[connectionUUID].namespaces[namespace].tables[table],
        metadataStatus: RequestStatus.Requesting,
        errorMessage: null,
      };
    },
    getBrowserTableMetadataSuccess: (
      state: DatabaseBrowserState,
      { payload }: GetBrowserTableMetadataSuccessPayload,
    ) => {
      const { connectionUUID, namespace, table, rows, columns, refresh, lastUpdated } = payload;
      state.databases[connectionUUID].namespaces[namespace].tables[table] = {
        ...state.databases[connectionUUID].namespaces[namespace].tables[table],
        rows,
        columns,
        metadataStatus: RequestStatus.Success,
        errorMessage: null,
        lastUpdated:
          refresh ||
          !state.databases[connectionUUID]?.namespaces?.[namespace]?.tables?.[table]?.lastUpdated
            ? lastUpdated
            : state.databases[connectionUUID].namespaces[namespace].tables[table].lastUpdated,
      };
    },
    getBrowserTableMetadataFailure: (
      state: DatabaseBrowserState,
      { payload }: GetBrowserTableMetadataFailurePayload,
    ) => {
      const { connectionUUID, namespace, table, error } = payload;
      state.databases[connectionUUID].namespaces[namespace].tables[table] = {
        ...state.databases[connectionUUID].namespaces?.[namespace]?.tables?.[table],
        metadataStatus: RequestStatus.Failure,
        errorMessage: error,
        // Clear the rows and columns if the request failed
        rows: defaultTable.rows,
        columns: defaultTable.columns,
        lastUpdated: defaultTable.lastUpdated,
      };
    },

    setDatabaseFilterTerm: (
      state: DatabaseBrowserState,
      { payload }: SetDatabaseFilterTermPayload,
    ) => {
      const { connectionUUID, filterTerm } = payload;
      state.databases[connectionUUID] = {
        ...state.databases[connectionUUID],
        filterTerm,
      };

      // For each namespace that has fetched data, set the request status to requesting
      const namespaces = state.databases[connectionUUID]?.namespaces || {};
      Object.keys(namespaces).forEach((namespace) => {
        if (namespaces[namespace].requestingTablesStatus !== RequestStatus.Unrequested) {
          state.databases[connectionUUID].namespaces[namespace] = {
            ...state.databases[connectionUUID].namespaces[namespace],
            requestingTablesStatus: RequestStatus.Requesting,
          };
        }
      });
    },
  },
});

export const {
  listNamespacesRequest,
  listNamespacesSuccess,
  listNamespacesFailure,
  listNamespaceTablesFailure,
  listNamespaceTablesRequest,
  listNamespaceTablesSuccess,
  listNamespaceTablesCancelled,
  getBrowserTableMetadataRequest,
  getBrowserTableMetadataSuccess,
  getBrowserTableMetadataFailure,
  setDatabaseFilterTerm,
  openDatabaseBrowser,
  closeDatabaseBrowser,
  setConnection,
  connectionListRequest,
  connectionListSuccess,
  connectionListFailure,
  createDatasetsRequest,
  createDatasetsComplete,
  createDatasetsCancel,
  modifySelectedTable,
  modifySelectedFolder,
  tryModifySelectedEntry,
  addPreviewTable,
  refreshPreviewTable,
  removePreviewTable,
  selectPreviewTable,
} = dbBrowserSlice.actions;

export default dbBrowserSlice.reducer;
