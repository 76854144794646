/**
 * This file verifies if the user is authenticated or not.
 * It throws errors when the user is not authenticated
 * and allows execution when the user is authenticated.
 */
import moment from 'moment';
import { call, put, select } from 'redux-saga/effects';
import { putAuthenticatedFailure } from '../store/actions/auth.actions';
import { createAlertChannelRequest } from '../store/actions/dialog.actions';
import { selectAuth } from '../store/sagas/selectors';

import { NotAuthenticatedError } from './errors';

/**
 * Spins up a worker if a user has verified access to the client.
 * Otherwise, if the user has a valid refresh token, request for a
 * new access token with access and refresh token expiry dates and
 * spins up a worker upon success.
 *
 * @param {*} worker
 * @param {*} failAction
 */
export const authenticate = (worker, failAction) =>
  function* w(action) {
    const { isAuthenticated, accessExp, accessToken, refreshExp } = yield select(selectAuth);
    try {
      if (!isAuthenticated) {
        // User is not authenticated. Throw a not authenticated error.
        throw new NotAuthenticatedError('Not Authenticated.');
      }

      if (accessToken && accessExp && moment(accessExp).isAfter(moment())) {
        // User's access token is valid. Run the worker.
        yield call(worker, action);
      } else if (moment().isAfter(moment(refreshExp))) {
        // User's refresh token has expired.
        throw new NotAuthenticatedError('Refresh token has expired.');
      } else {
        // Both of the user's tokens have expired.
        throw new NotAuthenticatedError('Access and refresh tokens have expired.');
      }
    } catch (error) {
      if (error instanceof NotAuthenticatedError) {
        // Handle the not authenticated error.
        yield put(createAlertChannelRequest({ error }));
      }
      // Log the failure action.
      yield put(failAction({ error, file: action.file }));
    }
  };

/**
 * Dummy worker that just puts the action.
 * Only use below.
 */
function* putNormally(action) {
  yield put(action);
}

/**
 * Example usage: yield * putAuthenicated(myAction())
 * Put an action that requires authentication.
 * If not authenticated, authenticate will try to refresh creds
 * and put the action.  Otherwise our dummy worker will just put.
 */
export const putAuthenticated = authenticate(putNormally, putAuthenticatedFailure);
