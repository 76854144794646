import MoreVert from '@mui/icons-material/MoreVert';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import Popper from '@mui/material/Popper';
import PropTypes from 'prop-types';
import React, { useContext, useRef, useState } from 'react';
import { ScreenSizeContext } from '../../../../DataAssistant/DataAssistant';
import './AvaActionMenu.scss';

/**
 * Provides styling to a collection of action buttons for conversation messages.
 */
const AvaActionMenu = ({ children }) => {
  const isSmallScreen = useContext(ScreenSizeContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const buttonGroupRef = useRef(null);

  const renderButtons = () => <ButtonGroup orientation="vertical">{children}</ButtonGroup>;

  // at least one child must be truthy to render
  const shouldRender = children.some((child) => child !== null);
  if (!shouldRender) return null;

  return isSmallScreen ? (
    <div className="AvaActionMenu SmallScreenSize">
      <IconButton size="small" onClick={(e) => setAnchorEl(e.currentTarget)}>
        <MoreVert fontSize="inherit" />
      </IconButton>
      <Popper disablePortal anchorEl={anchorEl} open={Boolean(anchorEl)} placement="top">
        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
          <div ref={buttonGroupRef}>{renderButtons()}</div>
        </ClickAwayListener>
      </Popper>
    </div>
  ) : (
    <div className="AvaActionMenu">{renderButtons()}</div>
  );
};

AvaActionMenu.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default AvaActionMenu;
