import axios from 'axios';
import { endpoints } from '../constants/endpoints';

/**
 * Create a new session
 * Fail if reach concurrency limit
 * @param {String} accessToken
 * @param {String} session_id
 * @param {String} app_context
 * @param {Integer} user_id
 * @param {Boolean} vote
 */
export const postUserFeedback = (accessToken, userId, appContext, vote, content, sessionId) =>
  axios.post(
    endpoints.userFeedback,
    { user_id: userId, app_context: appContext, vote, content, session_id: sessionId },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

export const getUserFeedback = (accessToken, sessionId) =>
  axios.get(endpoints.userFeedback, {
    params: {
      sessionId,
    },
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

export const editUserFeedback = (accessToken, feedbackId, vote) =>
  axios.put(
    `${endpoints.userFeedback}/${feedbackId}`,
    { vote },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
