import CheckBox from '@mui/icons-material/CheckBox';
import Folder from '@mui/icons-material/Folder';
import IndeterminateCheckBox from '@mui/icons-material/IndeterminateCheckBox';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAllTablesChecked,
  selectNamespaceHasFetchedAllTables,
  selectNamespaceTables,
  selectSomeTablesChecked,
} from '../../../../store/selectors/dbBrowser.selector';
import { tryModifySelectedEntry } from '../../../../store/slices/dbBrowser.slice';
import TextWithTooltip from '../../../common/TextWithTooltip';
import { StickyWrapperContentProps } from '../../StickyWrapper/StickyWrapper';
import './NamespaceItem.scss';

const NamespaceItem: React.FC<StickyWrapperContentProps> = ({ parents, current }) => {
  const connectionUUID = parents?.[0]?.id ?? '';
  const namespace = current.name;
  const dispatch = useDispatch();
  // Boolean that is true if every child item is selected
  const allTablesSelected = useSelector(selectAllTablesChecked(connectionUUID, namespace));
  // Boolean that is true if at least one item is selected
  const someTablesSelected = useSelector(selectSomeTablesChecked(connectionUUID, namespace));
  // Boolean that is true if any of the child items are tables
  const tablesObj = useSelector(selectNamespaceTables(connectionUUID, namespace));
  const tables = Object.keys(tablesObj);
  const folderHasTables = tables.length > 0;

  const hasFetchedAllTables = useSelector(
    selectNamespaceHasFetchedAllTables(connectionUUID, namespace),
  );

  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    dispatch(tryModifySelectedEntry(namespace));
  };

  const getCheckboxIcon = () => {
    return someTablesSelected && !allTablesSelected ? <IndeterminateCheckBox /> : <CheckBox />;
  };

  return (
    <ListItem
      className="DatabaseBrowser-Navigation-FolderItem"
      data-testid="DatabaseBrowser-Navigation-FolderItem"
    >
      <Box className="DatabaseBrowser-Navigation-FolderItem-Title">
        <ListItemIcon>
          <Folder fontSize="medium" />
        </ListItemIcon>
        <TextWithTooltip className="DatabaseBrowser-Navigation-Name" text={namespace} />
      </Box>
      {folderHasTables && (
        <Checkbox
          id={`checkbox-${namespace}`}
          data-testid={`checkbox-${namespace}`}
          edge="end"
          size="small"
          onClick={(e: React.MouseEvent) => e.stopPropagation()}
          onChange={handleCheckbox}
          checked={someTablesSelected}
          checkedIcon={getCheckboxIcon()}
          inputProps={{ 'aria-label': `checkbox-${namespace}` }}
          // Don't allow selecting all tables if not all tables have been fetched
          disabled={!hasFetchedAllTables && !someTablesSelected}
        />
      )}
    </ListItem>
  );
};

export default NamespaceItem;
