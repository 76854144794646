import CheckBox from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';
import ContentCut from '@mui/icons-material/ContentCut';
import Edit from '@mui/icons-material/Edit';
import FileCopyOutlined from '@mui/icons-material/FileCopyOutlined';
import Pause from '@mui/icons-material/Pause';
import PlayArrow from '@mui/icons-material/PlayArrow';
import SkipNextOutlined from '@mui/icons-material/SkipNextOutlined';
import Stop from '@mui/icons-material/Stop';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ASK_AVA_BUTTONS, ASK_AVA_RECIPE_NAME } from '../constants/askAva';
import { REPLAY_STATUS } from '../constants/editor';
import {
  copySelection,
  deleteSelection,
  deselectAll,
  pauseReplay,
  selectAll,
  startAskAvaReplay,
  submitUtteranceRequest,
} from '../store/actions/editor.actions';
import { UTTERANCE_STATE } from './editor';

/**
 * this will return the object expected by GET_WORKFLOW_SUCCESS reducer
 * TODO - when we implement workflow replays / more editor functionality
 *        this logic will need to be changed.
 */
export const generateWorkflowResponseForAskAvaEditor = ({
  appId,
  ownerEmail,
  userName,
  gelCode,
}) => {
  // Create temporary metadata - this will not be persisted
  const metadata = {
    appId,
    canModify: true,
    headVersion: 1,
    name: ASK_AVA_RECIPE_NAME,
    owned: true,
    ownerEmail,
    userName,
  };
  // split GEL sentences on new lines
  // const steps = gelCode.split('\n');
  // gelCode is a list
  const steps = gelCode;
  // init stepList (utteranceList in editor.reducer.js)
  const stepList = [];
  // init recipe (workflow in editor.reducer.js)
  const recipe = {};
  steps.forEach((step) => {
    // for each utterance
    // create a unique id
    const uuid = uuidv4();
    // add the unique id to the step list
    stepList.push(uuid);
    // create a default utterance state with the utterance value equal to the GEL sentence
    // save to recipe object
    recipe[uuid] = UTTERANCE_STATE({ utterance: step.text, valid: step.valid });
  });
  return {
    recipe,
    stepList,
    metadata,
  };
};

// helper function to generate contact message body if ask ava results return
// a bad confidence score.
export const generateAskAvaContactFormBody = (prompt, language, generatedCode) => {
  return `Hi - I used the DataChat Ask module to answer the following prompt:

"${prompt}"

DataChat returned the following ${language} code:

${generatedCode
  .split('\n')
  .map((line, index) => `${index + 1}. ${line}`)
  .join('\n')}

Could you please verify this for correctness?`;
};

// Helper function that returns the parent dataset options
// and the version options for each parent dataset
export const getDatasetOptions = (datasetList, baseDatasets) => {
  // init vars
  const datasetOptions = [];
  const versionOptions = [];

  // for each parent dataset
  Object.entries(datasetList).forEach((dataset) => {
    // determine if this parent dataset is a base dataset
    const isBaseDataset = baseDatasets[dataset[0]] !== undefined;
    // add it to parent dataset list
    datasetOptions.push({ name: dataset[0], base: isBaseDataset });
    // for each version within the parent dataset
    Object.entries(dataset[1]).forEach((version) => {
      // if the version has been forgotten, do not add
      if (version[1].forgotten) return;
      // is this version a base dataset
      const isBaseVersion = isBaseDataset && Number(version[0]) === 1;
      // add to version list
      versionOptions.push({
        base: isBaseVersion,
        version: Number(version[0]),
        name: dataset[0],
      });
    });
  });
  return { datasetOptions, versionOptions };
};

// helper function that returns button details based on state of editor.
export const getAskAvaButtonDetail = ({
  stepList,
  execIndex,
  isReplaying,
  replayStatus,
  isSelecting,
  canEditSelection,
  askAvaEditMode,
  generatingSolution,
}) => {
  const playButtonDisabled =
    stepList.length === execIndex || replayStatus === REPLAY_STATUS.WORKING || !isReplaying;
  const showEdit = stepList.length === execIndex || (!askAvaEditMode && !generatingSolution);
  const buttonDetail = {
    [ASK_AVA_BUTTONS.CONTINUE]: {
      icon: <PlayArrow />,
      tooltip: ASK_AVA_BUTTONS.CONTINUE,
      action: submitUtteranceRequest(true, false, false),
      disabled: playButtonDisabled,
      visible: askAvaEditMode || generatingSolution,
    },
    [ASK_AVA_BUTTONS.NEXT]: {
      icon: <SkipNextOutlined />,
      tooltip: ASK_AVA_BUTTONS.NEXT,
      action: submitUtteranceRequest(false, false, false),
      disabled: playButtonDisabled,
      visible: askAvaEditMode || generatingSolution,
    },
    [ASK_AVA_BUTTONS.PAUSE]: {
      icon: <Pause />,
      tooltip: ASK_AVA_BUTTONS.PAUSE,
      action: pauseReplay(),
      disabled: replayStatus !== REPLAY_STATUS.WORKING,
      visible: askAvaEditMode || generatingSolution,
    },
    [ASK_AVA_BUTTONS.STOP]: {
      icon: showEdit ? <Edit /> : <Stop />,
      tooltip: showEdit ? ASK_AVA_BUTTONS.EDIT : ASK_AVA_BUTTONS.STOP,
      action: startAskAvaReplay({ autoStart: false }),
      disabled: replayStatus === REPLAY_STATUS.WORKING || execIndex === 0,
      visible: true,
    },
    [ASK_AVA_BUTTONS.COPY]: {
      icon: <FileCopyOutlined />,
      tooltip: ASK_AVA_BUTTONS.COPY,
      action: copySelection(),
      disabled: !isSelecting,
      visible: askAvaEditMode,
      dividerBefore: true,
    },
    [ASK_AVA_BUTTONS.CUT]: {
      icon: <ContentCut />,
      tooltip: ASK_AVA_BUTTONS.CUT,
      action: deleteSelection(),
      disabled: !canEditSelection,
      visible: askAvaEditMode,
    },
    [ASK_AVA_BUTTONS.SELECT_ALL]: {
      icon: <CheckBoxOutlineBlank />,
      tooltip: ASK_AVA_BUTTONS.SELECT_ALL,
      action: selectAll(),
      disabled: false,
      visible: !isSelecting && askAvaEditMode,
    },
    [ASK_AVA_BUTTONS.DESELECT_ALL]: {
      icon: <CheckBox />,
      tooltip: ASK_AVA_BUTTONS.DESELECT_ALL,
      action: deselectAll(),
      disabled: false,
      visible: isSelecting && askAvaEditMode,
    },
  };

  return buttonDetail;
};

export const removeStrongTags = (message) => message.replace(/(<([^>]+)>)/gi, '');
