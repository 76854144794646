import {
  all,
  call,
  cancel,
  delay,
  fork,
  put,
  race,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import { getComplete } from '../../api/complete.api';
import { authenticate } from '../../utils/authenticate';
import { parseJSONIfPossible } from '../../utils/string';
import { isPrompt } from '../../utils/suggestions_utils';
import { getCurrentTime } from '../../utils/time';
import {
  LOAD_SUGGESTIONS_FAILURE,
  LOAD_SUGGESTIONS_REQUEST,
  LOAD_SUGGESTIONS_SUCCESS,
  loadSuggestionsFailure,
  loadSuggestionsSuccess,
  showSuggestionsLoadingIndicator,
} from '../actions/suggestions.actions';
import { selectSession } from '../selectors/session.selector';
import { selectAccessToken, selectRequestingUtterance, selectTimeOfRequest } from './selectors';

/**
 * Manages the API request for suggestions.
 * Indicates if the response is a success or a failure.
 *
 * @param {Object} message The message to be sent to the server
 */
export function* loadSuggestionsWorker({ message, contextId, textboxId, fieldId }) {
  const timeOfRequest = yield select(selectTimeOfRequest);

  try {
    const accessToken = yield select(selectAccessToken);
    const session = yield select(selectSession);
    const response = yield call(
      getComplete,
      message,
      accessToken,
      session,
      true, // Self interrupting AC
    );
    // TODO: Use axios interceptors to retrieve the exact time of request and response for a more accurate result.
    const timeOfResponse = getCurrentTime();
    const timeTaken = timeOfResponse - timeOfRequest;
    // Separate suggestions and prompts
    // Ignore null in tuple for prompts (take only second value)
    // and ignore entity name in tuple for suggestions (take only first value).
    const suggestions = [];
    const prompts = [];
    const results = [];
    for (let i = 0; i < response.data.length; i++) {
      const curr = response.data[i];
      results.push([parseJSONIfPossible(curr[0]), curr[1]]);
      if (isPrompt(curr)) {
        prompts.push(curr[1]);
      } else {
        // If we have both prompt and suggestion, send both of them to textbox
        // so that when none of the suggestions match with user input
        // we can still show the prompts.
        prompts.push(curr[1]);
        suggestions.push(parseJSONIfPossible(curr[0]));
      }
    }
    suggestions.push('FieldId');
    suggestions.push('TextboxId');
    suggestions.push('ContextId');
    prompts.push(fieldId);
    prompts.push(textboxId);
    prompts.push(contextId);
    const utterance = yield select(selectRequestingUtterance);
    // Only dispatch LOAD_SUGGESTIONS_SUCCESS when it is the utterance currently requested
    // There are cases that the new request has been dispatched at the moment. Discard the result for the stale request
    if (message === utterance) {
      yield put(
        loadSuggestionsSuccess({
          suggestions,
          prompts,
          timeOfResponse,
          timeTaken,
          message,
          res: results,
        }),
      );
    }
  } catch (error) {
    const timeOfResponse = getCurrentTime();
    const timeTaken = timeOfResponse - timeOfRequest;
    yield put(loadSuggestionsFailure({ error, timeOfResponse, timeTaken }));
  }
}

/**
 * Spins up a worker that manages the latest API request for suggestions.
 */
export function* loadSuggestionsWatcher() {
  yield takeLatest(
    LOAD_SUGGESTIONS_REQUEST,
    authenticate(loadSuggestionsWorker, loadSuggestionsFailure),
  );
}

/**
 * Updates the global state to show the loading suggestions indicator after some time.
 */
export function* loadingIndicatorWorker() {
  yield delay(100);
  yield put(showSuggestionsLoadingIndicator());
}

/**
 * Spins up a worker that updates the global state to show the loading
 * suggestions indicator after some time.upon receiving a LOAD_SUGGESTIONS_REQUEST.
 * Destroys the previous task if a new LOAD_SUGGESTIONS_REQUEST is received or
 * the suggestions response is received.
 */
export function* showLoadingIndicatorWatcher() {
  let lastTask;
  while (true) {
    const { request } = yield race({
      request: take(LOAD_SUGGESTIONS_REQUEST),
      success: take(LOAD_SUGGESTIONS_SUCCESS),
      failure: take(LOAD_SUGGESTIONS_FAILURE),
    });
    if (lastTask) yield cancel(lastTask);
    if (request) lastTask = yield fork(loadingIndicatorWorker);
  }
}

/**
 * Spins up event listeners.
 */
export default function* () {
  yield all([fork(loadSuggestionsWatcher), fork(showLoadingIndicatorWatcher)]);
}
