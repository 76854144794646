import { createSelector } from 'reselect';
import { RootState } from '../../configureStore';

/** Get the profilePictures state. */
export const selectProfilePictures = (state: RootState) => state.profilePictures.profilePictures;

/** Get the userIds where we've fetched the profile picture (could be null). */
export const selectUserIdsWithProfilePictures = createSelector(
  selectProfilePictures,
  (profilePictures) => Object.keys(profilePictures).map(Number),
);

/** Get the user's profile picture (could be null). */
export const selectProfilePictureByUserId = createSelector(
  [selectProfilePictures, (_, userId) => userId],
  (profilePictures, userId) => profilePictures[userId] ?? null,
);

/** Select if the profile pictures are loading. */
export const selectIsProfilePictureLoading = (state: RootState) => state.profilePictures.isLoading;

/** Select the error state for the profile picture APIs. */
export const selectProfilePicturesError = (state: RootState) => state.profilePictures.error;
