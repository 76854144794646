import React from 'react';
import { connect } from 'react-redux';
import { updateChatPanelText } from '../../store/actions/chat.actions';
import '../../styles/DataChatLink.scss';
import DataChatLink from './DataChatLink';

type Props = {
  children: React.Node,
  command: string,
  tooltip: string,
  disable: boolean,
  updateChatPanelText: Function,
};

class DataChatModifyLink extends React.Component<Props> {
  /**
   * Inject text into textbox for modification
   */
  handleClick = () => {
    this.props.updateChatPanelText({ text: this.props.command });
  };

  render() {
    return (
      <DataChatLink
        verb="modify"
        handleClick={this.handleClick}
        command={this.props.command}
        disable={this.props.disable}
        tooltip={this.props.tooltip}
      >
        {this.props.children}
      </DataChatLink>
    );
  }
}

export default connect(() => {}, {
  updateChatPanelText,
})(DataChatModifyLink);
