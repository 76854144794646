import { createSelector } from 'reselect';
import { RootState } from '../../configureStore';
import { IntegrationProvider } from '../../types/integrations.types';
import { ExternalOAuthClient, OAuthClient } from '../../types/oauth.types';

export const selectOAuthClient = (state: RootState): OAuthClient | null =>
  state.oauth?.client || null;
export const selectOAuthLoading = (state: RootState): boolean => state.oauth?.loading || false;
export const selectOAuthError = (state: RootState): string | null => state.oauth?.error || null;
export const selectOAuthRedirectURL = (state: RootState): string | null =>
  state.oauth?.redirectURL || null;
export const selectOAuthApprovalError = (state: RootState): string | null =>
  state.oauth?.approvalError || null;

/** This returns the first result in the list of clients */
export const selectExternalOAuthClientByProvider = (
  state: RootState,
  provider: IntegrationProvider,
): ExternalOAuthClient | null => state.oauth.externalOAuthClients?.[provider]?.clients?.[0] || null;

export const selectExternalOAuthClientsLoading = (
  state: RootState,
  provider: IntegrationProvider,
): boolean => state.oauth.externalOAuthClients[provider].loading;
export const selectExternalOAuthClientsError = (
  state: RootState,
  provider: IntegrationProvider,
): string | null => state.oauth.externalOAuthClients[provider].error;

export const selectSnowflakeExternalOAuthClientID = createSelector(
  [(state: RootState) => selectExternalOAuthClientByProvider(state, IntegrationProvider.SNOWFLAKE)],
  (client) => {
    return client?.id || '';
  },
);

export const selectSnowflakeOAuthClientID = createSelector(
  [(state: RootState) => selectExternalOAuthClientByProvider(state, IntegrationProvider.SNOWFLAKE)],
  (client) => client?.payload?.client_id || '',
);

export const selectSnowflakeOAuthAccountName = createSelector(
  [(state: RootState) => selectExternalOAuthClientByProvider(state, IntegrationProvider.SNOWFLAKE)],
  (client) => client?.payload?.account_name || '',
);
