import FormatListNumbered from '@mui/icons-material/FormatListNumbered';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Button to open recipe for a conversation message.
 */
const ViewRecipeAction = ({ id, onClick }) => {
  return (
    <IconButton id={`${id}-view-recipe`} size="small" onClick={onClick}>
      <FormatListNumbered fontSize="inherit" />
    </IconButton>
  );
};

ViewRecipeAction.propTypes = {
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ViewRecipeAction;
