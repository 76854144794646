import axios, { AxiosResponse } from 'axios';
import { endpoints } from '../constants/endpoints';
import { IntegrationProvider } from '../types/integrations.types';
import { ExternalOAuthClient, OAuthClient } from '../types/oauth.types';

export const disconnectOAuth = (
  accessToken: string,
  oAuthProvider: IntegrationProvider,
): Promise<AxiosResponse> => {
  return axios.delete(endpoints.oauth(oAuthProvider), {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
};

export const putOAuth = (
  accessToken: string,
  oAuthProvider: IntegrationProvider,
  code: string,
): Promise<AxiosResponse> => {
  return axios.put(
    endpoints.oauth(oAuthProvider),
    { token: code },
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    },
  );
};

export type GetOAuthsResponse = {
  provider: IntegrationProvider;
  payload: { [key: string]: any };
}[];
export const getOAuths = (accessToken: string): Promise<AxiosResponse<GetOAuthsResponse>> => {
  return axios.get(endpoints.oauth(), {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
};

export const getOAuthClient = async (
  accessToken: string,
  clientId: string,
): Promise<AxiosResponse<OAuthClient>> => {
  return axios.get(endpoints.oauthClient(clientId), {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
};
export const approveOAuthConsent = async (
  accessToken: string,
  clientId: string,
  redirectUri: string,
  state?: string,
): Promise<string> => {
  const res = await axios.get(endpoints.oauthApproveConsent, {
    params: { response_type: 'code', redirect_uri: redirectUri, client_id: clientId, state },
    headers: { Authorization: `Bearer ${accessToken}` },
  });

  if (!res.headers.location) {
    throw new Error('No location header found in response');
  }

  return res.headers.location;
};

export const getExternalOAuthClientsByProvider = async (
  accessToken: string,
  provider: IntegrationProvider,
): Promise<AxiosResponse<ExternalOAuthClient[]>> => {
  return axios.get<ExternalOAuthClient[]>(endpoints.externalOAuthClientsByProvider(provider), {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
};

/**
 * Add External OAuth Client
 * @param {String} accessToken
 * @param {Object} payload
 * @param {String} payload.client_id
 * @param {String} payload.account_name
 * @param {String} payload.client_secret
 * @param {Number} organization_id
 * @param {IntegrationProvider} provider
 */
export const putExternalOauthClient = async (
  accessToken: string,
  payload: { client_id: string; account_name: string; client_secret: string },
  organization_id: number,
  provider: IntegrationProvider,
): Promise<AxiosResponse> => {
  return axios.put(
    endpoints.externalOAuthClient,
    { payload, provider, organization_id },
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    },
  );
};

export const deleteExternalOauthClient = async (
  accessToken: string,
  clientId: number,
): Promise<AxiosResponse> => {
  return axios.delete(endpoints.externalOAuthClientById(clientId), {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
};
