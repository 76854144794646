/**
 * This file contains utility functions that manipulate strings.
 *
 */

/**
 * Returns a string with replaced characters.
 *
 * @param {String} original The initial string.
 * @param {String} replacee String to be replaced.
 * @param {String} replacer String that replaces the string to be replaced.
 */
export const replaceAllInstancesOf = (
  original: string,
  replacee: string,
  replacer: string,
): string => {
  const re = new RegExp(replacee, 'g');
  const newString = original.replace(re, replacer);
  return newString;
};

/**
 * Removes HTML from a string.
 *
 * @param {String} text The initial string.
 */
export const stripHTML = (text: string) => text.replace(/<(?:.|\n)*?>/gm, '');

/**
 * Shortens a string and replaces the removed text with three dots
 *
 * @param {String} text The initial string.
 * @param {Integer} cutoff The maximum length of the text.
 */
export const truncate = (text: string, cutoff: number) => {
  if (text.length <= cutoff) return text;
  return text.substring(0, cutoff).concat('...');
};
/**
 * Stringify an object
 *
 * @param {any} o any javascript object
 * @returns {string|number} a string representation of the object
 */
export const stringify = (o: unknown) => {
  switch (typeof o) {
    case 'object':
      return JSON.stringify(o);
    case 'number':
      return o.toString();
    default:
      return String(o);
  }
};

/**
 * Helper function for wrapping text in a strong tag
 *
 * @param {String} string text to wrap in strong tag
 * @returns {String} passed in string wrapped in strong tag
 */
export const strong = (string: string) => {
  return `<strong>${string}</strong>`;
};

/**
 * Parse a generic JavaScript object (Number, Array, Object, etc) to an object smartly
 * It is guaranteed that this function either returns a string or an object.
 *
 *
 * @param o the generic JavaScript object
 * @returns {string|object} a string if parsing failed or does not need to be turned into object
 *                          an object otherwise
 */
export const smartParseObject = (o: unknown) => {
  if (o instanceof Object) {
    return o as object;
  }

  // Some cases where we want to get the string representation
  if (
    !o ||
    Array.isArray(o) ||
    o instanceof Number ||
    (o instanceof String && !Number.isNaN(Number.parseFloat(o as string)))
  ) {
    return String(o);
  }

  try {
    // Convert javascript acceptable (but JSON unacceptable) values to String.
    // These are Infinity, -Infinity, and NaN
    o = String(o).replace(/(-)Infinity|()Infinity/g, '"$1Infinity"');
    o = String(o).replace(/NaN/, 'null');
    const result = JSON.parse(o as string) as object;

    // return string if the result is an array of empty object
    if (Array.isArray(result) || Object.entries(result).length === 0) return String(o);

    return result;
  } catch (e) {
    return String(o);
  }
};

/**
 * Remove empty lines from a string
 *
 * Example:
 *  removeEmptyLines("\n\nABC\n\nDEF\n") === "ABC\nDEF"
 * @param string
 * @return {string}
 */
export const removeEmptyLines = (string: string) =>
  string
    .split('\n')
    .filter((line) => line.length > 0)
    .join('\n');

/**
 * Trim new line characters from the start and end of a string.
 * @param string
 * @return {string}
 */
export const trimNewLines = (string: string) => string.replace(/^[\r\n]+|[\r\n]+$/g, '');

/**
 * Trim new line characters from the start of a string.
 * @param string
 * @return {string}
 */
export const trimNewLinesStart = (string: string) => string.replace(/^[\r\n]+/, '');

/**
 * Trim new line characters from the end of a string.
 * @param string
 * @return {string}
 */
export const trimNewLinesEnd = (string: string) => string.replace(/[\r\n]+$/, '');

export const parseJSONIfPossible = (string: string) => {
  if (string === 'null') return string;
  try {
    return JSON.parse(string) as object;
  } catch (err) {
    return string;
  }
};

/**
 * Filter non-string variable and empty string (include string with just space)
 */
export const isValidUtterance = (str: string) =>
  !(typeof str !== 'string' || str === undefined || str === null || str.trim().length === 0);

/**
 * Replace spaces (including nbsp) or hyphens with underscores
 *
 * @param str to be cleaned
 * @returns cleaned str
 */
export const cleanSpacesHyphens = (str: string) => str.replace(/(\s|-|&nbsp;)+/g, '_');

/**
 * Remove underscores in the beginning of a string
 *
 * @param str to be cleaned
 * @returns cleaned str
 */
export const cleanLeadingUnderscores = (str: string) => str.replace(/^_+/g, '');

// remove unnecessary characters from a given dataset name
export const removeDisallowedCharacters = (str: string) => {
  return str.replace(/[,;.@#&^$&()!~:<>|*?"\\/=`]/g, '');
};

/**
 * Remove nbsps (non-breaking spaces) from beginning or end of a string
 *
 * @param str to be cleaned
 * @returns cleaned str
 */
export const cleanEdgeNbsp = (str: string) => str.replace(/^(&nbsp;)+|(&nbsp;)+$|/gi, '');

// From the worker/dc/core/cnl/keyword.py
export const BANNED_DATASET_NAMES = [
  'math_expr',
  'data',
  'dataset',
  'name',
  'named',
  'rename',
  'current',
  '',
];
/**
 * Cleans a dataset name, first with cleanEdgeNbsp, cleanSpacesHyphens,
 * then by removing < and >, then removing special characters,
 * then cleanLeadingUnderscores, and finally prefixing ds if
 * the resulting name is banned.
 *
 * @param str to be cleaned
 * @returns cleaned str
 */
export const cleanDatasetName = (str: string) => {
  str = cleanEdgeNbsp(str);

  str = cleanSpacesHyphens(str);

  // remove invalid characters
  str = str
    .replace(/(&lt;|&gt;)/g, '') // replace < >
    .replace(/[,*+?^/={}()|[\]\\!#@$%&;'"`]/g, '');

  str = cleanLeadingUnderscores(str);

  // check reserved word
  if (BANNED_DATASET_NAMES.includes(str)) {
    str = `ds_${str}`;
  }
  return str;
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const truncateDatasetName = (datasetName: string, isSelected: boolean): string => {
  // uses the same smart truncating logic from dependencyGraph.js
  // if the dataset name is `this_is_a_long_dataset_name_with_many_characters_that_was_extended`,
  // we will set contents to `this...extended`
  // if the dataset name is long and is composed entirely of word characters (i.e. a, b, c..., A, B, C, ...)
  // then we will just truncate the name
  const regexContent =
    !isSelected && datasetName.length > 21
      ? datasetName.replace(/^([a-zA-Z0-9]+)(.+?)([a-zA-Z0-9]+)$/, '$1 ... $3')
      : datasetName;
  return !isSelected && regexContent.length > 21 ? truncate(datasetName, 18) : regexContent;
};

/**
 * circularStringify is a wrapped JSON.stringify call that replaces circular
 * references with the string '[Circular]'.
 */
export const circularStringify = (o: any) => {
  const seen = new WeakSet();
  return JSON.stringify(
    o,
    (_, v) => {
      if (typeof v === 'object' && v !== null) {
        if (seen.has(v)) return '[Circular]';
        seen.add(v);
      }
      return v;
    },
    '\t',
  );
};
