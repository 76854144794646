export const SAVE_TABLE_FORMATTING = 'tableObject/SAVE_TABLE_FORMATTING';
export const DELETE_TABLE_OBJECTS = 'tableObject/DELETE_TABLE_OBJECTS';
export const SAVE_TABLE_COLUMN_ORDER = 'tableObject/SAVE_TABLE_COLUMN_ORDER';

export const saveTableFormatting = (parentId, id, valueFormatterMap) => ({
  type: SAVE_TABLE_FORMATTING,
  parentId,
  id,
  valueFormatterMap,
});

export const saveTableColumnOrder = (parentId, id, columnsState) => ({
  type: SAVE_TABLE_COLUMN_ORDER,
  parentId,
  id,
  columnsState,
});

export const deleteTableObjects = (parentId) => ({
  type: DELETE_TABLE_OBJECTS,
  parentId,
});
