import { CLOSE_CONTACT_FORM, OPEN_CONTACT_FORM } from '../actions/contact_form.actions';

const initialState = {
  showContactForm: false, // Indicates if the contact form is open
  defaultSubject: '',
  defaultContent: '',
  defaultMessageType: '',
  hiddenDetails: '', // content to be sent but hidden from the user.
};

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_CONTACT_FORM:
      return {
        ...state,
        showContactForm: true,
        defaultSubject: action.subject,
        defaultContent: action.content,
        defaultMessageType: action.messageType,
        hiddenDetails: action.hiddenDetails,
      };
    case CLOSE_CONTACT_FORM:
      return {
        ...state,
        showContactForm: false,
        defaultSubject: '',
        defaultContent: '',
        defaultMessageType: '',
        hiddenDetails: '',
      };

    default:
      return state;
  }
};
