import React, { FC } from 'react';
import { ThemeProvider } from 'styled-components';
import { ConfigProvider, theme as antdTheme } from 'antd';
import theme from '..';

interface IProps {
  children: React.ReactNode;
}

const Provider: FC<IProps> = ({ children }) => {
  const { colors } = theme;

  return (
    <ConfigProvider
      theme={{
        // https://ant.design/theme-editor for more theme customizations
        token: {
          colorBgLayout: colors.layoutBg,
          colorBgBase: colors.baseBg,
          colorPrimary: colors.primary,
          colorSuccess: colors.success,
          colorError: colors.error,
          colorLink: colors.buttonLnk,
          colorTextBase: colors.primaryText,
          // fontFamily: "'Source Sans Pro', sans-serif",
        },
        components: {
          Layout: {
            headerBg: colors.baseBg,
            headerPadding: '0 24px',
            triggerBg: colors.baseBg,
            triggerColor: colors.primaryText,
            siderBg: colors.baseBg,
          },
          Typography: {
            titleMarginTop: 0,
            titleMarginBottom: 0,
            margin: 0,
          },
          Form: {
            labelColor: colors.formLabel,
          },
          Button: {
            colorText: colors.buttonText,
          },
          Segmented: {
            trackBg: colors.baseBg,
            itemSelectedBg: colors.secondaryBg,
            itemSelectedColor: colors.primary,
            trackPadding: 4,
          },
        },
        algorithm: antdTheme.defaultAlgorithm,
      }}
    >
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ConfigProvider>
  );
};

export default Provider;
