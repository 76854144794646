export const GET_SUBSCRIPTION_TIERS_REQUEST = 'subscriptionTiers/GET_SUBSCRIPTION_TIERS_REQUEST';
export const GET_SUBSCRIPTION_TIERS_SUCCESS = 'subscriptionTiers/GET_SUBSCRIPTION_TIERS_SUCCESS';
export const GET_SUBSCRIPTION_TIERS_FAILURE = 'subscriptionTiers/GET_SUBSCRIPTION_TIERS_FAILURE';

export const getSubscriptionTiersRequest = () => ({
  type: GET_SUBSCRIPTION_TIERS_REQUEST,
});

export const getSubscriptionTiersSuccess = ({ subscriptionTiers }) => ({
  type: GET_SUBSCRIPTION_TIERS_SUCCESS,
  subscriptionTiers,
});

export const getSubscriptionTiersFailure = (error) => ({
  type: GET_SUBSCRIPTION_TIERS_FAILURE,
  error,
});
