import { all, put, select, take } from 'redux-saga/effects';
import { CONTEXTS } from '../../../constants';
import { selectContext } from '../../selectors/context.selector';
import {
  setContext,
  startUpdatingPendingDuration,
  stopUpdatingPendingDuration,
  updatePlaceholderFromServer,
} from '../../slices/context.slice';

/** Sets the context to PRE_TASK if it isn't already PRE_TASK */
export function* setPreTaskContext() {
  const context = (yield select(selectContext)) as string;
  if (context !== CONTEXTS.PRE_TASK) {
    yield all([put(setContext(CONTEXTS.PRE_TASK)), put(startUpdatingPendingDuration())]);
  }
}

/** Sets the context to WORKING if it isn't already working */
export function* setWorkingContext() {
  const context = (yield select(selectContext)) as string;
  if (context !== CONTEXTS.WORKING) yield put(setContext(CONTEXTS.WORKING));
}

/** Sets the context to ASKING if it isn't already ASKING */
export function* setAskingContext() {
  const context = (yield select(selectContext)) as string;
  if (context !== CONTEXTS.ASKING) yield put(setContext(CONTEXTS.ASKING));
}

/** Sets the context to REST if it isn't already resting */
export function* setRestingContext() {
  const context = (yield select(selectContext)) as string;
  if (context !== CONTEXTS.REST) {
    yield put(setContext(CONTEXTS.REST));
    // 1. Stop the timer measuring how long the skill takes to execute
    // (as a reducer effect, it also clears the time accumulated)
    yield put(stopUpdatingPendingDuration());
    // 2. Clear the old placeholder
    yield put(updatePlaceholderFromServer({ placeholderFromServer: undefined }));
  }
}

/**
 * Begin session preparation.
 *
 * Sets the context to STARTUP if it isn't already STARTUP
 * And start the pending animation
 */
export function* setStartupContext() {
  const context = (yield select(selectContext)) as string;
  if (context !== CONTEXTS.STARTUP) {
    yield all([put(setContext(CONTEXTS.STARTUP)), put(startUpdatingPendingDuration())]);
  }
}

/** Utility that waits for the context to be set to REST */
export function* waitForRestingContext(): Generator<any, void, any> {
  while (true) {
    const contextPayload = yield take(setContext.type);
    if (contextPayload.payload === CONTEXTS.REST) break;
  }
}
