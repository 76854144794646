import { put, select, takeLatest } from 'typed-redux-saga';

import { CONTEXTS } from '../../constants';
import { sendMessageRequest } from '../actions/messages.actions';
import { selectContext } from '../selectors/context.selector';
import { selectInitialUtterance } from '../selectors/initial_utterance.selector';
import { selectSession } from '../selectors/session.selector';
import { executeInitialUtterance, exitInitialUtterance } from '../slices/initial_utterance.slice';

export function* executeInitialUtteranceWorker() {
  const utterance = yield* select(selectInitialUtterance);
  const isInitialUtterance = yield* select(selectInitialUtterance);
  const sessionID = yield* select(selectSession);
  const context = (yield select(selectContext)) as string;
  if (isInitialUtterance && context === CONTEXTS.REST && sessionID) {
    // @ts-expect-error - TS thinks sessionID should be of type null | undefined
    const { message, utteranceMetadata } = utterance;
    yield put(sendMessageRequest({ message, sessionID, utteranceMetadata }));
    yield put(exitInitialUtterance());
  }
}

export default function* initialUtteranceSaga() {
  yield takeLatest(executeInitialUtterance, executeInitialUtteranceWorker);
}
