export const RESET_EDITOR = 'editor/RESET_EDITOR';

export const INITIALIZE_EDITOR = 'editor/INITIALIZE_EDITOR';

export const FINISH_LOADING = 'editor/FINISH_LOADING';

export const GET_WORKFLOW_REQUEST = 'editor/GET_WORKFLOW_REQUEST';
export const GET_WORKFLOW_SUCCESS = 'editor/GET_WORKFLOW_SUCCESS';
export const GET_WORKFLOW_FAILURE = 'editor/GET_WORKFLOW_FAILURE';

export const START_FRONTEND_REPLAY_REQUEST = 'editor/START_FRONTEND_REPLAY_REQUEST';
export const START_FRONTEND_REPLAY_SUCCESS = 'editor/START_FRONTEND_REPLAY_SUCCESS';
export const START_FRONTEND_REPLAY_FAILURE = 'editor/START_FRONTEND_REPLAY_FAILURE';

export const SUBMIT_UTTERANCE_REQUEST = 'editor/SUBMIT_UTTERANCE_REQUEST';
export const SUBMIT_UTTERANCE_SUCCESS = 'editor/SUBMIT_UTTERANCE_SUCCESS';
export const SUBMIT_UTTERANCE_FAILURE = 'editor/SUBMIT_UTTERANCE_FAILURE';

export const RECEIVED_QUESTION = 'editor/RECEIVED_QUESTION';

export const END_FRONTEND_REPLAY_REQUEST = 'editor/END_FRONTEND_REPLAY_REQUEST';
export const END_FRONTEND_REPLAY_SUCCESS = 'editor/END_FRONTEND_REPLAY_SUCCESS';
export const END_FRONTEND_REPLAY_FAILURE = 'editor/END_FRONTEND_REPLAY_FAILURE';

export const SAVE_WORKFLOW_REQUEST = 'editor/SAVE_WORKFLOW_REQUEST';
export const SAVE_WORKFLOW_SUCCESS = 'editor/SAVE_WORKFLOW_SUCCESS';
export const SAVE_WORKFLOW_FAILURE = 'editor/SAVE_WORKFLOW_FAILURE';
export const SET_NAME_SAVE_FAILED = 'editor/SET_NAME_SAVE_FAILED';

export const CREATE_WORKFLOW_REQUEST = 'editor/CREATE_WORKFLOW_REQUEST';
export const CREATE_WORKFLOW_SUCCESS = 'editor/CREATE_WORKFLOW_SUCCESS';
export const CREATE_WORKFLOW_FAILURE = 'editor/CREATE_WORKFLOW_FAILURE';

export const RESTORE_VERSION_REQUEST = 'editor/RESTORE_VERSION_REQUEST';
export const RESTORE_VERSION_SUCCESS = 'editor/RESTORE_VERSION_SUCCESS';
export const RESTORE_VERSION_FAILURE = 'editor/RESTORE_VERSION_FAILURE';

export const DELETE_WORKFLOW_REQUEST = 'editor/DELETE_WORKFLOW_REQUEST';
export const DELETE_WORKFLOW_SUCCESS = 'editor/DELETE_WORKFLOW_SUCCESS';
export const DELETE_WORKFLOW_FAILURE = 'editor/DELETE_WORKFLOW_FAILURE';

export const UNDO_CHANGE = 'editor/UNDO_CHANGE';
export const REDO_CHANGE = 'editor/REDO_CHANGE';
export const ADD_UTTERANCE = 'editor/ADD_UTTERANCE';
export const SET_DEBOUNCE_FLAG = 'editor/SET_DEBOUNCE_FLAG';
export const EDIT_UTTERANCE = 'editor/EDIT_UTTERANCE';
export const TOGGLE_COMMENT = 'editor/TOGGLE_COMMENT';
export const DELETE_UTTERANCE = 'editor/DELETE_UTTERANCE';
export const SPLIT_UTTERANCE = 'editor/SPLIT_UTTERANCE';
export const MERGE_UTTERANCES = 'editor/MERGE_UTTERANCES';
export const APPLY_FOCUS = 'editor/APPLY_FOCUS';
export const REORDER_UTTERANCE = 'editor/REORDER_UTTERANCE';
export const PASTE_SELECTION = 'editor/PASTE_SELECTION';
export const TOGGLE_BREAKPOINT = 'editor/TOGGLE_BREAKPOINT';
export const UPDATE_SELECTION = 'editor/UPDATE_SELECTION';
export const SELECT_ALL = 'editor/SELECT_ALL';
export const DESELECT_ALL = 'editor/DESELECT_ALL';
export const COPY_SELECTION = 'editor/COPY_SELECTION';
export const COMMENT_SELECTION = 'editor/COMMENT_SELECTION';
export const DELETE_SELECTION = 'editor/DELETE_SELECTION';
export const LOAD_VERSION = 'editor/LOAD_VERSION';

export const EXECUTE_NEXT = 'editor/EXECUTE_NEXT';
export const PAUSE_REPLAY = 'editor/PAUSE_REPLAY';

// ask ava module editor actions (wf editor in grid mode)
export const LOAD_ASK_AVA_EDITOR = 'editor/LOAD_ASK_AVA_EDITOR';
export const START_ASK_AVA_REPLAY = 'editor/START_ASK_AVA_REPLAY';
export const END_ASK_AVA_REPLAY = 'editor/END_ASK_AVA_REPLAY';

export const TRY_SAVE_WORKFLOW = 'editor/TRY_SAVE_WORKFLOW';
export const DELETE_WORKFLOW_DATASET = 'editor/DELETE_WORKFLOW_DATASET';

export const NAVIGATE_TO_WORKFLOW_EDITOR = 'editor/NAVIGATE_TO_WORKFLOW_EDITOR';
export const SET_EDITOR_OBJECT_INFORMATION = 'editor/SET_OBJECT_INFORMATION';

export const UPDATE_CHART_DATASET_REQUEST = 'editor/UPDATE_CHART_DATASET_REQUEST';
export const UPDATE_CHART_DATASET_SUCCESS = 'editor/UPDATE_CHART_DATASET_SUCCESS';
export const UPDATE_CHART_DATASET_FAILURE = 'editor/UPDATE_CHART_DATASET_FAILURE';

export const UPDATE_DC_OBJECT_DATASET_REQUEST = 'editor/UPDATE_DC_OBJECT_DATASET_REQUEST';
export const UPDATE_DC_OBJECT_DATASET_SUCCESS = 'editor/UPDATE_DC_OBJECT_DATASET_SUCCESS';
export const UPDATE_DC_OBJECT_DATASET_FAILURE = 'editor/UPDATE_DC_OBJECT_DATASET_FAILURE';

export const CREATE_WORKFLOW_DATASET_REQUEST = 'editor/CREATE_WORKFLOW_DATASET_REQUEST';
export const CREATE_WORKFLOW_DATASET_SUCCESS = 'editor/CREATE_WORKFLOW_DATASET_SUCCESS';
export const CREATE_WORKFLOW_DATASET_FAILURE = 'editor/CREATE_WORKFLOW_DATASET_FAILURE';

export const resetEditor = () => ({ type: RESET_EDITOR });

/**
 *
 * @param {object} o object argument with objectType, objectId, and autoSave
 * @param {string} o.objectType the type of object to navigate to
 * @param {string} o.objectId the id of the object to navigate to
 * @param {boolean} o.autoSave whether to autosave the workflow
 * @returns
 */
export const navigateToWorkflowEditor = ({ objectType, objectId, autoSave = false } = {}) => ({
  type: NAVIGATE_TO_WORKFLOW_EDITOR,
  objectType,
  objectId,
  autoSave,
});

export const setEditorObjectInformation = ({ objectType, objectId, autoSave = false } = {}) => ({
  type: SET_EDITOR_OBJECT_INFORMATION,
  objectType,
  objectId,
  autoSave,
});

/**
 * Spins up a saga which handles necessary actions to initialize the workflow editor
 * for the specified workflowId and version.
 */
export const initializeEditor = ({ workflowId, version = null, autoSave = false } = {}) => ({
  type: INITIALIZE_EDITOR,
  workflowId,
  version,
  autoSave,
});

/**
 * Action to set the loading flag to false.
 */
export const finishLoading = () => ({ type: FINISH_LOADING });

/**
 * Spins up a saga to
 */
export const restoreVersionRequest = ({ workflowId, version = null } = {}) => ({
  type: RESTORE_VERSION_REQUEST,
  workflowId,
  version,
});

/**
 * Action to indicate that the version was successfully restored.
 */
export const restoreVersionSuccess = () => ({ type: RESTORE_VERSION_SUCCESS });

/**
 * Action to indicate that the version failed to be restored.
 */
export const restoreVersionFailure = (error) => ({ type: RESTORE_VERSION_FAILURE, error });

// Retrieve workflow from server upon opening of workflow editor
export const getWorkflowRequest = ({ workflowId, refresh = false } = {}) => ({
  type: GET_WORKFLOW_REQUEST,
  workflowId,
  refresh,
});

export const getWorkflowSuccess = ({ metadata, versions, refresh = false } = {}) => ({
  type: GET_WORKFLOW_SUCCESS,
  metadata,
  versions,
  refresh,
});

export const getWorkflowFailure = ({ error }) => ({
  type: GET_WORKFLOW_FAILURE,
  error,
});

/**
 * Save changes to an existing workflow.
 */
export const saveWorkflowRequest = () => ({ type: SAVE_WORKFLOW_REQUEST });

export const saveWorkflowSuccess = () => ({ type: SAVE_WORKFLOW_SUCCESS });

export const saveWorkflowFailure = () => ({ type: SAVE_WORKFLOW_FAILURE });

export const setNameSaveFailed = ({ setFailed }) => ({ type: SET_NAME_SAVE_FAILED, setFailed });

/**
 * Create a new workflow based on the current editor.
 */
export const createWorkflowRequest = ({ name, overwrite } = {}) => ({
  type: CREATE_WORKFLOW_REQUEST,
  name,
  overwrite,
});

export const createWorkflowSuccess = () => ({ type: CREATE_WORKFLOW_SUCCESS });

export const createWorkflowFailure = () => ({ type: CREATE_WORKFLOW_FAILURE });

/**
 * .Delete the current workflow.
 */
export const deleteWorkflowRequest = () => ({ type: DELETE_WORKFLOW_REQUEST });

export const deleteWorkflowSuccess = () => ({
  type: DELETE_WORKFLOW_SUCCESS,
});

export const deleteWorkflowFailure = ({ error }) => ({
  type: DELETE_WORKFLOW_FAILURE,
  error,
});

/**
 ** Replay Related Actions
 */
export const pauseReplay = () => ({ type: PAUSE_REPLAY });

// Start a  session to replay the workflow
export const startFrontendReplayRequest = ({ stepwise = false } = {}) => ({
  type: START_FRONTEND_REPLAY_REQUEST,
  stepwise,
});

export const startFrontendReplaySuccess = ({ stepwise = false } = {}) => ({
  type: START_FRONTEND_REPLAY_SUCCESS,
  stepwise,
});

export const startFrontendReplayFailure = ({ error }) => ({
  type: START_FRONTEND_REPLAY_FAILURE,
  error,
});

// End the current replay session
export const endFrontendReplayRequest = ({ restart = false, stepwise = false } = {}) => ({
  type: END_FRONTEND_REPLAY_REQUEST,
  restart,
  stepwise,
});

export const endFrontendReplaySuccess = () => ({
  type: END_FRONTEND_REPLAY_SUCCESS,
});

export const endFrontendReplayFailure = ({ error }) => ({
  type: END_FRONTEND_REPLAY_FAILURE,
  error,
});

// Submit an utterance for replay
// saveToWorkflow will be passed to the submitUtteranceSuccess
export const submitUtteranceRequest = (
  continueReplay = false,
  saveToWorkflow = true,
  addStep = true,
) => ({
  type: SUBMIT_UTTERANCE_REQUEST,
  continueReplay,
  saveToWorkflow,
  addStep,
});

// saveToWorkflow will be passed to the handleNextReplayStep generator - If false it won't save the workflow
export const submitUtteranceSuccess = (
  usrMsgId,
  additionalInfo,
  saveToWorkflow = true,
  addStep = true,
) => ({
  type: SUBMIT_UTTERANCE_SUCCESS,
  usrMsgId,
  additionalInfo,
  saveToWorkflow,
  addStep,
});

export const submitUtteranceFailure = (error) => ({ type: SUBMIT_UTTERANCE_FAILURE, error });

export const receivedQuestion = () => ({ type: RECEIVED_QUESTION });

/**
 ** Editing Related Actions
 */
export const undoChange = () => ({ type: UNDO_CHANGE });

export const redoChange = () => ({ type: REDO_CHANGE });

export const addUtterance = (uttIndex) => ({ type: ADD_UTTERANCE, uttIndex });

export const setDebounceFlag = (uttKey) => ({
  type: SET_DEBOUNCE_FLAG,
  uttKey,
});

export const editUtterance = (uttKey, newValue) => ({ type: EDIT_UTTERANCE, uttKey, newValue });

export const toggleComment = (uttKey) => ({ type: TOGGLE_COMMENT, uttKey });

export const deleteUtterance = (uttKey) => ({ type: DELETE_UTTERANCE, uttKey });

/**
 * Action for splitting an utterance into two.
 * @param {Number} uttIndex index of the utterance being split
 * @param {String} firstHalf left half of the split utterance
 * @param {String} secondHalf right half of the split utterance
 */
export const splitUtterance = (uttIndex, firstHalf, secondHalf) => ({
  type: SPLIT_UTTERANCE,
  uttIndex,
  firstHalf,
  secondHalf,
});

/**
 * Action for merging two consecutive utterances together
 * @param {Number} uttIndex index of the first utterance
] */
export const mergeUtterances = (uttIndex) => ({
  type: MERGE_UTTERANCES,
  uttIndex,
});

/**
 * Action to apply focus to a specific line and where to place the caret
 * @param {Number} uttIndex index of the utterance to focus
 * @param {String} position either 'start' or 'end'
 */
export const applyFocus = (uttIndex, position) => ({
  type: APPLY_FOCUS,
  uttIndex,
  position,
});

export const reorderUtterance = (newUtteranceList, startIndex, endIndex) => ({
  type: REORDER_UTTERANCE,
  newUtteranceList,
  startIndex,
  endIndex,
});

export const toggleBreakpoint = (uttKey) => ({ type: TOGGLE_BREAKPOINT, uttKey });

export const updateSelection = (uttKey, selecting, shifting) => ({
  type: UPDATE_SELECTION,
  uttKey,
  selecting,
  shifting,
});

export const selectAll = () => ({ type: SELECT_ALL });

export const deselectAll = () => ({ type: DESELECT_ALL });

export const copySelection = () => ({ type: COPY_SELECTION });

export const commentSelection = () => ({ type: COMMENT_SELECTION });

export const pasteSelection = (uttIndex, utterances, editObject = undefined) => ({
  type: PASTE_SELECTION,
  uttIndex,
  utterances,
  editObject,
});

export const deleteSelection = () => ({ type: DELETE_SELECTION });

export const loadVersion = ({ version, previewing = false } = {}) => ({
  type: LOAD_VERSION,
  version,
  previewing,
});

// Runs when ask ava query response is recieved; populates editor reducer with utterances
export const loadAskAvaEditor = ({ recipe, stepList, metadata }) => ({
  type: LOAD_ASK_AVA_EDITOR,
  recipe,
  stepList,
  metadata,
});

// sets the editor to its "replaying" state and kicks off a continuous replay
export const startAskAvaReplay = ({ autoStart } = { autoStart: false }) => ({
  type: START_ASK_AVA_REPLAY,
  autoStart,
});

// stop the ask ava module replay
export const endAskAvaReplay = () => ({
  type: END_ASK_AVA_REPLAY,
});

export const trySaveWorkflow = () => ({
  type: TRY_SAVE_WORKFLOW,
});

export const deleteWorkflowDataset = () => ({
  type: DELETE_WORKFLOW_DATASET,
});

export const createWorkflowDatasetRequest = () => ({
  type: CREATE_WORKFLOW_DATASET_REQUEST,
});

export const createWorkflowDatasetSuccess = ({ dcDatasetId }) => ({
  type: CREATE_WORKFLOW_DATASET_SUCCESS,
  dcDatasetId,
});

export const createWorkflowDatasetFailure = ({ error }) => ({
  type: CREATE_WORKFLOW_DATASET_FAILURE,
  error,
});

export const updateChartDatasetRequest = ({ dcDatasetId, dcChartId }) => ({
  type: UPDATE_CHART_DATASET_REQUEST,
  dcDatasetId,
  dcChartId,
});

export const updateChartDatasetSuccess = () => ({
  type: UPDATE_CHART_DATASET_SUCCESS,
});

export const updateChartDatasetFailure = ({ error }) => ({
  type: UPDATE_CHART_DATASET_FAILURE,
  error,
});

export const updateDcObjectDatasetRequest = ({ dcDatasetId, dcObjectId }) => ({
  type: UPDATE_DC_OBJECT_DATASET_REQUEST,
  dcDatasetId,
  dcObjectId,
});

export const updateDcObjectDatasetSuccess = () => ({
  type: UPDATE_DC_OBJECT_DATASET_SUCCESS,
});

export const updateDcObjectDatasetFailure = ({ error }) => ({
  type: UPDATE_DC_OBJECT_DATASET_FAILURE,
  error,
});
