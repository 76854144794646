import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import {
  GridColumnMenuContainer,
  GridColumnsMenuItem,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { EMPTY_COLUMN } from '../../../../pages/authenticated/constants';
import { getValueFormatter } from '../../../../utils/formatting/column_formatting';
import { VALUE_FORMATTERS } from '../../../../utils/formatting/constants';
import SubMenuItem from '../../../DatasetDisplayPanel/ColumnMenu/Common/SubMenuItem';
import { determineMaxWidths } from './utils';

const TableColumnOptionMenu = (props) => {
  const {
    hideMenu,
    currentColumn,
    valueFormatterMap,
    setValueFormatterMap,
    data,
    currNumShownRows,
  } = props;

  // retrieve reference with hook
  const apiRef = useGridApiContext();

  // handle scientific conversion
  const handleFormatting = (e, newValueFormatterType) => {
    const newValueFormatterMap = {
      ...valueFormatterMap,
      [currentColumn.field]: newValueFormatterType,
    };
    setValueFormatterMap(newValueFormatterMap);

    const maxColumnWidth = determineMaxWidths(
      data.data,
      [{ name: currentColumn.field, type: currentColumn.type }],
      0,
      currNumShownRows,
      newValueFormatterMap,
    )[currentColumn.field];

    // update current column ref
    apiRef.current.updateColumns([
      {
        field: currentColumn.field,
        valueFormatter: getValueFormatter(
          { type: currentColumn.type, displayType: currentColumn.displayType },
          'none',
          newValueFormatterType === VALUE_FORMATTERS.SCIENTIFIC,
        ),
        minWidth: maxColumnWidth,
      },
    ]);

    // close the menu
    hideMenu(e);
  };

  const handleHide = () => {
    apiRef.current.setColumnVisibility(currentColumn.field, false);
  };

  return (
    <div className="Table-ColumnOptionMenu">
      <GridColumnMenuContainer hideMenu={hideMenu} currentColumn={currentColumn} open>
        {currentColumn.field !== EMPTY_COLUMN && <MenuItem onClick={handleHide}>Hide</MenuItem>}
        <GridColumnsMenuItem onClick={hideMenu} column={currentColumn} />
        {currentColumn?.type === 'number' && (
          <div className="NumericColumnOptions">
            <Divider />
            <SubMenuItem
              onClick={(e) => {
                if (valueFormatterMap[currentColumn.field] !== VALUE_FORMATTERS.SCIENTIFIC) {
                  return;
                }
                handleFormatting(e, VALUE_FORMATTERS.NUMBER);
              }}
              showCheckMark={valueFormatterMap[currentColumn.field] !== VALUE_FORMATTERS.SCIENTIFIC}
              label="Number"
              exampleText="100,000.12"
            />
            <SubMenuItem
              onClick={(e) => {
                if (valueFormatterMap[currentColumn.field] === VALUE_FORMATTERS.SCIENTIFIC) {
                  return;
                }
                handleFormatting(e, VALUE_FORMATTERS.SCIENTIFIC);
              }}
              showCheckMark={valueFormatterMap[currentColumn.field] === VALUE_FORMATTERS.SCIENTIFIC}
              label="Scientific"
              exampleText="1.00e-5"
            />
          </div>
        )}
      </GridColumnMenuContainer>
    </div>
  );
};

TableColumnOptionMenu.propTypes = {
  currentColumn: PropTypes.object.isRequired, // from Builtin Prop
  hideMenu: PropTypes.func.isRequired, // from Builtin Prop
  valueFormatterMap: PropTypes.object.isRequired, // from Parent Table.jsx
  setValueFormatterMap: PropTypes.func.isRequired, // from Parent Table.jsx
  data: PropTypes.object.isRequired, // from Parent Table.jsx
  currNumShownRows: PropTypes.string.isRequired, // from Parent Table.jsx
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {})(TableColumnOptionMenu);
