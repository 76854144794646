import {
  GET_INVOICES_FAILURE,
  GET_INVOICES_REQUEST,
  GET_INVOICES_SUCCESS,
  GET_UPCOMING_INVOICE_FAILURE,
  GET_UPCOMING_INVOICE_REQUEST,
  GET_UPCOMING_INVOICE_SUCCESS,
} from '../actions/invoice.actions';

const initialState = {
  invoices: [], // list of invoices from stripe
  isRequesting: false, // Boolean which tells us if the api still working
  error: undefined, // error from this action
  upcomingInvoice: null, // price, start date, and end date of future invoice on subscription
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_INVOICES_REQUEST:
      return { ...state, isRequesting: true };

    case GET_INVOICES_SUCCESS:
      return {
        ...state,
        invoices: action.data,
        isRequesting: false,
      };

    case GET_INVOICES_FAILURE:
      return {
        ...state,
        error: action.error,
        isRequesting: false,
      };

    case GET_UPCOMING_INVOICE_REQUEST:
      return { ...state, isRequesting: true };

    case GET_UPCOMING_INVOICE_SUCCESS:
      return {
        ...state,
        upcomingInvoice: action.data,
        isRequesting: false,
      };

    case GET_UPCOMING_INVOICE_FAILURE:
      return {
        ...state,
        isRequesting: false,
        error: action.error,
      };

    default:
      return state;
  }
};
