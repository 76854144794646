import LightbulbIcon from '@mui/icons-material/LightbulbOutlined';
import Chip from '@mui/material/Chip';
import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { QuestionRecommendation } from '../../../store/slices/recommendations.slice';

interface RecommendationProps {
  recommendation: QuestionRecommendation;
  submitRecommendation?: (recommendation: QuestionRecommendation) => void;
}

const Recommendation: React.FC<RecommendationProps> = ({
  recommendation,
  submitRecommendation,
}) => {
  const { question } = recommendation;
  return (
    <Tooltip title={question}>
      <Chip
        label={question}
        icon={<LightbulbIcon />}
        onClick={() => submitRecommendation && submitRecommendation(recommendation)}
        variant="outlined"
      />
    </Tooltip>
  );
};

Recommendation.defaultProps = {
  submitRecommendation: undefined,
};

export default Recommendation;
