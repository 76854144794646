/* eslint-disable no-useless-catch */

import { call, getContext, select } from 'redux-saga/effects';
import { API_SERVICES } from '../../../constants/api';
import { WORKSPACE_ACCESS_TYPES } from '../../../constants/workspace';
import { Accessor, AccessorByUserId } from '../../../types/accessDialog.types';
import {
  GetSessionCollaboratorsResponse,
  GetSessionInfoResponse,
} from '../../../types/session.types';
import { selectAccessToken } from '../selectors';

/**
 * Gets the userId of the owner of the session.
 */
export function* getSessionOwnerID(sessionId: string): Generator<any, number, any> {
  const accessToken: string = yield select(selectAccessToken);
  const sessionService = yield getContext(API_SERVICES.SESSION);

  try {
    const response: GetSessionInfoResponse = yield call(
      sessionService.getSessionInfo,
      accessToken,
      sessionId,
    );
    return response.data.ownerId;
  } catch (error: any) {
    throw error;
  }
}

/**
 * Gets the session collaborators and formats them into Accessor type.
 */
export function* getAccessorsFromSessionCollaborators(
  sessionId: string,
  sessionOwnerId: number,
): Generator<any, AccessorByUserId, any> {
  const accessToken: string = yield select(selectAccessToken);
  const sessionService = yield getContext(API_SERVICES.SESSION);

  try {
    const response: GetSessionCollaboratorsResponse = yield call(
      sessionService.getSessionCollaborators,
      accessToken,
      sessionId,
    );

    // Format the response data into Record<string, Accessor> type
    return response.data.reduce((acc: AccessorByUserId, item) => {
      acc[item.id] = {
        // All session collaborators are editors (except owner)
        AccessType:
          sessionOwnerId === item.id ? WORKSPACE_ACCESS_TYPES.OWNER : WORKSPACE_ACCESS_TYPES.EDITOR,
        UserEmail: item.email,
        UserId: item.id,
      } as Accessor;
      return acc;
    }, {});
  } catch (error: any) {
    throw error;
  }
}
