import React from 'react';
import { useSelector } from 'react-redux';
import { selectObjectsByFolder } from '../../store/sagas/selectors';
import { HomeObjectKeysTypes } from '../../utils/homeScreen/types';
import { filterObjectsByType, searchObjects } from './load_card_utils';

type UseSearchLoadObjectsPayload = {
  typeFilteredObjects: HomeObjectKeysTypes[];
  /* Searched objects */
  searchedObjects: HomeObjectKeysTypes[];
  /* The text that was searched */
  searchText: string;
  /* a hook to update the searched objects */
  handleSearch: (arg: string) => void;
};

export const useSearchLoadObjects = (objectTypes: string[]): UseSearchLoadObjectsPayload => {
  const [searchText, setSearchText] = React.useState<string>('');
  const allFolderObjects = useSelector(selectObjectsByFolder) as Record<
    string,
    HomeObjectKeysTypes[]
  >;
  // Objects from allFolderObjects, filtered by objectType
  const typeFilteredObjects = React.useMemo(
    () => filterObjectsByType(allFolderObjects, objectTypes),
    [allFolderObjects, objectTypes],
  );

  // Objects from allFolderObjects, filtered by objectType and searchText
  // This result will include the ancestors of the matched objects
  const searchedObjects = React.useMemo(
    () => searchObjects(searchText, allFolderObjects, typeFilteredObjects),

    [allFolderObjects, typeFilteredObjects, searchText],
  );

  const handleSearch = React.useCallback((t: string) => setSearchText(t), []);

  return {
    searchText,
    searchedObjects,
    typeFilteredObjects,
    handleSearch,
  };
};
