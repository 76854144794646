import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AsyncResult } from '../../../types/AsyncResult.types';
import { DatasetClaim } from '../../../types/claim.type';
import { Description, DescriptionParameters } from '../../../types/dataset.types';
import { DatasetsByID } from './types';

const pipelinerDatasetsSlice = createSlice({
  name: 'pipelinerDatasets',
  initialState: {} as DatasetsByID,
  reducers: {
    getDescription: (
      state,
      action: PayloadAction<{
        id: string;
        claim: DatasetClaim;
        params: DescriptionParameters;
      }>,
    ) => {
      // get dataset
      const dataset = state[action.payload.id] ?? {};

      // set description
      dataset.description = { kind: 'loading' };

      // update state
      state[action.payload.id] = dataset;
    },
    gotDescription: (
      state,
      action: PayloadAction<{
        id: string;
        description: AsyncResult<Description>;
      }>,
    ) => {
      // get dataset
      const dataset = state[action.payload.id] ?? {};

      // set description
      dataset.description = action.payload.description;

      // update state
      state[action.payload.id] = dataset;
    },
  },
});

export const { getDescription, gotDescription } = pipelinerDatasetsSlice.actions;
export default pipelinerDatasetsSlice;
