import axios from 'axios';
import qs from 'qs';
import { dcAppServiceEndpoints, endpoints } from '../constants/endpoints';
import { handleDCAppServiceCall } from '../utils/errorHandling/errorHandlers.api';

/**
 * Create a new session
 * Fail if reach concurrency limit
 * @param {String} accessToken
 * @param {String} sessionType
 * If a session is created from opening an object and if a session needs to inherit the name
 *  of an object. Simply pass in the objectId and objectType in the api request.
 * @param {Integer} objectId // Object foreign key id from dc_object table or actual object id from the respective tables.
 * @param {String} objectType // Object type constant from HOME_OBJECTS in home_screen constants file.
 */
export const postCreateSession = (
  accessToken,
  sessionType = 'chat',
  objectId = '',
  objectType = '',
) =>
  axios.post(endpoints.session, qs.stringify({ sessionType, objectId, objectType }), {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

export const getSessionInfo = (accessToken, sessionId) => {
  // if accessToken is not provided, it means that the user is not logged in
  // Instead, we will use the query params to get the client_id and secret
  // to make the request
  let clientId;
  let secret;
  if (!accessToken) {
    const query = new URLSearchParams(window.location.search);
    clientId = query.get('client_id');
    secret = query.get('secret');
  }
  return axios.get(endpoints.session, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      sessionId,
      client_id: clientId,
      secret,
    },
  });
};

/**
 * terminate a session
 * @param {*} accessToken
 * @param {*} sessionId
 */
export const exitSession = (accessToken, sessionId) =>
  axios.delete(endpoints.session, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      sessionId,
    },
  });

/**
 * Get all active sessions
 * @param {*} accessToken
 */
export const getAllUserSessions = (accessToken, sessionType) =>
  axios.get(endpoints.session, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      sessionType,
    },
  });

/**
 * Get dependency graph of the current session
 * @param {String} accessToken access token
 * @param {String} sessionId session id
 * @param {String} lastUpdate timestamp of most recently fetched changes
 */
export const getSessionDependencyGraph = (accessToken, sessionId, lastUpdate) => {
  return axios.get(`${endpoints.session}/dependency_graph`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    params: {
      sessionId,
      lastUpdate,
    },
  });
};

/**
 * Save a session name
 * @param {*} accessToken
 * @param {*} sessionID
 * @param {*} sessionName
 */
export const saveSessionName = (accessToken, sessionId, sessionName) =>
  axios.put(endpoints.session_name, qs.stringify({ newName: sessionName }), {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    params: {
      sessionId,
    },
  });

export const retrieveSessionColumnStats = (accessToken, { sessionId, dataset, version }) => {
  return handleDCAppServiceCall(() =>
    axios.get(dcAppServiceEndpoints.describeAllColumn, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        sessionId,
        dataset,
        version,
      },
    }),
  );
};

export const retrieveSessionColumnStatsAsync = (accessToken, { sessionId, dataset, version }) => {
  return handleDCAppServiceCall(() =>
    axios.post(dcAppServiceEndpoints.describeAllColumnsAsync, null, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        sessionId,
        dataset,
        version,
      },
    }),
  );
};

export const retrieveLineageGraph = (accessToken, { sessionId, objectType, objectId }) => {
  return handleDCAppServiceCall(() =>
    axios.get(dcAppServiceEndpoints.retrieveLineageGraph(objectId, objectType), {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        sessionId,
      },
    }),
  );
};

export const exportDataset = (accessToken, { sessionId, datasetName, version, filename }) => {
  return handleDCAppServiceCall(() =>
    axios.get(dcAppServiceEndpoints.exportDataset, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        sessionId,
        datasetName,
        version,
        filename,
      },
    }),
  );
};

// TODO - uncomment request information; remove eslint disable comment
// eslint-disable-next-line
export const getSlicedWorkflow = (
  accessToken,
  { sessionId, userId, usedDatasets, selectedDatasets, question },
) =>
  // ({
  //   data: '{"workflow_data": {"1": {"skill_uuid": "f46d9ec53ab14ff884a20229a25d0f57", "skill": "LoadDataFromFile", "kwargs": {"filepath": {"name": "titanic.csv", "email": "", "_user_id": null,"value": null}, "delimiter": null, "legacy": null, "cell_ranges": null, "excluded_sheets": null,"included_sheets": null, "column_names": [], "column_types": [], "column_formats": []}, "utterance": "Load data from the file <strong>titanic.csv</strong>", "inputs": {"file": [{"name": "titanic.csv", "version":""}]}, "outputs": {"table": [{"name": "Table1", "version": 1, "internal_id": "LoadFromFile_Table"}],"dataset": [{"name": "titanic", "version": 1, "table_name": "table_b4qu_s0as", "internal_id":"LoadFromFile"}]}, "recipe": {}, "answers": {}, "current_datasets": {"skill_input": {}, "skill_output":{"name": "titanic", "version": 1}}, "session_startup": false, "is_comment": false}, "2": {"skill_uuid":"8c4f2b72b80b47769f72d80423969c96", "skill": "LoadDataFromFile", "kwargs": {"filepath": {"name":"sba_sample.csv", "email": "", "_user_id": null, "value": null}, "delimiter": null,"legacy": null, "cell_ranges": null, "excluded_sheets": null, "included_sheets": null, "column_names": [],"column_types": [], "column_formats": []}, "utterance": "Load data from the file<strong>sba_sample.csv</strong>", "inputs": {"file": [{"name": "sba_sample.csv", "version": ""}]},"outputs": {"dataset": [{"name": "sba_sample", "version": 1, "table_name": "table_wyls_2a32","internal_id": "LoadFromFile"}], "table": [{"name": "Table2", "version": 1, "internal_id":"LoadFromFile_Table"}]}, "recipe": {}, "answers": {}, "current_datasets": {"skill_input": {"name":"titanic", "version": 1}, "skill_output": {"name": "sba_sample", "version": 1}}, "session_startup": false,"is_comment": false}}, "edited": false, "utterance_list": [1, 2], "raw_text_verified": true, "last_verified":"03/02/2023, 00:33:12", "app_version": "v0.7.79", "app_dir_name": "ava", "upgraded": false}',
  // })
  handleDCAppServiceCall(() =>
    axios.post(
      dcAppServiceEndpoints.getSlicedWorkflow,
      {
        userId,
        used_datasets: usedDatasets,
        selected_datasets: selectedDatasets,
        question,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          sessionId,
        },
      },
    ),
  );

/**
 * Get collaborators of a session.
 * @param {string} accessToken The access token
 * @param {string} sessionId The session ID
 */
export const getSessionCollaborators = (accessToken, sessionId) =>
  axios.get(endpoints.sessionCollaborators(sessionId), {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

/**
 * Add and remove collaborators from a session.
 * @param {string} accessToken The access token
 * @param {string} sessionId The session ID
 * @param {string[]} addedCollaboratorEmails The emails of collaborators to add
 * @param {string[]} deletedCollaboratorEmails The emails of the collaborators to remove
 * @returns
 */
export const patchSessionCollaborators = (
  accessToken,
  sessionId,
  addedCollaboratorEmails,
  deletedCollaboratorEmails,
) =>
  axios.patch(
    endpoints.sessionCollaborators(sessionId),
    { added_user_emails: addedCollaboratorEmails, deleted_user_emails: deletedCollaboratorEmails },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
