import { DeepPartial } from '../types/DeepPartial.types';

export default function merge<T extends object>(lhs: T, rhs: DeepPartial<T>): T {
  // shallow copy lhs
  const merged: T = { ...lhs };

  // merge each key in rhs to lhs
  for (const key in rhs) {
    // if rhs inherited this key, skip
    if (!Object.prototype.hasOwnProperty.call(rhs, key)) continue;

    // get the value of the key from rhs
    const value = rhs[key];

    // if the value is undefined, delete the key
    if (value === undefined) delete merged[key];
    // if the value is null, set the key to null
    else if (value === null) merged[key] = null;
    // if the value is an object, merge the object
    else if (typeof value === 'object') merged[key] = merge(merged[key], value);
    // otherwise, set the key to the value
    else merged[key] = value;
  }

  return merged;
}
