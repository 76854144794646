import cloneDeep from 'lodash/cloneDeep';
import {
  QUICK_UTTERANCE_VARIABLES,
  checkPageStatus,
  checkSinglePageStatus,
  getInitialInputValue,
} from '../../components/UtteranceComposer/constants';
import {
  getPageState,
  getUpdatedState,
} from '../../components/UtteranceComposer/UtteranceComposer.utils';
import { UNNAMED_SESSION } from '../../constants/session';
import {
  CLEAR_SUGGESTIONS_FORM,
  CLEAR_UTTERANCE_COMPOSER,
  CLOSE_SKILL_COMPOSER,
  DELETE_INPUT,
  HANDLE_PAGE_NAVIGATOR,
  HANDLE_USER_INPUT,
  INSERT_INPUT,
  LOAD_PARTIAL_SUGGESTIONS_REQUEST_FORM,
  LOAD_SUGGESTIONS_FAILURE_FORM,
  LOAD_SUGGESTIONS_REQUEST_FORM,
  LOAD_SUGGESTIONS_SUCCESS_FORM,
  OPEN_SKILL_COMPOSER,
  OPEN_UTTERANCE_COMPOSER_GROUP,
  SAVE_SELECTED_DATASET,
  SKILL_CHANGE,
  UPDATE_SINGLE_SELECT_DATA,
} from '../actions/utterance_composer.actions';

export const initialState = {
  // Flag for displaying the utterance composer
  showUtteranceComposerGroup: false,
  // Flag for displaying the skill composer
  showSkillComposer: false,
  // Stores multiple recipes to show on a dynamic form
  formGroup: {},
  entityState: {},
  pageState: {},
  // suggestions list for each entity
  suggestions: {},
  prompts: {},
  suggestionsState: {},
  // error from loading suggestion list
  suggestionError: undefined,
  // selectedDataset is used by both the skill and utterance composer
  selectedDataset: '',
  utterance: undefined,
  completed: false,
  coreCompleted: false,
  textToInsertIntoSingleSelectForm: '', // Tracks the file name that we plan to
  // load automatically in the SingleSelect form
  openByCellMenu: false, // a flag to denote if the form is open by cell menu in grid mode
  disableAutoFocus: false, // a flag to disable auto focus for single select after open a form by cell menu in grid mode
  skillRecipe: {},
};

/**
 * Creates a map of page recipes from an array of page recipes.
 * Each key in the map is the id of the page.
 * @param pagesArray - array of pages
 * @returns object map of pages
 */
const createPageMappingGroup = (pagesArray) => {
  const pagesMap = {};
  pagesArray.forEach((page) => {
    pagesMap[page.id] = {
      ...page,
    };
  });
  return pagesMap;
};

export const getConcatKey = (input, value) => {
  let concatKey = '';
  if (value) {
    if (input.concatInputOnSuggestion === 'lastWord') {
      const words = value.trim().split(' ');
      concatKey = words[words.length - 1];
    } else if (input.concatInputOnSuggestion === 'last2Word') {
      const words = value.trim().split(' ');
      concatKey = words.splice(words.length - 2, 2).join(' ');
    }
  }
  return concatKey;
};

const initializePageState = (formGroup, sessionName) => {
  const entityState = {}; // keeps track of the value of each entity eg {x-axis: {value: 'survived', completed: true]}
  const pageState = {}; // keeps track of the state of each page per skill {'Scatter': false, 'EachOf': false}
  // initialize entityState, pathState, pageState
  for (const skill of Object.keys(formGroup)) {
    const currentSkillPageState = {};
    const { pages, defaultOrder } = formGroup[skill];
    for (const currentPageName of defaultOrder) {
      const currentPage = pages[currentPageName];
      let currentPageState = false;
      if (!currentPage.page_navigator) {
        let inputs = [];
        inputs = inputs.concat.apply([], currentPage.inputs);
        for (const input of inputs) {
          if (!entityState[input.id]) {
            entityState[input.id] = {
              value:
                // if the init_value is SESSION_NAME and the session has a name,
                // set the input value to the session name
                input.init_value === QUICK_UTTERANCE_VARIABLES.SESSION_NAME &&
                sessionName !== UNNAMED_SESSION
                  ? sessionName
                  : input.default_value
                  ? input.init_value
                  : getInitialInputValue(input.input_type),
              completed: input.default_value !== undefined,
              coreCompleted: input.default_value !== undefined,
              optional: !!input.optional,
            };
          }
        }
        currentPageState = checkSinglePageStatus(currentPage, entityState);
      }
      currentSkillPageState[currentPageName] = currentPageState;
    }
    pageState[skill] = currentSkillPageState;
  }
  return [entityState, pageState];
};

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_UTTERANCE_COMPOSER_GROUP: {
      const { recipes, sessionName } = action;
      const formGroup = {};
      for (const recipe of recipes) {
        const pages = createPageMappingGroup(recipe.pages);
        formGroup[recipe.skill] = {
          defaultOrder: recipe.defaultOrder,
          pages,
          startingPage: recipe.startingPage,
          label: recipe.label,
          hideDatasetInHeader: recipe.hideDatasetInHeader,
          hideStepViewer: recipe.hideStepViewer,
          iconLabel: recipe.iconLabel,
        };
      }
      const [entityState, pageState] = initializePageState(formGroup, sessionName);
      return {
        ...state,
        formGroup,
        entityState,
        pageState,
        showUtteranceComposerGroup: true,
        openByCellMenu: Boolean(action.openByCellMenu),
        disableAutoFocus: Boolean(action.disableAutoFocus),
      };
    }
    case INSERT_INPUT: {
      /** Handles the +/- options in the forms */
      const formGroup = cloneDeep(state.formGroup);
      const pages = formGroup[action.skillName].pages[action.pageName];
      const { inputs, repeatIndex, appendIndex } = pages;
      const newInputs = cloneDeep(inputs);
      // dictionary which keeps track of the index of the inputs row and its appending inputs
      const appendInputs = pages.appendInputs ? pages.appendInputs : {};
      const index = appendIndex !== undefined ? appendIndex + 1 : 0;
      const newEntityState = {};
      const newRow = [];
      newInputs[repeatIndex].forEach((input, i) => {
        if (!input.concat) {
          input.id = `${input.id}-${index}`;
          input.keyword = input.repeatConcat;
          input.addedRowIndex = index;
          newRow.push(input);
          newEntityState[input.id] = {
            value: input.default_value ? input.init_value : getInitialInputValue(input.input_type),
            completed: input.default_value !== undefined || input.optional,
            coreCompleted: input.default_value !== undefined || input.optional,
            optional: !!input.optional,
          };
          if (input.keyword_appended && inputs[repeatIndex]?.[i]) {
            inputs[repeatIndex][i].keyword = input.keyword_appended;
          }
        }
      });

      // reset the pageState for the corresponding page
      const newPageState = { ...state.pageState };
      newPageState[action.skillName][action.pageName] = false;

      if (appendInputs[repeatIndex]) {
        appendInputs[repeatIndex].push(newRow);
      } else {
        appendInputs[repeatIndex] = [newRow];
      }

      formGroup[action.skillName].pages[action.pageName].inputs = inputs;
      formGroup[action.skillName].pages[action.pageName].appendInputs = appendInputs;
      formGroup[action.skillName].pages[action.pageName].appendIndex = index;
      if (pages.shareWith) {
        for (const page of pages.shareWith) {
          formGroup[action.skillName].pages[page].appendInputs = appendInputs;
          formGroup[action.skillName].pages[page].appendIndex = index;
          formGroup[action.skillName].pages[page].inputs[repeatIndex].forEach((input, i) => {
            if (input.keyword_appended && inputs[repeatIndex]?.[i]) {
              input.keyword = input.keyword_appended;
            }
          });
          newPageState[action.skillName][page] = false;
        }
      }

      return {
        ...state,
        formGroup,
        entityState: { ...state.entityState, ...newEntityState },
        pageState: newPageState,
      };
    }
    case DELETE_INPUT: {
      const formGroup = cloneDeep(state.formGroup);
      const { skillName, pageName, appendIndex } = action;
      const { pageState } = state;
      const newEntityState = cloneDeep(state.entityState);
      const { appendInputs, inputs, repeatIndex } = formGroup[skillName].pages[pageName];
      const appendInput = appendInputs[action.inputIndex];

      appendInput[appendIndex].forEach((input) => {
        delete newEntityState[input.id];
      });
      appendInput.splice(appendIndex, 1);

      if (appendInput.length === 0) {
        inputs[repeatIndex].forEach((input, i) => {
          if (!input.concat) {
            if (input.keyword_single && inputs[repeatIndex]?.[i]) {
              inputs[repeatIndex][i].keyword = input.keyword_single;
            }
          }
        });
        formGroup[action.skillName].pages[action.pageName].inputs = inputs;
      }
      formGroup[skillName].pages[pageName].appendInputs[action.inputIndex] = appendInput;

      const newPageState = cloneDeep(pageState);
      newPageState[skillName] = checkPageStatus(
        newEntityState,
        formGroup,
        skillName,
        pageState[skillName],
      );

      return {
        ...state,
        formGroup,
        entityState: newEntityState,
        pageState: newPageState,
      };
    }
    case LOAD_SUGGESTIONS_REQUEST_FORM:
    case LOAD_PARTIAL_SUGGESTIONS_REQUEST_FORM: {
      const newSuggestions = cloneDeep(state.suggestions);
      const newSuggestionState = cloneDeep(state.suggestionsState);
      const { id, message } = action;
      newSuggestionState[id] = true;
      return {
        ...state,
        suggestionError: {
          ...state.suggestionError,
          [id]: false,
          [message]: false,
        },
        suggestions: newSuggestions,
        suggestionsState: newSuggestionState,
      };
    }
    case LOAD_SUGGESTIONS_SUCCESS_FORM: {
      const newSuggestionState = cloneDeep(state.suggestionsState);
      const newSuggestions = cloneDeep(state.suggestions);
      const newPrompts = cloneDeep(state.prompts);
      const { id, message } = action;
      const key = id || message.trim();
      newSuggestions[key] = action.suggestions;
      newPrompts[key] = action.prompts;
      newSuggestionState[key] = false;
      return {
        ...state,
        suggestions: newSuggestions,
        suggestionsState: newSuggestionState,
        prompts: newPrompts,
        isRequesting: false,
      };
    }
    case LOAD_SUGGESTIONS_FAILURE_FORM: {
      const { id } = action;
      const newSuggestionState = cloneDeep(state.suggestionsState);
      newSuggestionState[id] = false;
      return {
        ...state,
        suggestionError: {
          ...state.suggestionError,
          [action.id]: action.error,
          [action.message]: action.error,
        },
        suggestionsState: newSuggestionState,
        isRequesting: false,
      };
    }
    case CLEAR_SUGGESTIONS_FORM: {
      return {
        ...state,
        suggestionError: undefined,
        isRequesting: false,
        suggestions: {},
        suggestionsState: {},
      };
    }
    case CLEAR_UTTERANCE_COMPOSER: {
      return initialState;
    }
    case SAVE_SELECTED_DATASET: {
      return {
        ...state,
        selectedDataset: action.selectedDataset,
      };
    }
    case UPDATE_SINGLE_SELECT_DATA:
      return {
        ...state,
        textToInsertIntoSingleSelectForm: action.textToInsertIntoSingleSelectForm,
      };
    case OPEN_SKILL_COMPOSER: {
      return {
        ...state,
        showSkillComposer: true,
        selectedDataset: action.dataset,
        skillRecipe: action.recipe,
      };
    }
    case CLOSE_SKILL_COMPOSER: {
      return {
        ...state,
        showSkillComposer: false,
      };
    }
    case HANDLE_USER_INPUT: {
      const { newValue, currentSkill, pathState } = action;
      const newEntityState = {
        ...state.entityState,
        [newValue.entity]: {
          value: newValue.value,
          completed: newValue?.value?.length > 0,
          error: newValue?.errorMsg,
        },
      };

      const newPageState = {
        ...state.pageState,
        [currentSkill]: getPageState(state.formGroup[currentSkill], newEntityState),
      };
      const res = getUpdatedState(
        newEntityState,
        newPageState,
        state.formGroup,
        currentSkill,
        pathState,
        newValue,
      );

      const { utterance, completed, entityState, pageState, formGroup, coreCompleted } = res;

      return {
        ...state,
        utterance,
        completed,
        entityState,
        pageState,
        formGroup,
        coreCompleted,
      };
    }
    case HANDLE_PAGE_NAVIGATOR: {
      const { navigatorId, pathState, currentSkill } = action;

      const newPageState = {
        ...state.pageState,
        [currentSkill]: cloneDeep(state.pageState[currentSkill]),
      };
      newPageState[currentSkill][navigatorId] = true;

      const res = getUpdatedState(
        state.entityState,
        newPageState,
        state.formGroup,
        currentSkill,
        pathState,
      );

      const { utterance, completed, entityState, pageState, formGroup, coreCompleted } = res;
      return {
        ...state,
        utterance,
        completed,
        entityState,
        pageState,
        formGroup,
        coreCompleted,
      };
    }
    case SKILL_CHANGE: {
      const { skill } = action;

      const newPageState = {
        ...state.pageState,
        [skill]: getPageState(state.formGroup[skill], state.entityState),
      };

      const res = getUpdatedState(
        state.entityState,
        newPageState,
        state.formGroup,
        skill,
        state.pageState[skill],
      );

      const { utterance, completed, entityState, pageState, formGroup, coreCompleted } = res;
      return {
        ...state,
        utterance,
        completed,
        entityState,
        pageState,
        formGroup,
        coreCompleted,
      };
    }
    default:
      return state;
  }
};
