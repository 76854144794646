import { ChartTypes } from 'translate_dc_to_echart';
import Bar from './charts/Bar';
import Box from './charts/Box';
import Bubble from './charts/Bubble';
import Donut from './charts/Donut';
import Heatmap from './charts/Heatmap';
import Histogram from './charts/Histogram';
import HorizontalBar from './charts/HorizontalBar';
import Line from './charts/Line';
import Scatter from './charts/Scatter';
import SingleMetric from './charts/SingleMetric';
import StackedArea from './charts/StackedArea';
import StackedBar from './charts/StackedBar';
import Violin from './charts/Violin';

/**
 * Mapping of chart type to chart class
 */
export default {
  [ChartTypes.bar]: new Bar(),
  [ChartTypes.box]: new Box(),
  [ChartTypes.bubble]: new Bubble(),
  [ChartTypes.donut]: new Donut(),
  [ChartTypes.heatmap]: new Heatmap(),
  [ChartTypes.histogram]: new Histogram(),
  [ChartTypes.horizBar]: new HorizontalBar(),
  [ChartTypes.line]: new Line(),
  [ChartTypes.scatter]: new Scatter(),
  [ChartTypes.singleMetric]: new SingleMetric(),
  [ChartTypes.stackedArea]: new StackedArea(),
  [ChartTypes.stackedBar]: new StackedBar(),
  [ChartTypes.violin]: new Violin(),
};
