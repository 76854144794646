import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';
import { CONTEXTS } from '../../constants';
import {
  SEND_INTERRUPT_FAILURE,
  SEND_INTERRUPT_REQUEST,
  SEND_INTERRUPT_SUCCESS,
} from '../actions/interrupt.actions';

export interface ContextState {
  context: string;
  startTime: number | undefined;
  pendingDuration: number;
  placeholderFromServer: string | undefined;
  isReplaying: boolean;
  isStepwise: boolean;
  isInterrupting: boolean;
  isAdding: boolean;
  stepwiseMessage: string | null;
  lastStep: boolean;
  showLoader: boolean;
}

export const initialState: ContextState = {
  context: CONTEXTS.REST, // State or Status of the server
  startTime: undefined, // Point in time when the context began in milliseconds.
  pendingDuration: 0, // Time elapsed since context began in milliseconds
  // A placeholder sent by the server used to indicate the current state of the skill.
  placeholderFromServer: undefined,
  isReplaying: false, // Indicates if a replay session is running.
  isStepwise: false, // Indicates if a replay session is in stepwise mode
  isInterrupting: false,
  isAdding: false, // Indicates if an action is being added during stepwise replay
  stepwiseMessage: null,
  lastStep: false,
  showLoader: false,
};

/**
 * The FE context can be thought of as an object recording the
 * latest status of skill execution.
 * Including what placeholder we should show to users (sort of like what stage the skill is in),
 * how much time have we spent on the skill, when do we start.
 * And most importantly, are we now executing a skill
 */

// actions from a different slice
const sendInterruptFailureAction = createAction(SEND_INTERRUPT_FAILURE);
const sendInterruptRequestAction = createAction(SEND_INTERRUPT_REQUEST);
const sendInterruptSuccessAction = createAction(SEND_INTERRUPT_SUCCESS);

const contextSlice = createSlice({
  name: 'context',
  initialState,
  reducers: {
    setContext: (state, { payload }: PayloadAction<string>) => {
      // if the context is the same, don't update the startTime
      if (state.context === payload) return;
      state.context = payload;
      state.startTime = new Date().getTime();
    },
    /**
     * Dispatches an action to indicate that a replay session has started.
     */
    startReplay: (state, { payload = false }: PayloadAction<boolean>) => {
      state.isReplaying = true;
      state.isStepwise = payload;
    },
    /**
     * Dispatches an action that a replay session has ended.
     */
    stopReplay: (state) => {
      state.isReplaying = false;
      state.stepwiseMessage = null;
      state.isStepwise = false;
    },
    startUpdatingPendingDuration: () => {},
    updatePendingDuration: (state, { payload }: PayloadAction<number>) => {
      state.pendingDuration = payload;
    },
    stopUpdatingPendingDuration: (state) => {
      state.pendingDuration = 0;
    },
    /**
     * Dispatches an action to update the store's placeholder.
     *
     * @param {String} placeholderFromServer A placeholder message from the server.
     */
    updatePlaceholderFromServer: (
      state,
      { payload }: PayloadAction<{ placeholderFromServer: string | undefined }>,
    ) => {
      state.placeholderFromServer = payload.placeholderFromServer;
    },
    resetContext: () => initialState,
    updateStepwiseMessage: (state, { payload }: PayloadAction<{ data: string | null }>) => {
      state.stepwiseMessage = payload.data;
    },
    // Turn header loader on or off
    setHeaderLoader: (state, { payload }: PayloadAction<{ value: boolean }>) => {
      state.showLoader = payload.value;
    },
    setIsStepwise: (state, { payload }: PayloadAction<boolean>) => {
      state.isStepwise = payload;
    },
    /**
     * Used to indicate if an action is being added during stepwise replay
     * Used by Workflow editor and session stepwise replays.
     * @param {boolean} isAdding
     */
    setIsAdding: (state, { payload }: PayloadAction<boolean>) => {
      state.isAdding = payload;
    },
    /**
     * Used to indicate whether the replay in on its last step
     * @param {boolean} lastStep
     */
    setLastStep: (state, { payload }: PayloadAction<boolean>) => {
      state.lastStep = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendInterruptRequestAction, (state) => {
      state.isInterrupting = true;
    });
    builder.addCase(sendInterruptSuccessAction, (state) => {
      state.isInterrupting = false;
    });
    builder.addCase(sendInterruptFailureAction, (state) => {
      state.isInterrupting = false;
    });
  },
});

export const {
  setContext,
  startReplay,
  stopReplay,
  startUpdatingPendingDuration,
  updatePendingDuration,
  stopUpdatingPendingDuration,
  updatePlaceholderFromServer,
  resetContext,
  updateStepwiseMessage,
  setHeaderLoader,
  setIsStepwise,
  setIsAdding,
  setLastStep,
} = contextSlice.actions;

export default contextSlice.reducer;
