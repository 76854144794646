import {
  CLEAR_INSIGHTS_BOARD_CURRENT_LAYOUT,
  CLEAR_INSIGHTS_BOARD_UPDATE_ERROR,
  CLOSE_PUBLISH_MENU,
  DELETE_INSIGHTS_BOARD_PUBLICATIONS_FAILURE,
  DELETE_INSIGHTS_BOARD_PUBLICATIONS_SUCCESS,
  GET_INSIGHTS_BOARD_LAYOUT_FAILURE,
  GET_INSIGHTS_BOARD_LAYOUT_SUCCESS,
  GET_SNAPSHOTS_FAILURE,
  GET_SNAPSHOTS_SUCCESS,
  OPEN_PUBLISH_MENU,
  SET_PUBLICATION_CUSTOMIZATION,
  TOGGLE_INSIGHTS_BOARD_UNSAVED_CHANGES,
  TOGGLE_INSIGHTS_BOARD_VIEW_MODE,
  UPDATE_INSIGHTS_BOARD_FAILURE,
  UPDATE_INSIGHTS_BOARD_SUCCESS,
} from '../actions/insights_board.actions';

export const initialState = {
  error: {},
  publishMenuIsOpen: false, // Indicates if the chat panel should be shown
  chartType: null, // Type of chart being considered to be added to Insights Board
  objectId: null, // object Id of chart being considered to be added to Insights Board
  tableDataToPublish: null,
  currentLayout: {}, // Layout of an insights board
  snapshots: [], // Published snapshots
  insightsBoardUpdateError: {},
  editMode: true, // toggle between edit mode and view mode // TODO move to DB
  hasUnsavedChanges: false,
  lastSavedTime: new Date().getTime(),
  tableDisplayCustomizations: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_PUBLISH_MENU:
      return {
        ...state,
        publishMenuIsOpen: true,
        chartType: action.messageType,
        objectId: action.objectId,
        // dataset data is no longer needed for publish
        tableDataToPublish: action.messageType === 'dataset' ? action.messageType : action.data,
        tableUpdate: action.update,
        datasetName: action.datasetName,
        datasetVersion: action.datasetVersion,
      };
    case CLOSE_PUBLISH_MENU:
      return {
        ...state,
        publishMenuIsOpen: false,
        chartType: null,
        objectId: null,
        tableDataToPublish: null,
        datasetName: null,
        datasetVersion: null,
      };
    case GET_INSIGHTS_BOARD_LAYOUT_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case GET_INSIGHTS_BOARD_LAYOUT_SUCCESS:
      return {
        ...state,
        currentLayout: action.insightsBoardLayout,
        editMode: true, // TODO: Move this to Database
      };
    case GET_SNAPSHOTS_SUCCESS:
      return {
        ...state,
        snapshots: action.snapshots,
      };
    case GET_SNAPSHOTS_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case UPDATE_INSIGHTS_BOARD_FAILURE:
      return {
        ...state,
        insightsBoardUpdateError: action.error.response,
      };
    case CLEAR_INSIGHTS_BOARD_UPDATE_ERROR:
      return {
        ...state,
        insightsBoardUpdateError: {},
      };
    case CLEAR_INSIGHTS_BOARD_CURRENT_LAYOUT:
      return {
        ...state,
        currentLayout: {},
      };
    case TOGGLE_INSIGHTS_BOARD_VIEW_MODE:
      return {
        ...state,
        editMode: !state.editMode,
      };
    case DELETE_INSIGHTS_BOARD_PUBLICATIONS_SUCCESS:
      return {
        ...state,
        currentLayout: action.updatedLayout,
        insightsBoardUpdateError: {},
      };
    case DELETE_INSIGHTS_BOARD_PUBLICATIONS_FAILURE:
      return {
        ...state,
        insightsBoardUpdateError: action.error.response,
      };
    case TOGGLE_INSIGHTS_BOARD_UNSAVED_CHANGES:
      return {
        ...state,
        hasUnsavedChanges: action.toggleValue,
      };
    case UPDATE_INSIGHTS_BOARD_SUCCESS:
      return {
        ...state,
        hasUnsavedChanges: action.isFromHeader ? state.hasUnsavedChanges : false,
        currentLayout: {
          ...state.currentLayout,
          name: action.payload.name ? action.payload.name : state.currentLayout.name,
        },
        lastSavedTime: new Date().getTime(),
      };
    case SET_PUBLICATION_CUSTOMIZATION:
      // eslint-disable-next-line no-case-declarations
      const tableName =
        action.tableName === undefined
          ? state.tableDisplayCustomizations[action.publicationId]?.tableName
          : action.tableName;
      return {
        ...state,
        tableDisplayCustomizations: {
          ...state.tableDisplayCustomizations,
          [action.publicationId.toString()]: { tableName },
        },
      };
    default:
      return state;
  }
};
