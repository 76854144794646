import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { ReactComponent as WorkplaceIcon } from '../../../../../assets/icons/workplace-link.svg';
import { isWorkflowEditorPage } from '../../../../../constants/paths';
import { showReplayDisableToast } from '../../../../../constants/toast';
import { shareChartRequest } from '../../../../../store/actions/chart.actions';
import { addToast } from '../../../../../store/actions/toast.actions';
import { selectCurrentLocation } from '../../../../../store/sagas/selectors';
import { selectIsStepwise } from '../../../../../store/selectors/context.selector';
import { CHART_DESTINATIONS } from '../../../constants';

/**
 * A functional component to render a button UI to share visals to workspace (under experiment mode)
 */
const WorkspaceButton = (props) => {
  // redux dispatch hook
  const dispatch = useDispatch();

  const { isReplaying, isStepwise, experimentFlag, objectId, messageType, isWorkflowEditor } =
    props;

  /**
   * 1. Obtain the id of the chart
   * 2. Evoke the action that tells Worker to retrieve, save, and publish this chart
   *    from the message store
   */
  const shareToWorkplace = () => {
    if (isReplaying && !isStepwise) {
      showReplayDisableToast(addToast, 'share to workplace', dispatch);
      return;
    }
    dispatch(shareChartRequest('chart', objectId, CHART_DESTINATIONS.Workplace));
  };

  const showShareToWorkplaceButton =
    objectId && experimentFlag && messageType === 'viz' && !isWorkflowEditor;

  return (
    <>
      {showShareToWorkplaceButton ? (
        <MenuItem key="share" className="Menu-Item-Container" onClick={shareToWorkplace}>
          <div
            className={`${
              isReplaying && !isStepwise ? 'Menu-Item Replaying disabled' : 'Menu-Item'
            }`}
            data-cy="Menu-Item-Share"
          >
            <WorkplaceIcon className="Menu-Item-Icon" />
            <span className="Menu-Item-Text">Share to workplace</span>
          </div>
        </MenuItem>
      ) : null}
    </>
  );
};

WorkspaceButton.propTypes = {
  isReplaying: PropTypes.bool.isRequired,
  isStepwise: PropTypes.bool.isRequired,
  isWorkflowEditor: PropTypes.bool.isRequired,
  experimentFlag: PropTypes.bool.isRequired,
  objectId: PropTypes.string,
  messageType: PropTypes.string.isRequired,
};

WorkspaceButton.defaultProps = {
  objectId: undefined,
};

const mapStateToProps = (state) => ({
  isStepwise: selectIsStepwise(state),
  isReplaying: state.context.isReplaying,
  experimentFlag: state.settings.userConfig.experimentFlag,
  isWorkflowEditor: isWorkflowEditorPage(selectCurrentLocation(state)),
});

export default connect(mapStateToProps, {})(WorkspaceButton);
