import {
  ADD_CHART_STATUS,
  ADD_DATASET_TO_LIST,
  CLEAR_CHART_STATUSES,
  HANDLE_SELECT_CHART,
  HANDLE_SELECT_DATASET,
  SELECT_ALL_CHARTS,
  UNSELECT_ALL_CHARTS,
} from '../actions/chart_selection.actions';

const initialState = {
  chartStatuses: [],
  selectedDatasets: [],
  selectedCount: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_CHART_STATUS: {
      return {
        ...state,
        chartStatuses: [
          ...state.chartStatuses,
          {
            objectId: action.objectId,
            chartType: action.chartType,
            selectable: action.selectable,
            dataset: action.dataset,
            version: action.version,
            selected: false,
          },
        ],
      };
    }
    case ADD_DATASET_TO_LIST: {
      // check if the dataset to add is already in the chartStatuses array
      for (let i = 0; i < state.chartStatuses.length; i++) {
        if (
          state.chartStatuses[i].dataset === action.name &&
          state.chartStatuses[i].version === action.version
        ) {
          return state;
        }
      }
      // if the dataset to add is not already in the chartStatuses array,
      // add the dataset to the selectedDatasets array
      return {
        ...state,
        selectedDatasets: [
          ...state.selectedDatasets,
          {
            name: action.name,
            version: action.version,
            selected: false,
          },
        ],
      };
    }
    case CLEAR_CHART_STATUSES:
      return initialState;
    case HANDLE_SELECT_CHART: {
      const copySelectedCount = action.selected ? state.selectedCount + 1 : state.selectedCount - 1;
      const copyChartStatuses = [...state.chartStatuses];
      if (copyChartStatuses[action.index].selectable) {
        copyChartStatuses[action.index].selected = action.selected;
        return {
          ...state,
          chartStatuses: copyChartStatuses,
          selectedCount: copySelectedCount,
        };
      }
      return state;
    }
    case HANDLE_SELECT_DATASET: {
      const copySelectedCount = action.selected ? state.selectedCount + 1 : state.selectedCount - 1;
      const copySelectedDatasets = [...state.selectedDatasets];
      for (let i = 0; i < copySelectedDatasets.length; i++) {
        if (
          copySelectedDatasets[i].name === action.name &&
          copySelectedDatasets[i].version === action.version
        ) {
          if (action.selected) {
            copySelectedDatasets[i].selected = true;
          } else {
            // if the box is checked, the object is already in copySelectedDatasets, so remove it
            copySelectedDatasets[i].selected = false;
          }
          break;
        }
      }

      return {
        ...state,
        selectedCount: copySelectedCount,
        selectedDatasets: copySelectedDatasets,
      };
    }
    case SELECT_ALL_CHARTS: {
      let copySelectedCount = state.selectedCount;
      const copyChartStatuses = [...state.chartStatuses];
      for (let index = 0; index < copyChartStatuses.length; index++) {
        if (copyChartStatuses[index].selectable && copyChartStatuses[index].selected === false) {
          copyChartStatuses[index].selected = true;
          copySelectedCount++;
        }
      }

      const copySelectedDatasets = [...state.selectedDatasets];
      for (let index = 0; index < copySelectedDatasets.length; index++) {
        if (copySelectedDatasets[index].selected === false) {
          copySelectedDatasets[index].selected = true;
          copySelectedCount++;
        }
      }

      return {
        ...state,
        chartStatuses: copyChartStatuses,
        selectedDatasets: copySelectedDatasets,
        selectedCount: copySelectedCount,
      };
    }
    case UNSELECT_ALL_CHARTS: {
      const copyChartStatuses = [...state.chartStatuses];
      for (let index = 0; index < copyChartStatuses.length; index++) {
        if (copyChartStatuses[index].selectable) {
          copyChartStatuses[index].selected = false;
        }
      }
      const copySelectedDatasets = [...state.selectedDatasets];
      for (let index = 0; index < copySelectedDatasets.length; index++) {
        copySelectedDatasets[index].selected = false;
      }
      return {
        ...state,
        chartStatuses: copyChartStatuses,
        selectedDatasets: copySelectedDatasets,
        selectedCount: 0,
      };
    }
    default:
      return state;
  }
};
