import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { InitializeSessionPayload, initializeSession } from './session.slice';

export interface QuestionRecommendation {
  insightful_rating: number;
  question: string;
  schema_name: string;
}

export interface ObjectivesRecommendation {
  factors: string[];
  kpi: string;
  relevance: number;
  title: string;
  type: string;
  utterance: string;
  recommendation_type: string;
}
export interface GetRecommendationsRequestPayload {
  sessionId?: string;
  refresh?: boolean;
  forceRegenerate?: boolean;
}

export enum RecommendationsLoadingStatus {
  Idle = 'idle',
  Queuing = 'queuing',
  Generating = 'generating',
  Failed = 'failed',
}

export enum ObjectivesRegistrationStatus {
  Idle = 'idle',
  Registering = 'registering',
  Failed = 'failed',
}

export enum QuestionRecommendationStatus {
  Idle = 'idle',
  Registering = 'registering',
  Failed = 'failed',
}

export interface RecommendationsState {
  sessionId?: string;
  recommendations: {
    questions: {
      data: QuestionRecommendation[];
      loading: boolean;
      loadingStatus: RecommendationsLoadingStatus;
      error: string | null;
    };
    objectives: {
      data: ObjectivesRecommendation[];
      loading: boolean;
      loadingStatus: RecommendationsLoadingStatus;
      error: string | null;
      registrationStatus: ObjectivesRegistrationStatus;
    };
  };
}

const initialState: RecommendationsState = {
  sessionId: undefined,
  recommendations: {
    questions: {
      data: [],
      loading: false,
      loadingStatus: RecommendationsLoadingStatus.Idle,
      error: null,
    },
    objectives: {
      data: [],
      loading: false,
      loadingStatus: RecommendationsLoadingStatus.Idle,
      error: null,
      registrationStatus: ObjectivesRegistrationStatus.Idle,
    },
  },
};

const recommendationsSlice = createSlice({
  name: 'recommendations',
  initialState,
  reducers: {
    // Question Recommendations
    getQuestionRecommendationsRequest: {
      reducer: (state) => {
        state.recommendations.questions.loading = true;
        state.recommendations.questions.error = null;
        state.recommendations.questions.data = [];
      },
    },
    getQuestionRecommendationsSuccess: (
      state,
      { payload }: PayloadAction<{ recommendations: QuestionRecommendation[] }>,
    ) => {
      state.recommendations.questions.data = payload.recommendations;
      state.recommendations.questions.loading = false;
      state.recommendations.questions.loadingStatus = RecommendationsLoadingStatus.Idle;
    },
    getQuestionRecommendationsFailure: (state, { payload }: PayloadAction<{ error: Error }>) => {
      state.recommendations.questions.loading = false;
      state.recommendations.questions.error = payload.error.message;
      state.recommendations.questions.loadingStatus = RecommendationsLoadingStatus.Failed;
    },
    setQuestionRecommendationsLoadingStatus: (
      state,
      { payload }: PayloadAction<RecommendationsLoadingStatus>,
    ) => {
      state.recommendations.questions.loadingStatus = payload;
    },
    resetQuestionRecommendations: (state) => {
      state.recommendations.questions.data = [];
      state.recommendations.questions.loading = false;
      state.recommendations.questions.loadingStatus = RecommendationsLoadingStatus.Idle;
    },
    // Objectives Recommendations
    getObjectivesRecommendationsRequest: {
      reducer: (state) => {
        state.recommendations.objectives.loading = true;
        state.recommendations.objectives.error = null;
        state.recommendations.objectives.data = [];
      },
    },
    getObjectivesRecommendationsSuccess: (
      state,
      { payload }: PayloadAction<{ recommendations: ObjectivesRecommendation[] }>,
    ) => {
      state.recommendations.objectives.data = payload.recommendations;
      state.recommendations.objectives.loading = false;
      state.recommendations.objectives.loadingStatus = RecommendationsLoadingStatus.Idle;
    },
    getObjectivesRecommendationsFailure: (state, { payload }: PayloadAction<{ error: Error }>) => {
      state.recommendations.objectives.loading = false;
      state.recommendations.objectives.error = payload.error.message;
      state.recommendations.objectives.loadingStatus = RecommendationsLoadingStatus.Failed;
    },
    setObjectivesRecommendationsLoadingStatus: (
      state,
      { payload }: PayloadAction<RecommendationsLoadingStatus>,
    ) => {
      state.recommendations.objectives.loadingStatus = payload;
    },
    resetObjectivesRecommendations: {
      reducer: (state) => {
        state.recommendations.objectives.data = [];
        state.recommendations.objectives.loading = false;
        state.recommendations.objectives.loadingStatus = RecommendationsLoadingStatus.Idle;
      },
    },
    registerEngagementWithObjectivesRecommendationRequest: {
      reducer: (state) => {
        // eslint-disable-next-line
        state.recommendations.objectives.registrationStatus =
          ObjectivesRegistrationStatus.Registering;
      },
    },
    registerEngagementWithObjectivesRecommendationSuccess: {
      reducer: (state) => {
        state.recommendations.objectives.registrationStatus = ObjectivesRegistrationStatus.Idle;
      },
    },
    registerEngagementWithObjectivesRecommendationFailure: {
      reducer: (state) => {
        state.recommendations.objectives.registrationStatus = ObjectivesRegistrationStatus.Failed;
      },
    },

    registerEngagementWithQuestionRecommendationRequest: {
      reducer: (state) => {
        state.recommendations.questions.registrationStatus =
          QuestionRecommendationStatus.Registering;
      },
    },
    registerEngagementWithQuestionRecommendationSuccess: {
      reducer: (state) => {
        state.recommendations.questions.registrationStatus = QuestionRecommendationStatus.Idle;
      },
    },
    registerEngagementWithQuestionRecommendationFailure: {
      reducer: (state) => {
        state.recommendations.questions.registrationStatus = QuestionRecommendationStatus.Failed;
      },
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      initializeSession,
      (state, { payload }: PayloadAction<InitializeSessionPayload>) => {
        if (state.sessionId !== payload.session)
          // if the session id has changed, reset the recommendations
          return { ...initialState, sessionId: payload.session };
        return state;
      },
    );
  },
});

export const {
  getObjectivesRecommendationsRequest,
  getObjectivesRecommendationsSuccess,
  getObjectivesRecommendationsFailure,
  setObjectivesRecommendationsLoadingStatus,
  getQuestionRecommendationsRequest,
  getQuestionRecommendationsSuccess,
  getQuestionRecommendationsFailure,
  setQuestionRecommendationsLoadingStatus,
  resetQuestionRecommendations,
  resetObjectivesRecommendations,
  registerEngagementWithObjectivesRecommendationRequest,
  registerEngagementWithObjectivesRecommendationSuccess,
  registerEngagementWithObjectivesRecommendationFailure,
  registerEngagementWithQuestionRecommendationRequest,
  registerEngagementWithQuestionRecommendationSuccess,
  registerEngagementWithQuestionRecommendationFailure,
} = recommendationsSlice.actions;

export default recommendationsSlice.reducer;
