import { COLUMN_TYPES } from '../constants';

export const MAX_PICK_COUNT = 3;

export const DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const TIME_FORMAT = 'HH:mm:ss';

/**
 * Returns whether a column type is supported by the model profiler.
 * Currently, interval and json columns are not supported.
 */
export const typeSupportedByProfiler = (type) => {
  switch (type) {
    case COLUMN_TYPES.INTEGER:
    case COLUMN_TYPES.FLOAT:
    case COLUMN_TYPES.BOOLEAN:
    case COLUMN_TYPES.STRING:
    case COLUMN_TYPES.TIME:
    case COLUMN_TYPES.DATE:
    case COLUMN_TYPES.TIMESTAMP:
      return true;
    case COLUMN_TYPES.INTERVAL:
    case COLUMN_TYPES.JSON:
      return false;
    default:
      return false;
  }
};

/**
 * Converts user input into the expected value type.
 * @param {String} type expected type of the user's input
 * @param {mixed} rawInput raw user input
 * @returns rawInput converted into the representation type defined by type
 */
export const formatPredictionInput = (type, rawInput) => {
  let formatted;
  switch (type) {
    case COLUMN_TYPES.INTEGER:
      formatted = Number.parseInt(rawInput, 10);
      break;
    case COLUMN_TYPES.FLOAT:
      formatted = Number.parseFloat(rawInput);
      break;
    case COLUMN_TYPES.BOOLEAN: {
      formatted = String(rawInput);
      switch (formatted.toLocaleLowerCase()) {
        case 'true':
        case 't':
        case '1':
          formatted = true;
          break;
        case 'false':
        case 'f':
        case '0':
          formatted = false;
          break;
        default:
          formatted = Boolean(rawInput);
          break;
      }
      break;
    }
    case COLUMN_TYPES.STRING: {
      if (rawInput === null || rawInput === undefined) break;
      formatted = String(rawInput);
      break;
    }
    case COLUMN_TYPES.DATE:
    case COLUMN_TYPES.TIME:
    case COLUMN_TYPES.TIMESTAMP:
      // Date, Time, and DateTime types are handled as regular text inputs
      if (rawInput === null || rawInput === undefined) break;
      formatted = String(rawInput);
      break;
    default:
      formatted = rawInput;
      break;
  }

  // Convert any 'null-like' values to null
  if (formatted === undefined || Number.isNaN(formatted) || formatted === '') {
    return null;
  }
  return formatted;
};

const compareDates = (a, b) => {
  if (Number.isNaN(Date.parse(a))) return -1;
  if (Number.isNaN(Date.parse(b))) return 1;
  return new Date(a) - new Date(b);
};

/**
 * Takes a list of data points and sorts it in-place based on the sort column
 * @param {String} type  expected type of the user's input
 * @param {String} sortColumn feature column to sort the data points on
 * @param {Object} points list of data point objects
 *
 * Data point objects take the following form: { "col_a": 1, "col_b": "x", "col_c": 10.0 }
 */
export const sortDataPoints = (type, sortColumn, points) => {
  switch (type) {
    case COLUMN_TYPES.INTEGER:
    case COLUMN_TYPES.FLOAT: {
      points.sort((a, b) => a[sortColumn] - b[sortColumn]);
      break;
    }
    case COLUMN_TYPES.DATE:
    case COLUMN_TYPES.TIME:
    case COLUMN_TYPES.TIMESTAMP:
      points.sort((a, b) => compareDates(a[sortColumn], b[sortColumn]));
      break;
    default: {
      points.sort((a, b) => {
        if (a[sortColumn] < b[sortColumn]) {
          return -1;
        }
        if (a[sortColumn] > b[sortColumn]) {
          return 1;
        }
        return 0;
      });
      break;
    }
  }
};

/**
 * Parses a model's spec for easier usage.
 * @param {Object} spec
 * @returns formatted model spec
 */
export const parseModelSpec = (spec) => {
  let modelOutOfDate = false;
  const modelInputs = spec['Model inputs'];
  // eslint-disable-next-line no-unused-vars
  Object.entries(modelInputs).forEach(([_, value]) => {
    // Check whether the model is not suited for use with the profiler
    if (
      value.autobinning === undefined ||
      value.feature_pruned === undefined ||
      value.timeslice_enable === undefined
    ) {
      modelOutOfDate = true;
    }
  });

  // eslint-disable-next-line no-unused-vars
  Object.entries(modelInputs).forEach(([_, value]) => {
    if (Array.isArray(value.min_max)) {
      value.min_max = value.min_max.map((element) => {
        if (typeof element === 'object' && element !== null && Object.values(element).length > 0) {
          return Object.values(element)[0];
        }
        return element;
      });
    }
  });

  // Backup in case the published model is older and doesn't have the prediction output column name
  // NOTE: If the format of the prediction output column name changes
  // on the BE, this bit of code will also have to be changed to reflect
  const modelOutput = spec['Model output'];
  if (modelOutput.Name && modelOutput.PredictName === undefined) {
    modelOutput.PredictName = `${
      modelOutput.Name.charAt(0).toUpperCase() + modelOutput.Name.slice(1)
    }Predicted`;
  }
  return {
    modelOutOfDate,
    mlWorkflow: JSON.parse(spec['ML workflow']),
    modelInputs,
    modelOutput,
    modelScores: JSON.parse(spec['model scores']),
    modelName: JSON.parse(spec.model_name),
    modelType: JSON.parse(spec.model_type),
    modelVersion: spec.model_version,
  };
};
