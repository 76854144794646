import { DEFAULT_NUM_ROWS_IB } from './index';

export function ordinal(n) {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}
export const itemType = (chartType) => {
  switch (chartType) {
    case 'viz':
      return 'Chart';
    case 'table':
      return 'Table';
    case 'note':
      return 'Note';
    case 'dataset':
      return 'Dataset';
    case 'pivot table':
      return 'Pivot Table';
    default:
      return 'Item';
  }
};

export const visualize = ({ dependentVariable } = {}) => {
  return `visualize ${dependentVariable} with optimization`;
};

export const publishChartUtterance = (
  chartType,
  objectId,
  datasetName,
  datasetVersion,
  boardName,
) => {
  if (chartType === 'dataset') {
    return `Publish the <strong>${chartType}</strong> with dataset name ${datasetName} version ${datasetVersion} to the insights board called <strong>${boardName}</strong>`;
  } else if (chartType === 'table') {
    return `Publish the <strong>${chartType}</strong> with id <strong>${objectId}</strong> with rows ${DEFAULT_NUM_ROWS_IB} to the insights board called <strong>${boardName}</strong>`;
  }
  return `Publish the <strong>${chartType}</strong> with id <strong>${objectId}</strong> to the insights board called <strong>${boardName}</strong>`;
};

export const loadFile = (file) => {
  return `Load data from the file <strong>${file}</strong>`;
};

export const loadSnapshot = (sessionName, withoutPausing = true) => {
  return `Load data from the snapshot called <strong>${sessionName}</strong>${
    withoutPausing ? ' without pausing' : ''
  }`;
};

export const loadDatabase = (conn) => {
  return `Load data from the database ${conn}`;
};

export const loadDataset = (dataset) => {
  return `Load data from the datasets <strong>${dataset}</strong>`;
};

export const loadDatasets = (datasets) => {
  const formattedDatasets = datasets.map((d) => `<strong>${d}</strong>`).join(', ');
  return `Load data from the datasets ${formattedDatasets}`;
};

/**
 * Creates a kwargs object for the LoadDataFromDataset skill
 *
 * @param {{ name: string, uuid: string }[]} datasets
 * @returns {
 *   skill: 'LoadDataFromDataset',
 *   kwargs: {
 *     datasets: {
 *       kind: 'dataset_object',
 *       value: {
 *         name: string,
 *         uuid: string
 *       }
 *     }[]
 *   }
 * }
 */
export const loadDatasetsKwargs = (datasets) => {
  const formattedDatasets = datasets.map((d) => {
    return {
      kind: 'dataset_object',
      value: {
        name: d.name,
        uuid: d.uuid,
      },
    };
  });
  return {
    skill: 'LoadDataFromDataset',
    kwargs: {
      datasets: formattedDatasets,
    },
  };
};

export const loadURL = (URL) => {
  return `Load data from the URL <strong>${URL}</strong>`;
};

export const publishChartDisplay = (boardName) => {
  return `Publish the chart to the insights board called <strong>${boardName}</strong>`;
};

export const saveWorkflowVersion = (workflowName, ownerEmail = null) => {
  if (ownerEmail) {
    return `Save this session as a version of <strong>${workflowName} [${ownerEmail}]</strong>`;
  }
  return `Save this session as a version of <strong>${workflowName}</strong>`;
};

export const publishDatasetDisplay = (datasetName, datasetVersion, boardName) => {
  return `Publish the dataset ${datasetName} version ${datasetVersion}
  to the insights board called <strong>${boardName}</strong>`;
};

export const selectSaveUtterance = (
  dcChartIds = [],
  tableIds = [],
  noteIds = [],
  tabvisualIds = [],
  datasetEntities = [],
  workflowName,
) => {
  let charts = '';
  let tables = '';
  let notes = '';
  let tabVisuals = '';
  let datasets = '';
  if (dcChartIds.length) {
    charts = ` charts${dcChartIds.map((id) => {
      return ` <strong>${id}</strong>`;
    })}`;
  }
  if (tableIds.length) {
    tables = ` tables${tableIds.map((id) => {
      return ` <strong>${id}</strong>`;
    })}`;
  }
  if (noteIds.length) {
    notes = ` notes${noteIds.map((id) => {
      return ` <strong>${id}</strong>`;
    })}`;
  }

  if (tabvisualIds.length) {
    tabVisuals = ` tabvisuals${tabvisualIds.map((id) => {
      return ` <strong>${id}</strong>`;
    })}`;
  }

  if (datasetEntities.length) {
    // pass the datasets to the backend with the name and version separated by an underscore
    // this passes any intermediate datasets to the backend
    datasets = ` datasets${datasetEntities.map((ds) => {
      return ` ${ds.name}, version ${ds.version}`;
    })}`;
  }

  const composedUtteranceArray = [];
  if (dcChartIds.length > 0) {
    composedUtteranceArray.push(charts);
  }
  if (tableIds.length > 0) {
    composedUtteranceArray.push(tables);
  }
  if (noteIds.length > 0) {
    composedUtteranceArray.push(notes);
  }
  if (tabvisualIds.length > 0) {
    composedUtteranceArray.push(tabVisuals);
  }
  if (datasetEntities.length > 0) {
    composedUtteranceArray.push(datasets);
  }

  const composedUtterance = `Save the recipe for the ${composedUtteranceArray.join(
    ',',
  )}  with the name <strong>${workflowName}</strong>`;
  return composedUtterance;
};

export const selectSaveUtteranceDisplay = (
  dcChartIds = [],
  tableIds = [],
  noteIds = [],
  tabvisualIds = [],
  datasetEntities = [],
  workflowName,
) => {
  const quantify = (num, word) => {
    if (!num || num === 0) return '';
    let phrase = ` ${num} ${word}`;
    if (num > 1) phrase += 's';
    return phrase;
  };
  const charts = quantify(dcChartIds.length, 'chart');
  const tables = quantify(tableIds.length, 'table');
  const notes = quantify(noteIds.length, 'note');
  const tabvisuals = quantify(tabvisualIds.length, 'tabvisuals');
  const datasets = quantify(datasetEntities.length, 'dataset');

  const composedUtteranceArray = [];
  if (dcChartIds.length > 0) {
    composedUtteranceArray.push(charts);
  }
  if (tableIds.length > 0) {
    composedUtteranceArray.push(tables);
  }
  if (noteIds.length > 0) {
    composedUtteranceArray.push(notes);
  }
  if (tabvisualIds.length > 0) {
    composedUtteranceArray.push(tabvisuals);
  }
  if (datasetEntities.length > 0) {
    composedUtteranceArray.push(datasets);
  }

  const composedUtterance = `Save the recipe for the selected ${composedUtteranceArray.join(
    ',',
  )} with the name <strong>${workflowName}</strong>`;
  return composedUtterance;
};

export const replayWorkflow = (workflowName) => {
  return `Replay the recipe <strong>${workflowName}</strong> without pausing`;
};

export const startStepwiseReplay = (workflowName) => {
  return `Replay the recipe <strong>${workflowName}</strong> one step at a time`;
};

export const addBreakpoint = (rowNumber) => {
  return `Add breakpoint ${rowNumber}`;
};

export const removeBreakpoint = (rowNumber) => {
  return `Remove breakpoint ${rowNumber}`;
};

export const plotChart = (chartName) => {
  return `Plot Chart ${chartName}`;
};

export const utteranceUseDataset = (name, version) => {
  if (version === undefined) {
    return `Use the dataset called ${name}`;
  }
  return `Use the dataset called ${name} version ${version}`;
};

export const describeDataset = (name) => {
  return `Describe the dataset ${name}`;
};

export const describeColumn = (column) => `Describe the column ${column} from the dataset current`;

export const dropColumn = (column) => `Drop the columns ${column}`;

export const sampleDataset = (name) => {
  return `Sample the dataset ${name}`;
};

export const previewDataset = (name) => {
  return `Preview the dataset ${name}`;
};

export const CURRENT_DATASET_NAME = 'current';

export const defineExpression = (aggExpressionList, extExpressionList) => {
  return `Define multiple ${aggExpressionList}  ${extExpressionList}`;
};
/**
 * A method to format the list of expression name
 * for forget the expressions skills in backend
 * @param {string[]} expressionList
 * @returns A formmated string of utterance
 */
export const forgetExpressions = (expressionList) => {
  const nameList = expressionList.map((expr) => {
    // need strong tag when name contains space
    return `<strong>${expr}</strong>`;
  });
  return `Forget the expressions ${nameList.join(', ')}`;
};
