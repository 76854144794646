import axios from 'axios';
import { endpoints } from '../constants/endpoints';

export const getUnseenNotifications = (accessToken) =>
  axios.get(endpoints.notification, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

export const getSeenNotifications = (accessToken) =>
  axios.get(endpoints.seenNotifications, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

export const getAllNotifications = (accessToken) =>
  axios.get(endpoints.manageNotifications, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

export const terminateNotification = (accessToken, notificationId) =>
  axios.post(
    endpoints.manageNotifications,
    {
      notification_id: notificationId,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    },
  );

export const acknowledgeNotification = (accessToken, notificationIds) =>
  axios.post(
    endpoints.seenNotifications,
    { notification_ids: notificationIds },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    },
  );

export const deleteNotification = (accessToken, notificationId) =>
  axios.delete(
    endpoints.seenNotifications,
    {},
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        notification_id: notificationId,
      },
    },
  );

export const createNotification = (accessToken, newNotification) =>
  axios.post(endpoints.notification, JSON.stringify(newNotification), {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });

export const editNotification = (accessToken, newNotification) =>
  axios.post(endpoints.manageNotifications, JSON.stringify(newNotification), {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });
