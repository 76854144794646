import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { NavigationItemSource, NavigationItemStatus } from '../../constants/session';
import { sortNamesByActive } from '../../utils/dataspace';

export interface Dataset {
  id: string;
  pipeliner_dataset_id: string;
  name: string;
  version: number;
  status: NavigationItemStatus;
  source: NavigationItemSource;
}

export interface DataSpaceState {
  /** Map from dc_dataset ID to dataset */
  datasets: { [key: string]: Dataset };
  /** Map from session ID to order of datasets (by name) in sidebar/tabs */
  datasetOrder: { [sessionID: string]: string[] };
  /** Keeps track of unsaved changes in the current dataset */
  editDataset: {
    /** Map from current column name to edited column name */
    columnRenameMap: { [currentColumnName: string]: string };
  };
  /** current dataset id in the FE */
  currentDcDatasetId: string | null;
  /** current dataset id in the BE (need to keep track of this so we can
   * fire a use utterance before skill submission if the FE current and BE current are different)
   */
  currentWorkerDcDatasetId: string | null;
  loading: boolean;
  requested: boolean;
  error: string | null;
  setCurrentDatasetError: string | null;
  duplicateName: string;
  /** Whether the save session's active datasets dialog is open. */
  savingActiveDatasetsAs: boolean;
  /** Whether the save current dataset modal is open. */
  showSaveDatasetAs: boolean;
}

export interface GetDataspaceSuccessPayload {
  datasets: { [key: string]: Dataset };
  sessionID: string;
}

export interface UpdateDatasetPayload {
  dcDatasetID: string;
  status?: NavigationItemStatus;
  name?: string;
}

export interface UpdateDatasetSuccessPayload {
  sessionId: string;
  datasets: Dataset[];
}

export interface DcDatasetIdPayload {
  dcDatasetId: string | null;
}

export interface ChangeDatasetOrderPayload {
  sessionID: string;
  // Dataset to move's name
  sourceDatasetName: string;
  // What dataset (by name) to put this dataset next to
  destinationDatasetName: string;
  // Offset from the destination dataset to put source
  destinationOffset: number;
}

export interface SaveDatasetAsPayload {
  name: string;
  upsert: Upsert;
}

export enum Upsert {
  INSERT = 'insert',
  UPDATE = 'update',
}

export const initialState: DataSpaceState = {
  datasets: {},
  datasetOrder: {},
  editDataset: {
    columnRenameMap: {},
  },
  currentDcDatasetId: null,
  currentWorkerDcDatasetId: null,
  loading: false,
  requested: false,
  error: null,
  setCurrentDatasetError: null,
  duplicateName: '',
  savingActiveDatasetsAs: false,
  showSaveDatasetAs: false,
};

const dataspaceSlice = createSlice({
  name: 'dataspace',
  initialState,
  reducers: {
    getDataspaceRequest: (state) => {
      state.loading = true;
      state.requested = true;
      state.error = null;
    },
    getDataspaceSuccess: (state, { payload }: PayloadAction<GetDataspaceSuccessPayload>) => {
      state.datasets = payload.datasets;
      state.loading = false;

      // Update the order of datasets
      let datasetOrder = state.datasetOrder[payload.sessionID] ?? [];

      // Set to track added datasets
      const newDatasetNames = new Set(
        Object.values(payload.datasets).map((dataset) => dataset.name),
      );

      // Remove any datasets in the order that are no longer in the dataspace
      datasetOrder = datasetOrder.filter((datasetName) => {
        if (!newDatasetNames.has(datasetName)) {
          // Remove any datasets in the order that are no longer
          // in the dataspace
          return false;
        }
        // If a dataset name is already in our order,
        // Remove from newDatasetNames to not add twice
        newDatasetNames.delete(datasetName);
        return true;
      });

      // Add any new dataset names to the end of the order
      datasetOrder.push(...[...newDatasetNames]);

      state.datasetOrder[payload.sessionID] = sortNamesByActive(
        datasetOrder,
        Object.values(payload.datasets),
      );
    },
    getDataspaceFailure: (state, { payload }: PayloadAction<{ error: Error }>) => {
      state.loading = false;
      state.error = payload.error.message;
    },
    updateDatasetRequest: {
      reducer: (state) => {
        state.error = null;
      },
      prepare: (payload: UpdateDatasetPayload) => ({ payload }),
    },
    updateDatasetSuccess: (state, { payload }: PayloadAction<UpdateDatasetSuccessPayload>) => {
      const { datasets, sessionId } = payload;
      for (const dataset of datasets) {
        // first set the dataset in the store
        state.datasets[dataset.id] = dataset;
        // then update the current dataset if necessary
        if (
          // if the dataset was current, but is being deselected or hidden
          // we need to set a new current dataset
          dataset.status !== NavigationItemStatus.ACTIVE &&
          state.currentDcDatasetId === dataset.id
        ) {
          // set the new current dataset to the first promoted dataset we find
          const newCurrent = Object.values(state.datasets).find(
            (d) => d.status === NavigationItemStatus.ACTIVE && d.id !== dataset.id,
          );
          state.currentDcDatasetId = newCurrent?.id || null;
        } else if (dataset.status === NavigationItemStatus.ACTIVE && !state.currentDcDatasetId) {
          // if the dataset is being promoted, but there is no current dataset
          // we need to set the promoted dataset as current
          state.currentDcDatasetId = dataset.id;
        }
      }
      // update the order of datasets
      const datasetOrder = state.datasetOrder[sessionId] ?? [];
      state.datasetOrder[sessionId] = sortNamesByActive(
        datasetOrder,
        Object.values(state.datasets),
      );
    },
    updateDatasetFailure: (state, { payload }: PayloadAction<{ error: Error }>) => {
      state.error = payload.error.message;
    },
    setCurrentDatasetRequest: {
      reducer: (state) => {
        state.setCurrentDatasetError = null;
      },
      prepare: (payload: DcDatasetIdPayload) => ({ payload }),
    },
    setCurrentDatasetSuccess: (state, { payload }: PayloadAction<DcDatasetIdPayload>) => {
      state.currentDcDatasetId = payload.dcDatasetId;
    },
    /**
     * Request the save all active datasets in the current dataspace as dataset objects.
     * @param payload - The name of the folder to be created and parent new dataset objects.
     */

    /** Saves all active datasets as dataset objects under a folder.  */
    saveDatasetsAs: {
      /** @param folder name of folder to save datasets under */
      prepare: (folder: string) => ({ payload: folder }),
      reducer: () => {},
    },
    /** Request to save the active dataset as a new dataset object. */
    saveDatasetAsRequest: {
      /** @param name name of the new dataset object */
      prepare: (name: string) => ({ payload: name }),
      reducer: () => {},
    },
    saveDatasetAsSuccess: { prepare: (payload: string) => ({ payload }), reducer: () => {} },
    saveDatasetAsFailure: {
      prepare: (payload: { error: Error }) => ({ payload }),
      reducer: () => {},
    },
    setCurrentDatasetFailure: (state, { payload }: PayloadAction<{ error: Error }>) => {
      state.setCurrentDatasetError = payload.error.message;
    },
    resetDataSpace: (state) => ({ ...initialState, datasetOrder: state.datasetOrder }),
    getCurrentDcDatasetIdRequest: () => {},
    getCurrentDcDatasetIdSuccess: (state, { payload }: PayloadAction<string>) => {
      const workerDcDatasetID = payload;
      if (state.currentWorkerDcDatasetId === workerDcDatasetID) {
        return;
      }
      state.currentWorkerDcDatasetId = workerDcDatasetID;
      state.currentDcDatasetId = workerDcDatasetID;
    },
    getCurrentDcDatasetIdFailure: (state, { payload }: PayloadAction<{ error: Error }>) => {
      state.currentDcDatasetId = null;
      state.currentWorkerDcDatasetId = null;
      state.error = payload.error.message;
    },
    swapDatasetOrder: (state, { payload }: PayloadAction<ChangeDatasetOrderPayload>) => {
      const { sessionID, sourceDatasetName, destinationDatasetName, destinationOffset } = payload;
      let datasetOrder = state.datasetOrder[sessionID];
      if (!datasetOrder) return;

      // First remove the source dataset from its original place
      datasetOrder = datasetOrder.filter((name) => name !== sourceDatasetName);
      // Then find where our destination dataset is
      const destinationI = datasetOrder.findIndex((name) => name === destinationDatasetName);
      // Then insert our source dataset there, with a provided offset
      datasetOrder.splice(destinationI + destinationOffset, 0, sourceDatasetName);

      state.datasetOrder[payload.sessionID] = datasetOrder;
    },
    duplicateDatasetStart: (state, { payload }: PayloadAction<DcDatasetIdPayload>) => {
      const dataset = state.datasets[payload?.dcDatasetId ?? ''];
      state.duplicateName = dataset?.name;
    },
    duplicateDatasetUpdate: (state, { payload }: PayloadAction<string>) => {
      state.duplicateName = payload;
    },
    duplicateDatasetConfirmed: {
      reducer: () => {},
      prepare: (payload: DcDatasetIdPayload) => ({ payload }),
    },
    duplicateDatasetCanceled: () => {},
    setSavingSessionDatasetAs: (state, { payload }: PayloadAction<boolean>) => {
      state.savingActiveDatasetsAs = payload;
    },
    /** Opens the save dataset as modal. */
    openSaveDatasetAs: (state) => {
      state.showSaveDatasetAs = true;
    },
    /** Closes the save dataset as modal. */
    closeSaveDatasetAs: (state) => {
      state.showSaveDatasetAs = false;
    },
    /** Action to trigger saga that will upsert an in-session dataset as a dataset object. */
    saveDatasetAs: {
      prepare: (name: string, upsert: Upsert) => ({ payload: { name, upsert } }),
      reducer: () => {},
    },
    /** discards any unsaved changes to a dataset */
    discardDatasetEdits: (state) => {
      state.editDataset = {
        ...initialState.editDataset,
      };
    },
    /**
     * Upsert into the columnRenameMap with oldColumnName as the key and newColumnName as the
     * value. If oldColumnName and newColumnName are equal to each other, then we remove the key
     * from the columnRenameMap.
     */
    editColumnName: (
      state,
      { payload }: PayloadAction<{ oldColumnName: string; newColumnName: string }>,
    ) => {
      const { oldColumnName, newColumnName } = payload;
      if (oldColumnName === newColumnName) {
        // Remove the column from the map if the new name is the same as the old name or the new column name is empty
        delete state.editDataset.columnRenameMap[oldColumnName];
      } else {
        state.editDataset.columnRenameMap[oldColumnName] = newColumnName;
      }
    },
    submitDatasetEdits: () => {},
    handleUnsavedChanges: () => {},
  },
});

export const {
  getDataspaceRequest,
  getDataspaceSuccess,
  getDataspaceFailure,
  updateDatasetRequest,
  updateDatasetSuccess,
  updateDatasetFailure,
  saveDatasetsAs,
  saveDatasetAsRequest,
  saveDatasetAsSuccess,
  saveDatasetAsFailure,
  setCurrentDatasetRequest,
  setCurrentDatasetSuccess,
  setCurrentDatasetFailure,
  resetDataSpace,
  getCurrentDcDatasetIdRequest,
  getCurrentDcDatasetIdSuccess,
  getCurrentDcDatasetIdFailure,
  swapDatasetOrder,
  duplicateDatasetStart,
  duplicateDatasetUpdate,
  duplicateDatasetConfirmed,
  duplicateDatasetCanceled,
  setSavingSessionDatasetAs,
  openSaveDatasetAs,
  closeSaveDatasetAs,
  saveDatasetAs,
  discardDatasetEdits,
  editColumnName,
  submitDatasetEdits,
  handleUnsavedChanges,
} = dataspaceSlice.actions;

export default dataspaceSlice.reducer;
