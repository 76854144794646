import Refresh from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { isStatusRequesting, isStatusSuccess, isStatusUnrequested } from '../../utils/home_screen';
import './index.scss';

const getRelativeTime = (lastUpdated, status, failureText) => {
  let relativeTime = lastUpdated ? `Last Updated: ${moment(lastUpdated).fromNow()}` : '';
  if (isStatusRequesting(status)) {
    relativeTime = 'Loading...';
  } else if (isStatusUnrequested(status)) {
    relativeTime = '';
  } else if (!isStatusSuccess(status)) {
    relativeTime = failureText;
  }
  return relativeTime;
};

/**
 * RefreshButton is a button to refresh data (e.g invalidate a cache).
 * Its tooltip contains the last time the data was updated, and status
 * indicates the status of requesting that data.
 */
const RefreshButton = ({
  tooltip,
  requestingPlaceholder,
  failureText,
  lastUpdated,
  status,
  onClick,
  disabled,
  size,
  testId,
}) => {
  const classList = ['RefreshButton'];
  if (isStatusRequesting(status)) classList.push('RefreshButtonLoading');

  const getTooltipContent = () => {
    if (isStatusRequesting(status)) return requestingPlaceholder;
    if (!lastUpdated) return tooltip;

    return (
      <span className="RefreshButton-Tooltip-Content">
        <span>{tooltip}</span>
        <span>{getRelativeTime(lastUpdated, status, failureText)}</span>
      </span>
    );
  };

  return (
    <Tooltip title={getTooltipContent()} className={classList.join(' ')}>
      <span>
        <IconButton
          data-testid={testId}
          size={size}
          disabled={isStatusRequesting(status) || disabled}
          onClick={(e) => {
            e.stopPropagation();
            onClick();
          }}
        >
          <Refresh fontSize={size} />
        </IconButton>
      </span>
    </Tooltip>
  );
};

RefreshButton.propTypes = {
  // The last time the data was updated
  // Should be passable to Date constructor, e.g ISO 8601 String
  tooltip: PropTypes.string,
  requestingPlaceholder: PropTypes.string.isRequired, // Placeholder tooltip if currently fetching data
  status: PropTypes.string, // Status of request for data
  onClick: PropTypes.func.isRequired, // Function to call when clicking the button
  size: PropTypes.string,
  disabled: PropTypes.bool,
  testId: PropTypes.string,
  failureText: PropTypes.string,
  lastUpdated: PropTypes.string,
};

RefreshButton.defaultProps = {
  tooltip: undefined,
  status: undefined,
  size: 'small',
  disabled: false,
  testId: '',
  failureText: 'Failed to fetch data',
  lastUpdated: undefined,
};

export default RefreshButton;
