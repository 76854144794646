export const LOAD_UTTERANCE_REQUEST = 'suggestions/LOAD_UTTERANCE_REQUEST';
export const LOAD_UTTERANCE_SUCCESS = 'suggestions/LOAD_UTTERANCE_SUCCESS';
export const LOAD_UTTERANCE_FAILURE = 'suggestions/LOAD_UTTERANCE_FAILURE';

/**
 * Request for loading an entity-parsed utterance in HTML.
 * @param {object} obj
 * @param {string} obj.rawUtterance The utterance without HTML.
 */
export const loadUtteranceRequest = ({ rawUtterance }) => ({
  type: LOAD_UTTERANCE_REQUEST,
  rawUtterance,
});

/**
 *
 * @param {object} obj
 * @param {string} obj.utterance Entity-parsed utterance in HTML.
 */
export const loadUtteranceSuccess = ({ utterance }) => ({
  type: LOAD_UTTERANCE_SUCCESS,
  utterance,
});

/**
 *
 * @param {object} obj
 * @param {Error} obj.error
 */
export const loadUtteranceFailure = ({ error }) => ({
  type: LOAD_UTTERANCE_FAILURE,
  error,
});
