import TableChart from '@mui/icons-material/TableChart';
import Button from '@mui/material/Button';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../../../configureStore';
import { NavigationTabs } from '../../../../constants/session';
import { selectChartById } from '../../../../store/selectors/chartspace.selector';
import { selectDatasetById } from '../../../../store/selectors/dataspace.selector';
import { selectSession } from '../../../../store/selectors/session.selector';
import { setCurrentDatasetRequest } from '../../../../store/slices/dataspace.slice';
import { setCurrentNavigationTab } from '../../../../store/slices/session.slice';
import './ChartHeaderLink.scss';

interface ChartHeaderLinkProps {
  dcChartId: string | undefined;
}

const ChartHeaderLink: FC<ChartHeaderLinkProps> = ({ dcChartId }) => {
  const dispatch = useDispatch();
  const sessionId = useSelector(selectSession);
  const chart = useSelector((state: RootState) => selectChartById(state, dcChartId));
  const parentDataset = useSelector((state: RootState) =>
    selectDatasetById(state, chart?.dc_dataset_id),
  );

  if (!parentDataset || !chart) return null;

  const handleNavigateToDataSpace = () => {
    // Set the dataset as current dataset in the session
    dispatch(setCurrentDatasetRequest({ dcDatasetId: parentDataset.id }));

    // Navigate to the Dataspace tab
    dispatch(setCurrentNavigationTab({ sessionId, tab: NavigationTabs.DATA_SPACE }));
  };

  return (
    <Button
      className="LinkWrapper"
      variant="outlined"
      size="small"
      onClick={handleNavigateToDataSpace}
    >
      <TableChart fontSize="inherit" />
      <span className="LinkWrapperText">{parentDataset.name}</span>
    </Button>
  );
};

export default ChartHeaderLink;
