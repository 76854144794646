// TODO: This file should be moved out of the sagas folder because the connected
// components would like to use these selectors as well.
// TODO: Prefer the name convention of {state}Selector instead of select{State}.
// See example in https://github.com/reduxjs/reselect
/**
 * This file contains functions for retrieving the application's state.
 */
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { createSelector } from 'reselect';
import { getDatasetReferenceString } from '../../components/ChartData/dataUtils';
import { APPS } from '../../constants/apps';
import { REPLAY_STATUS } from '../../constants/editor';
import {
  DC_OBJECTS,
  DEFAULT_FILTER_OPTION,
  HOME_OBJECTS,
  HOME_OBJECTS_PERMISSION,
  HOME_OBJECT_KEYS,
  RECENT_OBJECT_TYPES,
} from '../../constants/home_screen';
import {
  EXPERIMENTAL_OBJECTS,
  EXPERIMENTAL_REMOVED_FROM_NEW_OBJECTS,
  EXPERIMENTAL_REMOVED_OBJECTS,
} from '../../constants/home_screen_temp';
import { paths } from '../../constants/paths';
import { stripeActiveSubscriptionRegex } from '../../constants/payments/subscriptions';
import { THEMES } from '../../constants/themes/themes';
import { WORKSPACE_VISIBILITY } from '../../constants/workspace';
import { PERMISSION_TRUE } from '../../utils/permissions';
import {
  selectSelectedDatasetName,
  selectSelectedDatasetVersion,
} from '../selectors/dataset.selector';
import {
  selectHiddenDatasetsByNameVersion,
  selectVisibleDatasetsByNameVersion,
} from '../selectors/dataspace.selector';
import { selectSession } from '../selectors/session.selector';

// Apps
export const selectAppsById = (state) => state.apps.apps.byId;
export const selectAva = createSelector(
  selectAppsById,
  (apps) => Object.values(apps).find((app) => app.name === APPS.AVA)?.id,
);
export const selectAvaIncubating = createSelector(
  selectAppsById,
  (apps) => Object.values(apps).find((app) => app.name === APPS.AVA_INCUBATING)?.id,
);
export const selectAppsByName = (state) => state.apps.apps.byName;
export const selectHasRequestedApps = (state) => state.apps.hasRequestedApps;
export const selectHasRequestedViews = (state) => state.appViews.hasRequestedViews;

// Auth
export const selectAuth = (state) => state.auth;
export const selectAuthError = (state) => state.auth.error;
export const selectIsAuthenticated = (state) => state.auth.isAuthenticated;
/**
 * Selects user's token for `Authorization: Bearer <token>` header.
 *
 * @param {import('../../configureStore').RootState} state
 */ // JSDoc type tag enable use in TypeScript files w/out assertions or casting.
export const selectAccessToken = (state) => state.auth.accessToken;
export const selectRefreshToken = (state) => state.auth.refreshToken;
export const selectEmail = (state) => state.auth.email;
export const selectUserID = (state) => state.auth.user;
export const selectPermissions = (state) => state.auth.permissions;
export const selectUserRole = (state) => state.auth.role;
export const selectName = (state) => state.auth.name;
export const selectIsRequestingRefresh = (state) => state.auth.isRequestingRefresh;
export const selectUpdateProfileFailed = (state) => state.auth.updateProfileFailed;

// Examples
export const selectIsLoadingExamples = (state) => state.examples.isLoadingExamples;
export const selectExamples = (state) => state.examples.examples;
export const selectChosenExample = (state) => state.examples.selectedExample;

// License
export const selectIsCheckingLicenseExpiry = (state) => state.license.isCheckingLicenseExpiry;

// Browser Type
export const selectBrowserType = (state) => state.browserType.hasSeenWarning;

// Embed
export const selectPublicationId = (state) => state.embed.publicationId;
export const selectPublication = (state) => state.embed.publication;
export const selectIsLoadingPublication = (state) => state.embed.isLoading;
export const selectPublicationError = (state) => state.embed.publicationError;
export const selectProfilingError = (state) => state.embed.profilingError;
export const selectSubmittingPrediction = (state) => state.embed.submittingPrediction;
export const selectSubmittingProfile = (state) => state.embed.submittingProfile;
export const selectLoadingProfileResult = (state) => state.embed.loadingProfileResult;
export const selectPublicationKey = (state) => state.embed.apiKey;
export const selectPublicationSecret = (state) => state.embed.apiSecret;

// Sessionless Datasets
export const selectSessionlessDatasetStorage = (state) => state.chart.sessionlessDatasetStorage;

// Chart selection
export const selectChartStatuses = (state) => state.chartSelection.chartStatuses;
export const selectChartCount = (state) => state.chartSelection.selectedCount;

// Model
export const selectShowModelProfiler = (state) => state.model.showModelProfiler;
export const selectModelSubmittingPrediction = (state) => state.model.submittingPrediction;
export const selectModelSubmittingProfile = (state) => state.model.submittingProfile;
export const selectModelLoadingProfile = (state) => state.model.loadingProfileResult;
export const selectModelProfilerModels = (state) => state.model.models;
export const selectModelCurrentIndex = (state) => state.model.currentIndex;
export const selectModelCurrentModel = createSelector(
  selectModelCurrentIndex,
  selectModelProfilerModels,
  (currentIndex, models) => {
    if (currentIndex !== undefined) return models[currentIndex];
    return [];
  },
);

// Chat
export const selectCurrentUtterance = (state) => state.chat.currentUtterance;

// Utterance Composer
export const selectShowUtteranceComposerGroup = (state) =>
  state.utteranceComposer.showUtteranceComposerGroup;
export const selectComposerHideUttViewer = (state) => state.utteranceComposer.hideStepViewer;
export const selectComposerHideDatasetInHeader = (state) =>
  state.utteranceComposer.hideDatasetInHeader;
export const selecthideStepViewer = (state) => state.utteranceComposer.hideStepViewer;

// Skill Composer
export const selectShowSkillComposer = (state) => state.utteranceComposer.showSkillComposer;
export const selectSkillRecipe = (state) => state.utteranceComposer.skillRecipe;

// Dashboard
export const selectIsGeneratingDashboard = (state) => state.dashboard.isGenerating;
export const selectDashboards = (state) => state.dashboard.dashboards;
export const selectDashboardId = (state) => state.dashboard.id;
export const selectDashboardSessionName = (state) => state.dashboard.sessionName;
export const selectDashboardAppId = (state) => state.dashboard.appId;
export const selectCurrentDashboardCharts = (state) => state.dashboard.current.charts;
export const selectCurrentDashboardLayout = (state) => state.dashboard.current.layout;
export const selectDashboardLayout = (state) => state.dashboard.layout;

// Insights Boards (should replace dashboard selectors eventually)
// TODO: add selectors for insights boards
// export const selectIsGeneratingInsightsBoard = (state) => state.insightsBoard.isGenerating;
export const selectHomeScreenObjects = createSelector(
  [(state) => state.homeScreen.objects, (state, objectType) => objectType],
  (homeObjects, objectType) => Object.values(homeObjects[objectType]),
);
export const selectInsightsBoardName = (state) => state.insightsBoard?.currentLayout?.name;

export const selectInsightsBoardCustomizations = (state) =>
  state.insightsBoard.tableDisplayCustomizations;
const selectInsightsBoardLayoutItems = (state) => {
  return state.insightsBoard.currentLayout?.layout_items;
};
export const selectDcChartId = (publicationId) =>
  createSelector([selectInsightsBoardLayoutItems, () => publicationId], (layoutItems, pubId) => {
    const dcChartId = layoutItems?.find((item) => item.publication_id === pubId)?.chart_id;
    if (dcChartId?.Valid === true) {
      return dcChartId.Int64;
    }
    return null;
  });

// Cross Filters (On Insights Boards)
export const selectCrossFilterGroups = (state) => state.crossFilter.crossFilterGroups;
export const selectOldCrossFilterGroups = (state) => state.crossFilter.oldCrossFilterGroups;
export const selectCrossFiltersError = (state) => state.crossFilter.error;
export const selectCrossFiltersLoading = (state) => state.crossFilter.isLoading;
// Get the groupIds of all the deleted cross filter groups since the last interaction w/ API
export const selectDeletedCrossFilterGroups = (state) => state.crossFilter.deletedCrossFilterGroups;
// Get the IDs of all the deleted cross filters since the last interaction w/ API
export const selectDeletedCrossFilters = (state) => state.crossFilter.deletedCrossFilters;
// Get the updated cross filter groups since the last interaction w/ API
export const selectUpdatedCrossFilterGroups = createSelector(
  [selectOldCrossFilterGroups, selectCrossFilterGroups],
  (oldCrossFilterGroups, newCrossFilterGroups) => {
    const updatedCrossFilterGroups = cloneDeep(newCrossFilterGroups);

    Object.entries(newCrossFilterGroups).forEach(([newGroupId, newGroup]) => {
      const oldGroup = oldCrossFilterGroups[newGroupId] || {};

      // Delete all of the groups that haven't changed
      if (isEqual(oldGroup, newGroup)) {
        delete updatedCrossFilterGroups[newGroupId];
        return;
      }

      const oldFilters = oldCrossFilterGroups[newGroupId]?.crossFilters || [];
      const newFilters = newCrossFilterGroups[newGroupId]?.crossFilters || [];

      // Delete all of the individual cross filters that haven't changed
      updatedCrossFilterGroups[newGroupId].crossFilters = newFilters.filter(
        (n) => !oldFilters.find((o) => isEqual(o, n)),
      );
    });

    return updatedCrossFilterGroups;
  },
);
// Determine if the cross filters have changed since the last interaction w/ API
export const selectHaveCrossFiltersChanged = createSelector(
  [selectDeletedCrossFilters, selectDeletedCrossFilterGroups, selectUpdatedCrossFilterGroups],
  (deletedFilters, deletedFilterGroups, updatedFilterGroups) =>
    !isEmpty(deletedFilters) || !isEmpty(deletedFilterGroups) || !isEmpty(updatedFilterGroups),
);

// App Views
export const selectActiveApp = (state) =>
  state.session.appId && state.apps.apps.byId[state.session.appId];

// App Settings
export const appsSelector = createSelector([selectAppsById], (byId) => Object.values(byId));
export const selectAppSettingApp = (state) => {
  const apps = appsSelector(state);
  const app = apps.find((item) => item.name === APPS.AVA) || apps[0];
  return app;
};
export const selectDefaultViewId = (state) => {
  const app = selectAppSettingApp(state);
  const config = state.settings.userConfig;
  const defaultViewId = config.defaultAppViews[app.id.toString()] || app.default_app_view_id;
  return defaultViewId;
};

// Settings
/**
 *
 * @param {*} state
 * @returns {string[]} list of auth backends
 */
export const selectAuthBackendName = (state) => state.config.authBackendName;
export const selectHubspotRegistrationLink = (state) => state.config.hubspotRegistrationLink;
export const selectAskAvaEnabled = (state) => state.config.askEnabled;
export const selectHasRequestedUserConfig = (state) => state.settings.hasRequestedUserConfig;
export const selectHasRequestedUserRecord = (state) => state.settings.hasRequestedUserRecord;
export const selectSettingsMenuView = (state) => state.settings.settingsMenuView;
export const selectDefaultAppViews = (state) => state.settings.userConfig.defaultAppViews;
export const selectExperimentalFlag = (state) => state.settings.userConfig.experimentFlag;
export const selectAutoPopup = (state) => state.settings.userConfig.autoPopup;
export const selectUserConfig = (state) => state.settings.userConfig;
export const selectExperimentalEditorFlag = (state) => state.settings.userConfig.experimentalEditor;
export const selectShowUserSelectMenu = (state) => state.settings.showUserSelectMenu;
export const selectAutoSizeColumns = (state) => state.settings.userConfig.autoSizeColumns;
export const selectShowGELCommand = (state) => state.settings.userConfig.showGELCommand;
export const selectOrganizationUsersSelectState = (state) =>
  state.settings.organizationUsersSelectState;
export const selectShowOfficeHoursMenu = (state) => state.settings.showOfficeHoursMenu;

export const selectPermission = (state, permission) => state.auth.permissions[permission];
export const allPermissions = (state) => state.auth.permissions;

export const selectOrganizationID = (state) => state.settings.organizationID;
export const selectOrganizationName = (state) => state.settings.organizationName;

export const selectTheme = (state) => THEMES[state.settings.theme];
export const selectShowUtteranceTimeoutExtForm = (state) =>
  state.settings.showUtteranceTimeoutExtForm;

// Router
export const selectRouter = (state) => state.router;
export const selectCurrentLocation = createSelector(
  [selectRouter],
  (router) => router?.location?.pathname,
);
export const selectCurrentQuery = createSelector(
  [selectRouter],
  (router) => router?.location?.search,
);
export const selectCurrentAction = createSelector([selectRouter], (router) => router?.action);
export const selectIsOnCheckoutPage = createSelector(
  [selectCurrentLocation],
  (location) => location === paths.payment,
);

// Suggestions
export const selectTimeOfRequest = (state) => state.suggestions.timeOfRequest;
export const selectTimeOfResponse = (state) => state.suggestions.timeOfResponse;
export const selectIsRequesting = (state) => state.suggestions.isRequesting;
export const selectRequestingUtterance = (state) => state.suggestions.lastRequestUtterance;
// Messages
export const selectMessages = (state) => state.messages;

export const selectChartMessages = createSelector(selectMessages, (messages) =>
  messages.filter((message) => !isEmpty(message.chart)),
);

export const selectVizContexts = createSelector(selectChartMessages, (messages) =>
  messages.filter(
    (obj) =>
      !(obj.chart.data.name === obj.chart.data.title) ||
      obj.chart.type !== 'table' ||
      !obj.chart.data.name,
  ),
);

export const selectUserMessages = createSelector(selectMessages, (messages) =>
  messages
    .filter((message) => !isEmpty(message.userTextMessage))
    .map((message) => message.userTextMessage),
);

// Deployment
export const selectDeploymentMode = (state) => state.config.deploymentMode;

// Delay Message
export const selectDelayMessage = (state) => state.delayMessage.message;

// File Cache
export const selectFilesRequesting = (state) => state.upload.filesRequesting;
export const selectDatasetsLoadImmediately = (state) => state.upload.datasetsToLoadImmediately;
export const selectFilesLoadImmediately = (state) => state.upload.filesToLoadImmediately;
export const selectFileModalOpen = (state) => state.upload.fileModalOpen;

// File Manager
export const selectFiles = (state) => state.fileManager.files;
export const selectFileManagerMode = (state) => state.fileManager.fileManagerMode;
export const selectIsRequestingDeleteFiles = (state) => state.fileManager.isRequestingDeleteFiles;

// Recipe Editor
export const selectEditorCanEditSelection = (state) => {
  const { utteranceList, selection, previewing, execIndex, isReplaying } = state.editor;
  return !(
    selection.length === 0 ||
    (isReplaying &&
      selection.find((uttKey) => utteranceList.indexOf(uttKey) < execIndex) !== undefined) ||
    previewing
  );
};
export const selectEditorSelectionIsAllComments = (state) => {
  const { workflow, selection } = state.editor;
  // TODO: replace 'String(workflow[uttKey].value).trim().startsWith('--'))' with isComment function
  // NOTE: Currently, adding imports to this file can break jest tests. Fixing #23655 will resolve this.
  return selection.every((uttKey) => String(workflow[uttKey].value).trim().startsWith('--'));
};
export const selectEditorChangeIndex = (state) => state.editor.changeIndex;
export const selectEditorChangeLog = (state) => state.editor.changeLog;
export const selectEditorEdited = (state) => state.editor.edited;
export const selectEditorError = (state) => state.editor.error;
export const selectEditorExecIndex = (state) => state.editor.execIndex;
export const selectEditorIsReplaying = (state) => state.editor.isReplaying;
export const selectEditorIsStepwise = (state) => state.editor.isStepwise;
export const selectEditorIsSelecting = (state) => !isEmpty(state.editor.selection);
export const selectEditorIsSelectingAll = (state) => {
  return state.editor.selection?.length === state.editor.utteranceList?.length;
};
export const selectEditorLoading = (state) => state.editor.loading;
export const selectEditorMetadata = (state) => state.editor.metadata;
export const selectEditorOnLastStep = (state) => {
  const { execIndex, utteranceList } = state.editor;
  return execIndex === utteranceList.length;
};
export const selectEditorLengthInDigits = (state) => {
  return String(state.editor.utteranceList.length).length;
};
export const selectEditorUtteranceList = (state) => state.editor.utteranceList;
export const selectEditorUtteranceListOriginal = (state) => state.editor.utteranceListOriginal;
export const selectEditorPreviewing = (state) => state.editor.previewing;
export const selectEditorReplayStatus = (state) => state.editor.replayStatus;
export const selectEditorSelection = (state) => state.editor.selection;
export const selectEditorUttObject = (state, uttKey) => state.editor.workflow[uttKey];
export const selectEditorVersions = (state) => state.editor.versions;
export const selectEditorVersionMetadata = (state) => state.editor.versionMetadata;
export const selectEditorWorkflow = (state) => state.editor.workflow;
export const selectEditorWorkflowId = (state) => state.editor.metadata.workflowId;
export const selectEditorSnapshotUUID = (state) => state.editor.metadata.snapshotUUID;
export const selectEditorWorkflowUuid = (state) => state.editor.metadata.uuid;
export const selectEditorManualFocus = (state) => state.editor.manualFocus;
export const selectWorkflowNameSaveFailed = (state) => state.editor.workflowNameSaveFailed;
export const selectEditorWorkflowDataset = (state) => state.editor.metadata.dataset;
export const selectEditorHasWorkflowDataset = createSelector(
  [selectEditorWorkflowDataset],
  (dataset) => Boolean(dataset?.id),
);
export const selectEditorWorkflowName = createSelector(
  [selectEditorHasWorkflowDataset, selectEditorSnapshotUUID, selectEditorMetadata],
  (hasWorkflowDataset, snapshotUUID, metadata) =>
    hasWorkflowDataset
      ? metadata.dataset.name
      : snapshotUUID
      ? metadata.snapshotName
      : metadata.name,
);
export const selectEditorWorkflowDatasetUpdating = (state) => state.editor.updatingDataset;
export const selectEditorObjectType = (state) => state.editor.objectType;
export const selectEditorObjectID = (state) => state.editor.objectId;

// Dialog
export const selectDialog = (state) => state.dialog;

// Server Status
export const selectServerStatus = (state) => state.serverStatus;
export const selectServerWaiting = (state) => state.serverStatus.serverWaiting;

// Organization
export const selectUsersInOrganization = (state) => state.organization.usersInOrganization;
export const selectUsersInOrganizationById = createSelector(
  [selectUsersInOrganization, selectOrganizationID],
  (usersInOrganization, organizationID) => {
    return usersInOrganization?.[organizationID] ?? {};
  },
);
export const selectUsersInOrganizationByEmail = createSelector(
  [selectUsersInOrganizationById],
  (users) =>
    Object.values(users).reduce((acc, user) => {
      acc[user.email] = user;
      return acc;
    }, {}),
);
export const selectIsLoadingOrganizationUsers = (state) =>
  state.organization.isLoadingOrganizationUsers;

// Utterances Preview
export const selectWorkflowPreview = (state, workflowId) =>
  state.utterancesPreview.workflows[workflowId];
export const selectSessionPreview = (state, sessionId) =>
  state.utterancesPreview.sessions[sessionId];

// Workspace V2
export const selectMoveMenuObjects = (state) => state.workspacev2.moveMenuObjects;
export const selectCurrentFolder = (state) =>
  state.workspacev2.folderStack[state.workspacev2.folderStack.length - 1];
export const selectFolderStack = (state) => state.workspacev2.folderStack;

// Home Screen
export const selectDefaultHomeObjects = createSelector(
  [selectExperimentalFlag, allPermissions],
  (experimentFlag, permissions) => {
    const defaultHomeObjects = new Set();
    [HOME_OBJECTS.SESSION].concat(DC_OBJECTS).forEach((objectType) => {
      // for each home object type
      if (!experimentFlag && EXPERIMENTAL_OBJECTS.has(objectType)) {
        // Do not add experimental objects to the set if not in experimental mode
        return;
      }
      if (experimentFlag && EXPERIMENTAL_REMOVED_OBJECTS.has(objectType)) {
        // Do not add to the set if this type was removed for
        // experimental mode, and we are in experimental mode.
        return;
      }
      // get the permission name which is mapped to that object type
      const objectPermissionName = HOME_OBJECTS_PERMISSION?.[objectType];
      if (!objectPermissionName || permissions[objectPermissionName] === PERMISSION_TRUE) {
        // if there is no object permission name for this objectType
        // (meaning all users have access), then add to set
        // OR
        // if there is a permission name for this object type and the permission
        // is equal to "True", add to set
        defaultHomeObjects.add(objectType);
      }
    });
    return defaultHomeObjects;
  },
);
export const selectNewMenuAllowedTypes = createSelector(
  // Allowed options under new menu
  [selectDefaultHomeObjects, selectExperimentalFlag],
  (defaultHomeObjects, experimentalFlag) => {
    const allowedSet = new Set(
      [...defaultHomeObjects].filter(
        (objectType) => !experimentalFlag || !EXPERIMENTAL_REMOVED_FROM_NEW_OBJECTS.has(objectType),
      ),
    );
    return allowedSet;
  },
);
export const selectHomeObjects = createSelector(
  [(state) => state.homeScreen.objects],
  (homeObjects) => {
    const homeObjectsAsArrays = {};
    Object.keys(homeObjects).forEach((key) => {
      homeObjectsAsArrays[key] = Object.values(homeObjects[key]);
    });
    return homeObjectsAsArrays;
  },
);
export const selectObjectsByFolder = createSelector(
  [(state) => state.homeScreen.objects],
  (homeObjects) => {
    const folderObjects = { root: [] };
    const folders = homeObjects[HOME_OBJECTS.FOLDER];
    Object.keys(homeObjects).forEach((key) => {
      Object.values(homeObjects[key]).forEach((object) => {
        if (object.Parent && folders[object?.Parent]) {
          // if the object has a parent and the parent is a folder the user has access to
          if (folderObjects[object.Parent]) {
            // if the folder already exists in the folderObjects, push the object to the array
            // for that folder
            folderObjects[object.Parent].push(object);
          } else {
            // otherwise create a new array for that folder in folderObjects
            folderObjects[object.Parent] = [object];
          }
        } else {
          // if the object does not have a parent , push it to the root
          folderObjects.root.push(object);
        }
      });
    });
    return folderObjects;
  },
);
export const selectSelectedTab = (state) => state.homeScreen.selectedTab;
export const selectObjectRequestStatus = (state) => state.homeScreen.objectRequestStatus;
export const selectObjectDeleteStatus = (state) => state.homeScreen.objectDeleteStatus;
export const selectIsLoadingSearch = (state) => state.homeScreen.isLoadingSearch;
export const selectIsSearchSubmitted = (state) => state.homeScreen.isSearchSubmitted;
export const selectTempFilteredResults = (state) => state.homeScreen.tempFilteredResults;
export const selectFilteredResults = (state) => state.homeScreen.filteredResults;
export const selectSearchValue = (state) => state.homeScreen.searchValue;
export const selectTempSearchValue = (state) => state.homeScreen.tempSearchValue;
export const selectFuse = (state) => state.homeScreen.fuse;
export const selectSearchFilters = (state) => state.homeScreen.searchFilters;
export const selectSearchObjectTypeFilter = (state) => {
  // If search bar object type filter is deafult, return empty string
  if (state.homeScreen.searchFilters.objectType === DEFAULT_FILTER_OPTION) return '';
  return state.homeScreen.searchFilters.objectType;
};
export const selectDialogValue = (state) => state.homeScreen.dialogValue;
export const selectCurrentFolderObjects = createSelector(
  [selectObjectsByFolder, selectFolderStack],
  (objectsByFolder, folderStack) => {
    const currentFolder = folderStack[folderStack.length - 1];
    const currentFolderUuid = currentFolder.uuid;
    return objectsByFolder[currentFolderUuid] || [];
  },
);
export const selectShowHiddenObjects = (state) => state.homeScreen.showHiddenObjects;
export const selectSelectedObjectOptions = (state) => state.homeScreen.selectedObject;
export const selectSelectedFilters = (state) => state.homeScreen.selectedFilters;
export const selectChartBackingSnapshotUUIDList = (state) => state.homeScreen.snapshotsWithCharts;
export const selectDeletingObjects = (state) => state.homeScreen.deletingObjects;

// Get filters object based on selected tab
export const selectFiltersObject = (state, selectedTab) => {
  const filteredObject =
    selectedTab === HOME_OBJECTS.SEARCH ? selectSearchFilters(state) : selectSelectedFilters(state);
  return filteredObject;
};

// Home Screen Object Table Context Menu
export const selectSelectedObjectData = (state) => state.homeScreen.selectedObject.objectData;
export const selectContextMenuAnchor = (state) => state.homeScreen.contextMenuAnchor;
export const selectActionType = (state) => state.homeScreen.selectedObject.actionType;
export const selectSelectedObjectFeatured = (state) => state.homeScreen.selectedObject.featured;

// Connection
export const selectConnectionObject = (state) => state.connection.object;
export const selectConnectionData = (state) => state.connection.data;
export const selectConnectionEditorName = (state) => state.connection.data.connectionName;
export const selectIsConnectionLoading = (state) => state.connection.isLoading;
export const selectIsEditMode = (state) => state.connection.editMode;
export const selectIsSubmitting = (state) => state.connection.isSubmitting;
export const selectIsConnectionTesting = (state) => state.connection.isTesting;
export const selectSelectedDb = (state) => state.connection.selectedDb;
export const selectConnectionEditorIsOpen = (state) => state.connection.isOpen;

// Modals
export const selectShowContactForm = (state) => state.contactForm.showContactForm;

// Session Datasets
export const selectSessionDatasetStorage = (state) => state.dataset.sessionDatasetStorage;
export const selectCurrentDatasetName = (state) => state.dataset.currentDataset?.[0];
export const selectCurrentDatasetVersion = (state) => state.dataset.currentDataset?.[1];

export const selectSelectedDataset = createSelector(
  [
    selectSessionDatasetStorage,
    selectVisibleDatasetsByNameVersion,
    selectHiddenDatasetsByNameVersion,
    (state) => state.utteranceComposer.selectedDataset,
  ],
  (datasetStorage, datasetList, hiddenDatasetList, selectedDataset) => {
    let res = {};
    if (Array.isArray(selectedDataset) && selectedDataset?.length === 2) {
      const [dName, dVersion] = selectedDataset;

      const pipelinerDatasetId =
        datasetList[dName]?.[dVersion]?.dataset_id ??
        hiddenDatasetList?.[dName]?.[dVersion]?.dataset_id;

      const referenceString = getDatasetReferenceString({ pipelinerDatasetId });
      res = datasetStorage?.[referenceString] || {};
    }
    return res;
  },
);

export const selectSelectedDatasetMessage = createSelector(
  [selectMessages, selectSelectedDatasetName, selectSelectedDatasetVersion],
  (messages, datasetName, datasetVersion) =>
    messages.find(
      (message) =>
        message?.chart?.data?.name === datasetName &&
        message?.chart?.data?.version === datasetVersion,
    ),
);
export const selectBaseDatasets = (state) => state.dataset.baseDatasets;
export const selectBaseDatasetList = createSelector([selectBaseDatasets], (baseDatasets) => {
  // returns the values of the baseDatasets
  return Object.values(baseDatasets);
});

// Table Objects
export const selectAllTableObjects = (state) => state?.tableObject;
export const selectGroupedTableObjects = (state) => {
  const sessionId = selectSession(state);
  const insightsBoardName = selectInsightsBoardName(state);
  const parentId = insightsBoardName || sessionId;
  return state.tableObject?.parentMap?.[parentId];
};

// calendly link
export const selectCalendlyLink = (state) => state.config.supportCalendarLink;

// Subscriptions
export const selectPaymentSystemEnabled = (state) => state.config.paymentEnabled;
export const selectStripeObject = (state) => state.config.stripe;
export const selectShowMyAccountMenuOption = (state) => state.config.showMyAccountMenuOption;
export const selectSubscriptions = (state) => state.subscriptions.subscriptions;
export const selectIsCancellingSubscription = (state) =>
  state.subscriptions.isCancellingSubscription;
export const selectSubscriptionTiers = (state) => state.subscriptionTiers.subscriptionTiers;
export const selectYearly = (state) => state.subscriptions.yearly;
export const selectDisablePaymentOptionsPopup = (state) =>
  state.subscriptions.disablePaymentOptionsPopup;
export const selectSubscriptionCustomerId = (state) => state.subscriptions.subscriptionCustomerId;
export const selectIsUserExempt = (state) => state.subscriptions.isExempt;
export const selectIsExemptRequesting = (state) => state.subscriptions.isExemptRequesting;
export const selectIsExemptError = (state) => state.subscriptions.isExemptError;
export const selectFreeTierAttempted = (state) => state.subscriptions.freeTierAttempted;
export const selectTrialTier = createSelector([selectSubscriptionTiers], (tiers) =>
  tiers?.find((x) => x.can_trial),
);
export const selectFreeTier = createSelector([selectSubscriptionTiers], (tiers) =>
  tiers?.find((x) => x.price === 0),
);
export const selectIsConfigRequesting = (state) => state.config.isRequesting;
export const selectConfigError = (state) => state.config.error;
// Current subscription
export const selectCurrentSubscription = (state) => state.subscriptions.currentSubscription;
export const selectIsRequestingCurrentSubscription = (state) =>
  state.subscriptions.isRequestingCurrentSubscription;
export const selectCurrentSubscriptionError = (state) =>
  state.subscriptions.currentSubscriptionError;
export const selectSubscriptionPaymentMethod = (state) =>
  state.subscriptions.currentSubscription?.default_payment_method;
export const selectSubscriptionName = (state) => state.subscriptions.currentSubscription?.name;
export const selectSubscriptionStatus = (state) => state.subscriptions.currentSubscription?.status;
export const selectIsCurrentSubscriptionActive = createSelector(
  selectSubscriptionStatus,
  (status) => (status ? stripeActiveSubscriptionRegex.test(status) : null),
);
export const selectHasSubscription = createSelector(
  [selectPaymentSystemEnabled, selectIsUserExempt, selectIsCurrentSubscriptionActive],
  (paymentEnabled, isExempt, isCurrentSubscriptionActive) =>
    !paymentEnabled || isExempt || isCurrentSubscriptionActive,
);
export const selectIsHasSubscriptionValid = createSelector(
  [
    selectIsConfigRequesting,
    selectConfigError,
    selectIsExemptRequesting,
    selectIsExemptError,
    selectIsRequestingCurrentSubscription,
    selectCurrentSubscriptionError,
  ],
  (
    isConfigRequesting,
    configError,
    isExemptRequesting,
    isExemptError,
    isRequestingCurrentSubscription,
    currentSubscriptionError,
  ) =>
    !isConfigRequesting &&
    !configError &&
    !isExemptRequesting &&
    !isExemptError &&
    !isRequestingCurrentSubscription &&
    !currentSubscriptionError,
);
export const selectCurrentSubscriptionDetails = createSelector(
  [selectSubscriptionName, selectSubscriptionTiers],
  (name, subscriptionTiers) => {
    if (name && subscriptionTiers) {
      // if both currentSubscription and subscription tiers endpoints have returned successfully
      const subscriptionDetails = subscriptionTiers.find((tier) => tier.name === name);
      if (subscriptionDetails) {
        return JSON.parse(subscriptionDetails.description.String);
      }
    }
    return {};
  },
);
export const selectCurrentSubscriptionPrice = createSelector(
  selectCurrentSubscription,
  (currentSubscription) => currentSubscription?.price,
);

export const selectCanUseFreeTrial = (state) => state.subscriptionCheckFreeTrial.canUseFreeTrial;
export const selectAmountDue = (state) => state.subscriptions.amountDue;
export const selectIsRequestingPrice = (state) => state.subscriptions.isRequestingPrice;
export const selectSubscriptionProcessing = (state) => state.subscriptions.subscriptionProcessing;
export const selectPaymentDialogOpen = (state) => state.subscriptions.paymentDialogOpen;
export const selectPaymentMethodProcessing = (state) => state.subscriptions.paymentMethodProcessing;

// if there is no payment method selected, use the current subscription default payment method
export const selectSelectedPaymentMethod = (state) =>
  state.subscriptions.selectedPaymentMethod ||
  state.subscriptions.currentSubscription?.default_payment_method;
export const selectSelectedPaymentMethodDetails = createSelector(
  [(state) => state.subscriptions.paymentMethods, selectSelectedPaymentMethod],
  (paymentMethods, selectedPaymentMethod) => {
    const details = paymentMethods?.[selectedPaymentMethod];
    return details || {};
  },
);

// Invoices
export const selectInvoices = (state) => state.invoice.invoices;
export const selectInvoiceIsRequesting = (state) => state.invoice.isRequesting;
export const selectUpcomingInvoice = (state) => state.invoice.upcomingInvoice;

// Usage Stats
export const selectUsageStats = (state) => state.subscriptions.usageStats;
export const selectIsUsageStatsLoading = (state) => state.subscriptions.isUsageStatsLoading;

// Session Limit Usage Stats
export const selectSessionLimitUsageStats = (state) =>
  state.subscriptions.usageStats.sessionLimitUsageStats;
export const selectTotalSessionCount = (state) =>
  state.subscriptions.usageStats.sessionLimitUsageStats.total_session_count;
export const selectSessionCountLimit = (state) =>
  state.subscriptions.usageStats.sessionLimitUsageStats.session_count_limit;

// Cell Count Usage Stats
export const selectCellCountUsageStats = (state) =>
  state.subscriptions.usageStats.cellCountUsageStats;
export const selectSessionsCellCountStats = (state) =>
  state.subscriptions.usageStats.cellCountUsageStats.Session;
export const selectTotalCellCount = (state) =>
  state.subscriptions.usageStats.cellCountUsageStats.total_cell_count;
export const selectCellCountLimit = (state) =>
  state.subscriptions.usageStats.cellCountUsageStats.cell_count_limit;

// NL2 Code Usage Stats
export const selectNl2CodeMonthlyStats = (state) => ({
  count: state.subscriptions.usageStats.nl2CodeUsageStats.monthly_nl2code_count,
  limit: state.subscriptions.usageStats.nl2CodeUsageStats.monthly_nl2code_limit,
});

/**
 * If a user is on the SEARCH tab, return the filtered results.
 * If there is an objectTypeFilter, only return those object types.
 * If a user is not on the SEARCH tab, just return the homeObjects
 * of that specific tab's object type
 * @param {Object} state // redux state
 * @param {String} selectedTab // Current tab a user is on
 */
export const selectCurrentHomeObjects = createSelector(
  [
    selectFilteredResults, // Specific to search bar filtered results
    selectHomeObjects,
    selectSearchObjectTypeFilter,
    (state, selectedTab) => selectedTab,
  ],
  (filteredResults, homeObjects, searchObjectTypeFilter, selectedTab) => {
    if (selectedTab === HOME_OBJECTS.SEARCH) {
      // Filter down the object results corresponding to the filters set by the user
      return filteredResults[searchObjectTypeFilter || HOME_OBJECTS.ALL];
    }
    // Filter down the object results corresponding to the filters set by the user
    return homeObjects[selectedTab];
  },
);

/**
 * selector to grab the most recently accessed datachat objects
 * returns 10 object
 * @param {Object} homeObjects // object where each key the obj type, the value is
 */
export const selectRecentObjects = createSelector([selectHomeObjects], (homeObjects) => {
  // init array of recent dates
  const recentDates = Array(10).fill(new Date(1800, 1, 1));
  // init empty array of recently accessed objects
  const recentObjects = Array(10).fill({});
  // loop through the keys of the redux store for each object type
  RECENT_OBJECT_TYPES.forEach((objectType) => {
    homeObjects[objectType].forEach((currentObject) => {
      // get current object's date
      let currentDate = currentObject[HOME_OBJECT_KEYS.LAST_ACTIVE];
      // if the current object is not hidden
      if (currentObject?.[HOME_OBJECT_KEYS.VISIBILITY] !== WORKSPACE_VISIBILITY.HIDDEN) {
        // compare the current date with each of the dates in the recentDates array.
        recentDates.forEach((date, index) => {
          if (currentDate > date) {
            // if the current date is more recent than the date in recent dates,
            // replace the item in recent dates with the current date
            recentDates[index] = currentDate;
            const tempObject = recentObjects[index];
            recentObjects[index] = currentObject;
            // set the currentDate & currentObject to the date & object that were just replaced
            currentDate = date;
            currentObject = tempObject;
          }
        });
      }
    });
  });
  return recentObjects;
});

/**
 * Returns the full object based on the selected objectUuid and objectType
 */
export const selectFullSelectedObjectData = createSelector(
  [(state) => state.homeScreen.objects, selectSelectedObjectData],
  (homeObjects, selectedObjectData) => {
    if (!selectedObjectData) {
      return null;
    }
    return homeObjects[selectedObjectData?.objectType][selectedObjectData?.uuid];
  },
);

// drill through
export const selectIsDrillingThrough = (state) => state.drillThrough.isDrillingThrough;

// bulk export
export const selectIsExportingRequestInitialized = (state) =>
  state.bulkExport.isExportingRequestInitialized;
export const selectExportObjectID = (state) => state.bulkExport.exportObjectID;
export const selectIsExportedFromPopOutChart = (state) =>
  state.bulkExport.isExportedFromPopOutChart;
export const selectIsExportedFromChartBuilder = (state) =>
  state.bulkExport.isExportedFromChartBuilder;
export const selectProgress = (state) => state.bulkExport.progress;
export const selectChartSpecQueue = (state) => state.bulkExport.chartSpecQueue;

// expressions
export const selectExpressions = (state) => state.expressions.expressions;

// Graph Mode
export const lastUpdateGraphModeSelector = (state) => state.graphMode.lastUpdate;
export const selectSkillDataGraphMode = (state, skillId) => {
  if (skillId in state.graphMode.skills) {
    return state.graphMode.skills[skillId];
  }
  return {};
};

// hubspot
export const selectHubspotTracking = (state) => state.config.hubspotTracking;

// Google Tag Manager
export const selectGoogleTagManagerTracking = (state) => state.config.googleTagManagerTracking;

// Ask Ava
export const selectSelectedAskAvaOutputLanguage = (state) => state.askAva.selectedLanguage;
export const selectRequestingGeneratedCode = (state) => state.askAva.requestingGeneratedCode;
export const selectAskAvaRequestId = (state) => state.askAva.requestId;
export const selectGeneratedCode = (state) => state.askAva.generatedCode;
export const selectSubmittedQuery = (state) => state.askAva.submittedQuery;
export const selectSavingAskAvaRecipe = (state) => state.askAva.savingRecipe;
export const selectAskAvaUserFeedback = (state) => state.askAva.feedback;
export const selectIsSubmittingAskAvaUserFeedback = (state) => state.askAva.isSubmittingFeedback;
export const selectAskAvaSelectedDatasets = (state) => state.askAva.selectedDatasets;
export const selectAskAvaSubmittedSelectedDatasets = (state) =>
  state.askAva.submittedSelectedDatasets;
export const selectAskAvaUsedDatasets = (state) => state.askAva.usedDatasets;
export const selectAskAvaApiCode = (state) => state.askAva.apiCode;
export const selectAskAvaRecipeName = (state) => state.askAva.recipeName;
export const selectAskAvaShowSteps = (state) => state.askAva.showSteps;
export const selectAskAvaSessionContext = (state) => state.askAva.sessionId;
export const selectAskAvaSolutionComplete = (state) => state.askAva.solutionComplete;
export const selectAskAvaSummary = (state) => state.askAva.summary;
export const selectAskAvaVectorId = (state) => state.askAva.vectorId;
export const selectCodeMatchesCached = createSelector(
  [
    (state, codeType) => state.askAva.generatedCode?.[codeType]?.code,
    (state, codeType) => state.askAva.generatedCode?.[codeType]?.lastCachedCode,
  ],
  (code, lastCachedCode) => {
    // returns true if the code in the editor matches the latest cached recipe
    // first, check if the recipe lengths differ
    if (code?.length !== lastCachedCode?.length) return false;
    // second, check if any of the steps differ
    for (let i = 0; i < code.length; i++) {
      if (code[i]?.text !== lastCachedCode[i]?.text) return false;
    }
    return true;
  },
);
export const selectAskAvaEditMode = createSelector(
  [selectEditorReplayStatus, selectEditorIsReplaying],
  (replayStatus, isReplaying) => {
    const notRunningInSession =
      [
        REPLAY_STATUS.FAILURE,
        REPLAY_STATUS.QUESTIONING,
        REPLAY_STATUS.NONE,
        REPLAY_STATUS.READY,
      ].indexOf(replayStatus) >= 0;
    // askAvaEditMode will be true if there is nothing running in the session
    // and the editor is replaying
    return notRunningInSession && isReplaying;
  },
);
export const selectAskAvaGeneratingSolution = createSelector(
  [selectEditorReplayStatus, selectEditorIsReplaying, selectRequestingGeneratedCode],
  (replayStatus, isReplaying, requestingGeneratedCode, requestInQueue) => {
    const runningInSession =
      [
        REPLAY_STATUS.FAILURE,
        REPLAY_STATUS.QUESTIONING,
        REPLAY_STATUS.NONE,
        REPLAY_STATUS.READY,
      ].indexOf(replayStatus) < 0;
    // generatingSolution will be true if either (1) we are fetching code from llm model or
    // (2) a step is running in a session while the editor is replaying.
    return requestInQueue || requestingGeneratedCode || (runningInSession && isReplaying);
  },
);
// determines if the code in the ask ava editor is differes from the original llm-generated code
export const selectAskAvaCodeIsEdited = createSelector(
  [selectSelectedAskAvaOutputLanguage, selectGeneratedCode],
  (selectedLanguage, generatedCode) => {
    const { code, originalCode } = generatedCode[selectedLanguage];
    return (
      // length of recipe differs from the original
      code?.length !== originalCode?.length ||
      // or the text of any step differs from its original counterpart
      code?.find((step, index) => step.text !== originalCode[index]?.text) !== undefined
    );
  },
);

// LLM credentials
export const selectCredentials = (state) => state.credentials.credentials;
export const selectCredentialList = (state) => Object.values(state.credentials.credentials);
export const selectRequestingCredentials = (state) => state.credentials.requesting;
export const selectActionCredentialId = (state) => state.credentials.actionCredentialId;
export const selectCredentialsAction = (state) => state.credentials.credentialsAction;

// Share
export const selectPublicLink = (state) => state.share.publicLink;
export const selectCreatingPublicLink = (state) => state.share.creatingPublicLink;
export const selectShareLinkDialogOpen = (state) => state.share.linkDialogOpen;
export const selectDataToShare = (state) => state.share.shareData;

// Chart Builder
export const selectChartBuilderIsOpen = (state) => state.chartBuilder.chartBuilderIsOpen;
export const selectPivotBuilderIsOpen = (state) => state.chartBuilder.pivotBuilderIsOpen;

// Coralogix
export const selectCoralogixRumEnabled = (state) => state.config.coralogixRumEnabled;
export const selectCoralogixVersion = (state) => state.config.coralogixVersion;
export const selectCoralogixEnv = (state) => state.config.coralogixEnv;
