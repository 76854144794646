import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { Column, DCColTypes } from 'translate_dc_to_echart';
import { InputType } from '../types/chartBuilderIO';
import { FilterParams } from '../types/columnSelection';

/**
 * Combine two objects with arrays as values, merging the arrays when the keys clash.
 */
export const mergeFilters = (a: Record<string, any[]>, b: Record<string, any[]>) =>
  [...new Set([...Object.keys(a), ...Object.keys(b)])].reduce((acc, key) => {
    acc[key] = [...(a[key] ?? []), ...(b[key] ?? [])];
    return acc;
  }, {} as Record<string, any[]>);

/**
 * Only allows selection of the same columns that are already selected.
 * Returns all cols if no selections.
 */
export const onlyDuplicatedWithOtherSelections = ({
  input,
  fields,
  cols,
}: FilterParams): Column[] => {
  // Get all selected columns ( as string or { name, type } ) that are not the current input
  const selectedColumns = Object.values({ ...fields, [input]: undefined })
    .flat()
    .filter((v) => typeof v === 'string' || (typeof v === 'object' && 'name' in v && 'type' in v));

  // If no selections, allow all columns
  if (selectedColumns.length === 0) return cols;

  return selectedColumns as Column[];
};

export const notDuplicated = (
  { fields, cols }: FilterParams,
  againstInput?: InputType,
): Column[] => {
  let output = cols;

  // Filter against some other input
  if (againstInput) {
    const againstSelection = fields[againstInput] || [];
    if (!Array.isArray(againstSelection)) return output; // againstSelection is boolean or MarkLine
    output = output.filter((col) => !againstSelection.some((item) => isEqual(item, col)));
  }

  return output;
};

/** Prevents selection of the same column as selected in the x-axis */
export const notDuplicatedWithXAxis = (params: FilterParams): Column[] =>
  notDuplicated(params, InputType.x);

/** Prevents selection of the same column as selected in the y-axis */
export const notDuplicatedWithYAxis = (params: FilterParams): Column[] =>
  notDuplicated(params, InputType.y);

/** Prevents selection of the same column as selected in the overlay. */
export const notDuplicatedWithOverlay = (params: FilterParams): Column[] =>
  notDuplicated(params, InputType.overlay);

/** Prevents selection of more than 'max' columns. */
export const maximumOf = ({ input, fields, cols }: FilterParams, max: number): Column[] => {
  const selection = fields[input] ?? [];
  if (Array.isArray(selection) && selection.length >= max) return selection as Column[];
  return cols;
};

/** Prevents selection of more than 2 columns. */
export const maximumOfTwoColumns = (params: FilterParams): Column[] => maximumOf(params, 2);

/** Prevents selections of more than 'max' columns when againstInput has selection(s). */
export const maximumOfWith = (
  { input, fields, cols }: FilterParams,
  max: number,
  againstInput: InputType,
): Column[] => {
  const againstSelection = fields[againstInput] || [];
  if (!isEmpty(againstSelection)) return maximumOf({ input, fields, cols }, max);
  return cols;
};

/** Prevents selection of more than 1 column when a group is selected. */
export const maximumOfOneColumnWithGroup = (params: FilterParams): Column[] =>
  maximumOfWith(params, 1, InputType.group);

/** Prevents selection when multiple columns are selected in againstInput. */
export const disallowWithMultiSelectionOf = (
  { fields, cols }: FilterParams,
  againstInput: InputType,
): Column[] =>
  Array.isArray(fields[againstInput]) && fields[againstInput]?.length > 1 ? [] : cols;

/** Prevents selections in input when multiple columns are selected in x-axis */
export const disallowWithMultiXAxis = (params: FilterParams): Column[] =>
  disallowWithMultiSelectionOf(params, InputType.x);

/** Prevents selections in input when multiple columns are selected in y-axis */
export const disallowWithMultiYAxis = (params: FilterParams): Column[] =>
  disallowWithMultiSelectionOf(params, InputType.y);

/** Prevents non-numeric selections. */
export const isNumeric = (params: FilterParams): Column[] =>
  params.cols.filter((col) => [DCColTypes.INTEGER, DCColTypes.FLOAT].includes(col.type));

/** Prevents numeric selections. */
export const notNumeric = (params: FilterParams): Column[] => {
  return params.cols.filter((col) => ![DCColTypes.INTEGER, DCColTypes.FLOAT].includes(col.type));
};
