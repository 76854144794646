// Constants representing permissions
export const PERMISSION_START_SESSIONS = 'Start Sessions';
export const PERMISSION_COLLABORATION = 'Collaboration';
export const PERMISSION_SHARING = 'Sharing';
export const PERMISSION_DATABASE_CONNECTIONS = 'Database Connections';
export const PERMISSION_SCHEDULING_WORKFLOWS = 'Scheduling Workflows';
export const PERMISSION_ALERTING = 'Alerting';
export const PERMISSION_MODEL_DEPLOYMENT = 'Model Deployment';
export const PERMISSION_FILE_STORAGE = 'File Storage';
export const PERMISSION_CONCURRENT_SESSIONS = 'Concurrent Sessions';
export const PERMISSION_SINGLE_DATASET_CELL_MAXIMUM = 'Single Dataset Cell Maximum';
export const PERMISSION_PUBLISHED_CONTENT = 'Published Content';
export const PERMISSION_INSIGHTS_BOARD_MAXIMUM_COUNT = 'Insight Board Maximum Count';
export const PERMISSION_EDIT_ROLES = 'Edit Roles';
export const PERMISSION_ASSIGN_ROLES = 'Assign Roles';
export const PERMISSION_SESSION_DATA_LIMIT = 'Session Data Limit';
export const PERMISSION_INSIGHTS_BOARD_REFRESH_COUNT = 'Insights Board Refresh Count';
export const PERMISSION_PUBLISH_CONTENT = 'Publish Content';

// Constants representing permission values to check for
export const PERMISSION_TRUE = 'True';
export const PERMISSION_FALSE = 'False';

export const TYPE_BOOLEAN = 'Boolean';
export const TYPE_INTEGER = 'Integer';
// Not used in the DB currently, but used for the role's name
export const TYPE_STRING = 'String';

// If a feature isn't here, it will be false/unfilled
export const DEFAULT_FEATURE_VALUES = {
  [PERMISSION_START_SESSIONS]: 'True',
  [PERMISSION_COLLABORATION]: 'False',
  [PERMISSION_SHARING]: 'False',
  [PERMISSION_DATABASE_CONNECTIONS]: 'False',
  [PERMISSION_SCHEDULING_WORKFLOWS]: 'False',
  [PERMISSION_ALERTING]: 'False',
  [PERMISSION_MODEL_DEPLOYMENT]: 'False',
  [PERMISSION_FILE_STORAGE]: '1000',
  [PERMISSION_CONCURRENT_SESSIONS]: '1',
  [PERMISSION_SINGLE_DATASET_CELL_MAXIMUM]: '100000',
  [PERMISSION_PUBLISHED_CONTENT]: '250',
  [PERMISSION_INSIGHTS_BOARD_MAXIMUM_COUNT]: '1',
  [PERMISSION_EDIT_ROLES]: 'False',
  [PERMISSION_ASSIGN_ROLES]: 'False',
  [PERMISSION_SESSION_DATA_LIMIT]: '100',
  [PERMISSION_INSIGHTS_BOARD_REFRESH_COUNT]: '1',
  [PERMISSION_PUBLISH_CONTENT]: 'False',
};

export const UNIT_MB = 'MB';
export const FEATURE_UNITS = {
  [PERMISSION_FILE_STORAGE]: UNIT_MB,
  [PERMISSION_PUBLISHED_CONTENT]: UNIT_MB,
  [PERMISSION_SESSION_DATA_LIMIT]: UNIT_MB,
};

export const NEW_ROLE = {
  id: -1,
  name: '',
  features: DEFAULT_FEATURE_VALUES,
};
