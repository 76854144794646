import { Layout as antLayout, LayoutProps } from 'antd';
import styled from 'styled-components';
import { theme } from '../../index';

type ILayoutProps = LayoutProps;

const Layout = styled(antLayout)<ILayoutProps>``;

Layout.Header = styled(Layout.Header)`
  border-bottom: 1px solid ${theme.colors.border};
`;

Layout.Sider = styled(Layout.Sider)`
  .ant-layout-sider-trigger {
    border-inline-end: 1px solid rgba(5, 5, 5, 0.06);
  }

  .ant-menu {
    height: 100%;
  }
`;

export default Layout;
