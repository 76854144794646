import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getColumnsInUseNames, shouldUseBackendCompute } from 'translate_dc_to_echart';
import { FAT_TABLE_LENGTH } from '../../constants/chart';
import { TOAST_ERROR, TOAST_LONG } from '../../constants/toast';
import { closeChartBuilder } from '../../store/actions/chart_builder.actions';
import { sampleDatasetRequest } from '../../store/actions/dataset.actions';
import { describeAndSendUtteranceRequest } from '../../store/actions/messages.actions';
import { addToast, dismissAllToasts } from '../../store/actions/toast.actions';
import {
  selectSessionDatasetStorage,
  selectSessionlessDatasetStorage,
} from '../../store/sagas/selectors';
import { selectVisibleDatasetsByNameVersion } from '../../store/selectors/dataspace.selector';
import { selectSession } from '../../store/selectors/session.selector';
import { InsightsBoardDatasetClaim, SessionDatasetClaim } from '../../types/claim.type';
import { useDataFromStorage, useSessionPipelinerDatasetId } from '../ChartData/dataUtils';
import usePipelinerDatasetDescription from '../usePipelinerDatasetDescription';
import ChartBuilder from './ChartBuilder';
import './ChartBuilder.scss';
import { LOADING_COMPLETE_MESSAGE, iconMapping } from './utils/constants';
import { initializeChartType } from './utils/manageChart';

// Determine if we're performing the first render of this component
function useFirstRender() {
  const ref = useRef(true);
  const firstRender = ref.current;
  ref.current = false;
  return firstRender;
}

// Custom hook for manipulating the chart builder data
const useData = (
  chartEditingMode,
  computeSpec,
  datasetData,
  datasetName,
  datasetVersion,
  dcSpec,
  dispatch,
  firstRender,
  insightsBoardId,
  isFatTable,
  isSessionless,
  objectId,
  pipelinerDatasetId,
  setComputeSpec,
  totalRowCount,
  computedData,
  isComputeFailed,
  isComputeLoading,
) => {
  const [columns, setColumns] = useState([]);
  const [dataSampleLimit, setDataSampleLimit] = useState(
    chartEditingMode ? dcSpec.values?.dataSampleLimit ?? null : null,
  );
  const [placeholderText, setPlaceholderText] = useState(
    'Complete the required fields to plot your chart.',
  );
  const [rows, setRows] = useState([]);
  const [computedColumns, setComputedColumns] = useState(null);
  const [computedRows, setComputedRows] = useState(null);

  // Did we use the backend compute?
  const usedCompute = useMemo(
    () =>
      shouldUseBackendCompute({
        computeSpec,
        numRows: dataSampleLimit,
      }),
    [computeSpec, dataSampleLimit],
  );

  /**
   * Request the chart's data
   * @param {String} dName The name of the dataset
   * @param {String} dVersion The version of the dataset
   * @param {Object} newComputeSpec The computeSpec from the chartSpec:
   * { aggregate, bins, transforms }
   * @param {Number} publicationId The id used for claims when missing IB id
   * @param {Number} sampleLimit The dataSampleLimit to determine numRows for the request
   * @param {Boolean} onlyAttemptCompute Should we only attempt to use the compute?
   * Opposite of skipAttemptCompute, useful when we want to avoid getting the whole dataset
   * @param {Boolean} skipAttemptCompute Should we skip the compute request? Useful when populating
   * the chart builder with the raw row data, so that we don't accidentally trigger a compute
   */
  const requestData = useCallback(
    ({
      callback,
      dName = datasetName,
      dVersion = datasetVersion,
      newComputeSpec,
      publicationId,
      sampleLimit = dataSampleLimit,
      onlyAttemptCompute = false,
      skipAttemptCompute = false,
    }) => {
      // If there was a computeSpec passed, update the state
      if (newComputeSpec) setComputeSpec(newComputeSpec);

      // Does the new request use the compute?
      const usingCompute = shouldUseBackendCompute({
        computeSpec: newComputeSpec ?? computeSpec,
        numRows: sampleLimit,
      });

      // Quit if we're only attempting compute & we decide not to use it
      if (onlyAttemptCompute && !usingCompute) return;

      // Set loading indicator and message
      const shownText = usingCompute
        ? 'Processing your data, please wait...'
        : dName && dVersion
        ? `Loading ${dName} v${dVersion}, please wait...`
        : 'Loading data, please wait...';
      setPlaceholderText(shownText);

      dispatch(dismissAllToasts());
      dispatch(
        sampleDatasetRequest({
          callback,
          dcChartId: !isSessionless && isFatTable ? objectId : null,
          ...(!skipAttemptCompute && { computeSpec: newComputeSpec ?? computeSpec }),
          insightsBoardId,
          numRows: sampleLimit ?? undefined,
          pipelinerDatasetId,
          publicationId,
          selectedColumns: !isSessionless && isFatTable ? getColumnsInUseNames(dcSpec) : null,
          useCache: false,
        }),
      );
    },
    [
      computeSpec,
      dataSampleLimit,
      datasetName,
      datasetVersion,
      dcSpec,
      dispatch,
      insightsBoardId,
      isFatTable,
      isSessionless,
      objectId,
      pipelinerDatasetId,
      setComputeSpec,
    ],
  );

  /**
   * Set the dataSampleLimit and retrieve the raw dataset if we don't have it
   * If we do have the raw dataset, update the component state with the information
   *
   * TODO: Add a user config setting which allows the user to always open with a sample
   */
  useEffect(() => {
    if (firstRender) {
      // Request data if we don't have it, or if we don't have enough yet
      const hasColumns = datasetData.columns && datasetData.columns?.length > 0;
      const hasEnoughRows =
        datasetData.rows && datasetData.rows?.length >= (dataSampleLimit ?? totalRowCount);

      // Fetch the raw data regardless of compute or not
      // So that we can populate suggestions in the CB from the raw dataset
      if (!hasEnoughRows || !hasColumns) {
        requestData({
          dName: datasetName,
          dVersion: datasetVersion,
          sampleLimit: chartEditingMode && !usedCompute ? dataSampleLimit : 1,
          onlyAttemptCompute: false,
          skipAttemptCompute: true,
        });
      }
    }

    // If we have the data, use it
    if (datasetData.rows && datasetData.columns) {
      setColumns(datasetData.columns);
      setRows(datasetData.rows.slice(0, dataSampleLimit ?? datasetData.rows.length));
    }
    // eslint-disable-next-line
  }, [
    chartEditingMode,
    datasetData.columns,
    datasetData.columns?.length,
    datasetData.rows,
    datasetData.rows?.length,
    datasetName,
    datasetVersion,
    isSessionless,
  ]);

  /**
   * Set the computed data when it's available
   */
  useEffect(() => {
    if (computedData && !isEmpty(computedData) && !isComputeFailed && !isComputeLoading) {
      setComputedColumns(computedData.columns ?? []);
      setComputedRows(computedData.rows ?? []);
    } else if (isComputeFailed) {
      setComputedColumns(null);
      setComputedRows(null);
    }
  }, [computedData, computedData.columns, computedData.rows, isComputeFailed, isComputeLoading]);

  return {
    columns,
    dataSampleLimit,
    placeholderText,
    requestData,
    rows,
    setDataSampleLimit,
    setPlaceholderText,
    setRows,
    computedColumns,
    computedRows,
    usedCompute,
  };
};

const ChartBuilderContainer = (props) => {
  const {
    chartEditingMode,
    datasetName,
    datasetVersion,
    dcSpec,
    insightsBoardId,
    objectId,
    publicationId,
  } = props;

  /**
   * Initialize the options state which contains the dataset & chart type information
   */
  const { series = [] } = dcSpec?.plot;
  const { horizontal = false } = dcSpec?.plot?.presentation;
  const [options, setOptions] = useState({
    datasetName,
    datasetVersion,
    chartType: initializeChartType(chartEditingMode, series, horizontal),
  });

  /**
   * Pull the computeSpec out of the existing dcSpec if editing an existing chart
   * This is used to request and retrieve the computed data when using BE Compute
   */
  const { aggregate = [], bins = [], transforms = [] } = dcSpec?.values ?? {};
  const [computeSpec, setComputeSpec] = useState({ aggregate, bins, transforms });

  // Do we have a long list of columns?
  const [isFatTable, setIsFatTable] = useState(false);

  // Has the user manually changed their dataSampleLimit?
  const [hasUserSetDataSampleLimit, setHasUserSetDataSampleLimit] = useState(
    chartEditingMode && Boolean(dcSpec.values?.dataSampleLimit),
  );

  // Is it the first render of this component?
  const firstRender = useFirstRender();

  const dispatch = useDispatch();
  const datasetList = useSelector(selectVisibleDatasetsByNameVersion);

  // We're sessionless when we're on an IB
  const isSessionless = !!insightsBoardId;

  const pipelinerDatasetId = useSessionPipelinerDatasetId({
    datasetName,
    datasetVersion,
    pipelinerDatasetId: props.pipelinerDatasetId,
  });

  // The storage state for the current session context
  const sessionDatasetStorage = useSelector(selectSessionDatasetStorage);
  const sessionlessDatasetStorage = useSelector(selectSessionlessDatasetStorage);

  /**
   * The chart's data for the base (un-computed) dataset.
   * This can come from either the props (dcSpec) when editing, or from the
   * dataset reference info when creating a new chart
   */
  const {
    data: datasetData,
    // Did we fail to load the dataset from its reference info?
    isFailed,
    // Are we currently loading the dataset from its reference info?
    isLoading,
    // Was there an error loading the dataset?
    error: rawDataLoadingError,
  } = useDataFromStorage({
    dcChartId: isSessionless && isFatTable ? objectId : null,
    datasetStorage: isSessionless ? sessionlessDatasetStorage : sessionDatasetStorage,
    isTable: false,
    pipelinerDatasetId,
    usedCompute: false,
  });

  /**
   * The chart's data from the computed dataset.
   * This comes from the POST to /compute_dataset when using BE Compute.
   */
  const {
    data: computedData,
    isFailed: isComputeFailed,
    isLoading: isComputeLoading,
    error: computedDataLoadingError,
  } = useDataFromStorage({
    dcChartId: isFatTable ? objectId : null,
    computeSpec,
    datasetStorage: isSessionless ? sessionlessDatasetStorage : sessionDatasetStorage,
    isTable: false,
    pipelinerDatasetId,
    usedCompute: true,
  });

  // get session id
  const sessionId = useSelector(selectSession);

  // get pipeliner dataset's description w/totalRowCount
  const description = usePipelinerDatasetDescription(
    pipelinerDatasetId,
    isSessionless
      ? new InsightsBoardDatasetClaim(insightsBoardId)
      : new SessionDatasetClaim(sessionId),
    { totalRowCount: true },
  );

  // get dataset description
  const totalRowCount = description.kind === 'data' ? description.data.totalRowCount : 0;

  // Get our component state & data which depend on the data retrieval process
  const {
    columns,
    dataSampleLimit,
    placeholderText,
    requestData,
    rows,
    setDataSampleLimit,
    setPlaceholderText,
    setRows,
    computedColumns,
    computedRows,
    usedCompute,
  } = useData(
    chartEditingMode,
    computeSpec,
    datasetData,
    datasetName,
    datasetVersion,
    dcSpec,
    dispatch,
    firstRender,
    insightsBoardId,
    isFatTable,
    isSessionless,
    objectId,
    pipelinerDatasetId,
    setComputeSpec,
    totalRowCount,
    computedData,
    isComputeFailed,
    isComputeLoading,
  );

  /** Whether the dataset backing this chart is loading. */
  let isLoadingDataset = isLoading; // default to dataset loading state
  // if we're using compute, use compute loading state
  if (usedCompute) isLoadingDataset ||= isComputeLoading;
  // if we're not using compute, use the description loading state
  else isLoadingDataset ||= description.kind === 'loading';

  /** Whether the dataset backing this chart failed to load. */
  let isLoadingFailed = isFailed; // default to dataset failed state
  // if we're using compute, use compute failed state
  if (usedCompute) isLoadingFailed ||= isComputeFailed;
  // if we're not using compute, use the description error state
  else isLoadingFailed ||= description.kind === 'error';

  // Handles displaying error/info messages when the sampleDatasetRequest fails
  useEffect(() => {
    if (!isLoadingDataset && isLoadingFailed) {
      const alertMessage =
        !options.datasetName || !options.datasetVersion
          ? 'Dataset failed to load.'
          : `${options.datasetName} v${options.datasetVersion} failed to load.`;

      dispatch(
        addToast({
          toastType: TOAST_ERROR,
          length: TOAST_LONG,
          message: alertMessage,
        }),
      );

      setPlaceholderText(
        `${alertMessage} Please reduce the number of cells by using Sample or Compute.`,
      );
    }
  }, [
    dispatch,
    isLoadingDataset,
    isLoadingFailed,
    options.datasetName,
    options.datasetVersion,
    setPlaceholderText,
  ]);

  /**
   * Handles sending the required utterance to the BE
   * @param {Object} message utterance to be sent
   * @param {function} callback callback function to be executed with the utterance, if any
   */
  const sendUtterance = ({ message, callback }) => {
    dispatch(
      describeAndSendUtteranceRequest({
        message,
        callback,
      }),
    );
  };

  /**
   * Handle when the dataSampleLimit is changed by the user or validateDataSampleLimit
   * We should be very careful about how much data we retrieve
   * @param {Number} rowCount The number of rows to sample
   * @param {Boolean} hasUserSetLimit Whether the user has manually set a limit
   */
  const chooseDataSampleLimit = (rowCount, hasUserSetThisLimit) => {
    // Do nothing if the dataSampleLimit didn't change
    // Do not infer changes if the user manually set the dataSampleLimit
    if (rowCount === dataSampleLimit || (hasUserSetDataSampleLimit && !hasUserSetThisLimit)) return;

    // Indicate if the user manually set the data sample limit
    if (hasUserSetThisLimit) setHasUserSetDataSampleLimit(hasUserSetThisLimit);

    const isFullData = rowCount === null || rowCount >= totalRowCount;
    const isDataSampleIncomplete =
      datasetData.rows?.length < totalRowCount && datasetData.rows.length < rowCount;
    const isComputedDataMissing =
      rowCount === null && (computedRows === null || computedColumns === null);

    // Set the dataSampleLimit to the new value
    setDataSampleLimit(isFullData ? null : rowCount);

    // Fire data requests if we're using a sample and we don't have enough data
    // Or, if we're using a compute and we don't have the computed data
    if (isDataSampleIncomplete || isComputedDataMissing) {
      requestData({
        sampleLimit: rowCount,
        // We shouldn't request regular data if the user didn't manually set the sample limit
        onlyAttemptCompute: rowCount === null && !hasUserSetThisLimit,
        // If we're trying to use a sample, skip the compute check
        skipAttemptCompute: rowCount !== null,
      });
      return;
    }

    // If we didn't request any data, set the rowCount (same as sampleLimit)
    setRows(isFullData ? datasetData.rows : datasetData.rows.slice(0, rowCount));
  };

  // Signal that we're ready to use the CB
  useEffect(() => {
    const hasComputedData = usedCompute
      ? computedRows?.length > 0 && computedColumns?.length > 0
      : true;
    const hasRawData = rows?.length > 0 && columns?.length > 0;
    const hasCompleteMessage = placeholderText === LOADING_COMPLETE_MESSAGE;

    if (hasComputedData && hasRawData && !isLoadingDataset && !hasCompleteMessage) {
      setPlaceholderText(LOADING_COMPLETE_MESSAGE);
      setIsFatTable(rows?.[0]?.length > FAT_TABLE_LENGTH || false);
    }
  }, [
    columns,
    columns?.length,
    computedColumns,
    computedColumns?.length,
    computedRows,
    computedRows?.length,
    isLoadingDataset,
    isSessionless,
    placeholderText,
    rows,
    rows?.length,
    setPlaceholderText,
    usedCompute,
  ]);

  // If the dataset changes, get the new data and reset the sampleLimit
  useEffect(() => {
    if (!firstRender) {
      setOptions((opt) => ({
        ...opt,
        datasetName,
        datasetVersion,
      }));
      setDataSampleLimit(null);
      requestData({
        dName: datasetName,
        dVersion: datasetVersion,
        sampleLimit: 1,
        onlyAttemptCompute: false,
        skipAttemptCompute: true,
      });
    }
    // eslint-disable-next-line
  }, [datasetName, datasetVersion, dcSpec?.values?.dataSampleLimit, setDataSampleLimit]);

  /**
   * Handles closing the dataset selection menu
   * @param {Object} newDataset The new dataset. Null if menu closed without selection
   * @return {Boolean} True or false if the dataset is changing
   */
  const handleDatasetClose = (newDataset) => {
    if (newDataset) {
      // If the current dataset is not the same as the selected dataset, fire a Use
      const isCurrent =
        options.datasetName === newDataset[0] && options.datasetVersion === newDataset[1];

      if (!isCurrent) {
        // Set message that we're loading the new dataset
        setPlaceholderText(`Loading ${newDataset[0]} v${newDataset[1]}, please wait...`);

        try {
          // Send a 'use dataset' message
          dispatch(dismissAllToasts());
          sendUtterance({
            message: {
              skill: 'SetCurrentDataset',
              kwargs: {
                dataset_entity: JSON.stringify({
                  dataset_name: newDataset[0],
                  version: newDataset[1],
                }),
              },
            },
          });
        } catch (e) {
          // Fail with a toast and reset the placeholder message
          dispatch(
            addToast({
              toastType: TOAST_ERROR,
              length: TOAST_LONG,
              message: 'Could not change datasets. Please try again.',
            }),
          );
          setPlaceholderText('Complete the required fields to plot your chart.');
        }
        return true;
      }
    }
    return false;
  };

  /**
   * Sets the new chart type when the selection changes
   * @param {String} chartTypeString newly selected chart type
   */
  const setChartType = (chartTypeString) => {
    setOptions({ ...options, chartType: iconMapping[chartTypeString] });
  };

  // Handles closing the chart builder modal when browser history changes
  const handleNavigateBack = useCallback(() => dispatch(closeChartBuilder()), [dispatch]);

  // Add and remove event listeners
  useEffect(() => {
    window.addEventListener('popstate', handleNavigateBack);
    return () => {
      window.removeEventListener('popstate', handleNavigateBack);
    };
  }, [handleNavigateBack]);

  return ReactDOM.createPortal(
    <ChartBuilder
      data={dcSpec}
      series={series}
      datasetList={datasetList}
      rows={rows}
      totalRowCount={totalRowCount}
      isFatTable={isFatTable}
      isLoadingDataset={isLoadingDataset}
      isLoadingFailed={isLoadingFailed}
      dataLoadingError={rawDataLoadingError ?? computedDataLoadingError}
      columnsTypes={columns}
      options={options}
      chartEditingMode={chartEditingMode}
      placeholderText={placeholderText}
      renderChart={rows?.length > 0}
      handleDatasetClose={handleDatasetClose}
      setChartType={setChartType}
      insightsBoardId={insightsBoardId}
      chooseDataSampleLimit={chooseDataSampleLimit}
      dataSampleLimit={dataSampleLimit}
      externalName={props.externalName}
      toggleChartEditingMode={props.toggleChartEditingMode}
      objectId={props.objectId}
      publicationId={publicationId}
      isSessionless={props.isSessionless}
      modifyChartCallback={props.modifyChartCallback}
      userCanModify={props.userCanModify}
      requestData={requestData}
      computedColumns={computedColumns}
      computedRows={computedRows}
      usedCompute={usedCompute}
    />,
    document.getElementsByClassName('body')[0],
  );
};

ChartBuilderContainer.propTypes = {
  chartEditingMode: PropTypes.bool, // Flag that indicates if the chart is being edited
  datasetName: PropTypes.string.isRequired, // Dataset name to be used in the chart builder
  datasetVersion: PropTypes.number.isRequired, // Dataset version to be used in the chart builder
  dcSpec: PropTypes.object, // Info required to plot the chart
  externalName: PropTypes.string, // User-facing name used to refer to the chart
  insightsBoardId: PropTypes.string, // Unique identifier for each IB
  isSessionless: PropTypes.bool, // Flag that indicates if the chart builder is backed by a session
  modifyChartCallback: PropTypes.func, // Fetches the content of the publication with the given id
  objectId: PropTypes.string, // Unique identifier for each session item
  pipelinerDatasetId: PropTypes.string,
  publicationId: PropTypes.number, // Unique identifier for each item published to an IB
  toggleChartEditingMode: PropTypes.func, // Toggles the edit mode for the chart
  userCanModify: PropTypes.bool, // Flag that indicates if the current user can modify the chart
};

ChartBuilderContainer.defaultProps = {
  chartEditingMode: false,
  dcSpec: { values: {}, plot: { presentation: {}, series: [] } },
  externalName: '',
  insightsBoardId: null,
  isSessionless: false,
  modifyChartCallback: () => {},
  objectId: undefined,
  pipelinerDatasetId: null,
  publicationId: undefined,
  toggleChartEditingMode: () => {},
  userCanModify: false,
};

export default ChartBuilderContainer;
