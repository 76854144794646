export const ACCOUNT_TABS = {
  SUBSCRIPTION: 'Subscription',
  INVOICES: 'Invoices',
  USAGEANDLIMITS: 'Usage & Limits',
};

export const USAGE_METRICS = {
  CELL_COUNT: 'cell_count',
  SESSION_LIMIT: 'session_limit',
  NL2_CODE_LIMIT: 'nl2code_limit',
};

export const CELL_COUNT_METRICS = {
  CELL_COUNT: USAGE_METRICS.CELL_COUNT,
  CELL_COUNT_LIMIT: 'cell_count_limit',
  TOTAL_CELL_COUNT: 'total_cell_count',
};

export const SESSION_COUNT_METRICS = {
  SESSION_COUNT_LIMIT: 'session_count_limit',
  TOTAL_SESSION_COUNT: 'total_session_count',
};

export const OVERALL_STATS_HEADINGS = {
  TOTAL_CELL_COUNT: 'Total Cells Created',
  TOTAL_SESSION_LIMIT: 'Total Session Count',
  MONTHLY_NL2_CODE_REQUESTS: 'Monthly Ask Requests',
};

export const UNLIMITED_LIMIT = 'UNL';

export const DEFAULT_METRICS = [USAGE_METRICS.SESSION_LIMIT, USAGE_METRICS.CELL_COUNT];

// Usage stats key names in subscriptions.reducer should match the values of this object.
export const REDUCER_METRICS_NAMES = {
  [USAGE_METRICS.SESSION_LIMIT]: 'sessionLimitUsageStats',
  [USAGE_METRICS.CELL_COUNT]: 'cellCountUsageStats',
  [USAGE_METRICS.NL2_CODE_LIMIT]: 'nl2CodeUsageStats',
};

// Subscription status constants from Stripe.
export const SUBSCRIPTION_STATUS = {
  TRIAL: 'trialing',
  ACTIVE: 'active',
  PAST_DUE: 'past_due',
  UNPAID: 'unpaid',
  CANCELED: 'canceled',
  INCOMPLETE: 'incomplete',
  INCOMPLETE_EXPIRED: 'incomplete_expired',
};

export const SUBSCRIPTION_STATUS_DISPLAY_TEXT = {
  [SUBSCRIPTION_STATUS.TRIAL]: 'Trial',
  [SUBSCRIPTION_STATUS.ACTIVE]: 'Active',
  [SUBSCRIPTION_STATUS.PAST_DUE]: 'Past Due',
  [SUBSCRIPTION_STATUS.UNPAID]: 'Unpaid',
  [SUBSCRIPTION_STATUS.CANCELED]: 'Canceled',
  [SUBSCRIPTION_STATUS.INCOMPLETE]: 'Incomplete',
  [SUBSCRIPTION_STATUS.INCOMPLETE_EXPIRED]: 'Incomplete / Expired',
};

export const PAYMENT_INTENT_STATUS = {
  REQUIRES_PAYMENT: 'requires_payment_method',
};
