import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { MarkerType, applyEdgeChanges, applyNodeChanges } from 'reactflow';
import { LAYOUT_ALGORITHM } from '../../components/ReactFlowGraph/constants/layoutConfig';
import {
  GET_GRAPH_UPDATE_SUCCESS,
  ON_EDGES_CHANGE,
  ON_NODES_CHANGE,
  RESET_GRAPH_MODE,
  SET_LAST_UPDATE,
  SET_LAYOUT_ALGORITHM,
} from '../actions/graphMode.actions';

const initialState = {
  edges: [], // array of ReactFlow formatted edge objects (see https://reactflow.dev/docs/api/edges/edge-options/)
  nodes: [], // array of ReactFlow compatible node objects (seehttps://reactflow.dev/docs/api/nodes/node-options/)
  skills: {}, // rows from dc_session_graph_skill
  products: {}, // rows from dc_session_graph_product
  // other variables
  lastUpdate: null, // latest timestamp used to fetch updates to the graph
  layoutAlgorithm: LAYOUT_ALGORITHM.LAYERED_DOWN, // algorithm used to layout graph nodes
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LAST_UPDATE: {
      return { ...state, lastUpdate: action.lastUpdate };
    }
    case RESET_GRAPH_MODE: {
      return { ...state, edges: [], nodes: [], skills: {}, products: {}, lastUpdate: null };
    }
    case GET_GRAPH_UPDATE_SUCCESS: {
      const { nodeUpdates, edgeUpdates, skillUpdates, productUpdates } = action;
      let { lastUpdate } = state;
      const nodes = cloneDeep(state.nodes);
      const nodesUpdated = isEmpty(nodeUpdates) ? 0 : nodeUpdates.length;
      const edges = cloneDeep(state.edges);
      const edgesUpdated = isEmpty(edgeUpdates) ? 0 : edgeUpdates.length;
      const skills = cloneDeep(state.skills);
      const skillsUpdated = isEmpty(skillUpdates) ? 0 : skillUpdates.length;
      const products = cloneDeep(state.products);
      const productsUpdated = isEmpty(productUpdates) ? 0 : productUpdates.length;

      // apply product updates
      if (productsUpdated > 0) {
        productUpdates.forEach((p) => {
          products[p.data_id] = {
            internalId: p.internal_id,
            name: p.product_name,
            productId: String(p.product_id),
            skillId: String(p.skill_id),
            status: p.product_status?.Valid ? p.product_status.String : null,
            type: p.product_type?.Valid ? p.product_type.String : null,
            version: p.product_version,
          };
        });
      }

      // apply skill updates
      if (skillsUpdated > 0) {
        skillUpdates.forEach((s) => {
          if (moment(lastUpdate).isBefore(moment(s.last_updated))) lastUpdate = s.last_updated;
          skills[s.skill_id] = {
            answers: s.answers,
            currentDatasets: s.currentDatasets,
            dropped: s.dropped,
            exitCode: s.exit_code?.Valid ? s.exit_code.String : null,
            fromUser: s.from_user,
            inputs: s.inputs,
            isComment: s.is_comment,
            kwargs: s.kwargs,
            lastUpdated: s.last_updated,
            messageIds: s.skill_message_ids,
            sessionStartup: s.session_startup,
            skill: s.skill,
            skillUUID: s.skill_uuid,
            status: s.skill_status?.Valid ? s.skill_status.String : null,
            utterance: s.utterance,
          };
        });
      }

      // apply edge updates
      if (edgesUpdated > 0) {
        edgeUpdates.forEach((e) => {
          if (moment(lastUpdate).isBefore(moment(e.last_updated))) lastUpdate = e.last_updated;
          edges.push({
            data: {
              lastUpdated: e.last_updated,
              productId: String(e.product_id),
              bendPoints: [],
            },
            id: String(e.data_id),
            markerEnd: { type: MarkerType.ArrowClosed },
            source: String(e.source_node_id),
            target: String(e.target_node_id),
            type: 'dependency-edge',
          });
        });
      }

      // apply node updates
      if (nodesUpdated > 0) {
        nodeUpdates.forEach((n) => {
          // Update lastUpdate
          if (moment(lastUpdate).isBefore(moment(n.last_updated))) lastUpdate = n.last_updated;
          // Add new node to the graph
          nodes.push({
            id: String(n.data_id),
            data: {
              skillId: String(n.skill_id),
              lastUpdated: n.last_updated,
            },
            position: { x: 0, y: 0 },
            type: 'skill-node',
          });
        });
      }

      return { ...state, lastUpdate, nodes, edges, skills, products };
    }
    case ON_NODES_CHANGE: {
      return { ...state, nodes: applyNodeChanges(action.nodeChanges, state.nodes) };
    }
    case ON_EDGES_CHANGE: {
      return { ...state, edges: applyEdgeChanges(action.edgeChanges, state.edges) };
    }
    case SET_LAYOUT_ALGORITHM: {
      return { ...state, layoutAlgorithm: action.layoutAlgorithm };
    }
    default:
      return state;
  }
};
