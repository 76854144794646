import { CLASS, REPLAY_MESSAGES, REPLAY_START, SKILL_EVENTS } from '../../../constants';

export const isAlertMessageType = (message) => {
  const alertMessageTypes = ['failure', 'question'];
  return alertMessageTypes.includes(message.class);
};

/**
 * Returns true if message is a chart type. Otherwise, returns false.
 *
 * @param {Object} message a message object
 */
export const isDashboardItem = (message) => {
  const dashboardItems = ['viz', 'table', 'note'];
  return dashboardItems.includes(message.type);
};

/**
 * Filters the messages into skillEvent messages, replay messages,
 * placeholder message, and general messages.
 * General messages is any message that is not a placeholder or done message.
 *
 * @param {Object[]} messages List of message objects
 */
export const filterMessages = (messages) => {
  const skillEventMessages = []; // List of Done message objects
  const replayMessages = []; // List of replay message strings
  const cancelMessages = []; // List of messages that have cancel classx
  const placeholderMessages = []; // List placeholder message string
  const generalMessages = []; // List of general message objects
  let isStepwise = false; // Indicates whether a replay is stepwise
  messages.forEach((message) => {
    // Update context indicators
    if (message.skill_event && Object.values(SKILL_EVENTS).includes(message.skill_event)) {
      // Exclude new bubble messages because they are not related to the skill's context.
      if (message.skill_event !== SKILL_EVENTS.NEW_BUBBLE) skillEventMessages.push(message);
    }
    if (Object.values(REPLAY_MESSAGES).includes(message.data)) {
      replayMessages.push(message.data);
    }
    if (message.instruction === -1 || message.stepwise) {
      isStepwise = true;
    }
    if (message.class && message.class === CLASS.CANCEL) {
      cancelMessages.push(message.data);
    }
    // Split placeholder and general messages
    if (message.class && message.class === CLASS.UPDATE) {
      placeholderMessages.push(message.data);
    } else {
      generalMessages.push(message);
    }
  });
  return {
    skillEventMessages,
    replayMessages,
    cancelMessages,
    placeholderMessages,
    generalMessages,
    isStepwise,
  };
};

// Select all visual message - ignore all the messages that will trigger FE action
export const selectVisualMessages = (messages) => {
  const res = [];
  messages.forEach((message) => {
    if (message.instruction) return;
    if (message.type === 'file') message.data.method = 'manual';
    res.push(message);
  });
  return res;
};

// We want to extract all the utterances included in the first "Replay" block
export const selectDashboardMessages = (messages) => {
  const res = [];
  // Notice that there can be nested level of replay
  // The variable indicates how "deep" we are in the replay stack
  let replayIndicator = 0;
  let workflow;
  for (let i = 0; i < messages.length; i++) {
    const message = messages[i];
    // Signal the start of a replay
    if (message.type === 'text' && message.data.includes(REPLAY_START)) {
      replayIndicator++;
      if (replayIndicator === 1) {
        // if we are at level-1, this means the utterance starts the top-level replay block
        // Hence, whatever follows "Replay the workflow ..." is the session name
        workflow = message.data.slice(REPLAY_START.length).trim();
      }
      // These replay messages have no visual effect anyway
      // So skip them
      continue;
    }
    if (message.type === 'text' && message.data.includes(REPLAY_MESSAGES.END)) {
      replayIndicator--;
      // If we have exited the top-level replay block,
      // we are done. All the necessary meesages for dashboard reload are retrieved
      if (replayIndicator === 0) break;
      // Otherwise, we shall continue
      else continue;
    }
    // Whatever inside the replay block needs to be retrieved
    if (replayIndicator > 0) {
      res.push(message);
    }
  }
  // Return both the session name and the utteranced to-be-reloaded.
  return { workflow, dashboardMessages: res };
};

/**
 * @param {Object} generalMessages a message object
 * @returns the list of all the user messages in string format.
 */
export const getClientMessages = (generalMessages) => {
  return generalMessages
    .filter((message) => message.src_type === 'Client')
    .map((message) => message.data);
};

/**
 * Returns whether there has been activity in this session.
 * This excludes automated startup activity.
 * @param {List} messages list of front-end context objects. Typically from state.messages
 * @returns True or False
 */
export const messagesShowActivity = (messages) => {
  return messages.length > 2;
};
