import { IntegrationProvider } from '../types/integrations.types';

export const endpoints = {
  app: '/api/app',
  apikey: '/api/apikey',
  web_app_config: '/api/web_app_config',
  catalogDataset: (id: string) => `/api/catalog/datasets/${id}`,
  chat: '/api/chat',
  fetch: '/api/fetch',
  chart: '/api/chart',
  charts: '/api/charts',
  checkLicenseExpiry: '/api/license/check_expiry',
  config: '/api/config',
  dashboard: '/api/dashboard',
  file: '/api/file',
  files: '/api/files',
  getUploadUrl: '/api/get_upload_url',
  download: '/api/download',
  login: '/api/auth/login',
  refresh: '/api/auth/refresh',
  register: '/api/auth/register',
  otp: '/api/auth/otp',
  isUserRegistered: '/api/auth/is_user_registered',
  emailResetToken: '/api/auth/reset_password',
  verifyEmail: '/api/verify_email',
  sendVerificationEmail: '/api/send_email_verification',
  resetPassword: '/api/auth/reset_password/confirm',
  verifyPasswordToken: '/api/auth/reset_password/verify',
  resetPasswordFromMenu: '/api/auth/update_password',
  session: '/api/session',
  session_name: '/api/session_name',
  sessionSnapshot: '/api/session_snapshot',
  snapshot: '/api/snapshot',
  userRecord: '/api/user_record',
  loadRecommendation: '/api/load_recommendation',
  dbConnection: '/api/dbconnection',
  dbConnectionCopy: '/api/dbconnection/copy',
  dbFieldOptions: '/api/dbconnection/field_options',
  workflow: '/api/workflow',
  workflowCopy: '/api/workflow/copy',
  embed: '/api/v1',
  embedChart: '/api/v1/chart',
  embedModel: '/api/v1/model',
  logUserAction: '/api/log_user_action',
  interrupt: '/api/interrupt',
  extendUtteranceTimeout: '/api/extend_utterance_timeout',
  ssoDiscourse: '/api/sso/discourse',
  insightsBoard: '/api/insights_board',
  insightsBoardCharts: '/api/insights_board/charts',
  insightsBoardCrossFilters: (id: number) => `/api/insights_board/${id}/cross_filters`,
  insightsBoardPublications: (id: number) => `/api/insights_board/${id}/publications`,
  sharedInsightsBoard: '/api/shared_insights_board',
  insightsBoardFonts: '/api/insights_board_fonts',
  organization: '/api/organization',
  logout: '/api/auth/logout',
  samlAuth: '/api/saml/0/login',
  iapAuth: '/api/iap/login',
  publication: '/api/publication',
  systemVersion: '/api/version',
  notification: '/api/notification',
  seenNotifications: '/api/seen_notifications',
  subscriptions: '/api/subscriptions',
  subscriptionTiers: '/api/subscription_tiers',
  showSubscriptionPopup: '/api/subscriptions_access',
  checkForFreeTrial: '/api/subscriptions_can_use_free_trial',
  manageNotifications: '/api/manage_notifications',
  workspace: (id: number) => `/api/workspace/${id}`,
  workspaceCreate: (name: string) => `/api/workspace/${name}`,
  workspaceGetAvailable: '/api/workspace/available',
  workspaceRemoveObject: (id: number) => `/api/workspace/${id}/remove`,
  workspaceShareWith: (id: number) => `/api/workspace/${id}/share`,
  workspaceUnshareWith: (id: number) => `/api/workspace/${id}/unshare`,
  workspaceGetAccess: (id: number) => `/api/workspace/${id}/access`,
  userRole: (id: number) => `/api/user_role/${id}`,
  allUserRoles: '/api/user_role',
  role: '/api/role',
  feature: '/api/feature',
  workspaceV2: '/api/workspacev2',
  workspaceV2Snapshot: '/api/workspacev2/snapshot',
  exampleDatasets: '/api/example_datasets',
  userActionResetPassword: (id: number) => `/api/user_action/reset_password/${id}`,
  contact: 'api/contact',
  query: 'api/query',
  profilePicture: '/api/profile_picture',
  profilePictures: '/api/profile_pictures',
  profilePictureData: '/api/profile_pictures/data',
  usageStats: (metric: string) => `/api/usage/${metric}`,
  modifyChart: (id: number) => `/api/chart/${id}`,
  userProfile: '/api/profile',
  userFeedback: '/api/feedback',
  llmConnection: '/api/llm_connection',
  table: '/api/table',
  cancelTask: (sessionId: string, taskId: string) =>
    `/app/sessions/${sessionId}/tasks/${taskId}/cancel`,
  tasks: (sessionId: string) => `/api/sessions/${sessionId}/tasks`,
  executeNLQuery: '/code/execute_nl_query',
  dataset: '/api/dataset',
  patchWorkspaceDataset: (dcObjectId: number) => `/api/workspacev2/${dcObjectId}`,
  chartById: (id: string) => `/api/charts/${id}`,
  getChartWorkflow: (id: string) => `/api/charts/${id}/workflow`,
  getWorkspaceDatasetWorkflow: (id: number) => `/api/workspacev2/${id}/workflow`,
  sessionDatasets: (sessionID: string): string => `/api/sessions/${sessionID}/datasets`,
  singleSessionDataset: (sessionID: string, dcDatasetID: string): string =>
    `/api/sessions/${sessionID}/datasets/${dcDatasetID}`,
  sessionCharts: (sessionID: string): string => `/api/sessions/${sessionID}/charts`,
  singleSessionChart: (sessionID: string, dcChartID: string): string =>
    `/api/sessions/${sessionID}/charts/${dcChartID}`,
  sessionCollaborators: (sessionID: string): string => `/api/sessions/${sessionID}/users`,
  oauth: (oAuthProvider?: IntegrationProvider) =>
    `/api/oauths${oAuthProvider ? `/${oAuthProvider}` : ''}`,
  oauthClient: (clientId: string) => `/api/clients/${clientId}`,
  oauthApproveConsent: '/api/oauth/authorization',
  snowflakeAuth: '/api/snowflake/auth',
  externalOAuthClientsByProvider: (provider: IntegrationProvider) =>
    `/api/external_oauth_client?provider=${provider}`,
  externalOAuthClient: '/api/external_oauth_client',
  externalOAuthClientById: (id: number) => `/api/external_oauth_client/${id}`,
};

const DC_APP_SERVICE_ROUTE = '/app';

export const dcAppServiceEndpoints = {
  deleteCharts: `${DC_APP_SERVICE_ROUTE}/charts`,
  restoreWorkflow: `${DC_APP_SERVICE_ROUTE}/restore_workflow`,
  complete: `${DC_APP_SERVICE_ROUTE}/autocomplete`,
  retrievePipelinerDataset: (pipelinerDatasetId: string) =>
    `${DC_APP_SERVICE_ROUTE}/dataset/${pipelinerDatasetId}/data`,
  testAllDbConnection: `${DC_APP_SERVICE_ROUTE}/test_all_db_connections`,
  datasetInfo: `${DC_APP_SERVICE_ROUTE}/dataset_info`,
  datasetNamesTable: `${DC_APP_SERVICE_ROUTE}/dataset_names_table`,
  datasetNames: `${DC_APP_SERVICE_ROUTE}/dataset_names`,
  modelNames: `${DC_APP_SERVICE_ROUTE}/models`,
  chartNamesTable: `${DC_APP_SERVICE_ROUTE}/chart_names_table`,
  describeColumn: `${DC_APP_SERVICE_ROUTE}/describe_column`,
  describeAllColumn: `${DC_APP_SERVICE_ROUTE}/describe_all_columns`,
  describeAllColumnsAsync: `${DC_APP_SERVICE_ROUTE}/describe_all_columns_async`,
  columnTypes: `${DC_APP_SERVICE_ROUTE}/column_types`,
  allColumnTypes: `${DC_APP_SERVICE_ROUTE}/all_column_types`,
  utterance: `${DC_APP_SERVICE_ROUTE}/utterance`,
  allDefinedExpressions: `${DC_APP_SERVICE_ROUTE}/all_defined_expressions`,
  definedExpressions: `${DC_APP_SERVICE_ROUTE}/defined_expressions`,
  suggestedExpressions: `${DC_APP_SERVICE_ROUTE}/suggested_expressions`,
  retrieveLineageGraph: (objectId: string, objectType: string) =>
    `${DC_APP_SERVICE_ROUTE}/${objectType}/${objectId}/lineage`,
  exportDataset: `${DC_APP_SERVICE_ROUTE}/export_dataset`,
  shareChart: `${DC_APP_SERVICE_ROUTE}/share_chart`,
  listDatasets: `${DC_APP_SERVICE_ROUTE}/list_datasets`,
  getSlicedWorkflow: `${DC_APP_SERVICE_ROUTE}/get_sliced_workflow`,
  baseDatasets: `${DC_APP_SERVICE_ROUTE}/get_base_datasets`,
  columnValueAutocomplete: `${DC_APP_SERVICE_ROUTE}/get_column_value_suggestions`,
  temporalExpressionSuggestions: `${DC_APP_SERVICE_ROUTE}/get_temporal_expression_suggestions`,
  isValidTemporalExpression: `${DC_APP_SERVICE_ROUTE}/get_is_valid_temporal_expression`,
  labelInference: `${DC_APP_SERVICE_ROUTE}/label_inference`,
  createDatasetFromDatabase: `${DC_APP_SERVICE_ROUTE}/dataset/create_from_db`,
  datasetFromSession: `${DC_APP_SERVICE_ROUTE}/dataset/from_session`,
  dataset: `${DC_APP_SERVICE_ROUTE}/datasets`,
  computedDataset: `${DC_APP_SERVICE_ROUTE}/compute_dataset`,
  messageStore: `${DC_APP_SERVICE_ROUTE}/message_store`,
  currentDcDatasetId: `${DC_APP_SERVICE_ROUTE}/current_dataset_id`,
  listNamespaces: (connectionUUID: string) =>
    `${DC_APP_SERVICE_ROUTE}/databases/${connectionUUID}/namespaces`,
  listNamespaceTables: (connectionUUID: string, namespace: string) =>
    `${DC_APP_SERVICE_ROUTE}/databases/${connectionUUID}/namespaces/${namespace}/tables`,
  getDatabaseTableMetadata: (connectionUUID: string, namespace: string, table: string) =>
    `${DC_APP_SERVICE_ROUTE}/databases/${connectionUUID}/namespaces/${namespace}/tables/${table}`,
  materializeDataset: (id: string) => `${DC_APP_SERVICE_ROUTE}/dataset/${id}/materialize`,
  datasetSource: `${DC_APP_SERVICE_ROUTE}/dataset_source`,
  datasetDescription: (pipelinerDatasetId: string) =>
    `${DC_APP_SERVICE_ROUTE}/dataset/${pipelinerDatasetId}/description`,
};
const CHARTING_SERVICE_ROUTE = '/charting_api';
export const chartingServiceEndpoints = {
  chartImage: (chartId: string) => `${CHARTING_SERVICE_ROUTE}/v2/charts/${chartId}/image`,
};

export const stripeEndpoints = {
  getSubscriptions: '/payments/get-subscriptions',
  getInvoices: '/payments/invoice',
  config: '/payments/config',
  getCustomerId: '/payments/has-customer-id',
  createCustomer: '/payments/create-customer',
  createSubscription: '/payments/create-subscription',
  updateSubscription: '/payments/update-subscription',
  subscriptionPrice: '/payments/subscription-price',
  currentSubscription: '/payments/current-subscription',
  attachPaymentMethod: '/payments/attach-payment-method',
  paymentMethods: '/payments/methods',
  cancelSubscription: '/payments/cancel-subscription',
  upcomingInvoice: '/payments/upcoming-invoice',
};

export const nl2codeEndpoints = {
  generateCode: '/code/generate_code',
  metadata: '/vector_mgmt/cache',
  sweepContext: '/code/sweep_context',
  checkContext: '/code/check_context',
  queryLlm: '/code/query_llm',
  registerEngagement: '/code/register_engagement',
  cancelQuery: 'code/cancel_nl_query',
};
