import {
  GET_SUBSCRIPTION_TIERS_FAILURE,
  GET_SUBSCRIPTION_TIERS_REQUEST,
  GET_SUBSCRIPTION_TIERS_SUCCESS,
} from '../actions/subscriptionTiers.actions';

export const initialState = {
  subscriptionTiers: null,
  isRequesting: false,
  error: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTION_TIERS_REQUEST:
      return {
        ...state,
        isRequesting: true,
      };
    case GET_SUBSCRIPTION_TIERS_SUCCESS:
      return {
        ...state,
        subscriptionTiers: action.subscriptionTiers,
        isRequesting: false,
        error: undefined,
      };
    case GET_SUBSCRIPTION_TIERS_FAILURE:
      return {
        ...state,
        error: action.error,
        isRequesting: false,
      };
    default:
      return state;
  }
};
