import { LOCATION_CHANGE } from 'redux-first-history';
import { select, takeLatest } from 'redux-saga/effects';
import { selectBrowserType } from './selectors';
import { browserTypeAlert } from './utils/alert-channels';

function* browserTypeCheck() {
  const state = yield select(selectBrowserType);
  if (!state) {
    const { userAgent } = navigator;
    let browser = 'unknown';
    browser =
      /chrome|crios/i.test(userAgent) &&
      !/opr|opera|chromium|edg|ucbrowser|googlebot/i.test(userAgent)
        ? 'Chrome'
        : browser;
    const isMobile = /mobile/i.test(userAgent);
    if (browser !== 'Chrome' && !isMobile) {
      yield browserTypeAlert();
    }
  }
}

export default function* () {
  yield takeLatest(LOCATION_CHANGE, browserTypeCheck);
}
