import PropTypes from 'prop-types';
import React from 'react';

/**
 * A general container component to wrap
 * all the buttons and refresh info
 * on the right of header of visual
 */
const ButtonRowContainer = ({ children }) => {
  return <span className="ChartHeader-ButtonsAndRefreshInfo">{children}</span>;
};

ButtonRowContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ButtonRowContainer;
